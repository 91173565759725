import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

import { getRoute } from '../menu/UrlAuxiliar';

export class PageHeader extends React.PureComponent {
  static propTypes = {
    menu: PropTypes.object.isRequired,
    location: PropTypes.object,
    textoDetalhe: PropTypes.string
  };

  findMenuByCurrentLocation = () => {
    const { location, menu } = this.props;

    if (location) {
      return this.findMenu(menu, location.pathname);
    }

    return null;
  };

  findMenu = (menu, route) => {
    let foundMenu = undefined;
    let found = undefined;
    let splittedMenu = undefined;

    menu.forEach(item => {
      const subMenu = item.get('subMenu');

      if (subMenu && !subMenu.isEmpty()) {
        const foundSubmenu = this.findMenu(subMenu, route);

        if (foundSubmenu.item) {
          foundMenu = item;
          found = foundSubmenu.item;

          return false;
        }
      }

      if (
        (route === item.get('route') && !item.get('urlAuxiliar')) ||
        route === `${item.get('route')}${item.get('urlAuxiliar')}`
      ) {
        foundMenu = subMenu;
        found = item;
        return false;
      }

      splittedMenu = item.get('route') && item.get('route').split('/');
      if (splittedMenu && route === splittedMenu[1]) {
        found = item;
        foundMenu = subMenu;
        return false;
      }
    });

    return {
      menu: foundMenu,
      item: found
    };
  };

  findDetalheByLocation = () => {
    const { menu, location } = this.props;
    let origem = undefined;
    let pathName = location.pathname;

    if (location) {
      origem = pathName.split('/');
    }

    return this.findMenu(menu, origem[1]);
  };

  mountBreadCrumb(found, detalhe) {
    const { textoDetalhe } = this.props;

    let object = found;
    let descricao;
    let pageTitle;
    let descDetalhes = 'Detalhes';

    if (!object || !object.item) {
      object = detalhe;
    }

    if (!object || !object.item) {
      return null;
    }

    if (object.menu.get('title')) {
      descricao = object.menu.get('title') + ' - ' + object.item.get('title');
    } else {
      descricao = object.item.get('title');
    }

    pageTitle = object.item.get('title');

    if (textoDetalhe) {
      descDetalhes = `Detalhes - ${textoDetalhe}`;
    }

    return (
      <section className="page-header">
        <div className="page-header-title">
          <div className="container">{pageTitle}</div>
        </div>

        <ul className="breadcrumb">
          <li>
            <Link to="/">Início</Link>
          </li>
          <li>
            <Link to={getRoute(object.item)}>{descricao}</Link>
          </li>
          {detalhe && <li>{descDetalhes}</li>}
        </ul>
      </section>
    );
  }

  render() {
    const found = this.findMenuByCurrentLocation();
    let detalhe = undefined;

    if (!found || !found.item) {
      detalhe = this.findDetalheByLocation();
      if (!detalhe) {
        return null;
      }
    }

    return <div>{this.mountBreadCrumb(found, detalhe)}</div>;
  }
}

function mapStateToProps(state, ownerprops) {
  return {
    menu: state.menu.get('menu'),
    location: ownerprops.location
  };
}

let PageHeaderWithRouter = withRouter(PageHeader);

export default connect(mapStateToProps)(PageHeaderWithRouter);
