import Component from 'react-pure-render/component';
import PropTypes from 'prop-types';
import React from 'react';
import InputError from './InputError.react.js';

export default class InputMask extends Component {
  static propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    errors: PropTypes.object,
    maskRegex: PropTypes.string
  };

  getInputDOMNode = () => {
    return this.refs.input;
  };

  getValue = () => {
    // return $(this.refs.input).inputmask('unmaskedvalue');
  };

  clear = () => {
    this.refs.input.value = '';
  };

  focus = () => {
    this.refs.input.focus();
  };

  render() {
    const { label, name, errors, ...rest } = this.props;
    const newProps = { ...rest };
    delete newProps.maskRegex;

    const errorProps = InputError.createError(name, errors);

    return (
      <div className={`form-group ${errorProps.hasError}`}>
        {label && <label className="label">{label}</label>}
        <input
          ref="input"
          className={errorProps && errorProps.className}
          type="text"
          data-masked=""
          name={name}
          {...newProps}
        />
        <p> {errorProps.help} </p>
      </div>
    );
  }
}
