import Panel from 'portaltransparencia-common/components/Panel.react.js';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import { PARAM_IDARQUIVO_LAI } from 'portaltransparencia-common/parametros/ParametrosPortalTransparencia';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import { downloadArquivo } from '../arquivo/Actions.js';
import PageHeader from '../components/PageHeader.react.js';
import NotasExplicativas from '../notaexplicativa/Form.react';
import ParametroService from '../parametros/ParametroService.js';
import * as actions from './Actions.js';
import LeiAcessoInformacaoResponsavel from './LeiAcessoInformacaoResponsavel.react.js';
import LinkArquivoLeiPortal from './LinkArquivoLeiPortal.react.js';

export class FormLeisRegemPortal extends Component {
  static propTypes = {
    leiesic: PropTypes.object.isRequired,
    instrucaoNormativa: PropTypes.object.isRequired,
    leiOrganicaMunicipio: PropTypes.object.isRequired,
    findLeieSic: PropTypes.func.isRequired,
    findInstrucaoNormativa: PropTypes.func.isRequired,
    downloadArquivo: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    paramLAI: PropTypes.string,
    responsavelLAI: PropTypes.object,
    findResponsavelLAI: PropTypes.func.isRequired,
    findLeiOrganicaMunicipio: PropTypes.func.isRequired
  };

  componentDidMount() {
    const {
      findLeieSic,
      findInstrucaoNormativa,
      findLeiOrganicaMunicipio,
      paramLAI,
      findResponsavelLAI
    } = this.props;
    const { entidade, exercicio } = StorageService.getItem('userInfo');

    if (paramLAI && paramLAI != '0') {
      findLeieSic(entidade.id, exercicio.id);
    }
    findResponsavelLAI();
    findInstrucaoNormativa(entidade.id, exercicio.id);
    findLeiOrganicaMunicipio();
  }

  render() {
    const {
      leiesic,
      instrucaoNormativa,
      leiOrganicaMunicipio,
      downloadArquivo,
      responsavelLAI
    } = this.props;

    return (
      <section>
        <PageHeader location={this.props.location} />

        <NotasExplicativas pathname={this.props.location.pathname} />

        <Panel expansible={false} isForm>
          <Row>
            <Col sm={6} className="mt-xs">
              <h2 className="text-subtitle">
                <b>Lei Complementar nº 131/2009 - Lei da Transparência</b>
              </h2>
              Altera a redação da Lei de Responsabilidade Fiscal (LRF) no que se
              refere à transparência da gestão fiscal.
              <br />
              <br />
              O texto inova e determina que sejam disponibilizadas, em tempo
              real, informações pormenorizadas sobre a execução orçamentária e
              financeira da União, dos Estados, do Distrito Federal e dos
              Municípios.
              <hr className="mt-xs mb-xs" />
              <h2 className="text-subtitle">
                <b>Lei de Acesso à Informação (LAI)</b>
              </h2>
              Garante ao cidadão o direito constitucional de acesso às
              informações públicas.
              <br />
            </Col>

            <Col sm={6} className="mt-xs">
              <a
                href="http://www.planalto.gov.br/ccivil_03/constituicao/constituicao.htm"
                target="_blank"
              >
                Constituição Federal Atualizada
              </a>
              <br />
              <br />
              <a
                href="http://www.planalto.gov.br/ccivil_03/leis/LCP/Lcp101.htm"
                target="_blank"
              >
                Lei Complementar nº 101/2000 - LRF
              </a>
              <br />
              <br />
              <a
                href="http://www.planalto.gov.br/ccivil_03/leis/lcp/lcp131.htm"
                target="_blank"
              >
                Lei Complementar nº 131/2009
              </a>
              <br />
              <br />
              <a
                href="http://www.planalto.gov.br/ccivil_03/_ato2011-2014/2011/lei/l12527.htm"
                target="_blank"
              >
                Lei nº 12.527/2011 - LAI
              </a>
              <br />
              <br />

              <LinkArquivoLeiPortal
                arquivo={leiesic}
                downloadArquivo={downloadArquivo}
              />

              <br />

              <LinkArquivoLeiPortal
                arquivo={instrucaoNormativa}
                downloadArquivo={downloadArquivo}
              />

              <br />

              <LinkArquivoLeiPortal
                arquivo={leiOrganicaMunicipio}
                downloadArquivo={downloadArquivo}
              />

              <hr className="mt-xs mb-xs" />

              <LeiAcessoInformacaoResponsavel responsavelLAI={responsavelLAI} />
            </Col>
          </Row>
        </Panel>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    leiesic: state.leiesic.get('leiesic'),
    instrucaoNormativa: state.leiesic.get('instrucaoNormativa'),
    leiOrganicaMunicipio: state.leiesic.get('leiOrganicaMunicipio'),
    paramLAI: ParametroService.getValorParametro(state, PARAM_IDARQUIVO_LAI),
    responsavelLAI: state.leiesic.get('responsavelLAI')
  };
}

const allActions = {
  ...actions,
  downloadArquivo
};

export default connect(mapStateToProps, allActions)(FormLeisRegemPortal);
