import Component from 'react-pure-render/component';
import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

export default class Tab extends Component {
  static propTypes = {
    children: PropTypes.node,
    title: PropTypes.string,
    hide: PropTypes.bool,
    name: PropTypes.string,
    hiddenPrint: PropTypes.bool,
    onlyTable: PropTypes.bool,
    tableResponsive: PropTypes.bool,
    active: PropTypes.bool
  };

  render() {
    const {
      children,
      hiddenPrint,
      hide,
      name,
      onlyTable,
      tableResponsive,
      active
    } = this.props;

    if (hide) {
      return null;
    }

    const classsName = hiddenPrint ? ' hidden-print' : '';

    const classes = {
      'tab-item': true,
      'tab-table': onlyTable,
      'table-responsive': tableResponsive,
      active: active
    };

    return (
      <div className={classnames(classes) + classsName} name={name}>
        {children}
      </div>
    );
  }
}
