import Panel from 'portaltransparencia-common/components/Panel.react.js';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import { PARAM_IDARQUIVO_MEDIDAS_ADOTADAS } from 'portaltransparencia-common/parametros/ParametrosPortalTransparencia';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import { downloadArquivo } from '../arquivo/Actions.js';
import PageHeader from '../components/PageHeader.react.js';
import NotasExplicativas from '../notaexplicativa/Form.react';
import ParametroService from '../parametros/ParametroService.js';
import * as actions from './Actions.js';

export class FormMedidasAdotadas extends Component {
  static propTypes = {
    findMedidasAdotadasParaCobranca: PropTypes.func.isRequired,
    downloadArquivo: PropTypes.func.isRequired,
    arquivo: PropTypes.object.isRequired,
    valorParametro: PropTypes.string,
    location: PropTypes.object.isRequired
  };

  componentDidMount() {
    const { findMedidasAdotadasParaCobranca } = this.props;
    const { entidade, exercicio } = StorageService.getItem('userInfo');

    findMedidasAdotadasParaCobranca(entidade.id, exercicio.id);
  }

  downloadMedidasAdotadas = event => {
    const { downloadArquivo, arquivo } = this.props;
    event && event.preventDefault();
    downloadArquivo(arquivo.get('id'));
  };

  render() {
    const { valorParametro } = this.props;

    return (
      <section>
        <PageHeader location={this.props.location} />

        <NotasExplicativas pathname={this.props.location.pathname} />

        <Panel expansible={false}>
          <Panel insidePanel title="Medidas Adotadas para Cobrança">
            {valorParametro && (
              <div id="medidas">
                <div>Arquivo de Medidas Adotadas para Cobrança</div>
                <button
                  id="btnMedidasAdotadas"
                  type="button"
                  className="btn neutral icon-left mt-xs"
                  onClick={this.downloadMedidasAdotadas}
                >
                  <i className="fa fa-download" />
                  Baixar
                </button>
              </div>
            )}
            {!valorParametro && (
              <div id="mensagem">
                Sem arquivo de medidas adotadas para cobrança
              </div>
            )}
          </Panel>
        </Panel>
      </section>
    );
  }
}

const allActions = {
  ...actions,
  downloadArquivo
};

function mapStateToProps(state) {
  return {
    arquivo: state.medidasAdotadasCobranca.get('arquivo'),
    valorParametro: ParametroService.getValorParametro(
      state,
      PARAM_IDARQUIVO_MEDIDAS_ADOTADAS
    )
  };
}

export default connect(mapStateToProps, allActions)(FormMedidasAdotadas);
