import { Map } from 'immutable';
import DatePicker from 'portaltransparencia-common/components/DatePicker.react.js';
import Input from 'portaltransparencia-common/components/EloInput.react.js';
import ExportButtonGroup from 'portaltransparencia-common/components/ExportButtonGroup.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import SearchPagination from 'portaltransparencia-common/components/SearchPagination.react.js';
import configuration from 'portaltransparencia-common/config/Config.js';
import {
  changeFieldValue,
  onChange,
  resetCrud
} from 'portaltransparencia-common/crud/Actions.js';
import {
  addFilterUrl,
  addPagination
} from 'portaltransparencia-common/libs/AddFilter.js';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import { PARAM_SUPRIMENTO_FUNDOS_CARTAO_CORPORATIVO } from 'portaltransparencia-common/parametros/ParametrosPortalTransparencia';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import PageHeader from '../components/PageHeader.react.js';
import NotasExplicativas from '../notaexplicativa/Form.react';
import ParametroService from '../parametros/ParametroService.js';
import UltimaAtualizacaoByTable from '../ultimaatualizacao/UltimaAtualizacaoByTable.react.js';
import * as actions from './Actions.js';
import SuprimentoFundosTable from './SuprimentoFundosTable.react.js';
import { addErrorNotification } from 'portaltransparencia-common/ui/Actions.js';

export class FormSuprimentosFundos extends Component {
  static propTypes = {
    page: PropTypes.object.isRequired,
    findSuprimentosFundos: PropTypes.func.isRequired,
    total: PropTypes.object.isRequired,
    findTotalSuprimentosFundos: PropTypes.func.isRequired,
    filtros: PropTypes.object,
    onChange: PropTypes.func,
    changeFieldValue: PropTypes.func,
    location: PropTypes.object.isRequired,
    resetCrud: PropTypes.func.isRequired,
    valorParametro: PropTypes.string,
    addErrorNotification: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.consultarSuprimentosFundos();
  }

  consultarSuprimentosFundos = event => {
    const { page } = this.props;
    const initialPage = page.set('number', 0);

    this.searchWithPage(initialPage);

    event && event.preventDefault();
    this.refs.panelFiltroSuprimentosFundo &&
      this.refs.panelFiltroSuprimentosFundo.collapse();
  };

  onExporta = (exportType, openTab, windowParams) => {
    const { apiURL } = configuration;
    const { page, addErrorNotification } = this.props;

    if (!page.get('content').isEmpty()) {
      let filtro = this.montarFiltro(page) + '&exportType=' + exportType;

      window.open(
        `${apiURL}/api/liquidacoes/suprimentos-fundos/report?${filtro}`,
        openTab,
        windowParams
      );
    } else {
      addErrorNotification(
        'A consulta não apresentou resultado. Não é possível exportar para o formato selecionado!'
      );
    }
  };

  onChangeFiltro = event => {
    const { onChange } = this.props;
    onChange('filtroSuprimentos', event);
  };

  onResetFiltro = () => {
    const {
      resetCrud,
      findSuprimentosFundos,
      findTotalSuprimentosFundos
    } = this.props;

    resetCrud('filtroSuprimentos');
    findSuprimentosFundos('');
    findTotalSuprimentosFundos('');
  };

  montarFiltro = function (page) {
    const { entidade, exercicio } = StorageService.getItem('userInfo');
    const { filtros } = this.props;

    let filter = `entidade=${entidade.id}&exercicio=${exercicio.id}`;

    if (filtros.get('cnpj')) {
      filter = addFilterUrl(filter, `cnpjCpf=${filtros.get('cnpj')}`);
    }

    if (filtros.get('nome')) {
      filter = addFilterUrl(
        filter,
        `nome=${encodeURIComponent(filtros.get('nome'))}`
      );
    }

    if (
      filtros.get('dataInicial') &&
      filtros.get('dataInicial').length === 10
    ) {
      filter = addFilterUrl(
        filter,
        `dataInicial=${filtros.get('dataInicial')}`
      );
    }

    if (filtros.get('dataFinal') && filtros.get('dataFinal').length === 10) {
      filter = addFilterUrl(filter, `dataFinal=${filtros.get('dataFinal')}`);
    }

    return addPagination(page, filter);
  };

  searchWithPage = page => {
    const { findSuprimentosFundos, findTotalSuprimentosFundos } = this.props;
    const filter = this.montarFiltro(page);

    findSuprimentosFundos(filter);
    findTotalSuprimentosFundos(filter);
  };

  mensagemSemSuprimentoFundos = suprimentoFundos => {
    if (suprimentoFundos && suprimentoFundos.isEmpty()) {
      return 'Não houve concessão de suprimento de fundos para o exercício, para esta entidade.';
    } else {
      return 'Não houve concessão de suprimento de fundos para o filtro desejado.';
    }
  };

  render() {
    const {
      page,
      filtros,
      total,
      location,
      paramCartaoCorporativo
    } = this.props;

    if (!page.has('content')) {
      return null;
    }

    return (
      <section>
        <PageHeader location={location} />

        <NotasExplicativas pathname={this.props.location.pathname} />

        <Panel
          isForm
          mobileClose
          title="Consulta"
          ref="panelFiltroSuprimentosFundo"
        >
          <form>
            <Row>
              <Col xs={12} sm={4} md={4}>
                <Input
                  id="cnpj"
                  name="cnpj"
                  ref="cnpj"
                  placeholder="CNPJ/CPF"
                  label="CNPJ/CPF"
                  type="text"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('cnpj')}
                />
              </Col>
              <Col xs={12} sm={8} md={8}>
                <Input
                  id="nome"
                  name="nome"
                  ref="nome"
                  placeholder="Nome"
                  label="Nome"
                  type="text"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('nome')}
                />
              </Col>
              <Col xs={12} sm={6} md={2}>
                <DatePicker
                  id="dataInicial"
                  name="dataInicial"
                  ref="dataInicial"
                  label="Data Inicial"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('dataInicial')}
                />
              </Col>
              <Col xs={12} sm={6} md={2}>
                <DatePicker
                  id="dataFinal"
                  name="dataFinal"
                  ref="dataFinal"
                  label="Data Final"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('dataFinal')}
                />
              </Col>

              <Col xs={12} sm={4} md={4}>
                <div className="form-group">
                  <label className="label"></label>
                  <div className="btn-group">
                    <button
                      id="btnFiltrar"
                      className="btn module-color icon-right"
                      type="submit"
                      ref="pesquisar"
                      onClick={this.consultarSuprimentosFundos}
                    >
                      Pesquisar
                      <em className="fa fa-search"></em>
                    </button>
                    <button
                      id="btnResetFilter"
                      className="btn neutral icon-right inline no-text"
                      type="button"
                      onClick={this.onResetFiltro}
                    >
                      Limpar
                      <em className="fa fa-times"></em>
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </form>
        </Panel>

        <Panel isForm tableResponsive expansible={false}>
          <Row>
            <Col xs={6}>
              <UltimaAtualizacaoByTable
                modulo="3"
                table="pagamento"
                column="dataultimaatualizacao"
              />
            </Col>
            <Col xs={6}>
              <ExportButtonGroup onClick={this.onExporta} visible={true} />
            </Col>
          </Row>

          <SuprimentoFundosTable
            suprimentosFundos={page.get('content')}
            total={total}
            history={this.props.history}
            filter={this.montarFiltro(page)}
            mensagem={this.mensagemSemSuprimentoFundos(filtros)}
            page={page}
            searchWithPage={this.searchWithPage}
          />
        </Panel>
        <SearchPagination page={page} searchWithPage={this.searchWithPage} />
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    filtros: state.crud.getIn(['filtroSuprimentos', 'currentRecord'], Map({})),
    page: state.suprimentoFundos.get('suprimentosFundos'),
    total: state.suprimentoFundos.get('total'),
    paramCartaoCorporativo: ParametroService.getValorParametro(
      state,
      PARAM_SUPRIMENTO_FUNDOS_CARTAO_CORPORATIVO
    )
  };
}

const allActions = {
  ...actions,
  onChange,
  changeFieldValue,
  resetCrud,
  addErrorNotification
};

export default connect(mapStateToProps, allActions)(FormSuprimentosFundos);
