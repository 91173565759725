import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';

import * as types from './Types.js';

const initialState = fromJS({
  organograma: []
});

function organogramaReducer(state = initialState, action) {
  switch (action.type) {
    case types.ORGANOGRAMA_LIST_START: {
      return startLoad(state);
    }

    case types.ORGANOGRAMA_LIST_ERROR: {
      return endLoad(state);
    }

    case types.ORGANOGRAMA_LIST_SUCCESS: {
      return endLoad(state.set('organograma', fromJS(action.payload)));
    }
  }

  return state;
}

export default organogramaReducer;
