import { Map } from 'immutable';
import AutoComplete from 'portaltransparencia-common/components/AutoComplete.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import {
  onChange,
  resetCrud
} from 'portaltransparencia-common/crud/Actions.js';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import * as actions from './Actions.js';

export class CargosFiltro extends Component {
  static propTypes = {
    location: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    resetCrud: PropTypes.func.isRequired,
    errors: PropTypes.object,
    filtros: PropTypes.object,

    filtro: PropTypes.string,
    findCargosPaginado: PropTypes.func.isRequired,
    findCargosNaoPaginado: PropTypes.func.isRequired,
    findQuadrosSalarial: PropTypes.func.isRequired,
    findGruposOcupacional: PropTypes.func.isRequired,
    findNiveis: PropTypes.func.isRequired
  };

  onPesquisa = event => {
    const { filtro, findCargosPaginado } = this.props;
    if (filtro) {
      findCargosPaginado(filtro);
    }

    event && event.preventDefault();
    this.refs.panelFiltroCargos && this.refs.panelFiltroCargos.collapse();
  };

  onChangeFiltro = event => {
    const { onChange } = this.props;

    new Promise(resolve => {
      onChange('filtroCargos', event);
      resolve();
    }).then(() => {
      switch (event.name) {
        case 'cargo': {
          this.findCargos();
        }
        case 'quadroSalarial': {
          this.findQuadrosSalarial();
        }
        case 'grupoOcupacional': {
          this.findGruposOcupacional();
        }
        case 'nivel': {
          this.findNiveis();
        }
      }
    });
  };

  onResetFiltro = () => {
    const { resetCrud, findCargosPaginado } = this.props;
    resetCrud('filtroCargos');
    findCargosPaginado('');
  };

  findCargos = param => {
    const { findCargosNaoPaginado, filtro } = this.props;
    return findCargosNaoPaginado(filtro, param);
  };

  findQuadrosSalarial = param => {
    const { findQuadrosSalarial, filtro } = this.props;
    return findQuadrosSalarial(filtro, param);
  };

  findGruposOcupacional = param => {
    const { findGruposOcupacional, filtro } = this.props;
    return findGruposOcupacional(filtro, param);
  };

  findNiveis = param => {
    const { findNiveis, filtro } = this.props;
    return findNiveis(filtro, param);
  };

  renderValue = option => {
    return <span>{option.descricao}</span>;
  };

  render() {
    const { filtros, errors } = this.props;
    return (
      <form>
        <div className="panel-inside-panel mt-xs">
          <Panel
            title="Consultar Cargos"
            isForm
            tableResponsive
            ref="panelFiltroCargos"
          >
            <Row>
              <Col xs={12} sm={4} md={6} key={filtros.get('cargo')}>
                <AutoComplete
                  label="Cargo"
                  id="cargo"
                  name="cargo"
                  onLoad={this.findCargos}
                  onChange={this.onChangeFiltro}
                  value={filtros.get('cargo')}
                  errors={errors}
                  labelKey="descricao"
                  placeholder="Selecione o Cargo"
                  clearable
                  optionRenderer={this.renderValue}
                />
              </Col>
              <Col xs={12} sm={3} md={6} key={filtros.get('quadroSalarial')}>
                <AutoComplete
                  label="Quadro Salarial"
                  id="quadroSalarial"
                  name="quadroSalarial"
                  onLoad={this.findQuadrosSalarial}
                  onChange={this.onChangeFiltro}
                  value={filtros.get('quadroSalarial')}
                  errors={errors}
                  labelKey="descricao"
                  placeholder="Selecione o Quadro Salarial"
                  optionRenderer={this.renderValue}
                />
              </Col>
              <Col xs={12} sm={3} md={4} key={filtros.get('grupoOcupacional')}>
                <AutoComplete
                  label="Grupo Ocupacional"
                  id="grupoOcupacional"
                  name="grupoOcupacional"
                  onLoad={this.findGruposOcupacional}
                  onChange={this.onChangeFiltro}
                  value={filtros.get('grupoOcupacional')}
                  errors={errors}
                  labelKey="descricao"
                  placeholder="Selecione o Grupo Ocupacional"
                  optionRenderer={this.renderValue}
                />
              </Col>
              <Col xs={12} sm={3} md={4} key={filtros.get('nivel')}>
                <AutoComplete
                  label="Nível"
                  id="nivel"
                  name="nivel"
                  onLoad={this.findNiveis}
                  onChange={this.onChangeFiltro}
                  value={filtros.get('nivel')}
                  errors={errors}
                  labelKey="descricao"
                  placeholder="Selecione o Nível"
                  optionRenderer={this.renderValue}
                />
              </Col>
              <Col xs={12} sm={4} md={3}>
                <div className="form-group">
                  <label className="label"></label>
                  <div className="btn-group">
                    <button
                      id="btnFiltrar"
                      className="btn module-color icon-right inline"
                      ref="pesquisar"
                      onClick={this.onPesquisa}
                    >
                      Pesquisar
                      <em className="fa fa-search"></em>
                    </button>
                    <button
                      id="btnReset"
                      className="btn neutral icon-right inline no-text"
                      type="button"
                      ref="limpar"
                      onClick={this.onResetFiltro}
                    >
                      Limpar
                      <em className="fa fa-times"></em>
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </Panel>
        </div>
      </form>
    );
  }
}
function mapStateToProps(state) {
  return {
    filtros: state.crud.getIn(['filtroCargos', 'currentRecord'], Map({}))
  };
}
const allActions = {
  ...actions,
  onChange,
  resetCrud
};
export default connect(mapStateToProps, allActions)(CargosFiltro);
