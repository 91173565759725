import { Map } from 'immutable';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import Input from 'portaltransparencia-common/components/EloInput.react.js';
import {
  changeFieldValue,
  onChange,
  resetCrud
} from 'portaltransparencia-common/crud/Actions.js';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import FilterButtons from '../components/FilterButtons.react.js';
import PageHeader from '../components/PageHeader.react.js';
import * as actions from './Actions.js';
import TableActions from 'portaltransparencia-common/components/TableActions.react';
import {
  addFilterUrl,
  addPagination
} from 'portaltransparencia-common/libs/AddFilter.js';

export class FormCedidos extends Component {
  static propTypes = {
    servidores: PropTypes.object.isRequired,
    findServidores: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    filtros: PropTypes.object,
    onChange: PropTypes.func,
    resetCrud: PropTypes.func,
    item: PropTypes.object
  };

  componentDidMount() {
    this.consultarServidoresCedidos();
  }

  onChangeFiltro = event => {
    const { onChange } = this.props;

    onChange('filtroServidoresCedidos', event);
  };

  consultarServidoresCedidos = () => {
    const { findServidores, filtros } = this.props;

    findServidores(this.montarFiltro(filtros));
  };

  montarFiltro = () => {
    const { filtros } = this.props;

    let filter = '';
    if (filtros.get('matricula')) {
      filter = addFilterUrl(filter, `matricula=${filtros.get('matricula')}`);
    }

    if (filtros.get('nome')) {
      filter = addFilterUrl(filter, `nome=${filtros.get('nome')}`);
    }

    if (filtros.get('tipo')) {
      filter = addFilterUrl(filter, `tipo=${filtros.get('tipo')}`);
    }

    if (filtros.get('orgaoDestino')) {
      filter = addFilterUrl(
        filter,
        `orgaoDestino=${filtros.get('orgaoDestino')}`
      );
    }

    return addPagination(filtros, filter);
  };

  onResetFiltro = () => {
    const { resetCrud } = this.props;

    resetCrud('filtroServidoresCedidos');
  };

  renderServidoresCedidos = servidor => {
    return (
      <TableActions ref="row" data={servidor}>
        <td>{servidor.get('nome')}</td>
        <td className="right">{servidor.get('matricula')}</td>
        <td className="right">{servidor.get('cargo')}</td>
        <td className="right">{servidor.get('tipo')}</td>
        <td className="right">{servidor.get('orgaoDestino')}</td>
        <td className="right">{servidor.get('ato')}</td>
        <td className="right">{servidor.get('periodo')}</td>
        <td />
      </TableActions>
    );
  };

  render() {
    const { servidores, location, filtros } = this.props;

    return (
      <section>
        <PageHeader location={location} />
        <Panel
          title="Consultar servidores cedidos - Senior"
          isForm
          mobileClose
          ref="panelfiltroCedidos"
        >
          <Row>
            <Col xs={3} sm={3} md={3}>
              <Input
                type="number"
                name="matricula"
                placeholder="Matrícula"
                label="Matricula"
                onChange={this.onChangeFiltro}
                value={filtros.get('matricula')}
              />
            </Col>
            <Col xs={12} sm={3} md={3}>
              <Input
                type="text"
                name="nome"
                placeholder="Nome"
                label="Nome"
                onChange={this.onChangeFiltro}
                value={filtros.get('nome')}
              />
            </Col>

            <Col xs={12} sm={3} md={3}>
              <Input
                name="tipo"
                ref="tipo"
                label="Tipo"
                type="select"
                onChange={this.onChangeFiltro}
                value={filtros.get('tipo')}
              >
                <option value="">Selecione</option>
                <option value="Cessão Com ônus">Cessão Com ônus</option>
                <option value="Cessão Sem ônus">Cessão Sem ônus</option>
              </Input>
            </Col>

            <Col xs={12} sm={3} md={3}>
              <Input
                type="text"
                name="orgaoDestino"
                placeholder="Orgão Destino"
                label="Orgão Destino"
                onChange={this.onChangeFiltro}
                value={filtros.get('orgaoDestino')}
              />
            </Col>
            <Col xs={12} sm={4} md={3}>
              <FilterButtons
                onConsulta={this.consultarServidoresCedidos}
                onReset={this.onResetFiltro}
              />
            </Col>
          </Row>
        </Panel>
        <Panel
          title="Lista de servidores cedidos"
          isForm
          expansible={false}
          tableResponsive
        >
          <div className="panel-table-outer">
            <table className="panel-table striped facny">
              <TableHeader empty={servidores.get('servidores').isEmpty()}>
                <tr>
                  <th>Nome</th>
                  <th className="right">Matrícula</th>
                  <th className="right">Cargo</th>
                  <th className="right">Tipo</th>
                  <th className="right">Orgão Destino</th>
                  <th className="right">Ato</th>
                  <th className="right">Período</th>
                  <th />
                </tr>
              </TableHeader>
              <tbody>
                {servidores.get('servidores').map(this.renderServidoresCedidos)}
              </tbody>
            </table>
          </div>
        </Panel>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    servidores: state.servidoresCedidos.get('servidores'),
    filtros: state.crud.getIn(
      ['filtroServidoresCedidos', 'currentRecord'],
      Map({})
    )
  };
}

const allActions = {
  ...actions,
  onChange,
  changeFieldValue,
  resetCrud
};

export default connect(mapStateToProps, allActions)(FormCedidos);
