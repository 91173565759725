import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export default class FornecedorRowTotal extends Component {
  static propTypes = {
    totais: PropTypes.object,
    show: PropTypes.bool.isRequired
  };

  render = () => {
    const { totais, show } = this.props;
    if (!show) {
      return null;
    }

    return (
      <tfoot>
        <tr>
          <td></td>
          <td></td>
          <td className="right">
            <i>Total:</i> <NumberFormatter value={totais.get('empenhado')} />
          </td>
          <td className="right">
            <i>Total:</i> <NumberFormatter value={totais.get('anulado')} />
          </td>
          <td className="right">
            <i>Total:</i> <NumberFormatter value={totais.get('liquidado')} />
          </td>
          <td className="right">
            <i>Total:</i> <NumberFormatter value={totais.get('retido')} />
          </td>
          <td className="right">
            <i>Total:</i> <NumberFormatter value={totais.get('pago')} />
          </td>
          <td className="right">
            <i>Total:</i> <NumberFormatter value={totais.get('aPagar')} />
          </td>
          <td></td>
        </tr>
      </tfoot>
    );
  };
}
