let configuration = {};

export const configure = (options = {}) => {
  const { apiURL, modulo } = options;

  configuration.apiURL = apiURL;

  configuration.modulo = modulo;

  return configuration;
};

export default configuration;
