import DisplayData from 'portaltransparencia-common/components/displaydata/DisplayData.react.js';
import DisplayDataItem from 'portaltransparencia-common/components/displaydata/DisplayDataItem.react.js';
import DisplayDataRow from 'portaltransparencia-common/components/displaydata/DisplayDataRow.react.js';
import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import NumberUtils from 'portaltransparencia-common/libs/NumberUtils.js';
import { getAllValuesFromUrlSearchParams } from 'portaltransparencia-common/libs/URLUtils';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import PageHeader from '../components/PageHeader.react.js';
import * as actions from './Actions.js';

export class FormEmpenhoItemDetalhe extends Component {
  static propTypes = {
    params: PropTypes.object,
    location: PropTypes.object.isRequired,
    findEmpenhoItemDetalhe: PropTypes.func.isRequired,
    itemDetalhe: PropTypes.object.isRequired
  };

  componentDidMount() {
    const { location, findEmpenhoItemDetalhe } = this.props;

    const params = getAllValuesFromUrlSearchParams(location);

    findEmpenhoItemDetalhe(
      `entidade=${params.entidade}&exercicio=${params.exercicio}&empenho=${params.empenho}&ordemItemEmpenho=${params.ordemItemEmpenho}`
    );
  }

  renderItens = data => {
    return (
      <tr key={data.get('ordemNadItem')} data={data}>
        <td id="ordemNadItem">{data.get('ordemNadItem')}</td>
        <td id="descricao">{data.get('descricao')}</td>
        <td id="quantidade" className="right">
          <NumberFormatter value={data.get('quantidade')} />
        </td>
        <td id="unidade">{data.get('unidade')}</td>
        <td id="valorUnitario" className="right">
          <NumberFormatter value={data.get('valorUnitario')} />
        </td>
        <td id="valorTotal" className="right">
          <NumberFormatter value={data.get('valorTotal')} />
        </td>
      </tr>
    );
  };

  render() {
    const { itemDetalhe } = this.props;

    if (!itemDetalhe || itemDetalhe.isEmpty()) {
      return null;
    }

    return (
      <section>
        <PageHeader textoDetalhe="Item" location={this.props.location} />
        <Panel title="Detalhes do Item" expansible={false}>
          <DisplayData border>
            <DisplayDataRow>
              <DisplayDataItem
                sm={6}
                title="Empenho"
                value={`${itemDetalhe.get('empenho')}/${itemDetalhe.get(
                  'exercicio'
                )}`}
                id="empenho"
              />
              <DisplayDataItem
                sm={6}
                title="Sequência do Item"
                value={itemDetalhe.get('sequencia')}
                id="sequencia"
              />
            </DisplayDataRow>
            <DisplayDataRow>
              <DisplayDataItem
                sm={12}
                title="Especificação"
                value={itemDetalhe.get('especificacao')}
                id="especificacao"
              />
            </DisplayDataRow>
            <DisplayDataRow>
              <DisplayDataItem
                sm={3}
                title="Quantidade"
                value={`${itemDetalhe.get('quantidade')}  ${itemDetalhe.get(
                  'unidade'
                )}`}
                id="quantidade"
              />
              <DisplayDataItem
                sm={5}
                title="Valor Unitário"
                value={NumberUtils.format(itemDetalhe.get('valorUnitario'))}
                id="valorUnitario"
              />
              <DisplayDataItem
                sm={5}
                title="Valor Total"
                value={NumberUtils.format(itemDetalhe.get('valorTotal'))}
                id="valorTotal"
              />
            </DisplayDataRow>
          </DisplayData>
        </Panel>
        <Panel isTable tableResponsive expansible={false}>
          <div className="panel-table-outer">
            <table className="panel-table striped fancy">
              <TableHeader empty={itemDetalhe.get('itensPerc').isEmpty()}>
                <tr>
                  <th>Ordem</th>
                  <th>Especificação</th>
                  <th className="right">Quantidade</th>
                  <th>Unidade</th>
                  <th className="right">Valor Unitário</th>
                  <th className="right">Valor Total</th>
                </tr>
              </TableHeader>
              <tbody>
                {itemDetalhe.get('itensPerc').map(this.renderItens)}
              </tbody>
            </table>
          </div>
        </Panel>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    itemDetalhe: state.empenhos.get('itemDetalhe')
  };
}

const allActions = {
  ...actions
};

export default connect(mapStateToProps, allActions)(FormEmpenhoItemDetalhe);
