import DisplayData from 'portaltransparencia-common/components/displaydata/DisplayData.react.js';
import DisplayDataItem from 'portaltransparencia-common/components/displaydata/DisplayDataItem.react.js';
import DisplayDataRow from 'portaltransparencia-common/components/displaydata/DisplayDataRow.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export class AdjudicacaoDetalheInformacao extends Component {
  static propTypes = {
    adjudicacao: PropTypes.object.isRequired
  };

  render() {
    const { adjudicacao } = this.props;

    return (
      <Panel title={'Adjudicação'}>
        <DisplayData border>
          <DisplayDataRow>
            <DisplayDataItem
              sm={4}
              title="Fornecedor"
              value={adjudicacao.get('nome')}
              id="nome"
            />
            <DisplayDataItem
              sm={4}
              title="CNPJ/CPF"
              value={adjudicacao.get('cnpjCpf')}
              id="cnpjCpf"
            />
            <DisplayDataItem
              sm={4}
              title="Data"
              value={DateUtils.transform(adjudicacao.get('data'))}
              id="data"
            />
          </DisplayDataRow>
        </DisplayData>
      </Panel>
    );
  }
}

export default AdjudicacaoDetalheInformacao;
