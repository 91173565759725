import Component from 'react-pure-render/component';
import PropTypes from 'prop-types';
import React from 'react';
import Input from './EloInput.react.js';

export default class MesSelect extends Component {
  static propTypes = {
    nomeCampo: PropTypes.string,
    labelCampo: PropTypes.string,
    value: PropTypes.string,
    onChangeFiltro: PropTypes.func
  };

  render() {
    const { nomeCampo, labelCampo, onChangeFiltro, value } = this.props;

    return (
      <Input
        id={nomeCampo}
        name={nomeCampo}
        ref={nomeCampo}
        label={labelCampo}
        type="select"
        onChange={onChangeFiltro}
        value={value}
      >
        <option value="">Selecione</option>
        <option value="1">Janeiro</option>
        <option value="2">Fevereiro</option>
        <option value="3">Março</option>
        <option value="4">Abril</option>
        <option value="5">Maio</option>
        <option value="6">Junho</option>
        <option value="7">Julho</option>
        <option value="8">Agosto</option>
        <option value="9">Setembro</option>
        <option value="10">Outubro</option>
        <option value="11">Novembro</option>
        <option value="12">Dezembro</option>
      </Input>
    );
  }
}
