import SumListField from 'portaltransparencia-common/components/SumListField.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import NumberUtils from 'portaltransparencia-common/libs/NumberUtils.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export class ConvenioContrapartida extends Component {
  static propTypes = {
    contrapartidarepasses: PropTypes.object.isRequired,
    contrapartidadevolucao: PropTypes.object.isRequired,
    contrapartidapagamentos: PropTypes.object.isRequired
  };

  renderContrapartidaRepasses = repasse => {
    return (
      <tr key={repasse}>
        <td>{DateUtils.formatDateTimeShort(repasse.get('data'))}</td>
        <td style={{ textAlign: 'right' }}>
          {NumberUtils.format(repasse.get('valor'))}
        </td>
      </tr>
    );
  };

  renderContrapartidaDevolucao = devolucao => {
    return (
      <tr key={devolucao}>
        <td>{DateUtils.formatDateTimeShort(devolucao.get('data'))}</td>
        <td style={{ textAlign: 'right' }}>
          {NumberUtils.format(devolucao.get('valor'))}
        </td>
      </tr>
    );
  };

  renderContrapartidaPagamento = pagamento => {
    return (
      <tr key={pagamento}>
        <td>{DateUtils.formatDateTimeShort(pagamento.get('data'))}</td>
        <td style={{ textAlign: 'right' }}>
          {NumberUtils.format(pagamento.get('valor'))}
        </td>
      </tr>
    );
  };

  render() {
    const {
      contrapartidarepasses,
      contrapartidadevolucao,
      contrapartidapagamentos
    } = this.props;
    return (
      <div className="panel-body">
        <table className="panel-table fancy">
          <div className="col-md-4">
            <h2 className="panel-title">Repasses</h2>
            <TableHeader
              empty={contrapartidarepasses.isEmpty()}
              text="Não possui Repasses."
            >
              <tr>
                <th>Data</th>
                <th style={{ textAlign: 'center' }}>Valor</th>
              </tr>
            </TableHeader>
            <tbody>
              {contrapartidarepasses.map(this.renderContrapartidaRepasses)}
            </tbody>
            <tfoot>
              <tr>
                <td></td>
                <td className="right">
                  <i>Total:</i>
                  {
                    <SumListField
                      fieldName="valor"
                      list={contrapartidarepasses}
                    />
                  }
                </td>
              </tr>
            </tfoot>
          </div>
          <div className="col-md-4">
            <h2 className="panel-title">Devoluções</h2>
            <TableHeader
              empty={contrapartidadevolucao.isEmpty()}
              text="Não possui Devoluções."
            >
              <tr>
                <th>Data</th>
                <th style={{ textAlign: 'center' }}>Valor</th>
              </tr>
            </TableHeader>
            <tbody>
              {contrapartidadevolucao.map(this.renderContrapartidaDevolucao)}
            </tbody>
            <tfoot>
              <tr>
                <td></td>
                <td className="right">
                  <i>Total:</i>
                  {
                    <SumListField
                      fieldName="valor"
                      list={contrapartidadevolucao}
                    />
                  }
                </td>
              </tr>
            </tfoot>
          </div>
          <div className="col-md-4">
            <h2 className="panel-title">Pagamentos</h2>
            <TableHeader
              empty={contrapartidapagamentos.isEmpty()}
              text="Não possui Pagamentos."
            >
              <tr>
                <th>Data</th>
                <th style={{ textAlign: 'center' }}>Valor</th>
              </tr>
            </TableHeader>
            <tbody>
              {contrapartidapagamentos.map(this.renderContrapartidaPagamento)}
            </tbody>
            <tfoot>
              <tr>
                <td></td>
                <td className="right">
                  <i>Total:</i>
                  {
                    <SumListField
                      fieldName="valor"
                      list={contrapartidapagamentos}
                    />
                  }
                </td>
              </tr>
            </tfoot>
          </div>
        </table>
      </div>
    );
  }
}
export default ConvenioContrapartida;
