import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';

import * as types from './Types.js';

const initialState = fromJS({
  receitas: []
});

function receitaDetalhesReducer(state = initialState, action) {
  switch (action.type) {
    case types.RECEITAS_START: {
      return startLoad(state);
    }

    case types.RECEITAS_ERROR: {
      return endLoad(state);
    }

    case types.RECEITAS_SUCCESS: {
      const receitas = fromJS(
        action.payload.map(item => {
          return {
            ...item,
            open: false,
            visible: item.nivel === 1
          };
        })
      );
      return endLoad(state.set('receitas', receitas));
    }
    case types.EXPAND_RECEITA: {
      const receitas = state.get('receitas').map(item => {
        if (item.get('receita') === action.payload.receita) {
          return item.set('open', true).set('visible', true);
        } else if (
          item.get('receita') &&
          item.get('receita').indexOf(action.payload.receita) === 0 &&
          item.get('nivel') === nextLevel(state.get('receitas'), action.payload)
        ) {
          return item.set('visible', true);
        }
        return item;
      });

      return state.set('receitas', receitas);
    }
    case types.COLLAPSE_RECEITA: {
      const receitas = state.get('receitas').map(item => {
        if (item.get('receita') === action.payload.receita) {
          return item.set('open', false).set('visible', true);
        } else if (
          item.get('receita') &&
          item.get('receita').indexOf(action.payload.receita) === 0 &&
          item.get('nivel') > action.payload.nivel
        ) {
          return item.set('open', false).set('visible', false);
        }
        return item;
      });

      return state.set('receitas', receitas);
    }
  }

  return state;
}

function nextLevel(list, item) {
  const index = list.findIndex(i => i.get('receita') === item.receita);

  const proximoNivel = list.getIn([index + 1, 'nivel']);

  return proximoNivel;
}

export default receitaDetalhesReducer;
