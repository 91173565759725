function base64ToArrayBuffer(base64) {
  const binaryString = window.atob(base64);
  const binaryLength = binaryString.length;
  const bytes = new Uint8Array(binaryLength);

  for (let i = 0; i < binaryLength; i++) {
    bytes[i] = binaryString.charCodeAt(i); //ascii;
  }

  return bytes;
}
export function downloadByUrl(url, fileName) {
  const link = document.createElement('a');
  link.href = url;
  if (fileName) {
    link.setAttribute('download', fileName);
  }
  link.target = '_blank';
  link.click();
}

export function downloadArquivoBlob(arquivo, pathFileName = '') {
  const buffer = base64ToArrayBuffer(arquivo);
  let fileName = getFileName(pathFileName);
  let type = fileName ? 'application/octet-stream' : 'application/pdf';
  const blob = new Blob([buffer], { type });

  downloadByUrl(window.URL.createObjectURL(blob), fileName);
}

const getFileName = pathFileName =>
  pathFileName.split('\\')[pathFileName.split('\\').length - 1];

export const getExtension = fileName => {
  const regExtension = /(?:\.([^.]+))?$/;
  return regExtension.exec(`.${fileName}`)[1].toLowerCase();
};
