import React from 'react';
import RenunciaReceitasForm from './RenunciaReceitasForm.react';
import * as actions from './Actions';
import { connect } from 'react-redux';
import NotasExplicativas from '../notaexplicativa/Form.react';
import PropTypes from 'prop-types';
import Component from 'react-pure-render/component';
import { addPagination } from 'portaltransparencia-common/libs/AddFilter';
import RenunciaReceitasList from './RenunciaReceitasList.react';
import PageHeader from '../components/PageHeader.react.js';
import configuration from 'portaltransparencia-common/config/Config';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import RenunciaReceitasResumoList from './RenunciaReceitasResumoList.react';

export class RenunciaReceitasPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {
        cpfCnpj: undefined,
        contribuinte: undefined,
        dataInicial: undefined,
        dataFinal: undefined,
        tipoMovimentacaoTributo: undefined,
        classificacaoTributo: undefined,
        situacaoLegal: undefined
      }
    };
  }

  static propTypes = {
    findRenunciaReceitas: PropTypes.func.isRequired,
    findClassificacoes: PropTypes.func.isRequired,
    findResumo: PropTypes.func.isRequired,
    findTotalResumo: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    page: PropTypes.object.isRequired,
    classificacoes: PropTypes.any.isRequired,
    resumo: PropTypes.object.isRequired,
    totalizador: PropTypes.object.isRequired
  };

  componentDidMount() {
    const { findClassificacoes } = this.props;

    findClassificacoes();
  }

  createParams = (filter, page) => {
    const params = new URLSearchParams({
      tipoMovimentacaoTributo: filter.tipoMovimentacaoTributo,
      dataInicial: filter.dataInicial,
      dataFinal: filter.dataFinal
    });

    filter.cpfCnpj &&
      params.append('cpfCnpj', filter.cpfCnpj.replace(/\D/g, ''));
    filter.contribuinte && params.append('contribuinte', filter.contribuinte);
    filter.classificacaoTributo &&
      params.append('classificacaoTributo', filter.classificacaoTributo);
    filter.situacaoLegal &&
      params.append('situacaoLegal', filter.situacaoLegal);
    return page ? addPagination(page, params.toString()) : params.toString();
  };

  findResumo = async params => {
    const { findResumo } = this.props;

    await findResumo(params);
  };

  findTotalResumo = async params => {
    const { findTotalResumo } = this.props;

    await findTotalResumo(params);
  };

  findRenunciaReceitas = async params => {
    const { findRenunciaReceitas } = this.props;

    await findRenunciaReceitas(params);
  };

  searchForm = async (filter, page) => {
    const params = this.createParams(filter);
    const paramsWithPage = this.createParams(filter, page);
    Promise.all([
      this.findResumo(paramsWithPage),
      this.findTotalResumo(params),
      this.findRenunciaReceitas(paramsWithPage)
    ]).finally(() => {
      this.setState({
        filter: filter
      });
    });
  };

  searchRenuncias = async (filter, page) => {
    this.findRenunciaReceitas(this.createParams(filter, page)).finally(() => {
      this.setState({
        filter: filter
      });
    });
  };

  searchResumo = async (filter, page) => {
    this.findResumo(this.createParams(filter, page)).finally(() => {
      this.setState({
        filter: filter
      });
    });
  };

  onExport = (exportType, openTab, windowParams, page) => {
    const { apiURL } = configuration;
    const { entidade } = StorageService.getItem('userInfo');
    const { filter } = this.state;

    const params = `${this.createParams(filter, page)}&entidade=${
      entidade.id
    }&exportType=${exportType}`;

    window.open(
      `${apiURL}/api/movimentacao-tributo/report?${params}`,
      openTab,
      windowParams
    );
  };

  render() {
    const { location, page, classificacoes, resumo, totalizador } = this.props;
    const { filter } = this.state;
    const { searchForm, searchResumo, searchRenuncias, onExport } = this;

    return (
      <>
        <PageHeader location={location} />
        <NotasExplicativas pathname={location?.pathname} />
        <RenunciaReceitasForm
          search={searchForm}
          classificacoes={classificacoes}
        />
        <RenunciaReceitasResumoList
          resumo={resumo}
          search={searchResumo}
          totalizador={totalizador}
          filter={filter}
        />
        <RenunciaReceitasList
          page={page}
          search={searchRenuncias}
          filter={filter}
          onExport={onExport}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  page: state.renunciaReceitas.get('page'),
  classificacoes: state.renunciaReceitas.get('classificacoes'),
  resumo: state.renunciaReceitas.get('resumo'),
  totalizador: state.renunciaReceitas.get('totalizador')
});

const allActions = {
  ...actions
};

export default connect(mapStateToProps, allActions)(RenunciaReceitasPage);
