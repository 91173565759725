import * as types from './Types.js';

export function findOrcamentos(filter) {
  return ({ fetch }) => ({
    type: types.ORCAMENTO_LIST,
    payload: {
      promise: fetch(`/api/orcamentos/list?${filter}`)
    }
  });
}

export function findObjetivoEstrategico(filter) {
  return ({ fetch }) => ({
    type: types.OBJETIVO_ESTRATEGICO,
    payload: {
      promise: fetch(`/api/objetivoestrategico?${filter}`)
    }
  });
}
