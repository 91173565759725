import DisplayData from 'portaltransparencia-common/components/displaydata/DisplayData.react.js';
import DisplayDataItem from 'portaltransparencia-common/components/displaydata/DisplayDataItem.react.js';
import DisplayDataRow from 'portaltransparencia-common/components/displaydata/DisplayDataRow.react.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export default class EmpenhoDetalheFornecedor extends Component {
  static propTypes = {
    empenho: PropTypes.object.isRequired
  };

  render() {
    const { empenho } = this.props;
    const mostrarEndereco = empenho.get('tipoPessoa') === 'J';
    const endereco = (
      <div>
        {empenho.get('endereco')} - {empenho.get('bairro')} CEP:{' '}
        {empenho.get('cep')} - {empenho.get('cidade')} - {empenho.get('uf')}
      </div>
    );
    return (
      <DisplayData border>
        <DisplayDataRow>
          <DisplayDataItem sm={7} title="Nome" value={empenho.get('nome')} />
          <DisplayDataItem
            sm={5}
            title="CNPJ/CPF"
            value={empenho.get('cnpj')}
          />
        </DisplayDataRow>
        <DisplayDataRow>
          <DisplayDataItem
            sm={12}
            title="Endereço"
            value={endereco}
            show={mostrarEndereco}
          />
        </DisplayDataRow>
      </DisplayData>
    );
  }
}
