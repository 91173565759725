import { Map } from 'immutable';
import {
  search,
  loadRecord,
  endLoad,
  editRecord,
  newRecord,
  saveRecord,
  deleteRecord,
  endSubmit,
  cancelRecord,
  startLoad,
  startSubmit,
  changeFieldValue,
  addItem,
  removeItem,
  addFilter,
  removeFilter,
  loadModel,
  reorder
} from './Core.js';
import * as types from './Types.js';
import { normalizePath } from './Utils.js';

const success = types.success;
const start = types.start;
const error = types.error;

const initialState = Map({});
const initialCrudState = Map({});

function dynamicPath(reducer) {
  return function (state = initialState, action) {
    if (action.meta && action.meta.path) {
      const normalizedPath = normalizePath(action.meta.path);

      return state.updateIn(normalizedPath, initialCrudState, value =>
        reducer(value, action)
      );
    }

    return reducer(state, action);
  };
}

function crudReducer(state = initialState, action) {
  switch (action.type) {
    //endLoad
    case success(types.SEARCH):
    case success(types.APPLY_FILTER): {
      return endLoad(search(state, action.payload));
    }
    case success(types.RETRIEVE): {
      return endLoad(loadRecord(state, action.payload));
    }
    case success(types.NEW_RECORD): {
      return endLoad(newRecord(state, action.payload));
    }

    case error(types.SAVE_RECORD):
    case error(types.UPDATE_RECORD):
    case error(types.DELETE_RECORD): {
      return endSubmit(state);
    }

    //endSubmit
    case success(types.SAVE_RECORD):
    case success(types.UPDATE_RECORD): {
      return endSubmit(saveRecord(state, action.payload));
    }
    case success(types.DELETE_RECORD): {
      return endSubmit(deleteRecord(state, action.meta.value));
    }

    case success(types.LOAD_MODEL): {
      return loadModel(state, action.payload);
    }

    case types.EDIT_RECORD: {
      return editRecord(state, action.payload);
    }
    case types.CANCEL_RECORD: {
      return cancelRecord(state);
    }

    case start(types.LOAD_MODEL):
    case start(types.SEARCH):
    case start(types.RETRIEVE):
    case start(types.NEW_RECORD): {
      return startLoad(state);
    }

    case start(types.SAVE_RECORD):
    case start(types.DELETE_RECORD): {
      return startSubmit(state);
    }

    case types.CHANGE_FIELD_VALUE: {
      return changeFieldValue(state, action.payload);
    }

    case types.ADD_ITEM: {
      return addItem(state, action.payload);
    }

    case types.REORDER_RECORD: {
      return reorder(state, action.payload);
    }

    case types.REMOVE_ITEM: {
      return removeItem(state, action.payload);
    }

    case types.RESET_CRUD: {
      return state.clear().merge(initialCrudState);
    }

    case types.ADD_FILTER: {
      return addFilter(state, action.payload);
    }

    case types.REMOVE_FILTER: {
      return removeFilter(state, action.payload);
    }
  }

  return state;
}

export default dynamicPath(crudReducer);
