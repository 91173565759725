import Component from 'react-pure-render/component';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import Moment from 'moment';
import NumberUtils from 'portaltransparencia-common/libs/NumberUtils';
import PropTypes from 'prop-types';
import React from 'react';
import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react.js';

export class Arquivo extends Component {
  static propTypes = {
    arquivos: PropTypes.object,
    sortByDate: PropTypes.any,
    onDownload: PropTypes.func
  };

  downloadArquivo = arquivo => {
    const { onDownload } = this.props;

    return () => onDownload(arquivo);
  };

  renderArquivos = arquivo => {
    const nomeArquivo = arquivo.get('nomeArquivo') || 'Arquivo sem título';
    const tamanhoArquivo = NumberUtils.formatBytes(
      arquivo.get('tamanhoArquivo')
    );
    const dataArquivo = DateUtils.formatDateTimeShort(
      arquivo.get('dataPublicacao') || arquivo.get('data')
    );
    const key = arquivo.get('id') + '_' + arquivo.get('idArquivo');
    const id = arquivo.get('id') ? arquivo.get('id') : arquivo.get('idArquivo');

    return (
      <TableActions
        key={key}
        data={id}
        onDownload={this.downloadArquivo(arquivo)}
      >
        <td id="nomeArquivo">{`${nomeArquivo} ${tamanhoArquivo}`}</td>
        {this.hasDataPublicacao() && <td id="dataArquivo">{dataArquivo}</td>}
      </TableActions>
    );
  };

  hasDataPublicacao = () => {
    const { arquivos } = this.props;
    return !arquivos
      .filter(a => a.get('dataPublicacao') || a.get('data'))
      .isEmpty();
  };

  render() {
    const { arquivos, sortByDate } = this.props;

    if (!arquivos) {
      return <div className="info">Não há arquivos.</div>;
    }

    let arquivosTratados = arquivos;
    if (sortByDate) {
      arquivosTratados = arquivosTratados.sort((a, b) => {
        let dataA = a.get('dataPublicacao') || a.get('data');
        let dataB = b.get('dataPublicacao') || b.get('data');
        if (!dataA || !dataB) {
          return !dataA && !dataB ? 0 : dataA ? 1 : -1;
        }
        dataA = Moment(dataA);
        dataB = Moment(dataB);
        return dataA.isSame(dataB) ? 0 : dataA.isBefore(dataB) ? -1 : 1;
      });
      if (
        typeof sortByDate === 'string' &&
        sortByDate.toUpperCase() === 'DESC'
      ) {
        arquivosTratados = arquivosTratados.reverse();
      }
    }

    return (
      <div className="panel-table-outer">
        <table className="panel-table striped fancy">
          <TableHeader empty={arquivos.isEmpty()} text="Não há arquivos.">
            <tr>
              <th>Nome do Arquivo / Descrição</th>
              {this.hasDataPublicacao() && <th>Data de Publicação</th>}
              <th />
            </tr>
          </TableHeader>
          <tbody>{arquivosTratados.map(this.renderArquivos)}</tbody>
        </table>
      </div>
    );
  }
}

export default Arquivo;
