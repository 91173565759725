import { isNumber } from 'portaltransparencia-common/libs/NumberUtils';

import * as types from './Types.js';

export function searchVeiculos(filter) {
  let url = '/api/veiculos?' + filter;

  return ({ fetch }) => ({
    type: types.CONSULTA_VEICULOS,
    payload: {
      promise: fetch(url)
    }
  });
}

export function loadVeiculo(veiculo, chapa, entidade) {
  let url = `/api/veiculos/veiculo?${handlerFieldVeiculo(
    veiculo
  )}=${veiculo}&chapa=${chapa}${handlerFieldEntidade(entidade)}`;

  return ({ fetch }) => ({
    type: types.LOAD_VEICULO,
    payload: {
      promise: fetch(url)
    }
  });
}

export function loadTiposAlienacao() {
  let url = '/api/veiculos/tipo-alienacao';

  return ({ fetch }) => ({
    type: types.TIPOSALIENACAO,
    payload: {
      promise: fetch(url)
    }
  });
}

export function resetConsulta() {
  return {
    type: types.RESET_CONSULTA_VEICULOS,
    payload: {}
  };
}

export function loadVeiculoDetails(veiculo, entidade) {
  return ({ fetch }) => ({
    type: types.VEICULOS_GASTOS,
    payload: {
      promise: fetch(
        `/api/veiculos/gastos?${handlerFieldVeiculo(
          veiculo
        )}=${veiculo}${handlerFieldEntidade(entidade)}`
      )
    }
  });
}

export function loadGastosManutencao(veiculo, mes, entidade, chapa) {
  return ({ fetch }) => ({
    type: types.GASTOS_MANUTENCAO,
    payload: {
      promise: fetch(
        `/api/veiculos/manutencao?${getSearchGastos(
          veiculo,
          mes,
          entidade,
          chapa
        )}`
      )
    }
  });
}

export function loadGastosAbastecimento(veiculo, mes, entidade, chapa) {
  return ({ fetch }) => ({
    type: types.GASTOS_ABASTECIMENTO,
    payload: {
      promise: fetch(
        `/api/veiculos/abastecimento?${getSearchGastos(
          veiculo,
          mes,
          entidade,
          chapa
        )}`
      )
    }
  });
}

export function loadGastosPneu(veiculo, mes, entidade, chapa) {
  return ({ fetch }) => ({
    type: types.GASTOS_PNEU,
    payload: {
      promise: fetch(
        `/api/veiculos/pneu?${getSearchGastos(veiculo, mes, entidade, chapa)}`
      )
    }
  });
}

export function loadGastosBateria(veiculo, mes, entidade, chapa) {
  return ({ fetch }) => ({
    type: types.GASTOS_BATERIA,
    payload: {
      promise: fetch(
        `/api/veiculos/bateria?${getSearchGastos(
          veiculo,
          mes,
          entidade,
          chapa
        )}`
      )
    }
  });
}

export function loadGastosPeca(veiculo, mes, entidade, chapa) {
  return ({ fetch }) => ({
    type: types.GASTOS_PECA,
    payload: {
      promise: fetch(
        `/api/veiculos/peca?${getSearchGastos(veiculo, mes, entidade, chapa)}`
      )
    }
  });
}

const handlerFieldEntidade = entidade => {
  return entidade ? `&entidade=${entidade}` : '';
};

const handlerFieldVeiculo = veiculo => {
  /*
   * Quando o portal está integrado com o oxy patrimonio
   * o tipo do ID do veiculo passa a ser UUID, atributo veiculoOxy no backend.
   */
  return isNumber(veiculo) ? 'veiculo' : 'veiculoOxy';
};

const handlerFieldChapa = chapa => {
  return chapa ? `&chapa=${chapa}` : '';
};

const getSearchGastos = (veiculo, mes, entidade, chapa) => {
  return `${handlerFieldVeiculo(
    veiculo
  )}=${veiculo}&mes=${mes}${handlerFieldEntidade(entidade)}${handlerFieldChapa(
    chapa
  )}`;
};
