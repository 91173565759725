import { Map } from 'immutable';
import AutoComplete from 'portaltransparencia-common/components/AutoComplete.react.js';
import DatePicker from 'portaltransparencia-common/components/DatePicker.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import SearchPagination from 'portaltransparencia-common/components/SearchPagination.react.js';
import Validation from 'portaltransparencia-common/components/Validation.js';
import {
  onChange,
  resetCrud
} from 'portaltransparencia-common/crud/Actions.js';
import {
  addFilterUrl,
  addPagination
} from 'portaltransparencia-common/libs/AddFilter.js';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import FilterButtons from '../components/FilterButtons.react';
import PageHeader from '../components/PageHeader.react.js';
import NotasExplicativas from '../notaexplicativa/Form.react';
import * as actions from './Actions.js';
import PesquisaItensConsumidos from './PesquisaItensConsumidos.react.js';

export class FormConsumo extends Component {
  static propTypes = {
    loadCentrosCustoDestino: PropTypes.func.isRequired,
    loadCentrosCusto: PropTypes.func.isRequired,
    loadTotalConsumido: PropTypes.func.isRequired,
    pesquisaItensConsumo: PropTypes.func.isRequired,
    itensConsumo: PropTypes.object,
    valorTotal: PropTypes.number,
    resetItens: PropTypes.func.isRequired,
    resetCrud: PropTypes.func.isRequired,
    page: PropTypes.object.isRequired,
    errors: PropTypes.object,
    validate: PropTypes.func,
    handleError: PropTypes.func,
    filtros: PropTypes.object,
    onChange: PropTypes.func,
    location: PropTypes.object.isRequired
  };

  componentDidMount() {
    const { loadCentrosCusto } = this.props;

    loadCentrosCusto();
  }

  onChangeFiltro = event => {
    const { onChange } = this.props;

    onChange('filtroItensConsumo', event);
  };

  onPesquisaItens = event => {
    event && event.preventDefault();

    const { validate, handleError } = this.props;

    validate(error => {
      if (handleError(this, error)) {
        return;
      }

      const { page } = this.props;
      const initialPage = page.set('number', 0);

      this.searchWithPage(initialPage);
      this.refs.panelFiltroAlmoxarifado &&
        this.refs.panelFiltroAlmoxarifado.collapse();
    });
  };

  onResetFiltro = () => {
    const { resetItens, resetCrud } = this.props;
    resetCrud('filtroItensConsumo');
    resetItens();
  };

  searchWithPage = page => {
    const { pesquisaItensConsumo, loadTotalConsumido } = this.props;

    const filter = this.montaFilter(page);

    loadTotalConsumido(filter).then(() => pesquisaItensConsumo(filter));
  };

  montaFilter = function (page) {
    let userInfo = StorageService.getItem('userInfo');
    const { entidade, exercicio } = userInfo;

    let filter = '?entidade=' + entidade.id + '&exercicio=' + exercicio.id;
    const { filtros } = this.props;

    if (
      filtros.get('dataInicio') !== undefined &&
      filtros.get('dataInicio').length === 10
    ) {
      filter = addFilterUrl(filter, `dataInicio=${filtros.get('dataInicio')}`);
    }

    if (
      filtros.get('dataFinal') !== undefined &&
      filtros.get('dataFinal').length === 10
    ) {
      filter = addFilterUrl(filter, `dataFinal=${filtros.get('dataFinal')}`);
    }

    filter = addFilterUrl(
      filter,
      'centroCustoOrigem=' +
        (filtros.getIn(['centroCustoOrigem', 'centroCusto'])
          ? filtros.getIn(['centroCustoOrigem', 'centroCusto'])
          : '')
    );

    if (filtros && filtros.get('centroCustoDestino')) {
      filter = addFilterUrl(
        filter,
        'centroCustoDestino=' +
          filtros.getIn(['centroCustoDestino', 'centroCusto'])
      );
    }

    return addPagination(page, filter);
  };

  getValidatorData = () => {
    const { filtros } = this.props;
    return {
      centroCustoDestino: filtros.get('centroCustoDestino'),
      dataInicio: filtros.get('dataInicio'),
      dataFinal: filtros.get('dataFinal')
    };
  };

  renderValue = option => {
    return <span>{`${option.id.centroCusto} - ${option.descricao}`}</span>;
  };

  render() {
    const {
      loadCentrosCusto,
      page,
      errors,
      filtros,
      loadCentrosCustoDestino,
      valorTotal
    } = this.props;

    return (
      <section>
        <PageHeader location={this.props.location} />

        <NotasExplicativas pathname={this.props.location.pathname} />

        <Panel
          title="Consumo por Centro Custo"
          isForm
          ref="panelFiltroAlmoxarifado"
        >
          <form>
            <Row>
              <Col sm={3}>
                <DatePicker
                  id="dataInicio"
                  name="dataInicio"
                  ref="dataInicio"
                  label="Data início"
                  placeholder="Data de início"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('dataInicio')}
                  errors={errors}
                />
              </Col>
              <Col sm={3}>
                <DatePicker
                  id="dataFinal"
                  name="dataFinal"
                  ref="dataFinal"
                  label="Data final"
                  placeholder="Data final"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('dataFinal')}
                  errors={errors}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <AutoComplete
                  label="Centro de Custo origem"
                  id="centroCustoOrigem"
                  name="centroCustoOrigem"
                  onLoad={loadCentrosCusto}
                  onChange={this.onChangeFiltro}
                  value={filtros.get('centroCustoOrigem')}
                  errors={errors}
                  placeholder="Selecione o Centro de Custo origem"
                  clearable
                  valueKey="centroCusto"
                />
              </Col>
              <Col sm={6}>
                <AutoComplete
                  label="Centro de Custo destino"
                  id="centroCustoDestino"
                  name="centroCustoDestino"
                  onLoad={loadCentrosCustoDestino}
                  onChange={this.onChangeFiltro}
                  value={filtros.get('centroCustoDestino')}
                  errors={errors}
                  placeholder="Selecione o Centro de Custo destino"
                  clearable
                  valueKey="centroCusto"
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <FilterButtons
                  onConsulta={this.onPesquisaItens}
                  onReset={this.onResetFiltro}
                />
              </Col>
            </Row>
          </form>
        </Panel>

        <PesquisaItensConsumidos
          itensConsumo={page}
          searchWithPage={this.searchWithPage}
          valorTotal={valorTotal}
        />

        <SearchPagination page={page} searchWithPage={this.searchWithPage} />
      </section>
    );
  }
}

const validatorTypes = {
  rules: {
    dataInicio: 'required',
    dataFinal: 'required',
    centroCustoDestino: 'required'
  },
  labels: {
    dataInicio: 'Data Início',
    dataFinal: 'Data Final',
    centroCustoDestino: 'Centro de Custo de Consumo'
  }
};

function mapStateToProps(state) {
  return {
    valorTotal: state.almoxarifado.get('valorTotal'),
    page: state.almoxarifado.get('itensConsumo'),
    filtros: state.crud.getIn(['filtroItensConsumo', 'currentRecord'], Map({}))
  };
}

const allActions = {
  ...actions,
  onChange,
  resetCrud
};

FormConsumo = Validation(validatorTypes)(FormConsumo);

export default connect(mapStateToProps, allActions)(FormConsumo);
