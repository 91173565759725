import { addHttp } from 'portaltransparencia-common/libs/LinkExterno.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

import { getRoute } from '../menu/UrlAuxiliar';
import MenuLink from './MenuLink.react.js';
import SubMenu from './SubMenu.react.js';

export class MenuItem extends Component {
  static propTypes = {
    menu: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    father: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.state = {
      active: false
    };
  }

  onClick = () => {
    this.setState({
      active: !this.state.active
    });
  };

  openExternalLink = () => {
    window.open(addHttp(this.props.menu.get('link')));
  };

  render() {
    const { menu, father, onClose } = this.props;
    const { active } = this.state;

    const subMenu = menu.get('submenus');

    if (father) {
      const to = subMenu ? undefined : menu.get('route') || menu.get('link');
      const onClick = subMenu ? this.onClick : onClose;

      return (
        <MenuLink to={to} onClick={onClick} hasLink={!!menu.get('link')}>
          <b className="father" id={menu.get('title')}>
            <span className="father-inner">
              {menu.get('title')}
              <i>{menu.get('subtitle')}</i>
            </span>
          </b>
          {subMenu && (
            <SubMenu menu={subMenu} active={active} onClose={onClose} />
          )}
        </MenuLink>
      );
    }

    if (menu.get('link')) {
      return (
        <li className="item" onClick={this.openExternalLink}>
          <a>{menu.get('title')}</a>
        </li>
      );
    }

    return (
      <MenuLink to={getRoute(menu)} onClick={onClose}>
        <a>{menu.get('title')}</a>
      </MenuLink>
    );
  }
}

export default MenuItem;
