import Panel from 'portaltransparencia-common/components/Panel.react.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import { downloadArquivo } from '../arquivo/Actions';
import FormAtendimento from '../atendimento/Form.react.js';
import PageHeader from '../components/PageHeader.react.js';
import NotasExplicativas from '../notaexplicativa/Form.react';
import * as actions from './Actions.js';

export class SicFisico extends Component {
  static propTypes = {
    findArquivoSicFisico: PropTypes.func.isRequired,
    findMensagemSicFisico: PropTypes.func.isRequired,
    sicFisico: PropTypes.object.isRequired,
    mensagem: PropTypes.string,
    location: PropTypes.object,
    downloadArquivo: PropTypes.func.isRequired,
    atendimento: PropTypes.object
  };

  componentDidMount() {
    const { findArquivoSicFisico, findMensagemSicFisico } = this.props;

    findArquivoSicFisico();
    findMensagemSicFisico();
  }

  downloadSicFisico = event => {
    const { downloadArquivo, sicFisico } = this.props;
    event && event.preventDefault();
    downloadArquivo(sicFisico.get('id'));
  };

  renderLinkSicFisico = () => {
    const { sicFisico, mensagem, atendimento } = this.props;

    if (sicFisico.size > 0) {
      return (
        <div className="btn-group center mb-xs">
          <button
            id="btnSicFisico"
            className="btn module-color inline icon-right"
            onClick={this.downloadSicFisico}
          >
            Veja aqui como pedir uma informação presencial
            <em className="fa fa-download"></em>
          </button>
        </div>
      );
    }

    let orgao = '';
    atendimento.map(data => {
      if (data.get('id').get('id') == 50) {
        orgao = data.get('valor');
      }
    });

    return (
      <Panel expansible={false}>
        <div>
          <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
            {mensagem ? mensagem : 'Dirija-se ao Protocolo da ' + orgao}
          </pre>
        </div>
        <hr className="mt-xs mb-xs" />
        Aguarde a inserção da solicitação no Protocolo-SIC Físico (Solicitação
        Presencial) e receba o seu número de protocolo. Guarde o seu número de
        protocolo, pois ele é o comprovante do cadastro da solicitação.
      </Panel>
    );
  };

  render() {
    return (
      <section>
        <PageHeader location={this.props.location} />

        {this.renderLinkSicFisico()}

        <NotasExplicativas pathname={this.props.location.pathname} />

        <Panel expansible={false}>
          <FormAtendimento />
        </Panel>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    sicFisico: state.sicfisico.get('sicFisico'),
    mensagem: state.sicfisico.getIn(['mensagem', 'texto']),
    atendimento: state.atendimento.get('atendimento')
  };
}

const allActions = {
  ...actions,
  downloadArquivo
};

export default connect(mapStateToProps, allActions)(SicFisico);
