import { Col, Row } from 'react-bootstrap';

import AuthService from 'portaltransparencia-common/auth/AuthService.js';
import Component from 'react-pure-render/component';
import Input from 'portaltransparencia-common/components/EloInput.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import PropTypes from 'prop-types';
import React from 'react';

export default class ProcessoConsultaFiltro extends Component {
  static propTypes = {
    filtros: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    findSolicitacaoEsic: PropTypes.func.isRequired,
    valorParametroEntidadeProtocolo: PropTypes.string,
    errors: PropTypes.object,
    tiposProcessos: PropTypes.array
  };

  onChangeDados = event => {
    const { onChange } = this.props;

    onChange('currentRecord', event);
  };

  onConsultaProtocolo = () => {
    const {
      findSolicitacaoEsic,
      filtros,
      valorParametroEntidadeProtocolo
    } = this.props;

    let entidade = valorParametroEntidadeProtocolo;
    if (!valorParametroEntidadeProtocolo) {
      entidade = AuthService.getUserInfo().entidade.id;
    }

    const ano = filtros.get('anoProcesso');
    const tipo = filtros.get('tipoProcesso');
    const numero = filtros.get('numeroProcesso');
    const senha = filtros.get('senhaProcesso');

    if (ano && numero && senha && tipo) {
      findSolicitacaoEsic(entidade, ano, numero, senha, tipo);
    }
  };

  renderTiposProcessos = data => {
    return (
      <option key={data.get('id')} value={data.get('id')}>
        {data.get('id') + ' - ' + data.get('descricao')}
      </option>
    );
  };

  render() {
    const { filtros, tiposProcessos } = this.props;

    return (
      <Panel title="Consulta Solicitação" isForm expansible={false}>
        <Row>
          <Col sm={8} md={2}>
            <Input
              placeholder="Informe o Número"
              id="numeroProcesso"
              label="Número"
              type="number"
              name="numeroProcesso"
              ref="numeroProcesso"
              onChange={this.onChangeDados}
              value={filtros.get('numeroProcesso')}
            />
          </Col>
          <Col sm={4} md={3}>
            <Input
              name="tipoProcesso"
              ref="tipoProcesso"
              label="Tipo Processo"
              type="select"
              onChange={this.onChangeDados}
              value={filtros.get('tipoProcesso')}
            >
              <option value="">Selecione</option>
              {tiposProcessos && tiposProcessos.map(this.renderTiposProcessos)}
            </Input>
          </Col>
          <Col md={1}>
            <Input
              placeholder="Informe o ano"
              id="anoProcesso"
              label="Ano"
              type="number"
              name="anoProcesso"
              ref="anoProcesso"
              onChange={this.onChangeDados}
              value={filtros.get('anoProcesso')}
              autoFocus
            />
          </Col>
          <Col md={2}>
            <Input
              placeholder="Informe a Senha"
              id="senhaProcesso"
              label="Senha"
              type="text"
              name="senhaProcesso"
              ref="senhaProcesso"
              onChange={this.onChangeDados}
              value={filtros.get('senhaProcesso')}
            />
          </Col>
          <Col md={4}>
            <div className="form-group">
              <label className="label"></label>
              <button
                className="btn module-color icon-right"
                type="button"
                ref="consultar"
                onClick={this.onConsultaProtocolo}
              >
                Pesquisar
                <em className="fa fa-search"></em>
              </button>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={12} className="mt-xs">
            <div className="info" style={{ textAlign: 'justify' }}>
              Em caso de indeferimento, poderá ser interposto um recurso contra
              a decisão. Para isso é necessário a abertura de uma nova
              solicitação informando o número do protocolo que foi indeferido,
              juntamente com a justificativa de interposição.
            </div>
          </Col>
        </Row>
      </Panel>
    );
  }
}
