import { Map } from 'immutable';
import Input from 'portaltransparencia-common/components/EloInput.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import SearchPagination from 'portaltransparencia-common/components/SearchPagination.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import TableHeaderItem from 'portaltransparencia-common/components/TableHeaderItem.react';
import {
  onChange,
  resetCrud
} from 'portaltransparencia-common/crud/Actions.js';
import {
  addFilterUrl,
  addPagination
} from 'portaltransparencia-common/libs/AddFilter.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import * as actions from './Actions.js';

export class FormPermuta extends Component {
  static propTypes = {
    permutas: PropTypes.object.isRequired,
    findPermutas: PropTypes.func.isRequired,
    filtros: PropTypes.object,
    resetCrud: PropTypes.func.isRequired,
    onChange: PropTypes.func
  };

  componentDidMount() {
    this.onConsultarPermutas();
  }

  onConsultarPermutas = event => {
    const { permutas } = this.props;
    const initialPage = permutas.set('number', 0);

    this.searchWithPage(initialPage);
    event && event.preventDefault();
    this.refs.panelFiltroPermuta && this.refs.panelFiltroPermuta.collapse();
  };

  searchWithPage = page => {
    const { findPermutas } = this.props;
    findPermutas(this.montarFiltro(page));
  };

  onResetFiltro = () => {
    const { resetCrud, findPermutas } = this.props;
    resetCrud('filtroPermutas');
    findPermutas('');
  };

  onChangeFiltro = event => {
    const { onChange } = this.props;
    onChange('filtroPermutas', event);
  };

  montarFiltro = page => {
    const { filtros } = this.props;
    const nomeServidor = filtros.get('nomeServidor');
    const cargo = filtros.get('cargo');
    const localDe = filtros.get('localDe');
    const localPara = filtros.get('localPara');

    let filtro = '';

    if (nomeServidor) {
      filtro = addFilterUrl(filtro, `nomeServidor=${nomeServidor}`);
    }

    if (cargo) {
      filtro = addFilterUrl(filtro, `cargo=${cargo}`);
    }

    if (localDe) {
      filtro = addFilterUrl(filtro, `localDe=${localDe}`);
    }

    if (localPara) {
      filtro = addFilterUrl(filtro, `localPara=${localPara}`);
    }

    return addPagination(page, filtro);
  };

  renderPermuta = (permuta, index) => {
    return (
      <tr ref="row" key={index}>
        <td>{permuta.get('protocolo')}</td>
        <td>{permuta.get('matricula')}</td>
        <td>{permuta.get('nome')}</td>
        <td>{permuta.get('lotacaoAtual')}</td>
        <td>{permuta.get('lotacaoDesejada')}</td>
        <td>{DateUtils.formatDateTimeShort(permuta.get('dataSolicitacao'))}</td>
        <td>{permuta.get('cargo')}</td>
      </tr>
    );
  };

  render() {
    const { permutas, filtros } = this.props;
    if (!permutas.has('content')) {
      return null;
    }

    return (
      <section>
        <Panel
          title="Consultar em Permutas"
          mobileClose
          isForm
          ref="panelFiltroPermuta"
        >
          <form>
            <Row>
              <Col sm={6} md={6}>
                <Input
                  id="nomeServidor"
                  placeholder="Informe o nome do servidor"
                  name="nomeServidor"
                  ref="nomeServidor"
                  label="Nome do Servidor"
                  type="text"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('nomeServidor')}
                />
              </Col>
              <Col sm={6} md={6}>
                <Input
                  id="cargo"
                  placeholder="Informe o cargo"
                  type="text"
                  ref="cargo"
                  name="cargo"
                  label="Cargo"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('cargo')}
                />
              </Col>
              <Col sm={6} md={6}>
                <Input
                  id="localDe"
                  placeholder="Informe o local de origem"
                  type="text"
                  ref="localDe"
                  name="localDe"
                  label="Local de:"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('localDe')}
                />
              </Col>
              <Col sm={6} md={6}>
                <Input
                  id="localPara"
                  placeholder="Informe o local de destino"
                  type="text"
                  ref="localPara"
                  name="localPara"
                  label="Local Para:"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('localPara')}
                />
              </Col>

              <Col xs={12} sm={12} md={12}>
                <div className="form-group">
                  <label className="label" />
                  <div className="btn-group">
                    <button
                      id="btnFiltrar"
                      className="btn module-color icon-right"
                      ref="filtrar"
                      onClick={this.onConsultarPermutas}
                    >
                      Pesquisar
                      <em className="fa fa-search" />
                    </button>
                    <button
                      id="btnReset"
                      className="btn neutral icon-right inline no-text"
                      type="button"
                      onClick={this.onResetFiltro}
                    >
                      Limpar
                      <em className="fa fa-times" />
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </form>
        </Panel>

        <Panel isForm expansible={false} tableResponsive>
          <div className="panel-table-outer">
            <table className="panel-table striped fancy" id="tablePermutas">
              <TableHeader empty={permutas.get('content').isEmpty()}>
                <tr>
                  <TableHeaderItem
                    field="protocolo"
                    label="Protocolo"
                    page={permutas}
                    searchWithPage={this.searchWithPage}
                  />
                  <TableHeaderItem
                    field="matricula"
                    label="Matrícula"
                    page={permutas}
                    searchWithPage={this.searchWithPage}
                  />
                  <TableHeaderItem
                    field="nome"
                    label="Nome"
                    page={permutas}
                    searchWithPage={this.searchWithPage}
                  />
                  <TableHeaderItem
                    field="lotacaoAtual"
                    label="De"
                    page={permutas}
                    searchWithPage={this.searchWithPage}
                  />
                  <TableHeaderItem
                    field="lotacaoDesejada"
                    label="Para"
                    page={permutas}
                    searchWithPage={this.searchWithPage}
                  />
                  <TableHeaderItem
                    field="dataSolicitacao"
                    label="Data Solicitação"
                    page={permutas}
                    searchWithPage={this.searchWithPage}
                  />
                  <TableHeaderItem
                    field="cargo"
                    label="Cargo"
                    page={permutas}
                    searchWithPage={this.searchWithPage}
                  />
                </tr>
              </TableHeader>
              <tbody id="tbodyPermutas">
                {permutas.get('content').map(this.renderPermuta)}
              </tbody>
            </table>
          </div>
          <SearchPagination
            page={permutas}
            searchWithPage={this.searchWithPage}
          />
        </Panel>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    permutas: state.permutas.get('permutas'),
    filtros: state.crud.getIn(['filtroPermutas', 'currentRecord'], Map({}))
  };
}

const allActions = {
  ...actions,
  resetCrud,
  onChange
};

export default connect(mapStateToProps, allActions)(FormPermuta);
