import StorageService from 'portaltransparencia-common/libs/StorageService.js';

import * as types from './Types.js';

export function findFornecedoresAPagar(filtro) {
  const { entidade } = StorageService.getItem('userInfo');
  const baseFilter = `entidade=${entidade.id}&`;

  return ({ fetch }) => ({
    type: types.FORNECEDORAPAGAR,
    payload: {
      promise: fetch(`/fornecedorapagar/lista?${baseFilter}${filtro}`)
    }
  });
}

export function findFornecedoresAPagarTotais(filtro) {
  const { entidade } = StorageService.getItem('userInfo');
  const baseFilter = `entidade=${entidade.id}&`;

  return ({ fetch }) => ({
    type: types.FORNECEDORAPAGARTOTAIS,
    payload: {
      promise: fetch(`/fornecedorapagar/totais?${baseFilter}${filtro}`)
    }
  });
}
