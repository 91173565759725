export const PESQUISA_CONSELHOS = 'PESQUISA_CONSELHOS';
export const PESQUISA_CONSELHOS_START = 'PESQUISA_CONSELHOS_START';
export const PESQUISA_CONSELHOS_ERROR = 'PESQUISA_CONSELHOS_ERROR';
export const PESQUISA_CONSELHOS_SUCCESS = 'PESQUISA_CONSELHOS_SUCCESS';

export const PESQUISA_CONSELHO = 'PESQUISA_CONSELHO';
export const PESQUISA_CONSELHO_START = 'PESQUISA_CONSELHO_START';
export const PESQUISA_CONSELHO_ERROR = 'PESQUISA_CONSELHO_ERROR';
export const PESQUISA_CONSELHO_SUCCESS = 'PESQUISA_CONSELHO_SUCCESS';

export const FILTRAR_CONSELHOS = 'FILTRAR_CONSELHOS';
export const RESETAR_CONSELHOS = 'RESETAR_CONSELHOS';
