import { Map } from 'immutable';
import Input from 'portaltransparencia-common/components/EloInput.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import SearchPagination from 'portaltransparencia-common/components/SearchPagination.react.js';
import {
  onChange,
  resetCrud
} from 'portaltransparencia-common/crud/Actions.js';
import {
  addFilterUrl,
  addPagination
} from 'portaltransparencia-common/libs/AddFilter.js';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import FilterButtons from '../components/FilterButtons.react';
import PageHeader from '../components/PageHeader.react.js';
import NotasExplicativas from '../notaexplicativa/Form.react';
import * as actions from './Actions.js';
import DividaAtivaListagem from './DividaAtivaListagem.react.js';

export class FormDividaAtiva extends Component {
  static propTypes = {
    page: PropTypes.object.isRequired,
    findDividasAtivas: PropTypes.func.isRequired,
    findDividasAtivasContribuinte: PropTypes.func.isRequired,
    filtros: PropTypes.object,
    onChange: PropTypes.func,
    location: PropTypes.object.isRequired,
    resetCrud: PropTypes.func.isRequired
  };

  constructor() {
    super();

    this.state = {
      totalDividasAtivas: 0
    };
  }

  onChangeFiltro = event => {
    const { onChange } = this.props;

    onChange('filtroDividaAtiva', event);
  };

  onResetFiltro = () => {
    const { resetCrud, findDividasAtivas } = this.props;
    resetCrud('filtroDividaAtiva');

    findDividasAtivas();
  };

  getTotal = filter => {
    const { findDividasAtivasTotal } = this.props;

    findDividasAtivasTotal(filter).then(result =>
      this.setState({ totalDividasAtivas: result.value.valor })
    );
  };

  onConsulta = () => {
    const { page } = this.props;
    const initialPage = page.set('number', 0);

    this.searchWithPage(initialPage);
    this.refs.panelFiltroDividaAtiva &&
      this.refs.panelFiltroDividaAtiva.collapse();

    this.getTotal(page ? this.montarFiltro(page) : '');
  };

  montarFiltro = page => {
    const { entidade } = StorageService.getItem('userInfo');
    const { filtros } = this.props;

    let filter = `entidadeContabil=${entidade.id}`;

    if (filtros.get('nome')) {
      filter = addFilterUrl(filter, `nomeFiltro=${filtros.get('nome')}`);
    }

    return addPagination(page, filter);
  };

  searchWithPage = page => {
    const { findDividasAtivas } = this.props;

    findDividasAtivas(this.montarFiltro(page));
  };

  handleKeyDown = event => {
    const teclaEnter = 13;
    if (event.keyCode === teclaEnter) {
      this.onConsulta();
    }
  };

  render() {
    const { page, filtros } = this.props;

    let filter = '';
    if (page) {
      filter = this.montarFiltro(page);
    }

    return (
      <section>
        <PageHeader location={this.props.location} />

        <NotasExplicativas pathname={this.props.location.pathname} />

        <Panel
          title="Consultar em Dívida Ativa"
          mobileClose
          isForm
          ref="panelFiltroDividaAtiva"
        >
          <Row>
            <Col xs={12} sm={8} md={9}>
              <Input
                id="nome"
                type="text"
                ref="nome"
                name="nome"
                placeholder="Nome"
                label="Nome"
                onChange={this.onChangeFiltro}
                value={filtros.get('nome')}
                onKeyDown={this.handleKeyDown}
              />
            </Col>
            <Col xs={12} sm={4} md={3}>
              <FilterButtons
                idBtnReset="btnResetFilter"
                onConsulta={this.onConsulta}
                onReset={this.onResetFiltro}
              />
            </Col>
          </Row>
        </Panel>

        <Panel isTable tableResponsive expansible={false}>
          {page && page.get('content') && (
            <DividaAtivaListagem
              dividas={page.get('content')}
              filter={filter}
              valoTotalDividaTiva={this.state.totalDividasAtivas}
            />
          )}
        </Panel>

        <SearchPagination page={page} searchWithPage={this.searchWithPage} />
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    page: state.dividaAtiva.get('dividas'),
    filtros: state.crud.getIn(['filtroDividaAtiva', 'currentRecord'], Map({}))
  };
}

const allActions = {
  ...actions,
  onChange,
  resetCrud
};

export default connect(mapStateToProps, allActions)(FormDividaAtiva);
