import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export class MainSection extends Component {
  static propTypes = {
    children: PropTypes.node
  };

  render() {
    const { children } = this.props;

    return (
      <div className="content-wrapper">
        <div className="container">{children}</div>
      </div>
    );
  }
}

export default MainSection;
