export class KeycloakConfig {
  static config = onPremise => {
    const ambienteConfigPadrao = onPremise ? 'oxy' : 'dev';
    const configKeyCloakRealmOxyElotech = {
      realm: 'OxyElotech',
      url: `https://openid.${ambienteConfigPadrao}.elotech.com.br/auth`,
      resource: 'portal-transparencia-frontend',
      clientId: 'portal-transparencia-frontend',
      'ssl-required': 'none',
      'public-client': true,
      'confidential-port': 0,
      'enable-cors': true
    };

    if (onPremise) {
      return configKeyCloakRealmOxyElotech;
    }

    if (
      window.location.hostname.includes('.localnet') ||
      window.location.hostname.includes('localhost')
    ) {
      return configKeyCloakRealmOxyElotech;
    }

    return '/painel-api/api/auth/realm-config/portal-transparencia-frontend';
  };
}
