import Component from 'react-pure-render/component';
import React from 'react';
import './Spinner.css';

export default class Spinner extends Component {
  render() {
    return (
      <div className="content-loader spin">
        <div className="content-loader-inner">
          <div className="item-a"></div>
          <div className="item-b"></div>
        </div>
      </div>
    );
  }
}
