import Component from 'react-pure-render/component';
import PropTypes from 'prop-types';
import React from 'react';
import { Col } from 'react-bootstrap';

export default class DisplayDataItem extends Component {
  static propTypes = {
    title: PropTypes.string,
    sm: PropTypes.number,
    value: PropTypes.any,
    value2: PropTypes.any,
    separator: PropTypes.string,
    show: PropTypes.bool,
    message: PropTypes.string
  };

  static defaultProps = {
    separator: ' - ',
    show: true,
    message: 'Sem registro'
  };

  render() {
    const { title, sm, value, value2, separator, show, message } = this.props;

    if (!show) {
      return null;
    }

    return (
      <Col sm={sm}>
        {title && <b className="dd-title">{title}</b>}
        <div className="dd-content" id={title}>
          {!value && typeof value === 'undefined' && <i>{message}</i>}

          {value && <span> {value} </span>}

          {value2 && (
            <span>
              {separator} {value2}
            </span>
          )}
        </div>
      </Col>
    );
  }
}
