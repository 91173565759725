import Input from 'portaltransparencia-common/components/EloInput.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import TextLink from 'portaltransparencia-common/components/TextLink.react';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import PageHeader from '../components/PageHeader.react.js';
import NotasExplicativas from '../notaexplicativa/Form.react';
import * as actions from './Actions.js';

export class FormPerguntas extends Component {
  static propTypes = {
    perguntas: PropTypes.object.isRequired,
    findPerguntas: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired
  };

  componentDidMount() {
    this.props.findPerguntas('');
  }

  findPerguntas = event => {
    if (this.refs.filtro) {
      this.props.findPerguntas(this.refs.filtro.getValue());
    }
    event && event.preventDefault();
    this.refs.panelFiltroPerguntas && this.refs.panelFiltroPerguntas.collapse();
  };

  onReset = () => {
    const { findPerguntas } = this.props;
    findPerguntas('');
  };

  handleKeyDown = event => {
    const teclaEnter = 13;
    if (event.keyCode === teclaEnter) {
      this.findPerguntas();
    }
  };

  renderPerguntas = data => {
    return (
      <Panel
        insidePanel
        key={data.getIn(['id', 'id'])}
        title={data.get('pergunta')}
        collapsed
      >
        <Row>
          <Col lg={12}>
            <TextLink text={data.get('resposta')} />
          </Col>
        </Row>
      </Panel>
    );
  };

  render() {
    const { perguntas } = this.props;

    if (!perguntas) {
      return null;
    }

    return (
      <section>
        <form>
          <PageHeader location={this.props.location} />

          <NotasExplicativas pathname={this.props.location.pathname} />

          <Panel
            title="Consultar Perguntas e Respostas"
            mobileClose
            isForm
            ref="panelFiltroPerguntas"
          >
            <Row>
              <Col xs={12} sm={8} md={9}>
                <Input
                  id="filtro"
                  name="filtro"
                  ref="filtro"
                  label="Consulte aqui as perguntas e/ou respostas que deseja encontrar"
                  type="text"
                />
              </Col>
              <Col xs={12} sm={4} md={3}>
                <div className="form-group">
                  <label className="label"></label>
                  <div className="btn-group">
                    <button
                      id="btnFiltrar"
                      className="btn module-color icon-right"
                      ref="filtrar"
                      onClick={this.findPerguntas}
                    >
                      Pesquisar
                      <em className="fa fa-search"></em>
                    </button>
                    <button
                      className="btn neutral icon-right inline no-text"
                      type="reset"
                      title="Limpar formulário"
                      onClick={this.onReset}
                    >
                      Limpar
                      <em className="fa fa-times"></em>
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </Panel>
        </form>

        {!perguntas.isEmpty() && (
          <Panel expansible={false}>
            {perguntas.map(this.renderPerguntas)}
          </Panel>
        )}
        {perguntas.isEmpty() && (
          <div className="info">Sem perguntas frequentes.</div>
        )}
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    perguntas: state.pergunta.get('perguntas')
  };
}

export default connect(mapStateToProps, actions)(FormPerguntas);
