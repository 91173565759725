import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import { withRouter } from 'react-router';

export class LeisAtosVinculosLista extends Component {
  static propTypes = {
    vinculos: PropTypes.object,
    sistema: PropTypes.string,
    tipoLeisAtos: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
  }

  passaTipoNaUrl = tipo => {
    return tipo ? `tipo=${tipo}&` : '';
  };

  onViewDetalhes = lei => {
    const { tipoLeisAtos, sistema } = this.props;
    const entidade = lei.get('entidade');
    const exercicio = lei.get('exercicio');
    const id = lei.get('idLeiVinculada');
    const tipo = lei.get('tipo');
    const natureza = lei.get('natureza');

    const url =
      `/leiseatos-${tipoLeisAtos}/detalhes?` +
      `entidade=${entidade}&` +
      `exercicio=${exercicio}&` +
      this.passaTipoNaUrl(tipo) +
      `id=${id}&` +
      `sistema=${sistema}&` +
      `natureza=${natureza}&` +
      `tipoLeisAtos=${tipoLeisAtos}`;

    this.props.history.push(url);
  };

  renderVinculos = vinculo => {
    const key = vinculo.get('id');

    if (!key) {
      return null;
    }

    return (
      <TableActions
        ref="row"
        key={key}
        data={vinculo}
        onMore={this.onViewDetalhes}
      >
        <td>{vinculo.get('numero')}</td>
        <td>{vinculo.get('exercicio')}</td>
        <td>{vinculo.get('natureza')}</td>
        <td>{vinculo.get('tipoDocumentoLegal')}</td>
        <td>{vinculo.get('descricao')}</td>
      </TableActions>
    );
  };

  render() {
    const { vinculos } = this.props;

    return (
      <section>
        <div className="panel-table-outer">
          <table className="panel-table striped fancy">
            <TableHeader empty={!vinculos || vinculos.isEmpty()} onMore>
              <tr>
                <th>Número</th>
                <th>Exercício</th>
                <th>Natureza</th>
                <th>Tipo</th>
                <th>Descrição</th>
                <th></th>
              </tr>
            </TableHeader>
            <tbody>{vinculos && vinculos.map(this.renderVinculos)}</tbody>
          </table>
        </div>
      </section>
    );
  }
}

export default withRouter(LeisAtosVinculosLista);
