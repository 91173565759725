import Panel from 'portaltransparencia-common/components/Panel.react.js';
import { getValueFromUrlSearchParams } from 'portaltransparencia-common/libs/URLUtils';
import {
  PARAM_SALARIOBASE,
  PARAM_USAWEBSERVICE_MARINGA
} from 'portaltransparencia-common/parametros/ParametrosPortalTransparencia';
import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import PageHeader from '../components/PageHeader.react.js';
import ParametroService from '../parametros/ParametroService.js';
import * as actions from './Actions.js';
import CabecalhoEntidade from '../entidades/CabecalhoEntidade.react.js';
import BotaoImprimir from 'portaltransparencia-common/components/BotaoImprimir.react.js';
import AutonomoDetalheInformacao from './AutonomoDetalhesInformacao.react.js';
import AutonomoDetalheFinanceiro from './AutonomoDetalheFinanceiro.react.js';

export class FormAutonomoDetails extends Component {
  static propTypes = {
    autonomo: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    findAutonomo: PropTypes.func.isRequired,
    clearDetalhe: PropTypes.func.isRequired,
    paramWebService: PropTypes.string,
    paramExibeSalarioBase: PropTypes.string
  };

  componentDidMount() {
    const { findAutonomo, location } = this.props;
    const codigo = getValueFromUrlSearchParams(location, 'codigo');
    findAutonomo(codigo);
  }

  componentWillUnmount() {
    const { clearDetalhe } = this.props;
    clearDetalhe();
  }

  render() {
    const { autonomo, location } = this.props;

    return (
      <div>
        <Helmet title={`Autônomos - ${autonomo.get('nome')}`} />
        <PageHeader
          location={location}
          textoDetalhe={`Autônomo: ${autonomo.get('nome')}`}
        />
        {autonomo && (
          <div>
            <CabecalhoEntidade />
            <BotaoImprimir />
            <Panel
              title="Informações Cadastrais"
              mobileClose
              onClickPanel={this.onClickPanelInformacoesCadastrais}
            >
              <AutonomoDetalheInformacao autonomo={autonomo} />
            </Panel>
          </div>
        )}

        {autonomo.get('dadosFinanceiros') &&
          !autonomo.get('dadosFinanceiros').isEmpty() && (
            <Panel
              isTable
              tableResponsive
              expansible={false}
              title="Dados Financeiros"
            >
              {
                <AutonomoDetalheFinanceiro
                  dadosFinanceiros={autonomo.get('dadosFinanceiros')}
                />
              }
            </Panel>
          )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    autonomo: state.servidor.get('autonomo'),
    paramWebService: ParametroService.getValorParametro(
      state,
      PARAM_USAWEBSERVICE_MARINGA
    ),
    paramExibeSalarioBase: ParametroService.getValorParametro(
      state,
      PARAM_SALARIOBASE
    )
  };
}

export default connect(mapStateToProps, actions)(FormAutonomoDetails);
