export const RECEITAS = 'RECEITAS';
export const RECEITAS_START = 'RECEITAS_START';
export const RECEITAS_SUCCESS = 'RECEITAS_SUCCESS';
export const RECEITAS_ERROR = 'RECEITAS_ERROR';

export const RECEITAS_DIA = 'RECEITAS_DIA';
export const RECEITAS_DIA_START = 'RECEITAS_DIA_START';
export const RECEITAS_DIA_SUCCESS = 'RECEITAS_DIA_SUCCESS';
export const RECEITAS_DIA_ERROR = 'RECEITAS_DIA_ERROR';

export const RECEITAS_RAZAO = 'RECEITAS_RAZAO';
export const RECEITAS_RAZAO_START = 'RECEITAS_RAZAO_START';
export const RECEITAS_RAZAO_SUCCESS = 'RECEITAS_RAZAO_SUCCESS';
export const RECEITAS_RAZAO_ERROR = 'RECEITAS_RAZAO_ERROR';

export const RECEITA = 'RECEITA';
export const RECEITA_START = 'RECEITA_START';
export const RECEITA_SUCCESS = 'RECEITA_SUCCESS';
export const RECEITA_ERROR = 'RECEITA_ERROR';

export const GRAFICO_ANUAL = 'GRAFICO_ANUAL';
export const GRAFICO_ANUAL_START = 'GRAFICO_ANUAL_START';
export const GRAFICO_ANUAL_SUCCESS = 'GRAFICO_ANUAL_SUCCESS';
export const GRAFICO_ANUAL_ERROR = 'GRAFICO_ANUAL_ERROR';

export const RECEITAS_FONTE_RECURSO = 'RECEITAS_FONTE_RECURSO';
export const RECEITAS_FONTE_RECURSO_START = 'RECEITAS_FONTE_RECURSO_START';
export const RECEITAS_FONTE_RECURSO_SUCCESS = 'RECEITAS_FONTE_RECURSO_SUCCESS';
export const RECEITAS_FONTE_RECURSO_ERROR = 'RECEITAS_FONTE_RECURSO_ERROR';

export const DETALHE_RECEITAS_FONTE_RECURSO = 'DETALHE_RECEITAS_FONTE_RECURSO';
export const DETALHE_RECEITAS_FONTE_RECURSO_START =
  'DETALHE_RECEITAS_FONTE_RECURSO_START';
export const DETALHE_RECEITAS_FONTE_RECURSO_SUCCESS =
  'DETALHE_RECEITAS_FONTE_RECURSO_SUCCESS';
export const DETALHE_RECEITAS_FONTE_RECURSO_ERROR =
  'DETALHE_RECEITAS_FONTE_RECURSO_ERROR';

export const RECEITAS_COVID = 'RECEITAS_COVID';
export const RECEITAS_COVID_START = 'RECEITAS_COVID_START';
export const RECEITAS_COVID_SUCCESS = 'RECEITAS_COVID_SUCCESS';
export const RECEITAS_COVID_ERROR = 'RECEITAS_COVID_ERROR ';

export const RECEITAS_ESPECIFICO = 'RECEITAS_ESPECIFICO';
export const RECEITAS_ESPECIFICO_START = 'RECEITAS_ESPECIFICO_START';
export const RECEITAS_ESPECIFICO_SUCCESS = 'RECEITAS_ESPECIFICO_SUCCESS';
export const RECEITAS_ESPECIFICO_ERROR = 'RECEITAS_ESPECIFICO_ERROR ';

export const SELECT_RECEITA = 'SELECT_RECEITA';
export const EXPAND_RECEITA = 'EXPAND_RECEITA';
export const COLLAPSE_RECEITA = 'COLLAPSE_RECEITA';
