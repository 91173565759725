export function addHttp(link) {
  if (
    link &&
    link.length > 0 &&
    !link.includes('https://') &&
    !link.includes('http://')
  ) {
    link = 'http://' + link;
  }
  return link;
}
