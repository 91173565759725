import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';

import * as types from './Types.js';

const initialState = fromJS({
  orgao: [],
  funcao: [],
  programa: [],
  projeto: [],
  elemento: [],
  fonteRecurso: [],
  esferaAdmin: [],
  tipo: {
    label: 'Valor Orçado',
    valor: 'valorOrcado'
  }
});

function despesaPorNivelReducer(state = initialState, action) {
  switch (action.type) {
    case types.DESPESAPORNIVEL_START:
    case types.DESPESA_POR_FONTE_RECURSO_START:
    case types.DESPESA_POR_ESFERA_ADMINISTRATIVA_START: {
      return startLoad(state);
    }

    case types.DESPESAPORNIVEL_ERROR:
    case types.DESPESA_POR_FONTE_RECURSO_ERROR:
    case types.DESPESA_POR_ESFERA_ADMINISTRATIVA_ERROR: {
      return endLoad(state);
    }

    case types.DESPESAPORNIVEL_SUCCESS: {
      switch (action.meta.path) {
        case 'O':
          return endLoad(state.set('orgao', fromJS(action.payload)));
        case 'F':
          return endLoad(state.set('funcao', fromJS(action.payload)));
        case 'P':
          return endLoad(state.set('programa', fromJS(action.payload)));
        case 'PA':
          return endLoad(state.set('projeto', fromJS(action.payload)));
        case 'N':
          return endLoad(state.set('elemento', fromJS(action.payload)));
      }
    }

    case types.DESPESA_POR_FONTE_RECURSO_SUCCESS: {
      return endLoad(state.set('fonteRecurso', fromJS(action.payload)));
    }

    case types.DESPESA_POR_ESFERA_ADMINISTRATIVA_SUCCESS: {
      return endLoad(state.set('esferaAdmin', fromJS(action.payload)));
    }

    case types.CHANGE_TIPO_DESPESA: {
      return state.set('tipo', action.payload);
    }
  }

  return state;
}

export default despesaPorNivelReducer;
