import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';

import * as types from './Types.js';

const initialState = fromJS({
  alvaras: [],
  alvara: {},
  alvaraArquivos: []
});

function participantesReducer(state = initialState, action) {
  switch (action.type) {
    case types.ALVARAS_START:
    case types.ALVARA_START:
    case types.ALVARA_ARQUIVOS_START: {
      return startLoad(state);
    }

    case types.ALVARAS_SUCCESS: {
      return endLoad(state.set('alvaras', fromJS(action.payload)));
    }
    case types.ALVARA_SUCCESS: {
      return endLoad(state.set('alvara', fromJS(action.payload)));
    }
    case types.ALVARA_ARQUIVOS_SUCCESS: {
      return endLoad(state.set('alvaraArquivos', fromJS(action.payload)));
    }

    case types.ALVARAS_ERROR:
    case types.ALVARA_ERROR:
    case types.ALVARA_ARQUIVOS_ERROR: {
      return endLoad(state);
    }
  }
  return state;
}

export default participantesReducer;
