import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export default class AvaliacaoPpaProgramaRow extends Component {
  static propTypes = {
    programa: PropTypes.object.isRequired
  };

  render() {
    const { programa } = this.props;

    return (
      <tr
        key={`${programa.get('entidade')}_${programa.get(
          'exercicio'
        )}_${programa.get('programa')}`}
      >
        <td>{programa.get('entidade')}</td>
        <td>{programa.get('exercicio')}</td>
        <td className="right">
          <NumberFormatter value={programa.get('valorPlanejado')} />
        </td>
        <td className="right">
          <NumberFormatter value={programa.get('valorPlanejadoAtualizado')} />
        </td>
        <td className="right">
          <NumberFormatter value={programa.get('valorExecutado')} />
        </td>
        <td className="right">
          <NumberFormatter value={programa.get('metaFisicaPrevista')} />
        </td>
        <td className="right">
          <NumberFormatter value={programa.get('numeroRealizado')} />
        </td>
      </tr>
    );
  }
}
