import PropTypes from 'prop-types';
import React from 'react';
import NumberUtils from '../libs/NumberUtils.js';

export default class NumberFormatter extends React.PureComponent {
  static propTypes = {
    value: PropTypes.number,
    format: PropTypes.string
  };

  static defaultProps = {
    value: 0,
    format: '0,0.00'
  };

  render() {
    const { value, format } = this.props;

    return <span>{NumberUtils.format(value, format)}</span>;
  }
}
