import classnames from 'classnames';
import $ from 'jquery';
import Accessibility from 'portaltransparencia-common/components/Accessibility.react.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

import FormEntidade from '../entidades/Form.react.js';

export class SubHeader extends Component {
  static propTypes = {
    location: PropTypes.object,
    entidadeSelecionada: PropTypes.object,
    exercicioLogado: PropTypes.object,
    habilitaPerguntas: PropTypes.bool
  };

  constructor(props) {
    super(props);

    this.state = {
      width: 0,
      showEntidade: false
    };
  }

  componentDidMount() {
    this.setState({
      width: $(window).width()
    });

    if ($(window).width() < 992 && $('.change-entity-button').length > 0) {
      $(document).mouseup(e => {
        const container = $('.change-entity');

        if (
          !container.is(e.target) && // if the target of the click isn't the container...
          container.has(e.target).length === 0 && // ... nor a descendant of the container
          container.hasClass('active')
        ) {
          container.removeClass('active');
        }
      });
    }

    $(window).resize(() => {
      this.setState({
        width: $(window).width()
      });
    });
  }

  onClickTrocaEntidade = () => {
    $('.menu-area').removeClass('active');
    $('.change-entity').toggleClass('active');

    this.setState({
      showEntidade: false
    });
  };

  onClickVerEntidade = () => {
    $('.change-entity').removeClass('active');
    this.setState({
      showEntidade: !this.state.showEntidade
    });
  };

  renderTrocaEntidade = () => {
    const { width } = this.state;

    if (width >= 993) {
      return null;
    }

    return (
      <span
        className="change-entity-button"
        onClick={this.onClickTrocaEntidade}
      />
    );
  };

  renderVerEntidade = () => {
    const { entidadeSelecionada, exercicioLogado } = this.props;
    const { width, showEntidade } = this.state;

    if (width >= 993) {
      return null;
    }

    const nomeEntidade = entidadeSelecionada && entidadeSelecionada.get('nome');
    const anoEntidade =
      exercicioLogado && exercicioLogado.getIn(['id', 'exercicio']);

    const classNames = classnames('see-entity-button-title', {
      active: showEntidade
    });

    return (
      <span className="see-entity-button" onClick={this.onClickVerEntidade}>
        <b className={classNames}>
          {nomeEntidade} - {anoEntidade}
        </b>
      </span>
    );
  };

  render() {
    const { habilitaPerguntas, location } = this.props;

    return (
      <header className="sub-header">
        <div className="container">
          <div className="sisters">
            <div className="change-entity">
              {this.renderTrocaEntidade()}
              {this.renderVerEntidade()}
              <FormEntidade location={location} />
            </div>
          </div>

          <div className="sisters">
            <Accessibility habilitaPerguntas={habilitaPerguntas} />
          </div>
        </div>
      </header>
    );
  }
}

export default SubHeader;
