import Panel from 'portaltransparencia-common/components/Panel.react.js';
import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import { getValueFromUrlSearchParams } from 'portaltransparencia-common/libs/URLUtils';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import PageHeader from '../components/PageHeader.react.js';
import * as actions from './Actions.js';

export class FormPacienteDetalhe extends Component {
  static propTypes = {
    pacientes: PropTypes.object.isRequired,
    findPacientes: PropTypes.func.isRequired,
    clearRegistros: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    params: PropTypes.object
  };

  componentDidMount() {
    const {
      match: {
        params: { idFila }
      }
    } = this.props;
    const { findPacientes } = this.props;
    findPacientes(idFila);
  }

  componentWillUnmount() {
    const { clearRegistros } = this.props;

    clearRegistros();
  }

  renderPacientes = paciente => {
    return (
      <TableActions
        ref="row"
        key={paciente.get('f_usuario_cns')}
        data={paciente}
      >
        <td>{paciente.get('f_ordem')}</td>
        <td>{paciente.get('f_usuario_cns')}</td>
        <td>{paciente.get('f_prioridade')}</td>
        <td>{paciente.get('f_especialidade')}</td>
        <td>{paciente.get('f_tipo_atendimento')}</td>
        <td>{DateUtils.formatDateTime(paciente.get('f_data_entrada'))}</td>
        <td>{paciente.get('f_status')}</td>
      </TableActions>
    );
  };

  render() {
    const { pacientes, location } = this.props;
    let nomeFila = getValueFromUrlSearchParams(location, 'fila');
    nomeFila = nomeFila ? nomeFila : '';
    return (
      <section>
        <PageHeader location={this.props.location} />

        <Panel
          title={`Pacientes da Fila de Espera - ${nomeFila}`}
          isForm
          expansible={false}
          tableResponsive
        >
          <div className="panel-table-outer">
            <table className="panel-table striped fancy">
              <TableHeader empty={pacientes.isEmpty()}>
                <tr>
                  <th>Ordem</th>
                  <th>Código SUS</th>
                  <th>Prioridade</th>
                  <th>Especialidade</th>
                  <th>Tipo Atendimento</th>
                  <th>Data de Entrada</th>
                  <th>Situação</th>
                  <th />
                </tr>
              </TableHeader>
              <tbody>{pacientes.map(this.renderPacientes)}</tbody>
            </table>
          </div>
        </Panel>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    pacientes: state.filaEsperaAtendimento.get('pacientes')
  };
}

export default connect(mapStateToProps, actions)(FormPacienteDetalhe);
