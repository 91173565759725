import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';

import * as types from './Types.js';

const initialState = fromJS({
  suprimentosFundos: [],
  total: {},
  filtros: []
});

function suprimentosFundosReducer(state = initialState, action) {
  switch (action.type) {
    case types.SUPRIMENTOS_FUNDOS_LIST_START:
    case types.SUPRIMENTOS_FUNDOS_TOTAL_START: {
      return startLoad(state);
    }

    case types.SUPRIMENTOS_FUNDOS_LIST_ERROR:
    case types.SUPRIMENTOS_FUNDOS_TOTAL_ERROR: {
      return endLoad(state);
    }

    case types.SUPRIMENTOS_FUNDOS_LIST_SUCCESS: {
      return endLoad(state.set('suprimentosFundos', fromJS(action.payload)));
    }
    case types.SUPRIMENTOS_FUNDOS_TOTAL_SUCCESS: {
      return endLoad(state.set('total', fromJS(action.payload)));
    }
  }

  return state;
}

export default suprimentosFundosReducer;
