import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react';
import TreeTableItem from 'portaltransparencia-common/components/treetable/TreeTableItem.react';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export default class TableItem extends Component {
  static propTypes = {
    item: PropTypes.object,
    expand: PropTypes.func.isRequired,
    collapse: PropTypes.func.isRequired,
    paramEmLiquidacoes: PropTypes.string
  };

  onClickItem = () => {
    const { item, expand, collapse } = this.props;

    if (item.get('open')) {
      collapse(item.get('programatica'), item.get('nivel'));
    } else {
      expand(item.get('programatica'), item.get('nivel'));
    }
  };

  render = () => {
    const { item, paramEmLiquidacoes } = this.props;

    if (!item.get('visible')) {
      return null;
    }

    return (
      <TreeTableItem
        key={item.get('programatica')}
        data={item}
        dataLevel={item.get('programatica')}
        father={item.get('aceitaMovimentacao') == 'N'}
        level={item.get('nivel')}
        visible={item.get('visible')}
      >
        <td style={{ cursor: 'pointer' }} onClick={this.onClickItem}>
          {item.get('programatica')}
        </td>
        <td>{item.get('descricao')}</td>
        <td className="right">
          <NumberFormatter value={item.get('valorPrevisto')} />
        </td>
        <td className="right">
          <NumberFormatter
            value={item.get('valorAtualizado') - item.get('valorPrevisto')}
          />
        </td>
        <td className="right">
          <NumberFormatter value={item.get('valorAtualizado')} />
        </td>
        <td className="right">
          <NumberFormatter value={item.get('valorEmpenhado')} />
        </td>
        {paramEmLiquidacoes == 'S' && (
          <td className="right">
            <NumberFormatter value={item.get('valorEmLiquidacao')} />
          </td>
        )}
        <td className="right">
          <NumberFormatter value={item.get('valorLiquidado')} />
        </td>
        <td className="right">
          <NumberFormatter value={item.get('valorPago')} />
        </td>
      </TreeTableItem>
    );
  };
}
