import BotaoVoltar from 'portaltransparencia-common/components/BotaoVoltar.react.js';
import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import PageHeader from '../components/PageHeader.react.js';
import CabecalhoEntidade from '../entidades/CabecalhoEntidade.react.js';
import * as actions from './Actions.js';
import MovimentacaoEntrada from './MovimentacaoEntrada.react.js';
import MovimentacaoFiltro from './MovimentacaoFiltro.react.js';
import MovimentacaoSaida from './MovimentacaoSaida.react.js';

class FormMovimentacao extends Component {
  static propTypes = {
    findMovimentacoes: PropTypes.func.isRequired,
    movimentacoes: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
  };

  componentDidMount() {
    const {
      findMovimentacoes,
      match: { params }
    } = this.props;

    findMovimentacoes(params.centrocusto, params.item);
  }

  render() {
    const {
      movimentacoes,
      match: { params }
    } = this.props;

    if (movimentacoes.isEmpty()) {
      return (
        <div>
          {' '}
          <BotaoVoltar />{' '}
          <div className="info">Nenhuma movimentação encontrada.</div>
        </div>
      );
    }

    return (
      <section>
        <Helmet
          title={`Almoxarifado - ${movimentacoes.first().get('itemDescricao')}`}
        />

        <PageHeader location={this.props.location} />

        <CabecalhoEntidade />

        <MovimentacaoFiltro movimentacoes={movimentacoes} />

        <MovimentacaoEntrada movimentacoes={movimentacoes} params={params} />

        <MovimentacaoSaida movimentacoes={movimentacoes} params={params} />
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    movimentacoes: state.almoxarifado.get('movimentacoes'),
    exercicioLogado: state.entidade.get('exercicioLogado')
  };
}
export default connect(mapStateToProps, actions)(FormMovimentacao);
