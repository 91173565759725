import Panel from 'portaltransparencia-common/components/Panel.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

import CargoFaixaRow from './CargoFaixaRow.react.js';

export class CargoFaixasTable extends Component {
  static propTypes = {
    faixas: PropTypes.object.isRequired
  };

  generateKey = faixa => {
    return `${faixa.get('cargo')}_
            ${faixa.get('quadroSalarial')}_
            ${faixa.get('grupoOcupacional')}_
            ${faixa.get('nivel')}_
            ${faixa.get('faixa')}_
            ${faixa.get('inicioValidade')}`;
  };

  renderFaixas = faixa => {
    return <CargoFaixaRow key={this.generateKey(faixa)} faixa={faixa} />;
  };

  render() {
    const { faixas } = this.props;

    return (
      <Panel isForm expansible={false} tableResponsive>
        <div className="panel-table-outer">
          <table className="panel-table striped fancy">
            <TableHeader empty={faixas.isEmpty()}>
              <tr>
                <th>Faixa</th>
                <th>Descrição</th>
                <th className="right">Salário</th>
                <th className="right">Valor Gratificação</th>
              </tr>
            </TableHeader>
            <tbody>{faixas.map(this.renderFaixas)}</tbody>
          </table>
        </div>
      </Panel>
    );
  }
}

export default CargoFaixasTable;
