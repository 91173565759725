import * as actions from './Actions.js';

import { Col, Row } from 'react-bootstrap';
import {
  addErrorNotification,
  addSuccessNotification
} from 'portaltransparencia-common/ui/Actions.js';

import Component from 'react-pure-render/component';
import Input from 'portaltransparencia-common/components/EloInput.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import PropTypes from 'prop-types';
import React from 'react';
import Validation from 'portaltransparencia-common/components/Validation.js';
import { connect } from 'react-redux';
import { getValueFromUrlSearchParams } from 'portaltransparencia-common/libs/URLUtils';
import { validaCpfCnpj } from 'portaltransparencia-common/libs/ValidaCPFCNPJ.js';
import { validaEmail } from 'portaltransparencia-common/libs/ValidaEmail.js';

export class LicitacaoDetalheSolicitacao extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    solicitarNotificacao: PropTypes.func.isRequired,
    addSuccessNotification: PropTypes.func.isRequired,
    addErrorNotification: PropTypes.func.isRequired,
    findMensagemSolicitacao: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
    validate: PropTypes.func,
    handleError: PropTypes.func,
    mensagemSolicitacao: PropTypes.object
  };

  componentDidMount() {
    const { findMensagemSolicitacao } = this.props;

    findMensagemSolicitacao();
  }

  getValidatorData = () => {
    const {
      interessadoEditalNome,
      interessadoEditalCpfCnpj,
      interessadoEditalEmail
    } = this.refs;

    return {
      interessadoEditalNome: interessadoEditalNome.getValue(),
      interessadoEditalCpfCnpj: interessadoEditalCpfCnpj.getValue(),
      interessadoEditalEmail: interessadoEditalEmail.getValue()
    };
  };

  onSolicitacarNotificacao = () => {
    const {
      solicitarNotificacao,
      addSuccessNotification,
      location,
      validate,
      handleError
    } = this.props;

    validate(error => {
      if (handleError(this, error)) {
        return;
      }

      const entidade = getValueFromUrlSearchParams(location, 'entidade');
      const exercicio = getValueFromUrlSearchParams(location, 'exercicio');
      const tipoLicitacao = getValueFromUrlSearchParams(
        location,
        'tipoLicitacao'
      );
      const licitacao = getValueFromUrlSearchParams(location, 'licitacao');

      let interessadoEdital = {
        interessadoFornecedor: {
          cpfCnpj: this.refs.interessadoEditalCpfCnpj.getValue(),
          nome: this.refs.interessadoEditalNome.getValue(),
          email: this.refs.interessadoEditalEmail.getValue()
        },
        entidade: entidade,
        exercicio: exercicio,
        tipoLicitacao: tipoLicitacao,
        licitacao: licitacao
      };

      solicitarNotificacao(interessadoEdital).then(() =>
        addSuccessNotification('Os dados foram salvos com sucesso.')
      );
    });
  };

  render() {
    const {
      errors,
      mensagemSolicitacao,
      paramObrigarIdentificacao
    } = this.props;
    const mensagem = `Cadastre seu e-mail para receber todas as informações pertinentes a esse processo licitatório, como atas, avisos de revogação ou cancelamento.${
      paramObrigarIdentificacao !== 'S'
        ? ' Caso prefira baixar o edital sem o cadastramento não receberá as informações atualizadas via e-mail.'
        : ''
    }`;

    return (
      <Panel title="Solicitação de Notificação" isForm>
        <Row>
          <Col sm={12} className="mt-xs">
            <div style={{ fontSize: '11px', textAlign: 'justify' }}>
              {mensagemSolicitacao && mensagemSolicitacao.get('texto') !== ''
                ? mensagemSolicitacao.get('texto')
                : mensagem}
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Input
              label="Nome"
              name="interessadoEditalNome"
              type="text"
              ref="interessadoEditalNome"
              errors={errors}
              required
            />
          </Col>

          <Col sm={12}>
            <Input
              label="CPF/CNPJ"
              name="interessadoEditalCpfCnpj"
              type="text"
              ref="interessadoEditalCpfCnpj"
              errors={errors}
              required
            />
          </Col>

          <Col sm={7}>
            <Input
              label="E-mail"
              name="interessadoEditalEmail"
              type="email"
              ref="interessadoEditalEmail"
              errors={errors}
              required
            />
          </Col>
          <Col sm={5}>
            <div className="form-group">
              <label className="label"></label>
              <button
                id="btnSolicitar"
                className="btn positive icon-right"
                type="button"
                ref="solicitar"
                onClick={this.onSolicitacarNotificacao}
              >
                Solicitar
                <em className="fa fa-check"></em>
              </button>
            </div>
          </Col>
        </Row>
      </Panel>
    );
  }
}

function mapStateToProps(state) {
  return {
    licitacao: state.licitacao.get('licitacao'),
    mensagemSolicitacao: state.licitacao.get('mensagemSolicitacao')
  };
}

const allActions = {
  ...actions,
  addSuccessNotification,
  addErrorNotification,
  validaEmail,
  validaCpfCnpj
};

const validatorTypes = {
  rules: {
    interessadoEditalNome: 'required',
    interessadoEditalCpfCnpj: 'required|cnpj_cpf',
    interessadoEditalEmail: 'required|email'
  },
  labels: {
    interessadoEditalNome: 'Nome',
    interessadoEditalCpfCnpj: 'CNPJ/CPF',
    interessadoEditalEmail: 'E-mail'
  }
};

LicitacaoDetalheSolicitacao = Validation(validatorTypes)(
  LicitacaoDetalheSolicitacao
);

export default connect(
  mapStateToProps,
  allActions
)(LicitacaoDetalheSolicitacao);
