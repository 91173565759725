import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import NumberUtils from 'portaltransparencia-common/libs/NumberUtils.js';
import TableActions from 'portaltransparencia-common/components/TableActions.react';

export default class AdiantamentoDetalheAnexos extends Component {
  static propTypes = {
    anexos: PropTypes.object.isRequired,
    downloadArquivo: PropTypes.func.isRequired
  };

  downloadArquivo = arquivo => {
    const { downloadArquivo } = this.props;

    return () => downloadArquivo(arquivo.get('id'));
  };

  renderArquivos = anexo => {
    const arquivo = anexo.get('arquivo');
    const nomeArquivo = arquivo.get('nome') || 'Arquivo sem título';
    const tamanhoArquivo = NumberUtils.formatBytes(
      arquivo.get('tamanhoArquivo')
    );
    const key = arquivo.get('id') + '_' + arquivo.get('fullPath');

    return (
      <TableActions
        key={key}
        data={arquivo.get('id')}
        onDownload={this.downloadArquivo(arquivo)}
      >
        <td id="nome">{`${nomeArquivo} ${tamanhoArquivo}`}</td>
      </TableActions>
    );
  };

  render() {
    const { anexos } = this.props;

    return (
      <div>
        {anexos && (
          <div className="panel-table-outer">
            <table className="panel-table striped fancy">
              <TableHeader empty={anexos.isEmpty()} text="Não há arquivos.">
                <tr>
                  <th>Nome do Arquivo / Descrição</th>
                  <th></th>
                </tr>
              </TableHeader>
              <tbody>{anexos.map(this.renderArquivos)}</tbody>
            </table>
          </div>
        )}
      </div>
    );
  }
}
