import * as types from './Types.js';

export function findOrganograma(filter) {
  return ({ fetch }) => ({
    type: types.ORGANOGRAMA_LIST,
    payload: {
      promise: fetch(`/api/organograma?${filter}`)
    }
  });
}
