import { Map } from 'immutable';
import Input from 'portaltransparencia-common/components/EloInput.react.js';
import ExportButtonGroup from 'portaltransparencia-common/components/ExportButtonGroup.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import SearchPagination from 'portaltransparencia-common/components/SearchPagination.react.js';
import Tab from 'portaltransparencia-common/components/Tab.react.js';
import Tabs from 'portaltransparencia-common/components/Tabs.react.js';
import configuration from 'portaltransparencia-common/config/Config.js';
import {
  onChange,
  resetCrud
} from 'portaltransparencia-common/crud/Actions.js';
import {
  addFilterUrl,
  addPagination
} from 'portaltransparencia-common/libs/AddFilter.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import {
  PARAM_INTEGRADO_OXY_FOLHA_PAGAMENTO,
  PARAM_USAWEBSERVICE_MARINGA
} from 'portaltransparencia-common/parametros/ParametrosPortalTransparencia';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import PageHeader from '../components/PageHeader.react.js';
import NotasExplicativas from '../notaexplicativa/Form.react';
import ParametroService from '../parametros/ParametroService.js';
import UltimaAtualizacaoByTable from '../ultimaatualizacao/UltimaAtualizacaoByTable.react.js';
import UltimaAtualizacaoDataConsulta from '../ultimaatualizacao/UltimaAtualizacaoDataConsulta.react.js';
import * as actions from './Actions.js';
import EstagiarioTable from './EstagiarioTable.react.js';
import ResumoNaturezaTable from './ResumoNaturezaTable.react.js';
import ServidorTable from './ServidorTable.react.js';
import AutonomoTable from './AutonomoTable.react.js';
import { addErrorNotification } from 'portaltransparencia-common/ui/Actions.js';

export class FormServidor extends Component {
  static propTypes = {
    page: PropTypes.object.isRequired,
    estagiarios: PropTypes.object.isRequired,
    searchServidores: PropTypes.func.isRequired,
    searchEstagiarios: PropTypes.func.isRequired,
    loadNaturezas: PropTypes.func.isRequired,
    naturezas: PropTypes.object.isRequired,
    loadSituacoes: PropTypes.func.isRequired,
    situacoes: PropTypes.object.isRequired,
    filtros: PropTypes.object,
    onChange: PropTypes.func,
    paramWebService: PropTypes.string,
    location: PropTypes.object.isRequired,
    resetCrud: PropTypes.func.isRequired,
    resumoNatureza: PropTypes.object.isRequired,
    loadNaturezasAposentadosPensionistas: PropTypes.func.isRequired,
    loadResumoNatureza: PropTypes.func.isRequired,
    searchServidoresAposentados: PropTypes.func.isRequired,
    paramIntegradoOxyFolha: PropTypes.string,
    autonomos: PropTypes.object.isRequired,
    searchAutonomos: PropTypes.func.isRequired,
    addErrorNotification: PropTypes.func.isRequired
  };

  componentDidMount() {
    const { onChange } = this.props;

    onChange('filtroServidores', {
      name: 'estagioVigente',
      value: true
    });

    const mesAtual = new Date().getMonth();

    if (onChange) {
      onChange('filtroServidores', {
        name: 'mesCompetencia',
        value: mesAtual + 1
      });
    }

    this.loadFormServidores(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.pathname !== this.props.location.pathname) {
      this.loadFormServidores(nextProps);
    }
  }

  loadFormServidores = props => {
    const {
      location,
      loadNaturezas,
      loadSituacoes,
      loadResumoNatureza,
      loadNaturezasAposentadosPensionistas,
      page,
      paramWebService,
      estagiarios,
      autonomos
    } = props;

    if (paramWebService !== '1') {
      if (this.isTelaAposentados(location.pathname)) {
        loadNaturezasAposentadosPensionistas();
      } else {
        loadNaturezas();
      }
      loadResumoNatureza(this.isTelaServidoresCovid());
    }
    this.searchWithPage(page);
    this.searchEstagiarioWithPage(estagiarios);
    this.searchAutonomoWithPage(autonomos);
    loadSituacoes();
  };

  onChangeFiltro = event => {
    const { onChange } = this.props;

    onChange('filtroServidores', event);
  };

  onConsultaServidores = event => {
    const { page, estagiarios, autonomos } = this.props;
    const initialPage = page.set('number', 0);
    const initialPageEstagiario = estagiarios.set('number', 0);
    const initialPageAutonomo = autonomos.set('number', 0);

    this.searchWithPage(initialPage);
    this.searchEstagiarioWithPage(initialPageEstagiario);
    this.searchAutonomoWithPage(initialPageAutonomo);
    event && event.preventDefault();
    this.refs.panelFiltroServidores &&
      this.refs.panelFiltroServidores.collapse();
  };

  onResetFiltro = () => {
    const {
      resetCrud,
      searchServidores,
      searchEstagiarios,
      searchServidoresAposentados,
      location,
      searchAutonomos
    } = this.props;
    resetCrud('filtroServidores');

    if (this.isTelaAposentados(location.pathname)) {
      searchServidoresAposentados('');
    } else {
      searchServidores('');
    }
    searchEstagiarios('');
    searchAutonomos('');
  };

  montaFilter = function (page) {
    const { filtros } = this.props;
    const { entidade, exercicio } = StorageService.getItem('userInfo');

    let filter = `entidade=${entidade.id}&exercicio=${exercicio.id}`;

    if (filtros.get('nomeServidor')) {
      filter = addFilterUrl(
        filter,
        `nomeServidor=${filtros.get('nomeServidor')}`
      );
    }
    if (filtros.get('situacao')) {
      filter = addFilterUrl(filter, `situacao=${filtros.get('situacao')}`);
    }
    if (filtros.get('cargo')) {
      filter = addFilterUrl(filter, `cargo=${filtros.get('cargo')}`);
    }
    if (filtros.get('natureza')) {
      filter = addFilterUrl(filter, `natureza=${filtros.get('natureza')}`);
    }
    if (filtros.get('lotacao')) {
      filter = addFilterUrl(filter, `lotacao=${filtros.get('lotacao')}`);
    }
    if (filtros.get('classe')) {
      filter = addFilterUrl(filter, `classe=${filtros.get('classe')}`);
    }
    if (filtros.get('vinculo')) {
      filter = addFilterUrl(filter, `vinculo=${filtros.get('vinculo')}`);
    }
    if (filtros.get('valorSalarioInicial')) {
      filter = addFilterUrl(
        filter,
        `valorSalarioInicial=${filtros.get('valorSalarioInicial')}`
      );
    }
    if (filtros.get('valorSalarioFinal')) {
      filter = addFilterUrl(
        filter,
        `valorSalarioFinal=${filtros.get('valorSalarioFinal')}`
      );
    }
    if (filtros.get('cpf')) {
      filter = addFilterUrl(filter, `cpf=${filtros.get('cpf')}`);
    }
    if (filtros.get('mesCompetencia')) {
      filter = addFilterUrl(
        filter,
        `mesCompetencia=${filtros.get('mesCompetencia')}`
      );
    }

    filter = addFilterUrl(
      filter,
      `estagioVigente=${
        !this.refs.estagioVigente || this.refs.estagioVigente.getChecked()
      }`
    );

    filter = addFilterUrl(
      filter,
      `admissaoExcepcional=${this.isTelaServidoresCovid()}`
    );

    return addPagination(page, filter);
  };

  searchWithPage = (page, props) => {
    const { searchServidores, searchServidoresAposentados, location } =
      props || this.props;

    if (location.pathname && location.pathname.includes('/aposentados')) {
      searchServidoresAposentados(this.montaFilter(page));
    } else {
      searchServidores(this.montaFilter(page));
    }
  };

  searchEstagiarioWithPage = (page, props) => {
    const { searchEstagiarios } = props || this.props;

    searchEstagiarios(this.montaFilter(page));
  };

  searchAutonomoWithPage = (page, props) => {
    const { searchAutonomos } = props || this.props;

    searchAutonomos(this.montaFilter(page));
  };

  isTelaAposentados = pathname => {
    return pathname && pathname.includes('/aposentados');
  };

  isTelaServidoresCovid = () => {
    const { location } = this.props;
    return location.pathname && location.pathname.includes('servidores-covid');
  };

  isTelaEstagiarios = () => {
    const { location } = this.props;
    return location.pathname && location.pathname.includes('/estagiarios');
  };

  isTelaAutonomos = () => {
    const { location } = this.props;
    return location.pathname && location.pathname.includes('/autonomos');
  };

  onExporta = (exportType, openTab, windowParams) => {
    const { page, addErrorNotification } = this.props;
    const { apiURL } = configuration;

    if (page.get('content').size > 0) {
      let filtro = this.montaFilter(page) + '&exportType=' + exportType;
      let isAposentados = '';
      let endPoint =
        this.refs.tab && this.refs.tab.getActiveTabName() == 'servidores'
          ? 'servidores'
          : 'estagiarios';

      if (this.refs.tab && this.refs.tab.getActiveTabName() == 'autonomos') {
        endPoint = 'autonomos';
      }

      if (this.isTelaAposentados(window.location.pathname)) {
        isAposentados = '/aposentados';
      }

      window.open(
        `${apiURL}/api/${endPoint}${isAposentados}/report?${filtro}`,
        openTab,
        windowParams
      );
    } else {
      addErrorNotification(
        'A consulta não apresentou resultado. Não é possível exportar para o formato selecionado!'
      );
    }
  };

  renderNaturezas = data => {
    return (
      <option key={data.get('definicao')} value={data.get('definicao')}>
        {data.get('descricao')}
      </option>
    );
  };

  renderSituacoes = data => {
    return (
      <option key={data} value={data}>
        {data}
      </option>
    );
  };

  usaWebService = () => {
    return this.props.paramWebService == '1';
  };

  getOptions = data => {
    const { exercicio } = StorageService.getItem('userInfo');
    return (
      <option value={data + 1}>
        {DateUtils.getMonthDescription(data + 1)} / {exercicio.id}
      </option>
    );
  };

  renderUltimaAtualizacao = () => {
    const { paramIntegradoOxyFolha } = this.props;
    if (!this.usaWebService() && paramIntegradoOxyFolha !== 'S') {
      return (
        <Col xs={6}>
          {!this.usaWebService() && (
            <UltimaAtualizacaoByTable
              modulo="1"
              table="rhfechamentocontabil"
              column="data"
              filtrarEntidade
              columnOxy=""
            />
          )}
        </Col>
      );
    } else {
      if (paramIntegradoOxyFolha === 'S') {
        return (
          <Col xs={6}>
            <UltimaAtualizacaoDataConsulta />
          </Col>
        );
      }
    }
  };

  render() {
    const {
      page,
      estagiarios,
      naturezas,
      situacoes,
      filtros,
      resumoNatureza,
      location,
      paramIntegradoOxyFolha,
      autonomos
    } = this.props;
    const { exercicio } = StorageService.getItem('userInfo');

    if (!page.has('content')) {
      return null;
    }

    let dataAtual = new Date();
    let meses = 11;

    if (dataAtual.getFullYear() == exercicio.id) {
      meses = dataAtual.getMonth();
    }

    let arrayMonths = [];
    for (var i = 0; i <= meses; i++) {
      arrayMonths.push(i);
    }

    return (
      <section>
        <PageHeader location={this.props.location} />

        <NotasExplicativas pathname={location.pathname} />

        <Panel
          title="Consultar em Servidores"
          mobileClose
          isForm
          ref="panelFiltroServidores"
        >
          <form>
            <Row>
              <Col sm={4} md={4}>
                <Input
                  id="nomeServidor"
                  placeholder="Informe o nome do servidor"
                  name="nomeServidor"
                  ref="nomeServidor"
                  label="Nome do Servidor"
                  type="text"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('nomeServidor')}
                />
              </Col>
              <Col sm={4} md={4}>
                <Input
                  id="situacao"
                  name="situacao"
                  ref="situacao"
                  label="Situação"
                  type="select"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('situacao')}
                >
                  <option value="">Selecione</option>
                  {situacoes.map(this.renderSituacoes)}
                </Input>
              </Col>
              <Col sm={4} md={4}>
                <Input
                  id="cargo"
                  placeholder="Informe o cargo"
                  type="text"
                  ref="cargo"
                  name="cargo"
                  label="Cargo"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('cargo')}
                />
              </Col>
              <Col sm={4} md={4}>
                <Input
                  id="lotacao"
                  placeholder="Informe a lotação"
                  type="text"
                  ref="lotacao"
                  name="lotacao"
                  label="Lotação"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('lotacao')}
                />
              </Col>
              {this.usaWebService() && (
                <Col sm={4} md={4}>
                  <Input
                    id="vinculo"
                    placeholder="Informe o vínculo"
                    type="text"
                    ref="vinculo"
                    name="vinculo"
                    label="Vínculo"
                    onChange={this.onChangeFiltro}
                    value={filtros.get('vinculo')}
                  />
                </Col>
              )}
              {!this.usaWebService() && (
                <Col sm={4} md={4}>
                  <Input
                    id="classe"
                    placeholder="Informe a classe"
                    type="text"
                    ref="classe"
                    name="classe"
                    label="Classe"
                    onChange={this.onChangeFiltro}
                    value={filtros.get('classe')}
                  />
                </Col>
              )}
              {!this.usaWebService() && (
                <Col xs={12} sm={5} md={4}>
                  <Input
                    id="natureza"
                    name="natureza"
                    ref="natureza"
                    label="Natureza"
                    type="select"
                    onChange={this.onChangeFiltro}
                    value={filtros.get('natureza')}
                  >
                    <option value="">Selecione</option>
                    {naturezas.map(this.renderNaturezas)}
                  </Input>
                </Col>
              )}
              {!this.usaWebService() && (
                <Col xs={6} sm={6} md={3}>
                  <Input
                    id="valorSalarioInicial"
                    type="number"
                    ref="valorSalarioInicial"
                    name="valorSalarioInicial"
                    placeholder="Salário maior que"
                    label="Salário maior que"
                    onChange={this.onChangeFiltro}
                    value={filtros.get('valorSalarioInicial')}
                  />
                </Col>
              )}
              {!this.usaWebService() && (
                <Col xs={6} sm={6} md={3}>
                  <Input
                    id="valorSalarioFinal"
                    type="number"
                    ref="valorSalarioFinal"
                    name="valorSalarioFinal"
                    placeholder="Salário menor que"
                    label="Salário menor que"
                    onChange={this.onChangeFiltro}
                    value={filtros.get('valorSalarioFinal')}
                  />
                </Col>
              )}
              {!this.usaWebService() && (
                <Col xs={6} sm={6} md={3}>
                  <Input
                    id="cpf"
                    type="text"
                    ref="cpf"
                    name="cpf"
                    placeholder="Informe o CPF"
                    label="CPF"
                    onChange={this.onChangeFiltro}
                    value={filtros.get('cpf')}
                  />
                </Col>
              )}
              {!this.usaWebService() && !this.isTelaServidoresCovid() && (
                <Col xs={6} sm={6} md={3}>
                  <div className="form-group">
                    <label className="label"></label>
                    <Input
                      id="estagioVigente"
                      label="Somente estágios vigentes"
                      type="checkbox"
                      name="estagioVigente"
                      ref="estagioVigente"
                      onChange={this.onChangeFiltro}
                      checked={filtros.get('estagioVigente')}
                    />
                  </div>
                </Col>
              )}
              {!this.usaWebService() && paramIntegradoOxyFolha == 'S' && (
                <Col xs={12} sm={12} md={12}>
                  <Input
                    id="mesCompetencia"
                    name="mesCompetencia"
                    ref="mesCompetencia"
                    label="Mês / Exercício"
                    type="select"
                    onChange={this.onChangeFiltro}
                    value={filtros.get('mesCompetencia')}
                  >
                    <option value="">Exercicio / {exercicio.id}</option>
                    {arrayMonths && arrayMonths.map(this.getOptions)}
                  </Input>
                </Col>
              )}
              <Col xs={12} sm={12} md={12}>
                <div className="form-group">
                  <label className="label" />

                  <div className="btn-group">
                    <button
                      id="btnFiltrar"
                      className="btn module-color icon-right"
                      ref="filtrar"
                      onClick={this.onConsultaServidores}
                    >
                      Pesquisar
                      <em className="fa fa-search" />
                    </button>
                    <button
                      id="btnReset"
                      className="btn neutral icon-right inline no-text"
                      type="button"
                      onClick={this.onResetFiltro}
                    >
                      Limpar
                      <em className="fa fa-times" />
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </form>
        </Panel>

        <Panel isForm tableResponsive expansible={false}>
          <Row>
            {this.renderUltimaAtualizacao()}
            <Col xs={6}>
              <ExportButtonGroup onClick={this.onExporta} visible={true} />
            </Col>
          </Row>

          <Tabs ref="tab">
            <Tab title="Servidores" name="servidores" onlyTable active>
              <ServidorTable
                usaWebService={this.usaWebService()}
                page={page}
                history={this.props.history}
                searchWithPage={this.searchWithPage}
              />
              <SearchPagination
                page={page}
                searchWithPage={this.searchWithPage}
              />
            </Tab>

            {!this.isTelaServidoresCovid() && (
              <Tab
                title="Estagiários"
                name="estagiarios"
                id="tabEstagiarios"
                onlyTable
              >
                <EstagiarioTable
                  usaWebService={this.usaWebService()}
                  page={estagiarios}
                  history={this.props.history}
                  searchWithPage={this.searchEstagiarioWithPage}
                />
                <SearchPagination
                  page={estagiarios}
                  searchWithPage={this.searchEstagiarioWithPage}
                />
              </Tab>
            )}

            {!this.usaWebService() && !this.isTelaServidoresCovid() && (
              <Tab
                title="Autônomos"
                name="autonomos"
                id="tabAutonomos"
                onlyTable
              >
                <AutonomoTable
                  page={autonomos}
                  history={this.props.history}
                  searchWithPage={this.searchAutonomoWithPage}
                />
                <SearchPagination
                  page={autonomos}
                  searchWithPage={this.searchAutonomoWithPage}
                />
              </Tab>
            )}

            {!this.usaWebService() && (
              <Tab
                title="Totais de Servidores Ativos"
                name="totaisNatureza"
                onlyTable
                hide={this.isTelaAposentados(location.pathname)}
              >
                <ResumoNaturezaTable resumosNatureza={resumoNatureza} />
              </Tab>
            )}
          </Tabs>
        </Panel>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    page: state.servidor.get('servidores'),
    estagiarios: state.servidor.get('estagiarios'),
    naturezas: state.servidor.get('naturezas'),
    situacoes: state.servidor.get('situacoes'),
    paramWebService: ParametroService.getValorParametro(
      state,
      PARAM_USAWEBSERVICE_MARINGA
    ),
    filtros: state.crud.getIn(['filtroServidores', 'currentRecord'], Map({})),
    resumoNatureza: state.servidor.get('resumoNatureza'),
    paramIntegradoOxyFolha: ParametroService.getValorParametro(
      state,
      PARAM_INTEGRADO_OXY_FOLHA_PAGAMENTO
    ),
    autonomos: state.servidor.get('autonomos')
  };
}

const allActions = {
  ...actions,
  onChange,
  resetCrud,
  addErrorNotification
};

export default connect(mapStateToProps, allActions)(FormServidor);
