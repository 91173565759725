import Hint from 'portaltransparencia-common/components/hint/Hint.react';
import Widget from 'portaltransparencia-common/components/Widget.react.js';
import { format } from 'portaltransparencia-common/libs/NumberUtils';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';

export class ReceitaAno extends Component {
  static propTypes = {
    valorResultadoReceitaMenosEmpenhado: PropTypes.number.isRequired,
    receitaValorAtualizado: PropTypes.number.isRequired,
    valorArrecadado: PropTypes.number.isRequired,
    exercicio: PropTypes.any.isRequired,
    paramDespesasEntidadesDiferentes: PropTypes.string,
    paramReceitasEntidadesDiferentes: PropTypes.string,
    pathname: PropTypes.string
  };

  render() {
    const {
      valorResultadoReceitaMenosEmpenhado,
      receitaValorAtualizado,
      valorArrecadado,
      exercicio,
      paramDespesasEntidadesDiferentes,
      paramReceitasEntidadesDiferentes,
      pathname
    } = this.props;

    const tipoInformacao = pathname == '/receitaxdespesa-fundeb' ? 'F' : 'C';

    if (tipoInformacao == 'F') {
      var title =
        `Valores de receita e despesa específicas para demandas do FUNDEB no ano de ` +
        exercicio;
    } else {
      var title =
        paramDespesasEntidadesDiferentes == 'S' &&
        paramReceitasEntidadesDiferentes == 'S'
          ? `Valores consolidados de receita e despesa específicas para demandas do COVID-19`
          : `Valores de receita e despesa específicas para demandas do COVID-19 no ano de ` +
            exercicio;
    }

    return (
      <Widget
        icon="fa fa-line-chart"
        title={title}
        showHint={
          paramDespesasEntidadesDiferentes == 'S' &&
          paramReceitasEntidadesDiferentes == 'S'
        }
        messageHint={
          'Os valores demonstrados correspondem às receitas e despesas de todas as entidades e exercícios realizadas no combate à pandemia do coronavírus.'
        }
      >
        <Row>
          <Col sm={4} md={4}>
            <article className="widget-numeric block">
              <b className="widget-numeric-number">
                {valorArrecadado && format(valorArrecadado)}
              </b>
              <div className="widget-numeric-subtitle">
                <Hint
                  message={
                    (tipoInformacao == 'F'
                      ? `Soma dos valores arrecadados especificamente para demandas do FUNDEB de todos os meses `
                      : `Soma dos valores arrecadados especificamente para demandas do COVID-19 de todos os meses `) +
                    (paramDespesasEntidadesDiferentes == 'S' &&
                    paramReceitasEntidadesDiferentes == 'S'
                      ? ''
                      : `de ${exercicio}.`)
                  }
                  size="xs"
                  position="bottom-left"
                  spacing="mr-xs"
                  isInline
                />
                Valor Arrecadado
              </div>
            </article>
          </Col>
          <Col sm={4} md={4}>
            <article className="widget-numeric block">
              <b className="widget-numeric-number">
                {receitaValorAtualizado && format(receitaValorAtualizado)}
              </b>
              <div className="widget-numeric-subtitle">
                <Hint
                  message={
                    (tipoInformacao == 'F'
                      ? `Soma das despesas empenhadas especificamente para demandas do FUNDEB de todos os meses `
                      : `Soma das despesas empenhadas especificamente para demandas do COVID-19 de todos os meses `) +
                    (paramDespesasEntidadesDiferentes == 'S' &&
                    paramReceitasEntidadesDiferentes == 'S'
                      ? ''
                      : `de ${exercicio}.`)
                  }
                  size="xs"
                  position="bottom-left"
                  spacing="mr-xs"
                  isInline
                />
                Valor Empenhado
              </div>
            </article>
          </Col>
          <Col sm={4} md={4}>
            <article className="widget-numeric block">
              <b className="widget-numeric-number">
                {valorResultadoReceitaMenosEmpenhado &&
                  format(valorResultadoReceitaMenosEmpenhado)}
              </b>
              <div className="widget-numeric-subtitle">Resultado</div>
            </article>
          </Col>
        </Row>
      </Widget>
    );
  }
}

export default ReceitaAno;
