import * as actions from './Actions.js';

import {
  PARAM_EXIBE_OBRAS_NUMERO_SEI,
  PARAM_PLANILHASOBRAS
} from 'portaltransparencia-common/parametros/ParametrosPortalTransparencia';
import {
  findEmpenhoLiquidacoesDocumentosMultiplos,
  findEmpenhoLiquidacoesMultipla,
  resetLiquidacoesDocumentosMultiplos,
  resetLiquidacoesMultipla
} from '../empenhos/Actions.js';

import BotaoImprimir from 'portaltransparencia-common/components/BotaoImprimir.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import Tab from 'portaltransparencia-common/components/Tab.react.js';
import Tabs from 'portaltransparencia-common/components/Tabs.react.js';
import { getValueFromUrlSearchParams } from 'portaltransparencia-common/libs/URLUtils';
import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';
import { downloadArquivo } from '../arquivo/Actions.js';
import PageHeader from '../components/PageHeader.react.js';
import EmpenhoDetalheLiquidacoes from '../empenhos/FormDetalheLiquidacao.react.js';
import EmpenhoDetalheLiquidacoesDocumentos from '../empenhos/FormDetalheLiquidacaoDocumentos.react.js';
import CabecalhoEntidade from '../entidades/CabecalhoEntidade.react.js';
import ParametroService from '../parametros/ParametroService.js';
import { ObraDetalheEmpenho } from './ObraDetalheEmpenho.react.js';
import ObraDetalheInformacao from './ObraDetalheInformacao.react.js';
import ObraDetalheMedicao from './ObraDetalheMedicao.react.js';
import ObraDetalhePlanilha from './ObraDetalhePlanilha.react.js';
import ObraFonteRecurso from './ObraFonteRecurso.react.js';
import ObraOrdemServicoArquivo from './ObraOrdemServicoArquivo.react.js';
import ObraDetalheResponsavel from './ObraDetalheResponsavel.react.js';

export class ObraDetalhe extends Component {
  static propTypes = {
    obra: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    findObra: PropTypes.func.isRequired,
    medicoes: PropTypes.object.isRequired,
    empenhos: PropTypes.object.isRequired,
    findMedicoes: PropTypes.func.isRequired,
    downloadArquivo: PropTypes.func.isRequired,
    planilhas: PropTypes.object.isRequired,
    findPlanilhas: PropTypes.func.isRequired,
    findEmpenhos: PropTypes.func.isRequired,
    medicaoArquivos: PropTypes.object.isRequired,
    findArquivosMedicao: PropTypes.func.isRequired,
    fontesRecurso: PropTypes.object.isRequired,
    findFontesRecursos: PropTypes.func.isRequired,
    responsaveis: PropTypes.object,
    findResponsaveis: PropTypes.func,
    paramPlanilhas: PropTypes.string,
    history: PropTypes.object,
    configGeral: PropTypes.object,
    paramExibeNumeroSEI: PropTypes.string
  };

  componentDidMount() {
    const {
      findObra,
      location,
      findMedicoes,
      findPlanilhas,
      findEmpenhos,
      findOrdemArquivos,
      findEmpenhoLiquidacoesMultipla,
      findFontesRecursos,
      findResponsaveis,
      resetLiquidacoesMultipla,
      findEmpenhoLiquidacoesDocumentosMultiplos,
      resetLiquidacoesDocumentosMultiplos,
      configGeral,
      responsaveis
    } = this.props;

    const entidade = getValueFromUrlSearchParams(location, 'entidade');
    const exercicioObra = getValueFromUrlSearchParams(
      location,
      'exercicioObra'
    );
    const idOrdemServico = getValueFromUrlSearchParams(
      location,
      'idOrdemServico'
    );
    const idOxy = getValueFromUrlSearchParams(location, 'idOxy');

    resetLiquidacoesMultipla();
    resetLiquidacoesDocumentosMultiplos();
    findObra(entidade, exercicioObra, idOrdemServico, idOxy).then(
      ({ value }) => {
        findMedicoes(
          value.entidade,
          value.exercicio,
          value.idOrdemServico,
          value.idOxy
        );
        findOrdemArquivos(
          value.entidade,
          value.exercicio,
          value.idOrdemServico,
          value.idOxy
        );
        findPlanilhas(
          value.entidade,
          value.exercicio,
          value.idOrdemServico,
          value.idOxy
        );
        findEmpenhos(
          value.entidade,
          value.exercicio,
          value.idOrdemServico,
          value.idOxy
        ).then(({ value: valueEmpenho }) => {
          valueEmpenho.map(empenho => {
            const filtro = `?search=id.entidade==${value.entidade}&entidade=${value.entidade}&exercicio=${value.exercicio}&empenho=${empenho.numeroEmpenho}`;
            findEmpenhoLiquidacoesMultipla(filtro);
            findEmpenhoLiquidacoesDocumentosMultiplos(filtro);
          });
        });
        findFontesRecursos(
          value.idOxy,
          value.entidade,
          value.exercicio,
          value.idOrdemServico
        );
        if (configGeral.get('isIntegradoOxy')) {
          findResponsaveis(value.idOxy).then(
            console.log('responsaveis: ', responsaveis)
          );
        }
      }
    );
  }
  buildFilterEmpenho(obra) {
    const entidade = obra.get('entidade');
    const exercicioObra = obra.get('exercicio');
    const idOrdemObra = obra.get('idOrdembuildFilterEmpenhoServico');
    const idOxy = obra.get('idOxy');
    if (idOxy) return `?search=idOxy==${idOxy}`;
    if (!entidade) return '';
    const filter = `?search=id.entidade==${entidade} and exercicioObra==${exercicioObra} and idOrdemObra==${idOrdemObra}`;
    return filter;
  }

  getValorEmpenhado = empenhos => {
    let valorAnulacoes = 0;
    let valorEstornoAnulacoes = 0;
    let valorEmpenhado = 0;

    if (empenhos) {
      empenhos.map(empenho => {
        valorAnulacoes = empenho.get('valorAnulacoes')
          ? empenho.get('valorAnulacoes')
          : 0;
        valorEstornoAnulacoes = empenho.get('valorEstornoAnulacoes')
          ? empenho.get('valorEstornoAnulacoes')
          : 0;
        valorEmpenhado +=
          empenho.get('valor') - (valorAnulacoes - valorEstornoAnulacoes);
      });
    }

    return valorEmpenhado;
  };

  render() {
    const {
      obra,
      medicoes,
      downloadArquivo,
      planilhas,
      location,
      medicaoArquivos,
      findArquivosMedicao,
      paramPlanilhas,
      empenhos,
      history,
      configGeral,
      ordemArquivos,
      paramExibeNumeroSEI,
      fontesRecurso,
      responsaveis
    } = this.props;

    const descricaoObra =
      obra.get('descricaoBem') ||
      `${obra.get('ordemServico')}/${obra.get('exercicio')}`;

    return (
      <div>
        <Helmet title={`Obras Públicas - ${descricaoObra}`} />

        <PageHeader
          location={location}
          textoDetalhe={`Obras Públicas - ${descricaoObra}`}
        />

        <BotaoImprimir />

        <CabecalhoEntidade />

        <ObraDetalheInformacao
          entidade={getValueFromUrlSearchParams(location, 'entidade')}
          exercicioObra={getValueFromUrlSearchParams(location, 'exercicioObra')}
          idOrdemServico={getValueFromUrlSearchParams(
            location,
            'idOrdemServico'
          )}
          idOxy={getValueFromUrlSearchParams(location, 'idOxy')}
          valorEmpenhado={this.getValorEmpenhado(empenhos)}
          isIntegradoOxy={configGeral.get('isIntegradoOxy')}
          paramExibeNumeroSEI={paramExibeNumeroSEI}
        />

        <Panel expansible={false} isTable tableResponsive>
          <Tabs scrollVisibled>
            <Tab title="Medições" active>
              <ObraDetalheMedicao
                medicoes={medicoes}
                onDownload={downloadArquivo}
                location={location}
                medicaoArquivos={medicaoArquivos}
                findArquivosMedicao={findArquivosMedicao}
              />
            </Tab>

            <Tab title="Empenhos">
              <h4 className="mt-xs mb-xs center">
                <b>Empenhos</b>
              </h4>
              <ObraDetalheEmpenho
                empenhos={empenhos}
                entidade={obra.get('entidade')}
                location={location}
                history={history}
              />
            </Tab>
            <Tab title="Planilhas" hide={paramPlanilhas !== 'S'}>
              <ObraDetalhePlanilha
                planilhas={planilhas}
                onDownload={downloadArquivo}
              />
            </Tab>
            <Tab title="Anexos/Cronogramas">
              <ObraOrdemServicoArquivo ordemArquivos={ordemArquivos} />
            </Tab>
            <Tab title="Fontes recurso">
              <h4 className="mt-xs mb-xs center">
                <b>Fontes recurso</b>
              </h4>
              <ObraFonteRecurso
                fontesRecurso={fontesRecurso}
                empenhos={empenhos}
                isIntegradoOxy={configGeral.get('isIntegradoOxy')}
              />
            </Tab>

            <Tab title="Liquidações">
              <EmpenhoDetalheLiquidacoes exibeEmpenho={true} />
            </Tab>

            <Tab title="Documentos Liquidação">
              <EmpenhoDetalheLiquidacoesDocumentos exibeEmpenho={true} />
            </Tab>
            {configGeral.get('isIntegradoOxy') && (
              <Tab title="Responsáveis">
                <ObraDetalheResponsavel responsaveis={responsaveis} />
              </Tab>
            )}
          </Tabs>
        </Panel>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    obra: state.obra.get('obra'),
    medicoes: state.obra.get('medicoes'),
    planilhas: state.obra.get('planilhas'),
    ordemArquivos: state.obra.get('ordemArquivos'),
    medicaoArquivos: state.obra.get('medicaoArquivos'),
    empenhos: state.obra.get('empenhos'),
    fontesRecurso: state.obra.get('fontesRecurso'),
    responsaveis: state.obra.get('responsaveis'),
    configGeral: state.entidade.get('configuracoesGerais'),
    paramPlanilhas: ParametroService.getValorParametro(
      state,
      PARAM_PLANILHASOBRAS
    ),
    paramExibeNumeroSEI: ParametroService.getValorParametro(
      state,
      PARAM_EXIBE_OBRAS_NUMERO_SEI
    )
  };
}

const allActions = {
  ...actions,
  findEmpenhoLiquidacoesMultipla,
  resetLiquidacoesMultipla,
  findEmpenhoLiquidacoesDocumentosMultiplos,
  resetLiquidacoesDocumentosMultiplos,
  downloadArquivo
};

export default connect(mapStateToProps, allActions)(ObraDetalhe);
