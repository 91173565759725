import DisplayData from 'portaltransparencia-common/components/displaydata/DisplayData.react';
import DisplayDataItem from 'portaltransparencia-common/components/displaydata/DisplayDataItem.react';
import DisplayDataRow from 'portaltransparencia-common/components/displaydata/DisplayDataRow.react';
import Modal from 'portaltransparencia-common/components/Modal.react.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils';
import PropTypes from 'prop-types';
import React from 'react';
import { Panel } from 'react-bootstrap';
import Component from 'react-pure-render/component';

export class LicitacaoInfoModal extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    licitacao: PropTypes.object.isRequired
  };

  render() {
    const { show, onClose, licitacao } = this.props;

    if (show) {
      return (
        <Modal onClose={onClose}>
          <div className="container">
            <Panel title="Licitação Original" expansible={false}>
              <DisplayData border column>
                <DisplayDataRow>
                  <DisplayDataItem
                    sm={12}
                    title="Entidade"
                    value={licitacao.get('entidadeInfOriginal')}
                    id="entidadeInfOriginal"
                  />
                </DisplayDataRow>
                <DisplayDataRow>
                  <DisplayDataItem
                    sm={12}
                    title="Modalidade"
                    value={licitacao.get('modalidadeInfOriginal')}
                    id="modalidadeInfOriginal"
                  />
                </DisplayDataRow>
                <DisplayDataRow>
                  <DisplayDataItem
                    sm={12}
                    title="Número"
                    value={licitacao.get('numeroInfOriginal')}
                    id="numeroInfOriginal"
                  />
                </DisplayDataRow>
                <DisplayDataRow>
                  <DisplayDataItem
                    sm={12}
                    title="Data"
                    value={DateUtils.formatDateTimeShort(
                      licitacao.get('dataInfOriginal')
                    )}
                    id="dataInfOriginal"
                  />
                </DisplayDataRow>
                <DisplayDataRow>
                  <DisplayDataItem
                    sm={12}
                    title="Data Publicação"
                    value={DateUtils.formatDateTimeShort(
                      licitacao.get('dataPubOriginal')
                    )}
                    id="dataPubOriginal"
                  />
                </DisplayDataRow>
                <DisplayDataRow>
                  <DisplayDataItem
                    sm={12}
                    title="Observação"
                    value={licitacao.get('obsInfOriginal')}
                    id="obsInfOriginal"
                  />
                </DisplayDataRow>
              </DisplayData>
            </Panel>
          </div>
        </Modal>
      );
    }

    return null;
  }
}

export default LicitacaoInfoModal;
