import Panel from 'portaltransparencia-common/components/Panel.react.js';
import SumListField from 'portaltransparencia-common/components/SumListField.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import DateUtils from 'portaltransparencia-common/libs/DateUtils';
import NumberUtils from 'portaltransparencia-common/libs/NumberUtils';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export class GastoPneuTable extends Component {
  static propTypes = {
    gastosPneu: PropTypes.object.isRequired
  };

  renderGastoPneu = gastosPneu => {
    const key = `${gastosPneu.get('numero')}_${gastosPneu.get(
      'data'
    )}_${gastosPneu.get('item')}`;

    return (
      <tr key={key}>
        <td>{gastosPneu.get('numero')}</td>
        <td>{DateUtils.transform(gastosPneu.get('data'))}</td>
        <td>{`${gastosPneu.get('centroDestino')} - ${gastosPneu.get(
          'centroDestinoDescricao'
        )}`}</td>
        <td>{gastosPneu.get('item')}</td>
        <td className="right">{gastosPneu.get('kmAnterior')}</td>
        <td className="right">{gastosPneu.get('kmAtual')}</td>
        <td className="right">{gastosPneu.get('mediaCombustivel')}</td>
        <td className="right">
          {NumberUtils.format(gastosPneu.get('quantidade'))}
        </td>
        <td className="right">{NumberUtils.format(gastosPneu.get('valor'))}</td>
        <td className="right">
          {NumberUtils.format(gastosPneu.get('valorTotal'))}
        </td>
      </tr>
    );
  };

  render() {
    const { gastosPneu } = this.props;

    return (
      <Panel title="Gastos com Pneu do Veículo" isTable>
        <div className="panel-table-outer">
          <table className="panel-table striped fancy">
            <TableHeader
              empty={gastosPneu.isEmpty()}
              texto="Nenhum gasto encontrado."
            >
              <tr>
                <th>Número</th>
                <th>Data</th>
                <th>Centro Consumo</th>
                <th>Ítem</th>
                <th className="right">Km Anterior</th>
                <th className="right">Km</th>
                <th className="right">Média</th>
                <th className="right">Qtde.</th>
                <th className="right">Valor</th>
                <th className="right">Valor Total</th>
              </tr>
            </TableHeader>
            <tbody>{gastosPneu.map(this.renderGastoPneu)}</tbody>
            {!gastosPneu.isEmpty() && (
              <tfoot>
                <tr>
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  <td className="right">
                    <i>Total: </i>
                    <SumListField fieldName="valorTotal" list={gastosPneu} />
                  </td>
                </tr>
              </tfoot>
            )}
          </table>
        </div>
      </Panel>
    );
  }
}

export default GastoPneuTable;
