import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';

import * as types from './Types.js';

const initialState = fromJS({
  cotasParlamentares: []
});

function cotasParlamentaresReducer(state = initialState, action) {
  switch (action.type) {
    case types.COTAS_PARLAMENTARES_START: {
      return startLoad(state);
    }

    case types.COTAS_PARLAMENTARES_ERROR: {
      return endLoad(state);
    }

    case types.COTAS_PARLAMENTARES_SUCCESS: {
      return endLoad(state.set('cotasParlamentaes', fromJS(action.payload)));
    }
  }

  return state;
}

export default cotasParlamentaresReducer;
