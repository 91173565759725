import Input from 'portaltransparencia-common/components/EloInput.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import { resetCrud } from 'portaltransparencia-common/crud/Actions.js';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';

import FilterButtons from '../components/FilterButtons.react';

export default class CreditosAdicionaisFiltro extends Component {
  static propTypes = {
    page: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    montaFiltro: PropTypes.func.isRequired,
    filtroCreditosAdicionais: PropTypes.string.isRequired,
    resetFiltro: PropTypes.func,
    onConsultaWithPage: PropTypes.func.isRequired,
    filtros: PropTypes.object
  };

  onChangeFiltro = event => {
    const { onChange, filtroCreditosAdicionais } = this.props;
    onChange(filtroCreditosAdicionais, event);
  };

  onResetFiltro = () => {
    const { filtroCreditosAdicionais } = this.props;
    resetCrud(filtroCreditosAdicionais);
    filtroCreditosAdicionais('');
  };

  onConsulta = event => {
    const { page, onConsultaWithPage } = this.props;
    onConsultaWithPage(page);
    event && event.preventDefault();
    this.refs.panelFiltroCreditosAdicionais &&
      this.refs.panelFiltroCreditosAdicionais.collapse();
  };

  render = () => {
    const { filtros } = this.props;
    return (
      <Panel
        title="Consultar Créditos Adicionais"
        isForm
        mobileClose
        ref="panelFiltroCreditosAdicionais"
      >
        <form>
          <Row>
            <Col xs={5} sm={5} md={4}>
              <Input
                name="despesa"
                ref="despesa"
                placeholder="Despesa"
                label="Despesa"
                type="text"
                id="despesa"
                onChange={this.onChangeFiltro}
                value={filtros.get('despesa')}
              />
            </Col>
            <Col xs={5} sm={5} md={2}>
              <Input
                name="decreto"
                ref="decreto"
                placeholder="Ex: 1/2015"
                label="Decreto"
                type="text"
                id="decreto"
                onChange={this.onChangeFiltro}
                value={filtros.get('decreto')}
              />
            </Col>
            <Col xs={12} sm={4} md={3}>
              <FilterButtons
                onConsulta={this.onConsulta}
                onReset={this.onResetFiltro}
              />
            </Col>
          </Row>
        </form>
      </Panel>
    );
  };
}
