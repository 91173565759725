import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export class RecursoDetalhePessoa extends Component {
  static propTypes = {
    recursoPessoas: PropTypes.any.isRequired
  };

  renderPessoas = data => {
    return (
      <TableActions ref="row" key={data.get('id')} data={data}>
        <td>{data.get('cnpjCpf')}</td>
        <td>{data.get('nomePessoa')}</td>
      </TableActions>
    );
  };

  render() {
    const { recursoPessoas } = this.props;

    return (
      <div className="panel-table-outer">
        <table className="panel-table striped fancy">
          <TableHeader empty={recursoPessoas.isEmpty()}>
            <tr>
              <th>Documento</th>
              <th>Nome</th>
            </tr>
          </TableHeader>
          <tbody>{recursoPessoas.map(this.renderPessoas)}</tbody>
        </table>
      </div>
    );
  }
}

export default RecursoDetalhePessoa;
