export function hasValidation(field) {
  return !!(field.min || field.max || field.required);
}

export function parseMetaModel(metaModel) {
  let rules = {};
  let labels = {};

  for (let fieldName in metaModel.fields) {
    let field = metaModel.fields[fieldName];

    if (hasValidation(field)) {
      let values = [];

      if (field.required) {
        values.push('required');
      }
      if (field.max) {
        values.push(`max:${field.max}`);
      }
      if (field.min) {
        values.push(`min:${field.min}`);
      }

      rules[fieldName] = values.join('|');
      labels[fieldName] = field.caption;
    }
  }

  return {
    rules: rules,
    labels: labels
  };
}
