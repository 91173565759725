import * as actionsTypes from './Types.js';

let nextId = 1;

export function throwError(error) {
  return {
    type: actionsTypes.THROW_ERROR,
    error: error
  };
}

export function addNotification(notification) {
  return {
    type: actionsTypes.ADD_NOTIFICATION,
    payload: { ...notification, uid: nextId++ }
  };
}

export function addSuccessNotification(message) {
  return addNotification({
    title: 'Informação',
    message: message,
    level: 'success',
    autoDismiss: 10
  });
}

export function addErrorNotification(message, error) {
  let msgs = null;

  if (error && error.violations && error.violations.length > 0) {
    msgs = error.violations.map(violation => {
      return `${violation.field}: ${violation.message} `;
    });
  }
  if (!msgs) {
    msgs = message;
  }

  return addNotification({
    title: 'Erro',
    message: msgs,
    level: 'error',
    autoDismiss: 10,
    error: error,
    modal: true
  });
}

export function addAlertNotification(title, message, time) {
  return addNotification({
    title: title,
    message: message,
    level: 'success',
    autoDismiss: time,
    dismissible: 'click',
    action: {
      label: 'Ok'
    }
  });
}

export function removeNotification(notification) {
  return {
    type: actionsTypes.REMOVE_NOTIFICATION,
    payload: notification
  };
}

export function removeLoading() {
  return {
    type: actionsTypes.REMOVE_LOADING
  };
}

export function sidebarToggle() {
  return {
    type: actionsTypes.SIDEBAR_TOGGLE
  };
}

export function offsidebarModuloToggle() {
  return {
    type: actionsTypes.OFFSIDEBAR_TOGGLE_MODULO
  };
}

export function offsidebarUsuarioToggle() {
  return {
    type: actionsTypes.OFFSIDEBAR_TOGGLE_USUARIO
  };
}

export function offsidebarNotificacaoToggle() {
  return {
    type: actionsTypes.OFFSIDEBAR_TOGGLE_NOTIFICACAO
  };
}

export function offsidebarToggle() {
  return {
    type: actionsTypes.OFFSIDEBAR_TOGGLE
  };
}

export function setInMaintance() {
  return {
    type: actionsTypes.MAINTANCE
  };
}

export function unSetInMaintance() {
  return {
    type: actionsTypes.UNMAINTANCE
  };
}
