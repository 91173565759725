import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';
import ListUtils from 'portaltransparencia-common/libs/ListUtils.js';

import * as types from './Types.js';

const initialState = fromJS({
  escalas: [],
  escalasFiltradas: []
});

function escalaReducer(state = initialState, action) {
  switch (action.type) {
    case types.PESQUISA_ESCALAS_START:
      return startLoad(state);

    case types.PESQUISA_ESCALAS_SUCCESS: {
      return endLoad(
        state
          .set('escalas', fromJS(action.payload))
          .set('escalasFiltradas', fromJS(action.payload))
      );
    }

    case types.FILTRAR_ESCALAS: {
      const listaFiltrada = ListUtils.filterTree(
        fromJS(action.payload),
        action.meta,
        ['nome'],
        'list'
      );
      return state.set('escalasFiltradas', listaFiltrada);
    }

    case types.RESETAR_ESCALAS: {
      const publicacoes = state.get('escalas').toJS();
      return state.set('escalasFiltradas', fromJS(publicacoes));
    }
  }

  return state;
}

export default escalaReducer;
