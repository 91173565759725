import { List, fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';

import * as types from './Types.js';

const initialState = fromJS({
  veiculos: [],
  gastos: [],
  veiculo: {},
  tiposAlienacao: [],
  gastosManutencao: [],
  gastosAbastecimento: List(),
  gastosPneu: List(),
  gastosBateria: List(),
  gastosPeca: List()
});

function veiculoReducer(state = initialState, action) {
  switch (action.type) {
    case types.GASTOS_MANUTENCAO_START:
    case types.GASTOS_ABASTECIMENTO_START:
    case types.GASTOS_PNEU_START:
    case types.GASTOS_BATERIA_START:
    case types.GASTOS_PECA_START:
    case types.TIPOSALIENACAO_START:
    case types.LOAD_VEICULO_START:
    case types.CONSULTA_VEICULOS_START:
    case types.VEICULOS_GASTOS_START: {
      return startLoad(state);
    }

    case types.GASTOS_MANUTENCAO_ERROR:
    case types.GASTOS_ABASTECIMENTO_ERROR:
    case types.GASTOS_PNEU_ERROR:
    case types.GASTOS_BATERIA_ERROR:
    case types.GASTOS_PECA_ERROR:
    case types.TIPOSALIENACAO_ERROR:
    case types.LOAD_VEICULO_ERROR:
    case types.CONSULTA_VEICULOS_ERROR:
    case types.VEICULOS_GASTOS_ERROR: {
      return endLoad(state);
    }

    case types.TIPOSALIENACAO_SUCCESS: {
      return endLoad(state.set('tiposAlienacao', fromJS(action.payload)));
    }
    case types.LOAD_VEICULO_SUCCESS: {
      return endLoad(state.set('veiculo', fromJS(action.payload)));
    }
    case types.CONSULTA_VEICULOS_SUCCESS: {
      return endLoad(state.set('veiculos', fromJS(action.payload)));
    }
    case types.VEICULOS_GASTOS_SUCCESS: {
      return endLoad(state.set('gastos', fromJS(action.payload)));
    }
    case types.GASTOS_MANUTENCAO_SUCCESS: {
      return endLoad(state.set('gastosManutencao', fromJS(action.payload)));
    }
    case types.GASTOS_ABASTECIMENTO_SUCCESS: {
      return endLoad(state.set('gastosAbastecimento', fromJS(action.payload)));
    }
    case types.GASTOS_PNEU_SUCCESS: {
      return endLoad(state.set('gastosPneu', fromJS(action.payload)));
    }
    case types.GASTOS_BATERIA_SUCCESS: {
      return endLoad(state.set('gastosBateria', fromJS(action.payload)));
    }
    case types.GASTOS_PECA_SUCCESS: {
      return endLoad(state.set('gastosPeca', fromJS(action.payload)));
    }
  }

  return state;
}

export default veiculoReducer;
