import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';

import * as types from './Types.js';

const initialState = fromJS({
  leisatos: {},
  leiAto: {},
  naturezaLei: [],
  tiposDocumentoLegal: [],
  tiposEscopo: []
});

function leiatoReducer(state = initialState, action) {
  switch (action.type) {
    case types.LEISATOS_START:
    case types.CONSULTA_LEISATOS_START:
    case types.LEISATOS_DETALHE_START:
    case types.LEIS_ATOS_NATUREZA_START:
    case types.LIST_TIPOS_DOCUMENTO_LEGAL_START:
    case types.ARQUIVO_DOWNLOAD_LEIS_START:
    case types.LIST_TIPOS_ESCOPO_START: {
      return startLoad(state);
    }

    case types.LEISATOS_ERROR:
    case types.CONSULTA_LEISATOS_ERROR:
    case types.LEISATOS_DETALHE_ERROR:
    case types.LEIS_ATOS_NATUREZA_ERROR:
    case types.LIST_TIPOS_DOCUMENTO_LEGAL_ERROR:
    case types.ARQUIVO_DOWNLOAD_LEIS_SUCCESS:
    case types.ARQUIVO_DOWNLOAD_LEIS_ERROR:
    case types.LIST_TIPOS_ESCOPO_ERROR: {
      return endLoad(state);
    }

    case types.LEISATOS_SUCCESS:
    case types.CONSULTA_LEISATOS_SUCCESS: {
      return endLoad(state.set('leisatos', fromJS(action.payload)));
    }
    case types.CLEAR_LEISATOS: {
      return endLoad(state.set('leisatos', fromJS([])));
    }

    case types.LEISATOS_DETALHE_SUCCESS: {
      return endLoad(state.set('leiAto', fromJS(action.payload)));
    }
    case types.CLEAR_DETALHE: {
      return endLoad(state.set('leiAto', fromJS([])));
    }
    case types.LEIS_ATOS_NATUREZA_SUCCESS: {
      return endLoad(state.set('naturezaLei', fromJS(action.payload)));
    }
    case types.LIST_TIPOS_DOCUMENTO_LEGAL_SUCCESS: {
      return endLoad(state.set('tiposDocumentoLegal', fromJS(action.payload)));
    }
    case types.LIST_TIPOS_ESCOPO_SUCCESS: {
      return endLoad(state.set('tiposEscopo', fromJS(action.payload)));
    }
  }

  return state;
}

export default leiatoReducer;
