import { fromJS } from 'immutable';

import * as types from './Types.js';

export function findDespesaPorNivel(entidade, exercicio, nivellei, tipo) {
  return ({ fetch }) => ({
    type: types.DESPESAPORNIVEL,
    payload: {
      promise: fetch(
        `/despesapornivel/${entidade}/${exercicio}/${nivellei}/${tipo}`
      )
    },
    meta: {
      path: nivellei
    }
  });
}

export function findDiafindDespesaPorFonteRecurso() {
  return ({ fetch }) => ({
    type: types.DESPESA_POR_FONTE_RECURSO,
    payload: {
      promise: fetch('/despesapornivel/fonte-recursos')
    }
  });
}

export function findDiafindDespesaPorEsferaAdministrativa() {
  return ({ fetch }) => ({
    type: types.DESPESA_POR_ESFERA_ADMINISTRATIVA,
    payload: {
      promise: fetch('/despesapornivel/esfera-administrativa')
    }
  });
}

export function changeTipo(valor, label) {
  return {
    type: types.CHANGE_TIPO_DESPESA,
    payload: fromJS({
      valor: valor,
      label: label
    })
  };
}
