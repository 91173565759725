import { Map } from 'immutable';
import Input from 'portaltransparencia-common/components/EloInput.react.js';
import ExportButtonGroup from 'portaltransparencia-common/components/ExportButtonGroup.react';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import configuration from 'portaltransparencia-common/config/Config';
import {
  changeFieldValue,
  onChange,
  resetCrud
} from 'portaltransparencia-common/crud/Actions.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import {
  PARAM_INTEGRADO_OXY_FOLHA_PAGAMENTO,
  PARAM_LEISEATOS_AISE
} from 'portaltransparencia-common/parametros/ParametrosPortalTransparencia';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import FilterButtons from '../components/FilterButtons.react';
import PageHeader from '../components/PageHeader.react.js';
import ParametroService from '../parametros/ParametroService.js';
import UltimaAtualizacaoDataConsulta from '../ultimaatualizacao/UltimaAtualizacaoDataConsulta.react.js';
import * as actions from './Actions.js';
import { addErrorNotification } from 'portaltransparencia-common/ui/Actions.js';

export class FormQuadroPessoal extends Component {
  static propTypes = {
    quadroPessoal: PropTypes.object.isRequired,
    findQuadroPessoal: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    filtros: PropTypes.object,
    onChange: PropTypes.func,
    resetCrud: PropTypes.func,
    resetQuadroPessoal: PropTypes.func,
    paramIntegradoOxyFolha: PropTypes.string,
    paramIntegradoAise: PropTypes.string,
    addErrorNotification: PropTypes.func.isRequired
  };

  componentDidMount() {
    const { onChange, findQuadroPessoal } = this.props;

    const mesAtual = new Date().getMonth();

    if (onChange && findQuadroPessoal) {
      onChange('filtroQuadroPessoal', {
        name: 'mesCompetencia',
        value: mesAtual + 1
      });

      findQuadroPessoal(mesAtual + 1);
    }
  }

  consultarQuadroPessoal = () => {
    const { findQuadroPessoal, filtros } = this.props;

    findQuadroPessoal(filtros.get('mesCompetencia'));
  };

  onChangeFiltro = event => {
    const { onChange } = this.props;

    onChange('filtroQuadroPessoal', event);
  };

  onResetFiltro = () => {
    const { resetCrud, resetQuadroPessoal } = this.props;

    resetCrud('filtroQuadroPessoal');
    resetQuadroPessoal();
  };

  onExporta = (exportType, openTab, windowParams) => {
    const { apiURL } = configuration;
    const { page, quadroPessoal, addErrorNotification } = this.props;

    if (quadroPessoal.length > 0) {
      let filtro =
        this.montaFiltro(page, this.props) + '&exportType=' + exportType;

      window.open(
        `${apiURL}/api/quadro-pessoal/report?${filtro}`,
        openTab,
        windowParams
      );
    } else {
      addErrorNotification(
        'A consulta não apresentou resultado. Não é possível exportar para o formato selecionado!'
      );
    }
  };

  getOptions = data => {
    const { exercicio } = StorageService.getItem('userInfo');
    return (
      <option value={data + 1}>
        {DateUtils.getMonthDescription(data + 1)} / {exercicio.id}
      </option>
    );
  };

  getPanelTitle = () => {
    const { paramIntegradoOxyFolha } = this.props;

    if (paramIntegradoOxyFolha === 'S') {
      return 'Consultar em Quadro de Pessoal';
    }
    return 'Consultar Quadro de Pessoal';
  };

  addFilter = (actualFilter, filterAnd) => {
    let filterOperator = '';
    if (actualFilter !== '') {
      filterOperator = '&';
    }
    return actualFilter + filterOperator + filterAnd;
  };

  montaFiltro = () => {
    const { entidade, exercicio } = StorageService.getItem('userInfo');

    let filtro = `entidade=${entidade.id}&exercicio=${exercicio.id}`;

    if (this.refs.mesCompetencia) {
      filtro = this.addFilter(
        filtro,
        'competencia=' + this.refs.mesCompetencia.getValue()
      );
    }

    return filtro;
  };

  renderQuadroPessoal = (quadroPessoal, index) => {
    return (
      <tr ref="row" key={index}>
        <td className="rigth">{quadroPessoal.get('tipoCargo')}</td>
        <td className="rigth">{quadroPessoal.get('vagasCriadas')}</td>
        <td className="rigth">{quadroPessoal.get('vagasOcupadas')}</td>
        <td className="rigth">{quadroPessoal.get('vagasDisponiveis')}</td>
      </tr>
    );
  };

  render() {
    const {
      filtros,
      quadroPessoal,
      paramIntegradoOxyFolha,
      paramIntegradoAise
    } = this.props;
    const { exercicio } = StorageService.getItem('userInfo');

    if (!quadroPessoal) {
      return null;
    }

    let dataAtual = new Date();
    let meses = 11;

    if (dataAtual.getFullYear() === exercicio.id) {
      meses = dataAtual.getMonth();
    }

    let arrayMonths = [];
    for (var i = 0; i <= meses; i++) {
      arrayMonths.push(i);
    }

    return (
      <section>
        {(paramIntegradoOxyFolha === 'S' || paramIntegradoAise === 'S') && (
          <PageHeader location={this.props.location} />
        )}
        <Panel
          title={this.getPanelTitle()}
          isForm
          mobileClose
          ref="panelfiltroQuadroPessoal"
        >
          <Row>
            <Col xs={12} sm={6} md={4}>
              <Input
                id="mesCompetencia"
                name="mesCompetencia"
                ref="mesCompetencia"
                label="Mês / Exercício"
                type="select"
                onChange={this.onChangeFiltro}
                value={filtros.get('mesCompetencia')}
              >
                <option value="">Exercício / {exercicio.id}</option>
                {arrayMonths && arrayMonths.map(this.getOptions)}
              </Input>
            </Col>
            <Col xs={12} sm={4} md={3}>
              <FilterButtons
                onConsulta={this.consultarQuadroPessoal}
                onReset={this.onResetFiltro}
              />
            </Col>
          </Row>
        </Panel>

        <Panel isForm expansible={false} tableResponsive>
          <Row>
            <Col xs={6}>
              <UltimaAtualizacaoDataConsulta />
            </Col>
            <Col xs={6}>
              <ExportButtonGroup onClick={this.onExporta} visible={true} />
            </Col>
          </Row>
          <div className="panel-table-outer">
            <table
              className="panel-table striped fancy"
              id="tableQuadroPessoal"
            >
              <TableHeader empty={quadroPessoal.length === 0}>
                <tr>
                  <th>Tipo Cargo</th>
                  <th className="rigth">Vagas Criadas</th>
                  <th className="rigth">Vagas Ocupadas</th>
                  <th className="rigth">Vagas Disponíveis</th>
                </tr>
              </TableHeader>
              {quadroPessoal.length > 0 && (
                <tbody id="tbodyQuadroPessoal">
                  {quadroPessoal.map(this.renderQuadroPessoal)}
                </tbody>
              )}
            </table>
          </div>
        </Panel>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    quadroPessoal: state.quadroPessoal.get('quadroPessoal'),
    filtros: state.crud.getIn(
      ['filtroQuadroPessoal', 'currentRecord'],
      Map({})
    ),
    paramIntegradoAise: ParametroService.getValorParametro(
      state,
      PARAM_LEISEATOS_AISE
    ),
    paramIntegradoOxyFolha: ParametroService.getValorParametro(
      state,
      PARAM_INTEGRADO_OXY_FOLHA_PAGAMENTO
    )
  };
}

const allActions = {
  ...actions,
  onChange,
  changeFieldValue,
  resetCrud,
  addErrorNotification
};

export default connect(mapStateToProps, allActions)(FormQuadroPessoal);
