import DisplayData from 'portaltransparencia-common/components/displaydata/DisplayData.react.js';
import DisplayDataItem from 'portaltransparencia-common/components/displaydata/DisplayDataItem.react.js';
import DisplayDataRow from 'portaltransparencia-common/components/displaydata/DisplayDataRow.react.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export default class EmpenhoDetalheEmpenho extends Component {
  static propTypes = {
    empenho: PropTypes.object.isRequired
  };

  showEmpenho = () => {
    const { empenho } = this.props;
    this.props.history.push(
      `/contratos/detalhes?entidade=${empenho.get(
        'entidade'
      )}&exercicio=${empenho.get('anoContrato')}&contrato=${empenho.get(
        'idContrato'
      )}&tipoAto=${empenho.get('tipoContrato')}`
    );
  };

  showLicitacao = () => {
    const { empenho } = this.props;
    this.props.history.push(
      `/licitacoes/detalhes?entidade=${empenho.get(
        'entidade'
      )}&exercicio=${empenho.get('anoLicitacao')}&licitacao=${empenho.get(
        'licitacao'
      )}&tipoLicitacao=${empenho.get('tipoLicitacaoCompras')}`
    );
  };

  render() {
    const { empenho } = this.props;

    return (
      <DisplayData border>
        <DisplayDataRow>
          <DisplayDataItem
            sm={12}
            title="Empenho"
            value={`${empenho.get('empenho')}/${empenho.get('exercicio')}`}
          />
          {empenho.has('especie') && (
            <DisplayDataItem
              sm={12}
              title="Espécie"
              value={empenho.get('especie')}
            />
          )}
        </DisplayDataRow>

        <DisplayDataRow>
          <DisplayDataItem
            sm={12}
            title="Data Emissão"
            value={DateUtils.formatDateTimeShort(empenho.get('data'))}
          />
          <DisplayDataItem
            sm={12}
            title="Data Entrega"
            value={DateUtils.formatDateTimeShort(empenho.get('dataEntrega'))}
            show={empenho.has('dataEntrega')}
          />
          <DisplayDataItem
            sm={12}
            title="Modalidade"
            value={empenho.get('modalidade')}
          />
        </DisplayDataRow>

        <DisplayDataRow>
          {empenho.get('anoLicitacao') && (
            <DisplayDataItem
              sm={12}
              title="Nº Licitação"
              value={
                (empenho.get('licitacao') !== 0 && (
                  <a style={{ cursor: 'pointer' }} onClick={this.showLicitacao}>
                    {`${empenho.get('noLicitacao')}/${empenho.get(
                      'anoLicitacao'
                    )}`}
                  </a>
                )) ||
                empenho.get('noLicitacao') + '/' + empenho.get('anoLicitacao')
              }
            />
          )}
          {empenho.get('noProcesso') != 0 && (
            <DisplayDataItem
              sm={12}
              title="Nº Processo"
              value={
                empenho.get('noProcesso') + '/' + empenho.get('anoProcesso')
              }
            />
          )}
        </DisplayDataRow>

        <DisplayDataRow>
          {empenho.get('contrato') && (
            <DisplayDataItem
              sm={12}
              title="Nº Contrato"
              value={
                <a style={{ cursor: 'pointer' }} onClick={this.showEmpenho}>
                  {`${empenho.get('contrato')}/${empenho.get('anoContrato')}`}
                </a>
              }
            />
          )}
        </DisplayDataRow>

        <DisplayDataRow>
          {empenho.has('contrato') && (
            <DisplayDataItem
              sm={12}
              title="Objeto do Contrato"
              value={empenho.get('objeto')}
            />
          )}
        </DisplayDataRow>
      </DisplayData>
    );
  }
}
