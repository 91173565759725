export const messages = {
  accepted: 'O :attribute deve ser aceito.',
  alpha: 'The :attribute field must contain only alphabetic characters.',
  alpha_dash:
    'The :attribute field may only contain alpha-numeric characters, as well as dashes and underscores.',
  alpha_num: 'The :attribute field must be alphanumeric.',
  between: 'The :attribute field must be between :min and :max.',
  confirmed: 'The :attribute confirmation does not match.',
  email: 'O formato do :attribute não é válido.',
  def: 'The :attribute attribute has errors.',
  digits: 'The :attribute must be :digits digits.',
  different: 'The :attribute and :different must be different.',
  in: 'The selected :attribute is invalid.',
  integer: 'The :attribute must be an integer.',
  min: {
    numeric: 'The :attribute must be at least :min.',
    string: 'The :attribute must be at least :min characters.'
  },
  max: {
    numeric: 'O campo ":attribute" não pode ser superior a ":max".',
    string: 'O campo ":attribute" não deve possuir mais que ":max" caracteres.'
  },
  not_in: 'The selected :attribute is invalid.',
  numeric: 'The :attribute must be a number.',
  required: ':attribute é obrigatório.',
  required_if: 'O campo :attribute é obrigatório.',
  same: 'The :attribute and :same fields must match.',
  size: {
    numeric: 'The :attribute must be :size.',
    string: 'The :attribute must be :size characters.'
  },
  string: 'The :attribute must be a string.',
  url: 'O formato do campo ":attribute" é inválido.',
  regex: 'O formato do campo ":attribute" é inválido.',
  cnpj_cpf: 'O :attribute é inválido.',
  cpf: 'O :attribute é inválido.',
  attributes: {}
};
