import { Map } from 'immutable';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import SearchPagination from 'portaltransparencia-common/components/SearchPagination.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import TableHeaderItem from 'portaltransparencia-common/components/TableHeaderItem.react';
import {
  onChange,
  resetCrud
} from 'portaltransparencia-common/crud/Actions.js';
import { addPagination } from 'portaltransparencia-common/libs/AddFilter.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';
import * as actions from './Actions.js';

export class FormProcessoDisciplinar extends Component {
  static propTypes = {
    processos: PropTypes.object.isRequired,
    filtros: PropTypes.object,
    findProcessos: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    resetCrud: PropTypes.func.isRequired,
    searchWithPage: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.onConsultaProcessos();
  }

  onConsultaProcessos = event => {
    const { processos } = this.props;
    const initialPage = processos.set('number', 0);

    this.searchWithPage(initialPage);
    event && event.preventDefault();
  };

  searchWithPage = page => {
    const { findProcessos } = this.props;
    findProcessos(this.montarFiltro(page));
  };

  montarFiltro = page => {
    let filtro = '';
    return addPagination(page, filtro);
  };

  onChangeFiltro = event => {
    const { onChange } = this.props;
    onChange('filtroProcessos', event);
  };

  renderProcesso = (processo, index) => {
    return (
      <tr ref="row" key={index}>
        <td>{processo.get('numeroProcesso')}</td>
        <td>{processo.get('dataAbertura')}</td>
        <td>{processo.get('assunto')}</td>
        <td>{processo.get('andamento')}</td>
      </tr>
    );
  };

  render() {
    const { processos } = this.props;
    if (!processos.has('content')) {
      return null;
    }

    return (
      <section>
        <Panel
          title="Consultar Processos Disciplinares"
          mobileClose
          isForm
          ref="panelConsultaProcessos"
        />
        <Panel isForm expansible={false} tableResponsive>
          <div className="panel-table-outer">
            <table className="panel-table striped fancy" id="tableProcessos">
              <TableHeader empty={processos.get('content').isEmpty()}>
                <tr>
                  <TableHeaderItem
                    field="numeroProcesso"
                    label="Número Processo"
                    page={processos}
                    searchWithPage={this.searchWithPage}
                  />
                  <TableHeaderItem
                    field="dataAbertura"
                    label="Data da Abertura"
                    page={processos}
                    searchWithPage={this.searchWithPage}
                  />
                  <TableHeaderItem
                    field="assunto"
                    label="Assunto"
                    page={processos}
                    searchWithPage={this.searchWithPage}
                  />
                  <TableHeaderItem
                    field="andamento"
                    label="Andamento"
                    page={processos}
                    searchWithPage={this.searchWithPage}
                  />
                </tr>
              </TableHeader>
              <tbody id="tbodyProcessos">
                {processos.get('content').map(this.renderProcesso)}
              </tbody>
            </table>
          </div>
          <SearchPagination
            page={processos}
            searchWithPage={this.searchWithPage}
          />
        </Panel>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    processos: state.processos.get('processos'),
    filtros: state.crud.getIn(['filtroProcessos', 'currentRecord'], Map({}))
  };
}

const allActions = {
  ...actions,
  resetCrud,
  onChange
};

export default connect(mapStateToProps, allActions)(FormProcessoDisciplinar);
