import 'jquery-ui/datepicker.js';

import $ from 'jquery';
import Moment from 'moment';

const MONTH_DESCRIPTION = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro'
];

const MONTH_SHORT_DESCRIPTION = [
  'Jan',
  'Fev',
  'Mar',
  'Abr',
  'Mai',
  'Jun',
  'Jul',
  'Ago',
  'Set',
  'Out',
  'Nov',
  'Dez'
];

const DateUtils = {
  parseDate(value, format = 'yy-mm-dd') {
    return $.datepicker.parseDate(format, value);
  },

  formatDateTime(value, targetFormat = 'DD/MM/YYYY HH:mm:ss') {
    if (value) {
      return Moment(value).format(targetFormat);
    }
    return value;
  },

  formatDateTimeShort(value, targetFormat = 'DD/MM/YYYY') {
    if (value) {
      return this.formatDateTime(value, targetFormat);
    }
    return value;
  },

  formatDate(value, format = 'dd/mm/yy') {
    return $.datepicker.formatDate(format, value);
  },

  formatTime(value, format = 'HH:mm:ss') {
    if (value) {
      return Moment(value).format(format);
    }
    return value;
  },

  transform(value, sourceFormat = 'yy-mm-dd', targetFormat = 'dd/mm/yy') {
    if (value) {
      const date = this.parseDate(value, sourceFormat);
      return this.formatDate(date, targetFormat);
    }
    return value;
  },

  isPtBr(value) {
    return /^\d{2}\/\d{2}\/\d{4}$/.test(value);
  },

  isISO8601(value) {
    return /^\d{4}-\d{2}-\d{2}$/.test(value);
  },

  getMonthDescription(mes, abreviado) {
    if (mes < 1 || mes > 12) {
      return '';
    }
    if (abreviado) {
      return MONTH_SHORT_DESCRIPTION[mes - 1];
    }
    return MONTH_DESCRIPTION[mes - 1];
  }
};

export default DateUtils;
