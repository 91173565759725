import * as types from './Types.js';

export function findNotasExplicativa() {
  return ({ fetch }) => ({
    type: types.NOTAS_EXPLICATIVA,
    payload: {
      promise: fetch('/api/notas-explicativa/list')
    }
  });
}

export function findNotaExplicativaByMenu(menuPath) {
  return {
    type: types.NOTAS_EXPLICATIVA_MENU,
    payload: menuPath
  };
}
