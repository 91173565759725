import * as actions from './Actions.js';

import {
  PARAM_INTEGRADO_OXY_FOLHA_PAGAMENTO,
  PARAM_USA_LEGISLATIVO
} from 'portaltransparencia-common/parametros/ParametrosPortalTransparencia';
import {
  getAllValuesFromUrlSearchParams,
  getValueFromUrlSearchParams
} from 'portaltransparencia-common/libs/URLUtils';

import Arquivo from '../arquivo/Arquivo.react.js';
import BotaoImprimir from 'portaltransparencia-common/components/BotaoImprimir.react.js';
import CabecalhoEntidade from '../entidades/CabecalhoEntidade.react.js';
import Component from 'react-pure-render/component';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import DisplayData from 'portaltransparencia-common/components/displaydata/DisplayData.react.js';
import DisplayDataItem from 'portaltransparencia-common/components/displaydata/DisplayDataItem.react.js';
import DisplayDataRow from 'portaltransparencia-common/components/displaydata/DisplayDataRow.react.js';
import Helmet from 'react-helmet';
import LeisAtosPublicacoes from './LeisAtosPublicacoes.react.js';
import LeisAtosVinculosLista from './LeisAtosVinculosLista.react.js';
import PageHeader from '../components/PageHeader.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import ParametroService from '../parametros/ParametroService.js';
import PropTypes from 'prop-types';
import React from 'react';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import { addFilterUrl } from 'portaltransparencia-common/libs/AddFilter.js';
import { connect } from 'react-redux';
import { downloadArquivo } from '../arquivo/Actions.js';

export class FormLeisAtosDetail extends Component {
  static propTypes = {
    leiAto: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    downloadArquivo: PropTypes.func.isRequired,
    downloadLegislacao: PropTypes.func.isRequired,
    findLeisAtosDetalhes: PropTypes.func.isRequired,
    clearDetalhe: PropTypes.func.isRequired,
    paramIntegradoOxyFolha: PropTypes.string
  };

  componentDidMount() {
    this.filtrarLeisAtos();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const oldId = getValueFromUrlSearchParams(this.props.location, 'id');
    const newId = getValueFromUrlSearchParams(nextProps.location, 'id');
    if (oldId !== newId) {
      this.filtrarLeisAtos(nextProps);
    }
  }

  componentWillUnmount() {
    const { clearDetalhe } = this.props;

    clearDetalhe();
  }

  filtrarLeisAtos = (props = this.props) => {
    const { findLeisAtosDetalhes, location } = props;
    const { entidade, exercicio } = StorageService.getItem('userInfo');
    const {
      ano,
      id,
      tipo,
      sistema,
      natureza
    } = getAllValuesFromUrlSearchParams(location);

    let filter = '';
    filter = addFilterUrl(filter, `entidade=${entidade.id}`);

    if (ano) {
      filter = addFilterUrl(filter, `ano=${ano}`);
    }
    if (id) {
      filter = addFilterUrl(filter, `id=${id}`);
    }
    if (tipo) {
      filter = addFilterUrl(filter, `tipo=${tipo}`);
    }
    if (sistema) {
      filter = addFilterUrl(filter, `sistema=${sistema}`);
    }
    if (natureza) {
      filter = addFilterUrl(filter, `natureza=${natureza}`);
    }
    if (exercicio) {
      filter = addFilterUrl(filter, `exercicio=${exercicio.id}`);
    }

    return findLeisAtosDetalhes(filter);
  };

  downloadFunc = arquivo => {
    const { leiAto, downloadLegislacao, downloadArquivo } = this.props;

    if (leiAto.get('sistema') === 'L') {
      return downloadLegislacao(
        arquivo,
        leiAto.get('tipo'),
        leiAto.get('natureza')
      );
    }
    return downloadArquivo(arquivo);
  };

  renderPainelRodape = tipoLeisAtos => {
    const { leiAto } = this.props;

    if (leiAto.get('veiculosPublicacao')) {
      return (
        <>
          <Panel isTable tableResponsive expansible={false} title="Publicações">
            <LeisAtosPublicacoes
              veiculosPublicacao={leiAto.get('veiculosPublicacao')}
              onDownload={this.downloadFunc}
            />
          </Panel>
          <Panel isTable tableResponsive expansible={false} title="Arquivos">
            <Arquivo
              arquivos={leiAto.get('arquivos')}
              onDownload={this.downloadFunc}
            />
          </Panel>
        </>
      );
    }

    return (
      <>
        <Panel
          isTable
          tableResponsive
          expansible={false}
          title="Leis Vinculadas"
        >
          <LeisAtosVinculosLista
            vinculos={leiAto.get('leisVinculadas')}
            sistema={leiAto.get('sistema')}
            tipoLeisAtos={tipoLeisAtos}
          />
        </Panel>

        <Panel isTable tableResponsive expansible={false} title="Arquivos">
          <Arquivo
            arquivos={leiAto.get('arquivos')}
            onDownload={this.downloadFunc}
          />
        </Panel>
      </>
    );
  };

  render() {
    const {
      leiAto,
      location,
      paramIntegradoOxyFolha,
      paramIntegradoLegislacao
    } = this.props;

    if (!leiAto || leiAto.isEmpty()) {
      return (
        <div>
          <Panel title="Detalhes" mobileClose expansible={false}>
            Nenhum detalhe a ser exibido
          </Panel>
        </div>
      );
    }

    const tipoLeisAtos = getValueFromUrlSearchParams(location, 'tipoLeisAtos');

    return (
      <div key={leiAto}>
        <Helmet title={`Lei/Ato: ${leiAto.get('numero')}`} />
        <PageHeader
          location={this.props.location}
          textoDetalhe={`Lei/Ato: ${leiAto.get('numero')} - ${leiAto.get(
            'exercicio'
          )}`}
        />

        <div>
          <CabecalhoEntidade />

          <BotaoImprimir />

          <Panel title="Detalhes" mobileClose expansible={false}>
            <DisplayData border>
              <DisplayDataRow>
                <DisplayDataItem
                  sm={2}
                  title="Número"
                  value={leiAto.get('numero')}
                  id="numero"
                />
                <DisplayDataItem
                  sm={3}
                  title="Natureza"
                  value={leiAto.get('natureza')}
                  id="natureza"
                />
                {paramIntegradoOxyFolha === 'S'
                  ? null
                  : (!paramIntegradoLegislacao ||
                      paramIntegradoLegislacao === 'N') && (
                      <DisplayDataItem
                        sm={5}
                        title="Tipo Documento Legal"
                        value={leiAto.get('tipoDocumentoLegal')}
                        id="tipoDocumentoLegal"
                      />
                    )}

                {leiAto.get('data') && (
                  <DisplayDataItem
                    sm={2}
                    title="Data"
                    value={DateUtils.formatDateTimeShort(leiAto.get('data'))}
                    id="data"
                  />
                )}

                <DisplayDataItem
                  sm={4}
                  title="Ano"
                  value={leiAto.get('exercicio')}
                  id="exercicio"
                />
              </DisplayDataRow>

              {paramIntegradoOxyFolha === 'S' ? null : (
                <>
                  <DisplayDataRow>
                    {leiAto.get('dataPublicacao') && (
                      <DisplayDataItem
                        sm={3}
                        title="Data Publicação"
                        value={DateUtils.formatDateTimeShort(
                          leiAto.get('dataPublicacao')
                        )}
                        id="dataPublicacao"
                      />
                    )}
                    {leiAto.get('veiculoPublicacao') && (
                      <DisplayDataItem
                        sm={5}
                        title="Veículo Publicação"
                        value={leiAto.get('veiculoPublicacao')}
                        id="veiculoPublicacao"
                      />
                    )}
                    {leiAto.get('numeroEdicao') && (
                      <DisplayDataItem
                        sm={2}
                        title="Nº Edição"
                        value={leiAto.get('numeroEdicao')}
                        id="numeroEdicao"
                      />
                    )}
                    {leiAto.get('pagina') && (
                      <DisplayDataItem
                        sm={2}
                        title="Página"
                        value={leiAto.get('pagina')}
                        id="pagina"
                      />
                    )}
                  </DisplayDataRow>
                </>
              )}

              {leiAto.get('descricaoEscopo') && (
                <DisplayDataRow>
                  <DisplayDataItem
                    sm={12}
                    title="Escopo"
                    value={leiAto.get('descricaoEscopo')}
                    id="descricaoEscopo"
                  />
                </DisplayDataRow>
              )}

              <DisplayDataRow>
                <DisplayDataItem
                  sm={12}
                  title="Descrição"
                  value={leiAto.get('descricao')}
                  id="descricao"
                />
              </DisplayDataRow>

              {paramIntegradoOxyFolha === 'S' ? null : (
                <DisplayDataRow>
                  <DisplayDataItem
                    sm={12}
                    title="Súmula"
                    value={leiAto.get('sumula')}
                    id="sumula"
                  />
                </DisplayDataRow>
              )}
            </DisplayData>
          </Panel>

          {this.renderPainelRodape(tipoLeisAtos)}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    leiAto: state.leisatos.get('leiAto'),
    paramIntegradoOxyFolha: ParametroService.getValorParametro(
      state,
      PARAM_INTEGRADO_OXY_FOLHA_PAGAMENTO
    ),
    paramIntegradoLegislacao: ParametroService.getValorParametro(
      state,
      PARAM_USA_LEGISLATIVO
    )
  };
}

const allActions = {
  ...actions,
  downloadArquivo
};

export default connect(mapStateToProps, allActions)(FormLeisAtosDetail);
