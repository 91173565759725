import * as types from './Types.js';

export function findTotalAcesso() {
  return ({ fetch }) => ({
    type: types.TOTALACESSOS,
    payload: {
      promise: fetch('/totalacesso')
    }
  });
}

export function findTotalAcessoDiarioOficial() {
  return ({ fetch }) => ({
    type: types.TOTALACESSOS_DIARIO_OFICIAL,
    payload: {
      promise: fetch('/totalacessos-diario-oficial')
    }
  });
}
