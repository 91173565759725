import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export default class AutonomoRow extends Component {
  static propTypes = {
    autonomo: PropTypes.object.isRequired
  };

  onViewDetalhes = item => {
    this.props.history.push(
      `/autonomos/detalhes?vinculo=${item.get('vinculo')}&matricula=${item.get(
        'matricula'
      )}`
    );
  };

  render() {
    const { autonomo, usaWebService } = this.props;
    const key = `${autonomo.get('entidade')}_${autonomo.get('matricula')}`;

    return (
      <TableActions
        ref="row"
        key={key}
        data={autonomo}
        onMore={this.onViewDetalhes}
      >
        <td id="tableNome">{autonomo.get('nome')}</td>
        <td id="tableMatricula">{autonomo.get('matricula')}</td>
        <td id="tableSituacao">{autonomo.get('situacao')}</td>
        {!usaWebService && (
          <td id="tableDescricaoClasse">{autonomo.get('descricaoClasse')}</td>
        )}
        <td id="tableDescricaoLotacao">{autonomo.get('descricaoLotacao')}</td>
      </TableActions>
    );
  }
}
