import Component from 'react-pure-render/component';
import PropTypes from 'prop-types';
import React from 'react';
import { Row } from 'react-bootstrap';

export default class DisplayDataRow extends Component {
  static propTypes = {
    children: PropTypes.node
  };

  render() {
    const { children } = this.props;

    return <Row>{children}</Row>;
  }
}
