const Fields = {
  getName(event) {
    const target = event.target || event;
    return target.name;
  },

  getValue(event) {
    const target = event.target || event;
    const { type, checked, value } = target;
    const isCheckbox = type && type.toLowerCase() === 'checkbox';

    return isCheckbox ? checked : value;
  },

  fireChange(change, event) {
    const name = this.getName(event);
    const value = this.getValue(event);

    change(name, value);
  }
};

export default Fields;
