import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';

import * as types from './Types.js';

const initialState = fromJS({
  cargos: {},
  cargosOxy: [],
  faixas: [],
  filtroCargos: [],
  filtroQuadros: [],
  filtroGrupos: [],
  filtroNiveis: [],
  funcoesGratificadas: [],
  filtroPlanoSalarial: [],
  matrizes: [],
  matrizesVigentes: [],
  matriz: [],
  cargoSelecionado: {}
});

function CargoReducer(state = initialState, action) {
  switch (action.type) {
    case types.LIST_CARGOS_START:
    case types.LIST_FAIXAS_START:
    case types.FILTRO_CARGOS_START:
    case types.FILTRO_QUADROS_START:
    case types.FILTRO_GRUPOS_START:
    case types.FILTRO_NIVEIS_START:
    case types.LIST_FUNCOES_GRATIFICADAS_START:
    case types.FILTRO_MATRIZES_START:
    case types.LIST_MATRIZES_START:
    case types.LIST_CARGOS_OXY_START:
    case types.LIST_MATRIZ_VIGENTE_START:
    case types.LIST_MATRIZ_START: {
      return startLoad(state);
    }

    case types.LIST_CARGOS_ERROR:
    case types.LIST_FAIXAS_ERROR:
    case types.FILTRO_CARGOS_ERROR:
    case types.FILTRO_QUADROS_ERROR:
    case types.FILTRO_GRUPOS_ERROR:
    case types.FILTRO_NIVEIS_ERROR:
    case types.LIST_FUNCOES_GRATIFICADAS_ERROR:
    case types.FILTRO_MATRIZES_ERROR:
    case types.LIST_MATRIZES_ERROR:
    case types.LIST_CARGOS_OXY_ERROR:
    case types.LIST_MATRIZ_VIGENTE_ERROR:
    case types.LIST_MATRIZ_ERROR: {
      return endLoad(state);
    }

    case types.LIST_CARGOS_SUCCESS: {
      return endLoad(state.set('cargos', fromJS(action.payload)));
    }
    case types.LIST_FAIXAS_SUCCESS: {
      return endLoad(state.set('faixas', fromJS(action.payload)));
    }
    case types.FILTRO_CARGOS_SUCCESS: {
      return endLoad(state.set('filtroCargos', fromJS(action.payload)));
    }
    case types.FILTRO_QUADROS_SUCCESS: {
      return endLoad(state.set('filtroQuadros', fromJS(action.payload)));
    }
    case types.FILTRO_GRUPOS_SUCCESS: {
      return endLoad(state.set('filtroGrupos', fromJS(action.payload)));
    }
    case types.FILTRO_NIVEIS_SUCCESS: {
      return endLoad(state.set('filtroNiveis', fromJS(action.payload)));
    }
    case types.LIST_FUNCOES_GRATIFICADAS_SUCCESS: {
      return endLoad(state.set('funcoesGratificadas', fromJS(action.payload)));
    }
    case types.FILTRO_MATRIZES_SUCCESS: {
      return endLoad(state.set('filtroMatrizes', fromJS(action.payload)));
    }
    case types.LIST_MATRIZES_SUCCESS: {
      return endLoad(state.set('matrizes', fromJS(action.payload)));
    }
    case types.LIST_CARGOS_OXY_SUCCESS: {
      return endLoad(state.set('cargosOxy', fromJS(action.payload)));
    }

    case types.LIST_MATRIZ_VIGENTE_SUCCESS: {
      return endLoad(state.set('matrizesVigentes', fromJS(action.payload)));
    }

    case types.LIST_MATRIZ_SUCCESS: {
      return endLoad(state.set('matriz', fromJS(action.payload)));
    }
  }

  return state;
}

export default CargoReducer;
