export const CONVENIOS = 'CONVENIOS';
export const CONVENIOS_START = 'CONVENIOS_START';
export const CONVENIOS_SUCCESS = 'CONVENIOS_SUCCESS';
export const CONVENIOS_ERROR = 'CONVENIOS_ERROR';

export const RESET_CONSULTA_CONVENIOS = 'RESET_CONSULTA_CONVENIOS';

export const CONVENIO = 'CONVENIO';
export const CONVENIO_START = 'CONVENIO_START';
export const CONVENIO_SUCCESS = 'CONVENIO_SUCCESS';
export const CONVENIO_ERROR = 'CONVENIO_ERROR';

export const ADITIVOS_CONVENIO = 'ADITIVOS_CONVENIO';
export const ADITIVOS_CONVENIO_START = 'ADITIVOS_CONVENIO_START';
export const ADITIVOS_CONVENIO_SUCCESS = 'ADITIVOS_CONVENIO_SUCCESS';
export const ADITIVOS_CONVENIO_ERROR = 'ADITIVOS_CONVENIO_ERROR';

export const ARQUIVOS_CONVENIO = 'ARQUIVOS_CONVENIO';
export const ARQUIVOS_CONVENIO_START = 'ARQUIVOS_CONVENIO_START';
export const ARQUIVOS_CONVENIO_SUCCESS = 'ARQUIVOS_CONVENIO_SUCCESS';
export const ARQUIVOS_CONVENIO_ERROR = 'ARQUIVOS_CONVENIO_ERROR';

export const SITUACOES_CONVENIO = 'SITUACOES_CONVENIO';
export const SITUACOES_CONVENIO_START = 'SITUACOES_CONVENIO_START';
export const SITUACOES_CONVENIO_SUCCESS = 'SITUACOES_CONVENIO_SUCCESS';
export const SITUACOES_CONVENIO_ERROR = 'SITUACOES_CONVENIO_ERROR';

export const CONTRAPARTIDA_PAGAMENTO = 'CONTRAPARTIDA_PAGAMENTO';
export const CONTRAPARTIDA_PAGAMENTO_START = 'CONTRAPARTIDA_PAGAMENTO_START';
export const CONTRAPARTIDA_PAGAMENTO_SUCCESS =
  'CONTRAPARTIDA_PAGAMENTO_SUCCESS';
export const CONTRAPARTIDA_PAGAMENTO_ERROR = 'CONTRAPARTIDA_PAGAMENTO_ERROR';

export const CONTRAPARTIDA_REPASSES = 'CONTRAPARTIDA_REPASSES';
export const CONTRAPARTIDA_REPASSES_START = 'CONTRAPARTIDA_REPASSES_START';
export const CONTRAPARTIDA_REPASSES_SUCCESS = 'CONTRAPARTIDA_REPASSES_SUCCESS';
export const CONTRAPARTIDA_REPASSES_ERROR = 'CONTRAPARTIDA_REPASSES_ERROR';

export const CONTRAPARTIDA_DEVOLUCAO = 'CONTRAPARTIDA_DEVOLUCAO';
export const CONTRAPARTIDA_DEVOLUCAO_START = 'CONTRAPARTIDA_DEVOLUCAO_START';
export const CONTRAPARTIDA_DEVOLUCAO_SUCCESS =
  'CONTRAPARTIDA_DEVOLUCAO_SUCCESS';
export const CONTRAPARTIDA_DEVOLUCAO_ERROR = 'CONTRAPARTIDA_DEVOLUCAO_ERROR';

export const REPASSES_CONVENIO = 'REPASSES_CONVENIO';
export const REPASSES_CONVENIO_START = 'REPASSES_CONVENIO_START';
export const REPASSES_CONVENIO_SUCCESS = 'REPASSES_CONVENIO_SUCCESS';
export const REPASSES_CONVENIO_ERROR = 'REPASSES_CONVENIO_ERROR';

export const PAGAMENTO_CONVENIO = 'PAGAMENTO_CONVENIO';
export const PAGAMENTO_CONVENIO_START = 'PAGAMENTO_CONVENIO_START';
export const PAGAMENTO_CONVENIO_SUCCESS = 'PAGAMENTO_CONVENIO_SUCCESS';
export const PAGAMENTO_CONVENIO_ERROR = 'PAGAMENTO_CONVENIO_ERROR';
