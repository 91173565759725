import { Map } from 'immutable';
import Input from 'portaltransparencia-common/components/EloInput.react.js';
import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import {
  changeFieldValue,
  onChange,
  resetCrud
} from 'portaltransparencia-common/crud/Actions.js';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import FilterButtons from '../components/FilterButtons.react';
import PageHeader from '../components/PageHeader.react.js';
import * as actions from './Actions.js';
import TableActions from 'portaltransparencia-common/components/TableActions.react';
import NotasExplicativas from '../notaexplicativa/Form.react';

export class FormVerbas extends Component {
  static propTypes = {
    lotacoes: PropTypes.object.isRequired,
    findVerbas: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    filtros: PropTypes.object,
    onChange: PropTypes.func,
    resetCrud: PropTypes.func,
    findVerbaDetalhes: PropTypes.func
  };

  componentDidMount() {
    const { filtros } = this.props;

    filtros.setIn('mesCompetencia', '1');
  }

  onViewDetalhes = item => {
    const { filtros } = this.props;

    this.props.history.push(
      `/verbas/detalhes?evento=${filtros.get(
        'definicaoEvento'
      )}&competencia=${filtros.get('mesCompetencia')}&lotacao=${item.get(
        'codigo'
      )}`
    );
  };

  onChangeFiltro = event => {
    const { onChange } = this.props;

    onChange('filtroVerbas', event);
  };

  onPesquisar = () => {
    const { findVerbas, filtros } = this.props;

    if (filtros.get('definicaoEvento') && filtros.get('mesCompetencia')) {
      findVerbas(filtros.get('definicaoEvento'), filtros.get('mesCompetencia'));
    }
  };

  onResetFiltro = () => {
    const { resetCrud } = this.props;

    resetCrud('filtroVerbas');
  };

  renderLotacoes = lotacao => {
    return (
      <TableActions ref="row" data={lotacao} onMore={this.onViewDetalhes}>
        <td>{`${lotacao.get('descricao')} (${lotacao.get('codigo')})`}</td>
        <td className="right">
          <NumberFormatter value={lotacao.get('valorCalculado')} />
        </td>
        <td />
      </TableActions>
    );
  };

  getValorTotalCalculado = lotacoes => {
    return lotacoes
      .map(lotacao => lotacao.get('valorCalculado'))
      .reduce((acc, curr) => acc + curr, 0);
  };

  render() {
    const { lotacoes, location, filtros } = this.props;

    return (
      <section>
        <PageHeader location={location} />

        <NotasExplicativas pathname={this.props.location.pathname} />

        <Panel
          title="Consultar Verbas"
          isForm
          mobileClose
          ref="panelfiltroLicitacoes"
        >
          <Row>
            <Col xs={12} sm={6} md={4}>
              <Input
                id="mesCompetencia"
                name="mesCompetencia"
                ref="mesCompetencia"
                label="Mês Competência "
                type="select"
                onChange={this.onChangeFiltro}
                value={filtros.get('mesCompetencia')}
              >
                <option value="">Selecione</option>
                <option value="1">Janeiro</option>
                <option value="2">Fevereiro</option>
                <option value="3">Março</option>
                <option value="4">Abril</option>
                <option value="5">Maio</option>
                <option value="6">Junho</option>
                <option value="7">Julho</option>
                <option value="8">Agosto</option>
                <option value="9">Setembro</option>
                <option value="10">Outubro</option>
                <option value="11">Novembro</option>
                <option value="12">Dezembro</option>
              </Input>
            </Col>
            <Col xs={12} sm={6} md={4}>
              <Input
                id="definicaoEvento"
                name="definicaoEvento"
                ref="definicaoEvento"
                label="Tipo de Verba"
                type="select"
                onChange={this.onChangeFiltro}
                value={filtros.get('definicaoEvento')}
              >
                <option value="">Selecione</option>
                <option value="200">Representação</option>
                <option value="201">Gabinete</option>
                <option value="202">Reembolsável</option>
              </Input>
            </Col>
            <Col xs={12} sm={4} md={3}>
              <FilterButtons
                onConsulta={this.onPesquisar}
                onReset={this.onResetFiltro}
              />
            </Col>
          </Row>
        </Panel>

        <Panel title="Lançamentos" isForm expansible={false} tableResponsive>
          <div className="panel-table-outer">
            <table className="panel-table striped facny">
              <TableHeader empty={lotacoes.isEmpty()}>
                <tr>
                  <th>Lotação</th>
                  <th className="right">Valor Calculado</th>
                  <th />
                </tr>
              </TableHeader>
              <tbody>{lotacoes.map(this.renderLotacoes)}</tbody>

              {!lotacoes.isEmpty() && (
                <tfoot>
                  <tr>
                    <td />
                    <td className="right">
                      <i>Total Calculado:</i>
                      <br />
                      <NumberFormatter
                        value={this.getValorTotalCalculado(lotacoes)}
                      />
                    </td>
                    <td />
                  </tr>
                </tfoot>
              )}
            </table>
          </div>
        </Panel>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    lotacoes: state.verbas.get('lotacoes'),
    filtros: state.crud.getIn(['filtroVerbas', 'currentRecord'], Map({}))
  };
}

const allActions = {
  ...actions,
  onChange,
  changeFieldValue,
  resetCrud
};

export default connect(mapStateToProps, allActions)(FormVerbas);
