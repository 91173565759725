import Panel from 'portaltransparencia-common/components/Panel.react.js';
import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import PageHeader from '../components/PageHeader.react.js';
import NotasExplicativas from '../notaexplicativa/Form.react.js';
import UltimaAtualizacaoByTable from '../ultimaatualizacao/UltimaAtualizacaoByTable.react.js';
import * as actions from './Actions.js';
import ConselhoFiltro from './ConselhoFiltro.react.js';

export class FormConselhos extends Component {
  static propTypes = {
    params: PropTypes.object,
    conselhos: PropTypes.object,
    findConselhos: PropTypes.func.isRequired,
    filterConselhos: PropTypes.func.isRequired,
    resetConselhos: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    expand: PropTypes.func,
    collapse: PropTypes.func
  };

  componentDidMount() {
    const { findConselhos: findConselhos } = this.props;
    findConselhos();
  }

  renderListas = conselho => {
    return (
      <TableActions
        ref="row"
        key={conselho.get('id')}
        data={conselho}
        onMore={this.onViewDetalhes}
      >
        <td>{conselho.get('nome')}</td>
        <td>
          {DateUtils.formatDateTimeShort(conselho.get('datainicial')) +
            ' à ' +
            DateUtils.formatDateTimeShort(conselho.get('datafinal'))}
        </td>
        <td>{conselho.get('local')}</td>
        <td>{conselho.get('dias')}</td>
      </TableActions>
    );
  };

  onViewDetalhes = item => {
    const idConselho = item.get('id');
    let url = `/conselhos/${idConselho}`;
    this.props.history.push(url);
  };

  render() {
    const {
      conselhos,
      findConselhos,
      filterConselhos,
      resetConselhos
    } = this.props;

    let list = conselhos;

    return (
      <section>
        <PageHeader location={this.props.location} />

        <NotasExplicativas pathname={this.props.location.pathname} />

        <ConselhoFiltro
          show={list}
          findConselhos={findConselhos}
          filterConselhos={filterConselhos}
          resetConselhos={resetConselhos}
        />

        <Panel isTable tableResponsive expansible={false}>
          <UltimaAtualizacaoByTable
            modulo="14"
            table="reuniao"
            column="dataultimaatualizacao"
          />
          <div className="panel-table-outer">
            <table className="panel-table striped fancy">
              <TableHeader empty={list.isEmpty()}>
                <tr>
                  <th>Descrição</th>
                  <th>Vigência</th>
                  <th>Local das reuniões</th>
                  <th>Dias de reunião</th>
                  <th></th>
                </tr>
              </TableHeader>
              <tbody>{list.map(this.renderListas)}</tbody>
            </table>
          </div>
        </Panel>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    conselhos: state.conselho.get('conselhosFiltrados')
  };
}

const allActions = {
  ...actions
};

export default connect(mapStateToProps, allActions)(FormConselhos);
