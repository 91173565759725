import Input from 'portaltransparencia-common/components/EloInput.react.js';
import Validation from 'portaltransparencia-common/components/Validation.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export class FormSolicitacaoDetailTipo extends Component {
  static propTypes = {
    dados: PropTypes.object,
    onChangeDados: PropTypes.func.isRequired,
    errors: PropTypes.object,
    permiteSolicitacaoAnonima: PropTypes.string
  };

  getValidatorData = () => {
    const { dados } = this.props;

    return {
      tipoSolicitacao: dados.get('tipoSolicitacao'),
      cnpj_cpf: dados.get('cnpj_cpf')
    };
  };

  render() {
    const {
      dados,
      onChangeDados,
      errors,
      permiteSolicitacaoAnonima
    } = this.props;

    return (
      <div>
        {permiteSolicitacaoAnonima === 'S' && (
          <div className="mt-xs">
            <Input
              label="Identificada"
              type="radio"
              name="tipoSolicitacao"
              onChange={onChangeDados}
              value="I"
              checked={dados.get('tipoSolicitacao') == 'I'}
            />

            <Input
              label="Anônima"
              type="radio"
              name="tipoSolicitacao"
              onChange={onChangeDados}
              value="A"
              checked={dados.get('tipoSolicitacao') == 'A'}
            />
          </div>
        )}

        {dados.get('tipoSolicitacao') == 'I' && (
          <Input
            label="CPF/CNPJ"
            type="text"
            name="cnpj_cpf"
            ref="cnpj_cpf"
            onChange={onChangeDados}
            value={dados.get('cnpj_cpf')}
            errors={errors}
          />
        )}
      </div>
    );
  }
}

const validationSchema = {
  rules: {
    cnpj_cpf: 'required_if:tipoSolicitacao,I|cnpj_cpf'
  },
  labels: {
    cnpj_cpf: 'CNPJ/CPF'
  }
};

FormSolicitacaoDetailTipo = Validation(validationSchema)(
  FormSolicitacaoDetailTipo
);

export default FormSolicitacaoDetailTipo;
