import Component from 'react-pure-render/component';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import NotificationSystem from 'react-notification-system';
import * as actions from '../ui/Actions.js';
import Spinner from './Spinner.react.js';
import ModalMessage from './ModalMessage.react.js';

class Information extends Component {
  static propTypes = {
    ui: PropTypes.object,
    notifications: PropTypes.array,
    removeNotification: PropTypes.func,
    addNotification: PropTypes.func,
    addErrorNotification: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    window.addEventListener('error', this.handleError, false);
  }

  UNSAFE_componentWillReceiveProps = nextProps => {
    const {
      ui: { notifications }
    } = nextProps;

    notifications.forEach(this.addNotification);
  };

  addNotification = notification => {
    if (!notification.modal) {
      const { removeNotification } = this.props;

      const internalNotification = {
        ...notification,
        position: 'tr',
        onRemove: () => {
          removeNotification(notification);
        }
      };

      this.refs.notificationSystem.addNotification(internalNotification);
    }
  };

  handleError = e => {
    const { addErrorNotification } = this.props;

    let error = {
      colno: e.colno,
      lineno: e.lineno,
      timeStamp: e.timeStamp,
      message: e.message
    };

    if (e.error) {
      error = { ...error, ...e.error, message: e.error.message };
    }

    if (error.message !== 'Script error.') {
      addErrorNotification(error.message, error);
    }
  };

  renderUiMessage = () => {
    const { ui } = this.props;

    if (ui.isLoading) {
      return <Spinner />;
    }
  };

  onCloseModal = notification => {
    const { removeNotification } = this.props;

    removeNotification(notification);
  };

  renderModalMessage = () => {
    const {
      ui: { notifications }
    } = this.props;
    let modalNotifications = notifications.filter(
      notification => notification.modal
    );

    const modals = modalNotifications.map(notification => {
      const key = JSON.stringify(notification);
      return (
        <ModalMessage
          title="Erro"
          message={notification.message}
          key={key}
          sender={notification}
          onClose={this.onCloseModal}
        />
      );
    });

    return <div>{modals}</div>;
  };

  render() {
    return (
      <div>
        {this.renderUiMessage()}
        {this.renderModalMessage()}
        <NotificationSystem ref="notificationSystem" allowHTML />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ui: state.ui
  };
}

export default connect(mapStateToProps, actions)(Information);
