import AsyncSelect from 'react-select/lib/Async';
import InputError from './InputError.react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import { createFilter } from 'react-select';

const customFilter = createFilter({
  ignoreCase: true,
  ignoreAccents: true
});

const customStyles = {
  option: base => ({
    ...base,
    cursor: 'pointer'
  }),
  control: (base, state) => {
    return {
      ...base,
      height: '28px',
      background: 'white',
      minHeight: '28px',
      border: state.isFocused ? '1px solid #5d5d5d' : '1px solid #D6D6D6',
      boxShadow: 'none',
      cursor: 'pointer',
      padding: '4px 0 4px 6px',
      '&:hover': {
        borderColor: 'none'
      },
      div: {
        height: '20px',
        margin: '0',
        padding: '0',
        color: '#5d5d5d',
        '> div': {
          fontSize: '13px',
          justifyContent: 'center',
          alignItems: 'center',
          paddingRight: '10px',
          span: {
            fontSize: '4px'
          }
        },
        span: {
          //arrow
          margin: '3px 0',
          '+ div': {
            padding: '0 5px'
          }
        }
      },
      '&.error': {
        borderColor: '#C7382D',
        '*': {
          color: '#C7382D'
        }
      }
    };
  },
  selectContainer: base => {
    return {
      ...base,
      height: '28px',
      marginRight: '5px'
    };
  },
  menu: base => {
    return {
      ...base,
      zIndex: 6
    };
  }
};

const customStylesDisabled = {
  control: base => {
    return {
      ...base,
      height: '28px',
      backgroundColor: '#dcdcdc',
      minHeight: '28px',
      border: '1px solid #D6D6D6',
      boxShadow: 'none',
      cursor: 'not-allowed',
      '&:hover': {
        borderColor: 'none'
      },
      div: {
        maxHeight: '28px'
      }
    };
  }
};

class AutoComplete extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onLoad: PropTypes.func.isRequired,
    valueKey: PropTypes.string,
    labelKey: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.any,
    errors: PropTypes.object,
    filterFields: PropTypes.array,
    optionRenderer: PropTypes.func,
    getOptionLabel: PropTypes.func,
    getOptionValue: PropTypes.func,
    defaultOptions: PropTypes.bool,
    disabled: PropTypes.bool,
    clearable: PropTypes.bool,
    noOptionsMessage: PropTypes.func,
    enableCache: PropTypes.bool,
    key: PropTypes.string
  };

  static defaultProps = {
    valueKey: 'id',
    labelKey: 'descricao',
    clearable: true,
    defaultOptions: true,
    enableCache: true
  };

  constructor(props) {
    super(props);
    this.state = {
      value: '',
      options: []
    };
  }

  handleChange = value => {
    const { name, valueKey, onChange } = this.props;
    const { defaultOptions, loadedOptions } = this.refs.select.state;
    const toItem = option => option.item;

    let item = null;
    if (value) {
      item = loadedOptions.length
        ? loadedOptions
            .map(toItem)
            .find(item => item[valueKey] == value[valueKey])
        : defaultOptions
            .map(toItem)
            .find(item => item[valueKey] == value[valueKey]);
    }

    if (onChange) {
      onChange({ name: name, value: item });
    } else {
      this.setState({ value: value });
    }
  };

  loadOptions = (inputValue, callback) => {
    const { onLoad, filterFields } = this.props;

    const loadParams = {
      value: inputValue
    };

    onLoad(loadParams)
      .then(({ value }) => this.transform(value))
      .then(data => {
        if (filterFields && filterFields.length > 0 && inputValue) {
          const filteredOptions = data.options.filter(option =>
            filterFields.some(field =>
              option[field].toLowerCase().includes(inputValue.toLowerCase())
            )
          );
          callback(filteredOptions);
        } else {
          callback(data.options);
        }
      })
      .catch(err => {
        callback(err);
      });
  };

  transform = payload => {
    const { valueKey, labelKey } = this.props;

    const content = payload.content || payload;

    const options = content.map(item => {
      return {
        [valueKey]: item[valueKey],
        [labelKey]: item[labelKey],
        item: item
      };
    });

    const data = {
      options,
      complete: payload.content ? payload.last : true
    };
    return data;
  };

  getValue = () => {
    const { value: propValue, labelKey, valueKey } = this.props;
    const { value: stateValue } = this.state;

    const value = propValue || stateValue;

    if (value instanceof Map) {
      return {
        [labelKey]: value.get(labelKey),
        [valueKey]: value.get(valueKey)
      };
    }

    return value;
  };

  resolveOptionsLabel = () => {
    const { labelKey, valueKey } = this.props;
    return option => {
      const label = option[labelKey] ? option[labelKey] : '';
      const value = option[valueKey]
        ? option[valueKey] instanceof Object
          ? ''
          : option[valueKey]
        : '';
      const hifen = label && value ? ' - ' : '';

      return value + hifen + label;
    };
  };

  render() {
    const {
      label,
      name,
      errors,
      disabled,
      clearable,
      valueKey,
      defaultOptions,
      onBlur,
      getOptionLabel,
      enableCache,
      key
    } = this.props;

    const getOptionsLabel = getOptionLabel
      ? getOptionLabel()
      : this.resolveOptionsLabel();
    const getOptionValue = () => option => option[valueKey];
    const errorProps = InputError.createError(name, errors);
    let value = this.getValue();
    if (value[valueKey] === undefined) {
      value = '';
    }

    return (
      <div className={`form-group ${errorProps.hasError}`}>
        <label className="label">{label}</label>

        <AsyncSelect
          ref="select"
          noOptionsMessage={() => 'Nenhum registro encontrado'}
          name={name}
          defaultOptions={defaultOptions}
          placeholder="Selecione..."
          loadOptions={this.loadOptions}
          value={value}
          defaultValue=""
          getOptionLabel={getOptionsLabel}
          getOptionValue={getOptionValue}
          onChange={this.handleChange}
          isLoading
          filterOptions={customFilter}
          isDisabled={disabled}
          isClearable={clearable}
          styles={disabled ? customStylesDisabled : customStyles}
          cacheOptions={enableCache}
          onBlur={onBlur}
          key={key}
        />
        <p> {errorProps.help} </p>
      </div>
    );
  }
}

export default AutoComplete;
