import { Map } from 'immutable';
import DatePicker from 'portaltransparencia-common/components/DatePicker.react.js';
import Input from 'portaltransparencia-common/components/EloInput.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import SearchPagination from 'portaltransparencia-common/components/SearchPagination.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import TableHeaderItem from 'portaltransparencia-common/components/TableHeaderItem.react';
import {
  onChange,
  resetCrud
} from 'portaltransparencia-common/crud/Actions.js';
import {
  addFilterUrl,
  addPagination
} from 'portaltransparencia-common/libs/AddFilter.js';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import PageHeader from '../components/PageHeader.react.js';
import NotasExplicativas from '../notaexplicativa/Form.react';
import * as actions from './Actions.js';

export class FormAfastamento extends Component {
  static propTypes = {
    afastamentos: PropTypes.object.isRequired,
    findAfastamentos: PropTypes.func.isRequired,
    filtros: PropTypes.object,
    resetCrud: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    tiposAfastamento: PropTypes.object.isRequired
  };

  componentDidMount() {
    const { onChange } = this.props;

    onChange('filtroAfastamentos', {
      name: 'afastamentoVigente',
      value: true
    });

    this.onConsultarAfastamentos();
  }

  montarFiltro = page => {
    const { entidade } = StorageService.getItem('userInfo');
    const { filtros } = this.props;

    let filter = '';
    filter = addFilterUrl(filter, `entidade=${entidade.id}`);

    if (filtros.get('nome')) {
      filter = addFilterUrl(filter, `nome=${filtros.get('nome')}`);
    }

    if (filtros.get('dataInicial') && filtros.get('dataInicial').length == 10) {
      filter = addFilterUrl(
        filter,
        `dataInicial=${filtros.get('dataInicial')}`
      );
    }

    if (filtros.get('dataFinal') && filtros.get('dataFinal').length == 10) {
      filter = addFilterUrl(filter, `dataFinal=${filtros.get('dataFinal')}`);
    }

    filter = addFilterUrl(
      filter,
      `afastamentoVigente=${
        !this.refs.afastamentoVigente ||
        this.refs.afastamentoVigente.getChecked()
      }`
    );

    if (filtros.get('tipoAfastamento')) {
      filter = addFilterUrl(
        filter,
        `tipoAfastamento=${filtros.get('tipoAfastamento')}`
      );
    }

    return addPagination(page, filter);
  };

  onConsultarAfastamentos = event => {
    const { afastamentos, loadTiposAfastamento } = this.props;
    const initialPage = afastamentos.set('number', 0);

    this.searchWithPage(initialPage);
    event && event.preventDefault();
    this.refs.panelFiltroAfastamentos &&
      this.refs.panelFiltroAfastamentos.collapse();

    loadTiposAfastamento();
  };

  searchWithPage = page => {
    const { findAfastamentos } = this.props;
    findAfastamentos(this.montarFiltro(page));
  };

  onResetFiltro = () => {
    const { resetCrud, findAfastamentos } = this.props;

    resetCrud('filtroAfastamentos');
    findAfastamentos('');
  };

  onChangeFiltro = event => {
    const { onChange } = this.props;
    onChange('filtroAfastamentos', event);
  };

  renderTiposAfastamento = item => {
    return (
      <option key={item.get('id')} value={item.get('id')}>
        {item.get('descricao')}
      </option>
    );
  };

  renderAfastamentos(afastamento, index) {
    return (
      <tr ref="row" key={index}>
        <td>{afastamento.get('matricula')}</td>
        <td>{afastamento.get('nome')}</td>
        <td>{afastamento.get('cargo')}</td>
        <td>{afastamento.get('departamento')}</td>
        <td>{afastamento.get('dataInicio')}</td>
        <td>{afastamento.get('dataFim')}</td>
        <td>{afastamento.get('tipoMovimentacao')}</td>
      </tr>
    );
  }

  render() {
    const { filtros, afastamentos, tiposAfastamento } = this.props;

    if (!afastamentos.has('content')) {
      return null;
    }

    return (
      <section>
        <form>
          <PageHeader location={this.props.location} />

          <NotasExplicativas pathname={this.props.location.pathname} />

          <Panel
            title="Consultar Afastamentos"
            isForm
            mobileClose
            ref="panelFiltroAfastamentos"
          >
            <Row>
              <Col xs={12} sm={12} md={12}>
                <Input
                  id="nome"
                  type="text"
                  name="nome"
                  label="Nome"
                  placeholder="Informe o nome"
                  value={filtros.get('nome')}
                  onChange={this.onChangeFiltro}
                />
              </Col>
              <Col xs={12} sm={12} md={3}>
                <DatePicker
                  name="dataInicial"
                  ref="dataInicial"
                  label="Data Inicial"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('dataInicial')}
                />
              </Col>
              <Col xs={12} sm={12} md={3}>
                <DatePicker
                  name="dataFinal"
                  ref="dataFinal"
                  label="Data Final"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('dataFinal')}
                />
              </Col>
              <Col xs={12} sm={12} md={3}>
                <Input
                  id="tipoAfastamento"
                  label="Tipo de Afastamento"
                  type="select"
                  name="tipoAfastamento"
                  ref="tipoAfastamento"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('tipoAfastamento')}
                >
                  <option value="">Selecione</option>
                  {tiposAfastamento.map(this.renderTiposAfastamento)}
                </Input>
              </Col>
              <Col xs={12} sm={12} md={12}>
                <label className="label"></label>
                <Input
                  id="afastamentoVigente"
                  label="Somente vigentes"
                  type="checkbox"
                  name="afastamentoVigente"
                  ref="afastamentoVigente"
                  onChange={this.onChangeFiltro}
                  checked={filtros.get('afastamentoVigente')}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={12}>
                <div className="form-group">
                  <label className="label" />

                  <div className="btn-group">
                    <button
                      id="btnFiltrar"
                      className="btn module-color icon-right"
                      ref="filtrar"
                      onClick={this.onConsultarAfastamentos}
                    >
                      Pesquisar
                      <em className="fa fa-search" />
                    </button>
                    <button
                      id="btnReset"
                      className="btn neutral icon-right inline no-text"
                      type="button"
                      onClick={this.onResetFiltro}
                    >
                      Limpar
                      <em className="fa fa-times" />
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </Panel>
        </form>

        <Panel isForm expansible={false} tableResponsive>
          <div className="panel-table-outer">
            <table className="panel-table striped fancy" id="tableAfastamentos">
              <TableHeader empty={afastamentos.get('content').isEmpty()}>
                <tr>
                  <TableHeaderItem
                    field="matricula"
                    label="Matrícula"
                    page={afastamentos}
                    searchWithPage={this.searchWithPage}
                  />
                  <TableHeaderItem
                    field="nome"
                    label="Nome"
                    page={afastamentos}
                    searchWithPage={this.searchWithPage}
                  />
                  <TableHeaderItem
                    field="cargo"
                    label="Cargo"
                    page={afastamentos}
                    searchWithPage={this.searchWithPage}
                  />
                  <TableHeaderItem
                    field="departamento"
                    label="Departamento"
                    page={afastamentos}
                    searchWithPage={this.searchWithPage}
                  />
                  <TableHeaderItem
                    field="dataInicial"
                    label="Data Início"
                    page={afastamentos}
                    searchWithPage={this.searchWithPage}
                  />
                  <TableHeaderItem
                    field="dataFim"
                    label="Data Final"
                    page={afastamentos}
                    searchWithPage={this.searchWithPage}
                  />
                  <TableHeaderItem
                    field="tipoMovimentacao"
                    label="Afastamento"
                    page={afastamentos}
                    searchWithPage={this.searchWithPage}
                  />
                </tr>
              </TableHeader>
              <tbody id="tbodyAfastamentos">
                {afastamentos.get('content').map(this.renderAfastamentos)}
              </tbody>
            </table>
          </div>
          <SearchPagination
            page={afastamentos}
            searchWithPage={this.searchWithPage}
          />
        </Panel>
      </section>
    );
  }
}
function mapStateToProps(state) {
  return {
    afastamentos: state.afastamentos.get('afastamentos'),
    tiposAfastamento: state.afastamentos.get('tiposAfastamento'),
    filtros: state.crud.getIn(['filtroAfastamentos', 'currentRecord'], Map({}))
  };
}

const allActions = {
  ...actions,
  onChange,
  resetCrud
};

export default connect(mapStateToProps, allActions)(FormAfastamento);
