import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';
import StringUtils from 'portaltransparencia-common/libs/StringUtils.js';
import * as types from './Types.js';

const initialState = fromJS({
  glossario: [],
  glossarioFiltrado: [],
  letraSelecionada: ''
});

function glossarioReducer(state = initialState, action) {
  switch (action.type) {
    case types.GLOSSARIO_START: {
      return startLoad(state);
    }

    case types.GLOSSARIO_ERROR: {
      return endLoad(state);
    }

    case types.GLOSSARIO_SUCCESS: {
      return endLoad(state.set('glossario', fromJS(action.payload)));
    }

    case types.LETRA_SELECIONADA: {
      let glossarioFiltrado = state
        .get('glossario')
        .filter(
          g =>
            StringUtils.removeAcento(
              g.get('topico').toLowerCase().substring(0, 1)
            ).toUpperCase() == action.payload.toUpperCase()
        );

      return endLoad(
        state
          .set('glossarioFiltrado', fromJS(glossarioFiltrado))
          .set('letraSelecionada', action.payload)
      );
    }
  }

  return state;
}

export default glossarioReducer;
