import { Map } from 'immutable';
import BotaoImprimir from 'portaltransparencia-common/components/BotaoImprimir.react.js';
import ExportButtonGroup from 'portaltransparencia-common/components/ExportButtonGroup.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import configuration from 'portaltransparencia-common/config/Config.js';
import { getAllValuesFromUrlSearchParams } from 'portaltransparencia-common/libs/URLUtils';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Helmet from 'react-helmet';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';
import {
  onChange,
  resetCrud
} from 'portaltransparencia-common/crud/Actions.js';
import { addFilterUrl } from 'portaltransparencia-common/libs/AddFilter.js';
import PageHeader from '../components/PageHeader.react.js';
import CabecalhoEntidade from '../entidades/CabecalhoEntidade.react.js';
import UltimaAtualizacao from '../ultimaatualizacao/Form.react.js';
import * as actions from './Actions.js';
import { addErrorNotification } from 'portaltransparencia-common/ui/Actions.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import SumListField from 'portaltransparencia-common/components/SumListField.react.js';

export class RepassesRecebidosDetalhes extends Component {
  static propTypes = {
    repassesMes: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    findRepassesRecebidosMes: PropTypes.func,
    filtros: PropTypes.object,
    onChange: PropTypes.func,
    resetCrud: PropTypes.func,
    addErrorNotification: PropTypes.func.isRequired
  };

  componentDidMount() {
    const { findRepassesRecebidosMes, resetCrud } = this.props;

    resetCrud('filtroRepasses');

    const filtro = this.montarFiltro();
    findRepassesRecebidosMes(filtro);
  }

  onExporta = (exportType, openTab, windowParams) => {
    const { apiURL } = configuration;
    const { location, repassesMes, addErrorNotification } = this.props;

    if (!repassesMes.isEmpty()) {
      const params = getAllValuesFromUrlSearchParams(location);

      let repasse = `entidade=${params.entidade}&exercicio=${params.exercicio}&tipo=${params.tipo}&mes=${params.mes}`;

      window.open(
        `${apiURL}/api/repasses/report/mes?${repasse}&exportType=${exportType}`,
        openTab,
        windowParams
      );
    } else {
      addErrorNotification(
        'A consulta não apresentou resultado. Não é possível exportar para o formato selecionado!'
      );
    }
  };

  onChangeFiltro = event => {
    const { onChange } = this.props;

    onChange('filtroRepasses', event);
  };

  onResetFiltro = () => {
    const { resetCrud } = this.props;

    resetCrud('filtroRepasses');
    this.onConsulta();
  };

  montarFiltro = function () {
    const { location } = this.props;
    const params = getAllValuesFromUrlSearchParams(location);

    let filter = '';
    filter = addFilterUrl(filter, `entidade=${params.entidade}`);
    filter = addFilterUrl(filter, `exercicio=${params.exercicio}`);

    if (params.mes) {
      filter = addFilterUrl(filter, `mes=${params.mes}`);
    }

    return filter;
  };

  onConsulta = () => {
    const { findRepassesRecebidosMes } = this.props;

    const filtro = this.montarFiltro();
    findRepassesRecebidosMes(filtro);
  };

  renderRepasses = data => {
    const key = `${data.get('data')}_${data.get('valor')}_${data.get(
      'conta'
    )}_${data.get('idContaCorrente')}_${data.get('nroLancamento')}`;
    return (
      <tr key={key}>
        <td colSpan={2}>{DateUtils.formatDateTimeShort(data.get('data'))} </td>
        <td colSpan={9}>{data.get('local')}</td>
        <td colSpan={9}>{data.get('compHist')}</td>
        <td colSpan={1} className="right">
          <NumberFormatter value={data.get('valor')} />
        </td>
      </tr>
    );
  };

  render() {
    const { repassesMes, location } = this.props;
    const params = getAllValuesFromUrlSearchParams(location);

    return (
      <section>
        <Helmet
          title={
            'Repasses Mensais Recebidos em ' +
            DateUtils.getMonthDescription(params.mes)
          }
        />

        <PageHeader location={location} />

        <CabecalhoEntidade />

        <Panel
          title={
            'Repasses Mensais Recebidos em ' +
            DateUtils.getMonthDescription(params.mes)
          }
          isForm
          expansible={false}
          tableResponsive
        >
          <Row>
            <Col xs={6}>
              <UltimaAtualizacao modulo="3" />
            </Col>
            <Col xs={6}>
              <ExportButtonGroup onClick={this.onExporta} visible={true} />
            </Col>
          </Row>

          <BotaoImprimir />

          <section>
            <div className="panel-table-outer">
              <table className="panel-table striped fancy">
                <TableHeader width="100%" empty={repassesMes.isEmpty()}>
                  <tr>
                    <th colSpan={2}>Data do Repasse</th>
                    <th colSpan={9}>Banco</th>
                    <th colSpan={9}>Histórico</th>
                    <th style={{ textAlign: 'right' }} colSpan={1}>
                      Valor
                    </th>
                  </tr>
                </TableHeader>
                <tbody>{repassesMes.map(this.renderRepasses)}</tbody>
                {!repassesMes.isEmpty() && (
                  <tfoot>
                    <tr>
                      <td colSpan={2}></td>
                      <td colSpan={9}></td>
                      <td colSpan={9}></td>
                      <td colSpan={1} className="right">
                        <i>Total: </i>
                        <SumListField fieldName="valor" list={repassesMes} />
                      </td>
                    </tr>
                  </tfoot>
                )}
              </table>
            </div>
          </section>
        </Panel>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    filtros: state.crud.getIn(['filtroRepasses', 'currentRecord'], Map({})),
    repassesMes: state.repasseRecebido.get('repassesMes')
  };
}

const allActions = {
  ...actions,
  onChange,
  resetCrud,
  addErrorNotification
};

export default connect(mapStateToProps, allActions)(RepassesRecebidosDetalhes);
