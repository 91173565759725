import * as types from './Types.js';

export function searchTerceirizados(filter) {
  let url = '/empresa-terceirizada';

  if (filter !== '') {
    url += '?' + filter;
  }

  return ({ fetch }) => ({
    type: types.CONSULTA_TERCEIRIZADOS,
    payload: {
      promise: fetch(url)
    }
  });
}

export function resetConsulta() {
  return {
    type: types.RESET_CONSULTA_TERCEIRIZADOS,
    payload: {}
  };
}

export function reset() {
  return {
    type: types.FORMULARIO_SOLICITACAO_NOTIFICACAO_RESET
  };
}
