import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';

import * as types from './Types.js';

const initialState = fromJS({
  totalacesso: {},
  totalDiarioOficial: {}
});

function totalAcessoReducer(state = initialState, action) {
  switch (action.type) {
    case types.TOTALACESSOS_START: {
      return startLoad(state);
    }

    case types.TOTALACESSOS_SUCCESS: {
      return endLoad(state.set('totalacesso', fromJS(action.payload)));
    }

    case types.TOTALACESSOS_ERROR: {
      return endLoad(state);
    }

    case types.TOTALACESSOS_DIARIO_OFICIAL_START: {
      return startLoad(state);
    }

    case types.TOTALACESSOS_DIARIO_OFICIAL_SUCCESS: {
      return endLoad(state.set('totalDiarioOficial', fromJS(action.payload)));
    }

    case types.TOTALACESSOS_DIARIO_OFICIAL_ERROR: {
      return endLoad(state);
    }
  }

  return state;
}

export default totalAcessoReducer;
