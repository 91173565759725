import DisplayData from 'portaltransparencia-common/components/displaydata/DisplayData.react.js';
import DisplayDataItem from 'portaltransparencia-common/components/displaydata/DisplayDataItem.react.js';
import DisplayDataRow from 'portaltransparencia-common/components/displaydata/DisplayDataRow.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import { getAllValuesFromUrlSearchParams } from 'portaltransparencia-common/libs/URLUtils';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';
import { Col, Row } from 'react-bootstrap';

import PageHeader from '../components/PageHeader.react.js';
import * as actions from './Actions.js';
import { Ellipsis } from 'portaltransparencia-common/components/Ellipsis.react.js';
import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react.js';
import configuration from 'portaltransparencia-common/config/Config.js';
import storageService from 'portaltransparencia-common/libs/StorageService.js';
import ExportButtonGroup from 'portaltransparencia-common/components/ExportButtonGroup.react.js';

export class FormPcaDetalhe extends Component {
  static propTypes = {
    pca: PropTypes.object.isRequired,
    findPca: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired
  };

  componentDidMount() {
    const { location, findPca } = this.props;
    const params = getAllValuesFromUrlSearchParams(location);

    findPca(params.entidade, params.exercicio, params.planoContratacao);
  }

  renderItens = data => {
    const key = data.get('planoContratacao') + '_' + data.get('numeroItem');

    return (
      <TableActions ref="row" key={key} data={data}>
        <td>
          <Ellipsis
            value={`${data.get('codigoItem')} - ${data.get('descricao')}`}
            maxLength={50}
          />
        </td>
        <td>{DateUtils.transform(data.get('dataPca'))}</td>
        <td>{data.get('categoriaItemPcaDescricao')}</td>
        <td>{data.get('classificacaoCatalogo')}</td>
        <td>{`${data.get('classificacaoSuperiorCodigo')} - ${data.get(
          'classificacaoSuperiorNome'
        )}`}</td>
        <td className="right">
          <NumberFormatter value={data.get('quantidade')} format="0,0.0000" />
        </td>
        <td className="right">
          <NumberFormatter
            value={data.get('valorUnitario')}
            format="0,0.0000"
          />
        </td>
        <td className="right">
          <NumberFormatter value={data.get('valorTotal')} format="0,0.0000" />
        </td>
        <td className="right">
          <NumberFormatter
            value={data.get('valorOrcamentoExercicio')}
            format="0,0.0000"
          />
        </td>
      </TableActions>
    );
  };

  onExporta = (exportType, openTab, windowParams) => {
    const { apiURL } = configuration;
    const { pca, addErrorNotification } = this.props;
    const { entidade, exercicio } = storageService.getItem('userInfo');

    if (pca) {
      window.open(
        `${apiURL}/api/pca/report/itens?entidade=${entidade.id}&exercicio=${
          exercicio.id
        }&planoContratacao=${pca.get(
          'planoContratacao'
        )}&exportType=${exportType}`,
        openTab,
        windowParams
      );
    } else {
      addErrorNotification(
        'A consulta não apresentou resultado. Não é possível exportar para o formato selecionado!'
      );
    }
  };

  render() {
    const { pca, location } = this.props;
    return (
      <section>
        <PageHeader location={location} />

        <Panel title={'PCA - Plano de Contratação Anual'}>
          <DisplayData border>
            <DisplayDataRow>
              <DisplayDataItem
                sm={2}
                title="Número"
                value={pca.get('planoContratacao')}
                id="planoContratacao"
              />
              <DisplayDataItem
                sm={2}
                title="Data"
                value={DateUtils.transform(pca.get('dataPca'))}
                id="dataPca"
              />
              <DisplayDataItem
                sm={4}
                title="Status"
                value={pca.get('statusDescricao')}
                id="statusDescricao"
              />
              <DisplayDataItem
                sm={6}
                title="Órgão"
                value={pca.get('unidadeDescricao')}
                id="unidadeDescricao"
              />
            </DisplayDataRow>
          </DisplayData>
          <Row>
            <Col xs={12}>
              <ExportButtonGroup onClick={this.onExporta} visible={true} />
            </Col>
          </Row>
        </Panel>
        {pca && pca.get('itens') && (
          <Panel isForm tableResponsive expansible={false}>
            <div className="panel-table-outer">
              <table className="panel-table striped fancy">
                <TableHeader
                  empty={pca.get('itens').isEmpty()}
                  text="Sem registros"
                >
                  <tr>
                    <th>Item</th>
                    <th>Data Desejada</th>
                    <th>Categoria PCA</th>
                    <th>Classificação</th>
                    <th>Classificação Superior</th>
                    <th className="right">Quantidade</th>
                    <th className="right">Valor Unitário</th>
                    <th className="right">Valor Total</th>
                    <th className="right">Valor Orçamento</th>
                    <th />
                  </tr>
                </TableHeader>
                <tbody>{pca.get('itens').map(this.renderItens)}</tbody>
              </table>
            </div>
          </Panel>
        )}
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    pca: state.pca.get('pca')
  };
}

const allActions = {
  ...actions
};

export default connect(mapStateToProps, allActions)(FormPcaDetalhe);
