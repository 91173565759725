import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import { withRouter } from 'react-router';

export class LinkToEmpenho extends Component {
  static propTypes = {
    entidade: PropTypes.number,
    exercicio: PropTypes.number,
    empenho: PropTypes.number
  };

  showEmpenho = () => {
    const { entidade, exercicio, empenho, history } = this.props;
    history.push(
      `/empenhos/detalhe?search=id.entidade==${entidade}&entidade=${entidade}&exercicio=${exercicio}&empenho=${empenho}`
    );
  };

  render() {
    const { entidade, empenho, exercicio } = this.props;

    if (entidade && exercicio && empenho) {
      return (
        <a
          style={{ cursor: 'pointer' }}
          onClick={this.showEmpenho}
        >{`${empenho}/${exercicio}`}</a>
      );
    }

    return null;
  }
}

export default withRouter(LinkToEmpenho);
