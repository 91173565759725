import BotaoImprimir from 'portaltransparencia-common/components/BotaoImprimir.react.js';
import DisplayData from 'portaltransparencia-common/components/displaydata/DisplayData.react.js';
import DisplayDataItem from 'portaltransparencia-common/components/displaydata/DisplayDataItem.react.js';
import DisplayDataRow from 'portaltransparencia-common/components/displaydata/DisplayDataRow.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

import CabecalhoEntidade from '../entidades/CabecalhoEntidade.react.js';

export default class AjudaCustoServidorDetalhe extends Component {
  static propTypes = {
    servidor: PropTypes.object.isRequired
  };

  render() {
    const { servidor } = this.props;

    return (
      <section>
        <CabecalhoEntidade />

        <BotaoImprimir />

        <Panel title="Detalhes de Ajuda de Custo">
          <DisplayData border>
            <DisplayDataRow>
              <DisplayDataItem
                sm={6}
                title="Matrícula"
                value={servidor.get('matricula')}
              />
              <DisplayDataItem
                sm={6}
                title="Servidor"
                value={servidor.get('nome')}
              />
            </DisplayDataRow>

            <DisplayDataRow>
              <DisplayDataItem
                sm={6}
                title="Código do Cargo"
                value={servidor.get('cargo')}
              />
              <DisplayDataItem
                sm={6}
                title="Nome do Cargo"
                value={servidor.get('cargoDesc')}
              />
            </DisplayDataRow>
          </DisplayData>
        </Panel>
      </section>
    );
  }
}
