export const LOGIN = 'LOGIN';
export const LOGIN_START = 'LOGIN_START';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

export const LOGOUT_START = 'LOGOUT_START';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const CHANGE_USERINFO = 'CHANGE_USERINFO';
export const CHANGE_USERINFO_SUCCESS = 'CHANGE_USERINFO_SUCCESS';

export const LOAD_USER_INFO = 'LOAD_USER_INFO';
export const LOAD_USER_INFO_START = 'LOAD_USER_INFO_START';
export const LOAD_USER_INFO_SUCCESS = 'LOAD_USER_INFO_SUCCESS';

export const LOAD_USUARIO_LOGADO = 'LOAD_USUARIO_LOGADO';
export const LOAD_USUARIO_LOGADO_START = 'LOAD_USUARIO_LOGADO_START';
export const LOAD_USUARIO_LOGADO_SUCCESS = 'LOAD_USUARIO_LOGADO_SUCCESS';
export const LOAD_USUARIO_LOGADO_ERROR = 'LOAD_USUARIO_LOGADO_ERROR';
