import Panel from 'portaltransparencia-common/components/Panel.react.js';
import { getAllValuesFromUrlSearchParams } from 'portaltransparencia-common/libs/URLUtils';
import { PARAM_EXIBE_GASTOS_TOTAIS } from 'portaltransparencia-common/parametros/ParametrosPortalTransparencia';
import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import { downloadArquivo } from '../arquivo/Actions.js';
import Arquivo from '../arquivo/Arquivo.react.js';
import PageHeader from '../components/PageHeader.react.js';
import ParametroService from '../parametros/ParametroService.js';
import * as actions from './Actions.js';
import DiariaDetalheInformacao from './DiariaDetalheInformacao.react.js';

export class FormDiariaDetail extends Component {
  static propTypes = {
    diaria: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    downloadArquivo: PropTypes.func.isRequired,
    findDiaria: PropTypes.func.isRequired,
    arquivos: PropTypes.object.isRequired,
    findArquivos: PropTypes.func.isRequired,
    paramMostrarGastosTotais: PropTypes.string.isRequired
  };

  componentDidMount() {
    const { location, findDiaria, findArquivos } = this.props;

    const params = getAllValuesFromUrlSearchParams(location);

    findDiaria(
      params.entidade,
      params.exercicio,
      params.empenho,
      params.noLiquidacao,
      params.unidadeOrcamentaria
    );
    findArquivos(
      params.entidade,
      params.exercicio,
      params.empenho,
      params.noLiquidacao
    );
  }

  render() {
    const {
      diaria,
      downloadArquivo,
      arquivos,
      paramMostrarGastosTotais
    } = this.props;

    return (
      <div>
        <Helmet title={`Diárias - ${diaria.get('nome')}`} />

        <PageHeader
          location={this.props.location}
          textoDetalhe={`Diária: ${diaria.get('empenho')}/${diaria.get(
            'exercicio'
          )} - ${diaria.get('nome')}`}
        />

        <DiariaDetalheInformacao
          diaria={diaria}
          mostrarGastosTotais={paramMostrarGastosTotais === 'S'}
        />

        <div className="hidden-print">
          <Panel title="Anexos" isTable tableResponsive>
            <Arquivo arquivos={arquivos} onDownload={downloadArquivo} />
          </Panel>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    diaria: state.diaria.get('diaria'),
    arquivos: state.diaria.get('arquivos'),
    paramMostrarGastosTotais: ParametroService.getValorParametro(
      state,
      PARAM_EXIBE_GASTOS_TOTAIS
    )
  };
}

const allActions = {
  ...actions,
  downloadArquivo
};

export default connect(mapStateToProps, allActions)(FormDiariaDetail);
