import { Map } from 'immutable';
import AutoComplete from 'portaltransparencia-common/components/AutoComplete.react.js';
import DatePicker from 'portaltransparencia-common/components/DatePicker.react.js';
import Input from 'portaltransparencia-common/components/EloInput.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import SearchPagination from 'portaltransparencia-common/components/SearchPagination.react.js';
import Tab from 'portaltransparencia-common/components/Tab.react.js';
import Tabs from 'portaltransparencia-common/components/Tabs.react.js';
import {
  changeFieldValue,
  onChange,
  resetCrud
} from 'portaltransparencia-common/crud/Actions.js';
import {
  addFilterUrl,
  addPagination
} from 'portaltransparencia-common/libs/AddFilter.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import { getValueFromUrlSearchParams } from 'portaltransparencia-common/libs/URLUtils';
import {
  PARAM_DESPESAS_ENTIDADES_DIFERENTES,
  PARAM_DESPESAS_PAGAS_VALOR
} from 'portaltransparencia-common/parametros/ParametrosPortalTransparencia';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import PageHeader from '../components/PageHeader.react.js';
import NotasExplicativas from '../notaexplicativa/Form.react';
import ParametroService from '../parametros/ParametroService.js';
import UltimaAtualizacaoByTable from '../ultimaatualizacao/UltimaAtualizacaoByTable.react.js';
import * as actions from './Actions.js';
import LiquidacoesListagem from './LiquidacoesListagem.react.js';
import configuration from 'portaltransparencia-common/config/Config.js';
import { addErrorNotification } from 'portaltransparencia-common/ui/Actions.js';

export class FormLiquidacoes extends Component {
  static propTypes = {
    pageLiquidacoesPagar: PropTypes.object.isRequired,
    findLiquidacoesPagar: PropTypes.func.isRequired,
    findTotalLiquidacoesPagar: PropTypes.func.isRequired,
    totalLiquidacoesPagar: PropTypes.object.isRequired,

    pageLiquidacoesPagas: PropTypes.object.isRequired,
    findLiquidacoesPagas: PropTypes.func.isRequired,
    findTotalLiquidacoesPagas: PropTypes.func.isRequired,
    totalLiquidacoesPagas: PropTypes.object.isRequired,

    filtros: PropTypes.object,
    onChange: PropTypes.func,
    changeFieldValue: PropTypes.func,
    location: PropTypes.object.isRequired,
    resetCrud: PropTypes.func.isRequired,
    valorParametro: PropTypes.string,
    params: PropTypes.object,
    paramDespesasPagasValor: PropTypes.string,
    paramDespesasEntidadesDif: PropTypes.string,
    findFonteRecurso: PropTypes.func.isRequired,
    errors: PropTypes.object,
    isIntegradoOxy: PropTypes.bool,
    addErrorNotification: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.onResetFiltro();
  }

  componentWillUnmount() {
    const { resetCrud } = this.props;
    resetCrud('filtroLiquidacoes');
  }

  loadInitialFiltros = () => {
    const { onChange } = this.props;
    const { exercicio } = StorageService.getItem('userInfo');

    onChange('filtroLiquidacoes', {
      name: 'dataInicial',
      value: `${exercicio.id}-01-01`
    });

    onChange('filtroLiquidacoes', {
      name: 'dataFinal',
      value: `${exercicio.id}-12-31`
    });
  };

  PAGAR = 'pagar';
  PAGAS = 'pagas';

  pagarSortedUrl;
  pagasSortedUrl;

  findFonteRecurso = () => {
    const { findFonteRecurso } = this.props;
    return findFonteRecurso(this.montarFiltro());
  };

  permitirCarregarTelaPorTipo = tipoTela => {
    const {
      match: {
        params: { tipo }
      }
    } = this.props;

    return tipo == 'todos' || tipo == tipoTela;
  };

  carregarLiquidacoesPagar = () => {
    const { pageLiquidacoesPagar } = this.props;
    const initialPage = pageLiquidacoesPagar.set('number', 0);

    this.searchWithPageLiquidacoesPagar(initialPage);
  };

  carregarLiquidacoesPagas = () => {
    const { pageLiquidacoesPagas } = this.props;
    const initialPage = pageLiquidacoesPagas.set('number', 0);

    this.searchWithPageLiquidacoesPagas(initialPage);
  };

  consultarLiquidacoes = event => {
    if (this.permitirCarregarTelaPorTipo(this.PAGAR)) {
      this.consultarLiquidacoesPagar(event);
    }

    if (this.permitirCarregarTelaPorTipo(this.PAGAS)) {
      this.consultarLiquidacoesPagas(event);
    }

    this.refs.panelFiltroLiquidacoes &&
      this.refs.panelFiltroLiquidacoes.collapse();
  };

  consultarLiquidacoesPagar = event => {
    this.carregarLiquidacoesPagar();
    event && event.preventDefault();
  };

  consultarLiquidacoesPagas = event => {
    this.carregarLiquidacoesPagas();
    event && event.preventDefault();
  };

  onChangeFiltro = event => {
    const { onChange } = this.props;
    onChange('filtroLiquidacoes', event);
  };

  onResetFiltro = () => {
    const { resetCrud } = this.props;

    resetCrud('filtroLiquidacoes');

    this.loadInitialFiltros();

    if (this.permitirCarregarTelaPorTipo(this.PAGAR)) {
      this.carregarLiquidacoesPagar();
    }

    if (this.permitirCarregarTelaPorTipo(this.PAGAS)) {
      this.carregarLiquidacoesPagas();
    }
  };

  onMontaFiltroString = filter => {
    const { entidade, exercicio } = StorageService.getItem('userInfo');
    const { filtros, location, paramDespesasEntidadesDif } = this.props;
    const fonteRecurso = filtros.get('fonteRecurso');

    filter = '1=1';

    if (
      (location &&
        location.pathname &&
        location.pathname.includes('liquidacoes-covid/todos')) ||
      (location &&
        location.pathname &&
        location.pathname.includes('liquidacoes-covid/pagas'))
    ) {
      if (paramDespesasEntidadesDif !== 'S') {
        filter = addFilterUrl(filter, 'entidade=' + entidade.id);
      }
      filter = addFilterUrl(filter, "isCovid='S'");
    } else {
      filter = addFilterUrl(filter, 'entidade=' + entidade.id);
    }

    if (
      (location &&
        location.pathname &&
        location.pathname.includes('liquidacoes-fundeb/todos')) ||
      (location &&
        location.pathname &&
        location.pathname.includes('liquidacoes-fundeb/pagas'))
    ) {
      if (paramDespesasEntidadesDif !== 'S') {
        filter = addFilterUrl(filter, 'entidade=' + entidade.id);
      }
      filter = addFilterUrl(filter, "isFundeb='S'");
    } else {
      filter = addFilterUrl(filter, 'entidade=' + entidade.id);
    }

    if (filtros.get('cnpj')) {
      filter = addFilterUrl(filter, `cnpjCpf=${filtros.get('cnpj')}`);
    }

    if (filtros.get('nome')) {
      filter = addFilterUrl(
        filter,
        `nome=${encodeURIComponent(filtros.get('nome'))}`
      );
    }

    if (filtros.get('notaFiscal')) {
      filter = addFilterUrl(filter, `notaFiscal=${filtros.get('notaFiscal')}`);
    }

    if (filtros.get('empenho')) {
      filter = addFilterUrl(filter, `empenho=${filtros.get('empenho')}`);
    }

    if (filtros.get('dataInicial') && filtros.get('dataInicial').length == 10) {
      let validValue = DateUtils.transform(
        filtros.get('dataInicial'),
        'yy-mm-dd',
        'yy-mm-dd'
      );
      filter = addFilterUrl(filter, `dataInicial=${validValue}`);
    }

    if (filtros.get('dataFinal') && filtros.get('dataFinal').length == 10) {
      let validValue = DateUtils.transform(
        filtros.get('dataFinal'),
        'yy-mm-dd',
        'yy-mm-dd'
      );
      filter = addFilterUrl(filter, `dataFinal=${validValue}`);
    }

    if (filtros.get('isPorExercicio')) {
      filter = addFilterUrl(
        filter,
        `isPorExercicio=${true}&exercicio=${exercicio.id}`
      );
    }

    if (filtros.get('isPendentes')) {
      filter = addFilterUrl(
        filter,
        `isPendentes=${true}&exercicio=${exercicio.id}`
      );
    }

    if (filtros.get('fonteRecurso')) {
      filter = addFilterUrl(
        filter,
        `fonteRecurso=${fonteRecurso.get('codigo')}`
      );
    }

    return filter;
  };

  onExportaPagar = (exportType, openTab, windowParams) => {
    const { apiURL } = configuration;
    const { pageLiquidacoesPagar, addErrorNotification } = this.props;
    const { exercicio } = StorageService.getItem('userInfo');
    const { entidade } = StorageService.getItem('userInfo');

    if (pageLiquidacoesPagar.get('content').size > 0) {
      let filterLiquidacoesPagar = '';

      filterLiquidacoesPagar = this.onMontaFiltroString(filterLiquidacoesPagar);

      let filtro = `${filterLiquidacoesPagar}&exportType=${exportType}&entidade=${entidade.id}&exercicio=${exercicio.id}`;

      if (this.pagarSortedUrl) {
        filtro = `${filtro}&sort=${this.pagarSortedUrl}`;
      }

      window.open(
        `${apiURL}/api/liquidacoes/${this.PAGAR}/report?${filtro}`,
        openTab,
        windowParams
      );
    } else {
      addErrorNotification(
        'A consulta não apresentou resultado. Não é possível exportar para o formato selecionado!'
      );
    }
  };

  onExportaPagas = (exportType, openTab, windowParams) => {
    const { apiURL } = configuration;
    const { pageLiquidacoesPagas, addErrorNotification } = this.props;
    const { exercicio } = StorageService.getItem('userInfo');
    const { entidade } = StorageService.getItem('userInfo');

    if (pageLiquidacoesPagas.get('content').size > 0) {
      let filterLiquidacoesPagas = '';

      filterLiquidacoesPagas = this.onMontaFiltroString(filterLiquidacoesPagas);

      let filtro = `${filterLiquidacoesPagas}&exportType=${exportType}&entidade=${entidade.id}&exercicio=${exercicio.id}`;

      if (this.pagasSortedUrl) {
        filtro = `${filtro}&sort=${this.pagasSortedUrl}`;
      }

      window.open(
        `${apiURL}/api/liquidacoes/${this.PAGAS}/report?${filtro}`,
        openTab,
        windowParams
      );
    } else {
      addErrorNotification(
        'A consulta não apresentou resultado. Não é possível exportar para o formato selecionado!'
      );
    }
  };

  montarFiltro = function (page) {
    let filter = '';
    filter = this.onMontaFiltroString(filter);

    return addPagination(page, filter);
  };

  searchWithPageLiquidacoesPagar = page => {
    const {
      findLiquidacoesPagar,
      findTotalLiquidacoesPagar,
      changeFieldValue,
      location,
      match: {
        params: { tipo }
      }
    } = this.props;

    const { exercicio } = StorageService.getItem('userInfo');

    let filtro = '';
    const cnpjCpf = getValueFromUrlSearchParams(location, 'cnphCpf');
    if (cnpjCpf) {
      changeFieldValue('filtroLiquidacoes', 'cnpj', cnpjCpf);
      filtro = location.search.substring(1) + '&';
    }

    if (page.get('sort') && typeof page.get('sort') === 'string') {
      this.pagarSortedUrl = page.get('sort');
    }

    filtro = `${filtro} and tipo=${tipo} ${this.montarFiltro(page)}&exercicio=${
      exercicio.id
    }`;

    findLiquidacoesPagar(filtro);
    findTotalLiquidacoesPagar(filtro);
  };

  findTableDataUltimaAtualizacao = () => {
    const { location } = this.props;
    if (
      (location &&
        location.pathname &&
        location.pathname.includes('liquidacoes/pagas')) ||
      (location &&
        location.pathname &&
        location.pathname.includes('liquidacoes-covid/pagas')) ||
      (location &&
        location.pathname &&
        location.pathname.includes('liquidacoes-fundeb/pagas'))
    ) {
      return 'pagamento';
    } else {
      return 'liquidacao';
    }
  };

  searchWithPageLiquidacoesPagas = page => {
    const {
      findLiquidacoesPagas,
      findTotalLiquidacoesPagas,
      changeFieldValue,
      location,
      match: {
        params: { tipo }
      }
    } = this.props;
    const { exercicio } = StorageService.getItem('userInfo');

    let filtro = '';
    const cnpjCpf = getValueFromUrlSearchParams(location, 'cnphCpf');
    if (cnpjCpf && cnpjCpf != '') {
      changeFieldValue('filtroLiquidacoes', 'cnpj', cnpjCpf);
      filtro = location.search.substring(1) + '&';
    }

    if (page.get('sort') && typeof page.get('sort') === 'string') {
      this.pagasSortedUrl = page.get('sort');
    }

    filtro = `${filtro} and tipo=${tipo} ${this.montarFiltro(page)}&exercicio=${
      exercicio.id
    }`;

    findLiquidacoesPagas(filtro);
    findTotalLiquidacoesPagas(filtro);
  };

  render() {
    const {
      pageLiquidacoesPagas,
      pageLiquidacoesPagar,
      filtros,
      totalLiquidacoesPagas,
      totalLiquidacoesPagar,
      location,
      errors,
      isIntegradoOxy,
      match: {
        params: { tipo }
      }
    } = this.props;

    const filterLiquidacoesPagar = this.montarFiltro(pageLiquidacoesPagar);
    const filterLiquidacoesPagas = this.montarFiltro(pageLiquidacoesPagas);

    return (
      <section>
        <PageHeader location={location} />

        <NotasExplicativas pathname={this.props.location.pathname} />

        <Panel isForm mobileClose title="Consulta" ref="panelFiltroLiquidacoes">
          <form>
            <Row>
              <Col xs={12} sm={4} md={4}>
                <Input
                  id="cnpj"
                  name="cnpj"
                  ref="cnpj"
                  placeholder="CNPJ/CPF"
                  label="CNPJ/CPF"
                  type="text"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('cnpj')}
                />
              </Col>
              <Col xs={12} sm={8} md={8}>
                <Input
                  id="nome"
                  name="nome"
                  ref="nome"
                  placeholder="Nome"
                  label="Nome"
                  type="text"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('nome')}
                />
              </Col>
              <Col xs={12} sm={4} md={3}>
                <Input
                  id="notaFiscal"
                  name="notaFiscal"
                  ref="notaFiscal"
                  placeholder="Nota Fiscal"
                  label="Nota Fiscal"
                  type="text"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('notaFiscal')}
                />
              </Col>
              <Col xs={12} sm={4} md={3}>
                <Input
                  id="empenho"
                  name="empenho"
                  ref="empenho"
                  placeholder="Empenho"
                  label="Empenho"
                  type="number"
                  min="0"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('empenho')}
                />
              </Col>
              <Col xs={12} sm={6} md={3}>
                <DatePicker
                  id="dataInicial"
                  name="dataInicial"
                  ref="dataInicial"
                  label="Data Inicial"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('dataInicial')}
                />
              </Col>
              <Col xs={12} sm={6} md={3}>
                <DatePicker
                  id="dataFinal"
                  name="dataFinal"
                  ref="dataFinal"
                  label="Data Final"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('dataFinal')}
                />
              </Col>
              <Col xs={12} sm={12} md={3}>
                <AutoComplete
                  key={filtros.get('fonteRecurso')}
                  label="Fonte Recurso"
                  id="fonteRecurso"
                  name="fonteRecurso"
                  onLoad={this.findFonteRecurso}
                  onChange={this.onChangeFiltro}
                  value={filtros.get('fonteRecurso')}
                  errors={errors}
                  valueKey="codigo"
                  labelKey="descricao"
                  placeholder="Selecione a Fonte Recurso"
                  valueRenderer={this.renderValue}
                />
              </Col>
              <Col xs={12} sm={12} md={6}>
                <div className="form-group">
                  <label className="label"></label>
                  <div className="radio-check-group">
                    {!isIntegradoOxy && (
                      <Input
                        id="isPorExercicio"
                        label="Somente Empenhos do Exercício"
                        type="checkbox"
                        name="isPorExercicio"
                        ref="isPorExercicio"
                        onChange={this.onChangeFiltro}
                        checked={filtros.get('isPorExercicio')}
                      />
                    )}
                    <Input
                      id="isPendentes"
                      label="Somente Empenhos de Restos"
                      type="checkbox"
                      name="isPendentes"
                      ref="isPendentes"
                      onChange={this.onChangeFiltro}
                      checked={filtros.get('isPendentes')}
                    />
                  </div>
                </div>
              </Col>

              <Col xs={12} sm={4} md={4}>
                <div className="form-group">
                  <label className="label"></label>
                  <div className="btn-group">
                    <button
                      id="btnFiltrar"
                      className="btn module-color icon-right"
                      type="submit"
                      ref="pesquisar"
                      onClick={this.consultarLiquidacoes}
                    >
                      Pesquisar
                      <em className="fa fa-search"></em>
                    </button>
                    <button
                      id="btnResetFilter"
                      className="btn neutral icon-right inline no-text"
                      type="button"
                      onClick={this.onResetFiltro}
                    >
                      Limpar
                      <em className="fa fa-times"></em>
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </form>
        </Panel>

        <Panel isForm tableResponsive expansible={false}>
          <Row>
            <Col xs={6}>
              <UltimaAtualizacaoByTable
                modulo="3"
                table={this.findTableDataUltimaAtualizacao()}
                column="dataultimaatualizacao"
              />
            </Col>
            <Col xs={6} />
          </Row>

          <Tabs ref="tab">
            <Tab
              title="Despesas a Pagar"
              name="liquidacoesPagar"
              onlyTable
              active={this.permitirCarregarTelaPorTipo(this.PAGAR)}
              hide={tipo == this.PAGAS}
            >
              <LiquidacoesListagem
                page={pageLiquidacoesPagar}
                total={totalLiquidacoesPagar}
                filter={filterLiquidacoesPagar}
                tipo={this.PAGAR}
                history={this.props.history}
                searchWithPage={this.searchWithPageLiquidacoesPagar}
                sortedUrl={this.pagarSortedUrl}
                onExporta={this.onExportaPagar}
              />
              <SearchPagination
                page={pageLiquidacoesPagar}
                searchWithPage={this.searchWithPageLiquidacoesPagar}
              />
            </Tab>

            <Tab
              title="Despesas Pagas"
              name="liquidacoesPagas"
              onlyTable
              active={tipo == this.PAGAS}
              hide={tipo == this.PAGAR}
            >
              <LiquidacoesListagem
                page={pageLiquidacoesPagas}
                total={totalLiquidacoesPagas}
                filter={filterLiquidacoesPagas}
                tipo={this.PAGAS}
                history={this.props.history}
                searchWithPage={this.searchWithPageLiquidacoesPagas}
                sortedUrl={this.pagasSortedUrl}
                paramDespesasPagasValor={this.props.paramDespesasPagasValor}
                onExporta={this.onExportaPagas}
              />
              <SearchPagination
                page={pageLiquidacoesPagas}
                searchWithPage={this.searchWithPageLiquidacoesPagas}
              />
            </Tab>
          </Tabs>
        </Panel>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    filtros: state.crud.getIn(['filtroLiquidacoes', 'currentRecord'], Map({})),
    pageLiquidacoesPagar: state.liquidacoes.get('liquidacoesPagar'),
    totalLiquidacoesPagar: state.liquidacoes.get('totalLiquidacoesPagar'),
    pageLiquidacoesPagas: state.liquidacoes.get('liquidacoesPagas'),
    totalLiquidacoesPagas: state.liquidacoes.get('totalLiquidacoesPagas'),
    paramDespesasPagasValor: ParametroService.getValorParametro(
      state,
      PARAM_DESPESAS_PAGAS_VALOR
    ),
    paramDespesasEntidadesDif: ParametroService.getValorParametro(
      state,
      PARAM_DESPESAS_ENTIDADES_DIFERENTES
    ),
    isIntegradoOxy: state.entidade.getIn([
      'configuracoesGerais',
      'isIntegradoOxy'
    ])
  };
}

const allActions = {
  ...actions,
  onChange,
  changeFieldValue,
  resetCrud,
  addErrorNotification
};

export default connect(mapStateToProps, allActions)(FormLiquidacoes);
