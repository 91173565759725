export const ORCAMENTO_LIST = 'ORCAMENTO_LIST';
export const ORCAMENTO_LIST_START = 'ORCAMENTO_LIST_START';
export const ORCAMENTO_LIST_SUCCESS = 'ORCAMENTO_LIST_SUCCESS';
export const ORCAMENTO_LIST_ERROR = 'ORCAMENTO_LIST_ERROR';

export const OBJETIVO_ESTRATEGICO = 'OBJETIVO_ESTRATEGICO';
export const OBJETIVO_ESTRATEGICO_START = 'OBJETIVO_ESTRATEGICO_START';
export const OBJETIVO_ESTRATEGICO_SUCCESS = 'OBJETIVO_ESTRATEGICO_SUCCESS';
export const OBJETIVO_ESTRATEGICO_ERROR = 'OBJETIVO_ESTRATEGICO_ERROR';

export const OBJETIVO_ESTRATEGICO_ARQ_START = 'OBJETIVO_ESTRATEGICO_ARQ_START';
export const OBJETIVO_ESTRATEGICO_ARQ_ERROR = 'OBJETIVO_ESTRATEGICO_ARQ_ERROR';
