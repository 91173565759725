import StorageService from 'portaltransparencia-common/libs/StorageService.js';

import * as types from './Types.js';

export function findProcessos(filtro) {
  const { entidade, exercicio } = StorageService.getItem('userInfo');
  const baseFilter = `entidade=${entidade.id}&exercicio=${exercicio.id}&`;

  return ({ fetch }) => ({
    type: types.PROCESSO_DISCIPLINAR_LIST,
    payload: {
      promise: fetch(
        `/api/servidores/processos-disciplinares?${baseFilter}${filtro}`
      )
    }
  });
}
