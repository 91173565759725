import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';

import * as types from './Types.js';

const initialState = fromJS({
  aptosPagamentos: [],
  total: {},
  filtros: []
});

function AptoPagamentoReducer(state = initialState, action) {
  switch (action.type) {
    case types.APTOS_PAGAMENTOS_START:
    case types.APTOS_PAGAMENTOS_TOTAL_START: {
      return startLoad(state);
    }

    case types.APTOS_PAGAMENTOS_ERROR:
    case types.APTOS_PAGAMENTOS_TOTAL_ERROR: {
      return endLoad(state);
    }

    case types.APTOS_PAGAMENTOS_SUCCESS: {
      return endLoad(state.set('aptosPagamentos', fromJS(action.payload)));
    }
    case types.APTOS_PAGAMENTOS_TOTAL_SUCCESS: {
      return endLoad(state.set('total', fromJS(action.payload)));
    }
  }

  return state;
}

export default AptoPagamentoReducer;
