import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export default class ResumoNaturezaRow extends Component {
  static propTypes = {
    resumoNatureza: PropTypes.object.isRequired
  };

  render() {
    const { resumoNatureza } = this.props;

    return (
      <tr key={resumoNatureza.get('natureza')}>
        <td id="resumoNaturezaDescricao">{resumoNatureza.get('natureza')}</td>
        <td id="resumoNaturezaTotal">{resumoNatureza.get('total')}</td>
      </tr>
    );
  }
}
