export const DESPESAS = 'DESPESAS';
export const DESPESAS_START = 'DESPESAS_START';
export const DESPESAS_SUCCESS = 'DESPESAS_SUCCESS';
export const DESPESAS_ERROR = 'DESPESAS_ERROR';

export const DESPESAS_NIVEL = 'DESPESAS_NIVEL';
export const DESPESAS_NIVEL_START = 'DESPESAS_NIVEL_START';
export const DESPESAS_NIVEL_SUCCESS = 'DESPESAS_NIVEL_SUCCESS';
export const DESPESAS_NIVEL_ERROR = 'DESPESAS_NIVEL_ERROR';

export const EXPAND_DESPESA = 'EXPAND_DESPESA';
export const COLLAPSE_DESPESA = 'COLLAPSE_DESPESA';
