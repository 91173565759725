import React from 'react';
import Component from 'react-pure-render/component';

import AtalhoGeralItem from './AtalhoGeralItem.react.js';

export default class AtalhosGeraisForm extends Component {
  render() {
    return (
      <div>
        <ul type="circle" className="listing ml-xs">
          <li>
            <strong>Gerais </strong>
            <ul>
              <AtalhoGeralItem
                title="Home"
                desc="Tela inicial da aplicação "
                shortcut="ctr+alt+home"
              />
              <AtalhoGeralItem
                title="Pesquisar"
                desc="Acionar a pesquisa "
                shortcut="ctr+alt+end"
              />
              <AtalhoGeralItem
                title="Acessibilidade Fonte Normal"
                desc="Botão de acessibilidade para voltar o tamanho da fonte para o tamanho inicial "
                shortcut="alt+1"
              />
              <AtalhoGeralItem
                title="Acessibilidade Aumentar Fonte"
                desc="Botão de acessibilidade para aumentar o tamanho da fonte"
                shortcut="alt+2"
              />
              <AtalhoGeralItem
                title="Acessibilidade Diminuir Fonte"
                desc="Botão de acessibilidade para dimiuir o tamanho da fonte"
                shortcut="alt+3"
              />
              <AtalhoGeralItem
                title="Acessibilidade Inverter Cor"
                desc="Botão de acessibilidade para inverter as cores "
                shortcut="alt+4"
              />
              <br />
            </ul>
          </li>
        </ul>
      </div>
    );
  }
}
