import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export class Cargo extends Component {
  static propTypes = {
    cargo: PropTypes.object.isRequired,
    paramMostraAto: PropTypes.string
  };

  onViewDetalhes = item => {
    const entidade = item.get('entidade');
    const cargo = item.get('cargo');
    const quadro = item.get('quadroSalarial');
    const grupo = item.get('grupoOcupacional');
    const nivel = item.get('nivel');

    this.props.history.push(
      `cargos/detalhes?entidade=${entidade}&cargo=${cargo}&quadroSalarial=${quadro}&grupoOcupacional=${grupo}&nivel=${nivel}`
    );
  };

  generateKey = cargo => {
    return `${cargo.get('cargo')}_
            ${cargo.get('quadroSalarial')}_
            ${cargo.get('grupoOcupacional')}_
            ${cargo.get('nivel')}`;
  };

  render() {
    const { cargo, paramMostraAto } = this.props;

    return (
      <TableActions
        ref="row"
        key={this.generateKey(cargo)}
        data={cargo}
        onMore={this.onViewDetalhes}
      >
        <td>{cargo.get('descricao')}</td>
        <td>{cargo.get('quadroSalarialDesc')}</td>
        <td>{cargo.get('grupoOcupacionalDesc')}</td>
        <td>{cargo.get('nivelDesc')}</td>
        <td>{cargo.get('vagasTotal')}</td>
        <td>{cargo.get('vagasOcupadas')}</td>
        <td>{cargo.get('cargoSituacao')}</td>
        {paramMostraAto == 'S' && <td>{cargo.get('numeroAto')}</td>}
        {paramMostraAto == 'S' && <td>{cargo.get('anoAto')}</td>}
      </TableActions>
    );
  }
}

export default Cargo;
