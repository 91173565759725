import StorageService from 'portaltransparencia-common/libs/StorageService.js';

import * as types from './Types.js';

export function findPermutas(filtro) {
  const { entidade, exercicio } = StorageService.getItem('userInfo');
  const baseFilter = `entidade=${entidade.id}&exercicio=${exercicio.id}&`;

  return ({ fetch }) => ({
    type: types.PERMUTAS_LIST,
    payload: {
      promise: fetch(`/api/servidores/permutas?${baseFilter}${filtro}`)
    }
  });
}
