import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import * as actions from './Actions.js';

export class FormUltimaAtualizacaoDataConsulta extends Component {
  static propTypes = {
    findDataConsulta: PropTypes.func,
    dataConsulta: PropTypes.any
  };

  componentDidMount() {
    const { findDataConsulta } = this.props;

    findDataConsulta();
  }

  render() {
    const { dataConsulta } = this.props;

    if (!dataConsulta) {
      return null;
    }

    return (
      <div className="last-updated">
        <i className="fa fa-clock-o"></i> Última Atualização:{' '}
        {DateUtils.formatDateTime(dataConsulta.get('ultimoAcesso'))}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    dataConsulta: state.ultimaAtualizacao.get('dataConsulta')
  };
}

export default connect(
  mapStateToProps,
  actions
)(FormUltimaAtualizacaoDataConsulta);
