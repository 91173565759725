import Component from 'react-pure-render/component';
import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col } from 'react-bootstrap';

export default class BotaoVoltar extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    onClick: PropTypes.func
  };

  onClick = () => {
    const { onClick } = this.props;

    if (onClick) {
      onClick();
    } else {
      // browserHistory.goBack();
    }
  };

  render() {
    const { disabled } = this.props;

    return (
      <Row>
        <Col sm={12}>
          <button
            className="btn go-back hidden-print"
            type="button"
            onClick={this.onClick}
            disabled={disabled}
          >
            <i className="fa fa-chevron-left"></i> Voltar
          </button>
        </Col>
      </Row>
    );
  }
}
