export const ADIANTAMENTOS = 'ADIANTAMENTOS';
export const ADIANTAMENTOS_START = 'ADIANTAMENTOS_START';
export const ADIANTAMENTOS_SUCCESS = 'ADIANTAMENTOS_SUCCESS';
export const ADIANTAMENTOS_ERROR = 'ADIANTAMENTOS_ERROR';

export const CONSULTA_ADIANTAMENTOS = 'CONSULTA_ADIANTAMENTOS';
export const CONSULTA_ADIANTAMENTOS_START = 'CONSULTA_ADIANTAMENTOS_START';
export const CONSULTA_ADIANTAMENTOS_ERROR = 'CONSULTA_ADIANTAMENTOS_ERROR';
export const CONSULTA_ADIANTAMENTOS_SUCCESS = 'CONSULTA_ADIANTAMENTOS_SUCCESS';

export const ADIANTAMENTO = 'ADIANTAMENTO';
export const ADIANTAMENTO_START = 'ADIANTAMENTO_START';
export const ADIANTAMENTO_SUCCESS = 'ADIANTAMENTO_SUCCESS';
export const ADIANTAMENTO_ERROR = 'ADIANTAMENTO_ERROR';

export const ARQUIVOS = 'ARQUIVOS';
export const ARQUIVOS_START = 'ARQUIVOS_START';
export const ARQUIVOS_SUCCESS = 'ARQUIVOS_SUCCESS';
export const ARQUIVOS_ERROR = 'ARQUIVOS_ERROR';

export const TOTAL_ADIANTAMENTOS = 'TOTAL_ADIANTAMENTOS';
export const TOTAL_ADIANTAMENTOS_START = 'TOTAL_ADIANTAMENTOS_START';
export const TOTAL_ADIANTAMENTOS_SUCCESS = 'TOTAL_ADIANTAMENTOS_SUCCESS';
export const TOTAL_ADIANTAMENTOS_ERROR = 'TOTAL_ADIANTAMENTOS_ERROR';
