import { fromJS } from 'immutable';

import * as types from './Types.js';

const initialState = fromJS({
  userInfo: {}
});

function userInfoReducer(state = initialState, action) {
  switch (action.type) {
    case types.EXERCICIO_LOGADO_SUCCESS: {
      const userInfo = {
        entidade: {
          id: action.payload.entidade
        },
        exercicio: {
          id: action.payload.exercicio
        }
      };

      return state.set('userInfo', fromJS(userInfo));
    }
  }

  return state;
}

export default userInfoReducer;
