import {
  FETCH_INFO_START,
  FETCH_INFO_SUCCESS,
  FETCH_INFO_ERROR
} from './Types.js';
import { startLoad, endLoad } from '../crud/Core.js';

import { fromJS } from 'immutable';

const initialState = fromJS({
  isLoading: false,
  app: {},
  build: {}
});

export default function info(state = initialState, action) {
  if (action.payload) {
    switch (action.type) {
      case FETCH_INFO_START:
        return startLoad(state);

      case FETCH_INFO_SUCCESS: {
        const newState = state
          .set('app', fromJS(action.payload.app))
          .set('build', fromJS(action.payload.build));
        return endLoad(newState);
      }

      case FETCH_INFO_ERROR:
        return endLoad(state);
    }
  }

  return state;
}
