import URI from 'urijs';
import ServerError from '../libs/ServerError.js';
import { logout } from '../auth/Actions.js';
import { setInMaintance } from '../ui/Actions.js';
import AuthService from '../auth/AuthService.js';
import Configuration from './Config.js';
import { LRUCache } from 'lru-fast';
import {
  HTTP_STATUS_OK,
  HTTP_STATUS_MULTIPLE_CHOICES,
  HTTP_STATUS_NOT_MODIFIED,
  HTTP_STATUS_UNAUTHORIZED,
  HTTP_STATUS_UNAVAILABLE_FOR_LEGAL_REASONS,
  HTTP_STATUS_NO_CONTENT
} from '../httpUtils/httpStatus.js';
import keycloakService from '../../admportaltransparencia/src/keycloak/KeycloakService.js';

const CACHE_SIZE = 100;
const etagDataCache = new LRUCache(CACHE_SIZE);
const etagCache = new LRUCache(CACHE_SIZE);
function ensureServerUrl(serverUrl, input) {
  if (typeof input !== 'string') return input;
  if (URI(input).is('absolute')) return input;
  return URI(serverUrl + input)
    .normalize()
    .toString();
}

function getRootUrl(url) {
  if (url.includes('http')) {
    return url.toString().replace(/^(.*\/\/[^\/?#]*).*$/, '$1');
  }
  return '';
}

function isJsonResponse(response) {
  return (
    response.headers &&
    response.headers.get('Content-Type') &&
    response.headers.get('Content-Type').indexOf('application/json') >= 0
  );
}

function isTextResponse(response) {
  return (
    response.headers &&
    response.headers.get('Content-Type') &&
    response.headers.get('Content-Type').indexOf('text/html') >= 0
  );
}

function checkETag(response, url) {
  //304 Not Modified
  if (response.status === HTTP_STATUS_NOT_MODIFIED) {
    const cachedResponse = etagDataCache.get(url);
    if (cachedResponse) {
      return cachedResponse.clone();
    }
  }
  if (response.status === HTTP_STATUS_OK) {
    const responseEtag = response.headers.get('Etag');

    if (responseEtag) {
      etagDataCache.set(url, response.clone());
      etagCache.set(url, responseEtag);
    }
  }
  return response;
}

function checkStatus(response, dispatch, options) {
  if (
    (response.status >= HTTP_STATUS_OK &&
      response.status < HTTP_STATUS_MULTIPLE_CHOICES) ||
    response.status == HTTP_STATUS_NOT_MODIFIED
  ) {
    return response;
  }

  if (options.ignoreStatus === response.status) {
    return {};
  }

  switch (response.status) {
    case HTTP_STATUS_UNAUTHORIZED:
      dispatch(logout());
      throw new ServerError(response, {
        message: 'Usuário não autenticado, faça login novamente'
      });
    case HTTP_STATUS_UNAVAILABLE_FOR_LEGAL_REASONS:
      dispatch(setInMaintance());
      return Promise.resolve({});
    default:
      if (isJsonResponse(response)) {
        return response.json().then(payload => {
          throw new ServerError(response, payload);
        });
      }
      if (isTextResponse(response)) {
        return response.text().then(payload => {
          throw new Error(payload);
        });
      }
      throw new ServerError(response, {});
  }
}

function parseJSON(response) {
  //204 No Content
  if (response.status == HTTP_STATUS_NO_CONTENT) {
    return {};
  }

  if (isJsonResponse(response)) {
    return response.json();
  }

  if (isTextResponse(response)) {
    return response.text();
  }

  return response;
}

function doCustomRequest(serverUrl, deps) {
  return (url, opts) => {
    return doRequest(serverUrl, url, opts, deps);
  };
}

function doRequest(
  serverUrl,
  url,
  options = {},
  { dispatch, getState, browserHistory }
) {
  if (options.local) {
    url = getRootUrl(serverUrl) + options.local + url;
  } else {
    url = ensureServerUrl(serverUrl, url);
  }

  const token =
    AuthService.getAuthorizationToken() || keycloakService.getToken();

  const userInfo = getState().auth && getState().auth.get('userInfo');

  const requestOptions = { ...options };
  requestOptions.headers = { ...requestOptions.headers };
  requestOptions.headers['modulo'] = Configuration.modulo;
  requestOptions.headers['ACTIVE_VIEW'] = browserHistory?.location?.pathname;
  requestOptions.headers['location_search'] = browserHistory?.location?.search;

  if (userInfo) {
    const idEntidade = userInfo.getIn(['entidade', 'id']);
    const idExercicio = userInfo.getIn(['exercicio', 'id']);

    if (idEntidade) {
      requestOptions.headers['entidade'] = idEntidade;
    }

    if (idExercicio) {
      requestOptions.headers['exercicio'] = idExercicio;
    }
  }

  if (token) {
    requestOptions.headers['Authorization'] = `Bearer ${token}`;
  }

  if (requestOptions.method === 'GET' || !requestOptions.method) {
    const etag = etagCache.get(url);
    if (etag) {
      requestOptions.headers['If-None-Match'] = etagCache.get(url);
    }
  }

  return fetch(url, requestOptions, dispatch)
    .then(response => checkETag(response, url))
    .then(response => checkStatus(response, dispatch, options))
    .catch(function (error) {
      throw error;
    });
}

const defaultOptions = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
};

function retrieve(serverUrl, deps) {
  return (url, opts) => {
    const options = {
      ...defaultOptions,
      method: 'GET',
      ...opts
    };

    return doRequest(serverUrl, url, options, deps).then(parseJSON);
  };
}

function post(serverUrl, deps) {
  return (url, data, opts) => {
    const options = {
      ...defaultOptions,
      method: 'POST',
      body: JSON.stringify(data),
      ...opts
    };

    return doRequest(serverUrl, url, options, deps).then(parseJSON);
  };
}

function put(serverUrl, deps) {
  return (url, data, opts) => {
    const options = {
      ...defaultOptions,
      method: 'PUT',
      body: JSON.stringify(data),
      ...opts
    };

    return doRequest(serverUrl, url, options, deps).then(parseJSON);
  };
}

function remove(serverUrl, deps) {
  return (url, data, opts) => {
    const options = {
      ...defaultOptions,
      method: 'DELETE',
      body: JSON.stringify(data),
      ...opts
    };

    return doRequest(serverUrl, url, options, deps);
  };
}

// Simple wrapper making isomorphic-fetch universal.
export default function createFetch(serverUrl, deps) {
  return {
    fetch: retrieve(serverUrl, deps),
    post: post(serverUrl, deps),
    put: put(serverUrl, deps),
    remove: remove(serverUrl, deps),

    doCustomRequest: doCustomRequest(serverUrl, deps)
  };
}
