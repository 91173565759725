import StorageService from 'portaltransparencia-common/libs/StorageService.js';

import * as types from './Types.js';

export function findPcas(filter) {
  const { entidade } = StorageService.getItem('userInfo');
  const baseFilter = `entidade=${entidade.id}&`;

  return ({ fetch }) => ({
    type: types.PCAS,
    payload: {
      promise: fetch(`/api/pca?${baseFilter}${filter}`)
    }
  });
}

export function resetConsulta() {
  return {
    type: types.RESET_CONSULTA_PCAS,
    payload: {}
  };
}

export function findPca(entidade, exercicio, planoContratacao) {
  return ({ fetch }) => ({
    type: types.PCA,
    payload: {
      promise: fetch(
        `/api/pca/detalhe?entidade=${entidade}&exercicio=${exercicio}&planoContratacao=${planoContratacao}`
      )
    }
  });
}
