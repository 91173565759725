import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export class SubvencaoRemuneracoes extends Component {
  static propTypes = {
    remuneracoes: PropTypes.object.isRequired
  };

  renderRemuneracoes = data => {
    return (
      <tr ref="row" key={data.get('id')}>
        <td>{data.get('funcao')}</td>
        <td>{data.get('cargaHoraria')}</td>
        <td>
          <NumberFormatter value={data.get('remuneracao')} />
        </td>
        <td>{data.get('periodoDesc')}</td>
        <td>
          <NumberFormatter value={data.get('valorTotal')} />
        </td>
      </tr>
    );
  };

  render() {
    const { remuneracoes } = this.props;

    return (
      <div className="panel-table-outer">
        <table className="panel-table striped fancy">
          <TableHeader empty={remuneracoes.isEmpty()}>
            <tr>
              <th>Função</th>
              <th>Carga Horária</th>
              <th>Remuneração</th>
              <th>Período</th>
              <th>Valor Total</th>
            </tr>
          </TableHeader>
          <tbody>{remuneracoes.map(this.renderRemuneracoes)}</tbody>
        </table>
      </div>
    );
  }
}

export default SubvencaoRemuneracoes;
