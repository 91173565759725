import Input from 'portaltransparencia-common/components/EloInput.react.js';
import Validation from 'portaltransparencia-common/components/Validation.js';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import { validaEmail } from 'portaltransparencia-common/libs/ValidaEmail.js';
import {
  addErrorNotification,
  addSuccessNotification,
  offsidebarToggle
} from 'portaltransparencia-common/ui/Actions.js';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import * as actions from './Actions.js';

export class FormContato extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    dados: PropTypes.object,
    enviarMensagem: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
    addSuccessNotification: PropTypes.func.isRequired,
    addErrorNotification: PropTypes.func.isRequired,
    offsidebarToggle: PropTypes.func.isRequired,
    validate: PropTypes.func,
    handleError: PropTypes.func
  };

  onChangeDados = event => {
    const { onChange } = this.props;

    onChange('currentRecord', event);
  };

  onClick = () => {
    const { validate, handleError, offsidebarToggle } = this.props;

    validate(error => {
      if (handleError(this, error)) {
        return;
      }

      const {
        enviarMensagem,
        dados,
        reset,
        addSuccessNotification,
        addErrorNotification
      } = this.props;
      const { entidade } = StorageService.getItem('userInfo');

      enviarMensagem(dados, entidade.id).then(
        success => {
          if (success.value) {
            addSuccessNotification('Mensagem enviada com sucesso!');
            reset();
          } else {
            addErrorNotification(
              'Desculpe, não foi possível enviar a mensagem.'
            );
          }
        },
        error => {
          addSuccessNotification('Erro: ', error);
        }
      );
    });
    offsidebarToggle();
  };

  getValidatorData = () => {
    const { nome, email, assunto, mensagem } = this.refs;

    return {
      nome: nome.getValue(),
      email: email.getValue(),
      assunto: assunto.getValue(),
      mensagem: mensagem.getValue()
    };
  };

  render() {
    const { dados, errors } = this.props;

    return (
      <section>
        <Row>
          <Col xs={12}>
            <Input
              required
              id="nome"
              label="Nome"
              type="text"
              name="nome"
              ref="nome"
              errors={errors}
              onChange={this.onChangeDados}
              value={dados.get('nome')}
            />
          </Col>
          <Col xs={12}>
            <Input
              required
              id="email"
              label="E-mail"
              type="email"
              name="email"
              ref="email"
              errors={errors}
              onChange={this.onChangeDados}
              value={dados.get('email')}
            />
          </Col>
          <Col xs={12}>
            <Input
              required
              id="assunto"
              label="Assunto"
              type="text"
              name="assunto"
              ref="assunto"
              errors={errors}
              onChange={this.onChangeDados}
              value={dados.get('assunto')}
            />
          </Col>
          <Col xs={12}>
            <Input
              required
              id="mensagem"
              label="Mensagem"
              type="textarea"
              name="mensagem"
              ref="mensagem"
              errors={errors}
              onChange={this.onChangeDados}
              value={dados.get('mensagem')}
            />
          </Col>
          <Col xs={12}>
            <button
              id="btEnviar"
              className="btn module-color icon-right mt-xs right"
              type="button"
              ref="send"
              onClick={this.onClick}
            >
              Enviar
              <em className="fa fa-send"></em>
            </button>
          </Col>
        </Row>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    dados: state.contato.get('currentRecord')
  };
}

const allActions = {
  ...actions,
  addSuccessNotification,
  addErrorNotification,
  offsidebarToggle,
  validaEmail
};

const validatorTypes = {
  rules: {
    nome: 'required',
    email: 'required|email',
    assunto: 'required',
    mensagem: 'required'
  },
  labels: {
    nome: 'Nome',
    email: 'E-mail',
    assunto: 'Assunto',
    mensagem: 'Mensagem'
  }
};

FormContato = Validation(validatorTypes)(FormContato);

export default connect(mapStateToProps, allActions)(FormContato);
