import {
  BasicInput,
  Button,
  Col,
  FormikSelect,
  Panel,
  Row,
  Yup
} from '@elotech/components';
import Input from 'portaltransparencia-common/components/EloInput.react.js';
import DatePicker from 'portaltransparencia-common/components/DatePicker.react.js';
import { Formik } from 'formik';
import React from 'react';
import Component from 'react-pure-render/component';
import PropTypes from 'prop-types';
import * as types from './Types';

const initialValues = {
  cpfCnpj: undefined,
  contribuinte: undefined,
  dataInicial: undefined,
  dataFinal: undefined,
  tipoMovimentacaoTributo: undefined,
  classificacaoTributo: undefined,
  situacaoLegal: undefined
};

const validationSchema = () =>
  Yup.object().shape({
    tipoMovimentacaoTributo: Yup.string()
      .label('Tipo de Movimentação')
      .required(),
    dataInicial: Yup.date()
      .label('Data Inicial')
      .required()
      .test(
        'dataInicial',
        'Data Inicial deve ser menor ou igual a Data Final',
        function (dataInicial) {
          return dataInicial && dataInicial <= this.parent.dataFinal;
        }
      )
      .test(
        'dataInicial',
        'A Data Inicial deve estar dentre os últimos 5 anos com base na data atual',
        function (dataInicial) {
          if (this.parent.tipoMovimentacaoTributo === types.CADUCIDADE)
            return true;
          const dataMinima = new Date();
          dataMinima.setFullYear(dataMinima.getFullYear() - 5);
          return (
            dataInicial &&
            new Date(dataInicial).toISOString().split('T')[0] >=
              new Date(dataMinima).toISOString().split('T')[0]
          );
        }
      ),
    dataFinal: Yup.date()
      .label('Data Final')
      .required()
      .test(
        'dataFinal',
        'Data Final deve ser maior ou igual a Data Inicial',
        function (dataFinal) {
          return dataFinal && dataFinal >= this.parent.dataInicial;
        }
      )
      .test(
        'dataFinal',
        'Data Final deve ter menos de 5 anos com base na data atual para a busca de caducidades',
        function (dataFinal) {
          if (this.parent.tipoMovimentacaoTributo !== types.CADUCIDADE)
            return true;
          const dataMaxima = new Date();
          dataMaxima.setFullYear(dataMaxima.getFullYear() - 5);
          return (
            dataFinal &&
            new Date(dataFinal).toISOString().split('T')[0] <=
              new Date(dataMaxima).toISOString().split('T')[0]
          );
        }
      )
  });

class RenunciaReceitasForm extends Component {
  static propTypes = {
    search: PropTypes.func.isRequired,
    classificacoes: PropTypes.any.isRequired
  };

  render() {
    const { search, classificacoes } = this.props;

    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={values => search(values)}
        validationSchema={validationSchema}
        render={formik => (
          <Panel
            title="Consultar em Renúncia de Receitas"
            mobileClose
            isForm
            expansible
          >
            <Row>
              <BasicInput
                size={3}
                name="cpfCnpj"
                render={({ field }) => (
                  <Input
                    type="text"
                    maxLength={30}
                    label="CPF/CNPJ"
                    name={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                  />
                )}
              />
              <BasicInput
                size={3}
                name="contribuinte"
                render={({ field }) => (
                  <Input
                    type="text"
                    label="Nome Contribuinte"
                    name={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                  />
                )}
              />
              <BasicInput
                size={3}
                name="dataInicial"
                render={({ field }) => (
                  <DatePicker
                    label="Data Inicial"
                    name={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                  />
                )}
              />
              <BasicInput
                size={3}
                name="dataFinal"
                render={({ field }) => (
                  <DatePicker
                    label="Data Final"
                    name={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                  />
                )}
              />
            </Row>
            <Row>
              <FormikSelect
                size={3}
                label="Tipo de Movimentação"
                name="tipoMovimentacaoTributo"
                options={types.RenunciaReceitas}
                getOptionLabel={item => item.label}
                getOptionValue={item => item.tipo}
              />
              <FormikSelect
                size={3}
                label="Classificação"
                name="classificacaoTributo"
                options={classificacoes}
                getOptionLabel={item => item.descricao}
                getOptionValue={item => item.id}
              />
              <FormikSelect
                size={3}
                label="Situação"
                name="situacaoLegal"
                options={types.Situacoes}
                getOptionLabel={item => item.label}
                getOptionValue={item => item.tipo}
              />
              <Col size={3} style={{ marginTop: 30 }}>
                <Button
                  className="ml-xs inline"
                  iconPosition="right"
                  color="module-color"
                  onClick={formik.submitForm}
                >
                  Pesquisar
                  <em className="fa fa-search" />
                </Button>
                <Button
                  className="ml-xs inline"
                  iconPosition="right"
                  color="neutral"
                  onClick={formik.resetForm}
                >
                  Limpar
                  <em className="fa fa-times" />
                </Button>
              </Col>
            </Row>
          </Panel>
        )}
      />
    );
  }
}

export default RenunciaReceitasForm;
