import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import NumberUtils from 'portaltransparencia-common/libs/NumberUtils';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import Hint from 'portaltransparencia-common/components/hint/Hint.react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

export class VeiculoGasto extends Component {
  static propTypes = {
    gasto: PropTypes.object.isRequired,
    onShowModal: PropTypes.func.isRequired,
    onShowModalAbastecimento: PropTypes.func.isRequired,
    onShowModalBateria: PropTypes.func.isRequired,
    onShowModalPneu: PropTypes.func.isRequired,
    onShowModalPeca: PropTypes.func.isRequired,
    entidades: PropTypes.any,
    entidadeLogada: PropTypes.any
  };

  onShowModal = () => {
    const { onShowModal, gasto } = this.props;
    onShowModal(gasto.get('mes'));
  };

  onShowModalAbastecimento = () => {
    const { onShowModalAbastecimento, gasto } = this.props;
    onShowModalAbastecimento(gasto.get('mes'));
  };

  onShowModalBateria = () => {
    const { onShowModalBateria, gasto } = this.props;
    onShowModalBateria(gasto.get('mes'));
  };

  onShowModalPneu = () => {
    const { onShowModalPneu, gasto } = this.props;
    onShowModalPneu(gasto.get('mes'));
  };

  onShowModalPeca = () => {
    const { onShowModalPeca, gasto } = this.props;
    onShowModalPeca(gasto.get('mes'));
  };

  renderManutencao = manutencao => {
    return (
      <a
        style={{ cursor: 'pointer' }}
        onClick={this.onShowModal}
        title="ver detalhes"
      >
        {NumberUtils.format(manutencao)}
      </a>
    );
  };

  renderAbastecimento = abastecimento => {
    return (
      <a
        style={{ cursor: 'pointer' }}
        onClick={this.onShowModalAbastecimento}
        title="ver detalhes"
      >
        {NumberUtils.format(abastecimento)}
      </a>
    );
  };

  renderBateria = bateria => {
    return (
      <a
        style={{ cursor: 'pointer' }}
        onClick={this.onShowModalBateria}
        title="ver detalhes"
      >
        {NumberUtils.format(bateria)}
      </a>
    );
  };

  renderPneu = pneu => {
    return (
      <a
        style={{ cursor: 'pointer' }}
        onClick={this.onShowModalPneu}
        title="ver detalhes"
      >
        {NumberUtils.format(pneu)}
      </a>
    );
  };

  renderPeca = peca => {
    return (
      <a
        style={{ cursor: 'pointer' }}
        onClick={this.onShowModalPeca}
        title="ver detalhes"
      >
        {NumberUtils.format(peca)}
      </a>
    );
  };

  getNomeEntidade(ID) {
    const { entidades } = this.props;
    return (
      entidades.find(entidade => entidade.get('id') === ID)?.get('nome') ||
      'NÃO ENCONTRADA'
    );
  }

  render() {
    const { gasto, entidadeLogada } = this.props;
    return (
      <tr ref="row" key={`${gasto.get('entidade')}_${gasto.get('mes')}`}>
        <td>
          {DateUtils.getMonthDescription(gasto.get('mes'))}
          {entidadeLogada != gasto.get('entidade') && (
            <Hint
              message={`Gasto realizado na entidade ${this.getNomeEntidade(
                gasto.get('entidade')
              )}`}
              isInline
              spacing="ml-xs"
              position="left"
              size="sm"
              icon="fa fa-info"
            ></Hint>
          )}
        </td>
        <td className="right">
          {this.renderManutencao(gasto.get('manutencao'))}
        </td>
        <td className="right">
          {this.renderAbastecimento(gasto.get('abastecimento'))}
        </td>
        <td className="right">{this.renderPneu(gasto.get('pneus'))}</td>
        <td className="right">{this.renderBateria(gasto.get('baterias'))}</td>
        <td className="right">{this.renderPeca(gasto.get('pecas'))}</td>
        <td className="right">
          <NumberFormatter value={gasto.get('totalGeral')} />
        </td>
        <td className="right">
          <NumberFormatter value={gasto.get('kmPercorrido')} format="0,0.0" />
        </td>
        <td>{gasto.get('observacao')}</td>
      </tr>
    );
  }
}

function mapStateToProps(state) {
  return {
    entidades: state.entidade.get('entidades'),
    entidadeLogada: state.auth.getIn(['userInfo', 'entidade', 'id'])
  };
}

export default connect(mapStateToProps)(withRouter(VeiculoGasto));
