import DisplayData from 'portaltransparencia-common/components/displaydata/DisplayData.react.js';
import DisplayDataItem from 'portaltransparencia-common/components/displaydata/DisplayDataItem.react.js';
import DisplayDataRow from 'portaltransparencia-common/components/displaydata/DisplayDataRow.react.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import NumberUtils from 'portaltransparencia-common/libs/NumberUtils.js';
import PropTypes from 'prop-types';
import React from 'react';
import { Col } from 'react-bootstrap';
import Component from 'react-pure-render/component';

export class PensionistaDetalheInformacao extends Component {
  static propTypes = {
    pensionista: PropTypes.object.isRequired,
    dadosPensao: PropTypes.object,
    usaWebService: PropTypes.bool.isRequired,
    anotacoes: PropTypes.object.isRequired,
    exibeSalarioBase: PropTypes.bool.isRequired,
    horarioFlexivel: PropTypes.object.isRequired,
    uf: PropTypes.string
  };

  renderDadosPensao = () => {
    const { dadosPensao } = this.props;

    if (dadosPensao && dadosPensao.has('tipoBeneficio')) {
      return (
        <div>
          <DisplayDataRow>
            <DisplayDataItem
              sm={4}
              title="Tipo de Benefício"
              value={dadosPensao.get('tipoBeneficio')}
              id="tipoBeneficio"
            />
            <DisplayDataItem
              sm={5}
              title="Parentesco"
              value={dadosPensao.get('parentesco')}
              id="parentesco"
            />
            <DisplayDataItem
              sm={3}
              title="Parcela Percentual da Pensão"
              value={dadosPensao.get('percentualBeneficio')}
              id="percentualPensao"
            />
          </DisplayDataRow>

          <DisplayDataRow>
            <DisplayDataItem
              sm={6}
              title="Origem da Pensão"
              value={dadosPensao.get('origemPensao')}
              id="origemPensao"
            />
            <DisplayDataItem
              sm={6}
              title="Data de Óbito"
              value={DateUtils.formatDateTimeShort(
                dadosPensao.get('dataObito')
              )}
              id="dataObito"
            />
          </DisplayDataRow>

          <DisplayDataRow>
            <DisplayDataItem
              sm={6}
              title="Cargo da Origem da Pensão"
              value={dadosPensao.get('cargoOrigemPensao')}
              id="cargoOrigemPensao"
            />
            <DisplayDataItem
              sm={6}
              title="Entidade Origem"
              value={dadosPensao.get('entidadeOrigem')}
              id="entidadeOrigem"
            />
          </DisplayDataRow>
        </div>
      );
    }

    return null;
  };

  renderAnotacoes() {
    const { anotacoes } = this.props;

    if (anotacoes && !anotacoes.isEmpty()) {
      return (
        <DisplayDataRow>
          <div>
            <b className="dd-title">Anotações</b>
            <div className="col-sm-12 col-md-12">
              <div className="dd-content">
                <ul id="anotacoes">
                  {anotacoes.map((item, i) => (
                    <li key={i}>{item}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </DisplayDataRow>
      );
    }

    return null;
  }

  renderDadosCargo() {
    const { pensionista, usaWebService, exibeSalarioBase } = this.props;

    if (!exibeSalarioBase || usaWebService) {
      return (
        <DisplayDataRow>
          <DisplayDataItem
            sm={8}
            title="Cargo"
            value={pensionista.get('descricaoCargo')}
            id="descricaoCargo"
            show={pensionista.has('descricaoCargo')}
          />
          <DisplayDataItem
            sm={4}
            title="Faixa"
            value={pensionista.get('faixa')}
            id="faixa"
            show={pensionista.has('faixa')}
          />
        </DisplayDataRow>
      );
    } else {
      return (
        <DisplayDataRow>
          <DisplayDataItem
            sm={5}
            title="Cargo"
            value={pensionista.get('descricaoCargo')}
            id="descricaoCargo"
            show={pensionista.has('descricaoCargo')}
          />
          <DisplayDataItem
            sm={3}
            title="Faixa"
            value={pensionista.get('faixa')}
            id="faixa"
            show={pensionista.has('faixa')}
          />
          <DisplayDataItem
            sm={4}
            title="Valor"
            value={NumberUtils.format(pensionista.get('salarioBase'))}
            id="salarioBase"
            show={pensionista.has('faixa') && pensionista.has('salarioBase')}
          />
        </DisplayDataRow>
      );
    }
  }

  renderPeriodo = (inicio, fim) =>
    DateUtils.formatTime(inicio) + ' às ' + DateUtils.formatTime(fim);

  renderHorario = item => (
    <span>
      <b>{item.get('dia') + ': '}</b>
      {item.get('entrada1') &&
        this.renderPeriodo(item.get('entrada1'), item.get('saida1'))}
      {item.get('entrada2') &&
        ' ' + this.renderPeriodo(item.get('entrada2'), item.get('saida2'))}
      {item.get('entrada3') &&
        ' ' + this.renderPeriodo(item.get('entrada3'), item.get('saida3'))}
      {item.get('entrada4') &&
        ' ' + this.renderPeriodo(item.get('entrada4'), item.get('saida4'))}{' '}
    </span>
  );

  renderHorarioFlexivel() {
    const { horarioFlexivel } = this.props;

    return (
      <Col sm={8}>
        <b className="dd-title">Horário de Trabalho</b>
        <div className="dd-content" id={'horarioTrabalhoFlexivel'}>
          {horarioFlexivel.map(this.renderHorario)}
        </div>
      </Col>
    );
  }

  render() {
    const { pensionista, usaWebService, exibeSalarioBase, uf } = this.props;

    if (!pensionista) {
      return null;
    }

    return (
      <section>
        <DisplayData border>
          <DisplayDataRow>
            <DisplayDataItem
              sm={5}
              title="Nome"
              value={pensionista.get('nome')}
              id="nome"
            />

            <DisplayDataItem
              sm={3}
              title="Matrícula"
              value={pensionista.get('matricula')}
              id="matricula"
            />

            <DisplayDataItem
              sm={4}
              title="Situação"
              value={pensionista.get('situacao')}
              id="situacao"
            />
          </DisplayDataRow>

          <DisplayDataRow>
            <DisplayDataItem
              sm={4}
              title="Lotação"
              value={pensionista.get('descricaoLotacao')}
              id="descricaoLotacao"
            />

            <DisplayDataItem
              sm={4}
              title="Classe / Natureza"
              value={pensionista.get('descricaoClasse')}
              id="descricaoClasse"
              show={!usaWebService && pensionista.has('descricaoClasse')}
            />
          </DisplayDataRow>

          <DisplayDataRow>
            <DisplayDataItem
              sm={4}
              title="Tipo de Pensão"
              value={pensionista.get('tipoPensao')}
              id="tipoPensao"
              show={pensionista.has('tipoPensao')}
            />

            <DisplayDataItem
              sm={5}
              title="Originário da Pensão"
              value={pensionista.get('originarioPensao')}
              id="originarioPensao"
              show={pensionista.has('originarioPensao')}
            />

            <DisplayDataItem
              sm={3}
              title="Tipo de Cota"
              value={pensionista.get('tipoCota')}
              id="tipoCota"
              show={pensionista.has('tipoCota')}
            />
            <DisplayDataItem
              sm={12}
              title="Vínculo"
              value={pensionista.get('vinculo')}
              id="vinculo"
              show={usaWebService}
            />
          </DisplayDataRow>

          <DisplayDataRow>
            <DisplayDataItem
              sm={3}
              title="Data de Início do Benefício"
              value={DateUtils.formatDateTimeShort(
                pensionista.get('dataAdmissao')
              )}
              id="dataAdmissao"
              show={
                (uf != 'RO' ||
                  (pensionista.get('definicaoClasse') != 8 &&
                    pensionista.get('definicaoClasse') != 9)) &&
                pensionista.has('dataAdmissao')
              }
            />
            <DisplayDataItem
              sm={3}
              title="Data Término do Benefício"
              value={DateUtils.formatDateTimeShort(
                pensionista.get('dataTerminoBeneficio')
              )}
              id="dataTerminoBeneficio"
              show={pensionista.has('dataTerminoBeneficio')}
            />

            <DisplayDataItem
              sm={3}
              title="Desligamento"
              value={DateUtils.formatDateTimeShort(
                pensionista.get('dataDemissao')
              )}
              id="dataDemissao"
              show={pensionista.has('dataDemissao')}
            />

            {exibeSalarioBase && (
              <DisplayDataItem
                sm={3}
                title="Valor base do Benefício"
                value={NumberUtils.format(pensionista.get('salarioBase'))}
                id="salarioBase"
                show={pensionista.has('salarioBase')}
              />
            )}
          </DisplayDataRow>
        </DisplayData>
      </section>
    );
  }
}

export default PensionistaDetalheInformacao;
