import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';

import * as types from './Types.js';

const initialState = fromJS({
  servidores: {},
  ajudasCusto: {},
  servidor: {}
});

function ajudaCustoReducer(state = initialState, action) {
  switch (action.type) {
    case types.AJUDA_CUSTO_SERVIDORES_START:
    case types.AJUDA_CUSTO_SERVIDOR_START:
    case types.AJUDAS_CUSTO_START: {
      return startLoad(state);
    }

    case types.AJUDA_CUSTO_SERVIDORES_ERROR:
    case types.AJUDA_CUSTO_SERVIDOR_ERROR:
    case types.AJUDAS_CUSTO_ERROR: {
      return endLoad(state);
    }

    case types.AJUDA_CUSTO_SERVIDORES_SUCCESS: {
      return endLoad(state.set('servidores', fromJS(action.payload)));
    }
    case types.AJUDA_CUSTO_SERVIDOR_SUCCESS: {
      return endLoad(state.set('servidor', fromJS(action.payload)));
    }
    case types.AJUDAS_CUSTO_SUCCESS: {
      return endLoad(state.set('ajudasCusto', fromJS(action.payload)));
    }
  }

  return state;
}

export default ajudaCustoReducer;
