import Panel from 'portaltransparencia-common/components/Panel.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

import PatrimonioLocalRow from './PatrimonioLocalRow.react.js';

export default class PatrimonioLocalTable extends Component {
  static propTypes = {
    locais: PropTypes.object.isRequired
  };

  renderPatrimoniosDetalhes = data => {
    return <PatrimonioLocalRow key={data.get('descricaoNivel')} local={data} />;
  };

  render() {
    const { locais } = this.props;

    return (
      <Panel isTable tableResponsive expansible={false}>
        <div className="panel-table-outer">
          <table className="panel-table striped fancy">
            <TableHeader empty={locais.isEmpty()}>
              <tr>
                <th>Descrição</th>
                <th>Localização</th>
              </tr>
            </TableHeader>
            <tbody>{locais.map(this.renderPatrimoniosDetalhes)}</tbody>
          </table>
        </div>
      </Panel>
    );
  }
}
