import PropTypes from 'prop-types';
import React from 'react';

class TreeTable extends React.PureComponent {
  static propTypes = {
    list: PropTypes.object.isRequired,
    header: PropTypes.element.isRequired,
    itemRenderer: PropTypes.func.isRequired
  };

  render() {
    const { list, header, itemRenderer } = this.props;

    return (
      <div className="panel-table-outer">
        <table className="panel-table tree tree-arrow" ref="treetable">
          {header}
          <tbody>{list.map(itemRenderer)}</tbody>
        </table>
      </div>
    );
  }
}

export default TreeTable;
