export const DIARIAS_LIST = 'DIARIAS_LIST';
export const DIARIAS_LIST_START = 'DIARIAS_LIST_START';
export const DIARIAS_LIST_SUCCESS = 'DIARIAS_LIST_SUCCESS';
export const DIARIAS_LIST_ERROR = 'DIARIAS_LIST_ERROR';

export const DIARIAS_WITHOUT_QUANTIDADE_LIST =
  'DIARIAS_WITHOUT_QUANTIDADE_LIST';
export const DIARIAS_WITHOUT_QUANTIDADE_LIST_START =
  'DIARIAS_WITHOUT_QUANTIDADE_LIST_START';
export const DIARIAS_WITHOUT_QUANTIDADE_LIST_SUCCESS =
  'DIARIAS_WITHOUT_QUANTIDADE_LIST_SUCCESS';
export const DIARIAS_WITHOUT_QUANTIDADE_LIST_ERROR =
  'DIARIAS_WITHOUT_QUANTIDADE_LIST_ERROR';

export const RESET_CONSULTA_DIARIAS = 'RESET_CONSULTA_DIARIAS';

export const DIARIA = 'DIARIA';
export const DIARIA_START = 'DIARIA_START';
export const DIARIA_SUCCESS = 'DIARIA_SUCCESS';
export const DIARIA_ERROR = 'DIARIA_ERROR';

export const ARQUIVOS = 'ARQUIVOS';
export const ARQUIVOS_START = 'ARQUIVOS_START';
export const ARQUIVOS_SUCCESS = 'ARQUIVOS_SUCCESS';
export const ARQUIVOS_ERROR = 'ARQUIVOS_ERROR';

export const TOTAL_DIARIAS = 'TOTAL_DIARIAS';
export const TOTAL_DIARIAS_START = 'TOTAL_DIARIAS_START';
export const TOTAL_DIARIAS_SUCCESS = 'TOTAL_DIARIAS_SUCCESS';
export const TOTAL_DIARIAS_ERROR = 'TOTAL_DIARIAS_ERROR';

export const OBJETIVO_DIARIAS = 'OBJETIVO_DIARIAS';
export const OBJETIVO_DIARIAS_START = 'OBJETIVO_DIARIAS_START';
export const OBJETIVO_DIARIAS_SUCCESS = 'OBJETIVO_DIARIAS_SUCCESS';
export const OBJETIVO_DIARIAS_ERROR = 'OBJETIVO_DIARIAS_ERROR';

export const SECRETARIA_DIARIAS = 'SECRETARIA_DIARIAS';
export const SECRETARIA_DIARIAS_START = 'SECRETARIA_DIARIAS_START';
export const SECRETARIA_DIARIAS_SUCCESS = 'SECRETARIA_DIARIAS_SUCCESS';
export const SECRETARIA_DIARIAS_ERROR = 'SECRETARIA_DIARIAS_ERROR';

export const SELECT_CODIGO_SECRETARIA = 'SELECT_CODIGO_SECRETARIA';
