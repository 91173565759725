import NumberUtils from 'portaltransparencia-common/libs/NumberUtils';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export class FormArquivoObjetivoEstrategico extends Component {
  static propTypes = {
    arquivos: PropTypes.object,
    download: PropTypes.func
  };

  renderArquivos = data => {
    const { download } = this.props;

    return (
      <div className="mt-xs" key={data.get('idArquivo')}>
        {data.get('idArquivo') && (
          <div>
            {data.get('nome')}
            <button
              type="button"
              className="btn  neutral icon-left inline ml-xs"
              onClick={download(data.get('idArquivo'))}
              title="Download do arquivo de Planejamento Estratégico"
            >
              <i className="fa fa-download"></i>
              {`Baixar ${NumberUtils.formatBytes(data.get('tamanhoArquivo'))}`}
            </button>
          </div>
        )}
      </div>
    );
  };

  render() {
    const { arquivos } = this.props;
    return <div>{arquivos.map(this.renderArquivos)}</div>;
  }
}

export default FormArquivoObjetivoEstrategico;
