import * as types from './Types.js';

export function previousStep() {
  return {
    type: types.PREVIOUS_STEP
  };
}

export function nextStep() {
  return {
    type: types.NEXT_STEP
  };
}

export function goToStep(step) {
  return {
    type: types.GO_TO_STEP,
    payload: step
  };
}
