import * as types from './Types.js';

export function findDespesas() {
  return ({ fetch }) => ({
    type: types.DESPESAS,
    payload: {
      promise: fetch('/despesapornivel/detalhada')
    }
  });
}

export function findDespesasPorNivel(filtro) {
  return ({ fetch }) => ({
    type: types.DESPESAS_NIVEL,
    payload: {
      promise: fetch(`/despesapornivel/nivel${filtro}`)
    }
  });
}

export function expand(despesa, nivel) {
  return {
    type: types.EXPAND_DESPESA,
    payload: {
      despesa,
      nivel
    }
  };
}

export function collapse(despesa, nivel) {
  return {
    type: types.COLLAPSE_DESPESA,
    payload: {
      despesa,
      nivel
    }
  };
}
