import BotaoImprimir from 'portaltransparencia-common/components/BotaoImprimir.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import { getValueFromUrlSearchParams } from 'portaltransparencia-common/libs/URLUtils';
import {
  PARAM_EXIBE_DADOS_FUNCIONAIS_E_FINANCEIROS,
  PARAM_SALARIOBASE,
  PARAM_USAWEBSERVICE_MARINGA
} from 'portaltransparencia-common/parametros/ParametrosPortalTransparencia';
import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import PageHeader from '../components/PageHeader.react.js';
import CabecalhoEntidade from '../entidades/CabecalhoEntidade.react.js';
import NotasExplicativas from '../notaexplicativa/Form.react';
import ParametroService from '../parametros/ParametroService.js';
import * as actions from './Actions.js';
import ServidorDetalheFinanceiroOxy from './ServidorDetalheFinanceiro.react.js';
import ServidorDetalheInformacaoOxy from './ServidorDetalheInformacao.react.js';

export class FormServidorDetailOxy extends Component {
  static propTypes = {
    servidor: PropTypes.object.isRequired,
    dadosFinanceiros: PropTypes.object.isRequired,
    dadosPensao: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    findDadosPensao: PropTypes.func.isRequired,
    findDadosPensaoPorVinculo: PropTypes.func.isRequired,
    findServidor: PropTypes.func.isRequired,
    findServidorPorVinculo: PropTypes.func.isRequired,
    clearDetalhe: PropTypes.func.isRequired,
    paramWebService: PropTypes.string,
    paramExibeInfoCadastralEFinanceiro: PropTypes.string,
    anotacoes: PropTypes.object.isRequired,
    paramExibeSalarioBase: PropTypes.string,
    horarioFlexivel: PropTypes.object.isRequired,
    uf: PropTypes.string
  };

  state = {
    collapsedDadosFinanceiros:
      this.props.paramExibeInfoCadastralEFinanceiro !== 'S',
    collapsedInformacoesCadastrais: false
  };

  componentDidMount() {
    const {
      findDadosPensao,
      findServidor,
      findServidorPorVinculo,
      location
    } = this.props;

    const matricula = getValueFromUrlSearchParams(location, 'matricula');
    const vinculo = getValueFromUrlSearchParams(location, 'vinculo');
    let entidadeOrigem = getValueFromUrlSearchParams(
      location,
      'entidadeOrigem'
    );

    entidadeOrigem = entidadeOrigem ? entidadeOrigem : 0;

    if (vinculo) {
      findServidorPorVinculo(vinculo, matricula, entidadeOrigem);
    } else {
      findServidor(matricula);
    }
    findDadosPensao(matricula, entidadeOrigem);
  }

  componentWillUnmount() {
    const { clearDetalhe } = this.props;

    clearDetalhe();
  }

  onClickPanelDadosFinanceiros = () => {
    this.setState(state => ({
      collapsedDadosFinanceiros: !state.collapsedDadosFinanceiros
    }));

    if (this.props.paramExibeInfoCadastralEFinanceiro !== 'S') {
      this.setState(state => ({
        collapsedInformacoesCadastrais: !state.collapsedDadosFinanceiros
      }));
    }
  };

  onClickPanelInformacoesCadastrais = () => {
    this.setState(state => ({
      collapsedInformacoesCadastrais: !state.collapsedInformacoesCadastrais
    }));

    if (this.props.paramExibeInfoCadastralEFinanceiro !== 'S') {
      this.setState(state => ({
        collapsedDadosFinanceiros: !state.collapsedInformacoesCadastrais
      }));
    }
  };

  render() {
    const {
      dadosFinanceiros,
      dadosPensao,
      paramWebService,
      servidor,
      anotacoes,
      paramExibeSalarioBase,
      horarioFlexivel,
      uf
    } = this.props;

    return (
      <div>
        <Helmet title={`Servidores - ${servidor.get('nome')}`} />
        <PageHeader
          location={this.props.location}
          textoDetalhe={`Servidor: ${servidor.get('nome')}`}
        />
        {servidor && (
          <div>
            <CabecalhoEntidade />
            <BotaoImprimir />
            <Panel
              title="Informações Cadastrais"
              mobileClose
              collapsed={this.state.collapsedInformacoesCadastrais}
              onClickPanel={this.onClickPanelInformacoesCadastrais}
            >
              <ServidorDetalheInformacaoOxy
                servidor={servidor}
                dadosPensao={dadosPensao}
                usaWebService={paramWebService == '1'}
                anotacoes={anotacoes}
                exibeSalarioBase={paramExibeSalarioBase === 'S'}
                horarioFlexivel={horarioFlexivel}
                uf={uf}
              />
            </Panel>
          </div>
        )}
        <NotasExplicativas pathname={this.props.location.pathname} />
        {!dadosFinanceiros.isEmpty() && (
          <Panel
            isTable
            tableResponsive
            collapsed={this.state.collapsedDadosFinanceiros}
            onClickPanel={this.onClickPanelDadosFinanceiros}
            title="Dados Financeiros"
          >
            <ServidorDetalheFinanceiroOxy
              exibePonto={servidor.get('exibePonto') === 'S'}
              dadosFinanceiros={dadosFinanceiros}
            />
          </Panel>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    servidor: state.servidorOxy.get('servidor'),
    dadosFinanceiros: state.servidorOxy.get('dadosFinanceiros'),
    dadosPensao: state.servidorOxy.get('dadosPensao'),
    paramWebService: ParametroService.getValorParametro(
      state,
      PARAM_USAWEBSERVICE_MARINGA
    ),
    paramExibeInfoCadastralEFinanceiro: ParametroService.getValorParametro(
      state,
      PARAM_EXIBE_DADOS_FUNCIONAIS_E_FINANCEIROS
    ),
    anotacoes: state.servidorOxy.get('anotacoes'),
    paramExibeSalarioBase: ParametroService.getValorParametro(
      state,
      PARAM_SALARIOBASE
    ),
    horarioFlexivel: state.servidorOxy.get('horarioFlexivel'),
    uf: state.entidade.getIn(['entidadeSelecionada', 'uf'])
  };
}

export default connect(mapStateToProps, actions)(FormServidorDetailOxy);
