import * as types from './Types.js';

export function findServidoresAjudaCusto(filter) {
  return ({ fetch }) => ({
    type: types.AJUDA_CUSTO_SERVIDORES,
    payload: {
      promise: fetch(`/api/ajuda-custo?${filter}`)
    }
  });
}

export function findServidorAjudaCusto(filter) {
  return ({ fetch }) => ({
    type: types.AJUDA_CUSTO_SERVIDOR,
    payload: {
      promise: fetch(`/api/ajuda-custo/servidor?${filter}`)
    }
  });
}

export function findAjudasCusto(filter) {
  return ({ fetch }) => ({
    type: types.AJUDAS_CUSTO,
    payload: {
      promise: fetch(`/api/ajuda-custo/detalhe?${filter}`)
    }
  });
}
