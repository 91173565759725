import * as types from './Types.js';

export function findAutorizacao(entidade, exercicio, numeroRequisicao) {
  return ({ fetch }) => ({
    type: types.AUTORIZACAO,
    payload: {
      promise: fetch(
        `/api/autorizacoes/detalhes?entidade=${entidade}&exercicio=${exercicio}&numeroRequisicao=${numeroRequisicao}`
      )
    }
  });
}

export function findArquivos(entidade, exercicio, numeroRequisicao) {
  return ({ fetch }) => ({
    type: types.AUTORIZACAO_ARQUIVOS,
    payload: {
      promise: fetch(
        `/api/autorizacoes/arquivos?entidade=${entidade}&exercicio=${exercicio}&numeroRequisicao=${numeroRequisicao}`
      )
    }
  });
}
