import SumListField from 'portaltransparencia-common/components/SumListField.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import NumberUtils from 'portaltransparencia-common/libs/NumberUtils.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export class ConvenioPagamentos extends Component {
  static propTypes = {
    pagamentos: PropTypes.object.isRequired
  };

  renderConvenioPagamentos = pagamento => {
    return (
      <tr key={pagamento}>
        <td>{DateUtils.formatDateTimeShort(pagamento.get('data'))}</td>
        <td className="right">{NumberUtils.format(pagamento.get('valor'))}</td>
      </tr>
    );
  };

  render() {
    const { pagamentos } = this.props;
    return (
      <div className="panel-table-outer">
        <table className="panel-table striped fancy">
          <TableHeader
            empty={pagamentos.isEmpty()}
            text="Não possui Pagamentos."
          >
            <tr>
              <th>Data</th>
              <th className="right">Valor</th>
            </tr>
          </TableHeader>
          <tbody>{pagamentos.map(this.renderConvenioPagamentos)}</tbody>
          <tfoot>
            <tr>
              <td></td>
              <td className="right">
                <i>Total:</i>
                {<SumListField fieldName="valor" list={pagamentos} />}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    );
  }
}
export default ConvenioPagamentos;
