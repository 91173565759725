import SumListField from 'portaltransparencia-common/components/SumListField.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import NumberUtils from 'portaltransparencia-common/libs/NumberUtils.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export class ConvenioRepasses extends Component {
  static propTypes = {
    repasses: PropTypes.object.isRequired
  };

  renderConvenioRepasses = repasse => {
    return (
      <tr key={repasse}>
        <td>{DateUtils.formatDateTimeShort(repasse.get('data'))}</td>
        <td className="right">{NumberUtils.format(repasse.get('valor'))}</td>
      </tr>
    );
  };

  render() {
    const { repasses } = this.props;
    return (
      <div className="panel-table-outer">
        <table className="panel-table striped fancy">
          <TableHeader empty={repasses.isEmpty()} text="Não possui Repasses.">
            <tr>
              <th>Data</th>
              <th className="right">Valor</th>
            </tr>
          </TableHeader>
          <tbody>{repasses.map(this.renderConvenioRepasses)}</tbody>
          <tfoot>
            <tr>
              <td></td>
              <td className="right">
                <i>Total:</i>
                {<SumListField fieldName="valor" list={repasses} />}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    );
  }
}
export default ConvenioRepasses;
