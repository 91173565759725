import Tab from 'portaltransparencia-common/components/Tab.react.js';
import Tabs from 'portaltransparencia-common/components/Tabs.react.js';
import { getValueFromUrlSearchParams } from 'portaltransparencia-common/libs/URLUtils';
import {
  PARAM_ANULACOES,
  PARAM_DESPESAS_PAGAS_VALOR,
  PARAM_EM_LIQUIDACAO,
  PARAM_ITENS_EMPENHO,
  PARAM_LIQUIDACOES,
  PARAM_NOTAS_FISCAIS,
  PARAM_PAGAMENTOS
} from 'portaltransparencia-common/parametros/ParametrosPortalTransparencia';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import ParametroService from '../parametros/ParametroService.js';
import EmpenhoAnexos from './FormDetalheAnexos.react.js';
import EmpenhoAnulacoes from './FormDetalheAnulacao.react.js';
import EmpenhoEmLiquidacao from './FormDetalheEmLiquidacao.react.js';
import EmpenhoItens from './FormDetalheItens.react.js';
import EmpenhoLinks from './FormDetalheLinks.react.js';
import EmpenhoLiquidacoes from './FormDetalheLiquidacao.react.js';
import EmpenhoLiquidacoesDocumentos from './FormDetalheLiquidacaoDocumentos.react.js';
import EmpenhoMovimentacao from './FormDetalheMovimentacao.react.js';
import EmpenhoPagamentos from './FormDetalhePagamento.react.js';
import EmpenhoRetencoes from './FormDetalheRetencao.react.js';

export class EmpenhoDetalheAbas extends Component {
  static propTypes = {
    paramAnulacoes: PropTypes.string,
    paramLiquidacoes: PropTypes.string,
    paramPagamentos: PropTypes.string,
    paramItens: PropTypes.string,
    paramDocumentos: PropTypes.string,
    paramEmLiquidacoes: PropTypes.string,
    paramDespesasPagasValor: PropTypes.string,
    location: PropTypes.object.isRequired
  };

  render() {
    const {
      paramAnulacoes,
      paramLiquidacoes,
      paramPagamentos,
      paramItens,
      paramDocumentos,
      paramEmLiquidacoes,
      location
    } = this.props;

    let liquidacoesActive = false;
    let pagamentosActive = false;
    let emLiquidacoesActive = false;
    const paramActive = getValueFromUrlSearchParams(location, 'active');
    if (paramActive) {
      liquidacoesActive = paramActive == 'liquidacoes';
      pagamentosActive = paramActive == 'pagamentos';
      emLiquidacoesActive = paramActive == 'emliquidacao';
    }

    let active = !(
      liquidacoesActive ||
      pagamentosActive ||
      emLiquidacoesActive
    );

    return (
      <Tabs id="tabs">
        <Tab onlyTable tableResponsive title="Movimentação" active={active}>
          <EmpenhoMovimentacao location={location} />
        </Tab>

        <Tab onlyTable tableResponsive title="Itens" hide={paramItens != 'S'}>
          <EmpenhoItens />
        </Tab>

        <Tab
          onlyTable
          tableResponsive
          id="anulacoes"
          title="Anulações"
          hide={paramAnulacoes != 'S'}
        >
          <EmpenhoAnulacoes />
        </Tab>

        <Tab
          onlyTable
          tableResponsive
          title="Em Liquidação"
          hide={paramEmLiquidacoes != 'S'}
          active={emLiquidacoesActive}
        >
          <EmpenhoEmLiquidacao />
        </Tab>

        <Tab
          onlyTable
          tableResponsive
          title="Liquidações"
          hide={paramLiquidacoes != 'S'}
          active={liquidacoesActive}
        >
          <EmpenhoLiquidacoes
            paramDespesasPagasValor={this.props.paramDespesasPagasValor}
          />
        </Tab>

        <Tab
          onlyTable
          tableResponsive
          title="Retenções"
          hide={paramLiquidacoes != 'S'}
        >
          <EmpenhoRetencoes />
        </Tab>

        <Tab
          onlyTable
          tableResponsive
          title="Pagamentos"
          hide={paramPagamentos != 'S'}
          active={pagamentosActive}
        >
          <EmpenhoPagamentos
            paramDespesasPagasValor={this.props.paramDespesasPagasValor}
          />
        </Tab>

        <Tab
          onlyTable
          tableResponsive
          title="Documentos"
          hide={paramDocumentos != 'S'}
        >
          <EmpenhoLiquidacoesDocumentos />
        </Tab>

        <Tab onlyTable tableResponsive title="Anexos">
          <EmpenhoAnexos />
        </Tab>

        <Tab onlyTable tableResponsive title="Links">
          <EmpenhoLinks />
        </Tab>
      </Tabs>
    );
  }
}

function mapStateToProps(state) {
  return {
    paramAnulacoes: ParametroService.getValorParametro(state, PARAM_ANULACOES),
    paramLiquidacoes: ParametroService.getValorParametro(
      state,
      PARAM_LIQUIDACOES
    ),
    paramPagamentos: ParametroService.getValorParametro(
      state,
      PARAM_PAGAMENTOS
    ),
    paramItens: ParametroService.getValorParametro(state, PARAM_ITENS_EMPENHO),
    paramDocumentos: ParametroService.getValorParametro(
      state,
      PARAM_NOTAS_FISCAIS
    ),
    paramEmLiquidacoes: ParametroService.getValorParametro(
      state,
      PARAM_EM_LIQUIDACAO
    ),
    paramDespesasPagasValor: ParametroService.getValorParametro(
      state,
      PARAM_DESPESAS_PAGAS_VALOR
    )
  };
}

export default connect(mapStateToProps)(EmpenhoDetalheAbas);
