import Panel from 'portaltransparencia-common/components/Panel.react.js';
import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';
import DisplayData from 'portaltransparencia-common/components/displaydata/DisplayData.react.js';
import DisplayDataItem from 'portaltransparencia-common/components/displaydata/DisplayDataItem.react.js';
import DisplayDataRow from 'portaltransparencia-common/components/displaydata/DisplayDataRow.react.js';
import { downloadArquivo } from '../arquivo/Actions.js';
import PageHeader from '../components/PageHeader.react.js';
import * as actions from './Actions.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import NumberUtils from 'portaltransparencia-common/libs/NumberUtils';

export class FormAcordoDetail extends Component {
  static propTypes = {
    acordo: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    downloadArquivo: PropTypes.func.isRequired,
    findAcordo: PropTypes.func.isRequired
  };

  componentDidMount() {
    const {
      findAcordo,
      match: {
        params: { id }
      }
    } = this.props;
    findAcordo(id);
  }

  onDownloadEstrutura = idArquivo => {
    return () => {
      const { downloadArquivo } = this.props;

      downloadArquivo(idArquivo);
    };
  };

  render() {
    const { acordo, location } = this.props;

    const tamanho =
      (acordo.get('arquivo') &&
        NumberUtils.formatBytes(acordo.get('tamanhoArquivo'))) ||
      '';

    return (
      <section>
        <Helmet
          id="helmet"
          title={'Acordo Sem Recursos Financeiros ' + acordo.get('numero')}
        />

        <PageHeader
          location={location}
          textoDetalhe={`Acordo Sem Recursos Financeiros: ${acordo.get(
            'numero'
          )}`}
        />

        <Panel title="Detalhes do Acordo Sem Recursos Financeiros">
          <DisplayData border>
            <DisplayDataRow>
              <DisplayDataItem
                sm={2}
                title="Número"
                value={acordo.get('numero')}
                id="numero"
              />
              <DisplayDataItem
                sm={2}
                title="Ano"
                value={acordo.get('ano')}
                id="ano"
              />
              <DisplayDataItem
                sm={8}
                title="Tipo"
                value={acordo.get('tipo')}
                id="tipo"
              />
            </DisplayDataRow>

            <DisplayDataRow>
              <DisplayDataItem
                sm={12}
                title="Parte Contratada"
                value={acordo.getIn(['fornecedor', 'nome'])}
                id="parteContratada"
              />
            </DisplayDataRow>

            <DisplayDataRow>
              <DisplayDataItem
                sm={6}
                title="Situação"
                value={acordo.get('situacao')}
                id="situacao"
              />
              <DisplayDataItem
                sm={3}
                title="Data Início"
                value={DateUtils.formatDateTimeShort(acordo.get('dataInicio'))}
                id="dataInicio"
              />
              <DisplayDataItem
                sm={3}
                title="Data Término"
                value={DateUtils.formatDateTimeShort(acordo.get('dataTermino'))}
                id="dataTermino"
              />
            </DisplayDataRow>

            <DisplayDataRow>
              <DisplayDataItem
                sm={12}
                title="Objeto"
                value={acordo.get('objeto')}
                id="objeto"
              />
            </DisplayDataRow>
          </DisplayData>

          <div className="panel-inside-panel mt-xs">
            <Panel insidePanel title="Arquivo" isTable tableResponsive>
              <DisplayDataRow>
                <DisplayDataItem
                  sm={12}
                  value={acordo.getIn(['arquivo', 'nome'])}
                  id="arquivo"
                />
              </DisplayDataRow>
              {acordo.get('arquivo') && (
                <DisplayDataRow>
                  <DisplayDataItem
                    sm={12}
                    value={
                      <button
                        type="button"
                        className="btn neutral icon-left mt-xs"
                        onClick={this.onDownloadEstrutura(
                          acordo.getIn(['arquivo', 'eloArquivo', 'id'])
                        )}
                      >
                        <i className="fa fa-download"></i>
                        {`Baixar ${tamanho}`}
                      </button>
                    }
                    id="arquivoDownload"
                  />
                </DisplayDataRow>
              )}
            </Panel>
          </div>
        </Panel>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    acordo: state.acordoSemRecurso.get('acordo')
  };
}

const allActions = {
  ...actions,
  downloadArquivo
};

export default connect(mapStateToProps, allActions)(FormAcordoDetail);
