import { Map } from 'immutable';
import AutoComplete from 'portaltransparencia-common/components/AutoComplete.react.js';
import DatePicker from 'portaltransparencia-common/components/DatePicker.react.js';
import Input from 'portaltransparencia-common/components/EloInput.react.js';
import MesSelect from 'portaltransparencia-common/components/MesSelect.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import {
  onChange,
  resetCrud,
  changeFieldValue
} from 'portaltransparencia-common/crud/Actions.js';
import {
  addFilterRsql,
  addFilterUrl,
  addPagination
} from 'portaltransparencia-common/libs/AddFilter';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import { PARAM_DESPESAS_ENTIDADES_DIFERENTES } from 'portaltransparencia-common/parametros/ParametrosPortalTransparencia';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import FilterButtons from '../components/FilterButtons.react';
import ParametroService from '../parametros/ParametroService.js';
import * as actions from './Actions.js';

export class EmpenhosFiltro extends Component {
  static propTypes = {
    location: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    resetCrud: PropTypes.func.isRequired,
    errors: PropTypes.object,
    filtros: PropTypes.object,
    resetEmpenho: PropTypes.func.isRequired,
    page: PropTypes.object,
    uf: PropTypes.string,
    expansible: PropTypes.bool,
    fromTelaCompras: PropTypes.bool,
    fromTelaCovid: PropTypes.bool,
    fromTelaFundeb: PropTypes.bool,
    findOrgao: PropTypes.func.isRequired,
    findUnidade: PropTypes.func.isRequired,
    findFuncao: PropTypes.func.isRequired,
    findSubFuncao: PropTypes.func.isRequired,
    findPrograma: PropTypes.func.isRequired,
    findProjeto: PropTypes.func.isRequired,
    findElemento: PropTypes.func.isRequired,
    findDesdobramento: PropTypes.func.isRequired,
    findSubDesdobramento: PropTypes.func.isRequired,
    findFonteRecurso: PropTypes.func.isRequired,

    findEmpenhos: PropTypes.func.isRequired,
    findEmpenhosTotais: PropTypes.func.isRequired,
    saveFiltroListagem: PropTypes.func.isRequired,
    paramDespesasEntidadesDif: PropTypes.string,
    isIntegradoOxy: PropTypes.bool,
    exibeDesdobramento: PropTypes.bool,
    exibeSubDesdobramento: PropTypes.bool,
    actionExterno: PropTypes.func,
    notCollapseInitial: PropTypes.bool
  };

  static defaultProps = {
    expansible: true
  };

  componentDidMount() {
    this.loadInitialFiltros();
    this.onConsultaEmpenhoInitial();
  }

  loadInitialFiltros = () => {
    const { onChange } = this.props;
    const { exercicio } = StorageService.getItem('userInfo');

    onChange('filtroEmpenhos', {
      name: 'dataInicial',
      value: `${exercicio.id}-01-01`
    });
    onChange('filtroEmpenhos', {
      name: 'dataFinal',
      value: `${exercicio.id}-12-31`
    });

    onChange('filtroEmpenhos', {
      name: 'mesInicialEmissao',
      value: '1'
    });
    onChange('filtroEmpenhos', {
      name: 'mesFinalEmissao',
      value: '12'
    });
  };

  somenteNumeros(valor) {
    return valor.replace(/[^0-9]/g, '');
  }

  getFiltro = () => {
    const { entidade, exercicio } = StorageService.getItem('userInfo');
    const {
      filtros,
      page,
      fromTelaCompras,
      fromTelaCovid,
      fromTelaFundeb,
      paramDespesasEntidadesDif
    } = this.props;

    //Montando o SEARCH
    let filter = '';

    filter = addFilterRsql(filter, `id.exercicio=='${exercicio.id}'`);

    if (fromTelaCovid || fromTelaFundeb) {
      if (paramDespesasEntidadesDif !== 'S') {
        if (entidade.id > 0) {
          filter = addFilterRsql(filter, `id.entidade=='${entidade.id}'`);
        }
      }
    } else {
      filter = addFilterRsql(filter, `id.entidade=='${entidade.id}'`);
    }
    if (this.refs.cnpj && this.refs.cnpj.getValue().trim()) {
      filter = addFilterRsql(
        filter,
        `fornecedor.cnpj==${this.somenteNumeros(
          this.refs.cnpj.getValue().trim()
        )}`
      );
    }
    if (this.refs.nome && this.refs.nome.getValue().trim()) {
      filter = addFilterRsql(
        filter,
        `fornecedor.nome=="*${this.refs.nome.getValue().trim()}*"`
      );
    }
    if (this.refs.empenhoInicial && this.refs.empenhoInicial.getValue()) {
      filter = addFilterRsql(
        filter,
        `id.empenho>='${this.refs.empenhoInicial.getValue()}'`
      );
    }
    if (this.refs.empenhoFinal && this.refs.empenhoFinal.getValue()) {
      filter = addFilterRsql(
        filter,
        `id.empenho<='${this.refs.empenhoFinal.getValue()}'`
      );
    }
    if (this.refs.valorInicial && this.refs.valorInicial.getValue()) {
      filter = addFilterRsql(
        filter,
        `valor>='${this.refs.valorInicial.getValue()}'`
      );
    }
    if (this.refs.valorFinal && this.refs.valorFinal.getValue()) {
      filter = addFilterRsql(
        filter,
        `valor<='${this.refs.valorFinal.getValue()}'`
      );
    }
    if (filtros.get('dataInicial') && filtros.get('dataInicial').length == 10) {
      let validValue = DateUtils.transform(
        filtros.get('dataInicial'),
        'yy-mm-dd',
        'yy-mm-dd'
      );
      filter = addFilterRsql(filter, `data>='${validValue}'`);
    }
    if (filtros.get('dataFinal') && filtros.get('dataFinal').length == 10) {
      let validValue = DateUtils.transform(
        filtros.get('dataFinal'),
        'yy-mm-dd',
        'yy-mm-dd'
      );
      filter = addFilterRsql(filter, `data<='${validValue}'`);
    }
    if (
      filtros.get('dataInicialMovimentacao') &&
      filtros.get('dataInicialMovimentacao').length == 10
    ) {
      let validValue = DateUtils.transform(
        filtros.get('dataInicialMovimentacao'),
        'yy-mm-dd',
        'yy-mm-dd'
      );
      filter = addFilterRsql(filter, `fichasEmpenho.id.data>='${validValue}'`);
    }
    if (
      filtros.get('dataFinalMovimentacao') &&
      filtros.get('dataFinalMovimentacao').length == 10
    ) {
      let validValue = DateUtils.transform(
        filtros.get('dataFinalMovimentacao'),
        'yy-mm-dd',
        'yy-mm-dd'
      );
      filter = addFilterRsql(filter, `fichasEmpenho.id.data<='${validValue}'`);
    }

    filter = this.getFiltroProgramatica(filter);
    if (filter != '') {
      filter = 'search=' + filter;
    }

    if (fromTelaCovid) {
      filter = addFilterUrl(filter, "isCovid='S'");
    }

    if (fromTelaFundeb) {
      filter = addFilterUrl(filter, "isFundeb='S'");
      filter = addFilterUrl(filter, 'exercicio=' + exercicio.id);
    }

    //Passando Parametros para colocar no where do select
    if (fromTelaCovid || fromTelaFundeb) {
      if (paramDespesasEntidadesDif !== 'S') {
        if (entidade.id > 0) {
          filter = addFilterUrl(filter, 'entidade=' + entidade.id);
        }
      }
    } else {
      filter = addFilterUrl(filter, 'entidade=' + entidade.id);
    }
    if (this.refs.nome && this.refs.nome.getValue()) {
      filter = addFilterUrl(
        filter,
        'nome=%' + this.refs.nome.getValue().toUpperCase() + '%'
      );
    }

    if (this.refs.apagar && this.refs.apagar.getChecked()) {
      filter = addFilterUrl(filter, 'aPagar=' + this.refs.apagar.getChecked());
    }
    if (this.refs.passagens && this.refs.passagens.getChecked()) {
      filter = addFilterUrl(
        filter,
        'passagens=' + this.refs.passagens.getChecked()
      );
    }
    if (this.refs.consumo && this.refs.consumo.getChecked()) {
      filter = addFilterUrl(
        filter,
        'consumo=' + this.refs.consumo.getChecked()
      );
    }
    if (this.refs.permanente && this.refs.permanente.getChecked()) {
      filter = addFilterUrl(
        filter,
        'permanente=' + this.refs.permanente.getChecked()
      );
    }
    if (
      this.refs.restituicoesCombustiveis &&
      this.refs.restituicoesCombustiveis.getChecked()
    ) {
      filter = addFilterUrl(
        filter,
        'restituicoesCombustiveis=' +
          this.refs.restituicoesCombustiveis.getChecked()
      );
      filter = addFilterUrl(filter, 'exercicio=' + exercicio.id);
    }

    if (
      this.refs.auxilioFinanceiro &&
      this.refs.auxilioFinanceiro.getChecked()
    ) {
      filter = addFilterUrl(
        filter,
        'auxilioFinanceiro=' + this.refs.auxilioFinanceiro.getChecked()
      );
      filter = addFilterUrl(filter, 'exercicio=' + exercicio.id);
    }
    if (this.refs.comprasDireta && this.refs.comprasDireta.getChecked()) {
      filter = addFilterUrl(
        filter,
        'comprasDireta=' + this.refs.comprasDireta.getChecked()
      );
      if (fromTelaCompras && filtros.get('mesInicialMov')) {
        filter = addFilterUrl(
          filter,
          'mesInicialMov=' + filtros.get('mesInicialMov')
        );
      }
      if (fromTelaCompras && filtros.get('mesFinalMov')) {
        filter = addFilterUrl(
          filter,
          'mesFinalMov=' + filtros.get('mesFinalMov')
        );
      }
      if (
        fromTelaCompras &&
        filtros.get('mesInicialEmissao') &&
        filtros.get('mesFinalEmissao')
      ) {
        filter = addFilterUrl(
          filter,
          'mesInicialEmissao=' + filtros.get('mesInicialEmissao')
        );
        filter = addFilterUrl(
          filter,
          'mesFinalEmissao=' + filtros.get('mesFinalEmissao')
        );
      }
    }

    return addPagination(page, filter);
  };

  onConsultaEmpenho = event => {
    const { saveFiltroListagem } = this.props;
    let filter = this.getFiltro();
    let notCollapse;

    if (filter != '') {
      filter = '?' + filter;
      saveFiltroListagem(filter);
      this.carregaDados(filter);
      this.refs.panelFiltroProgramatica &&
        this.refs.panelFiltroProgramatica.collapse();
      this.refs.panelFiltro && this.refs.panelFiltro.collapse();
    }
    event && event.preventDefault();
  };

  onConsultaEmpenhoInitial = event => {
    const { saveFiltroListagem, notCollapseInitial } = this.props;
    let filter = this.getFiltro();
    let notCollapse;

    if (notCollapseInitial) {
      notCollapse = notCollapseInitial;
    } else {
      notCollapse = false;
    }

    if (filter != '') {
      filter = '?' + filter;
      saveFiltroListagem(filter);
      this.carregaDados(filter);
      if (notCollapse == false) {
        this.refs.panelFiltroProgramatica &&
          this.refs.panelFiltroProgramatica.collapse();
        this.refs.panelFiltro && this.refs.panelFiltro.collapse();
      }
    }
    event && event.preventDefault();
  };

  carregaDados = filter => {
    const {
      findEmpenhos,
      findEmpenhosTotais,
      actionExterno,
      fromTelaCovid,
      fromTelaFundeb,
      paramDespesasEntidadesDif
    } = this.props;
    let userInfo = StorageService.getItem('userInfo');
    const { entidade } = userInfo;

    if (filter != null) {
      if (actionExterno) {
        actionExterno(filter);
      } else {
        if (fromTelaCovid || fromTelaFundeb) {
          if (paramDespesasEntidadesDif !== 'S') {
            filter = filter + '&entidade=' + entidade.id;
          }
        }
        findEmpenhos(filter).then(findEmpenhosTotais(filter));
      }
    }
  };

  onChangeFiltro = event => {
    const { onChange } = this.props;
    new Promise(resolve => {
      onChange('filtroEmpenhos', event);
      resolve();
    }).then(() => {
      switch (event.name) {
        case 'orgao': {
          this.findOrgao();
        }
        case 'unidade': {
          this.findUnidade();
        }
        case 'funcao': {
          this.findFuncao();
        }
        case 'subFuncao': {
          this.findSubFuncao();
        }
        case 'programa': {
          this.findPrograma();
        }
        case 'projeto': {
          this.findProjeto();
        }
        case 'elemento': {
          this.findElemento();
        }
        case 'desdobramento': {
          this.findDesdobramento();
        }
        case 'subDesdobramento': {
          this.findSubDesdobramento();
        }
        case 'fonteRecurso': {
          this.findFonteRecurso();
        }
      }
    });
  };

  onChangeFiltroElemento = event => {
    const { changeFieldValue } = this.props;

    if (event.value) {
      this.exibeDesdobramento = true;
      this.exibeSubDesdobramento = true;
    } else {
      this.exibeDesdobramento = false;
      this.exibeSubDesdobramento = false;

      changeFieldValue('filtroEmpenhos', 'desdobramento', '');
      changeFieldValue('filtroEmpenhos', 'subDesdobramento', '');
    }

    this.onChangeFiltro(event);
  };

  onChangeFiltroDesdobramento = event => {
    const { changeFieldValue } = this.props;

    changeFieldValue('filtroEmpenhos', 'subDesdobramento', '');

    this.onChangeFiltro(event);
  };

  onResetFiltro = () => {
    const { resetCrud, resetEmpenho } = this.props;
    resetCrud('filtroEmpenhos');
    resetEmpenho();
  };

  findOrgao = () => {
    const { findOrgao } = this.props;
    return findOrgao(this.getFiltroProgramatica());
  };

  findUnidade = () => {
    const { findUnidade } = this.props;
    return findUnidade(this.getFiltroProgramatica());
  };

  findFuncao = () => {
    const { findFuncao } = this.props;
    return findFuncao(this.getFiltroProgramatica());
  };

  findSubFuncao = () => {
    const { findSubFuncao } = this.props;
    return findSubFuncao(this.getFiltroProgramatica());
  };

  findPrograma = () => {
    const { findPrograma } = this.props;
    return findPrograma(this.getFiltroProgramatica());
  };

  findProjeto = () => {
    const { findProjeto } = this.props;
    return findProjeto(this.getFiltroProgramatica());
  };

  findElemento = () => {
    const { findElemento } = this.props;
    return findElemento(this.getFiltroProgramatica());
  };

  findDesdobramento = () => {
    const { findDesdobramento } = this.props;
    return findDesdobramento(this.getFiltroProgramatica());
  };

  findSubDesdobramento = () => {
    const { findSubDesdobramento } = this.props;
    return findSubDesdobramento(this.getFiltroProgramatica());
  };

  findFonteRecurso = () => {
    const { findFonteRecurso } = this.props;
    return findFonteRecurso(this.getFiltroProgramatica());
  };

  getFiltroProgramatica = filtroEmpenho => {
    const { filtros } = this.props;
    const orgao = filtros.get('orgao');
    const unidade = filtros.get('unidade');
    const funcao = filtros.get('funcao');
    const subFuncao = filtros.get('subFuncao');
    const programa = filtros.get('programa');
    const projeto = filtros.get('projeto');
    const elemento = filtros.get('elemento');
    const desdobramento = filtros.get('desdobramento');
    const subDesdobramento = filtros.get('subDesdobramento');
    const fonteRecurso = filtros.get('fonteRecurso');

    let filter = '';
    let prefix = '';
    if (filtroEmpenho) {
      filter = filtroEmpenho;
      prefix = 'programatica.';
    }
    if (orgao) {
      filter = addFilterUrl(filter, prefix + 'orgao=' + orgao.get('codigo'));
      if (unidade) {
        filter = addFilterUrl(
          filter,
          prefix + 'unidade=' + unidade.get('codigo')
        );
      }
    }
    if (funcao) {
      filter = addFilterUrl(filter, prefix + 'funcao=' + funcao.get('codigo'));
    }
    if (subFuncao) {
      filter = addFilterUrl(
        filter,
        prefix + 'subFuncao=' + subFuncao.get('codigo')
      );
    }
    if (programa) {
      filter = addFilterUrl(
        filter,
        prefix + 'programa=' + programa.get('codigo')
      );
    }
    if (projeto) {
      filter = addFilterUrl(
        filter,
        prefix + 'projeto=' + projeto.get('codigo')
      );
    }
    if (elemento) {
      this.exibeDesdobramento = true;
      this.exibeSubDesdobramento = true;

      filter = addFilterUrl(
        filter,
        prefix + 'elemento=' + elemento.get('codigo')
      );
    }
    if (desdobramento) {
      filter = addFilterUrl(
        filter,
        prefix + 'desdobramento=' + desdobramento.get('codigo')
      );
    }
    if (subDesdobramento) {
      filter = addFilterUrl(
        filter,
        prefix + 'subDesdobramento=' + subDesdobramento.get('codigo')
      );
    }
    if (fonteRecurso) {
      filter = addFilterUrl(
        filter,
        'fonteRecurso=' + fonteRecurso.get('codigo')
      );
    }
    return filter;
  };

  renderValue = option => {
    return <span>{option.codigo + ' - ' + option.descricao}</span>;
  };

  renderFiltroProgramatica = () => {
    const { filtros, errors } = this.props;

    return (
      <div className="panel-inside-panel mt-xs">
        <Panel
          insidePanel
          title="Especificar Programática"
          ref="panelFiltroProgramatica"
          isTable
          tableResponsive
        >
          <Row>
            <Col xs={12} sm={4} md={4} key={filtros.get('orgao')}>
              <AutoComplete
                label="Órgão"
                id="orgao"
                name="orgao"
                onLoad={this.findOrgao}
                onChange={this.onChangeFiltro}
                value={filtros.get('orgao')}
                errors={errors}
                valueKey="codigo"
                labelKey="descricao"
                placeholder="Selecione o Órgão"
                clearable
                valueRenderer={this.renderValue}
              />
            </Col>
            <Col xs={12} sm={4} md={4} key={filtros.get('unidade')}>
              <AutoComplete
                label="Unidade"
                id="unidade"
                name="unidade"
                onLoad={this.findUnidade}
                onChange={this.onChangeFiltro}
                value={filtros.get('unidade')}
                errors={errors}
                valueKey="codigo"
                labelKey="descricao"
                placeholder="Selecione a Unidade"
                valueRenderer={this.renderValue}
              />
            </Col>
            <Col xs={12} sm={4} md={4} key={filtros.get('funcao')}>
              <AutoComplete
                label="Função"
                id="funcao"
                name="funcao"
                onLoad={this.findFuncao}
                onChange={this.onChangeFiltro}
                value={filtros.get('funcao')}
                errors={errors}
                valueKey="codigo"
                labelKey="descricao"
                placeholder="Selecione a Função"
                valueRenderer={this.renderValue}
              />
            </Col>
            <Col xs={12} sm={4} md={4} key={filtros.get('subFuncao')}>
              <AutoComplete
                label="Sub Função"
                id="subFuncao"
                name="subFuncao"
                onLoad={this.findSubFuncao}
                onChange={this.onChangeFiltro}
                value={filtros.get('subFuncao')}
                errors={errors}
                valueKey="codigo"
                labelKey="descricao"
                placeholder="Selecione a Sub Função"
                valueRenderer={this.renderValue}
              />
            </Col>
            <Col xs={12} sm={4} md={4} key={filtros.get('programa')}>
              <AutoComplete
                label="Programa"
                id="programa"
                name="programa"
                onLoad={this.findPrograma}
                onChange={this.onChangeFiltro}
                value={filtros.get('programa')}
                errors={errors}
                valueKey="codigo"
                labelKey="descricao"
                placeholder="Selecione o Programa"
                valueRenderer={this.renderValue}
              />
            </Col>
            <Col xs={12} sm={4} md={4} key={filtros.get('projeto')}>
              <AutoComplete
                label="Projeto"
                id="projeto"
                name="projeto"
                onLoad={this.findProjeto}
                onChange={this.onChangeFiltro}
                value={filtros.get('projeto')}
                errors={errors}
                valueKey="codigo"
                labelKey="descricao"
                placeholder="Selecione o Projeto"
                valueRenderer={this.renderValue}
              />
            </Col>
            <Col xs={12} sm={4} md={4} key={filtros.get('elemento')}>
              <AutoComplete
                label="Elemento"
                id="elemento"
                name="elemento"
                onLoad={this.findElemento}
                onChange={this.onChangeFiltroElemento}
                value={filtros.get('elemento')}
                errors={errors}
                valueKey="codigo"
                labelKey="descricao"
                placeholder="Selecione o Elemento"
                valueRenderer={this.renderValue}
              />
            </Col>
            {this.exibeDesdobramento && (
              <Col xs={12} sm={4} md={4} key={filtros.get('desdobramento')}>
                <AutoComplete
                  label="Desdobramento"
                  id="desdobramento"
                  name="desdobramento"
                  onLoad={this.findDesdobramento}
                  onChange={this.onChangeFiltroDesdobramento}
                  value={filtros.get('desdobramento')}
                  errors={errors}
                  valueKey="codigo"
                  labelKey="descricao"
                  placeholder="Selecione o Desdobramento"
                  valueRenderer={this.renderValue}
                />
              </Col>
            )}
            {this.exibeSubDesdobramento && (
              <Col xs={12} sm={4} md={4} key={filtros.get('subDesdobramento')}>
                <AutoComplete
                  label="Sub Desdobramento"
                  id="subDesdobramento"
                  name="subDesdobramento"
                  onLoad={this.findSubDesdobramento}
                  onChange={this.onChangeFiltro}
                  value={filtros.get('subDesdobramento')}
                  errors={errors}
                  valueKey="codigo"
                  labelKey="descricao"
                  placeholder="Selecione o Sub Desdobramento"
                  valueRenderer={this.renderValue}
                />
              </Col>
            )}
            <Col xs={12} sm={4} md={4} key={filtros.get('fonteRecurso')}>
              <AutoComplete
                label="Fonte Recurso"
                id="fonteRecurso"
                name="fonteRecurso"
                onLoad={this.findFonteRecurso}
                onChange={this.onChangeFiltro}
                value={filtros.get('fonteRecurso')}
                errors={errors}
                valueKey="codigo"
                labelKey="descricao"
                placeholder="Selecione a Fonte Recurso"
                valueRenderer={this.renderValue}
              />
            </Col>
          </Row>
        </Panel>
      </div>
    );
  };

  render() {
    const { filtros, location, uf, fromTelaCompras, expansible } = this.props;

    let disableComprasDireta = false;
    if (location && location.pathname == '/compras-direta-itens') {
      disableComprasDireta = true;
    }

    return (
      <Panel
        title="Consultar em Empenhos"
        isForm
        ref="panelFiltro"
        expansible={expansible}
      >
        <form>
          <Row>
            <Col xs={12} sm={4} md={4}>
              <Input
                id="cnpj"
                name="cnpj"
                ref="cnpj"
                placeholder="CNPJ/CPF"
                label="CNPJ/CPF"
                type="text"
                onChange={this.onChangeFiltro}
                value={filtros.get('cnpj')}
              />
            </Col>
            <Col xs={12} sm={8} md={8}>
              <Input
                id="nome"
                name="nome"
                ref="nome"
                placeholder="Nome do Fornecedor"
                label="Nome do Fornecedor"
                type="text"
                onChange={this.onChangeFiltro}
                value={filtros.get('nome')}
              />
            </Col>
            <Col xs={6} sm={6} md={3}>
              <Input
                id="valorInicial"
                type="number"
                ref="valorInicial"
                name="valorInicial"
                placeholder="Valor empenhado maior que"
                label="Valor empenhado maior que"
                onChange={this.onChangeFiltro}
                value={filtros.get('valorInicial')}
              />
            </Col>
            <Col xs={6} sm={6} md={3}>
              <Input
                id="valorFinal"
                type="number"
                ref="valorFinal"
                name="valorFinal"
                placeholder="Valor empenhado menor que"
                label="Valor empenhado menor que"
                onChange={this.onChangeFiltro}
                value={filtros.get('valorFinal')}
              />
            </Col>
            <Col xs={6} sm={6} md={3}>
              <Input
                id="empenhoInicial"
                type="number"
                ref="empenhoInicial"
                name="empenhoInicial"
                placeholder="Número Empenho Inicial"
                label="Empenho Inicial"
                onChange={this.onChangeFiltro}
                value={filtros.get('empenhoInicial')}
              />
            </Col>
            <Col xs={6} sm={6} md={3}>
              <Input
                id="empenhoFinal"
                type="number"
                ref="empenhoFinal"
                name="empenhoFinal"
                placeholder="Número Empenho Final"
                label="Empenho Final"
                onChange={this.onChangeFiltro}
                value={filtros.get('empenhoFinal')}
              />
            </Col>
            <Col xs={12} sm={12} md={12}>
              <div className="form-group">
                <label className="label"></label>
                <div className="radio-check-group">
                  <Input
                    id="apagar"
                    label="Somente A Pagar"
                    type="checkbox"
                    name="apagar"
                    ref="apagar"
                    onChange={this.onChangeFiltro}
                    checked={filtros.get('apagar')}
                  />
                  <Input
                    id="passagens"
                    label="Somente Passagens"
                    type="checkbox"
                    name="passagens"
                    ref="passagens"
                    onChange={this.onChangeFiltro}
                    checked={filtros.get('passagens')}
                  />
                  <Input
                    id="comprasDireta"
                    label="Compras Diretas"
                    type="checkbox"
                    name="comprasDireta"
                    ref="comprasDireta"
                    onChange={this.onChangeFiltro}
                    checked={filtros.get('comprasDireta')}
                    disabled={disableComprasDireta}
                  />
                  <Input
                    id="restituicoesCombustiveis"
                    label="Restituições de Combustíveis e Lubrificantes"
                    type="checkbox"
                    name="restituicoesCombustiveis"
                    ref="restituicoesCombustiveis"
                    onChange={this.onChangeFiltro}
                    checked={filtros.get('restituicoesCombustiveis')}
                  />
                  <Input
                    id="auxilioFinanceiro"
                    label="Auxílio Financeiro (COVID-19)"
                    type="checkbox"
                    name="auxilioFinanceiro"
                    ref="auxilioFinanceiro"
                    onChange={this.onChangeFiltro}
                    checked={filtros.get('auxilioFinanceiro')}
                  />
                  {disableComprasDireta && (
                    <Input
                      id="consumo"
                      label="Somente Consumo"
                      type="checkbox"
                      name="consumo"
                      ref="consumo"
                      onChange={this.onChangeFiltro}
                      checked={filtros.get('consumo')}
                    />
                  )}
                  {disableComprasDireta && (
                    <Input
                      id="permanente"
                      label="Somente Permanente"
                      type="checkbox"
                      name="permanente"
                      ref="permanente"
                      onChange={this.onChangeFiltro}
                      checked={filtros.get('permanente')}
                    />
                  )}
                </div>
              </div>
            </Col>
            {(!fromTelaCompras || uf != 'RO') && (
              <div>
                <Col xs={6} sm={4} md={3}>
                  <DatePicker
                    id="dataInicialMovimentacao"
                    name="dataInicialMovimentacao"
                    ref="dataInicialMovimentacao"
                    label="Data Inicial Movimentação"
                    onChange={this.onChangeFiltro}
                    value={filtros.get('dataInicialMovimentacao')}
                  />
                </Col>
                <Col xs={6} sm={4} md={3}>
                  <DatePicker
                    id="dataFinalMovimentacao"
                    name="dataFinalMovimentacao"
                    ref="dataFinalMovimentacao"
                    label="Data Final Movimentação"
                    onChange={this.onChangeFiltro}
                    value={filtros.get('dataFinalMovimentacao')}
                  />
                </Col>
                <Col xs={6} sm={4} md={3}>
                  <DatePicker
                    id="dataInicial"
                    name="dataInicial"
                    ref="dataInicial"
                    label="Data Inicial Emissão"
                    onChange={this.onChangeFiltro}
                    value={filtros.get('dataInicial')}
                  />
                </Col>
                <Col xs={6} sm={4} md={3}>
                  <DatePicker
                    id="dataFinal"
                    name="dataFinal"
                    ref="dataFinal"
                    label="Data Final Emissão"
                    onChange={this.onChangeFiltro}
                    value={filtros.get('dataFinal')}
                  />
                </Col>
              </div>
            )}
            {uf == 'RO' && fromTelaCompras && (
              <div>
                <Col xs={6} sm={4} md={3}>
                  <MesSelect
                    nomeCampo={'mesInicialMov'}
                    labelCampo={'Mês Inicial Movimentação'}
                    onChangeFiltro={this.onChangeFiltro}
                    value={filtros.get('mesInicialMov')}
                  />
                </Col>
                <Col xs={6} sm={4} md={3}>
                  <MesSelect
                    nomeCampo={'mesFinalMov'}
                    labelCampo={'Mês Final Movimentação'}
                    onChangeFiltro={this.onChangeFiltro}
                    value={filtros.get('mesFinalMov')}
                  />
                </Col>
                <Col xs={6} sm={4} md={3}>
                  <MesSelect
                    nomeCampo={'mesInicialEmissao'}
                    labelCampo={'Mês Inicial Emissão'}
                    onChangeFiltro={this.onChangeFiltro}
                    value={filtros.get('mesInicialEmissao')}
                  />
                </Col>
                <Col xs={6} sm={4} md={3}>
                  <MesSelect
                    nomeCampo={'mesFinalEmissao'}
                    labelCampo={'Mês Final Emissão'}
                    onChangeFiltro={this.onChangeFiltro}
                    value={filtros.get('mesFinalEmissao')}
                  />
                </Col>
              </div>
            )}
          </Row>

          {this.renderFiltroProgramatica()}

          <FilterButtons
            idBtnConsulta="btnFiltrar"
            onConsulta={this.onConsultaEmpenho}
            onReset={this.onResetFiltro}
          />
        </form>
      </Panel>
    );
  }
}
function mapStateToProps(state) {
  return {
    filtros: state.crud.getIn(['filtroEmpenhos', 'currentRecord'], Map({})),
    uf: state.entidade.getIn(['entidadeSelecionada', 'uf']),
    paramDespesasEntidadesDif: ParametroService.getValorParametro(
      state,
      PARAM_DESPESAS_ENTIDADES_DIFERENTES
    ),
    isIntegradoOxy: state.entidade.getIn([
      'configuracoesGerais',
      'isIntegradoOxy'
    ])
  };
}
const allActions = {
  ...actions,
  onChange,
  resetCrud,
  changeFieldValue
};
export default connect(mapStateToProps, allActions, null, { forwardRef: true })(
  EmpenhosFiltro
);
