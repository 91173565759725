import Input from 'portaltransparencia-common/components/EloInput.react.js';
import Validation from 'portaltransparencia-common/components/Validation.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export class FormSolicitacaoDetailPedido extends Component {
  static propTypes = {
    dados: PropTypes.object,
    onChangeDados: PropTypes.func.isRequired,
    errors: PropTypes.object,
    assuntos: PropTypes.object,
    getValidationMessages: PropTypes.func.isRequired,
    isIntegradoOxy: PropTypes.bool
  };

  getValidatorData = () => {
    const { dados, isIntegradoOxy } = this.props;

    const dataValidator = {
      assunto: dados.get('assunto'),
      assuntoOxy: isIntegradoOxy ? dados.get('assuntoOxy') : true,
      digitacao: dados.get('digitacao'),
      formaRecebimento: dados.get('formaRecebimento')
    };

    return dataValidator;
  };

  renderAssuntos = assunto =>
    assunto && (
      <option key={assunto.get('id')} value={assunto.get('id')}>
        {`${assunto.get('id')} - ${assunto.get('descricao')}`}
      </option>
    );

  renderHelpText = message => {
    return <span className="help-message">{message}</span>;
  };

  render() {
    const {
      dados,
      onChangeDados,
      errors,
      getValidationMessages,
      isIntegradoOxy,
      assuntos
    } = this.props;

    return (
      <div>
        {isIntegradoOxy && (
          <Input
            name="assuntoOxy"
            label="Categoria"
            ref="assuntoOxy"
            type="select"
            onChange={onChangeDados}
            value={dados.get('assuntoOxy')}
            errors={errors}
          >
            <option>Selecione uma opção</option>
            {assuntos?.map(this.renderAssuntos)}
          </Input>
        )}
        <Input
          label="Assunto"
          type="text"
          name="assunto"
          ref="assunto"
          onChange={onChangeDados}
          value={dados.get('assunto')}
          errors={errors}
        />
        <Input
          label="(Nº/Ano) do protocolo, para o caso de recurso"
          type="text"
          name="protocoloRecurso"
          ref="protocoloRecurso"
          onChange={onChangeDados}
          value={dados.get('protocoloRecurso')}
          errors={errors}
        />
        <Input
          label="Descrição da Solicitação"
          type="textarea"
          name="digitacao"
          ref="digitacao"
          onChange={onChangeDados}
          value={dados.get('digitacao')}
          errors={errors}
        />
        <br />
        Forma de Recebimento:
        {dados.get('tipoSolicitacao') == 'I' && (
          <div>
            <Input
              label="E-mail"
              type="radio"
              name="formaRecebimento"
              onChange={onChangeDados}
              value="E-mail"
              checked={dados.get('formaRecebimento') == 'E-mail'}
              disabled={dados.get('tipoSolicitacao') == 'A'}
              errors={errors}
            />
            <Input
              label="Papel (Valor das cópias custeado pelo solicitante)"
              type="radio"
              name="formaRecebimento"
              onChange={onChangeDados}
              value="Papel"
              checked={dados.get('formaRecebimento') == 'Papel'}
              errors={errors}
            />
            <Input
              label="CD/DVD(Fornecido pelo solicitante)"
              type="radio"
              name="formaRecebimento"
              onChange={onChangeDados}
              value="CD/DVD"
              checked={dados.get('formaRecebimento') == 'CD/DVD'}
              errors={errors}
            />
            <Input
              label="Pendrive(Fornecido pelo solicitante)"
              type="radio"
              name="formaRecebimento"
              onChange={onChangeDados}
              value="Pendrive"
              checked={dados.get('formaRecebimento') == 'Pendrive'}
              errors={errors}
            />
          </div>
        )}
        <Input
          label="On-Line"
          type="radio"
          name="formaRecebimento"
          onChange={onChangeDados}
          value="On-Line"
          checked={dados.get('formaRecebimento') == 'On-Line'}
          errors={errors}
        />
        {this.renderHelpText(getValidationMessages('formaRecebimento'))}
      </div>
    );
  }
}

const validationSchema = {
  rules: {
    assuntoOxy: 'required',
    assunto: 'required',
    digitacao: 'required|max:4000',
    formaRecebimento: 'required'
  },
  labels: {
    assuntoOxy: 'Categoria',
    assunto: 'Assunto',
    digitacao: 'Descrição da solicitação',
    formaRecebimento: 'Forma de recebimento'
  }
};

FormSolicitacaoDetailPedido = Validation(validationSchema)(
  FormSolicitacaoDetailPedido
);

export default FormSolicitacaoDetailPedido;
