export const REPASSES_ENVIADOS = 'REPASSES_ENVIADOS';
export const REPASSES_ENVIADOS_START = 'REPASSES_ENVIADOS_START';
export const REPASSES_ENVIADOS_SUCCESS = 'REPASSES_ENVIADOS_SUCCESS';
export const REPASSES_ENVIADOS_ERROR = 'REPASSES_ENVIADOS_ERROR';

export const REPASSES_RECEBIDOS = 'REPASSES_RECEBIDOS';
export const REPASSES_RECEBIDOS_START = 'REPASSES_RECEBIDOS_START';
export const REPASSES_RECEBIDOS_SUCCESS = 'REPASSES_RECEBIDOS_SUCCESS';
export const REPASSES_RECEBIDOS_ERROR = 'REPASSES_RECEBIDOS_ERROR';

export const REPASSES_MES = 'REPASSES_MES';
export const REPASSES_MES_START = 'REPASSES_MES_START';
export const REPASSES_MES_SUCCESS = 'REPASSES_MES_SUCCESS';
export const REPASSES_MES_ERROR = 'REPASSES_MES_ERROR';
