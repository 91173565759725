import ButtonSwitch from 'portaltransparencia-common/components/buttonswitch/ButtonSwitch.react.js';
import ButtonSwitchItem from 'portaltransparencia-common/components/buttonswitch/ButtonSwitchItem.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import TextLink from 'portaltransparencia-common/components/TextLink.react';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import NotasExplicativas from '../notaexplicativa/Form.react';
import * as actions from './Actions.js';
import StringUtils from 'portaltransparencia-common/libs/StringUtils.js';

export class FormGlossario extends Component {
  static propTypes = {
    glossario: PropTypes.object.isRequired,
    glossarioFiltrado: PropTypes.object.isRequired,
    letraSelecionada: PropTypes.string,
    findGlossario: PropTypes.func.isRequired,
    selecionarLetra: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired
  };

  componentDidMount() {
    const { findGlossario } = this.props;

    findGlossario();
  }

  renderGlossario = data => {
    return (
      <Panel insidePanel key={data.get('id')} title={data.get('topico')}>
        <Row>
          <Col lg={12}>
            <TextLink text={data.get('descricao')} />
          </Col>
        </Row>
      </Panel>
    );
  };

  selecionarLetra = letra => {
    return () => this.props.selecionarLetra(letra);
  };

  render() {
    const {
      glossario,
      selecionarLetra,
      glossarioFiltrado,
      letraSelecionada
    } = this.props;

    if (glossario && !glossario.isEmpty()) {
      let alfabeto = Array.from(
        glossario
          .map(t =>
            StringUtils.removeAcento(
              t.get('topico').toLowerCase().substring(0, 1)
            ).toUpperCase()
          )
          .toSet()
      ).sort();

      if (!letraSelecionada && alfabeto.length > 0) {
        selecionarLetra(alfabeto[0]);
      }

      return (
        <section>
          <h1 className="text-title center mb-xs">Glossário</h1>

          <NotasExplicativas pathname={this.props.location.pathname} />

          <Panel expansible={false}>
            <div className="btn-group center">
              <ButtonSwitch center={true}>
                {alfabeto &&
                  alfabeto.map(letra => {
                    return (
                      <ButtonSwitchItem
                        key={letra}
                        onClick={this.selecionarLetra(letra)}
                        title={letra}
                        value={letra}
                        active={letra == letraSelecionada}
                      />
                    );
                  })}
              </ButtonSwitch>
            </div>

            <hr className="mt-xs mb-xs" />

            {glossarioFiltrado && glossarioFiltrado.map(this.renderGlossario)}
          </Panel>
        </section>
      );
    }

    return (
      <section>
        <h1 className="text-title center mb-xs">Glossário</h1>
        <div className="info">Nenhum termo no glossário encontrado.</div>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    glossario: state.glossario.get('glossario'),
    glossarioFiltrado: state.glossario.get('glossarioFiltrado'),
    letraSelecionada: state.glossario.get('letraSelecionada')
  };
}

export default connect(mapStateToProps, actions)(FormGlossario);
