import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import NotaExplicativaModal from './NotaExplicativaModal.react';
import * as actions from './Actions.js';

export class NotasExplicativas extends Component {
  static propTypes = {
    pathname: PropTypes.string.isRequired,
    notasFiltradas: PropTypes.object,
    findNotaExplicativaByMenu: PropTypes.func,
    findImage: PropTypes.func,
    image: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      modalStates: {}
    };
  }

  componentDidMount() {
    const { findNotaExplicativaByMenu, pathname } = this.props;
    findNotaExplicativaByMenu(pathname);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { findNotaExplicativaByMenu, pathname } = this.props;

    if (pathname !== nextProps.pathname) {
      findNotaExplicativaByMenu(nextProps.pathname);
    }

    if (nextProps.notasFiltradas !== this.props.notasFiltradas) {
      this.initializeModalStates(nextProps.notasFiltradas);
    }
  }

  initializeModalStates = notasFiltradas => {
    const popUpNotas = notasFiltradas.filter(
      nota => nota.get('tipoNotaExplicativa').get('id') == 2
    );

    const modalStates = {};
    popUpNotas.forEach(nota => {
      modalStates[nota.get('id')] = true;
    });
    this.setState({ modalStates });
  };

  onCloseModal = modalId => {
    this.setState(prevState => ({
      modalStates: {
        ...prevState.modalStates,
        [modalId]: false
      }
    }));
  };

  renderNotas = notas => {
    const { modalStates } = this.state;
    const { image } = this.props;

    const textMessage = notas.filter(
      nota => nota.get('tipoNotaExplicativa').get('id') == 1
    );
    const popUp = notas.filter(
      nota => nota.get('tipoNotaExplicativa').get('id') == 2
    );

    return (
      <>
        {textMessage.size > 0 && (
          <Row>
            <Col sm={12} className="mb-xs">
              <div className="info primary icon">
                <div className="icon">
                  <em className="fa fa-info-circle"></em>
                </div>
                <article>
                  <h4>
                    {textMessage.map(nota => (
                      <li key={nota.get('id')} style={{ marginTop: '5px' }}>
                        {nota.get('notaExplicativa')}
                      </li>
                    ))}
                  </h4>
                </article>
              </div>
            </Col>
          </Row>
        )}
        {popUp.map(nota => (
          <div key={nota.get('id')} style={{ marginTop: '54px' }}>
            <NotaExplicativaModal
              modalId={nota.get('id')}
              nota={nota}
              onClose={() => this.onCloseModal(nota.get('id'))}
              image={image.url}
              show={modalStates[nota.get('id')]}
            />
          </div>
        ))}
      </>
    );
  };

  render() {
    const { notasFiltradas } = this.props;

    const notasFiltradasEspecificas = notasFiltradas.filter(
      n => n.get('path') === this.props.pathname
    );

    if (!notasFiltradasEspecificas || notasFiltradasEspecificas.isEmpty()) {
      return null;
    }
    return this.renderNotas(notasFiltradasEspecificas);
  }
}

function mapStateToProps(state) {
  return {
    notasFiltradas: state.notaExplicativa.get('notasFiltradas'),
    image: state.notaExplicativa.get('image')
  };
}

export default connect(mapStateToProps, actions)(NotasExplicativas);
