import {
  LOGIN,
  LOGOUT_START,
  LOGOUT_SUCCESS,
  CHANGE_USERINFO,
  LOAD_USER_INFO,
  LOAD_USUARIO_LOGADO
} from './Types.js';
import ValidationError from '../libs/ValidationError.js';
import AuthService from './AuthService.js';

const createAuthenticationOptions = (body, { method, ...rest }) => {
  return {
    method: method || 'POST',
    local: '/unico-api/api',
    body: body && JSON.stringify(body),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...rest
    }
  };
};

export function login({ username, password, rememberMe }) {
  return ({ doCustomRequest, dispatch }) => {
    const getPromise = async () => {
      try {
        const response = await doCustomRequest(
          '/authenticate',
          createAuthenticationOptions({ username, password, rememberMe }, {})
        );
        const responseJson = await response.json();
        const token = responseJson.id_token;

        return AuthService.saveAuthentication(token, rememberMe);
      } catch (error) {
        if (error.status === 401) {
          throw new ValidationError('Usuário e/ou senha inválida', {
            field: 'password'
          });
        }
        throw error;
      }
    };

    return {
      type: LOGIN,
      payload: getPromise().then(() => dispatch(loadUserInfo())),
      meta: {
        credentials: {
          username,
          rememberMe
        }
      }
    };
  };
}

export function logout() {
  return ({ dispatch }) => {
    dispatch({ type: LOGOUT_START, payload: {} });

    AuthService.clearAuthentication();
    AuthService.clearUserInfo();

    return {
      type: LOGOUT_SUCCESS,
      payload: {}
    };
  };
}

const mergeUserInfo = userInfo => {
  const mergedUserInfo = {
    ...userInfo,
    entidade: userInfo.entidade || userInfo.entidadePadrao,
    exercicio: userInfo.exercicio || userInfo.exercicioPadrao
  };

  AuthService.saveUserInfo(mergedUserInfo);

  return mergedUserInfo;
};

export function updateUserInfo(userInfo) {
  const getPromise = async () => mergeUserInfo(userInfo);

  return {
    type: CHANGE_USERINFO,
    payload: getPromise()
  };
}

export function loadUserInfo() {
  return ({ fetch, dispatch }) => {
    return {
      type: LOAD_USER_INFO,
      payload: fetch('/userinfo')
        .then(mergeUserInfo)
        .then(userInfo => {
          dispatch(loadUsuarioLogado());
          return userInfo;
        })
    };
  };
}

export function loadUsuarioLogado() {
  return ({ fetch }) => {
    return {
      type: LOAD_USUARIO_LOGADO,
      payload: fetch(
        '/usuarios/me?fields=id,usuario,favoritos,modulos.modulo.id,modulos.modulo.descricao,' +
          'modulos.modulo.path,modulos.modulo.url',
        { local: '/unico-api/api' }
      )
    };
  };
}

export function reviveUserInfo() {
  return ({ fetch, dispatch }) => {
    const getPromise = async () => {
      const userInfo = await fetch('/userinfo');
      const currentUserInfo = AuthService.getUserInfo();
      const revivedUserInfo = {
        ...userInfo,
        ...currentUserInfo
      };

      return mergeUserInfo(revivedUserInfo);
    };

    return {
      type: LOAD_USER_INFO,
      payload: getPromise().then(userInfo => {
        dispatch(loadUsuarioLogado());
        return userInfo;
      })
    };
  };
}
