import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export class RecursoDetalheContrarrazao extends Component {
  static propTypes = {
    recursoContrarrazoes: PropTypes.object.isRequired
  };

  renderContrarrazoes = data => {
    return (
      <TableActions ref="row" key={data.get('id')} data={data}>
        <td>{DateUtils.formatDateTimeShort(data.get('data'))}</td>
        <td>
          <i
            className={`fas fa-${
              data.get('respeitadoPrazoLegal') === 'S'
                ? 'check green-60'
                : 'times red-60'
            }`}
          />
        </td>
        <td>{data.get('descricao')}</td>
        <td>{data.get('cnpjCpf')}</td>
        <td>{data.get('nomePessoa')}</td>
      </TableActions>
    );
  };

  render() {
    const { recursoContrarrazoes } = this.props;

    return (
      <div className="panel-table-outer">
        <table className="panel-table striped fancy">
          <TableHeader empty={recursoContrarrazoes.isEmpty()}>
            <tr>
              <th>Data</th>
              <th>Prazo Legal</th>
              <th>Descrição</th>
              <th>Documento</th>
              <th>Nome</th>
            </tr>
          </TableHeader>
          <tbody>{recursoContrarrazoes.map(this.renderContrarrazoes)}</tbody>
        </table>
      </div>
    );
  }
}

export default RecursoDetalheContrarrazao;
