import * as types from './Types.js';

import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';

import { fromJS } from 'immutable';

const initialState = fromJS({
  obras: [],
  obra: {},
  situacoes: [],
  medicoes: [],
  planilhas: [],
  medicaoArquivos: [],
  empenhos: [],
  ordemArquivos: [],
  fontesRecurso: [],
  responsaveis: [],
  isParalisada: false
});

function obraReducer(state = initialState, action) {
  switch (action.type) {
    case types.CONSULTA_OBRAS_START:
    case types.OBRAS_LIST_START:
    case types.OBRA_START:
    case types.SITUACOES_OBRAS_START:
    case types.SETISPARALISADA_OBRAS_START:
    case types.PLANILHAS_START:
    case types.MEDICAO_ARQUIVOS_START:
    case types.FONTES_RECURSO_START:
    case types.OBRA_RESPONSAVEL_START: {
      return startLoad(state);
    }

    case types.OBRAS_LIST_ERROR:
    case types.OBRA_ERROR:
    case types.SITUACOES_OBRAS_ERROR:
    case types.CONSULTA_OBRAS_ERROR:
    case types.SETISPARALISADA_OBRAS_ERROR:
    case types.MEDICOES_ERROR:
    case types.PLANILHAS_ERROR:
    case types.MEDICAO_ARQUIVOS_ERROR:
    case types.FONTES_RECURSO_ERROR:
    case types.OBRA_RESPONSAVEL_ERROR: {
      return endLoad(state);
    }

    case types.OBRAS_LIST_SUCCESS:
    case types.CONSULTA_OBRAS_SUCCESS: {
      return endLoad(state.set('obras', fromJS(action.payload)));
    }
    case types.OBRA_SUCCESS: {
      return endLoad(state.set('obra', fromJS(action.payload)));
    }
    case types.SITUACOES_OBRAS_SUCCESS: {
      return endLoad(state.set('situacoes', fromJS(action.payload)));
    }
    case types.MEDICOES_SUCCESS: {
      return endLoad(state.set('medicoes', fromJS(action.payload)));
    }
    case types.SETISPARALISADA_OBRAS_SUCCESS: {
      return state.set('isParalisada', fromJS(action.payload?.isParalisada));
    }
    case types.ORDEM_ARQUIVOS_SUCCESS: {
      return endLoad(state.set('ordemArquivos', fromJS(action.payload)));
    }
    case types.PLANILHAS_SUCCESS: {
      return endLoad(state.set('planilhas', fromJS(action.payload)));
    }
    case types.OBRA_EMPENHOS_SUCCESS: {
      return endLoad(state.set('empenhos', fromJS(action.payload)));
    }
    case types.MEDICAO_ARQUIVOS_SUCCESS: {
      return endLoad(state.set('medicaoArquivos', fromJS(action.payload)));
    }
    case types.FONTES_RECURSO_SUCCESS: {
      return endLoad(state.set('fontesRecurso', fromJS(action.payload)));
    }
    case types.OBRA_RESPONSAVEL_SUCCESS: {
      return endLoad(state.set('responsaveis', fromJS(action.payload)));
    }

    default:
      return state;
  }
}

export default obraReducer;
