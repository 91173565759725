import { Map } from 'immutable';
import ExportButtonGroup from 'portaltransparencia-common/components/ExportButtonGroup.react.js';
import Modal from 'portaltransparencia-common/components/Modal.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import TreeTable from 'portaltransparencia-common/components/treetable/TreeTable.react';
import configuration from 'portaltransparencia-common/config/Config.js';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import {
  getAllValuesFromUrlSearchParams,
  getValueFromUrlSearchParams
} from 'portaltransparencia-common/libs/URLUtils';
import { PARAM_EXIBE_LANCAMENTOS_EXECUCAO_RECEITA } from 'portaltransparencia-common/parametros/ParametrosPortalTransparencia';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';
import {
  onChange,
  resetCrud
} from 'portaltransparencia-common/crud/Actions.js';
import { addFilterUrl } from 'portaltransparencia-common/libs/AddFilter';
import DateUtils from 'portaltransparencia-common/libs/DateUtils';
import PageHeader from '../components/PageHeader.react.js';
import NotasExplicativas from '../notaexplicativa/Form.react';
import ParametroService from '../parametros/ParametroService.js';
import UltimaAtualizacaoByTable from '../ultimaatualizacao/UltimaAtualizacaoByTable.react.js';
import * as actions from './Actions.js';
import ChartReceita from './Chart.react';
import TableItem from './TableItem.js';
import FormReceitaFiltro from './FormReceitaFiltro.react.js';
import { addErrorNotification } from 'portaltransparencia-common/ui/Actions.js';

export class FormReceita extends Component {
  static propTypes = {
    valorMes: PropTypes.object.isRequired,
    findReceitasWithFiltro: PropTypes.func.isRequired,
    receitas: PropTypes.object.isRequired,
    getGraficoAnual: PropTypes.func.isRequired,
    receitaSelecionada: PropTypes.object,
    selecionarReceita: PropTypes.func.isRequired,
    expand: PropTypes.func.isRequired,
    collapse: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    mostrarValorLancado: PropTypes.bool.isRequired,
    filterReceitas: PropTypes.func.isRequired,
    resetReceitas: PropTypes.func.isRequired,
    addErrorNotification: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      width: 1140
    };
  }

  async componentDidMount() {
    const { getGraficoAnual } = this.props;

    await this.onResetReceitas();
    getGraficoAnual(this.getFiltroGrafico());

    const node = ReactDOM.findDOMNode(this);
    this.setState({
      width: node.clientWidth
    });
  }

  filtroInicial = () => {
    const userInfo = StorageService.getItem('userInfo');
    const { entidade, exercicio } = userInfo;

    const execucao = true;
    let filter = `entidade=${entidade.id}&exercicio=${exercicio.id}&execucao=${execucao}`;

    return filter;
  };

  montarFiltro = (filtroDateIni = '') => {
    const { location, filtros } = this.props;
    const fonteRecurso = getValueFromUrlSearchParams(
      location,
      'fonteRecursoRateio'
    );
    const { exercicio } = StorageService.getItem('userInfo');

    let filter = this.filtroInicial();

    if (filtroDateIni) {
      filter += filtroDateIni;
    }

    let fonte = '';
    if (fonteRecurso) {
      fonte = `&fonteRecurso=${fonteRecurso}`;
    }

    filter = addFilterUrl(filter, `${fonte}`);

    if (
      filtros.get('dataInicial') &&
      filtros.get('dataInicial').length === 10
    ) {
      filter = addFilterUrl(
        filter,
        `&dataInicial=${encodeURIComponent(
          DateUtils.transform(
            filtros.get('dataInicial'),
            'yy-mm-dd',
            'yy-mm-dd'
          )
        )}`
      );
    } else {
      filter = addFilterUrl(
        filter,
        `&dataInicial=${encodeURIComponent(
          DateUtils.transform(`${exercicio.id}-01-01`, 'yy-mm-dd', 'yy-mm-dd')
        )}`
      );
    }

    if (filtros.get('dataFinal') && filtros.get('dataFinal').length === 10) {
      filter = addFilterUrl(
        filter,
        `&dataFinal=${encodeURIComponent(
          DateUtils.transform(filtros.get('dataFinal'), 'yy-mm-dd', 'yy-mm-dd')
        )}`
      );
    } else {
      filter = addFilterUrl(
        filter,
        `&dataFinal=${encodeURIComponent(
          DateUtils.transform(`${exercicio.id}-12-31`, 'yy-mm-dd', 'yy-mm-dd')
        )}`
      );
    }

    return filter;
  };

  getFiltroGrafico = () => {
    // TODO: validar o que fazer quando nao tem receita selecionada
    return `${this.montarFiltro()}&receita=${this.props.receitaSelecionada.get(
      'receita'
    )}`;
  };

  onClick = data => {
    const { location } = this.props;
    const { fonteRecurso } = getAllValuesFromUrlSearchParams(location);
    const { entidade, exercicio } = StorageService.getItem('userInfo');
    const receita = data.get('receita');

    let filtro = '';
    if (fonteRecurso) {
      filtro = `${filtro}&fonteRecurso=${fonteRecurso}`;
    }

    this.props.history.push(
      `/receita/dias?entidade=${entidade.id}&exercicio=${exercicio.id}&receita=${receita}${filtro}`
    );
  };

  onExporta = (exportType, openTab, windowParams) => {
    const { apiURL } = configuration;
    const { receitas, addErrorNotification } = this.props;

    if (!receitas.isEmpty()) {
      let filtro = this.montarFiltro() + '&exportType=' + exportType;

      window.open(
        `${apiURL}/api/receitas/report?${filtro}`,
        openTab,
        windowParams
      );
    } else {
      addErrorNotification(
        'A consulta não apresentou resultado. Não é possível exportar para o formato selecionado!'
      );
    }
  };

  onConsulta = () => {
    const { findReceitasWithFiltro } = this.props;
    let filter = this.montarFiltro();
    findReceitasWithFiltro(filter);
  };

  onResetReceitas = () => {
    const { exercicio } = StorageService.getItem('userInfo');
    const { findReceitasWithFiltro, onChange } = this.props;

    onChange('filtroReceitas', {
      name: 'dataInicial',
      value: `${exercicio.id}-01-01`
    });
    onChange('filtroReceitas', {
      name: 'dataFinal',
      value: `${exercicio.id}-12-31`
    });

    let filtroDateIni = `&dataInicial=${exercicio.id}-01-01&dataFinal=${exercicio.id}-12-31`;

    findReceitasWithFiltro(this.montarFiltro(filtroDateIni));
  };

  selecionarReceita = async data => {
    const { selecionarReceita, getGraficoAnual } = this.props;

    await selecionarReceita(data);
    getGraficoAnual(this.getFiltroGrafico());

    if (this.isMobile()) {
      this.setState({ showModal: true });
    }
  };

  renderItem = item => {
    const { expand, collapse, mostrarValorLancado } = this.props;

    return (
      <TableItem
        key={item.get('receita')}
        item={item}
        onClickButton={this.onClick}
        onClickButtonGraph={this.selecionarReceita}
        expand={expand}
        collapse={collapse}
        mostrarValorLancado={mostrarValorLancado}
      />
    );
  };

  getTitleWidget = () => {
    const { receitaSelecionada } = this.props;

    if (receitaSelecionada) {
      const numero = receitaSelecionada.get('receita')
        ? receitaSelecionada.get('receita')
        : '';
      const descricao = receitaSelecionada.get('descricao')
        ? receitaSelecionada.get('descricao')
        : '';

      return `${numero} - ${descricao}`;
    }

    return '-';
  };

  isMobile = () => {
    return this.state.width < 500;
  };

  onCloseModal = () => {
    this.setState({ showModal: false });
  };

  render() {
    const {
      receitas,
      valorMes,
      mostrarValorLancado,
      onChange,
      filtros
    } = this.props;

    const header = (
      <TableHeader
        empty={receitas.isEmpty()}
        mostrarValorLancado={mostrarValorLancado}
      >
        <tr>
          <th>Receita</th>
          <th>Descrição</th>
          <th className="right">Valor Previsto</th>
          <th className="right">Orçado Atualizado</th>
          {mostrarValorLancado && <th className="right">Lançamento</th>}
          <th className="right">Valor Arrecadado</th>
          <th></th>
        </tr>
      </TableHeader>
    );

    const filtroReceitas = 'filtroReceitas';

    let list = receitas;

    return (
      <section>
        {this.isMobile() && this.state.showModal && (
          <Modal onClose={this.onCloseModal}>
            <ChartReceita valorMes={valorMes} title={this.getTitleWidget()} />
          </Modal>
        )}

        <PageHeader location={this.props.location} />

        <NotasExplicativas pathname={this.props.location.pathname} />

        <FormReceitaFiltro
          onChange={onChange}
          filtros={filtros}
          montaFiltro={this.montarFiltro}
          resetCrud={resetCrud}
          filtroReceitas={filtroReceitas}
          filtroInicial={this.filtroInicial}
          onConsulta={this.onConsulta}
          onResetReceitas={this.onResetReceitas}
        />

        <Row>
          {!this.isMobile() && (
            <Col xs={3}>
              <ChartReceita valorMes={valorMes} title={this.getTitleWidget()} />
            </Col>
          )}
          <Col xs={12} sm={9}>
            <Panel isTable tableResponsive expansible={false}>
              <Row>
                <Col xs={6}>
                  <UltimaAtualizacaoByTable
                    modulo="3"
                    table="receita"
                    column="dataultimaatualizacao"
                  />
                </Col>
                <Col xs={6}>
                  <ExportButtonGroup onClick={this.onExporta} visible={true} />
                </Col>
              </Row>
              <TreeTable
                list={list}
                header={header}
                itemRenderer={this.renderItem}
              />
            </Panel>
          </Col>
        </Row>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    receitas: state.receita.get('receitas'),
    valorMes: state.receita.get('valorMes'),
    receitaSelecionada: state.receita.get('receitaSelecionada'),
    filtros: state.crud.getIn(['filtroReceitas', 'currentRecord'], Map({})),
    mostrarValorLancado:
      ParametroService.getValorParametro(
        state,
        PARAM_EXIBE_LANCAMENTOS_EXECUCAO_RECEITA
      ) === 'S'
  };
}

const allActions = {
  ...actions,
  onChange,
  resetCrud,
  addErrorNotification
};

export default connect(mapStateToProps, allActions)(FormReceita);
