import { Map } from 'immutable';
import Input from 'portaltransparencia-common/components/EloInput.react.js';
import ExportButtonGroup from 'portaltransparencia-common/components/ExportButtonGroup.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import SearchPagination from 'portaltransparencia-common/components/SearchPagination.react.js';
import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import TableHeaderItem from 'portaltransparencia-common/components/TableHeaderItem.react';
import configuration from 'portaltransparencia-common/config/Config.js';
import {
  onChange,
  resetCrud
} from 'portaltransparencia-common/crud/Actions.js';
import {
  addFilterUrl,
  addPagination
} from 'portaltransparencia-common/libs/AddFilter.js';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import FilterButtons from '../components/FilterButtons.react.js';
import PageHeader from '../components/PageHeader.react.js';
import NotasExplicativas from '../notaexplicativa/Form.react.js';
import UltimaAtualizacao from '../ultimaatualizacao/Form.react.js';
import * as actions from './Actions.js';
import { addErrorNotification } from 'portaltransparencia-common/ui/Actions.js';

export class FormLicitacaoParticipantes extends Component {
  static propTypes = {
    page: PropTypes.object.isRequired,
    findParticipantes: PropTypes.func.isRequired,

    filtros: PropTypes.object,
    onChange: PropTypes.func,
    onChangeFieldValue: PropTypes.func,
    location: PropTypes.object.isRequired,
    resetCrud: PropTypes.func.isRequired,
    addErrorNotification: PropTypes.func.isRequired
  };

  consultarParticipantes = () => {
    const { page } = this.props;
    const initialPage = page.set('number', 0);

    this.onConsultaWithPage(initialPage);
  };

  onConsultaParticipantes = () => {
    this.consultarParticipantes();
    this.refs.panelFiltroParticipantes &&
      this.refs.panelFiltroParticipantes.collapse();
  };

  onConsultaWithPage = page => {
    let filter = this.montaFiltro(page);
    const { findParticipantes } = this.props;

    findParticipantes(filter);
  };

  onChangeFiltro = event => {
    const { onChange } = this.props;
    onChange('filtroParticipante', event);
  };

  onResetFiltro = () => {
    const { findParticipantes, resetCrud } = this.props;
    resetCrud('filtroParticipante');

    findParticipantes('');
  };

  onExporta = (exportType, openTab, windowParams) => {
    const { page, addErrorNotification } = this.props;
    const { apiURL } = configuration;

    if (!page.get('content').isEmpty()) {
      let filtro = this.montaFiltro(page) + '&exportType=' + exportType;

      window.open(
        `${apiURL}/api/licitacoes/participantes/report?${filtro}`,
        openTab,
        windowParams
      );
    } else {
      addErrorNotification(
        'A consulta não apresentou resultado. Não é possível exportar para o formato selecionado!'
      );
    }
  };

  montaFiltro = page => {
    const { entidade, exercicio } = StorageService.getItem('userInfo');
    let filter = `entidade=${entidade.id}&exercicio=${exercicio.id}`;

    const { filtros } = this.props;

    if (filtros.get('razaoSocial')) {
      filter = addFilterUrl(
        filter,
        `razaoSocial=${filtros.get('razaoSocial')}`
      );
    }
    if (filtros.get('nomeFantasia')) {
      filter = addFilterUrl(
        filter,
        `nomeFantasia=${filtros.get('nomeFantasia')}`
      );
    }
    if (filtros.get('cnpj')) {
      filter = addFilterUrl(filter, `cnpj=${filtros.get('cnpj')}`);
    }
    if (filtros.get('numeroLicitacao')) {
      filter = addFilterUrl(
        filter,
        `numeroLicitacao=${filtros.get('numeroLicitacao')}`
      );
    }

    return addPagination(page, filter);
  };

  onViewParticipante = data => {
    this.props.history.push(
      `/participantes-licitacao/detalhes?pessoa=${data.get('pessoa')}`
    );
  };

  renderParticipantes = data => {
    return (
      <TableActions
        ref="row"
        key={data.get('pessoa')}
        data={data}
        onMore={this.onViewParticipante}
      >
        <td>{data.get('razaoSocial')}</td>
        <td>{data.get('nomeFantasia')}</td>
        <td>{data.get('cnpj')}</td>
      </TableActions>
    );
  };

  render() {
    const { page, filtros, location } = this.props;

    return (
      <section>
        <PageHeader location={location} />

        <NotasExplicativas pathname={this.props.location.pathname} />

        <Panel
          isForm
          mobileClose
          title="Consulta"
          ref="panelFiltroParticipantes"
        >
          <form>
            <Row>
              <Col xs={12} sm={6} md={6}>
                <Input
                  id="razaoSocial"
                  name="razaoSocial"
                  ref="razaoSocial"
                  placeholder="Razão Social"
                  label="Razão Social"
                  type="text"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('razaoSocial')}
                />
              </Col>
              <Col xs={12} sm={6} md={6}>
                <Input
                  id="nomeFantasia"
                  name="nomeFantasia"
                  ref="nomeFantasia"
                  placeholder="Nome Fantasia"
                  label="Nome Fantasia"
                  type="text"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('nomeFantasia')}
                />
              </Col>
              <Col xs={12} sm={2} md={4}>
                <Input
                  id="cnpj"
                  name="cnpj"
                  ref="cnpj"
                  placeholder="CNPJ/CPF"
                  label="CNPJ/CPF"
                  type="text"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('cnpj')}
                />
              </Col>
              <Col xs={12} sm={2} md={2}>
                <Input
                  id="numeroLicitacao"
                  name="numeroLicitacao"
                  ref="numeroLicitacao"
                  placeholder="Nº Licitação"
                  label="Nº Licitação"
                  type="number"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('numeroLicitacao')}
                />
              </Col>
              <Col xs={12} sm={4} md={4}>
                <FilterButtons
                  idBtnReset="btnResetFilter"
                  onConsulta={this.onConsultaParticipantes}
                  onReset={this.onResetFiltro}
                />
              </Col>
            </Row>
          </form>
        </Panel>

        {page.get('content') && (
          <Panel isForm tableResponsive expansible={false}>
            <Row>
              <Col xs={6}>
                <UltimaAtualizacao modulo="3" />
              </Col>
              <Col xs={6} />
            </Row>

            <Row>
              <Col xs={6} />
              <Col xs={6}>
                <ExportButtonGroup onClick={this.onExporta} visible={true} />
              </Col>
            </Row>

            <div className="panel-table-outer">
              <table className="panel-table striped fancy">
                <TableHeader
                  empty={page.get('content').isEmpty()}
                  text="Sem registros"
                >
                  <tr>
                    <TableHeaderItem
                      field="razaoSocial"
                      label="Razão Social"
                      page={page}
                      searchWithPage={this.onConsultaWithPage}
                      alignment="left"
                    />
                    <TableHeaderItem
                      field="nomeFantasia"
                      label="Nome Fantasia"
                      page={page}
                      searchWithPage={this.onConsultaWithPage}
                      alignment="left"
                    />
                    <TableHeaderItem
                      field="cnpjCpf"
                      label="CNPJ/CPF"
                      page={page}
                      searchWithPage={this.onConsultaWithPage}
                      alignment="left"
                    />
                    <th />
                  </tr>
                </TableHeader>
                <tbody>
                  {page.get('content').map(this.renderParticipantes)}
                </tbody>
              </table>
            </div>

            <SearchPagination
              page={page}
              searchWithPage={this.onConsultaWithPage}
            />
          </Panel>
        )}
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    filtros: state.crud.getIn(['filtroParticipante', 'currentRecord'], Map({})),
    page: state.licitacoesParticipantes.get('participantes')
  };
}

const allActions = {
  ...actions,
  onChange,
  resetCrud,
  addErrorNotification
};

export default connect(mapStateToProps, allActions)(FormLicitacaoParticipantes);
