import * as types from './Types.js';

export function findArquivoLGPD(entidade, exercicio) {
  return ({ fetch }) => ({
    type: types.ARQUIVO_LGPD,
    payload: {
      promise: fetch(`/parametros/lgpd/${entidade}/${exercicio}`)
    }
  });
}

export function findResponsavelLGPD() {
  return ({ fetch }) => ({
    type: types.RESPONSAVEL_LGPD,
    payload: {
      promise: fetch('/parametros/responsavel-lgpd')
    }
  });
}
