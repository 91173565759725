import * as actionsTypes from './Types.js';

const ERROR_ACTION_PATTERN = /_ERROR$/g;
const START_ACTION_PATTERN = /_START$/g;
const SUCCESS_ACTION_PATTERN = /_SUCCESS$/g;

const defaultState = {
  text: '',
  isLoading: false,
  isError: false,
  sidebarToggled: true,
  offsidebarToggled: false,
  offsidebarModuloToggled: false,
  offsidebarUsuarioToggled: false,
  offsidebarNotificacaoToggled: false,
  maintance: false,
  activeCalls: 0,
  notifications: []
};

export default function ui(state = defaultState, action) {
  switch (action.type) {
    case actionsTypes.ADD_NOTIFICATION:
      return {
        ...state,
        notifications: [...state.notifications, action.payload]
      };

    case actionsTypes.REMOVE_NOTIFICATION: {
      const notificationsRemoved = state.notifications.filter(
        i => i.uid !== action.payload.uid
      );
      return { ...state, notifications: notificationsRemoved };
    }

    case actionsTypes.REMOVE_LOADING:
      return { ...state, isLoading: false, activeCalls: 0 };

    case actionsTypes.SIDEBAR_TOGGLE:
      return { ...state, sidebarToggled: !state.sidebarToggled };

    case actionsTypes.OFFSIDEBAR_TOGGLE:
      return { ...state, offsidebarToggled: !state.offsidebarToggled };

    case actionsTypes.OFFSIDEBAR_TOGGLE_MODULO:
      return {
        ...state,
        offsidebarModuloToggled: !state.offsidebarModuloToggled,
        offsidebarUsuarioToggled: false,
        offsidebarNotificacaoToggled: false
      };

    case actionsTypes.OFFSIDEBAR_TOGGLE_NOTIFICACAO:
      return {
        ...state,
        offsidebarModuloToggled: false,
        offsidebarUsuarioToggled: false,
        offsidebarNotificacaoToggled: !state.offsidebarNotificacaoToggled
      };

    case actionsTypes.OFFSIDEBAR_TOGGLE_USUARIO:
      return {
        ...state,
        offsidebarModuloToggled: false,
        offsidebarUsuarioToggled: !state.offsidebarUsuarioToggled,
        offsidebarNotificacaoToggled: false
      };
    case actionsTypes.MAINTANCE:
      return { ...state, maintance: true };

    case actionsTypes.UNMAINTANCE:
      return { ...state, maintance: false };

    default:
      return global(state, action);
  }
}

function global(state, action) {
  if (action.type.match(START_ACTION_PATTERN)) {
    const text =
      (action.payload && action.payload.text) || 'Acessando Servidor...';

    return {
      ...state,
      text: text,
      isLoading: true,
      isError: false,
      activeCalls: state.activeCalls + 1
    };
  }

  if (action.type.match(SUCCESS_ACTION_PATTERN)) {
    const activeCalls = dec(state.activeCalls);

    return {
      ...state,
      text: '',
      isLoading: activeCalls > 0,
      isError: false,
      activeCalls: activeCalls
    };
  }

  if (action.type.match(ERROR_ACTION_PATTERN)) {
    const activeCalls = dec(state.activeCalls);

    return {
      ...state,
      text: '',
      isLoading: activeCalls > 0,
      isError: true,
      activeCalls: activeCalls
    };
  }

  return state;
}

function dec(value) {
  if (value < 1) {
    return 0;
  }
  return value - 1;
}
