import Panel from 'portaltransparencia-common/components/Panel.react.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

import AvaliacaoPpaUnidadePanel from './AvaliacaoPpaUnidadePanel.react.js';

export default class AvaliacaoPpaProjetoPanel extends Component {
  static propTypes = {
    projeto: PropTypes.object.isRequired
  };

  renderUnidades = unidade => {
    return (
      <AvaliacaoPpaUnidadePanel
        key={unidade.get('codigoUnidade')}
        unidade={unidade}
      />
    );
  };

  render() {
    const { projeto } = this.props;

    return (
      <Panel
        key={projeto.get('codigo')}
        isForm
        mobileClose
        title={`Projeto ${projeto.get('codigo') || ''} - ${
          projeto.get('descricao') || ''
        }`}
        collapsed
        insidePanel
      >
        {projeto.get('unidades') &&
          projeto.get('unidades').map(this.renderUnidades)}
      </Panel>
    );
  }
}
