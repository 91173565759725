export function addFilterRsql(actualFilter, filterAnd) {
  let filterOperator = '';
  if (actualFilter !== '') {
    filterOperator = ' and ';
  }
  return actualFilter + filterOperator + filterAnd;
}

export function addFilterUrl(actualFilter, filterAnd) {
  let filterOperator = '';

  if (actualFilter !== '') {
    filterOperator = '&';
  }
  return actualFilter + filterOperator + filterAnd;
}

export function addPagination(page, filter) {
  filter = filter ? filter : '';
  if (page) {
    if (page.get('number')) {
      filter = addFilterUrl(filter, `page=${page.get('number')}`);
    }
    if (page.get('size')) {
      filter = addFilterUrl(filter, `size=${page.get('size')}`);
    }

    if (page.getIn(['sort', 0])) {
      const sort = page.getIn(['sort', 0]);

      if (sort && sort.has('property') && sort.has('direction')) {
        filter = addFilterUrl(
          filter,
          `sort=${sort.get('property')},${sort.get('direction')}`
        );
      }
    } else if (page.get('sort') && typeof page.get('sort') === 'string') {
      filter = addFilterUrl(filter, `sort=${page.get('sort')}`);
    }
  }

  return filter;
}
