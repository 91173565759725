import * as types from './Types.js';

export function findAdjudicacao(
  entidade,
  exercicio,
  exercicioLicitacao,
  tipoLicitacao,
  licitacao,
  pessoa
) {
  return ({ fetch }) => ({
    type: types.ADJUDICACAO,
    payload: {
      promise: fetch(
        `/api/adjudicacoes/detalhes?entidade=${entidade}&exercicio=${exercicio}&exercicioLicitacao=${exercicioLicitacao}&tipoLicitacao=${tipoLicitacao}&licitacao=${licitacao}&pessoa=${pessoa}`
      )
    }
  });
}

export function findArquivos(
  entidade,
  exercicio,
  exercicioLicitacao,
  tipoLicitacao,
  licitacao,
  pessoa
) {
  return ({ fetch }) => ({
    type: types.ADJUDICACAO_ARQUIVOS,
    payload: {
      promise: fetch(
        `/api/adjudicacoes/arquivos?entidade=${entidade}&exercicio=${exercicio}&exercicioLicitacao=${exercicioLicitacao}&tipoLicitacao=${tipoLicitacao}&licitacao=${licitacao}&pessoa=${pessoa}`
      )
    }
  });
}
