const URLUtils = {
  getURLParameter(url, paramName) {
    return (
      decodeURIComponent(
        (new RegExp('[?|&]' + paramName + '=' + '([^&;]+?)(&|#|;|$)').exec(
          url
        ) || [null, ''])[1].replace(/\+/g, '%20')
      ) || null
    );
  }
};

export const getValueFromUrlSearchParams = (location, key) => {
  const searchParams = new URLSearchParams(location.search);
  return searchParams.get(key);
};
export const getAllValuesFromUrlSearchParams = location => {
  const searchParams = new URLSearchParams(location.search);
  return Object.fromEntries(searchParams.entries());
};

export const getTenantIdentifier = () => {
  const hostname = window.location.hostname;
  if (hostname.includes('.')) {
    return hostname.substring(0, hostname.indexOf('.'));
  }
  return hostname;
};

export default URLUtils;
