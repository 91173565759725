import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import NumberUtils from 'portaltransparencia-common/libs/NumberUtils.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export class SubvencaoPrestacaoContas extends Component {
  static propTypes = {
    contas: PropTypes.object.isRequired
  };

  onViewDetalhes = item => {
    const entidade = item.get('entidade');
    const exercicio = item.get('exercicio');

    if (item.get('unidadeOrcamentaria') == ' ') {
      const prestacaoContas = item.get('prestacaoContas');

      this.props.history.push(
        `adiantamento-detalhes?entidade=${entidade}&exercicio=${exercicio}&prestacaoContas=${prestacaoContas}`
      );
    } else {
      const empenho = item.get('empenho');
      const noLiquidacao = item.get('noLiquidacao');
      const unidadeOrcamentaria = item.get('unidadeOrcamentaria');
      const matricula = item.get('matricula');

      this.props.history.push(
        `adiantamento-detalhes?entidade=${entidade}&exercicio=${exercicio}&empenho=${empenho}&noLiquidacao=${noLiquidacao}&unidadeOrcamentaria=${unidadeOrcamentaria}&matricula=${matricula}`
      );
    }
  };

  generateKey = data => {
    return `${data.get('entidade')}_
            ${data.get('exercicio')}_
            ${data.get('prestacaoContas')}_
            ${data.get('empenho')}_
            ${data.get('noLiquidacao')}`;
  };

  renderPrestacaoContas = data => {
    const key = this.generateKey(data);

    return (
      <TableActions
        ref="row"
        key={key}
        data={data}
        onMore={this.onViewDetalhes}
      >
        <td>{data.get('prestacaoContas')}</td>
        <td>{data.get('nome')}</td>
        <td>{DateUtils.formatDateTimeShort(data.get('dataPrestacao'))} </td>
        <td>{data.get('empenho') + '/' + data.get('exercicio')}</td>
        <td>{DateUtils.formatDateTimeShort(data.get('dataEmpenho'))}</td>
        <td>{NumberUtils.format(data.get('valorEmpenho'))}</td>
        <td>{NumberUtils.format(data.get('valorDocumentos'))}</td>
        <td>{NumberUtils.format(data.get('valorRestituido'))}</td>
        <td>{data.get('cargo')}</td>
        <td>{data.get('lotacao')}</td>
      </TableActions>
    );
  };

  render() {
    const { contas } = this.props;
    const adiantamentos = contas.get('content');
    return (
      <section>
        <div className="panel-table-outer">
          <table className="panel-table striped fancy">
            <TableHeader empty={adiantamentos && adiantamentos.isEmpty()}>
              <tr>
                <th>Cód. Prestação</th>
                <th>Beneficiário</th>
                <th>Data Prestação</th>
                <th>Empenho/Ano</th>
                <th>Data Empenho</th>
                <th>Valor Empenho</th>
                <th>Valor Notas</th>
                <th>Valor Restituido</th>
                <th>Cargo</th>
                <th>Lotação</th>
                <th></th>
              </tr>
            </TableHeader>
            <tbody>
              {adiantamentos && adiantamentos.map(this.renderPrestacaoContas)}
            </tbody>
          </table>
        </div>
      </section>
    );
  }
}

export default SubvencaoPrestacaoContas;
