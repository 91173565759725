import BotaoImprimir from 'portaltransparencia-common/components/BotaoImprimir.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import { getAllValuesFromUrlSearchParams } from 'portaltransparencia-common/libs/URLUtils';
import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import { downloadArquivo } from '../arquivo/Actions.js';
import Arquivo from '../arquivo/Arquivo.react.js';
import PageHeader from '../components/PageHeader.react.js';
import CabecalhoEntidade from '../entidades/CabecalhoEntidade.react.js';
import * as actions from './Actions.js';
import AutorizacaoDetalheInformacao from './AutorizacaoDetalheInformacao.react.js';

export class AutorizacaoDetalhe extends Component {
  static propTypes = {
    arquivos: PropTypes.object.isRequired,
    autorizacao: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    downloadArquivo: PropTypes.func.isRequired,
    findArquivos: PropTypes.func.isRequired,
    findAutorizacao: PropTypes.func.isRequired
  };

  componentDidMount() {
    const { location, findAutorizacao, findArquivos } = this.props;

    const {
      entidade,
      exercicio,
      numeroRequisicao
    } = getAllValuesFromUrlSearchParams(location);

    findAutorizacao(entidade, exercicio, numeroRequisicao).then(() => {
      findArquivos(entidade, exercicio, numeroRequisicao);
    });
  }

  render() {
    const { autorizacao, downloadArquivo, arquivos } = this.props;

    const arquivosList = arquivos
      .map(item => item.get('arquivoCompras'))
      .filter(item => !!item);

    return (
      <section>
        <Helmet title={'Autorização'} />

        <CabecalhoEntidade />

        <PageHeader
          location={this.props.location}
          textoDetalhe={`Autorizacao: ${autorizacao.get('numeroRequisicao')}`}
        />

        <AutorizacaoDetalheInformacao autorizacao={autorizacao} />

        <Panel isTable tableResponsive title="Anexos">
          <Arquivo arquivos={arquivosList} onDownload={downloadArquivo} />
        </Panel>

        <BotaoImprimir />
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    autorizacao: state.autorizacao.get('autorizacao'),
    arquivos: state.autorizacao.get('arquivos')
  };
}

const allActions = {
  ...actions,
  downloadArquivo
};

export default connect(mapStateToProps, allActions)(AutorizacaoDetalhe);
