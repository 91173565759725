import StorageService from 'portaltransparencia-common/libs/StorageService.js';

import * as types from './Types.js';

export function findFornecedores(filtro) {
  const { entidade, exercicio } = StorageService.getItem('userInfo');
  const baseFilter = `entidade=${entidade.id}&exercicio=${exercicio.id}&`;

  return ({ fetch }) => ({
    type: types.FORNECEDOR,
    payload: {
      promise: fetch(`/api/compras-direta/fornecedor?${baseFilter}${filtro}`)
    }
  });
}

export function findFornecedoresTotais(filtro) {
  const { entidade, exercicio } = StorageService.getItem('userInfo');
  const baseFilter = `entidade=${entidade.id}&exercicio=${exercicio.id}&`;

  return ({ fetch }) => ({
    type: types.FORNECEDORTOTAIS,
    payload: {
      promise: fetch(
        `/api/compras-direta/fornecedor/totais?${baseFilter}${filtro}`
      )
    }
  });
}
