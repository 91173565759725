import React from 'react';
import Component from 'react-pure-render/component';

import Menu from '../menu/Form.react.js';

export default class App extends Component {
  componentDidMount() {
    const speechSynthesis = 'speechSynthesis' in window;

    function canSpeechElement(e) {
      return [
        'BUTTON',
        'LABEL',
        'B',
        'TH',
        'A',
        'H1',
        'H2',
        'I',
        'SPAN'
      ].includes(e.target.nodeName);
    }

    function textToSpeech(text) {
      window.speechSynthesis.cancel();
      let msg = new SpeechSynthesisUtterance();
      msg.text = text;
      window.speechSynthesis.speak(msg);
    }

    function getSelectionText() {
      let text = '';
      if (window.getSelection) {
        text = window.getSelection().toString();
      } else if (document.selection && document.selection.type !== 'Control') {
        text = document.selection.createRange().text;
      }
      return text;
    }

    document.addEventListener('mouseup', () => {
      if (speechSynthesis && localStorage.getItem('speech') === 'S') {
        textToSpeech(getSelectionText());
      }
    });

    document.addEventListener('mouseover', e => {
      if (
        speechSynthesis &&
        localStorage.getItem('speech') === 'S' &&
        canSpeechElement(e)
      ) {
        textToSpeech(e.target.innerText);
      }
    });
  }

  render() {
    return <Menu {...this.props} />;
  }
}
