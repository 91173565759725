import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';

import * as types from './Types.js';

const initialState = fromJS({
  adjudicacao: {},
  arquivos: []
});

function adjudicacaoReducer(state = initialState, action) {
  switch (action.type) {
    case types.ADJUDICACAO_ARQUIVOS_START:
    case types.ADJUDICACAO_START: {
      return startLoad(state);
    }

    case types.ADJUDICACAO_ARQUIVOS_ERROR:
    case types.ADJUDICACAO_ERROR: {
      return endLoad(state);
    }

    case types.ADJUDICACAO_SUCCESS: {
      return endLoad(state.set('adjudicacao', fromJS(action.payload)));
    }
    case types.ADJUDICACAO_ARQUIVOS_SUCCESS: {
      return endLoad(state.set('arquivos', fromJS(action.payload)));
    }
  }

  return state;
}

export default adjudicacaoReducer;
