import BotaoImprimir from 'portaltransparencia-common/components/BotaoImprimir.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import { getAllValuesFromUrlSearchParams } from 'portaltransparencia-common/libs/URLUtils';
import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import { downloadArquivo } from '../arquivo/Actions.js';
import Arquivo from '../arquivo/Arquivo.react.js';
import PageHeader from '../components/PageHeader.react.js';
import CabecalhoEntidade from '../entidades/CabecalhoEntidade.react.js';
import * as actions from './Actions.js';
import HomologacaoDetalheInformacao from './HomologacaoDetalheInformacao.react.js';

export class HomologacaoDetalhe extends Component {
  static propTypes = {
    arquivos: PropTypes.object.isRequired,
    homologacao: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    downloadArquivo: PropTypes.func.isRequired,
    findArquivos: PropTypes.func.isRequired,
    findHomologacao: PropTypes.func.isRequired
  };

  componentDidMount() {
    const { location, findHomologacao, findArquivos } = this.props;

    const {
      entidade,
      exercicio,
      exercicioLicitacao,
      tipoLicitacao,
      licitacao,
      pessoa
    } = getAllValuesFromUrlSearchParams(location);

    findHomologacao(
      entidade,
      exercicio,
      exercicioLicitacao,
      tipoLicitacao,
      licitacao,
      pessoa
    ).then(() => {
      findArquivos(
        entidade,
        exercicio,
        exercicioLicitacao,
        tipoLicitacao,
        licitacao,
        pessoa
      );
    });
  }

  render() {
    const { homologacao, downloadArquivo, arquivos } = this.props;

    const arquivosList = arquivos
      .map(item => item.get('arquivoCompras'))
      .filter(item => !!item);

    return (
      <div>
        <Helmet title={'Homologação'} />

        <BotaoImprimir />

        <PageHeader
          location={this.props.location}
          textoDetalhe={`Homologacao: ${homologacao.get(
            'licitacao'
          )}/${homologacao.get('exercicio')}`}
        />

        <CabecalhoEntidade />

        <HomologacaoDetalheInformacao homologacao={homologacao} />

        <Panel isTable tableResponsive title="Anexos">
          <Arquivo arquivos={arquivosList} onDownload={downloadArquivo} />
        </Panel>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    homologacao: state.homologacao.get('homologacao'),
    arquivos: state.homologacao.get('arquivos')
  };
}

const allActions = {
  ...actions,
  downloadArquivo
};

export default connect(mapStateToProps, allActions)(HomologacaoDetalhe);
