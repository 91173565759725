import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react.js';
import SumListField from 'portaltransparencia-common/components/SumListField.react.js';
import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export class DespesaListagem extends Component {
  static propTypes = {
    despesas: PropTypes.object.isRequired,
    nivel: PropTypes.object.isRequired
  };

  renderDespesa = data => {
    return (
      <TableActions
        ref="row"
        key={data.get('codigo')}
        data={data}
        onMore={this.onViewEmpenhos}
      >
        {data.get('codigo') && <td>{data.get('codigo')}</td>}
        <td>{data.get('descricao')}</td>
        <td className="right">
          <NumberFormatter value={data.get('valorOrcado')} />
        </td>
        <td className="right">
          <NumberFormatter value={data.get('valorEmpenhado')} />
        </td>
        <td className="right">
          <NumberFormatter value={data.get('valorLiquidado')} />
        </td>
        <td className="right">
          <NumberFormatter value={data.get('valorRetido')} />
        </td>
        <td className="right">
          <NumberFormatter value={data.get('valorPago')} />
        </td>
      </TableActions>
    );
  };

  onViewEmpenhos = item => {
    let { nivel } = this.props;

    const { exercicio } = StorageService.getItem('userInfo');

    let filtro = `?search=id.exercicio==${exercicio.id}`;

    const rota = nivel.get('rota');
    const functionFiltroRota = filterNivelByRota[rota];

    if (functionFiltroRota) {
      const filtroByRota = functionFiltroRota(item);
      filtro += filtroByRota;
      this.props.history.push('/empenhos' + filtro);
      return;
    }

    const nivelLei = nivel.get('nivellei');
    const tipo = nivel.get('tipo');
    filtro = `${filtro}&tipoNivelDespesa=${tipo}&codigoNivelDespesa=${item.get(
      'codigo'
    )}&nivelLei=${nivelLei}`;
    this.props.history.push('/empenhos' + filtro);
  };

  render() {
    const { despesas, nivel } = this.props;

    const mostraCodigo = nivel.get('rota') != 'esfera-administrativa';

    return (
      <div className="panel-table-outer">
        <table className="panel-table striped fancy">
          <TableHeader empty={despesas.isEmpty()}>
            <tr>
              {mostraCodigo && <th>Código</th>}
              <th>Descrição</th>
              <th className="right">Valor Orçado</th>
              <th className="right">Empenhado</th>
              <th className="right">Liquidado</th>
              <th className="right">Retido</th>
              <th className="right">Pago</th>
              <th></th>
            </tr>
          </TableHeader>
          <tbody>{despesas.map(this.renderDespesa)}</tbody>

          {despesas.size > 0 && (
            <tfoot>
              <tr>
                {mostraCodigo && <td></td>}
                <td></td>
                <td className="right">
                  <i>Total: </i>
                  <SumListField fieldName="valorOrcado" list={despesas} />
                </td>
                <td className="right">
                  <i>Total: </i>
                  <SumListField fieldName="valorEmpenhado" list={despesas} />
                </td>
                <td className="right">
                  <i>Total: </i>
                  <SumListField fieldName="valorLiquidado" list={despesas} />
                </td>
                <td className="right">
                  <i>Total: </i>
                  <SumListField fieldName="valorRetido" list={despesas} />
                </td>
                <td className="right">
                  <i>Total: </i>
                  <SumListField fieldName="valorPago" list={despesas} />
                </td>
                <td></td>
              </tr>
            </tfoot>
          )}
        </table>
      </div>
    );
  }
}

const filterNivelByRota = {
  'fonte-recursos'(item) {
    return `&fonteRecurso=${item.get('codigo')}`;
  },
  'esfera-administrativa'(item) {
    const functionCodigoEsfera =
      filterCodigoByEsferaAdministrativa[item.get('codigo')];

    if (functionCodigoEsfera) {
      const codigoEsfera = functionCodigoEsfera();
      return `&esfera=${codigoEsfera}`;
    }

    return '&esfera=F';
  },
  orgao(item) {
    return `&programatica.orgao=${item.get('codigo')}`;
  },
  funcao(item) {
    return `&programatica.funcao=${item.get('codigo')}`;
  },
  programa(item) {
    return `&programatica.programa=${item.get('codigo')}`;
  },
  projeto(item) {
    return `&programatica.projeto=${item.get('codigo')}`;
  },
  elemento(item) {
    const codigoWithoutZero = item.get('codigoWithoutZero');
    if (codigoWithoutZero) {
      const codigoFormatted = codigoWithoutZero.replace(/\./g, '');
      return `&programatica.elemento=${codigoFormatted}`;
    } else {
      return `&programatica.elemento=${item.get('codigo')}`;
    }
  }
};

const filterCodigoByEsferaAdministrativa = {
  Investimentos() {
    return 'I';
  },
  Seguridade() {
    return 'S';
  }
};

export default DespesaListagem;
