import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export class LicitacaoDetalheAdjudicacao extends Component {
  static propTypes = {
    adjudicacoes: PropTypes.object.isRequired,
    onDownload: PropTypes.func.isRequired
  };

  onViewDetalhes = adjudicacao => {
    const entidade = adjudicacao.get('entidade');
    const exercicio = adjudicacao.get('exercicio');
    const exercicioLicitacao = adjudicacao.get('exercicioLicitacao');
    const tipoLicitacao = adjudicacao.get('tipoLicitacao');
    const licitacao = adjudicacao.get('licitacao');
    const pessoa = adjudicacao.get('pessoa');

    this.props.history.push(
      `/adjudicacoes/detalhes?entidade=${entidade}&exercicio=${exercicio}&exercicioLicitacao=${exercicioLicitacao}&tipoLicitacao=${tipoLicitacao}&licitacao=${licitacao}&pessoa=${pessoa}`
    );
  };

  renderAdjudicacoes = data => {
    const key =
      data.get('entidade') +
      '_' +
      data.get('exercicio') +
      '_' +
      data.get('exerciciolicitacao') +
      '_' +
      data.get('tipolicitacao') +
      '_' +
      data.get('licitacao') +
      '_' +
      data.get('pessoa');

    return (
      <TableActions
        ref="row"
        key={key}
        data={data}
        onMore={this.onViewDetalhes}
      >
        <td>{data.get('nome')}</td>
        <td>{data.get('cnpjCpf')}</td>
        <td>{DateUtils.formatDateTimeShort(data.get('data'))}</td>
      </TableActions>
    );
  };

  render() {
    const { adjudicacoes } = this.props;

    return (
      <div className="panel-table-outer">
        <table className="panel-table striped fancy">
          <TableHeader empty={adjudicacoes.isEmpty()}>
            <tr>
              <th>Fornecedor</th>
              <th>CNPJ/CPF</th>
              <th>Data Adjudicação</th>
              <th></th>
            </tr>
          </TableHeader>
          <tbody>{adjudicacoes.map(this.renderAdjudicacoes)}</tbody>
        </table>
      </div>
    );
  }
}

export default LicitacaoDetalheAdjudicacao;
