import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';

import * as types from './Types.js';

const initialState = fromJS({
  fornecedoresAPagar: {},
  totais: {}
});

function fornecedorAPagarReducer(state = initialState, action) {
  switch (action.type) {
    case types.FORNECEDORAPAGAR_START:
    case types.FORNECEDORAPAGARTOTAIS_START: {
      return startLoad(state);
    }

    case types.FORNECEDORAPAGAR_ERROR:
    case types.FORNECEDORAPAGARTOTAIS_ERROR: {
      return endLoad(state);
    }

    case types.FORNECEDORAPAGAR_SUCCESS: {
      return endLoad(state.set('fornecedoresAPagar', fromJS(action.payload)));
    }
    case types.FORNECEDORAPAGARTOTAIS_SUCCESS: {
      return endLoad(state.set('totais', fromJS(action.payload)));
    }
  }

  return state;
}

export default fornecedorAPagarReducer;
