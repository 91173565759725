import axios from 'axios';

const resource = '/portaltransparencia-api/api/obras';

export const findOneObra = (
  entidade,
  exercicioObra,
  idOrdemServico,
  idOxy = ''
) => {
  const url = idOxy
    ? `${resource}?idOxy=${idOxy}`
    : `${resource}?entidade=${entidade}&exercicioObra=${exercicioObra}&idOrdemServico=${idOrdemServico}`;
  return axios.get(url);
};
