export const RENUNCIA_RECEITA = 'RENUNCIA_RECEITA';
export const RENUNCIA_RECEITA_START = 'RENUNCIA_RECEITA_START';
export const RENUNCIA_RECEITA_SUCCESS = 'RENUNCIA_RECEITA_SUCCESS';
export const RENUNCIA_RECEITA_ERROR = 'RENUNCIA_RECEITA_ERROR';

export const RENUNCIA_RECEITA_DETALHE = 'RENUNCIA_RECEITA_DETALHE';
export const RENUNCIA_RECEITA_DETALHE_START = 'RENUNCIA_RECEITA_DETALHE_START';
export const RENUNCIA_RECEITA_DETALHE_SUCCESS =
  'RENUNCIA_RECEITA_DETALHE_SUCCESS';
export const RENUNCIA_RECEITA_DETALHE_ERROR = 'RENUNCIA_RECEITA_DETALHE_ERROR';

export const TIPO_OPERACAO_LIST = 'TIPO_OPERACAO_LIST';
export const TIPO_OPERACAO_LIST_START = 'TIPO_OPERACAO_LIST_START';
export const TIPO_OPERACAO_LIST_SUCCESS = 'TIPO_OPERACAO_LIST_SUCCESS';
export const TIPO_OPERACAO_LIST_ERROR = 'TIPO_OPERACAO_LIST_ERROR';

export const RECEITA_LIST = 'RECEITA_LIST';
export const RECEITA_LIST_START = 'RECEITA_LIST_START';
export const RECEITA_LIST_SUCCESS = 'RECEITA_LIST_SUCCESS';
export const RECEITA_LIST_ERROR = 'RECEITA_LIST_ERROR';
