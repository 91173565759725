export const APTOS_PAGAMENTOS = 'APTOS_PAGAMENTOS';
export const APTOS_PAGAMENTOS_START = 'APTOS_PAGAMENTOS_START';
export const APTOS_PAGAMENTOS_SUCCESS = 'APTOS_PAGAMENTOS_SUCCESS';
export const APTOS_PAGAMENTOS_ERROR = 'APTOS_PAGAMENTOS_ERROR';

export const CONTA_PAGAMENTO = 'CONTA_PAGAMENTO';
export const CONTA_PAGAMENTO_START = 'CONTA_PAGAMENTO_START';
export const CONTA_PAGAMENTO_SUCCESS = 'CONTA_PAGAMENTO_SUCCESS';
export const CONTA_PAGAMENTO_ERROR = 'CONTA_PAGAMENTO_ERROR';

export const APTOS_PAGAMENTOS_TOTAL = 'APTOS_PAGAMENTOS_TOTAL';
export const APTOS_PAGAMENTOS_TOTAL_START = 'APTOS_PAGAMENTOS_TOTAL_START';
export const APTOS_PAGAMENTOS_TOTAL_SUCCESS = 'APTOS_PAGAMENTOS_TOTAL_SUCCESS';
export const APTOS_PAGAMENTOS_TOTAL_ERROR = 'APTOS_PAGAMENTOS_TOTAL_ERROR';

export const RETENCOES_LIQUIDACAO = 'RETENCOES_LIQUIDACAO';
export const RETENCOES_LIQUIDACAO_START = 'RETENCOES_LIQUIDACAO_START';
export const RETENCOES_LIQUIDACAO_SUCCESS = 'RETENCOES_LIQUIDACAO_SUCCESS';
export const RETENCOES_LIQUIDACAO_ERROR = 'RETENCOES_LIQUIDACAO_ERROR';
