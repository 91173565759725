import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export default class AutonomoRow extends Component {
  static propTypes = {
    autonomo: PropTypes.object.isRequired
  };

  onViewDetalhes = item => {
    this.props.history.push(
      `/autonomos/detalhes?codigo=${item.get('matricula')}`
    );
  };

  render() {
    const { autonomo } = this.props;
    const key = `${autonomo.get('entidade')}_${autonomo.get('matricula')}`;

    return (
      <TableActions
        ref="row"
        key={key}
        data={autonomo}
        onMore={this.onViewDetalhes}
      >
        <td id="tableNome">{autonomo.get('nome')}</td>
        <td id="tableCodigo">{autonomo.get('matricula')}</td>
        <td id="tableAtividade">{autonomo.get('descricaoAtividades')}</td>
        <td id="tableLotacao">{autonomo.get('descricaoLotacao')}</td>
        <td id="tableDataInicio">
          {DateUtils.formatDateTimeShort(autonomo.get('dataAdmissao'))}
        </td>
        <td id="tableDataFim">
          {DateUtils.formatDateTimeShort(autonomo.get('dataFimContrato'))}
        </td>
      </TableActions>
    );
  }
}
