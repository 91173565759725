import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';

import * as types from './Types.js';

const initialState = fromJS({
  audienciasPublicas: [],
  detalhes: [],
  arquivos: []
});

function AudienciasPublicasReducer(state = initialState, action) {
  switch (action.type) {
    case types.AUDIENCIAS_PUBLICAS_START:
    case types.AUDIENCIAS_PUBLICAS_DETALHES_START:
    case types.AUDIENCIAS_PUBLICAS_ARQUIVOS_START: {
      return startLoad(state);
    }

    case types.AUDIENCIAS_PUBLICAS_ERROR:
    case types.AUDIENCIAS_PUBLICAS_DETALHES_ERROR:
    case types.AUDIENCIAS_PUBLICAS_ARQUIVOS_ERROR: {
      return endLoad(state);
    }

    case types.AUDIENCIAS_PUBLICAS_SUCCESS: {
      return endLoad(state.set('audienciasPublicas', fromJS(action.payload)));
    }

    case types.AUDIENCIAS_PUBLICAS_DETALHES_SUCCESS: {
      return endLoad(state.set('detalhes', fromJS(action.payload)));
    }

    case types.AUDIENCIAS_PUBLICAS_ARQUIVOS_SUCCESS: {
      return endLoad(state.set('arquivos', fromJS(action.payload)));
    }
  }

  return state;
}

export default AudienciasPublicasReducer;
