export const SERVIDORES_LIST = 'SERVIDORES_LIST';
export const SERVIDORES_LIST_START = 'SERVIDORES_LIST_START';
export const SERVIDORES_LIST_SUCCESS = 'SERVIDORES_LIST_SUCCESS';
export const SERVIDORES_LIST_ERROR = 'SERVIDORES_LIST_ERROR';

export const SERVIDOR_DETALHE = 'SERVIDOR_DETALHE';
export const SERVIDOR_DETALHE_START = 'SERVIDOR_DETALHE_START';
export const SERVIDOR_DETALHE_SUCCESS = 'SERVIDOR_DETALHE_SUCCESS';
export const SERVIDOR_DETALHE_ERROR = 'SERVIDOR_DETALHE_ERROR';

export const SERVIDOR_DETALHE_PENSAO = 'SERVIDOR_DETALHE_PENSAO';
export const SERVIDOR_DETALHE_PENSAO_START = 'SERVIDOR_DETALHE_PENSAO_START';
export const SERVIDOR_DETALHE_PENSAO_SUCCESS =
  'SERVIDOR_DETALHE_PENSAO_SUCCESS';
export const SERVIDOR_DETALHE_PENSAO_ERROR = 'SERVIDOR_DETALHE_PENSAO_ERROR';

export const SERVIDOR_VENCIMENTOS_DESCONTOS = 'SERVIDOR_VENCIMENTOS_DESCONTOS';
export const SERVIDOR_VENCIMENTOS_DESCONTOS_START =
  'SERVIDOR_VENCIMENTOS_DESCONTOS_START';
export const SERVIDOR_VENCIMENTOS_DESCONTOS_SUCCESS =
  'SERVIDOR_VENCIMENTOS_DESCONTOS_SUCCESS';
export const SERVIDOR_VENCIMENTOS_DESCONTOS_ERROR =
  'SERVIDOR_VENCIMENTOS_DESCONTOS_ERROR';

export const NATUREZAS_LIST = 'NATUREZAS_LIST';
export const NATUREZAS_LIST_START = 'NATUREZAS_LIST_START';
export const NATUREZAS_LIST_SUCCESS = 'NATUREZAS_LIST_SUCCESS';
export const NATUREZAS_LIST_ERROR = 'NATUREZAS_LIST_ERROR';

export const SERVIDOR_REGISTROS_PONTOS = 'SERVIDOR_REGISTROS_PONTOS';
export const SERVIDOR_REGISTROS_PONTOS_START =
  'SERVIDOR_REGISTROS_PONTOS_START';
export const SERVIDOR_REGISTROS_PONTOS_SUCCESS =
  'SERVIDOR_REGISTROS_PONTOS_SUCCESS';
export const SERVIDOR_REGISTROS_PONTOS_ERROR =
  'SERVIDOR_REGISTROS_PONTOS_ERROR';

export const SITUACOES_LIST = 'SITUACOES_LIST';
export const SITUACOES_LIST_START = 'SITUACOES_LIST_START';
export const SITUACOES_LIST_SUCCESS = 'SITUACOES_LIST_SUCCESS';
export const SITUACOES_LIST_ERROR = 'SITUACOES_LIST_ERROR';

export const CLEAR_DETALHE = 'CLEAR_DETALHE';

export const SERVIDORES_RESUMO = 'SERVIDORES_RESUMO';
export const SERVIDORES_RESUMO_START = 'SERVIDORES_RESUMO_START';
export const SERVIDORES_RESUMO_SUCCESS = 'SERVIDORES_RESUMO_SUCCESS';
export const SERVIDORES_RESUMO_ERROR = 'SERVIDORES_RESUMO_ERROR';

export const ESTAGIARIOS_LIST = 'ESTAGIARIOS_LIST';
export const ESTAGIARIOS_LIST_START = 'ESTAGIARIOS_LIST_START';
export const ESTAGIARIOS_LIST_SUCCESS = 'ESTAGIARIOS_LIST_SUCCESS';
export const ESTAGIARIOS_LIST_ERROR = 'ESTAGIARIOS_LIST_ERROR';

export const ESTAGIARIO_DETALHE = 'ESTAGIARIO_DETALHE';
export const ESTAGIARIO_DETALHE_START = 'ESTAGIARIO_DETALHE_START';
export const ESTAGIARIO_DETALHE_SUCCESS = 'ESTAGIARIO_DETALHE_SUCCESS';
export const ESTAGIARIO_DETALHE_ERROR = 'ESTAGIARIO_DETALHE_ERROR';

export const AUTONOMOS_LIST = 'AUTONOMOS_LIST';
export const AUTONOMOS_LIST_START = 'AUTONOMOS_LIST_START';
export const AUTONOMOS_LIST_SUCCESS = 'AUTONOMOS_LIST_SUCCESS';
export const AUTONOMOS_LIST_ERROR = 'AUTONOMOS_LIST_ERROR';

export const AUTONOMO_DETALHE = 'AUTONOMO_DETALHE';
export const AUTONOMO_DETALHE_START = 'AUTONOMO_DETALHE_START';
export const AUTONOMO_DETALHE_SUCCESS = 'AUTONOMO_DETALHE_SUCCESS';
export const AUTONOMO_DETALHE_ERROR = 'AUTONOMO_DETALHE_ERROR';
