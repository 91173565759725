import * as types from './Types.js';

export function findDiarias(filter) {
  return ({ fetch }) => ({
    type: types.DIARIAS_LIST,
    payload: {
      promise: fetch(`/api/diarias?${filter}`)
    }
  });
}

export function findDiariasWithoutQuantidade(filter) {
  return ({ fetch }) => ({
    type: types.DIARIAS_WITHOUT_QUANTIDADE_LIST,
    payload: {
      promise: fetch(`/api/diarias/diariasWithoutQuantidade?${filter}`)
    }
  });
}

export function resetConsulta() {
  return {
    type: types.RESET_CONSULTA_DIARIAS,
    payload: {}
  };
}

export function findDiaria(
  entidade,
  exercicio,
  empenho,
  noLiquidacao,
  unidadeOrcamentaria
) {
  return ({ fetch }) => ({
    type: types.DIARIA,
    payload: {
      promise: fetch(
        `/api/diarias?entidade=${entidade}&exercicio=${exercicio}&empenho=${empenho}&noLiquidacao=${noLiquidacao}&unidadeOrcamentaria=${unidadeOrcamentaria}`
      )
    }
  });
}

export function findArquivos(entidade, exercicio, empenho, noLiquidacao) {
  return ({ fetch }) => ({
    type: types.ARQUIVOS,
    payload: {
      promise: fetch(
        `/api/diarias/arquivos?entidade=${entidade}&exercicio=${exercicio}&empenho=${empenho}&noLiquidacao=${noLiquidacao}`
      )
    }
  });
}

export function findTotalDiarias(filter) {
  return ({ fetch }) => ({
    type: types.TOTAL_DIARIAS,
    payload: {
      promise: fetch(`/api/diarias/total?${filter}`)
    }
  });
}

export function getObjetivosDiarias() {
  return ({ fetch }) => ({
    type: types.OBJETIVO_DIARIAS,
    payload: {
      promise: fetch('/api/diarias/objetivos-diaria')
    }
  });
}

export function selectCodigoSecretaria(value) {
  return {
    type: types.SELECT_CODIGO_SECRETARIA,
    payload: { value }
  };
}

export function getSecretariasDiarias(entidade, exercicio, descricao) {
  return ({ fetch }) => ({
    type: types.SECRETARIA_DIARIAS,
    payload: {
      promise: fetch(
        `/api/diarias/secretarias-diaria?entidade=${entidade}&exercicio=${exercicio}&descricao=${descricao}`
      ).then(response =>
        response.map(e => {
          return {
            codigo: e.codigo,
            descricao: e.descricao
          };
        })
      )
    }
  });
}
