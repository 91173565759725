import PropTypes from 'prop-types';
import React from 'react';
import NumberFormatter from './NumberFormatter.react.js';

const sumValue = field => (valor, item) => valor + item.get(field);

export default class SumListField extends React.PureComponent {
  static propTypes = {
    fieldName: PropTypes.string.isRequired,
    list: PropTypes.object.isRequired,
    format: PropTypes.string
  };

  render() {
    const { fieldName, list, format } = this.props;

    return (
      <NumberFormatter
        value={list.reduce(sumValue(fieldName), 0)}
        format={format}
      />
    );
  }
}
