import ExportButtonGroup from 'portaltransparencia-common/components/ExportButtonGroup.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import Tab from 'portaltransparencia-common/components/Tab.react.js';
import Tabs from 'portaltransparencia-common/components/Tabs.react.js';
import configuration from 'portaltransparencia-common/config/Config.js';
import { onChange } from 'portaltransparencia-common/crud/Actions.js';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import PageHeader from '../components/PageHeader.react.js';
import NotasExplicativas from '../notaexplicativa/Form.react';
import UltimaAtualizacao from '../ultimaatualizacao/Form.react.js';
import * as actions from './Actions.js';
import RepasseListagem from './RepasseListagem.react.js';
import { addErrorNotification } from 'portaltransparencia-common/ui/Actions.js';

export class FormRepasse extends Component {
  static propTypes = {
    params: PropTypes.object,
    enviados: PropTypes.object.isRequired,
    recebidos: PropTypes.object.isRequired,
    findRepassesEnviados: PropTypes.func.isRequired,
    findRepassesRecebidos: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    location: PropTypes.object.isRequired,
    addErrorNotification: PropTypes.func.isRequired
  };

  componentDidMount() {
    const { findRepassesEnviados, findRepassesRecebidos } = this.props;

    findRepassesEnviados();
    findRepassesRecebidos();
  }

  onExporta = (exportType, openTab, windowParams) => {
    const { apiURL } = configuration;
    const { enviados, recebidos, addErrorNotification } = this.props;

    const {
      params: { tipoRepasse }
    } = this.props.match;

    if (
      (tipoRepasse == 'despesa' && enviados.size > 0) ||
      (tipoRepasse == 'receita' && recebidos.size > 0)
    ) {
      let userInfo = StorageService.getItem('userInfo');
      const { entidade, exercicio } = userInfo;

      const tipo = this.refs.tab.getActiveTabName() == 'recebidos' ? 'R' : 'E';

      const filter =
        '&entidade=' +
        entidade.id +
        '&exercicio=' +
        exercicio.id +
        '&tipo=' +
        tipo +
        '&exportType=' +
        exportType;

      window.open(
        `${apiURL}/api/repasses/report?${filter}`,
        openTab,
        windowParams
      );
    } else {
      addErrorNotification(
        'A consulta não apresentou resultado. Não é possível exportar para o formato selecionado!'
      );
    }
  };

  render() {
    const { enviados, recebidos } = this.props;

    let userInfo = StorageService.getItem('userInfo');
    const { exercicio } = userInfo;

    const {
      params: { tipoRepasse }
    } = this.props.match;

    let isTipoDespesa = false;
    let isTipoReceita = false;
    if (tipoRepasse == 'despesa') {
      isTipoDespesa = true;
    }
    if (tipoRepasse == 'receita') {
      isTipoReceita = true;
    }

    return (
      <section>
        <PageHeader location={this.props.location} />

        <NotasExplicativas pathname={this.props.location.pathname} />

        <Panel isForm expansible={false} tableResponsive>
          <Row>
            <Col xs={6}>
              <UltimaAtualizacao
                modulo="3"
                table="eloportalrepasses"
                column="dataultimaatualizacao"
              />
            </Col>
            <Col xs={6}>
              <ExportButtonGroup onClick={this.onExporta} visible={true} />
            </Col>
          </Row>

          <Tabs ref="tab">
            {isTipoReceita && (
              <Tab
                title={'Repasses Recebidos em ' + exercicio.id}
                name="recebidos"
                onlyTable
                active={isTipoReceita}
              >
                <RepasseListagem
                  repasses={recebidos}
                  tipoRepasse={tipoRepasse}
                />
              </Tab>
            )}
            {isTipoDespesa && (
              <Tab
                title={'Repasses Enviados em ' + exercicio.id}
                name="enviados"
                onlyTable
                active={isTipoDespesa}
              >
                <RepasseListagem
                  repasses={enviados}
                  tipoRepasse={tipoRepasse}
                />
              </Tab>
            )}
          </Tabs>
        </Panel>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    enviados: state.repasse.get('enviados'),
    recebidos: state.repasse.get('recebidos')
  };
}

const allActions = {
  ...actions,
  onChange,
  addErrorNotification
};

export default connect(mapStateToProps, allActions)(FormRepasse);
