import Component from 'react-pure-render/component';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import DisplayData from 'portaltransparencia-common/components/displaydata/DisplayData.react.js';
import DisplayDataItem from 'portaltransparencia-common/components/displaydata/DisplayDataItem.react.js';
import DisplayDataRow from 'portaltransparencia-common/components/displaydata/DisplayDataRow.react.js';
import NumberUtils from 'portaltransparencia-common/libs/NumberUtils.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import PropTypes from 'prop-types';
import React from 'react';
import LicitacaoInfoModal from './LicitacaoInfoModal.react';

export class LicitacaoDetalheInformacao extends Component {
  static propTypes = {
    licitacao: PropTypes.object.isRequired,
    mostraDataEntrega: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.state = {
      showModalInfo: false
    };
  }

  openModalInfo = () => {
    this.setState({
      showModalInfo: true
    });
  };

  closeModalInfo = () => {
    this.setState({
      showModalInfo: false
    });
  };

  getEntregaPropostas = licitacao => {
    let dataInicial = licitacao.get('dataInicialProposta');
    let dataFinal = licitacao.get('dataFinalProposta');
    let horaInicial = licitacao.get('horaInicialProposta');
    let horaFinal = licitacao.get('horaFinalProposta');
    if (!dataInicial && !dataFinal && !horaInicial && !horaFinal) {
      return;
    }

    let txtEntregaPropostas = '';

    if (dataInicial) {
      txtEntregaPropostas =
        DateUtils.formatDateTimeShort(dataInicial) +
        (horaInicial ? ' ' + horaInicial : '');
    }

    if (dataFinal) {
      if (txtEntregaPropostas) {
        txtEntregaPropostas += ' a ';
      }
      txtEntregaPropostas +=
        DateUtils.formatDateTimeShort(dataFinal) +
        (horaFinal ? ' ' + horaFinal : '');
    }

    return txtEntregaPropostas;
  };

  render() {
    const { licitacao, mostraDataEntrega } = this.props;
    const { showModalInfo } = this.state;

    let dataAbertura = DateUtils.formatDateTimeShort(
      licitacao.get('dataAbertura')
    );
    if (licitacao.get('horarioAbertura')) {
      dataAbertura =
        DateUtils.formatDateTimeShort(licitacao.get('dataAbertura')) +
        ' as ' +
        licitacao.get('horarioAbertura');
    }

    const valorMaximoProcesso = () => {
      return (
        licitacao.get('orcamentoSigiloso') !== 'S' &&
        NumberUtils.format(licitacao.get('valorMaximo'), 'R$ 0,0.00')
      );
    };

    return (
      <>
        <Panel title="Detalhes da Licitação">
          <DisplayData border column>
            <DisplayDataRow>
              <DisplayDataItem
                sm={licitacao.get('entidadeInfOriginal') ? 9 : 12}
                title="Entidade"
                value={licitacao.get('nomeEntidade')}
                id="entidade"
              />
              {licitacao.get('entidadeInfOriginal') && (
                <DisplayDataItem
                  sm={3}
                  title="Licitação Original"
                  value={
                    <button
                      type="button"
                      id="openModalInfo"
                      ref="openModalInfo"
                      className="btn positive icon-right"
                      onClick={this.openModalInfo}
                    >
                      Visualizar
                      <em className="fa fa-search"></em>
                    </button>
                  }
                />
              )}
            </DisplayDataRow>
            <DisplayDataRow>
              <DisplayDataItem
                sm={5}
                title="Modalidade"
                value={licitacao.get('descricao')}
                id="descricao"
              />
              <DisplayDataItem
                sm={4}
                title="Natureza"
                value={licitacao.get('natureza')}
                id="natureza"
              />
              <DisplayDataItem
                sm={4}
                title="Julgamento"
                value={licitacao.get('formaApuracao')}
                id="formaApuracao"
              />
              <DisplayDataItem
                sm={5}
                title="Número/Exercício"
                value={licitacao.get('numeroLicitacao')}
                separator="/"
                value2={licitacao.get('exercicio')}
                id="licitacao"
              />
              <DisplayDataItem
                sm={2}
                title="Covid"
                value={licitacao.get('isCovid') === 'S' ? 'Sim' : 'Não'}
              />
            </DisplayDataRow>

            <DisplayDataRow>
              <DisplayDataItem
                sm={6}
                title="Situação"
                value={licitacao.get('tipoCancelamento')}
                id="tipoCancelamento"
              />
              <DisplayDataItem
                sm={6}
                title="Publicação"
                value={DateUtils.formatDateTimeShort(
                  licitacao.get('dataPublicacao')
                )}
                id="dataPublicacao"
              />
              <DisplayDataItem
                sm={6}
                title="Processo Administrativo"
                value={licitacao.get('displayProcessoAdm')}
                id="processoAdministrativo"
              />
              <DisplayDataItem
                sm={7}
                title="Tipo Licitação TCE-MT"
                value={
                  [
                    'Não contempla/não favorece',
                    'Menor Preço',
                    'Melhor Técnica ou Conteúdo Artístico',
                    'Técnica e Preço',
                    'Tarifa',
                    'Tarifa e técnica',
                    'Contraprestação',
                    'Contraprestação e técnica',
                    'Maior retorno econômico',
                    'Maior desconto',
                    'Maior lance, no caso de leilão'
                  ][licitacao.get('tcemtTipoProcLicit')]
                }
                show={licitacao.get('tcemtTipoProcLicit') != null}
              />
              <DisplayDataItem
                sm={6}
                title="Tipo Participação"
                value={licitacao.get('tipoParticipacao')}
                id="descTipoParticipacao"
                show={licitacao.get('tipoParticipacao') != null}
              />
              <DisplayDataItem
                sm={6}
                title="Tipo Compra"
                value={licitacao.get('descTipoCompra')}
                id="descTipoCompra"
                show={licitacao.get('descTipoCompra') != null}
              />
            </DisplayDataRow>

            <DisplayDataRow>
              <DisplayDataItem
                sm={4}
                title="Abertura"
                value={dataAbertura}
                id="dataAbertura"
              />
              {mostraDataEntrega && (
                <DisplayDataItem
                  sm={4}
                  title="Entrega de Propostas"
                  value={this.getEntregaPropostas(licitacao)}
                  id="entregaPropostas"
                />
              )}
              <DisplayDataItem
                sm={4}
                title="Valor Máximo Processo"
                value={valorMaximoProcesso()}
                id="valorMaximo"
              />
              <DisplayDataItem
                sm={4}
                title="Valor Homologado"
                value={NumberUtils.format(
                  licitacao.get('totalVencido'),
                  'R$ 0,0.00'
                )}
                id="totalVencido"
              />
            </DisplayDataRow>

            <DisplayDataRow>
              <DisplayDataItem
                sm={12}
                title="Objeto"
                value={licitacao.get('objeto')}
                id="objeto"
              />
            </DisplayDataRow>

            {licitacao.get('observacao') && licitacao.get('observacao').trim() && (
              <DisplayDataRow>
                <DisplayDataItem
                  sm={12}
                  title="Observação/Justificativa"
                  value={licitacao.get('observacao')}
                  id="observacao"
                />
              </DisplayDataRow>
            )}
          </DisplayData>
        </Panel>
        <LicitacaoInfoModal
          show={showModalInfo}
          onClose={this.closeModalInfo}
          licitacao={licitacao}
        ></LicitacaoInfoModal>
      </>
    );
  }
}

export default LicitacaoDetalheInformacao;
