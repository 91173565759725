import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react.js';
import SumListField from 'portaltransparencia-common/components/SumListField.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import * as actions from './Actions.js';

const sumValue = field => (valor, item) => valor + item.get(field);

export class EmpenhoDetalhePagamentos extends Component {
  static propTypes = {
    pagamentos: PropTypes.object.isRequired,
    paramDespesasPagasValor: PropTypes.string
  };

  renderItens = data => {
    let noLiquidacao =
      data.get('noLiquidacao') + '/' + data.get('exercicioLiquidacao');
    let noPagamento =
      data.get('noPagamento') + '/' + data.get('exercicioPagamento');

    return (
      <tr key={noLiquidacao + '_' + noPagamento} data={data}>
        <td>{noPagamento}</td>
        <td>{noLiquidacao}</td>
        <td>{DateUtils.formatDateTimeShort(data.get('data'))}</td>
        <td>{data.get('banco')}</td>
        <td>{data.get('agencia')}</td>
        <td>{data.get('conta')}</td>
        <td>{data.get('tipoDocumento')}</td>
        <td>{data.get('nroDocumento')}</td>
        <td className="right" id="valorPago">
          <NumberFormatter
            value={
              this.props.paramDespesasPagasValor &&
              this.props.paramDespesasPagasValor == 'S'
                ? data.get('valor') + data.get('valorRetido')
                : data.get('valor')
            }
          />
        </td>
      </tr>
    );
  };

  render() {
    const { pagamentos } = this.props;

    return (
      <div className="panel-table-outer">
        <table className="panel-table striped fancy">
          <TableHeader
            empty={pagamentos.isEmpty()}
            text="Não possui pagamentos."
          >
            <tr>
              <th>Pagamento</th>
              <th>Liquidação</th>
              <th>Data</th>
              <th>Banco</th>
              <th>Agência</th>
              <th>Conta</th>
              <th>Tipo Documento</th>
              <th>Documento</th>
              <th className="right">Valor</th>
            </tr>
          </TableHeader>
          <tbody>{pagamentos.map(this.renderItens)}</tbody>

          {!pagamentos.isEmpty() && (
            <tfoot>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="right">
                  <i>Total: </i>
                  {this.props.paramDespesasPagasValor &&
                  this.props.paramDespesasPagasValor == 'S' ? (
                    <NumberFormatter
                      value={
                        pagamentos.reduce(sumValue('valor'), 0) +
                        pagamentos.reduce(sumValue('valorRetido'), 0)
                      }
                    />
                  ) : (
                    <SumListField fieldName="valor" list={pagamentos} />
                  )}
                </td>
              </tr>
            </tfoot>
          )}
        </table>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    pagamentos: state.empenhos.get('pagamentos')
  };
}

const allActions = {
  ...actions
};

export default connect(mapStateToProps, allActions)(EmpenhoDetalhePagamentos);
