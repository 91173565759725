import Modal from 'portaltransparencia-common/components/Modal.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export class ConsultaProcessoDetalhe extends Component {
  static propTypes = {
    processoTramitacao: PropTypes.object.isRequired,
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
  };

  renderProcessoRow = tramitacao => {
    return (
      <tr key={tramitacao.get('sequencia')} data={tramitacao}>
        <td>{tramitacao.get('sequencia')}</td>
        <td>{tramitacao.get('data')}</td>
        <td>{tramitacao.get('local')} </td>
        <td>{tramitacao.get('funcao')}</td>
        <td>{tramitacao.get('situacao')}</td>
        <td>{tramitacao.get('parecer')}</td>
      </tr>
    );
  };

  render() {
    const { processoTramitacao, show, onClose } = this.props;

    const processo = processoTramitacao.get('processo');
    const exercicio = processoTramitacao.get('exercicio');
    const digitacao = processoTramitacao.get('digitacao');
    const tramitacoes = processoTramitacao.get('tramitacoes');

    if (show) {
      return (
        <Modal onClose={onClose}>
          <div className="container">
            <Panel
              title={`Processo: ${processo}/${exercicio} - ${digitacao}`}
              expansible={false}
            >
              <table className="panel-table striped fancy">
                <TableHeader empty={processoTramitacao.isEmpty()}>
                  <tr>
                    <th>Sequência</th>
                    <th>Data</th>
                    <th>Local</th>
                    <th>Função</th>
                    <th>Situação</th>
                    <th>Parecer</th>
                  </tr>
                </TableHeader>
                <tbody>
                  {tramitacoes && tramitacoes.map(this.renderProcessoRow)}
                </tbody>
              </table>
            </Panel>
          </div>
        </Modal>
      );
    }
    return null;
  }
}

export default ConsultaProcessoDetalhe;
