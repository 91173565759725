import Modal from 'portaltransparencia-common/components/Modal.react.js';
import DisplayData from 'portaltransparencia-common/components/displaydata/DisplayData.react.js';
import DisplayDataItem from 'portaltransparencia-common/components/displaydata/DisplayDataItem.react.js';
import DisplayDataRow from 'portaltransparencia-common/components/displaydata/DisplayDataRow.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import SearchPagination from 'portaltransparencia-common/components/SearchPagination.react.js';
import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import TableHeaderItem from 'portaltransparencia-common/components/TableHeaderItem.react';
import Widget from 'portaltransparencia-common/components/Widget.react.js';
import {
  addFilterRsql,
  addPagination
} from 'portaltransparencia-common/libs/AddFilter.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import NumberUtils from 'portaltransparencia-common/libs/NumberUtils.js';
import { addAlertNotification } from 'portaltransparencia-common/ui/Actions.js';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'react-bootstrap';

import PageHeader from '../components/PageHeader.react.js';
import NotasExplicativas from '../notaexplicativa/Form.react.js';
import {
  findDiariosOficiais,
  onChangeFilter,
  onClearFilter,
  urlDownload,
  copyDiarioUrl
} from './ActionsDiario.js';
import DiarioFiltro from './DiarioFiltro.react.js';
import FormTotalAcesso from '../totalacessos/Form.react.js';
import Spinner from 'portaltransparencia-common/components/Spinner.react.js';

export class FormDiario extends React.Component {
  static propTypes = {
    onChangeFilter: PropTypes.func.isRequired,
    onClearFilter: PropTypes.func.isRequired,
    findDiariosOficiais: PropTypes.func.isRequired,
    urlDownload: PropTypes.func.isRequired,
    copyDiarioUrl: PropTypes.func.isRequired,
    diarios: PropTypes.object.isRequired,
    filter: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    addAlertNotification: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = { showModal: false };
  }

  componentDidMount() {
    this.onConsultar();
  }

  searchWithPage = page => {
    const { findDiariosOficiais } = this.props;

    const filterQuery = this.montaFilter(page);

    findDiariosOficiais(filterQuery);
  };

  onConsultar = () => {
    const { findDiariosOficiais, diarios } = this.props;

    const filterQuery = this.montaFilter(diarios);
    findDiariosOficiais(filterQuery);
  };

  mountRsqlFilter = () => {
    const { filter } = this.props;

    let filterQuery = '';

    if (filter.mesPublicacao) {
      filterQuery = addFilterRsql(
        filterQuery,
        `publishedMonth==${filter.mesPublicacao}`
      );
    }

    if (filter.anoPublicacao) {
      filterQuery = addFilterRsql(
        filterQuery,
        `publishedYear==${filter.anoPublicacao}`
      );
    }

    if (filter.numero) {
      filterQuery = addFilterRsql(filterQuery, `edition==${filter.numero}`);
    }

    if (filter.chave) {
      filterQuery = addFilterRsql(filterQuery, `fileText=="*${filter.chave}*"`);
    }

    if (filter.dataInicio) {
      filterQuery = addFilterRsql(
        filterQuery,
        `publishedAt>=${filter.dataInicio}T00:00`
      );
    }

    if (filter.dataFim) {
      filterQuery = addFilterRsql(
        filterQuery,
        `publishedAt<=${filter.dataFim}T23:59:59`
      );
    }

    return filterQuery ? `search=${filterQuery}` : '';
  };

  montaFilter = page => {
    return addPagination(
      page.set('sort', 'edition,desc'),
      this.mountRsqlFilter()
    );
  };

  onVisualizarDiario = diarioOficial => {
    const { urlDownload } = this.props;
    urlDownload(diarioOficial.get('id'));
  };

  onCopiarUrlDiario = diarioOficial => {
    const { copyDiarioUrl, addAlertNotification } = this.props;
    copyDiarioUrl(diarioOficial.get('id')).then(() => {
      addAlertNotification('URL copiada', 'Url copiada com sucesso', 5);
    });
  };

  renderDiarioOficial = diarioOficial => (
    <TableActions
      showView
      key={diarioOficial.get('id')}
      data={diarioOficial}
      onDownload={this.onDownloadDiario}
      onView={this.onVisualizarDiario}
      onCopyUrl={this.onCopiarUrlDiario}
      onMore={this.onOpenListenModal}
      moreIcon="fa fa-headphones"
      moreTitle="Ouvir"
    >
      <td>{diarioOficial.get('edition')}</td>
      <td>{diarioOficial.get('editionType')}</td>
      <td>{DateUtils.formatDateTimeShort(diarioOficial.get('publishedAt'))}</td>
      <td>{diarioOficial.get('publishedBy')}</td>
      <td>{diarioOficial.get('publishedBy')}</td>
      <td>{NumberUtils.formatBytes(diarioOficial.get('fileSize'))}</td>
    </TableActions>
  );

  hasFilter = () => true;

  clearFiltroAndReload = () => {
    const { onClearFilter, findDiariosOficiais } = this.props;

    onClearFilter();
    findDiariosOficiais('sort=edition,desc');
  };

  onCloseListenModel = () => {
    this.setState({ showModal: false });
  };

  onOpenListenModal = diarioOficial => {
    this.setState({
      showModal: true,
      diarioId: diarioOficial.get('id'),
      diarioEdition: diarioOficial.get('edition'),
      isAudioLoading: true
    });
  };

  setIsAudioLoading = isAudioLoading => {
    this.setState({ isAudioLoading });
  };

  render() {
    const { diarios, onChangeFilter, filter } = this.props;
    let rsqlFilter = this.mountRsqlFilter();
    const { showModal, diarioId, diarioEdition, isAudioLoading } = this.state;

    return (
      <>
        <section>
          <PageHeader location={this.props.location} />
          <NotasExplicativas pathname={this.props.location.pathname} />
          <DiarioFiltro
            show
            findDiarios={this.onConsultar}
            onChangeFilter={onChangeFilter}
            onClearFilter={this.clearFiltroAndReload}
            filter={filter}
          />
          {diarios.get('content') &&
            diarios.get('content').length > 0 &&
            !rsqlFilter && (
              <Widget icon="fa fa-newspaper-o" title={'Última publicação'}>
                <DisplayData border>
                  <DisplayDataRow>
                    <DisplayDataItem
                      sm={2}
                      title="Edição"
                      value={diarios.get('content').get(0).get('edition')}
                    />
                    <DisplayDataItem
                      sm={3}
                      title="Tipo"
                      value={diarios.get('content').get(0).get('editionType')}
                    />
                    <DisplayDataItem
                      sm={3}
                      title="Tamanho arquivo"
                      value={NumberUtils.formatBytes(
                        diarios.get('content').get(0).get('fileSize')
                      )}
                    />
                    <DisplayDataItem
                      sm={4}
                      title="Data publicação"
                      value={DateUtils.formatDateTimeShort(
                        diarios.get('content').get(0).get('publishedAt')
                      )}
                    />
                  </DisplayDataRow>
                  <DisplayDataRow>
                    <DisplayDataItem
                      sm={6}
                      title="Usuário publicação"
                      value={diarios.get('content').get(0).get('publishedBy')}
                    />
                    <DisplayDataItem
                      sm={6}
                      title="Usuário assinatura"
                      value={diarios.get('content').get(0).get('publishedBy')}
                    />
                  </DisplayDataRow>
                </DisplayData>
                <div className="center mt-xs">
                  <button
                    type="button"
                    className="btn inline"
                    onClick={() =>
                      this.onVisualizarDiario(diarios.get('content').get(0))
                    }
                    title={'Visualizar'}
                  >
                    Visualizar
                  </button>
                </div>
                {showModal && (
                  <Modal onClose={this.onCloseListenModel}>
                    <Panel title={`Edição ${diarioEdition}`} expansible={false}>
                      {isAudioLoading && <Spinner />}
                      <center>
                        <audio
                          controls
                          src={`/portaltransparencia-api/api/legislacao/diarios-oficiais/text-to-speech/${diarioId}`}
                          onCanPlay={() => this.setIsAudioLoading(false)}
                        >
                          Seu browser não suporta <code>audio</code>
                        </audio>
                      </center>
                    </Panel>
                  </Modal>
                )}
              </Widget>
            )}
          <Panel isForm tableResponsive expansible={false}>
            <div className="panel-table-outer">
              <table className="panel-table striped fancy">
                <TableHeader
                  empty={
                    !diarios.get('content') || diarios.get('content').isEmpty()
                  }
                >
                  <tr>
                    <TableHeaderItem label="Edição" sortable={false} />
                    <TableHeaderItem label="Tipo" sortable={false} />
                    <TableHeaderItem label="Data publicação" sortable={false} />
                    <TableHeaderItem
                      label="Usuário publicação"
                      sortable={false}
                    />
                    <TableHeaderItem
                      label="Usuário assinatura"
                      sortable={false}
                    />
                    <TableHeaderItem label="Tamanho arquivo" sortable={false} />
                    <th></th>
                  </tr>
                </TableHeader>
                <tbody>
                  {diarios.get('content') &&
                    diarios
                      .get('content')
                      .map(diarioOficial =>
                        this.renderDiarioOficial(diarioOficial)
                      )
                      .toArray()}
                </tbody>
              </table>
            </div>
            <SearchPagination
              page={diarios}
              searchWithPage={this.searchWithPage}
            />
          </Panel>
        </section>
        <div className="container text-xs-right">
          <Row>
            <Col>
              <FormTotalAcesso tipo="TOTAL_DIARIO_OFICIAL" />
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    diarios: state.diarioReducer.get('diarios'),
    filter: {
      chave: state.diarioReducer.get('chave'),
      mesPublicacao: state.diarioReducer.get('mesPublicacao'),
      anoPublicacao: state.diarioReducer.get('anoPublicacao'),
      numero: state.diarioReducer.get('numero'),
      dataInicio: state.diarioReducer.get('dataInicio'),
      dataFim: state.diarioReducer.get('dataFim')
    }
  };
}

const allActions = {
  findDiariosOficiais,
  onChangeFilter,
  onClearFilter,
  urlDownload,
  copyDiarioUrl,
  addAlertNotification
};

export default connect(mapStateToProps, allActions)(FormDiario);
