import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

import ExportButton from './ExportButton.react.js';

export default class ExportButtonGroup extends Component {
  static propTypes = {
    onClick: PropTypes.func,
    html: PropTypes.bool,
    xls: PropTypes.bool,
    pdf: PropTypes.bool,
    txt: PropTypes.bool,
    csv: PropTypes.bool,
    rtf: PropTypes.bool,
    doc: PropTypes.bool,
    visible: PropTypes.bool,
    open: PropTypes.bool
  };

  static defaultProps = {
    html: true,
    xls: true,
    pdf: true,
    txt: true,
    csv: true,
    rtf: true,
    doc: true,
    visible: true,
    open: false
  };

  constructor(props) {
    super(props);
    this.state = {
      open: props.open
    };
  }

  clickButton = exportType => {
    const { onClick } = this.props;

    const windowParams = 'width=800, height=600';

    let openTab = '_self';
    if (exportType == 'html' || exportType == 'pdf') {
      openTab = '_blank';
    }

    onClick(exportType, openTab, windowParams);
  };

  dropDownClick = () => {
    if (this.state.open) {
      this.setState({
        open: false
      });
    } else {
      this.setState({
        open: true
      });
    }
  };

  render() {
    const { open } = this.state;
    const { html, xls, pdf, txt, csv, rtf, doc, visible } = this.props;

    const classes = {
      open: open
    };

    if (!visible) {
      return null;
    }

    return (
      <div>
        <div className="btn-group export right mt-xs mb-xs hidden-print hidden-xs">
          <ExportButton
            id="html"
            icon="fa fa-print"
            label="Imprimir"
            onClick={this.clickButton}
            exportType="html"
            visible={html}
          />

          <ExportButton
            id="xls"
            icon="fa fa-file-excel-o"
            label="XLS"
            onClick={this.clickButton}
            exportType="xls"
            visible={xls}
          />

          <ExportButton
            id="pdf"
            icon="fa fa-file-pdf-o"
            label="PDF"
            onClick={this.clickButton}
            exportType="pdf"
            visible={pdf}
          />

          <ExportButton
            id="txt"
            icon="fa fa-file-text-o"
            label="TXT"
            onClick={this.clickButton}
            exportType="txt"
            visible={txt}
          />

          <ExportButton
            id="csv"
            icon="fa fa-file-code-o"
            label="CSV"
            onClick={this.clickButton}
            exportType="csv"
            visible={csv}
          />

          <ExportButton
            id="rtf"
            icon="fa fa-file-archive-o"
            label="RTF"
            onClick={this.clickButton}
            exportType="rtf"
            visible={rtf}
          />

          <ExportButton
            id="doc"
            icon="fa fa-file-word-o"
            label="DOC"
            onClick={this.clickButton}
            exportType="doc"
            visible={doc}
          />
        </div>

        <div
          className={
            'btn-dropdown visible-xs mb-xs mt-xs ' + classnames(classes)
          }
          onClick={this.dropDownClick}
        >
          <button
            type="sumbit"
            className="btn btn-dropdown-opener neutral right"
          >
            Exportar
          </button>

          <ul className="btn-dropdown-list">
            <li>
              <ExportButton
                id="html"
                className="module-color"
                icon="fa fa-print"
                label="Imprimir"
                onClick={this.clickButton}
                exportType="html"
                visible={html}
              />
            </li>

            <li>
              <ExportButton
                id="xls"
                icon="fa fa-file-excel-o"
                label="XLS"
                onClick={this.clickButton}
                exportType="xls"
                visible={xls}
              />
            </li>

            <li>
              <ExportButton
                id="pdf"
                icon="fa fa-file-pdf-o"
                label="PDF"
                onClick={this.clickButton}
                exportType="pdf"
                visible={pdf}
              />
            </li>

            <li>
              <ExportButton
                id="txt"
                icon="fa fa-file-text-o"
                label="TXT"
                onClick={this.clickButton}
                exportType="txt"
                visible={txt}
              />
            </li>

            <li>
              <ExportButton
                id="csv"
                icon="fa fa-file-code-o"
                label="CSV"
                onClick={this.clickButton}
                exportType="csv"
                visible={csv}
              />
            </li>

            <li>
              <ExportButton
                id="rtf"
                icon="fa fa-file-archive-o"
                label="RTF"
                onClick={this.clickButton}
                exportType="rtf"
                visible={rtf}
              />
            </li>

            <li>
              <ExportButton
                id="doc"
                icon="fa fa-file-word-o"
                label="DOC"
                onClick={this.clickButton}
                exportType="doc"
                visible={doc}
              />
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
