import DisplayData from 'portaltransparencia-common/components/displaydata/DisplayData.react.js';
import DisplayDataItem from 'portaltransparencia-common/components/displaydata/DisplayDataItem.react.js';
import DisplayDataRow from 'portaltransparencia-common/components/displaydata/DisplayDataRow.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import NumberUtils from 'portaltransparencia-common/libs/NumberUtils.js';
import { getAllValuesFromUrlSearchParams } from 'portaltransparencia-common/libs/URLUtils';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import PageHeader from '../components/PageHeader.react.js';
import * as actions from './Actions.js';

export class FormAlvaraDetalhe extends Component {
  static propTypes = {
    alvara: PropTypes.object.isRequired,
    alvaraArquivos: PropTypes.object.isRequired,
    findAlvaraDetalhes: PropTypes.func.isRequired,
    findAlvaraArquivos: PropTypes.func.isRequired,
    downloadArquivo: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired
  };

  componentDidMount() {
    const { location, findAlvaraDetalhes, findAlvaraArquivos } = this.props;
    const params = getAllValuesFromUrlSearchParams(location);

    findAlvaraDetalhes(`pessoa=${params.pessoa}`);
    findAlvaraArquivos(`pessoa=${params.pessoa}`);
  }

  renderAlvara = data => {
    const { downloadArquivo } = this.props;
    const key = `${data.get('pessoa')}_${data.get('numeroAlvara')}_${data.get(
      'dataValidade'
    )}`;
    return (
      <TableActions
        ref="row"
        key={key}
        data={data}
        onDownload={
          data.get('idArquivo') &&
          (() => downloadArquivo(data.get('idArquivo')))
        }
      >
        <td>{DateUtils.formatDateTimeShort(data.get('dataValidade'))}</td>
        <td>{NumberUtils.format(data.get('areaUtilizada'))}</td>
        <td>{data.get('atividadePrincipal')}</td>
        <td>{data.get('numeroAlvara')}</td>
        <td>{data.get('tipoDocumento')}</td>
      </TableActions>
    );
  };

  render() {
    const { alvara, alvaraArquivos, location } = this.props;

    return (
      <section>
        <PageHeader location={location} />

        <Panel title={'Entidade ' + alvara.get('nome')}>
          <DisplayData border>
            <DisplayDataRow>
              <DisplayDataItem
                sm={6}
                title="Razão Social"
                value={alvara.get('nome')}
                id="nome"
              />
              <DisplayDataItem
                sm={4}
                title="CNPJ/CPF"
                value={alvara.get('cnpjCpf')}
                id="cnpjCpf"
              />
            </DisplayDataRow>
            <DisplayDataRow>
              <DisplayDataItem
                sm={6}
                title="Nome Fantasia"
                value={alvara.get('nomeFantasia')}
                id="nomeFantasia"
              />
            </DisplayDataRow>
          </DisplayData>
        </Panel>
        {alvaraArquivos && (
          <Panel isForm tableResponsive expansible={false}>
            <div className="panel-table-outer">
              <table className="panel-table striped fancy">
                <TableHeader
                  empty={alvaraArquivos.isEmpty()}
                  text="Sem registros"
                >
                  <tr>
                    <th>Data Validade</th>
                    <th>Área Utilizada</th>
                    <th>Atividade Principal</th>
                    <th>Número Alvará</th>
                    <th>Tipo Documento</th>
                    <th />
                  </tr>
                </TableHeader>
                <tbody>{alvaraArquivos.map(this.renderAlvara)}</tbody>
              </table>
            </div>
          </Panel>
        )}
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    alvara: state.alvara.get('alvara'),
    alvaraArquivos: state.alvara.get('alvaraArquivos')
  };
}

export default connect(mapStateToProps, actions)(FormAlvaraDetalhe);
