import * as types from './Types.js';

export function findParticipantes(filter) {
  return ({ fetch }) => ({
    type: types.LICITACOES_PARTICIPANTES,
    payload: fetch(`/api/licitacoes/participantes?${filter}`)
  });
}

export function findParticipanteDetalhes(filter) {
  return ({ fetch }) => ({
    type: types.LICITACOES_PARTICIPANTE,
    payload: fetch(`/api/licitacoes/participante?${filter}`)
  });
}
