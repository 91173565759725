import StorageService from 'portaltransparencia-common/libs/StorageService.js';

import * as types from './Types.js';

export function searchServidores(filter) {
  const { entidade, exercicio } = StorageService.getItem('userInfo');
  const baseFilter = `entidade=${entidade.id}&exercicio=${exercicio.id}&`;

  return ({ fetch }) => ({
    type: types.SERVIDORES_OXY_LIST,
    payload: {
      promise: fetch(`/api/servidores?${baseFilter}${filter}`)
    }
  });
}

export function loadResumoNatureza(admissaoExcepcional) {
  let url = `/api/servidores/resumonatureza?admissaoExcepcional=${admissaoExcepcional}`;

  return ({ fetch }) => ({
    type: types.SERVIDORES_OXY_RESUMO,
    payload: {
      promise: fetch(url)
    }
  });
}

export function findServidor(matricula) {
  return ({ fetch }) => ({
    type: types.SERVIDOR_OXY_DETALHE,
    payload: {
      promise: fetch(`/api/servidores/${matricula}`)
    }
  });
}

export function findServidorPorVinculo(vinculo, matricula, entidadeOrigem) {
  return ({ fetch }) => ({
    type: types.SERVIDOR_OXY_DETALHE,
    payload: {
      promise: fetch(
        `/api/servidores/${vinculo}/${matricula}/${entidadeOrigem}`
      )
    }
  });
}

export function findDadosPensao(matricula, entidadeOrigem) {
  return ({ fetch }) => ({
    type: types.SERVIDOR_OXY_DETALHE_PENSAO,
    payload: {
      promise: fetch(
        `/api/servidores/dados-pensao?matricula=${matricula}&entidadeOrigem=${entidadeOrigem}`,
        {
          ignoreStatus: 404
        }
      )
    }
  });
}

export function loadVencimentosDescontos(item, tipoEvento) {
  const url = '/api/servidores/vencimentos-descontos?';
  const matriculaParam = `matricula=${item.get('matricula')}`;
  const anoParam = `&anoCompetencia=${item.get('anoCompetencia')}`;
  const mesParam = `&mesCompetencia=${item.get('mesCompetencia')}`;
  const tipoEventoParam = `&tipoEvento=${tipoEvento}`;
  const tipoFolhaParam = `&tipoFolha=${
    item.get('tipoFolha') ? item.get('tipoFolha') : ''
  }`;
  const codigoCalculoParam = `&codigoCalculo=${
    item.get('codigoCalculo') ? item.get('codigoCalculo') : ''
  }`;
  const entidade = `&entidadeOrigem=${
    item.get('entidade') ? item.get('entidade') : 0
  }`;

  return ({ fetch }) => ({
    type: types.SERVIDOR_OXY_VENCIMENTOS_DESCONTOS,
    payload: {
      promise: fetch(
        url +
          matriculaParam +
          anoParam +
          mesParam +
          tipoEventoParam +
          tipoFolhaParam +
          codigoCalculoParam +
          entidade
      )
    }
  });
}

export function loadNaturezas() {
  const { entidade, exercicio } = StorageService.getItem('userInfo');
  const baseFilter = `entidade=${entidade.id}&exercicio=${exercicio.id}`;

  return ({ fetch }) => ({
    type: types.NATUREZAS_OXY_LIST,
    payload: {
      promise: fetch(`/api/definicoes-classes?${baseFilter}`)
    }
  });
}

export function loadNaturezasAposentadosPensionistas() {
  const { entidade, exercicio } = StorageService.getItem('userInfo');
  const baseFilter = `entidade=${entidade.id}&exercicio=${exercicio.id}&`;

  return ({ fetch }) => ({
    type: types.NATUREZAS_OXY_LIST,
    payload: {
      promise: fetch(`/api/definicoes-classes/aposentados?${baseFilter}`)
    }
  });
}

export function loadRegistrosPontos(item) {
  const url = '/api/servidores/registros-pontos?';
  const matriculaParam = `matricula=${item.get('matricula')}`;
  const anoParam = `&anoCompetencia=${item.get('anoCompetencia')}`;
  const mesParam = `&mesCompetencia=${item.get('mesCompetencia')}`;

  return ({ fetch }) => ({
    type: types.SERVIDOR_OXY_REGISTROS_PONTOS,
    payload: {
      promise: fetch(url + matriculaParam + anoParam + mesParam)
    }
  });
}

export function loadSituacoes() {
  return ({ fetch }) => ({
    type: types.SITUACOES_OXY_LIST,
    payload: {
      promise: fetch('/api/servidores/situacoes')
    }
  });
}

export function clearDetalhe() {
  return {
    type: types.CLEAR_OXY_DETALHE,
    payload: {}
  };
}

export function searchServidoresAposentados(filter) {
  const { entidade, exercicio } = StorageService.getItem('userInfo');

  const baseFilter = `entidade=${entidade.id}&exercicio=${exercicio.id}&`;

  return ({ fetch }) => ({
    type: types.SERVIDORES_OXY_LIST,
    payload: {
      promise: fetch(`/api/servidores/aposentados?${baseFilter}${filter}`)
    }
  });
}

export function searchEstagiarios(filter) {
  const { entidade, exercicio } = StorageService.getItem('userInfo');
  const baseFilter = `entidade=${entidade.id}&exercicio=${exercicio.id}&`;

  return ({ fetch }) => ({
    type: types.ESTAGIARIOS_OXY_LIST,
    payload: {
      promise: fetch(`/api/estagiarios?${baseFilter}${filter}`)
    }
  });
}

export function findEstagiario(codigo) {
  return ({ fetch }) => ({
    type: types.ESTAGIARIO_OXY_DETALHE,
    payload: {
      promise: fetch(`/api/estagiarios/${codigo}`)
    }
  });
}

export function findEstagiarioPorVinculo(vinculo, codigo) {
  return ({ fetch }) => ({
    type: types.ESTAGIARIO_OXY_DETALHE,
    payload: {
      promise: fetch(`/api/estagiarios/${vinculo}/${codigo}`)
    }
  });
}

export function searchInativos(filter) {
  const { entidade, exercicio } = StorageService.getItem('userInfo');
  const baseFilter = `entidade=${entidade.id}&exercicio=${exercicio.id}&`;

  return ({ fetch }) => ({
    type: types.INATIVOS_LIST,
    payload: {
      promise: fetch(`/api/inativos?${baseFilter}${filter}`)
    }
  });
}

export function findInativo(codigo) {
  return ({ fetch }) => ({
    type: types.INATIVO_DETALHE,
    payload: {
      promise: fetch(`/api/inativos/${codigo}`)
    }
  });
}

export function findInativoPorVinculo(vinculo, codigo) {
  return ({ fetch }) => ({
    type: types.INATIVO_DETALHE,
    payload: {
      promise: fetch(`/api/inativos/${vinculo}/${codigo}`)
    }
  });
}

export function loadVencimentosDescontosInativo(item, tipoEvento) {
  const url = '/api/inativos/vencimentos-descontos?';
  const matriculaParam = `matricula=${item.get('matricula')}`;
  const anoParam = `&anoCompetencia=${item.get('anoCompetencia')}`;
  const mesParam = `&mesCompetencia=${item.get('mesCompetencia')}`;
  const tipoEventoParam = `&tipoEvento=${tipoEvento}`;
  const tipoFolhaParam = `&tipoFolha=${
    item.get('tipoFolha') ? item.get('tipoFolha') : ''
  }`;
  const codigoCalculoParam = `&codigoCalculo=${
    item.get('codigoCalculo') ? item.get('codigoCalculo') : ''
  }`;
  const entidade = `&entidadeOrigem=${
    item.get('entidade') ? item.get('entidade') : 0
  }`;

  return ({ fetch }) => ({
    type: types.SERVIDOR_OXY_VENCIMENTOS_DESCONTOS,
    payload: {
      promise: fetch(
        url +
          matriculaParam +
          anoParam +
          mesParam +
          tipoEventoParam +
          tipoFolhaParam +
          codigoCalculoParam +
          entidade
      )
    }
  });
}

export function searchPensionistas(filter) {
  const { entidade, exercicio } = StorageService.getItem('userInfo');
  const baseFilter = `entidade=${entidade.id}&exercicio=${exercicio.id}&`;

  return ({ fetch }) => ({
    type: types.PENSIONISTAS_LIST,
    payload: {
      promise: fetch(`/api/pensionistas?${baseFilter}${filter}`)
    }
  });
}

export function findPensionista(codigo) {
  return ({ fetch }) => ({
    type: types.PENSIONISTA_DETALHE,
    payload: {
      promise: fetch(`/api/pensionistas/${codigo}`)
    }
  });
}

export function findPensionistaPorVinculo(vinculo, codigo) {
  return ({ fetch }) => ({
    type: types.PENSIONISTA_DETALHE,
    payload: {
      promise: fetch(`/api/pensionistas/${vinculo}/${codigo}`)
    }
  });
}

export function loadVencimentosDescontosPensionista(item, tipoEvento) {
  const url = '/api/pensionistas/vencimentos-descontos?';
  const matriculaParam = `matricula=${item.get('matricula')}`;
  const anoParam = `&anoCompetencia=${item.get('anoCompetencia')}`;
  const mesParam = `&mesCompetencia=${item.get('mesCompetencia')}`;
  const tipoEventoParam = `&tipoEvento=${tipoEvento}`;
  const tipoFolhaParam = `&tipoFolha=${
    item.get('tipoFolha') ? item.get('tipoFolha') : ''
  }`;
  const codigoCalculoParam = `&codigoCalculo=${
    item.get('codigoCalculo') ? item.get('codigoCalculo') : ''
  }`;
  const entidade = `&entidadeOrigem=${
    item.get('entidade') ? item.get('entidade') : 0
  }`;

  return ({ fetch }) => ({
    type: types.SERVIDOR_OXY_VENCIMENTOS_DESCONTOS,
    payload: {
      promise: fetch(
        url +
          matriculaParam +
          anoParam +
          mesParam +
          tipoEventoParam +
          tipoFolhaParam +
          codigoCalculoParam +
          entidade
      )
    }
  });
}

export function searchAutonomos(filter) {
  const { entidade, exercicio } = StorageService.getItem('userInfo');
  const baseFilter = `entidade=${entidade.id}&exercicio=${exercicio.id}&`;

  return ({ fetch }) => ({
    type: types.AUTONOMOS_LIST,
    payload: {
      promise: fetch(`/api/autonomos?${baseFilter}${filter}`)
    }
  });
}

export function findAutonomo(codigo) {
  return ({ fetch }) => ({
    type: types.AUTONOMO_DETALHE,
    payload: {
      promise: fetch(`/api/autonomos/${codigo}`)
    }
  });
}

export function findAutonomoPorVinculo(vinculo, codigo) {
  return ({ fetch }) => ({
    type: types.AUTONOMO_DETALHE,
    payload: {
      promise: fetch(`/api/autonomos/${vinculo}/${codigo}`)
    }
  });
}

export function loadVencimentosDescontosAutonomo(item, tipoEvento) {
  const url = '/api/autonomos/vencimentos-descontos?';
  const matriculaParam = `matricula=${item.get('matricula')}`;
  const anoParam = `&anoCompetencia=${item.get('anoCompetencia')}`;
  const mesParam = `&mesCompetencia=${item.get('mesCompetencia')}`;
  const tipoEventoParam = `&tipoEvento=${tipoEvento}`;
  const tipoFolhaParam = `&tipoFolha=${
    item.get('tipoFolha') ? item.get('tipoFolha') : ''
  }`;
  const codigoCalculoParam = `&codigoCalculo=${
    item.get('codigoCalculo') ? item.get('codigoCalculo') : ''
  }`;
  const entidade = `&entidadeOrigem=${
    item.get('entidade') ? item.get('entidade') : 0
  }`;

  return ({ fetch }) => ({
    type: types.SERVIDOR_OXY_VENCIMENTOS_DESCONTOS,
    payload: {
      promise: fetch(
        url +
          matriculaParam +
          anoParam +
          mesParam +
          tipoEventoParam +
          tipoFolhaParam +
          codigoCalculoParam +
          entidade
      )
    }
  });
}
