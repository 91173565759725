import Panel from 'portaltransparencia-common/components/Panel.react.js';
import { getValueFromUrlSearchParams } from 'portaltransparencia-common/libs/URLUtils';
import {
  PARAM_SALARIOBASE,
  PARAM_USAWEBSERVICE_MARINGA
} from 'portaltransparencia-common/parametros/ParametrosPortalTransparencia';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import ParametroService from '../parametros/ParametroService.js';
import * as actions from './Actions.js';
import EstagiarioDetalheFinanceiroOxy from './EstagiarioDetalheFinanceiro.react.js';
import EstagiarioDetalheInformacaoOxy from './EstagiarioDetalheInformacao.react.js';

export class FormEstagiarioDetailOxy extends Component {
  static propTypes = {
    estagiario: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    findEstagiario: PropTypes.func.isRequired,
    clearDetalhe: PropTypes.func.isRequired,
    paramWebService: PropTypes.string,
    paramExibeSalarioBase: PropTypes.string
  };

  componentDidMount() {
    const { findEstagiario, findEstagiarioPorVinculo, location } = this.props;
    const codigo = getValueFromUrlSearchParams(location, 'codigo');
    const vinculo = getValueFromUrlSearchParams(location, 'vinculo');

    if (vinculo) {
      findEstagiarioPorVinculo(vinculo, codigo);
    } else {
      findEstagiario(codigo);
    }
  }

  componentWillUnmount() {
    const { clearDetalhe } = this.props;
    clearDetalhe();
  }

  render() {
    const {
      estagiario,
      location,
      paramWebService,
      paramExibeSalarioBase
    } = this.props;

    let descricao = 'Pessoal - Servidores';
    let descDetalhes = `Detalhes - Estagiário: ${estagiario.get('nome')}`;
    let local = '/servidores';
    let localDetalhe = location;

    return (
      <div>
        <section className="page-header">
          <div className="page-header-title">
            <div className="container">{`Estagiário: ${estagiario.get(
              'nome'
            )}`}</div>
          </div>

          <ul className="breadcrumb">
            <li>
              <Link to="/">Início</Link>
            </li>
            <li>
              <Link to={local}>{descricao}</Link>
            </li>
            <li>
              <Link to={localDetalhe}>{descDetalhes}</Link>
            </li>
          </ul>
        </section>
        {estagiario && (
          <EstagiarioDetalheInformacaoOxy
            estagiario={estagiario}
            usaWebService={paramWebService == '1'}
            exibeSalarioBase={paramExibeSalarioBase === 'S'}
          />
        )}
        {estagiario.get('dadosFinanceiros') &&
          !estagiario.get('dadosFinanceiros').isEmpty() && (
            <Panel
              isTable
              tableResponsive
              expansible={false}
              title="Dados Financeiros"
            >
              <EstagiarioDetalheFinanceiroOxy
                dadosFinanceiros={estagiario.get('dadosFinanceiros')}
              />
            </Panel>
          )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    estagiario: state.servidorOxy.get('estagiario'),
    paramWebService: ParametroService.getValorParametro(
      state,
      PARAM_USAWEBSERVICE_MARINGA
    ),
    paramExibeSalarioBase: ParametroService.getValorParametro(
      state,
      PARAM_SALARIOBASE
    )
  };
}

export default connect(mapStateToProps, actions)(FormEstagiarioDetailOxy);
