import * as types from './Types';

export function findEstabelecimentos() {
  return ({ fetch }) => {
    return {
      type: types.PESQUISA_ESCALAS,
      payload: {
        promise: fetch('/api/escalas-profissionais')
      }
    };
  };
}

export function filterEscalas(filtro) {
  return ({ getState }) => {
    const escalas = getState().escala.get('escalasFiltradas');

    return {
      type: types.FILTRAR_ESCALAS,
      payload: escalas,
      meta: filtro
    };
  };
}

export function resetEscalas() {
  return {
    type: types.RESETAR_ESCALAS
  };
}
