import ExportButtonGroup from 'portaltransparencia-common/components/ExportButtonGroup.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import SearchPagination from 'portaltransparencia-common/components/SearchPagination.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import TableHeaderItem from 'portaltransparencia-common/components/TableHeaderItem.react';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';

import UltimaAtualizacaoByTable from '../ultimaatualizacao/UltimaAtualizacaoByTable.react.js';
import FornecedorRow from './FornecedorRow.react.js';
import FornecedorRowTotal from './FornecedorRowTotal.react.js';

export default class FornecedorTable extends Component {
  static propTypes = {
    montaFiltro: PropTypes.func.isRequired,
    onConsultaWithPage: PropTypes.func.isRequired,
    page: PropTypes.object.isRequired,
    totais: PropTypes.object,
    title: PropTypes.string,
    getAPagar: PropTypes.bool,
    getComprasDireta: PropTypes.bool,
    onExporta: PropTypes.func.isRequired
  };

  static defaultProps = {
    title: false
  };

  renderFornecedorRows = fornecedor => {
    return (
      <FornecedorRow
        key={fornecedor.get('fornecedor')}
        history={this.props.history}
        fornecedor={fornecedor}
        getAPagar={this.props.getAPagar}
        getComprasDireta={this.props.getComprasDireta}
      />
    );
  };

  render = () => {
    const { page, totais, onConsultaWithPage, title, onExporta } = this.props;
    if (!page.has('content')) {
      return null;
    }

    return (
      <section>
        <Panel title={title} isForm expansible={false} tableResponsive>
          <Row>
            <Col xs={6}>
              <UltimaAtualizacaoByTable
                modulo="3"
                table="empenho"
                column="dataultimaatualizacao"
              />
            </Col>
            <Col xs={6}>
              <ExportButtonGroup onClick={onExporta} visible={true} />
            </Col>
          </Row>
          <div className="panel-table-outer">
            <table className="panel-table striped fancy">
              <TableHeader empty={page.get('content').isEmpty()}>
                <tr>
                  <TableHeaderItem
                    field="nome"
                    label="Nome"
                    page={page}
                    searchWithPage={onConsultaWithPage}
                  />
                  <TableHeaderItem
                    field="cnpjCpf"
                    label="CNPJ/CPF"
                    page={page}
                    searchWithPage={onConsultaWithPage}
                  />
                  <th className="right">Empenhado</th>
                  <th className="right">Anulado</th>
                  <th className="right">Liquidado</th>
                  <th className="right">Retido</th>
                  <th className="right">Pago</th>
                  <th className="right">A Pagar</th>
                  <th></th>
                </tr>
              </TableHeader>
              <tbody>
                {page.get('content').map(this.renderFornecedorRows)}
              </tbody>
              <FornecedorRowTotal
                totais={totais}
                show={!page.get('content').isEmpty()}
              />
            </table>
          </div>
        </Panel>
        <SearchPagination page={page} searchWithPage={onConsultaWithPage} />
      </section>
    );
  };
}
