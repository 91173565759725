import BotaoImprimir from 'portaltransparencia-common/components/BotaoImprimir.react.js';
import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react';
import SumListField from 'portaltransparencia-common/components/SumListField.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import { addFilterUrl } from 'portaltransparencia-common/libs/AddFilter.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils';
import { getAllValuesFromUrlSearchParams } from 'portaltransparencia-common/libs/URLUtils';
import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import PageHeader from '../components/PageHeader.react.js';
import CabecalhoEntidade from '../entidades/CabecalhoEntidade.react.js';
import * as actions from './Actions.js';

export class FormReceitaDiaDetail extends Component {
  static propTypes = {
    receitasRazao: PropTypes.object.isRequired,
    findReceitasRazao: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    findReceita: PropTypes.func.isRequired,
    receita: PropTypes.object.isRequired
  };

  componentDidMount() {
    const { findReceitasRazao, findReceita } = this.props;
    let filter = this.montarFiltro();

    findReceita(filter).then(findReceitasRazao(filter));
  }

  montarFiltro() {
    const { location } = this.props;
    const {
      entidade,
      exercicio,
      data,
      receita,
      fonteRecurso
    } = getAllValuesFromUrlSearchParams(location);

    let filter = '';

    if (entidade) {
      filter = addFilterUrl(filter, `entidade=${entidade}`);
    }
    if (exercicio) {
      filter = addFilterUrl(filter, `exercicio=${exercicio}`);
    }
    if (data) {
      filter = addFilterUrl(
        filter,
        `data=${DateUtils.formatDateTime(data, 'YYYY-MM-DD')}`
      );
    }
    if (receita) {
      filter = addFilterUrl(filter, `receita=${receita}`);
    }
    if (fonteRecurso) {
      filter = addFilterUrl(filter, `fonteRecurso=${fonteRecurso}`);
    }

    return filter;
  }

  renderReceitas = data => {
    return (
      <tr ref="row" key={data.get('lancamento')}>
        <td>{data.get('lancamento')}</td>
        <td>{DateUtils.formatDateTimeShort(data.get('data'))}</td>
        <td>{data.get('descricao')}</td>
        <td>{data.get('banco')}</td>
        <td>{data.get('agencia')}</td>
        <td>{data.get('conta')}</td>
        <td>
          <NumberFormatter value={data.get('valor')} />
        </td>
      </tr>
    );
  };

  render() {
    const { receitasRazao, receita } = this.props;

    if (!receita || !receitasRazao) {
      return null;
    }

    return (
      <div>
        <Helmet title={`Receita - ${receita.get('descricao')}`} />
        <PageHeader
          location={this.props.location}
          textoDetalhe={`Receita: ${receita.get('receita')}  - ${receita.get(
            'descricao'
          )}`}
        />
        <BotaoImprimir />
        <CabecalhoEntidade />
        <div className="panel table open">
          <div className="panel-body">
            <div className="panel-table-outer">
              <table className="panel-table striped fancy">
                <TableHeader empty={receitasRazao.isEmpty()}>
                  <tr>
                    <th>Lançamento</th>
                    <th>Data</th>
                    <th>Descrição</th>
                    <th>Banco</th>
                    <th>Agência</th>
                    <th>Conta</th>
                    <th>Valor</th>
                  </tr>
                </TableHeader>
                <tbody>{receitasRazao.map(this.renderReceitas)}</tbody>
                {!receitasRazao.isEmpty() && (
                  <tfoot>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        <i>Total:</i>
                        <br />
                        <SumListField fieldName="valor" list={receitasRazao} />
                      </td>
                    </tr>
                  </tfoot>
                )}
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    receitasRazao: state.receita.get('receitasRazao'),
    receita: state.receita.get('receita')
  };
}

export default connect(mapStateToProps, actions)(FormReceitaDiaDetail);
