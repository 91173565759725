import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

import $ from 'jquery';
export class ButtonSwitchItem extends React.PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
  };

  componentDidMount() {
    $('.switch-buttons .btn').click(function () {
      var este = $(this);
      este.parent().find('.btn').removeClass('active');
      este.addClass('active');
    });
  }

  render() {
    const { title, onClick, active } = this.props;

    const classes = classnames('btn', {
      active: active
    });

    return (
      <button
        className={classes}
        type="button"
        onClick={onClick}
        style={{ fontSize: 16 }}
      >
        {title}
      </button>
    );
  }
}

export default ButtonSwitchItem;
