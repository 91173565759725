import Version from 'portaltransparencia-common/version/Version.react.js';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';

import FormTotalAcesso from '../totalacessos/Form.react.js';
import UltimaAtualizacao from '../ultimaatualizacao/Form.react.js';

export class Footer extends Component {
  static propTypes = {
    hideLogo: PropTypes.bool
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { hideLogo } = this.props;

    let imgSrc = '/assets/';
    if (
      process.env.NODE_ENV === 'production' &&
      process.env.PUBLIC_URL.includes('portaltransparencia')
    ) {
      imgSrc = '/portaltransparencia/assets/';
    }
    return (
      <footer className="footer">
        <div className="container">
          <Row>
            <Col sm={4} md={3}>
              <i className="fa fa-exclamation-triangle"></i>
              <label>Informações sujeitas a alteração</label>
            </Col>

            <Col sm={4} md={2}>
              <FormTotalAcesso tipo="TOTAL_DIARIO" />
            </Col>

            <Col sm={4} md={2}>
              <Version />
            </Col>

            <Col sm={5} md={3}>
              <UltimaAtualizacao tipo="footer" modulo="3" />
            </Col>

            {!hideLogo && (
              <Col sm={4} md={2} className="elo-logo">
                <div className="right visible-md visible-lg">
                  <img
                    className="footer-logo"
                    src={`${imgSrc}img/common/footer-logo.png`}
                  />
                </div>
                <div className="visible-xs visible-sm">
                  <img
                    className="footer-logo"
                    src={`${imgSrc}img/common/footer-logo.png`}
                  />
                </div>
              </Col>
            )}
          </Row>
        </div>
      </footer>
    );
  }
}

export default Footer;
