export const ALVARAS = 'ALVARAS';
export const ALVARAS_START = 'ALVARAS_START';
export const ALVARAS_SUCCESS = 'ALVARAS_SUCCESS';
export const ALVARAS_ERROR = 'ALVARAS_ERROR';

export const ALVARA = 'ALVARA';
export const ALVARA_START = 'ALVARA_START';
export const ALVARA_SUCCESS = 'ALVARA_SUCCESS';
export const ALVARA_ERROR = 'ALVARA_ERROR';

export const ALVARA_ARQUIVOS = 'ALVARA_ARQUIVOS';
export const ALVARA_ARQUIVOS_START = 'ALVARA_ARQUIVOS_START';
export const ALVARA_ARQUIVOS_SUCCESS = 'ALVARA_ARQUIVOS_SUCCESS';
export const ALVARA_ARQUIVOS_ERROR = 'ALVARA_ARQUIVOS_ERROR';

export const ARQUIVO_DOWNLOAD = 'ARQUIVO_DOWNLOAD';
export const ARQUIVO_DOWNLOAD_START = 'ARQUIVO_DOWNLOAD_START';
export const ARQUIVO_DOWNLOAD_SUCCESS = 'ARQUIVO_DOWNLOAD_SUCCESS';
export const ARQUIVO_DOWNLOAD_ERROR = 'ARQUIVO_DOWNLOAD_ERROR';
