import ExportButtonGroup from 'portaltransparencia-common/components/ExportButtonGroup.react.js';
import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import TableHeaderItem from 'portaltransparencia-common/components/TableHeaderItem.react';
import configuration from 'portaltransparencia-common/config/Config.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import storageService from 'portaltransparencia-common/libs/StorageService.js';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';

import LinkToEmpenho from '../empenhos/LinkToEmpenho.react';
import UltimaAtualizacaoByTable from '../ultimaatualizacao/UltimaAtualizacaoByTable.react.js';
import { addErrorNotification } from 'portaltransparencia-common/ui/Actions.js';
import { connect } from 'react-redux';
import * as actions from './Actions.js';

export class DiariaListagem extends Component {
  static propTypes = {
    totalDiarias: PropTypes.object.isRequired,
    filter: PropTypes.string,
    page: PropTypes.object,
    searchWithPage: PropTypes.func,
    mostrarSecretaria: PropTypes.bool,
    mostrarCargo: PropTypes.bool,
    mostrarValorMedioUnitario: PropTypes.bool,
    mostrarGastosTotais: PropTypes.bool,
    addErrorNotification: PropTypes.func.isRequired
  };

  onViewDetalhes = item => {
    const entidade = item.get('entidade');
    const exercicio = item.get('exercicio');
    const empenho = item.get('empenho');
    const noLiquidacao = item.get('numeroLiquidacao') ?? '';
    const unidadeOrcamentaria = item.get('unidadeOrcamentaria') ?? '';

    this.props.history.push(
      `/diarias/detalhes?entidade=${entidade}&exercicio=${exercicio}&empenho=${empenho}&noLiquidacao=${noLiquidacao}&unidadeOrcamentaria=${unidadeOrcamentaria}`
    );
  };

  onExporta = (exportType, openTab, windowParams) => {
    const { apiURL } = configuration;
    const { filter, page, addErrorNotification } = this.props;

    if (page.get('content').size > 0) {
      let filtro = filter + '&exportType=' + exportType;

      window.open(
        `${apiURL}/api/diarias/report?${filtro}`,
        openTab,
        windowParams
      );
    } else {
      addErrorNotification(
        'A consulta não apresentou resultado. Não é possível exportar para o formato selecionado!'
      );
    }
  };

  renderDiarias = data => {
    const {
      mostrarSecretaria,
      mostrarCargo,
      mostrarValorMedioUnitario,
      mostrarGastosTotais
    } = this.props;
    const { entidade } = storageService.getItem('userInfo');
    let processo = `${data.get('numeroProcesso')}/${data.get('anoProcesso')}`;
    if (!data.get('numeroProcesso')) {
      processo = '';
    }

    const key =
      data.get('entidade') +
      '_' +
      data.get('exercicio') +
      '_' +
      data.get('empenho') +
      '_' +
      data.get('numeroLiquidacao') +
      '_' +
      data.get('unidadeOrcamentaria');

    return (
      <TableActions
        ref="row"
        key={key}
        data={data}
        onMore={this.onViewDetalhes}
      >
        {entidade && entidade.id <= 0 && (
          <td className="center">{data.get('entidade')}</td>
        )}
        <td id="diariaEmpenho">
          <LinkToEmpenho
            entidade={data.get('entidadeEmpenho')}
            exercicio={data.get('exercicioEmpenho')}
            empenho={data.get('empenho')}
          />
        </td>
        <td>{data.get('nome')}</td>
        {mostrarCargo && <td>{data.get('cargo')}</td>}
        {mostrarCargo && <td>{data.get('matricula')}</td>}
        {mostrarSecretaria && <td>{data.get('secretaria')}</td>}
        <td>{DateUtils.formatDateTimeShort(data.get('dataSaida'))}</td>
        <td>{DateUtils.formatDateTimeShort(data.get('dataRetorno'))}</td>
        <td>{data.get('destino')}</td>
        <td>{processo}</td>
        <td className="right">
          <NumberFormatter value={data.get('quantidadeDiarias')} />
        </td>
        {mostrarValorMedioUnitario && (
          <td className="right" id="taValorUnitario">
            <NumberFormatter value={data.get('valorUnitario')} />
          </td>
        )}
        <td className="right">
          <NumberFormatter value={data.get('valorEmpenho')} />
        </td>
        <td className="right">
          <NumberFormatter value={data.get('valorAnulado')} />
        </td>
        {mostrarGastosTotais && (
          <td className="right" id="taGastosTotais">
            <NumberFormatter value={data.get('gastosTotais')} />
          </td>
        )}
      </TableActions>
    );
  };

  render() {
    const {
      totalDiarias,
      page,
      searchWithPage,
      mostrarSecretaria,
      mostrarCargo,
      mostrarValorMedioUnitario,
      mostrarGastosTotais
    } = this.props;
    const { entidade } = storageService.getItem('userInfo');
    if (!page || (page && !page.get('content'))) {
      return null;
    }

    const diarias = page.get('content');

    return (
      <Panel isForm expansible={false} tableResponsive>
        <Row>
          <Col xs={6}>
            <UltimaAtualizacaoByTable
              modulo="3"
              table="diaria"
              column="dataultimaatualizacao"
            />
          </Col>
          <Col xs={6}>
            <ExportButtonGroup onClick={this.onExporta} visible={true} />
          </Col>
        </Row>

        <div className="panel-table-outer">
          <table className="panel-table striped fancy">
            <TableHeader empty={diarias.isEmpty()}>
              <tr>
                {entidade && entidade.id <= 0 && (
                  <TableHeaderItem
                    field="entidade"
                    label="Entidade"
                    page={page}
                    searchWithPage={searchWithPage}
                  />
                )}
                <TableHeaderItem
                  field="empenho"
                  label="Empenho"
                  page={page}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="nome"
                  label="Nome"
                  page={page}
                  searchWithPage={searchWithPage}
                />
                {mostrarCargo && (
                  <TableHeaderItem
                    field="cargo"
                    label="Cargo"
                    page={page}
                    searchWithPage={searchWithPage}
                  />
                )}
                {mostrarCargo && (
                  <TableHeaderItem
                    field="matricula"
                    label="Matrícula"
                    page={page}
                    searchWithPage={searchWithPage}
                  />
                )}
                {mostrarSecretaria && (
                  <TableHeaderItem
                    field="secretaria"
                    label="Secretaria"
                    page={page}
                    searchWithPage={searchWithPage}
                  />
                )}
                <TableHeaderItem
                  field="dataSaida"
                  label="Data Saída"
                  page={page}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="dataRetorno"
                  label="Data Retorno"
                  page={page}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="destino"
                  label="Destino / UF"
                  page={page}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="numeroProcesso"
                  label="Processo Adm."
                  page={page}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="quantidadeDiarias"
                  label="Qtd. Diárias"
                  page={page}
                  searchWithPage={searchWithPage}
                  alignment="right"
                />
                {mostrarValorMedioUnitario && (
                  <TableHeaderItem
                    field="valorDiarias"
                    label="Valor Médio ou Unitário"
                    page={page}
                    searchWithPage={searchWithPage}
                    alignment="right"
                  />
                )}

                <TableHeaderItem
                  field="valorTotal"
                  label="Valor Empenhado"
                  page={page}
                  searchWithPage={searchWithPage}
                  alignment="right"
                />

                <th className="right" id="thValorAnulado">
                  Valor Anulado
                </th>
                {mostrarGastosTotais && (
                  <TableHeaderItem
                    id="thGastosTotais"
                    field="gastosTotais"
                    label="Gastos Totais"
                    page={page}
                    searchWithPage={searchWithPage}
                    alignment="right"
                  />
                )}
                <th />
              </tr>
            </TableHeader>
            <tbody>{diarias.map(this.renderDiarias)}</tbody>

            {!diarias.isEmpty() && (
              <tfoot>
                <tr>
                  <td />
                  <td />
                  {mostrarCargo && <td />}
                  {mostrarCargo && <td />}
                  {mostrarSecretaria && <td />}
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  {mostrarValorMedioUnitario && <td />}
                  <td className="right">
                    <i>Total</i>
                    <br />
                    <NumberFormatter value={totalDiarias.get('valorDiarias')} />
                  </td>
                  <td className="right">
                    <i>Total Anulação</i>
                    <br />
                    <NumberFormatter value={totalDiarias.get('valorAnulado')} />
                  </td>
                  <td />
                  {mostrarGastosTotais && <td />}
                </tr>
              </tfoot>
            )}
          </table>
        </div>
      </Panel>
    );
  }
}

function mapStateToProps(state) {}

const allActions = {
  ...actions,
  addErrorNotification
};

export default connect(mapStateToProps, allActions)(DiariaListagem);
