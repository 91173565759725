export const EMENDA_PARLAMENTAR = 'EMENDA_PARLAMENTAR';
export const EMENDA_PARLAMENTAR_START = 'EMENDA_PARLAMENTAR_START';
export const EMENDA_PARLAMENTAR_SUCCESS = 'EMENDA_PARLAMENTAR_SUCCESS';
export const EMENDA_PARLAMENTAR_ERROR = 'EMENDA_PARLAMENTAR_ERROR';

export const EMENDAS = 'EMENDAS';
export const EMENDAS_START = 'EMENDAS_START';
export const EMENDAS_SUCCESS = 'EMENDAS_SUCCESS';
export const EMENDAS_ERROR = 'EMENDAS_ERROR';

export const CONSULTA_EMENDAS = 'CONSULTA_EMENDAS';
export const CONSULTA_EMENDAS_START = 'CONSULTA_EMENDAS_START';
export const CONSULTA_EMENDAS_SUCCESS = 'CONSULTA_EMENDAS_SUCCESS';
export const CONSULTA_EMENDAS_ERROR = 'CONSULTA_EMENDAS_ERROR';

export const RESET_CONSULTA_EMENDAS = 'RESET_CONSULTA_EMENDAS';

export const FORMULARIO_SOLICITACAO_NOTIFICACAO_RESET =
  'FORMULARIO_SOLICITACAO_NOTIFICACAO_RESET';

export const CONVENIO = 'CONVENIO';
export const CONVENIO_START = 'CONVENIO_START';
export const CONVENIO_SUCCESS = 'CONVENIO_SUCCESS';
export const CONVENIO_ERROR = 'CONVENIO_ERROR';
