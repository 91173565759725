const RESPONSAVEL_TIPO = {
  ASSINANTE: 'ASSINANTE',
  TESTEMUNHA: 'TESTEMUNHA',
  FISCAL: 'FISCAL',
  RECEBIMENTO_DEFINITIVO: 'RECEBIMENTO_DEFINITIVO',
  RECEBIMENTO_PARCIAL: 'RECEBIMENTO_PARCIAL',
  GESTOR: 'GESTOR',
  CONTROLADOR: 'CONTROLADOR'
};

const RESPONSAVEL_TIPO_FORMATTED = {
  [RESPONSAVEL_TIPO.ASSINANTE]: 'Assinante',
  [RESPONSAVEL_TIPO.TESTEMUNHA]: 'Testemunha',
  [RESPONSAVEL_TIPO.FISCAL]: 'Fiscal',
  [RESPONSAVEL_TIPO.RECEBIMENTO_DEFINITIVO]: 'Recebimento Definitivo',
  [RESPONSAVEL_TIPO.RECEBIMENTO_PARCIAL]: 'Recebimento Parcial',
  [RESPONSAVEL_TIPO.GESTOR]: 'Gestor',
  [RESPONSAVEL_TIPO.CONTROLADOR]: 'Controlador'
};

const tipoOrdem = [
  RESPONSAVEL_TIPO.ASSINANTE,
  RESPONSAVEL_TIPO.TESTEMUNHA,
  RESPONSAVEL_TIPO.CONTROLADOR,
  RESPONSAVEL_TIPO.FISCAL,
  RESPONSAVEL_TIPO.GESTOR
];

const ContratoResponsavelTipoUtils = {
  TIPO: RESPONSAVEL_TIPO,
  format: tipo => (tipo && RESPONSAVEL_TIPO_FORMATTED[tipo]) || 'Não Informado',
  order: tipo => tipoOrdem.indexOf(tipo)
};

export default ContratoResponsavelTipoUtils;
