import Component from 'react-pure-render/component';
import PropTypes from 'prop-types';
import React from 'react';

export default class InputError extends Component {
  static propTypes = {
    field: PropTypes.string.isRequired,
    errors: PropTypes.object
  };

  static hasError(field, errors) {
    const message = errors && errors[field];

    if (Array.isArray(message)) {
      return message.length > 0;
    }

    return !!message;
  }

  static createError(field, errors) {
    if (InputError.hasError(field, errors)) {
      return {
        className: 'error',
        hasError: 'has-error',
        help: <InputError field={field} errors={errors} />,
        message: errors[field]
      };
    }
    return {};
  }

  render() {
    const { field, errors } = this.props;

    if (InputError.hasError(field, errors)) {
      return <span className="help-message">{errors[field]}</span>;
    }
    return null;
  }
}
