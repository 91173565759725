import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react.js';
import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export class FuncaoGratificada extends Component {
  static propTypes = {
    funcaoGratificada: PropTypes.object.isRequired
  };

  renderValor(funcaoGratificada) {
    const BASE_DE_CALCULO = 'B';

    if (funcaoGratificada.get('tipoRemuneracao') == BASE_DE_CALCULO) {
      return 'Base de Cálculo';
    } else {
      return <NumberFormatter value={funcaoGratificada.get('valor')} />;
    }
  }

  render() {
    const { funcaoGratificada } = this.props;

    return (
      <TableActions
        ref="row"
        key={funcaoGratificada.get('funcao')}
        data={funcaoGratificada}
      >
        <td>{funcaoGratificada.get('descricao')}</td>
        <td>{funcaoGratificada.get('vagas')}</td>
        <td>{funcaoGratificada.get('ocupadas')}</td>
        <td className="right">{this.renderValor(funcaoGratificada)}</td>
      </TableActions>
    );
  }
}

export default FuncaoGratificada;
