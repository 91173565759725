import DisplayData from 'portaltransparencia-common/components/displaydata/DisplayData.react.js';
import DisplayDataItem from 'portaltransparencia-common/components/displaydata/DisplayDataItem.react.js';
import DisplayDataRow from 'portaltransparencia-common/components/displaydata/DisplayDataRow.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import { getAllValuesFromUrlSearchParams } from 'portaltransparencia-common/libs/URLUtils';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import { downloadArquivo } from '../arquivo/Actions.js';
import PageHeader from '../components/PageHeader.react.js';
import { hasArquivo } from '../licitacoes/Arquivo';
import * as actions from './Actions.js';

export class FormLicitacaoParticipantesDetalhe extends Component {
  static propTypes = {
    participante: PropTypes.object.isRequired,
    findParticipanteDetalhes: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    downloadArquivo: PropTypes.func.isRequired
  };

  componentDidMount() {
    const { location, findParticipanteDetalhes } = this.props;
    const params = getAllValuesFromUrlSearchParams(location);

    findParticipanteDetalhes(`pessoa=${params.pessoa}`);
  }

  renderLicitacoes = data => {
    const { downloadArquivo, participante } = this.props;
    const key = data.get('pessoa') + '_' + data.get('licitacao');
    const showColArquivo = hasArquivo(participante.get('licitacoes'));

    return (
      <TableActions
        ref="row"
        key={key}
        data={data}
        onDownload={data.get('idArquivo') && downloadArquivo}
      >
        <td>{data.get('modalidade')}</td>
        <td>{`${data.get('licitacao')}/${data.get('exercicio')}`}</td>
        <td>{DateUtils.formatDateTimeShort(data.get('dataAbertura'))}</td>
        {showColArquivo && <td>{data.get('numeroContrato')}</td>}
      </TableActions>
    );
  };

  render() {
    const { participante, location } = this.props;
    let showColArquivo;
    if (participante && participante.get('licitacoes')) {
      showColArquivo = hasArquivo(participante.get('licitacoes'));
    }

    return (
      <section>
        <PageHeader location={location} />

        <Panel title={'Participante ' + participante.get('razaoSocial')}>
          <DisplayData border>
            <DisplayDataRow>
              <DisplayDataItem
                sm={6}
                title="Nome Fantasia"
                value={participante.get('nomeFantasia')}
                id="nomeFantasia"
              />
              <DisplayDataItem
                sm={4}
                title="CNPJ/CPF"
                value={participante.get('cnpj')}
                id="cnpj"
              />
            </DisplayDataRow>
            <DisplayDataRow>
              <DisplayDataItem
                sm={6}
                title="Razão Social"
                value={participante.get('razaoSocial')}
                id="razaoSocial"
              />
            </DisplayDataRow>
          </DisplayData>
        </Panel>
        {participante && participante.get('licitacoes') && (
          <Panel isForm tableResponsive expansible={false}>
            <div className="panel-table-outer">
              <table className="panel-table striped fancy">
                <TableHeader
                  empty={participante.get('licitacoes').isEmpty()}
                  text="Sem registros"
                >
                  <tr>
                    <th>Modalidade</th>
                    <th>Licitação</th>
                    <th>Data</th>
                    {showColArquivo && <th>Contrato</th>}
                    <th />
                  </tr>
                </TableHeader>
                <tbody>
                  {participante.get('licitacoes').map(this.renderLicitacoes)}
                </tbody>
              </table>
            </div>
          </Panel>
        )}
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    participante: state.licitacoesParticipantes.get('participante')
  };
}

const allActions = {
  ...actions,
  downloadArquivo
};

export default connect(
  mapStateToProps,
  allActions
)(FormLicitacaoParticipantesDetalhe);
