// Path can be string, array, or function for lazy path definition.
export function normalizePath(path) {
  switch (typeof path) {
    case 'string':
      return [path];
    default:
      return path;
  }
}

export function moveInArray(arr, old_index, new_index) {
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr;
}
