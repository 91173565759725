import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import * as actions from './Actions.js';

export class FormUltimaAtualizacao extends Component {
  static propTypes = {
    modulo: PropTypes.string.isRequired,
    tipo: PropTypes.string,
    ultimaAtualizacao: PropTypes.object.isRequired,
    findUltimaAtualizacao: PropTypes.func.isRequired,
    findUltimaAtualizacaoLicitacao: PropTypes.func.isRequired,
    findUltimaAtualizacaoContrato: PropTypes.func.isRequired,
    findUltimaAtualizacaoPatrimonio: PropTypes.func.isRequired,
    findUltimaAtualizacaoAlmoxarifado: PropTypes.func.isRequired,
    findUltimaAtualizacaoFrota: PropTypes.func.isRequired,
    findUltimaAtualizacaoObra: PropTypes.func.isRequired,
    findDataConsulta: PropTypes.func.isRequired
  };

  componentDidMount() {
    const {
      ultimaAtualizacao,
      tipo,
      modulo,
      findUltimaAtualizacaoLicitacao,
      findUltimaAtualizacaoContrato,
      findUltimaAtualizacao,
      findUltimaAtualizacaoPatrimonio,
      findUltimaAtualizacaoAlmoxarifado,
      findUltimaAtualizacaoFrota,
      findUltimaAtualizacaoObra
    } = this.props;

    if (!ultimaAtualizacao.has('ultimaAtualizacao')) {
      switch (tipo) {
        case 'licitacao':
          findUltimaAtualizacaoLicitacao();
          break;
        case 'contrato':
          findUltimaAtualizacaoContrato();
          break;
        default:
          switch (modulo) {
            case '7':
              findUltimaAtualizacaoPatrimonio();
              break;
            case '19':
              findUltimaAtualizacaoAlmoxarifado();
              break;
            case '8':
              findUltimaAtualizacaoFrota();
              break;
            case '25':
              findUltimaAtualizacaoObra();
              break;
            default:
              if (modulo !== undefined) {
                findUltimaAtualizacao();
              }
              break;
          }
      }
    }
  }

  ultimaAtualizacao = () => {
    const { tipo, modulo, ultimaAtualizacao, isIntegradoOxy } = this.props;

    let ultimaAtualizacaoPorModulo;

    if (isIntegradoOxy && modulo === '3') {
      ultimaAtualizacaoPorModulo = ultimaAtualizacao.find(
        u => u.get('modulo') === '53'
      );
    } else {
      ultimaAtualizacaoPorModulo = ultimaAtualizacao.find(
        u => u.get('modulo') === modulo
      );
    }

    if (tipo === undefined && modulo === '3') {
      return new Date();
    } else if (ultimaAtualizacaoPorModulo) {
      return ultimaAtualizacaoPorModulo.get('ultimoAcesso');
    }

    return null;
  };

  render() {
    const data = this.ultimaAtualizacao();

    if (!data) {
      return null;
    }

    return (
      <div className="last-updated">
        <i className="fa fa-clock-o"></i> Última atualização:{' '}
        {DateUtils.formatDateTimeShort(data) +
          ' às ' +
          DateUtils.formatTime(data)}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ultimaAtualizacao: state.ultimaAtualizacao.get('ultimaAtualizacao'),
    isIntegradoOxy: state.entidade.getIn([
      'configuracoesGerais',
      'isIntegradoOxy'
    ])
  };
}

export default connect(mapStateToProps, actions)(FormUltimaAtualizacao);
