export const PCAS = 'PCAS';
export const PCAS_START = 'PCAS_START';
export const PCAS_SUCCESS = 'PCAS_SUCCESS';
export const PCAS_ERROR = 'PCAS_ERROR';

export const RESET_CONSULTA_PCAS = 'RESET_CONSULTA_PCAS';

export const PCA = 'PCA';
export const PCA_START = 'PCA_START';
export const PCA_SUCCESS = 'PCA_SUCCESS';
export const PCA_ERROR = 'PCA_ERROR';
