export const SUBVENCOES = 'SUBVENCOES';
export const SUBVENCOES_START = 'SUBVENCOES_START';
export const SUBVENCOES_SUCCESS = 'SUBVENCOES_SUCCESS';
export const SUBVENCOES_ERROR = 'SUBVENCOES_ERROR';

export const SUBVENCAO = 'SUBVENCAO';
export const SUBVENCAO_START = 'SUBVENCAO_START';
export const SUBVENCAO_SUCCESS = 'SUBVENCAO_SUCCESS';
export const SUBVENCAO_ERROR = 'SUBVENCAO_ERROR';

export const SUBVENCAO_VALORES_LIBERADOS = 'SUBVENCAO_VALORES_LIBERADOS';
export const SUBVENCAO_VALORES_LIBERADOS_START =
  'SUBVENCAO_VALORES_LIBERADOS_START';
export const SUBVENCAO_VALORES_LIBERADOS_SUCCESS =
  'SUBVENCAO_VALORES_LIBERADOS_SUCCESS';
export const SUBVENCAO_VALORES_LIBERADOS_ERROR =
  'SUBVENCAO_VALORES_LIBERADOS_ERROR';

export const SUBVENCAO_PRESTACAO_CONTAS = 'SUBVENCAO_PRESTACAO_CONTAS';
export const SUBVENCAO_PRESTACAO_CONTAS_START =
  'SUBVENCAO_PRESTACAO_CONTAS_START';
export const SUBVENCAO_PRESTACAO_CONTAS_SUCCESS =
  'SUBVENCAO_PRESTACAO_CONTAS_SUCCESS';
export const SUBVENCAO_PRESTACAO_CONTAS_ERROR =
  'SUBVENCAO_PRESTACAO_CONTAS_ERROR';

export const SUBVENCAO_ADITIVOS = 'SUBVENCAO_ADITIVOS';
export const SUBVENCAO_ADITIVOS_START = 'SUBVENCAO_ADITIVOS_START';
export const SUBVENCAO_ADITIVOS_SUCCESS = 'SUBVENCAO_ADITIVOS_SUCCESS';
export const SUBVENCAO_ADITIVOS_ERROR = 'SUBVENCAO_ADITIVOS_ERROR';

export const SUBVENCAO_REMUNERACOES = 'SUBVENCAO_REMUNERACOES';
export const SUBVENCAO_REMUNERACOES_START = 'SUBVENCAO_REMUNERACOES_START';
export const SUBVENCAO_REMUNERACOES_SUCCESS = 'SUBVENCAO_REMUNERACOES_SUCCESS';
export const SUBVENCAO_REMUNERACOES_ERROR = 'SUBVENCAO_REMUNERACOES_ERROR';

export const SUBVENCAO_ANEXOS = 'SUBVENCAO_ANEXOS';
export const SUBVENCAO_ANEXOS_START = 'SUBVENCAO_ANEXOS_START';
export const SUBVENCAO_ANEXOS_SUCCESS = 'SUBVENCAO_ANEXOS_SUCCESS';
export const SUBVENCAO_ANEXOS_ERROR = 'SUBVENCAO_ANEXOS_ERROR';

export const TIPO_SUBVENCAO = 'TIPO_SUBVENCAO';
export const TIPO_SUBVENCAO_START = 'TIPO_SUBVENCAO_START';
export const TIPO_SUBVENCAO_SUCCESS = 'TIPO_SUBVENCAO_SUCCESS';
export const TIPO_SUBVENCAO_ERROR = 'TIPO_SUBVENCAO_ERROR';

export const CERTIDAO_ANEXOS = 'CERTIDAO_ANEXOS';
export const CERTIDAO_ANEXOS_START = 'CERTIDAO_ANEXOS_START';
export const CERTIDAO_ANEXOS_SUCCESS = 'CERTIDAO_ANEXOS_SUCCESS';
export const CERTIDAO_ANEXOS_ERROR = 'CERTIDAO_ANEXOS_ERROR';

export const SUBVENCAO_ARQUIVO_SIT = 'SUBVENCAO_ARQUIVO_SIT';
export const SUBVENCAO_ARQUIVO_SIT_START = 'SUBVENCAO_ARQUIVO_SIT_START';
export const SUBVENCAO_ARQUIVO_SIT_SUCCESS = 'SUBVENCAO_ARQUIVO_SIT_SUCCESS';
export const SUBVENCAO_ARQUIVO_SIT_ERROR = 'SUBVENCAO_ARQUIVO_SIT_ERROR';
