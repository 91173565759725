import AuthService from 'portaltransparencia-common/auth/AuthService.js';
import Component from 'react-pure-render/component';

export default class RedirectAdminPage extends Component {
  componentDidMount() {
    const admUrl = `${AuthService.getHost()}/admportaltransparencia/`;
    window.location.href = admUrl;
  }

  render() {
    return null;
  }
}
