import { Map } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import { HotKeys } from 'react-hotkeys';
import { connect } from 'react-redux';

import Base from '../components/Base.react.js';
import { getHandlers, getKeyMap } from '../hotkeys/HotKeysConfig';

export class FormMenu extends React.Component {
  static propTypes = {
    menu: PropTypes.object.isRequired
  };

  renderSubMenu = data => {
    return Map({
      id: data.get('id'),
      image: data.get('image'),
      title: data.get('title'),
      route: data.get('route'),
      subtitle: data.get('subtitle'),
      link: data.get('link'),
      submenus: data.get('subMenu').map(this.renderMenu),
      urlAuxiliar: data.get('urlAuxiliar')
    });
  };
  renderMenu = data => {
    if (data.get('subMenu').size == 0) {
      return Map({
        id: data.get('id'),
        route: data.get('route'),
        title: data.get('title'),
        image: data.get('image'),
        link: data.get('link'),
        subtitle: data.get('subtitle'),
        urlAuxiliar: data.get('urlAuxiliar')
      });
    } else {
      return this.renderSubMenu(data);
    }
  };

  render() {
    const { menu, history, ...rest } = this.props;
    return (
      <HotKeys keyMap={getKeyMap(menu)} handlers={getHandlers(menu, history)}>
        <Base {...rest} menu={menu.map(this.renderMenu)} />
      </HotKeys>
    );
  }
}

function mapStateToProps(state) {
  return {
    menu: state.menu.get('menu')
  };
}

export default connect(mapStateToProps)(FormMenu);
