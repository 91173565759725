import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';

import * as types from './Types.js';

const initialState = fromJS({
  convenios: [],
  convenio: {},
  aditivos: [],
  arquivos: [],
  filtros: [],
  situacoes: [],
  repasses: [],
  contrapartidapagamentos: [],
  contrapartidarepasses: [],
  contrapartidadevolucao: [],
  pagamentos: []
});

function convenioReducer(state = initialState, action) {
  switch (action.type) {
    case types.CONVENIOS_START:
    case types.CONVENIO_START:
    case types.ADITIVOS_CONVENIO_START:
    case types.ARQUIVOS_CONVENIO_START:
    case types.SITUACOES_CONVENIO_START:
    case types.REPASSES_CONVENIO_START:
    case types.CONTRAPARTIDA_PAGAMENTO_START:
    case types.PAGAMENTO_CONVENIO_START:
    case types.CONTRAPARTIDA_REPASSES_START:
    case types.CONTRAPARTIDA_DEVOLUCAO_START: {
      return startLoad(state);
    }

    case types.CONVENIOS_ERROR:
    case types.CONVENIO_ERROR:
    case types.ADITIVOS_CONVENIO_ERROR:
    case types.ARQUIVOS_CONVENIO_ERROR:
    case types.SITUACOES_CONVENIO_ERROR:
    case types.REPASSES_CONVENIO_ERROR:
    case types.CONTRAPARTIDA_PAGAMENTO_ERROR:
    case types.PAGAMENTO_CONVENIO_ERROR:
    case types.CONTRAPARTIDA_REPASSES_ERROR:
    case types.CONTRAPARTIDA_DEVOLUCAO_ERROR: {
      return endLoad(state);
    }

    case types.CONVENIOS_SUCCESS: {
      return endLoad(state.set('convenios', fromJS(action.payload)));
    }
    case types.CONVENIO_SUCCESS: {
      return endLoad(state.set('convenio', fromJS(action.payload)));
    }
    case types.ADITIVOS_CONVENIO_SUCCESS: {
      return endLoad(state.set('aditivos', fromJS(action.payload)));
    }
    case types.ARQUIVOS_CONVENIO_SUCCESS: {
      return endLoad(state.set('arquivos', fromJS(action.payload)));
    }
    case types.SITUACOES_CONVENIO_SUCCESS: {
      return endLoad(state.set('situacoes', fromJS(action.payload)));
    }
    case types.REPASSES_CONVENIO_SUCCESS: {
      return endLoad(state.set('repasses', fromJS(action.payload)));
    }
    case types.PAGAMENTO_CONVENIO_SUCCESS: {
      return endLoad(state.set('pagamentos', fromJS(action.payload)));
    }
    case types.CONTRAPARTIDA_REPASSES_SUCCESS: {
      return endLoad(
        state.set('contrapartidarepasses', fromJS(action.payload))
      );
    }
    case types.CONTRAPARTIDA_DEVOLUCAO_SUCCESS: {
      return endLoad(
        state.set('contrapartidadevolucao', fromJS(action.payload))
      );
    }
    case types.CONTRAPARTIDA_PAGAMENTO_SUCCESS: {
      return endLoad(
        state.set('contrapartidapagamentos', fromJS(action.payload))
      );
    }

    case types.RESET_CONSULTA_CONVENIOS: {
      return initialState;
    }
  }

  return state;
}

export default convenioReducer;
