import StorageService from 'portaltransparencia-common/libs/StorageService.js';

import * as types from './Types.js';

export function findAfastamentos(filter) {
  const { entidade } = StorageService.getItem('userInfo');
  const baseFilter = `entidade=${entidade.id}&`;

  return ({ fetch }) => ({
    type: types.AFASTAMENTOS_LIST,
    payload: {
      promise: fetch(`/api/afastamentos?${baseFilter}${filter}`)
    }
  });
}

export function loadTiposAfastamento() {
  return ({ fetch }) => ({
    type: types.TIPOSAFASTAMENTO_LIST,
    payload: {
      promise: fetch('/api/afastamentos/tiposAfastamento')
    }
  });
}
