import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import PropTypes from 'prop-types';
import React from 'react';
import SlideShowItem from './SlideShowItem.react.js';
import Slider from 'react-slick';
import { fromJS } from 'immutable';

class SlideShow extends React.PureComponent {
  static propTypes = {
    images: PropTypes.object.isRequired,
    className: PropTypes.any
  };

  render() {
    const { images, className } = this.props;
    const settings = {
      customPaging: function (i) {
        let item = fromJS(images.toJS()[i]);
        return (
          <a>
            <SlideShowItem
              image={item}
              key={item.get('id')}
              isThumbnail={true}
            />
          </a>
        );
      },
      dots: true,
      dotsClass: 'slick-dots slick-thumb',
      infinite: false,
      adaptiveHeight: true,
      speed: 500,
      slideWidth: 300,
      slidesToShow: 1,
      slidesToScroll: 1,
      className
    };
    return (
      <Slider {...settings}>
        {images.map(item => {
          return (
            <SlideShowItem
              image={item}
              key={item.get('id')}
              isThumbnail={false}
            />
          );
        })}
      </Slider>
    );
  }
}

export default SlideShow;
