import * as types from './Types.js';

export function findContratosPorLicitacao(
  entidade,
  exercicio,
  tipoLicitacao,
  licitacao
) {
  return ({ fetch }) => ({
    type: types.CONTRATOS_LICITACAO,
    payload: {
      promise: fetch(
        `/api/contratos/licitacao?entidade=${entidade}&exercicioLicitacao=${exercicio}&tipoLicitacao=${tipoLicitacao}&numeroLicitacao=${licitacao}`
      )
    }
  });
}

export function findAditivos(entidade, exercicio, contrato, tipoAto) {
  return ({ fetch }) => ({
    type: types.ADITIVOS,
    payload: {
      promise: fetch(
        `/api/aditivos?entidade=${entidade}&exercicio=${exercicio}&contrato=${contrato}&tipoAto=${tipoAto}`
      )
    }
  });
}

export function findResponsaveis(entidade, exercicio, contrato, tipoAto) {
  return ({ fetch }) => ({
    type: types.RESPONSAVEIS,
    payload: {
      promise: fetch(
        `/api/responsaveis/contrato?entidade=${entidade}&exercicio=${exercicio}&contrato=${contrato}&tipoAto=${tipoAto}`
      )
    }
  });
}

export function findContrato(entidade, exercicio, contrato, tipoAto) {
  return ({ fetch }) => ({
    type: types.CONTRATO,
    payload: {
      promise: fetch(
        `/api/contratos?entidade=${entidade}&exercicio=${exercicio}&contrato=${contrato}&tipoAto=${tipoAto}`
      )
    }
  });
}

export function searchContratos(filter) {
  let url = '/api/contratos?' + filter;

  return ({ fetch }) => ({
    type: types.CONSULTA_CONTRATOS,
    payload: {
      promise: fetch(url)
    }
  });
}

export function loadTiposContrato(uf) {
  const url = `/api/contratos/tipos?uf=${uf}`;
  return ({ fetch }) => ({
    type: types.TIPOS_CONTRATO,
    payload: {
      promise: fetch(url)
    }
  });
}

export function resetConsulta() {
  return {
    type: types.RESET_CONSULTA_CONTRATOS
  };
}

export function findArquivos(entidade, exercicio, contrato, tipoAto) {
  return ({ fetch }) => ({
    type: types.CONTRATO_ARQUIVOS,
    payload: {
      promise: fetch(
        `/api/contratos/arquivos?entidade=${entidade}&exercicio=${exercicio}&contrato=${contrato}&tipoAto=${tipoAto}`
      )
    }
  });
}

export function findLicitacao(entidade, exercicio, tipoLicitacao, licitacao) {
  return ({ fetch }) => ({
    type: types.LICITACAO,
    payload: {
      promise: fetch(
        `/api/licitacoes/${entidade}/${exercicio}/${tipoLicitacao}/${licitacao}`
      )
    }
  });
}
