import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';

import * as types from './Types.js';

const initialState = fromJS({
  movimentos: [],
  movimento: {}
});

function movimentoReducer(state = initialState, action) {
  switch (action.type) {
    case types.MOVIMENTOS_GARAGEM_FIND_START:
    case types.MOVIMENTO_GARAGEM_START: {
      return startLoad(state);
    }

    case types.MOVIMENTOS_GARAGEM_FIND_ERROR:
    case types.MOVIMENTO_GARAGEM_ERROR: {
      return endLoad(state);
    }

    case types.MOVIMENTOS_GARAGEM_FIND_SUCCESS: {
      return endLoad(state.set('movimentos', fromJS(action.payload)));
    }

    case types.MOVIMENTO_GARAGEM_SUCCESS: {
      return endLoad(state.set('movimento', fromJS(action.payload)));
    }
  }

  return state;
}
export default movimentoReducer;
