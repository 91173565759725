import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';

import * as types from './Types.js';

const initialState = fromJS({
  dadosAbertos: []
});

function dadosAbertosReducer(state = initialState, action) {
  switch (action.type) {
    case types.DADOS_ABERTOS_START: {
      return startLoad(state);
    }

    case types.DADOS_ABERTOS_ERROR: {
      return endLoad(state);
    }

    case types.DADOS_ABERTOS_SUCCESS: {
      return endLoad(state.set('dadosAbertos', fromJS(action.payload)));
    }
  }

  return state;
}

export default dadosAbertosReducer;
