import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

import PageHeader from '../components/PageHeader.react.js';

export class DadosAbertos extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    onClickPessoal: PropTypes.func
  };

  onClickPessoal = () => {
    this.props.history.push('/servidores');
  };

  onClickLicitações = () => {
    this.props.history.push('/licitacoes');
  };

  onClickContratos = () => {
    this.props.history.push('/contratos');
  };

  onClickConvênios = () => {
    this.props.history.push('/convenios');
  };

  onClickReceitas = () => {
    this.props.history.push('/receita');
  };

  onClickDespesas = () => {
    this.props.history.push('/despesa-detalhada');
  };

  render() {
    return (
      <section>
        <PageHeader location={this.props.location} />

        <div className="col-md-4">
          <div className="panel widget widget-same-height undefined">
            <span className="widget-icon">
              <em className="fa fa-user"></em>
            </span>
            <h2 className="panel-title">Pessoal</h2>
            <div className="panel-body">
              <div className="panel-body">
                <p className="widget-numeric-subtitle center">
                  Dados da folha de pagamento dos servidores.
                </p>
              </div>
            </div>
            <button onClick={this.onClickPessoal} className="btn">
              Visualizar
            </button>
          </div>
        </div>

        <div className="col-md-4">
          <div className="panel widget widget-same-height undefined">
            <span className="widget-icon">
              <em className="fa fa-files-o"></em>
            </span>
            <h2 className="panel-title">Licitações</h2>
            <div className="panel-body">
              <div className="panel-body">
                <p className="widget-numeric-subtitle center">
                  Dados das compras e contratações realizadas.
                </p>
              </div>
            </div>
            <button onClick={this.onClickLicitações} className="btn">
              Visualizar
            </button>
          </div>
        </div>

        <div className="col-md-4">
          <div className="panel widget widget-same-height undefined">
            <span className="widget-icon">
              <em className="fa fa-file-o"></em>
            </span>
            <h2 className="panel-title">Contratos</h2>
            <div className="panel-body">
              <div className="panel-body">
                <p className="widget-numeric-subtitle center">
                  Dados dos contratos firmados para aquisição de materiais ou
                  serviços.
                </p>
              </div>
            </div>
            <button onClick={this.onClickContratos} className="btn">
              Visualizar
            </button>
          </div>
        </div>

        <div className="col-md-4">
          <div className="panel widget widget-same-height undefined">
            <span className="widget-icon">
              <em className="fa fa-retweet"></em>
            </span>
            <h2 className="panel-title">Convênios</h2>
            <div className="panel-body">
              <div className="panel-body">
                <p className="widget-numeric-subtitle center">
                  Dados dos convênios celebrados com outros governos ou
                  entidades.
                </p>
              </div>
            </div>
            <button onClick={this.onClickConvênios} className="btn">
              Visualizar
            </button>
          </div>
        </div>

        <div className="col-md-4">
          <div className="panel widget widget-same-height undefined">
            <span className="widget-icon">
              <em className="fa fa-file-text"></em>
            </span>
            <h2 className="panel-title">Receitas</h2>
            <div className="panel-body">
              <div className="panel-body">
                <p className="widget-numeric-subtitle center">
                  Dados dos recursos financeiros recebidos.
                </p>
              </div>
            </div>
            <button onClick={this.onClickReceitas} className="btn">
              Visualizar
            </button>
          </div>
        </div>

        <div className="col-md-4">
          <div className="panel widget widget-same-height undefined">
            <span className="widget-icon">
              <em className="fa fa-file-text-o"></em>
            </span>
            <h2 className="panel-title">Despesas</h2>
            <div className="panel-body">
              <div className="panel-body">
                <p className="widget-numeric-subtitle center">
                  Dados dos dispêndios realizados para o funcionamento e a
                  manutenção dos serviços públicos.
                </p>
              </div>
            </div>
            <button onClick={this.onClickDespesas} className="btn">
              Visualizar
            </button>
          </div>
        </div>
      </section>
    );
  }
}

export default DadosAbertos;
