import * as types from './Types.js';

export function findCargosPaginado(filtro) {
  return ({ fetch }) => ({
    type: types.LIST_CARGOS,
    payload: {
      promise: fetch(`/api/cargos?${filtro}`)
    }
  });
}

export function findFaixas(filtro) {
  return ({ fetch }) => ({
    type: types.LIST_FAIXAS,
    payload: {
      promise: fetch(`/api/cargos/faixas?${filtro}`)
    }
  });
}

export function findCargosNaoPaginado(filtro, param) {
  return ({ fetch }) => ({
    type: types.FILTRO_CARGOS,
    payload: {
      promise: fetch(
        `/api/cargos/cargos?${filtro}&descricao=${
          param?.value ? param.value : ''
        }`
      )
    }
  });
}

export function findQuadrosSalarial(filtro, param) {
  return ({ fetch }) => ({
    type: types.FILTRO_QUADROS,
    payload: {
      promise: fetch(
        `/api/cargos/quadros?${filtro}&descricao=${
          param?.value ? param.value : ''
        }`
      )
    }
  });
}

export function findGruposOcupacional(filtro, param) {
  return ({ fetch }) => ({
    type: types.FILTRO_GRUPOS,
    payload: {
      promise: fetch(
        `/api/cargos/grupos?${filtro}&descricao=${
          param?.value ? param.value : ''
        }`
      )
    }
  });
}

export function findNiveis(filtro, param) {
  return ({ fetch }) => ({
    type: types.FILTRO_NIVEIS,
    payload: {
      promise: fetch(
        `/api/cargos/niveis?${filtro}&descricao=${
          param?.value ? param.value : ''
        }`
      )
    }
  });
}

export function findFuncoesGratificadasPaginado(filtro) {
  return ({ fetch }) => ({
    type: types.LIST_FUNCOES_GRATIFICADAS,
    payload: {
      promise: fetch(`/api/funcao-gratificada?${filtro}`)
    }
  });
}

export function loadCargos(filtro) {
  return ({ fetch }) => ({
    type: types.LIST_CARGOS_OXY,
    payload: {
      promise: fetch(`/api/cargos/busca-cargos?${filtro}`)
    }
  });
}

export function findTipoCargoSelect(filtro, param) {
  return ({ fetch }) => ({
    type: types.FILTRO_CARGOS,
    payload: {
      promise: fetch(`/api/cargos/tipo-cargo?&descricao=${param?.value || ''}`)
    }
  });
}

export function findVigenciaMatrizSalarialSelect(filtro) {
  return ({ fetch }) => ({
    type: types.LIST_MATRIZ_VIGENTE,
    payload: {
      promise: fetch(`/api/cargos/matrizes-vigencia?matriz=${filtro}`)
    }
  });
}

export function findMatrizes(filtro, param) {
  return ({ fetch }) => ({
    type: types.LIST_MATRIZES_SUCCESS,
    payload: {
      promise: fetch(
        `/api/cargos/matrizes?${filtro}&nomeMatrizSalarial=${
          param?.value || ''
        }`
      )
    }
  });
}

export function getMatrizVigente(filtro) {
  return ({ fetch }) => ({
    type: types.LIST_MATRIZ,
    payload: {
      promise: fetch(`/api/cargos/matriz-vigente?${filtro}`)
    }
  });
}

export function loadCargosReduzido(filtro, param) {
  return ({ fetch }) => ({
    type: types.LIST_CARGOS_REDUZIDO_OXY,
    payload: {
      promise: fetch(
        `/api/cargos/dados-cargos?${filtro}&descricao=${param?.value || ''}`
      )
    }
  });
}
