import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export default class EstagiarioRow extends Component {
  static propTypes = {
    estagiario: PropTypes.object.isRequired,
    usaWebService: PropTypes.bool.isRequired
  };

  onViewDetalhes = item => {
    this.props.history.push(
      `/estagiarios/detalhes?vinculo=${item.get('vinculo')}&codigo=${item.get(
        'codigo'
      )}`
    );
  };

  render() {
    const { estagiario, usaWebService } = this.props;
    const key = `${estagiario.get('entidade')}_${estagiario.get('codigo')}`;

    return (
      <TableActions
        ref="row"
        key={key}
        data={estagiario}
        onMore={this.onViewDetalhes}
      >
        <td id="tableNome">{estagiario.get('nome')}</td>
        <td id="tableCodigo">{estagiario.get('codigo')}</td>
        <td id="tableCargo">{estagiario.get('cargo')}</td>
        <td id="tableLotacao">{estagiario.get('lotacao')}</td>
        {!usaWebService && (
          <td id="tableInicioContrato">
            {DateUtils.formatDateTimeShort(estagiario.get('inicioContrato'))}
          </td>
        )}
        {!usaWebService && (
          <td id="tableTerminoContrato">
            {DateUtils.formatDateTimeShort(estagiario.get('terminoContrato'))}
          </td>
        )}
      </TableActions>
    );
  }
}
