import { List } from 'immutable';
import $ from 'jquery';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import { Link } from 'react-router-dom';

import MenuItem from './MenuItem.react.js';

export class Menu extends Component {
  static propTypes = {
    menu: PropTypes.instanceOf(List).isRequired,
    onClose: PropTypes.func.isRequired
  };

  topo = event => {
    $('.menu-itens').animate(
      {
        scrollTop: 0
      },
      1000
    );
  };

  render() {
    const { menu, onClose } = this.props;

    return (
      <ul className="menu-itens">
        <span className="menu-button-close" onClick={onClose}>
          <span />
          <span />
          <span />
        </span>

        <Link to="/" onClick={onClose}>
          <li className="item">
            <b className="father">
              <span className="father-inner">Início</span>
            </b>
          </li>
        </Link>

        {menu.map(menuItem => {
          return (
            <MenuItem
              menu={menuItem}
              key={`${menuItem.get('id')}_${menuItem.get(
                'route'
              )}_${menuItem.get('title')}`}
              father
              onClose={onClose}
            />
          );
        })}

        <li className="item">
          <b className="father">
            <span className="father-inner" onClick={this.topo}>
              Voltar ao Topo
            </span>
          </b>
        </li>
      </ul>
    );
  }
}

export default Menu;
