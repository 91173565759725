import DisplayData from 'portaltransparencia-common/components/displaydata/DisplayData.react.js';
import DisplayDataItem from 'portaltransparencia-common/components/displaydata/DisplayDataItem.react.js';
import DisplayDataRow from 'portaltransparencia-common/components/displaydata/DisplayDataRow.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import NumberUtils from 'portaltransparencia-common/libs/NumberUtils.js';
import { getAllValuesFromUrlSearchParams } from 'portaltransparencia-common/libs/URLUtils';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import PageHeader from '../components/PageHeader.react.js';
import * as actions from './Actions.js';

export class FormNotaExtraorcamentariaDetalhe extends Component {
  static propTypes = {
    notaExtra: PropTypes.object.isRequired,
    notaExtraItens: PropTypes.object.isRequired,
    findNotaExtraorcamentaria: PropTypes.func.isRequired,
    findNotaExtraorcamentariaItens: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired
  };

  componentDidMount() {
    const {
      location,
      findNotaExtraorcamentaria,
      findNotaExtraorcamentariaItens
    } = this.props;
    const params = getAllValuesFromUrlSearchParams(location);
    const filtro = `entidade=${params.entidade}&exercicio=${params.exercicio}&noExtraorcamentario=${params.noExtraorcamentario}`;
    findNotaExtraorcamentaria(filtro);
    findNotaExtraorcamentariaItens(filtro);
  }

  renderNotaExtra = data => {
    const key = `${data.get('noExtraorcamentario')}_${data.get(
      'ordem'
    )}_${data.get('item')}_${data.get('idNotaExtra')}`;

    return (
      <tr id={key}>
        <td>{data.get('item')}</td>
        <td>{data.get('especificacao')}</td>
        <td>{data.get('quantidade')}</td>
        <td>{data.get('unidadeItem')}</td>
        <td>{NumberUtils.format(data.get('valorUnitario'))}</td>
        <td>{NumberUtils.format(data.get('valorTotal'))}</td>
      </tr>
    );
  };

  render() {
    const { notaExtra, notaExtraItens, location } = this.props;

    if (!notaExtra) {
      return null;
    }

    return (
      <section>
        <PageHeader location={location} />

        <Panel
          title={`Nota Extraorçamentária ${notaExtra.get(
            'noExtraorcamentario'
          )}/${notaExtra.get('exercicio')}`}
        >
          <DisplayData border>
            <DisplayDataRow>
              <DisplayDataItem
                sm={6}
                title="Nº Nota Extraorçamentária"
                value={`${notaExtra.get('noExtraorcamentario')}/${notaExtra.get(
                  'exercicio'
                )}`}
                id="numeroNotaExtra"
              />
              <DisplayDataItem
                sm={4}
                title="Valor"
                value={NumberUtils.format(notaExtra.get('valor'))}
                id="valor"
              />
            </DisplayDataRow>
            <DisplayDataRow>
              <DisplayDataItem
                sm={6}
                title="Data"
                value={DateUtils.formatDateTimeShort(notaExtra.get('data'))}
                id="data"
              />
              <DisplayDataItem
                sm={6}
                title="Data Vencimento"
                value={DateUtils.formatDateTimeShort(
                  notaExtra.get('vencimento')
                )}
                id="dataVencimento"
              />
            </DisplayDataRow>
            <DisplayDataRow>
              <DisplayDataItem
                sm={4}
                title="CPF/CNPJ"
                value={notaExtra.get('cnpjCpf')}
                id="cnpjCpf"
              />
              <DisplayDataItem
                sm={8}
                title="Credor"
                value={`${notaExtra.get('fornecedor')} - ${notaExtra.get(
                  'nomeFornecedor'
                )}`}
                id="credor"
              />
            </DisplayDataRow>
            <DisplayDataRow>
              <DisplayDataItem
                sm={12}
                title="Histórico"
                value={notaExtra.get('historico')}
                id="historico"
              />
            </DisplayDataRow>
            <DisplayDataRow>
              <DisplayDataItem
                sm={12}
                title="Complemento"
                value={notaExtra.get('complemento')}
                id="complemento"
              />
            </DisplayDataRow>
            <DisplayDataRow>
              <DisplayDataItem
                sm={12}
                title="Fonte Recurso"
                value={`${notaExtra.get('fonteRecurso')} - ${notaExtra.get(
                  'descricaoFonte'
                )}`}
                id="fonteRecurso"
              />
            </DisplayDataRow>
          </DisplayData>
        </Panel>
        {notaExtraItens && (
          <Panel isForm tableResponsive expansible={false}>
            <div className="panel-table-outer">
              <table className="panel-table striped fancy">
                <TableHeader
                  empty={notaExtraItens.isEmpty()}
                  text="Sem registros"
                >
                  <tr>
                    <th>Item</th>
                    <th>Espeicificação</th>
                    <th>Quantidade</th>
                    <th>Unidade</th>
                    <th>Valor Unitário</th>
                    <th>Sub Total</th>
                  </tr>
                </TableHeader>
                <tbody>{notaExtraItens.map(this.renderNotaExtra)}</tbody>
              </table>
            </div>
          </Panel>
        )}
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    notaExtra: state.notaExtraorcamentaria.get('notaExtra'),
    notaExtraItens: state.notaExtraorcamentaria.get('notaExtraItens')
  };
}

export default connect(
  mapStateToProps,
  actions
)(FormNotaExtraorcamentariaDetalhe);
