import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';

import * as types from './Types.js';

const initialState = fromJS({
  aditivo: {},
  arquivos: []
});

function aditivoReducer(state = initialState, action) {
  switch (action.type) {
    case types.ADITIVO_ARQUIVOS_START:
    case types.ADITIVO_START: {
      return startLoad(state);
    }

    case types.ADITIVO_ARQUIVOS_ERROR:
    case types.ADITIVO_ERROR: {
      return endLoad(state);
    }

    case types.ADITIVO_SUCCESS: {
      return endLoad(state.set('aditivo', fromJS(action.payload)));
    }
    case types.ADITIVO_ARQUIVOS_SUCCESS: {
      return endLoad(state.set('arquivos', fromJS(action.payload)));
    }
  }

  return state;
}

export default aditivoReducer;
