import Panel from 'portaltransparencia-common/components/Panel.react.js';
import { addHttp } from 'portaltransparencia-common/libs/LinkExterno.js';
import NumberUtils from 'portaltransparencia-common/libs/NumberUtils';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

/* eslint-disable react/no-danger*/

export default class FormOrganograma extends Component {
  static propTypes = {
    organograma: PropTypes.object,
    exercicios: PropTypes.object,
    downloadArquivo: PropTypes.func.isRequired
  };

  onDownloadEstrutura = idArquivo => {
    return () => {
      const { downloadArquivo } = this.props;

      downloadArquivo(idArquivo);
    };
  };

  render() {
    const { organograma } = this.props;

    const tamanho =
      (organograma.get('arquivo') &&
        NumberUtils.formatBytes(
          organograma.getIn(['arquivo', 'tamanhoArquivo'])
        )) ||
      '';

    return (
      <Panel insidePanel title={organograma.get('descricao')} collapsed>
        <div key={organograma.get('id')}>
          <div
            dangerouslySetInnerHTML={{ __html: organograma.get('resumo') }}
          />
          {!organograma.get('resumo') && (
            <div>Nenhuma informação no momento.</div>
          )}

          {!organograma.get('link') && organograma.get('arquivo') && (
            <button
              type="button"
              className="btn neutral icon-left mt-xs"
              onClick={this.onDownloadEstrutura(
                organograma.getIn(['arquivo', 'eloArquivo', 'id'])
              )}
            >
              <i className="fa fa-download"></i>
              {`Baixar ${tamanho}`}
            </button>
          )}
          {organograma.get('link') && (
            <a
              href={addHttp(organograma.get('link'))}
              target="_blank"
              className="btn neutral icon-left mt-xs"
            >
              <i className="fa fa-external-link"></i>
              Abrir Link
            </a>
          )}
        </div>
      </Panel>
    );
  }
}
