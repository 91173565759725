import Widget from 'portaltransparencia-common/components/Widget.react.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils';
import PropTypes from 'prop-types';
import React from 'react';
import { Chart } from 'react-google-charts';
import Component from 'react-pure-render/component';

export class ChartReceita extends Component {
  static propTypes = {
    valorMes: PropTypes.object.isRequired,
    title: PropTypes.string
  };

  getDataChartBar = data => {
    const dataChart = [['Mês', 'Arrecadado']];

    if (data.isEmpty()) {
      return false;
    }

    return dataChart.concat(
      data
        .map(item => {
          return [
            DateUtils.getMonthDescription(item.get('mes'), true),
            item.get('valorArrecadado')
          ];
        })
        .toJS()
    );
  };

  render() {
    const { valorMes, title } = this.props;

    const dataChart = this.getDataChartBar(valorMes);

    const options = {
      bar: { groupWidth: '90%' },
      legend: { position: 'top' },
      width: '100%',
      height: 300
    };

    return (
      <Widget
        icon="fa fa-bar-chart"
        title={title}
        isFixed
        alturaTopo={103}
        isChart
      >
        {dataChart && (
          <Chart
            ref="chart"
            chartType="ColumnChart"
            data={dataChart}
            legend_toggle
            graphID="dashboard-bar-chart"
            options={options}
            width={`${options.width}px`}
          />
        )}
        {!dataChart && 'O gráfico não encontrou os dados'}
      </Widget>
    );
  }
}

export default ChartReceita;
