import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

import ComprasDiretasItem from './ComprasDiretasItem.react.js';

export default class ComprasDiretasItensTBodyList extends Component {
  static propTypes = {
    compras: PropTypes.object.isRequired,
    onView: PropTypes.func
  };

  generateKey = compra => {
    return `${compra.get('entidade')}
        _${compra.get('exercicio')}
        _${compra.get('empenho')}
        _${compra.get('unidadeOrcamentaria')}
        _${compra.get('ordem')}`;
  };

  renderCompra = compra => {
    const { onView } = this.props;

    return (
      <ComprasDiretasItem
        key={this.generateKey(compra)}
        compra={compra}
        onView={onView}
        history={this.props.history}
      />
    );
  };

  render() {
    const { compras } = this.props;

    if (!compras || compras.isEmpty()) {
      return null;
    }

    return <tbody id="items">{compras.map(this.renderCompra)}</tbody>;
  }
}
