import Panel from 'portaltransparencia-common/components/Panel.react.js';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import { PARAM_LGPD_LINK } from 'portaltransparencia-common/parametros/ParametrosPortalTransparencia';
import PropTypes, { string } from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import { downloadArquivo } from '../arquivo/Actions.js';
import PageHeader from '../components/PageHeader.react.js';
import NotasExplicativas from '../notaexplicativa/Form.react';
import ParametroService from '../parametros/ParametroService.js';
import * as actions from './Actions.js';

export class FormLeisLGPD extends Component {
  static propTypes = {
    leilgpd: PropTypes.object.isRequired,
    arquivoLGPD: PropTypes.object.isRequired,
    leiLGPD: PropTypes.object.isRequired,
    findArquivoLGPD: PropTypes.func.isRequired,
    downloadArquivo: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    responsavelLGPD: PropTypes.object,
    paramLinkLGPD: PropTypes.string,
    findResponsavelLGPD: PropTypes.func.isRequired,
    Arquivo: PropTypes.string
  };

  componentDidMount() {
    const { findArquivoLGPD, findResponsavelLGPD } = this.props;
    const { entidade, exercicio } = StorageService.getItem('userInfo');

    findArquivoLGPD(entidade.id, exercicio.id);
    findResponsavelLGPD();
  }

  openArquivoLGPD = event => {
    event && event.preventDefault();
    const { downloadArquivo, arquivoLGPD } = this.props;
    downloadArquivo(arquivoLGPD.get('id'));
  };

  render() {
    const { arquivoLGPD, paramLinkLGPD, responsavelLGPD } = this.props;
    const Arquivo = new String(arquivoLGPD.get('nome')).replace(/.[^/.]+$/, '');

    return (
      <section>
        <PageHeader location={this.props.location} />

        <NotasExplicativas pathname={this.props.location.pathname} />

        <Panel expansible={false} isForm>
          <Row>
            <Col sm={6} className="mt-xs">
              <h2 className="text-subtitle">
                <b>Lei nº 13.709/2018 - LGPD</b>
              </h2>
              Estabelece o tratamento de dados pessoais, inclusive nos meios
              digitais, por pessoa natural ou por pessoa jurídica de direito
              público ou privado, com o objetivo de proteger os direitos
              fundamentais de liberdade e de privacidade e o livre
              desenvolvimento da personalidade da pessoa natural.
              <br />
              <hr className="mt-xs mb-xs" />
              <h2 className="text-subtitle">
                <b>Lei nº 14.129/2021 - Governo Digital</b>
              </h2>
              Princípios, regras e instrumentos do Governo Digitalo para o
              aumento da eficiência da administração pública, especialmente por
              meio da desburocratização, da inovação, da transformação digital e
              da participação do cidadão.
              <br />
            </Col>
            <Col sm={6} className="mt-xs">
              <a
                href="https://www.planalto.gov.br/ccivil_03/_ato2015-2018/2018/lei/l13709.htm"
                target="_blank"
              >
                Lei nº 13.709/2018 - LGPD
              </a>
              <br />
              <br />
              <a
                href="https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2021/lei/l14129.htm"
                target="_blank"
              >
                Lei nº 14.129/2021 - Governo Digital
              </a>
              <br />

              <br />
              <a href={paramLinkLGPD} target="_blank">
                Política de Privacidade e Proteção de Dados
              </a>
              <br />

              <br />
              {arquivoLGPD && arquivoLGPD.get('nome') && (
                <a
                  id="link-arquivolgpd"
                  href="#"
                  onClick={this.openArquivoLGPD}
                  target="_blank"
                >
                  {Arquivo}
                </a>
              )}

              {responsavelLGPD && responsavelLGPD.size > 0 && (
                <hr className="mt-xs mb-xs" />
              )}
              {responsavelLGPD && responsavelLGPD.size > 0 && (
                <div>
                  <b>Responsável pelo tratamento de dados pessoais </b> <br />
                  <br />
                  <div key={responsavelLGPD}>
                    Nome: {responsavelLGPD.get('nome')}
                    <br />
                    E-mail: {responsavelLGPD.get('email')}
                    <br />
                    Fone: {responsavelLGPD.get('tel')}
                    <br />
                    <br />
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Panel>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    leilgpd: state.leilgpd.get('leilgpd'),
    arquivoLGPD: state.leilgpd.get('arquivoLGPD'),
    paramLinkLGPD: ParametroService.getValorParametro(state, PARAM_LGPD_LINK),
    responsavelLGPD: state.leilgpd.get('responsavelLGPD')
  };
}

const allActions = {
  ...actions,
  downloadArquivo
};

export default connect(mapStateToProps, allActions)(FormLeisLGPD);
