import BotaoImprimir from 'portaltransparencia-common/components/BotaoImprimir.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import { getAllValuesFromUrlSearchParams } from 'portaltransparencia-common/libs/URLUtils';
import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import { downloadArquivo } from '../arquivo/Actions.js';
import Arquivo from '../arquivo/Arquivo.react.js';
import PageHeader from '../components/PageHeader.react.js';
import CabecalhoEntidade from '../entidades/CabecalhoEntidade.react.js';
import * as actions from './Actions.js';
import RecursoDetalheInformacao from './RecursoDetalheInformacao.react.js';
import RecursoDetalhePessoa from './RecursoDetalhePessoa.react.js';
import RecursoDetalheContrarrazao from './RecursoDetalheContrarrazao.react.js';
import RecursoDetalheParecer from './RecursoDetalheParecer.react.js';

export class RecursoDetalhe extends Component {
  static propTypes = {
    arquivos: PropTypes.object.isRequired,
    recurso: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    downloadArquivo: PropTypes.func.isRequired,
    findArquivos: PropTypes.func.isRequired,
    findRecurso: PropTypes.func.isRequired
  };

  componentDidMount() {
    const { location, findRecurso, findArquivos } = this.props;

    const { id } = getAllValuesFromUrlSearchParams(location);

    findRecurso(id).then(() => {
      findArquivos(id);
    });
  }

  render() {
    const { recurso, downloadArquivo, arquivos } = this.props;

    return (
      <div>
        <Helmet title={'Recurso'} />

        <BotaoImprimir />

        <PageHeader
          location={this.props.location}
          textoDetalhe={`Recurso: ${recurso.get('licitacao')}/${recurso.get(
            'exercicio'
          )}`}
        />

        <CabecalhoEntidade />

        <RecursoDetalheInformacao recurso={recurso} />

        {recurso?.get('pessoasCitadas')?.length > 0 && (
          <Panel isTable tableResponsive title="Pessoas Citadas">
            <RecursoDetalhePessoa
              recursoPessoas={recurso.get('pessoasCitadas')}
            />
          </Panel>
        )}

        {recurso?.get('contrarrazoes')?.length > 0 && (
          <Panel isTable tableResponsive title="Contrarrazões">
            <RecursoDetalheContrarrazao
              recursoContrarrazoes={recurso.get('contrarrazoes')}
            />
          </Panel>
        )}

        {recurso?.get('pareceres')?.length > 0 && (
          <Panel isTable tableResponsive title="Julgamentos">
            <RecursoDetalheParecer
              recursoPareceres={recurso.get('pareceres')}
            />
          </Panel>
        )}

        <Panel isTable tableResponsive title="Anexos">
          <Arquivo arquivos={arquivos} onDownload={downloadArquivo} />
        </Panel>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    recurso: state.recurso.get('recurso'),
    arquivos: state.recurso.get('arquivos')
  };
}

const allActions = {
  ...actions,
  downloadArquivo
};

export default connect(mapStateToProps, allActions)(RecursoDetalhe);
