export const TERCEIRIZADOS = 'TERCEIRIZADOS';
export const TERCEIRIZADOS_START = 'TERCEIRIZADOS_START';
export const TERCEIRIZADOS_SUCCESS = 'TERCEIRIZADOS_SUCCESS';
export const TERCEIRIZADOS_ERROR = 'TERCEIRIZADOS_ERROR';

export const CONSULTA_TERCEIRIZADOS = 'CONSULTA_TERCEIRIZADOS';
export const CONSULTA_TERCEIRIZADOS_START = 'CONSULTA_TERCEIRIZADOS_START';
export const CONSULTA_TERCEIRIZADOS_SUCCESS = 'CONSULTA_TERCEIRIZADOS_SUCCESS';
export const CONSULTA_TERCEIRIZADOS_ERROR = 'CONSULTA_TERCEIRIZADOS_ERROR';

export const RESET_CONSULTA_TERCEIRIZADOS = 'RESET_CONSULTA_TERCEIRIZADOS';

export const FORMULARIO_SOLICITACAO_NOTIFICACAO_RESET =
  'FORMULARIO_SOLICITACAO_NOTIFICACAO_RESET';
