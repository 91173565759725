import * as types from './Types.js';

export function searchAcordos(filter) {
  let url = '/api/acordos-sem-recurso';

  if (filter !== '') {
    url += '?' + filter;
  }

  return ({ fetch }) => ({
    type: types.CONSULTA_ACORDOS,
    payload: {
      promise: fetch(url)
    }
  });
}

export function resetConsulta() {
  return {
    type: types.RESET_CONSULTA_ACORDOS,
    payload: {}
  };
}

export function findAcordo(id) {
  return ({ fetch }) => ({
    type: types.ACORDO_SEM_RECURSO,
    payload: {
      promise: fetch(`/api/acordos-sem-recurso/${id}`)
    }
  });
}

export function reset() {
  return {
    type: types.FORMULARIO_SOLICITACAO_NOTIFICACAO_RESET
  };
}
