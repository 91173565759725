import * as actions from './Actions.js';

import { Col, Row } from 'react-bootstrap';
import {
  addFilterUrl,
  addPagination
} from 'portaltransparencia-common/libs/AddFilter.js';
import {
  onChange,
  resetCrud
} from 'portaltransparencia-common/crud/Actions.js';

import Component from 'react-pure-render/component';
import DatePicker from 'portaltransparencia-common/components/DatePicker.react.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import FilterButtons from '../components/FilterButtons.react';
import Input from 'portaltransparencia-common/components/EloInput.react.js';
import { Map } from 'immutable';
import NotasExplicativas from '../notaexplicativa/Form.react';
import PageHeader from '../components/PageHeader.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import PatrimonioTable from './PatrimonioTable.react.js';
import PropTypes from 'prop-types';
import React from 'react';
import SearchPagination from 'portaltransparencia-common/components/SearchPagination.react.js';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import { connect } from 'react-redux';
import { findStatusIntegracaoOxy } from '../status-integracao/Actions';
import { tiposPatrimonio } from './TiposPatrimonio';
import { addErrorNotification } from 'portaltransparencia-common/ui/Actions.js';
import configuration from 'portaltransparencia-common/config/Config.js';

export class FormPatrimonio extends Component {
  static propTypes = {
    page: PropTypes.object.isRequired,
    searchPatrimonios: PropTypes.func.isRequired,
    incorporacoes: PropTypes.object.isRequired,
    findIncorporacoes: PropTypes.func.isRequired,
    findDesincorporacoes: PropTypes.func.isRequired,
    filtros: PropTypes.object,
    onChange: PropTypes.func,
    location: PropTypes.object.isRequired,
    resetCrud: PropTypes.func.isRequired,
    valorParametro: PropTypes.string,
    findStatusIntegracaoOxy: PropTypes.func.isRequired,
    addErrorNotification: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = { isIntegradoComOxy: false };
  }

  componentDidMount() {
    const { findIncorporacoes, findDesincorporacoes, page } = this.props;

    this.verificaIntegracaoComOxy();

    findIncorporacoes().then(() => this.searchWithPage(page));
    findDesincorporacoes().then(() => this.searchWithPage(page));
  }

  verificaIntegracaoComOxy = () => {
    const { findStatusIntegracaoOxy } = this.props;

    findStatusIntegracaoOxy().then(({ value }) =>
      this.setState({ isIntegradoComOxy: value })
    );
  };

  onChangeFiltro = event => {
    const { onChange } = this.props;

    onChange('filtroPatrimonios', event);
  };

  onResetFiltro = () => {
    const { resetCrud, searchPatrimonios } = this.props;
    resetCrud('filtroPatrimonios');
    searchPatrimonios('');
  };

  onConsultaPatrimonios = () => {
    const { page } = this.props;

    const initialPage = page.set('number', 0);

    this.searchWithPage(initialPage);

    this.refs.panelFiltro && this.refs.panelFiltro.collapse();
  };

  buildRsql = function (page) {
    const { entidade, exercicio } = StorageService.getItem('userInfo');
    const { filtros } = this.props;
    const { isIntegradoComOxy } = this.state;

    let filter = 'entidade=' + entidade.id + '&exercicio=' + exercicio.id;

    if (filtros.get('chapa')) {
      filter = addFilterUrl(filter, `chapa=${filtros.get('chapa')}`);
    }

    // Comodato ainda não implementado no Oxy.
    if (filtros.get('comodato') && !isIntegradoComOxy) {
      filter = addFilterUrl(filter, `comodato=${filtros.get('comodato')}`);
    }

    if (filtros.get('descricao')) {
      filter = addFilterUrl(filter, `descricao=${filtros.get('descricao')}`);
    }
    if (filtros.get('incorporacao')) {
      const incorporacaoAttrFilter = isIntegradoComOxy
        ? 'idOperacaoOxy'
        : 'incorporacao';

      filter = addFilterUrl(
        filter,
        `${incorporacaoAttrFilter}=${filtros.get('incorporacao')}`
      );
    }
    if (filtros.get('desincorporacao')) {
      const incorporacaoAttrFilter = isIntegradoComOxy
        ? 'idOperacaoDesincorporacaoOxy'
        : 'desincorporacao';

      filter = addFilterUrl(
        filter,
        `${incorporacaoAttrFilter}=${filtros.get('desincorporacao')}`
      );
    }
    if (filtros.get('valorAtualMaior')) {
      filter = addFilterUrl(
        filter,
        `valorInicial=${filtros.get('valorAtualMaior')}`
      );
    }
    if (filtros.get('valorAtualMenor')) {
      filter = addFilterUrl(
        filter,
        `valorFinal=${filtros.get('valorAtualMenor')}`
      );
    }

    if (
      filtros.get('dataBaixaInicial') &&
      filtros.get('dataBaixaInicial').length === 10
    ) {
      let validValue = DateUtils.transform(
        filtros.get('dataBaixaInicial'),
        'yy-mm-dd',
        'yy-mm-dd'
      );
      filter = addFilterUrl(filter, `dataBaixaInicial=${validValue}`);
    }
    if (
      filtros.get('dataBaixaFinal') &&
      filtros.get('dataBaixaFinal').length === 10
    ) {
      let validValue = DateUtils.transform(
        filtros.get('dataBaixaFinal'),
        'yy-mm-dd',
        'yy-mm-dd'
      );
      filter = addFilterUrl(filter, `dataBaixaFinal=${validValue}`);
    }

    if (
      filtros.get('dataInicial') &&
      filtros.get('dataInicial').length === 10
    ) {
      let validValue = DateUtils.transform(
        filtros.get('dataInicial'),
        'yy-mm-dd',
        'yy-mm-dd'
      );
      filter = addFilterUrl(filter, `dataAquisicaoInicial=${validValue}`);
    }
    if (filtros.get('dataFinal') && filtros.get('dataFinal').length === 10) {
      let validValue = DateUtils.transform(
        filtros.get('dataFinal'),
        'yy-mm-dd',
        'yy-mm-dd'
      );
      filter = addFilterUrl(filter, `dataAquisicaoFinal=${validValue}`);
    }

    if (filtros.get('tipo')) {
      filter = addFilterUrl(filter, `tipo=${filtros.get('tipo')}`);
    }
    if (filtros.get('baixado')) {
      filter = addFilterUrl(filter, `baixado=${filtros.get('baixado')}`);
    }

    return addPagination(page, filter);
  };

  searchWithPage = page => {
    const { searchPatrimonios } = this.props;

    const filter = this.buildRsql(page);
    searchPatrimonios(filter);
  };

  onExporta = (exportType, openTab, windowParams) => {
    const { apiURL } = configuration;
    const { entidade } = StorageService.getItem('userInfo');
    const { page, addErrorNotification } = this.props;

    if (page.get('content').size > 0) {
      const filter = this.buildRsql(page);
      let filtro = `entidade=${entidade.id}&${filter}&exportType=${exportType}`;

      window.open(
        `${apiURL}/api/patrimonios/report?${filtro}`,
        openTab,
        windowParams
      );
    } else {
      addErrorNotification(
        'A consulta não apresentou resultado. Não é possível exportar para o formato selecionado!'
      );
    }
  };

  renderIncorporacoes = data => {
    const { isIntegradoComOxy } = this.state;

    const idKey = isIntegradoComOxy ? data.get('idOxy') : data.get('id');

    return (
      <option key={idKey} value={idKey}>
        {data.get('descricao')}
      </option>
    );
  };

  renderDesincorporacoes = data => {
    const { isIntegradoComOxy } = this.state;

    const idKey = isIntegradoComOxy ? data.get('idOxy') : data.get('id');

    return (
      <option key={idKey} value={idKey}>
        {data.get('descricao')}
      </option>
    );
  };

  renderTiposPatrimonio = tipo => {
    const { isIntegradoComOxy } = this.state;

    const idKey = isIntegradoComOxy ? tipo.get('oxyValue') : tipo.get('sigla');

    return (
      <option key={idKey} value={idKey}>
        {tipo.get('descricao')}
      </option>
    );
  };

  render() {
    const { page, incorporacoes, desincorporacoes, filtros } = this.props;
    const { isIntegradoComOxy } = this.state;

    if (!page.has('content')) {
      return null;
    }

    return (
      <section>
        <PageHeader location={this.props.location} />

        <NotasExplicativas pathname={this.props.location.pathname} />

        <Panel
          title="Consultar em Patrimônio"
          isForm
          mobileClose
          ref="panelFiltro"
        >
          <form>
            <Row>
              <Col xs={3} sm={2} md={2}>
                <Input
                  id="chapa"
                  name="chapa"
                  ref="chapa"
                  placeholder="Tombamento"
                  label="Tombamento"
                  type="text"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('chapa')}
                />
              </Col>
              <Col xs={9} sm={10} md={7}>
                <Input
                  id="descricao"
                  name="descricao"
                  ref="descricao"
                  placeholder="Descrição"
                  label="Descrição"
                  type="text"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('descricao')}
                />
              </Col>
              <Col xs={12} sm={4} md={3}>
                <Input
                  id="tipo"
                  name="tipo"
                  ref="tipo"
                  label="Tipo Patrimônio"
                  type="select"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('tipo')}
                >
                  <option value="">Selecione</option>
                  {tiposPatrimonio.map(this.renderTiposPatrimonio)}
                </Input>
              </Col>
            </Row>
            <Row>
              <Col xs={6} sm={3} md={3}>
                <Input
                  id="valorAtualMaior"
                  type="number"
                  ref="valorAtualMaior"
                  name="valorAtualMaior"
                  placeholder="Valor maior que"
                  label="Valor maior que"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('valorAtualMaior')}
                />
              </Col>
              <Col xs={6} sm={3} md={3}>
                <Input
                  id="valorAtualMenor"
                  type="number"
                  ref="valorAtualMenor"
                  name="valorAtualMenor"
                  placeholder="Valor menor que"
                  label="Valor menor que"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('valorAtualMenor')}
                />
              </Col>

              <Col xs={4} sm={3} md={3}>
                <Input
                  id="incorporacao"
                  name="incorporacao"
                  ref="incorporacao"
                  label="Tipo Incorporação"
                  type="select"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('incorporacao')}
                >
                  <option value="">Selecione</option>
                  {incorporacoes.map(this.renderIncorporacoes)}
                </Input>
              </Col>
              <Col xs={4} sm={3} md={3}>
                <Input
                  id="desincorporacao"
                  name="desincorporacao"
                  ref="desincorporacao"
                  label="Tipo Desincorporação"
                  type="select"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('desincorporacao')}
                >
                  <option value="">Selecione</option>
                  {desincorporacoes.map(this.renderDesincorporacoes)}
                </Input>
              </Col>
              {!isIntegradoComOxy && (
                <div className="form-group">
                  <label className="label"></label>
                  <Col xs={8} sm={3} md={3}>
                    <Input
                      name="comodato"
                      label="Bens Cedidos/Comodato"
                      type="checkbox"
                      onChange={this.onChangeFiltro}
                      value={filtros.get('comodato')}
                    />
                  </Col>
                </div>
              )}
            </Row>
            <Row>
              <Col xs={6} sm={4} md={2}>
                <DatePicker
                  id="dataBaixaInicial"
                  name="dataBaixaInicial"
                  ref="dataBaixaInicial"
                  label="Data Baixa Inicial"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('dataBaixaInicial')}
                />
              </Col>
              <Col xs={6} sm={4} md={2}>
                <DatePicker
                  id="dataBaixaFinal"
                  name="dataBaixaFinal"
                  ref="dataBaixaFinal"
                  label="Data Baixa Final"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('dataBaixaFinal')}
                />
              </Col>
              <Col xs={6} sm={4} md={2}>
                <DatePicker
                  id="dataInicial"
                  name="dataInicial"
                  ref="dataInicial"
                  label="Data Aquisição Inicial"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('dataInicial')}
                />
              </Col>
              <Col xs={6} sm={4} md={2}>
                <DatePicker
                  id="dataFinal"
                  name="dataFinal"
                  ref="dataFinal"
                  label="Data Aquisição Final"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('dataFinal')}
                />
              </Col>
              <Col xs={6} sm={4} md={2}>
                <Input
                  id="baixado"
                  name="baixado"
                  ref="baixado"
                  label="Situação"
                  type="select"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('baixado')}
                >
                  <option value="">Selecione</option>
                  <option key={'N'} value={'N'}>
                    Ativo
                  </option>
                  <option key={'S'} value={'S'}>
                    Baixado
                  </option>
                </Input>
              </Col>
              <Col xs={12} sm={4} md={3}>
                <FilterButtons
                  onConsulta={this.onConsultaPatrimonios}
                  onReset={this.onResetFiltro}
                />
              </Col>
            </Row>
          </form>
        </Panel>

        <PatrimonioTable
          patrimoniosPage={page}
          history={this.props.history}
          filter={this.buildRsql(page.get('content'))}
          searchWithPage={this.searchWithPage}
          onExporta={this.onExporta}
        />

        <SearchPagination page={page} searchWithPage={this.searchWithPage} />
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    page: state.patrimonio.get('patrimonios'),
    incorporacoes: state.patrimonio.get('incorporacoes'),
    desincorporacoes: state.patrimonio.get('desincorporacoes'),
    filtros: state.crud.getIn(['filtroPatrimonios', 'currentRecord'], Map({}))
  };
}

const allActions = {
  ...actions,
  onChange,
  resetCrud,
  findStatusIntegracaoOxy,
  addErrorNotification
};

export default connect(mapStateToProps, allActions)(FormPatrimonio);
