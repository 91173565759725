const CPF = 11;
const CNPJ = 14;

const MaskUtils = {
  formatCPF(value) {
    if (value) {
      return value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4');
    }
    return value;
  },

  formatCNPJ(value) {
    if (value) {
      return value.replace(
        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
        '$1.$2.$3/$4-$5'
      );
    }
    return value;
  },

  format(value) {
    if (value) {
      if (value.length == CPF) {
        return this.formatCPF(value);
      } else if (value.length == CNPJ) {
        return this.formatCNPJ(value);
      }
    }
    return value;
  },

  formatTelefone(valor) {
    valor = valor.replace(/\D/g, '');
    valor = valor.replace(/^(\d{2})(\d)/g, '($1) $2');
    valor = valor.replace(/(\d)(\d{4})$/, '$1-$2');
    return valor;
  }
};

export default MaskUtils;
