import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

export class Version extends Component {
  static propTypes = {
    version: PropTypes.string.isRequired
  };

  static defaultProps = {
    version: 'Não encontrada.'
  };

  render() {
    const { version } = this.props;

    return (
      <div id="versionRoot">
        <i className="fa fa-certificate"></i>
        <label id="versionLabel"> Versão: {version}</label>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    version: state.info.getIn(['build', 'version'])
  };
}

export default connect(mapStateToProps)(Version);
