import $ from 'jquery';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import configuration from '../config/Config.js';
import { addSuccessNotification } from '../ui/Actions.js';

export class Accessibility extends Component {
  static propTypes = {
    habilitaPerguntas: PropTypes.bool,
    addSuccessNotification: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      scale: 1,
      zoom: 100
    };
  }

  componentDidMount() {
    //botões de acessibilidade mobile
    $('.accessibility-btns').click(function (event) {
      if (event.target === this) {
        $(this).toggleClass('active');
      }
    });

    $('.accessibility-btn.btn-reset').click(() => {
      this.setState(
        {
          scale: 1,
          zoom: 100
        },
        () => {
          const elements = $('.portal');
          elements.css('-moz-transform', '');
          elements.css('-moz-transform-origin', '');
          elements.css('zoom', '');
        }
      );
    });

    $('.accessibility-btn.btn-maximize').click(() => {
      if (this.state.zoom < 130) {
        this.setState(
          {
            scale: this.state.scale + 0.03,
            zoom: this.state.zoom + 5
          },
          this.applyZoom
        );
      }
    });

    $('.accessibility-btn.btn-minimize').click(() => {
      if (this.state.zoom > 90) {
        this.setState(
          {
            scale: this.state.scale - 0.03,
            zoom: this.state.zoom - 5
          },
          this.applyZoom
        );
      }
    });

    $('.accessibility-btn.btn-invert').click(function () {
      $('html').toggleClass('invert');
    });

    $('.accessibility-btn.btn-sign-language').click(function () {
      var openButton = $('#btnPluginVLibras');
      var wrapper = $('#wrapperPluginVLibras');
      var closeButton = $('.vpw-settings-btn-close');

      if (wrapper.hasClass('active')) {
        closeButton.click();
      } else {
        openButton.click();
      }
    });
  }

  toggleSpeechAcessibility = () => {
    const { addSuccessNotification } = this.props;
    const speech = localStorage.getItem('speech');
    const newValue = !speech || speech === 'N' ? 'S' : 'N';
    localStorage.setItem('speech', newValue);
    addSuccessNotification(
      `Conversor de texto para áudio ${
        newValue === 'S' ? 'ativado' : 'desativado'
      }!`
    );
  };

  applyZoom = () => {
    const { scale, zoom } = this.state;

    const elements = $('.portal');
    elements.css('-moz-transform', `scale(${scale})`);
    elements.css('-moz-transform-origin', 'top center');

    $('.portal').css('zoom', `${zoom}%`);
  };

  render() {
    const { apiURL } = configuration;
    const { habilitaPerguntas } = this.props;

    return (
      <div>
        <ul className="accessibility-btns">
          <Link to="/acessibilidade">
            <li className="accessibility-btn btn-access">
              <span className="accessibility-label">Acessibilidade</span>
            </li>
          </Link>
          <li className="accessibility-btn btn-maximize">
            <span className="accessibility-label">
              Aumentar o tamanho do texto
            </span>
          </li>
          <li className="accessibility-btn btn-minimize">
            <span className="accessibility-label">
              Diminuir o tamanho do texto
            </span>
          </li>
          <li className="accessibility-btn btn-reset">
            <span className="accessibility-label">
              Redefinir ao tamanho normal do texto
            </span>
          </li>
          <li className="accessibility-btn btn-invert">
            <span className="accessibility-label">
              Inverter as cores do Portal da Transparência
            </span>
          </li>
          <li className="accessibility-btn btn-sign-language">
            <span className="accessibility-label">
              Traduzir o texto para Libras
            </span>
          </li>
          <li
            className="accessibility-btn btn-reader"
            onClick={this.toggleSpeechAcessibility}
          >
            <span className="accessibility-label">
              Converter o texto em áudio
            </span>
          </li>

          <li className="accessibility-btn btn-help btn-help-opener">
            <span className="accessibility-label">
              <ul className="accessibility-label-inner">
                <li className="item">
                  <Link to="/mapa-do-site">
                    <em className="fa fa-map-o" />
                    Mapa do Site
                  </Link>
                </li>
                <li className="item">
                  <a href={`${apiURL}/api/files/ajuda`} target="_blank">
                    <em className="fa fa-file-text-o" />
                    Manual de Ajuda
                  </a>
                </li>
                {habilitaPerguntas && (
                  <li className="item">
                    <Link to="/perguntas">
                      <em className="fa fa-comment-o" />
                      Perguntas Frequentes
                    </Link>
                  </li>
                )}
                <li className="item">
                  <Link to="/teclas-de-atalho">
                    <em className="fa fa-bolt" />
                    Teclas de Atalho
                  </Link>
                </li>
                <li className="item helper-opener">
                  <b className="item-text">
                    <em className="fa  fa-object-ungroup" />
                    Como Usar o Portal
                  </b>
                </li>
                <li className="item">
                  <Link to="/glossario">
                    <em className="fa fa-book"></em>
                    Glossário
                  </Link>
                </li>
                <li className="item">
                  <a href={`${apiURL}/swagger-ui.html`} target="_blank">
                    <em className="fa fa-server" />
                    Dados Abertos (Web Services)
                  </a>
                </li>
              </ul>
            </span>
          </li>
        </ul>
      </div>
    );
  }
}
const allActions = {
  addSuccessNotification
};

export default connect(null, allActions)(Accessibility);
