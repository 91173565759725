export const LEIESIC = 'LEIESIC';
export const LEIESIC_START = 'LEIESIC_START';
export const LEIESIC_SUCCESS = 'LEIESIC_SUCCESS';
export const LEIESIC_ERROR = 'LEIESIC_ERROR';

export const RESPONSAVEL_LAI = 'RESPONSAVEL_LAI';
export const RESPONSAVEL_LAI_START = 'RESPONSAVEL_LAI_START';
export const RESPONSAVEL_LAI_SUCCESS = 'RESPONSAVEL_LAI_SUCCESS';
export const RESPONSAVEL_LAI_ERROR = 'RESPONSAVEL_LAI_ERROR';

export const INSTRUCAO_NORMATIVA = 'INSTRUCAO_NORMATIVA';
export const INSTRUCAO_NORMATIVA_START = 'INSTRUCAO_NORMATIVA_START';
export const INSTRUCAO_NORMATIVA_SUCCESS = 'INSTRUCAO_NORMATIVA_SUCCESS';
export const INSTRUCAO_NORMATIVA_ERROR = 'INSTRUCAO_NORMATIVA_ERROR';

export const LEI_ORGANICA_MUNICIPIO = 'LEI_ORGANICA_MUNICIPIO';
export const LEI_ORGANICA_MUNICIPIO_START = 'LEI_ORGANICA_MUNICIPIO_START';
export const LEI_ORGANICA_MUNICIPIO_SUCCESS = 'LEI_ORGANICA_MUNICIPIO_SUCCESS';
export const LEI_ORGANICA_MUNICIPIO_ERROR = 'LEI_ORGANICA_MUNICIPIO_ERROR';
