import NumberUtils from 'portaltransparencia-common/libs/NumberUtils.js';
import BotaoImprimir from 'portaltransparencia-common/components/BotaoImprimir.react.js';
import DisplayData from 'portaltransparencia-common/components/displaydata/DisplayData.react.js';
import DisplayDataItem from 'portaltransparencia-common/components/displaydata/DisplayDataItem.react.js';
import DisplayDataRow from 'portaltransparencia-common/components/displaydata/DisplayDataRow.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

class MovimentacaoFiltro extends Component {
  static propTypes = {
    movimentacoes: PropTypes.object.isRequired
  };

  render() {
    const { movimentacoes } = this.props;

    if (movimentacoes.isEmpty()) {
      return null;
    }

    const saldo = () => {
      let somaEntradas = 0.0;
      let somaSaidas = 0.0;
      movimentacoes.forEach(item => {
        item.get('tipo') === 'ENTRADA'
          ? (somaEntradas += item.get('quantidade'))
          : (somaSaidas += item.get('quantidade'));
      });
      return somaEntradas - somaSaidas;
    };

    const valorTotal = () => {
      let somaEntradas = 0.0;
      let somaSaidas = 0.0;
      movimentacoes.forEach(item => {
        item.get('tipo') === 'ENTRADA'
          ? (somaEntradas += item.get('valorTotal'))
          : (somaSaidas += item.get('valorTotal'));
      });
      return somaEntradas - somaSaidas;
    };

    const custoMedio = () => {
      if (saldo() === 0) {
        return 0;
      }
      return valorTotal() / saldo();
    };

    return (
      <section>
        <Panel title="Informações">
          <DisplayData border>
            <DisplayDataRow>
              <DisplayDataItem
                sm={6}
                title="Centro de Custo"
                value={`${movimentacoes
                  .first()
                  .get('centroCustoCodigo')} - ${movimentacoes
                  .first()
                  .get('centroCustoDescricao')}`}
              />
              <DisplayDataItem
                sm={6}
                title="Item"
                value={`${movimentacoes
                  .first()
                  .get('itemCodigo')} - ${movimentacoes
                  .first()
                  .get('itemDescricao')}`}
              />
            </DisplayDataRow>
            <DisplayDataRow>
              <DisplayDataItem
                sm={6}
                title="Unidade"
                value={movimentacoes.first().get('unidade')}
              />
              <DisplayDataItem
                sm={6}
                title="Saldo"
                value={NumberUtils.format(saldo(), '0,0.00')}
              />
            </DisplayDataRow>
            <DisplayDataRow>
              <DisplayDataItem
                sm={6}
                title="Custo Médio"
                value={NumberUtils.format(custoMedio(), 'R$ 0,0.00')}
              />
              <DisplayDataItem
                sm={6}
                title="Valor Total"
                value={NumberUtils.format(valorTotal(), 'R$ 0,0.00')}
              />
            </DisplayDataRow>
          </DisplayData>
        </Panel>

        <BotaoImprimir />
      </section>
    );
  }
}

export default MovimentacaoFiltro;
