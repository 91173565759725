import Panel from 'portaltransparencia-common/components/Panel.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import TreeTable from 'portaltransparencia-common/components/treetable/TreeTable.react';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import { getAllValuesFromUrlSearchParams } from 'portaltransparencia-common/libs/URLUtils';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import PageHeader from '../components/PageHeader.react.js';
import NotasExplicativas from '../notaexplicativa/Form.react';
import UltimaAtualizacaoByTable from '../ultimaatualizacao/UltimaAtualizacaoByTable.react.js';
import * as actions from './Actions.js';
import TableItem from './TableItem.js';
import configuration from 'portaltransparencia-common/config/Config.js';
import ExportButtonGroup from 'portaltransparencia-common/components/ExportButtonGroup.react.js';
import { addErrorNotification } from 'portaltransparencia-common/ui/Actions.js';

export class FormReceitaDetalhada extends Component {
  static propTypes = {
    findReceitas: PropTypes.func.isRequired,
    receitas: PropTypes.object.isRequired,
    expand: PropTypes.func.isRequired,
    collapse: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    addErrorNotification: PropTypes.func.isRequired
  };

  componentDidMount() {
    const { findReceitas } = this.props;

    findReceitas(this.montarFiltro());
  }

  onExporta = (exportType, openTab, windowParams) => {
    const { apiURL } = configuration;
    const { receitas, addErrorNotification } = this.props;

    if (!receitas.isEmpty()) {
      let filtro = this.montarFiltro() + '&exportType=' + exportType;

      window.open(
        `${apiURL}/api/receitas/detalhada/report?${filtro}`,
        openTab,
        windowParams
      );
    } else {
      addErrorNotification(
        'A consulta não apresentou resultado. Não é possível exportar para o formato selecionado!'
      );
    }
  };

  montarFiltro = () => {
    const { fonteRecurso } = getAllValuesFromUrlSearchParams(
      this.props.location
    );
    const { entidade, exercicio } = StorageService.getItem('userInfo');

    let fonte = '';
    if (fonteRecurso) {
      fonte = `&fonteRecurso=${fonteRecurso}`;
    }

    return `entidade=${entidade.id}&exercicio=${exercicio.id}${fonte}`;
  };

  renderItem = item => {
    const { expand, collapse } = this.props;

    return (
      <TableItem
        key={item.get('receita')}
        item={item}
        expand={expand}
        collapse={collapse}
      />
    );
  };

  render() {
    const { receitas } = this.props;

    const header = (
      <TableHeader empty={receitas.isEmpty()}>
        <tr>
          <th>Receita</th>
          <th>Descrição</th>
          <th className="right">Previsão Inicial</th>
          <th className="right">Previsão das Deduções</th>
          <th className="right">Previsão Atualizada</th>
          <th className="right">Arrecadação</th>
          <th className="right">Deduções</th>
          <th className="right">Arrecadação Líquida</th>
        </tr>
      </TableHeader>
    );

    return (
      <section>
        <PageHeader location={this.props.location} />

        <NotasExplicativas pathname={this.props.location.pathname} />

        <Panel isTable tableResponsive expansible={false}>
          <Row>
            <Col xs={6}>
              <UltimaAtualizacaoByTable
                modulo="3"
                table="receita"
                column="dataultimaatualizacao"
              />
            </Col>
            <Col xs={6}>
              <ExportButtonGroup onClick={this.onExporta} />
            </Col>
            <Col xs={6} />
          </Row>
          <TreeTable
            list={receitas}
            header={header}
            itemRenderer={this.renderItem}
          />
        </Panel>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    receitas: state.receitasDetalhe.get('receitas')
  };
}

const allActions = {
  ...actions,
  addErrorNotification
};

export default connect(mapStateToProps, allActions)(FormReceitaDetalhada);
