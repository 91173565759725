import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export default class EstagiarioRowOxy extends Component {
  static propTypes = {
    estagiario: PropTypes.object.isRequired
  };

  onViewDetalhes = item => {
    this.props.history.push(
      `/estagiarios/detalhes?vinculo=${item.get('vinculo')}&codigo=${item.get(
        'codigo'
      )}`
    );
  };

  render() {
    const { estagiario } = this.props;
    const key = `${estagiario.get('entidade')}_${estagiario.get('codigo')}`;

    return (
      <TableActions
        ref="row"
        key={key}
        data={estagiario}
        onMore={this.onViewDetalhes}
      >
        <td id="tableNome">{estagiario.get('nome')}</td>
        <td id="tableMatricula">{estagiario.get('matricula')}</td>
        <td id="tableSituacao">{estagiario.get('situacao')}</td>
        <td id="tableDescricaoCargo">{estagiario.get('descricaoCargo')}</td>
        <td id="tableDescricaoClasse">{estagiario.get('descricaoClasse')}</td>
        <td id="tableDescricaoLotacao">{estagiario.get('descricaoLotacao')}</td>
      </TableActions>
    );
  }
}
