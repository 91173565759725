import DisplayData from 'portaltransparencia-common/components/displaydata/DisplayData.react.js';
import DisplayDataItem from 'portaltransparencia-common/components/displaydata/DisplayDataItem.react.js';
import DisplayDataRow from 'portaltransparencia-common/components/displaydata/DisplayDataRow.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export class HomologacaoDetalheInformacao extends Component {
  static propTypes = {
    homologacao: PropTypes.object.isRequired
  };

  render() {
    const { homologacao } = this.props;

    return (
      <Panel title={'Homologação'}>
        <DisplayData border>
          <DisplayDataRow>
            <DisplayDataItem
              sm={4}
              title="CNPJ/CPF"
              value={homologacao.get('cnpjCpf')}
              id="cnpjCpf"
            />
            <DisplayDataItem
              sm={4}
              title="Nome"
              value={homologacao.get('nome')}
              id="nome"
            />
            <DisplayDataItem
              sm={4}
              title="Data Homologação"
              value={DateUtils.transform(homologacao.get('data'))}
              id="data"
            />
          </DisplayDataRow>
        </DisplayData>
      </Panel>
    );
  }
}

export default HomologacaoDetalheInformacao;
