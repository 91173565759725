import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

const StyledSmall = styled.small`
  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const Ellipsis = ({
  value = '',
  initialShowMore = false,
  maxLength = 100
}) => {
  const newValue = `${value || ''}`;

  const [showMore, setShowMore] = useState(initialShowMore);

  const canExpand = useMemo(() => newValue.length > maxLength, [
    newValue,
    maxLength
  ]);

  return (
    <span style={{ 'word-break': 'break-word' }}>
      {canExpand && !showMore ? `${newValue.slice(0, maxLength)}...` : newValue}{' '}
      {canExpand && (
        <StyledSmall onClick={() => setShowMore(prev => !prev)}>
          {showMore ? 'Ver Menos' : 'Ver Mais'}
        </StyledSmall>
      )}
    </span>
  );
};
