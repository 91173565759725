import { Map } from 'immutable';
import DatePicker from 'portaltransparencia-common/components/DatePicker.react.js';
import Input from 'portaltransparencia-common/components/EloInput.react.js';
import ExportButtonGroup from 'portaltransparencia-common/components/ExportButtonGroup.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import SearchPagination from 'portaltransparencia-common/components/SearchPagination.react.js';
import configuration from 'portaltransparencia-common/config/Config.js';
import {
  onChange,
  resetCrud
} from 'portaltransparencia-common/crud/Actions.js';
import {
  addFilterUrl,
  addPagination
} from 'portaltransparencia-common/libs/AddFilter.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import { addErrorNotification } from 'portaltransparencia-common/ui/Actions.js';
import { downloadArquivo } from '../arquivo/Actions.js';
import FilterButtons from '../components/FilterButtons.react';
import PageHeader from '../components/PageHeader.react.js';
import { loadModalidades } from '../licitacoes/Actions.js';
import NotasExplicativas from '../notaexplicativa/Form.react';
import UltimaAtualizacao from '../ultimaatualizacao/Form.react.js';
import * as actions from './Actions.js';
import ContratoListagem from './ContratoListagem.react.js';

export class FormContrato extends Component {
  static propTypes = {
    page: PropTypes.object.isRequired,
    downloadArquivo: PropTypes.func.isRequired,
    searchContratos: PropTypes.func.isRequired,
    loadTiposContrato: PropTypes.func.isRequired,
    tiposContrato: PropTypes.object.isRequired,
    loadModalidades: PropTypes.func.isRequired,
    tiposLicitacao: PropTypes.object,
    filtros: PropTypes.object,
    onChange: PropTypes.func,
    location: PropTypes.object.isRequired,
    resetCrud: PropTypes.func.isRequired,
    uf: PropTypes.string,
    isIntegradoOxy: PropTypes.bool,
    addErrorNotification: PropTypes.func.isRequired
  };

  state = {
    carregouTiposContrato: false
  };

  async carregarTiposContratos() {
    const { uf, loadTiposContrato } = this.props;
    const { carregouTiposContrato } = this.state;
    if (carregouTiposContrato || !uf) {
      return;
    }
    this.setState({ carregouTiposContrato: true });
    return loadTiposContrato(uf);
  }

  componentDidMount() {
    const { loadModalidades, page, resetCrud } = this.props;
    resetCrud('filtroContratos');
    this.carregarTiposContratos().then(() => this.searchWithPage(page));
    loadModalidades();
    this.loadInitialFiltros();
  }

  componentDidUpdate(prevProps) {
    const { carregouTiposContrato } = this.state || {};
    const { uf: oldUf } = prevProps || {};
    const { uf, page } = this.props;
    if (!carregouTiposContrato || uf !== oldUf) {
      this.setState({ carregouTiposContrato: false });
      this.carregarTiposContratos().then(() => this.searchWithPage(page));
    }
  }

  loadInitialFiltros = () => {
    const { onChange } = this.props;
    const { exercicio } = StorageService.getItem('userInfo');

    onChange('filtroContratos', {
      name: 'exercicioContrato',
      value: `${exercicio.id}`
    });
  };

  onChangeFiltro = event => {
    const { onChange } = this.props;

    onChange('filtroContratos', event);
  };

  showRateio = () =>
    !(this.props.uf === 'PR') ||
    !this.props.tiposContrato?.filter(obj => obj.get('sigla') === 'RA').size;

  onResetFiltro = () => {
    const { resetCrud, searchContratos } = this.props;
    resetCrud('filtroContratos');
    let userInfo = StorageService.getItem('userInfo');
    const { entidade, exercicio } = userInfo;
    let filter = `entidade=${entidade.id}&exercicio=${exercicio.id}`;

    searchContratos(filter);
  };

  renderTiposContrato = data => {
    const { isIntegradoOxy } = this.props;
    const key = data.get('siglaTipoContrato');
    const value = data.get('tipoContrato');
    const descricao = data.get('tipoContrato');
    return (
      <option key={key} value={value}>
        {descricao}
      </option>
    );
  };

  renderTiposLicitacao = data => {
    return (
      <option key={data.get('id')} value={data.get('id')}>
        {data.get('id') + ' - ' + data.get('descricao')}
      </option>
    );
  };

  onConsultaContratos = event => {
    const { page } = this.props;

    const initialPage = page.set('number', 0);

    this.searchWithPage(initialPage);

    event && event.preventDefault();
    this.refs.panelFiltroContratos && this.refs.panelFiltroContratos.collapse();
  };

  searchWithPage = page => {
    const { searchContratos } = this.props;

    const filter = this.montaFiltro(page);

    searchContratos(filter);
  };

  addFilter = function (actualFilter, filterAnd) {
    let filterOperator = '';
    if (actualFilter !== '') {
      filterOperator = '&';
    }
    return actualFilter + filterOperator + filterAnd;
  };

  montaFiltro = function (page) {
    let userInfo = StorageService.getItem('userInfo');
    const { entidade, exercicio } = userInfo;
    const { filtros } = this.props;
    let filter = `entidade=${entidade.id}`;
    if (filtros.get('exercicioContrato')) {
      filter = this.addFilter(
        filter,
        `&exercicio=${filtros.get(
          'exercicioContrato'
        )}&exercicioContrato=${filtros.get('exercicioContrato')}`
      );
    } else {
      filter = this.addFilter(filter, `&exercicio=${exercicio.id}`);
    }
    if (this.refs.tipoContrato && this.refs.tipoContrato.getValue()) {
      filter = this.addFilter(
        filter,
        `tipoContrato=${this.refs.tipoContrato.getValue()}`
      );
    }
    if (this.refs.numeroContrato && this.refs.numeroContrato.getValue()) {
      filter = this.addFilter(
        filter,
        `numeroContrato=${this.refs.numeroContrato.getValue()}`
      );
    }
    if (this.refs.tipoAto && this.refs.tipoAto.getValue()) {
      filter = this.addFilter(
        filter,
        `tipoAto=${this.refs.tipoAto.getValue()}`
      );
    }
    if (this.refs.tipoLicitacao && this.refs.tipoLicitacao.getValue()) {
      filter = this.addFilter(
        filter,
        `tipoLicitacao=${this.refs.tipoLicitacao.getValue()}`
      );
    }
    if (this.refs.numeroLicitacao && this.refs.numeroLicitacao.getValue()) {
      filter = this.addFilter(
        filter,
        `numeroLicitacao=${this.refs.numeroLicitacao.getValue()}`
      );
    }
    if (this.refs.nome && this.refs.nome.getValue()) {
      filter = this.addFilter(filter, `nome=${this.refs.nome.getValue()}`);
    }
    if (
      filtros.get('dataInicialAssinatura') &&
      filtros.get('dataInicialAssinatura').length == 10
    ) {
      let validValue = DateUtils.transform(
        filtros.get('dataInicialAssinatura'),
        'yy-mm-dd',
        'yy-mm-dd'
      );
      filter = addFilterUrl(filter, `dataInicialAssinatura=${validValue}`);
    }
    if (
      filtros.get('dataFinalAssinatura') &&
      filtros.get('dataFinalAssinatura').length == 10
    ) {
      let validValue = DateUtils.transform(
        filtros.get('dataFinalAssinatura'),
        'yy-mm-dd',
        'yy-mm-dd'
      );
      filter = addFilterUrl(filter, `dataFinalAssinatura=${validValue}`);
    }
    if (filtros.get('rateio')) {
      filter = this.addFilter(
        filter,
        `rateio=${filtros.get('rateio') ? 'S' : 'N'}`
      );
    }

    if (filtros.get('exercicioLicitacao')) {
      filter = this.addFilter(
        filter,
        `exercicioLicitacao=${filtros.get('exercicioLicitacao')}`
      );
    }

    if (this.refs.objeto && this.refs.objeto.getValue()) {
      filter = this.addFilter(filter, `objeto=${this.refs.objeto.getValue()}`);
    }

    if (
      this.refs.situacaoNoExercicioSelecionado &&
      this.refs.situacaoNoExercicioSelecionado.getValue()
    ) {
      filter = this.addFilter(
        filter,
        `situacaoNoExercicioSelecionado=${this.refs.situacaoNoExercicioSelecionado.getValue()}`
      );
    }

    if (filtros.get('contratosVigentes')) {
      filter = this.addFilter(
        filter,
        `contratosVigentes=${filtros.get('contratosVigentes')}`
      );
    }

    return addPagination(page, filter);
  };

  onExporta = (exportType, openTab, windowParams) => {
    const { page, addErrorNotification } = this.props;
    const { apiURL } = configuration;

    if (page.get('content').size > 0) {
      const filter = this.montaFiltro(page);
      let filtro = filter + '&exportType=' + exportType;

      window.open(
        `${apiURL}/api/contratos/report?${filtro}`,
        openTab,
        windowParams
      );
    } else {
      addErrorNotification(
        'A consulta não apresentou resultado. Não é possível exportar para o formato selecionado!'
      );
    }
  };

  render() {
    const {
      tiposContrato,
      tiposLicitacao,
      downloadArquivo,
      page,
      filtros,
      location
    } = this.props;

    if (!page.has('content')) {
      return null;
    }

    const filter = this.montaFiltro(page);

    return (
      <section>
        <PageHeader location={this.props.location} />

        <NotasExplicativas pathname={this.props.location.pathname} />

        <Panel
          title="Consultar em Contratos / Atas"
          isForm
          ref="panelFiltroContratos"
        >
          <form>
            <Row>
              <Col xs={12} sm={3} md={3}>
                <Input
                  name="tipoAto"
                  ref="tipoAto"
                  label="Tipo ato"
                  type="select"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('tipoAto')}
                >
                  <option value="">Selecione</option>
                  <option key={'Contrato'} value={1}>
                    Contrato
                  </option>
                  <option key={'Ata de Registro de Preços'} value={2}>
                    Ata de Registro de Preços
                  </option>
                </Input>
              </Col>
              <Col xs={12} sm={3} md={3}>
                <Input
                  name="tipoContrato"
                  ref="tipoContrato"
                  label="Tipo do Contrato"
                  type="select"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('tipoContrato')}
                >
                  <option value="">Selecione</option>
                  {tiposContrato.map(this.renderTiposContrato)}
                </Input>
              </Col>
              <Col xs={12} sm={3} md={3}>
                <Input
                  type="number"
                  ref="numeroContrato"
                  name="numeroContrato"
                  placeholder="Número do Contrato"
                  label="Número do Contrato"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('numeroContrato')}
                />
              </Col>
              <Col xs={12} sm={3} md={3}>
                <Input
                  type="number"
                  name="exercicioContrato"
                  placeholder="Ano contrato"
                  label="Ano contrato"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('exercicioContrato')}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6} md={6}>
                <Input
                  type="text"
                  ref="nome"
                  name="nome"
                  placeholder="Nome do Contratado"
                  label="Nome do Contratado"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('nome')}
                />
              </Col>
              <Col xs={12} sm={6} md={6}>
                <Input
                  type="text"
                  ref="objeto"
                  name="objeto"
                  placeholder="Objeto"
                  label="Objeto"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('objeto')}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={4} md={4}>
                <Input
                  name="tipoLicitacao"
                  ref="tipoLicitacao"
                  label="Tipo da Licitação"
                  type="select"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('tipoLicitacao')}
                >
                  <option value="">Selecione</option>
                  {tiposLicitacao &&
                    tiposLicitacao.map(this.renderTiposLicitacao)}
                </Input>
              </Col>
              <Col xs={8} sm={4} md={2}>
                <Input
                  type="number"
                  ref="numeroLicitacao"
                  name="numeroLicitacao"
                  placeholder="Número da Licitação"
                  label="Número da Licitação"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('numeroLicitacao')}
                />
              </Col>
              <Col xs={8} sm={4} md={2}>
                <Input
                  type="number"
                  name="exercicioLicitacao"
                  placeholder="Ano licitação"
                  label="Ano licitação"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('exercicioLicitacao')}
                />
              </Col>
              <Col xs={8} sm={4} md={2}>
                <Input
                  name="situacaoNoExercicioSelecionado"
                  ref="situacaoNoExercicioSelecionado"
                  label="Situação no Ano Selecionado"
                  type="select"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('situacaoNoExercicioSelecionado')}
                >
                  <option value="">Selecione</option>
                  <option key={'Encerrado'} value={'Encerrado'}>
                    Encerrado
                  </option>
                  <option key={'Rescindido'} value={'Rescindido'}>
                    Rescindido
                  </option>
                  <option key={'Suspenso'} value={'Suspenso'}>
                    Suspenso
                  </option>
                  <option key={'Vigente'} value={'Vigente'}>
                    Vigente
                  </option>
                </Input>
              </Col>
              <Col xs={8} sm={4} md={2}>
                <label className="label"></label>
                <Input
                  label="Contratos Vigentes Atualmente"
                  type="checkbox"
                  name="contratosVigentes"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('contratosVigentes')}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={6} sm={3} md={2}>
                <DatePicker
                  id="dataInicialAssinatura"
                  name="dataInicialAssinatura"
                  ref="dataInicialAssinatura"
                  label="Data Início da Assinatura"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('dataInicialAssinatura')}
                />
              </Col>
              <Col xs={6} sm={3} md={2}>
                <DatePicker
                  id="dataFinalAssinatura"
                  name="dataFinalAssinatura"
                  ref="dataFinalAssinatura"
                  label="Data Fim da Assinatura"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('dataFinalAssinatura')}
                />
              </Col>
              {this.showRateio() && (
                <Col xs={12} sm={3} md={3}>
                  <label className="mb-xs label"> </label>
                  <Input
                    type="checkbox"
                    ref="rateio"
                    name="rateio"
                    placeholder="Contrato de Rateio"
                    label="Rateio"
                    onChange={this.onChangeFiltro}
                    value={filtros.get('rateio')}
                  />
                </Col>
              )}
              <Col xs={12} sm={3} md={3}>
                <FilterButtons
                  onConsulta={this.onConsultaContratos}
                  onReset={this.onResetFiltro}
                />
              </Col>
            </Row>
          </form>
        </Panel>

        <Panel isForm tableResponsive expansible={false}>
          <Row>
            <Col xs={6}>
              <UltimaAtualizacao modulo="10" tipo="contrato" />
            </Col>
            <Col xs={6}>
              <ExportButtonGroup onClick={this.onExporta} visible={true} />
            </Col>
          </Row>
          <ContratoListagem
            filter={filter}
            contratosPage={page}
            onDownload={downloadArquivo}
            searchWithPage={this.searchWithPage}
            pathname={location.pathname}
            history={this.props.history}
          />
        </Panel>

        <SearchPagination page={page} searchWithPage={this.searchWithPage} />
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    page: state.contrato.get('contratos'),
    uf: state.entidade.getIn(['entidadeSelecionada', 'uf']),
    tiposContrato: state.contrato.get('tiposContrato'),
    tiposLicitacao: state.licitacao.get('modalidades'),
    filtros: state.crud.getIn(['filtroContratos', 'currentRecord'], Map({})),
    isIntegradoOxy: state.entidade.getIn([
      'configuracoesGerais',
      'isIntegradoOxy'
    ])
  };
}

const allActions = {
  ...actions,
  loadModalidades,
  downloadArquivo,
  onChange,
  resetCrud,
  addErrorNotification
};

export default connect(mapStateToProps, allActions)(FormContrato);
