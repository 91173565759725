import PropTypes from 'prop-types';
import React from 'react';

export default class TableActionButton extends React.PureComponent {
  static propTypes = {
    data: PropTypes.any.isRequired,
    onClick: PropTypes.func,
    title: PropTypes.string,
    icon: PropTypes.string
  };

  onClick = () => {
    const { onClick, data } = this.props;

    onClick(data);
  };

  render() {
    const { title, icon } = this.props;

    return (
      <button
        type="button"
        className="btn"
        onClick={this.onClick}
        title={title}
      >
        <div className="btn-actions-label">{title}</div>
        <i className={icon}></i>
      </button>
    );
  }
}
