import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Tooltip from '../../../../node_modules/react-bootstrap/lib/Tooltip.js';
import DateUtils from '../../libs/DateUtils';
import { addHttp } from '../../libs/LinkExterno.js';
import NumberUtils from '../../libs/NumberUtils';
import Link from '../link/Link.react.js';
import TreeList from './TreeList.react.js';

class TreeItem extends React.PureComponent {
  static propTypes = {
    item: PropTypes.object,
    onClick: PropTypes.func.isRequired,
    expanded: PropTypes.bool
  };

  constructor(props) {
    super(props);

    this.state = {
      open: props.expanded || false
    };
  }

  onClickExpand = event => {
    const { open } = this.state;

    event.stopPropagation();

    this.setState({ open: !open });
  };

  onClickLink = item => {
    window.open(addHttp(item.get('link')));
  };

  render() {
    const { item, onClick, expanded } = this.props;
    const { open } = this.state;

    const isLeaf = !item.has('list');
    const hasItems = item.has('list') && !item.get('list').isEmpty();
    const emptyDirectory = !isLeaf && !hasItems;

    const classes = {
      'tree-list-item': true,
      open: open,
      'has-ul': hasItems
    };

    if (emptyDirectory) {
      return (
        <li className={classnames(classes)} onClick={this.onClickExpand}>
          <i>
            <label>{item.get('valor')} - Sem arquivos</label>
          </i>
        </li>
      );
    }

    let dataArquivo = '';
    if (item.get('dataArquivo')) {
      dataArquivo = DateUtils.transform(item.get('dataArquivo'));
    }

    return (
      <Tooltip title={item.get('resumo')} placement="top-start">
        <li className={classnames(classes)} onClick={this.onClickExpand}>
          {!isLeaf && (
            <label className="tree-list-label">{item.get('valor')}</label>
          )}

          {isLeaf && !item.get('link') && (
            <div>
              <Link
                value={item.get('valor')}
                icon="fa fa-download"
                data={item}
                onClick={onClick}
              />
              {NumberUtils.formatBytes(item.get('tamanhoArquivo'))}
              <em title="Data do Arquivo"> {dataArquivo}</em>
            </div>
          )}

          {isLeaf && item.get('link') && (
            <Link
              value={item.get('valor')}
              icon="fa fa-external-link"
              data={item}
              onClick={this.onClickLink}
            />
          )}

          {hasItems && (
            <TreeList
              list={item.get('list')}
              onClick={onClick}
              expanded={expanded}
            />
          )}
        </li>
      </Tooltip>
    );
  }
}

export default TreeItem;
