import * as uiActions from 'portaltransparencia-common/ui/Actions.js';

import Contato from '../contato/Form.react.js';
import FormSolicitacao from '../esic/FormSolicitacaoOffSide.react.js';
import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';

export class OffSidebarNotificacoes extends React.Component {
  static propTypes = {
    active: PropTypes.any.isRequired,
    offsidebarToggle: PropTypes.func,
    paramShowAcessoAInformacao: PropTypes.string,
    paramPermiteSolicitacaoAnonima: PropTypes.string
  };

  render() {
    const {
      active,
      offsidebarToggle,
      paramShowAcessoAInformacao,
      paramPermiteSolicitacaoAnonima
    } = this.props;

    const classNameModule = classnames('sidebar-esic', {
      active: active
    });

    return (
      <aside className={classNameModule}>
        <button
          className="close-esic btn module-color no-text"
          onClick={offsidebarToggle}
        >
          <em className="fa fa-arrow-right" />
        </button>
        <h2 className="title">
          <b>Acesso à Informação</b>
        </h2>

        <div key={paramShowAcessoAInformacao} className="sidebar-esic-content">
          {paramShowAcessoAInformacao == '1' && <Contato />}
          {paramShowAcessoAInformacao == '2' && (
            <FormSolicitacao
              paramPermiteSolicitacaoAnonima={paramPermiteSolicitacaoAnonima}
            />
          )}
        </div>
      </aside>
    );
  }
}

function mapStateToProps(state) {
  return {
    active: state.ui.offsidebarToggled
  };
}

export default connect(mapStateToProps, uiActions)(OffSidebarNotificacoes);
