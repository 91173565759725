import Panel from 'portaltransparencia-common/components/Panel.react.js';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import * as actions from './Actions.js';

export class FormContato extends Component {
  static propTypes = {
    contatos: PropTypes.object,
    findContatoResponsaveis: PropTypes.func,
    entidade: PropTypes.object,
    exercicio: PropTypes.object
  };

  componentDidMount() {
    const { findContatoResponsaveis } = this.props;
    const { entidade, exercicio } = StorageService.getItem('userInfo');

    if (exercicio && entidade) {
      findContatoResponsaveis(
        `entidade=${entidade.id}&exercicio=${exercicio.id}`
      );
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.entidade != this.props.entidade ||
      nextProps.exercicio != this.props.exercicio
    ) {
      nextProps.findContatoResponsaveis(
        `entidade=${nextProps.entidade.get(
          'id'
        )}&exercicio=${nextProps.exercicio.get('id')}`
      );
    }
  }

  renderContato = data => {
    return (
      <Col sm={6} key={data.get('id')}>
        <h3>
          <b>{data.get('nome')}</b>
        </h3>
        <h4>{data.get('cargo')}</h4>
        <h4>{data.get('email')}</h4>
        <br />
      </Col>
    );
  };

  render() {
    const { contatos } = this.props;
    return (
      contatos &&
      contatos.size > 0 && (
        <Panel title="Contatos" collapsible collapsed>
          <div>
            <Row>{contatos.map(this.renderContato)}</Row>
          </div>
        </Panel>
      )
    );
  }
}

function mapStateToProps(state) {
  return {
    contatos: state.atendimento.get('contatos'),
    entidade: state.auth.getIn(['userInfo', 'entidade']),
    exercicio: state.auth.getIn(['userInfo', 'exercicio'])
  };
}

const allActions = {
  ...actions
};

export default connect(mapStateToProps, allActions)(FormContato);
