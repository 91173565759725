import BotaoImprimir from 'portaltransparencia-common/components/BotaoImprimir.react.js';
import DisplayData from 'portaltransparencia-common/components/displaydata/DisplayData.react.js';
import DisplayDataItem from 'portaltransparencia-common/components/displaydata/DisplayDataItem.react.js';
import DisplayDataRow from 'portaltransparencia-common/components/displaydata/DisplayDataRow.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import NumberUtils from 'portaltransparencia-common/libs/NumberUtils.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

import CabecalhoEntidade from '../entidades/CabecalhoEntidade.react.js';

export class EstagiarioDetalheInformacao extends Component {
  static propTypes = {
    estagiario: PropTypes.object.isRequired,
    usaWebService: PropTypes.bool.isRequired,
    exibeSalarioBase: PropTypes.bool.isRequired
  };

  render() {
    const { estagiario, exibeSalarioBase, usaWebService } = this.props;
    if (!estagiario) {
      return null;
    }

    return (
      <div>
        <CabecalhoEntidade />
        <BotaoImprimir />
        <Panel title="Informações Cadastrais" mobileClose expansible={false}>
          <DisplayData border>
            <DisplayDataRow>
              <DisplayDataItem
                sm={6}
                title="Nome"
                value={estagiario.get('nome')}
                id="nome"
              />
              <DisplayDataItem
                sm={3}
                title="Matrícula"
                value={estagiario.get('codigo')}
                id="codigo"
              />
            </DisplayDataRow>

            <DisplayDataRow>
              <DisplayDataItem
                sm={6}
                title="Lotação"
                value={estagiario.get('lotacao')}
                id="lotacao"
              />
              <DisplayDataItem
                sm={6}
                title="Horário de Trabalho"
                value={estagiario.get('horarioTrabalho')}
                id="horarioTrabalho"
                show={estagiario.has('horarioTrabalho')}
              />
            </DisplayDataRow>

            <DisplayDataRow>
              <DisplayDataItem
                sm={6}
                title="Local de Trabalho"
                value={estagiario.get('localTrabalho')}
                id="localTrabalho"
                show={estagiario.has('localTrabalho')}
              />
              <DisplayDataItem
                sm={6}
                title="Posto de Trabalho"
                value={estagiario.get('postoTrabalho')}
                id="postoTrabalho"
                show={estagiario.has('postoTrabalho')}
              />
            </DisplayDataRow>

            <DisplayDataRow>
              <DisplayDataItem
                sm={6}
                title="Cargo"
                value={estagiario.get('cargo')}
                id="cargo"
                show={estagiario.has('cargo')}
              />
              <DisplayDataItem
                sm={3}
                title="Horas Semanais"
                value={estagiario.get('horasSemanais')}
                id="horasSemanais"
                show={estagiario.has('horasSemanais')}
              />
              <DisplayDataItem
                sm={3}
                title="Horas Mensais"
                value={estagiario.get('cargaHorariaMensal')}
                id="horasMensais"
                show={estagiario.has('cargaHorariaMensal')}
              />
            </DisplayDataRow>

            <DisplayDataRow>
              <DisplayDataItem
                sm={3}
                title="Início do Contrato"
                value={DateUtils.formatDateTimeShort(
                  estagiario.get('inicioContrato')
                )}
                id="inicioContrato"
                show={estagiario.has('inicioContrato')}
              />
              <DisplayDataItem
                sm={3}
                title="Fim do Contrato"
                value={DateUtils.formatDateTimeShort(
                  estagiario.get('terminoContrato')
                )}
                id="terminoContrato"
                show={estagiario.has('terminoContrato')}
              />
              {usaWebService || !exibeSalarioBase ? (
                <DisplayDataItem
                  sm={4}
                  title="Faixa"
                  value={estagiario.get('faixa')}
                  id="faixa"
                  show={estagiario.has('faixa')}
                />
              ) : (
                <>
                  <DisplayDataItem
                    sm={3}
                    title="Plano Salarial"
                    value={estagiario.get('planoSalarial')}
                    id="planoSalarial"
                    show={estagiario.has('planoSalarial')}
                  />
                  <DisplayDataItem
                    sm={3}
                    title="Valor"
                    value={NumberUtils.format(estagiario.get('valorFaixa'))}
                    id="valorFaixa"
                    show={
                      estagiario.has('faixa') && estagiario.has('valorFaixa')
                    }
                  />
                </>
              )}
            </DisplayDataRow>
          </DisplayData>
        </Panel>
      </div>
    );
  }
}

export default EstagiarioDetalheInformacao;
