export const ATENDIMENTO = 'ATENDIMENTO';
export const ATENDIMENTO_START = 'ATENDIMENTO_START';
export const ATENDIMENTO_SUCCESS = 'ATENDIMENTO_SUCCESS';
export const ATENDIMENTO_ERROR = 'ATENDIMENTO_ERROR';

export const CONTATOS = 'CONTATOS';
export const CONTATOS_START = 'CONTATOS_START';
export const CONTATOS_SUCCESS = 'CONTATOS_SUCCESS';
export const CONTATOS_ERROR = 'CONTATOS_ERROR';

export const PARAMS = 'PARAMS';
export const PARAMS_START = 'PARAMS_START';
export const PARAMS_SUCCESS = 'PARAMS_SUCCESS';
export const PARAMS_ERROR = 'PARAMS_ERROR';

export const CONFIG_APARENCIA = 'CONFIG_APARENCIA';
export const CONFIG_APARENCIA_START = 'CONFIG_APARENCIA_START';
export const CONFIG_APARENCIA_SUCCESS = 'CONFIG_APARENCIA_SUCCESS';
export const CONFIG_APARENCIA_ERROR = 'CONFIG_APARENCIA_ERROR';
