import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react.js';
import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

import LinkToEmpenho from '../empenhos/LinkToEmpenho.react';

export default class ComprasDiretasItem extends Component {
  static propTypes = {
    compra: PropTypes.object.isRequired,
    onView: PropTypes.func
  };

  onViewEmpenhos = compra => {
    this.props.history.push(
      `empenhos/detalhe?search=id.entidade==${compra.get(
        'entidade'
      )}&entidade=${compra.get('entidade')}&exercicio=${compra.get(
        'exercicio'
      )}&empenho=${compra.get('empenho')}`
    );
  };

  generateKey = compra => {
    return `${compra.get('entidade')}
      _${compra.get('exercicio')}
      _${compra.get('empenho')}
      _${compra.get('unidadeOrcamentaria')}
      _${compra.get('ordem')}`;
  };

  render() {
    const { compra, onView } = this.props;

    const key = this.generateKey(compra);
    const numeroProcesso = compra.get('numeroProcesso') || 0;
    const onMore = onView || this.onViewEmpenhos;

    return (
      <TableActions ref="row" key={key} data={compra} onMore={onMore}>
        <td>
          <LinkToEmpenho
            entidade={compra.get('entidade')}
            exercicio={compra.get('exercicio')}
            empenho={compra.get('empenho')}
          />
        </td>
        <td>{DateUtils.formatDateTimeShort(compra.get('data'))}</td>
        <td>{compra.get('tipoLicitacao')}</td>
        <td>{numeroProcesso + '/' + compra.get('exercicio')}</td>
        <td>{compra.get('cnpj') + ' - ' + compra.get('fornecedor')}</td>
        <td>{compra.get('item')}</td>
        <td>{compra.get('quantidade')}</td>
        <td className="right">
          <NumberFormatter value={compra.get('valorUnitario')} />
        </td>
        <td className="right">
          <NumberFormatter value={compra.get('valorTotal')} />
        </td>
      </TableActions>
    );
  }
}
