import { Map } from 'immutable';
import ExportButtonGroup from 'portaltransparencia-common/components/ExportButtonGroup.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import Tab from 'portaltransparencia-common/components/Tab.react.js';
import Tabs from 'portaltransparencia-common/components/Tabs.react.js';
import configuration from 'portaltransparencia-common/config/Config.js';
import {
  onChange,
  resetCrud
} from 'portaltransparencia-common/crud/Actions.js';
import {
  addFilterUrl,
  addPagination
} from 'portaltransparencia-common/libs/AddFilter.js';
import storageService from 'portaltransparencia-common/libs/StorageService.js';
import {
  PARAM_EXIBE_FUNCAO_GRATIFICADA,
  PARAM_MOSTRA_NUMERO_ANO_ATO,
  PARAM_INTEGRADO_OXY_FOLHA_PAGAMENTO
} from 'portaltransparencia-common/parametros/ParametrosPortalTransparencia';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import PageHeader from '../components/PageHeader.react.js';
import NotasExplicativas from '../notaexplicativa/Form.react';
import ParametroService from '../parametros/ParametroService.js';
import UltimaAtualizacaoByTable from '../ultimaatualizacao/UltimaAtualizacaoByTable.react.js';
import * as actions from './Actions.js';
import CargosFiltro from './CargosFiltro.react';
import CargosTable from './CargosTable.react';
import FuncoesGratificadasTable from './FuncoesGratificadasTable.react.js';
import CargosFiltroOxy from './oxy/CargosFiltroOxy.react.js';
import CargosTableOxy from './oxy/CargosTableOxy.react';
import { addErrorNotification } from 'portaltransparencia-common/ui/Actions.js';

export class FormCargos extends Component {
  static propTypes = {
    page: PropTypes.object.isRequired,
    findCargosPaginado: PropTypes.func.isRequired,
    filtros: PropTypes.object,
    onChange: PropTypes.func,
    location: PropTypes.object.isRequired,
    resetCrud: PropTypes.func.isRequired,
    valorParametro: PropTypes.string,
    findFuncoesGratificadasPaginado: PropTypes.func.isRequired,
    funcoesGratificadas: PropTypes.object.isRequired,
    paramExibeFuncaoGratificada: PropTypes.string,
    paramMostraAto: PropTypes.string,
    addErrorNotification: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.consultarCargos();

    if (this.props.paramExibeFuncaoGratificada == 'S') {
      this.consultarFuncoesGratificadas();
    }
  }

  consultarCargos = event => {
    const { page, paramIntegradoOxyFolha } = this.props;

    if (paramIntegradoOxyFolha === 'S') {
      this.searchOxy();
    } else {
      const initialPage = page.set('number', 0);
      this.searchWithPage(initialPage);
    }

    event && event.preventDefault();
  };

  consultarFuncoesGratificadas = () => {
    const { funcoesGratificadas } = this.props;
    const initialPage = funcoesGratificadas.set('number', 0);

    this.searchWithPageFuncoesGratificadas(initialPage);
  };

  searchWithPage = page => {
    const { findCargosPaginado } = this.props;

    findCargosPaginado(this.montaFiltro(page));
  };

  searchWithPageOxy = page => {
    const { loadCargos } = this.props;

    loadCargos(this.montaFiltroOxy(page));
  };

  searchOxy = () => {
    const { loadCargos } = this.props;

    loadCargos(this.montaFiltroOxy());
  };

  searchWithPageFuncoesGratificadas = page => {
    const { findFuncoesGratificadasPaginado } = this.props;

    findFuncoesGratificadasPaginado(this.montaFiltro(page));
  };

  montaFiltro = page => {
    const { filtros } = this.props;

    const cargo = filtros.get('cargo');
    const quadroSalarial = filtros.get('quadroSalarial');
    const grupoOcupacional = filtros.get('grupoOcupacional');
    const nivel = filtros.get('nivel');
    let filter = '';

    if (cargo) {
      filter = addFilterUrl(filter, `cargo=${cargo.getIn(['id', 'cargo'])}`);
    }
    if (quadroSalarial) {
      filter = addFilterUrl(
        filter,
        `quadro=${quadroSalarial.getIn(['id', 'quadroSalarial'])}`
      );
    }
    if (grupoOcupacional) {
      filter = addFilterUrl(
        filter,
        `grupo=${grupoOcupacional.getIn(['id', 'grupoOcupacional'])}`
      );
    }
    if (nivel) {
      filter = addFilterUrl(filter, `nivel=${nivel.getIn(['id', 'nivel'])}`);
    }

    return addPagination(page, filter);
  };

  montaFiltroOxy = page => {
    const { filtros } = this.props;

    let filter = '';
    if (filtros.get('cargo')) {
      filter = addFilterUrl(filter, `id=${filtros.get('cargo').getIn(['id'])}`);
    }

    if (filtros.get('planoSalarial')) {
      filter = addFilterUrl(
        filter,
        `planoSalarialId=${filtros.get('planoSalarial').getIn(['id'])}`
      );
    }
    if (filtros.get('tipoCargo')) {
      filter = addFilterUrl(
        filter,
        `cargoTipo=${filtros.get('tipoCargo').getIn(['value'])}`
      );
    }
    if (filtros.get('matrizSalarial')) {
      filter = addFilterUrl(
        filter,
        `matriz=${filtros.get('matrizSalarial').getIn(['id'])}`
      );
    }
    if (filtros.get('vigenciaMatrizSalarial')) {
      filter = addFilterUrl(
        filter,
        `matrizSalarialVigencia=${filtros.get('vigenciaMatrizSalarial')}`
      );
    }

    return addPagination(page, filter);
  };

  onExporta = (exportType, openTab, windowParams) => {
    const { apiURL } = configuration;
    const { entidade, exercicio } = storageService.getItem('userInfo');
    const { page, addErrorNotification } = this.props;

    if (page.get('content').size > 0) {
      let filtro = this.montaFiltro() + '&exportType=' + exportType;
      let endPoint =
        this.refs.tab && this.refs.tab.getActiveTabName() == 'funcaoGratificada'
          ? 'funcao-gratificada'
          : 'cargos';

      window.open(
        `${apiURL}/api/${endPoint}/report?entidade=${entidade.id}&exercicio=${exercicio.id}&${filtro}`,
        openTab,
        windowParams
      );
    } else {
      addErrorNotification(
        'A consulta não apresentou resultado. Não é possível exportar para o formato selecionado!'
      );
    }
  };

  render() {
    const {
      page,
      funcoesGratificadas,
      paramExibeFuncaoGratificada,
      paramIntegradoOxyFolha,
      cargosOxy
    } = this.props;

    let filter = '';
    if (paramIntegradoOxyFolha === 'S') {
      filter = this.montaFiltroOxy();

      if (!cargosOxy || cargosOxy.size === 0) {
        return null;
      }
    } else {
      filter = this.montaFiltro(page);

      if (
        !page.has('content') ||
        (paramExibeFuncaoGratificada == 'S' &&
          !funcoesGratificadas.has('content'))
      ) {
        return null;
      }
    }

    return (
      <section>
        <PageHeader location={this.props.location} />

        <NotasExplicativas pathname={this.props.location.pathname} />

        {paramIntegradoOxyFolha === 'S' ? (
          <CargosFiltroOxy
            filtro={this.montaFiltroOxy()}
            cargosOxy={cargosOxy}
          />
        ) : (
          <CargosFiltro filtro={this.montaFiltro()} />
        )}

        <Panel isForm expansible={false} tableResponsive>
          <Row>
            <Col xs={6}>
              <UltimaAtualizacaoByTable
                modulo="1"
                table="rhfechamentocontabil"
                column="data"
                filtrarEntidade
              />
            </Col>
            {paramIntegradoOxyFolha !== 'S' ? (
              <Col xs={6}>
                <ExportButtonGroup onClick={this.onExporta} visible={true} />
              </Col>
            ) : (
              <></>
            )}
          </Row>

          {paramIntegradoOxyFolha === 'S' ? (
            <CargosTableOxy
              filter={filter}
              page={page}
              searchWithPage={this.searchWithPageOxy}
              cargos={cargosOxy}
              history={this.props.history}
            />
          ) : (
            <Tabs ref="tab">
              <Tab title="Cargos" name="cargos" onlyTable active>
                <CargosTable
                  filter={filter}
                  page={page}
                  history={this.props.history}
                  searchWithPage={this.searchWithPage}
                  paramMostraAto={this.props.paramMostraAto}
                />
              </Tab>
              {paramExibeFuncaoGratificada == 'S' && (
                <Tab
                  title="Função Gratificada"
                  name="funcaoGratificada"
                  onlyTable
                >
                  <FuncoesGratificadasTable
                    page={funcoesGratificadas}
                    searchWithPage={this.searchWithPageFuncoesGratificadas}
                  />
                </Tab>
              )}
            </Tabs>
          )}
        </Panel>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    filtros: state.crud.getIn(['filtroCargos', 'currentRecord'], Map({})),
    page: state.cargo.get('cargos'),
    cargosOxy: state.cargo.get('cargosOxy'),
    funcoesGratificadas: state.cargo.get('funcoesGratificadas'),
    paramExibeFuncaoGratificada: ParametroService.getValorParametro(
      state,
      PARAM_EXIBE_FUNCAO_GRATIFICADA
    ),
    paramMostraAto: ParametroService.getValorParametro(
      state,
      PARAM_MOSTRA_NUMERO_ANO_ATO
    ),
    paramIntegradoOxyFolha: ParametroService.getValorParametro(
      state,
      PARAM_INTEGRADO_OXY_FOLHA_PAGAMENTO
    )
  };
}

const allActions = {
  ...actions,
  onChange,
  resetCrud,
  addErrorNotification
};

export default connect(mapStateToProps, allActions)(FormCargos);
