import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';

import $ from 'jquery';
export class ButtonSwitch extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node,
    center: PropTypes.bool
  };

  static defaultProps = {
    center: false
  };

  UNSAFE_componentWillMount() {
    $('.switch-buttons .btn').click(function () {
      var este = $(this);
      este.parent().find('.btn').removeClass('active');
      este.addClass('active');
    });
  }

  getClassName = () => {
    const { center } = this.props;

    if (center) {
      return 'switch-buttons center';
    } else {
      return 'switch-buttons';
    }
  };

  getValue = () => {
    const { children } = this.props;

    const activeChild = React.Children.toArray(children).filter(child => {
      const node = ReactDOM.findDOMNode(
        this.refs[`btn-item-${child.props.value}`]
      );

      return node.className === 'btn active';
    });

    if (activeChild.length > 0) {
      return activeChild[0].props.value;
    }
  };

  render() {
    const { children } = this.props;

    return (
      <div className={this.getClassName()}>
        {React.Children.map(children, child => {
          return React.cloneElement(child, {
            ref: `btn-item-${child.props.value}`
          });
        })}
      </div>
    );
  }
}

export default ButtonSwitch;
