import Tab from 'portaltransparencia-common/components/Tab.react.js';
import Tabs from 'portaltransparencia-common/components/Tabs.react.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

import Arquivo from '../arquivo/Arquivo.react.js';
import ConvenioAditivos from './ConvenioAditivos.react.js';
import ConvenioContrapartida from './ConvenioContrapartida.react.js';
import ConvenioPagamentos from './ConvenioPagamentos.react.js';
import ConvenioRepasses from './ConvenioRepasses.react.js';

export class ConvenioDetalheAbas extends Component {
  static propTypes = {
    repasses: PropTypes.object.isRequired,
    aditivos: PropTypes.object.isRequired,
    contrapartidarepasses: PropTypes.object.isRequired,
    contrapartidadevolucao: PropTypes.object.isRequired,
    contrapartidapagamentos: PropTypes.object.isRequired,
    pagamentos: PropTypes.object.isRequired,
    arquivos: PropTypes.object.isRequired,
    downloadArquivo: PropTypes.func.isRequired
  };

  render() {
    const {
      repasses,
      aditivos,
      contrapartidarepasses,
      contrapartidadevolucao,
      contrapartidapagamentos,
      pagamentos,
      downloadArquivo,
      arquivos
    } = this.props;

    return (
      <Tabs id="tabs">
        <Tab onlyTable tableResponsive title="Aditivos" active={true}>
          <ConvenioAditivos aditivos={aditivos} />
        </Tab>

        <Tab onlyTable tableResponsive title="Repasses" active={false}>
          <ConvenioRepasses repasses={repasses} />
        </Tab>
        <Tab onlyTable tableResponsive title="Contrapartida" active={false}>
          <ConvenioContrapartida
            contrapartidarepasses={contrapartidarepasses}
            contrapartidadevolucao={contrapartidadevolucao}
            contrapartidapagamentos={contrapartidapagamentos}
          />
        </Tab>
        <Tab title="Pagamentos" active={false}>
          <ConvenioPagamentos pagamentos={pagamentos} />
        </Tab>
        <Tab onlyTable tableResponsive title="Anexos" active={false}>
          <Arquivo arquivos={arquivos} onDownload={downloadArquivo} />
        </Tab>
      </Tabs>
    );
  }
}

export default ConvenioDetalheAbas;
