import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';

import * as types from './Types.js';

const initialState = fromJS({
  horasExtras: {
    secretarias: [],
    totalHorasExtras: '',
    totalValor: ''
  },
  detalhes: []
});

function horaExtraReducer(state = initialState, action) {
  switch (action.type) {
    case types.HORAS_EXTRAS_START:
    case types.HORAS_EXTRAS_DETALHES_START: {
      return startLoad(state);
    }

    case types.HORAS_EXTRAS_SUCCESS: {
      return endLoad(state.set('horasExtras', fromJS(action.payload)));
    }

    case types.HORAS_EXTRAS_DETALHES_SUCCESS: {
      return endLoad(state.set('detalhes', fromJS(action.payload)));
    }

    case types.HORAS_EXTRAS_ERROR: {
      return endLoad(state);
    }

    case types.HORAS_EXTRAS_DETALHES_ERROR: {
      return endLoad(state);
    }
  }
  return state;
}

export default horaExtraReducer;
