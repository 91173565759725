import Widget from 'portaltransparencia-common/components/Widget.react.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils';
import PropTypes from 'prop-types';
import React from 'react';
import { Chart } from 'react-google-charts';
import Component from 'react-pure-render/component';

export class ArrecadacaoReceitaXDespesa extends Component {
  static propTypes = {
    width: PropTypes.number.isRequired,
    arrecadacaoDespesaMes: PropTypes.object.isRequired,
    exercicio: PropTypes.any.isRequired,
    pathname: PropTypes.string
  };

  getDataChartBar = data => {
    const dataChart = [['Mês', 'Arrecadado', 'Empenhado', 'Liquidado', 'Pago']];

    return dataChart.concat(
      data
        .map(item => {
          return [
            DateUtils.getMonthDescription(item.get('mes'), true),
            item.get('valorArrecadado'),
            item.get('valorEmpenhado'),
            item.get('valorLiquidado'),
            item.get('valorPago')
          ];
        })
        .toJS()
    );
  };

  getOptions = () => {
    let { width } = this.props;
    if (width > 400) {
      width = width * 0.98;
    }

    return {
      bar: { groupWidth: '90%' },
      legend: { position: 'top' },
      width: width,
      height: 300
    };
  };

  render() {
    const { arrecadacaoDespesaMes, exercicio, pathname } = this.props;

    const options = this.getOptions();
    const dataChart = this.getDataChartBar(arrecadacaoDespesaMes);

    const tipoInformacao = pathname == '/receitaxdespesa-fundeb' ? 'F' : 'C';

    return (
      <Widget
        icon="fa fa-bar-chart"
        isChart
        title={
          (tipoInformacao == 'F' ? `FUNDEB: ` : `COVID-19: `) +
          `Receita X Despesa de ${exercicio}`
        }
      >
        {arrecadacaoDespesaMes.size > 0 && (
          <Chart
            ref="chart"
            chartType="ColumnChart"
            data={dataChart}
            legend_toggle
            graphID="dashboard-bar-chart"
            options={options}
            width={`${options.width}px`}
            chartLanguage="pt-br"
          />
        )}
      </Widget>
    );
  }
}

export default ArrecadacaoReceitaXDespesa;
