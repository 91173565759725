const mime = require('mime-types');

export function getFileBase64(file, onLoad) {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    let dataUrl = reader.result;
    let base64 = dataUrl.split(',')[1];
    onLoad(base64);
  };
  reader.onerror = error => {
    throw 'Error loading file: ' + error;
  };
}

export function getFileBase64Url(base64Content, fileName) {
  let contentType = mime.lookup(fileName);
  let url = 'data:' + contentType + ';base64,' + base64Content;

  return url;
}

export function convertBase64toBlob(content, contentType) {
  contentType = contentType || '';
  const sliceSize = 512;
  const byteCharacters = window.atob(content);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    let slice = byteCharacters.slice(offset, offset + sliceSize);
    let byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    let byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  let blob = new Blob(byteArrays, {
    type: contentType
  });
  return blob;
}
