import Panel from 'portaltransparencia-common/components/Panel.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

import AvaliacaoPpaProgramaRow from './AvaliacaoPpaProgramaRow.react.js';

export default class AvaliacaoPpaProgramaTable extends Component {
  static propTypes = {
    programas: PropTypes.object.isRequired
  };

  renderProgramas = programa => {
    const key = `${programa.get('exercicio')}_${programa.get('programa')}`;
    return <AvaliacaoPpaProgramaRow key={key} programa={programa} />;
  };

  render() {
    const { programas } = this.props;

    if (programas.isEmpty()) {
      return null;
    }

    const programaID = programas.get(0).get('programa') || '';

    return (
      <Panel
        key={programaID}
        isForm
        mobileClose
        title={`Programa ${programaID}`}
        insidePanel
      >
        <div className="panel-table-outer">
          <table className="panel-table striped fancy">
            <TableHeader empty={programas.isEmpty()}>
              <tr>
                <th>Entidade</th>
                <th>Exercício</th>
                <th className="right">Planejado</th>
                <th className="right">Planejado Atualizado</th>
                <th className="right">Executado</th>
                <th className="right">Meta Física Prevista</th>
                <th className="right">Meta Física Atingida</th>
              </tr>
            </TableHeader>
            <tbody>{programas.map(this.renderProgramas)}</tbody>
          </table>
        </div>
      </Panel>
    );
  }
}
