const cores = {
  padrao: '#00AFCB',
  vermelho: '#D94934',
  rosa: '#CC146D',
  lilas: '#B35FD9',
  roxo: '#72378C',
  verde: '#348C30',
  amarelo: '#F1AB3B',
  cinza: '#686A6D',
  preto: '#3C3C3B'
};

const changePrimaryColor = cor => {
  const hex = cores[cor];
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  var r = parseInt(result[1], 16);
  var g = parseInt(result[2], 16);
  var b = parseInt(result[3], 16);

  r /= 255;
  g /= 255;
  b /= 255;
  var max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  var h,
    s,
    l = (max + min) / 2;

  if (max == min) {
    h = s = 0; // achromatic
  } else {
    var d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }

  s = s * 100;
  s = Math.round(s);
  l = l * 100;
  l = Math.round(l);
  h = Math.round(360 * h);

  var style = document.createElement('style');
  style.innerHTML =
    `
      body {
        --primary-h: ` +
    h +
    `;
        --primary-s: ` +
    s +
    '%' +
    `;
        --primary-l: ` +
    l +
    '%' +
    `;
        --primary: hsl(var(--primary-h), var(--primary-s), var(--primary-l));
        --background-primary-h: ` +
    h +
    `;
        --background-primary-s: ` +
    s +
    '%' +
    `;
        --background-primary-l: ` +
    l +
    '%' +
    `;
        --background-primary:  hsl(var(--primary-h), var(--primary-s), var(--primary-l));
        --dark-primary: var(--primary);
      }
    `;

  document.head.appendChild(style);
};

export default changePrimaryColor;
