import Component from 'react-pure-render/component';
import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

export default class WizardHeaderItem extends Component {
  static propTypes = {
    activeStep: PropTypes.number,
    stepNumber: PropTypes.number.isRequired,
    stepFaIcon: PropTypes.string.isRequired,
    stepTitle: PropTypes.string.isRequired
  };

  render() {
    const { activeStep, stepNumber, stepFaIcon, stepTitle } = this.props;

    const classes = {
      step: true,
      [`step-${stepNumber}`]: true,
      active: stepNumber <= activeStep
    };

    return (
      <li className={classnames(classes)}>
        <em className={'fa ' + stepFaIcon}></em>
        <b className="wizard-tooltip">{stepTitle}</b>
      </li>
    );
  }
}
