import * as types from './Types.js';

export function findArquivoSicFisico() {
  return ({ fetch }) => ({
    type: types.ARQUIVO_SIC_FISICO,
    payload: {
      promise: fetch('/parametros/sic-fisico')
    }
  });
}

export function findMensagemSicFisico() {
  return ({ fetch }) => ({
    type: types.MENSAGEM_SIC_FISICO,
    payload: {
      promise: fetch('/parametros/sic-fisico/mensagem?tipoCadastro=1')
    }
  });
}
