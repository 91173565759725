import Input from 'portaltransparencia-common/components/EloInput.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';

import FilterButtons from '../components/FilterButtons.react';

export default class FornecedorFiltro extends Component {
  static propTypes = {
    page: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    filtros: PropTypes.object.isRequired,
    findFornecedores: PropTypes.func.isRequired,
    findFornecedoresTotais: PropTypes.func.isRequired,
    montaFiltro: PropTypes.func.isRequired,
    onConsultaWithPage: PropTypes.func.isRequired,
    resetCrud: PropTypes.func.isRequired,
    filtroFornecedor: PropTypes.string.isRequired
  };

  onChangeFiltro = event => {
    const { onChange, filtroFornecedor } = this.props;
    onChange(filtroFornecedor, event);
  };

  onResetFiltro = () => {
    const {
      resetCrud,
      findFornecedores,
      findFornecedoresTotais,
      filtroFornecedor
    } = this.props;
    resetCrud(filtroFornecedor);
    findFornecedores('');
    findFornecedoresTotais('');
  };

  onConsulta = event => {
    const { page, onConsultaWithPage } = this.props;
    const initialPage = page.set('number', 0);
    onConsultaWithPage(initialPage);
    event && event.preventDefault();
    this.refs.panelFiltroFornecedorPagar &&
      this.refs.panelFiltroFornecedorPagar.collapse();
  };

  render = () => {
    const { filtros } = this.props;
    return (
      <Panel
        title="Consultar"
        mobileClose
        isForm
        ref="panelFiltroFornecedorPagar"
      >
        <form>
          <Row>
            <Col xs={12} sm={4} md={5}>
              <Input
                id="nome"
                name="nome"
                ref="nome"
                placeholder="Nome do Fornecedor"
                label="Nome do Fornecedor"
                type="text"
                onChange={this.onChangeFiltro}
                value={filtros.get('nome')}
              />
            </Col>
            <Col xs={12} sm={4} md={4}>
              <Input
                id="cnpj"
                name="cnpj"
                ref="cnpj"
                placeholder="CNPJ/CPF"
                label="CNPJ/CPF"
                type="text"
                onChange={this.onChangeFiltro}
                value={filtros.get('cnpj')}
              />
            </Col>
            <Col xs={12} sm={4} md={3}>
              <FilterButtons
                idBtnConsulta="btnFiltrar"
                idBtnReset="btnResetFilter"
                on
                onConsulta={this.onConsulta}
                onReset={this.onResetFiltro}
              />
            </Col>
          </Row>
        </form>
      </Panel>
    );
  };
}
