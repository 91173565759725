import DatePicker from 'portaltransparencia-common/components/DatePicker.react.js';
import Input from 'portaltransparencia-common/components/EloInput.react.js';
import Validation from 'portaltransparencia-common/components/Validation.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export class FormSolicitacaoDetailPessoa extends Component {
  static propTypes = {
    dados: PropTypes.object,
    cadastrada: PropTypes.any,
    onChangeDados: PropTypes.func.isRequired,
    errors: PropTypes.object
  };

  getValidatorData = () => {
    const { nome, rg, telefone, celular, email } = this.refs;

    return {
      nome: nome.getValue(),
      rg: rg.getValue(),
      telefone: telefone.getValue(),
      celular: celular.getValue(),
      email: email.getValue()
    };
  };

  renderEscolaridades = data => {
    return (
      <option key={data.get('id')} value={data.get('id')}>
        {data.get('descricao')}
      </option>
    );
  };

  render() {
    const { dados, cadastrada, onChangeDados, errors } = this.props;

    return (
      <div>
        <Input
          label="Nome"
          type="text"
          name="nome"
          ref="nome"
          onChange={onChangeDados}
          value={dados.get('nome')}
          disabled={cadastrada}
          errors={errors}
        />

        <Input
          label="E-Mail"
          type="email"
          name="email"
          ref="email"
          onChange={onChangeDados}
          value={dados.get('email')}
          errors={errors}
        />

        <Input
          label="Celular"
          type="text"
          name="celular"
          ref="celular"
          onChange={onChangeDados}
          value={dados.get('celular')}
          errors={errors}
        />

        <Input
          label="Telefone"
          type="text"
          name="telefone"
          ref="telefone"
          onChange={onChangeDados}
          value={dados.get('telefone')}
          errors={errors}
        />

        <Input
          label="RG"
          type="text"
          name="rg"
          ref="rg"
          onChange={onChangeDados}
          value={dados.get('rg')}
          errors={errors}
        />

        <DatePicker
          id="dataNascimento"
          name="dataNascimento"
          ref="dataNascimento"
          label="Data Nascimento"
          onChange={onChangeDados}
          value={dados.get('dataNascimento')}
        />

        <Input
          label="Sexo"
          type="select"
          name="sexo"
          ref="sexo"
          onChange={onChangeDados}
          value={dados.get('sexo')}
          errors={errors}
        >
          <option key="" value="">
            Selecione
          </option>
          <option key="M" value="M">
            Masculino
          </option>
          <option key="F" value="F">
            Feminino
          </option>
        </Input>

        <Input
          label="Escolaridade"
          type="select"
          name="escolaridade"
          ref="escolaridade"
          onChange={onChangeDados}
          value={dados.get('escolaridade')}
          errors={errors}
        >
          <option key="" value="">
            Selecione
          </option>

          {dados.get('listaEscolaridades') &&
            dados.get('listaEscolaridades').map(this.renderEscolaridades)}
        </Input>
      </div>
    );
  }
}

const validationSchema = {
  rules: {
    nome: 'required',
    email: 'required'
  },
  labels: {
    nome: 'Nome',
    email: 'E-Mail'
  }
};

FormSolicitacaoDetailPessoa = Validation(validationSchema)(
  FormSolicitacaoDetailPessoa
);

export default FormSolicitacaoDetailPessoa;
