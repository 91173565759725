import * as types from './Types.js';

export function findEstruturasPaginado(filtro) {
  return ({ fetch }) => ({
    type: types.LIST_ESTRUTURAS,
    payload: {
      promise: fetch(`/api/estrutura-organizacional/lotacoes?${filtro}`)
    }
  });
}

export function findServidoresPorLotacao(id) {
  return ({ fetch }) => ({
    type: types.LIST_SERVIDORES,
    payload: {
      promise: fetch(`/api/estrutura-organizacional/lotacoes/${id}/servidores`)
    }
  });
}
