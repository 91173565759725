import * as types from './Types.js';

export function findSuprimentosFundos(filter) {
  return ({ fetch }) => ({
    type: types.SUPRIMENTOS_FUNDOS_LIST,
    payload: {
      promise: fetch(`/api/liquidacoes/suprimentos-fundos?${filter}`)
    }
  });
}

export function findTotalSuprimentosFundos(filter) {
  return ({ fetch }) => ({
    type: types.SUPRIMENTOS_FUNDOS_TOTAL,
    payload: {
      promise: fetch(`/api/liquidacoes/suprimentos-fundos/total?${filter}`)
    }
  });
}
