import AuthService from 'portaltransparencia-common/auth/AuthService.js';

import { PARAMETRO_MENU_SUCCESS } from '../menu/Types.js';
import { findNotasExplicativa } from '../notaexplicativa/Actions.js';
import { PARAMETRO_ALL_SUCCESS } from '../parametros/Types.js';
import * as types from './Types.js';

export function findEntidades() {
  return ({ fetch }) => ({
    type: types.ENTIDADES_LIST,
    payload: {
      promise: fetch(
        '/api/entidades/lista?fields=id,nome,cnpj,logradouro,bairro,cidade,uf,cep,entidadePrincipal,tipo,telefone,registrado'
      )
    }
  });
}

export function findExerciciosByEntidade(idEntidade) {
  return ({ fetch }) => ({
    type: types.EXERCICIOSBYENTIDADE_LIST,
    payload: {
      promise: fetch(`/api/exercicios/entidade/${idEntidade}`)
    }
  });
}

export function acExercicioLogado(entidade, exercicio) {
  const userInfo = {
    entidade: {
      id: entidade
    },
    exercicio: {
      id: exercicio
    }
  };

  AuthService.saveUserInfo(userInfo);

  const getPromise = async (fetch, dispatch) => {
    const [
      menu,
      parametros,
      configuracaoGeral,
      exercicios,
      brasao
    ] = await Promise.all([
      fetch(`/parametros/menu/${entidade}/${exercicio}`),
      fetch(`/parametros/${entidade}/${exercicio}`),
      fetch('/api/configuracoes-gerais'),
      fetch(`/api/exercicios/entidade/${entidade}`),
      fetch(`/api/entidades/${entidade}/brasao`).catch(() => ({ brasao: '' }))
    ]);

    dispatch({
      type: types.EXERCICIO_LOGADO_SUCCESS,
      payload: {
        entidade,
        exercicio
      }
    });

    dispatch({
      type: PARAMETRO_MENU_SUCCESS,
      payload: menu
    });

    dispatch({
      type: PARAMETRO_ALL_SUCCESS,
      payload: parametros
    });

    dispatch({
      type: types.ENTIDADE_BRASAO_SUCCESS,
      payload: brasao.brasao
    });

    dispatch({
      type: types.EXERCICIOSBYENTIDADE_LIST_SUCCESS,
      payload: exercicios
    });

    dispatch({
      type: types.CONFIGURACOES_GERAIS_SUCCESS,
      payload: configuracaoGeral
    });

    await dispatch(findNotasExplicativa());
  };

  return ({ fetch, dispatch }) => {
    return {
      type: 'LOAD_CONTEXT',
      payload: getPromise(fetch, dispatch)
    };
  };
}

export function loadExercicioPadrao() {
  return ({ fetch, dispatch }) => ({
    type: types.EXERCICIO_PADRAO,
    payload: fetch('/api/exercicios/padrao').then(exercicio =>
      dispatch(
        acExercicioLogado(exercicio.id.entidade.id, exercicio.id.exercicio)
      )
    )
  });
}
