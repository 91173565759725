import Component from 'react-pure-render/component';
import PropTypes from 'prop-types';
import React from 'react';
import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react.js';

export class LicitacaoDetalheMidia extends Component {
  static propTypes = {
    midias: PropTypes.object.isRequired
  };

  onViewMidia = fileURL => {
    window.open(fileURL, '_blank');
  };

  renderMidias = data => {
    return (
      <TableActions
        id={'btView' + data.get('codigo') + data.get('idOxy')}
        ref="row"
        key={data.get('codigo') + data.get('idOxy')}
        data={data.get('endereco')}
        onView={this.onViewMidia}
      >
        <td>{data.get('descricao')}</td>
        <td>
          <a target="_blank" href={data.get('endereco')}>
            {data.get('endereco')}
          </a>
        </td>
      </TableActions>
    );
  };

  render() {
    const { midias } = this.props;

    return (
      <>
        <h4 className="mt-xs mb-xs center">
          <b>Mídias Online</b>
        </h4>
        <div className="panel-table-outer">
          <table className="panel-table striped fancy">
            <TableHeader empty={midias.isEmpty()}>
              <tr>
                <th>Título</th>
                <th>Endereço</th>
                <th></th>
              </tr>
            </TableHeader>
            <tbody>
              {midias
                .filter(
                  midia => midia.get('tipo') === 'MIDIA' || !midia.get('tipo')
                )
                .map(this.renderMidias)}
            </tbody>
          </table>
        </div>

        <h4 className="mt-xs mb-xs center">
          <b>Links</b>
        </h4>
        <div className="panel-table-outer">
          <table className="panel-table striped fancy">
            <TableHeader empty={midias.isEmpty()}>
              <tr>
                <th>Título</th>
                <th>Endereço</th>
                <th></th>
              </tr>
            </TableHeader>
            <tbody>
              {midias
                .filter(midia => midia.get('tipo') === 'LINK')
                .map(this.renderMidias)}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

export default LicitacaoDetalheMidia;
