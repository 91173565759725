import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export default class DisplayData extends Component {
  static propTypes = {
    children: PropTypes.node,
    border: PropTypes.bool,
    block: PropTypes.bool
  };

  render() {
    const { children, border, block, column } = this.props;

    const classes = {
      'display-data': true,
      border: border && !block,
      block: block && !border,
      'block-border': block && border,
      column: column
    };

    return <div className={classnames(classes)}>{children}</div>;
  }
}
