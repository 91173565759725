import BotaoImprimir from 'portaltransparencia-common/components/BotaoImprimir.react.js';
import DisplayData from 'portaltransparencia-common/components/displaydata/DisplayData.react.js';
import DisplayDataItem from 'portaltransparencia-common/components/displaydata/DisplayDataItem.react.js';
import DisplayDataRow from 'portaltransparencia-common/components/displaydata/DisplayDataRow.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import { getAllValuesFromUrlSearchParams } from 'portaltransparencia-common/libs/URLUtils';
import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import {
  downloadArquivo,
  downloadArquivoAudienciaPublica
} from '../arquivo/Actions.js';
import Arquivo from '../arquivo/Arquivo.react.js';
import PageHeader from '../components/PageHeader.react.js';
import * as actions from './Actions.js';

export class FormAudienciasPublicasDetail extends Component {
  static propTypes = {
    detalhes: PropTypes.object.isRequired,
    findAudienciasPublicasDetalhes: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    audienciasPublicas: PropTypes.object.isRequired,
    downloadArquivo: PropTypes.func.isRequired,
    downloadArquivoAudienciaPublica: PropTypes.func.isRequired,
    findArquivos: PropTypes.func.isRequired,
    arquivos: PropTypes.array.isRequired,
    isIntegradoOxy: PropTypes.bool
  };

  componentDidMount() {
    const {
      findAudienciasPublicasDetalhes,
      location,
      findArquivos
    } = this.props;

    const params = getAllValuesFromUrlSearchParams(location);

    findAudienciasPublicasDetalhes(
      params.entidade,
      params.exercicio,
      params.id
    );
    findArquivos(params.entidade, params.exercicio, params.id);
  }

  render() {
    const {
      detalhes,
      downloadArquivo,
      downloadArquivoAudienciaPublica,
      arquivos,
      isIntegradoOxy
    } = this.props;

    if (detalhes.isEmpty()) {
      return null;
    }

    return (
      <div>
        <Helmet title={'Audiência Pública'} />

        <BotaoImprimir />

        <PageHeader location={this.props.location} />

        <Panel title={'Audiência Pública'}>
          <DisplayData border>
            <DisplayDataRow>
              <DisplayDataItem
                sm={6}
                title="Natureza"
                value={detalhes.get('natureza')}
                id="natureza"
              />
              <DisplayDataItem
                sm={6}
                title="Tipo"
                value={detalhes.get('tipo')}
                id="tipo"
              />
            </DisplayDataRow>

            <DisplayDataRow>
              <DisplayDataItem
                sm={3}
                title="Data"
                value={DateUtils.formatDateTimeShort(detalhes.get('data'))}
                id="data"
              />
              <DisplayDataItem
                sm={3}
                title="Data Envio à Câmara"
                value={DateUtils.formatDateTimeShort(detalhes.get('dataEnvio'))}
                id="dataEnvio"
              />
              <DisplayDataItem
                sm={3}
                title="Data Envio ao TCE"
                value={DateUtils.formatDateTimeShort(
                  detalhes.get('dataCadastro')
                )}
                id="dataCadastro"
              />
              <DisplayDataItem
                sm={3}
                title="Indicativo de Ocorrência"
                value={detalhes.get('indicativo') ? 'Houve' : 'Não houve'}
                id="indicativo"
              />
            </DisplayDataRow>

            <DisplayDataRow>
              <DisplayDataItem
                sm={6}
                title="Local"
                value={detalhes.get('local')}
                id="local"
              />
              <DisplayDataItem
                sm={6}
                title="Forma Chamamento"
                value={detalhes.get('chamamento')}
                id="chamamento"
              />
            </DisplayDataRow>

            <DisplayDataRow>
              <DisplayDataItem
                sm={6}
                title="Número Lei"
                value={detalhes.get('numeroLei')}
                id="numeroLei"
              />
              <DisplayDataItem
                sm={6}
                title="Data Lei"
                value={DateUtils.formatDateTimeShort(detalhes.get('dataLei'))}
                id="dataLei"
              />
              <DisplayDataItem
                sm={6}
                title="Data Publicação Lei"
                value={DateUtils.formatDateTimeShort(
                  detalhes.get('dataPublicacaoLei')
                )}
                id="dataPublicacaoLei"
              />
            </DisplayDataRow>

            <DisplayDataRow>
              <DisplayDataItem
                sm={6}
                title="Veículo Publicação"
                value={detalhes.get('veiculoPublicacao')}
                id="veiculoPublicacao"
              />
            </DisplayDataRow>
          </DisplayData>
        </Panel>

        <div className="hidden-print">
          <Panel title="Anexos" isTable tableResponsive>
            <Arquivo
              arquivos={arquivos}
              onDownload={
                isIntegradoOxy
                  ? downloadArquivoAudienciaPublica
                  : downloadArquivo
              }
            />
          </Panel>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    detalhes: state.audienciapublica.get('detalhes'),
    arquivos: state.audienciapublica.get('arquivos'),
    isIntegradoOxy: state.entidade.getIn([
      'configuracoesGerais',
      'isIntegradoOxy'
    ])
  };
}

const allActions = {
  ...actions,
  downloadArquivo,
  downloadArquivoAudienciaPublica
};

export default connect(
  mapStateToProps,
  allActions
)(FormAudienciasPublicasDetail);
