export const PRESTACAOCONTAS = 'PRESTACAOCONTAS';
export const PRESTACAOCONTAS_START = 'PRESTACAOCONTAS_START';
export const PRESTACAOCONTAS_SUCCESS = 'PRESTACAOCONTAS_SUCCESS';
export const PRESTACAOCONTAS_ERROR = 'PRESTACAOCONTAS_ERROR';

export const PRESTACAOCONTAS_ARQUIVOS = 'PRESTACAOCONTAS_ARQUIVOS';
export const PRESTACAOCONTAS_ARQUIVOS_START = 'PRESTACAOCONTAS_ARQUIVOS_START';
export const PRESTACAOCONTAS_ARQUIVOS_SUCCESS =
  'PRESTACAOCONTAS_ARQUIVOS_SUCCESS';
export const PRESTACAOCONTAS_ARQUIVOS_ERROR = 'PRESTACAOCONTAS_ARQUIVOS_ERROR';
