import * as types from './Types.js';

export function findEmpenhosEmLiquidacao(filtro) {
  return ({ fetch }) => ({
    type: types.EMPENHOS_EM_LIQUIDACAO,
    payload: {
      promise: fetch('/api/em-liquidacao?' + filtro)
    }
  });
}
