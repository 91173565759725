import Input from 'portaltransparencia-common/components/EloInput.react.js';
import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import SearchPagination from 'portaltransparencia-common/components/SearchPagination.react.js';
import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import TableHeaderItem from 'portaltransparencia-common/components/TableHeaderItem.react';
import {
  onChange,
  resetCrud
} from 'portaltransparencia-common/crud/Actions.js';
import {
  addFilterUrl,
  addPagination
} from 'portaltransparencia-common/libs/AddFilter.js';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import PageHeader from '../components/PageHeader.react.js';
import NotasExplicativas from '../notaexplicativa/Form.react.js';
import UltimaAtualizacao from '../ultimaatualizacao/Form.react.js';
import ExportButtonGroup from 'portaltransparencia-common/components/ExportButtonGroup.react.js';
import * as actions from './Actions.js';
import { Map, fromJS } from 'immutable';
import configuration from 'portaltransparencia-common/config/Config.js';
import { addErrorNotification } from 'portaltransparencia-common/ui/Actions.js';
import { LinkToleiAto } from './LinkToLeiAto.react.js';

export class FormRenunciaReceita extends Component {
  static propTypes = {
    page: PropTypes.object.isRequired,
    findRenunciaReceita: PropTypes.func.isRequired,
    filtros: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    resetCrud: PropTypes.func.isRequired,
    tiposTributos: PropTypes.object.isRequired,
    tiposModalidades: PropTypes.object.isRequired,
    addErrorNotification: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.loadInitialFiltros();
    this.carregarRenunciaReceita();
  }

  loadInitialFiltros = () => {
    const { onChange } = this.props;
    const { entidade, exercicio } = StorageService.getItem('userInfo');
  };

  carregarRenunciaReceita = () => {
    const { page } = this.props;
    const initialPage = page.set('number', 0);

    this.searchWithPage(initialPage);
  };

  consultarRenunciaReceita = event => {
    this.carregarRenunciaReceita();
    event && event.preventDefault();
    this.refs.panelFiltroRenunciaReceita &&
      this.refs.panelFiltroRenunciaReceita.collapse();
  };

  onChangeFiltro = event => {
    const { onChange } = this.props;
    onChange('filtroRenuncias', event);
  };

  onResetFiltro = () => {
    const { resetCrud } = this.props;

    resetCrud('filtroRenuncias');
    this.loadInitialFiltros();
  };

  renderCombo = item => {
    return (
      <option key={item} value={item}>
        {item}
      </option>
    );
  };

  montarFiltro = function (page) {
    const { entidade, exercicio } = StorageService.getItem('userInfo');

    const { filtros } = this.props;

    let filter = `entidade=${entidade.id}&exercicio=${exercicio.id}`;

    if (filtros.get('descTipoTributo')) {
      if (filtros.get('descTipoTributo') != '-1') {
        filter = addFilterUrl(
          filter,
          `descTipoTributo=${filtros.get('descTipoTributo')}`
        );
      }
    }
    if (filtros.get('descTipoModalidade')) {
      if (filtros.get('descTipoModalidade') != '-1') {
        filter = addFilterUrl(
          filter,
          `descTipoModalidade=${filtros.get('descTipoModalidade')}`
        );
      }
    }
    if (filtros.get('setorBeneficiario')) {
      filter = addFilterUrl(
        filter,
        `setorBeneficiario=${filtros.get('setorBeneficiario')}`
      );
    }
    if (filtros.get('compensacao')) {
      filter = addFilterUrl(
        filter,
        `compensacao=${filtros.get('compensacao')}`
      );
    }

    return addPagination(page, filter);
  };

  searchWithPage = page => {
    const { findRenunciaReceita } = this.props;
    findRenunciaReceita(this.montarFiltro(page));
  };

  onViewMore = data => {
    this.props.history.push(
      `renuncia-receitas/detalhes?entidade=${data.get(
        'entidade'
      )}&exercicio=${data.get('exercicio')}&descTipoTributo=${data.get(
        'descTipoTributo'
      )}`
    );
  };

  renderRenunciaReceita = data => {
    console.log(this.props.history + ' teste render');
    return (
      <TableActions
        ref="row"
        key={data.get('id')}
        data={data}
        onMore={this.onViewMore}
      >
        <td>{data.get('descTipoTributo')}</td>
        <td>{data.get('descTipoModalidade')}</td>
        <td>{data.get('setorBeneficiario')}</td>
        <td>{data.get('compensacao')}</td>
        <td>{data.get('descfundamentolegal')}</td>

        <td>
          <LinkToleiAto
            entidade={data.get('entidade')}
            exercicio={data.get('exercicio_leiato')}
            id={data.get('idleis_atos')}
            historyLeiAto={this.props.history}
            mostrarportal={data.get('mostrarportal')}
          />
        </td>

        <td className="right">
          <NumberFormatter value={data.get('valorUm')} />
        </td>
        <td className="right">
          <NumberFormatter value={data.get('valorDois')} />
        </td>
        <td className="right">
          <NumberFormatter value={data.get('valorTres')} />
        </td>
      </TableActions>
    );
  };

  onExporta = (exportType, openTab, windowParams) => {
    const { page, addErrorNotification } = this.props;
    const { apiURL } = configuration;

    if (page && page.get('content')) {
      if (page.get('content').size > 0) {
        let filtro = this.montarFiltro(page) + '&exportType=' + exportType;
        window.open(
          `${apiURL}/api/contab/renuncia-receitas/report?${filtro}`,
          openTab,
          windowParams
        );
      } else {
        addErrorNotification(
          'A consulta não apresentou resultado. Não é possível exportar para o formato selecionado!'
        );
      }
    } else {
      addErrorNotification(
        'A consulta não apresentou resultado. Não é possível exportar para o formato selecionado!'
      );
    }
  };

  render() {
    const {
      page,
      filtros,
      location,
      tiposModalidades,
      tiposTributos
    } = this.props;
    const { exercicio } = StorageService.getItem('userInfo');
    const valor1 = parseInt(exercicio.id);
    const valor2 = valor1 + 1;
    const valor3 = valor2 + 1;

    return (
      <section>
        <PageHeader location={location} />

        <NotasExplicativas pathname={this.props.location.pathname} />

        <Panel
          isForm
          mobileClose
          title="Consulta em Renúncia de Receita"
          ref="panelFiltroRenunciaReceita"
        >
          <form>
            <Row>
              <Col xs={12} sm={4} md={3}>
                <Input
                  id="descTipoTributo"
                  name="descTipoTributo"
                  ref="descTipoTributo"
                  label="Espécie"
                  type="select"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('descTipoTributo')}
                >
                  <option value="-1">Selecione</option>
                  {tiposTributos.map(this.renderCombo)}
                </Input>
              </Col>
              <Col xs={12} sm={4} md={5}>
                <Input
                  id="descTipoModalidade"
                  name="descTipoModalidade"
                  ref="descTipoModalidade"
                  label="Tipo"
                  type="select"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('descTipoModalidade')}
                >
                  <option value="-1">Selecione</option>
                  {tiposModalidades.map(this.renderCombo)}
                </Input>
              </Col>
              <Col xs={12} sm={8} md={8}>
                <Input
                  id="setorBeneficiario"
                  name="setorBeneficiario"
                  ref="setorBeneficiario"
                  placeholder="Setor/Programa/Beneficiário"
                  label="Setor/Programa/Beneficiário"
                  type="text"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('setorBeneficiario')}
                />
              </Col>
              <Col xs={12} sm={8} md={8}>
                <Input
                  id="compensacao"
                  name="compensacao"
                  ref="compensacao"
                  placeholder="Medida"
                  label="Medida"
                  type="text"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('compensacao')}
                />
              </Col>
              <Col xs={12} sm={4} md={4}>
                <div className="form-group">
                  <label className="label"></label>
                  <div className="btn-group">
                    <button
                      id="btnFiltrar"
                      className="btn module-color icon-right"
                      type="submit"
                      ref="pesquisar"
                      onClick={this.consultarRenunciaReceita}
                    >
                      Pesquisar
                      <em className="fa fa-search"></em>
                    </button>
                    <button
                      id="btnResetFilter"
                      className="btn neutral icon-right inline no-text"
                      type="button"
                      onClick={this.onResetFiltro}
                    >
                      Limpar
                      <em className="fa fa-times"></em>
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </form>
        </Panel>

        <Panel isForm tableResponsive expansible={false}>
          <Row>
            <Col xs={4}>
              <UltimaAtualizacao modulo="3" tipo="contabRenunciaReceitas" />
            </Col>
            <Col xs={8}>
              <ExportButtonGroup onClick={this.onExporta} visible={true} />
            </Col>
          </Row>

          {page && page.get('content') && (
            <div className="panel-table-outer">
              <table className="panel-table striped fancy">
                <TableHeader
                  empty={page.get('content').isEmpty()}
                  text="Não foram encontrados registros."
                >
                  <tr>
                    <TableHeaderItem
                      field="descTipoTributo"
                      label="Espécie"
                      page={page}
                      searchWithPage={this.searchWithPage}
                    />
                    <TableHeaderItem
                      field="descTipoModalidade"
                      label="Tipo"
                      page={page}
                      searchWithPage={this.searchWithPage}
                    />
                    <TableHeaderItem
                      field="setorBeneficiario"
                      label="Setor/Programa/Beneficiário"
                      page={page}
                      searchWithPage={this.searchWithPage}
                    />
                    <TableHeaderItem
                      field="compensacao"
                      label="Medida/Justificativa"
                      page={page}
                      searchWithPage={this.searchWithPage}
                    />
                    <TableHeaderItem
                      field="descfundamentolegal"
                      label="Fundamento Legal"
                      page={page}
                      searchWithPage={this.searchWithPage}
                    />
                    <TableHeaderItem
                      field="idleis_atos"
                      label="Leis Atos"
                      page={page}
                      searchWithPage={this.searchWithPage}
                    />
                    <TableHeaderItem
                      field="valorUm"
                      label={valor1}
                      page={page}
                      searchWithPage={this.searchWithPage}
                      alignment="right"
                    />
                    <TableHeaderItem
                      field="valorDois"
                      label={valor2}
                      page={page}
                      searchWithPage={this.searchWithPage}
                      alignment="right"
                    />
                    <TableHeaderItem
                      field="valorTres"
                      label={valor3}
                      page={page}
                      searchWithPage={this.searchWithPage}
                      alignment="right"
                    />
                    <th />
                  </tr>
                </TableHeader>
                <tbody>
                  {page.get('content').map(this.renderRenunciaReceita)}
                </tbody>
              </table>
            </div>
          )}

          <SearchPagination page={page} searchWithPage={this.searchWithPage} />
        </Panel>
      </section>
    );
  }
}

function mapStateToProps(state) {
  const tiposModalidades = fromJS([
    'Anistia',
    'Remissão',
    'Subsídio',
    'Crédito Presumido',
    'Alteração de Alíquota ou Modificação de Base de Cálculo',
    'Outros Benefícios'
  ]);

  const tiposTributos = fromJS([
    'IPTU',
    'ITBI',
    'ISS',
    'CONTRIBUIÇÃO DE MELHORIA',
    'TAXAS',
    'OUTROS TRIBUTOS',
    'COSIP',
    'IMPUGNAÇÕES/IMPOSIÇÕES'
  ]);

  return {
    filtros: state.crud.getIn(['filtroRenuncias', 'currentRecord'], Map({})),
    page: state.contabRenunciaReceitas.get('renunciasReceita'),
    tiposTributos: tiposTributos,
    tiposModalidades: tiposModalidades
  };
}

const allActions = {
  ...actions,
  onChange,
  resetCrud,
  addErrorNotification
};

export default connect(mapStateToProps, allActions)(FormRenunciaReceita);
