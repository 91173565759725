import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';
import * as types from './Types.js';

const initialState = fromJS({
  emendas: [],
  emenda: [],
  convenio: {}
});

function emendaParlamentarReducer(state = initialState, action) {
  switch (action.type) {
    case types.CONVENIO_START:
    case types.EMENDAS_START:
    case types.CONSULTA_EMENDAS_START:
    case types.EMENDA_PARLAMENTAR_START: {
      return startLoad(state);
    }

    case types.EMENDAS_ERROR:
    case types.CONSULTA_EMENDAS_ERROR:
    case types.EMENDA_PARLAMENTAR_ERROR: {
      return endLoad(state);
    }

    case types.EMENDAS_SUCCESS:
    case types.CONSULTA_EMENDAS_SUCCESS: {
      return endLoad(state.set('emendas', fromJS(action.payload)));
    }

    case types.EMENDA_PARLAMENTAR_SUCCESS: {
      return endLoad(state.set('emenda', fromJS(action.payload)));
    }
    case types.CONVENIO_SUCCESS: {
      return endLoad(state.set('convenio', fromJS(action.payload)));
    }

    case types.RESET_CONSULTA_EMENDAS: {
      return initialState;
    }
  }

  return state;
}

export default emendaParlamentarReducer;
