import * as types from './Types.js';

export function searchProcessosSigilosos(filter) {
  const baseFilter = 'sigiloso=true&';

  return ({ fetch }) => ({
    type: types.SIGILOSOS_LIST,
    payload: {
      promise: fetch(`/api/processos/consulta-processos?${baseFilter}${filter}`)
    }
  });
}

export function searchProcessosDesclassificados(filter) {
  const baseFilter = 'desclassificado=true&';

  return ({ fetch }) => ({
    type: types.DESCLASSIFICADOS_LIST,
    payload: {
      promise: fetch(`/api/processos/consulta-processos?${baseFilter}${filter}`)
    }
  });
}
