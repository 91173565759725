import * as actions from './Actions.js';

import { Col, Row } from 'react-bootstrap';

import { Chart } from 'react-google-charts';
import Component from 'react-pure-render/component';
import DateUtils from 'portaltransparencia-common/libs/DateUtils';
import FormAtendimento from '../atendimento/Form.react.js';
import NotasExplicativas from '../notaexplicativa/Form.react';
import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react';
import PageHeader from '../components/PageHeader.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import PropTypes from 'prop-types';
import React from 'react';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import SumListField from 'portaltransparencia-common/components/SumListField.react.js';
import Widget from 'portaltransparencia-common/components/Widget.react.js';
import { connect } from 'react-redux';
import EstatisticasList from './EstatisticasList.react.js';

export class Estatisticas extends Component {
  static propTypes = {
    estatisticas: PropTypes.object.isRequired,
    estatisticasAno: PropTypes.object.isRequired,
    processosPorGenero: PropTypes.object.isRequired,
    processosPorIdade: PropTypes.object.isRequired,
    processosPorEscolaridade: PropTypes.object.isRequired,
    mediaRespostasAno: PropTypes.number.isRequired,
    mediaRespostas: PropTypes.number.isRequired,
    findEstatisticas: PropTypes.func.isRequired,
    findEstatisticasAno: PropTypes.func.isRequired,
    loadMediaRespostasAno: PropTypes.func.isRequired,
    loadMediaRespostas: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    findProcessosPorGenero: PropTypes.func.isRequired,
    findProcessosPorIdade: PropTypes.func.isRequired,
    findProcessosPorEscolaridade: PropTypes.func.isRequired,
    findRelatorioAnual: PropTypes.func.isRequired,
    relatorioAnual: PropTypes.object.isRequired
  };

  componentDidMount() {
    const {
      findEstatisticas,
      findEstatisticasAno,
      loadMediaRespostasAno,
      loadMediaRespostas,
      findProcessosPorGenero,
      findProcessosPorIdade,
      findProcessosPorEscolaridade,
      findRelatorioAnual
    } = this.props;

    const { exercicio, entidade } = StorageService.getItem('userInfo');

    findEstatisticas(entidade.id);
    findEstatisticasAno(entidade.id, exercicio.id);
    loadMediaRespostasAno(entidade.id, exercicio.id);
    loadMediaRespostas(entidade.id);
    findProcessosPorGenero(entidade.id, exercicio.id);
    findProcessosPorIdade(entidade.id, exercicio.id);
    findProcessosPorEscolaridade(entidade.id, exercicio.id);
    findRelatorioAnual(entidade.id, exercicio.id);
  }

  renderEstatisticasDoAno = (data, media, title) => {
    return (
      <Widget icon="fa fa-info" isForm title={title}>
        <div className="panel-table-outer">
          <table className="panel-table striped fancy">
            <tbody>
              <tr>
                <td>Pedidos</td>
                <td className="center">
                  <SumListField fieldName="pedidos" list={data} format="0,0" />
                </td>
              </tr>
              <tr>
                <td>Atendidos</td>
                <td className="center">
                  <SumListField
                    fieldName="atendidos"
                    list={data}
                    format="0,0"
                  />
                </td>
              </tr>
              <tr>
                <td>Cancelados</td>
                <td className="center">
                  <SumListField
                    fieldName="cancelados"
                    list={data}
                    format="0,0"
                  />
                </td>
              </tr>
              <tr>
                <td>Indeferidos</td>
                <td className="center">
                  <SumListField
                    fieldName="indeferidos"
                    list={data}
                    format="0,0"
                  />
                </td>
              </tr>
              <tr>
                <td>Média Resposta</td>
                <td className="center">
                  <NumberFormatter value={media} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Widget>
    );
  };

  renderEstatisticas = (data, media, title) => {
    return (
      <Widget icon="fa fa-info" isForm title={title}>
        <div className="panel-table-outer">
          <table className="panel-table striped fancy">
            <tbody>
              <tr>
                <td>Pedidos</td>
                <td className="center">
                  <SumListField fieldName="pedidos" list={data} format="0,0" />
                </td>
              </tr>
              <tr>
                <td>Atendidos</td>
                <td className="center">
                  <SumListField
                    fieldName="atendidos"
                    list={data}
                    format="0,0"
                  />
                </td>
              </tr>
              <tr>
                <td>Cancelados</td>
                <td className="center">
                  <SumListField
                    fieldName="cancelados"
                    list={data}
                    format="0,0"
                  />
                </td>
              </tr>
              <tr>
                <td>Indeferidos</td>
                <td className="center">
                  <SumListField
                    fieldName="indeferidos"
                    list={data}
                    format="0,0"
                  />
                </td>
              </tr>
              <tr>
                <td>Média Resposta</td>
                <td className="center">
                  <NumberFormatter value={media} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Widget>
    );
  };

  getDataChartBar = data => {
    const dataChart = [
      ['Mês', 'Atendidos', 'Cancelados', 'Indeferidos', 'Pedidos']
    ];

    return dataChart.concat(
      data
        .map(item => {
          return [
            DateUtils.getMonthDescription(item.get('mes'), true),
            item.get('atendidos'),
            item.get('cancelados'),
            item.get('indeferidos'),
            item.get('pedidos')
          ];
        })
        .toJS()
    );
  };

  getOptionsBar = () => {
    return {
      bar: { groupWidth: '90%' },
      legend: { position: 'top', maxLines: '2' },
      vAxis: { title: 'quantidade' },
      width: 500,
      height: 300
    };
  };

  renderChartBar = data => {
    const dataChart = this.getDataChartBar(data);
    const options = this.getOptionsBar();

    return (
      <Widget icon="fa fa-bar-chart" title="Estatística do ano">
        {data.size > 0 && (
          <Chart
            ref="chart"
            chartType="ColumnChart"
            data={dataChart}
            legend_toggle
            graphID="esic-bar-chart"
            options={options}
          />
        )}
      </Widget>
    );
  };

  getDataPorTipoValor = lista => {
    const dataChart = [['Label', 'Value']];

    return dataChart.concat(
      lista
        .map(item => {
          return [item.get('label'), item.get('value')];
        })
        .toJS()
    );
  };

  renderChartBarProcessosPorGenero = data => {
    const dataChart = this.getDataPorTipoValor(data);

    return (
      <Widget
        icon="fa fa-pie-chart"
        title="Estatística de processos por Gênero"
      >
        {data.size > 0 && (
          <Chart
            chartType="PieChart"
            data={dataChart}
            legend_toggle
            graphID="esic-pie-chart"
            options={this.getOptionsPie()}
          />
        )}
      </Widget>
    );
  };

  renderChartBarProcessosPorEscolaridade = data => {
    return (
      <Widget
        icon="fa fa-pie-chart"
        title="Estatística de processos por escolaridade"
      >
        {data.size > 0 && (
          <Chart
            chartType="PieChart"
            data={this.getDataPorTipoValor(data)}
            legend_toggle
            graphID="esic-pie-chart"
            options={this.getOptionsPie()}
          />
        )}
      </Widget>
    );
  };

  renderChartBarProcessosPorFaixaEtaria = data => {
    return (
      <Widget
        icon="fa fa-pie-chart"
        title="Estatística de processos por faixa etária"
      >
        {data.size > 0 && (
          <Chart
            chartType="PieChart"
            data={this.getDataPorTipoValor(data)}
            legend_toggle
            graphID="esic-pie-chart"
            options={this.getOptionsPie()}
          />
        )}
      </Widget>
    );
  };

  getOptionsPie = () => {
    return {
      width: 515,
      height: 325
    };
  };

  getDataChartPie = data => {
    let atendidos = 0;
    let cancelados = 0;
    let pendentes = 0;
    let indeferidos = 0;
    let pedidos = 0;

    data.map(item => {
      pedidos += item.get('pedidos');
      atendidos += item.get('atendidos');
      cancelados += item.get('cancelados');
      indeferidos += item.get('indeferidos');
      pendentes = pedidos - atendidos - cancelados - indeferidos;
    });

    return [
      ['Tipo', 'Quantidade'],
      ['Atendidos', atendidos],
      ['Cancelados', cancelados],
      ['Indeferidos', indeferidos],
      ['Pendentes', pendentes]
    ];
  };

  renderChartPie = data => {
    const dataChart = this.getDataChartPie(data);
    const options = this.getOptionsPie();

    return (
      <Widget icon="fa fa-pie-chart" title="Estatística do e-SIC">
        {data.size > 0 && (
          <Chart
            chartType="PieChart"
            data={dataChart}
            legend_toggle
            graphID="esic-pie-chart"
            options={options}
          />
        )}
      </Widget>
    );
  };

  render() {
    const {
      estatisticas,
      processosPorGenero,
      processosPorIdade,
      processosPorEscolaridade,
      estatisticasAno,
      mediaRespostasAno,
      mediaRespostas,
      location,
      relatorioAnual
    } = this.props;

    if ((estatisticas, estatisticasAno)) {
      return (
        <section>
          <PageHeader location={this.props.location} />
          <NotasExplicativas pathname={location.pathname} />

          <EstatisticasList relatorioAnual={relatorioAnual} />

          <Row>
            <Col md={6} sm={6}>
              {this.renderChartBar(estatisticasAno)}
            </Col>
            <Col md={6} sm={6}>
              {this.renderChartPie(estatisticas)}
            </Col>
          </Row>
          <Row>
            <Col md={6} sm={6}>
              {this.renderEstatisticasDoAno(
                estatisticasAno,
                mediaRespostasAno,
                'Estatísticas de Acesso à Informação e-SIC do ano'
              )}
            </Col>
            <Col md={6} sm={6}>
              {this.renderEstatisticas(
                estatisticas,
                mediaRespostas,
                'Desde a implantação do e-SIC'
              )}
            </Col>
          </Row>
          <Row>
            <Col md={6} sm={6}>
              {this.renderChartBarProcessosPorGenero(processosPorGenero)}
            </Col>
            <Col md={6} sm={6}>
              {this.renderChartBarProcessosPorFaixaEtaria(processosPorIdade)}
            </Col>
          </Row>
          <Row>
            <Col md={6} sm={6}>
              {this.renderChartBarProcessosPorEscolaridade(
                processosPorEscolaridade
              )}
            </Col>
          </Row>
          <Panel expansible={false}>
            <FormAtendimento />
          </Panel>
        </section>
      );
    }
    return null;
  }
}

function mapStateToProps(state) {
  return {
    estatisticas: state.esic.get('estatisticas'),
    estatisticasAno: state.esic.get('estatisticasAno'),
    mediaRespostas: state.esic.get('mediaRespostas'),
    mediaRespostasAno: state.esic.get('mediaRespostasAno'),
    processosPorGenero: state.esic.get('processosPorGenero'),
    processosPorIdade: state.esic.get('processosPorIdade'),
    processosPorEscolaridade: state.esic.get('processosPorEscolaridade'),
    relatorioAnual: state.esic.get('relatorioAnual')
  };
}

const allActions = {
  ...actions
};

export default connect(mapStateToProps, allActions)(Estatisticas);
