import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import NumberUtils from 'portaltransparencia-common/libs/NumberUtils.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import TableHeaderItem from 'portaltransparencia-common/components/TableHeaderItem.react.js';
import SearchPagination from 'portaltransparencia-common/components/SearchPagination.react.js';
import * as actions from './Actions.js';
import { connect } from 'react-redux';
import { addPagination } from 'portaltransparencia-common/libs/AddFilter.js';

export class SubvencaoArquivoSit extends Component {
  static propTypes = {
    page: PropTypes.object.isRequired,
    findSubvencaoArquivoSit: PropTypes.func.isRequired,
    filter: PropTypes.string
  };

  componentDidMount() {
    const { findSubvencaoArquivoSit } = this.props;
    let filtro = this.montaFiltro();
    findSubvencaoArquivoSit(filtro);
  }

  montaFiltro = page => {
    const { filter } = this.props;
    let filtro = filter;
    return addPagination(page, filtro);
  };

  onConsultaWithPage = page => {
    const { findSubvencaoArquivoSit } = this.props;
    let filtro = this.montaFiltro(page);
    findSubvencaoArquivoSit(filtro);
  };

  generateKey = data => {
    return `${data.get('entidade')}_
            ${data.get('exercicio')}_
            ${data.get('codigo')}`;
  };

  renderPrestacaoContas = data => {
    const key = this.generateKey(data);

    return (
      <TableActions ref="row" key={key} data={data}>
        <th>{data.get('codigo')}</th>
        <th>{data.get('nomeFavorecido')}</th>
        <th>{DateUtils.formatDateTimeShort(data.get('dataEmissao'))} </th>
        <th>
          {data.get('empenho')
            ? `${data.get('empenho')}/${data.get('exercicioEmpenho')}`
            : ''}
        </th>
        <th>{DateUtils.formatDateTimeShort(data.get('dataEmpenho'))}</th>
        <th style={{ textAlign: 'right' }}>
          {NumberUtils.format(data.get('valorEmpenho'))}
        </th>
        <th style={{ textAlign: 'right' }}>
          {NumberUtils.format(data.get('valor'))}
        </th>
        <th>{data.get('tipoDocumento')}</th>
      </TableActions>
    );
  };

  render() {
    const { page } = this.props;

    return (
      <div>
        <table className="panel-table striped fancy">
          <TableHeader
            empty={page.get('content') && page.get('content').isEmpty()}
            text="Sem registros."
          >
            <tr>
              <TableHeaderItem
                field="codigo"
                label="Cód. Prestação"
                page={page}
                searchWithPage={this.onConsultaWithPage}
                alignment="left"
              />
              <TableHeaderItem
                field="nomeFavorecido"
                label="Beneficiário"
                page={page}
                searchWithPage={this.onConsultaWithPage}
                alignment="left"
              />
              <TableHeaderItem
                field="dataEmissao"
                label="Data Emissão"
                page={page}
                searchWithPage={this.onConsultaWithPage}
                alignment="left"
              />
              <TableHeaderItem
                field="empenho"
                label="Empenho/Ano"
                page={page}
                searchWithPage={this.onConsultaWithPage}
                alignment="left"
              />
              <TableHeaderItem
                field="dataEmpenho"
                label="Data Empenho"
                page={page}
                searchWithPage={this.onConsultaWithPage}
                alignment="left"
              />
              <TableHeaderItem
                field="valorEmpenho"
                label="Valor Empenho"
                page={page}
                searchWithPage={this.onConsultaWithPage}
                alignment="right"
              />
              <TableHeaderItem
                field="valor"
                label="Valor"
                page={page}
                searchWithPage={this.onConsultaWithPage}
                alignment="right"
              />
              <TableHeaderItem
                field="tipoDocumento"
                label="Tipo Doc. Despesa"
                page={page}
                searchWithPage={this.onConsultaWithPage}
                alignment="left"
              />
            </tr>
          </TableHeader>
          <tbody>
            {page.get('content') &&
              page.get('content').map(this.renderPrestacaoContas)}
          </tbody>
        </table>

        <SearchPagination
          page={page}
          searchWithPage={this.onConsultaWithPage}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    page: state.subvencao.get('subvencaoArquivoSit')
  };
}

const allActions = {
  ...actions
};

export default connect(mapStateToProps, allActions)(SubvencaoArquivoSit);
