export const RESTOSAPAGARPROCESSADOS = 'RESTOSAPAGARPROCESSADOS';
export const RESTOSAPAGARPROCESSADOS_START = 'RESTOSAPAGARPROCESSADOS_START';
export const RESTOSAPAGARPROCESSADOS_SUCCESS =
  'RESTOSAPAGARPROCESSADOS_SUCCESS';
export const RESTOSAPAGARPROCESSADOS_ERROR = 'RESTOSAPAGARPROCESSADOS_ERROR';

export const RESTOSAPAGARNAOPROCESSADOS = 'RESTOSAPAGARNAOPROCESSADOS';
export const RESTOSAPAGARNAOPROCESSADOS_START =
  'RESTOSAPAGARNAOPROCESSADOS_START';
export const RESTOSAPAGARNAOPROCESSADOS_SUCCESS =
  'RESTOSAPAGARNAOPROCESSADOS_SUCCESS';
export const RESTOSAPAGARNAOPROCESSADOS_ERROR =
  'RESTOSAPAGARNAOPROCESSADOS_ERROR';

export const PROGRAMATICAORGAO = 'PROGRAMATICAORGAO';
export const PROGRAMATICAORGAO_START = 'PROGRAMATICAORGAO_START';
export const PROGRAMATICAORGAO_SUCCESS = 'PROGRAMATICAORGAO_SUCCESS';
export const PROGRAMATICAORGAO_ERROR = 'PROGRAMATICAORGAO_ERROR';

export const PROGRAMATICAUNIDADE = 'PROGRAMATICAUNIDADE';
export const PROGRAMATICAUNIDADE_START = 'PROGRAMATICAUNIDADE_';
export const PROGRAMATICAUNIDADE_SUCCESS = 'PROGRAMATICAUNIDADE_SUCCESS';
export const PROGRAMATICAUNIDADE_ERROR = 'PROGRAMATICAUNIDADE_ERROR';

export const PROGRAMATICAFUNCAO = 'PROGRAMATICAFUNCAO';
export const PROGRAMATICAFUNCAO_START = 'PROGRAMATICAFUNCAO_START';
export const PROGRAMATICAFUNCAO_SUCCESS = 'PROGRAMATICAFUNCAO_SUCCESS';
export const PROGRAMATICAFUNCAO_ERROR = 'PROGRAMATICAFUNCAO_ERROR';

export const PROGRAMATICASUBFUNCAO = 'PROGRAMATICASUBFUNCAO';
export const PROGRAMATICASUBFUNCAO_START = 'PROGRAMATICASUBFUNCAO_START';
export const PROGRAMATICASUBFUNCAO_SUCCESS = 'PROGRAMATICASUBFUNCAO_SUCCESS';
export const PROGRAMATICASUBFUNCAO_ERROR = 'PROGRAMATICASUBFUNCAO_ERROR';

export const PROGRAMATICAPROGRAMA = 'PROGRAMATICAPROGRAMA';
export const PROGRAMATICAPROGRAMA_START = 'PROGRAMATICAPROGRAMA_START';
export const PROGRAMATICAPROGRAMA_SUCCESS = 'PROGRAMATICAPROGRAMA_SUCCESS';
export const PROGRAMATICAPROGRAMA_ERROR = 'PROGRAMATICAPROGRAMA_ERROR';

export const PROGRAMATICAPROJETO = 'PROGRAMATICAPROJETO';
export const PROGRAMATICAPROJETO_START = 'PROGRAMATICAPROJETO_START';
export const PROGRAMATICAPROJETO_SUCCESS = 'PROGRAMATICAPROJETO_SUCCESS';
export const PROGRAMATICAPROJETO_ERROR = 'PROGRAMATICAPROJETO_ERROR';

export const PROGRAMATICAELEMENTO = 'PROGRAMATICAELEMENTO';
export const PROGRAMATICAELEMENTO_START = 'PROGRAMATICAELEMENTO_START';
export const PROGRAMATICAELEMENTO_SUCCESS = 'PROGRAMATICAELEMENTO_SUCCESS';
export const PROGRAMATICAELEMENTO_ERROR = 'PROGRAMATICAELEMENTO_ERROR';

export const PROGRAMATICADESDOBRAMENTO = 'PROGRAMATICADESDOBRAMENTO';
export const PROGRAMATICADESDOBRAMENTO_START =
  'PROGRAMATICADESDOBRAMENTO_START';
export const PROGRAMATICADESDOBRAMENTO_SUCCESS =
  'PROGRAMATICADESDOBRAMENTO_SUCCESS';
export const PROGRAMATICADESDOBRAMENTO_ERROR =
  'PROGRAMATICADESDOBRAMENTO_ERROR';

export const PROGRAMATICASUBDESDOBRAMENTO = 'PROGRAMATICASUBDESDOBRAMENTO';
export const PROGRAMATICASUBDESDOBRAMENTO_START =
  'PROGRAMATICASUBDESDOBRAMENTO_START';
export const PROGRAMATICASUBDESDOBRAMENTO_SUCCESS =
  'PROGRAMATICASUBDESDOBRAMENTO_SUCCESS';
export const PROGRAMATICASUBDESDOBRAMENTO_ERROR =
  'PROGRAMATICASUBDESDOBRAMENTO_ERROR';

export const PROGRAMATICAFONTERECURSO = 'PROGRAMATICAFONTERECURSO';
export const PROGRAMATICAFONTERECURSO_START = 'PROGRAMATICAFONTERECURSO_START';
export const PROGRAMATICAFONTERECURSO_SUCCESS =
  'PROGRAMATICAFONTERECURSO_SUCCESS';
export const PROGRAMATICAFONTERECURSO_ERROR = 'PROGRAMATICAFONTERECURSO_ERROR';
