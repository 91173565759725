import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';

import * as types from './Types.js';

const initialState = fromJS({
  ultimaAtualizacao: [],
  ultimaAtualizacaoByTable: {},
  ultimaAtualizacaoByTableComparativo: {},
  ultimaAtualizacaoDataConsulta: {},
  dataConsulta: {}
});

function ultimaAtualizacaoReducer(state = initialState, action) {
  switch (action.type) {
    case types.ULTIMAATUALIZACAO_START:
    case types.ULTIMAATUALIZACAO_BY_TABLE_START:
    case types.ULTIMAATUALIZACAO_BY_TABLE_COMPARATIVO_START:
    case types.DATA_CONSULTA_START: {
      return startLoad(state);
    }

    case types.ULTIMAATUALIZACAO_ERROR:
    case types.ULTIMAATUALIZACAO_BY_TABLE_ERROR:
    case types.ULTIMAATUALIZACAO_BY_TABLE_COMPARATIVO_ERROR:
    case types.DATA_CONSULTA_ERROR: {
      return endLoad(state);
    }

    case types.ULTIMAATUALIZACAO_SUCCESS: {
      return endLoad(state.set('ultimaAtualizacao', fromJS(action.payload)));
    }

    case types.ULTIMAATUALIZACAO_BY_TABLE_SUCCESS: {
      return endLoad(
        state.set('ultimaAtualizacaoByTable', fromJS(action.payload))
      );
    }

    case types.ULTIMAATUALIZACAO_BY_TABLE_COMPARATIVO_SUCCESS: {
      return endLoad(
        state.set('ultimaAtualizacaoByTableComparativo', fromJS(action.payload))
      );
    }

    case types.DATA_CONSULTA_SUCCESS: {
      return endLoad(state.set('dataConsulta', fromJS(action.payload)));
    }
  }

  return state;
}

export default ultimaAtualizacaoReducer;
