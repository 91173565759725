export const SUPRIMENTOS_FUNDOS_LIST = 'SUPRIMENTOS_FUNDOS_LIST';
export const SUPRIMENTOS_FUNDOS_LIST_START = 'SUPRIMENTOS_FUNDOS_LIST_START';
export const SUPRIMENTOS_FUNDOS_LIST_SUCCESS =
  'SUPRIMENTOS_FUNDOS_LIST_SUCCESS';
export const SUPRIMENTOS_FUNDOS_LIST_ERROR = 'SUPRIMENTOS_FUNDOS_LIST_ERROR';

export const SUPRIMENTOS_FUNDOS_TOTAL = 'SUPRIMENTOS_FUNDOS_TOTAL';
export const SUPRIMENTOS_FUNDOS_TOTAL_START = 'SUPRIMENTOS_FUNDOS_TOTAL_START';
export const SUPRIMENTOS_FUNDOS_TOTAL_SUCCESS =
  'SUPRIMENTOS_FUNDOS_TOTAL_SUCCESS';
export const SUPRIMENTOS_FUNDOS_TOTAL_ERROR = 'SUPRIMENTOS_FUNDOS_TOTAL_ERROR';
