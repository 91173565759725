import configuration from 'portaltransparencia-common/config/Config.js';

import * as types from './Types.js';

export function findAlvaras(filter) {
  return ({ fetch }) => ({
    type: types.ALVARAS,
    payload: fetch(`/api/alvara?${filter}`)
  });
}

export function findAlvaraDetalhes(filter) {
  return ({ fetch }) => ({
    type: types.ALVARA,
    payload: fetch(`/api/alvara/detalhe?${filter}`)
  });
}

export function findAlvaraArquivos(filter) {
  return ({ fetch }) => ({
    type: types.ALVARA_ARQUIVOS,
    payload: fetch(`/api/alvara/detalhe/arquivos?${filter}`)
  });
}

export function downloadArquivo(id) {
  window.open(`${configuration.apiURL}/api/alvara/arquivo/${id}`, '_blank');

  return {
    type: types.ARQUIVO_DOWNLOAD,
    payload: id
  };
}
