import { Map } from 'immutable';
import BotaoImprimir from 'portaltransparencia-common/components/BotaoImprimir.react.js';
import ExportButtonGroup from 'portaltransparencia-common/components/ExportButtonGroup.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import configuration from 'portaltransparencia-common/config/Config.js';
import {
  getAllValuesFromUrlSearchParams,
  getValueFromUrlSearchParams
} from 'portaltransparencia-common/libs/URLUtils';
import PropTypes, { string } from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Helmet from 'react-helmet';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';
import {
  onChange,
  resetCrud
} from 'portaltransparencia-common/crud/Actions.js';
import { addFilterUrl } from 'portaltransparencia-common/libs/AddFilter.js';

import PageHeader from '../components/PageHeader.react.js';
import CabecalhoEntidade from '../entidades/CabecalhoEntidade.react.js';
import UltimaAtualizacao from '../ultimaatualizacao/Form.react.js';
import * as actions from './Actions.js';
import RepasseDetalhe from './RepasseDetalhe.react.js';
import { addErrorNotification } from 'portaltransparencia-common/ui/Actions.js';
import DatePicker from 'portaltransparencia-common/components/DatePicker.react.js';
import FilterButtons from '../components/FilterButtons.react';

export class FormRepasseDetail extends Component {
  static propTypes = {
    repassesMes: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    findRepassesRecebidosMes: PropTypes.func,
    findRepassesEnviadosMes: PropTypes.func,
    filtros: PropTypes.object,
    onChange: PropTypes.func,
    resetCrud: PropTypes.func,
    addErrorNotification: PropTypes.func.isRequired
  };

  componentDidMount() {
    const {
      location,
      findRepassesRecebidosMes,
      findRepassesEnviadosMes,
      resetCrud
    } = this.props;
    const params = getAllValuesFromUrlSearchParams(location);

    resetCrud('filtroRepasses');

    const filtro = this.montarFiltro();

    if (params.tipo == this.TIPO_RECEBIDO) {
      findRepassesRecebidosMes(filtro);
    } else {
      findRepassesEnviadosMes(filtro);
    }
  }

  TIPO_RECEBIDO = 'R';
  TIPO_ENVIADO = 'E';

  onExporta = (exportType, openTab, windowParams) => {
    const { apiURL } = configuration;
    const { location, repassesMes, addErrorNotification } = this.props;

    if (!repassesMes.isEmpty()) {
      const params = getAllValuesFromUrlSearchParams(location);

      let repasse = `entidade=${params.entidade}&exercicio=${params.exercicio}&conta=${params.conta}&tipo=${params.tipo}`;

      if (params.tipo == this.TIPO_ENVIADO) {
        repasse = repasse + `&idContaCorrente=${params.idContaCorrente}`;
      }

      window.open(
        `${apiURL}/api/repasses/report/mes?${repasse}&exportType=${exportType}`,
        openTab,
        windowParams
      );
    } else {
      addErrorNotification(
        'A consulta não apresentou resultado. Não é possível exportar para o formato selecionado!'
      );
    }
  };

  getTitulo = () => {
    const params = getAllValuesFromUrlSearchParams(this.props.location);
    if (params.tipo == this.TIPO_RECEBIDO) {
      return 'Recebidos';
    } else if (params.tipo == this.TIPO_ENVIADO) {
      return 'Enviados';
    }
    return '';
  };

  onChangeFiltro = event => {
    const { onChange } = this.props;

    onChange('filtroRepasses', event);
  };

  onResetFiltro = () => {
    const { resetCrud } = this.props;

    resetCrud('filtroRepasses');
    this.onConsulta();
  };

  montarFiltro = function () {
    const { location, filtros } = this.props;
    const params = getAllValuesFromUrlSearchParams(location);

    let filter = '';
    filter = addFilterUrl(filter, `entidade=${params.entidade}`);
    filter = addFilterUrl(filter, `exercicio=${params.exercicio}`);

    if (params.conta) {
      filter = addFilterUrl(filter, `conta=${params.conta}`);
    }

    if (params.idContaCorrente) {
      filter = addFilterUrl(
        filter,
        `idContaCorrente=${params.idContaCorrente}`
      );
    }

    if (
      filtros.get('dataInicial') &&
      filtros.get('dataInicial').length === 10
    ) {
      filter = addFilterUrl(
        filter,
        `dataInicial=${filtros.get('dataInicial')}`
      );
    }

    if (filtros.get('dataFinal') && filtros.get('dataFinal').length === 10) {
      filter = addFilterUrl(filter, `dataFinal=${filtros.get('dataFinal')}`);
    }

    return filter;
  };

  onConsulta = () => {
    const {
      location,
      findRepassesEnviadosMes,
      findRepassesRecebidosMes
    } = this.props;
    const tipo = getAllValuesFromUrlSearchParams(location).tipo;
    const filtro = this.montarFiltro();

    if (tipo == this.TIPO_RECEBIDO) {
      return findRepassesRecebidosMes(filtro);
    } else {
      return findRepassesEnviadosMes(filtro);
    }
  };

  render() {
    const { repassesMes, location, filtros } = this.props;

    return (
      <section>
        <Helmet title={'Repasses - Detalhado'} />

        <PageHeader location={location} />

        <CabecalhoEntidade />
        <Panel
          title={`Repasses ${this.getTitulo()}`}
          isTable
          tableResponsive
          expansible={false}
        >
          <form>
            <Row>
              <Col xs={6} sm={3} md={3}>
                <DatePicker
                  id="dataInicial"
                  name="dataInicial"
                  ref="dataInicial"
                  label="Data Inicial do Lançamento"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('dataInicial')}
                />
              </Col>
              <Col xs={6} sm={3} md={3}>
                <DatePicker
                  id="dataFinal"
                  name="dataFinal"
                  ref="dataFinal"
                  label="Data Final do Lançamento"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('dataFinal')}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={3} md={3}>
                <FilterButtons
                  idBtnReset="btnResetFilter"
                  onConsulta={this.onConsulta}
                  onReset={this.onResetFiltro}
                />
              </Col>
            </Row>
          </form>
        </Panel>

        <Panel isForm expansible={false} tableResponsive>
          <Row>
            <Col xs={6}>
              <UltimaAtualizacao modulo="3" />
            </Col>
            <Col xs={6}>
              <ExportButtonGroup onClick={this.onExporta} visible={true} />
            </Col>
          </Row>

          <BotaoImprimir />

          <RepasseDetalhe repasses={repassesMes} />
        </Panel>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    filtros: state.crud.getIn(['filtroRepasses', 'currentRecord'], Map({})),
    repassesMes: state.repasse.get('repassesMes')
  };
}

const allActions = {
  ...actions,
  onChange,
  resetCrud,
  addErrorNotification
};

export default connect(mapStateToProps, allActions)(FormRepasseDetail);
