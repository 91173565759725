import Link from 'portaltransparencia-common/components/link/Link.react.js';
import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import { addHttp } from 'portaltransparencia-common/libs/LinkExterno.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import * as actions from './Actions.js';

export class EmpenhoDetalheLinks extends Component {
  static propTypes = {
    links: PropTypes.object.isRequired
  };

  onClickLink = item => {
    window.open(addHttp(item.get('link')));
  };

  renderItens = data => {
    let key = data.get('id') + '_' + data.get('numero');
    const isLeaf = !data.has('list');

    return (
      <tr ref="row" key={key}>
        <td id="tipoLink">{data.get('tipoLink')}</td>
        <td id="noAno">{data.get('noAno')}</td>

        <td id="numero">{data.get('numero')}</td>
        <td id="link">
          {isLeaf && data.get('link') && (
            <Link
              value={data.get('descricao')}
              icon="fa fa-external-link"
              data={data}
              onClick={this.onClickLink}
            />
          )}
        </td>
      </tr>
    );
  };

  render() {
    const { links } = this.props;

    return (
      <div className="panel-table-outer">
        <table className="panel-table striped fancy">
          <TableHeader empty={links.isEmpty()}>
            <tr>
              <th>Tipo Link</th>
              <th>Nº/Ano</th>
              <th>Número</th>
              <th>Link</th>
            </tr>
          </TableHeader>
          <tbody>{links.map(this.renderItens)}</tbody>
        </table>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    links: state.empenhos.get('links')
  };
}

const allActions = {
  ...actions
};

export default connect(mapStateToProps, allActions)(EmpenhoDetalheLinks);
