import * as wizardActions from 'portaltransparencia-common/components/wizard/Actions.js';
import Wizard from 'portaltransparencia-common/components/wizard/Wizard.react.js';
import WizardContentItem from 'portaltransparencia-common/components/wizard/WizardContentItem.react.js';
import focusInvalidField from 'portaltransparencia-common/libs/focusInvalidField.js';
import { validaCpfCnpj } from 'portaltransparencia-common/libs/ValidaCPFCNPJ.js';
import { validaEmail } from 'portaltransparencia-common/libs/ValidaEmail.js';
import {
  addErrorNotification,
  addSuccessNotification,
  offsidebarToggle
} from 'portaltransparencia-common/ui/Actions.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import * as actions from './Actions.js';
import FormSolicitacaoDetailEndereco from './FormSolicitacaoDetailEndereco.react.js';
import FormSolicitacaoDetailPedido from './FormSolicitacaoDetailPedido.react.js';
import FormSolicitacaoDetailPessoa from './FormSolicitacaoDetailPessoa.react.js';
import { FormSolicitacaoDetailTipo } from './FormSolicitacaoDetailTipo.react.js';

export class SolicitacaoEsicOffSide extends Component {
  static propTypes = {
    onChangeDados: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    saveProcesso: PropTypes.func.isRequired,
    addSuccessNotification: PropTypes.func.isRequired,
    addErrorNotification: PropTypes.func.isRequired,
    offsidebarToggle: PropTypes.func,
    findPessoa: PropTypes.func.isRequired,
    loadEscolaridades: PropTypes.func.isRequired,
    dados: PropTypes.object,
    assuntos: PropTypes.object,
    previousStep: PropTypes.func.isRequired,
    nextStep: PropTypes.func.isRequired,
    goToStep: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    cadastrada: PropTypes.any,
    paramPermiteSolicitacaoAnonima: PropTypes.string
  };

  componentDidMount() {
    const { loadEscolaridades, loadAssuntos } = this.props;

    loadEscolaridades();
    loadAssuntos();
  }

  onChangeDados = event => {
    const { onChange } = this.props;

    onChange('solicitacaoEsic', event);
  };

  findPessoa = () => {
    const { findPessoa, dados } = this.props;
    return findPessoa(dados.get('cnpj_cpf'));
  };

  validarDadosTipo = callback => {
    this.refs.detailTipo.validate(error => {
      if (error) {
        focusInvalidField(this.refs.detailTipo, error);
      } else {
        callback();
      }
    });
  };

  validarDadosPessoa = () => {
    const { nextStep } = this.props;

    this.refs.detailPessoa.validate(error => {
      if (error) {
        focusInvalidField(this.refs.detailPessoa, error);
      } else {
        nextStep();
      }
    });
  };

  isEmpty = value => {
    return value === '' || value === undefined ? true : false;
  };

  validarDadosEndereco = () => {
    const { nextStep, dados } = this.props;

    if (
      this.isEmpty(dados.get('logradouro')) &&
      this.isEmpty(dados.get('numero')) &&
      this.isEmpty(dados.get('bairro')) &&
      this.isEmpty(dados.get('cep')) &&
      this.isEmpty(dados.get('cidade')) &&
      this.isEmpty(dados.get('uf')) &&
      this.isEmpty(dados.get('complemento'))
    ) {
      nextStep();
    } else {
      this.refs.detailEndereco.validate(error => {
        if (error) {
          focusInvalidField(this.refs.detailEndereco, error);
        } else {
          nextStep();
        }
      });
    }
  };

  saveProcesso = () => {
    const { saveProcesso, dados, reset, offsidebarToggle } = this.props;

    this.refs.detailPedido.validate(error => {
      if (error) {
        focusInvalidField(this.refs.detailPedido, error);
        return;
      }

      saveProcesso(dados).then(reset);

      offsidebarToggle();
    });
  };

  handlePreviousClick = () => {
    const { dados, previousStep, goToStep } = this.props;

    switch (dados.get('activeStep')) {
      case 4: {
        if (dados.get('tipoSolicitacao') == 'I') {
          previousStep();
        } else {
          goToStep(1);
        }
        break;
      }

      default: {
        previousStep();
      }
    }
  };

  handleNextClick = () => {
    const { dados, nextStep, goToStep, addErrorNotification } = this.props;

    switch (dados.get('activeStep')) {
      case 1: {
        this.validarDadosTipo(() => {
          if (dados.get('tipoSolicitacao') == 'I') {
            this.findPessoa()
              .then(() => {
                nextStep();
              })
              .catch(() => {
                addErrorNotification(
                  'Nenhuma pessoa encontrada, preencha seu cadastro.'
                );
              });
          } else {
            goToStep(4);
          }
        });
        break;
      }
      case 2: {
        this.validarDadosPessoa();
        break;
      }
      case 3: {
        this.validarDadosEndereco();
        break;
      }
      case 4: {
        this.saveProcesso();
        break;
      }
    }
  };

  renderProcessoReferencia = dados => {
    return (
      <div className="info warning center mt-xs">
        Recurso ao Processo {dados.get('processoReferencia')}
      </div>
    );
  };

  onClickCancel = () => {
    const { goToStep, reset, offsidebarToggle } = this.props;
    goToStep(1);
    reset();
    offsidebarToggle();
  };

  render() {
    const {
      dados,
      cadastrada,
      paramPermiteSolicitacaoAnonima,
      isIntegradoOxy,
      assuntos
    } = this.props;

    return (
      <Wizard>
        <WizardContentItem
          activeStep={dados.get('activeStep')}
          stepNumber={1}
          stepTitle="Tipo de Solicitação - e-Sic"
          handlePreviousClick={this.handlePreviousClick}
          handleNextClick={this.handleNextClick}
          onClickCancel={this.onClickCancel}
        >
          {dados.get('processoReferencia') &&
            this.renderProcessoReferencia(dados)}
          <FormSolicitacaoDetailTipo
            ref="detailTipo"
            dados={dados}
            onChangeDados={this.onChangeDados}
            permiteSolicitacaoAnonima={paramPermiteSolicitacaoAnonima}
          />
        </WizardContentItem>

        {dados.get('tipoSolicitacao') === 'I' && (
          <WizardContentItem
            activeStep={dados.get('activeStep')}
            stepNumber={2}
            stepTitle="Dados da Pessoa"
            handlePreviousClick={this.handlePreviousClick}
            handleNextClick={this.handleNextClick}
            onClickCancel={this.onClickCancel}
          >
            {dados.get('processoReferencia') &&
              this.renderProcessoReferencia(dados)}
            <FormSolicitacaoDetailPessoa
              ref="detailPessoa"
              dados={dados}
              cadastrada={cadastrada}
              onChangeDados={this.onChangeDados}
            />
          </WizardContentItem>
        )}
        {dados.get('tipoSolicitacao') === 'I' && (
          <WizardContentItem
            activeStep={dados.get('activeStep')}
            stepNumber={3}
            stepTitle="Dados do Endereço"
            handlePreviousClick={this.handlePreviousClick}
            handleNextClick={this.handleNextClick}
            onClickCancel={this.onClickCancel}
          >
            {dados.get('processoReferencia') &&
              this.renderProcessoReferencia(dados)}
            <FormSolicitacaoDetailEndereco
              ref="detailEndereco"
              dados={dados}
              cadastrada={cadastrada}
              onChangeDados={this.onChangeDados}
            />
          </WizardContentItem>
        )}
        <WizardContentItem
          activeStep={dados.get('activeStep')}
          stepNumber={4}
          stepTitle="Dados do Pedido"
          handlePreviousClick={this.handlePreviousClick}
          handleNextClick={this.handleNextClick}
          onClickCancel={this.onClickCancel}
          nextButtonLabel="Enviar"
        >
          {dados.get('processoReferencia') &&
            this.renderProcessoReferencia(dados)}
          <FormSolicitacaoDetailPedido
            ref="detailPedido"
            dados={dados}
            assuntos={assuntos}
            onChangeDados={this.onChangeDados}
            isIntegradoOxy={isIntegradoOxy}
          />
        </WizardContentItem>
      </Wizard>
    );
  }
}

function mapStateToProps(state) {
  return {
    dados: state.esic.get('currentRecord'),
    cadastrada: state.esic.get('cadastrada'),
    assuntos: state.esic.get('assuntos'),
    isIntegradoOxy: state.entidade.getIn([
      'configuracoesGerais',
      'isIntegradoOxy'
    ])
  };
}

const allActions = {
  ...actions,
  ...wizardActions,
  addSuccessNotification,
  addErrorNotification,
  offsidebarToggle,
  validaCpfCnpj,
  validaEmail
};

export default connect(mapStateToProps, allActions)(SolicitacaoEsicOffSide);
