import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import { Component } from 'react';
import React from 'react';
import PropTypes from 'prop-types';
import TableActions from 'portaltransparencia-common/components/TableActions.react';
import { Row } from 'react-bootstrap';
import { Col } from '@elotech/components';

export class ObraDetalheResponsavel extends Component {
  static propTypes = {
    responsaveis: PropTypes.object.isRequired
  };

  renderResponsaveis = data => {
    return (
      <TableActions ref="row" key={data.get('oxyId')} data={data}>
        <td>{data.get('pessoaNome')}</td>
        <td>{data.get('tipo')}</td>
        <td>{data.get('tipoDescricao')}</td>
      </TableActions>
    );
  };

  render() {
    const { responsaveis } = this.props;

    return (
      <Row>
        <Col md={12}>
          <div className="panel-table-outer">
            <table className="panel-table striped fancy">
              <TableHeader empty={responsaveis.isEmpty()}>
                <tr>
                  <th>Nome</th>
                  <th>Tipo</th>
                  <th>Descrição</th>
                </tr>
              </TableHeader>
              <tbody>{responsaveis.map(this.renderResponsaveis)}</tbody>
            </table>
          </div>
        </Col>
      </Row>
    );
  }
}

export default ObraDetalheResponsavel;
