import Panel from 'portaltransparencia-common/components/Panel.react.js';
import { getValueFromUrlSearchParams } from 'portaltransparencia-common/libs/URLUtils';
import {
  PARAM_SALARIOBASE,
  PARAM_USAWEBSERVICE_MARINGA
} from 'portaltransparencia-common/parametros/ParametrosPortalTransparencia';
import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import PageHeader from '../components/PageHeader.react.js';
import ParametroService from '../parametros/ParametroService.js';
import * as actions from './Actions.js';
import EstagiarioDetalheFinanceiro from './EstagiarioDetalheFinanceiro.react.js';
import EstagiarioDetalheInformacao from './EstagiarioDetalheInformacao.react.js';

export class FormEstagiarioDetail extends Component {
  static propTypes = {
    estagiario: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    findEstagiario: PropTypes.func.isRequired,
    clearDetalhe: PropTypes.func.isRequired,
    paramWebService: PropTypes.string,
    paramExibeSalarioBase: PropTypes.string
  };

  componentDidMount() {
    const { findEstagiario, findEstagiarioPorVinculo, location } = this.props;
    const codigo = getValueFromUrlSearchParams(location, 'codigo');
    const vinculo = getValueFromUrlSearchParams(location, 'vinculo');

    if (vinculo) {
      findEstagiarioPorVinculo(vinculo, codigo);
    } else {
      findEstagiario(codigo);
    }
  }

  componentWillUnmount() {
    const { clearDetalhe } = this.props;
    clearDetalhe();
  }

  render() {
    const {
      estagiario,
      location,
      paramWebService,
      paramExibeSalarioBase
    } = this.props;

    return (
      <div>
        <Helmet title={`Estagiários - ${estagiario.get('nome')}`} />
        <PageHeader
          location={location}
          textoDetalhe={`Estagiário: ${estagiario.get('nome')}`}
        />
        {estagiario && (
          <EstagiarioDetalheInformacao
            estagiario={estagiario}
            usaWebService={paramWebService == '1'}
            exibeSalarioBase={paramExibeSalarioBase === 'S'}
          />
        )}
        {estagiario.get('dadosFinanceiros') &&
          !estagiario.get('dadosFinanceiros').isEmpty() && (
            <Panel
              isTable
              tableResponsive
              expansible={false}
              title="Dados Financeiros"
            >
              <EstagiarioDetalheFinanceiro
                dadosFinanceiros={estagiario.get('dadosFinanceiros')}
              />
            </Panel>
          )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    estagiario: state.servidor.get('estagiario'),
    paramWebService: ParametroService.getValorParametro(
      state,
      PARAM_USAWEBSERVICE_MARINGA
    ),
    paramExibeSalarioBase: ParametroService.getValorParametro(
      state,
      PARAM_SALARIOBASE
    )
  };
}

export default connect(mapStateToProps, actions)(FormEstagiarioDetail);
