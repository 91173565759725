import $ from 'jquery';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';

export class Helper extends Component {
  componentDidMount() {
    $('.helper-opener').click(function () {
      $('.helper').addClass('active');
    });

    function goToStage(where) {
      //ir para estágio
      var oldClass = $('.helper').attr('class');
      var newClass = oldClass.replace(/[^0-9]/g, '');

      if (where == 'next') {
        newClass = parseInt(newClass) + 1;
      } else if (where == 'prev') {
        newClass = parseInt(newClass) - 1;
      } else if (where == 'start') {
        newClass = -1;
      }

      //limites
      if (newClass == '-1') {
        newClass = 0;
      }
      if (newClass == '22') {
        newClass = 21;
      }

      $('.helper').removeClass('stage' + oldClass);
      $('.helper').addClass('active stage' + newClass);

      //mostrar mensagem do estágio selecionado
      $('.helper').find('.message').removeClass('active');
      $('.helper')
        .find('.message' + newClass)
        .addClass('active');
    }

    //Clique do botão do helper
    $('.helper .helper-message .btn').click(function () {
      if ($(this).hasClass('next')) {
        goToStage('next');
      } else if ($(this).hasClass('prev')) {
        goToStage('prev');
      } else if ($(this).hasClass('close')) {
        goToStage('start');
        $('.helper').removeClass('active');
      }

      if ($('.helper').hasClass('stage2')) {
        $('.menu-area').addClass('active');
      } else {
        $('.menu-area').removeClass('active');
      }
    });
  }

  render() {
    return (
      <div className="helper stage0">
        <div className="helper-blur top"></div>
        <div className="helper-blur right"></div>
        <div className="helper-blur bottom"></div>
        <div className="helper-blur left"></div>
        <div className="helper-highlight"></div>

        <div className="helper-message">
          <div className="message message0 active">
            <h1 className="center helper-title">Olá</h1>
            <h3 className="center helper-title">Precisa de ajuda?</h3>
            Este tutorial mostrará como utilizar os mecanismos desta plataforma.
            Se deseja saber como tudo funciona clique no botão{' '}
            <b>&apos;Próximo&apos;</b>, caso já saiba como utilizar clique no
            botão <b>&apos;Concluir Tutorial&apos;</b>.
          </div>

          <div className="message message1">
            Clicando aqui você poderá expandir o <b>menu</b>, o mesmo pode ser
            acessado em qualquer página da plataforma.
          </div>

          <div className="message message2">
            O <b>menu</b> expandido fica desta forma.
          </div>

          <div className="message message3">
            Esta área indica de qual <b>entidade e ano</b> são os dados que você
            está visualizado.
          </div>

          <div className="message message4">
            Estes são os <b>botões de acessibilidade</b> que auxiliará caso você
            esteja com dificuldade para visualizar e/ou encontrar informações.
          </div>

          <div className="message message5">
            Estes três botões alterarão o tamanho das letras. A funcionalidade
            destes botões são <b>restaurar ao tamanho inicial</b>,{' '}
            <b>aumentar</b> e <b>diminuir</b> as letras, respectivamente.
          </div>

          <div className="message message6">
            Este é o botão <b>Inverter Cores</b> que inverterá a cor do portal
            para cores mais contrastadas.
          </div>

          <div className="message message7">
            Este é o botão <b>Ajuda</b> que lhe redirecionará para uma página ou
            documento com informações detalhadas do portal.
          </div>

          <div className="message message8">
            Este é o botão <b>Pesquisar</b>, ao clicar nele abrirá um campo
            permitindo você pesquisar em todo o portal qualquer termo.
          </div>

          <div className="message message9">
            Este é o botão <b>Acesso À Informação</b> mais conhecido com{' '}
            <b>E-Sic</b>, ao clicar nele abrirá uma caixa no canto direito da
            tela permitindo você realizar sua solicitação de informação.
          </div>

          <div className="message message10">
            <img src="img/misc/helper-buttons-pt.png" />
            Haverão funcionalidades com um ou mais botões [imagem] localizados
            no canto inferior direito. Estes são destinado a impressão, adição,
            exclusão ou qualquer outra ação condizente com a página.
          </div>

          <div className="message message11">
            <img src="img/misc/helper-buttons-pt-a.png" />
            Neste portal da transparência você poderá exportar as tabelas de
            listagem com extesões essênciais. Esta ação pode ser feita clicando
            no botões no canto superior direito de cada tabela.
          </div>

          <div className="message message12">
            <h1 className="center">
              <big>
                <em className="fa fa-thumbs-o-up"></em>
              </big>
            </h1>
            <h2 className="helper-title center">É isso!</h2>O tutorial chegou ao
            fim, para utilizar a plataforma clique no botão{' '}
            <b>&apos;Concluir Tutorial&apos;</b>. Obrigado!
          </div>

          <div className="helper-buttons">
            <Row>
              <Col xs={5}>
                <button className="btn positive icon-right close">
                  Concluir Tutorial
                  <em className="fa fa-check"></em>
                </button>
              </Col>

              <Col xs={7}>
                <div className="right">
                  <button className="btn neutral icon-left inline prev">
                    <em className="fa fa-chevron-left"></em>
                    Anterior
                  </button>

                  <button className="btn module-color icon-right inline next">
                    Próximo
                    <em className="fa fa-chevron-right"></em>
                  </button>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

export default Helper;
