import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export default class InativoRow extends Component {
  static propTypes = {
    inativo: PropTypes.object.isRequired
  };

  onViewDetalhes = item => {
    this.props.history.push(
      `/inativos/detalhes?vinculo=${item.get('vinculo')}&matricula=${item.get(
        'matricula'
      )}`
    );
  };

  render() {
    const { inativo, usaWebService } = this.props;
    const key = `${inativo.get('entidade')}_${inativo.get('matricula')}`;

    return (
      <TableActions
        ref="row"
        key={key}
        data={inativo}
        onMore={this.onViewDetalhes}
      >
        <td id="tableNome">{inativo.get('nome')}</td>
        <td id="tableMatricula">{inativo.get('matricula')}</td>
        <td id="tableSituacao">{inativo.get('situacao')}</td>
        <td id="tableDescricaoCargo">{inativo.get('descricaoCargo')}</td>
        {!usaWebService && (
          <td id="tableDescricaoClasse">{inativo.get('descricaoClasse')}</td>
        )}
        <td id="tableDescricaoLotacao">{inativo.get('descricaoLotacao')}</td>
      </TableActions>
    );
  }
}
