import Widget from 'portaltransparencia-common/components/Widget.react.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils';
import NumberUtils from 'portaltransparencia-common/libs/NumberUtils.js';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Chart } from 'react-google-charts';
import Component from 'react-pure-render/component';

export class GastosPorTipo extends Component {
  static propTypes = {
    width: PropTypes.number.isRequired,
    gastosPorTipoMes: PropTypes.object.isRequired,
    tipo: PropTypes.string.isRequired,
    descricao: PropTypes.object,
    titulo: PropTypes.string.isRequired,
    grupoPublicacaoRelatorioLRF: PropTypes.number.isRequired,
    subGrupoPublicacaoRelatorioLRF: PropTypes.number.isRequired
  };

  getDataChartBar = data => {
    const dataChart = [['Mês', 'Empenhado', 'Liquidado', 'Pago']];

    return dataChart.concat(
      data
        .map(item => {
          return [
            DateUtils.getMonthDescription(item.get('mes'), true),
            item.get('valorEmpenhado'),
            item.get('valorLiquidado'),
            item.get('valorPago')
          ];
        })
        .toJS()
    );
  };

  getTotalGasto = () => {
    let totalGasto = 0;
    this.props.gastosPorTipoMes.map(item => {
      if (item.get('valorPago') > totalGasto) {
        totalGasto = item.get('valorPago');
      }
    });

    return totalGasto;
  };

  getOptions = () => {
    let { width } = this.props;
    return {
      legend: { position: 'top' },
      width: width,
      height: 300,
      colors: ['#DC3912', '#FF9900', '#109618']
    };
  };

  showPublicacoes = () => {
    this.props.history.push(
      `publicacoes/${this.props.grupoPublicacaoRelatorioLRF}/${this.props.subGrupoPublicacaoRelatorioLRF}`
    );
  };

  getDescricaoGrupoPublicacao = () => {
    if (this.props.grupoPublicacaoRelatorioLRF === 1) {
      return '(Execução Orçamentária - RREO)';
    }
    return '(Gestão Fiscal - RGF)';
  };

  render() {
    const { gastosPorTipoMes, tipo, descricao, titulo } = this.props;
    if (!gastosPorTipoMes) {
      return null;
    }

    const options = this.getOptions();
    const dataChart = this.getDataChartBar(gastosPorTipoMes);
    const { exercicio } = StorageService.getItem('userInfo');

    return (
      <Widget
        icon="fa fa-bar-chart"
        title={`${titulo}`}
        isChart
        iconHint="fa fa-question"
        showHint
        messageHint={descricao}
      >
        <Row>
          <Col xs={12} sm={12} md={9}>
            {gastosPorTipoMes.size > 0 && (
              <Chart
                ref="chart"
                chartType="ColumnChart"
                data={dataChart}
                legend_toggle
                graphID="dashboard-bar-chart"
                options={options}
                width={`${options.width}px`}
              />
            )}
          </Col>
          <Col xs={12} sm={12} md={3}>
            <div style={{ height: '300px', display: 'table' }}>
              <article
                className="widget-numeric"
                style={{
                  verticalAlign: 'middle',
                  display: 'table-cell',
                  padding: '0 20px 0 0'
                }}
              >
                <b className="widget-numeric-number">
                  {NumberUtils.format(this.getTotalGasto())}
                </b>
                <p className="widget-numeric-subtitle">{`Total gasto com ${tipo} em ${exercicio.id}`}</p>
              </article>
            </div>
          </Col>
          <a
            style={{ cursor: 'pointer', marginLeft: '30px' }}
            onClick={this.showPublicacoes}
          >
            {`Relatórios da Lei de Responsabilidade Fiscal ${this.getDescricaoGrupoPublicacao()}`}
          </a>
        </Row>
      </Widget>
    );
  }
}

export default GastosPorTipo;
