import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';
import ListUtils from 'portaltransparencia-common/libs/ListUtils.js';

import * as types from './Types.js';

const initialState = fromJS({
  conselhos: [],
  conselheiros: [],
  conselhosFiltrados: []
});

function conselhoReducer(state = initialState, action) {
  switch (action.type) {
    case types.PESQUISA_CONSELHOS_SUCCESS: {
      return endLoad(
        state
          .set('conselhos', fromJS(action.payload))
          .set('conselhosFiltrados', fromJS(action.payload))
      );
    }

    case types.PESQUISA_CONSELHO_START: {
      return startLoad(state);
    }
    case types.PESQUISA_CONSELHO_SUCCESS: {
      return endLoad(state.set('conselheiros', fromJS(action.payload)));
    }

    case types.FILTRAR_CONSELHOS: {
      const listaFiltrada = ListUtils.filterTree(
        fromJS(action.payload),
        action.meta,
        ['nome'],
        'list'
      );
      return state.set('conselhosFiltrados', listaFiltrada);
    }

    case types.RESETAR_CONSELHOS: {
      const conselhos = state.get('conselhos').toJS();
      return state.set('conselhosFiltrados', fromJS(conselhos));
    }
  }

  return state;
}

export default conselhoReducer;
