import Panel from 'portaltransparencia-common/components/Panel.react.js';
import {
  onChange,
  resetCrud
} from 'portaltransparencia-common/crud/Actions.js';
import {
  addFilterUrl,
  addPagination
} from 'portaltransparencia-common/libs/AddFilter.js';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PageHeader from '../components/PageHeader.react.js';
import Input from 'portaltransparencia-common/components/EloInput.react.js';
import { addErrorNotification } from 'portaltransparencia-common/ui/Actions.js';
import NotasExplicativas from '../notaexplicativa/Form.react';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import SearchPagination from 'portaltransparencia-common/components/SearchPagination.react.js';
import TableActions from 'portaltransparencia-common/components/TableActions.react';
import * as actions from './Actions.js';
import { Map } from 'immutable';
import TableHeaderItem from 'portaltransparencia-common/components/TableHeaderItem.react';

export class FormEstruturaOrganizacional extends Component {
  static propTypes = {
    page: PropTypes.object.isRequired,
    findEstruturasPaginado: PropTypes.func.isRequired,
    filtros: PropTypes.object,
    onChange: PropTypes.func,
    location: PropTypes.object.isRequired,
    resetCrud: PropTypes.func.isRequired,
    addErrorNotification: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.consultaEstruturas();
  }

  consultaEstruturas = event => {
    const { page } = this.props;
    const initialPage = page.set('number', 0);
    this.searchWithPage(initialPage);
    event && event.preventDefault();
  };

  resetarFiltrosAndConsultar = () => {
    const { resetCrud, findEstruturasPaginado } = this.props;
    resetCrud('filtroEstruturas');
    findEstruturasPaginado('');
  };

  searchWithPage = page => {
    const { filtros, findEstruturasPaginado } = this.props;

    const descricao = filtros.get('descricao');
    let filter = '';

    if (descricao) {
      filter = addFilterUrl(filter, `search=${descricao}`);
    }

    findEstruturasPaginado(addPagination(page, filter));
  };

  onChangeFiltro = event => {
    const { onChange } = this.props;

    onChange('filtroEstruturas', event);
  };

  renderEstruturas = estrutura => {
    return (
      <TableActions
        id="btnAction"
        ref="row"
        key={estrutura.get('id')}
        data={estrutura}
        onMore={this.onViewDetalhes}
      >
        <td>{estrutura.get('codigo')}</td>
        <td>{estrutura.get('descricao')}</td>
        <td>{estrutura.get('quantidadeServidores')}</td>
      </TableActions>
    );
  };

  onViewDetalhes = item => {
    const idLotacao = item.get('id');
    let url = `/estrutura-organizacional/${idLotacao}`;
    this.props.history.push(url);
  };

  render() {
    const { page, filtros } = this.props;

    if (!page.has('content')) {
      return null;
    }

    return (
      <section>
        <PageHeader location={this.props.location} />
        <NotasExplicativas pathname={this.props.location.pathname} />

        <Panel
          title="Consulta Estrutura Organizacional"
          mobileClose
          isForm
          ref="panelFiltroEstrutura"
        >
          <form>
            <Row>
              <Col sm={8} md={8}>
                <Input
                  id="descricao"
                  name="descricao"
                  ref="descricao"
                  label="Descrição da Lotação"
                  type="text"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('descricao')}
                />
              </Col>
              <Col sm={4} md={4}>
                <div className="form-group">
                  <label className="label" />

                  <div className="btn-group">
                    <button
                      id="btnFiltrar"
                      className="btn module-color icon-right"
                      ref="filtrar"
                      onClick={this.consultaEstruturas}
                    >
                      Pesquisar
                      <em className="fa fa-search" />
                    </button>

                    <button
                      id="btnReset"
                      className="btn neutral icon-right inline no-text"
                      type="button"
                      onClick={this.resetarFiltrosAndConsultar}
                    >
                      Limpar
                      <em className="fa fa-times" />
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </form>
        </Panel>
        <Panel isForm tableResponsive expansible={false}>
          <div className="panel-table-outer">
            <table className="panel-table striped fancy">
              <TableHeader
                empty={page.get('content').isEmpty()}
                text="Sem registros"
              >
                <tr>
                  <TableHeaderItem
                    field="codigo"
                    label="Código Lotação"
                    page={page}
                    searchWithPage={this.searchWithPage}
                  />
                  <TableHeaderItem
                    field="nome"
                    label="Descrição Lotação"
                    page={page}
                    searchWithPage={this.searchWithPage}
                  />
                  <TableHeaderItem
                    field="quantidadeServidores"
                    label="Quantidade de Servidores"
                    page={page}
                    searchWithPage={this.searchWithPage}
                  />
                  <th />
                </tr>
              </TableHeader>
              <tbody>{page.get('content').map(this.renderEstruturas)}</tbody>
            </table>
          </div>
          <SearchPagination page={page} searchWithPage={this.searchWithPage} />
        </Panel>
      </section>
    );
  }
}

function mapStateToProps(state) {
  const filtros = state.crud.getIn(
    ['filtroEstruturas', 'currentRecord'],
    Map()
  );
  const page = state.estrutura.get('estruturas');
  return {
    filtros,
    page
  };
}

const allActions = {
  ...actions,
  onChange,
  resetCrud,
  addErrorNotification
};

export default connect(
  mapStateToProps,
  allActions
)(FormEstruturaOrganizacional);
