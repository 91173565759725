import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export default class FilterButtons extends Component {
  static propTypes = {
    idBtnPesquisa: PropTypes.string,
    idBtnReset: PropTypes.string,
    onConsulta: PropTypes.func.isRequired,
    onReset: PropTypes.func.isRequired
  };

  static defaultProps = {
    idBtnPesquisa: 'btnFiltrar',
    idBtnReset: 'btnReset'
  };

  onClickConsulta = e => {
    if (this.props.onConsulta) {
      this.props.onConsulta(e);
    }

    e && e.preventDefault();
    e && e.stopPropagation();
  };

  onClickReset = e => {
    if (this.props.onReset) {
      this.props.onReset(e);
    }

    e && e.preventDefault();
    e && e.stopPropagation();
  };

  render() {
    const { idBtnPesquisa, idBtnReset } = this.props;

    return (
      <div className="form-group">
        <label className="label"></label>
        <div className="btn-group">
          <button
            id={idBtnPesquisa}
            className="btn module-color icon-right inline"
            ref="pesquisar"
            onClick={this.onClickConsulta}
          >
            Pesquisar
            <em className="fa fa-search"></em>
          </button>
          <button
            id={idBtnReset}
            className="btn neutral icon-right inline no-text"
            ref="limpar"
            onClick={this.onClickReset}
          >
            Limpar
            <em className="fa fa-times"></em>
          </button>
        </div>
      </div>
    );
  }
}
