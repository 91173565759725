import classnames from 'classnames';
import { List } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

import MenuItem from './MenuItem.react.js';

export class SubMenu extends Component {
  static propTypes = {
    menu: PropTypes.instanceOf(List).isRequired,
    onClose: PropTypes.func.isRequired,
    active: PropTypes.bool
  };

  render() {
    const { menu, active, onClose } = this.props;

    const classes = classnames({
      children: true,
      active: active
    });

    return (
      <ul className={classes}>
        {menu &&
          menu.map(menuItem => {
            return (
              <MenuItem
                menu={menuItem}
                key={`${menuItem.get('id')}_${menuItem.get(
                  'route'
                )}_${menuItem.get('title')}`}
                onClose={onClose}
              />
            );
          })}
      </ul>
    );
  }
}

export default SubMenu;
