import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import TableHeaderItem from 'portaltransparencia-common/components/TableHeaderItem.react';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

import InativoRow from './InativoRow.react';

export default class InativoTable extends Component {
  static propTypes = {
    page: PropTypes.object.isRequired,
    searchWithPage: PropTypes.func.isRequired
  };

  renderInativos = data => {
    const key = `${data.get('entidade')}_${data.get('codigo')}`;

    return <InativoRow key={key} inativo={data} history={this.props.history} />;
  };

  render() {
    const { page, usaWebService, searchWithPage } = this.props;

    let inativos;
    if (page) {
      inativos = page.get('content');
    }

    if (!inativos) {
      return null;
    }
    return (
      <div className="panel-table-outer">
        <table className="panel-table striped fancy">
          <TableHeader
            empty={inativos.isEmpty()}
            text="Não foi encontrado nenhum inativo para a pesquisa selecionada."
          >
            <tr>
              <TableHeaderItem
                field="nome"
                label="Nome do(a) Inativo(a)"
                page={page}
                searchWithPage={searchWithPage}
              />
              <TableHeaderItem
                field="matricula"
                label="Matrícula"
                page={page}
                searchWithPage={searchWithPage}
              />
              <TableHeaderItem
                field="situacao"
                label="Situação"
                page={page}
                searchWithPage={searchWithPage}
              />
              <TableHeaderItem
                field="descricaoCargo"
                label="Cargo / Função"
                page={page}
                searchWithPage={searchWithPage}
              />
              {!usaWebService && (
                <TableHeaderItem
                  field="descricaoClasse"
                  label="Classe / Natureza"
                  page={page}
                  searchWithPage={searchWithPage}
                />
              )}
              <TableHeaderItem
                field="descricaoLotacao"
                label="Lotação"
                page={page}
                searchWithPage={searchWithPage}
              />
              <th />
            </tr>
          </TableHeader>

          <tbody>{inativos.map(this.renderInativos)}</tbody>
        </table>
      </div>
    );
  }
}
