import ExportButtonGroup from 'portaltransparencia-common/components/ExportButtonGroup.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react.js';
import TableHeaderItem from 'portaltransparencia-common/components/TableHeaderItem.react';
import configuration from 'portaltransparencia-common/config/Config.js';
import NumberUtils from 'portaltransparencia-common/libs/NumberUtils.js';
import storageService from 'portaltransparencia-common/libs/StorageService.js';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';

import UltimaAtualizacaoByTable from '../ultimaatualizacao/UltimaAtualizacaoByTable.react.js';
import AdiantamentoListagemItem from './AdiantamentoListagemItem.react.js';
import { connect } from 'react-redux';
import * as actions from './Actions.js';
import { addErrorNotification } from 'portaltransparencia-common/ui/Actions.js';

export class AdiantamentoListagem extends Component {
  static propTypes = {
    totalAdiantamentos: PropTypes.object.isRequired,
    filter: PropTypes.string,
    page: PropTypes.object,
    searchWithPage: PropTypes.func,
    addErrorNotification: PropTypes.func.isRequired,
    adiantamentolistagem: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = { showConfirmacao: false };
  }

  onExporta = (exportType, openTab, windowParams) => {
    const { apiURL } = configuration;
    const { filter, page, addErrorNotification } = this.props;
    const adiantamentos = page.get('content');

    if (adiantamentos.size > 0) {
      let filtro = filter + '&exportType=' + exportType;

      window.open(
        `${apiURL}/api/adiantamentos/report?${filtro}`,
        openTab,
        windowParams
      );
    } else {
      addErrorNotification(
        'A consulta não apresentou resultado. Não é possível exportar para o formato selecionado!'
      );
    }
  };

  generateKey = adiantamento => {
    return `${adiantamento.get('entidade')}_
            ${adiantamento.get('exercicio')}_
            ${adiantamento.get('prestacaoContas')}_
            ${adiantamento.get('empenho')}_
            ${adiantamento.get('exercicioEmpenho')}_
            ${adiantamento.get('noLiquidacao')}`;
  };

  renderAdiantamentos = data => {
    return (
      <AdiantamentoListagemItem
        key={this.generateKey(data)}
        adiantamento={data}
        history={this.props.history}
      />
    );
  };

  render() {
    const {
      totalAdiantamentos,
      page,
      searchWithPage,
      permiteExcederPrestacaoContas
    } = this.props;
    const { entidade } = storageService.getItem('userInfo');

    const adiantamentos = page.get('content');

    if (!adiantamentos.isEmpty) {
      permiteExcederPrestacaoContas = adiantamentos
        .get(0)
        .get('permiteExcederPrestacaoContas');
    }

    return (
      <Panel isForm tableResponsive expansible={false}>
        <Row>
          <Col xs={6}>
            <UltimaAtualizacaoByTable
              modulo="3"
              table="prestacaocontas"
              column="dataultimaatualizacao"
            />
          </Col>
          <Col xs={6}>
            <ExportButtonGroup onClick={this.onExporta} visible={true} />
          </Col>
        </Row>

        <div className="panel-table-outer">
          <table className="panel-table striped fancy">
            <TableHeader empty={adiantamentos.isEmpty()}>
              <tr>
                {entidade && entidade.id <= 0 && (
                  <TableHeaderItem
                    field="entidade"
                    label="Entidade"
                    page={page}
                    searchWithPage={searchWithPage}
                  />
                )}
                <TableHeaderItem
                  field="prestacaoContas"
                  label="Cód. Prestação"
                  page={page}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="nome"
                  label="Funcionário"
                  page={page}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="dataPrestacao"
                  label="Data Prestação"
                  page={page}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="empenho"
                  label="Empenho/Ano"
                  page={page}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="dataEmpenho"
                  label="Data Empenho"
                  page={page}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="valorEmpenho"
                  label="Valor Empenho"
                  page={page}
                  searchWithPage={searchWithPage}
                  alignment="right"
                />
                <TableHeaderItem
                  field="valorDocumentos"
                  label="Valor Notas"
                  page={page}
                  searchWithPage={searchWithPage}
                  alignment="right"
                />
                <TableHeaderItem
                  field="valorRestituido"
                  label="Valor Restituido"
                  page={page}
                  searchWithPage={searchWithPage}
                  alignment="right"
                />
                {adiantamentos.get(0) != null &&
                  adiantamentos.get(0).get('permiteExcederPrestacaoContas') ==
                    'S' && (
                    <TableHeaderItem
                      field="valorExcedido"
                      label="Valor Excedido"
                      page={page}
                      searchWithPage={searchWithPage}
                      alignment="right"
                    />
                  )}
                <TableHeaderItem
                  field="cargo"
                  label="Cargo"
                  page={page}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="lotacao"
                  label="Lotação"
                  page={page}
                  searchWithPage={searchWithPage}
                />
                <th></th>
              </tr>
            </TableHeader>
            <tbody>{adiantamentos.map(this.renderAdiantamentos)}</tbody>
            {!adiantamentos.isEmpty && (
              <tfoot>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <i>Total: </i>
                    <br />
                    {NumberUtils.format(totalAdiantamentos.get('valorEmpenho'))}
                  </td>
                  <td>
                    <i>Total: </i>
                    <br />
                    {NumberUtils.format(
                      totalAdiantamentos.get('valorDocumentos')
                    )}
                  </td>
                  <td>
                    <i>Total: </i>
                    <br />
                    {NumberUtils.format(
                      totalAdiantamentos.get('valorRestituido')
                    )}
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tfoot>
            )}
          </table>
        </div>
      </Panel>
    );
  }
}

function mapStateToProps(state) {}

const allActions = {
  ...actions,
  addErrorNotification
};

export default connect(mapStateToProps, allActions)(AdiantamentoListagem);
