import { List } from 'immutable';
import configuration from 'portaltransparencia-common/config/Config.js';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import ResultadoPesquisaItem from './ResultadoPesquisaItem.react.js';

export class ResultadoPesquisa extends React.PureComponent {
  static propTypes = {
    list: PropTypes.instanceOf(List).isRequired,
    valorPesquisado: PropTypes.string.isRequired
  };

  render() {
    const { list, valorPesquisado } = this.props;
    const { apiURL } = configuration;

    if (list.isEmpty()) {
      return (
        <div>
          Não encontramos nenhum resultado para sua pesquisa.
          <hr className="mt-xs mb-xs" />
          Você também pode utilizar os seguintes canais para lhe auxiliar:
          <ol className="listing mt-xs ml-xs">
            <li className="listing-item">
              <Link to="/perguntas"> Perguntas Frequentes </Link>
            </li>
            <li className="listing-item">
              <a href={`${apiURL}/api/files/ajuda`} target="_blank">
                Ajuda
              </a>
            </li>
          </ol>
        </div>
      );
    }

    return (
      <div>
        <ol className="listing ml-xs">
          {list.map(item => (
            <ResultadoPesquisaItem
              item={item}
              key={`${item.get('id')}_${item.get('title')}`}
              valorPesquisado={valorPesquisado}
              father
            />
          ))}
        </ol>
      </div>
    );
  }
}

export default ResultadoPesquisa;
