import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';
import ParametroService from '../parametros/ParametroService.js';
import { addHttp } from 'portaltransparencia-common/libs/LinkExterno';
import {
  PARAM_CONTATOS_COVID,
  PARAM_CONTATOS_COVID_LINK,
  PARAM_CONTATOS_COVID_ARQUIVO,
  PARAM_CONTATOS_COVID_TITULO,
  PARAM_CONTATOS_COVID_DESCRICAO
} from 'portaltransparencia-common/parametros/ParametrosPortalTransparencia';
import configuration from 'portaltransparencia-common/config/Config.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';

export class FormContatoCovid extends Component {
  static propTypes = {
    paramContatosCovid: PropTypes.string,
    paramContatosCovidLink: PropTypes.string,
    paramContatosCovidArquivo: PropTypes.string,
    paramContatosCovidTitulo: PropTypes.string,
    paramContatosCovidDescricao: PropTypes.string
  };

  componentDidMount = (openTab, windowParams) => {
    const {
      paramContatosCovid,
      paramContatosCovidLink,
      paramContatosCovidArquivo
    } = this.props;
    const LINK = 1;
    const ARQUIVO = 2;

    if (paramContatosCovid == LINK) {
      window.open(addHttp(`${paramContatosCovidLink}`), openTab, windowParams);
      this.props.history.push('');
    } else if (paramContatosCovid == ARQUIVO) {
      window.open(
        `${configuration.apiURL}/api/files/arquivo/${paramContatosCovidArquivo}`,
        '_blank'
      );
      this.props.history.push('');
    }
  };

  render() {
    const {
      paramContatosCovidTitulo,
      paramContatosCovidDescricao
    } = this.props;
    return (
      <Panel title={paramContatosCovidTitulo} expansible={false}>
        <article>
          <h4>{paramContatosCovidDescricao}</h4>
        </article>
      </Panel>
    );
  }
}

function mapStateToProps(state) {
  return {
    dados: state.contato.get('currentRecord'),
    paramContatosCovid: ParametroService.getValorParametro(
      state,
      PARAM_CONTATOS_COVID
    ),
    paramContatosCovidLink: ParametroService.getValorParametro(
      state,
      PARAM_CONTATOS_COVID_LINK
    ),
    paramContatosCovidArquivo: ParametroService.getValorParametro(
      state,
      PARAM_CONTATOS_COVID_ARQUIVO
    ),
    paramContatosCovidTitulo: ParametroService.getValorParametro(
      state,
      PARAM_CONTATOS_COVID_TITULO
    ),
    paramContatosCovidDescricao: ParametroService.getValorParametro(
      state,
      PARAM_CONTATOS_COVID_DESCRICAO
    )
  };
}

export default connect(mapStateToProps)(FormContatoCovid);
