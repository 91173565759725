import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import { downloadArquivo } from '../arquivo/Actions';
import * as actions from './Actions.js';

export class ManualFornecedor extends Component {
  static propTypes = {
    manualFornecedor: PropTypes.object.isRequired,
    findManualFornecedor: PropTypes.func.isRequired,
    downloadArquivo: PropTypes.func.isRequired
  };

  componentDidMount() {
    const { findManualFornecedor } = this.props;

    findManualFornecedor();
  }

  downloadManualFornecedor = event => {
    event.preventDefault();
    const { downloadArquivo, manualFornecedor } = this.props;
    downloadArquivo(manualFornecedor.get('id'));
  };

  render() {
    const { manualFornecedor } = this.props;
    if (!manualFornecedor.isEmpty()) {
      return (
        <button
          id="btnManualFornecedor"
          className="btn module-color inline icon-right mt-xs"
          onClick={this.downloadManualFornecedor}
        >
          {manualFornecedor.get('nome')}
          <em className="fa fa-download"></em>
        </button>
      );
    }
    return null;
  }
}

function mapStateToProps(state) {
  return {
    manualFornecedor: state.licitacao.get('manualFornecedor')
  };
}

const allActions = {
  ...actions,
  downloadArquivo
};

export default connect(mapStateToProps, allActions)(ManualFornecedor);
