import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';

import * as types from './Types.js';

const initialState = fromJS({
  perguntas: []
});

function perguntaReducer(state = initialState, action) {
  switch (action.type) {
    case types.PERGUNTAS_LIST_START: {
      return startLoad(state);
    }

    case types.PERGUNTAS_LIST_SUCCESS: {
      return endLoad(state.set('perguntas', fromJS(action.payload)));
    }

    case types.PERGUNTAS_LIST_ERROR: {
      return endLoad(state);
    }
  }

  return state;
}

export default perguntaReducer;
