import * as types from './Types.js';

export function findRepassesRecebidos(filtro) {
  let url = '/api/repasses/recebidos';

  if (filtro !== '') {
    url += '?' + filtro;
  }

  return ({ fetch }) => ({
    type: types.REPASSES_RECEBIDOS_PAGE,
    payload: {
      promise: fetch(url)
    }
  });
}

export function findRepassesRecebidosMes(filtro) {
  return ({ fetch }) => ({
    type: types.REPASSES_MES,
    payload: {
      promise: fetch(`/api/repasses/mes?${filtro}&tipo=R`)
    }
  });
}
