import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import TableHeaderItem from 'portaltransparencia-common/components/TableHeaderItem.react';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';

import UltimaAtualizacao from '../ultimaatualizacao/Form.react.js';

class PesquisaItensConsumidos extends Component {
  static propTypes = {
    valorTotal: PropTypes.number.isRequired,
    itensConsumo: PropTypes.object.isRequired,
    searchWithPage: PropTypes.func.isRequired
  };

  renderItensConsumo = itensConsumo => (
    <TableActions key={itensConsumo.get('item')} data={itensConsumo}>
      <td>{DateUtils.formatDateTimeShort(itensConsumo.get('data'))}</td>
      <td>{itensConsumo.get('numero')}</td>
      <td>
        {itensConsumo.get('centroCustoOrigem')} -{' '}
        {itensConsumo.get('centroCustoOrigemDescricao')}
      </td>
      <td>
        {itensConsumo.get('centroCustoDestino')} -{' '}
        {itensConsumo.get('centroCustoDestinoDescricao')}
      </td>
      <td>
        <div className="right">
          <NumberFormatter value={itensConsumo.get('valorTotal')} />
        </div>
      </td>
    </TableActions>
  );

  render() {
    const { itensConsumo, searchWithPage, valorTotal } = this.props;

    if (!itensConsumo.has('content')) {
      return null;
    }

    return (
      <Panel isForm tableResponsive expansible={false}>
        <Row>
          <Col xs={6}>
            <UltimaAtualizacao modulo="19" />
          </Col>
          <Col xs={6}>
            <div className="mt-xs right">
              {'Valor total consumido: R$ '}
              <NumberFormatter value={valorTotal} />
            </div>
          </Col>
        </Row>

        <div className="panel-table-outer">
          <table className="panel-table striped fancy">
            <TableHeader empty={itensConsumo.get('content').isEmpty()}>
              <tr>
                <TableHeaderItem
                  field="data"
                  label="Data"
                  page={itensConsumo}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="numero"
                  label="Número"
                  page={itensConsumo}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="centroCustoOrigem"
                  label="CC. Origem"
                  page={itensConsumo}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="centroCustoDestino"
                  label="CC. Destino"
                  page={itensConsumo}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="valorTotal"
                  label="Valor Total"
                  page={itensConsumo}
                  searchWithPage={searchWithPage}
                  alignment="right"
                />
                <th></th>
              </tr>
            </TableHeader>
            <tbody>
              {itensConsumo
                .get('content')
                .map(this.renderItensConsumo)
                .toArray()}
            </tbody>
          </table>
        </div>
      </Panel>
    );
  }
}

export default PesquisaItensConsumidos;
