import ExportButtonGroup from 'portaltransparencia-common/components/ExportButtonGroup.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import SearchPagination from 'portaltransparencia-common/components/SearchPagination.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import configuration from 'portaltransparencia-common/config/Config.js';
import { changeFieldValue } from 'portaltransparencia-common/crud/Actions.js';
import { addPagination } from 'portaltransparencia-common/libs/AddFilter.js';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import PageHeader from '../components/PageHeader.react.js';
import EmpenhosFiltro from '../empenhos/FormFiltro.react.js';
import NotasExplicativas from '../notaexplicativa/Form.react';
import UltimaAtualizacaoByTable from '../ultimaatualizacao/UltimaAtualizacaoByTable.react.js';
import * as actions from './Actions.js';
import ComprasDiretasItensTBodyList from './ComprasDiretasItensTBodyList.react.js';
import { addErrorNotification } from 'portaltransparencia-common/ui/Actions.js';

export class FormComprasDiretaItem extends Component {
  static propTypes = {
    page: PropTypes.object.isRequired,
    findComprasDiretasItens: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    changeFieldValue: PropTypes.func.isRequired,
    addErrorNotification: PropTypes.func.isRequired
  };

  componentDidMount() {
    const { findComprasDiretasItens, changeFieldValue } = this.props;
    changeFieldValue('filtroEmpenhos', 'comprasDireta', true);

    const { entidade, exercicio } = StorageService.getItem('userInfo');
    findComprasDiretasItens(
      `search= id.entidade==${entidade.id} and id.exercicio==${exercicio.id} &comprasDireta=true`
    );
  }

  onExporta = (exportType, openTab, windowParams) => {
    const { page, addErrorNotification } = this.props;
    const { apiURL } = configuration;
    const { entidade, exercicio } = StorageService.getItem('userInfo');

    if (!page.get('content').isEmpty()) {
      let filter = this.refs.empenhosFiltro
        ? this.refs.empenhosFiltro.getFiltro(page)
        : '';
      let filtro = filter + '&exportType=' + exportType;
      window.open(
        `${apiURL}/api/compras-direta/itens/report?${filtro}&entidade=${entidade.id}&exercicio=${exercicio.id}`,
        openTab,
        windowParams
      );
    } else {
      addErrorNotification(
        'A consulta não apresentou resultado. Não é possível exportar para o formato selecionado!'
      );
    }
  };

  onConsultaWithPage = page => {
    const { findComprasDiretasItens } = this.props;

    let filter = this.refs.empenhosFiltro.getFiltro(page);
    findComprasDiretasItens(addPagination(page, filter));
  };

  render() {
    const { page, location, findComprasDiretasItens } = this.props;

    if (!page.has('content')) {
      return null;
    }

    const { exercicio } = StorageService.getItem('userInfo');

    return (
      <section>
        <PageHeader location={this.props.location} />

        <NotasExplicativas pathname={this.props.location.pathname} />

        <EmpenhosFiltro
          ref="empenhosFiltro"
          location={location}
          actionExterno={findComprasDiretasItens}
          fromTelaCompras
          expansible={false}
        />

        <form>
          <Panel
            title={`Compras Diretas por Itens do Exercício de ${exercicio.id}`}
            isForm
            tableResponsive
            expansible={false}
          >
            <Row>
              <Col xs={6}>
                <UltimaAtualizacaoByTable
                  modulo="3"
                  table="empenho"
                  column="dataultimaatualizacao"
                />
              </Col>
              <Col xs={6}>
                <ExportButtonGroup onClick={this.onExporta} visible={true} />
              </Col>
            </Row>
            <div className="panel-table-outer">
              <table className="panel-table striped fancy">
                <TableHeader empty={page.get('content').isEmpty()}>
                  <tr>
                    <th>Empenho</th>
                    <th>Data</th>
                    <th>Tipo</th>
                    <th>Processo</th>
                    <th>Fornecedor</th>
                    <th>Item</th>
                    <th>Qtde</th>
                    <th className="right">Valor Unitário</th>
                    <th className="right">Valor Total</th>
                    <th></th>
                  </tr>
                </TableHeader>
                <ComprasDiretasItensTBodyList
                  history={this.props.history}
                  compras={page.get('content')}
                  onView={this.onViewEmpenhos}
                />
              </table>
            </div>
          </Panel>

          <SearchPagination
            page={page}
            searchWithPage={this.onConsultaWithPage}
          />
        </form>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    page: state.compradiretaitem.get('comprasDiretasItens')
  };
}

const allActions = {
  ...actions,
  changeFieldValue,
  addErrorNotification
};

export default connect(mapStateToProps, allActions)(FormComprasDiretaItem);
