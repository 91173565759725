import React from 'react';
import Component from 'react-pure-render/component';
import PropTypes from 'prop-types';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import TableHeaderItem from 'portaltransparencia-common/components/TableHeaderItem.react';
import TableActions from 'portaltransparencia-common/components/TableActions.react';
import DateUtils from 'portaltransparencia-common/libs/DateUtils';
import { Ellipsis } from 'portaltransparencia-common/components/Ellipsis.react';

export default class SuspensaoFornecedoresTable extends Component {
  static propTypes = {
    page: PropTypes.object.isRequired,
    searchWithPage: PropTypes.func.isRequired,
    onDownload: PropTypes.func.isRequired,
    isIntegradoOxy: PropTypes.bool
  };

  renderFornecedores = fornecedores => {
    const { onDownload, isIntegradoOxy } = this.props;
    const key = `${fornecedores.get('pessoa')}${fornecedores.get(
      'dataIniSuspensao'
    )}`;

    return (
      <TableActions
        ref="row"
        key={key}
        data={fornecedores.get('arquivoId')}
        onDownload={onDownload}
      >
        <td>{fornecedores.get('nome')}</td>
        <td>{fornecedores.get('cnpjCpf')}</td>
        {!isIntegradoOxy && <td>{fornecedores.get('situacaoDescricao')}</td>}
        <td>
          {DateUtils.transform(fornecedores.get('dataIniSuspensao')) ||
            'Data não informada'}
        </td>
        <td>
          {DateUtils.transform(fornecedores.get('dataFinSuspensao')) ||
            'Data não informada'}
        </td>
        <td>
          <Ellipsis value={fornecedores.get('observacao')} maxLength={150} />
        </td>
      </TableActions>
    );
  };

  render() {
    const { page, searchWithPage, isIntegradoOxy } = this.props;
    let fornecedores = page.get('content');

    return (
      <div className="panel-table-outer">
        <table className="panel-table striped fancy">
          <TableHeader
            empty={fornecedores.isEmpty()}
            text="Não foi encontrado nenhum fornecedor para a pesquisa selecionada."
          >
            <tr>
              <TableHeaderItem
                field={isIntegradoOxy ? `pessoa.nome` : `nome`}
                label="Nome"
                page={page}
                searchWithPage={searchWithPage}
              />
              <TableHeaderItem
                field="pessoa.cnpjCpf"
                label="CNPJ/CPF"
                page={page}
                searchWithPage={searchWithPage}
              />
              {!isIntegradoOxy && (
                <TableHeaderItem
                  field="situacaoDescricao"
                  label="Situação"
                  page={page}
                  searchWithPage={searchWithPage}
                />
              )}
              <TableHeaderItem
                field="dataRestricaoInicio"
                label="Dt. Início Suspensão"
                page={page}
                searchWithPage={searchWithPage}
              />
              <TableHeaderItem
                field="dataRestricaoFim"
                label="Dt. Final Suspensão"
                page={page}
                searchWithPage={searchWithPage}
              />
              <TableHeaderItem
                field="observacao"
                label="Observação"
                page={page}
                searchWithPage={searchWithPage}
              />
              <th />
            </tr>
          </TableHeader>

          <tbody>{fornecedores.map(this.renderFornecedores)}</tbody>
        </table>
      </div>
    );
  }
}
