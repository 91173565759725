import { BaseError } from 'make-error';

export default class ValidationError extends BaseError {
  constructor(message, params = {}) {
    super(`ValidationError: ${JSON.stringify({ message, params })}`);
    this.message = message;
    this.params = params;
    this.params.field = this.params.prop;
    this.params.label = this.params.label || this.params.field;
    this.params.name = this.params.name || this.params.field;
  }
}
