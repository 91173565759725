import { Col, Row } from 'react-bootstrap';

import Component from 'react-pure-render/component';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react.js';
import PropTypes from 'prop-types';
import React from 'react';
import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react.js';

export class ObraDetalhePlanilha extends Component {
  static propTypes = {
    planilhas: PropTypes.object.isRequired,
    onDownload: PropTypes.func.isRequired
  };

  renderPlanilhas = data => {
    const { onDownload } = this.props;
    const possuiArquivo = !!data.get('idArquivo') || !!data.get('arquivo');

    return (
      <TableActions
        ref="row"
        key={`${data.get('sequencia')}_${data.get('idOxy')}_${data.get(
          'responsavel'
        )}`}
        data={data}
        onDownload={possuiArquivo ? onDownload : undefined}
        renderButtons={!data.get('idOxy')}
      >
        <td>{data.get('sequencia')}</td>
        <td>{data.get('responsavel')}</td>
        <td>
          <NumberFormatter value={data.get('valorTotal')} />
        </td>
        <td>{DateUtils.formatDateTimeShort(data.get('dataBase'))}</td>
        <td>{data.get('tipoPlanilha')}</td>
        {this.verificaArquivo(data)}
      </TableActions>
    );
  };

  verificaArquivo = data => {
    if (!data.get('idOxy')) {
      return;
    }

    if (data.get('arquivos').length) {
      return <td>{data.get('arquivos').map(this.renderArquivo)}</td>;
    }

    return <td>Sem Arquivo</td>;
  };

  renderArquivo = arquivo => {
    const { onDownload } = this.props;

    return (
      <div key={arquivo.get('idArquivo')} style={{ marginTop: '3px' }}>
        <a
          style={{ cursor: 'pointer' }}
          onClick={() => onDownload(arquivo.get('idArquivo'))}
        >
          <i className="fa fa-download" style={{ marginRight: '5px' }} />
          {arquivo.get('nomeArquivo')}
        </a>
      </div>
    );
  };

  render() {
    const { planilhas } = this.props;

    return (
      <Row>
        <Col md={12}>
          <div className="panel-table-outer">
            <table className="panel-table striped fancy">
              <TableHeader empty={planilhas.isEmpty()}>
                <tr>
                  <th>Sequência</th>
                  <th>Responsável</th>
                  <th>Valor Total</th>
                  <th>Data Base</th>
                  <th>Tipo Planilha</th>
                  <th>Arquivos</th>
                </tr>
              </TableHeader>
              <tbody>{planilhas.map(this.renderPlanilhas)}</tbody>
            </table>
          </div>
        </Col>
      </Row>
    );
  }
}

export default ObraDetalhePlanilha;
