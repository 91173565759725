export const THROW_ERROR = 'THROW_ERROR';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const REMOVE_LOADING = 'REMOVE_LOADING';

export const SIDEBAR_TOGGLE = 'SIDEBAR_TOGGLE';

export const OFFSIDEBAR_TOGGLE = 'OFFSIDEBAR_TOGGLE';
export const OFFSIDEBAR_TOGGLE_MODULO = 'OFFSIDEBAR_TOGGLE_MODULO';
export const OFFSIDEBAR_TOGGLE_NOTIFICACAO = 'OFFSIDEBAR_TOGGLE_NOTIFICACAO';
export const OFFSIDEBAR_TOGGLE_USUARIO = 'OFFSIDEBAR_TOGGLE_USUARIO';

export const MAINTANCE = 'MAINTANCE';

export const UNMAINTANCE = 'UNMAINTANCE';
