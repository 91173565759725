export function getAccess(menu, rota) {
  let menuFilter = menu.find(menu => {
    if (menu.has('route') && menu.get('route') === rota) {
      return menu;
    }
  });

  if (!menuFilter) {
    let localizouMenu = menu.map(menu => {
      let subMenu = menu.get('subMenu');
      if (subMenu && subMenu.size > 0) {
        let subMenuFilter = subMenu
          .filter(
            subMenu => subMenu.has('route') && subMenu.get('route') === rota
          )
          .first();
        if (subMenuFilter && subMenuFilter.has('id')) {
          return menu;
        }
      }
    });
    menuFilter = localizouMenu.filter(menu => menu && menu.has('id')).first();
  }

  return !!(menuFilter && menuFilter.has('id'));
}
