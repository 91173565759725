export const CEDIDOS_DE = 'CEDIDOS_DE';
export const CEDIDOS_DE_START = 'CEDIDOS_DE_START';
export const CEDIDOS_DE_SUCCESS = 'CEDIDOS_DE_SUCCESS';
export const CEDIDOS_DE_ERROR = 'CEDIDOS_DE_ERROR';

export const CEDIDOS_PARA = 'CEDIDOS_PARA';
export const CEDIDOS_PARA_START = 'CEDIDOS_PARA_START';
export const CEDIDOS_PARA_SUCCESS = 'CEDIDOS_PARA_SUCCESS';
export const CEDIDOS_PARA_ERROR = 'CEDIDOS_PARA_ERROR';

export const HISTCEDIDOS_DE = 'HISTCEDIDOS_DE';
export const HISTCEDIDOS_DE_START = 'HISTCEDIDOS_DE_START';
export const HISTCEDIDOS_DE_SUCCESS = 'HISTCEDIDOS_DE_SUCCESS';
export const HISTCEDIDOS_DE_ERROR = 'HISTCEDIDOS_DE_ERROR';

export const HISTCEDIDOS_PARA = 'HISTCEDIDOS_PARA';
export const HISTCEDIDOS_PARA_START = 'HISTCEDIDOS_PARA_START';
export const HISTCEDIDOS_PARA_SUCCESS = 'HISTCEDIDOS_PARA_SUCCESS';
export const HISTCEDIDOS_PARA_ERROR = 'HISTCEDIDOS_PARA_ERROR';
