import * as types from './Types.js';

export function findAditivo(entidade, exercicio, numero, sequencia, tipoAto) {
  return ({ fetch }) => ({
    type: types.ADITIVO,
    payload: {
      promise: fetch(
        `/api/aditivos/detalhes?entidade=${entidade}&exercicio=${exercicio}&numero=${numero}&sequencia=${sequencia}&tipoAto=${tipoAto}`
      )
    }
  });
}

export function findArquivos(entidade, exercicio, numero, sequencia) {
  return ({ fetch }) => ({
    type: types.ADITIVO_ARQUIVOS,
    payload: {
      promise: fetch(
        `/api/aditivos/arquivos?entidade=${entidade}&exercicio=${exercicio}&numero=${numero}&sequencia=${sequencia}`
      )
    }
  });
}
