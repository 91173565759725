import * as types from './Types.js';

import StorageService from 'portaltransparencia-common/libs/StorageService.js';

export function findAdiantamentos(filter) {
  const { entidade, exercicio } = StorageService.getItem('userInfo');
  const baseFilter = `entidade=${entidade.id}&exercicio=${exercicio.id}&`;
  const requestUrl = `/api/adiantamentos?${baseFilter}${filter}`;

  return ({ fetch }) => ({
    type: types.ADIANTAMENTOS,
    payload: {
      promise: fetch(requestUrl)
    }
  });
}

export function findAdiantamentoDetail(filter) {
  const { entidade, exercicio } = StorageService.getItem('userInfo');
  const baseFilter = `entidade=${entidade.id}&exercicio=${exercicio.id}&`;

  let requestUrl =
    `/api/adiantamentos/detail?${baseFilter}` + `&empenho=${filter['empenho']}`;

  if (filter.noLiquidacao !== 'null') {
    requestUrl += `&noLiquidacao=${filter['noLiquidacao']}`;
  }

  if (filter.unidadeOrcamentaria !== 'null') {
    requestUrl += `unidadeOrcamentaria=${filter['unidadeOrcamentaria']}`;
  }

  if (filter.matricula !== 'null') {
    requestUrl += `&matricula=${filter['matricula']}`;
  }

  if (filter.isReembolso !== 'null') {
    requestUrl += `&isReembolso=${filter['isReembolso']}`;
  }

  return ({ fetch }) => ({
    type: types.ADIANTAMENTO,
    payload: {
      promise: fetch(requestUrl)
    }
  });
}

export function findTotalAdiantamentos(filter) {
  const { entidade, exercicio } = StorageService.getItem('userInfo');
  const baseFilter = `entidade=${entidade.id}&exercicio=${exercicio.id}&`;
  const requestUrl = `/api/adiantamentos/total?${baseFilter}${filter}`;

  return ({ fetch }) => ({
    type: types.TOTAL_ADIANTAMENTOS,
    payload: {
      promise: fetch(requestUrl)
    }
  });
}

export function findAdiantamentoPrestacaoContas(
  entidade,
  exercicio,
  prestacaoContas
) {
  return ({ fetch }) => ({
    type: types.ADIANTAMENTO,
    payload: {
      promise: fetch(
        `/api/adiantamentos/prestacao-contas?entidade=${entidade}&exercicio=${exercicio}&prestacaoContas=${prestacaoContas}`
      )
    }
  });
}

export function findAdiantamento(
  entidade,
  exercicio,
  empenho,
  noLiquidacao,
  unidadeOrcamentaria,
  matricula
) {
  return ({ fetch }) => ({
    type: types.ADIANTAMENTO,
    payload: {
      promise: fetch(
        `/api/adiantamentos?entidade=${entidade}&exercicio=${exercicio}&empenho=${empenho}&noLiquidacao=${noLiquidacao}&unidadeOrcamentaria=${unidadeOrcamentaria}&matricula=${matricula}`
      )
    }
  });
}

export function findArquivos(entidade, exercicio, prestacaoContas) {
  return ({ fetch }) => ({
    type: types.ARQUIVOS,
    payload: {
      promise: fetch(
        `/api/adiantamentos/arquivos?entidade=${entidade}&exercicio=${exercicio}&prestacaoContas=${prestacaoContas}`
      )
    }
  });
}
