import { Map } from 'immutable';
import DatePicker from 'portaltransparencia-common/components/DatePicker.react.js';
import Input from 'portaltransparencia-common/components/EloInput.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import SearchPagination from 'portaltransparencia-common/components/SearchPagination.react.js';
import {
  onChange,
  resetCrud
} from 'portaltransparencia-common/crud/Actions.js';
import {
  addFilterUrl,
  addPagination
} from 'portaltransparencia-common/libs/AddFilter.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';
import FilterButtons from '../components/FilterButtons.react';
import PageHeader from '../components/PageHeader.react.js';
import * as actions from './Actions.js';
import AudienciasPublicasListagem from './AudienciasPublicasListagem.react';
import { addErrorNotification } from 'portaltransparencia-common/ui/Actions.js';
import configuration from 'portaltransparencia-common/config/Config.js';

export class FormAudienciasPublicas extends Component {
  static propTypes = {
    page: PropTypes.object.isRequired,
    findAudienciasPublicas: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    filtros: PropTypes.object,
    isIntegradoOxy: PropTypes.bool,
    onChange: PropTypes.func,
    resetCrud: PropTypes.func.isRequired,
    searchAudienciasPublicas: PropTypes.func.isRequired,
    addErrorNotification: PropTypes.func.isRequired
  };

  componentDidMount() {
    const { findAudienciasPublicas } = this.props;

    findAudienciasPublicas(this.montaFilter());
  }

  searchWithPage = page => {
    const { findAudienciasPublicas } = this.props;

    const filter = this.montaFilter(page);
    findAudienciasPublicas(filter);
  };

  onConsulta = () => {
    const { page } = this.props;

    const initialPage = page.set('number', 0);

    this.searchWithPage(initialPage);

    this.refs.panelFiltro && this.refs.panelFiltro.collapse();
  };

  onChangeFiltro = event => {
    const { onChange } = this.props;

    onChange('filtroAudienciasPublicas', event);
  };

  onResetFiltro = () => {
    const { resetCrud } = this.props;
    resetCrud('filtroAudienciasPublicas');
  };

  montaFilter = function (page) {
    const { entidade, exercicio } = StorageService.getItem('userInfo');
    const { filtros } = this.props;

    let filter = 'entidade=' + entidade.id + '&exercicio=' + exercicio.id;

    if (filtros.get('natureza')) {
      filter = addFilterUrl(filter, `natureza=${filtros.get('natureza')}`);
    }

    if (filtros.get('tipo')) {
      filter = addFilterUrl(filter, `tipo=${filtros.get('tipo')}`);
    }

    if (filtros.get('dataInicial') && filtros.get('dataInicial').length == 10) {
      let validValue = DateUtils.transform(
        filtros.get('dataInicial'),
        'yy-mm-dd',
        'yy-mm-dd'
      );
      filter = addFilterUrl(filter, `dataInicial=${validValue}`);
    }
    if (filtros.get('dataFinal') && filtros.get('dataFinal').length == 10) {
      let validValue = DateUtils.transform(
        filtros.get('dataFinal'),
        'yy-mm-dd',
        'yy-mm-dd'
      );
      filter = addFilterUrl(filter, `dataFinal=${validValue}`);
    }

    return addPagination(page, filter);
  };

  onExporta = (exportType, openTab, windowParams) => {
    const { filter, page, addErrorNotification } = this.props;
    const { apiURL } = configuration;
    const { entidade } = StorageService.getItem('userInfo');

    if (page.get('content').size > 0) {
      let filtro = `entidade=${entidade.id}&${filter}&exportType=${exportType}`;

      window.open(
        `${apiURL}/api/audiencias-publicas/report?${filtro}`,
        openTab,
        windowParams
      );
    } else {
      addErrorNotification(
        'A consulta não apresentou resultado. Não é possível exportar para o formato selecionado!'
      );
    }
  };

  render() {
    const { page, filtros, isIntegradoOxy } = this.props;

    if (!page.has('content')) {
      return null;
    }

    return (
      <section>
        <PageHeader location={this.props.location} />

        <form>
          <Panel
            title={'Audiências Públicas'}
            isForm
            tableResponsive
            expansible={true}
          >
            <form>
              <Row>
                <Col xs={6} sm={3} md={2}>
                  <DatePicker
                    id="dataInicial"
                    name="dataInicial"
                    ref="dataInicial"
                    label="Data Inicial"
                    onChange={this.onChangeFiltro}
                    value={filtros.get('dataInicial')}
                  />
                </Col>
                <Col xs={6} sm={3} md={2}>
                  <DatePicker
                    id="dataFinal"
                    name="dataFinal"
                    ref="dataFinal"
                    label="Data Final"
                    onChange={this.onChangeFiltro}
                    value={filtros.get('dataFinal')}
                  />
                </Col>
              </Row>

              {!isIntegradoOxy && (
                <Row>
                  <Col xs={9} sm={10} md={6}>
                    <Input
                      id="natureza"
                      name="natureza"
                      ref="natureza"
                      placeholder="Natureza"
                      label="Natureza"
                      type="text"
                      onChange={this.onChangeFiltro}
                      value={filtros.get('natureza')}
                    />
                  </Col>
                  <Col xs={9} sm={10} md={6}>
                    <Input
                      id="tipo"
                      name="tipo"
                      ref="tipo"
                      placeholder="Tipo"
                      label="Tipo"
                      type="text"
                      onChange={this.onChangeFiltro}
                      value={filtros.get('tipo')}
                    />
                  </Col>
                </Row>
              )}

              <Col xs={12} sm={4} md={3}>
                <FilterButtons
                  onConsulta={this.onConsulta}
                  onReset={this.onResetFiltro}
                />
              </Col>
            </form>
          </Panel>

          <AudienciasPublicasListagem
            audienciasPublicasPage={page}
            history={this.props.history}
            filter={this.montaFilter(page.get('content'))}
            searchWithPage={this.searchWithPage}
            isIntegradoOxy={isIntegradoOxy}
            onExporta={this.onExporta}
          />

          <SearchPagination page={page} searchWithPage={this.onConsulta} />
        </form>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    page: state.audienciapublica.get('audienciasPublicas'),
    filtros: state.crud.getIn(
      ['filtroAudienciasPublicas', 'currentRecord'],
      Map({})
    ),
    isIntegradoOxy: state.entidade.getIn([
      'configuracoesGerais',
      'isIntegradoOxy'
    ])
  };
}

const allActions = {
  ...actions,
  onChange,
  resetCrud,
  addErrorNotification
};

export default connect(mapStateToProps, allActions)(FormAudienciasPublicas);
