import Panel from 'portaltransparencia-common/components/Panel.react.js';
import ListUtils from 'portaltransparencia-common/libs/ListUtils.js';
import { getValueFromUrlSearchParams } from 'portaltransparencia-common/libs/URLUtils';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import ResultadoPesquisa from './ResultadoPesquisa.react.js';

export class FormPesquisa extends Component {
  static propTypes = {
    menu: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
  };

  render() {
    const { menu, location } = this.props;
    const pesquisa = getValueFromUrlSearchParams(location, 'pesquisa');

    if (!pesquisa) {
      return (
        <section>
          <h1 className="text-title center mb-xs">Pesquisa</h1>

          <Panel expansible={false}>
            Utilize a <b className="hidden-sm hidden-xs">botão de pesquisa</b>{' '}
            <b className="hidden-md hidden-lg"> lupa</b> no canto direito do
            cabeçalho da página e informe o que deseja procurar.
          </Panel>

          <Link to="/">
            <button className="btn icon-left module-color mt-xs">
              <em className="fa fa-home"></em>
              Ir para o início
            </button>
          </Link>
        </section>
      );
    }

    let menuFiltrado = menu.filter(item =>
      ListUtils.searchTree(item, pesquisa, ['title', 'subtitle'], 'subMenu')
    );

    return (
      <section>
        <h1 className="text-title center mb-xs">Pesquisa</h1>

        <Panel title={'Você buscou por: ' + pesquisa} expansible={false}>
          <ResultadoPesquisa list={menuFiltrado} valorPesquisado={pesquisa} />
        </Panel>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    menu: state.menu.get('menu')
  };
}

export default connect(mapStateToProps)(FormPesquisa);
