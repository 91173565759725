import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import NumberUtils from 'portaltransparencia-common/libs/NumberUtils.js';

export function hasArquivo(list) {
  const listWithArquivo = list.filter(p => p.has('idArquivo'));

  return !listWithArquivo.isEmpty();
}

export function getNameAndSize(data) {
  let nomeArquivo = '';
  let tamanho = NumberUtils.formatBytes(data.get('tamanhoArquivo'));

  if (data.get('nomeArquivo')) {
    nomeArquivo = `${data.get('nomeArquivo')} ${tamanho}`;
  } else if (data.get('idArquivo')) {
    nomeArquivo = `Arquivo sem título ${tamanho}`;
  }

  return nomeArquivo;
}

export function getDate(date) {
  let dataArquivo = '';

  if (date) {
    dataArquivo = DateUtils.formatDateTimeShort(date);
  }

  return dataArquivo;
}
