import Component from 'react-pure-render/component';
import React from 'react';

export default class BotaoImprimir extends Component {
  onClick = () => {
    window.print();
  };

  render() {
    return (
      <div className="btn-save">
        <button
          className="btn other fa fa-print"
          title="Imprimir"
          type="button"
          onClick={this.onClick}
        ></button>
      </div>
    );
  }
}
