import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react.js';
import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export class LicitacaoDetalheAutorizacao extends Component {
  static propTypes = {
    autorizacoes: PropTypes.object.isRequired
  };

  onViewDetalhes = autorizacao => {
    const entidade = autorizacao.get('entidade');
    const exercicio = autorizacao.get('exercicioRequisicao');
    const numeroRequisicao = autorizacao.get('numeroRequisicao');

    this.props.history.push(
      `/autorizacoes/detalhes?entidade=${entidade}&exercicio=${exercicio}&numeroRequisicao=${numeroRequisicao}`
    );
  };

  renderAutorizacoes = data => {
    const key =
      data.get('entidade') +
      '_' +
      data.get('exercicio') +
      '_' +
      data.get('numeroRequisicao');

    return (
      <TableActions
        ref="row"
        key={key}
        data={data}
        onMore={this.onViewDetalhes}
      >
        <td className="right">{data.get('numeroRequisicao')}</td>
        <td className="right">{data.get('exercicioRequisicao')}</td>
        <td>{DateUtils.formatDateTimeShort(data.get('dataNad'))}</td>
        <td>{data.get('nomeFornecedor')}</td>
        <td className="right">{data.get('numeroNad')}</td>
        <td className="right">{data.get('exercicioNad')}</td>
        <td className="right">{data.get('empenho')}</td>
        <td className="right">
          <NumberFormatter value={data.get('valorRequisicao')} />
        </td>
      </TableActions>
    );
  };

  render() {
    const { autorizacoes } = this.props;

    return (
      <div className="panel-table-outer">
        <table className="panel-table striped fancy">
          <TableHeader empty={autorizacoes.isEmpty()}>
            <tr>
              <th className="right">Nº Autorização</th>
              <th className="right">Ano Autorização</th>
              <th>Data</th>
              <th>Fornecedor</th>
              <th className="right">Número NAD</th>
              <th className="right">Ano NAD</th>
              <th className="right">Empenho</th>
              <th className="right">Valor Requisição</th>
              <th></th>
            </tr>
          </TableHeader>
          <tbody>{autorizacoes.map(this.renderAutorizacoes)}</tbody>
        </table>
      </div>
    );
  }
}

export default LicitacaoDetalheAutorizacao;
