import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import { withRouter } from 'react-router';

export class LinkToleiAto extends Component {
  static propTypes = {
    entidade: PropTypes.number,
    exercicio: PropTypes.number,
    id: PropTypes.number,
    historyLeiAto: PropTypes.any,
    mostrarportal: PropTypes.string
  };

  showLeiAto = () => {
    const { entidade, exercicio, id, historyLeiAto } = this.props;
    historyLeiAto.push(
      `/leiseatos-geral/detalhes?entidade=${entidade}&ano=${exercicio}&id=${id}&sistema=C&natureza=Portaria&tipoLeisAtos=geral`
    );
  };

  render() {
    const { entidade, id, exercicio, mostrarportal } = this.props;
    if (entidade && exercicio && id) {
      if (mostrarportal === 'S') {
        return (
          <a
            style={{ cursor: 'pointer' }}
            onClick={this.showLeiAto}
          >{`${id}`}</a>
        );
      } else {
        return id;
      }
    }

    return null;
  }
}

export default withRouter(LinkToleiAto);
