import StorageService from '../libs/StorageService.js';
import cookie from 'react-cookie';

const AUTHORIZATION = 'authorization';
const REFRESH_TOKEN = 'refreshToken';
const USER_INFO = 'userInfo';
const ELOTECH_DOMAIN = '.elotech.localnet';
const DAYS_EXPIRES_REMEMBER = 10;

const HOST_DEV = 'http://juggernaut.elotech.localnet';

const AuthService = {
  getHost() {
    if (process.env.NODE_ENV === 'production') {
      return window.location.origin;
    }

    return process.env.HOST_DEV || HOST_DEV;
  },

  getDomain() {
    const domain = window.location.hostname;

    if (domain.includes(ELOTECH_DOMAIN)) {
      return ELOTECH_DOMAIN;
    }

    return domain;
  },

  saveAuthentication(token, rememberMe = false) {
    let expireDate = new Date();
    expireDate.setDate(expireDate.getDate() + DAYS_EXPIRES_REMEMBER);

    let options = {};
    if (rememberMe) {
      options = {
        path: '/',
        domain: AuthService.getDomain(),
        expires: expireDate
      };
    } else {
      options = { path: '/', domain: AuthService.getDomain() };
    }

    cookie.save(AUTHORIZATION, token, options);

    return token;
  },

  saveRefreshToken(token) {
    let expireDate = new Date();
    expireDate.setDate(expireDate.getDate() + DAYS_EXPIRES_REMEMBER);

    const options = {
      path: '/',
      domain: AuthService.getDomain(),
      expires: expireDate
    };

    cookie.save(REFRESH_TOKEN, token, options);

    return token;
  },

  getAuthorizationToken() {
    let token = cookie.load(AUTHORIZATION);
    return token;
  },

  getRefreshToken() {
    return cookie.load(REFRESH_TOKEN);
  },

  clearAuthentication() {
    let expireDate = new Date();
    expireDate.setDate(expireDate.getDate() - 1);
    cookie.save(AUTHORIZATION, null, {
      path: '/',
      domain: AuthService.getDomain(),
      expires: expireDate
    });
  },

  saveUserInfo(userInfo) {
    const { entidade, exercicio } = userInfo;

    const userInfoStorage = {
      entidade,
      exercicio
    };

    StorageService.saveItemAsJson(USER_INFO, userInfoStorage);
  },

  getUserInfo() {
    return StorageService.getItem(USER_INFO);
  },

  clearUserInfo() {
    StorageService.removeItem(USER_INFO);
  },

  clearKeycloakUser() {
    cookie.remove('keycloakUser');
  },

  clearUsuarioLogado() {
    cookie.remove('usuarioLogado');
  },

  clearRefreshToken() {
    cookie.remove(REFRESH_TOKEN);
  },

  requireAuth() {
    if (!AuthService.isLogged()) {
      AuthService.redirectToLogin();
    }
  },

  isLogged() {
    return !!cookie.load(AUTHORIZATION);
  },

  redirectToLogin() {
    const loginUrl = `${AuthService.getHost()}/login/auth?redirect=${
      window.location.href
    }`;
    window.location.href = loginUrl;
  }
};

export default AuthService;
