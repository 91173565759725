import * as types from './Types.js';

export function findPerguntas(filtro) {
  return ({ fetch }) => ({
    type: types.PERGUNTAS_LIST,
    payload: {
      promise: fetch('/api/perguntas-frequentes?filtro=' + filtro)
    }
  });
}

export function print() {
  const windowParams = 'width=800, height=600';

  return ({ fetch }) => ({
    type: 'PRINT',
    payload: fetch('/api/perguntas-frequentes/report')
      .then(response => response.blob())
      .then(file => URL.createObjectURL(file))
      .then(fileURL => window.open(fileURL, '_blank', windowParams))
  });
}
