import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import Component from 'react-pure-render/component';
import * as actions from '../Actions.js';
import PageHeader from '../../components/PageHeader.react.js';

import Panel from 'portaltransparencia-common/components/Panel.react.js';
import BotaoImprimir from 'portaltransparencia-common/components/BotaoImprimir.react.js';
import { getAllValuesFromUrlSearchParams } from 'portaltransparencia-common/libs/URLUtils';
import { addFilterUrl } from 'portaltransparencia-common/libs/AddFilter.js';
import CabecalhoEntidade from '../../entidades/CabecalhoEntidade.react.js';
import CargoDetalheInformacaoOxy from './CargoDetalheInformacaoOxy.react.js';

export class CargoMatrizOxy extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    getMatrizVigente: PropTypes.func.isRequired
  };

  componentDidMount() {
    const { location } = this.props;

    const params = getAllValuesFromUrlSearchParams(location);

    this.consultarCargos(params);
  }

  consultarCargos = params => {
    const { getMatrizVigente } = this.props;

    let filtro = '';
    if (params) {
      filtro = addFilterUrl(filtro, `id=${params.cargo}`);
      filtro = addFilterUrl(filtro, `matriz=${params.matriz}`);
      if (params.matrizSalarialVigencia) {
        filtro = addFilterUrl(
          filtro,
          `matrizSalarialVigencia=${params.matrizSalarialVigencia}`
        );
      }
    }

    getMatrizVigente(filtro);
  };

  generateKey = matriz => {
    return `${matriz.get('cargo')}_
            ${matriz.get('matrizVigente')}`;
  };

  renderHeader = (item, align) => <th className={align}>{item}</th>;

  renderTableItem = (item, align) => (
    <td className={align}>
      {item.toLocaleString('pt-br', { minimumFractionDigits: 2 })}
    </td>
  );

  renderLinhaMatriz = matrizLinha => {
    return (
      <tr>
        {matrizLinha.map((item, index) => {
          return this.renderTableItem(item, index == 0 ? '' : 'right');
        })}
      </tr>
    );
  };

  renderTable = matriz => {
    if (!matriz) {
      return;
    }

    const matrizFormatada = JSON.parse(matriz);

    return (
      <table className="panel-table striped fancy">
        <thead>
          <tr>
            {matrizFormatada[0].map((item, index) =>
              this.renderHeader(item, index === 0 ? '' : 'right')
            )}
          </tr>
        </thead>
        <tbody>
          {matrizFormatada.map((matriz, i) => {
            if (i > 0) {
              return this.renderLinhaMatriz(matriz);
            }
          })}
        </tbody>
      </table>
    );
  };

  getCargo = item => {
    return {
      cargoTipo: item.get('cargo').get('cargoTipo'),
      descricao: item.get('cargo').get('descricao'),
      matrizSalarialNome: item.get('cargo').get('matrizSalarialNome'),
      quantidadeVagas: item.get('cargo').get('quantidadeVagas'),
      quantidadeVagasOcupadas: item.get('cargo').get('quantidadeVagasOcupadas'),
      quantidadeVagasDisponiveis: item
        .get('cargo')
        .get('quantidadeVagasDisponiveis'),
      matrizVigente: item.get('matrizVigente')
    };
  };

  render() {
    const { matrizes } = this.props;

    if (!matrizes || matrizes.isEmpty()) {
      return (
        <div>
          <Helmet title={'Cargo'} />

          <PageHeader
            location={this.props.location}
            textoDetalhe="Cargo sem plano salarial"
          />

          <div className="info">Cargo sem informação de plano salarial.</div>
        </div>
      );
    }
    const cargo = this.getCargo(matrizes.first());
    return (
      <div>
        <PageHeader location={this.props.location} textoDetalhe="Cargo" />

        <BotaoImprimir />

        <CabecalhoEntidade />

        <CargoDetalheInformacaoOxy cargo={cargo} />

        {matrizes.map(matriz => {
          const cargo = matriz.get('cargo');
          return (
            <Panel
              key={this.generateKey(matriz)}
              title={`${cargo.get('planoSalarialDescricao')} - ${cargo.get(
                'planoSalarialEstruturaDesc'
              )}`}
              expansible={false}
              tableResponsive
            >
              <div className="panel-table-outer" key={this.generateKey(matriz)}>
                {this.renderTable(matriz.get('matrizVigente'))}
              </div>
            </Panel>
          );
        })}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    matrizes: state.cargo.get('matriz')
  };
}

const allActions = {
  ...actions
};

export default connect(mapStateToProps, allActions)(CargoMatrizOxy);
