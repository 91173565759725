import Hint from 'portaltransparencia-common/components/hint/Hint.react';
import Widget from 'portaltransparencia-common/components/Widget.react.js';
import { format } from 'portaltransparencia-common/libs/NumberUtils';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';

export class DespesaAno extends Component {
  static propTypes = {
    valorEmpenhado: PropTypes.number.isRequired,
    despesaValorAtualizado: PropTypes.number.isRequired,
    exercicio: PropTypes.any.isRequired
  };

  render() {
    const { valorEmpenhado, despesaValorAtualizado, exercicio } = this.props;

    let empenhadoSobreOrcado = 0;
    if (despesaValorAtualizado > 0) {
      empenhadoSobreOrcado = (valorEmpenhado * 100) / despesaValorAtualizado;
    }

    return (
      <Widget
        icon="fa fa-area-chart"
        isChart
        title={`Valores da despesa no ano de ${exercicio}`}
      >
        <Row>
          <Col sm={4} md={4}>
            <article className="widget-numeric block">
              <b className="widget-numeric-number">{format(valorEmpenhado)}</b>
              <div className="widget-numeric-subtitle">
                <Hint
                  message={`Soma dos valores empenhados de todos os meses de ${exercicio}.`}
                  size="xs"
                  position="bottom-left"
                  spacing="mr-xs"
                  isInline
                />
                Valor Empenhado
              </div>
            </article>
          </Col>
          <Col sm={4} md={4}>
            <article className="widget-numeric block">
              <b className="widget-numeric-number">
                {format(despesaValorAtualizado)}
              </b>
              <p className="widget-numeric-subtitle">Valor Orçado Atualizado</p>
            </article>
          </Col>
          <Col sm={4} md={4}>
            <article className="widget-numeric block">
              <b className="widget-numeric-number">
                {format(empenhadoSobreOrcado) + '%'}
              </b>
              <div className="widget-numeric-subtitle">
                Percentual Empenhado sobre Orçado
                <Hint
                  message={`Porcentagem da soma dos valores empenhados de todos os meses de ${exercicio} pelo Valor orçado atualizado.`}
                  size="xs"
                  isInline
                />
              </div>
            </article>
          </Col>
        </Row>
      </Widget>
    );
  }
}

export default DespesaAno;
