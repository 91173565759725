import * as types from './Types.js';

export function findNotasExtraorcamentarias(filtro) {
  return ({ fetch }) => ({
    type: types.NOTAS_EXTRAORCAMENTARIAS,
    payload: {
      promise: fetch('/api/nota-extraorcamentaria?' + filtro)
    }
  });
}

export function findNotaExtraorcamentaria(filtro) {
  return ({ fetch }) => ({
    type: types.NOTA_EXTRAORCAMENTARIA,
    payload: {
      promise: fetch('/api/nota-extraorcamentaria/detalhes?' + filtro)
    }
  });
}

export function findNotaExtraorcamentariaItens(filtro) {
  return ({ fetch }) => ({
    type: types.NOTA_EXTRAORCAMENTARIA_ITENS,
    payload: {
      promise: fetch('/api/nota-extraorcamentaria/itens?' + filtro)
    }
  });
}
