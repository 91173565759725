import PropTypes from 'prop-types';
import React from 'react';
import { List } from 'immutable';
import TreeItem from './TreeItem.react.js';

class TreeList extends React.PureComponent {
  static propTypes = {
    list: PropTypes.instanceOf(List).isRequired,
    onClick: PropTypes.func.isRequired,
    expanded: PropTypes.bool
  };

  render() {
    const { list, onClick, expanded } = this.props;

    return (
      <ul className="tree-list">
        {list &&
          list.map(item => {
            return (
              <TreeItem
                item={item}
                key={item.get('id')}
                onClick={onClick}
                expanded={expanded && list.size === 1}
              />
            );
          })}
      </ul>
    );
  }
}

export default TreeList;
