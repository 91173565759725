import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import Input from './EloInput.react.js';
import $ from 'jquery';
import 'jquery-ui/datepicker.js';
import 'jquery-ui/themes/cupertino/jquery-ui.min.css';
import DateUtils from '../libs/DateUtils.js';
import 'jquery-mask-plugin/dist/jquery.mask.min.js';
import NotificationSystem from 'react-notification-system';

$.datepicker.regional['pt-BR'] = {
  closeText: 'Fechar',
  prevText: '&#x3c;Anterior',
  nextText: 'Pr&oacute;ximo&#x3e;',
  currentText: 'Hoje',
  monthNames: [
    'Janeiro',
    'Fevereiro',
    'Mar&ccedil;o',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro'
  ],
  monthNamesShort: [
    'Jan',
    'Fev',
    'Mar',
    'Abr',
    'Mai',
    'Jun',
    'Jul',
    'Ago',
    'Set',
    'Out',
    'Nov',
    'Dez'
  ],
  dayNames: [
    'Domingo',
    'Segunda-feira',
    'Ter&ccedil;a-feira',
    'Quarta-feira',
    'Quinta-feira',
    'Sexta-feira',
    'Sabado'
  ],
  dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
  dayNamesMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
  weekHeader: 'Sm',
  dateFormat: 'dd/mm/yy',
  firstDay: 0,
  isRTL: false,
  showMonthAfterYear: false,
  yearSuffix: ''
};

$.datepicker.setDefaults($.datepicker.regional['pt-BR']);

const notificationStyle = {
  NotificationItem: {
    // Override the notification item
    DefaultStyle: {
      // Applied to every notification, regardless of the notification level
      margin: '100px 5px 2px 1px'
    }
  }
};

class DatePicker extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    value: PropTypes.string,
    name: PropTypes.string
  };

  constructor(props) {
    super(props);
    const date = this.props.value && this.props.value.substring(0, 10);

    this.state = {
      value: this.handlePropValue(date)
    };
  }

  componentDidMount() {
    $(this.refs.datepicker.refs.eloinput)
      .datepicker({
        onSelect: this.onSelect
      })
      .mask('00/00/0000', { placeholder: 'Digite a data' });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      const date = nextProps.value;
      /* eslint-disable react/no-set-state */
      this.setState({
        value: this.handlePropValue(date)
      });
      /* eslint-enable react/no-set-state */
    }
  }

  handlePropValue = date => {
    return DateUtils.isISO8601(date) ? DateUtils.transform(date) : date;
  };

  handleOnChange = event => {
    const newValue = event.target.value;

    this.setState({ value: newValue }, () => this.fireOnChange(newValue));
  };

  fireOnChange = value => {
    const { onChange, name } = this.props;

    let newValue = value;
    if (DateUtils.isPtBr(value)) {
      try {
        newValue = DateUtils.transform(value, 'dd/mm/yy', 'yy-mm-dd');
      } catch (error) {
        newValue = undefined;
        this.refs.notificationSystem.addNotification({
          title: 'Data inválida',
          level: 'error'
        });
      }
    }

    if (onChange) {
      onChange({
        target: {
          name: name,
          value: newValue
        }
      });
    }
  };

  onSelect = date => {
    this.setState({ value: date }, () => this.fireOnChange(date));
  };

  getInputValue = () => {
    return this.refs.datepicker.getValue();
  };

  getValue = (format = 'yy-mm-dd') => {
    return DateUtils.transform(this.getInputValue(), 'dd/mm/yy', format);
  };

  render() {
    const { value } = this.state;

    return (
      <div>
        <NotificationSystem
          ref="notificationSystem"
          allowHTML
          style={notificationStyle}
        />
        <Input
          {...this.props}
          ref="datepicker"
          label={this.props.label}
          type="text"
          value={value}
          onChange={this.handleOnChange}
        />
      </div>
    );
  }
}

export default DatePicker;
