import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import * as actions from './Actions.js';

class CabecalhoEntidade extends Component {
  static propTypes = {
    entidades: PropTypes.object.isRequired,
    userInfo: PropTypes.object
  };

  filtraEntidadeLogada = () => {
    const userInfo = StorageService.getItem('userInfo');
    const { entidade } = userInfo;

    const { entidades } = this.props;

    const entidadeLogada = entidades.find(u => u.get('id') === entidade.id);

    return entidadeLogada;
  };

  render() {
    const { entidades } = this.props;

    if (entidades.isEmpty()) {
      return null;
    }

    const entidadeLogada = this.filtraEntidadeLogada();

    if (!entidadeLogada) {
      return null;
    }

    return (
      <div className="visible-print">
        <h2 className="panel-title">
          <div className="center">
            <h1> {entidadeLogada.get('nome')} </h1>
            <h3>
              {' '}
              {entidadeLogada.get('logradouro')} -{' '}
              {entidadeLogada.get('bairro')} - {entidadeLogada.get('cidade')} -{' '}
              {entidadeLogada.get('uf')}{' '}
            </h3>
            <h3>
              {' '}
              CEP: {entidadeLogada.get('cep')} Telefone:{' '}
              {entidadeLogada.get('telefone')}{' '}
            </h3>
            <h2></h2>
          </div>
        </h2>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    entidades: state.entidade.get('entidades')
  };
}

const allActions = {
  ...actions
};

export default connect(mapStateToProps, allActions)(CabecalhoEntidade);
