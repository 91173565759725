import BotaoImprimir from 'portaltransparencia-common/components/BotaoImprimir.react.js';
import DisplayData from 'portaltransparencia-common/components/displaydata/DisplayData.react.js';
import DisplayDataItem from 'portaltransparencia-common/components/displaydata/DisplayDataItem.react.js';
import DisplayDataRow from 'portaltransparencia-common/components/displaydata/DisplayDataRow.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import { getAllValuesFromUrlSearchParams } from 'portaltransparencia-common/libs/URLUtils';
import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import PageHeader from '../components/PageHeader.react.js';
import CabecalhoEntidade from '../entidades/CabecalhoEntidade.react.js';
import * as actions from './Actions.js';

export class FormMovimentoGaragemDetail extends Component {
  static propTypes = {
    movimento: PropTypes.object.isRequired,
    findMovimentoGaragem: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired
  };

  componentDidMount() {
    const { findMovimentoGaragem, location } = this.props;
    const { entidade, exercicio, numero } = getAllValuesFromUrlSearchParams(
      location
    );

    findMovimentoGaragem(entidade, exercicio, numero);
  }

  render() {
    const { movimento } = this.props;

    if (movimento.isEmpty()) {
      return (
        <div className="info">
          Nenhuma movimentação disponível para a garagem.
        </div>
      );
    }

    return (
      <div>
        <Helmet title={`Movimento Garagem - ${movimento.get('descricao')}`} />

        <BotaoImprimir />

        <PageHeader
          location={window.location}
          textoDetalhe={`Movimento Garagem ${movimento.get('numero')}`}
        />

        <CabecalhoEntidade />

        <Panel title={`Movimento Garagem ${movimento.get('numero')}`}>
          <DisplayData border>
            <DisplayDataRow>
              <DisplayDataItem
                sm={12}
                title="Solicitante"
                value={movimento.get('solicitante')}
                id="solicitante"
              />
              <DisplayDataItem
                sm={12}
                title="Destino"
                value={
                  movimento.get('destino') +
                  ' - ' +
                  movimento.get('complementoDestino')
                }
                id="destino"
              />
            </DisplayDataRow>
            <DisplayDataRow>
              <DisplayDataItem
                sm={12}
                title="Justificativa"
                value={movimento.get('justificativa')}
                id="justificativa"
              />
            </DisplayDataRow>
            <DisplayDataRow>
              <DisplayDataItem
                sm={12}
                title="Motivo"
                value={
                  movimento.get('motivo') +
                  ' - ' +
                  movimento.get('complementoMotivo')
                }
                id="motivo"
              />
            </DisplayDataRow>
            <DisplayDataRow>
              <DisplayDataItem
                sm={4}
                title="Data retorno"
                value={DateUtils.formatDateTime(
                  movimento.get('dataRetorno'),
                  'DD/MM/YYYY'
                )}
                id="dataRetorno"
              />
              <DisplayDataItem
                sm={4}
                title="Hora retorno"
                value={DateUtils.formatTime(movimento.get('horaRetorno'))}
                id="horaRetorno"
              />
              <DisplayDataItem
                sm={4}
                title="Medidor retorno"
                value={movimento.get('medidorRetorno')}
                id="medidorRetorno"
              />
            </DisplayDataRow>
            <DisplayDataRow>
              <DisplayDataItem
                sm={4}
                title="Data saída"
                value={DateUtils.formatDateTime(
                  movimento.get('dataSaida'),
                  'DD/MM/YYYY'
                )}
                id="dataSaida"
              />
              <DisplayDataItem
                sm={4}
                title="Hora saída"
                value={DateUtils.formatTime(movimento.get('horaSaida'))}
                id="horaSaida"
              />
              <DisplayDataItem
                sm={4}
                title="Medidor saída"
                value={movimento.get('medidorSaida')}
                id="medidorSaida"
              />
            </DisplayDataRow>
            <DisplayDataRow>
              <DisplayDataItem
                sm={5}
                title="km Previsto"
                value={movimento.get('kmPrevisto')}
                id="kmPrevisto"
              />
              <DisplayDataItem
                sm={7}
                title="Motorista"
                value={movimento.get('motorista')}
                id="motorista"
              />
            </DisplayDataRow>
            <DisplayDataRow>
              <DisplayDataItem
                sm={12}
                title="Veículo"
                id="veiculo"
                value={
                  movimento.get('placa') +
                  ' - ' +
                  movimento.get('veiculoDescricao') +
                  ' (' +
                  movimento.get('veiculoNumero') +
                  ')'
                }
              />
            </DisplayDataRow>
            <DisplayDataRow>
              <DisplayDataItem
                sm={12}
                title="Observação"
                value={movimento.get('observacao')}
                id="observacao"
              />
            </DisplayDataRow>
          </DisplayData>
        </Panel>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    movimento: state.garagem.get('movimento')
  };
}

export default connect(mapStateToProps, actions)(FormMovimentoGaragemDetail);
