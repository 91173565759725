import DisplayData from 'portaltransparencia-common/components/displaydata/DisplayData.react.js';
import DisplayDataItem from 'portaltransparencia-common/components/displaydata/DisplayDataItem.react.js';
import DisplayDataRow from 'portaltransparencia-common/components/displaydata/DisplayDataRow.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import NumberUtils from 'portaltransparencia-common/libs/NumberUtils.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export class ConvenioDetalheInformacao extends Component {
  static propTypes = {
    convenio: PropTypes.object.isRequired
  };

  getFonteRecurso(convenio) {
    let fonteRecurso = '';
    let juncao = '';

    if (convenio.get('fonteRecurso')) {
      fonteRecurso += convenio.get('fonteRecurso');
      juncao = ' - ';
    }

    if (convenio.get('descricaoFonte')) {
      fonteRecurso += juncao + convenio.get('descricaoFonte');
    }
    return fonteRecurso;
  }

  getFiscalConvenio(convenio) {
    let fiscalConvenio = '';

    if (convenio.get('fiscalConvenio')) {
      fiscalConvenio = convenio.get('fiscalConvenio');
    }
    return fiscalConvenio;
  }

  render() {
    const { convenio } = this.props;
    const fonteRecurso = this.getFonteRecurso(convenio);
    const fiscalConvenio = this.getFiscalConvenio(convenio);
    const linkDoGoverno = (
      <em>
        <a target="_blank" href={convenio.get('linkGov')}>
          {convenio.get('linkGov')}{' '}
        </a>
      </em>
    );

    return (
      <Panel
        title={
          'Convênio ' +
          convenio.get('nrConvenio') +
          '/' +
          convenio.get('nrAnoConvenio')
        }
        expansible={false}
      >
        <DisplayData border>
          <DisplayDataRow>
            <DisplayDataItem
              sm={7}
              title="Fornecedor"
              value={convenio.get('nome')}
              id="nome"
            />
            <DisplayDataItem
              sm={5}
              title="CNPJ"
              value={convenio.get('cnpj')}
              id="cnpj"
            />
          </DisplayDataRow>

          <DisplayDataRow>
            <DisplayDataItem
              sm={4}
              title="Data Celebração"
              value={DateUtils.formatDateTimeShort(
                convenio.get('dataCelebracao')
              )}
              id="dataCelebracao"
            />
            <DisplayDataItem
              sm={4}
              title="Início Vigência Convênio"
              value={DateUtils.formatDateTimeShort(
                convenio.get('dataInicioVigencia')
              )}
              id="dataInicioVigencia"
            />
            <DisplayDataItem
              sm={4}
              title="Fim Vigência Convênio"
              value={DateUtils.formatDateTimeShort(
                convenio.get('dataFimVigencia')
              )}
              id="dataFimVigencia"
            />
          </DisplayDataRow>

          <DisplayDataRow>
            <DisplayDataItem
              sm={4}
              title="Quantidade Parcelas"
              value={convenio.get('qtParcelas')}
              id="qtParcelas"
            />
            <DisplayDataItem
              sm={4}
              title="Valor Repasse Convênio"
              value={NumberUtils.format(convenio.get('valorConvenio'))}
              id="valorConvenio"
            />
            <DisplayDataItem
              sm={4}
              title="Valor Recurso Próprio"
              value={NumberUtils.format(convenio.get('vlRecursoProprio'))}
              id="vlRecursoProprio"
            />
            <DisplayDataItem
              sm={4}
              title="Situação"
              value={convenio.get('situacao')}
              id="situacao"
            />
          </DisplayDataRow>

          <DisplayDataRow>
            <DisplayDataItem
              sm={12}
              title="Objeto"
              value={convenio.get('dsObjeto')}
              id="dsObjeto"
            />
          </DisplayDataRow>

          <DisplayDataRow>
            <DisplayDataItem
              sm={7}
              title="Fonte de Recurso"
              value={fonteRecurso}
              id="fonteRecurso"
            />
            <DisplayDataItem
              sm={5}
              title="Fiscal Convênio"
              value={fiscalConvenio}
              id="fiscalConvenio"
            />
          </DisplayDataRow>

          <DisplayDataRow>
            <DisplayDataItem
              sm={12}
              title="Link do Governo"
              value={linkDoGoverno}
              id="linkGov"
            />
          </DisplayDataRow>
        </DisplayData>
      </Panel>
    );
  }
}

export default ConvenioDetalheInformacao;
