import * as types from './Types.js';

export function findFornecimentoBens(filter) {
  return ({ fetch }) => ({
    type: types.FORNECIMENTO_BENS,
    payload: {
      promise: fetch(`/api/liquidacoes/ordem-cronologica-pagamentos?${filter}`)
    }
  });
}

export function findFornecimentoBensTotal(filter) {
  return ({ fetch }) => ({
    type: types.FORNECIMENTO_BENS_TOTAL,
    payload: {
      promise: fetch(
        `/api/liquidacoes/ordem-cronologica-pagamentos/total?${filter}`
      )
    }
  });
}

export function findLocacoes(filter) {
  return ({ fetch }) => ({
    type: types.LOCACOES,
    payload: {
      promise: fetch(`/api/liquidacoes/ordem-cronologica-pagamentos?${filter}`)
    }
  });
}

export function findLocacoesTotal(filter) {
  return ({ fetch }) => ({
    type: types.LOCACOES_TOTAL,
    payload: {
      promise: fetch(
        `/api/liquidacoes/ordem-cronologica-pagamentos/total?${filter}`
      )
    }
  });
}

export function findPrestacaoServicos(filter) {
  return ({ fetch }) => ({
    type: types.PRESTACAO_SERVICOS,
    payload: {
      promise: fetch(`/api/liquidacoes/ordem-cronologica-pagamentos?${filter}`)
    }
  });
}

export function findPrestacaoServicosTotal(filter) {
  return ({ fetch }) => ({
    type: types.PRESTACAO_SERVICOS_TOTAL,
    payload: {
      promise: fetch(
        `/api/liquidacoes/ordem-cronologica-pagamentos/total?${filter}`
      )
    }
  });
}

export function findObras(filter) {
  return ({ fetch }) => ({
    type: types.OBRAS,
    payload: {
      promise: fetch(`/api/liquidacoes/ordem-cronologica-pagamentos?${filter}`)
    }
  });
}

export function findObrasTotal(filter) {
  return ({ fetch }) => ({
    type: types.OBRAS_TOTAL,
    payload: {
      promise: fetch(
        `/api/liquidacoes/ordem-cronologica-pagamentos/total?${filter}`
      )
    }
  });
}

export function findPagamentos(filter) {
  return ({ fetch }) => ({
    type: types.PAGAMENTOS,
    payload: {
      promise: fetch(`/api/liquidacoes/ordem-cronologica-pagamentos?${filter}`)
    }
  });
}

export function findPagamentosTotal(filter) {
  return ({ fetch }) => ({
    type: types.PAGAMENTOS_TOTAL,
    payload: {
      promise: fetch(
        `/api/liquidacoes/ordem-cronologica-pagamentos/total?${filter}`
      )
    }
  });
}
