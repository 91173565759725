import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export class LicitacaoDetalhePreco extends Component {
  static propTypes = {
    precos: PropTypes.object.isRequired,
    onDownload: PropTypes.func.isRequired
  };

  verificaArquivo = data => {
    if (data.get('arquivos').length) {
      return <td>{data.get('arquivos').map(this.renderArquivo)}</td>;
    }
    return <td>Sem Arquivo</td>;
  };

  renderArquivo = arquivo => {
    const { onDownload } = this.props;

    return (
      <div key={arquivo.get('idArquivo')} style={{ marginTop: '3px' }}>
        <a
          style={{ cursor: 'pointer' }}
          onClick={() => onDownload(arquivo.get('idArquivo'))}
          hidden={arquivo.length === 0}
        >
          <i className="fa fa-download" style={{ marginRight: '5px' }} />
          {arquivo.get('nomeArquivo')}
        </a>
      </div>
    );
  };

  renderPrecos = data => {
    const key = data.get('fornecedor') + '_' + data.get('cnpjCpf');

    return (
      <TableActions ref="row" key={key} data={key}>
        <td>{data.get('fornecedor')}</td>
        <td>{data.get('cnpjCpf')}</td>
        {this.verificaArquivo(data)}
      </TableActions>
    );
  };

  render() {
    const { precos } = this.props;

    return (
      <div className="panel-table-outer">
        <table className="panel-table striped fancy">
          <TableHeader empty={precos.isEmpty()}>
            <tr>
              <th>Fornecedor</th>
              <th>CNPJ/CPF</th>
              <th>Arquivos</th>
              <th></th>
            </tr>
          </TableHeader>
          <tbody>{precos.map(this.renderPrecos)}</tbody>
        </table>
      </div>
    );
  }
}

export default LicitacaoDetalhePreco;
