import Panel from 'portaltransparencia-common/components/Panel.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export class AgendaPagamentoRetencoes extends Component {
  static propTypes = {
    retencaoLiquidacao: PropTypes.object,
    findRetencoesLiquidacao: PropTypes.func,
    filtroRetencoes: PropTypes.object
  };

  renderRetencoes = retencao => {
    return (
      <tr
        key={`${retencao.get('empenho')}_${retencao.get(
          'noLiquidacao'
        )}_${retencao.get('valor')}_`}
      >
        <td>{retencao.get('empenho')}</td>
        <td>{retencao.get('noLiquidacao')}</td>
        <td>{retencao.get('valor')}</td>
        <td>{retencao.get('valorEstornado')} </td>
        <td>{retencao.get('descricao')} </td>
      </tr>
    );
  };

  render() {
    const { retencaoLiquidacao } = this.props;

    if (retencaoLiquidacao.length > 0) {
      return (
        <Panel title={'Retenções'} expansible={false} isTable>
          <table className="panel-table striped fancy">
            <TableHeader empty={retencaoLiquidacao.length == 0}>
              <tr>
                <th>Empenho</th>
                <th>Liquidação</th>
                <th>Valor</th>
                <th>Valor Est.</th>
                <th>Descrição</th>
              </tr>
            </TableHeader>

            <tbody>
              {this.props.retencaoLiquidacao.map(this.renderRetencoes)}
            </tbody>
          </table>
        </Panel>
      );
    }
    return null;
  }
}

export default AgendaPagamentoRetencoes;
