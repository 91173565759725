import * as types from './Types.js';

export function findArrecadacaoDespesaMes(fundeb) {
  return ({ fetch }) => {
    return {
      type: types.ARRECADACAO_DESPESA_MES,
      payload: {
        promise: fetch(
          `/api/receitaxdespesa/arrecadacao-despesa?fundeb=${fundeb}`
        )
      }
    };
  };
}

export function findEmpenhoValorAnual(fundeb) {
  return ({ fetch }) => {
    return {
      type: types.RECEITA_VALOR_ATUALIZADO,
      payload: {
        promise: fetch(`/api/receitaxdespesa/empenhado-anual?fundeb=${fundeb}`)
      }
    };
  };
}
