import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';

import * as types from './Types.js';

const initialState = fromJS({
  arquivo: {}
});

function medidasAdotadasCobrancaReducer(state = initialState, action) {
  switch (action.type) {
    case types.MEDIDAS_ADOTADAS_COBRANCA_START: {
      return startLoad(state);
    }

    case types.MEDIDAS_ADOTADAS_COBRANCA_SUCCESS: {
      return endLoad(state.set('arquivo', fromJS(action.payload)));
    }

    case types.MEDIDAS_ADOTADAS_COBRANCA_ERROR: {
      return endLoad(state);
    }
  }

  return state;
}

export default medidasAdotadasCobrancaReducer;
