import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react.js';
import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import storageService from 'portaltransparencia-common/libs/StorageService.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import OrdemObservacao from '../ordemcronologicapagamentos/OrdemObservacao.react';

import LinkToEmpenho from '../empenhos/LinkToEmpenho.react';

export default class LiquidacaoTable extends Component {
  static propTypes = {
    liquidacao: PropTypes.object.isRequired,
    tipo: PropTypes.string.isRequired,
    paramDespesasPagasValor: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      showDetail: false
    };
  }

  openDetail = () => {
    this.setState({
      showDetail: true
    });
  };

  onCloseDetail = () => {
    this.setState({
      showDetail: false
    });
  };

  onViewEmpenho = item => {
    const { tipo } = this.props;
    const entidade = item.get('entidade');
    const exercicio = item.get('exercicio');
    const empenho = item.get('empenho');

    let active = 'liquidacoes';
    if (tipo == 'pagas') {
      active = 'pagamentos';
    }

    this.props.history.push(
      `/empenhos/detalhe?search=id.entidade==${entidade}&entidade=${entidade}&exercicio=${exercicio}&empenho=${empenho}&active=${active}`
    );
  };

  generateKey = liquidacao => {
    return `${liquidacao.get('entidade')}_
            ${liquidacao.get('exercicio')}_
            ${liquidacao.get('empenhoExercicio')}_
            ${liquidacao.get('empenho')}_
            ${liquidacao.get('data')}_
            ${liquidacao.get('noLiquidacao')}_
            ${liquidacao.get('valor')}`;
  };

  render() {
    const { liquidacao, tipo } = this.props;
    const { entidade } = storageService.getItem('userInfo');
    const { showDetail } = this.state;

    return (
      <TableActions
        ref="row"
        key={this.generateKey(liquidacao)}
        data={liquidacao}
        onMore={
          tipo !== 'ordem-cronologica-pagamentos'
            ? this.onViewEmpenho
            : undefined
        }
        onView={
          tipo === 'ordem-cronologica-pagamentos' &&
          liquidacao.get('observacao') !== ''
            ? this.openDetail
            : undefined
        }
      >
        {entidade && entidade.id <= 0 && (
          <td className="center">{liquidacao.get('entidade')}</td>
        )}
        {(tipo === 'aptos-pagamentos' ||
          tipo === 'ordem-cronologica-pagamentos') && (
          <td>{liquidacao.get('ordemFonteRecurso')}</td>
        )}
        <td>{liquidacao.get('fonteRecurso')}</td>
        {tipo === 'pagas' && (
          <td className="right">
            {DateUtils.formatDateTimeShort(liquidacao.get('dataLiquidacao'))}
          </td>
        )}
        {tipo !== 'ordem-cronologica-pagamentos' && (
          <td className="right">
            {DateUtils.formatDateTimeShort(liquidacao.get('data'))}
          </td>
        )}
        <td className="right">
          <LinkToEmpenho
            entidade={liquidacao.get('entidade')}
            exercicio={liquidacao.get('exercicio')}
            empenho={liquidacao.get('empenho')}
          />
        </td>
        <td className="right">{liquidacao.get('noLiquidacao')}</td>
        <td>{liquidacao.get('fornecedor')}</td>
        {tipo !== 'ordem-cronologica-pagamentos' && (
          <td>{liquidacao.get('tipoLicitacao')}</td>
        )}
        {tipo !== 'ordem-cronologica-pagamentos' && (
          <td className="right">{liquidacao.get('licitacao')}</td>
        )}
        {tipo !== 'ordem-cronologica-pagamentos' && (
          <td>{liquidacao.get('notasFiscais')}</td>
        )}
        {tipo === 'ordem-cronologica-pagamentos' && (
          <td colSpan={3}>{liquidacao.get('notasFiscais')}</td>
        )}
        {tipo === 'ordem-cronologica-pagamentos' && (
          <td className="right">
            {DateUtils.formatDateTimeShort(liquidacao.get('dataLiquidacao'))}
          </td>
        )}
        {tipo === 'ordem-cronologica-pagamentos' && (
          <td className="right">
            {DateUtils.formatDateTimeShort(liquidacao.get('dataVencimento'))}
          </td>
        )}
        {tipo === 'ordem-cronologica-pagamentos' && (
          <td className="right">
            {DateUtils.formatDateTimeShort(liquidacao.get('data'))}
          </td>
        )}
        <td className="right">
          {tipo === 'pagas' && this.props.paramDespesasPagasValor === 'S' ? (
            <NumberFormatter
              id="valor"
              value={liquidacao.get('valorParametro')}
            />
          ) : (
            <NumberFormatter id="valor" value={liquidacao.get('valor')} />
          )}
        </td>
        {tipo !== 'ordem-cronologica-pagamentos' && (
          <td className="right">
            {tipo === 'pagas' && this.props.paramDespesasPagasValor === 'S' ? (
              <NumberFormatter
                id="valorsaldo"
                value={liquidacao.get('valorParametro')}
              />
            ) : (
              <NumberFormatter
                id="valorsaldo"
                value={liquidacao.get('saldo')}
              />
            )}
          </td>
        )}

        <OrdemObservacao
          show={showDetail}
          onClose={this.onCloseDetail}
          justificativa={liquidacao.get('observacao')}
        ></OrdemObservacao>
      </TableActions>
    );
  }
}
