import { ReactComponent as ImgPortal } from '@elotech/arc/src/assets/img/modules/oxy-portal-transparencia.svg';
import classnames from 'classnames';
import { List } from 'immutable';
import $ from 'jquery';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

import AcessoESIC from './AcessoESIC.react.js';
import Menu from './Menu.react.js';

export class Header extends Component {
  static propTypes = {
    hideLogo: PropTypes.bool,
    menu: PropTypes.instanceOf(List).isRequired,
    selo: PropTypes.string
  };

  constructor() {
    super();
    this.state = {
      activeMenu: false,
      activePesquisa: false
    };
  }

  componentDidMount() {
    //--- MENU ---

    //clicar fora do menu
    $(document).mouseup(e => {
      const container = $('.menu-area');

      if (
        !container.is(e.target) && // if the target of the click isn't the container...
        container.has(e.target).length === 0 && // ... nor a descendant of the container
        container.hasClass('active')
      ) {
        this.closeMenu();
      }
    });

    //Seta para menu com subitens
    $('.menu-itens li.item').has('ul').addClass('hasul');

    $('.menu-area .menu-itens .father').click(function () {
      $(this).parent().toggleClass('active');
    });

    //--- Busca ---

    //clicar fora da busca
    $(document).mouseup(e => {
      var container = $('.header .search');

      if (
        !container.is(e.target) && // if the target of the click isn't the container...
        container.has(e.target).length === 0 &&
        container.hasClass('active')
      ) {
        // ... nor a descendant of the container
        this.closePesquisa();
      }
    });

    if (window.addEventListener) {
      var kkeys = [],
        konami = '80,79,82,84,65,76,71,65,76,79'; //this spells dinner
      window.addEventListener(
        'keydown',
        function (e) {
          kkeys.push(e.keyCode);
          if (kkeys.toString().indexOf(konami) >= 0) {
            // run code here
            $('.portal .header .logo-area-label').text('Portal GALO!');
            $('.portal .header .logo-img img').attr(
              'src',
              'https://i.imgbox.com/g3mewWZy.gif'
            );
            $('.portal .header .logo-img').addClass('galo');
          }
        },
        true
      );
    }
  }

  onClickPesquisa = () => {
    const pesquisa = this.refs.pesquisa.value;

    this.props.history.push(`/pesquisa?pesquisa=${pesquisa}`);
  };

  handleKeyDownPesquisa = event => {
    const teclaEnter = 13;
    if (event.keyCode === teclaEnter) {
      event.preventDefault();
      this.onChangePesquisa();
    }
  };

  onChangePesquisa = () => {
    this.onClickPesquisa();
  };

  closeMenu = () => {
    this.setState({
      activeMenu: false
    });
  };

  onClickActiveMenu = () => {
    this.setState({
      activeMenu: !this.state.activeMenu
    });
  };

  onClickActivePesquisa = () => {
    this.setState({
      activePesquisa: !this.state.activePesquisa
    });
  };

  closePesquisa = () => {
    this.setState({
      activePesquisa: false
    });
  };

  onClickSelo = () => {
    this.props.history.push('/selo');
  };

  render() {
    const { menu, hideLogo } = this.props;
    const { activeMenu, activePesquisa } = this.state;

    const classActiveMenu = {
      'menu-area': true,
      active: activeMenu
    };

    const classActivePesquisa = {
      search: true,
      active: activePesquisa
    };

    let imgSrc = '/assets/';
    if (
      process.env.NODE_ENV === 'production' &&
      process.env.PUBLIC_URL.includes('portaltransparencia')
    ) {
      imgSrc = '/portaltransparencia/assets/';
    }

    return (
      <header className="header">
        <div className="container">
          <div className="cousins">
            <div className={classnames(classActiveMenu)}>
              <div className="menu-button" onClick={this.onClickActiveMenu}>
                <button className="menu-button-toggle">
                  <span />
                  <span />
                  <span />
                </button>
                <b className="menu-label">Menu</b>
              </div>

              <Menu menu={menu} onClose={this.closeMenu} />
            </div>
          </div>

          {!hideLogo && (
            <div className="cousins">
              <div className="logo-area">
                <Link to="/">
                  <ImgPortal className="portal-logo" />
                </Link>
              </div>
            </div>
          )}

          <div className="cousins">
            <a
              href={
                'https://radar.tce.mt.gov.br/extensions/radar-da-transparencia-publica/panel.html'
              }
              title="Radar da Transparência"
              target="_blank"
            >
              <div className="esic header-buttons">
                <img className="fa" src={`${imgSrc}img/common/pntp-logo.png`} />
                <b className="title">
                  Radar da
                  <br />
                  Transparência
                </b>
              </div>
            </a>

            <AcessoESIC />

            <div
              id="botaoPesquisar"
              className="open-search header-buttons"
              onClick={this.onClickActivePesquisa}
            >
              <em className="fa fa-search" />
              <b className="title">Pesquisar</b>
            </div>

            {activePesquisa && (
              <div className={classnames(classActivePesquisa)}>
                <div id="searchform">
                  <input
                    autoFocus={classActivePesquisa.active}
                    ref="pesquisa"
                    name="pesquisa"
                    placeholder="Buscar.."
                    type="text"
                    onChange={this.onChangePesquisa}
                    onKeyDown={this.handleKeyDownPesquisa}
                  />
                  <button type="button" onClick={this.onClickPesquisa}>
                    <i className="fa fa-search" />
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </header>
    );
  }
}

export default withRouter(Header);
