import { fromJS } from 'immutable';
import * as types from './Types';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core';

const initialState = fromJS({
  page: {},
  classificacoes: [],
  resumo: {},
  totalizador: {}
});

const RenunciaReceitasReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FIND_RENUNCIA_RECEITAS_START:
      return startLoad(state);
    case types.FIND_RENUNCIA_RECEITAS_SUCCESS:
      return endLoad(state.set('page', fromJS(action.payload)));
    case types.FIND_RENUNCIA_RECEITAS_ERROR:
      return endLoad(state);

    case types.FIND_CLASSIFICACOES_START:
      return startLoad(state);
    case types.FIND_CLASSIFICACOES_SUCCESS:
      return endLoad(state.set('classificacoes', action.payload));
    case types.FIND_CLASSIFICACOES_ERROR:
      return endLoad(state);

    case types.FIND_RESUMO_START:
      return startLoad(state);
    case types.FIND_RESUMO_SUCCESS:
      return endLoad(state.set('resumo', action.payload));
    case types.FIND_RESUMO_ERROR:
      return endLoad(state);

    case types.FIND_TOTAL_RESUMO_START:
      return startLoad(state);
    case types.FIND_TOTAL_RESUMO_SUCCESS:
      return endLoad(state.set('totalizador', action.payload));
    case types.FIND_TOTAL_RESUMO_ERROR:
      return endLoad(state);

    default:
      return state;
  }
};

export default RenunciaReceitasReducer;
