import Modal from 'portaltransparencia-common/components/Modal.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

import AgendaPagamentoRetencoes from './AgendaPagamentoRetencoes.react';

export class AgendaPagamentoConta extends Component {
  static propTypes = {
    contaPagamento: PropTypes.object.isRequired,
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    retencoesLiquidacao: PropTypes.array
  };

  render() {
    const { contaPagamento, show, onClose, retencoesLiquidacao } = this.props;
    let title;

    if (contaPagamento.size === 0) {
      title = 'Processo: ';
    } else {
      title = `Processo: ${contaPagamento.get('empenhoExercicio')}`;
    }

    if (show) {
      return (
        <Modal onClose={onClose}>
          <div className="container">
            <Panel title={title} expansible={false} isTable>
              <table className="panel-table striped fancy">
                <TableHeader
                  empty={contaPagamento.size === 0}
                  text={'Sem conta bancária cadastrada para essa Liquidação'}
                >
                  <tr>
                    <th>Banco</th>
                    <th>Agência</th>
                    <th>Conta</th>
                  </tr>
                </TableHeader>
                <tbody>
                  {contaPagamento.get('conta') && (
                    <tr key={contaPagamento.get('conta')} data={contaPagamento}>
                      <td>{`${contaPagamento.get(
                        'banco'
                      )} - ${contaPagamento.get('descricaoBanco')}`}</td>
                      <td>{contaPagamento.get('agencia')}</td>
                      <td>{contaPagamento.get('conta')} </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </Panel>
            <AgendaPagamentoRetencoes
              retencaoLiquidacao={retencoesLiquidacao}
            />
          </div>
        </Modal>
      );
    }
    return null;
  }
}

export default AgendaPagamentoConta;
