import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';

import * as types from './Types.js';

const initialState = fromJS({
  pcas: [],
  pca: []
});

function pcaReducer(state = initialState, action) {
  switch (action.type) {
    case types.PCAS_START:
    case types.PCA_START: {
      return startLoad(state);
    }

    case types.PCAS_SUCCESS: {
      return endLoad(state.set('pcas', fromJS(action.payload)));
    }

    case types.PCA_SUCCESS: {
      return endLoad(state.set('pca', fromJS(action.payload)));
    }

    case types.PCAS_ERROR:
    case types.PCA_ERROR: {
      return endLoad(state);
    }

    case types.RESET_CONSULTA_PCAS: {
      return initialState;
    }
  }

  return state;
}

export default pcaReducer;
