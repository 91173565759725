import StringUtils from './StringUtils.js';

const listUtils = {
  searchTree(item, filter, fieldsToSearch, subListName) {
    var filtro = StringUtils.removeAcento(filter).toUpperCase();

    for (let i = 0; i < fieldsToSearch.length; i++) {
      const texto = item.get(fieldsToSearch[i]) || '';
      var valor = StringUtils.removeAcento(texto).toUpperCase();

      if (valor.includes(filtro)) {
        return true;
      }
    }

    if (item.has(subListName)) {
      let listaFiltrada = item
        .get(subListName)
        .filter(item =>
          this.searchTree(item, filter, fieldsToSearch, subListName)
        );

      return !listaFiltrada.isEmpty();
    }

    return false;
  },

  filterTree(list, filter, fieldsToSearch, subListName) {
    const listaFiltrada = list.filter(item =>
      this.searchTree(item, filter, fieldsToSearch, subListName)
    );

    return listaFiltrada.map(item => {
      if (item.has(subListName)) {
        const sublist = item
          .get(subListName)
          .filter(subitem =>
            this.searchTree(subitem, filter, fieldsToSearch, subListName)
          );

        return item.set(subListName, sublist);
      }
      return item;
    });
  },

  filterTreePublicacoes(list, filter, fieldsToSearch, subListName) {
    const listaFiltrada = list.filter(item =>
      this.searchTree(item, filter, fieldsToSearch, subListName)
    );

    return listaFiltrada.map(item => {
      if (item.has(subListName)) {
        const sublist = item
          .get(subListName)
          .filter(subitem =>
            this.searchTree(subitem, filter, fieldsToSearch, subListName)
          );
        if (!sublist.isEmpty) {
          return item.set(subListName, sublist);
        }
        return item;
      }
      return item;
    });
  }
};

export default listUtils;
