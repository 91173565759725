import { Map } from 'immutable';
import DatePicker from 'portaltransparencia-common/components/DatePicker.react.js';
import Input from 'portaltransparencia-common/components/EloInput.react.js';
import ExportButtonGroup from 'portaltransparencia-common/components/ExportButtonGroup.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import SearchPagination from 'portaltransparencia-common/components/SearchPagination.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import TableHeaderItem from 'portaltransparencia-common/components/TableHeaderItem.react';
import configuration from 'portaltransparencia-common/config/Config.js';
import {
  onChange,
  resetCrud
} from 'portaltransparencia-common/crud/Actions.js';
import {
  addFilterRsql,
  addPagination
} from 'portaltransparencia-common/libs/AddFilter.js';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import { somenteNumeros } from 'portaltransparencia-common/libs/StringUtils';
import {
  PARAM_GASTOSVEICULOS,
  PARAM_VEICULOS_MOVIMENTADOS
} from 'portaltransparencia-common/parametros/ParametrosPortalTransparencia';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import FilterButtons from '../components/FilterButtons.react';
import PageHeader from '../components/PageHeader.react.js';
import NotasExplicativas from '../notaexplicativa/Form.react';
import ParametroService from '../parametros/ParametroService.js';
import { findStatusIntegracaoOxy } from '../status-integracao/Actions';
import UltimaAtualizacao from '../ultimaatualizacao/Form.react.js';
import * as actions from './Actions.js';
import { letraPlaca, numeroPlaca } from './SeparacaoPlaca';
import Veiculo from './Veiculo.react.js';
import { addErrorNotification } from 'portaltransparencia-common/ui/Actions.js';

export class Form extends Component {
  static propTypes = {
    page: PropTypes.object.isRequired,
    searchVeiculos: PropTypes.func.isRequired,
    loadTiposAlienacao: PropTypes.func.isRequired,
    paramGastosVeiculo: PropTypes.string,
    filtros: PropTypes.object,
    tiposAlienacao: PropTypes.object,
    onChange: PropTypes.func,
    location: PropTypes.object.isRequired,
    resetCrud: PropTypes.func.isRequired,
    findStatusIntegracaoOxy: PropTypes.func.isRequired,
    entidades: PropTypes.object,
    mostraEntidades: PropTypes.bool,
    addErrorNotification: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = { isIntegradoComOxy: false };
  }

  componentDidMount() {
    const { loadTiposAlienacao, searchVeiculos } = this.props;
    this.onChangeFiltro({
      name: 'baixado',
      value: 'N'
    });
    this.verificaIntegracaoComOxy();

    searchVeiculos("?search=baixado=='N'");
    loadTiposAlienacao();
  }

  verificaIntegracaoComOxy = () => {
    const { findStatusIntegracaoOxy } = this.props;

    findStatusIntegracaoOxy().then(({ value }) =>
      this.setState({ isIntegradoComOxy: value })
    );
  };

  onChangeFiltro = event => {
    const { onChange } = this.props;

    onChange('filtroVeiculos', event);
  };

  onResetFiltro = () => {
    const { resetCrud, searchVeiculos } = this.props;
    resetCrud('filtroVeiculos');
    searchVeiculos('');
  };

  onConsultaVeiculos = event => {
    const { page } = this.props;

    const initialPage = page.set('number', 0);

    this.searchWithPage(initialPage);

    event && event.preventDefault();
    this.refs.panelFiltroVeiculos && this.refs.panelFiltroVeiculos.collapse();
  };

  searchWithPage = page => {
    const { searchVeiculos } = this.props;

    searchVeiculos(this.montaFiltro(page));
  };

  onExporta = (exportType, openTab, windowParams) => {
    const { apiURL } = configuration;
    const { page, addErrorNotification } = this.props;

    if (page.get('content').size > 0) {
      let filtro = this.montaFiltro(page) + '&exportType=' + exportType;

      window.open(
        `${apiURL}/api/veiculos/report?${filtro}`,
        openTab,
        windowParams
      );
    } else {
      addErrorNotification(
        'A consulta não apresentou resultado. Não é possível exportar para o formato selecionado!'
      );
    }
  };

  montaFiltro = function (page) {
    const { entidade } = StorageService.getItem('userInfo');
    const { filtros } = this.props;
    const { isIntegradoComOxy } = this.state;

    return isIntegradoComOxy
      ? this.montarFiltroOxy(page, entidade, filtros)
      : this.montarFiltroSiscop(page, entidade, filtros);
  };

  renderVeiculos = data => {
    const { paramGastosVeiculo, mostraEntidades, entidades } = this.props;

    let veiculo = data;
    if (mostraEntidades) {
      veiculo = veiculo.set(
        'entidadeNome',
        entidades
          ?.find(e => e.get('id') === veiculo.get('entidade'))
          ?.get('nome')
      );
    }

    return (
      <Veiculo
        history={this.props.history}
        key={veiculo.get('veiculo')}
        veiculo={veiculo}
        paramGastosVeiculo={paramGastosVeiculo}
        mostraEntidade={mostraEntidades}
      />
    );
  };

  montarFiltroOxy = (page, entidade, filtros) => {
    let filter = '';

    const tipoAlienacao =
      (this.refs &&
        this.refs.tipoAlienacao &&
        this.refs.tipoAlienacao.getValue()) ||
      '';

    const placa =
      (this.refs && this.refs.placa && this.refs.placa.getValue()) || '';

    if (filtros.get('baixado')) {
      const baixadoBoolean = filtros.get('baixado') === 'S';
      filter = addFilterRsql(filter, `baixado=='${baixadoBoolean}'`);
    }

    if (filtros.get('descricao')) {
      filter = addFilterRsql(
        filter,
        `descricao=='*${filtros.get('descricao')}*'`
      );
    }
    if (filtros.get('centroCusto')) {
      filter = addFilterRsql(
        filter,
        `local.centroCusto.descricao=='*${filtros.get('centroCusto')}*'`
      );
    }
    if (placa) {
      filter = addFilterRsql(filter, `placa=='*${placa}*'`);
    }
    if (tipoAlienacao) {
      filter = addFilterRsql(filter, `operacao.descricao=='${tipoAlienacao}'`);
    }
    if (filtros.get('dataInicial') && filtros.get('dataInicial').length == 10) {
      filter = addFilterRsql(
        filter,
        `dataAquisicao>='${filtros.get('dataInicial')}'`
      );
    }
    if (filtros.get('dataFinal') && filtros.get('dataFinal').length == 10) {
      filter = addFilterRsql(
        filter,
        `dataAquisicao<='${filtros.get('dataFinal')}'`
      );
    }
    if (filtros.get('frota')) {
      filter = addFilterRsql(
        filter,
        `numeroFrota=='*${somenteNumeros(filtros.get('frota'))}*'`
      );
    }
    if (filter !== '') {
      filter = '&search=' + filter;
    }

    return `entidade=${entidade.id}&${addPagination(page, filter)}`;
  };

  montarFiltroSiscop = (page, entidade, filtros) => {
    let filter = '';

    const tipoAlienacao =
      (this.refs &&
        this.refs.tipoAlienacao &&
        this.refs.tipoAlienacao.getValue()) ||
      '';
    const placa =
      (this.refs && this.refs.placa && this.refs.placa.getValue()) || '';

    if (filtros.get('baixado')) {
      filter = addFilterRsql(filter, `baixado=='${filtros.get('baixado')}'`);
    }

    if (filtros.get('descricao')) {
      filter = addFilterRsql(
        filter,
        `descricao=acc='*${filtros.get('descricao')}*'`
      );
    }
    if (filtros.get('centroCusto')) {
      filter = addFilterRsql(
        filter,
        `centroCusto.descricao=acc='*${filtros.get('centroCusto')}*'`
      );
    }
    if (placa) {
      filter = addFilterRsql(filter, `numeroPlaca=='*${numeroPlaca(placa)}*'`);
    }
    if (placa) {
      filter = addFilterRsql(filter, `letraPlaca=='*${letraPlaca(placa)}*'`);
    }
    if (tipoAlienacao) {
      filter = addFilterRsql(filter, `bem.incorporacao.id==${tipoAlienacao}`);
    }
    if (filtros.get('dataInicial') && filtros.get('dataInicial').length == 10) {
      filter = addFilterRsql(
        filter,
        `bem.dataAquisicao>='${filtros.get('dataInicial')}'`
      );
    }
    if (filtros.get('dataFinal') && filtros.get('dataFinal').length == 10) {
      filter = addFilterRsql(
        filter,
        `bem.dataAquisicao<='${filtros.get('dataFinal')}'`
      );
    }
    if (filtros.get('frota')) {
      filter = addFilterRsql(
        filter,
        `bem.numeroFrota=='*${somenteNumeros(filtros.get('frota'))}*'`
      );
    }
    if (filter !== '') {
      filter = '&search=' + filter;
    }

    return `entidade=${entidade.id}&${addPagination(page, filter)}`;
  };

  renderTiposAlienacao = data => {
    return (
      <option key={data} value={data.get('id')}>
        {data.get('descricao')}
      </option>
    );
  };

  render() {
    const { page, filtros, tiposAlienacao, mostraEntidades } = this.props;
    const { isIntegradoComOxy } = this.state;

    if (!page.has('content')) {
      return null;
    }

    return (
      <section>
        <PageHeader location={this.props.location} />

        <NotasExplicativas pathname={this.props.location.pathname} />

        <Panel
          title="Consultar em Veículos"
          isForm
          mobileClose
          ref="panelFiltroVeiculos"
        >
          <form>
            <>
              <Row>
                <Col xs={12} sm={4} md={4}>
                  <Input
                    autoFocus
                    name="descricao"
                    ref="descricao"
                    placeholder="Veículo"
                    label="Veículo"
                    type="text"
                    onChange={this.onChangeFiltro}
                    value={filtros.get('descricao')}
                  />
                </Col>
                <Col xs={6} sm={4} md={2}>
                  <Input
                    id="placa"
                    name="placa"
                    ref="placa"
                    placeholder="Placa"
                    label="Placa"
                    type="text"
                    onChange={this.onChangeFiltro}
                    value={filtros.get('placa')}
                  />
                </Col>
                <Col xs={6} sm={4} md={2}>
                  <Input
                    id="frota"
                    name="frota"
                    ref="frota"
                    placeholder="Frota"
                    label="Frota"
                    type="text"
                    onChange={this.onChangeFiltro}
                    value={filtros.get('frota')}
                  />
                </Col>
                <Col xs={12} sm={4} md={4}>
                  <Input
                    type="text"
                    ref="centroCusto"
                    name="centroCusto"
                    placeholder="Setor"
                    label="Setor"
                    onChange={this.onChangeFiltro}
                    value={filtros.get('centroCusto')}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={6} sm={4} md={3}>
                  <DatePicker
                    name="dataInicial"
                    ref="dataInicial"
                    label="Data Incorporação Inicial"
                    onChange={this.onChangeFiltro}
                    value={filtros.get('dataInicial')}
                  />
                </Col>
                <Col xs={6} sm={4} md={3}>
                  <DatePicker
                    name="dataFinal"
                    ref="dataFinal"
                    label="Data Incorporação Final"
                    onChange={this.onChangeFiltro}
                    value={filtros.get('dataFinal')}
                  />
                </Col>
                <Col xs={6} sm={4} md={3}>
                  <Input
                    id="tipoAlienacao"
                    name="tipoAlienacao"
                    ref="tipoAlienacao"
                    label="Tipo Incorporação"
                    type="select"
                    onChange={this.onChangeFiltro}
                    value={filtros.get('tipoAlienacao')}
                  >
                    <option value="">Selecione</option>
                    {tiposAlienacao.map(this.renderTiposAlienacao)}
                  </Input>
                </Col>
                <Col xs={6} sm={4} md={3}>
                  <Input
                    id="baixado"
                    name="baixado"
                    ref="baixado"
                    label="Situação"
                    type="select"
                    onChange={this.onChangeFiltro}
                    value={filtros.get('baixado')}
                  >
                    <option value="">Selecione</option>
                    <option key={'N'} value={'N'}>
                      Ativo
                    </option>
                    <option key={'S'} value={'S'}>
                      Baixado
                    </option>
                  </Input>
                </Col>
                <Col xs={12} sm={4} md={3}>
                  <FilterButtons
                    idBtnPesquisa="btnPesquisar"
                    onConsulta={this.onConsultaVeiculos}
                    onReset={this.onResetFiltro}
                  />
                </Col>
              </Row>
            </>
          </form>
        </Panel>

        <Panel isForm expansible={false} tableResponsive>
          <Row>
            <Col xs={6}>
              <UltimaAtualizacao modulo="8" />
            </Col>
            <Col xs={6}>
              <ExportButtonGroup onClick={this.onExporta} visible={true} />
            </Col>
          </Row>

          <div className="panel-table-outer">
            <table className="panel-table striped fancy">
              <TableHeader empty={page.get('content').isEmpty()}>
                <tr>
                  <TableHeaderItem
                    field="descricao"
                    label="Veículo"
                    page={page}
                    searchWithPage={this.searchWithPage}
                  />
                  <TableHeaderItem
                    field="letraPlaca"
                    label="Placa"
                    page={page}
                    searchWithPage={this.searchWithPage}
                  />
                  <TableHeaderItem
                    field="bem.numeroFrota"
                    label="Frota"
                    page={page}
                    searchWithPage={this.searchWithPage}
                  />
                  <TableHeaderItem
                    field="chassi"
                    label="Chassi"
                    page={page}
                    searchWithPage={this.searchWithPage}
                  />
                  <TableHeaderItem
                    field="renavan"
                    label="Renavan"
                    page={page}
                    searchWithPage={this.searchWithPage}
                  />
                  <TableHeaderItem
                    field={
                      isIntegradoComOxy ? 'dataAquisicao' : 'bem.dataAquisicao'
                    }
                    label="Incorporação"
                    page={page}
                    searchWithPage={this.searchWithPage}
                  />
                  <TableHeaderItem
                    field={
                      isIntegradoComOxy
                        ? 'local.centroCusto.descricao'
                        : 'centroCusto.descricao'
                    }
                    label="Setor"
                    page={page}
                    searchWithPage={this.searchWithPage}
                  />
                  <TableHeaderItem
                    field={isIntegradoComOxy ? 'baixado' : 'bem.baixado'}
                    label="Situação"
                    page={page}
                    searchWithPage={this.searchWithPage}
                  />
                  {mostraEntidades && (
                    <TableHeaderItem
                      field={'entidadeNome'}
                      label="Entidade"
                      sortable={false}
                    />
                  )}
                  <th></th>
                </tr>
              </TableHeader>
              <tbody>{page.get('content').map(this.renderVeiculos)}</tbody>
            </table>
          </div>
        </Panel>

        <SearchPagination page={page} searchWithPage={this.searchWithPage} />
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    page: state.veiculo.get('veiculos'),
    tiposAlienacao: state.veiculo.get('tiposAlienacao'),
    paramGastosVeiculo: ParametroService.getValorParametro(
      state,
      PARAM_GASTOSVEICULOS
    ),
    mostraEntidades:
      ParametroService.getValorParametro(state, PARAM_VEICULOS_MOVIMENTADOS) ===
      'S',
    filtros: state.crud.getIn(['filtroVeiculos', 'currentRecord'], Map({})),
    entidades: state.entidade.get('entidades')
  };
}

const allActions = {
  ...actions,
  onChange,
  resetCrud,
  findStatusIntegracaoOxy,
  addErrorNotification
};

export default connect(mapStateToProps, allActions)(Form);
