export const LEILGPD = 'LEILGPD';
export const LEILGPD_START = 'LEILGPD_START';
export const LEILGPD_SUCCESS = 'LEILGPD_SUCCESS';
export const LEILGPD_ERROR = 'LEILGPD_ERROR';

export const RESPONSAVEL_LGPD = 'RESPONSAVEL_LGPD';
export const RESPONSAVEL_LGPD_START = 'RESPONSAVEL_LGPD_START';
export const RESPONSAVEL_LGPD_SUCCESS = 'RESPONSAVEL_LGPD_SUCCESS';
export const RESPONSAVEL_LGPD_ERROR = 'RESPONSAVEL_LGPD_ERROR';

export const ARQUIVO_LGPD = 'ARQUIVO_LGPD';
export const ARQUIVO_LGPD_START = 'ARQUIVO_LGPD_START';
export const ARQUIVO_LGPD_SUCCESS = 'ARQUIVO_LGPD_SUCCESS';
export const ARQUIVO_LGPD_ERROR = 'ARQUIVO_LGPD_ERROR';
