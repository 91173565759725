import React from 'react';
import Component from 'react-pure-render/component';
import PropTypes from 'prop-types';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import TableHeaderItem from 'portaltransparencia-common/components/TableHeaderItem.react';
import TableActions from 'portaltransparencia-common/components/TableActions.react';
import DateUtils from 'portaltransparencia-common/libs/DateUtils';

export default class ResponsavelTable extends Component {
  static propTypes = {
    page: PropTypes.object.isRequired,
    searchWithPage: PropTypes.func.isRequired,
    isIntegradoOxy: PropTypes.bool
  };

  renderResponsaveis = responsavel => {
    const key = `${responsavel.get('exercicio')}_${responsavel.get(
      'contrato'
    )}_${responsavel.get('sequencia')}`;

    return (
      <TableActions ref="row" key={key} data={responsavel}>
        <td id="tableOcupante">{responsavel.get('ocupante')}</td>
        <td id="tableDescricaoCargo">{responsavel.get('descricaoCargo')}</td>
        <td id="tableTipoAto">{responsavel.get('descricaoTipoAto')}</td>
        <td id="tableNumeroContrato">{responsavel.get('displayContrato')}</td>
        <td id="tableDataInicio">
          {DateUtils.transform(responsavel.get('dataInicio'))}
        </td>
        <td id="tableDataTermino">
          {DateUtils.transform(responsavel.get('dataTermino'))}
        </td>
        <td id="tableVigencia">
          {DateUtils.transform(responsavel.get('vigencia'))}
        </td>
        <td id="tableSituacao">{responsavel.get('situacao')}</td>
      </TableActions>
    );
  };

  render() {
    const { page, searchWithPage, isIntegradoOxy } = this.props;
    let responsaveis = page.get('content');

    return (
      <div className="panel-table-outer">
        <table className="panel-table striped fancy">
          <TableHeader
            empty={responsaveis.isEmpty()}
            text="Não foi encontrado nenhum responsável para a pesquisa selecionada."
          >
            <tr>
              <TableHeaderItem
                field={isIntegradoOxy ? 'p.nome' : 'ocupante'}
                label="Ocupante"
                page={page}
                searchWithPage={searchWithPage}
              />
              <TableHeaderItem
                field="descricaoCargo"
                label="Cargo"
                page={page}
                searchWithPage={searchWithPage}
              />
              <TableHeaderItem
                field={isIntegradoOxy ? 'c.tipoAto' : 'tipoAto'}
                label="Tipo Ato"
                page={page}
                searchWithPage={searchWithPage}
              />
              <TableHeaderItem
                field={isIntegradoOxy ? 'c.numeroContrato' : 'numeroContrato'}
                label="Nr. Contrato"
                page={page}
                searchWithPage={searchWithPage}
              />
              <TableHeaderItem
                field={
                  isIntegradoOxy ? 'vigencia.inicioVigencia' : 'dataInicio'
                }
                label="Dt. Início"
                page={page}
                searchWithPage={searchWithPage}
              />
              <TableHeaderItem
                field={
                  isIntegradoOxy ? 'vigencia.terminoVigencia' : 'dataTermino'
                }
                label="Dt. Término"
                page={page}
                searchWithPage={searchWithPage}
              />
              <TableHeaderItem
                field={
                  isIntegradoOxy ? 'c.periodo.terminoVigencia' : 'vigencia'
                }
                label="Dt. Final Vigência"
                page={page}
                searchWithPage={searchWithPage}
              />
              <TableHeaderItem
                field={isIntegradoOxy ? 'c.situacao' : 'situacao'}
                label="Situação"
                page={page}
                searchWithPage={searchWithPage}
              />
            </tr>
          </TableHeader>

          <tbody>{responsaveis.map(this.renderResponsaveis)}</tbody>
        </table>
      </div>
    );
  }
}
