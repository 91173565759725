export const PAGAR_LIST = 'PAGAR_LIST';
export const PAGAR_LIST_START = 'PAGAR_LIST_START';
export const PAGAR_LIST_SUCCESS = 'PAGAR_LIST_SUCCESS';
export const PAGAR_LIST_ERROR = 'PAGAR_LIST_ERROR';

export const TOTAL_PAGAR = 'TOTAL_PAGAR';
export const TOTAL_PAGAR_START = 'TOTAL_PAGAR_START';
export const TOTAL_PAGAR_SUCCESS = 'TOTAL_PAGAR_SUCCESS';
export const TOTAL_PAGAR_ERROR = 'TOTAL_PAGAR_ERROR';

export const PAGAS_LIST = 'PAGAS_LIST';
export const PAGAS_LIST_START = 'PAGAS_LIST_START';
export const PAGAS_LIST_SUCCESS = 'PAGAS_LIST_SUCCESS';
export const PAGAS_LIST_ERROR = 'PAGAS_LIST_ERROR';

export const TOTAL_PAGAS = 'TOTAL_PAGAS';
export const TOTAL_PAGAS_START = 'TOTAL_PAGAS_START';
export const TOTAL_PAGAS_SUCCESS = 'TOTAL_PAGAS_SUCCESS';
export const TOTAL_PAGAS_ERROR = 'TOTAL_PAGAS_ERROR';

export const RESET_CONSULTA_APTOPAGAMENTO = 'RESET_CONSULTA_APTOPAGAMENTO';

export const PROGRAMATICAFONTERECURSO = 'PROGRAMATICAFONTERECURSO';
export const PROGRAMATICAFONTERECURSO_START = 'PROGRAMATICAFONTERECURSO_START';
export const PROGRAMATICAFONTERECURSO_SUCCESS =
  'PROGRAMATICAFONTERECURSO_SUCCESS';
export const PROGRAMATICAFONTERECURSO_ERROR = 'PROGRAMATICAFONTERECURSO_ERROR';
