import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react.js';
import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export default class FornecedorRow extends Component {
  static propTypes = {
    fornecedor: PropTypes.object.isRequired,
    getAPagar: PropTypes.bool,
    getComprasDireta: PropTypes.bool
  };

  static defaultProps = {
    getAPagar: true,
    getComprasDireta: false
  };

  retiraMascaraCNPJ = CNPJ => {
    if (CNPJ != undefined) {
      return CNPJ.replace('.', '')
        .replace('.', '')
        .replace('-', '')
        .replace('/', '');
    }
  };

  onViewEmpenhos = item => {
    const { entidade } = StorageService.getItem('userInfo');
    const nome = item.get('nome').replace('&', '%26');
    const cnpjCpf = this.retiraMascaraCNPJ(item.get('cnpjCpf'));
    const aPagar = this.props.getAPagar;
    const { exercicio } = StorageService.getItem('userInfo');
    const comprasDireta = this.props.getComprasDireta;
    let filtro = `?search=id.entidade==${
      entidade.id
    } and fornecedor.nome=="*${nome}*" and fornecedor.cnpj==${cnpjCpf}&aPagar=${aPagar}&exercicio=${
      aPagar ? '' : exercicio.id
    }&comprasDireta=${comprasDireta}`;

    this.props.history.push('empenhos/lista' + filtro);
  };

  render = () => {
    const { fornecedor } = this.props;
    return (
      <TableActions
        ref="row"
        key={fornecedor.get('fornecedor')}
        data={fornecedor}
        onMore={this.onViewEmpenhos}
      >
        <td>{fornecedor.get('nome')}</td>
        <td>{fornecedor.get('cnpjCpf')}</td>
        <td className="right">
          <NumberFormatter value={fornecedor.get('empenhado')} />
        </td>
        <td className="right">
          <NumberFormatter value={fornecedor.get('anulado')} />
        </td>
        <td className="right">
          <NumberFormatter value={fornecedor.get('liquidado')} />
        </td>
        <td className="right">
          <NumberFormatter value={fornecedor.get('retido')} />
        </td>
        <td className="right">
          <NumberFormatter value={fornecedor.get('pago')} />
        </td>
        <td className="right">
          <NumberFormatter value={fornecedor.get('aPagar')} />
        </td>
      </TableActions>
    );
  };
}
