import { Map } from 'immutable';
import AutoComplete from 'portaltransparencia-common/components/AutoComplete.react.js';
import Input from 'portaltransparencia-common/components/EloInput.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import {
  onChange,
  resetCrud
} from 'portaltransparencia-common/crud/Actions.js';
import { format } from 'portaltransparencia-common/libs/NumberUtils';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import { getValueFromUrlSearchParams } from 'portaltransparencia-common/libs/URLUtils';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import FilterButtons from '../components/FilterButtons.react.js';
import PageHeader from '../components/PageHeader.react.js';
import NotasExplicativas from '../notaexplicativa/Form.react';
import * as actions from './Actions.js';

export class ArrecadacaoPorBairros extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    filtros: PropTypes.object.isRequired,
    arrecadacoesPorBairros: PropTypes.object.isRequired,
    findReceitaArrecadacaoPorBairros: PropTypes.func.isRequired,
    resetArrecadacoesPorBairro: PropTypes.func.isRequired,
    findBairros: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    resetCrud: PropTypes.func.isRequired
  };

  renderArrecadacoes = arrecadacao => {
    const valorArrecadar =
      arrecadacao.get('valorLancado') -
      arrecadacao.get('valorPago') -
      arrecadacao.get('valorRenuncia');
    const porcentagemInadimplencia =
      arrecadacao.get('valorLancado') == 0
        ? '-'
        : (valorArrecadar / arrecadacao.get('valorLancado')) * 100;

    return (
      <tr
        ref="row"
        key={`${arrecadacao.get('logradouro')}_${arrecadacao.get(
          'bairro'
        )}_${arrecadacao.get('descricao')}`}
        data={arrecadacao}
      >
        <td>{arrecadacao.get('bairro')}</td>
        <td>{arrecadacao.get('logradouro')}</td>
        <td>{arrecadacao.get('descricao')}</td>
        <td className="right">{format(arrecadacao.get('valorLancado'))}</td>
        <td className="right">{format(arrecadacao.get('valorPago'))}</td>
        <td className="right">{format(arrecadacao.get('valorRenuncia'))}</td>
        <td className="right">{format(valorArrecadar)}</td>
        <td className="right">{format(porcentagemInadimplencia)}%</td>
      </tr>
    );
  };

  onChangeFiltro = event => {
    this.props.onChange('filtroArrecadacaoBairro', event);
  };

  onConsulta = () => {
    const { findReceitaArrecadacaoPorBairros, location, filtros } = this.props;
    const { entidade, exercicio } = StorageService.getItem('userInfo');
    const receita = getValueFromUrlSearchParams(location, 'receita');

    if (filtros.getIn(['bairro', 'descricao']) && receita) {
      const logradouro = filtros.get('logradouro')
        ? `&logradouro=${filtros.get('logradouro')}`
        : '';
      findReceitaArrecadacaoPorBairros(
        `entidade=${entidade.id}&exercicio=${
          exercicio.id
        }&bairro=${filtros.getIn([
          'bairro',
          'descricao'
        ])}&receita=${receita}${logradouro}`
      );
    }
  };

  onReset = () => {
    const { resetCrud, resetArrecadacoesPorBairro } = this.props;
    resetCrud('filtroArrecadacaoBairro');
    resetArrecadacoesPorBairro();
  };

  loadBairros = param => {
    const { findBairros } = this.props;
    const { entidade } = StorageService.getItem('userInfo');

    return findBairros(entidade.id, param.value).then(response => {
      return {
        value: response.value.map(item => ({ descricao: item }))
      };
    });
  };

  renderValue = option => <span>{option.descricao}</span>;

  render() {
    const { arrecadacoesPorBairros, filtros, location } = this.props;
    const receita = getValueFromUrlSearchParams(location, 'descricao') || '';

    return (
      <section>
        <PageHeader
          location={this.props.location}
          textoDetalhe={`Arrecadações de ${receita} por bairros`}
        />

        <NotasExplicativas pathname={this.props.location.pathname} />

        <form>
          <div className="panel-inside-panel mt-xs">
            <Panel
              title={`Consultar nas arrecadações de ${receita}`}
              isForm
              tableResponsive
            >
              <Row>
                <Col xs={12} sm={4} md={4}>
                  <AutoComplete
                    ref="bairro"
                    label="Bairro"
                    id="bairro"
                    name="bairro"
                    onLoad={this.loadBairros}
                    onChange={this.onChangeFiltro}
                    value={filtros.get('bairro')}
                    placeholder="Selecione o bairro"
                    labelKey="descricao"
                    valueKey="descricao"
                    clearable
                    optionRenderer={this.renderValue}
                  />
                </Col>
                <Col xs={12} sm={5} md={5}>
                  <Input
                    id="logradouro"
                    name="logradouro"
                    ref="logradouro"
                    placeholder="logradouro"
                    label="logradouro"
                    type="text"
                    onChange={this.onChangeFiltro}
                    value={filtros.get('logradouro')}
                  />
                </Col>
                <Col xs={12} sm={3} md={3}>
                  <FilterButtons
                    onConsulta={this.onConsulta}
                    onReset={this.onReset}
                  />
                </Col>
              </Row>
            </Panel>
          </div>
        </form>

        <Panel isForm expansible={false} tableResponsive>
          <div className="panel-table-outer">
            <table className="panel-table striped fancy">
              <TableHeader empty={arrecadacoesPorBairros.isEmpty()}>
                <tr>
                  <th>Bairro</th>
                  <th>Logradouro</th>
                  <th>Receita</th>
                  <th className="right">( + ) Valor Lançamento</th>
                  <th className="right">( - ) Valor Pago</th>
                  <th className="right">( - ) Valor Isenção</th>
                  <th className="right">( = ) Valor a Arrecadar</th>
                  <th className="right">Inadimplência</th>
                </tr>
              </TableHeader>
              <tbody>
                {arrecadacoesPorBairros.map(this.renderArrecadacoes)}
              </tbody>
            </table>
          </div>
        </Panel>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    filtros: state.crud.getIn(
      ['filtroArrecadacaoBairro', 'currentRecord'],
      Map({})
    ),
    arrecadacoesPorBairros: state.arrecadacao.get('arrecadacoesPorBairros')
  };
}

const allActions = {
  ...actions,
  onChange,
  resetCrud
};

export default connect(mapStateToProps, allActions)(ArrecadacaoPorBairros);
