import Component from 'react-pure-render/component';
import PropTypes from 'prop-types';
import React from 'react';
import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react.js';
import { downloadArquivoBlob } from '../arquivo/ArquivoUtils';

export class ObraOrdemServicoArquivo extends Component {
  static propTypes = {
    ordemArquivos: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
  }

  renderOrdemArquivos = data => {
    const key = `${data.get('descricao')}_${data.get('arquivoNome')}_${data.get(
      'tipo'
    )}`;

    return (
      <TableActions
        ref="row"
        key={key}
        data={data}
        onDownload={this.openArquivoOrdem}
      >
        <td>{data.get('tipo')}</td>
        <td>{data.get('descricao')}</td>
        <td>{data.get('arquivoNome')}</td>
      </TableActions>
    );
  };

  openArquivoOrdem = data => {
    downloadArquivoBlob(data.get('arquivo'), data.get('arquivoNome'));
  };

  render() {
    const { ordemArquivos } = this.props;

    return (
      <section>
        <div className="panel-table-outer">
          <table className="panel-table striped fancy">
            <TableHeader empty={ordemArquivos.isEmpty()}>
              <tr>
                <th>Tipo</th>
                <th>Descrição</th>
                <th>Nome Arquivo</th>
                <th style={{ textAlign: 'right', width: '100px' }}>Arquivos</th>
              </tr>
            </TableHeader>
            <tbody>{ordemArquivos.map(this.renderOrdemArquivos)}</tbody>
          </table>
        </div>
      </section>
    );
  }
}

export default ObraOrdemServicoArquivo;
