export const CONTRATOS_LICITACAO = 'CONTRATOS_LICITACAO';
export const CONTRATOS_LICITACAO_START = 'CONTRATOS_LICITACAO_START';
export const CONTRATOS_LICITACAO_SUCCESS = 'CONTRATOS_LICITACAO_SUCCESS';
export const CONTRATOS_LICITACAO_ERROR = 'CONTRATOS_LICITACAO_ERROR';

export const ADITIVOS = 'ADITIVOS';
export const ADITIVOS_START = 'ADITIVOS_START';
export const ADITIVOS_SUCCESS = 'ADITIVOS_SUCCESS';
export const ADITIVOS_ERROR = 'ADITIVOS_ERROR';

export const CONTRATO = 'CONTRATO';
export const CONTRATO_START = 'CONTRATO_START';
export const CONTRATO_SUCCESS = 'CONTRATO_SUCCESS';
export const CONTRATO_ERROR = 'CONTRATO_ERROR';

export const CONSULTA_CONTRATOS = 'CONSULTA_CONTRATOS';
export const CONSULTA_CONTRATOS_START = 'CONSULTA_CONTRATOS_START';
export const CONSULTA_CONTRATOS_SUCCESS = 'CONSULTA_CONTRATOS_SUCCESS';
export const CONSULTA_CONTRATOS_ERROR = 'CONSULTA_CONTRATOS_ERROR';

export const RESET_CONSULTA_CONTRATOS = 'RESET_CONSULTA_CONTRATOS';

export const TIPOS_CONTRATO = 'TIPOS_CONTRATO';
export const TIPOS_CONTRATO_START = 'TIPOS_CONTRATO_START';
export const TIPOS_CONTRATO_SUCCESS = 'TIPOS_CONTRATO_SUCCESS';
export const TIPOS_CONTRATO_ERROR = 'TIPOS_CONTRATO_ERROR';

export const CONTRATO_ARQUIVOS = 'CONTRATO_ARQUIVOS';
export const CONTRATO_ARQUIVOS_START = 'CONTRATO_ARQUIVOS_START';
export const CONTRATO_ARQUIVOS_SUCCESS = 'CONTRATO_ARQUIVOS_SUCCESS';
export const CONTRATO_ARQUIVOS_ERROR = 'CONTRATO_ARQUIVOS_ERROR';

export const RESPONSAVEIS = 'RESPONSAVEIS';
export const RESPONSAVEIS_START = 'RESPONSAVEIS_START';
export const RESPONSAVEIS_SUCCESS = 'RESPONSAVEIS_SUCCESS';
export const RESPONSAVEIS_ERROR = 'RESPONSAVEIS_ERROR';

export const LICITACAO = 'LICITACAO';
export const LICITACAO_START = 'LICITACAO_START';
export const LICITACAO_SUCCESS = 'LICITACAO_SUCCESS';
export const LICITACAO_ERROR = 'LICITACAO_ERROR';
