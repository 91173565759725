import Component from 'react-pure-render/component';
import DateUtils from 'portaltransparencia-common/libs/DateUtils';
import PropTypes from 'prop-types';
import React from 'react';
import SearchPagination from 'portaltransparencia-common/components/SearchPagination.react.js';
import TableActions from 'portaltransparencia-common/components/TableActions.react';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import TableHeaderItem from 'portaltransparencia-common/components/TableHeaderItem.react';
import { getPrazoByTipoGrauSigilo } from './TipoGrauSigiloEnum';

export default class ProcessoTable extends Component {
  static propTypes = {
    page: PropTypes.object.isRequired,
    searchWithPage: PropTypes.func.isRequired
  };

  renderProcessos = processo => {
    const key = `${processo.get('exercicio')}_${processo.get(
      'processo'
    )}_${processo.get('tipo')}`;

    return (
      <TableActions ref="row" key={key} data={processo}>
        <td id="tableProcesso">
          {processo.get('processo')}/{processo.get('exercicio')}
        </td>
        <td id="tableAssunto">{processo.get('assunto')}</td>
        <td id="tableGrauSigilo">{processo.get('grauSigilo')}</td>
        <td id="tableGrauSigiloEnum" className="center">
          {getPrazoByTipoGrauSigilo(processo.get('grauSigilo'))}
        </td>
        <td id="tableDataAbertura">{processo.get('data')}</td>
        <td id="tableDataDesclassificacao">
          {DateUtils.transform(processo.get('prazoDesclassificacao'))}
        </td>
        <td id="tableFundamentacaoLegal">
          {processo.get('fundamentacaoLegal')}
        </td>
      </TableActions>
    );
  };

  render() {
    const { page, searchWithPage } = this.props;
    let processos = page.get('content');

    return (
      <>
        <div className="panel-table-outer">
          <table className="panel-table striped fancy">
            <TableHeader
              empty={processos.isEmpty()}
              text="Não foi encontrado nenhum processo para a pesquisa selecionada."
            >
              <tr>
                <TableHeaderItem
                  field={'processo'}
                  label="Processo"
                  page={page}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field={'assunto'}
                  label="Assunto"
                  page={page}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field={'grauSigilo'}
                  label="Grau Sigilo"
                  page={page}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field={'grauSigiloEnum'}
                  label="Prazo Determinado (Anos)"
                  page={page}
                  searchWithPage={searchWithPage}
                  sortable={false}
                />
                <TableHeaderItem
                  field={'data'}
                  label="Data Abertura"
                  page={page}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field={'prazoDesclassificacao'}
                  label="Data Desclassificação"
                  page={page}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field={'fundamentacaoLegal'}
                  label="Fundamentação Legal"
                  page={page}
                  searchWithPage={searchWithPage}
                />
              </tr>
            </TableHeader>
            <tbody>{processos.map(this.renderProcessos)}</tbody>
          </table>
        </div>
        <SearchPagination page={page} searchWithPage={searchWithPage} />
      </>
    );
  }
}
