import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react.js';
import SumListField from 'portaltransparencia-common/components/SumListField.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import * as actions from './Actions.js';

export class EmpenhoDetalheRetencoes extends Component {
  static propTypes = {
    retencoes: PropTypes.object.isRequired
  };

  renderItens = data => {
    let noLiquidacao =
      data.get('noLiquidacao') + '/' + data.get('exercicioLiquidacao');
    let key = noLiquidacao + '_' + data.get('sequencia');

    return (
      <tr key={key} data={data}>
        <td id="noLiquidacao">{noLiquidacao}</td>
        <td id="descricao">{data.get('descricao')}</td>
        <td id="data">{DateUtils.formatDateTimeShort(data.get('data'))}</td>
        <td id="valor" className="right">
          <NumberFormatter value={data.get('valor')} />
        </td>
        <td id="valorEstornado" className="right">
          <NumberFormatter value={data.get('valorEstornado')} />
        </td>
      </tr>
    );
  };

  render() {
    const { retencoes } = this.props;

    return (
      <div className="panel-table-outer">
        <table className="panel-table striped fancy">
          <TableHeader empty={retencoes.isEmpty()}>
            <tr>
              <th>Liquidação</th>
              <th>Descrição</th>
              <th>Data</th>
              <th className="right">Valor</th>
              <th className="right">Valor Estornado</th>
            </tr>
          </TableHeader>
          <tbody>{retencoes.map(this.renderItens)}</tbody>

          {!retencoes.isEmpty() && (
            <tfoot>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td className="right">
                  <i>Total:</i>{' '}
                  {<SumListField fieldName="valor" list={retencoes} />}
                </td>
                <td className="right">
                  <i>Total:</i>{' '}
                  {<SumListField fieldName="valorEstornado" list={retencoes} />}
                </td>
              </tr>
            </tfoot>
          )}
        </table>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    retencoes: state.empenhos.get('retencoes')
  };
}

const allActions = {
  ...actions
};

export default connect(mapStateToProps, allActions)(EmpenhoDetalheRetencoes);
