import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react.js';
import SumListField from 'portaltransparencia-common/components/SumListField.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import * as actions from './Actions.js';

export class EmpenhoDetalheAnulacoes extends Component {
  static propTypes = {
    anulacoes: PropTypes.object.isRequired
  };

  renderItens = data => {
    return (
      <tr key={data.get('sequencia')} data={data}>
        <td id="sequencia">{data.get('sequencia')}</td>
        <td id="data">{DateUtils.formatDateTimeShort(data.get('data'))}</td>
        <td id="valor" className="right">
          <NumberFormatter value={data.get('valor')} />
        </td>
        <td id="motivo">{data.get('motivo')}</td>
      </tr>
    );
  };

  render() {
    const { anulacoes } = this.props;

    return (
      <div className="panel-table-outer">
        <table className="panel-table striped fancy">
          <TableHeader empty={anulacoes.isEmpty()} text="Não possui anulações.">
            <tr>
              <th>Sequencia</th>
              <th>Data</th>
              <th className="right">Valor</th>
              <th>Motivo</th>
            </tr>
          </TableHeader>
          <tbody>{anulacoes.map(this.renderItens)}</tbody>
          {!anulacoes.isEmpty() && (
            <tfoot>
              <tr ref="row">
                <td></td>
                <td></td>
                <td className="right">
                  <i>Total: </i>
                  <SumListField fieldName="valor" list={anulacoes} />
                </td>
                <td></td>
              </tr>
            </tfoot>
          )}
        </table>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    anulacoes: state.empenhos.get('anulacoes')
  };
}

const allActions = {
  ...actions
};

export default connect(mapStateToProps, allActions)(EmpenhoDetalheAnulacoes);
