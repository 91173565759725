import Panel from 'portaltransparencia-common/components/Panel.react.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import ResultadoPesquisa from '../pesquisa/ResultadoPesquisa.react.js';

export class MapaSite extends Component {
  static propTypes = {
    menu: PropTypes.object.isRequired
  };

  render() {
    const { menu } = this.props;

    return (
      <section>
        <h1 className="text-title center mb-xs">Mapa do Site</h1>

        <Panel expansible={false}>
          <ResultadoPesquisa list={menu} valorPesquisado="" />
        </Panel>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    menu: state.menu.get('menu')
  };
}

export default connect(mapStateToProps)(MapaSite);
