import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';

import * as types from './Types.js';

const initialState = fromJS({
  contratos: [],
  contratosLicitacao: [],
  aditivos: [],
  contrato: {},
  tiposContrato: [],
  arquivos: [],
  responsaveis: [],
  licitacao: {}
});

function contratoReducer(state = initialState, action) {
  switch (action.type) {
    case types.CONTRATO_ARQUIVOS_START:
    case types.CONTRATOS_LICITACAO_START:
    case types.ADITIVOS_START:
    case types.CONTRATO_START:
    case types.CONSULTA_CONTRATOS_START:
    case types.TIPOS_CONTRATO_START:
    case types.RESPONSAVEIS_START:
    case types.LICITACAO_START: {
      return startLoad(state);
    }

    case types.CONTRATO_ARQUIVOS_ERROR:
    case types.CONTRATOS_LICITACAO_ERROR:
    case types.ADITIVOS_ERROR:
    case types.CONTRATO_ERROR:
    case types.CONSULTA_CONTRATOS_ERROR:
    case types.TIPOS_CONTRATO_ERROR:
    case types.RESPONSAVEIS_ERROR:
    case types.LICITACAO_ERROR: {
      return endLoad(state);
    }

    case types.CONSULTA_CONTRATOS_SUCCESS: {
      return endLoad(state.set('contratos', fromJS(action.payload)));
    }
    case types.CONTRATOS_LICITACAO_SUCCESS: {
      return endLoad(state.set('contratosLicitacao', fromJS(action.payload)));
    }
    case types.ADITIVOS_SUCCESS: {
      return endLoad(state.set('aditivos', fromJS(action.payload)));
    }
    case types.CONTRATO_SUCCESS: {
      return endLoad(state.set('contrato', fromJS(action.payload)));
    }
    case types.TIPOS_CONTRATO_SUCCESS: {
      return endLoad(state.set('tiposContrato', fromJS(action.payload)));
    }
    case types.CONTRATO_ARQUIVOS_SUCCESS: {
      return endLoad(state.set('arquivos', fromJS(action.payload)));
    }
    case types.RESPONSAVEIS_SUCCESS: {
      return endLoad(state.set('responsaveis', fromJS(action.payload)));
    }
    case types.LICITACAO_SUCCESS: {
      return endLoad(state.set('licitacao', fromJS(action.payload)));
    }

    //RESET
    case types.RESET_CONSULTA_CONTRATOS: {
      return state.clear().merge(initialState);
    }
  }

  return state;
}

export default contratoReducer;
