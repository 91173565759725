import Component from 'react-pure-render/component';
import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

export default class TableHeaderItem extends Component {
  static propTypes = {
    page: PropTypes.object,
    field: PropTypes.string,
    label: PropTypes.string.isRequired,
    searchWithPage: PropTypes.func,
    alignment: PropTypes.string,
    sortable: PropTypes.bool
  };

  constructor(props) {
    super(props);

    this.state = {
      field: '',
      order: ''
    };
  }

  setOrder = () => {
    const { field, page, searchWithPage } = this.props;
    let order = 'asc';

    if (this.state.order && this.state.order === 'asc') {
      order = 'desc';
    }

    this.setState(
      {
        field: field,
        order: order
      },
      () => {
        const pageOrder = page
          .set('sort', `${this.state.field},${this.state.order}`)
          .set('number', 0);

        searchWithPage(pageOrder);
      }
    );
  };

  render() {
    const { page, alignment, sortable = true } = this.props;

    const sortField = (page && page.getIn(['sort', 0, 'property'])) || '';

    let ordered = false;
    if (sortField === this.state.field) {
      ordered = true;
    }

    const classes = {
      sortable,
      asc: ordered ? this.state.order === 'asc' : '',
      desc: ordered ? this.state.order === 'desc' : '',
      right: alignment ? alignment === 'right' : '',
      left: alignment ? alignment === 'left' : ''
    };

    const { label } = this.props;
    return (
      <th
        className={classnames(classes)}
        onClick={sortable ? this.setOrder : null}
      >
        {label}
      </th>
    );
  }
}
