import NumberUtils from 'portaltransparencia-common/libs/NumberUtils.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import * as actions from './Actions.js';

export class FormTotalAcesso extends Component {
  static propTypes = {
    totalacesso: PropTypes.object.isRequired,
    totalDiarioOficial: PropTypes.object.isRequired,
    findTotalAcesso: PropTypes.func.isRequired,
    findTotalAcessoDiarioOficial: PropTypes.func.isRequired,
    tipo: PropTypes.string
  };

  componentDidMount() {
    const {
      totalacesso,
      findTotalAcesso,
      findTotalAcessoDiarioOficial,
      tipo
    } = this.props;

    if (tipo == 'TOTAL_DIARIO_OFICIAL') {
      findTotalAcessoDiarioOficial();
    } else if (!totalacesso.has('totalAcessos')) {
      findTotalAcesso();
    }
  }

  render() {
    const { totalacesso, tipo, totalDiarioOficial } = this.props;

    return (
      <div>
        {(() => {
          if (tipo == 'TOTAL_DIARIO') {
            return (
              <>
                <i className="fa fa-user"></i>
                <label>
                  Nº de acessos:{' '}
                  {NumberUtils.formatMilhar(totalacesso.get('totalAcessos'))}
                </label>
              </>
            );
          } else if (tipo == 'TOTAL_DIARIO_OFICIAL') {
            return (
              <>
                <i className="fa fa-user" style={{ paddingRight: '5px' }}></i>
                <label>
                  Nº de acessos:{' '}
                  {NumberUtils.formatMilhar(
                    totalDiarioOficial.get('totalDiarioOficial')
                  )}
                </label>
              </>
            );
          }
        })()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    totalacesso: state.totalacesso.get('totalacesso'),
    totalDiarioOficial: state.totalacesso.get('totalDiarioOficial')
  };
}

const allActions = {
  ...actions
};

export default connect(mapStateToProps, allActions)(FormTotalAcesso);
