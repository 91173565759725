import * as types from './Types.js';

import Fields from 'portaltransparencia-common/libs/Fields.js';
import { somenteNumeros } from 'portaltransparencia-common/libs/StringUtils';

export function findSolicitacaoEsic(entidade, ano, numero, senha, tipo) {
  return ({ fetch }) => ({
    type: types.FIND_ESIC,
    payload: {
      promise: fetch(
        `/api/processos?entidade=${entidade}&exercicio=${ano}&numero=${numero}&tipo=${tipo}&senha=${senha}`
      )
    }
  });
}

export function findPessoa(cnpjCpf) {
  return ({ fetch }) => ({
    type: types.FIND_PESSOA,
    payload: {
      promise: fetch(`/api/pessoa?cnpjCpf=${cnpjCpf}`)
    }
  });
}

export function saveProcesso(dados) {
  let processo = null;
  const origin = new URL(window.location).origin;
  if (dados.get('tipoSolicitacao') == 'I') {
    processo = processoIdentificado(dados);
  } else {
    processo = processoAnonimo(dados);
  }
  processo['url'] = origin;
  return ({ post }) => ({
    type: types.SOLICITACAO_ESIC,
    payload: {
      promise: post('/api/processos', processo)
        .then(processo => {
          const portal = process.env.PUBLIC_URL
            ? `${process.env.PUBLIC_URL}/`
            : '/';
          const url = `${origin}${portal}esic?entidade=${processo.entidade}&exercicio=${processo.exercicio}&numero=${processo.processo}&senha=${processo.senha}&tipo=${processo.tipo}`;
          return url;
        })
        .then(url => window.location.replace(url))
    }
  });
}

function processoIdentificado(dados) {
  const pessoa = {
    nome: dados.get('nome'),
    rg: dados.get('rg'),
    cnpjCpf: somenteNumeros(dados.get('cnpj_cpf')),
    tipoPessoa: somenteNumeros(dados.get('cnpj_cpf')).length == 14 ? 'J' : 'F',
    contato: {
      telefone: dados.get('telefone'),
      celular: dados.get('celular'),
      email: dados.get('email')
    }
  };
  const endereco = {
    numero: dados.get('numero'),
    complemento: dados.get('complemento'),
    cep: dados.get('cep'),
    cidadeAux: dados.get('cidade'),
    bairroAux: dados.get('bairro'),
    endereco: dados.get('logradouro'),
    uf: { id: dados.get('uf') }
  };
  const processo = {
    pessoa,
    endereco,
    sexo: dados.get('sexo'),
    escolaridade: dados.get('escolaridade'),
    dataNascimento: dados.get('dataNascimento'),
    assunto: dados.get('assunto'),
    assuntoOxy: dados.get('assuntoOxy'),
    formaRecebimento: dados.get('formaRecebimento'),
    digitacao:
      dados.get('protocoloRecurso') === '' ||
      dados.get('protocoloRecurso') === undefined
        ? `${dados.get('digitacao')}`
        : `${dados.get('digitacao')} - "${dados.get('protocoloRecurso')}"`,
    processoReferencia: dados.get('processoReferencia')
  };

  return processo;
}

function processoAnonimo(dados) {
  let processo = {
    assunto: dados.get('assunto'),
    assuntoOxy: dados.get('assuntoOxy'),
    formaRecebimento: dados.get('formaRecebimento'),
    digitacao: `${dados.get('digitacao')}${
      dados.get('protocoloRecurso')
        ? ' - Recurso do Protocolo ' + dados.get('protocoloRecurso')
        : ''
    }`
  };
  return processo;
}

export function onChange(resource, event) {
  const field = Fields.getName(event);
  const value = Fields.getValue(event);

  return {
    type: types.CHANGE_FIELD_ESIC_VALUE,
    payload: {
      field: field,
      value: value
    },
    meta: {
      path: resource
    }
  };
}

export function findEstatisticas(entidade) {
  return ({ fetch }) => ({
    type: types.ESTATISTICAS,
    payload: {
      promise: fetch(`/api/estatisticas?entidade=${entidade}`)
    }
  });
}

export function findEstatisticasAno(entidade, exercicio) {
  return ({ fetch }) => ({
    type: types.ESTATISTICAS_ANO,
    payload: {
      promise: fetch(
        `/api/estatisticas?exercicio=${exercicio}&entidade=${entidade}`
      )
    }
  });
}

export function loadMediaRespostasAno(entidade, exercicio) {
  return ({ fetch }) => ({
    type: types.MEDIA_RESPOSTAS_ANO,
    payload: {
      promise: fetch(
        `/api/estatisticas/media?exercicio=${exercicio}&entidade=${entidade}`
      )
    }
  });
}

export function findProcessosPorGenero(entidade, exercicio) {
  return ({ fetch }) => ({
    type: types.ESTATISTICAS_PROCESSO_POR_GENERO,
    payload: {
      promise: fetch(
        `/api/estatisticas/processos-por-genero?exercicio=${exercicio}&entidade=${entidade}`
      )
    }
  });
}

export function findProcessosPorIdade(entidade, exercicio) {
  return ({ fetch }) => ({
    type: types.ESTATISTICAS_PROCESSO_POR_IDADE,
    payload: {
      promise: fetch(
        `/api/estatisticas/processos-por-idade?exercicio=${exercicio}&entidade=${entidade}`
      )
    }
  });
}

export function findProcessosPorEscolaridade(entidade, exercicio) {
  return ({ fetch }) => ({
    type: types.ESTATISTICAS_PROCESSO_POR_ESCOLARIDADE,
    payload: {
      promise: fetch(
        `/api/estatisticas/processos-por-escolaridade?exercicio=${exercicio}&entidade=${entidade}`
      )
    }
  });
}

export function findRelatorioAnual(entidade, exercicio) {
  return ({ fetch }) => ({
    type: types.RELATORIO_ANUAL_ESTATISTICA,
    payload: {
      promise: fetch(
        `/api/estatisticas/relatorio-anual?exercicio=${exercicio}&entidade=${entidade}`
      )
    }
  });
}

export function loadMediaRespostas(entidade) {
  return ({ fetch }) => ({
    type: types.MEDIA_RESPOSTAS,
    payload: {
      promise: fetch(`/api/estatisticas/media?entidade=${entidade}`)
    }
  });
}

export function reset() {
  return {
    type: types.SOLICITACAO_ESIC_RESET
  };
}

export function abrirRecursoProcesso() {
  return {
    type: types.RECURSO_PROCESSO
  };
}

export function pularTipoSolicitacao() {
  return {
    type: types.PULAR_TIPO_SOLICITACAO
  };
}

export function loadEscolaridades() {
  return ({ fetch }) => ({
    type: types.ESIC_LOAD_ESCOLARIDADE,
    payload: {
      promise: fetch('/api/processos/escolaridades')
    }
  });
}

export function loadAssuntos() {
  return ({ fetch }) => ({
    type: types.FIND_ASSUNTO,
    payload: {
      promise: fetch('/api/processos/assuntos?search')
    }
  });
}

export function loadTiposProcesso(entidade, exercicio) {
  return ({ fetch }) => ({
    type: types.ESIC_LOAD_TIPOPROCESSO,
    payload: {
      promise: fetch(
        `/api/processos/tipos-processo?exercicio=${exercicio}&entidade=${entidade}`
      )
    }
  });
}
