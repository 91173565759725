import BotaoImprimir from 'portaltransparencia-common/components/BotaoImprimir.react.js';
import DisplayData from 'portaltransparencia-common/components/displaydata/DisplayData.react.js';
import DisplayDataItem from 'portaltransparencia-common/components/displaydata/DisplayDataItem.react.js';
import DisplayDataRow from 'portaltransparencia-common/components/displaydata/DisplayDataRow.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import NumberUtils from 'portaltransparencia-common/libs/NumberUtils.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

import CabecalhoEntidade from '../entidades/CabecalhoEntidade.react.js';

export class EstagiarioDetalheInformacaoOxy extends Component {
  static propTypes = {
    estagiario: PropTypes.object.isRequired,
    usaWebService: PropTypes.bool.isRequired,
    exibeSalarioBase: PropTypes.bool.isRequired
  };

  renderDadosDataESalario() {
    const { estagiario, usaWebService, exibeSalarioBase } = this.props;
    if (!exibeSalarioBase || usaWebService) {
      return (
        <DisplayDataRow>
          <DisplayDataItem
            sm={3}
            title="Data de Início de Contrato"
            value={DateUtils.formatDateTimeShort(
              estagiario.get('inicioContrato')
            )}
            id="inicioContrato"
            show={estagiario.has('inicioContrato')}
          />

          <DisplayDataItem
            sm={4}
            title="Desligamento"
            value={DateUtils.formatDateTimeShort(
              estagiario.get('dataDemissao')
            )}
            id="dataDemissao"
            show={estagiario.has('dataDemissao')}
          />
        </DisplayDataRow>
      );
    } else {
      return (
        <DisplayDataRow>
          <DisplayDataItem
            sm={4}
            title="Data de Início de Contrato"
            value={DateUtils.formatDateTimeShort(
              estagiario.get('inicioContrato')
            )}
            id="inicioContrato"
            show={estagiario.has('inicioContrato')}
          />

          <DisplayDataItem
            sm={4}
            title="Desligamento"
            value={DateUtils.formatDateTimeShort(
              estagiario.get('dataDemissao')
            )}
            id="dataDemissao"
            show={estagiario.has('dataDemissao')}
          />

          {exibeSalarioBase && (
            <DisplayDataItem
              sm={4}
              title="Bolsa-Estágio"
              value={NumberUtils.format(estagiario.get('salarioBase'))}
              id="salarioBase"
              show={estagiario.has('salarioBase')}
            />
          )}
        </DisplayDataRow>
      );
    }
  }

  renderPeriodo = (inicio, fim) =>
    DateUtils.formatTime(inicio) + ' às ' + DateUtils.formatTime(fim);

  renderPeriodoComIntervalo = (
    inicio,
    saidaIntervalo,
    retornoIntervalo,
    fim
  ) => {
    if (inicio && saidaIntervalo && retornoIntervalo && fim) {
      return (
        this.renderPeriodo(inicio, saidaIntervalo) +
        ' - ' +
        this.renderPeriodo(retornoIntervalo, fim)
      );
    }

    if (inicio && saidaIntervalo) {
      return this.renderPeriodo(inicio, saidaIntervalo);
    }

    if (retornoIntervalo && fim) {
      return this.renderPeriodo(retornoIntervalo, fim);
    }

    return inicio && fim ? this.renderPeriodo(inicio, fim) : null;
  };

  renderHorarioTrabalho() {
    const { estagiario } = this.props;

    if (!estagiario) {
      return;
    }

    const horarioTrabalhoFormatado = this.renderPeriodoComIntervalo(
      estagiario.get('horarioEntrada'),
      estagiario.get('horarioSaidaIntervalo'),
      estagiario.get('horarioEntradaIntervalo'),
      estagiario.get('horarioSaida')
    );

    return (
      <DisplayDataRow>
        <DisplayDataItem
          sm={12}
          title="Horário de Trabalho"
          value={horarioTrabalhoFormatado}
          id="horarioTrabalho"
          show={horarioTrabalhoFormatado}
        />
      </DisplayDataRow>
    );
  }

  render() {
    const { estagiario, usaWebService } = this.props;
    if (!estagiario) {
      return null;
    }

    return (
      <div>
        <CabecalhoEntidade />
        <BotaoImprimir />
        <Panel title="Informações Cadastrais" mobileClose expansible={false}>
          <DisplayData border>
            <DisplayDataRow>
              <DisplayDataItem
                sm={4}
                title="Nome"
                value={estagiario.get('nome')}
                id="nome"
              />
              <DisplayDataItem
                sm={4}
                title="Matrícula"
                value={estagiario.get('codigo')}
                id="codigo"
              />
              <DisplayDataItem
                sm={4}
                title="Situação"
                value={estagiario.get('situacao')}
                id="situacao"
              />
            </DisplayDataRow>

            <DisplayDataRow>
              <DisplayDataItem
                sm={4}
                title="Lotação"
                value={estagiario.get('lotacao')}
                id="lotacao"
              />

              <DisplayDataItem
                sm={4}
                title="Classe / Natureza"
                value={estagiario.get('descricaoClasse')}
                id="descricaoClasse"
                show={!usaWebService && estagiario.has('descricaoClasse')}
              />
            </DisplayDataRow>

            {this.renderHorarioTrabalho()}
            {this.renderDadosDataESalario()}

            <DisplayDataRow>
              <DisplayDataItem
                sm={4}
                title="Natureza do Estágio"
                value={estagiario.get('naturezaEstagio')}
                id="naturezaEstagio"
                show={estagiario.has('naturezaEstagio')}
              />

              <DisplayDataItem
                sm={4}
                title="Nível"
                value={estagiario.get('nivel')}
                id="nivel"
                show={estagiario.has('nivel')}
              />

              <DisplayDataItem
                sm={4}
                title="Área de Atuação"
                value={estagiario.get('areaAtuacao')}
                id="areaAtuacao"
                show={estagiario.has('areaAtuacao')}
              />
            </DisplayDataRow>

            <DisplayDataRow>
              <DisplayDataItem
                sm={6}
                title="Instituição de Ensino"
                value={estagiario.get('instituicaoEnsino')}
                id="instituicaoEnsino"
                show={estagiario.has('instituicaoEnsino')}
              />

              <DisplayDataItem
                sm={6}
                title="Agente de Integração"
                value={estagiario.get('agenteIntegracao')}
                id="agenteIntegracao"
                show={estagiario.has('agenteIntegracao')}
              />
            </DisplayDataRow>
          </DisplayData>
        </Panel>
      </div>
    );
  }
}

export default EstagiarioDetalheInformacaoOxy;
