import ExportButtonGroup from 'portaltransparencia-common/components/ExportButtonGroup.react.js';
import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react.js';
import SumListField from 'portaltransparencia-common/components/SumListField.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import TableHeaderItem from 'portaltransparencia-common/components/TableHeaderItem.react';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';

import LiquidacaoTable from './LiquidacaoTable.react.js';

export class LiquidacoesListagem extends Component {
  static propTypes = {
    page: PropTypes.object,
    total: PropTypes.object,
    filter: PropTypes.string,
    tipo: PropTypes.string.isRequired,
    mensagem: PropTypes.string,
    searchWithPage: PropTypes.func,
    sortedUrl: PropTypes.string,
    paramDespesasPagasValor: PropTypes.string,
    onExporta: PropTypes.func.isRequired
  };

  generateKey = liquidacao => {
    return `${liquidacao.get('entidade')}_
            ${liquidacao.get('exercicio')}_
            ${liquidacao.get('empenhoExercicio')}_
            ${liquidacao.get('empenho')}_
            ${liquidacao.get('data')}_
            ${liquidacao.get('noLiquidacao')}_
            ${liquidacao.get('valor')}`;
  };

  renderTableLiquidacoes = data => {
    const { tipo } = this.props;

    return (
      <LiquidacaoTable
        key={this.generateKey(data)}
        liquidacao={data}
        tipo={tipo}
        history={this.props.history}
        paramDespesasPagasValor={this.props.paramDespesasPagasValor}
      />
    );
  };

  render() {
    const {
      total,
      mensagem,
      page,
      searchWithPage,
      tipo,
      onExporta
    } = this.props;
    const { entidade } = StorageService.getItem('userInfo');

    if (!page.get('content')) {
      return null;
    }

    return (
      <section>
        <Row>
          <Col xs={6} />
          <Col xs={6}>
            <ExportButtonGroup onClick={onExporta} visible={true} />
          </Col>
        </Row>

        <div className="panel-table-outer">
          <table className="panel-table striped fancy">
            <TableHeader empty={page.get('content').isEmpty()} text={mensagem}>
              <tr>
                {entidade && entidade.id <= 0 && (
                  <TableHeaderItem
                    field="entidade"
                    label="Entidade"
                    page={page}
                    searchWithPage={searchWithPage}
                    alignment="left"
                  />
                )}
                {(tipo === 'aptos-pagamentos' ||
                  tipo === 'ordem-cronologica-pagamentos') && (
                  <TableHeaderItem
                    field="ordemFonteRecurso"
                    label="Ordem"
                    page={page}
                    searchWithPage={searchWithPage}
                    alignment="left"
                  />
                )}
                <TableHeaderItem
                  field="fonteRecurso"
                  label="Fonte Recurso"
                  page={page}
                  searchWithPage={searchWithPage}
                  alignment="left"
                />
                {tipo === 'pagas' && (
                  <TableHeaderItem
                    field="dataLiquidacao"
                    label="Data Liquidação"
                    page={page}
                    searchWithPage={searchWithPage}
                    alignment="left"
                  />
                )}
                {tipo === 'pagas' &&
                this.props.paramDespesasPagasValor !== 'S' ? (
                  <TableHeaderItem
                    field="data"
                    label="Data Pagamento"
                    page={page}
                    searchWithPage={searchWithPage}
                    alignment="left"
                  />
                ) : (
                  tipo !== 'ordem-cronologica-pagamentos' && (
                    <TableHeaderItem
                      field="data"
                      label="Data"
                      page={page}
                      searchWithPage={searchWithPage}
                      alignment="left"
                    />
                  )
                )}
                <TableHeaderItem
                  field="empenho"
                  label="Empenho/Ano"
                  page={page}
                  searchWithPage={searchWithPage}
                  alignment="left"
                />
                <TableHeaderItem
                  field="noLiquidacao"
                  label="Liquidação"
                  page={page}
                  searchWithPage={searchWithPage}
                  alignment="left"
                />
                <TableHeaderItem
                  field="fornecedor"
                  label="Fornecedor"
                  page={page}
                  searchWithPage={searchWithPage}
                  alignment="left"
                />
                {tipo !== 'ordem-cronologica-pagamentos' && (
                  <TableHeaderItem
                    field="tipoLicitacao"
                    label="Tipo Licitação"
                    page={page}
                    searchWithPage={searchWithPage}
                    alignment="left"
                  />
                )}
                {tipo !== 'ordem-cronologica-pagamentos' && (
                  <TableHeaderItem
                    field="licitacao"
                    label="Licitação"
                    page={page}
                    searchWithPage={searchWithPage}
                    alignment="left"
                  />
                )}
                {tipo !== 'ordem-cronologica-pagamentos' && (
                  <th>Nota Fiscal</th>
                )}
                {tipo === 'ordem-cronologica-pagamentos' && (
                  <th colSpan={3}>Nota Fiscal</th>
                )}
                {tipo === 'ordem-cronologica-pagamentos' && (
                  <TableHeaderItem
                    field="dataLiquidacao"
                    label="Data Liquidação"
                    page={page}
                    searchWithPage={searchWithPage}
                    alignment="left"
                  />
                )}
                {tipo === 'ordem-cronologica-pagamentos' && (
                  <TableHeaderItem
                    field="dataVencimento"
                    label="Data Vencimento"
                    page={page}
                    searchWithPage={searchWithPage}
                    alignment="left"
                  />
                )}
                {tipo === 'ordem-cronologica-pagamentos' && (
                  <TableHeaderItem
                    field="data"
                    label="Data Pagamento"
                    page={page}
                    searchWithPage={searchWithPage}
                    alignment="left"
                  />
                )}
                {tipo === 'pagas' &&
                this.props.paramDespesasPagasValor === 'S' ? (
                  <TableHeaderItem
                    field="valorParametro"
                    label="Valor"
                    page={page}
                    searchWithPage={searchWithPage}
                    alignment="left"
                  />
                ) : (
                  <TableHeaderItem
                    field="valor"
                    label="Valor"
                    page={page}
                    searchWithPage={searchWithPage}
                    alignment="left"
                  />
                )}
                {tipo !== 'ordem-cronologica-pagamentos' && <th>Saldo</th>}
                <th />
              </tr>
            </TableHeader>
            <tbody>
              {page.get('content').map(this.renderTableLiquidacoes)}
            </tbody>

            {!page.get('content').isEmpty() && (
              <tfoot>
                <tr>
                  {(tipo === 'aptos-pagamentos' ||
                    tipo === 'ordem-cronologica-pagamentos') && <td />}
                  {tipo === 'ordem-cronologica-pagamentos' && <td />}
                  {tipo === 'ordem-cronologica-pagamentos' && <td />}
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  {tipo === 'pagas' && <td />}
                  <td className="right">
                    <i>Total:</i>
                    <br />
                    {tipo === 'pagas' &&
                    this.props.paramDespesasPagasValor === 'S' ? (
                      <SumListField
                        fieldName="valorParametro"
                        list={page.get('content')}
                      />
                    ) : (
                      <NumberFormatter value={total.get('valor')} />
                    )}
                  </td>
                  {tipo !== 'ordem-cronologica-pagamentos' && (
                    <td className="right">
                      <i>Total:</i>
                      <br />
                      {tipo === 'pagas' &&
                      this.props.paramDespesasPagasValor === 'S' ? (
                        <SumListField
                          fieldName="valorParametro"
                          list={page.get('content')}
                        />
                      ) : (
                        <NumberFormatter value={total.get('saldo')} />
                      )}
                    </td>
                  )}
                  <td />
                </tr>
              </tfoot>
            )}
          </table>
        </div>
      </section>
    );
  }
}

export default LiquidacoesListagem;
