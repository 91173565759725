import SumListField from 'portaltransparencia-common/components/SumListField.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

import ResumoNaturezaRow from './ResumoNaturezaRow.react.js';

export default class ResumoNaturezaTable extends Component {
  static propTypes = {
    resumosNatureza: PropTypes.object.isRequired
  };

  renderResumoNaturezaItem = data => {
    return (
      <ResumoNaturezaRow key={data.get('natureza')} resumoNatureza={data} />
    );
  };

  render() {
    const { resumosNatureza } = this.props;

    return (
      <div className="panel-table-outer">
        <table className="panel-table striped fancy">
          <TableHeader empty={resumosNatureza.isEmpty()}>
            <tr>
              <th>Natureza</th>
              <th>Total</th>
            </tr>
          </TableHeader>

          <tbody>{resumosNatureza.map(this.renderResumoNaturezaItem)}</tbody>
          {resumosNatureza.size > 0 && (
            <tfoot>
              <tr>
                <td></td>
                <td>
                  <i>Total:</i>
                  <SumListField
                    fieldName="total"
                    list={resumosNatureza}
                    format="0,0"
                  />
                </td>
              </tr>
            </tfoot>
          )}
        </table>
      </div>
    );
  }
}
