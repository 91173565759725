export const MOVIMENTOS_GARAGEM_FIND = 'MOVIMENTOS_GARAGEM_FIND';
export const MOVIMENTOS_GARAGEM_FIND_START = 'MOVIMENTOS_GARAGEM_FIND_START';
export const MOVIMENTOS_GARAGEM_FIND_SUCCESS =
  'MOVIMENTOS_GARAGEM_FIND_SUCCESS';
export const MOVIMENTOS_GARAGEM_FIND_ERROR = 'MOVIMENTOS_GARAGEM_FIND_ERROR';

export const MOVIMENTO_GARAGEM = 'MOVIMENTO_GARAGEM';
export const MOVIMENTO_GARAGEM_START = 'MOVIMENTO_GARAGEM_START';
export const MOVIMENTO_GARAGEM_SUCCESS = 'MOVIMENTO_GARAGEM_SUCCESS';
export const MOVIMENTO_GARAGEM_ERROR = 'MOVIMENTO_GARAGEM_ERROR';
