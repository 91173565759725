import { Map } from 'immutable';
import SearchPagination from 'portaltransparencia-common/components/SearchPagination.react.js';
import {
  onChange,
  resetCrud
} from 'portaltransparencia-common/crud/Actions.js';
import configuration from 'portaltransparencia-common/config/Config.js';
import {
  addFilterUrl,
  addPagination
} from 'portaltransparencia-common/libs/AddFilter.js';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';

import PageHeader from '../components/PageHeader.react';
import * as actions from './Actions.js';
import CreditosAdicionaisFiltro from './CreditosAdicionaisFiltro.react';
import CreditosAdicionaisListagem from './CreditosAdicionaisListagem.react';
import { addErrorNotification } from 'portaltransparencia-common/ui/Actions.js';

export class CreditosAdicionais extends Component {
  static propTypes = {
    page: PropTypes.object.isRequired,
    findCreditosAdicionais: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    filtros: PropTypes.object,
    resetCrud: PropTypes.func.isRequired,
    addErrorNotification: PropTypes.func.isRequired
  };

  componentDidMount() {
    const { page } = this.props;
    this.onConsultaWithPage(page);
  }

  onConsultaWithPage = page => {
    const { findCreditosAdicionais } = this.props;
    let filtro = this.montaFiltro(page);
    findCreditosAdicionais(filtro);
  };

  onResetFiltro() {
    const { findCreditosAdicionais } = this.props;
    resetCrud('filtroCreditosAdicionais ');
    findCreditosAdicionais('');
  }

  searchWithPage = page => {
    const { findCreditosAdicionais } = this.props;

    const filtro = this.montaFiltro(page);
    findCreditosAdicionais(filtro);
  };

  montaFiltro = function (page) {
    const { entidade, exercicio } = StorageService.getItem('userInfo');
    const { filtros } = this.props;

    let filter = 'entidade=' + entidade.id + '&exercicio=' + exercicio.id;

    if (filtros.get('despesa')) {
      filter = addFilterUrl(filter, `despesa=${filtros.get('despesa')}`);
    }
    if (filtros.get('decreto')) {
      filter = addFilterUrl(filter, `nroDecreto=${filtros.get('decreto')}`);
    }
    return addPagination(page, filter);
  };

  onExporta = (exportType, openTab, windowParams) => {
    const { apiURL } = configuration;
    const { page, addErrorNotification } = this.props;

    if (page.get('content').size > 0) {
      let filtro =
        this.montaFiltro(page, this.props) + '&exportType=' + exportType;

      window.open(
        `${apiURL}/api/creditosadicionais/report?${filtro}`,
        openTab,
        windowParams
      );
    } else {
      addErrorNotification(
        'A consulta não apresentou resultado. Não é possível exportar para o formato selecionado!'
      );
    }
  };

  render() {
    const { page, onChange, filtros } = this.props;
    const filtroCreditosAdicionais = 'filtroCreditosAdicionais';
    return (
      <section>
        <PageHeader location={this.props.location} />
        <Row>
          <Col>
            <CreditosAdicionaisFiltro
              page={page}
              onChange={onChange}
              montaFiltro={this.montaFiltro}
              filtroCreditosAdicionais={filtroCreditosAdicionais}
              resetFiltro={this.resetFiltro}
              onConsultaWithPage={this.onConsultaWithPage}
              filtros={filtros}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <CreditosAdicionaisListagem
              page={page}
              searchWithPage={this.searchWithPage}
              montaFiltro={this.montaFiltro}
              onExporta={this.onExporta}
            />
          </Col>
        </Row>

        <SearchPagination
          page={page}
          searchWithPage={this.onConsultaWithPage}
        />
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    page: state.creditosAdicionais.get('creditosAdicionais'),
    filtros: state.crud.getIn(
      ['filtroCreditosAdicionais', 'currentRecord'],
      Map({})
    )
  };
}

const allActions = {
  ...actions,
  onChange,
  resetCrud,
  addErrorNotification
};

export default connect(mapStateToProps, allActions)(CreditosAdicionais);
