import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import * as actions from './Actions.js';
import { connect } from 'react-redux';

export class LinkToConvenio extends Component {
  static propTypes = {
    entidade: PropTypes.number,
    nrConvenio: PropTypes.number,
    nrAnoConvenio: PropTypes.number,
    historyConvenio: PropTypes.any,
    vinculadoConvenio: PropTypes.string,
    findConvenioWithoutId: PropTypes.func,
    convenio: PropTypes.object
  };

  componentDidMount() {
    const {
      entidade,
      nrConvenio,
      nrAnoConvenio,
      findConvenioWithoutId,
      vinculadoConvenio
    } = this.props;

    if (nrConvenio && nrAnoConvenio && entidade && vinculadoConvenio) {
      findConvenioWithoutId(entidade, nrConvenio, nrAnoConvenio);
    }
  }

  showConvenio = () => {
    const {
      entidade,
      nrConvenio,
      historyConvenio,
      nrAnoConvenio,
      convenio,
      findConvenioWithoutId
    } = this.props;

    findConvenioWithoutId(entidade, nrConvenio, nrAnoConvenio);

    const idContaCorrente = convenio.get('idContaCorrente');
    const local = convenio.get('local');
    const idConvenio = convenio.get('id');

    historyConvenio.push(
      `/convenios/detalhes?entidade=${entidade}&id=${idConvenio}&nrConvenio=${nrConvenio}&nrAnoConvenio=${nrAnoConvenio}&idContaCorrente=${idContaCorrente}&local=${local}`
    );
  };

  render() {
    const {
      entidade,
      nrConvenio,
      nrAnoConvenio,
      vinculadoConvenio
    } = this.props;
    if (entidade && nrConvenio && nrAnoConvenio) {
      if (vinculadoConvenio === 'S') {
        return (
          <a
            style={{ cursor: 'pointer' }}
            onClick={this.showConvenio}
          >{`${nrConvenio}`}</a>
        );
      } else {
        return nrConvenio;
      }
    }

    return null;
  }
}

function mapStateToProps(state) {
  return {
    convenio: state.emendaParlamentar.get('convenio')
  };
}

const allActions = {
  ...actions
};

export default connect(mapStateToProps, allActions)(LinkToConvenio);
