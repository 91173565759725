import { Map } from 'immutable';
import {
  onChange,
  resetCrud
} from 'portaltransparencia-common/crud/Actions.js';
import {
  addFilterUrl,
  addPagination
} from 'portaltransparencia-common/libs/AddFilter.js';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import PageHeader from '../components/PageHeader.react.js';
import FornecedorFiltro from '../fornecedores/FornecedorFiltro.react.js';
import FornecedorTable from '../fornecedores/FornecedorTable.react.js';
import NotasExplicativas from '../notaexplicativa/Form.react';
import * as actions from './Actions.js';
import { addErrorNotification } from 'portaltransparencia-common/ui/Actions.js';
import configuration from 'portaltransparencia-common/config/Config.js';

export class ComprasDireta extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    page: PropTypes.object.isRequired,
    totais: PropTypes.object,
    findFornecedores: PropTypes.func.isRequired,
    findFornecedoresTotais: PropTypes.func.isRequired,
    filtros: PropTypes.object,
    location: PropTypes.object.isRequired,
    resetCrud: PropTypes.func.isRequired,
    valorParametro: PropTypes.string,
    addErrorNotification: PropTypes.func.isRequired
  };

  componentDidMount() {
    const { page } = this.props;
    this.onConsultaWithPage(page);
  }

  onConsultaWithPage = page => {
    let filter = this.montaFiltro(page);
    const { findFornecedores, findFornecedoresTotais } = this.props;
    findFornecedores(filter);
    findFornecedoresTotais(filter);
  };

  montaFiltro = page => {
    const { entidade, exercicio } = StorageService.getItem('userInfo');
    let filter = 'entidade=' + entidade.id + '&exercicio=' + exercicio.id;

    const { filtros } = this.props;

    if (filtros.get('nome')) {
      filter = addFilterUrl(filter, `nome=${filtros.get('nome')}`);
    }
    if (filtros.get('cnpj')) {
      filter = addFilterUrl(filter, `cnpjCpf=${filtros.get('cnpj')}`);
    }

    return addPagination(page, filter);
  };

  onExporta = (exportType, openTab, windowParams) => {
    const { page, addErrorNotification } = this.props;
    const { apiURL } = configuration;

    if (page.get('content').size > 0) {
      let filtro = this.montaFiltro(page) + '&exportType=' + exportType;
      window.open(
        `${apiURL}/fornecedorapagar/report?${filtro}`,
        openTab,
        windowParams
      );
    } else {
      addErrorNotification(
        'A consulta não apresentou resultado. Não é possível exportar para o formato selecionado!'
      );
    }
  };

  render() {
    const {
      filtros,
      onChange,
      findFornecedores,
      findFornecedoresTotais,
      page,
      totais,
      resetCrud
    } = this.props;
    const { exercicio } = StorageService.getItem('userInfo');

    const title = `Compras Diretas por Fornecedor do Exercicio de ${exercicio.id}`;
    const filtroFornecedor = 'filtroComprasDireta';

    return (
      <section>
        <PageHeader location={this.props.location} />

        <NotasExplicativas pathname={this.props.location.pathname} />

        <FornecedorFiltro
          filtros={filtros}
          montaFiltro={this.montaFiltro}
          onChange={onChange}
          page={page}
          findFornecedoresTotais={findFornecedoresTotais}
          findFornecedores={findFornecedores}
          onConsultaWithPage={this.onConsultaWithPage}
          resetCrud={resetCrud}
          filtroFornecedor={filtroFornecedor}
        />

        <FornecedorTable
          montaFiltro={this.montaFiltro}
          page={page}
          history={this.props.history}
          onConsultaWithPage={this.onConsultaWithPage}
          totais={totais}
          title={title}
          getAPagar={false}
          getComprasDireta={true}
          onExporta={this.onExporta}
        />
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    page: state.comprasDiretaFornecedor.get('comprasDireta'),
    totais: state.comprasDiretaFornecedor.get('totais'),
    filtros: state.crud.getIn(['filtroComprasDireta', 'currentRecord'], Map({}))
  };
}

const allActions = {
  ...actions,
  onChange,
  resetCrud,
  addErrorNotification
};

export default connect(mapStateToProps, allActions)(ComprasDireta);
