import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';

import * as types from './Types.js';

const initialState = fromJS({
  consultaSic: [],
  consultaSicFisico: [],
  processoTramitacao: {},
  filtros: [],
  situacoes: [],
  totalDiarias: {},
  assuntos: []
});

function ProcessoEsicReducer(state = initialState, action) {
  switch (action.type) {
    case types.FIND_TIPOESIC_START:
    case types.FIND_SITUACOES_START:
    case types.PROCESSO_START:
    case types.FIND_ASSUNTOS_START: {
      return startLoad(state);
    }

    case types.FIND_TIPOESIC_ERROR:
    case types.FIND_SITUACOES_ERROR:
    case types.PROCESSO_ERROR:
    case types.FIND_ASSUNTOS_ERROR: {
      return endLoad(state);
    }

    case types.FIND_SITUACOES_SUCCESS: {
      return endLoad(state.set('situacoes', fromJS(action.payload)));
    }

    case types.FIND_TIPOESIC_SUCCESS: {
      if (action.meta && action.meta.fisico) {
        return endLoad(state.set('consultaSicFisico', fromJS(action.payload)));
      }
      return endLoad(state.set('consultaSic', fromJS(action.payload)));
    }
    case types.ESIC_SUCCESS: {
      if (action.meta && action.meta.fisico) {
        return endLoad(state.set('consultaSicFisico', fromJS(action.payload)));
      }
      return endLoad(state.set('consultaSic', fromJS(action.payload)));
    }
    case types.TOTAL_ESIC_SUCCESS: {
      return endLoad(state.set('totalEsic', fromJS(action.payload)));
    }
    case types.PROCESSO_SUCCESS: {
      return endLoad(state.set('processoTramitacao', fromJS(action.payload)));
    }
    case types.FIND_ASSUNTOS_SUCCESS: {
      return endLoad(state.set('assuntos', fromJS(action.payload)));
    }

    case types.RESET_CONSULTA_ESIC: {
      return initialState;
    }
  }

  return state;
}

export default ProcessoEsicReducer;
