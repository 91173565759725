import * as types from './Types.js';

export function findAvaliacaoPPA(filtro) {
  return ({ fetch }) => {
    return {
      type: types.AVALIACAO_PPA,
      payload: {
        promise: fetch(`/api/avaliacao-ppa?${filtro}`)
      }
    };
  };
}
