import { handlers, keyMap } from './HotKeysGeneral';

function addKeyMap(menu, keyMap) {
  if (menu.get('atalho') && menu.get('atalho') !== '') {
    keyMap[menu.get('route')] = menu.get('atalho');
  }
}

function addHandler(menu, handler, history) {
  if (menu.get('atalho') && menu.get('atalho') !== '') {
    handler[menu.get('route')] = () => history.push(menu.get('route'));
  }
}

export function getKeyMap(menu) {
  let newKeyMap = {};

  menu.map(menuItem => {
    const subMenu = menuItem.get('subMenu');

    if (subMenu && subMenu.size > 0) {
      subMenu.map(subMenuItem => {
        addKeyMap(subMenuItem, newKeyMap);
      });
    } else {
      addKeyMap(menuItem, newKeyMap);
    }
  });

  return {
    ...keyMap,
    ...newKeyMap
  };
}

export function getHandlers(menu, history) {
  let newHandler = {};

  menu.map(menuItem => {
    const subMenu = menuItem.get('subMenu');

    if (subMenu && subMenu.size > 0) {
      subMenu.map(subMenuItem => {
        addHandler(subMenuItem, newHandler, history);
      });
    } else {
      if (menuItem.get('atalho') && menuItem.get('atalho') !== '') {
        addHandler(menuItem, newHandler, history);
      }
    }
  });

  return {
    ...handlers(history),
    ...newHandler
  };
}
