import { getValueFromUrlSearchParams } from 'portaltransparencia-common/libs/URLUtils';
import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import PageHeader from '../components/PageHeader.react.js';
import * as actions from './Actions.js';
import AjudaCustoPage from './AjudaCustoPage.react.js';
import AjudaCustoServidorDetalhe from './AjudaCustoServidorDetalhe.react.js';

export class FormAjudaCustoDetail extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    servidor: PropTypes.object.isRequired,
    findServidorAjudaCusto: PropTypes.func.isRequired,
    pageAjudasCusto: PropTypes.object.isRequired,
    findAjudasCusto: PropTypes.func.isRequired
  };

  componentDidMount() {
    const { location, findServidorAjudaCusto, findAjudasCusto } = this.props;

    const matricula = getValueFromUrlSearchParams(location, 'matricula');
    const filter = `matricula=${matricula}`;

    findServidorAjudaCusto(filter);
    findAjudasCusto(filter);
  }

  render() {
    const { servidor, pageAjudasCusto, findAjudasCusto } = this.props;

    return (
      <section>
        <Helmet
          id="helmet"
          title={`Ajuda de custo do servidor - ${servidor.get('nome')}`}
        />

        <PageHeader
          location={this.props.location}
          textoDetalhe={`Ajuda de custo do servidor - ${servidor.get('nome')}`}
        />

        <AjudaCustoServidorDetalhe servidor={servidor} />

        <AjudaCustoPage
          page={pageAjudasCusto}
          findAjudasCusto={findAjudasCusto}
          location={window.location}
        />
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    servidor: state.ajudaCusto.get('servidor'),
    pageAjudasCusto: state.ajudaCusto.get('ajudasCusto')
  };
}

export default connect(mapStateToProps, actions)(FormAjudaCustoDetail);
