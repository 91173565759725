import StringUtils from 'portaltransparencia-common/libs/StringUtils.js';
import PropTypes from 'prop-types';
import React from 'react';

import ItemLink from './ItemLink.react.js';
import ResultadoPesquisaList from './ResultadoPesquisaList.react.js';

export class ResultadoPesquisaItem extends React.PureComponent {
  static propTypes = {
    item: PropTypes.object,
    valorPesquisado: PropTypes.string.isRequired
  };

  showItem(item) {
    const { valorPesquisado } = this.props;

    const fieldsToSearch = ['title', 'subtitle'];

    var filtro = StringUtils.removeAcento(valorPesquisado).toUpperCase();

    for (let i = 0; i < fieldsToSearch.length; i++) {
      var valor = StringUtils.removeAcento(
        item.get(fieldsToSearch[i])
      ).toUpperCase();

      if (valor.includes(filtro)) {
        return true;
      }
    }
    return false;
  }

  render() {
    const { item, valorPesquisado, father } = this.props;

    const hasItems = item.has('subMenu') && !item.get('subMenu').isEmpty();
    const showItem = this.showItem(item);

    if (father) {
      return (
        <li>
          {hasItems ? (
            <strong>{item.get('title') + ':'}</strong>
          ) : (
            <ItemLink item={item} />
          )}

          {showItem && (
            <ResultadoPesquisaList
              list={item.get('subMenu')}
              valorPesquisado=""
            />
          )}
          {hasItems && !showItem && (
            <div>
              <ResultadoPesquisaList
                list={item.get('subMenu')}
                valorPesquisado={valorPesquisado}
              />
            </div>
          )}

          <br />
        </li>
      );
    }

    if (showItem) {
      return <ItemLink item={item} style={{ marginLeft: '20px' }} />;
    }

    return null;
  }
}

export default ResultadoPesquisaItem;
