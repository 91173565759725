import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';

import * as types from './Types.js';

const initialState = fromJS({
  notaExtra: {},
  notasExtra: [],
  notaExtraItens: {}
});

function notaExtraorcamentariaReducer(state = initialState, action) {
  switch (action.type) {
    case types.NOTA_EXTRAORCAMENTARIA_START:
    case types.NOTAS_EXTRAORCAMENTARIAS_START:
    case types.NOTA_EXTRAORCAMENTARIA_ITENS_START: {
      return startLoad(state);
    }

    case types.NOTA_EXTRAORCAMENTARIA_ERROR:
    case types.NOTAS_EXTRAORCAMENTARIAS_ERROR:
    case types.NOTA_EXTRAORCAMENTARIA_ITENS_ERROR: {
      return endLoad(state);
    }

    case types.NOTA_EXTRAORCAMENTARIA_SUCCESS: {
      return endLoad(state.set('notaExtra', fromJS(action.payload)));
    }
    case types.NOTAS_EXTRAORCAMENTARIAS_SUCCESS: {
      return endLoad(state.set('notasExtra', fromJS(action.payload)));
    }
    case types.NOTA_EXTRAORCAMENTARIA_ITENS_SUCCESS: {
      return endLoad(state.set('notaExtraItens', fromJS(action.payload)));
    }
  }

  return state;
}

export default notaExtraorcamentariaReducer;
