import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react.js';
import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import { withRouter } from 'react-router';

export class RepasseListagem extends Component {
  static propTypes = {
    repasses: PropTypes.object.isRequired,
    tipoRepasse: PropTypes.string.isRequired
  };

  renderRepasses = data => {
    const { tipoRepasse } = this.props;

    let campoValorDevolvido = '';
    let campoValorPrevisto = '';
    let campoValorRestante = '';

    if (tipoRepasse == 'despesa') {
      campoValorDevolvido = 'valorRecebido';
      campoValorPrevisto = 'valorPrevistoRepassar';
      campoValorRestante = 'valorRestanteRepassar';
    } else {
      campoValorDevolvido = 'valorDevolvido';
      campoValorPrevisto = 'valorPrevistoReceber';
      campoValorRestante = 'valorRestanteReceber';
    }

    const key =
      data.get('entidade') +
      '_' +
      data.get('exercicio') +
      '_' +
      data.get('conta') +
      '_' +
      data.get('idContaCorrente');

    return (
      <TableActions
        ref="row"
        key={key}
        data={data}
        onMore={this.onViewDetalhes}
      >
        <td>{data.get('nomeFornecedor')} </td>
        <td style={{ textAlign: 'right' }}>
          <NumberFormatter value={data.get(campoValorPrevisto)} />
        </td>
        <td style={{ textAlign: 'right' }}>
          <NumberFormatter value={data.get('valorLancado')} />
        </td>
        <td style={{ textAlign: 'right' }}>
          <NumberFormatter value={data.get(campoValorDevolvido)} />
        </td>
        <td style={{ textAlign: 'right' }}>
          <NumberFormatter value={data.get(campoValorRestante)} />
        </td>
      </TableActions>
    );
  };

  onViewDetalhes = item => {
    const entidade = item.get('entidade');
    const exercicio = item.get('exercicio');
    const conta = item.get('conta');
    const idContaCorrente = item.get('idContaCorrente');
    const tipo = item.get('tipo');
    if (tipo == 'E') {
      this.props.history.push(
        `/repasses-despesa/detalhes?entidade=${entidade}&exercicio=${exercicio}&conta=${conta}&idContaCorrente=${idContaCorrente}&tipo=${tipo}`
      );
    } else if (tipo == 'R') {
      this.props.history.push(
        `/repasses-receita/detalhes?entidade=${entidade}&exercicio=${exercicio}&conta=${conta}&idContaCorrente=${idContaCorrente}&tipo=${tipo}`
      );
    }
  };

  render() {
    const { repasses, tipoRepasse } = this.props;

    let colunaValor = '';
    let colunaValorDevolvido = '';
    let colunaValorRestante = '';

    if (tipoRepasse == 'despesa') {
      colunaValor = 'Valor Repassado';
      colunaValorDevolvido = 'Valor Recebido';
      colunaValorRestante = 'Valor a Repassar';
    } else {
      colunaValor = 'Valor Recebido';
      colunaValorDevolvido = 'Valor Devolvido';
      colunaValorRestante = 'Valor a Receber';
    }

    return (
      <div className="panel-table-outer">
        <table className="panel-table striped fancy">
          <TableHeader empty={repasses.isEmpty()}>
            <tr>
              <th>Órgão</th>
              <th style={{ textAlign: 'right' }}>Valor Previsto</th>
              <th style={{ textAlign: 'right' }}>{colunaValor}</th>
              <th style={{ textAlign: 'right' }}>{colunaValorDevolvido}</th>
              <th style={{ textAlign: 'right' }}>{colunaValorRestante}</th>
              <th style={{ width: 10 }}></th>
            </tr>
          </TableHeader>
          <tbody>{repasses.map(this.renderRepasses)}</tbody>
        </table>
      </div>
    );
  }
}

export default withRouter(RepasseListagem);
