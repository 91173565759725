import Panel from 'portaltransparencia-common/components/Panel.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

import AjudaCustoRow from './AjudaCustoRow.react.js';

export default class AjudaCustoTable extends Component {
  static propTypes = {
    ajudasCusto: PropTypes.object.isRequired
  };

  generateKey = ajudaCusto => {
    return `${ajudaCusto.get('anoCompetencia')}_
            ${ajudaCusto.get('mesCompetencia')}_
            ${ajudaCusto.get('competenciaLancamento')}_
            ${ajudaCusto.get('evento')}`;
  };

  renderAjudasCusto = data => {
    return <AjudaCustoRow key={this.generateKey(data)} ajudaCusto={data} />;
  };

  render() {
    const { ajudasCusto } = this.props;

    return (
      <Panel isForm tableResponsive expansible={false}>
        <div className="panel-table-outer">
          <table className="panel-table striped fancy">
            <TableHeader empty={ajudasCusto.isEmpty()}>
              <tr>
                <th>Ano Competência</th>
                <th>Mês Competência</th>
                <th>Lançamento da Competência</th>
                <th>Matrícula</th>
                <th>Valor</th>
                <th>Evento</th>
              </tr>
            </TableHeader>
            <tbody>{ajudasCusto.map(this.renderAjudasCusto)}</tbody>
          </table>
        </div>
      </Panel>
    );
  }
}
