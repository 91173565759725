import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';

import * as types from './Types.js';

const initialState = fromJS({
  suspensaoFornecedores: []
});

function suspensaoFornecedorReducer(state = initialState, action) {
  switch (action.type) {
    case types.SUSPENSAOFORNECEDORES_LIST_START: {
      return startLoad(state);
    }

    case types.SUSPENSAOFORNECEDORES_LIST_SUCCESS: {
      return endLoad(
        state.set('suspensaoFornecedores', fromJS(action.payload))
      );
    }

    case types.SUSPENSAOFORNECEDORES_LIST_ERROR: {
      return endLoad(state);
    }
  }

  return state;
}

export default suspensaoFornecedorReducer;
