import DisplayData from 'portaltransparencia-common/components/displaydata/DisplayData.react.js';
import DisplayDataItem from 'portaltransparencia-common/components/displaydata/DisplayDataItem.react.js';
import DisplayDataRow from 'portaltransparencia-common/components/displaydata/DisplayDataRow.react.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export default class ProcessoInformacoes extends Component {
  static propTypes = {
    protocolo: PropTypes.object.isRequired
  };

  render() {
    const { protocolo } = this.props;

    const processoReferencia = protocolo.get('processoReferencia');

    return (
      <DisplayData border>
        <DisplayDataRow>
          <DisplayDataItem
            sm={12}
            title="Requerente"
            value={protocolo.get('requerente')}
            id="requerente"
          />
        </DisplayDataRow>
        <DisplayDataRow>
          <DisplayDataItem
            sm={processoReferencia ? 2 : 4}
            title="Processo"
            value={protocolo.get('processo')}
            id="processo"
          />
          <DisplayDataItem
            sm={processoReferencia ? 3 : 4}
            title="Data"
            value={protocolo.get('data')}
            id="data"
          />
          <DisplayDataItem
            sm={4}
            title="Situação"
            value={protocolo.get('situacao')}
            id="situacao"
          />
          <DisplayDataItem
            sm={3}
            title="Referência"
            value={processoReferencia}
            id="referencia"
            show={processoReferencia != null}
          />
        </DisplayDataRow>
        <DisplayDataRow>
          <DisplayDataItem
            sm={12}
            title="Assunto"
            value={protocolo.get('assunto')}
            id="assunto"
          />
        </DisplayDataRow>
        <DisplayDataRow>
          <DisplayDataItem
            sm={12}
            title="Digitação"
            value={protocolo.get('digitacao')}
            id="digitacao"
          />
        </DisplayDataRow>
      </DisplayData>
    );
  }
}
