export const LIST_CARGOS = 'LIST_CARGOS';
export const LIST_CARGOS_START = 'LIST_CARGOS_START';
export const LIST_CARGOS_SUCCESS = 'LIST_CARGOS_SUCCESS';
export const LIST_CARGOS_ERROR = 'LIST_CARGOS_ERROR';

export const LIST_FAIXAS = 'LIST_FAIXAS';
export const LIST_FAIXAS_START = 'LIST_FAIXAS_START';
export const LIST_FAIXAS_SUCCESS = 'LIST_FAIXAS_SUCCESS';
export const LIST_FAIXAS_ERROR = 'LIST_FAIXAS_ERROR';

export const FILTRO_CARGOS = 'FILTRO_CARGOS';
export const FILTRO_CARGOS_START = 'FILTRO_CARGOS_START';
export const FILTRO_CARGOS_SUCCESS = 'FILTRO_CARGOS_SUCCESS';
export const FILTRO_CARGOS_ERROR = 'FILTRO_CARGOS_ERROR';

export const FILTRO_QUADROS = 'FILTRO_QUADROS';
export const FILTRO_QUADROS_START = 'FILTRO_QUADROS_START';
export const FILTRO_QUADROS_SUCCESS = 'FILTRO_QUADROS_SUCCESS';
export const FILTRO_QUADROS_ERROR = 'FILTRO_QUADROS_ERROR';

export const FILTRO_GRUPOS = 'FILTRO_GRUPOS';
export const FILTRO_GRUPOS_START = 'FILTRO_GRUPOS_START';
export const FILTRO_GRUPOS_SUCCESS = 'FILTRO_GRUPOS_SUCCESS';
export const FILTRO_GRUPOS_ERROR = 'FILTRO_GRUPOS_ERROR';

export const FILTRO_NIVEIS = 'FILTRO_NIVEIS';
export const FILTRO_NIVEIS_START = 'FILTRO_NIVEIS_START';
export const FILTRO_NIVEIS_SUCCESS = 'FILTRO_NIVEIS_SUCCESS';
export const FILTRO_NIVEIS_ERROR = 'FILTRO_NIVEIS_ERROR';

export const LIST_FUNCOES_GRATIFICADAS = 'LIST_FUNCOES_GRATIFICADAS';
export const LIST_FUNCOES_GRATIFICADAS_START =
  'LIST_FUNCOES_GRATIFICADAS_START';
export const LIST_FUNCOES_GRATIFICADAS_SUCCESS =
  'LIST_FUNCOES_GRATIFICADAS_SUCCESS';
export const LIST_FUNCOES_GRATIFICADAS_ERROR =
  'LIST_FUNCOES_GRATIFICADAS_ERROR';

export const LIST_CARGOS_OXY = 'LIST_CARGOS_OXY';
export const LIST_CARGOS_OXY_START = 'LIST_CARGOS_OXY_START';
export const LIST_CARGOS_OXY_SUCCESS = 'LIST_CARGOS_OXY_SUCCESS';
export const LIST_CARGOS_OXY_ERROR = 'LIST_CARGOS_OXY_ERROR';

export const LIST_MATRIZES = 'LIST_MATRIZES';
export const LIST_MATRIZES_START = 'LIST_MATRIZES_START';
export const LIST_MATRIZES_SUCCESS = 'LIST_MATRIZES_SUCCESS';
export const LIST_MATRIZES_ERROR = 'LIST_MATRIZES_ERROR';

export const FILTRO_MATRIZES = 'FILTRO_MATRIZES';
export const FILTRO_MATRIZES_START = 'FILTRO_MATRIZES_START';
export const FILTRO_MATRIZES_SUCCESS = 'FILTRO_MATRIZES_SUCCESS';
export const FILTRO_MATRIZES_ERROR = 'FILTRO_MATRIZES_ERROR';

export const LIST_MATRIZ_VIGENTE = 'LIST_MATRIZ_VIGENTE';
export const LIST_MATRIZ_VIGENTE_START = 'LIST_MATRIZ_VIGENTE_START';
export const LIST_MATRIZ_VIGENTE_SUCCESS = 'LIST_MATRIZ_VIGENTE_SUCCESS';
export const LIST_MATRIZ_VIGENTE_ERROR = 'LIST_MATRIZ_VIGENTE_ERROR';

export const LIST_MATRIZ = 'LIST_MATRIZ';
export const LIST_MATRIZ_START = 'LIST_MATRIZ_START';
export const LIST_MATRIZ_SUCCESS = 'LIST_MATRIZ_SUCCESS';
export const LIST_MATRIZ_ERROR = 'LIST_MATRIZ_ERROR';

export const LIST_CARGOS_REDUZIDO_OXY = 'LIST_CARGOS_REDUZIDO_OXY';
export const LIST_CARGOS_REDUZIDO_START = 'LIST_CARGOS_REDUZIDO_START';
export const LIST_CARGOS_REDUZIDO_SUCCESS = 'LIST_CARGOS_REDUZIDO_SUCCESS';
export const LIST_CARGOS_REDUZIDO_ERROR = 'LIST_CARGOS_REDUZIDO_ERROR';
