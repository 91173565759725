import * as types from './Types.js';

export function findLinksUteis() {
  return ({ fetch }) => ({
    type: types.LINKS_UTEIS,
    payload: {
      promise: fetch('/api/links-uteis/ativos?tipo=1')
    }
  });
}
