import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import TableHeaderItem from 'portaltransparencia-common/components/TableHeaderItem.react';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

import SuprimentoFundosRow from './SuprimentoFundosRow.react.js';

export default class SuprimentoFundosTable extends Component {
  static propTypes = {
    suprimentosFundos: PropTypes.object,
    total: PropTypes.object,
    filter: PropTypes.string,
    mensagem: PropTypes.string,
    page: PropTypes.object,
    searchWithPage: PropTypes.func
  };

  generateKey = suprimentoFundos => {
    return `${suprimentoFundos.get('entidade')}_
            ${suprimentoFundos.get('exercicio')}_
            ${suprimentoFundos.get('empenhoExercicio')}_
            ${suprimentoFundos.get('empenho')}_
            ${suprimentoFundos.get('noLiquidacao')}_
            ${suprimentoFundos.get('valor')}`;
  };

  renderRows = data => {
    return (
      <SuprimentoFundosRow
        key={this.generateKey(data)}
        suprimentoFundos={data}
        history={this.props.history}
      />
    );
  };

  render() {
    const {
      suprimentosFundos,
      total,
      mensagem,
      page,
      searchWithPage
    } = this.props;

    if (!suprimentosFundos) {
      return null;
    }
    return (
      <section>
        <div className="panel-table-outer">
          <table className="panel-table striped fancy">
            <TableHeader empty={suprimentosFundos.isEmpty()} text={mensagem}>
              <tr>
                <TableHeaderItem
                  field="empenho"
                  label="Empenho/Ano"
                  page={page}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="data"
                  label="Data do Pagamento"
                  page={page}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="processo"
                  label="Processo"
                  page={page}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="fornecedor"
                  label="Fornecedor"
                  page={page}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="valor"
                  label="Valor"
                  page={page}
                  searchWithPage={searchWithPage}
                  alignment="right"
                />
                <th></th>
              </tr>
            </TableHeader>
            <tbody>{suprimentosFundos.map(this.renderRows)}</tbody>

            {!suprimentosFundos.isEmpty() && (
              <tfoot>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="right">
                    <i>Total:</i>
                    <NumberFormatter value={total.get('valor')} />
                  </td>
                  <td></td>
                </tr>
              </tfoot>
            )}
          </table>
        </div>
      </section>
    );
  }
}
