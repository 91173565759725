import Hint from 'portaltransparencia-common/components/hint/Hint.react';
import Widget from 'portaltransparencia-common/components/Widget.react.js';
import { format } from 'portaltransparencia-common/libs/NumberUtils';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';

export class RepasseAno extends Component {
  static propTypes = {
    repasseConcedido: PropTypes.number,
    repasseRecebido: PropTypes.number,
    exercicio: PropTypes.any.isRequired
  };

  render() {
    const { repasseConcedido, repasseRecebido, exercicio } = this.props;
    return (
      <Widget
        icon="fa fa-area-chart"
        title={'Valores de repasse no ano de ' + exercicio}
      >
        <Row>
          <Col sm={6} md={6}>
            <article className="widget-numeric block">
              <b className="widget-numeric-number">
                {format(repasseConcedido)}
              </b>
              <div className="widget-numeric-subtitle">
                <Hint
                  message={`Soma dos valores de repasses enviados de todos os meses de ${exercicio}.`}
                  size="xs"
                  position="bottom-left"
                  spacing="mr-xs"
                  isInline
                />
                Repasses Concedidos no ano de {exercicio}
              </div>
            </article>
          </Col>
          <Col sm={6} md={6}>
            <article className="widget-numeric block">
              <b className="widget-numeric-number">{format(repasseRecebido)}</b>
              <div className="widget-numeric-subtitle">
                Repasses Recebidos no ano de {exercicio}
                <Hint
                  message={`Soma dos valores de repasses recebidos de todos os meses de ${exercicio}.`}
                  size="xs"
                  isInline
                />
              </div>
            </article>
          </Col>
        </Row>
      </Widget>
    );
  }
}

export default RepasseAno;
