import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react';
import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import TableHeaderItem from 'portaltransparencia-common/components/TableHeaderItem.react';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';
import ExportButtonGroup from 'portaltransparencia-common/components/ExportButtonGroup.react.js';

import UltimaAtualizacaoByTable from '../ultimaatualizacao/UltimaAtualizacaoByTable.react.js';

export class CreditosAdicionaisListagem extends Component {
  static propTypes = {
    page: PropTypes.object.isRequired,
    searchWithPage: PropTypes.func.isRequired,
    isIntegradoOxy: PropTypes.bool,
    onExporta: PropTypes.func.isRequired
  };

  renderCreditosAdicionais = data => {
    const { isIntegradoOxy } = this.props;

    const key =
      `${data.get('sequencia')}` +
      `_${data.get('despesa')}` +
      `_${data.get('fonteRecurso')}` +
      `_${data.get('natureza')}` +
      `_${data.get('valor')}` +
      `_${data.get('valorInicial')}` +
      `_${data.get('saldoAtualizado')}`;

    return (
      <TableActions ref="row" key={key} data={data}>
        <td>{data.get('despesa')}</td>
        {isIntegradoOxy && <td>{data.get('fonteRecurso')}</td>}
        <td className="right">{data.get('decreto')}</td>
        <td className="right">{data.get('natureza')}</td>
        <td className="right">
          <NumberFormatter value={data.get('valorInicial')} />
        </td>
        <td className="right">
          <NumberFormatter value={data.get('valor')} />
        </td>
        <td className="right">
          <NumberFormatter value={data.get('saldoAtualizado')} />
        </td>
      </TableActions>
    );
  };

  render() {
    const { page, searchWithPage, isIntegradoOxy, onExporta } = this.props;
    if (!this.props.page.get('content')) {
      return null;
    }

    return (
      <Panel expansible={false} isForm tableResponsive>
        <Row>
          <Col xs={6}>
            <UltimaAtualizacaoByTable
              modulo="3"
              table="decreto"
              column="dataultimaatualizacao"
            />
          </Col>
          <Col xs={6}>
            <ExportButtonGroup onClick={onExporta} visible={true} />
          </Col>
        </Row>
        <div className="panel-table-outer">
          <table className="panel-table striped fancy">
            <TableHeader empty={page.isEmpty()}>
              <tr>
                <TableHeaderItem
                  field="despesa"
                  label="Despesa"
                  page={page}
                  searchWithPage={searchWithPage}
                />
                {isIntegradoOxy && (
                  <TableHeaderItem
                    field="fonterecurso"
                    label="Fonte de Recurso"
                    page={page}
                    searchWithPage={searchWithPage}
                  />
                )}
                <TableHeaderItem
                  field="decreto"
                  label="Decreto"
                  page={page}
                  searchWithPage={searchWithPage}
                  alignment="right"
                />
                <TableHeaderItem
                  field="natureza"
                  label="Natureza"
                  page={page}
                  searchWithPage={searchWithPage}
                  alignment="right"
                />
                <TableHeaderItem
                  field="valorinicial"
                  label="Valor Inicial"
                  page={page}
                  searchWithPage={searchWithPage}
                  alignment="right"
                />
                {isIntegradoOxy ? (
                  <th className="right">Valor</th>
                ) : (
                  <TableHeaderItem
                    field="valor"
                    label="Valor"
                    page={page}
                    searchWithPage={searchWithPage}
                    alignment="right"
                  />
                )}
                {isIntegradoOxy ? (
                  <th className="right">Saldo Atualizado</th>
                ) : (
                  <TableHeaderItem
                    field="saldoatualizado"
                    label="Saldo Atualizado"
                    page={page}
                    searchWithPage={searchWithPage}
                    alignment="right"
                  />
                )}
                <th></th>
              </tr>
            </TableHeader>
            <tbody>
              {page.get('content').map(this.renderCreditosAdicionais)}
            </tbody>
          </table>
        </div>
      </Panel>
    );
  }
}

function mapStateToProps(state) {
  return {
    isIntegradoOxy: state.entidade.getIn([
      'configuracoesGerais',
      'isIntegradoOxy'
    ])
  };
}

export default connect(mapStateToProps, {})(CreditosAdicionaisListagem);
