import Input from 'portaltransparencia-common/components/EloInput.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';

import FilterButtons from '../components/FilterButtons.react';

export default class PublicacaoFiltro extends Component {
  static propTypes = {
    show: PropTypes.bool,
    findPublicacoes: PropTypes.func.isRequired,
    filterPublicacoes: PropTypes.func.isRequired,
    resetPublicacoes: PropTypes.func.isRequired
  };

  onPesquisa = () => {
    const { filterPublicacoes, resetPublicacoes } = this.props;
    const { publicacao: filtro } = this.refs;

    resetPublicacoes();

    if (filtro.getValue()) {
      filterPublicacoes(filtro.getValue());
    }
  };

  onResetFiltro = () => {
    const { resetPublicacoes } = this.props;
    resetPublicacoes();
  };

  handleKeyDown = event => {
    const teclaEnter = 13;
    if (event.keyCode === teclaEnter) {
      this.onPesquisa();
    }
  };

  render() {
    const { show } = this.props;

    if (!show) {
      return null;
    }

    return (
      <Panel title="Consultar em Publicações" mobileClose isForm>
        <Row>
          <Col xs={12} sm={4} md={8}>
            <Input
              id="publicacao"
              name="publicacao"
              ref="publicacao"
              label="Publicação"
              placeholder="Pesquisar Publicações"
              onKeyDown={this.handleKeyDown}
              type="text"
            />
          </Col>
          <Col xs={12} sm={4} md={4}>
            <FilterButtons
              idBtnReset="btnResetFilter"
              onConsulta={this.onPesquisa}
              onReset={this.onResetFiltro}
            />
          </Col>
        </Row>
      </Panel>
    );
  }
}
