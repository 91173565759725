export const LEISATOS = 'LEISATOS';
export const LEISATOS_START = 'LEISATOS_START';
export const LEISATOS_SUCCESS = 'LEISATOS_SUCCESS';
export const LEISATOS_ERROR = 'LEISATOS_ERROR';

export const CONSULTA_LEISATOS = 'CONSULTA_LEISATOS';
export const CONSULTA_LEISATOS_START = 'CONSULTA_LEISATOS_START';
export const CONSULTA_LEISATOS_SUCCESS = 'CONSULTA_LEISATOS_SUCCESS';
export const CONSULTA_LEISATOS_ERROR = 'CONSULTA_LEISATOS_ERROR';

export const CLEAR_LEISATOS = 'CLEAR_LEISATOS';

export const LEISATOS_DETALHE = 'LEISATOS_DETALHE';
export const LEISATOS_DETALHE_START = 'LEISATOS_DETALHE_START';
export const LEISATOS_DETALHE_SUCCESS = 'LEISATOS_DETALHE_SUCCESS';
export const LEISATOS_DETALHE_ERROR = 'LEISATOS_DETALHE_ERROR';

export const CLEAR_DETALHE = 'CLEAR_DETALHE';

export const ARQUIVO_DOWNLOAD_LEIS = 'ARQUIVO_DOWNLOAD_LEIS';
export const ARQUIVO_DOWNLOAD_LEIS_START = 'ARQUIVO_DOWNLOAD_LEIS_START';
export const ARQUIVO_DOWNLOAD_LEIS_SUCCESS = 'ARQUIVO_DOWNLOAD_LEIS_SUCCESS';
export const ARQUIVO_DOWNLOAD_LEIS_ERROR = 'ARQUIVO_DOWNLOAD_LEIS_ERROR';

export const LEIS_ATOS_NATUREZA = 'LEIS_ATOS_NATUREZA';
export const LEIS_ATOS_NATUREZA_START = 'LEIS_ATOS_NATUREZA_START';
export const LEIS_ATOS_NATUREZA_SUCCESS = 'LEIS_ATOS_NATUREZA_SUCCESS';
export const LEIS_ATOS_NATUREZA_ERROR = 'LEIS_ATOS_NATUREZA_ERROR';

export const LIST_TIPOS_DOCUMENTO_LEGAL = 'LIST_TIPOS_DOCUMENTO_LEGAL';
export const LIST_TIPOS_DOCUMENTO_LEGAL_START =
  'LIST_TIPOS_DOCUMENTO_LEGAL_START';
export const LIST_TIPOS_DOCUMENTO_LEGAL_SUCCESS =
  'LIST_TIPOS_DOCUMENTO_LEGAL_SUCCESS';
export const LIST_TIPOS_DOCUMENTO_LEGAL_ERROR =
  'LIST_TIPOS_DOCUMENTO_LEGAL_ERROR';

export const LIST_TIPOS_ESCOPO = 'LIST_TIPOS_ESCOPO';
export const LIST_TIPOS_ESCOPO_START = 'LIST_TIPOS_ESCOPO_START';
export const LIST_TIPOS_ESCOPO_SUCCESS = 'LIST_TIPOS_ESCOPO_SUCCESS';
export const LIST_TIPOS_ESCOPO_ERROR = 'LIST_TIPOS_ESCOPO_ERROR';
