import Component from 'react-pure-render/component';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import PropTypes from 'prop-types';
import React from 'react';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import ExportButtonGroup from 'portaltransparencia-common/components/ExportButtonGroup.react.js';
import { Row } from 'react-bootstrap';
import configuration from 'portaltransparencia-common/config/Config.js';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';

export class EstatisticaList extends Component {
  static propTypes = {
    relatorioAnual: PropTypes.object.isRequired
  };

  onExporta = (exportType, openTab, windowParams) => {
    const { apiURL } = configuration;
    const { exercicio, entidade } = StorageService.getItem('userInfo');

    const filter = `exercicio=${exercicio?.id}&entidade=${entidade?.id}&exportType=${exportType}`;

    window.open(
      `${apiURL}/api/estatisticas/export-relatorio-anual?${filter}`,
      openTab,
      windowParams
    );
  };

  render() {
    const { relatorioAnual } = this.props;

    return (
      <Panel title="Relatório Anual de Pedidos" isTable collapsed>
        <Row>
          <ExportButtonGroup onClick={this.onExporta} visible={true} />
        </Row>
        <div className="panel-table-outer">
          <table className="panel-table striped fancy">
            <TableHeader>
              <tr>
                <th>Descrição</th>
                <th>Quantidade</th>
              </tr>
            </TableHeader>
            <tbody>
              {relatorioAnual?.map(e => {
                return (
                  <tr>
                    <td>{e.get('descricao')}</td>
                    <td>{e.get('quantidade')}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Panel>
    );
  }
}

export default EstatisticaList;
