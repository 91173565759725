import Component from 'react-pure-render/component';
import PropTypes from 'prop-types';
import React from 'react';

export default class ExportButton extends Component {
  static propTypes = {
    icon: PropTypes.string,
    label: PropTypes.string,
    onClick: PropTypes.func,
    exportType: PropTypes.string,
    visible: PropTypes.bool
  };

  static defaultProps = {
    visible: true
  };

  clickButton = () => {
    const { onClick, exportType } = this.props;

    onClick(exportType);
  };

  render() {
    const { icon, label, visible } = this.props;

    if (visible) {
      return (
        <button
          type="button"
          className="btn icon-left"
          onClick={this.clickButton}
        >
          <em className={icon}></em>
          {label}
        </button>
      );
    }

    return null;
  }
}
