import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';

import * as types from './Types.js';

const initialState = fromJS({
  autorizacao: {},
  arquivos: []
});

function autorizacaoReducer(state = initialState, action) {
  switch (action.type) {
    case types.AUTORIZACAO_ARQUIVOS_START:
    case types.AUTORIZACAO_START: {
      return startLoad(state);
    }

    case types.AUTORIZACAO_ARQUIVOS_ERROR:
    case types.AUTORIZACAO_ERROR: {
      return endLoad(state);
    }

    case types.AUTORIZACAO_SUCCESS: {
      return endLoad(state.set('autorizacao', fromJS(action.payload)));
    }
    case types.AUTORIZACAO_ARQUIVOS_SUCCESS: {
      return endLoad(state.set('arquivos', fromJS(action.payload)));
    }
  }

  return state;
}

export default autorizacaoReducer;
