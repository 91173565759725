import BotaoImprimir from 'portaltransparencia-common/components/BotaoImprimir.react.js';
import { getAllValuesFromUrlSearchParams } from 'portaltransparencia-common/libs/URLUtils';
import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import PageHeader from '../components/PageHeader.react.js';
import CabecalhoEntidade from '../entidades/CabecalhoEntidade.react.js';
import * as actions from './Actions.js';
import CargoDetalheInformacao from './CargoDetalheInformacao.react.js';
import CargoFaixasTable from './CargoFaixasTable.react.js';
import configuration from 'portaltransparencia-common/config/Config.js';
import ExportButtonGroup from 'portaltransparencia-common/components/ExportButtonGroup.react.js';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';

export class FormCargoDetail extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    findFaixas: PropTypes.func.isRequired,
    faixas: PropTypes.object.isRequired
  };

  componentDidMount() {
    const { location, findFaixas } = this.props;
    const params = getAllValuesFromUrlSearchParams(location);
    const filter = `entidade=${params.entidade}&cargo=${params.cargo}&quadroSalarial=${params.quadroSalarial}&grupoOcupacional=${params.grupoOcupacional}&nivel=${params.nivel}`;
    findFaixas(filter);
  }

  getCargo = faixa => {
    return {
      cargo: faixa.get('cargo'),
      vagasOcupadas: faixa.get('vagasOcupadas'),
      vagasTotal: faixa.get('vagasTotal'),
      descricao: faixa.get('descricao'),
      tipo: faixa.get('tipo'),
      quadroSalarial: faixa.get('quadroSalarialDesc'),
      grupoOcupacional: faixa.get('grupoOcupacionalDesc'),
      nivel: faixa.get('nivelDesc'),
      cargoSituacao: faixa.get('cargoSituacao'),
      descExtincao: faixa.get('descExtincao'),
      numExtincao: faixa.get('numExtincao'),
      anoExtincao: faixa.get('anoExtincao'),
      iniVigenciaExtincao: faixa.get('iniVigenciaExtincao')
    };
  };

  onExporta = (exportType, openTab, windowParams) => {
    const { apiURL } = configuration;
    const { entidade, exercicio } = StorageService.getItem('userInfo');
    const { faixas, addErrorNotification } = this.props;

    if (faixas.length > 0) {
      let filtro = this.montaFiltro(faixas) + '&exportType=' + exportType;
      let endPoint = 'cargos';

      window.open(
        `${apiURL}/api/${endPoint}/cargo-faixa/report?entidade=${entidade.id}&exercicio=${exercicio.id}&${filtro}`,
        openTab,
        windowParams
      );
    } else {
      addErrorNotification(
        'A consulta não apresentou resultado. Não é possível exportar para o formato selecionado!'
      );
    }
  };

  montaFiltro = faixas => {
    const cargo = this.getCargo(faixas.first());
    let filtro = 'cargo=' + cargo.cargo;
    return filtro;
  };

  render() {
    const { faixas } = this.props;

    if (faixas.isEmpty()) {
      return (
        <div>
          <Helmet title={'Cargo'} />

          <PageHeader
            location={this.props.location}
            textoDetalhe="Cargo sem faixas"
          />

          <div className="info">Cargo sem faixas cadastradas.</div>
        </div>
      );
    }

    const cargo = this.getCargo(faixas.first());

    return (
      <div>
        <Helmet title={`Cargo - #${cargo.nivel}`} />

        <PageHeader
          location={this.props.location}
          textoDetalhe={`${cargo.nivel}`}
        />

        <BotaoImprimir />

        <CabecalhoEntidade />

        <CargoDetalheInformacao cargo={cargo} />

        <ExportButtonGroup onClick={this.onExporta} visible={true} />

        <CargoFaixasTable faixas={faixas} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    faixas: state.cargo.get('faixas')
  };
}

export default connect(mapStateToProps, actions)(FormCargoDetail);
