import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';

import * as types from './Types.js';

const initialState = fromJS({
  afastamentos: [],
  tiposAfastamento: []
});

function afastamentoReducer(state = initialState, action) {
  switch (action.type) {
    case types.AFASTAMENTOS_LIST_START: {
      return startLoad(state);
    }

    case types.AFASTAMENTOS_LIST_SUCCESS: {
      return endLoad(state.set('afastamentos', fromJS(action.payload)));
    }

    case types.TIPOSAFASTAMENTO_LIST_SUCCESS: {
      return endLoad(state.set('tiposAfastamento', fromJS(action.payload)));
    }
  }

  return state;
}

export default afastamentoReducer;
