import {
  acExercicioLogado,
  findExerciciosByEntidade,
  loadExercicioPadrao
} from './entidades/Actions.js';

import AuthService from 'portaltransparencia-common/auth/AuthService.js';
import { fetchInfo } from 'portaltransparencia-common/info/Actions.js';
import { extractEntidadeFromPath } from './utils/BaseHrefUtils.js';

/* eslint-disable no-console */
export const fetchInitialState = async store => {
  console.log('Fetch initial state');

  const userInfo = AuthService.getUserInfo();

  console.log('Fetch Actuator Info');
  await store.dispatch(fetchInfo());

  console.log('Resolve entidade from URL e PATH');
  const entidadeFromURL = resolveEntidadeFromUrl();

  if (entidadeFromURL) {
    console.log(`FETCH INITIAL STATE FROM URL: ENTIDADE:${entidadeFromURL}`);

    const { value } = await store.dispatch(
      findExerciciosByEntidade(entidadeFromURL)
    );

    if (value && value.length > 0) {
      if (userInfo) {
        const { exercicio } = userInfo;
        await store.dispatch(acExercicioLogado(entidadeFromURL, exercicio.id));
      } else {
        await store.dispatch(
          acExercicioLogado(entidadeFromURL, value[0].id.exercicio)
        );
      }

      return;
    }
    console.log(
      `FETCH INITIAL STATE FROM URL: ENTIDADE ${entidadeFromURL} NÃO POSSUÍ EXERCÍCIO`
    );
  }
  if (userInfo) {
    const { entidade, exercicio } = userInfo;

    console.log(
      `FETCH INITIAL STATE FROM STORAGE: ENTIDADE:${entidade.id} EXERCICIO:${exercicio.id}`
    );

    await store.dispatch(acExercicioLogado(entidade.id, exercicio.id));
  } else {
    console.log('FETCH ENTIDADE e EXERCICIO PADRÃO');
    await store.dispatch(loadExercicioPadrao());
    console.info('Recarregando a página');
    window.location.reload();
  }
};

export const resolveEntidade = () => {
  const entidadeId = resolveEntidadeFromUrl();
  if (!entidadeId) {
    const userInfo = AuthService.getUserInfo();
    if (userInfo && userInfo.entidade) {
      console.info(
        'Entidade resolvida from Local Storage: ',
        userInfo.entidade.id
      );
      return userInfo.entidade.id;
    }
  }
  return entidadeId;
};

function resolveEntidadeFromUrl() {
  var idEntidade = extractEntidadeFromPath(window.location.pathname);

  if (idEntidade) {
    console.info('Entidade resolvida from pathname: ', idEntidade);
    return idEntidade;
  }

  var query = window.location.search.substring(1);

  var params = query.split('&');

  for (var i = 0; i < params.length; i++) {
    var parametro = params[i].split('=');
    const parametroNome = parametro[0];
    const parametroValor = parametro[1];

    if (parametroNome == 'entidade') {
      console.info('Entidade resolvida from query params: ', parametroValor);
      return parametroValor;
    }
  }
  return false;
}
/* eslint-enable no-console */
