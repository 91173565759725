import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import NumberUtils from 'portaltransparencia-common/libs/NumberUtils';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import { downloadArquivo } from '../arquivo/Actions.js';
import * as actions from './Actions.js';

export class EmpenhoDetalheAnexos extends Component {
  static propTypes = {
    anexos: PropTypes.object.isRequired,
    downloadArquivo: PropTypes.func.isRequired
  };

  renderItens = data => {
    let key = data.get('idArquivo') + '_' + data.get('numero');
    const tamanho = NumberUtils.formatBytes(data.get('tamanhoArquivo'));

    return (
      <TableActions
        ref="row"
        key={key}
        data={data.get('idArquivo')}
        onDownload={downloadArquivo}
      >
        <td id="tipoAnexo">{data.get('tipoAnexo')}</td>
        <td id="noAno">{data.get('noAno')}</td>
        <td id="dataArquivo">
          {DateUtils.formatDateTimeShort(data.get('dataArquivo'))}
        </td>
        <td id="numero">{data.get('numero')}</td>
        <td id="nomeArquivo">{`${data.get('nomeArquivo')} ${tamanho}`}</td>
      </TableActions>
    );
  };

  render() {
    const { anexos } = this.props;

    return (
      <div className="panel-table-outer">
        <table className="panel-table striped fancy">
          <TableHeader empty={anexos.isEmpty()}>
            <tr>
              <th>Tipo Anexo</th>
              <th>Nº/Ano</th>
              <th>Data Arquivo</th>
              <th>Número</th>
              <th>Nome Arquivo</th>
              <th></th>
            </tr>
          </TableHeader>
          <tbody>{anexos.map(this.renderItens)}</tbody>
        </table>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    anexos: state.empenhos.get('anexos')
  };
}

const allActions = {
  ...actions,
  downloadArquivo
};

export default connect(mapStateToProps, allActions)(EmpenhoDetalheAnexos);
