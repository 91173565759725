import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export class Veiculo extends Component {
  static propTypes = {
    veiculo: PropTypes.object.isRequired,
    paramGastosVeiculo: PropTypes.string,
    mostraEntidade: PropTypes.bool
  };

  onViewDetalhes = item => {
    const idVeiculo = item.get('veiculo')
      ? item.get('veiculo')
      : item.get('veiculoOxy');

    const entidadeFiltro = item.get('entidade')
      ? `entidade=${item.get('entidade')}&`
      : '';

    this.props.history.push(
      `/veiculos/detalhes?${entidadeFiltro}veiculo=${idVeiculo}&chapa=${item.get(
        'chapa'
      )}`
    );
  };

  render() {
    const { veiculo, paramGastosVeiculo, mostraEntidade } = this.props;
    return (
      <TableActions
        ref="row"
        key={veiculo.get('veiculo')}
        data={veiculo}
        onMore={
          (paramGastosVeiculo == '1' || paramGastosVeiculo == '2') &&
          this.onViewDetalhes
        }
      >
        <td>{veiculo.get('descricao')}</td>
        <td>{veiculo.get('placa')}</td>
        <td>{veiculo.getIn(['bem', 'numeroFrota'])}</td>
        <td>{veiculo.get('chassi')}</td>
        <td>{veiculo.get('renavan')}</td>
        <td>
          {DateUtils.formatDateTimeShort(
            veiculo.getIn(['bem', 'dataAquisicao'])
          )}
        </td>
        <td>{veiculo.get('centroCusto')}</td>
        <td>{veiculo.getIn(['bem', 'baixado'])}</td>
        {mostraEntidade && <td>{veiculo.get('entidadeNome')}</td>}
      </TableActions>
    );
  }
}

export default Veiculo;
