export const FILAS_ESPERA_LIST = 'FILAS_ESPERA_LIST';
export const FILAS_ESPERA_LIST_START = 'FILAS_ESPERA_LIST_START';
export const FILAS_ESPERA_LIST_SUCCESS = 'FILAS_ESPERA_LIST_SUCCESS';
export const FILAS_ESPERA_LIST_ERROR = 'FILAS_ESPERA_LIST_ERROR';

export const PACIENTES_LIST = 'PACIENTES_LIST';
export const PACIENTES_LIST_START = 'PACIENTES_LIST_START';
export const PACIENTES_LIST_SUCCESS = 'PACIENTES_LIST_SUCCESS';
export const PACIENTES_LIST_ERROR = 'PACIENTES_LIST_ERROR';

export const CLEAR_REGISTROS = 'CLEAR_REGISTROS';
