import * as types from './Types.js';

export function findRecurso(id) {
  return ({ fetch }) => ({
    type: types.RECURSO,
    payload: {
      promise: fetch(`/api/recursos/detalhes?uuid=${id}`)
    }
  });
}

export function findArquivos(id) {
  return ({ fetch }) => ({
    type: types.RECURSO_ARQUIVOS,
    payload: {
      promise: fetch(`/api/recursos/arquivos?uuid=${id}`)
    }
  });
}
