export const FILTRO_ESTRUTURAS = 'FILTRO_ESTRUTURAS';
export const FILTRO_ESTRUTURAS_START = 'FILTRO_ESTRUTURAS_START';
export const FILTRO_ESTRUTURAS_SUCCESS = 'FILTRO_ESTRUTURAS_SUCCESS';
export const FILTRO_ESTRUTURAS_ERROR = 'FILTRO_ESTRUTURAS_ERROR';

export const LIST_ESTRUTURAS = 'LIST_ESTRUTURAS';
export const LIST_ESTRUTURAS_START = 'LIST_ESTRUTURAS_START';
export const LIST_ESTRUTURAS_SUCCESS = 'LIST_ESTRUTURAS_SUCCESS';
export const LIST_ESTRUTURAS_ERROR = 'LIST_ESTRUTURAS_ERROR';

export const LIST_SERVIDORES = 'LIST_SERVIDORES';
export const LIST_SERVIDORES_START = 'LIST_SERVIDORES_START';
export const LLIST_SERVIDORES_SUCCESS = 'LIST_SERVIDORES_SUCCESS';
export const LIST_SERVIDORES_ERROR = 'LIST_SERVIDORES_ERROR';
