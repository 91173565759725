import { Map, fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';

import * as types from './Types.js';

const initialState = fromJS({
  centrosCusto: [],
  itensEstoque: [],
  itensConsumo: Map(),
  valorTotal: 0,
  movimentacoes: [],
  orgao: []
});

function almoxarifadoReducer(state = initialState, action) {
  switch (action.type) {
    case types.RESET_ITENS: {
      return state.clear().merge(initialState);
    }

    case types.PESQUISA_ITEMESTOQUE_START:
    case types.PESQUISA_MOVIMENTACAO_START:
    case types.CENTRO_CUSTO_START:
    case types.ALMOXARIFADO_ORGAO_START:
    case types.PESQUISA_ITEMCONSUMO_START: {
      return startLoad(state);
    }

    case types.PESQUISA_ITEMCONSUMO_TOTAL_START: {
      return startLoad(state);
    }

    case types.PESQUISA_ITEMESTOQUE_ERROR:
    case types.PESQUISA_MOVIMENTACAO_ERROR:
    case types.CENTRO_CUSTO_ERROR:
    case types.ALMOXARIFADO_ORGAO_ERROR:
    case types.PESQUISA_ITEMCONSUMO_TOTAL_ERROR: {
      return endLoad(state);
    }
    case types.PESQUISA_ITEMCONSUMO_ERROR: {
      return endLoad(state);
    }

    case types.CENTRO_CUSTO_SUCCESS: {
      return endLoad(state.set('centrosCusto', fromJS(action.payload)));
    }

    case types.ALMOXARIFADO_ORGAO_SUCCESS: {
      return endLoad(state.set('orgao', fromJS(action.payload)));
    }

    case types.PESQUISA_ITEMESTOQUE_SUCCESS: {
      return endLoad(state.set('itensEstoque', fromJS(action.payload)));
    }

    case types.PESQUISA_ITEMCONSUMO_SUCCESS: {
      return endLoad(state.set('itensConsumo', fromJS(action.payload)));
    }

    case types.PESQUISA_ITEMCONSUMO_TOTAL_SUCCESS: {
      return endLoad(state.set('valorTotal', fromJS(action.payload)));
    }

    case types.PESQUISA_MOVIMENTACAO_SUCCESS: {
      return endLoad(state.set('movimentacoes', fromJS(action.payload)));
    }
  }

  return state;
}

export default almoxarifadoReducer;
