export const TIPO_REGISTRO_CONVENIO = {
  CONVENIO: 1,
  SUBVENCAO: 2
};

export const tipoRegistroConvenioArray = [
  { valor: TIPO_REGISTRO_CONVENIO.CONVENIO, nome: 'Convênio' },
  { valor: TIPO_REGISTRO_CONVENIO.SUBVENCAO, nome: 'Subvenção' }
];

export const getTipoRegistroConvenio = valor => {
  return tipoRegistroConvenioArray.find(item => item.valor === valor)?.nome;
};
