import DisplayData from 'portaltransparencia-common/components/displaydata/DisplayData.react.js';
import DisplayDataItem from 'portaltransparencia-common/components/displaydata/DisplayDataItem.react.js';
import DisplayDataRow from 'portaltransparencia-common/components/displaydata/DisplayDataRow.react.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export default class EmpenhoDetalheDespesa extends Component {
  static propTypes = {
    empenho: PropTypes.object.isRequired
  };

  render() {
    const { empenho } = this.props;

    return (
      <DisplayData border>
        <DisplayDataRow>
          <DisplayDataItem
            sm={12}
            title="Órgão"
            value={empenho.get('orgao')}
            value2={empenho.get('descricaoOrgao')}
          />
        </DisplayDataRow>
        <DisplayDataRow>
          <DisplayDataItem
            sm={12}
            title="Unidade"
            value={empenho.get('unidade')}
            value2={empenho.get('descricaoUnidade')}
          />
        </DisplayDataRow>
        <DisplayDataRow>
          <DisplayDataItem
            sm={12}
            title="Função"
            value={empenho.get('funcao')}
            value2={empenho.get('descricaoFuncao')}
          />
        </DisplayDataRow>
        <DisplayDataRow>
          <DisplayDataItem
            sm={12}
            title="SubFunção"
            value={empenho.get('subFuncao')}
            value2={empenho.get('descricaoSubFuncao')}
          />
        </DisplayDataRow>
        <DisplayDataRow>
          <DisplayDataItem
            sm={12}
            title="Programa"
            value={empenho.get('programa')}
            value2={empenho.get('descricaoPrograma')}
          />
        </DisplayDataRow>
        <DisplayDataRow>
          <DisplayDataItem
            sm={12}
            title="Projeto Atividade"
            value={empenho.get('projeto')}
            value2={empenho.get('descricaoProjeto')}
          />
        </DisplayDataRow>
        <DisplayDataRow>
          <DisplayDataItem
            sm={12}
            title="Elemento"
            value={empenho.get('elemento')}
            value2={empenho.get('descricaoElemento')}
          />
        </DisplayDataRow>
        <DisplayDataRow>
          <DisplayDataItem
            sm={12}
            title="Desdobramento"
            value={empenho.get('desdobraDesp')}
            value2={empenho.get('descricaoDesdobramento')}
          />
        </DisplayDataRow>
        <DisplayDataRow>
          <DisplayDataItem
            sm={12}
            title="SubDesdobramento"
            value={empenho.get('subDesdobramento')}
            value2={empenho.get('descricaoSubDesdobramento')}
          />
        </DisplayDataRow>
      </DisplayData>
    );
  }
}
