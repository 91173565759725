import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react.js';
import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

import { getLabelByTipo } from './TipoBemUtils';
import { Ellipsis } from 'portaltransparencia-common/components/Ellipsis.react';

export default class PatrimonioRow extends Component {
  static propTypes = {
    patrimonio: PropTypes.object.isRequired
  };

  onViewDetalhes = item => {
    const entidade = item.get('entidade');
    const tipo = item.get('tipo');
    const chapa = item.get('chapa');

    const bemValue = item.get('bem') ? item.get('bem') : item.get('idOxy');

    this.props.history.push(
      `patrimonio/detalhes?entidade=${entidade}&tipo=${tipo}&chapa=${chapa}&bem=${bemValue}`
    );
  };

  tipo(patrimonio) {
    let tipoFormatado = getLabelByTipo(patrimonio.get('tipo'));
    return tipoFormatado ? tipoFormatado : patrimonio.get('tipo');
  }

  render() {
    const { patrimonio } = this.props;
    const key =
      patrimonio.get('entidade') +
      '_' +
      patrimonio.get('tipo') +
      '_' +
      patrimonio.get('bem') +
      '_' +
      patrimonio.get('chapa');

    return (
      <TableActions
        ref="row"
        key={key}
        data={patrimonio}
        onMore={this.onViewDetalhes}
      >
        <td>{patrimonio.get('chapa')}</td>
        <td id="tipo">{this.tipo(patrimonio)}</td>
        <td>
          <Ellipsis value={patrimonio.get('descricao')} maxLength={150} />
        </td>
        <td>
          {DateUtils.formatDateTimeShort(patrimonio.get('dataAquisicao'))}
        </td>
        <td>{patrimonio.get('descTipoIncoporacao')}</td>
        <td className="right">
          <NumberFormatter value={patrimonio.get('valorAtual')} />
        </td>
        <td className="right">
          <NumberFormatter value={patrimonio.get('valorAquisicao')} />
        </td>
        <td>{patrimonio.get('baixado')}</td>
      </TableActions>
    );
  }
}
