import Panel from 'portaltransparencia-common/components/Panel.react.js';
import {
  addFilterUrl,
  addPagination
} from 'portaltransparencia-common/libs/AddFilter';
import {
  onChange,
  resetCrud
} from 'portaltransparencia-common/crud/Actions.js';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';
import PageHeader from '../components/PageHeader.react.js';
import NotasExplicativas from '../notaexplicativa/Form.react.js';
import * as actions from './Actions.js';
import { downloadArquivo } from '../arquivo/Actions.js';
import { changeFieldValue } from 'portaltransparencia-common/crud/Actions.js';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import Fields from 'portaltransparencia-common/libs/Fields.js';
import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import TableHeaderItem from 'portaltransparencia-common/components/TableHeaderItem.react';
import configuration from 'portaltransparencia-common/config/Config.js';
import Input from 'portaltransparencia-common/components/EloInput.react.js';
import FilterButtons from '../components/FilterButtons.react.js';
import UltimaAtualizacao from '../ultimaatualizacao/Form.react.js';
import ExportButtonGroup from 'portaltransparencia-common/components/ExportButtonGroup.react.js';
import SearchPagination from 'portaltransparencia-common/components/SearchPagination.react.js';
import { Map } from 'immutable';
import DatePicker from 'portaltransparencia-common/components/DatePicker.react.js';
import { addErrorNotification } from 'portaltransparencia-common/ui/Actions.js';

export class FormTerceirizados extends Component {
  static propTypes = {
    terceirizados: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    page: PropTypes.object.isRequired,
    searchTerceirizados: PropTypes.func.isRequired,
    changeFieldValue: PropTypes.func.isRequired,
    exportaEmenda: PropTypes.func,
    filtros: PropTypes.object,
    onChange: PropTypes.func,
    resetCrud: PropTypes.func.isRequired,
    downloadArquivo: PropTypes.func.isRequired,
    addErrorNotification: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.onResetFiltro('filtroTerceirizados');
  }

  onChangeFiltro = event => {
    const { onChange } = this.props;

    onChange('filtroTerceirizados', event);
  };

  onResetFiltro = () => {
    const { resetCrud, searchTerceirizados } = this.props;
    resetCrud('filtroTerceirizados');
    let userInfo = StorageService.getItem('userInfo');
    const { entidade, exercicio } = userInfo;

    let filter = `${`entidade=${entidade.id}&exercicio=${exercicio.id}`}`;

    searchTerceirizados(filter);
  };

  montaFilter = function (page) {
    const { entidade, exercicio } = StorageService.getItem('userInfo');

    let filter = `${`entidade=${entidade.id}&exercicio=${exercicio.id}`}`;

    if (this.refs.cnpj && this.refs.cnpj.getValue()) {
      filter = addFilterUrl(filter, 'cnpj=' + this.refs.cnpj.getValue());
    }
    if (this.refs.trabalhador && this.refs.trabalhador.getValue()) {
      filter = addFilterUrl(
        filter,
        'trabalhador=' + this.refs.trabalhador.getValue()
      );
    }
    if (this.refs.empresa && this.refs.empresa.getValue()) {
      filter = addFilterUrl(filter, 'empresa=' + this.refs.empresa.getValue());
    }
    if (this.refs.cargo && this.refs.cargo.getValue()) {
      filter = addFilterUrl(filter, 'cargo=' + this.refs.cargo.getValue());
    }
    if (this.refs.lotacao && this.refs.lotacao.getValue()) {
      filter = addFilterUrl(filter, 'lotacao=' + this.refs.lotacao.getValue());
    }
    if (this.refs.dataInicial && this.refs.dataInicial.getValue()) {
      filter = addFilterUrl(
        filter,
        'dataInicial=' + this.refs.dataInicial.getValue()
      );
    }
    if (this.refs.dataFinal && this.refs.dataFinal.getValue()) {
      filter = addFilterUrl(
        filter,
        'dataFinal=' + this.refs.dataFinal.getValue()
      );
    }

    return addPagination(page, filter);
  };

  onConsultaTerceirizados = event => {
    const { page } = this.props;

    const initialPage = page.set('number', 0);

    this.searchWithPage(initialPage);

    event && event.preventDefault();
    this.refs.panelFiltroTerceirizados &&
      this.refs.panelFiltroTerceirizados.collapse();
  };

  searchWithPage = page => {
    const { searchTerceirizados } = this.props;

    let filter = this.montaFilter(page);

    searchTerceirizados(filter);
  };

  onChange = event => {
    const { changeFieldValue } = this.props;

    Fields.fireChange(changeFieldValue, event);

    this.onChangeFiltro(event);
  };

  renderTerceirizados = data => {
    const key = `${data.get('entidade')}_${data.get('exercicio')}_${data.get(
      'id'
    )}}`;

    return (
      <TableActions ref="row" key={key} data={data}>
        <td>{data.get('nome')}</td>
        <td>{data.get('empresaTerceirizada').get('fornecedor').get('nome')}</td>
        <td>{data.get('empresaTerceirizada').get('fornecedor').get('cnpj')}</td>
        <td>{data.get('cargo')}</td>
        <td>{data.get('lotacao')}</td>
      </TableActions>
    );
  };

  onExporta = (exportType, openTab, windowParams) => {
    const { page, addErrorNotification } = this.props;
    const { apiURL } = configuration;

    if (page.get('content').size > 0) {
      const { exercicio } = StorageService.getItem('userInfo');

      let filtro =
        this.montaFilter(page) +
        '&exportType=' +
        exportType +
        '&exercicio=' +
        exercicio.id;

      window.open(
        `${apiURL}/empresa-terceirizada/report?${filtro}`,
        openTab,
        windowParams
      );
    } else {
      addErrorNotification(
        'A consulta não apresentou resultado. Não é possível exportar para o formato selecionado!'
      );
    }
  };

  render() {
    const { page, filtros } = this.props;

    if (!page.has('content')) {
      return null;
    }

    return (
      <section>
        <PageHeader location={this.props.location} />

        <NotasExplicativas pathname={this.props.location.pathname} />

        <Panel
          title="Consultar em Terceirizados"
          isForm
          mobileClose
          ref="panelFiltroTerceirizados"
        >
          <form>
            <Row>
              <Col xs={12} sm={12} md={3}>
                <DatePicker
                  id="dataInicial"
                  name="dataInicial"
                  ref="dataInicial"
                  label="Data Inicial"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('dataInicial')}
                />
              </Col>
              <Col xs={12} sm={12} md={3}>
                <DatePicker
                  id="dataFinal"
                  name="dataFinal"
                  ref="dataFinal"
                  label="Data Final"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('dataFinal')}
                />
              </Col>
              <Col xs={12} sm={4} md={3}>
                <Input
                  id="cnpj"
                  name="cnpj"
                  ref="cnpj"
                  placeholder="CNPJ"
                  label="CNPJ"
                  type="text"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('cnpj')}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={4} md={3}>
                <Input
                  id="empresa"
                  name="empresa"
                  ref="empresa"
                  placeholder="Empresa"
                  label="Empresa"
                  type="text"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('empresa')}
                />
              </Col>
              <Col xs={6} sm={4} md={3}>
                <Input
                  id="trabalhador"
                  name="trabalhador"
                  ref="trabalhador"
                  placeholder="Trabalhador"
                  label="Trabalhador"
                  type="text"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('trabalhador')}
                />
              </Col>
              <Col xs={6} sm={4} md={3}>
                <Input
                  id="cargo"
                  name="cargo"
                  ref="cargo"
                  placeholder="Cargo/Atividade"
                  label="Cargo/Atividade"
                  type="text"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('cargo')}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={3}>
                <Input
                  id="lotacao"
                  name="lotacao"
                  ref="lotacao"
                  placeholder="Lotação"
                  label="Lotação"
                  type="text"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('lotacao')}
                />
              </Col>
              <Col xs={12} sm={4} md={3}>
                <FilterButtons
                  idBtnReset="btnResetFilter"
                  onConsulta={this.onConsultaTerceirizados}
                  onReset={this.onResetFiltro}
                />
              </Col>
            </Row>
          </form>
        </Panel>

        <Panel isForm expansible={false} tableResponsive>
          <Row>
            <Col xs={4}>
              <UltimaAtualizacao modulo="3" tipo="EMPRESATERCEIRIZADA" />
            </Col>
            <Col xs={8}>
              <ExportButtonGroup onClick={this.onExporta} visible={true} />
            </Col>
          </Row>

          <div className="panel-table-outer">
            <table className="panel-table striped fancy">
              <TableHeader
                text="A entidade não possui terceirizados cadastrados."
                empty={page.get('content').isEmpty()}
              >
                <tr>
                  <TableHeaderItem
                    field="nome"
                    label="Trabalhador"
                    page={page}
                    searchWithPage={this.searchWithPage}
                  />
                  <TableHeaderItem
                    field="idempresaTerceirizada"
                    label="Empresa"
                    page={page}
                    searchWithPage={this.searchWithPage}
                  />
                  <TableHeaderItem
                    field="cnpj"
                    label="CNPJ"
                    page={page}
                    searchWithPage={this.searchWithPage}
                  />
                  <TableHeaderItem
                    field="cargo"
                    label="Cargo/Atividade"
                    page={page}
                    searchWithPage={this.searchWithPage}
                  />
                  <TableHeaderItem
                    field="lotacao"
                    label="Lotação"
                    page={page}
                    searchWithPage={this.searchWithPage}
                  />
                  <th />
                </tr>
              </TableHeader>
              <tbody>{page.get('content').map(this.renderTerceirizados)}</tbody>
            </table>
          </div>
        </Panel>

        <SearchPagination page={page} searchWithPage={this.searchWithPage} />
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    terceirizados: state.terceirizados.get('terceirizados'),
    page: state.terceirizados.get('terceirizados'),
    filtros: state.crud.getIn(['filtroTerceirizados', 'currentRecord'], Map({}))
  };
}

const allActions = {
  ...actions,
  changeFieldValue,
  onChange,
  resetCrud,
  downloadArquivo,
  addErrorNotification
};

export default connect(mapStateToProps, allActions)(FormTerceirizados);
