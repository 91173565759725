import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';
import * as types from './Types.js';

const initialState = fromJS({
  acordos: [],
  acordo: []
});

function acordoSemRecursoReducer(state = initialState, action) {
  switch (action.type) {
    case types.ACORDOS_START:
    case types.CONSULTA_ACORDOS_START:
    case types.ACORDO_SEM_RECURSO_START: {
      return startLoad(state);
    }

    case types.ACORDOS_ERROR:
    case types.CONSULTA_ACORDOS_ERROR:
    case types.ACORDO_SEM_RECURSO_ERROR: {
      return endLoad(state);
    }

    case types.ACORDOS_SUCCESS:
    case types.CONSULTA_ACORDOS_SUCCESS: {
      return endLoad(state.set('acordos', fromJS(action.payload)));
    }

    case types.ACORDO_SEM_RECURSO_SUCCESS: {
      return endLoad(state.set('acordo', fromJS(action.payload)));
    }

    case types.RESET_CONSULTA_ACORDOS: {
      return initialState;
    }
  }

  return state;
}

export default acordoSemRecursoReducer;
