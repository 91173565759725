import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import PageHeader from '../components/PageHeader.react.js';
import NotasExplicativas from '../notaexplicativa/Form.react.js';
import UltimaAtualizacaoByTable from '../ultimaatualizacao/UltimaAtualizacaoByTable.react.js';
import * as actions from './Actions.js';
import configuration from 'portaltransparencia-common/config/Config.js';
import ExportButtonGroup from 'portaltransparencia-common/components/ExportButtonGroup.react.js';
import { addErrorNotification } from 'portaltransparencia-common/ui/Actions.js';

export class FormReceitaPorFonte extends Component {
  static propTypes = {
    fontesRecursos: PropTypes.object.isRequired,
    findReceitasFonteRecursos: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    addErrorNotification: PropTypes.func.isRequired
  };

  componentDidMount() {
    const { findReceitasFonteRecursos } = this.props;

    findReceitasFonteRecursos(this.montarFiltro());
  }

  onViewMore = data => {
    const { entidade, exercicio } = StorageService.getItem('userInfo');

    this.props.history.push(
      `/receita-fonte-recursos/detalhes?entidade=${entidade.id}&exercicio=${
        exercicio.id
      }&fonteRecurso=${data.get('receita')}`
    );
  };

  renderFontes = data => {
    return (
      <TableActions
        ref="row"
        key={data.get('receita')}
        data={data}
        onMore={this.onViewMore}
      >
        <td>{data.get('receita')}</td>
        <td>{data.get('descricao')}</td>
        <td className="right">
          <NumberFormatter value={data.get('valorOrcado')} />
        </td>
        <td className="right">
          <NumberFormatter value={data.get('valorAtualizado')} />
        </td>
        <td className="right">
          <NumberFormatter value={data.get('valorArrecadado')} />
        </td>
      </TableActions>
    );
  };

  onExporta = (exportType, openTab, windowParams) => {
    const { apiURL } = configuration;
    const { fontesRecursos, addErrorNotification } = this.props;

    if (!fontesRecursos.isEmpty()) {
      let filtro = this.montarFiltro() + '&exportType=' + exportType;

      window.open(
        `${apiURL}/api/receitas/fonte-recursos/report?${filtro}`,
        openTab,
        windowParams
      );
    } else {
      addErrorNotification(
        'A consulta não apresentou resultado. Não é possível exportar para o formato selecionado!'
      );
    }
  };

  montarFiltro = () => {
    const { entidade, exercicio } = StorageService.getItem('userInfo');

    return `entidade=${entidade.id}&exercicio=${exercicio.id}`;
  };

  render() {
    const { fontesRecursos, location } = this.props;

    return (
      <section>
        <PageHeader location={location} />

        <NotasExplicativas pathname={this.props.location.pathname} />

        {fontesRecursos && (
          <Panel isForm tableResponsive expansible={false}>
            <Row>
              <Col xs={6}>
                <UltimaAtualizacaoByTable
                  modulo="3"
                  table="receita"
                  column="dataultimaatualizacao"
                />
              </Col>
              <Col xs={6}>
                <ExportButtonGroup onClick={this.onExporta} />
              </Col>
              <Col xs={6} />
            </Row>

            <div className="panel-table-outer">
              <table className="panel-table striped fancy">
                <TableHeader
                  empty={fontesRecursos.isEmpty()}
                  text="Sem registros"
                >
                  <tr>
                    <th>Fonte Recursos</th>
                    <th>Descrição</th>
                    <th>Valor Orçado</th>
                    <th>Valor Atualizado</th>
                    <th>Valor Arrecadado</th>
                    <th />
                  </tr>
                </TableHeader>
                <tbody>{fontesRecursos.map(this.renderFontes)}</tbody>
              </table>
            </div>
          </Panel>
        )}
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    fontesRecursos: state.receita.get('fontesRecursos')
  };
}

const allActions = {
  ...actions,
  addErrorNotification
};

export default connect(mapStateToProps, allActions)(FormReceitaPorFonte);
