export const DESPESAPORNIVEL = 'DESPESAPORNIVEL';
export const DESPESAPORNIVEL_START = 'DESPESAPORNIVEL_START';
export const DESPESAPORNIVEL_SUCCESS = 'DESPESAPORNIVEL_SUCCESS';
export const DESPESAPORNIVEL_ERROR = 'DESPESAPORNIVEL_ERROR';

export const DESPESA_POR_FONTE_RECURSO = 'DESPESA_POR_FONTE_RECURSO';
export const DESPESA_POR_FONTE_RECURSO_START =
  'DESPESA_POR_FONTE_RECURSO_START';
export const DESPESA_POR_FONTE_RECURSO_SUCCESS =
  'DESPESA_POR_FONTE_RECURSO_SUCCESS';
export const DESPESA_POR_FONTE_RECURSO_ERROR =
  'DESPESA_POR_FONTE_RECURSO_ERROR';

export const DESPESA_POR_ESFERA_ADMINISTRATIVA =
  'DESPESA_POR_ESFERA_ADMINISTRATIVA';
export const DESPESA_POR_ESFERA_ADMINISTRATIVA_START =
  'DESPESA_POR_ESFERA_ADMINISTRATIVA_START';
export const DESPESA_POR_ESFERA_ADMINISTRATIVA_SUCCESS =
  'DESPESA_POR_ESFERA_ADMINISTRATIVA_SUCCESS';
export const DESPESA_POR_ESFERA_ADMINISTRATIVA_ERROR =
  'DESPESA_POR_ESFERA_ADMINISTRATIVA_ERROR';

export const CHANGE_TIPO_DESPESA = 'CHANGE_TIPO_DESPESA';
