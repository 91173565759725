import Hint from 'portaltransparencia-common/components/hint/Hint.react';
import Widget from 'portaltransparencia-common/components/Widget.react.js';
import { format } from 'portaltransparencia-common/libs/NumberUtils';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';

export class DespesaPagaAno extends Component {
  static propTypes = {
    valorTotalArrecadado: PropTypes.number.isRequired,
    valorTotalPago: PropTypes.number.isRequired,
    exercicio: PropTypes.any.isRequired
  };

  render() {
    const { valorTotalArrecadado, valorTotalPago, exercicio } = this.props;

    let percentualArrecadadoSobrePago = 0;
    if (valorTotalArrecadado > 0) {
      percentualArrecadadoSobrePago =
        100 - (valorTotalPago * 100) / valorTotalArrecadado;
    }

    return (
      <Widget
        icon="fa fa-area-chart"
        isChart
        title={`Valores de arrecadação e despesa paga no ano de ${exercicio}`}
      >
        <Row>
          <Col sm={4} md={4}>
            <article className="widget-numeric block">
              <b className="widget-numeric-number">
                {format(valorTotalArrecadado)}
              </b>
              <p className="widget-numeric-subtitle">VALOR ARRECADADO</p>
            </article>
          </Col>
          <Col sm={4} md={4}>
            <article className="widget-numeric block">
              <b className="widget-numeric-number">{format(valorTotalPago)}</b>
              <p className="widget-numeric-subtitle">VALOR PAGO</p>
            </article>
          </Col>
          <Col sm={4} md={4}>
            <article className="widget-numeric block">
              <b className="widget-numeric-number">
                {format(percentualArrecadadoSobrePago) + '%'}
              </b>
              <div className="widget-numeric-subtitle">
                PERCENTUAL ARRECADADO SOBRE PAGO
                <Hint
                  message={`Porcentagem da soma dos valores arrecadados sobre os valores pagos durante o ano de ${exercicio}.`}
                  size="xs"
                  isInline
                />
              </div>
            </article>
          </Col>
        </Row>
      </Widget>
    );
  }
}

export default DespesaPagaAno;
