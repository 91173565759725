import * as types from './Types.js';

export function findMedidasAdotadasParaCobranca(entidade, exercicio) {
  return ({ fetch }) => ({
    type: types.MEDIDAS_ADOTADAS_COBRANCA,
    payload: {
      promise: fetch(
        `/parametros/medidas-adotadas-cobranca/${entidade}/${exercicio}`
      )
    }
  });
}
