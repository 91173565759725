import {
  Button,
  DisplayDataItem as DisplayDataItemElo
} from '@elotech/components';
import DisplayData from 'portaltransparencia-common/components/displaydata/DisplayData.react.js';
import DisplayDataItem from 'portaltransparencia-common/components/displaydata/DisplayDataItem.react.js';
import DisplayDataRow from 'portaltransparencia-common/components/displaydata/DisplayDataRow.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import MaskUtils from 'portaltransparencia-common/libs/MaskUtils.js';
import NumberUtils from 'portaltransparencia-common/libs/NumberUtils.js';
import React from 'react';
import { useHistory } from 'react-router';

const ContratoDetalheInformacao = ({ contrato }) => {
  const history = useHistory();

  const getLicitacao = () => {
    return (
      <>
        {contrato.get('numeroLicitacao')}
        {contrato.get('entidadeLicitacao') &&
          contrato.get('exercicioLicitacao') &&
          contrato.get('tipoLicitacao') &&
          contrato.get('licitacao') && (
            <Button
              type="button"
              className="btn neutral ml-xs inline icon-right"
              onClick={() => {
                history.push(
                  `/licitacoes/detalhes?entidade=${contrato.get(
                    'entidadeLicitacao'
                  )}&exercicio=${contrato.get(
                    'exercicioLicitacao'
                  )}&tipoLicitacao=${contrato.get(
                    'tipoLicitacao'
                  )}&licitacao=${contrato.get('licitacao')}`
                );
              }}
            >
              Ver <em className="fa fa-external-link"></em>
            </Button>
          )}
      </>
    );
  };

  return (
    <Panel
      title={
        contrato.get('descricaoTipoAto') +
        ': ' +
        contrato.get('numeroContrato') +
        '/' +
        contrato.get('exercicio')
      }
    >
      <DisplayData border>
        <DisplayDataRow>
          <DisplayDataItem
            sm={4}
            title="Tipo do Ato"
            value={`${contrato.get('descricaoTipoAto')}/${contrato.get(
              'tipoContrato'
            )}`}
            id="descricaoTipoAto"
          />
          <DisplayDataItem
            sm={4}
            title={'Número ' + contrato.get('descricaoTipoAto')}
            value={contrato.get('numeroContrato')}
            separator="/"
            value2={contrato.get('exercicio')}
            id="numeroContrato"
          />
          <DisplayDataItem
            sm={3}
            title="Situação"
            value={contrato.get('situacao')}
            id="situacao"
          />
          <DisplayDataItem
            sm={1}
            title="Covid"
            value={contrato.get('isCovid') === 'S' ? 'Sim' : 'Não'}
            id="covid"
          />
        </DisplayDataRow>

        <DisplayDataRow>
          <DisplayDataItem
            sm={6}
            title={'Valor ' + contrato.get('descricaoTipoAto')}
            value={NumberUtils.format(contrato.get('valorContratado'))}
            id="valorContratado"
          />
          <DisplayDataItem
            sm={6}
            title="Valor Aditivo"
            value={NumberUtils.format(contrato.get('valorAditivo'))}
            id="valorAditivo"
          />
        </DisplayDataRow>

        <DisplayDataRow>
          <DisplayDataItemElo sm={3} md={3} title="Número Licitação">
            {getLicitacao()}
          </DisplayDataItemElo>
          <DisplayDataItem
            sm={2}
            title="Ano Licitação"
            value={contrato.get('exercicioLicitacao')}
            id="exercicioLicitacao"
          />
          <DisplayDataItem
            sm={4}
            title="Entidade Licitação"
            value={contrato.get('descricaoEntidadeLicitacao')}
            id="descricaoEntidadeLicitacao"
          />
          <DisplayDataItem
            sm={3}
            title="Tipo Licitação"
            value={contrato.get('descricaoTipoLicitacao')}
            id="descricaoTipoLicitacao"
          />
        </DisplayDataRow>

        <DisplayDataRow>
          <DisplayDataItem
            sm={12}
            title="Contratado"
            value={
              MaskUtils.format(contrato.get('cnpjCpf')) +
              ' - ' +
              contrato.get('nome')
            }
            id="nome"
          />
        </DisplayDataRow>

        <DisplayDataRow>
          <DisplayDataItem
            sm={4}
            title="Início Vigência"
            value={DateUtils.transform(contrato.get('inicioVigencia'))}
            id="inicioVigencia"
          />
          <DisplayDataItem
            sm={4}
            title="Término Vigência"
            value={DateUtils.transform(contrato.get('terminoVigencia'))}
            id="terminoVigencia"
          />
          <DisplayDataItem
            sm={4}
            title="Vigência Atualizada"
            value={DateUtils.transform(contrato.get('vigencia'))}
            id="vigencia"
          />
          <DisplayDataItem
            sm={4}
            title="Dias para Vencimento"
            value={
              contrato.get('diasVencimento') > 0
                ? contrato.get('diasVencimento')
                : ''
            }
            id="diasVencimento"
          />
        </DisplayDataRow>
        {contrato.get('situacao') === 'Suspenso' && (
          <DisplayDataRow>
            <DisplayDataItem
              sm={3}
              title="Data início suspensão"
              value={DateUtils.transform(contrato.get('dataInicioSuspensao'))}
              id="dataInicioSuspensao"
            />
            <DisplayDataItem
              sm={3}
              title="Data fim suspensão"
              value={DateUtils.transform(contrato.get('dataFimSuspensao'))}
              id="dataFimSuspensao"
            />
            <DisplayDataItem
              sm={6}
              title="Motivo"
              value={contrato.get('motivoSuspensao')}
              id="motivoSuspensao"
            />
          </DisplayDataRow>
        )}
        <DisplayDataRow>
          <DisplayDataItem
            sm={12}
            title="Objeto"
            value={contrato.get('objeto')}
            id="objeto"
            message="Sem Objeto"
          />
        </DisplayDataRow>
      </DisplayData>
    </Panel>
  );
};

export default ContratoDetalheInformacao;
