import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';

import * as types from './Types.js';

const initialState = fromJS({
  valorMes: [],
  receitaSelecionada: {},
  receitas: [],
  receitasDia: [],
  receitasRazao: [],
  receita: {},
  fontesRecursos: [],
  covid: [],
  especifico: [],
  filtros: []
});

function repasseReducer(state = initialState, action) {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case types.GRAFICO_ANUAL_START:
    case types.RECEITAS_START:
    case types.RECEITAS_DIA_START:
    case types.RECEITAS_RAZAO_START:
    case types.RECEITA_START:
    case types.RECEITAS_COVID_START:
    case types.RECEITAS_ESPECIFICO_START:
    case types.DETALHE_RECEITAS_FONTE_RECURSO_START:
    case types.RECEITAS_FONTE_RECURSO_START: {
      return startLoad(state);
    }

    case types.GRAFICO_ANUAL_ERROR:
    case types.RECEITAS_ERROR:
    case types.RECEITAS_DIA_ERROR:
    case types.RECEITAS_RAZAO_ERROR:
    case types.RECEITA_ERROR:
    case types.RECEITAS_COVID_ERROR:
    case types.RECEITAS_ESPECIFICO_ERROR:
    case types.DETALHE_RECEITAS_FONTE_RECURSO_ERROR:
    case types.RECEITAS_FONTE_RECURSO_ERROR: {
      return endLoad(state);
    }

    case types.GRAFICO_ANUAL_SUCCESS: {
      return endLoad(state.set('valorMes', fromJS(action.payload)));
    }
    case types.RECEITAS_SUCCESS: {
      const receitas = fromJS(
        action.payload.map(item => {
          return {
            ...item,
            open: false,
            visible: item.nivel === 1
          };
        })
      );
      return endLoad(
        state
          .set('receitas', receitas)
          .set('receitaSelecionada', receitas.get(0))
      );
    }
    case types.DETALHE_RECEITAS_FONTE_RECURSO_SUCCESS: {
      const receitas = fromJS(
        action.payload.map(item => {
          return {
            ...item
          };
        })
      );
      return endLoad(state.set('receitas', receitas));
    }
    case types.RECEITAS_DIA_SUCCESS: {
      return endLoad(state.set('receitasDia', fromJS(action.payload)));
    }
    case types.RECEITAS_RAZAO_SUCCESS: {
      return endLoad(state.set('receitasRazao', fromJS(action.payload)));
    }
    case types.RECEITA_SUCCESS: {
      return endLoad(state.set('receita', fromJS(action.payload)));
    }
    case types.RECEITAS_FONTE_RECURSO_SUCCESS: {
      return endLoad(state.set('fontesRecursos', fromJS(action.payload)));
    }
    case types.RECEITAS_COVID_SUCCESS: {
      return endLoad(state.set('covid', fromJS(action.payload)));
    }
    case types.RECEITAS_ESPECIFICO_SUCCESS: {
      return endLoad(state.set('especifico', fromJS(action.payload)));
    }
    case types.EXPAND_RECEITA: {
      const receitas = state.get('receitas').map(item => {
        if (item.get('receita') === action.payload.receita) {
          return item.set('open', true).set('visible', true);
        } else if (
          item.get('receita') &&
          item.get('receita').indexOf(action.payload.receita) === 0 &&
          item.get('nivel') === nextLevel(state.get('receitas'), action.payload)
        ) {
          return item.set('visible', true);
        }
        return item;
      });

      return state.set('receitas', receitas);
    }
    case types.COLLAPSE_RECEITA: {
      const receitas = state.get('receitas').map(item => {
        if (item.get('receita') === action.payload.receita) {
          return item.set('open', false).set('visible', true);
        } else if (
          item.get('receita') &&
          item.get('receita').indexOf(action.payload.receita) === 0 &&
          item.get('nivel') > action.payload.nivel
        ) {
          return item.set('open', false).set('visible', false);
        }
        return item;
      });

      return state.set('receitas', receitas);
    }

    case types.SELECT_RECEITA: {
      const receita = {
        receita: action.payload.receita,
        descricao: action.payload.descricao
      };

      return state.set('receitaSelecionada', fromJS(receita));
    }
  }

  return state;
}

function nextLevel(list, item) {
  const index = list.findIndex(i => i.get('receita') === item.receita);

  const proximoNivel = list.getIn([index + 1, 'nivel']);

  return proximoNivel;
}

export default repasseReducer;
