import { any } from 'expect';
import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';

import * as types from './Types.js';

const initialState = fromJS({
  menu: [],
  notificacao: [],
  arquivoNotificacao: any,
  fechado: false
});

function menuReducer(state = initialState, action) {
  switch (action.type) {
    case types.PARAMETRO_MENU_START:
    case types.PARAMETRO_BUSCA_NOTIFICACAO_START:
    case types.PARAMETRO_BUSCA_ARQUIVO_NOTIFICACAO_START: {
      return startLoad(state);
    }

    case types.PARAMETRO_MENU_SUCCESS: {
      return endLoad(state.set('menu', fromJS(action.payload)));
    }

    case types.PARAMETRO_BUSCA_NOTIFICACAO_SUCCESS: {
      return endLoad(state.set('notificacao', fromJS(action.payload)));
    }

    case types.PARAMETRO_BUSCA_ARQUIVO_NOTIFICACAO_SUCCESS: {
      return endLoad(state.set('arquivoNotificacao', fromJS(action.payload)));
    }

    case types.PARAMETRO_MENU_ERROR:
    case types.PARAMETRO_BUSCA_NOTIFICACAO_ERROR:
    case types.PARAMETRO_BUSCA_ARQUIVO_NOTIFICACAO_ERROR: {
      return endLoad(state);
    }
    case types.PARAMETRO_TIPO_FECHA_MODAL: {
      return state.set('fechado', true);
    }
  }

  return state;
}

export default menuReducer;
