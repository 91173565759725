import * as types from './Types.js';

export function findLicitacoes() {
  return ({ fetch }) => ({
    type: types.LICITACOES,
    payload: {
      promise: fetch('/api/licitacoes')
    }
  });
}

export function loadModalidades() {
  return ({ fetch }) => ({
    type: types.MODALIDADES,
    payload: {
      data: { text: 'Buscando registros...' },
      promise: fetch('/api/tiposLicitacao')
    }
  });
}

export function loadNaturezasProcedimento() {
  return ({ fetch }) => ({
    type: types.NATUREZAS,
    payload: {
      data: { text: 'Buscando registros...' },
      promise: fetch('/api/naturezasprocedimento')
    }
  });
}

export function searchLicitacoes(filter) {
  let url = '/api/licitacoes';

  if (filter !== '') {
    url += '?' + filter;
  }

  return ({ fetch }) => ({
    type: types.CONSULTA_LICITACOES,
    payload: {
      promise: fetch(url)
    }
  });
}

export function resetConsulta() {
  return {
    type: types.RESET_CONSULTA_LICITACOES,
    payload: {}
  };
}

export function findLicitacao(entidade, exercicio, tipoLicitacao, licitacao) {
  return ({ fetch }) => ({
    type: types.LICITACAO,
    payload: {
      promise: fetch(
        `/api/licitacoes/${entidade}/${exercicio}/${tipoLicitacao}/${licitacao}`
      )
    }
  });
}

export function findMidias(entidade, exercicio, tipoLicitacao, licitacao) {
  return ({ fetch }) => ({
    type: types.MIDIAS,
    payload: {
      promise: fetch(
        `/api/licitacoes/midias?entidade=${entidade}&exercicio=${exercicio}&tipoLicitacao=${tipoLicitacao}&licitacao=${licitacao}`
      )
    }
  });
}

export function findArquivos(entidade, exercicio, tipoLicitacao, licitacao) {
  return ({ fetch }) => ({
    type: types.ARQUIVOS_LICITACAO,
    payload: {
      promise: fetch(
        `/api/licitacoes/arquivos?entidade=${entidade}&exercicio=${exercicio}&tipoLicitacao=${tipoLicitacao}&licitacao=${licitacao}`
      )
    }
  });
}

export function findPublicacoes(entidade, exercicio, tipoLicitacao, licitacao) {
  return ({ fetch }) => ({
    type: types.PUBLICACOES,
    payload: {
      promise: fetch(
        `/api/licitacoes/publicacoes?entidade=${entidade}&exercicio=${exercicio}&tipoLicitacao=${tipoLicitacao}&licitacao=${licitacao}`
      )
    }
  });
}

export function solicitarNotificacao(interessadoEdital) {
  return ({ post }) => ({
    type: types.SOLICITACAO_NOTIFICACAO,
    payload: {
      promise: post('/api/interessado-edital', interessadoEdital)
    }
  });
}

export function findCertidoes(entidade, exercicio, tipoLicitacao, licitacao) {
  return ({ fetch }) => ({
    type: types.CERTIDOES,
    payload: {
      promise: fetch(
        `/api/licitacoes/certidoes?entidade=${entidade}&exercicio=${exercicio}&tipoLicitacao=${tipoLicitacao}&licitacao=${licitacao}`
      )
    }
  });
}

export function findCotacoes(entidade, exercicio, tipoLicitacao, licitacao) {
  return ({ fetch }) => ({
    type: types.COTACOES,
    payload: {
      promise: fetch(
        `/api/licitacoes/cotacoes?entidade=${entidade}&exercicio=${exercicio}&tipoLicitacao=${tipoLicitacao}&licitacao=${licitacao}`
      )
    }
  });
}

export function findPareceres(entidade, exercicio, tipoLicitacao, licitacao) {
  return ({ fetch }) => ({
    type: types.PARECERES,
    payload: {
      promise: fetch(
        `/api/licitacoes/pareceres?entidade=${entidade}&exercicio=${exercicio}&tipoLicitacao=${tipoLicitacao}&licitacao=${licitacao}`
      )
    }
  });
}

export function findRecursos(entidade, exercicio, tipoLicitacao, licitacao) {
  return ({ fetch }) => ({
    type: types.RECURSOS,
    payload: {
      promise: fetch(
        `/api/licitacoes/recursos?entidade=${entidade}&exercicio=${exercicio}&tipoLicitacao=${tipoLicitacao}&licitacao=${licitacao}`
      )
    }
  });
}

export function findAdjudicacoes(
  entidade,
  exercicio,
  tipoLicitacao,
  licitacao
) {
  return ({ fetch }) => ({
    type: types.ADJUDICACOES,
    payload: {
      promise: fetch(
        `/api/adjudicacoes?entidade=${entidade}&exercicio=${exercicio}&tipoLicitacao=${tipoLicitacao}&licitacao=${licitacao}`
      )
    }
  });
}

export function findPrecos(entidade, exercicio, tipoLicitacao, licitacao) {
  return ({ fetch }) => ({
    type: types.PRECOS,
    payload: {
      promise: fetch(
        `/api/licitacoes/precos?entidade=${entidade}&exercicio=${exercicio}&tipoLicitacao=${tipoLicitacao}&licitacao=${licitacao}`
      )
    }
  });
}

export function findAtas(entidade, exercicio, tipoLicitacao, licitacao) {
  return ({ fetch }) => ({
    type: types.ATAS,
    payload: {
      promise: fetch(
        `/api/licitacoes/atas?entidade=${entidade}&exercicio=${exercicio}&tipoLicitacao=${tipoLicitacao}&licitacao=${licitacao}`
      )
    }
  });
}

export function findManualFornecedor() {
  return ({ fetch }) => ({
    type: types.MANUAL_FORNECEDOR,
    payload: {
      promise: fetch('/parametros/manual-fornecedor')
    }
  });
}

export function reset() {
  return {
    type: types.FORMULARIO_SOLICITACAO_NOTIFICACAO_RESET
  };
}

export function findLinksUteis(entidade) {
  return ({ fetch }) => ({
    type: types.LINKS_LICITACAO,
    payload: {
      promise: fetch(`/api/links-uteis?entidade=${entidade}&tipo=2`)
    }
  });
}

export function findMensagemSolicitacao() {
  return ({ fetch }) => ({
    type: types.MENSAGEM_SOLICITACAO_LICITACAO,
    payload: {
      promise: fetch('/parametros/sic-fisico/mensagem?tipoCadastro=2')
    }
  });
}

export function findHomologacoes(
  entidade,
  exercicio,
  tipoLicitacao,
  licitacao
) {
  return ({ fetch }) => ({
    type: types.HOMOLOGACOES,
    payload: {
      promise: fetch(
        `/api/homologacoes?entidade=${entidade}&exercicio=${exercicio}&tipoLicitacao=${tipoLicitacao}&licitacao=${licitacao}`
      )
    }
  });
}

export function findAutorizacoes(
  entidade,
  exercicio,
  tipoLicitacao,
  licitacao
) {
  return ({ fetch }) => ({
    type: types.AUTORIZACOES,
    payload: {
      promise: fetch(
        `/api/autorizacoes?entidade=${entidade}&exercicio=${exercicio}&tipoLicitacao=${tipoLicitacao}&licitacao=${licitacao}`
      )
    }
  });
}

export function findSei(params) {
  return ({ fetch }) => ({
    type: types.LICITACAO_SEI,
    payload: {
      promise: fetch(`/api/licitacoes/sei?${params}`)
    }
  });
}
