import Component from 'react-pure-render/component';
import PropTypes from 'prop-types';
import React from 'react';

class Link extends Component {
  static propTypes = {
    value: PropTypes.string,
    data: PropTypes.any,
    icon: PropTypes.string,
    iconPosition: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    title: PropTypes.string
  };

  onClick = event => {
    const { onClick, data } = this.props;
    onClick(data);
    event.preventDefault();
  };

  render() {
    const { value, icon, iconPosition, title } = this.props;

    return (
      <a ref="link" href="#" title={title} onClick={this.onClick}>
        {(!iconPosition || iconPosition == 'left') && <i className={icon}></i>}{' '}
        {value} {iconPosition == 'right' && <i className={icon}></i>}
      </a>
    );
  }
}

export default Link;
