import SearchPagination from 'portaltransparencia-common/components/SearchPagination.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import TableHeaderItem from 'portaltransparencia-common/components/TableHeaderItem.react';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export class CedidoListagem extends Component {
  static propTypes = {
    page: PropTypes.object.isRequired,
    searchWithPage: PropTypes.func,
    entityColumnTitle: PropTypes.string
  };

  static defaultProps = {
    entityColumnTitle: 'Entidade'
  };

  renderCedidos = data => {
    const key = `${data.get('matricula')}_${data.get('numeroAto')}_${data.get(
      'anoAto'
    )}_${data.get('dataInicio')}_${data.get('dataFim')}`;

    return (
      <tr ref="row" key={key}>
        <td>{data.get('nome')} </td>
        <td>{data.get('matricula')}</td>
        <td>{data.get('tipoCedido')} </td>
        <td>{data.get('cargoCedido')} </td>
        <td>{data.get('localCedido')} </td>
        <td>{data.get('numeroAto')} </td>
        <td>{data.get('anoAto')} </td>
        <td>{data.get('tipoAto')} </td>
        <td>
          {data.get('dataInicio')}&nbsp;-&nbsp;
          {data.get('dataFim') ? data.get('dataFim') : 'N/I'}
        </td>
      </tr>
    );
  };

  render() {
    const { page, searchWithPage, entityColumnTitle } = this.props;

    if (!page.get('content')) {
      return null;
    }

    return (
      <section>
        <div className="panel-table-outer">
          <table className="panel-table striped fancy">
            <TableHeader empty={page.get('content').isEmpty()}>
              <tr>
                <TableHeaderItem
                  field="nome"
                  label="Nome"
                  page={page}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="matricula"
                  label="Matrícula"
                  page={page}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="tipoCedido"
                  label="Tipo"
                  page={page}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="cargoCedido"
                  label="Cargo"
                  page={page}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="localCedido"
                  label={entityColumnTitle}
                  page={page}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="numeroAto"
                  label="N° Ato"
                  page={page}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="anoAto"
                  label="Ano Ato"
                  page={page}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="tipoAto"
                  label="Tipo Ato"
                  page={page}
                  searchWithPage={searchWithPage}
                />
                <th>Vigência</th>
              </tr>
            </TableHeader>
            <tbody>{page.get('content').map(this.renderCedidos)}</tbody>
          </table>
        </div>
        <SearchPagination page={page} searchWithPage={searchWithPage} />
      </section>
    );
  }
}

export default CedidoListagem;
