import Component from 'react-pure-render/component';
import PropTypes from 'prop-types';
import React from 'react';
import InputError from './InputError.react.js';
import InputMask from './InputMask.react.js';
import { Checkbox, Radio } from 'react-bootstrap';

const noOp = () => {};

export default class EloInput extends Component {
  static propTypes = {
    name: PropTypes.string,
    type: PropTypes.string.isRequired,
    errors: PropTypes.object,
    mask: PropTypes.string,
    label: PropTypes.string,
    maskRegex: PropTypes.string,
    children: PropTypes.node,
    value: PropTypes.any,
    defaultValue: PropTypes.any,
    onChange: PropTypes.func,
    readOnly: PropTypes.bool,
    placeholder: PropTypes.string,
    accept: PropTypes.string
  };
  constructor(props) {
    super(props);
    this.inputRef = undefined;
  }
  bindInputRef = ref => {
    this.inputRef = ref;
  };
  getInputRef = () => {
    if (this.isCheckbox()) {
      return this.inputRef;
    }
    return this.refs.eloinput;
  };
  getValue = () => {
    if (this.isMaskedInput()) {
      return this.refs.eloinput.getValue();
    }
    return this.refs.eloinput.value;
  };
  clear = () => {
    if (this.isMaskedInput()) {
      this.refs.eloinput.clear();
      return;
    }
    this.refs.eloinput.value = '';
  };
  focus = () => {
    if (this.isMaskedInput()) {
      this.refs.eloinput.focus();
      return;
    }
    if (this.isCheckbox()) {
      return this.inputRef.focus();
    }
    this.refs.eloinput.focus();
  };
  getChecked = () => {
    if (this.isMaskedInput()) {
      return this.refs.eloinput.getChecked();
    }
    if (this.isCheckbox()) {
      return this.inputRef.checked;
    }
    return this.refs.eloinput.checked;
  };
  isCheckbox = () => {
    const { type } = this.props;
    return type === 'checkbox';
  };
  isRadio = () => {
    const { type } = this.props;
    return type === 'radio';
  };
  isMaskedInput = () => {
    const { mask, maskRegex } = this.props;
    return !!(mask || maskRegex);
  };
  isSelect = () => {
    const { type } = this.props;
    return type === 'select';
  };
  isTextArea = () => {
    const { type } = this.props;
    return type === 'textarea';
  };
  render() {
    const {
      name,
      errors,
      type,
      label,
      mask,
      maskRegex,
      value,
      placeholder,
      accept,
      ...rest
    } = this.props;
    const errorProps = InputError.createError(name, errors);
    let newValue = value;
    let defaultValue = this.props.defaultValue;
    let onChange = this.props.onChange;
    if (this.props.readOnly) {
      onChange = onChange || noOp;
    }
    if ('value' in this.props) {
      newValue = newValue || defaultValue || '';
      defaultValue = undefined;
    }
    if (this.isMaskedInput()) {
      return (
        <InputMask
          ref="eloinput"
          type={type}
          name={name}
          label={label}
          data-inputmask={mask}
          maskRegex={maskRegex}
          errors={errors}
          {...errorProps}
          {...rest}
          value={newValue}
          defaultValue={defaultValue}
          onChange={onChange}
        />
      );
    }
    if (this.isCheckbox()) {
      return (
        <Checkbox
          ref="eloinput"
          name={name}
          validationState={errorProps && errorProps.bsStyle}
          inputRef={this.bindInputRef}
          {...rest}
          onChange={onChange}
        >
          {label}
        </Checkbox>
      );
    }
    if (this.isRadio()) {
      return (
        <Radio
          ref="eloinput"
          name={name}
          className={errorProps && errorProps.className}
          validationState={errorProps && errorProps.bsStyle}
          inputRef={this.bindInputRef}
          value={newValue}
          {...rest}
          onChange={onChange}
        >
          {label}
        </Radio>
      );
    }
    if (this.isSelect()) {
      return (
        <div className="form-group">
          {label && <label className="label">{label}</label>}
          <select
            className={errorProps && errorProps.className}
            ref="eloinput"
            type={type}
            name={name}
            value={newValue}
            {...rest}
            onChange={onChange}
          >
            {this.props.children}
          </select>
          {errorProps && errorProps.help}
        </div>
      );
    }
    if (this.isTextArea()) {
      return (
        <div className="form-group">
          {label && <label className="label">{label}</label>}
          <textarea
            className={errorProps && errorProps.className}
            ref="eloinput"
            type={type}
            name={name}
            value={newValue}
            {...rest}
            onChange={onChange}
            placeholder={placeholder}
          />
          {errorProps && errorProps.help}
        </div>
      );
    }
    return (
      <div className="form-group">
        {label && <label className="label">{label}</label>}
        <input
          className={errorProps && errorProps.className}
          ref="eloinput"
          type={type}
          name={name}
          {...rest}
          value={newValue}
          defaultValue={defaultValue}
          onChange={onChange}
          accept={accept}
        />
        {errorProps && errorProps.help}
      </div>
    );
  }
}
