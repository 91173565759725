import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import SumListField from 'portaltransparencia-common/components/SumListField.react.js';
import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import { getAllValuesFromUrlSearchParams } from 'portaltransparencia-common/libs/URLUtils';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import PageHeader from '../components/PageHeader.react.js';
import UltimaAtualizacaoByTable from '../ultimaatualizacao/UltimaAtualizacaoByTable.react.js';
import * as actions from './Actions.js';

export class FormPorFonteDetail extends Component {
  static propTypes = {
    receitas: PropTypes.object.isRequired,
    findDetalheReceitasFonteRecurso: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired
  };

  componentDidMount() {
    const { findDetalheReceitasFonteRecurso } = this.props;
    findDetalheReceitasFonteRecurso(this.montarFiltro());
  }

  montarFiltro = () => {
    const { fonteRecurso } = getAllValuesFromUrlSearchParams(
      this.props.location
    );
    const { entidade, exercicio } = StorageService.getItem('userInfo');

    let fonte = '';
    if (fonteRecurso) {
      fonte = `&fonteRecurso=${fonteRecurso}`;
    }

    return `entidade=${entidade.id}&exercicio=${exercicio.id}${fonte}`;
  };

  renderReceitas = data => {
    return (
      <TableActions ref="row" key={data.get('receita')} data={data}>
        <td>{data.get('receita')}</td>
        <td>{data.get('descricao')}</td>
        <td className="right">
          <NumberFormatter value={data.get('valorOrcado')} />
        </td>
        <td className="right">
          <NumberFormatter value={data.get('valorAtualizado')} />
        </td>
        <td className="right">
          <NumberFormatter value={data.get('valorArrecadado')} />
        </td>
      </TableActions>
    );
  };

  render() {
    const { receitas } = this.props;
    const { fonteRecurso } = getAllValuesFromUrlSearchParams(
      this.props.location
    );
    return (
      <section>
        <PageHeader
          location={this.props.location}
          textoDetalhe={`Fonte Recurso: ${fonteRecurso}`}
        />
        {receitas && (
          <Panel isForm tableResponsive expansible={false}>
            <Row>
              <Col xs={6}>
                <UltimaAtualizacaoByTable
                  modulo="3"
                  table="receita"
                  column="dataultimaatualizacao"
                />
              </Col>
              <Col xs={6} />
            </Row>

            <div className="panel-table-outer">
              <table className="panel-table striped fancy">
                <TableHeader empty={receitas.isEmpty()} text="Sem registros">
                  <tr>
                    <th>Receita</th>
                    <th>Descrição</th>
                    <th className="right">Valor Orçado</th>
                    <th className="right">Valor Atualizado</th>
                    <th className="right">Valor Arrecadado</th>
                    <th></th>
                  </tr>
                </TableHeader>
                <tbody>{receitas.map(this.renderReceitas)}</tbody>
                <tfoot>
                  <tr>
                    <td />
                    <td />
                    <td className="right">
                      <i>Total: </i>
                      {<SumListField fieldName="valorOrcado" list={receitas} />}
                    </td>
                    <td className="right">
                      <i>Total: </i>
                      {
                        <SumListField
                          fieldName="valorAtualizado"
                          list={receitas}
                        />
                      }
                    </td>
                    <td className="right">
                      <i>Total: </i>
                      {
                        <SumListField
                          fieldName="valorArrecadado"
                          list={receitas}
                        />
                      }
                    </td>
                    <td />
                  </tr>
                </tfoot>
              </table>
            </div>
          </Panel>
        )}
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    receitas: state.receita.get('receitas')
  };
}

export default connect(mapStateToProps, actions)(FormPorFonteDetail);
