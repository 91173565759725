import * as types from './Types.js';

import { downloadArquivoBlob, getExtension } from './ArquivoUtils';

import { Map } from 'immutable';
import configuration from 'portaltransparencia-common/config/Config.js';

export function downloadArquivo(item) {
  let id = item;

  if (item instanceof Map) {
    id = item.get('idArquivo');

    if (id) {
      window.open(`${configuration.apiURL}/api/files/arquivo/${id}`, '_blank');
    } else {
      let pathArquivo = item.get('pathArquivo');
      if (getExtension(pathArquivo) === 'pdf') pathArquivo = '';
      downloadArquivoBlob(item.get('arquivo'), pathArquivo);
    }
  } else {
    if (id) {
      window.open(`${configuration.apiURL}/api/files/arquivo/${id}`, '_blank');
    }
  }

  return {
    type: types.ARQUIVO_DOWNLOAD,
    payload: id
  };
}

export function downloadArquivoEscalas(item) {
  let id = item;

  if (item instanceof Map) {
    id = item.get('id');
  }
  window.open(
    `${configuration.apiURL}/api/escalas-profissionais/${id}`,
    '_blank'
  );

  return {
    type: types.ARQUIVO_DOWNLOAD,
    payload: id
  };
}

export function downloadArquivoAtas(item) {
  let id = item;

  if (item instanceof Map) {
    id = item.get('id');
  }
  window.open(`${configuration.apiURL}/api/conselhos/arquivo/${id}`, '_blank');

  return {
    type: types.ARQUIVO_DOWNLOAD,
    payload: id
  };
}

export function downloadArquivoDocumentos(item) {
  let id = item;

  if (item instanceof Map) {
    id = item.get('id');
  }
  window.open(`${configuration.apiURL}/api/documentos-saude/${id}`, '_blank');

  return {
    type: types.ARQUIVO_DOWNLOAD,
    payload: id
  };
}

export function downloadArquivoConvenio(item) {
  let id = item;

  if (item instanceof Map) {
    if (item.get('link')) {
      window.open(item.get('link'), '_blank');
    } else {
      id = item.get('idArquivo');

      window.open(
        `${configuration.apiURL}/api/convenios/convenio-anexo/download/${id}`,
        '_blank'
      );
    }
  }

  return {
    type: types.ARQUIVO_DOWNLOAD,
    payload: id
  };
}

export function downloadArquivoAudienciaPublica(item) {
  let id = item;

  if (item instanceof Map) {
    id = item.get('idArquivo');
  }

  window.open(
    `${configuration.apiURL}/api/audiencias-publicas/anexo/download/${id}`,
    '_blank'
  );

  return {
    type: types.ARQUIVO_DOWNLOAD,
    payload: id
  };
}
