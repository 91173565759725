import PropTypes from 'prop-types';
import React from 'react';
import { List } from 'immutable';
import TreeItem from './TreeItem.react.js';

class Tree extends React.PureComponent {
  static propTypes = {
    list: PropTypes.instanceOf(List).isRequired,
    onClick: PropTypes.func,
    text: PropTypes.string,
    expanded: PropTypes.bool
  };

  static defaultProps = {
    text: 'Sem registros.'
  };

  onClick = item => {
    const { onClick } = this.props;

    if (onClick) {
      onClick(item);
    }
  };

  render() {
    const { list, text, expanded } = this.props;

    if (list.isEmpty()) {
      return <div className="info">{text}</div>;
    }
    return (
      <div className="tree">
        <ul className="tree-list">
          {list.map(item => {
            return (
              <TreeItem
                item={item}
                key={item.get('id')}
                onClick={this.onClick}
                expanded={expanded}
              />
            );
          })}
        </ul>
      </div>
    );
  }
}

export default Tree;
