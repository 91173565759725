import { Map } from 'immutable';
import DatePicker from 'portaltransparencia-common/components/DatePicker.react.js';
import Input from 'portaltransparencia-common/components/EloInput.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import SearchPagination from 'portaltransparencia-common/components/SearchPagination.react.js';
import {
  onChange,
  resetCrud
} from 'portaltransparencia-common/crud/Actions.js';
import {
  addFilterUrl,
  addPagination
} from 'portaltransparencia-common/libs/AddFilter.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import Tab from 'portaltransparencia-common/components/Tab.react.js';
import Tabs from 'portaltransparencia-common/components/Tabs.react.js';
import PropTypes, { number } from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import LiquidacoesListagem from '../liquidacoes/LiquidacoesListagem.react.js';
import FilterButtons from '../components/FilterButtons.react.js';
import PageHeader from '../components/PageHeader.react.js';
import NotasExplicativas from '../notaexplicativa/Form.react.js';
import UltimaAtualizacaoByTable from '../ultimaatualizacao/UltimaAtualizacaoByTable.react.js';
import * as actions from './Actions.js';
import configuration from 'portaltransparencia-common/config/Config.js';
import { addErrorNotification } from 'portaltransparencia-common/ui/Actions.js';

export class FormOrdemCronologicaPagamentos extends Component {
  static propTypes = {
    fornecimentoBens: PropTypes.object.isRequired,
    locacoes: PropTypes.object.isRequired,
    prestacaoServicos: PropTypes.object.isRequired,
    obras: PropTypes.object.isRequired,
    pagamentos: PropTypes.object.isRequired,
    findFornecimentoBens: PropTypes.func.isRequired,
    findFornecimentoBensTotal: PropTypes.func.isRequired,
    findLocacoes: PropTypes.func.isRequired,
    findLocacoesTotal: PropTypes.func.isRequired,
    findPrestacaoServicos: PropTypes.func.isRequired,
    findPrestacaoServicosTotal: PropTypes.func.isRequired,
    findObras: PropTypes.func.isRequired,
    findObrasTotal: PropTypes.func.isRequired,
    findPagamentos: PropTypes.func.isRequired,
    findPagamentosTotal: PropTypes.func.isRequired,
    totalFornecimentoBens: PropTypes.object.isRequired,
    totalLocacoes: PropTypes.object.isRequired,
    totalPrestacaoServicos: PropTypes.object.isRequired,
    totalObras: PropTypes.object.isRequired,
    totalPagamentos: PropTypes.object.isRequired,
    filtros: PropTypes.object,
    onChange: PropTypes.func,
    location: PropTypes.object.isRequired,
    resetCrud: PropTypes.func.isRequired,
    uf: PropTypes.string,
    addErrorNotification: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.loadInitialFiltros();
  }

  loadInitialFiltros = (acao = 'buscar') => {
    const {
      onChange,
      fornecimentoBens,
      locacoes,
      prestacaoServicos,
      obras,
      pagamentos,
      findFornecimentoBens,
      findFornecimentoBensTotal,
      findLocacoes,
      findLocacoesTotal,
      findPrestacaoServicos,
      findPrestacaoServicosTotal,
      findObras,
      findObrasTotal,
      findPagamentos,
      findPagamentosTotal
    } = this.props;
    const { exercicio } = StorageService.getItem('userInfo');
    let filterFornecimentoBens = this.montarFiltro(
      fornecimentoBens,
      'fornecimentoBens',
      acao
    );
    let filterLocacoes = this.montarFiltro(locacoes, 'locacoes', acao);
    let filterPrestacaoServicos = this.montarFiltro(
      prestacaoServicos,
      'prestacaoServicos',
      acao
    );
    let filterObras = this.montarFiltro(obras, 'obras', acao);
    let filterPagamentos = this.montarFiltro(pagamentos, 'pagamentos', acao);

    onChange('filtroOrdemCronologicaPagamento', {
      name: 'dataInicial',
      value: `${exercicio.id}-01-01`
    });

    onChange('filtroOrdemCronologicaPagamento', {
      name: 'dataFinal',
      value: `${exercicio.id}-12-31`
    });

    filterFornecimentoBens = addFilterUrl(
      filterFornecimentoBens,
      `dataInicial=${`${exercicio.id}-01-01`}`
    );
    filterFornecimentoBens = addFilterUrl(
      filterFornecimentoBens,
      `dataFinal=${`${exercicio.id}-12-31`}`
    );
    filterLocacoes = addFilterUrl(
      filterLocacoes,
      `dataInicial=${`${exercicio.id}-01-01`}`
    );
    filterLocacoes = addFilterUrl(
      filterLocacoes,
      `dataFinal=${`${exercicio.id}-12-31`}`
    );
    filterPrestacaoServicos = addFilterUrl(
      filterPrestacaoServicos,
      `dataInicial=${`${exercicio.id}-01-01`}`
    );
    filterPrestacaoServicos = addFilterUrl(
      filterPrestacaoServicos,
      `dataFinal=${`${exercicio.id}-12-31`}`
    );
    filterObras = addFilterUrl(
      filterObras,
      `dataInicial=${`${exercicio.id}-01-01`}`
    );
    filterObras = addFilterUrl(
      filterObras,
      `dataFinal=${`${exercicio.id}-12-31`}`
    );
    filterPagamentos = addFilterUrl(
      filterPagamentos,
      `dataInicial=${`${exercicio.id}-01-01`}`
    );
    filterPagamentos = addFilterUrl(
      filterPagamentos,
      `dataFinal=${`${exercicio.id}-12-31`}`
    );

    findFornecimentoBens(filterFornecimentoBens);
    findFornecimentoBensTotal(filterFornecimentoBens);
    findLocacoes(filterLocacoes);
    findLocacoesTotal(filterLocacoes);
    findPrestacaoServicos(filterPrestacaoServicos);
    findPrestacaoServicosTotal(filterPrestacaoServicos);
    findObras(filterObras);
    findObrasTotal(filterObras);
    findPagamentos(filterPagamentos);
    findPagamentosTotal(filterPagamentos);
  };

  onExportaPagamentos = (exportType, openTab, windowParams) => {
    const { apiURL } = configuration;
    const { pagamentos, addErrorNotification } = this.props;
    const { exercicio } = StorageService.getItem('userInfo');

    if (pagamentos.get('content').size > 0) {
      const { filterPagamentos } = this.montarFiltro(pagamentos, 'pagamentos');

      let filtro = `${filterPagamentos}&exportType=${exportType}&exercicio=${exercicio.id}`;

      window.open(
        `${apiURL}/api/liquidacoes/ordem-cronologica-pagamentos/report?${filtro}`,
        openTab,
        windowParams
      );
    } else {
      addErrorNotification(
        'A consulta não apresentou resultado. Não é possível exportar para o formato selecionado!'
      );
    }
  };

  onExportaFornecimentoBens = (exportType, openTab, windowParams) => {
    const { apiURL } = configuration;
    const { fornecimentoBens, addErrorNotification } = this.props;
    const { exercicio } = StorageService.getItem('userInfo');

    if (fornecimentoBens.get('content').size > 0) {
      const { filterFornecimentoBens } = this.montarFiltro(
        fornecimentoBens,
        'fornecimentoBens'
      );

      let filtro = `${filterFornecimentoBens}&exportType=${exportType}&exercicio=${exercicio.id}`;

      window.open(
        `${apiURL}/api/liquidacoes/ordem-cronologica-pagamentos/report?${filtro}`,
        openTab,
        windowParams
      );
    } else {
      addErrorNotification(
        'A consulta não apresentou resultado. Não é possível exportar para o formato selecionado!'
      );
    }
  };

  onExportaLocacoes = (exportType, openTab, windowParams) => {
    const { apiURL } = configuration;
    const { locacoes, addErrorNotification } = this.props;
    const { exercicio } = StorageService.getItem('userInfo');

    if (locacoes.get('content').size > 0) {
      const { filterLocacoes } = this.montarFiltro(locacoes, 'locacoes');

      let filtro = `${filterLocacoes}&exportType=${exportType}&exercicio=${exercicio.id}`;

      window.open(
        `${apiURL}/api/liquidacoes/ordem-cronologica-pagamentos/report?${filtro}`,
        openTab,
        windowParams
      );
    } else {
      addErrorNotification(
        'A consulta não apresentou resultado. Não é possível exportar para o formato selecionado!'
      );
    }
  };

  onExportaPrestacaoServicos = (exportType, openTab, windowParams) => {
    const { apiURL } = configuration;
    const { prestacaoServicos, addErrorNotification } = this.props;
    const { exercicio } = StorageService.getItem('userInfo');

    if (prestacaoServicos.get('content').size > 0) {
      const { filterPrestacaoServicos } = this.montarFiltro(
        prestacaoServicos,
        'prestacaoServicos'
      );

      let filtro = `${filterPrestacaoServicos}&exportType=${exportType}&exercicio=${exercicio.id}`;

      window.open(
        `${apiURL}/api/liquidacoes/ordem-cronologica-pagamentos/report?${filtro}`,
        openTab,
        windowParams
      );
    } else {
      addErrorNotification(
        'A consulta não apresentou resultado. Não é possível exportar para o formato selecionado!'
      );
    }
  };

  onExportaObras = (exportType, openTab, windowParams) => {
    const { apiURL } = configuration;
    const { obras, addErrorNotification } = this.props;
    const { exercicio } = StorageService.getItem('userInfo');

    if (obras.get('content').size > 0) {
      const { filterObras } = this.montarFiltro(obras, 'obras');

      let filtro = `${filterObras}&exportType=${exportType}&exercicio=${exercicio.id}`;

      window.open(
        `${apiURL}/api/liquidacoes/ordem-cronologica-pagamentos/report?${filtro}`,
        openTab,
        windowParams
      );
    } else {
      addErrorNotification(
        'A consulta não apresentou resultado. Não é possível exportar para o formato selecionado!'
      );
    }
  };

  consultarOrdemCronologicaPagamentos = () => {
    const {
      fornecimentoBens,
      locacoes,
      prestacaoServicos,
      obras,
      pagamentos
    } = this.props;
    const initialPageFornecimentoBens = fornecimentoBens.set('number', 0);
    const initialPageLocacoes = locacoes.set('number', 0);
    const initialPagePrestacaoServicos = prestacaoServicos.set('number', 0);
    const initialPageObras = obras.set('number', 0);
    const initialPagePagamentos = pagamentos.set('number', 0);

    this.searchFornecimentoBensWithPage(initialPageFornecimentoBens);
    this.searchLocacoesWithPage(initialPageLocacoes);
    this.searchPrestacaoServicosWithPage(initialPagePrestacaoServicos);
    this.searchObrasWithPage(initialPageObras);
    this.searchPagamentosWithPage(initialPagePagamentos);
  };

  onConsultaOrdemCronologicaPagamento = () => {
    this.consultarOrdemCronologicaPagamentos();
    this.refs.panelFiltroOrdemCronologicaPagamento &&
      this.refs.panelFiltroOrdemCronologicaPagamento.collapse();
  };

  onChangeFiltro = event => {
    const { onChange } = this.props;
    onChange('filtroOrdemCronologicaPagamento', event);
  };

  onResetFiltro = () => {
    const { resetCrud } = this.props;

    resetCrud('filtroOrdemCronologicaPagamento');
    this.loadInitialFiltros('limpar');
  };

  montarFiltro = function (page, tab = '', acao = 'buscar') {
    let userInfo = StorageService.getItem('userInfo');
    const { entidade } = userInfo;

    const { filtros } = this.props;

    let filter = 'entidade=' + entidade.id;

    if (tab === 'fornecimentoBens') {
      filter = addFilterUrl(filter, `tipoContrato=fornecimentoBens`);
    }

    if (tab === 'locacoes') {
      filter = addFilterUrl(filter, `tipoContrato=locacoes`);
    }

    if (tab === 'prestacaoServicos') {
      filter = addFilterUrl(filter, `tipoContrato=prestacaoServicos`);
    }

    if (tab === 'obras') {
      filter = addFilterUrl(filter, `tipoContrato=obras`);
    }

    if (acao === 'buscar') {
      if (filtros.get('cnpj')) {
        filter = addFilterUrl(filter, `cnpjCpf=${filtros.get('cnpj')}`);
      }

      if (filtros.get('nome')) {
        filter = addFilterUrl(
          filter,
          `nome=${encodeURIComponent(filtros.get('nome'))}`
        );
      }

      if (filtros.get('notaFiscal')) {
        filter = addFilterUrl(
          filter,
          `notaFiscal=${filtros.get('notaFiscal')}`
        );
      }

      if (
        filtros.get('dataInicial') &&
        filtros.get('dataInicial').length === 10
      ) {
        let validValue = DateUtils.transform(
          filtros.get('dataInicial'),
          'yy-mm-dd',
          'yy-mm-dd'
        );
        filter = addFilterUrl(filter, `dataInicial=${validValue}`);
      }

      if (filtros.get('dataFinal') && filtros.get('dataFinal').length === 10) {
        let validValue = DateUtils.transform(
          filtros.get('dataFinal'),
          'yy-mm-dd',
          'yy-mm-dd'
        );
        filter = addFilterUrl(filter, `dataFinal=${validValue}`);
      }
    }

    return addPagination(page, filter);
  };

  searchFornecimentoBensWithPage = page => {
    const { findFornecimentoBens, findFornecimentoBensTotal } = this.props;

    const filtro = `${this.montarFiltro(page, 'fornecimentoBens')}`;

    findFornecimentoBens(filtro);
    findFornecimentoBensTotal(filtro);
  };

  searchLocacoesWithPage = page => {
    const { findLocacoes, findLocacoesTotal } = this.props;

    const filtro = `${this.montarFiltro(page, 'locacoes')}`;

    findLocacoes(filtro);
    findLocacoesTotal(filtro);
  };

  searchPrestacaoServicosWithPage = page => {
    const { findPrestacaoServicos, findPrestacaoServicosTotal } = this.props;

    const filtro = `${this.montarFiltro(page, 'prestacaoServicos')}`;

    findPrestacaoServicos(filtro);
    findPrestacaoServicosTotal(filtro);
  };

  searchObrasWithPage = page => {
    const { findObras, findObrasTotal } = this.props;

    const filtro = `${this.montarFiltro(page, 'obras')}`;

    findObras(filtro);
    findObrasTotal(filtro);
  };

  searchPagamentosWithPage = page => {
    const { findPagamentos, findPagamentosTotal } = this.props;

    const filtro = `${this.montarFiltro(page, 'pagamentos')}`;

    findPagamentos(filtro);
    findPagamentosTotal(filtro);
  };

  mensagemSemFornecedor = liquidacao => {
    if (liquidacao && !liquidacao.isEmpty()) {
      return 'Entidade não possui pendências financeiras "processadas" para o filtro informado';
    } else {
      return 'Entidade não possui pendências financeiras "processadas"!';
    }
  };

  render() {
    const {
      filtros,
      location,
      fornecimentoBens,
      locacoes,
      obras,
      pagamentos,
      prestacaoServicos,
      totalFornecimentoBens,
      totalLocacoes,
      totalPrestacaoServicos,
      totalObras,
      totalPagamentos,
      uf
    } = this.props;
    let mensagem = this.mensagemSemFornecedor(filtros);

    if (uf === 'RO') {
      return (
        <section>
          <PageHeader location={location} />

          <NotasExplicativas pathname={this.props.location.pathname} />

          <Panel
            isForm
            mobileClose
            title="Consulta Ordem Cronológica de Pagamentos"
            ref="panelFiltroOrdemCronologicaPagamento"
          >
            <form>
              <Row>
                <Col xs={12} sm={4} md={4}>
                  <Input
                    id="cnpj"
                    name="cnpj"
                    ref="cnpj"
                    placeholder="CNPJ/CPF"
                    label="CNPJ/CPF"
                    type="text"
                    onChange={this.onChangeFiltro}
                    value={filtros.get('cnpj')}
                  />
                </Col>
                <Col xs={12} sm={8} md={8}>
                  <Input
                    id="nome"
                    name="nome"
                    ref="nome"
                    placeholder="Nome"
                    label="Nome"
                    type="text"
                    onChange={this.onChangeFiltro}
                    value={filtros.get('nome')}
                  />
                </Col>
                <Col xs={12} sm={8} md={4}>
                  <Input
                    id="notaFiscal"
                    name="notaFiscal"
                    ref="notaFiscal"
                    placeholder="Nota Fiscal"
                    label="Nota Fiscal"
                    type="text"
                    onChange={this.onChangeFiltro}
                    value={filtros.get('notaFiscal')}
                  />
                </Col>
                <Col xs={12} sm={6} md={2}>
                  <DatePicker
                    id="dataInicial"
                    name="dataInicial"
                    ref="dataInicial"
                    label="Data Pagamento Inicial"
                    onChange={this.onChangeFiltro}
                    value={filtros.get('dataInicial')}
                  />
                </Col>
                <Col xs={12} sm={6} md={2}>
                  <DatePicker
                    id="dataFinal"
                    name="dataFinal"
                    ref="dataFinal"
                    label="Data Pagamento Final"
                    onChange={this.onChangeFiltro}
                    value={filtros.get('dataFinal')}
                  />
                </Col>

                <Col xs={12} sm={4} md={4}>
                  <FilterButtons
                    idBtnReset="btnResetFilter"
                    onConsulta={this.onConsultaOrdemCronologicaPagamento}
                    onReset={this.onResetFiltro}
                  />
                </Col>
              </Row>
            </form>
          </Panel>

          <Panel isForm tableResponsive>
            <Col xs={6}>
              <UltimaAtualizacaoByTable
                modulo="3"
                table="liquidacao"
                column="dataultimaatualizacao"
              />
            </Col>

            <LiquidacoesListagem
              page={pagamentos}
              total={totalPagamentos}
              filter={this.montarFiltro(pagamentos, 'pagamentos')}
              tipo={'ordem-cronologica-pagamentos'}
              history={this.props.history}
              searchWithPage={this.searchPagamentosWithPage}
              liquidacao={pagamentos.get('content')}
              mensagem={mensagem}
              onExporta={this.onExportaPagamentos}
            />

            <SearchPagination
              page={pagamentos}
              searchWithPage={this.searchPagamentosWithPage}
            />
          </Panel>
        </section>
      );
    } else {
      return (
        <section>
          <PageHeader location={location} />

          <NotasExplicativas pathname={this.props.location.pathname} />

          <Panel
            isForm
            mobileClose
            title="Consulta Ordem Cronológica de Pagamentos"
            ref="panelFiltroOrdemCronologicaPagamento"
          >
            <form>
              <Row>
                <Col xs={12} sm={4} md={4}>
                  <Input
                    id="cnpj"
                    name="cnpj"
                    ref="cnpj"
                    placeholder="CNPJ/CPF"
                    label="CNPJ/CPF"
                    type="text"
                    onChange={this.onChangeFiltro}
                    value={filtros.get('cnpj')}
                  />
                </Col>
                <Col xs={12} sm={8} md={8}>
                  <Input
                    id="nome"
                    name="nome"
                    ref="nome"
                    placeholder="Nome"
                    label="Nome"
                    type="text"
                    onChange={this.onChangeFiltro}
                    value={filtros.get('nome')}
                  />
                </Col>
                <Col xs={12} sm={8} md={4}>
                  <Input
                    id="notaFiscal"
                    name="notaFiscal"
                    ref="notaFiscal"
                    placeholder="Nota Fiscal"
                    label="Nota Fiscal"
                    type="text"
                    onChange={this.onChangeFiltro}
                    value={filtros.get('notaFiscal')}
                  />
                </Col>
                <Col xs={12} sm={6} md={2}>
                  <DatePicker
                    id="dataInicial"
                    name="dataInicial"
                    ref="dataInicial"
                    label="Data Pagamento Inicial"
                    onChange={this.onChangeFiltro}
                    value={filtros.get('dataInicial')}
                  />
                </Col>
                <Col xs={12} sm={6} md={2}>
                  <DatePicker
                    id="dataFinal"
                    name="dataFinal"
                    ref="dataFinal"
                    label="Data Pagamento Final"
                    onChange={this.onChangeFiltro}
                    value={filtros.get('dataFinal')}
                  />
                </Col>

                <Col xs={12} sm={4} md={4}>
                  <FilterButtons
                    idBtnReset="btnResetFilter"
                    onConsulta={this.onConsultaOrdemCronologicaPagamento}
                    onReset={this.onResetFiltro}
                  />
                </Col>
              </Row>
            </form>
          </Panel>

          <Panel isForm tableResponsive>
            <Row>
              <Col xs={6}>
                <UltimaAtualizacaoByTable
                  modulo="3"
                  table="liquidacao"
                  column="dataultimaatualizacao"
                />
              </Col>
              <Col xs={6} />
            </Row>

            <Tabs ref="tab">
              <Tab
                title="Fornecimento de Bens"
                name="fornecimentoBens"
                onlyTable
                active
              >
                <LiquidacoesListagem
                  page={fornecimentoBens}
                  total={totalFornecimentoBens}
                  filter={this.montarFiltro(
                    fornecimentoBens,
                    'fornecimentoBens'
                  )}
                  tipo={'ordem-cronologica-pagamentos'}
                  history={this.props.history}
                  searchWithPage={this.searchFornecimentoBensWithPage}
                  liquidacao={fornecimentoBens.get('content')}
                  mensagem={mensagem}
                  onExporta={this.onExportaFornecimentoBens}
                />

                <SearchPagination
                  page={fornecimentoBens}
                  searchWithPage={this.searchFornecimentoBensWithPage}
                />
              </Tab>

              <Tab title="Locações" name="locacoes" onlyTable>
                <LiquidacoesListagem
                  page={locacoes}
                  total={totalLocacoes}
                  filter={this.montarFiltro(locacoes, 'locacoes')}
                  tipo={'ordem-cronologica-pagamentos'}
                  history={this.props.history}
                  searchWithPage={this.searchLocacoesWithPage}
                  liquidacao={locacoes.get('content')}
                  mensagem={mensagem}
                  onExporta={this.onExportaLocacoes}
                />

                <SearchPagination
                  page={locacoes}
                  searchWithPage={this.searchLocacoesWithPage}
                />
              </Tab>

              <Tab
                title="Prestação de Serviços"
                name="prestacaoServicos"
                onlyTable
              >
                <LiquidacoesListagem
                  page={prestacaoServicos}
                  total={totalPrestacaoServicos}
                  filter={this.montarFiltro(
                    prestacaoServicos,
                    'prestacaoServicos'
                  )}
                  tipo={'ordem-cronologica-pagamentos'}
                  history={this.props.history}
                  searchWithPage={this.searchPrestacaoServicosWithPage}
                  liquidacao={prestacaoServicos.get('content')}
                  mensagem={mensagem}
                  onExporta={this.onExportaPrestacaoServicos}
                />

                <SearchPagination
                  page={prestacaoServicos}
                  searchWithPage={this.searchPrestacaoServicosWithPage}
                />
              </Tab>

              <Tab title="Obras" name="obras" onlyTable>
                <LiquidacoesListagem
                  page={obras}
                  total={totalObras}
                  filter={this.montarFiltro(obras, 'obras')}
                  tipo={'ordem-cronologica-pagamentos'}
                  history={this.props.history}
                  searchWithPage={this.searchObrasWithPage}
                  liquidacao={obras.get('content')}
                  mensagem={mensagem}
                  onExporta={this.onExportaObras}
                />

                <SearchPagination
                  page={obras}
                  searchWithPage={this.searchObrasWithPage}
                />
              </Tab>
            </Tabs>
          </Panel>
        </section>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    filtros: state.crud.getIn(
      ['filtroOrdemCronologicaPagamento', 'currentRecord'],
      Map({})
    ),
    fornecimentoBens: state.ordemcronologicapagamento.get('fornecimentoBens'),
    locacoes: state.ordemcronologicapagamento.get('locacoes'),
    prestacaoServicos: state.ordemcronologicapagamento.get('prestacaoServicos'),
    obras: state.ordemcronologicapagamento.get('obras'),
    pagamentos: state.ordemcronologicapagamento.get('pagamentos'),
    totalFornecimentoBens: state.ordemcronologicapagamento.get(
      'totalFornecimentoBens'
    ),
    totalLocacoes: state.ordemcronologicapagamento.get('totalLocacoes'),
    totalPrestacaoServicos: state.ordemcronologicapagamento.get(
      'totalPrestacaoServicos'
    ),
    totalObras: state.ordemcronologicapagamento.get('totalObras'),
    totalPagamentos: state.ordemcronologicapagamento.get('totalPagamentos'),
    uf: state.entidade.getIn(['entidadeSelecionada', 'uf'])
  };
}

const allActions = {
  ...actions,
  onChange,
  resetCrud,
  addErrorNotification
};

export default connect(
  mapStateToProps,
  allActions
)(FormOrdemCronologicaPagamentos);
