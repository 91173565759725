import { addSuccessNotification, addErrorNotification } from '../ui/Actions.js';

// Este dependency injection middleware. So simple that we don't need a lib.
// It's like mixed redux-thunk and redux-inject.
export const injectMiddleware = deps => ({
  dispatch,
  getState
}) => next => action => {
  const { dispatchedCreateFetch, browserHistory, ...rest } = deps;

  if (typeof action === 'function') {
    //POST, PUT, DELETE, FETCH
    const fetchMethods = dispatchedCreateFetch({
      dispatch,
      getState,
      browserHistory
    });

    return next(
      action({ ...rest, ...fetchMethods, dispatch, getState, browserHistory })
    );
  } else {
    return next(action);
  }
};

const NOTIFICATION_SUCCESS_PATTERN = /(SAVE|UPDATE|DELETE)_[A-Z]+_SUCCESS/;

const NOTIFICATION_ERROR_PATTERN = /_ERROR/;

export const notificationMiddleware = store => next => action => {
  if (!action.type) {
    return next(action);
  }

  if (action.type.match(NOTIFICATION_SUCCESS_PATTERN)) {
    if (action.type.match(/SAVE/)) {
      store.dispatch(addSuccessNotification('Adicionado com sucesso'));
    } else if (action.type.match(/UPDATE/)) {
      store.dispatch(addSuccessNotification('Atualizado com sucesso'));
    } else if (action.type.match(/DELETE/)) {
      store.dispatch(addSuccessNotification('Apagado com sucesso'));
    }
  } else if (action.type.match(NOTIFICATION_ERROR_PATTERN)) {
    store.dispatch(
      addErrorNotification(action.payload.message, action.payload)
    );
  }

  return next(action);
};
