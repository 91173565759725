import { Map } from 'immutable';
import Input from 'portaltransparencia-common/components/EloInput.react.js';
import ExportButtonGroup from 'portaltransparencia-common/components/ExportButtonGroup.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import Tab from 'portaltransparencia-common/components/Tab.react.js';
import Tabs from 'portaltransparencia-common/components/Tabs.react.js';
import configuration from 'portaltransparencia-common/config/Config.js';
import {
  onChange,
  resetCrud
} from 'portaltransparencia-common/crud/Actions.js';
import {
  addFilterUrl,
  addPagination
} from 'portaltransparencia-common/libs/AddFilter.js';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import FilterButtons from '../components/FilterButtons.react';
import PageHeader from '../components/PageHeader.react.js';
import NotasExplicativas from '../notaexplicativa/Form.react';
import UltimaAtualizacaoByTable from '../ultimaatualizacao/UltimaAtualizacaoByTable.react.js';
import * as actions from './Actions.js';
import CedidoListagem from './CedidoListagem.react.js';
import { addErrorNotification } from 'portaltransparencia-common/ui/Actions.js';

export class FormCedido extends Component {
  static propTypes = {
    cedidosDe: PropTypes.object.isRequired,
    findCedidosDe: PropTypes.func.isRequired,
    cedidosPara: PropTypes.object.isRequired,
    findCedidosPara: PropTypes.func.isRequired,
    histCedidosDe: PropTypes.object.isRequired,
    findHistCedidosDe: PropTypes.func.isRequired,
    histCedidosPara: PropTypes.object.isRequired,
    findHistCedidosPara: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    resetCrud: PropTypes.func,
    filtros: PropTypes.object,
    location: PropTypes.object.isRequired,
    valorParametro: PropTypes.string,
    addErrorNotification: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.carregarCedidos();
  }

  searchWithPageCedidosDe = page => {
    const { findCedidosDe } = this.props;
    findCedidosDe(this.montaFiltro(90, page));
  };

  searchWithPageCedidosPara = page => {
    const { findCedidosPara } = this.props;
    findCedidosPara(this.montaFiltro(23, page));
  };

  searchWithPageHistCedidosDe = page => {
    const { findHistCedidosDe } = this.props;
    findHistCedidosDe(this.montaFiltro(90, page));
  };

  searchWithPageHistCedidosPara = page => {
    const { findHistCedidosPara } = this.props;
    findHistCedidosPara(this.montaFiltro(23, page));
  };

  montaFiltro = (tipoMovimentacao, page) => {
    const { entidade, exercicio } = StorageService.getItem('userInfo');

    let filter = 'entidade=' + entidade.id + '&exercicio=' + exercicio.id;

    const { filtros } = this.props;

    if (filtros && filtros.get('nome')) {
      filter = addFilterUrl(filter, `nome=${filtros.get('nome')}`);
    }
    if (tipoMovimentacao) {
      filter = addFilterUrl(filter, `tipoMovimentacao=${tipoMovimentacao}`);
    }

    return addPagination(page, filter);
  };

  onExporta = (exportType, openTab, windowParams) => {
    const { apiURL } = configuration;
    const {
      cedidosDe,
      cedidosPara,
      histCedidosDe,
      histCedidosPara,
      addErrorNotification
    } = this.props;
    let page = cedidosDe;
    let tipo = 90;
    let filtro = '';
    if (this.refs.tab) {
      switch (this.refs.tab.getActiveTabName()) {
        case 'cedidosPara':
          tipo = 23;
          filtro = this.montaFiltro(tipo, cedidosPara);
          page = cedidosPara;
          break;
        case 'histCedidosDe':
          tipo = 90;
          filtro = this.montaFiltro(tipo, histCedidosDe);
          filtro = `${filtro}&cedenteFinalizado=true`;
          page = histCedidosDe;
          break;
        case 'histCedidosPara':
          tipo = 23;
          filtro = this.montaFiltro(tipo, histCedidosPara);
          filtro = `${filtro}&cedenteFinalizado=true`;
          page = histCedidosPara;
          break;
        default:
          tipo = 90;
          filtro = this.montaFiltro(tipo, cedidosDe);
          page = cedidosDe;
      }
    }

    if (page.get('content') && page.get('content').size > 0) {
      filtro = `${filtro}&exportType=${exportType}`;
      window.open(
        `${apiURL}/api/cedidos/report?${filtro}`,
        openTab,
        windowParams
      );
    } else {
      addErrorNotification(
        'A consulta não apresentou resultado. Não é possível exportar para o formato selecionado!'
      );
    }
  };

  carregarCedidos = () => {
    const {
      findCedidosDe,
      findCedidosPara,
      findHistCedidosDe,
      findHistCedidosPara,
      cedidosDe,
      cedidosPara,
      histCedidosDe,
      histCedidosPara
    } = this.props;

    findCedidosDe(this.montaFiltro(90, cedidosDe));
    findCedidosPara(this.montaFiltro(23, cedidosPara));
    findHistCedidosDe(this.montaFiltro(90, histCedidosDe));
    findHistCedidosPara(this.montaFiltro(23, histCedidosPara));
  };

  consultarCedidos = event => {
    this.carregarCedidos();

    event && event.preventDefault();
    this.refs.panelFiltroServidores &&
      this.refs.panelFiltroServidores.collapse();
  };

  onResetFiltro = () => {
    const {
      findCedidosDe,
      findCedidosPara,
      findHistCedidosDe,
      findHistCedidosPara,
      resetCrud
    } = this.props;

    resetCrud('filtroCedidos');
    findCedidosDe('tipoMovimentacao=90');
    findCedidosPara('tipoMovimentacao=23');
    findHistCedidosDe('tipoMovimentacao=90');
    findHistCedidosPara('tipoMovimentacao=23');
  };

  onChangeFiltro = event => {
    const { onChange } = this.props;
    onChange('filtroCedidos', event);
  };

  render() {
    const {
      cedidosDe,
      cedidosPara,
      histCedidosDe,
      histCedidosPara,
      filtros
    } = this.props;

    return (
      <section>
        <form>
          <PageHeader location={this.props.location} />

          <NotasExplicativas pathname={this.props.location.pathname} />

          <Panel
            title="Consultar em Cedidos"
            isForm
            mobileClose
            ref="panelFiltroServidores"
          >
            <Row>
              <Col xs={12} sm={8} md={9}>
                <Input
                  id="nome"
                  type="text"
                  name="nome"
                  label="Nome"
                  placeholder="Informe o nome"
                  value={filtros.get('nome')}
                  onChange={this.onChangeFiltro}
                />
              </Col>
              <Col xs={12} sm={4} md={3}>
                <FilterButtons
                  onConsulta={this.consultarCedidos}
                  onReset={this.onResetFiltro}
                />
              </Col>
            </Row>
          </Panel>

          <Panel isForm expansible={false} tableResponsive>
            <Row>
              <Col xs={6}>
                <UltimaAtualizacaoByTable
                  modulo="1"
                  table="rhfechamentocontabil"
                  column="data"
                  filtrarEntidade
                />
              </Col>
              <Col xs={6}>
                <ExportButtonGroup onClick={this.onExporta} visible={true} />
              </Col>
            </Row>

            <Tabs ref="tab">
              <Tab title="Cedidos De" name="cedidosDe" onlyTable active>
                <CedidoListagem
                  page={cedidosDe}
                  searchWithPage={this.searchWithPageCedidosDe}
                  entityColumnTitle="Órgão de Origem"
                />
              </Tab>

              <Tab title="Cedidos Para" name="cedidosPara" onlyTable>
                <CedidoListagem
                  page={cedidosPara}
                  searchWithPage={this.searchWithPageCedidosPara}
                  entityColumnTitle="Órgão de Destino"
                />
              </Tab>

              <Tab
                title="Cedidos De - Finalizados"
                name="histCedidosDe"
                onlyTable
              >
                <CedidoListagem
                  page={histCedidosDe}
                  searchWithPage={this.searchWithPageHistCedidosDe}
                />
              </Tab>

              <Tab
                title="Cedidos Para - Finalizados"
                name="histCedidosPara"
                onlyTable
              >
                <CedidoListagem
                  page={histCedidosPara}
                  searchWithPage={this.searchWithPageHistCedidosPara}
                />
              </Tab>
            </Tabs>
          </Panel>
        </form>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    filtros: state.crud.getIn(['filtroCedidos', 'currentRecord'], Map({})),
    cedidosDe: state.cedido.get('cedidosDe'),
    cedidosPara: state.cedido.get('cedidosPara'),
    histCedidosDe: state.cedido.get('histCedidosDe'),
    histCedidosPara: state.cedido.get('histCedidosPara')
  };
}

const allActions = {
  ...actions,
  onChange,
  resetCrud,
  addErrorNotification
};

export default connect(mapStateToProps, allActions)(FormCedido);
