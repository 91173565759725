import * as types from './Types.js';

export function findConvenios(filter) {
  return ({ fetch }) => ({
    type: types.CONVENIOS,
    payload: {
      promise: fetch(`/api/convenios?${filter}`)
    }
  });
}

export function resetConsulta() {
  return {
    type: types.RESET_CONSULTA_CONVENIOS,
    payload: {}
  };
}

export function findConvenio(entidade, id, nrConvenio, nrAnoConvenio) {
  return ({ fetch }) => ({
    type: types.CONVENIO,
    payload: {
      promise: fetch(
        `/api/convenios?entidade=${entidade}&id=${id}&nrConvenio=${nrConvenio}&nrAnoConvenio=${nrAnoConvenio}`
      )
    }
  });
}

export function findAditivos(entidade, id) {
  return ({ fetch }) => ({
    type: types.ADITIVOS_CONVENIO,
    payload: {
      promise: fetch(`/api/convenios/aditivos?entidade=${entidade}&id=${id}`)
    }
  });
}

export function findArquivos(entidade, id) {
  return ({ fetch }) => ({
    type: types.ARQUIVOS_CONVENIO,
    payload: {
      promise: fetch(`/api/convenios/arquivos?entidade=${entidade}&id=${id}`)
    }
  });
}

export function findPagamentos(entidade, idContaCorrente, local, id) {
  return ({ fetch }) => ({
    type: types.PAGAMENTO_CONVENIO,
    payload: {
      promise: fetch(
        `/api/convenios/pagamentos?entidade=${entidade}&idContaCorrente=${idContaCorrente}&local=${local}&id=${id}`
      )
    }
  });
}

export function findRepasses(entidade, id) {
  return ({ fetch }) => ({
    type: types.REPASSES_CONVENIO,
    payload: {
      promise: fetch(`/api/convenios/repasses?entidade=${entidade}&id=${id}`)
    }
  });
}

export function findContraPartidaRepasses(
  entidade,
  nrConvenio,
  nrAnoConvenio,
  idContaCorrente,
  tipo,
  local,
  id
) {
  return ({ fetch }) => ({
    type: types.CONTRAPARTIDA_REPASSES,
    payload: {
      promise: fetch(
        `/api/convenios/contrapartida?entidade=${entidade}&nrConvenio=${nrConvenio}&nrAnoConvenio=${nrAnoConvenio}&idContaCorrente=${idContaCorrente}&tipo=${tipo}&local=${local}&id=${id}`
      )
    }
  });
}

export function findContraPartidaDevolucao(
  entidade,
  nrConvenio,
  nrAnoConvenio,
  idContaCorrente,
  tipo,
  local,
  id
) {
  return ({ fetch }) => ({
    type: types.CONTRAPARTIDA_DEVOLUCAO,
    payload: {
      promise: fetch(
        `/api/convenios/contrapartida?entidade=${entidade}&nrConvenio=${nrConvenio}&nrAnoConvenio=${nrAnoConvenio}&idContaCorrente=${idContaCorrente}&tipo=${tipo}&local=${local}&id=${id}`
      )
    }
  });
}

export function findContraPartidaPagamento(
  entidade,
  nrConvenio,
  nrAnoConvenio,
  idContaCorrente,
  tipo,
  local,
  id
) {
  return ({ fetch }) => ({
    type: types.CONTRAPARTIDA_PAGAMENTO,
    payload: {
      promise: fetch(
        `/api/convenios/contrapartida?entidade=${entidade}&nrConvenio=${nrConvenio}&nrAnoConvenio=${nrAnoConvenio}&idContaCorrente=${idContaCorrente}&tipo=${tipo}&local=${local}&id=${id}`
      )
    }
  });
}

export function findSituacoes() {
  return ({ fetch }) => ({
    type: types.SITUACOES_CONVENIO,
    payload: {
      promise: fetch('/api/tipos-situacao-convenio')
    }
  });
}
