import Modal from 'portaltransparencia-common/components/Modal.react.js';
import SlideShow from 'portaltransparencia-common/components/slideshow/SlideShow.react.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export class ObraDetalheMedicaoImagem extends Component {
  static propTypes = {
    imagens: PropTypes.object.isRequired,
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
  };

  render() {
    const { imagens, show, onClose } = this.props;

    if (show && !imagens.isEmpty()) {
      return (
        <Modal onClose={onClose}>
          <SlideShow className="info" images={imagens} />
        </Modal>
      );
    }

    return null;
  }
}

export default ObraDetalheMedicaoImagem;
