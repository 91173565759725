import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

class TreeTableItem extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    dataLevel: PropTypes.string,
    onClickButton: PropTypes.func,
    level: PropTypes.number,
    father: PropTypes.bool,
    title: PropTypes.string,
    icon: PropTypes.string,
    showButton: PropTypes.bool,
    data: PropTypes.any.isRequired,
    open: PropTypes.bool,
    visible: PropTypes.bool,
    onClickButtonGraph: PropTypes.func,
    showButtonGraph: PropTypes.bool,
    iconGraph: PropTypes.string
  };

  static defaultProps = {
    title: 'Ver mais',
    icon: 'fa fa-chevron-right',
    showButtonGraph: false,
    iconGraph: 'fa fa-bar-chart'
  };

  onClickButton = e => {
    const { onClickButton, data } = this.props;

    e.preventDefault();

    onClickButton(data);
  };

  onClickButtonGraph = e => {
    const { onClickButtonGraph, data } = this.props;

    onClickButtonGraph(data);
    e.preventDefault();
  };

  render = () => {
    const {
      children,
      dataLevel,
      onClickButton,
      father,
      level,
      title,
      icon,
      showButton,
      open,
      visible,
      showButtonGraph,
      onClickButtonGraph,
      iconGraph
    } = this.props;

    if (!visible) {
      return null;
    }

    const className = classnames({
      father: father,
      [`level level-${level - 1}`]: level > 1 && true,
      open: open
    });

    const rowStyle = {
      borderLeftWidth: (level - 1) * 30,
      display: 'table-row'
    };

    return (
      <tr
        ref="row"
        className={className}
        style={rowStyle}
        data-level={dataLevel}
      >
        {children}
        <td>
          <div className="btn-actions">
            <div className="btn-actions-inner">
              {showButtonGraph && onClickButtonGraph && (
                <div className="btn-actions">
                  <div className="btn-actions-inner">
                    <button
                      type="button"
                      className="btn"
                      onClick={this.onClickButtonGraph}
                      title={title}
                    >
                      <i className={iconGraph}></i>
                    </button>
                  </div>
                </div>
              )}
              {showButton && onClickButton && (
                <div className="btn-actions">
                  <div className="btn-actions-inner">
                    <button
                      type="button"
                      className="btn"
                      onClick={this.onClickButton}
                      title={title}
                    >
                      <i className={icon}></i>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </td>
      </tr>
    );
  };
}

export default TreeTableItem;
