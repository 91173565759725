import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export default class PatrimonioLocalRow extends Component {
  static propTypes = {
    local: PropTypes.object.isRequired
  };

  render() {
    const { local } = this.props;

    return (
      <tr key={local.get('descricaoNivel')}>
        <td>{local.get('descricaoNivel')}</td>
        <td>{local.get('descricaoLocal')}</td>
      </tr>
    );
  }
}
