import StorageService from 'portaltransparencia-common/libs/StorageService.js';

import * as types from './Types.js';

export function searchLeisAtos(filter) {
  const { entidade } = StorageService.getItem('userInfo');

  const baseFilter = `entidade=${entidade.id}&`;
  return ({ fetch }) => ({
    type: types.CONSULTA_LEISATOS,
    payload: {
      promise: fetch(`/api/leisatos?${baseFilter}${filter}`)
    }
  });
}

export function LimpaPesquisa() {
  return {
    type: types.CLEAR_LEISATOS,
    payload: {}
  };
}

export function downloadLegislacao(data, tipo, natureza) {
  const { id, idArquivo, ano, tabela } = data.toJS();

  let url = `/api/files/legislacao/${id}/${idArquivo}/${ano}/${natureza}/${tabela}`;
  if (tipo !== undefined) {
    url = `/api/files/legislacao/${id}/${idArquivo}/${ano}/${natureza}/${tipo}/${tabela}`;
  }

  return ({ fetch }) => ({
    type: types.ARQUIVO_DOWNLOAD_LEIS,
    payload: {
      promise: fetch(url)
        .then(response => response.blob())
        .then(file => URL.createObjectURL(file))
        .then(fileURL => window.open(fileURL, '_blank'))
    }
  });
}

export function findLeisAtosDetalhes(filter) {
  return ({ fetch }) => ({
    type: types.LEISATOS_DETALHE,
    payload: {
      promise: fetch(`/api/leisatos/detalhes/?${filter}`)
    }
  });
}

export function clearDetalhe() {
  return {
    type: types.CLEAR_DETALHE,
    payload: {}
  };
}

export function loadLeisAtosNatureza(filter) {
  return ({ fetch }) => ({
    type: types.LEIS_ATOS_NATUREZA,
    payload: {
      promise: fetch(`/api/leisatos/naturezas/?${filter}`)
    }
  });
}

export function loadTiposDocumentoLegal(filter) {
  return ({ fetch }) => ({
    type: types.LIST_TIPOS_DOCUMENTO_LEGAL,
    payload: {
      promise: fetch(`/api/leisatos/tipos-documento-legal/?${filter}`)
    }
  });
}

export function loadTipoEscopo(filter) {
  return ({ fetch }) => ({
    type: types.LIST_TIPOS_ESCOPO,
    payload: {
      promise: fetch(`/api/leisatos/escopo/?${filter}`)
    }
  });
}
