import React from 'react';
import Component from 'react-pure-render/component';
import PropTypes from 'prop-types';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import TableHeaderItem from 'portaltransparencia-common/components/TableHeaderItem.react';
import TableActions from 'portaltransparencia-common/components/TableActions.react';
import DateUtils from 'portaltransparencia-common/libs/DateUtils';

export default class PcaTable extends Component {
  static propTypes = {
    page: PropTypes.object.isRequired,
    searchWithPage: PropTypes.func.isRequired,
    isIntegradoOxy: PropTypes.bool
  };

  renderPca = pca => {
    const key = pca.get('planoContratacao');

    return (
      <TableActions ref="row" key={key} data={pca} onMore={this.onViewDetalhes}>
        <td id="tdPlanoContratacao">{pca.get('planoContratacao')}</td>
        <td id="tdDataPca">{DateUtils.transform(pca.get('dataPca'))}</td>
        <td id="tdUnidadeDescricao">{pca.get('unidadeDescricao')}</td>
        <td id="tdStatusDescricao">{pca.get('statusDescricao')}</td>
      </TableActions>
    );
  };

  onViewDetalhes = item => {
    const { history } = this.props;
    history.push(
      '/pca/detalhe?entidade=' +
        item.get('entidade') +
        '&exercicio=' +
        item.get('exercicio') +
        '&planoContratacao=' +
        item.get('planoContratacao')
    );
  };

  render() {
    const { page, searchWithPage, isIntegradoOxy } = this.props;
    let pcas = page.get('content');

    return (
      <div className="panel-table-outer">
        <table className="panel-table striped fancy">
          <TableHeader
            empty={pcas.isEmpty()}
            text="Não foi encontrado nenhum PCA para a pesquisa selecionada."
          >
            <tr>
              <TableHeaderItem
                field="planoContratacao"
                label="Nº PCA"
                page={page}
                searchWithPage={searchWithPage}
              />
              <TableHeaderItem
                field="dataPca"
                label="Data"
                page={page}
                searchWithPage={searchWithPage}
              />
              <TableHeaderItem
                field="unidadeDescricao"
                label="Órgão"
                page={page}
                searchWithPage={searchWithPage}
              />
              <TableHeaderItem
                field="statusDescricao"
                label="Status"
                page={page}
                searchWithPage={searchWithPage}
              />
              <th></th>
            </tr>
          </TableHeader>
          <tbody>{pcas.map(this.renderPca)}</tbody>
        </table>
      </div>
    );
  }
}
