import Hint from 'portaltransparencia-common/components/hint/Hint.react';
import Widget from 'portaltransparencia-common/components/Widget.react.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils';
import { format } from 'portaltransparencia-common/libs/NumberUtils';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Chart } from 'react-google-charts';
import Component from 'react-pure-render/component';

export class ReceitaAno extends Component {
  static propTypes = {
    arrecadadoSobrePrevisto: PropTypes.number.isRequired,
    receitaValorAtualizado: PropTypes.number.isRequired,
    valorArrecadado: PropTypes.number.isRequired,
    exercicio: PropTypes.any.isRequired,
    receitaDashboard: PropTypes.object.isRequired
  };

  getDataChartBar = data => {
    const dataChart = [['Mês', 'Previsto', 'Arrecadado']];

    return dataChart.concat(
      data
        .map(item => {
          return [
            DateUtils.getMonthDescription(item.get('mes'), true),
            item.get('valorPrevisto'),
            item.get('valorArrecadado')
          ];
        })
        .toJS()
    );
  };

  getOptions = () => {
    let { width } = this.props;
    if (width > 400) {
      width = width * 0.8;
    }

    return {
      bar: { groupWidth: '90%' },
      legend: { position: 'top' },
      width: width,
      height: 300,
      colors: ['#B82E2E', '#3366CC']
    };
  };

  render() {
    const {
      arrecadadoSobrePrevisto,
      receitaValorAtualizado,
      valorArrecadado,
      exercicio,
      receitaDashboard
    } = this.props;

    const options = this.getOptions();
    const dataChart = this.getDataChartBar(receitaDashboard);

    return (
      <Widget
        icon="fa fa-line-chart"
        title={'Valores da receita no ano de ' + exercicio}
      >
        <Row>
          <Col sm={4} md={4}>
            <article className="widget-numeric block">
              <b className="widget-numeric-number">
                {valorArrecadado && format(valorArrecadado)}
              </b>
              <div className="widget-numeric-subtitle">
                <Hint
                  message={`Soma dos valores arrecadados de todos os meses de ${exercicio}.`}
                  size="xs"
                  position="bottom-left"
                  spacing="mr-xs"
                  isInline
                />
                Valor Arrecadado
              </div>
            </article>
          </Col>
          <Col sm={4} md={4}>
            <article className="widget-numeric block">
              <b className="widget-numeric-number">
                {receitaValorAtualizado && format(receitaValorAtualizado)}
              </b>
              <p className="widget-numeric-subtitle">
                Valor Previsto Atualizado
              </p>
            </article>
          </Col>
          <Col sm={4} md={4}>
            <article className="widget-numeric block">
              <b className="widget-numeric-number">
                {arrecadadoSobrePrevisto &&
                  format(arrecadadoSobrePrevisto) + '%'}
              </b>
              <div className="widget-numeric-subtitle">
                Percentual Arrecadado sobre Previsto
                <Hint
                  message={`Porcentagem da soma dos valores arrecadados de todos os meses de ${exercicio} pelo valor previsto atualizado.`}
                  size="xs"
                  isInline
                />
              </div>
            </article>
          </Col>
        </Row>

        <Row>
          <Col xs={12} sm={9} md={9}>
            {receitaDashboard.size > 0 && (
              <Chart
                ref="chart"
                chartType="ColumnChart"
                data={dataChart}
                legend_toggle
                graphID="dashboard-bar-chart"
                options={options}
                width={`${options.width}px`}
                chartLanguage="pt-br"
              />
            )}
          </Col>
        </Row>
      </Widget>
    );
  }
}

export default ReceitaAno;
