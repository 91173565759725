import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react.js';
import SumListField from 'portaltransparencia-common/components/SumListField.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import * as actions from './Actions.js';

export class EmpenhoDetalheEmLiquidacao extends Component {
  static propTypes = {
    emLiquidacao: PropTypes.object.isRequired
  };

  renderItens = data => {
    let noLiquidacao = data.get('noEmLiquidacao') + '/' + data.get('exercicio');

    return (
      <tr key={noLiquidacao} data={data}>
        <td id="noLiquidacao">{noLiquidacao}</td>
        <td id="data">{DateUtils.formatDateTimeShort(data.get('data'))}</td>
        <td id="valor" className="right">
          <NumberFormatter value={data.get('valor')} />
        </td>
        <td id="valorEstornado" className="right">
          <NumberFormatter value={data.get('valorEstornado')} />
        </td>
        <td id="valorLiquidado" className="right">
          <NumberFormatter value={data.get('valorLiquidado')} />
        </td>
      </tr>
    );
  };

  render() {
    const { emLiquidacao } = this.props;

    return (
      <div className="panel-table-outer">
        <table className="panel-table striped fancy">
          <TableHeader empty={emLiquidacao.isEmpty()}>
            <tr>
              <th>Liquidação</th>
              <th>Data</th>
              <th className="right">Valor</th>
              <th className="right">Valor Estornado</th>
              <th className="right">Valor Liquidado</th>
            </tr>
          </TableHeader>
          <tbody>{emLiquidacao.map(this.renderItens)}</tbody>

          {!emLiquidacao.isEmpty() && (
            <tfoot>
              <tr>
                <td></td>
                <td></td>
                <td className="right">
                  <i>Total:</i>{' '}
                  {<SumListField fieldName="valor" list={emLiquidacao} />}
                </td>
                <td className="right">
                  <i>Total:</i>{' '}
                  {
                    <SumListField
                      fieldName="valorEstornado"
                      list={emLiquidacao}
                    />
                  }
                </td>
                <td className="right">
                  <i>Total:</i>{' '}
                  {
                    <SumListField
                      fieldName="valorLiquidado"
                      list={emLiquidacao}
                    />
                  }
                </td>
              </tr>
            </tfoot>
          )}
        </table>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    emLiquidacao: state.empenhos.get('emLiquidacao')
  };
}

const allActions = {
  ...actions
};

export default connect(mapStateToProps, allActions)(EmpenhoDetalheEmLiquidacao);
