import { Map } from 'immutable';
import AutoComplete from 'portaltransparencia-common/components/AutoComplete.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import {
  onChange,
  resetCrud
} from 'portaltransparencia-common/crud/Actions.js';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import * as actions from '../Actions.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import {
  addFilterUrl,
  addPagination
} from 'portaltransparencia-common/libs/AddFilter.js';

export class CargosFiltroOxy extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    resetCrud: PropTypes.func.isRequired,
    errors: PropTypes.object,
    filtros: PropTypes.object,
    matrizesVigentes: PropTypes.object,
    loadCargos: PropTypes.func.isRequired,
    findTipoCargoSelect: PropTypes.func.isRequired,
    findMatrizes: PropTypes.func.isRequired,
    loadCargosReduzido: PropTypes.func.isRequired,
    findVigenciaMatrizSalarialSelect: PropTypes.func.isRequired
  };

  onPesquisa = event => {
    const { loadCargos } = this.props;

    loadCargos(this.montarFiltroCargo());

    event && event.preventDefault();
    this.refs.panelFiltroCargos && this.refs.panelFiltroCargos.collapse();
  };

  montarFiltroCargo = page => {
    const { filtros } = this.props;

    let filter = '';
    if (filtros.get('cargo')) {
      filter = addFilterUrl(filter, `id=${filtros.get('cargo').getIn(['id'])}`);
    }

    if (filtros.get('planoSalarial')) {
      filter = addFilterUrl(
        filter,
        `planoSalarialId=${filtros.get('planoSalarial').getIn(['id'])}`
      );
    }
    if (filtros.get('tipoCargo')) {
      filter = addFilterUrl(
        filter,
        `cargoTipo=${filtros.get('tipoCargo').getIn(['value'])}`
      );
    }
    if (filtros.get('matrizSalarial')) {
      filter = addFilterUrl(
        filter,
        `matriz=${filtros.get('matrizSalarial').getIn(['id'])}`
      );
    }
    if (filtros.get('vigenciaMatrizSalarial')) {
      filter = addFilterUrl(
        filter,
        `matrizSalarialVigencia=${filtros.get('vigenciaMatrizSalarial')}`
      );
    }
    return addPagination(page, filter);
  };

  onChangeFiltro = event => {
    const { onChange } = this.props;
    onChange('filtroCargos', event);

    new Promise(resolve => {
      onChange('filtroCargos', event);
      resolve();
    }).then(() => {
      switch (event.name) {
        case 'cargo': {
          this.findCargos();
          break;
        }

        case 'planoSalarial': {
          this.findMatrizes();
          break;
        }

        case 'tipoCargo': {
          this.findTipoCargos();
          break;
        }

        case 'matrizSalarial': {
          this.findVigenciaMatrizSalarial();
        }
      }
    });
  };

  onResetFiltro = () => {
    const { resetCrud, loadCargos } = this.props;

    resetCrud('filtroCargos');
    loadCargos('');
  };

  findCargos = param => {
    const { loadCargosReduzido, filtro } = this.props;
    return loadCargosReduzido(filtro, param);
  };

  findTipoCargos = param => {
    const { findTipoCargoSelect, filtro } = this.props;
    return findTipoCargoSelect(filtro, param);
  };

  findVigenciaMatrizSalarial = () => {
    const { findVigenciaMatrizSalarialSelect, filtros } = this.props;
    if (filtros.get('matrizSalarial')) {
      filtros.get('matrizSalarial').map((item, i) => {
        if (i == 'id') {
          return findVigenciaMatrizSalarialSelect(item);
        }
      });
    }
  };

  findMatrizes = param => {
    const { findMatrizes, filtro } = this.props;

    return findMatrizes(filtro, param);
  };

  renderMatrizesVigencias = data => {
    return (
      <option key={data} value={data}>
        {DateUtils.formatDateTime(data, 'DD/MM/YYYY')}
      </option>
    );
  };

  render() {
    const { filtros, errors, matrizesVigentes } = this.props;

    return (
      <form>
        <div className="panel-inside-panel mt-xs">
          <Panel
            title="Consultar Cargos"
            isForm
            tableResponsive
            ref="panelFiltroCargos"
          >
            <Row>
              <Col xs={12} sm={4} md={6}>
                <AutoComplete
                  label="Cargo"
                  id="cargo"
                  name="cargo"
                  onLoad={this.findCargos}
                  onChange={this.onChangeFiltro}
                  value={filtros.get('cargo')}
                  errors={errors}
                  valueKey="codigo"
                  labelKey="descricao"
                  placeholder="Selecione o Cargo"
                  clearable
                />
              </Col>
              <Col xs={12} sm={4} md={6}>
                <AutoComplete
                  label="Tipo do Cargo"
                  id="tipoCargo"
                  name="tipoCargo"
                  onLoad={this.findTipoCargos}
                  onChange={this.onChangeFiltro}
                  value={filtros.get('tipoCargo')}
                  errors={errors}
                  valueKey="codigo"
                  labelKey="descricao"
                  placeholder="Selecione o Tipo de Cargo"
                  clearable
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={4} md={4}>
                <AutoComplete
                  label="Matriz Salarial"
                  id="matrizSalarial"
                  name="matrizSalarial"
                  onLoad={this.findMatrizes}
                  onChange={this.onChangeFiltro}
                  value={filtros.get('matrizSalarial')}
                  errors={errors}
                  valueKey="id"
                  labelKey="nome"
                  placeholder="Selecione a Matriz Salarial"
                  clearable
                />
              </Col>
              <Col xs={12} sm={4} md={4}>
                <div className={'form-group'}>
                  <label className="label">Vigência Matriz Salarial</label>
                  <select
                    key={filtros.get('vigenciaMatrizSalarial')}
                    id="vigenciaMatrizSalarial"
                    name="vigenciaMatrizSalarial"
                    className="form-control control-label"
                    ref="vigenciaMatrizSalarial"
                    onChange={this.onChangeFiltro}
                    value={
                      filtros.get('matrizSalarial')
                        ? filtros.get('vigenciaMatrizSalarial')
                        : ''
                    }
                    disabled={!filtros.get('matrizSalarial')}
                  >
                    <option value="">Selecione...</option>
                    {matrizesVigentes.map(this.renderMatrizesVigencias)}
                  </select>
                </div>
              </Col>
              <Col xs={12} sm={4} md={3}>
                <div className="form-group">
                  <label className="label"></label>
                  <div className="btn-group">
                    <button
                      id="btnFiltrar"
                      className="btn module-color icon-right inline"
                      ref="pesquisar"
                      onClick={this.onPesquisa}
                    >
                      Pesquisar
                      <em className="fa fa-search"></em>
                    </button>
                    <button
                      id="btnReset"
                      className="btn neutral icon-right inline no-text"
                      type="button"
                      ref="limpar"
                      onClick={this.onResetFiltro}
                    >
                      Limpar
                      <em className="fa fa-times"></em>
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </Panel>
        </div>
      </form>
    );
  }
}
function mapStateToProps(state) {
  return {
    filtros: state.crud.getIn(['filtroCargos', 'currentRecord'], Map({})),
    matrizesVigentes: state.cargo.get('matrizesVigentes')
  };
}
const allActions = {
  ...actions,
  onChange,
  resetCrud
};
export default connect(mapStateToProps, allActions)(CargosFiltroOxy);
