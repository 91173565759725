import * as types from './Types.js';

export function findUltimaAtualizacao() {
  return ({ fetch }) => ({
    type: types.ULTIMAATUALIZACAO,
    payload: {
      promise: fetch('/api/ultimas-atualizacoes')
    }
  });
}

export function findUltimaAtualizacaoByTable(
  modulo,
  table,
  column,
  comparativo = 'N',
  filtrarEntidade = false
) {
  if (comparativo == 'N') {
    return ({ fetch }) => ({
      type: types.ULTIMAATUALIZACAO_BY_TABLE,
      payload: {
        promise: fetch(
          `/api/ultimas-atualizacoes/by-table?modulo=${modulo}&table=${table}&column=${column}&filtrarEntidade=${filtrarEntidade}`
        )
      }
    });
  } else {
    return ({ fetch }) => ({
      type: types.ULTIMAATUALIZACAO_BY_TABLE_COMPARATIVO,
      payload: {
        promise: fetch(
          `/api/ultimas-atualizacoes/by-table?modulo=${modulo}&table=${table}&column=${column}&filtrarEntidade=${filtrarEntidade}`
        )
      }
    });
  }
}

export function findUltimaAtualizacaoLicitacao() {
  return ({ fetch }) => ({
    type: types.ULTIMAATUALIZACAO,
    payload: {
      promise: fetch('/api/licitacoes/ultimas-atualizacoes')
    }
  });
}

export function findUltimaAtualizacaoContrato() {
  return ({ fetch }) => ({
    type: types.ULTIMAATUALIZACAO,
    payload: {
      promise: fetch('/api/contratos/ultimas-atualizacoes')
    }
  });
}

export function findUltimaAtualizacaoPatrimonio() {
  return ({ fetch }) => ({
    type: types.ULTIMAATUALIZACAO,
    payload: {
      promise: fetch('/api/patrimonios/ultimas-atualizacoes')
    }
  });
}

export function findUltimaAtualizacaoAlmoxarifado() {
  return ({ fetch }) => ({
    type: types.ULTIMAATUALIZACAO,
    payload: {
      promise: fetch('/api/estoque/ultimas-atualizacoes')
    }
  });
}

export function findUltimaAtualizacaoFrota() {
  return ({ fetch }) => ({
    type: types.ULTIMAATUALIZACAO,
    payload: {
      promise: fetch('/api/veiculos/ultimas-atualizacoes')
    }
  });
}

export function findUltimaAtualizacaoObra() {
  return ({ fetch }) => ({
    type: types.ULTIMAATUALIZACAO,
    payload: {
      promise: fetch('/api/obras/ultimas-atualizacoes')
    }
  });
}

export function findDataConsulta() {
  return ({ fetch }) => ({
    type: types.DATA_CONSULTA,
    payload: {
      promise: fetch('/api/ultimas-atualizacoes/consulta-atual')
    }
  });
}
