import React from 'react';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import PropTypes from 'prop-types';
import PageHeader from '../components/PageHeader.react.js';
import * as actions from './Actions.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import TableHeaderItem from 'portaltransparencia-common/components/TableHeaderItem.react';
import { getValueFromUrlSearchParams } from 'portaltransparencia-common/libs/URLUtils';
import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react.js';

export class VerbasDetalhes extends Component {
  static propTypes = {
    item: PropTypes.object,
    location: PropTypes.object,
    detalhes: PropTypes.object,
    findVerbaDetalhes: PropTypes.func,
    horasExtras: PropTypes.object
  };

  componentDidMount() {
    const { findVerbaDetalhes, location } = this.props;

    const eventoDefinicao = getValueFromUrlSearchParams(location, 'evento');
    const mesCompetencia = getValueFromUrlSearchParams(location, 'competencia');
    const lotacao = getValueFromUrlSearchParams(location, 'lotacao');

    findVerbaDetalhes(eventoDefinicao, mesCompetencia, lotacao);
  }

  renderDetalhes(detalhe, index) {
    return (
      <tr ref="row" key={index}>
        <td>{detalhe.get('matricula')}</td>
        <td>{detalhe.get('nome')}</td>
        <td className="right">
          <NumberFormatter value={detalhe.get('valorCalculado')} />
        </td>
      </tr>
    );
  }

  render() {
    const { location, detalhes } = this.props;

    const lotacao = getValueFromUrlSearchParams(location, 'lotacao');

    return (
      <div>
        <PageHeader
          location={this.props.location}
          textoDetalhe={`Lotação:${lotacao}`}
        />
        <Panel title="Detalhes das Verbas" mobileClose tableResponsive>
          <table className="panel-table striped fancy" id="tableVerbaDetalhes">
            <TableHeader>
              <tr>
                <th>Matrícula</th>
                <th>Nome</th>
                <th className="right">Valor Calculado</th>
                <th />
              </tr>
            </TableHeader>
            <tbody>{detalhes.map(this.renderDetalhes)}</tbody>
          </table>
        </Panel>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    detalhes: state.verbas.get('detalhes')
  };
}

export default connect(mapStateToProps, actions)(VerbasDetalhes);
