import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react';
import TreeTableItem from 'portaltransparencia-common/components/treetable/TreeTableItem.react';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export default class TableItem extends Component {
  static propTypes = {
    item: PropTypes.object,
    expand: PropTypes.func.isRequired,
    collapse: PropTypes.func.isRequired
  };

  onClickItem = () => {
    const { item, expand, collapse } = this.props;

    if (item.get('open')) {
      collapse(item.get('receita'), item.get('nivel'));
    } else {
      expand(item.get('receita'), item.get('nivel'));
    }
  };

  render = () => {
    const { item } = this.props;

    if (!item.get('visible')) {
      return null;
    }

    return (
      <TreeTableItem
        key={item.get('receita')}
        data={item}
        dataLevel={item.get('receita')}
        father={item.get('aceitaMovimentacao') == 'N'}
        level={item.get('nivel')}
        visible={item.get('visible')}
      >
        <td style={{ cursor: 'pointer' }} onClick={this.onClickItem}>
          {item.get('receita')}
        </td>
        <td>{item.get('descricao')}</td>
        <td className="right">
          <NumberFormatter value={item.get('valorOrcado')} />
        </td>
        <td className="right">
          <NumberFormatter value={item.get('valorDeducaoInicial')} />
        </td>
        <td className="right">
          <NumberFormatter value={item.get('valorAtualizado')} />
        </td>
        <td className="right">
          <NumberFormatter value={item.get('valorArrecadado')} />
        </td>
        <td className="right">
          <NumberFormatter value={item.get('valorDeducao')} />
        </td>
        <td className="right">
          <NumberFormatter value={item.get('valorRealizadoLiquido')} />
        </td>
      </TreeTableItem>
    );
  };
}
