import PropTypes from 'prop-types';
import React from 'react';
import { mouseTrap } from 'react-mousetrap';

export class Modal extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node,
    bindShortcut: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
  };

  UNSAFE_componentWillMount() {
    const { bindShortcut, onClose } = this.props;

    bindShortcut('esc', onClose);
  }

  render() {
    const { onClose, children } = this.props;

    return (
      <div className="panel">
        <div className="panel-body">
          <div className="modal open">
            <div className="container">
              <span className="close close-modal" onClick={onClose}></span>
              {children}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default mouseTrap(Modal);
