import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';

import * as types from './Types.js';

const initialState = fromJS({
  servidores: {
    servidores: []
  }
});

function servidoresCedidosReducer(state = initialState, action) {
  switch (action.type) {
    case types.SERVIDORES_CEDIDOS_START: {
      return startLoad(state);
    }

    case types.SERVIDORES_CEDIDOS_SUCCESS: {
      return endLoad(state.set('servidores', fromJS(action.payload)));
    }

    case types.SERVIDORES_CEDIDOS_ERROR: {
      return endLoad(state);
    }
  }
  return state;
}

export default servidoresCedidosReducer;
