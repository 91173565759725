import Link from 'portaltransparencia-common/components/link/Link.react.js';
import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react.js';
import TableActionButton from 'portaltransparencia-common/components/TableActionButton.react';
import TableActions from 'portaltransparencia-common/components/TableActions.react';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import NumberUtils from 'portaltransparencia-common/libs/NumberUtils.js';
import {
  PARAM_DESCONTOSFOLHA,
  PARAM_DETALHAMENTODESCONTOS,
  PARAM_DETALHAMENTOPROVENTOS,
  PARAM_DETALHA_OUTROS_PROVENTOS,
  PARAM_MESANO_MOSTRA_FINANCEIRO,
  PARAM_MOSTRA_APENAS_DESCONTOS_OFICIAIS,
  PARAM_MOSTRA_OUTROS_PROVENTOS,
  PARAM_MOSTRA_REDUTOR_CONSTITUCIONAL,
  PARAM_PROVENTOSFOLHA,
  PARAM_SALARIOBASE,
  PARAM_SALARIOLIQUIDO,
  PARAM_USAWEBSERVICE_MARINGA
} from 'portaltransparencia-common/parametros/ParametrosPortalTransparencia';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import ParametroService from '../parametros/ParametroService.js';
import ServidorDetalheRegistroPontoOxy from './ServidorDetalheRegistroPonto.react.js';
import ServidorDetalheVencimentosDescontosOxy from './ServidorDetalheVencimentosDescontos.react.js';

export class ServidorDetalheFinanceiroOxy extends Component {
  static propTypes = {
    exibePonto: PropTypes.bool.isRequired,
    dadosFinanceiros: PropTypes.object.isRequired,
    paramSalarioBase: PropTypes.string,
    paramSalarioLiquido: PropTypes.string,
    paramVencimentosDetalhes: PropTypes.string,
    paramDescontosDetalhes: PropTypes.string,
    paramVencimentos: PropTypes.string,
    paramDescontos: PropTypes.string,
    paramMesAnoMostraDados: PropTypes.string,
    paramDesconsideraOutros: PropTypes.string,
    paramMostraRedutor: PropTypes.string,
    paramMostraOutrosProventos: PropTypes.string,
    paramDetalhaOutrosProventos: PropTypes.string,
    paramUsaWebServiceMaringa: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      dialog: {
        showVencimentosDescontos: false,
        showPonto: false,
        item: {},
        tipoEvento: ''
      }
    };
  }

  renderDadosFinanceiros = item => {
    const {
      paramSalarioBase,
      paramSalarioLiquido,
      paramVencimentosDetalhes,
      paramDescontosDetalhes,
      paramVencimentos,
      paramDescontos,
      paramMesAnoMostraDados,
      paramDesconsideraOutros,
      paramMostraRedutor,
      paramMostraOutrosProventos,
      paramDetalhaOutrosProventos
    } = this.props;

    const mesAnoMostraDadosDefault = '0/0';
    const mensagemDesconto =
      'Serão considerados para os descontos apenas os eventos de IRRF e INSS';

    if (
      !item.get('mesCompetencia') ||
      !item.get('anoCompetencia') ||
      !item.get('descricaoTipoFolha')
    ) {
      return null;
    }

    const key =
      item.get('mesCompetencia') +
      '_' +
      item.get('anoCompetencia') +
      '_' +
      item.get('descricaoTipoFolha');
    const bruto = item.get('vencimentos') == null ? 0 : item.get('vencimentos');
    const descontos =
      item.get('descontos') == null ? 0 : Math.abs(item.get('descontos'));
    const redutor =
      item.get('redutorConstitucional') == null || paramMostraRedutor != 'S'
        ? 0
        : item.get('redutorConstitucional');
    const mesAnoMostraDados = !paramMesAnoMostraDados
      ? mesAnoMostraDadosDefault.split('/')
      : paramMesAnoMostraDados.split('/');

    const competenciaMostrarDados =
      item.get('anoCompetencia') > mesAnoMostraDados[1] ||
      (item.get('anoCompetencia') == mesAnoMostraDados[1] &&
        item.get('mesCompetencia') >= mesAnoMostraDados[0]);

    return (
      <TableActions
        ref="row"
        key={key}
        data={item}
        customActions={this.botaoPontoAction(item)}
      >
        <td>{item.get('mesCompetencia') + '/' + item.get('anoCompetencia')}</td>
        <td>{item.get('descricaoTipoFolha')}</td>
        {paramSalarioBase == 'S' && (
          <td className="right">
            <NumberFormatter value={item.get('salarioBase')} />
          </td>
        )}
        {paramMostraOutrosProventos == 'S' && (
          <td className="right">
            {paramDetalhaOutrosProventos == 'S' &&
              competenciaMostrarDados &&
              item.get('outrosProventos') && (
                <Link
                  onClick={this.onShowDialog(item, 'O')}
                  value={NumberUtils.format(item.get('outrosProventos'))}
                  icon="fa fa-external-link-square ml-xs"
                  iconPosition="right"
                />
              )}
            {(paramDetalhaOutrosProventos != 'S' ||
              !item.get('outrosProventos')) &&
              competenciaMostrarDados && (
                <NumberFormatter value={item.get('outrosProventos')} />
              )}
          </td>
        )}
        {paramVencimentos == 'S' && (
          <td className="right">
            {paramVencimentosDetalhes == 'S' && competenciaMostrarDados && (
              <Link
                onClick={this.onShowDialog(item, 'P')}
                value={NumberUtils.format(bruto - redutor)}
                icon="fa fa-external-link-square ml-xs"
                iconPosition="right"
              />
            )}
            {paramVencimentosDetalhes != 'S' && competenciaMostrarDados && (
              <NumberFormatter value={bruto - redutor} />
            )}
          </td>
        )}
        {paramDescontos == 'S' && (
          <td
            className="right"
            title={paramDesconsideraOutros == 'S' ? mensagemDesconto : ''}
          >
            {paramDescontosDetalhes == 'S' && competenciaMostrarDados && (
              <Link
                onClick={this.onShowDialog(item, 'D')}
                value={NumberUtils.format(descontos)}
                icon="fa fa-external-link-square ml-xs"
                iconPosition="right"
              />
            )}

            {paramDescontosDetalhes != 'S' && competenciaMostrarDados && (
              <NumberFormatter value={descontos} />
            )}
          </td>
        )}
        {paramMostraRedutor == 'S' && (
          <td
            className="right"
            title="Irá exibir como Total de Proventos somente valores até o Teto para Redutor Constitucional"
          >
            {competenciaMostrarDados && <NumberFormatter value={redutor} />}
          </td>
        )}
        {paramSalarioLiquido == 'S' && (
          <td className="right">
            {competenciaMostrarDados && (
              <NumberFormatter value={bruto - descontos - redutor} />
            )}
          </td>
        )}
      </TableActions>
    );
  };

  botaoPontoAction = data => {
    if (this.props.exibePonto) {
      return (
        <TableActionButton
          data={data}
          onClick={this.onShowPonto(data)}
          title="Visualizar Pontos"
          icon="fa fa-clock-o"
        />
      );
    }
    return false;
  };

  onShowPonto = item => {
    return () => {
      this.showPonto(item);
    };
  };

  showPonto = item => {
    this.setState({
      dialog: {
        showVencimentosDescontos: false,
        showPonto: true,
        item: item,
        tipoEvento: ''
      }
    });
  };

  onShowDialog = (item, tipoEvento) => {
    return () => {
      this.showDialog(item, tipoEvento);
    };
  };

  showDialog = (item, tipoEvento) => {
    this.setState({
      dialog: {
        showVencimentosDescontos: true,
        showPonto: false,
        item: item,
        tipoEvento: tipoEvento
      }
    });
  };

  onClose = () => {
    this.setState({
      dialog: {
        showVencimentosDescontos: false,
        showPonto: false,
        item: {},
        tipoEvento: ''
      }
    });
  };

  render() {
    const {
      dadosFinanceiros,
      paramSalarioBase,
      paramSalarioLiquido,
      paramVencimentos,
      paramDescontos,
      paramMostraRedutor,
      paramMostraOutrosProventos,
      paramUsaWebServiceMaringa
    } = this.props;
    const { dialog } = this.state;

    return (
      <section>
        {dialog.showVencimentosDescontos && (
          <ServidorDetalheVencimentosDescontosOxy
            item={dialog.item}
            tipoEvento={dialog.tipoEvento}
            paramMostraRedutor={paramMostraRedutor}
            onClose={this.onClose}
          />
        )}
        {dialog.showPonto && (
          <ServidorDetalheRegistroPontoOxy
            item={dialog.item}
            onClose={this.onClose}
          />
        )}

        <div className="panel-table-outer">
          <table className="panel-table striped fancy">
            <TableHeader empty={dadosFinanceiros.isEmpty()}>
              <tr>
                <th>Competência</th>
                <th>Tipo Folha</th>
                {paramSalarioBase == 'S' && (
                  <th className="right">Salário Base</th>
                )}
                {paramMostraOutrosProventos == 'S' && (
                  <th className="right">Outros Proventos</th>
                )}
                {paramVencimentos == 'S' && (
                  <th className="right">Vencimentos</th>
                )}
                {paramDescontos == 'S' && (
                  <th className="right">
                    {paramUsaWebServiceMaringa == '1'
                      ? 'Descontos Obrigatórios'
                      : 'Descontos'}
                  </th>
                )}
                {paramMostraRedutor == 'S' && (
                  <th className="right" id="redutor">
                    Redutor Constitucional
                  </th>
                )}
                {paramSalarioLiquido == 'S' && (
                  <th className="right">
                    {paramUsaWebServiceMaringa == '1'
                      ? 'Proventos - Descontos Obrigatórios'
                      : 'Líquido'}
                  </th>
                )}
                <th></th>
              </tr>
            </TableHeader>
            <tbody>{dadosFinanceiros.map(this.renderDadosFinanceiros)}</tbody>
          </table>
        </div>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    paramSalarioBase: ParametroService.getValorParametro(
      state,
      PARAM_SALARIOBASE
    ),
    paramSalarioLiquido: ParametroService.getValorParametro(
      state,
      PARAM_SALARIOLIQUIDO
    ),
    paramVencimentos: ParametroService.getValorParametro(
      state,
      PARAM_PROVENTOSFOLHA
    ),
    paramVencimentosDetalhes: ParametroService.getValorParametro(
      state,
      PARAM_DETALHAMENTOPROVENTOS
    ),
    paramDescontos: ParametroService.getValorParametro(
      state,
      PARAM_DESCONTOSFOLHA
    ),
    paramDescontosDetalhes: ParametroService.getValorParametro(
      state,
      PARAM_DETALHAMENTODESCONTOS
    ),
    paramMesAnoMostraDados: ParametroService.getValorParametro(
      state,
      PARAM_MESANO_MOSTRA_FINANCEIRO
    ),
    paramDesconsideraOutros: ParametroService.getValorParametro(
      state,
      PARAM_MOSTRA_APENAS_DESCONTOS_OFICIAIS
    ),
    paramMostraRedutor: ParametroService.getValorParametro(
      state,
      PARAM_MOSTRA_REDUTOR_CONSTITUCIONAL
    ),
    paramMostraOutrosProventos: ParametroService.getValorParametro(
      state,
      PARAM_MOSTRA_OUTROS_PROVENTOS
    ),
    paramDetalhaOutrosProventos: ParametroService.getValorParametro(
      state,
      PARAM_DETALHA_OUTROS_PROVENTOS
    ),
    paramUsaWebServiceMaringa: ParametroService.getValorParametro(
      state,
      PARAM_USAWEBSERVICE_MARINGA
    )
  };
}

export default connect(mapStateToProps)(ServidorDetalheFinanceiroOxy);
