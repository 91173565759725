export const ARRECADACAO_DESPESA_MES = 'ARRECADACAO_DESPESA_MES';
export const ARRECADACAO_DESPESA_MES_START = 'ARRECADACAO_DESPESA_MES_START';
export const ARRECADACAO_DESPESA_MES_SUCCESS =
  'ARRECADACAO_DESPESA_MES_SUCCESS';
export const ARRECADACAO_DESPESA_MES_ERROR = 'ARRECADACAO_DESPESA_MES_ERROR';

export const RECEITA_VALOR_ATUALIZADO = 'RECEITA_VALOR_ATUALIZADO';
export const RECEITA_VALOR_ATUALIZADO_START = 'RECEITA_VALOR_ATUALIZADO_START';
export const RECEITA_VALOR_ATUALIZADO_SUCCESS =
  'RECEITA_VALOR_ATUALIZADO_SUCCESS';
export const RECEITA_VALOR_ATUALIZADO_ERROR = 'RECEITA_VALOR_ATUALIZADO_ERROR';

export const DESPESA_VALOR_ATUALIZADO = 'DESPESA_VALOR_ATUALIZADO';
export const DESPESA_VALOR_ATUALIZADO_START = 'DESPESA_VALOR_ATUALIZADO_START';
export const DESPESA_VALOR_ATUALIZADO_SUCCESS =
  'DESPESA_VALOR_ATUALIZADO_SUCCESS';
export const DESPESA_VALOR_ATUALIZADO_ERROR = 'DESPESA_VALOR_ATUALIZADO_ERROR';

export const GASTOS_PESSOAL = 'GASTOS_PESSOAL';
export const GASTOS_PESSOAL_START = 'GASTOS_PESSOAL_START';
export const GASTOS_PESSOAL_SUCCESS = 'GASTOS_PESSOAL_SUCCESS';
export const GASTOS_PESSOAL_ERROR = 'GASTOS_PESSOAL_ERROR';

export const GASTOS_EDUCACAO = 'GASTOS_EDUCACAO';
export const GASTOS_EDUCACAO_START = 'GASTOS_EDUCACAO_START';
export const GASTOS_EDUCACAO_SUCCESS = 'GASTOS_EDUCACAO_SUCCESS';
export const GASTOS_EDUCACAO_ERROR = 'GASTOS_EDUCACAO_ERROR';

export const GASTOS_SAUDE = 'GASTOS_SAUDE';
export const GASTOS_SAUDE_START = 'GASTOS_SAUDE_START';
export const GASTOS_SAUDE_SUCCESS = 'GASTOS_SAUDE_SUCCESS';
export const GASTOS_SAUDE_ERROR = 'GASTOS_SAUDE_ERROR';

export const RECEITA_DASHBOARD = 'RECEITA_DASHBOARD';
export const RECEITA_DASHBOARD_START = 'RECEITA_DASHBOARD_START';
export const RECEITA_DASHBOARD_SUCCESS = 'RECEITA_DASHBOARD_SUCCESS';
export const RECEITA_DASHBOARD_ERROR = 'RECEITA_DASHBOARD_ERROR';
