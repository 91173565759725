import Component from 'react-pure-render/component';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import PropTypes from 'prop-types';
import React from 'react';
import Tab from 'portaltransparencia-common/components/Tab.react.js';
import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react.js';
import Tabs from 'portaltransparencia-common/components/Tabs.react.js';

export class ContratoDetalheAditivo extends Component {
  static propTypes = {
    aditivos: PropTypes.object.isRequired,
    uf: PropTypes.string,
    onDownload: PropTypes.func.isRequired
  };

  onViewDetalhes = aditivo => {
    const { history } = this.props;
    const entidade = aditivo.get('entidade');
    const exercicio = aditivo.get('exercicio');
    const numero = aditivo.get('numero');
    const sequencia = aditivo.get('sequencia');
    const tipoAto = aditivo.get('tipoAto');

    history.push(
      `/aditivos/detalhes?entidade=${entidade}&exercicio=${exercicio}&numero=${numero}&sequencia=${sequencia}&tipoAto=${tipoAto}`
    );
  };

  renderAditivos = data => {
    const key =
      data.get('entidade') +
      '_' +
      data.get('exercicio') +
      '_' +
      data.get('numero') +
      '_' +
      data.get('sequencia') +
      '_' +
      data.get('tipoAto');

    return (
      <TableActions
        ref="row"
        key={key}
        data={data}
        onMore={this.onViewDetalhes}
      >
        <td>{data.get('numeroAditivo')}</td>
        <td>{DateUtils.transform(data.get('data'))}</td>
        <td>{DateUtils.transform(data.get('dataPublicacao'))}</td>
        <td>
          <NumberFormatter value={data.get('valorAditivo')} />
        </td>
        <td>{DateUtils.transform(data.get('dataTermino'))}</td>
        <td>{data.get('motivacao')}</td>
      </TableActions>
    );
  };

  renderApostilamentos = data => {
    const key =
      data.get('entidade') +
      '_' +
      data.get('exercicio') +
      '_' +
      data.get('numero') +
      '_' +
      data.get('sequencia');

    return (
      <TableActions
        ref="row"
        key={key}
        data={data}
        onMore={this.onViewDetalhes}
      >
        <td>{data.get('numeroAditivo')}</td>
        <td>{DateUtils.transform(data.get('data'))}</td>
        <td>{DateUtils.transform(data.get('dataPublicacao'))}</td>
        <td>
          <NumberFormatter value={data.get('valorAditivo')} />
        </td>
        <td>{DateUtils.transform(data.get('dataTermino'))}</td>
        <td>{data.get('motivacao')}</td>
      </TableActions>
    );
  };

  render() {
    const { aditivos, uf } = this.props;

    return (
      <Panel expansible={false} isTable tableResponsive>
        <Tabs scrollVisibled>
          <Tab title="Aditivos" active>
            <table className="panel-table striped fancy word-break">
              <TableHeader empty={aditivos.isEmpty()} text="Sem aditivos.">
                <tr>
                  <th>N° Aditivo</th>
                  <th>Data</th>
                  <th>Publicação</th>
                  <th>Valor Aditivos</th>
                  <th>Término</th>
                  <th>Motivação</th>
                  <th></th>
                </tr>
              </TableHeader>
              <tbody>
                {aditivos
                  .filter(
                    aditivos =>
                      aditivos.get('idTipoAditivo') != '8' || uf !== 'RJ'
                  )
                  .map(this.renderAditivos)}
              </tbody>
            </table>
          </Tab>
          <Tab title="Apostilamentos" hide={uf !== 'RJ'}>
            <table className="panel-table striped fancy">
              <TableHeader
                empty={aditivos.isEmpty()}
                text="Sem apostilamentos para este contrato."
              >
                <tr>
                  <th>N° Apostilamento</th>
                  <th>Data</th>
                  <th>Publicação</th>
                  <th>Valor Aditivos</th>
                  <th>Término</th>
                  <th>Motivação</th>
                  <th></th>
                </tr>
              </TableHeader>
              <tbody>
                {aditivos
                  .filter(aditivos => aditivos.get('idTipoAditivo') == '8')
                  .map(this.renderApostilamentos)}
              </tbody>
            </table>
          </Tab>
        </Tabs>
      </Panel>
    );
  }
}

export default ContratoDetalheAditivo;
