import * as types from './Types.js';

export function findRepassesEnviados() {
  return ({ fetch }) => ({
    type: types.REPASSES_ENVIADOS,
    payload: {
      promise: fetch('/api/repasses?tipo=E')
    }
  });
}

export function findRepassesRecebidos() {
  return ({ fetch }) => ({
    type: types.REPASSES_RECEBIDOS,
    payload: {
      promise: fetch('/api/repasses?tipo=R')
    }
  });
}

export function findRepassesEnviadosMes(filtro) {
  return ({ fetch }) => ({
    type: types.REPASSES_MES,
    payload: {
      promise: fetch(`/api/repasses/mes?${filtro}&tipo=E`)
    }
  });
}

export function findRepassesRecebidosMes(filtro) {
  return ({ fetch }) => ({
    type: types.REPASSES_MES,
    payload: {
      promise: fetch(`/api/repasses/mes?${filtro}&tipo=R`)
    }
  });
}
