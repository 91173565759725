import numeral from 'numeral';
import language from 'numeral/languages/pt-br.js';

numeral.language('pt-br', language);
numeral.language('pt-br');

export const format = (value, mask = '0,0.00') => {
  return numeral(value).format(mask);
};

export const formatMilhar = (value, mask = '000,000,000') => {
  return numeral(value).format(mask);
};

export const isNumber = number => {
  return !isNaN(parseFloat(number)) && isFinite(number);
};

export const formatBytes = (value, mask = '0.0 b') => {
  if (value) {
    return `(${numeral(value).format(mask)})`;
  }
  return '';
};

const NumberUtils = {
  isNumber(number) {
    return !isNaN(parseFloat(number)) && isFinite(number);
  },

  format: function (value, mask = '0,0.00') {
    return numeral(value).format(mask);
  },

  formatMilhar: function (value, mask = '000,000,000') {
    return numeral(value).format(mask);
  },

  formatBytes: function (value, mask = '0.0 b') {
    if (value) {
      return `(${numeral(value).format(mask)})`;
    }
    return '';
  },

  unformat: function (value) {
    return numeral().unformat(value);
  }
};

export default NumberUtils;
