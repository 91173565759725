import * as types from './Types.js';
import Fields from '../libs/Fields.js';

export function search(resource, orderBy, endPoint) {
  let resourceFinal = endPoint ? resource + endPoint : resource;

  let pagePath = `/${resourceFinal}`;

  if (orderBy) {
    pagePath = pagePath + `?${orderBy}`;
  }

  return ({ fetch }) => ({
    type: types.SEARCH,
    payload: {
      promise: fetch(pagePath)
    },
    meta: {
      path: resource
    }
  });
}

export function loadRecord(resource, id) {
  return ({ fetch }) => ({
    type: types.RETRIEVE,
    payload: {
      promise: fetch(`/${resource}/${id}`)
    },
    meta: {
      path: resource
    }
  });
}

export function newRecord(resource) {
  return ({ fetch }) => ({
    type: types.NEW_RECORD,
    payload: {
      promise: fetch(`/${resource}/template`)
    },
    meta: {
      path: resource
    }
  });
}

export function saveRecord(resource, data, next) {
  return ({ post }) => ({
    type: types.SAVE_RECORD,
    payload: {
      data: { text: 'Salvando registro...' },
      promise: post(`/${resource}`, data).then(response => {
        if (next) {
          next(response);
        }
        return response;
      })
    },
    meta: {
      path: resource
    }
  });
}

export function updateRecord(resource, data, next) {
  const id = data.get('id');

  return ({ put }) => ({
    type: types.UPDATE_RECORD,
    payload: {
      data: { text: 'Atualizando registro...' },
      promise: put(`/${resource}/${id}`, data).then(response => {
        if (next) {
          next(response);
        }
        return response;
      })
    },
    meta: {
      path: resource
    }
  });
}

export function redirect(resource) {
  return ({ browserHistory }) => {
    browserHistory.push(`/${resource}`);

    return {
      type: 'REDIRECT',
      payload: resource
    };
  };
}

export function deleteRecord(resource, data) {
  const id = data.get('id');

  return ({ remove }) => ({
    type: types.DELETE_RECORD,
    payload: {
      data: { text: 'Removendo registro...' },
      promise: remove(`/${resource}/${id}`)
    },
    meta: {
      path: resource,
      value: data
    }
  });
}

export function editRecord(resource, data) {
  return {
    type: types.EDIT_RECORD,
    payload: data,
    meta: {
      path: resource
    }
  };
}

export function cancelRecord(resource) {
  return {
    type: types.CANCEL_RECORD,
    meta: {
      path: resource
    }
  };
}

export function onChange(resource, event) {
  const field = Fields.getName(event);
  const value = Fields.getValue(event);

  return {
    type: types.CHANGE_FIELD_VALUE,
    payload: {
      field: field,
      value: value
    },
    meta: {
      path: resource
    }
  };
}

export function changeFieldValue(resource, field, value, finder) {
  return {
    type: types.CHANGE_FIELD_VALUE,
    payload: {
      field: field,
      value: value,
      finder: finder
    },
    meta: {
      path: resource
    }
  };
}

export function addItem(resource, field, value) {
  return {
    type: types.ADD_ITEM,
    payload: {
      field: field,
      value: value
    },
    meta: {
      path: resource
    }
  };
}

export function reorder(resource, field, value, oldIndex, newIndex) {
  return {
    type: types.REORDER_RECORD,
    payload: {
      field: field,
      value: value,
      oldIndex: oldIndex,
      newIndex: newIndex
    },
    meta: {
      path: resource
    }
  };
}

export function removeItem(resource, field, value) {
  return {
    type: types.REMOVE_ITEM,
    payload: {
      field: field,
      value: value
    },
    meta: {
      path: resource
    }
  };
}

export function resetCrud(resource) {
  return {
    type: types.RESET_CRUD,
    meta: {
      path: resource
    }
  };
}

export function addFilter(resource, filter) {
  return {
    type: types.ADD_FILTER,
    payload: filter,
    meta: {
      path: resource
    }
  };
}

export function applyFilter(resource, filter, page, orderBy, endPoint) {
  let pagePath = '';

  if (page) {
    if (page.has('number')) {
      pagePath = pagePath + '&page=' + page.get('number');
    }
    if (page.has('size')) {
      pagePath = pagePath + '&size=' + page.get('size');
    }
  }

  if (orderBy) {
    pagePath = orderBy + pagePath;
  }

  let resourceFinal = endPoint ? resource + endPoint : resource;

  return ({ fetch }) => ({
    type: types.APPLY_FILTER,
    payload: {
      data: { text: 'Buscando registros...' },
      promise: fetch(`/${resourceFinal}?${filter}&${pagePath}`)
    },
    meta: {
      path: resource
    }
  });
}

export function removeFilter(resource, filter) {
  return {
    type: types.REMOVE_FILTER,
    payload: filter,
    meta: {
      path: resource
    }
  };
}

export function loadModel(resource) {
  return ({ fetch }) => ({
    type: types.LOAD_MODEL,
    payload: {
      promise: fetch(`/${resource}/model`)
    },
    meta: {
      path: resource
    }
  });
}

export function autoComplete(resource, filter, page) {
  let pagePath = '';
  if (page) {
    if (page.has('number')) {
      pagePath = pagePath + '&page=' + page.get('number');
    }
    if (page.has('size')) {
      pagePath = pagePath + '&size=' + page.get('size');
    }
  }

  return ({ fetch }) => ({
    type: 'AUTO_COMPLETE',
    payload: {
      promise: fetch(`/${resource}?${filter}${pagePath}`)
    }
  });
}

export function printSearch(resource, filter, fields) {
  const windowParams = 'width=800, height=600';

  return ({ fetch }) => ({
    type: 'PRINT',
    payload: fetch(`/${resource}/report?${filter}&fields=${fields}`).then(
      response => {
        window.open(
          window.URL.createObjectURL(
            new Blob([response], { type: 'text/html' })
          ),
          '_blank',
          windowParams
        );
      }
    )
  });
}
