export const TipoGrauSigiloEnum = {
  ULTRASSECRETA: 'ULTRASSECRETA',
  SECRETA: 'SECRETA',
  RESERVADA: 'RESERVADA'
};

export const prazoTipoGrauSigiloEnum = {
  ULTRASSECRETA: 25,
  SECRETA: 15,
  RESERVADA: 5
};

export function getPrazoByTipoGrauSigilo(tipoGrauSigilo) {
  return prazoTipoGrauSigiloEnum[tipoGrauSigilo] || null;
}
