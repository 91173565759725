export const PARAM_GERAL = 0;
export const PARAM_SUPERAVITFINANCEIRO = 1;
export const PARAM_EMPENHOS = 15;
export const PARAM_ANULACOES = 16;
export const PARAM_LIQUIDACOES = 17;
export const PARAM_PAGAMENTOS = 18;
export const PARAM_ITENS_EMPENHO = 19;
export const PARAM_NOTAS_FISCAIS = 20;
export const PARAM_RAZAO_RECEITA = 21;
export const PARAM_VEICULOS = 22;
export const PARAM_GASTOSVEICULOS = 23;
export const PARAM_DIARIAS = 24;
export const PARAM_REPASSES = 25;
export const PARAM_LICITACOES = 27;
export const PARAM_EDITAL = 28;
export const PARAM_ATA = 29;
export const PARAM_DOCUMENTOS_ATA = 30;
export const PARAM_CONTRATOS = 31;
export const PARAM_DOCUMENTOS_CONTRATOS = 32;
export const PARAM_PATRIMONIO = 33;
export const PARAM_OBRASPUBLICAS = 35;
export const PARAM_HOMOLOGACOES = 36;
export const PARAM_ADJUDICACOES = 37;
export const PARAM_SERVIDORES = 38;
export const PARAM_SALARIOBASE = 39;
export const PARAM_SALARIOLIQUIDO = 40;
export const PARAM_DETALHAMENTOPROVENTOS = 41;
export const PARAM_ENTIDADE_AISE = 42;
export const PARAM_DETALHAMENTODESCONTOS = 43;
export const PARAM_PERGUNTASFREQUENTES = 44;
export const PARAM_PLANILHASOBRAS = 45;
export const PARAM_CONVENIOS = 46;
export const PARAM_ALMOXARIFADO = 47;
export const PARAM_LEISEATOS = 48;
export const PARAM_ATENDIMENTO_ORGAO = 50;
export const PARAM_ATENDIMENTO_ENDERECO = 51;
export const PARAM_ATENDIMENTO_CIDADE = 52;
export const PARAM_ATENDIMENTO_TELEFONE = 53;
export const PARAM_ATENDIMENTO_EMAIL = 54;
export const PARAM_FORMULARIO_MENSAGEM = 55;
export const PARAM_ATENDIMENTO_EXPEDIENTE = 56;
export const PARAM_PROVENTOSFOLHA = 57;
export const PARAM_DESCONTOSFOLHA = 58;
export const PARAM_ADIANTAMENTO = 59;
export const PARAM_PLANEJAMENTOS = 60;
export const PARAM_CEDIDOS = 62;
export const PARAM_COTACOES = 63;
export const PARAM_PARECERES = 64;
export const PARAM_PROPOSTA_PRECO = 65;
export const PARAM_CERTIDOES = 66;
export const PARAM_PUBLICACOES_LICITACAO = 67;
export const PARAM_LEISEATOS_AISE = 68;
export const PARAM_LEISEATOS_CONTABIL = 69;
export const PARAM_LEISEATOS_LEGISLATIVO = 70;
export const PARAM_SOMENTE_IRRF_PREVIDENCIA = 71;
export const PARAM_COMPRASDIRETAS = 72;
export const PARAM_USAWEBSERVICE_MARINGA = 73;
export const PARAM_IDARQUIVO_LAI = 74;
export const PARAM_IDARQUIVO_AJUDA = 75;
export const PARAM_IDARQUIVO_SIC_FISICO = 76;
export const PARAM_MIDIAS = 77;
export const PARAM_ENTIDADE_PROTOCOLO = 78;
export const PARAM_DIVIDAATIVA = 79;
export const PARAM_VALOR_DIVIDA_CONTRIBUINTE = 80;
export const PARAM_DIVIDAATIVA_FONTE_DE_DADOS = 81;
export const PARAM_IDARQUIVO_MANUAL_FORNECEDOR = 82;
export const PARAM_LINK_LEIS_ATOS = 83;
export const PARAM_DIVIDA_ATIVA_SOMENTE_AJUIZADO = 84;
export const PARAM_SOLICITACAO_NOTIFICACAO = 86;
export const PARAM_MAIL_HOST = 88;
export const PARAM_MAIL_PORT = 89;
export const PARAM_MAIL_USERNAME = 90;
export const PARAM_MAIL_PASSWORD = 91;
export const PARAM_INSTRUCAO_NORMATIVA = 92;
export const PARAM_RESPONSAVEL_LAI = 93;
export const PARAM_EM_LIQUIDACAO = 94;
export const PARAM_LINKS_UTEIS = 95;
export const PARAM_ESIC_PROPRIO = 96;
export const PARAM_OBRIGAR_IDENTIFICACAO_DOWNLOAD = 97;
export const PARAM_AVALIACAO_PPA = 98;
export const PARAM_PLANEJAMENTO_ESTRATEGICO = 99;
export const PARAM_IDARQUIVO_MEDIDAS_ADOTADAS = 100;
export const PARAM_OCULTA_LOGO = 101;
export const PARAM_SUPRIMENTO_FUNDOS_CARTAO_CORPORATIVO = 102;
export const PARAM_PERMITIR_SOLICITACAO_ANONIMA = 105;
export const PARAM_MOSTRAR_SECRETARIA = 106;
export const PARAM_MOSTRAR_CARGO = 107;
export const PARAM_CENTROCUSTO_MOVIMENTACAO = 108;
export const PARAM_ARRECADACAO_RECEITAS = 109;
export const PARAM_ARRECADACAO_GUIAS_RECOLHIMENTO = 110;
export const PARAM_DIVIDAATIVA_CPFCNPJ_COMPLETO = 111;
export const PARAM_IMPUGNACOES_RECURSOS = 112;
export const PARAM_EXECUCAO_RECEITA = 113;
export const PARAM_EXECUCAO_DESPESA = 114;
export const PARAM_AGENDA_PREVISAO_PAGAMENTO = 115;
export const PARAM_MESANO_MOSTRA_FINANCEIRO = 116;
export const PARAM_USA_LEGISLATIVO = 117;
export const PARAM_MOSTRA_APENAS_DESCONTOS_OFICIAIS = 118;
export const PARAM_MOSTRA_REDUTOR_CONSTITUCIONAL = 119;
export const PARAM_HABILITAR_PROTOCOLO_TLS = 120;
export const PARAM_LICITACOES_PARTICIPANTES = 121;
export const PARAM_MOSTRA_SUBVENCOES = 122;
export const PARAM_MOSTRA_ALVARAS = 123;
export const PARAM_NOTAS_EXTRAS = 124;
export const PARAM_MOSTRA_OUTROS_PROVENTOS = 125;
export const PARAM_DETALHA_OUTROS_PROVENTOS = 126;
export const PARAM_AUTORIZACOES_LICITACAO = 127;
export const PARAM_EXIBE_DADOS_FUNCIONAIS_E_FINANCEIROS = 128;
export const PARAM_MOSTRA_FUNCAO_GRATIFICADA = 129;
export const PARAM_EXIBE_VALOR_MEDIO_UNITARIO = 212;
export const PARAM_INTEGRADO_OXY_FOLHA_PAGAMENTO = 233;
export const PARAM_EXIBE_GASTOS_TOTAIS = 215;
export const PARAM_DESPESAS_PAGAS_VALOR = 219;
export const PARAM_LICITACOES_ENTIDADES_DIFERENTES = 220;
export const PARAM_EXIBE_FUNCAO_GRATIFICADA = undefined;
export const PARAM_DESPESAS_ENTIDADES_DIFERENTES = 226;
export const PARAM_RECEITAS_ENTIDADES_DIFERENTES = 228;
export const PARAM_CONTATOS_COVID = 229;
export const PARAM_CONTATOS_COVID_LINK = 230;
export const PARAM_CONTATOS_COVID_ARQUIVO = 231;
export const PARAM_CONTATOS_COVID_TITULO = 232;
export const PARAM_CONTATOS_COVID_DESCRICAO = 233;
export const PARAM_EXIBE_LANCAMENTOS_EXECUCAO_RECEITA = 234;
export const PARAM_MOSTRA_NUMERO_ANO_ATO = 241;
export const PARAM_CONSULTA_AUTENTICIDADE_SUBVENCOES_TERMOS = 246;
export const PARAM_VISUALIZA_MIDIAS_ONLINE_EM_DESTAQUE = 247;
export const PARAM_NAO_VALIDA_DESPESA_DIARIA = 248;
export const PARAM_ATAS_AUDIENCIA = 249;
export const PARAM_CONTRATO_ITENS_CONTRATADOS = 250;
export const PARAM_CONTRATO_EMPENHOS = 251;
export const PARAM_DATAENTREGAPROPOSTAS_LICITACAO = 252;
export const PARAM_VEICULOS_MOVIMENTADOS = 253;
export const PARAM_REDES_SOCIAIS_FACEBOOK = 254;
export const PARAM_REDES_SOCIAIS_INSTAGRAM = 255;
export const PARAM_REDES_SOCIAIS_TWITTER = 256;
export const PARAM_REDES_SOCIAIS_WHATSAPP = 257;
export const PARAM_REDES_SOCIAIS_YOUTUBE = 258;
export const PARAM_LGPD_LINK = 261;
export const PARAM_IDARQUIVO_LGPD = 262;
export const PARAM_RESPONSAVEL_LGPD = 263;
export const PARAM_EXIBE_OBRAS_SEM_VINCULO_TCE = 264;
export const PARAM_EXIBE_OBRAS_NUMERO_SEI = 265;
export const PARAM_LEI_ATOS_CONSULTA = 267;
export const PARAM_RESUMO_ANUAL_EXIBIR_REPASSES = 268;
export const PARAM_HABILITAR_RECAPTCHA = 270;
export const PARAM_HABILITAR_LOG_ACESSO = 271;
export const PARAM_RESUMO_ANUAL_VARIACAO_EMPENHADO = 272;
export const PARAM_RESUMO_ANUAL_VARIACAO_LIQUIDADO = 273;
export const PARAM_RESUMO_ANUAL_VARIACAO_PAGO = 274;
export const PARAM_RESUMO_ANUAL_EXIBIR_EMPENHADO = 275;
export const PARAM_RESUMO_ANUAL_EXIBIR_LIQUIDADO = 276;
export const PARAM_RESUMO_ANUAL_EXIBIR_PAGO = 277;
