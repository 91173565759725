import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { findResponsavelLAI } from '../leisregemportal/Actions';
import LeiAcessoInformacaoResponsavel from '../leisregemportal/LeiAcessoInformacaoResponsavel.react.js';
import * as actions from './Actions.js';
import { addHttp } from 'portaltransparencia-common/libs/LinkExterno';
import {
  PARAM_REDES_SOCIAIS_FACEBOOK,
  PARAM_REDES_SOCIAIS_INSTAGRAM,
  PARAM_REDES_SOCIAIS_TWITTER,
  PARAM_REDES_SOCIAIS_WHATSAPP,
  PARAM_REDES_SOCIAIS_YOUTUBE
} from 'portaltransparencia-common/parametros/ParametrosPortalTransparencia';

export class FormAtendimento extends Component {
  static propTypes = {
    atendimento: PropTypes.object.isRequired,
    findAtendimento: PropTypes.func.isRequired,
    findResponsavelLAI: PropTypes.func,
    responsavelLAI: PropTypes.object,
    entidade: PropTypes.object,
    exercicio: PropTypes.object,
    configAparencia: PropTypes.object,
    findConfigAparencia: PropTypes.func,
    findParametrosRedesSociais: PropTypes.func,
    parametrosRedesSociais: PropTypes.object
  };

  componentDidMount() {
    const {
      atendimento,
      findAtendimento,
      findResponsavelLAI,
      findConfigAparencia,
      findParametrosRedesSociais,
      entidade,
      exercicio
    } = this.props;
    if (!atendimento.has('id')) {
      findAtendimento(entidade.get('id'), exercicio.get('id'));
      findResponsavelLAI();
    }
    findParametrosRedesSociais(entidade.get('id'));
    findConfigAparencia(
      'entidade=' + entidade.get('id') + '&exercicio=' + exercicio.get('id')
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.entidade != this.props.entidade ||
      nextProps.exercicio != this.props.exercicio
    ) {
      nextProps.findAtendimento(
        nextProps.entidade.get('id'),
        nextProps.exercicio.get('id')
      );
      nextProps.findResponsavelLAI();
      nextProps.findParametrosRedesSociais(nextProps.entidade.get('id'));
      nextProps.findConfigAparencia(
        'entidade=' +
          nextProps.entidade.get('id') +
          '&exercicio=' +
          nextProps.exercicio.get('id')
      );
    }
  }

  renderResponsavel = () => {
    const { responsavelLAI } = this.props;

    return <LeiAcessoInformacaoResponsavel responsavelLAI={responsavelLAI} />;
  };

  renderRedeSocialItem = (link, className) => {
    return (
      <Col sm={1}>
        <a
          href={addHttp(link)}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: '#464646' }}
        >
          <button className={className} />
        </a>
      </Col>
    );
  };

  renderRedesSociais = () => {
    const { parametrosRedesSociais } = this.props;

    if (parametrosRedesSociais) {
      const parametrosPreenchidos = parametrosRedesSociais.filter(
        parametro => parametro.get('valor') !== ''
      );

      let linkFacebook = '';
      let linkInstagram = '';
      let linkTwitter = '';
      let linkWhatsApp = '';
      let linkYoutube = '';

      if (parametrosPreenchidos.size > 0) {
        parametrosPreenchidos.map(parametro => {
          switch (parametro.get('id').get('id')) {
            case PARAM_REDES_SOCIAIS_FACEBOOK:
              linkFacebook = parametro.get('valor');
              break;
            case PARAM_REDES_SOCIAIS_INSTAGRAM:
              linkInstagram = parametro.get('valor');
              break;
            case PARAM_REDES_SOCIAIS_TWITTER:
              linkTwitter = parametro.get('valor');
              break;
            case PARAM_REDES_SOCIAIS_WHATSAPP:
              linkWhatsApp = parametro.get('valor');
              break;
            case PARAM_REDES_SOCIAIS_YOUTUBE:
              linkYoutube = parametro.get('valor');
              break;
            default:
              break;
          }
        });

        return (
          <div style={{ fontSize: '30px' }}>
            <b style={{ fontSize: '14px' }}>Acompanhe nossas Redes Sociais</b>
            <br></br>
            <Row>
              {linkFacebook &&
                linkFacebook.length > 1 &&
                this.renderRedeSocialItem(
                  linkFacebook,
                  'fa fa-facebook-square'
                )}
              {linkInstagram &&
                linkInstagram.length > 1 &&
                this.renderRedeSocialItem(linkInstagram, 'fa fa-instagram')}
              {linkTwitter &&
                linkTwitter.length > 1 &&
                this.renderRedeSocialItem(linkTwitter, 'fa fa-twitter')}
              {linkWhatsApp &&
                linkWhatsApp.length > 1 &&
                this.renderRedeSocialItem(linkWhatsApp, 'fa fa-whatsapp')}
              {linkYoutube &&
                linkYoutube.length > 1 &&
                this.renderRedeSocialItem(linkYoutube, 'fa fa-youtube-play')}
            </Row>
          </div>
        );
      }
    }
  };

  renderAtendimento = data => {
    if (data.get('id').get('id') == 50) {
      /* Orgão */

      return (
        <h3
          key={data.get('id').get('entidade') + '_' + data.get('id').get('id')}
        >
          <b>{data.get('valor')}</b>
          <br />
          <br />
        </h3>
      );
    } else if (data.get('id').get('id') == 56) {
      /* Horario Atendimento */

      return (
        <h4
          key={data.get('id').get('entidade') + '_' + data.get('id').get('id')}
        >
          <br />
          {data.get('valor')}
          <br />
        </h4>
      );
    } else {
      return (
        <h4 key={data.get('id').get('entidade') + data.get('id').get('id')}>
          <label>{data.get('valor')}</label>
        </h4>
      );
    }
  };

  onClickSelo = () => {
    this.props.history.push('/selo');
  };

  render() {
    const { atendimento, configAparencia } = this.props;

    return (
      <div>
        <Row>
          <Col sm={4}>
            {atendimento.map(this.renderAtendimento)}
            {this.renderRedesSociais()}
          </Col>
          <Col sm={4}>{this.renderResponsavel()}</Col>
          {configAparencia &&
            configAparencia.size > 0 &&
            configAparencia.get('selo') && (
              <Col md={4} mdOffset={0} sm={3} smOffset={1}>
                <b>Certificado</b>
                <Row>
                  <Col xs={6}>
                    <img
                      className="img-responsive mt-xs"
                      src={`data:image/*;base64,${configAparencia.get('selo')}`}
                      title="Certificado de Qualidade em Transparência Pública"
                      onClick={this.onClickSelo}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={6} style={{ textAlign: 'center' }}>
                    Certificado de Qualidade em Transparência Pública
                  </Col>
                </Row>
              </Col>
            )}
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    atendimento: state.atendimento.get('atendimento'),
    responsavelLAI: state.leiesic.get('responsavelLAI'),
    entidade: state.auth.getIn(['userInfo', 'entidade']),
    exercicio: state.auth.getIn(['userInfo', 'exercicio']),
    configAparencia: state.atendimento.get('configAparencia'),
    parametrosRedesSociais: state.atendimento.get('parametrosRedesSociais')
  };
}

const allActions = {
  ...actions,
  findResponsavelLAI
};

export default connect(
  mapStateToProps,
  allActions
)(withRouter(FormAtendimento));
