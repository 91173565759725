export const OBRAS_LIST = 'OBRAS_LIST';
export const OBRAS_LIST_START = 'OBRAS_LIST_START';
export const OBRAS_LIST_SUCCESS = 'OBRAS_LIST_SUCCESS';
export const OBRAS_LIST_ERROR = 'OBRAS_LIST_ERROR';

export const OBRA = 'OBRA';
export const OBRA_START = 'OBRA_START';
export const OBRA_SUCCESS = 'OBRA_SUCCESS';
export const OBRA_ERROR = 'OBRA_ERROR';

export const CONSULTA_OBRAS = 'CONSULTA_OBRAS';
export const CONSULTA_OBRAS_START = 'CONSULTA_OBRAS_START';
export const CONSULTA_OBRAS_SUCCESS = 'CONSULTA_OBRAS_SUCCESS';
export const CONSULTA_OBRAS_ERROR = 'CONSULTA_OBRAS_ERROR';

export const SETISPARALISADA_OBRAS = 'SETISPARALISADA_OBRAS';
export const SETISPARALISADA_OBRAS_START = 'SETISPARALISADA_OBRAS_START';
export const SETISPARALISADA_OBRAS_SUCCESS = 'SETISPARALISADA_OBRAS_SUCCESS';
export const SETISPARALISADA_OBRAS_ERROR = 'SETISPARALISADA_OBRAS_ERROR';

export const RESET_CONSULTA_OBRAS = 'RESET_CONSULTA_OBRAS';

export const SITUACOES_OBRAS = 'SITUACOES_OBRAS';
export const SITUACOES_OBRAS_START = 'SITUACOES_OBRAS_START';
export const SITUACOES_OBRAS_SUCCESS = 'SITUACOES_OBRAS_SUCCESS';
export const SITUACOES_OBRAS_ERROR = 'SITUACOES_OBRAS_ERROR';

export const MEDICOES = 'MEDICOES';
export const MEDICOES_START = 'MEDICOES_START';
export const MEDICOES_SUCCESS = 'MEDICOES_SUCCESS';
export const MEDICOES_ERROR = 'MEDICOES_ERROR';

export const PLANILHAS = 'PLANILHAS';
export const PLANILHAS_START = 'PLANILHAS_START';
export const PLANILHAS_SUCCESS = 'PLANILHAS_SUCCESS';
export const PLANILHAS_ERROR = 'PLANILHAS_ERROR';

export const OBRA_EMPENHOS = 'OBRA_EMPENHOS';
export const OBRA_EMPENHOS_START = 'OBRA_EMPENHOS_START';
export const OBRA_EMPENHOS_SUCCESS = 'OBRA_EMPENHOS_SUCCESS';
export const OBRA_EMPENHOS_ERROR = 'OBRA_EMPENHOS_ERROR';

export const ORDEM_ARQUIVOS = 'ORDEM_ARQUIVOS';
export const ORDEM_ARQUIVOS_START = 'ORDEM_ARQUIVOS_START';
export const ORDEM_ARQUIVOS_SUCCESS = 'ORDEM_ARQUIVOS_SUCCESS';
export const ORDEM_ARQUIVOS_ERROR = 'ORDEM_ARQUIVOS_ERROR';

export const MEDICAO_ARQUIVOS = 'MEDICAO_ARQUIVOS';
export const MEDICAO_ARQUIVOS_START = 'MEDICAO_ARQUIVOS_START';
export const MEDICAO_ARQUIVOS_SUCCESS = 'MEDICAO_ARQUIVOS_SUCCESS';
export const MEDICAO_ARQUIVOS_ERROR = 'MEDICAO_ARQUIVOS_ERROR';

export const FONTES_RECURSO = 'FONTES_RECURSO';
export const FONTES_RECURSO_START = 'FONTES_RECURSO_START';
export const FONTES_RECURSO_SUCCESS = 'FONTES_RECURSO_SUCCESS';
export const FONTES_RECURSO_ERROR = 'FONTES_RECURSO_ERROR';

export const OBRA_RESPONSAVEL = 'OBRA_RESPONSAVEL';
export const OBRA_RESPONSAVEL_START = 'OBRA_RESPONSAVEL_START';
export const OBRA_RESPONSAVEL_SUCCESS = 'OBRA_RESPONSAVEL_SUCCESS';
export const OBRA_RESPONSAVEL_ERROR = 'OBRA_RESPONSAVEL_ERROR';
