import { Map, List, fromJS } from 'immutable';
import { normalizePath, moveInArray } from './Utils.js';

export function loadRecord(state, payload) {
  return state
    .remove('previousRecord')
    .set('currentRecord', fromJS(payload))
    .set('formState', 'browse');
}

export function search(state, payload) {
  if (payload.content) {
    return searchPaginated(state, payload);
  }
  return state.set('searchResult', fromJS(payload));
}

function searchPaginated(state, payload) {
  const page = Map({
    size: payload.size,
    last: payload.last,
    totalPages: payload.totalPages,
    totalElements: payload.totalElements,
    number: payload.number,
    sort: payload.sort,
    first: payload.first,
    numberOfElements: payload.numberOfElements
  });

  return state.set('searchResult', fromJS(payload.content)).set('page', page);
}

export function saveRecord(state, payload) {
  return state
    .remove('previousRecord')
    .set('currentRecord', fromJS(payload))
    .set('formState', 'browse');
}

export function deleteRecord(state, payload) {
  return state.update('searchResult', result => {
    const index = result.indexOf(fromJS(payload));

    if (index >= 0) {
      return result.delete(result.indexOf(fromJS(payload)));
    }
    return result;
  });
}

export function newRecord(state, payload) {
  const data = fromJS(payload);

  return state
    .set('previousRecord', data)
    .set('currentRecord', data)
    .set('formState', 'insert');
}

export function editRecord(state, payload) {
  const data = fromJS(payload);

  return state
    .set('previousRecord', data)
    .set('currentRecord', data)
    .set('formState', 'edit');
}

export function cancelRecord(state) {
  return state
    .set('currentRecord', state.get('previousRecord'))
    .remove('previousRecord')
    .set('formState', 'browse');
}

export function startLoad(state) {
  return state.set('isLoading', true);
}

export function endLoad(state) {
  return state.set('isLoading', false);
}

export function startSubmit(state) {
  return state.set('isSubmiting', true);
}

export function endSubmit(state) {
  return state.set('isSubmiting', false);
}

export function changeFieldValue(state, payload, basePath = 'currentRecord') {
  const value = fromJS(payload.value);
  const path = normalizePath(payload.field);

  const currentField = state.getIn([basePath, ...path]);

  if (List.isList(currentField)) {
    const defaultFind = item => item.get('id') === value.get('id');
    const findFunction = payload.finder || defaultFind;

    const index = currentField.findIndex(item => findFunction(item, value));

    if (index < 0) {
      throw new Error(`Item não encontrado para o campo: ${payload.field}.`);
    }

    return state.setIn([basePath, ...path], currentField.set(index, value));
  }

  return state.setIn([basePath, ...path], value);
}

export function addItem(state, payload, basePath = 'currentRecord') {
  return state.updateIn([basePath, payload.field], List(), list =>
    list.push(fromJS(payload.value))
  );
}

export function reorder(state, payload, basePath = 'currentRecord') {
  const value = fromJS(payload.value);
  const path = normalizePath(payload.field);
  const currentRecord = state.getIn([basePath, ...path]);

  if (List.isList(currentRecord)) {
    const index = currentRecord.findIndex(
      item => item.get('id') === value.get('id')
    );

    if (index < 0) {
      throw new Error('Item não encontrado com o código: ' + value.get('id'));
    }

    let arrayOrder = fromJS(
      moveInArray(currentRecord.toArray(), payload.oldIndex, payload.newIndex)
    );

    return state.setIn([basePath, ...path], arrayOrder);
  }
}

export function removeItem(state, payload, basePath = 'currentRecord') {
  const value = fromJS(payload.value);
  const path = normalizePath(payload.field);
  const currentRecord = state.getIn([basePath, ...path]);

  if (List.isList(currentRecord)) {
    const index = currentRecord.findIndex(
      item => item.get('id') === value.get('id')
    );

    if (index < 0) {
      throw new Error('Item não encontrado com o código: ' + value.get('id'));
    }

    return state.updateIn([basePath, payload.field], list =>
      list.delete(list.indexOf(fromJS(payload.value)))
    );
  }
}

export function addFilter(state, payload) {
  const filters = state.get('searchFilter', List());

  const index = filters.findIndex(item => item.get('field') === payload.field);

  if (index >= 0) {
    return state.set('searchFilter', filters.set(index, fromJS(payload)));
  }

  return state.update('searchFilter', List(), list =>
    list.push(fromJS(payload))
  );
}

export function removeFilter(state, payload) {
  const filters = state.get('searchFilter', List());

  const index = filters.findIndex(item => item.get('field') === payload.field);

  if (index >= 0) {
    return state.updateIn(['searchFilter'], list => list.delete(index));
  }
}

export function loadModel(state, payload) {
  return state.set('model', fromJS(payload.fields));
}
