import Panel from 'portaltransparencia-common/components/Panel.react.js';
import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import NotasExplicativas from '../notaexplicativa/Form.react';
import SearchPagination from 'portaltransparencia-common/components/SearchPagination.react.js';

import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import PageHeader from '../components/PageHeader.react.js';
import * as actions from './Actions.js';

export class FormEstruturaOrganizacionalDetail extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    page: PropTypes.object.isRequired,
    findServidoresPorLotacao: PropTypes.func.isRequired,
    findEstruturasPaginado: PropTypes.func.isRequired
  };

  componentDidMount() {
    const {
      match: {
        params: { id }
      },
      findServidoresPorLotacao
    } = this.props;
    findServidoresPorLotacao(id);
  }

  getServidor = servidor => {
    return {
      matricula: servidor.get('matricula'),
      nome: servidor.get('nome'),
      cargo: servidor.get('cargo')
    };
  };

  renderServidores = servidor => {
    return (
      <TableActions
        id="btnAction"
        ref="row"
        key={servidor.get('matricula')}
        data={servidor}
      >
        <td>{servidor.get('matricula')}</td>
        <td>{servidor.get('nome')}</td>
        <td>{servidor.get('cargo')}</td>
      </TableActions>
    );
  };

  render() {
    const { page } = this.props;
    return (
      <section>
        <PageHeader location={this.props.location} />
        <NotasExplicativas pathname={this.props.location.pathname} />

        <Panel title="Servidores" isTable>
          <div className="panel-table-outer">
            <table className="panel-table striped fancy">
              <TableHeader
                empty={page.isEmpty()}
                text="Nenhum Servidor cadastrado nesta Lotação"
              >
                <tr>
                  <th>Matrícula</th>
                  <th>Nome</th>
                  <th>Cargo</th>
                </tr>
              </TableHeader>
              <tbody>{page.map(this.renderServidores)}</tbody>
            </table>
          </div>
          <SearchPagination page={page} searchWithPage={this.searchWithPage} />
        </Panel>
      </section>
    );
  }
}

function mapStateToProps(state) {
  const page = state.estrutura.get('servidores');
  return {
    page
  };
}

export default connect(
  mapStateToProps,
  actions
)(FormEstruturaOrganizacionalDetail);
