import ExportButtonGroup from 'portaltransparencia-common/components/ExportButtonGroup.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import configuration from 'portaltransparencia-common/config/Config.js';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';
import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import TableHeaderItem from 'portaltransparencia-common/components/TableHeaderItem.react';
import PageHeader from '../components/PageHeader.react.js';
import NotasExplicativas from '../notaexplicativa/Form.react';
import UltimaAtualizacao from '../ultimaatualizacao/Form.react.js';
import * as actions from './Actions.js';
import { addErrorNotification } from 'portaltransparencia-common/ui/Actions.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react.js';
import {
  addFilterUrl,
  addPagination
} from 'portaltransparencia-common/libs/AddFilter';
import SearchPagination from 'portaltransparencia-common/components/SearchPagination.react.js';
import { Map, fromJS } from 'immutable';
import {
  onChange,
  resetCrud
} from 'portaltransparencia-common/crud/Actions.js';
import Input from 'portaltransparencia-common/components/EloInput.react.js';
import FilterButtons from '../components/FilterButtons.react.js';

export class FormRepasse extends Component {
  static propTypes = {
    pageRecebidos: PropTypes.object.isRequired,
    findRepassesRecebidos: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    location: PropTypes.object.isRequired,
    addErrorNotification: PropTypes.func.isRequired,
    meses: PropTypes.object.isRequired,
    filtros: PropTypes.object,
    onChange: PropTypes.func,
    resetCrud: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.onResetFiltro('filtroRepasses');
  }

  onExporta = (exportType, openTab, windowParams) => {
    const { apiURL } = configuration;
    const { pageRecebidos, addErrorNotification } = this.props;

    if (pageRecebidos.get('content').size > 0) {
      let filter =
        this.montaFilter(pageRecebidos) + '&exportType=' + exportType;

      window.open(
        `${apiURL}/api/repasses/recebidos/report?${filter}`,
        openTab,
        windowParams
      );
    } else {
      addErrorNotification(
        'A consulta não apresentou resultado. Não é possível exportar para o formato selecionado!'
      );
    }
  };

  onConsultaRepasses = event => {
    const { pageRecebidos } = this.props;

    const initialPage = pageRecebidos.set('number', 0);

    this.searchWithPage(initialPage);

    event && event.preventDefault();
    this.refs.panelFiltroRepasses && this.refs.panelFiltroRepasses.collapse();
  };

  renderRepasses = data => {
    const key =
      data.get('entidade') +
      '_' +
      data.get('exercicio') +
      '_' +
      data.get('mes');

    return (
      <TableActions
        ref="row"
        key={key}
        data={data}
        onMore={data.get('valorRecebido') > 0 ? this.onViewDetalhes : null}
      >
        <td>{DateUtils.getMonthDescription(data.get('mes'))}</td>
        <td style={{ textAlign: 'right' }}>
          <NumberFormatter value={data.get('valorPrevistoReceberMensal')} />
        </td>
        <td style={{ textAlign: 'right' }}>
          <NumberFormatter value={data.get('valorAutorizadoMensal')} />
        </td>
        <td style={{ textAlign: 'right' }}>
          <NumberFormatter value={data.get('valorRecebido')} />
        </td>
        <td style={{ textAlign: 'right' }}>
          <NumberFormatter value={data.get('valorRestanteReceber')} />
        </td>
        <td style={{ textAlign: 'right' }}>
          <NumberFormatter value={data.get('valorRecebidoSobrePrevisto')} />
        </td>
        <td style={{ textAlign: 'right' }}>
          <NumberFormatter value={data.get('valorRestanteAutorizado')} />
        </td>
        <td style={{ textAlign: 'right' }}>
          <NumberFormatter value={data.get('valorRecebidoSobreAutorizado')} />
        </td>
      </TableActions>
    );
  };

  onChangeFiltro = event => {
    const { onChange } = this.props;

    onChange('filtroRepasses', event);
  };

  onResetFiltro = () => {
    const { resetCrud, findRepassesRecebidos } = this.props;
    resetCrud('filtroRepasses');
    let userInfo = StorageService.getItem('userInfo');
    const { entidade, exercicio } = userInfo;

    let filter = `${`entidade=${entidade.id}&exercicio=${exercicio.id}`}`;

    findRepassesRecebidos(filter);
  };

  montaFilter = function (page) {
    const { entidade, exercicio } = StorageService.getItem('userInfo');

    let filter = `${`entidade=${entidade.id}&exercicio=${exercicio.id}`}`;

    if (this.refs.valorRecebido && this.refs.valorRecebido.getValue()) {
      filter = addFilterUrl(
        filter,
        'valorRecebido=' + this.refs.valorRecebido.getValue()
      );
    }

    if (this.refs.mesRepasse && this.refs.mesRepasse.getValue()) {
      filter = addFilterUrl(filter, 'mes=' + this.refs.mesRepasse.getValue());
    }

    return addPagination(page, filter);
  };

  searchWithPage = page => {
    const { findRepassesRecebidos } = this.props;

    let filter = this.montaFilter(page);

    findRepassesRecebidos(filter);
  };

  renderCombo = item => {
    return (
      <option key={item.get('value')} value={item.get('value')}>
        {item.get('label')}
      </option>
    );
  };

  onViewDetalhes = item => {
    const entidade = item.get('entidade');
    const exercicio = item.get('exercicio');
    const tipo = 'R';
    const mes = item.get('mes');

    this.props.history.push(
      `/repasses-recebidos/detalhes?entidade=${entidade}&exercicio=${exercicio}&tipo=${tipo}&mes=${mes}`
    );
  };

  render() {
    const { pageRecebidos, filtros, meses } = this.props;

    if (!pageRecebidos.has('content')) {
      return null;
    }

    return (
      <section>
        <PageHeader location={this.props.location} />

        <NotasExplicativas pathname={this.props.location.pathname} />

        <Panel
          title="Consulta Repasses Mensais Recebidos"
          isForm
          mobileClose
          ref="panelFiltroRepasses"
        >
          <form>
            <Row>
              <Col sm={2}>
                <Input
                  id="valorRecebido"
                  name="valorRecebido"
                  ref="valorRecebido"
                  placeholder="Valor Recebido"
                  label="Valor Recebido"
                  type="number"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('valorRecebido')}
                />
              </Col>
              <Col sm={2}>
                <Input
                  id="mesRepasse"
                  name="mesRepasse"
                  ref="mesRepasse"
                  label="Mês do Repasse"
                  type="select"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('mesRepasse')}
                >
                  <option value="">Todos</option>
                  {meses.map(this.renderCombo)}
                </Input>
              </Col>
            </Row>
            <Row>
              <Col sm={8}>
                <FilterButtons
                  idBtnReset="btnResetFilter"
                  onConsulta={this.onConsultaRepasses}
                  onReset={this.onResetFiltro}
                />
              </Col>
            </Row>
          </form>
        </Panel>

        <Panel isForm expansible={false} tableResponsive>
          <Row>
            <Col xs={6}>
              <UltimaAtualizacao
                modulo="3"
                table="eloportalrepasses"
                column="dataultimaatualizacao"
              />
            </Col>
            <Col xs={6}>
              <ExportButtonGroup onClick={this.onExporta} visible={true} />
            </Col>
          </Row>

          <div className="panel-table-outer">
            <table className="panel-table striped fancy">
              <TableHeader
                empty={pageRecebidos.get('content').isEmpty()}
                text="Nenhum repasse encontrado."
              >
                <tr>
                  <TableHeaderItem
                    field="mes"
                    label="Mês do Repasse"
                    page={pageRecebidos}
                    searchWithPage={this.searchWithPage}
                  />
                  <TableHeaderItem
                    field="valorPrevistoReceberMensal"
                    label="Valor Previsto (a)"
                    page={pageRecebidos}
                    searchWithPage={this.searchWithPage}
                    alignment="right"
                  />
                  <TableHeaderItem
                    field="valorAutorizadoMensal"
                    label="Duodécimo Apurado (b)"
                    page={pageRecebidos}
                    searchWithPage={this.searchWithPage}
                    alignment="right"
                  />
                  <TableHeaderItem
                    field="valorRecebido"
                    label="Repasse Recebido (c)"
                    page={pageRecebidos}
                    searchWithPage={this.searchWithPage}
                    alignment="right"
                  />
                  <TableHeaderItem
                    field="valorRestanteReceber"
                    label={
                      <>
                        Valor Previsto a Receber
                        <br />
                        (d = c - a)
                      </>
                    }
                    page={pageRecebidos}
                    searchWithPage={this.searchWithPage}
                    alignment="right"
                  />
                  <TableHeaderItem
                    field="valorRecebidoSobrePrevisto"
                    label="%"
                    page={pageRecebidos}
                    searchWithPage={this.searchWithPage}
                    alignment="right"
                  />
                  <TableHeaderItem
                    field="valorRestanteAutorizado"
                    label={
                      <>
                        Valor Apurado a Receber
                        <br />
                        (e = c - b)
                      </>
                    }
                    page={pageRecebidos}
                    searchWithPage={this.searchWithPage}
                    alignment="right"
                  />
                  <TableHeaderItem
                    field="valorRecebidoSobreAutorizado"
                    label="%"
                    page={pageRecebidos}
                    searchWithPage={this.searchWithPage}
                    alignment="right"
                  />
                  <th style={{ width: 10 }}></th>
                </tr>
              </TableHeader>
              <tbody>
                {pageRecebidos.get('content').map(this.renderRepasses)}
              </tbody>
            </table>
          </div>
        </Panel>

        <SearchPagination
          page={pageRecebidos}
          searchWithPage={this.searchWithPage}
        />
      </section>
    );
  }
}

function mapStateToProps(state) {
  const meses = fromJS([
    { label: 'Janeiro', value: '01' },
    { label: 'Fevereiro', value: '02' },
    { label: 'Março', value: '03' },
    { label: 'Abril', value: '04' },
    { label: 'Maio', value: '05' },
    { label: 'Junho', value: '06' },
    { label: 'Julho', value: '07' },
    { label: 'Agosto', value: '08' },
    { label: 'Setembro', value: '09' },
    { label: 'Outubro', value: '10' },
    { label: 'Novembro', value: '11' },
    { label: 'Dezembro', value: '12' }
  ]);

  return {
    pageRecebidos: state.repasseRecebido.get('pageRecebidos'),
    filtros: state.crud.getIn(['filtroRepasses', 'currentRecord'], Map({})),
    meses: meses
  };
}

const allActions = {
  ...actions,
  onChange,
  resetCrud,
  addErrorNotification
};

export default connect(mapStateToProps, allActions)(FormRepasse);
