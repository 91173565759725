import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export class LicitacaoDetalheRecurso extends Component {
  static propTypes = {
    recursos: PropTypes.object.isRequired,
    onDownload: PropTypes.func.isRequired,
    isIntegradoOxy: PropTypes.bool.isRequired
  };

  verificaArquivo = data => {
    if (data.get('arquivos').size != 0) {
      return <td> {data.get('arquivos').map(this.renderArquivo)}</td>;
    }
    return <td>Sem Arquivos</td>;
  };

  renderArquivo = arquivo => {
    const { onDownload } = this.props;

    const downloadArquivo = () => {
      return onDownload(arquivo.get('idArquivo'));
    };

    return (
      <div key={arquivo.get('idArquivo')} style={{ marginTop: '3px' }}>
        <a style={{ cursor: 'pointer' }} onClick={downloadArquivo}>
          <i className="fa fa-download" style={{ marginRight: '5px' }} />
          {arquivo.get('nomeArquivo')}
        </a>
      </div>
    );
  };

  onViewDetalhes = recurso => {
    const { isIntegradoOxy } = this.props;
    if (isIntegradoOxy) {
      this.props.history.push(`/recursos/detalhes?id=${recurso.get('uuid')}`);
    } else {
      this.props.history.push(`/recursos/detalhes?id=${recurso.get('id')}`);
    }
  };

  renderRecurso = data => {
    const { isIntegradoOxy } = this.props;

    const key =
      data.get('id') +
      '_' +
      data.get('tipo') +
      '_' +
      data.get('data') +
      '_' +
      data.get('analise');

    return (
      <TableActions
        ref="row"
        key={key}
        data={data}
        onMore={this.onViewDetalhes}
      >
        <td>{DateUtils.formatDateTimeShort(data.get('data'))}</td>
        <td>{data.get('tipo')}</td>
        <td>{data.get('numeroProtocolo')}</td>
        <td>{DateUtils.formatDateTimeShort(data.get('dataProtocolo'))}</td>
        <td>{data.get('analise')}</td>
        {isIntegradoOxy && (
          <td className="center">
            <i
              className={`fas fa-${
                data.get('respeitadoPrazoLegal') === 'S'
                  ? 'check green-60'
                  : 'times red-60'
              }`}
            />
          </td>
        )}
        {this.verificaArquivo(data)}
      </TableActions>
    );
  };

  render() {
    const { recursos, isIntegradoOxy } = this.props;

    return (
      <div className="panel-table-outer">
        <table className="panel-table striped fancy">
          <TableHeader empty={recursos.isEmpty()}>
            <tr>
              <th>Data</th>
              <th>Tipo</th>
              <th>Número Protocolo</th>
              <th>Data Protocolo</th>
              <th>Análise</th>
              {isIntegradoOxy && <th>Prazo Legal</th>}
              <th>Arquivos</th>
            </tr>
          </TableHeader>
          <tbody>{recursos.map(this.renderRecurso)}</tbody>
        </table>
      </div>
    );
  }
}

export default LicitacaoDetalheRecurso;
