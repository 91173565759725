import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';

import * as types from './Types.js';
import userInfoReducer from './UserInfoReducer';

const initialState = fromJS({
  entidadeSelecionada: {},
  configuracoesGerais: {},
  entidades: [],
  exercicios: [],
  exercicioLogado: {},
  brasao: '',
  userInfo: {}
});

function atualizaEntidadeSelecionada(state, entidade, exercicio) {
  const exercicioSelecionado = state.get('exercicios').filter(value => {
    return (
      value.getIn(['id', 'entidade', 'id']) == entidade &&
      value.getIn(['id', 'exercicio']) == exercicio
    );
  });
  const entidadeSelecionada = state.get('entidades').filter(value => {
    return value.get('id') == entidade;
  });
  return state
    .set('exercicioLogado', exercicioSelecionado.first())
    .set('entidadeSelecionada', entidadeSelecionada.first());
}

function entidadeReducer(state = initialState, action) {
  switch (action.type) {
    case types.ENTIDADE_BRASAO_START:
    case types.ENTIDADES_LIST_START:
    case types.EXERCICIOSBYENTIDADE_LIST_START: {
      return startLoad(state);
    }

    case types.ENTIDADE_BRASAO_ERROR:
    case types.ENTIDADES_LIST_ERROR:
    case types.EXERCICIOSBYENTIDADE_LIST_ERROR: {
      return endLoad(state);
    }

    case types.ENTIDADE_BRASAO_SUCCESS: {
      return endLoad(state.set('brasao', fromJS(action.payload)));
    }

    case types.ENTIDADES_LIST_SUCCESS: {
      return endLoad(state.set('entidades', fromJS(action.payload)));
    }

    case types.EXERCICIOSBYENTIDADE_LIST_SUCCESS: {
      const nextState = endLoad(
        state.set('exercicios', fromJS(action.payload))
      );

      const entidade = state.getIn(['userInfo', 'entidade', 'id']);
      const exercicio = state.getIn(['userInfo', 'exercicio', 'id']);

      return atualizaEntidadeSelecionada(nextState, entidade, exercicio);
    }

    case types.EXERCICIO_LOGADO_SUCCESS: {
      const { entidade, exercicio } = action.payload;

      const nextState = userInfoReducer(state, action);

      return atualizaEntidadeSelecionada(nextState, entidade, exercicio);
    }

    case types.CONFIGURACOES_GERAIS_SUCCESS: {
      return endLoad(state.set('configuracoesGerais', fromJS(action.payload)));
    }
  }

  return state;
}

export default entidadeReducer;
