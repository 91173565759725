import DisplayData from 'portaltransparencia-common/components/displaydata/DisplayData.react.js';
import DisplayDataItem from 'portaltransparencia-common/components/displaydata/DisplayDataItem.react.js';
import DisplayDataRow from 'portaltransparencia-common/components/displaydata/DisplayDataRow.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import NumberUtils from 'portaltransparencia-common/libs/NumberUtils.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export class FormDetailInformacao extends Component {
  static propTypes = {
    veiculo: PropTypes.object.isRequired,
    idEntidade: PropTypes.any.isRequired
  };

  onShowBem = () => {
    const { veiculo } = this.props;
    const { idEntidade } = this.props;
    const bem = veiculo.get('veiculo');
    const chapa = veiculo.get('chapa');
    this.props.history.push(
      `/patrimonio/detalhes?entidade=${idEntidade}&tipo=V&bem=${bem}&chapa=${chapa}`
    );
  };

  render() {
    const { veiculo } = this.props;

    if (
      !veiculo.get('descricao') ||
      !(veiculo.get('veiculo') || veiculo.get('veiculoOxy'))
    ) {
      return null;
    }
    return (
      <Panel title="Dados do Veículo">
        <DisplayData border>
          <DisplayDataRow>
            <DisplayDataItem
              sm={10}
              title="Descrição"
              value={veiculo.get('descricao')}
            />
            <DisplayDataItem
              sm={2}
              title="Situação"
              value={veiculo.get('baixado') === 'S' ? 'Baixado' : 'Ativo'}
            />
          </DisplayDataRow>

          <DisplayDataRow>
            <DisplayDataItem
              sm={4}
              title="Valor"
              value={NumberUtils.format(
                veiculo.get('valorAquisicao'),
                'R$ 0,0.00'
              )}
            />
            <DisplayDataItem
              sm={3}
              title="Nota Fiscal"
              value={veiculo.get('notaFiscal')}
            />
            <DisplayDataItem
              sm={5}
              title="Combustível"
              value={veiculo.get('combustivelDescricao')}
            />
          </DisplayDataRow>

          <DisplayDataRow>
            <DisplayDataItem
              sm={4}
              title="Chassi"
              value={veiculo.get('chassi')}
            />
            <DisplayDataItem
              sm={2}
              title="Placa"
              value={veiculo.get('placa')}
            />
            <DisplayDataItem
              sm={2}
              title="Frota"
              value={veiculo.get('numeroFrota')}
            />
            <DisplayDataItem
              sm={2}
              title="Renavan"
              value={veiculo.get('renavan')}
            />
          </DisplayDataRow>

          <DisplayDataRow>
            <DisplayDataItem
              sm={4}
              title="Tipo Incorporação"
              value={veiculo.get('tipoIncorporacao')}
            />
            <DisplayDataItem
              sm={4}
              title="Data Incorporação"
              value={DateUtils.transform(veiculo.get('dataAquisicao'))}
            />
            <DisplayDataItem
              sm={4}
              title="Centro de Custo"
              value={veiculo.get('centroCusto')}
            />
          </DisplayDataRow>
        </DisplayData>
      </Panel>
    );
  }
}

export default FormDetailInformacao;
