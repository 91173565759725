import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';

import * as types from './Types.js';

const initialState = fromJS({
  empenhosEmLiquidacao: []
});

function emLiquidacaoReducer(state = initialState, action) {
  switch (action.type) {
    case types.EMPENHOS_EM_LIQUIDACAO_START: {
      return startLoad(state);
    }

    case types.EMPENHOS_EM_LIQUIDACAO_ERROR: {
      return endLoad(state);
    }

    case types.EMPENHOS_EM_LIQUIDACAO_SUCCESS: {
      return endLoad(state.set('empenhosEmLiquidacao', fromJS(action.payload)));
    }
  }

  return state;
}

export default emLiquidacaoReducer;
