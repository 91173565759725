import { applyMiddleware, compose, createStore } from 'redux';
import promiseMiddleware from 'redux-promise-middleware';
import CreateFetch from './CreateFetch';
import {
  injectMiddleware,
  notificationMiddleware
} from '../middleware/Middleware.js';
import { SUFFIX_START, SUFFIX_SUCCESS, SUFFIX_ERROR } from '../crud/Types.js';
import validate from '../validate';
import { LOGOUT_SUCCESS } from '../auth/Types.js';

export default function configureStore(
  pathReducer,
  appReducer,
  apiEndpoint,
  initialState,
  browserHistory
) {
  const middleware = [
    injectMiddleware({
      dispatchedCreateFetch: deps => CreateFetch(apiEndpoint, deps),
      browserHistory,
      validate
    }),
    promiseMiddleware({
      promiseTypeSuffixes: [SUFFIX_START, SUFFIX_SUCCESS, SUFFIX_ERROR]
    }),
    notificationMiddleware
  ];

  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const createReduxStore = composeEnhancers(applyMiddleware(...middleware));

  const resetStateOnSignOut = reducer => (state, action) => {
    // Reset app state on sign out, stackoverflow.com/q/35622588/233902.
    if (action.type === LOGOUT_SUCCESS) {
      state = {
        routing: state.routing, // Routing state has to be reused.
        ...initialState
      };
    }

    return reducer(state, action);
  };

  const reducer = resetStateOnSignOut(appReducer);

  const store = createReduxStore(createStore)(reducer, initialState);

  return store;
}
