import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';

import * as types from './Types.js';

const initialState = fromJS({
  leiesic: {},
  responsavelLAI: {},
  instrucaoNormativa: {},
  leiOrganicaMunicipio: {}
});

function leiesicReducer(state = initialState, action) {
  switch (action.type) {
    case types.INSTRUCAO_NORMATIVA_START:
    case types.LEIESIC_START:
    case types.RESPONSAVEL_LAI_START:
    case types.LEI_ORGANICA_MUNICIPIO_START: {
      return startLoad(state);
    }

    case types.INSTRUCAO_NORMATIVA_SUCCESS: {
      return endLoad(state.set('instrucaoNormativa', fromJS(action.payload)));
    }

    case types.LEIESIC_SUCCESS: {
      return endLoad(state.set('leiesic', fromJS(action.payload)));
    }

    case types.LEI_ORGANICA_MUNICIPIO_SUCCESS: {
      return endLoad(state.set('leiOrganicaMunicipio', fromJS(action.payload)));
    }

    case types.RESPONSAVEL_LAI_SUCCESS: {
      return endLoad(state.set('responsavelLAI', fromJS(action.payload)));
    }

    case types.INSTRUCAO_NORMATIVA_ERROR:
    case types.LEIESIC_ERROR:
    case types.RESPONSAVEL_LAI_ERROR:
    case types.LEI_ORGANICA_MUNICIPIO_ERROR: {
      return endLoad(state);
    }
  }

  return state;
}

export default leiesicReducer;
