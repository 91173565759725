import * as types from './Types.js';

export function setIsParalisada(isParalisada) {
  return () => ({
    type: types.SETISPARALISADA_OBRAS,
    payload: {
      promise: new Promise(resolve => {
        resolve({ isParalisada });
      })
    }
  });
}

export function findObra(entidade, exercicioObra, idOrdemServico, idOxy) {
  const url = idOxy
    ? `/api/obras?idOxy=${idOxy}`
    : `/api/obras?entidade=${entidade}&exercicioObra=${exercicioObra}&idOrdemServico=${idOrdemServico}`;
  return ({ fetch }) => ({
    type: types.OBRA,
    payload: {
      promise: fetch(url)
    }
  });
}

export function searchObras(filter) {
  let url = '/api/obras';

  if (filter !== '') {
    url += '?' + filter;
  }

  return ({ fetch }) => ({
    type: types.CONSULTA_OBRAS,
    payload: {
      promise: fetch(url)
    }
  });
}

export function resetConsulta() {
  return {
    type: types.RESET_CONSULTA_OBRAS,
    payload: {}
  };
}

export function loadSituacoes() {
  return ({ fetch }) => ({
    type: types.SITUACOES_OBRAS,
    payload: {
      data: { text: 'Buscando registros...' },
      promise: fetch('/api/obras/situacoes')
    }
  });
}

export function findMedicoes(entidade, exercicioObra, idOrdemServico, idOxy) {
  const url = idOxy
    ? `/api/obras/medicoes?idOxy=${idOxy}`
    : `/api/obras/medicoes?entidade=${entidade}&exercicioObra=${exercicioObra}&idOrdemServico=${idOrdemServico}`;
  return ({ fetch }) => ({
    type: types.MEDICOES,
    payload: {
      promise: fetch(url)
    }
  });
}

export function findOrdemArquivos(
  entidade,
  exercicioObra,
  idOrdemServico,
  idOxy
) {
  const url = idOxy
    ? `/api/obras/ordens-arquivos?idOxy=${idOxy}`
    : `/api/obras/ordens-arquivos?entidade=${entidade}&exercicioObra=${exercicioObra}&idOrdemServico=${idOrdemServico}`;
  return ({ fetch }) => ({
    type: types.ORDEM_ARQUIVOS,
    payload: {
      promise: fetch(url)
    }
  });
}

export function findPlanilhas(entidade, exercicioObra, idOrdemServico, idOxy) {
  const url = idOxy
    ? `/api/obras/planilhas?idOxy=${idOxy}`
    : `/api/obras/planilhas?entidade=${entidade}&exercicioObra=${exercicioObra}&idOrdemServico=${idOrdemServico}`;
  return ({ fetch }) => ({
    type: types.PLANILHAS,
    payload: {
      promise: fetch(url)
    }
  });
}

export function findArquivosMedicao(
  entidade,
  exercicioMedicao,
  idOrdemServico,
  sequencia,
  idOxy
) {
  let url = '/api/obras/medicoes/arquivos?';

  url += idOxy
    ? `idOxy=${idOxy}&sequencia=${sequencia}`
    : `entidade=${entidade}&exercicioMedicao=${exercicioMedicao}&idOrdemServico=${idOrdemServico}&sequencia=${sequencia}`;
  return ({ fetch }) => ({
    type: types.MEDICAO_ARQUIVOS,
    payload: {
      promise: fetch(url)
    }
  });
}

export function findEmpenhos(entidade, exercicioObra, idOrdemServico, idOxy) {
  const url = idOxy
    ? `/api/obras/empenhos?idOxy=${idOxy}`
    : `/api/obras/empenhos?entidade=${entidade}&exercicioObra=${exercicioObra}&idOrdemServico=${idOrdemServico}`;
  return ({ fetch }) => ({
    type: types.OBRA_EMPENHOS,
    payload: {
      promise: fetch(url)
    }
  });
}

export function findFontesRecursos(
  idOxy,
  entidade,
  exercicioObra,
  idOrdemServico
) {
  const url = idOxy
    ? `/api/obras/fontes-recursos?idOxy=${idOxy}`
    : `/api/obras/fontes-recursos?entidade=${entidade}&exercicioObra=${exercicioObra}&idOrdemServico=${idOrdemServico}`;
  return ({ fetch }) => ({
    type: types.FONTES_RECURSO,
    payload: {
      promise: fetch(url)
    }
  });
}

export function findResponsaveis(idOxy) {
  return ({ fetch }) => ({
    type: types.OBRA_RESPONSAVEL,
    payload: {
      promise: fetch(`/api/obras/responsaveis?idOxy=${idOxy}`)
    }
  });
}
