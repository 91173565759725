import * as types from './Types.js';

export function findServidores(filtros) {
  return ({ fetch }) => ({
    type: types.SERVIDORES_CEDIDOS,
    payload: {
      promise: fetch(`/api/servidores/cedidos?${filtros}`)
    }
  });
}
