import { Map } from 'immutable';
import Input from 'portaltransparencia-common/components/EloInput.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import SearchPagination from 'portaltransparencia-common/components/SearchPagination.react.js';
import {
  onChange,
  resetCrud
} from 'portaltransparencia-common/crud/Actions.js';
import {
  addFilterUrl,
  addPagination
} from 'portaltransparencia-common/libs/AddFilter.js';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import PageHeader from '../components/PageHeader.react.js';
import NotasExplicativas from '../notaexplicativa/Form.react';
import * as actions from './Actions.js';
import AjudaCustoServidorTable from './AjudaCustoServidorTable.react.js';
import configuration from 'portaltransparencia-common/config/Config.js';
import { addErrorNotification } from 'portaltransparencia-common/ui/Actions.js';

export class FormAjudaCusto extends Component {
  static propTypes = {
    pageServidores: PropTypes.object.isRequired,
    findServidoresAjudaCusto: PropTypes.func.isRequired,
    filtros: PropTypes.object,
    onChange: PropTypes.func,
    resetCrud: PropTypes.func,
    location: PropTypes.object.isRequired,
    addErrorNotification: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.carregarServidoresAjudaCusto();
  }

  carregarServidoresAjudaCusto = () => {
    const { pageServidores } = this.props;
    const initialPage = pageServidores.set('number', 0);

    this.searchWithPage(initialPage);
  };

  consultarServidoresAjudaCusto = event => {
    this.carregarServidoresAjudaCusto();
    event && event.preventDefault();
    this.refs.panelFiltroAjudaCusto &&
      this.refs.panelFiltroAjudaCusto.collapse();
  };

  onResetFiltro = () => {
    const { resetCrud, findServidoresAjudaCusto } = this.props;

    resetCrud('filtroAjudasCusto');
    findServidoresAjudaCusto('');
  };

  searchWithPage = page => {
    const { findServidoresAjudaCusto } = this.props;

    findServidoresAjudaCusto(this.montaFiltro(page));
  };

  montaFiltro = function (page) {
    let filter = '';

    const { filtros } = this.props;

    if (filtros.get('matricula')) {
      filter = addFilterUrl(filter, `matricula=${filtros.get('matricula')}`);
    }
    if (filtros.get('nome')) {
      filter = addFilterUrl(filter, `nome=${filtros.get('nome')}`);
    }

    return addPagination(page, filter);
  };

  onChangeFiltro = event => {
    const { onChange } = this.props;

    onChange('filtroAjudasCusto', event);
  };

  onExporta = (exportType, openTab, windowParams) => {
    const { apiURL } = configuration;
    const { pageServidores, addErrorNotification } = this.props;

    if (pageServidores.get('content').size > 0) {
      let filtro =
        this.montaFiltro(pageServidores) + '&exportType=' + exportType;

      window.open(
        `${apiURL}/api/ajuda-custo/report?${filtro}`,
        openTab,
        windowParams
      );
    } else {
      addErrorNotification(
        'A consulta não apresentou resultado. Não é possível exportar para o formato selecionado!'
      );
    }
  };

  render() {
    const { pageServidores, filtros } = this.props;

    const filter = this.montaFiltro(pageServidores);

    if (!pageServidores.has('content')) {
      return null;
    }

    return (
      <section>
        <PageHeader location={this.props.location} />

        <NotasExplicativas pathname={this.props.location.pathname} />

        <Panel
          title="Consultar Servidores para Ajuda de Custo"
          mobileClose
          isForm
          ref="panelFiltroAjudaCusto"
        >
          <form>
            <Row>
              <Col xs={12} sm={4} md={3}>
                <Input
                  id="matricula"
                  ref="matricula"
                  name="matricula"
                  placeholder="Matrícula do Servidor"
                  label="Matrícula do Servidor"
                  type="number"
                  value={filtros.get('matricula')}
                  onChange={this.onChangeFiltro}
                />
              </Col>
              <Col xs={12} sm={4} md={6}>
                <Input
                  id="nome"
                  ref="nome"
                  name="nome"
                  placeholder="Nome do Servidor"
                  label="Nome do Servidor"
                  type="text"
                  value={filtros.get('nome')}
                  onChange={this.onChangeFiltro}
                />
              </Col>
              <Col xs={12} sm={4} md={3}>
                <div className="form-group">
                  <label className="label"></label>
                  <div className="btn-group">
                    <button
                      id="btnFiltrar"
                      className="btn module-color icon-right inline"
                      ref="pesquisar"
                      onClick={this.consultarServidoresAjudaCusto}
                    >
                      Pesquisar
                      <em className="fa fa-search"></em>
                    </button>
                    <button
                      id="btnReset"
                      className="btn neutral icon-right inline no-text"
                      type="button"
                      ref="limpar"
                      onClick={this.onResetFiltro}
                    >
                      Limpar
                      <em className="fa fa-times"></em>
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </form>
        </Panel>

        <AjudaCustoServidorTable
          filter={filter}
          page={pageServidores}
          history={this.props.history}
          searchWithPage={this.searchWithPage}
          onExporta={this.onExporta}
        />

        <SearchPagination
          page={pageServidores}
          searchWithPage={this.searchWithPage}
        />
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    filtros: state.crud.getIn(['filtroAjudasCusto', 'currentRecord'], Map({})),
    pageServidores: state.ajudaCusto.get('servidores')
  };
}

const allActions = {
  ...actions,
  onChange,
  resetCrud,
  addErrorNotification
};

export default connect(mapStateToProps, allActions)(FormAjudaCusto);
