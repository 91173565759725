import * as types from './Types.js';

export function findEmendaParlamentar() {
  return ({ fetch }) => ({
    type: types.EMENDA_PARLAMENTAR,
    payload: {
      promise: fetch('/api/emendas-parlamentares/list')
    }
  });
}

export function searchEmendas(filter) {
  let url = '/api/emendas-parlamentares';

  if (filter !== '') {
    url += '?' + filter;
  }

  return ({ fetch }) => ({
    type: types.CONSULTA_EMENDAS,
    payload: {
      promise: fetch(url)
    }
  });
}

export function resetConsulta() {
  return {
    type: types.RESET_CONSULTA_EMENDAS,
    payload: {}
  };
}

export function findEmenda(entidade, exercicio, emendaParlamentar) {
  return ({ fetch }) => ({
    type: types.EMENDA_PARLAMENTAR,
    payload: {
      promise: fetch(
        `/api/emendas-parlamentares/${entidade}/${exercicio}/${emendaParlamentar}`
      )
    }
  });
}

export function findEmendaById(id) {
  return ({ fetch }) => ({
    type: types.EMENDA_PARLAMENTAR,
    payload: {
      promise: fetch(`/api/emendas-parlamentares/${id}`)
    }
  });
}

export function findConvenioWithoutId(entidade, nrConvenio, nrAnoConvenio) {
  return ({ fetch }) => ({
    type: types.CONVENIO,
    payload: {
      promise: fetch(
        `/api/convenios/link?entidade=${entidade}&nrConvenio=${nrConvenio}&nrAnoConvenio=${nrAnoConvenio}`
      )
    }
  });
}

export function reset() {
  return {
    type: types.FORMULARIO_SOLICITACAO_NOTIFICACAO_RESET
  };
}
