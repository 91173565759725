import { addHttp } from 'portaltransparencia-common/libs/LinkExterno';
import {
  PARAM_ESIC_PROPRIO,
  PARAM_FORMULARIO_MENSAGEM
} from 'portaltransparencia-common/parametros/ParametrosPortalTransparencia';
import * as uiActions from 'portaltransparencia-common/ui/Actions.js';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import ParametroService from '../parametros/ParametroService.js';

export class AcessoESIC extends React.PureComponent {
  static propTypes = {
    paramShowEsic: PropTypes.any,
    paramLinkEsicProprio: PropTypes.string
  };

  static defaultProps = {
    paramShowEsic: '2'
  };

  render() {
    const {
      paramShowEsic,
      offsidebarToggle,
      paramLinkEsicProprio
    } = this.props;

    if (paramShowEsic === '0') {
      return null;
    }

    if (paramShowEsic === '3') {
      return (
        <a href={addHttp(paramLinkEsicProprio)} target="_blank">
          <div className="esic header-buttons">
            <em className="fa fa-e-sic"></em>
            <b className="title">
              Acesso à<br />
              Informação
            </b>
          </div>
        </a>
      );
    }

    return (
      <a>
        <div className="esic header-buttons" onClick={offsidebarToggle}>
          <em className="fa fa-e-sic"></em>
          <b className="title">
            Acesso à<br />
            Informação
          </b>
        </div>
      </a>
    );
  }
}

function mapStateToProps(state) {
  return {
    paramShowEsic: ParametroService.getValorParametro(
      state,
      PARAM_FORMULARIO_MENSAGEM
    ),
    paramLinkEsicProprio: ParametroService.getValorParametro(
      state,
      PARAM_ESIC_PROPRIO
    )
  };
}

export default connect(mapStateToProps, uiActions)(AcessoESIC);
