import DisplayData from 'portaltransparencia-common/components/displaydata/DisplayData.react.js';
import DisplayDataItem from 'portaltransparencia-common/components/displaydata/DisplayDataItem.react.js';
import DisplayDataRow from 'portaltransparencia-common/components/displaydata/DisplayDataRow.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import NumberUtils from 'portaltransparencia-common/libs/NumberUtils.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export class AutorizacaoDetalheInformacao extends Component {
  static propTypes = {
    autorizacao: PropTypes.object.isRequired
  };

  render() {
    const { autorizacao } = this.props;

    return (
      <Panel title={'Autorização de Prestação de Serviço'}>
        <DisplayData border>
          <DisplayDataRow>
            <DisplayDataItem
              sm={4}
              title="Nº Autorização"
              value={autorizacao.get('numeroRequisicao')}
              id="numeroRequisicao"
            />
            <DisplayDataItem
              sm={4}
              title="Ano Autorização"
              value={autorizacao.get('exercicioRequisicao')}
              id="exercicioRequisicao"
            />
            <DisplayDataItem
              sm={4}
              title="Valor Requisição"
              value={NumberUtils.format(autorizacao.get('valorRequisicao'))}
              id="valorRequisicao"
            />
          </DisplayDataRow>
          <DisplayDataRow>
            <DisplayDataItem
              sm={4}
              title="Número NAD"
              value={autorizacao.get('numeroNad')}
              id="numeroNad"
            />
            <DisplayDataItem
              sm={4}
              title="Ano NAD"
              value={autorizacao.get('exercicioNad')}
              id="exercicioNad"
            />
            <DisplayDataItem
              sm={4}
              title="Data NAD"
              value={DateUtils.transform(autorizacao.get('dataNad'))}
              id="dataNad"
            />
          </DisplayDataRow>
          <DisplayDataRow>
            <DisplayDataItem
              sm={8}
              title="Fornecedor"
              value={autorizacao.get('nomeFornecedor')}
              id="nomeFornecedor"
            />
            <DisplayDataItem
              sm={4}
              title="Empenho"
              value={autorizacao.get('empenho')}
              id="empenho"
            />
          </DisplayDataRow>
        </DisplayData>
      </Panel>
    );
  }
}

export default AutorizacaoDetalheInformacao;
