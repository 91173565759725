import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';

import * as types from './Types.js';

const initialState = fromJS({
  servidores: [],
  servidor: [],
  dadosFinanceiros: [],
  dadosPensao: [],
  vencimentosDescontos: [],
  naturezas: [],
  situacoes: [],
  registrosPontos: [],
  servidoresFiltrados: [],
  resumoNatureza: [],
  estagiarios: [],
  estagiario: {},
  inativos: [],
  inativo: {},
  pensionistas: [],
  pensionista: {},
  autonomos: [],
  autonomo: {},
  anotacoes: [],
  horarioFlexivel: []
});

function servidorOxyReducer(state = initialState, action) {
  switch (action.type) {
    case types.SERVIDORES_OXY_RESUMO_START:
    case types.SITUACOES_OXY_LIST_START:
    case types.SERVIDOR_OXY_REGISTROS_PONTOS_START:
    case types.NATUREZAS_OXY_LIST_START:
    case types.SERVIDOR_OXY_VENCIMENTOS_DESCONTOS_START:
    case types.SERVIDOR_OXY_DETALHE_START:
    case types.SERVIDOR_OXY_DETALHE_PENSAO_START:
    case types.SERVIDORES_OXY_LIST_START:
    case types.ESTAGIARIO_OXY_DETALHE_START:
    case types.ESTAGIARIOS_OXY_LIST_START:
    case types.INATIVO_DETALHE_START:
    case types.INATIVOS_LIST_START:
    case types.PENSIONISTA_DETALHE_START:
    case types.PENSIONISTAS_LIST_START:
    case types.AUTONOMO_DETALHE_START:
    case types.AUTONOMOS_LIST_START: {
      return startLoad(state);
    }

    case types.SERVIDORES_OXY_LIST_SUCCESS: {
      return endLoad(state.set('servidores', fromJS(action.payload)));
    }

    case types.SERVIDORES_OXY_RESUMO_ERROR:
    case types.SITUACOES_OXY_LIST_ERROR:
    case types.SERVIDOR_OXY_REGISTROS_PONTOS_ERROR:
    case types.NATUREZAS_OXY_LIST_ERROR:
    case types.SERVIDOR_OXY_VENCIMENTOS_DESCONTOS_ERROR:
    case types.SERVIDOR_OXY_DETALHE_ERROR:
    case types.SERVIDOR_OXY_DETALHE_PENSAO_ERROR:
    case types.SERVIDORES_OXY_LIST_ERROR:
    case types.ESTAGIARIO_OXY_DETALHE_ERROR:
    case types.ESTAGIARIOS_OXY_LIST_ERROR:
    case types.INATIVO_DETALHE_ERROR:
    case types.INATIVOS_LIST_ERROR:
    case types.PENSIONISTA_DETALHE_ERROR:
    case types.PENSIONISTAS_LIST_ERROR:
    case types.AUTONOMO_DETALHE_ERROR:
    case types.AUTONOMOS_LIST_ERROR: {
      return endLoad(state);
    }

    case types.SERVIDOR_OXY_DETALHE_SUCCESS: {
      return endLoad(
        state
          .set('servidor', fromJS(action.payload))
          .set('dadosFinanceiros', fromJS(action.payload.dadosFinanceiros))
          .set('anotacoes', fromJS(action.payload.anotacoes))
          .set('horarioFlexivel', fromJS(action.payload.horarioFlexivel))
      );
    }

    case types.SERVIDOR_OXY_DETALHE_PENSAO_SUCCESS: {
      return endLoad(state.set('dadosPensao', fromJS(action.payload)));
    }

    case types.CLEAR_OXY_DETALHE: {
      return endLoad(
        state
          .set('dadosFinanceiros', fromJS([]))
          .set('servidor', fromJS({}))
          .set('anotacoes', fromJS([]))
          .set('horarioFlexivel', fromJS([]))
      );
    }

    case types.SERVIDOR_OXY_VENCIMENTOS_DESCONTOS_SUCCESS: {
      return endLoad(state.set('vencimentosDescontos', fromJS(action.payload)));
    }

    case types.NATUREZAS_OXY_LIST_SUCCESS: {
      return endLoad(state.set('naturezas', fromJS(action.payload)));
    }

    case types.SERVIDOR_OXY_REGISTROS_PONTOS_SUCCESS: {
      return endLoad(state.set('registrosPontos', fromJS(action.payload)));
    }

    case types.SITUACOES_OXY_LIST_SUCCESS: {
      return endLoad(state.set('situacoes', fromJS(action.payload)));
    }

    case types.SERVIDORES_OXY_RESUMO_SUCCESS: {
      return endLoad(state.set('resumoNatureza', fromJS(action.payload)));
    }

    case types.ESTAGIARIOS_OXY_LIST_SUCCESS: {
      return endLoad(state.set('estagiarios', fromJS(action.payload)));
    }

    case types.ESTAGIARIO_OXY_DETALHE_SUCCESS: {
      return endLoad(
        state
          .set('estagiario', fromJS(action.payload))
          .set('dadosFinanceiros', fromJS(action.payload.dadosFinanceiros))
      );
    }

    case types.INATIVOS_LIST_SUCCESS: {
      return endLoad(state.set('inativos', fromJS(action.payload)));
    }

    case types.INATIVO_DETALHE_SUCCESS: {
      return endLoad(
        state
          .set('inativo', fromJS(action.payload))
          .set('dadosFinanceiros', fromJS(action.payload.dadosFinanceiros))
      );
    }

    case types.PENSIONISTAS_LIST_SUCCESS: {
      return endLoad(state.set('pensionistas', fromJS(action.payload)));
    }

    case types.PENSIONISTA_DETALHE_SUCCESS: {
      return endLoad(
        state
          .set('pensionista', fromJS(action.payload))
          .set('dadosFinanceiros', fromJS(action.payload.dadosFinanceiros))
      );
    }

    case types.AUTONOMOS_LIST_SUCCESS: {
      return endLoad(state.set('autonomos', fromJS(action.payload)));
    }

    case types.AUTONOMO_DETALHE_SUCCESS: {
      return endLoad(
        state
          .set('autonomo', fromJS(action.payload))
          .set('dadosFinanceiros', fromJS(action.payload.dadosFinanceiros))
      );
    }
  }

  return state;
}

export default servidorOxyReducer;
