import { Map } from 'immutable';
import DatePicker from 'portaltransparencia-common/components/DatePicker.react.js';
import Input from 'portaltransparencia-common/components/EloInput.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import SearchPagination from 'portaltransparencia-common/components/SearchPagination.react.js';
import {
  onChange,
  resetCrud
} from 'portaltransparencia-common/crud/Actions.js';
import {
  addFilterUrl,
  addPagination
} from 'portaltransparencia-common/libs/AddFilter.js';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import {
  PARAM_EXIBE_GASTOS_TOTAIS,
  PARAM_EXIBE_VALOR_MEDIO_UNITARIO,
  PARAM_MOSTRAR_CARGO,
  PARAM_MOSTRAR_SECRETARIA,
  PARAM_NAO_VALIDA_DESPESA_DIARIA
} from 'portaltransparencia-common/parametros/ParametrosPortalTransparencia';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, ListGroup, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';
import AutoComplete from 'portaltransparencia-common/components/AutoComplete.react.js';

import PageHeader from '../components/PageHeader.react.js';
import NotasExplicativas from '../notaexplicativa/Form.react';
import ParametroService from '../parametros/ParametroService.js';
import * as actions from './Actions.js';
import DiariaListagem from './DiariaListagem.react.js';
import ufs from 'portaltransparencia-common/ufs.json';

export class FormDiaria extends Component {
  static propTypes = {
    page: PropTypes.object.isRequired,
    findDiarias: PropTypes.func.isRequired,
    filtros: PropTypes.object,
    onChange: PropTypes.func,
    totalDiarias: PropTypes.object.isRequired,
    findTotalDiarias: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    resetCrud: PropTypes.func.isRequired,
    paramMostrarSecretaria: PropTypes.string.isRequired,
    paramMostrarCargo: PropTypes.string.isRequired,
    getObjetivosDiarias: PropTypes.func.isRequired,
    getSecretariasDiarias: PropTypes.func.isRequired,
    objetivosDiarias: PropTypes.object.isRequired,
    secretariasDiarias: PropTypes.object.isRequired,
    paramMostrarValorMedioUnitario: PropTypes.string.isRequired,
    findDiariasWithoutQuantidade: PropTypes.func.isRequired,
    diariasWithoutQuantidade: PropTypes.object.isRequired,
    paramMostrarGastosTotais: PropTypes.string.isRequired,
    selectCodigoSecretaria: PropTypes.func.isRequired,
    codigoSecretariaSelecionada: PropTypes.string.isRequired,
    paramNaoValidaDespesaDiaria: PropTypes.string.isRequired,
    isIntegradoOxy: PropTypes.bool
  };

  componentDidMount() {
    const { getObjetivosDiarias } = this.props;

    this.searchWithPage(this.props.page.set('number', 0));
    getObjetivosDiarias();
  }

  onConsultaDiarias = event => {
    const { page } = this.props;
    const initialPage = page.set('number', 0);

    this.searchWithPage(initialPage);

    event && event.preventDefault();
    this.refs.panelFiltroDiarias && this.refs.panelFiltroDiarias.collapse();
  };

  onChangeFiltro = event => {
    const { onChange } = this.props;
    onChange('filtroDiarias', event);
  };

  onChangeSecretaria = event => {
    const { selectCodigoSecretaria } = this.props;
    this.onChangeFiltro(event);
    selectCodigoSecretaria(event?.value?.codigo || undefined);
  };

  onResetFiltro = () => {
    const {
      resetCrud,
      findDiarias,
      findTotalDiarias,
      findDiariasWithoutQuantidade
    } = this.props;

    resetCrud('filtroDiarias');

    findDiarias('');
    findTotalDiarias('');
    findDiariasWithoutQuantidade('');
  };

  montarFiltro = function (page, adicionaPaginacao = true) {
    const { entidade, exercicio } = StorageService.getItem('userInfo');
    const { filtros, codigoSecretariaSelecionada } = this.props;

    let filter = '';
    filter = addFilterUrl(filter, `entidade=${entidade.id}`);
    filter = addFilterUrl(filter, `exercicio=${exercicio.id}`);

    if (filtros.get('nome')) {
      filter = addFilterUrl(filter, `nome=${filtros.get('nome')}`);
    }

    if (
      filtros.get('dataInicial') &&
      filtros.get('dataInicial').length === 10
    ) {
      filter = addFilterUrl(
        filter,
        `dataInicial=${filtros.get('dataInicial')}`
      );
    }

    if (filtros.get('dataFinal') && filtros.get('dataFinal').length === 10) {
      filter = addFilterUrl(filter, `dataFinal=${filtros.get('dataFinal')}`);
    }

    if (filtros.get('destino')) {
      filter = addFilterUrl(filter, `destino=${filtros.get('destino')}`);
    }

    if (filtros.get('idTipoObjetivoDiaria')) {
      filter = addFilterUrl(
        filter,
        `idTipoObjetivoDiaria=${filtros.get('idTipoObjetivoDiaria')}`
      );
    }

    if (filtros.get('cargo')) {
      filter = addFilterUrl(filter, `cargo=${filtros.get('cargo')}`);
    }

    if (filtros.get('codigoSecretaria') && codigoSecretariaSelecionada) {
      filter = addFilterUrl(
        filter,
        `codigoSecretaria=${codigoSecretariaSelecionada}`
      );
    }

    if (filtros.get('uf')) {
      filter = addFilterUrl(filter, `uf=${filtros.get('uf')}`);
    }

    if (adicionaPaginacao) {
      return addPagination(page, filter);
    } else {
      return filter;
    }
  };

  searchWithPage = page => {
    const {
      findDiarias,
      findTotalDiarias,
      findDiariasWithoutQuantidade
    } = this.props;

    const filtro = this.montarFiltro(page);
    const filtroWhitoutPagination = this.montarFiltro(page, false);
    findDiarias(filtro);
    findTotalDiarias(filtro);
    findDiariasWithoutQuantidade(filtroWhitoutPagination);
  };

  searchDiariaWithQtdeNula = () => {
    const { diariasWithoutQuantidade } = this.props;

    if (diariasWithoutQuantidade && diariasWithoutQuantidade.length > 0) {
      return (
        <div className="panel">
          <div className="info warning icon">
            <div className="icon">
              <em className="fa  fa-exclamation-triangle"></em>
            </div>

            <article>
              <h4>Existe(m) diária(s) que não possui(em) quantidade.</h4>
              <p>
                {' '}
                {diariasWithoutQuantidade.map(itens => {
                  return (
                    <div key={diariasWithoutQuantidade.key}>
                      {' '}
                      Empenho: {itens.get('displayEmpenho')}
                    </div>
                  );
                })}{' '}
              </p>
            </article>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  renderObjetivoDiaria = data => {
    return (
      <option key={data.get('id')} value={data.get('id')}>
        {data.get('descricao')}
      </option>
    );
  };

  renderUf = uf => (
    <option key={uf.sigla} value={uf.sigla}>
      {uf.nome}
    </option>
  );

  loadSecretarias = param => {
    const { entidade, exercicio } = StorageService.getItem('userInfo');
    return this.props.getSecretariasDiarias(
      entidade.id,
      exercicio.id,
      param.value
    );
  };

  render() {
    const {
      page,
      filtros,
      totalDiarias,
      paramMostrarSecretaria,
      paramMostrarCargo,
      objetivosDiarias,
      secretariasDiarias,
      paramMostrarValorMedioUnitario,
      paramMostrarGastosTotais,
      paramNaoValidaDespesaDiaria,
      isIntegradoOxy
    } = this.props;

    const filter = this.montarFiltro(page);

    if (!page.has('content')) {
      return null;
    }

    return (
      <section>
        <PageHeader location={this.props.location} />

        <NotasExplicativas pathname={this.props.location.pathname} />

        <Panel
          isForm
          mobileClose
          title="Consultar em Diárias"
          ref="panelFiltroDiarias"
        >
          <form>
            <Row>
              <Col xs={12} sm={12} md={8}>
                <Input
                  name="nome"
                  ref="nome"
                  placeholder="Nome"
                  label="Nome"
                  type="text"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('nome')}
                />
              </Col>
              <Col xs={12} sm={6} md={2}>
                <DatePicker
                  name="dataInicial"
                  ref="dataInicial"
                  label="Data Inicial"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('dataInicial')}
                />
              </Col>
              <Col xs={12} sm={6} md={2}>
                <DatePicker
                  name="dataFinal"
                  ref="dataFinal"
                  label="Data Final"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('dataFinal')}
                />
              </Col>
              <Col xs={12} sm={8} md={8}>
                <Input
                  ref="destino"
                  name="destino"
                  placeholder="Destino"
                  label="Destino"
                  type="text"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('destino')}
                />
              </Col>
              <Col xs={12} sm={5} md={4}>
                <Input
                  id="idTipoObjetivoDiaria"
                  name="idTipoObjetivoDiaria"
                  ref="idTipoObjetivoDiaria"
                  label="Tipo Objetivo Diária"
                  type="select"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('idTipoObjetivoDiaria')}
                >
                  <option value="">Selecione</option>
                  {objetivosDiarias.map(this.renderObjetivoDiaria)}
                </Input>
              </Col>
              {paramMostrarCargo === 'S' && (
                <Col xs={12} sm={8} md={8}>
                  <Input
                    ref="cargo"
                    name="cargo"
                    placeholder="Cargo"
                    label="Cargo"
                    type="text"
                    onChange={this.onChangeFiltro}
                    value={filtros.get('cargo')}
                  />
                </Col>
              )}
              {paramMostrarSecretaria === 'S' && (
                <Col xs={12} sm={5} md={4}>
                  <AutoComplete
                    label="Secretaria"
                    name="codigoSecretaria"
                    refs="codigoSecretaria"
                    onLoad={this.loadSecretarias}
                    onChange={this.onChangeSecretaria}
                    value={filtros.get('codigoSecretaria')}
                    placeholder="Selecione a Secretaria"
                    labelKey="descricao"
                    valueKey="codigo"
                    minimumInput={2}
                  />
                </Col>
              )}
              {!isIntegradoOxy && (
                <Col xs={12} sm={5} md={4}>
                  <Input
                    id="uf"
                    name="uf"
                    ref="uf"
                    label="Estado"
                    type="select"
                    onChange={this.onChangeFiltro}
                    value={filtros.get('uf')}
                  >
                    <option value="">Selecione</option>
                    <option key="EX" value="EX">
                      Exterior
                    </option>
                    {ufs.map(this.renderUf)}
                  </Input>
                </Col>
              )}
              <Col xs={12} sm={4} md={3}>
                <div className="form-group">
                  <label className="label"></label>
                  <div className="btn-group">
                    <button
                      className="btn module-color icon-right"
                      type="button"
                      ref="pesquisar"
                      onClick={this.onConsultaDiarias}
                    >
                      Pesquisar
                      <em className="fa fa-search"></em>
                    </button>
                    <button
                      className="btn neutral icon-right inline no-text"
                      type="button"
                      onClick={this.onResetFiltro}
                    >
                      Limpar
                      <em className="fa fa-times"></em>
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </form>
        </Panel>

        {paramNaoValidaDespesaDiaria === 'N' &&
          this.searchDiariaWithQtdeNula(page)}

        <DiariaListagem
          totalDiarias={totalDiarias}
          filter={filter}
          page={page}
          history={this.props.history}
          searchWithPage={this.searchWithPage}
          mostrarSecretaria={paramMostrarSecretaria === 'S'}
          mostrarCargo={paramMostrarCargo === 'S'}
          objetivosDiarias={objetivosDiarias}
          secretariasDiarias={secretariasDiarias}
          mostrarValorMedioUnitario={
            paramMostrarValorMedioUnitario === 'S' ||
            paramMostrarValorMedioUnitario === undefined
          }
          mostrarGastosTotais={paramMostrarGastosTotais === 'S'}
        />

        <SearchPagination page={page} searchWithPage={this.searchWithPage} />
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    filtros: state.crud.getIn(['filtroDiarias', 'currentRecord'], Map({})),
    page: state.diaria.get('diarias'),
    totalDiarias: state.diaria.get('totalDiarias'),
    paramMostrarSecretaria: ParametroService.getValorParametro(
      state,
      PARAM_MOSTRAR_SECRETARIA
    ),
    paramMostrarCargo: ParametroService.getValorParametro(
      state,
      PARAM_MOSTRAR_CARGO
    ),
    objetivosDiarias: state.diaria.get('objetivosDiarias'),
    codigoSecretariaSelecionada: state.diaria.get(
      'codigoSecretariaSelecionada'
    ),
    secretariasDiarias: state.diaria.get('secretariasDiarias'),
    paramMostrarValorMedioUnitario: ParametroService.getValorParametro(
      state,
      PARAM_EXIBE_VALOR_MEDIO_UNITARIO
    ),
    diariasWithoutQuantidade: state.diaria.get('diariasWithoutQuantidade'),
    paramMostrarGastosTotais: ParametroService.getValorParametro(
      state,
      PARAM_EXIBE_GASTOS_TOTAIS
    ),
    paramNaoValidaDespesaDiaria: ParametroService.getValorParametro(
      state,
      PARAM_NAO_VALIDA_DESPESA_DIARIA
    ),
    isIntegradoOxy: state.entidade.getIn([
      'configuracoesGerais',
      'isIntegradoOxy'
    ])
  };
}

const allActions = {
  ...actions,
  onChange,
  resetCrud
};

export default connect(mapStateToProps, allActions)(FormDiaria);
