export const FIND_RENUNCIA_RECEITAS = 'FIND_RENUNCIA_RECEITAS';
export const FIND_RENUNCIA_RECEITAS_START = 'FIND_RENUNCIA_RECEITAS_START';
export const FIND_RENUNCIA_RECEITAS_SUCCESS = 'FIND_RENUNCIA_RECEITAS_SUCCESS';
export const FIND_RENUNCIA_RECEITAS_ERROR = 'FIND_RENUNCIA_RECEITAS_ERROR';
export const FIND_CLASSIFICACOES = 'FIND_CLASSIFICACOES';
export const FIND_CLASSIFICACOES_START = 'FIND_CLASSIFICACOES_START';
export const FIND_CLASSIFICACOES_SUCCESS = 'FIND_CLASSIFICACOES_SUCCESS';
export const FIND_CLASSIFICACOES_ERROR = 'FIND_CLASSIFICACOES_ERROR';
export const FIND_RESUMO = 'FIND_RESUMO';
export const FIND_RESUMO_START = 'FIND_RESUMO_START';
export const FIND_RESUMO_SUCCESS = 'FIND_RESUMO_SUCCESS';
export const FIND_RESUMO_ERROR = 'FIND_RESUMO_ERROR';
export const FIND_TOTAL_RESUMO = 'FIND_TOTAL_RESUMO';
export const FIND_TOTAL_RESUMO_START = 'FIND_TOTAL_RESUMO_START';
export const FIND_TOTAL_RESUMO_SUCCESS = 'FIND_TOTAL_RESUMO_SUCCESS';
export const FIND_TOTAL_RESUMO_ERROR = 'FIND_TOTAL_RESUMO_ERROR';

export const ANISTIA = 'ANISTIA';
export const REMISSAO = 'REMISSAO';
export const SUBSIDIO = 'SUBSIDIO';
export const CREDITO_PRESUMIDO = 'CREDITO_PRESUMIDO';
export const ISENCAO = 'ISENCAO';
export const CADUCIDADE = 'CADUCIDADE';

export const RenunciaReceitas = [
  { label: 'Anistia', tipo: ANISTIA },
  { label: 'Remissão', tipo: REMISSAO },
  { label: 'Subsídio', tipo: SUBSIDIO },
  { label: 'Crédito Presumido', tipo: CREDITO_PRESUMIDO },
  { label: 'Isenção', tipo: ISENCAO },
  { label: 'Caducidade', tipo: CADUCIDADE }
];

export const getLabelByTipo = tipo =>
  RenunciaReceitas.find(rr => rr.tipo === tipo)?.label;

export const NO_EXERCICIO = 'NO_EXERCICIO';
export const DIVIDA_ATIVA = 'DIVIDA_ATIVA';

export const Situacoes = [
  { label: 'No Exercício', tipo: NO_EXERCICIO },
  { label: 'Em Dívida Ativa e/ou Juízo', tipo: DIVIDA_ATIVA }
];
