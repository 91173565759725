import DisplayData from 'portaltransparencia-common/components/displaydata/DisplayData.react.js';
import DisplayDataItem from 'portaltransparencia-common/components/displaydata/DisplayDataItem.react.js';
import DisplayDataRow from 'portaltransparencia-common/components/displaydata/DisplayDataRow.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export class RecursoDetalheInformacao extends Component {
  static propTypes = {
    recurso: PropTypes.object.isRequired
  };

  render() {
    const { recurso } = this.props;

    return (
      <Panel title={'Recurso'}>
        <DisplayData border>
          <DisplayDataRow>
            <DisplayDataItem
              sm={3}
              title="Data"
              value={DateUtils.transform(recurso.get('data'))}
              id="data"
            />
            <DisplayDataItem
              sm={3}
              title="Tipo"
              value={recurso.get('tipo')}
              id="tipo"
            />
            <DisplayDataItem
              sm={3}
              title="Número Protocolo"
              value={recurso.get('numeroProtocolo')}
              id="numeroProtocolo"
            />
            <DisplayDataItem
              sm={3}
              title="Data Protocolo"
              value={DateUtils.transform(recurso.get('dataProtocolo'))}
              id="dataProtocolo"
            />
          </DisplayDataRow>
          <DisplayDataRow>
            <DisplayDataItem
              sm={9}
              title="Análise"
              value={recurso.get('descricao')}
              id="descricao"
            />
            <DisplayDataItem
              sm={3}
              title="Respeita Prazo Legal"
              value={
                <i
                  className={`fas fa-${
                    recurso.get('respeitadoPrazoLegal') === 'S'
                      ? 'check green-60'
                      : 'times red-60'
                  }`}
                />
              }
              id="respeitadoPrazoLegal"
            />
          </DisplayDataRow>
        </DisplayData>
      </Panel>
    );
  }
}

export default RecursoDetalheInformacao;
