import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import * as actions from './Actions.js';

export class EmpenhoDetalheLiquidacoesDocumentos extends Component {
  static propTypes = {
    liquidacoesDocumentos: PropTypes.object.isRequired
  };

  verificaTipoDocumento = (data, linkAcessoDanfe) => {
    return (
      <div>
        {data.get('tipoDocumento') && data.get('tipoDocumento') === 42 ? (
          <a
            id="linkDanfe"
            href={linkAcessoDanfe}
            target="_blank"
            title="Consultar DANFE"
          >
            {data.get('chaveAcesso')}
          </a>
        ) : (
          <span>{data.get('chaveAcesso')}</span>
        )}
      </div>
    );
  };

  renderItens = data => {
    const { exibeEmpenho } = this.props;
    let key = data.get('noLiquidacao') + '_' + data.get('sequencia');
    const acessoDanfe =
      'https://www.nfe.fazenda.gov.br/portal/consultaRecaptcha.aspx?tipoConsulta=completa&tipoConteudo=7PhJ+gAVw2g=&nfe=';
    let linkAcessoDanfe = acessoDanfe + data.get('chaveAcesso');

    if (data.get('chaveAcesso') && data.get('chaveAcesso').startsWith('http')) {
      linkAcessoDanfe = data.get('chaveAcesso');
    }

    let noLiquidacao =
      data.get('noLiquidacao') + '/' + data.get('exercicioLiquidacao');

    let noEmpenho = data.get('empenho') + '/' + data.get('exercicio');

    return (
      <tr
        key={key}
        data={data}
        className={data.get('valorEstornado') > 0 ? 'error' : ''}
      >
        {exibeEmpenho && <td id="noEmpenho">{noEmpenho}</td>}
        <td>{noLiquidacao}</td>
        <td>{data.get('descricao')}</td>
        <td>{data.get('noDocumento')}</td>
        <td>{DateUtils.formatDateTimeShort(data.get('data'))}</td>
        <td className="right">
          <NumberFormatter value={data.get('valor')} />
        </td>
        <td>{this.verificaTipoDocumento(data, linkAcessoDanfe)}</td>
      </tr>
    );
  };

  render() {
    const { liquidacoesDocumentos, exibeEmpenho } = this.props;
    let arrayEstorno = [];
    let vlrTotalEstornoDocumentos = 0;
    let vlrTotalDocumentos = 0;

    liquidacoesDocumentos.map(documento => {
      let noliquidacao =
        documento.get('noLiquidacao') +
        '/' +
        documento.get('exercicioLiquidacao');
      vlrTotalDocumentos = vlrTotalDocumentos + documento.get('valor');
      if (!arrayEstorno.includes(noliquidacao)) {
        arrayEstorno.push(noliquidacao);
        vlrTotalEstornoDocumentos =
          vlrTotalEstornoDocumentos + documento.get('valorEstornado');
      }
    });

    return (
      <div className="panel-table-outer">
        <table className="panel-table striped fancy">
          <TableHeader empty={liquidacoesDocumentos.isEmpty()}>
            <tr>
              {exibeEmpenho && <th>Empenho</th>}
              <th>Liquidação</th>
              <th>Descrição</th>
              <th>Nº Documento</th>
              <th>Data</th>
              <th className="right">Valor</th>
              <th>Chave de Acesso NF-e</th>
            </tr>
          </TableHeader>
          <tbody>
            {liquidacoesDocumentos
              .sortBy(liquidacoesDocumento =>
                liquidacoesDocumento.get('empenho')
              )
              .map(this.renderItens)}
          </tbody>

          {!liquidacoesDocumentos.isEmpty() && (
            <tfoot>
              <tr>
                {exibeEmpenho && <td></td>}
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="right">
                  <i>Total:</i>{' '}
                  <NumberFormatter
                    id="vlrTotal"
                    value={vlrTotalDocumentos - vlrTotalEstornoDocumentos}
                  />
                </td>
                <td></td>
              </tr>
            </tfoot>
          )}
        </table>
        {vlrTotalEstornoDocumentos > 0 && (
          <div
            className="col-md-12 red-60 typography-caption"
            style={{ marginTop: '3px' }}
          >
            <i className="fa fa-circle red-60" aria-hidden="true"></i>
            &nbsp; Estornado
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    liquidacoesDocumentos: state.empenhos.get('liquidacoesDocumentos')
  };
}

const allActions = {
  ...actions
};

export default connect(
  mapStateToProps,
  allActions
)(EmpenhoDetalheLiquidacoesDocumentos);
