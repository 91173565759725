import Modal from 'portaltransparencia-common/components/Modal.react.js';
import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export class DividaAtivaContribuinteListagem extends Component {
  static propTypes = {
    dividas: PropTypes.object.isRequired,
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
  };

  renderDividasAtivas = data => {
    const key =
      data.get('cadastroGeral') +
      data.get('condicao') +
      data.get('descricaoDivida') +
      data.get('valor');

    return (
      <tr ref="row" key={key}>
        <td>{data.get('descricaoTipoCadastro')} </td>
        <td>{data.get('cadastroGeral')}</td>
        <td>{data.get('setor')}</td>
        <td>{data.get('quadra')}</td>
        <td>{data.get('lote')}</td>
        <td>{data.get('descricaoDivida')}</td>
        <td>{data.get('guiaTributaria')}</td>
        <td>{data.get('situacaoLegal')}</td>
        <td>{data.get('condicao')}</td>
        <td className="right">
          <NumberFormatter value={data.get('valor')} />
        </td>
      </tr>
    );
  };

  render() {
    const { dividas, show, onClose } = this.props;

    if (show && !dividas.isEmpty()) {
      return (
        <Modal onClose={onClose}>
          <div className="container">
            <Panel title="Dívida Ativa do Contribuinte" expansible={false}>
              <div className="panel-table-outer">
                <table className="panel-table striped fancy">
                  <TableHeader empty={dividas.isEmpty()}>
                    <tr>
                      <th>Tipo Cadastro</th>
                      <th>Código</th>
                      <th>Setor</th>
                      <th>Quadra</th>
                      <th>Lote</th>
                      <th>Dívida</th>
                      <th>Tipo/Natureza</th>
                      <th>Situação</th>
                      <th>Tipo de Cobrança</th>
                      <th className="right">Valor</th>
                    </tr>
                  </TableHeader>
                  <tbody>{dividas.map(this.renderDividasAtivas)}</tbody>
                </table>
              </div>
            </Panel>
          </div>
        </Modal>
      );
    }
    return null;
  }
}

export default DividaAtivaContribuinteListagem;
