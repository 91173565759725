import Input from 'portaltransparencia-common/components/EloInput.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import TextLink from 'portaltransparencia-common/components/TextLink.react';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import PageHeader from '../components/PageHeader.react.js';
import NotasExplicativas from '../notaexplicativa/Form.react';
import * as actions from './Actions.js';

export class CartaServicos extends Component {
  static propTypes = {
    cartaServicos: PropTypes.object.isRequired,
    findCartaServicos: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired
  };

  componentDidMount() {
    this.props.findCartaServicos('');
  }

  findCartaServicos = event => {
    if (this.refs.filtro) {
      this.props.findCartaServicos(this.refs.filtro.getValue());
    }
    event && event.preventDefault();
    this.refs.panelFiltroCartaServicos &&
      this.refs.panelFiltroCartaServicos.collapse();
  };

  onReset = () => {
    const { findCartaServicos } = this.props;
    findCartaServicos('');
  };

  handleKeyDown = event => {
    const teclaEnter = 13;
    if (event.keyCode === teclaEnter) {
      this.findCartaServicos();
    }
  };

  openURL = data => {
    window.open(data.get('link'), '_blank');
  };

  renderCartaServicos = data => {
    return (
      <Panel
        insidePanel
        key={data.getIn(['id', 'id'])}
        title={data.get('titulo')}
        collapsed
      >
        <Row>
          <Col lg={12}>
            <TextLink text={data.get('descricao')} />
          </Col>
        </Row>

        {data.get('link') && (
          <Col xs={12}>
            <button
              type="button"
              style={{ marginLeft: '50%' }}
              className="btn module-color icon-right"
              onClick={e => {
                e.preventDefault();
                window.open(data.get('link'), '_blank');
              }}
            >
              {' '}
              Abrir
            </button>
          </Col>
        )}
      </Panel>
    );
  };

  render() {
    const { cartaServicos } = this.props;

    if (!cartaServicos) {
      return null;
    }

    return (
      <section>
        <form>
          <PageHeader location={this.props.location} />

          <NotasExplicativas pathname={this.props.location.pathname} />

          <Panel
            title="Consultar Carta de Serviços aos Usuários"
            mobileClose
            isForm
            ref="panelFiltroCartaServicos"
          >
            <Row>
              <Col xs={12} sm={8} md={9}>
                <Input
                  id="filtro"
                  name="filtro"
                  ref="filtro"
                  label="Consulte aqui as cartas de serviços aos usuários que deseja encontrar"
                  type="text"
                />
              </Col>
              <Col xs={12} sm={4} md={3}>
                <div className="form-group">
                  <label className="label"></label>
                  <div className="btn-group">
                    <button
                      id="btnFiltrar"
                      className="btn module-color icon-right"
                      ref="filtrar"
                      onClick={this.findCartaServicos}
                    >
                      Pesquisar
                      <em className="fa fa-search"></em>
                    </button>
                    <button
                      className="btn neutral icon-right inline no-text"
                      type="reset"
                      title="Limpar formulário"
                      onClick={this.onReset}
                    >
                      Limpar
                      <em className="fa fa-times"></em>
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </Panel>
        </form>

        {!cartaServicos.isEmpty() && (
          <Panel expansible={false}>
            {cartaServicos.map(this.renderCartaServicos)}
          </Panel>
        )}
        {cartaServicos.isEmpty() && (
          <div className="info">
            Sem cartas de serviços aos usuários frequentes.
          </div>
        )}
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    cartaServicos: state.cartaServicos.get('cartaServicos')
  };
}

export default connect(mapStateToProps, actions)(CartaServicos);
