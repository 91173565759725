import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';

import * as types from './Types.js';

const initialState = fromJS({
  fornecimentoBens: [],
  locacoes: [],
  prestacaoServicos: [],
  obras: [],
  pagamentos: [],
  totalFornecimentoBens: {},
  totalLocacoes: {},
  totalPrestacaoServicos: {},
  totalObras: {},
  totalPagamentos: {},
  filtros: []
});

function OrdemCronologicaPagamentosReducer(state = initialState, action) {
  switch (action.type) {
    case types.FORNECIMENTO_BENS_START:
    case types.FORNECIMENTO_BENS_TOTAL_START: {
      return startLoad(state);
    }
    case types.FORNECIMENTO_BENS_ERROR:
    case types.FORNECIMENTO_BENS_TOTAL_ERROR: {
      return endLoad(state);
    }
    case types.FORNECIMENTO_BENS_SUCCESS: {
      return endLoad(state.set('fornecimentoBens', fromJS(action.payload)));
    }
    case types.FORNECIMENTO_BENS_TOTAL_SUCCESS: {
      return endLoad(
        state.set('totalFornecimentoBens', fromJS(action.payload))
      );
    }

    case types.LOCACOES_START:
    case types.LOCACOES_TOTAL_START: {
      return startLoad(state);
    }
    case types.LOCACOES_ERROR:
    case types.LOCACOES_TOTAL_ERROR: {
      return endLoad(state);
    }
    case types.LOCACOES_SUCCESS: {
      return endLoad(state.set('locacoes', fromJS(action.payload)));
    }
    case types.LOCACOES_TOTAL_SUCCESS: {
      return endLoad(state.set('totalLocacoes', fromJS(action.payload)));
    }

    case types.PRESTACAO_SERVICOS_START:
    case types.PRESTACAO_SERVICOS_TOTAL_START: {
      return startLoad(state);
    }
    case types.PRESTACAO_SERVICOS_ERROR:
    case types.PRESTACAO_SERVICOS_TOTAL_ERROR: {
      return endLoad(state);
    }
    case types.PRESTACAO_SERVICOS_SUCCESS: {
      return endLoad(state.set('prestacaoServicos', fromJS(action.payload)));
    }
    case types.PRESTACAO_SERVICOS_TOTAL_SUCCESS: {
      return endLoad(
        state.set('totalPrestacaoServicos', fromJS(action.payload))
      );
    }

    case types.OBRAS_START:
    case types.OBRAS_TOTAL_START: {
      return startLoad(state);
    }
    case types.OBRAS_ERROR:
    case types.OBRAS_TOTAL_ERROR: {
      return endLoad(state);
    }
    case types.OBRAS_SUCCESS: {
      return endLoad(state.set('obras', fromJS(action.payload)));
    }
    case types.OBRAS_TOTAL_SUCCESS: {
      return endLoad(state.set('totalObras', fromJS(action.payload)));
    }

    case types.PAGAMENTOS_START:
    case types.PAGAMENTOS_TOTAL_START: {
      return startLoad(state);
    }
    case types.PAGAMENTOS_ERROR:
    case types.PAGAMENTOS_TOTAL_ERROR: {
      return endLoad(state);
    }
    case types.PAGAMENTOS_SUCCESS: {
      return endLoad(state.set('pagamentos', fromJS(action.payload)));
    }
    case types.PAGAMENTOS_TOTAL_SUCCESS: {
      return endLoad(state.set('totalPagamentos', fromJS(action.payload)));
    }
  }

  return state;
}

export default OrdemCronologicaPagamentosReducer;
