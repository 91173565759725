import React from 'react';
import Component from 'react-pure-render/component';
import PropTypes from 'prop-types';

import Panel from 'portaltransparencia-common/components/Panel.react.js';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import TableHeaderItem from 'portaltransparencia-common/components/TableHeaderItem.react';
import SearchPagination from 'portaltransparencia-common/components/SearchPagination.react.js';

import TableActions from 'portaltransparencia-common/components/TableActions.react';

export class CargosTableOxy extends Component {
  static propTypes = {
    filter: PropTypes.string.isRequired,
    cargos: PropTypes.object.isRequired,
    page: PropTypes.object.isRequired,
    searchWithPage: PropTypes.func.isRequired
  };

  generateKey = cargo => {
    return `${cargo.get('id')}_
    ${cargo.get('codigo')}_
    ${cargo.get('matrizSalarialId')}`;
  };

  onClick = data => {
    const { filter } = this.props;
    const { entidade, exercicio } = StorageService.getItem('userInfo');
    const cargo = data.get('id');
    const matriz = data.get('matrizSalarialId');
    let vigencia = '';

    if (filter) {
      let filtro = filter.split('&');
      vigencia = filtro.find(e => e.match('matrizSalarialVigencia'));
    }

    if (vigencia) {
      this.props.history.push(
        `/cargos/matriz-vigente?entidade=${entidade.id}&exercicio=${exercicio.id}&cargo=${cargo}&matriz=${matriz}&${vigencia}`
      );
    } else {
      this.props.history.push(
        `/cargos/matriz-vigente?entidade=${entidade.id}&exercicio=${exercicio.id}&cargo=${cargo}&matriz=${matriz}`
      );
    }
  };

  renderCargos = cargo => {
    return (
      <TableActions
        id="btnAction"
        ref="row"
        key={this.generateKey(cargo)}
        data={cargo}
        onMore={this.onClick}
      >
        <td>{cargo.get('descricao')}</td>
        <td>{cargo.get('cargoTipo').get('descricao')}</td>
        <td>{cargo.get('matrizSalarialNome')}</td>
        <td>{cargo.get('quantidadeVagas')}</td>
        <td>{cargo.get('quantidadeVagasOcupadas')}</td>
        <td>
          {cargo.get('quantidadeVagas') - cargo.get('quantidadeVagasOcupadas')}
        </td>
      </TableActions>
    );
  };

  render() {
    const { page, cargos, searchWithPage } = this.props;

    return (
      <Panel isTable expansible={false} tableResponsive>
        <div className="panel-table-outer">
          <table className="panel-table striped fancy">
            <TableHeader
              empty={cargos.get('content').isEmpty()}
              text="Sem registros"
            >
              <tr>
                <TableHeaderItem
                  field="cargo"
                  label="Cargo"
                  page={page}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="tipoCargo"
                  label="Tipo do Cargo"
                  page={page}
                  searchWithPage={searchWithPage}
                />
                <th>Descrição da Matriz</th>
                <th>Vagas Criadas</th>
                <th>Vagas Ocupadas</th>
                <th>Vagas Disponíveis</th>
                <th></th>
              </tr>
            </TableHeader>
            <tbody>{cargos.get('content').map(this.renderCargos)}</tbody>
          </table>
        </div>
        <SearchPagination page={cargos} searchWithPage={searchWithPage} />
      </Panel>
    );
  }
}

export default CargosTableOxy;
