import * as types from './Types';

export function findDocumentos() {
  return ({ fetch }) => {
    return {
      type: types.PESQUISA_DOCUMENTOS,
      payload: {
        promise: fetch('/api/documentos-saude')
      }
    };
  };
}
