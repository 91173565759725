import ExportButtonGroup from 'portaltransparencia-common/components/ExportButtonGroup.react.js';
import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import SearchPagination from 'portaltransparencia-common/components/SearchPagination.react.js';
import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import TableHeaderItem from 'portaltransparencia-common/components/TableHeaderItem.react';
import configuration from 'portaltransparencia-common/config/Config.js';
import { addPagination } from 'portaltransparencia-common/libs/AddFilter';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import storageService from 'portaltransparencia-common/libs/StorageService.js';
import { compactText } from 'portaltransparencia-common/libs/TextCompact.js';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import PageHeader from '../components/PageHeader.react.js';
import NotasExplicativas from '../notaexplicativa/Form.react';
import UltimaAtualizacaoByTable from '../ultimaatualizacao/UltimaAtualizacaoByTable.react.js';
import * as actions from './Actions.js';
import EmpenhosFiltro from './FormFiltro.react.js';
import LinkToEmpenho from './LinkToEmpenho.react';
import { PARAM_DESPESAS_ENTIDADES_DIFERENTES } from 'portaltransparencia-common/parametros/ParametrosPortalTransparencia';
import ParametroService from '../parametros/ParametroService.js';
import { addErrorNotification } from 'portaltransparencia-common/ui/Actions.js';

export class Empenhos extends Component {
  static propTypes = {
    params: PropTypes.object,
    page: PropTypes.object.isRequired,
    totais: PropTypes.object,
    resetEmpenho: PropTypes.func.isRequired,
    findEmpenhos: PropTypes.func.isRequired,
    findEmpenhosTotais: PropTypes.func.isRequired,
    findOrgao: PropTypes.func.isRequired,
    location: PropTypes.object,
    urlFiltro: PropTypes.string,
    filtroParam: PropTypes.string,
    disableHeader: PropTypes.bool,
    saveFiltroListagem: PropTypes.func.isRequired,
    paramDespesasEntidadesDif: PropTypes.string,
    addErrorNotification: PropTypes.func.isRequired
  };

  static defaultProps = {
    disableHeader: false,
    match: {
      params: {}
    }
  };

  componentDidMount() {
    const { page } = this.props;
    this.onConsultaEmpenhoPage(page);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.getFiltrosEmpenho(this.props) !== this.getFiltrosEmpenho(nextProps)
    ) {
      this.carregaFiltro(nextProps);
    }
  }

  componentWillUnmount() {
    const { resetEmpenho } = this.props;

    resetEmpenho();
  }

  sortedUrl;

  getFiltrosEmpenho = function (props) {
    const { urlFiltro, filtroParam, location } = props;

    let userInfo = StorageService.getItem('userInfo');
    const { entidade, exercicio } = userInfo;

    let filter = `&entidade=${entidade.id}&exercicio=${exercicio.id}`;

    if (location != null && location.search !== '' && !filtroParam) {
      return location.search + filter;
    } else if (filtroParam) {
      return filtroParam;
    } else if (urlFiltro) {
      return urlFiltro;
    }
    return '';
  };

  getFiltroParam = function (props) {
    const { saveFiltroListagem } = props;

    const filtro = this.getFiltrosEmpenho(props);

    saveFiltroListagem(filtro);

    return filtro;
  };

  carregaFiltro = props => {
    const { resetEmpenho } = props;

    resetEmpenho();

    let filtroParam = this.getFiltroParam(props);

    if (filtroParam && filtroParam !== '') {
      this.carregaDados(filtroParam);
    }
  };

  onConsultaEmpenhoPage = page => {
    let pagination = '';

    let filter = this.getFiltrosEmpenho(this.props);

    if (page.get('sort') && typeof page.get('sort') === 'string') {
      this.sortedUrl = page.get('sort');
    }
    if (filter !== '') {
      filter = '?' + filter + '&' + pagination;
      this.carregaDados(`?${addPagination(page, filter)}`);
    }
  };

  carregaDados = filter => {
    const {
      findEmpenhos,
      findEmpenhosTotais,
      location,
      paramDespesasEntidadesDif
    } = this.props;
    let userInfo = StorageService.getItem('userInfo');
    const { entidade } = userInfo;

    if (
      (location &&
        location.pathname &&
        location.pathname.includes('empenhos-covid/cf')) ||
      (location &&
        location.pathname &&
        location.pathname.includes('empenhos-fundeb/cf'))
    ) {
      if (paramDespesasEntidadesDif !== 'S') {
        filter = filter + '&entidade=' + entidade.id;
      }
    }
    if (filter != null) {
      findEmpenhos(filter);
      findEmpenhosTotais(filter);
    }
  };

  onViewDetalhes = item => {
    const entidade = item.get('entidade');
    const exercicio = item.get('exercicio');
    const empenho = item.get('empenho');

    this.props.history.push(
      `/empenhos/detalhe?search=id.entidade==${entidade}&entidade=${entidade}&exercicio=${exercicio}&empenho=${empenho}`
    );
  };

  addFilter = function (actualFilter, filterAnd, filterOperator) {
    if (actualFilter === '') {
      filterOperator = '';
    }
    return actualFilter + filterOperator + filterAnd;
  };

  renderEmpenhoDetalhes = data => {
    const { entidade } = storageService.getItem('userInfo');
    const { paramDespesasEntidadesDif } = this.props;
    return (
      <TableActions
        ref="row"
        key={data.get('empenho') + '_' + data.get('exercicio')}
        data={data}
        onMore={this.onViewDetalhes}
      >
        {(entidade && entidade.id <= 0) ||
          (paramDespesasEntidadesDif !== 'N' && (
            <td className="center">{data.get('entidade')}</td>
          ))}
        <td>
          <LinkToEmpenho
            entidade={data.get('entidade')}
            exercicio={data.get('exercicio')}
            empenho={data.get('empenho')}
          />
        </td>
        <td>{DateUtils.formatDateTimeShort(data.get('data'))}</td>
        <td>{data.get('nome')}</td>
        <td title={data.get('historico')}>
          {compactText(data.get('historico'), 50)}
        </td>
        <td className="right">
          <NumberFormatter value={data.get('valorEmpenhado')} />
        </td>
        <td className="right">
          <NumberFormatter value={data.get('valorAnulado')} />
        </td>
        <td className="right">
          <NumberFormatter value={data.get('valorLiquidado')} />
        </td>
        <td className="right">
          <NumberFormatter value={data.get('valorRetido')} />
        </td>
        <td className="right">
          <NumberFormatter value={data.get('valorPago')} />
        </td>
        <td className="right">
          <NumberFormatter value={data.get('valorAPagar')} />
        </td>
      </TableActions>
    );
  };

  renderEmpenhoTable() {
    const { totais, page, paramDespesasEntidadesDif } = this.props;
    const { entidade } = storageService.getItem('userInfo');

    return (
      <div className="panel-table-outer">
        <table className="panel-table striped fancy">
          <TableHeader empty={page.get('content').isEmpty()}>
            <tr>
              {(entidade && entidade.id <= 0) ||
                (paramDespesasEntidadesDif !== 'N' && (
                  <TableHeaderItem
                    field="id.entidade"
                    label="Entidade"
                    page={page}
                    searchWithPage={this.onConsultaEmpenhoPage}
                  />
                ))}
              <TableHeaderItem
                field="id.empenho"
                label="Empenho"
                page={page}
                searchWithPage={this.onConsultaEmpenhoPage}
              />
              <TableHeaderItem
                field="data"
                label="Data"
                page={page}
                searchWithPage={this.onConsultaEmpenhoPage}
              />
              <TableHeaderItem
                field="fornecedor.nome"
                label="Fornecedor"
                page={page}
                searchWithPage={this.onConsultaEmpenhoPage}
              />
              <TableHeaderItem
                field="historico"
                label="Histórico"
                page={page}
                searchWithPage={this.onConsultaEmpenhoPage}
              />
              <th className="right">Empenhado</th>
              <th className="right">Anulado</th>
              <th className="right">Liquidado</th>
              <th className="right">Retido</th>
              <th className="right">Pago</th>
              <th className="right">A Pagar</th>
              <th></th>
            </tr>
          </TableHeader>
          <tbody>{page.get('content').map(this.renderEmpenhoDetalhes)}</tbody>

          {!page.get('content').isEmpty() && (
            <tfoot>
              <tr>
                {(entidade && entidade.id <= 0) ||
                  (paramDespesasEntidadesDif !== 'N' && <td></td>)}
                <td></td>
                <td></td>
                <td></td>
                <td id="totalValorEmpenhado" className="right" colSpan={2}>
                  <i>Total: </i>
                  <br />
                  <NumberFormatter value={totais.get('valorEmpenhado')} />
                </td>
                <td id="totalValorAnulado" className="right">
                  <i>Total: </i>
                  <br />
                  <NumberFormatter value={totais.get('valorAnulado')} />
                </td>
                <td id="totalValorLiquidado" className="right">
                  <i>Total: </i>
                  <br />
                  <NumberFormatter value={totais.get('valorLiquidado')} />
                </td>
                <td id="totalValorRetido" className="right">
                  <i>Total: </i>
                  <br />
                  <NumberFormatter value={totais.get('valorRetido')} />
                </td>
                <td id="totalValorPago" className="right">
                  <i>Total: </i>
                  <br />
                  <NumberFormatter value={totais.get('valorPago')} />
                </td>
                <td id="totalValorAPagar" className="right">
                  <i>Total: </i>
                  <br />
                  <NumberFormatter value={totais.get('valorAPagar')} />
                </td>
                <td></td>
              </tr>
            </tfoot>
          )}
        </table>
      </div>
    );
  }

  renderPageHeader() {
    const { disableHeader } = this.props;

    if (!disableHeader) {
      return <PageHeader location={this.props.location} />;
    }
    return null;
  }

  renderFiltro() {
    const {
      match: {
        params: { mostraFiltro }
      },
      page,
      location
    } = this.props;
    //cf = comfiltro
    if (mostraFiltro === 'cf') {
      return (
        <EmpenhosFiltro
          key="idEmpenhoFiltro"
          page={page}
          fromTelaCovid={
            location &&
            location.pathname &&
            location.pathname.includes('empenhos-covid/cf')
          }
          fromTelaFundeb={
            location &&
            location.pathname &&
            location.pathname.includes('empenhos-fundeb/cf')
          }
          notCollapseInitial={true}
        />
      );
    }
    return null;
  }

  onExportaEmpenho = (exportType, openTab, windowParams) => {
    const { urlFiltro, page, addErrorNotification } = this.props;
    const { apiURL } = configuration;
    let userInfo = StorageService.getItem('userInfo');
    const { entidade, exercicio } = userInfo;
    let filter = 'entidade=' + entidade.id + '&exercicio=' + exercicio.id;

    let filtro;

    if (page.get('content').size > 0) {
      if (!urlFiltro) {
        filtro = `${window.location.search}&${filter}&exportType=${exportType}`;
      } else {
        filtro =
          urlFiltro + '&exportType=' + exportType + '&entidade=' + entidade.id;
      }

      if (this.sortedUrl) {
        filtro = `${filtro}&sort=${this.sortedUrl}`;
      }

      window.open(`${apiURL}/empenhos/report${filtro}`, openTab, windowParams);
    } else {
      addErrorNotification(
        'A consulta não apresentou resultado. Não é possível exportar para o formato selecionado!'
      );
    }
  };

  renderEmpenho() {
    const { disableHeader, page } = this.props;

    if (!page.has('content')) {
      return null;
    }

    //Com Panel -> default mostra
    if (!disableHeader) {
      return (
        <section>
          <Panel isForm tableResponsive expansible={false}>
            <Row>
              <Col xs={6}>
                <UltimaAtualizacaoByTable
                  modulo="3"
                  table="empenho"
                  column="dataultimaatualizacao"
                />
              </Col>
              <Col xs={6}>
                <ExportButtonGroup
                  onClick={this.onExportaEmpenho}
                  visible={true}
                />
              </Col>
            </Row>
            {this.renderEmpenhoTable()}
          </Panel>

          <SearchPagination
            page={page}
            showAllIfLessThan={20}
            searchWithPage={this.onConsultaEmpenhoPage}
          />
        </section>
      );
    }

    return (
      <div>
        {this.renderEmpenhoTable()}

        <SearchPagination
          page={page}
          showAllIfLessThan={20}
          searchWithPage={this.onConsultaEmpenhoPage}
        />
      </div>
    );
  }

  renderNotaExplicativa() {
    const {
      match: {
        params: { mostraFiltro }
      }
    } = this.props;

    if (mostraFiltro === 'cf') {
      return (
        <NotasExplicativas
          pathname={this.props.location && this.props.location.pathname}
        />
      );
    }

    return null;
  }

  render() {
    return (
      <section>
        {this.renderPageHeader()}

        {this.renderFiltro()}

        {this.renderNotaExplicativa()}

        {this.renderEmpenho()}
      </section>
    );
  }
}

function mapStateToProps(state, ownerprops) {
  return {
    page: state.empenhos.get('empenhos'),
    totais: state.empenhos.get('totais'),
    urlFiltro: ownerprops.filtroParam || state.empenhos.get('filtroListagem'),
    paramDespesasEntidadesDif: ParametroService.getValorParametro(
      state,
      PARAM_DESPESAS_ENTIDADES_DIFERENTES
    )
  };
}

const allActions = {
  ...actions,
  addErrorNotification
};

export default connect(mapStateToProps, allActions)(Empenhos);
