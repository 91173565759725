import * as actions from '../menu/Actions.js';

import {
  PARAM_ESIC_PROPRIO,
  PARAM_FORMULARIO_MENSAGEM
} from 'portaltransparencia-common/parametros/ParametrosPortalTransparencia';

import Component from 'react-pure-render/component';
import FormAtendimento from '../atendimento/Form.react.js';
import FormContato from '../atendimento/FormContato.react.js';
import Helmet from 'react-helmet';
import HomeMenuItem from './HomeMenuItem.react.js';
import ModalBackend from './ModalBackend.js';
import NotasExplicativas from '../notaexplicativa/Form.react.js';
import ParametroService from '../parametros/ParametroService.js';
import PropTypes from 'prop-types';
import React from 'react';
import { addHttp } from 'portaltransparencia-common/libs/LinkExterno';
import { connect } from 'react-redux';
import { offsidebarToggle } from 'portaltransparencia-common/ui/Actions.js';

export class Home extends Component {
  static propTypes = {
    menu: PropTypes.object.isRequired,
    paramShowAcessoAInformacao: PropTypes.string,
    paramLinkEsicProprio: PropTypes.string,
    offsidebarToggle: PropTypes.func,
    ModalBackend: PropTypes.object
  };

  renderMenu = menuItem => {
    return (
      <HomeMenuItem
        menuItem={menuItem}
        key={menuItem.get('id') + menuItem.get('title')}
      />
    );
  };

  onClick = () => {
    const { offsidebarToggle } = this.props;
    offsidebarToggle();
  };

  render() {
    const {
      menu,
      paramShowAcessoAInformacao,
      paramLinkEsicProprio
    } = this.props;

    const styles = {
      cursor: 'pointer',
      textDecorationLine: 'underline'
    };

    let possuiAcessoDiferenteESIC =
      paramShowAcessoAInformacao > 0 && paramShowAcessoAInformacao != 3;
    let possuiAcessoESIC =
      paramShowAcessoAInformacao > 0 && paramShowAcessoAInformacao == 3;

    return (
      <div className="pt-home">
        <Helmet title={'Portal Transparência'} />
        <ModalBackend />
        <div className="pt-home-inner">
          <h1 className="text-title mt-xs center">Seja bem-vindo!</h1>
          <h2 className="text-sub-title mt-xs mb-xs center">
            Escolha uma categoria abaixo para consultar
            {paramShowAcessoAInformacao > 0 && ' ou '}
            {possuiAcessoDiferenteESIC && (
              <a style={styles} onClick={this.onClick}>
                clique aqui
              </a>
            )}{' '}
            {possuiAcessoDiferenteESIC && ' para solicitar informações'}
            {possuiAcessoESIC && (
              <a
                style={styles}
                href={addHttp(paramLinkEsicProprio)}
                target="_blank"
              >
                clique aqui
              </a>
            )}{' '}
            {possuiAcessoESIC && ' para solicitar informações'}
          </h2>

          <NotasExplicativas pathname="/home" />

          <ul className="pt-home-itens" id="homeItens">
            {menu.map(this.renderMenu)}
          </ul>

          <div className="info mb-xs mt-xs">
            <FormAtendimento />
          </div>
          <FormContato />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    menu: state.menu.get('menu'),
    paramShowAcessoAInformacao: ParametroService.getValorParametro(
      state,
      PARAM_FORMULARIO_MENSAGEM
    ),
    paramLinkEsicProprio: ParametroService.getValorParametro(
      state,
      PARAM_ESIC_PROPRIO
    )
  };
}

const allActions = {
  ...actions,
  offsidebarToggle
};

export default connect(mapStateToProps, allActions)(Home);
