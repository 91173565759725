export const ACORDO_SEM_RECURSO = 'ACORDO_SEM_RECURSO';
export const ACORDO_SEM_RECURSO_START = 'ACORDO_SEM_RECURSO_START';
export const ACORDO_SEM_RECURSO_SUCCESS = 'ACORDO_SEM_RECURSO_SUCCESS';
export const ACORDO_SEM_RECURSO_ERROR = 'ACORDO_SEM_RECURSO_ERROR';

export const ACORDOS = 'ACORDOS';
export const ACORDOS_START = 'ACORDOS_START';
export const ACORDOS_SUCCESS = 'ACORDOS_SUCCESS';
export const ACORDOS_ERROR = 'ACORDOS_ERROR';

export const CONSULTA_ACORDOS = 'CONSULTA_ACORDOS';
export const CONSULTA_ACORDOS_START = 'CONSULTA_ACORDOS_START';
export const CONSULTA_ACORDOS_SUCCESS = 'CONSULTA_ACORDOS_SUCCESS';
export const CONSULTA_ACORDOS_ERROR = 'CONSULTA_ACORDOS_ERROR';

export const RESET_CONSULTA_ACORDOS = 'RESET_CONSULTA_ACORDOS';

export const FORMULARIO_SOLICITACAO_NOTIFICACAO_RESET =
  'FORMULARIO_SOLICITACAO_NOTIFICACAO_RESET';
