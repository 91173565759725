import { Map, fromJS } from 'immutable';
import DatePicker from 'portaltransparencia-common/components/DatePicker.react.js';
import Input from 'portaltransparencia-common/components/EloInput.react.js';
import ExportButtonGroup from 'portaltransparencia-common/components/ExportButtonGroup.react.js';
import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import SearchPagination from 'portaltransparencia-common/components/SearchPagination.react.js';
import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import TableHeaderItem from 'portaltransparencia-common/components/TableHeaderItem.react';
import configuration from 'portaltransparencia-common/config/Config.js';
import { changeFieldValue } from 'portaltransparencia-common/crud/Actions.js';
import {
  onChange,
  resetCrud
} from 'portaltransparencia-common/crud/Actions.js';
import {
  addFilterUrl,
  addPagination
} from 'portaltransparencia-common/libs/AddFilter';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import Fields from 'portaltransparencia-common/libs/Fields.js';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import { compactText } from 'portaltransparencia-common/libs/TextCompact.js';
import {
  PARAM_LICITACOES_ENTIDADES_DIFERENTES,
  PARAM_USAWEBSERVICE_MARINGA
} from 'portaltransparencia-common/parametros/ParametrosPortalTransparencia';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import { downloadArquivo } from '../arquivo/Actions';
import FilterButtons from '../components/FilterButtons.react.js';
import PageHeader from '../components/PageHeader.react.js';
import NotasExplicativas from '../notaexplicativa/Form.react';
import ParametroService from '../parametros/ParametroService.js';
import UltimaAtualizacao from '../ultimaatualizacao/Form.react.js';
import * as actions from './Actions.js';
import ManualFornecedor from './ManualFornecedor.react';
import { addErrorNotification } from 'portaltransparencia-common/ui/Actions.js';
import { Ellipsis } from 'portaltransparencia-common/components/Ellipsis.react.js';

export class Licitacao extends Component {
  static propTypes = {
    page: PropTypes.object.isRequired,
    naturezas: PropTypes.object.isRequired,
    situacoes: PropTypes.object.isRequired,
    findLicitacoes: PropTypes.func.isRequired,
    modalidades: PropTypes.object,
    loadModalidades: PropTypes.func.isRequired,
    loadNaturezasProcedimento: PropTypes.func.isRequired,
    searchLicitacoes: PropTypes.func.isRequired,
    changeFieldValue: PropTypes.func.isRequired,
    exportaLicitacoes: PropTypes.func,
    filtros: PropTypes.object,
    onChange: PropTypes.func,
    location: PropTypes.object.isRequired,
    resetCrud: PropTypes.func.isRequired,
    downloadArquivo: PropTypes.func.isRequired,
    paramUsaWebService: PropTypes.string,
    linksLicitacao: PropTypes.object,
    findLinksUteis: PropTypes.func.isRequired,
    paramLicitacoesEntidadesDif: PropTypes.string,
    addErrorNotification: PropTypes.func.isRequired
  };

  componentDidMount() {
    const {
      loadModalidades,
      loadNaturezasProcedimento,
      findLinksUteis
    } = this.props;
    const { entidade } = StorageService.getItem('userInfo');

    findLinksUteis(entidade.id);
    loadModalidades();
    loadNaturezasProcedimento();
    this.onResetFiltro('filtroLicitacoes');
  }

  onChangeFiltro = event => {
    const { onChange } = this.props;

    onChange('filtroLicitacoes', event);
  };

  onResetFiltro = () => {
    const {
      resetCrud,
      searchLicitacoes,
      paramLicitacoesEntidadesDif
    } = this.props;
    resetCrud('filtroLicitacoes');
    let userInfo = StorageService.getItem('userInfo');
    const { entidade, exercicio } = userInfo;

    let filter = `exercicio=${exercicio.id}&ataDeAdesao='S'`;

    if (paramLicitacoesEntidadesDif !== 'S') {
      filter = addFilterUrl(filter, 'entidade=' + entidade.id);
    }

    searchLicitacoes(filter);
  };

  montaFilter = function (page) {
    const { entidade, exercicio } = StorageService.getItem('userInfo');
    const { filtros, paramLicitacoesEntidadesDif } = this.props;

    let filter = `exercicio=${exercicio.id}&ataDeAdesao='S'`;

    if (paramLicitacoesEntidadesDif !== 'S') {
      filter = addFilterUrl(filter, 'entidade=' + entidade.id);
    }

    if (this.refs.modalidade && this.refs.modalidade.getValue()) {
      filter = addFilterUrl(
        filter,
        'modalidade=' + this.refs.modalidade.getValue()
      );
    }
    if (this.refs.numeroLicitacao && this.refs.numeroLicitacao.getValue()) {
      filter = addFilterUrl(
        filter,
        'numeroLicitacao=' + this.refs.numeroLicitacao.getValue()
      );
    }
    if (this.refs.numeroProcesso && this.refs.numeroProcesso.getValue()) {
      filter = addFilterUrl(
        filter,
        'numeroProcesso=' + this.refs.numeroProcesso.getValue()
      );
    }
    if (this.refs.situacao && this.refs.situacao.getValue()) {
      if (this.refs.situacao.getValue() != -1) {
        filter = addFilterUrl(
          filter,
          'situacao=' + this.refs.situacao.getValue()
        );
      }
    }
    if (this.refs.objeto && this.refs.objeto.getValue()) {
      filter = addFilterUrl(filter, 'objeto=' + this.refs.objeto.getValue());
    }
    if (
      filtros.get('dataAbertura') &&
      filtros.get('dataAbertura').length == 10
    ) {
      let validValue = DateUtils.transform(
        filtros.get('dataAbertura'),
        'yy-mm-dd',
        'yy-mm-dd'
      );
      filter = addFilterUrl(filter, `dataAbertura=${validValue}`);
    }
    if (filtros.get('natureza')) {
      filter = addFilterUrl(filter, `natureza=${filtros.get('natureza')}`);
    }
    if (this.refs.cnpjCpf && this.refs.cnpjCpf.getValue()) {
      filter = addFilterUrl(filter, 'cnpjCpf=' + this.refs.cnpjCpf.getValue());
    }

    return addPagination(page, filter);
  };

  onConsultaLicitacoes = event => {
    const { page } = this.props;

    const initialPage = page.set('number', 0);

    this.searchWithPage(initialPage);

    event && event.preventDefault();
    this.refs.panelFiltroLicitacoes &&
      this.refs.panelFiltroLicitacoes.collapse();
  };

  searchWithPage = page => {
    const { searchLicitacoes } = this.props;

    let filter = this.montaFilter(page);

    searchLicitacoes(filter);
  };

  onChange = event => {
    const { changeFieldValue } = this.props;

    Fields.fireChange(changeFieldValue, event);

    this.onChangeFiltro(event);
  };

  renderLicitacoes = data => {
    const key = `${data.get('entidade')}_${data.get('exercicio')}_${data.get(
      'tipoLicitacao'
    )}_${data.get('licitacao')}`;
    return (
      <TableActions
        ref="row"
        key={key}
        data={data}
        onMore={this.onViewDetalhes}
      >
        <td>{DateUtils.transform(data.get('dataAbertura'))}</td>
        <td>{data.get('descricao')}</td>
        <td>{data.get('displayLicitacao')}</td>
        <td>{data.get('natureza')}</td>
        <td>{data.get('displayProcessoAdm')}</td>
        <td>{data.get('tipoCancelamento')}</td>
        <td className="right">
          {data.get('orcamentoSigiloso') !== 'S' && (
            <NumberFormatter value={data.get('valorMaximo')} />
          )}
        </td>
        <td className="right">
          <NumberFormatter value={data.get('totalVencido')} />
        </td>
        <td title={data.get('objeto')}>
          <Ellipsis value={data.get('objeto')} maxLength={15} />
        </td>
        <td>{data.get('hasRecurso') === 'S' ? 'Sim' : 'Não'}</td>
      </TableActions>
    );
  };

  renderSituacao = item => {
    return (
      <option key={item} value={item}>
        {item}
      </option>
    );
  };

  onViewDetalhes = item => {
    const entidade = item.get('entidade');
    const exercicio = item.get('exercicio');
    const tipoLicitacao = item.get('tipoLicitacao');
    const licitacao = item.get('licitacao');

    return this.props.history.push(
      `/licitacoes-covid/detalhes?entidade=${entidade}&exercicio=${exercicio}&tipoLicitacao=${tipoLicitacao}&licitacao=${licitacao}`
    );
  };

  onExporta = (exportType, openTab, windowParams) => {
    const { page, addErrorNotification } = this.props;
    const { apiURL } = configuration;

    if (page.get('content').size > 0) {
      let filtro = this.montaFilter(page) + '&exportType=' + exportType;

      window.open(
        `${apiURL}/api/licitacoes/report?${filtro}`,
        openTab,
        windowParams
      );
    } else {
      addErrorNotification(
        'A consulta não apresentou resultado. Não é possível exportar para o formato selecionado!'
      );
    }
  };

  renderModalidades = data => {
    return (
      <option key={data.get('id')} value={data.get('id')}>
        {data.get('id') + ' - ' + data.get('descricao')}
      </option>
    );
  };

  renderNaturezas = () => {
    const { filtros, naturezas } = this.props;
    const naturezasPorModalidade = naturezas?.filter(
      n => n.get('tipoLicitacao') == filtros.get('modalidade')
    );

    return (
      naturezasPorModalidade &&
      naturezasPorModalidade.map(data => (
        <option
          key={`${data.get('tipoLicitacao')}_${data.get(
            'naturezaProcedimentoDescricao'
          )}_${data.get('naturezaProcedimento')}`}
          value={data.get('naturezaProcedimentoDescricao')}
        >
          {data.get('naturezaProcedimentoDescricao')}
        </option>
      ))
    );
  };

  openSala = link => {
    window.open(link, '_blank', 'width=800, height=600');
  };

  renderPlaceholderNatureza = () => {
    const { filtros } = this.props;

    return (
      <option value="">
        {filtros && filtros.get('modalidade')
          ? 'Selecione'
          : 'Selecione uma modalidade'}
      </option>
    );
  };

  render() {
    const {
      page,
      situacoes,
      modalidades,
      filtros,
      linksLicitacao
    } = this.props;

    if (!page.has('content')) {
      return null;
    }

    return (
      <section>
        <PageHeader location={this.props.location} />

        {linksLicitacao.length > 0 && (
          <div id="salas" className="align-group center mb-xs">
            <button className="inline mr-xs">
              Licitação On-line
              <em className="ml-xs fa fa-long-arrow-right" />
            </button>
            {linksLicitacao.map((link, i) => {
              const onClick = () => this.openSala(link.get('link'));
              return (
                <button
                  key={i}
                  id={`sala${i + 1}`}
                  className="btn neutral inline"
                  onClick={onClick}
                  title={link.descricao}
                >
                  {`Sala ${i + 1}`}
                </button>
              );
            })}
          </div>
        )}

        <NotasExplicativas pathname={this.props.location.pathname} />

        <Panel
          title="Consultar em Licitações"
          isForm
          mobileClose
          ref="panelFiltroLicitacoes"
        >
          <form>
            <Row>
              <Col xs={12} sm={4} md={3}>
                <Input
                  id="modalidade"
                  name="modalidade"
                  ref="modalidade"
                  label="Modalidade"
                  type="select"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('modalidade')}
                >
                  <option value="">Selecione</option>
                  {modalidades && modalidades.map(this.renderModalidades)}
                </Input>
              </Col>
              <Col xs={12} sm={4} md={3}>
                <Input
                  id="natureza"
                  name="natureza"
                  ref="natureza"
                  label="Natureza"
                  type="select"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('natureza')}
                  disabled={!filtros.get('modalidade')}
                >
                  {this.renderPlaceholderNatureza()}
                  {this.renderNaturezas()}
                </Input>
              </Col>
              <Col xs={6} sm={4} md={3}>
                <Input
                  id="numeroLicitacao"
                  name="numeroLicitacao"
                  ref="numeroLicitacao"
                  placeholder="Nº da Licitação"
                  label="Nº da Licitação"
                  type="number"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('numeroLicitacao')}
                />
              </Col>
              <Col xs={6} sm={4} md={3}>
                <Input
                  id="numeroProcesso"
                  name="numeroProcesso"
                  ref="numeroProcesso"
                  placeholder="Nº da Processo"
                  label="Nº do Processo"
                  type="number"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('numeroProcesso')}
                />
              </Col>
              <Col xs={12} sm={12} md={3}>
                <Input
                  id="situacao"
                  name="situacao"
                  ref="situacao"
                  label="Situação"
                  type="select"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('situacao')}
                >
                  <option value="-1">Selecione</option>
                  {situacoes.map(this.renderSituacao)}
                </Input>
              </Col>
              <Col xs={12} sm={4} md={3}>
                <DatePicker
                  id="dataAbertura"
                  name="dataAbertura"
                  ref="dataAbertura"
                  label="Data Abertura"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('dataAbertura')}
                />
              </Col>
              <Col xs={12} sm={4} md={3}>
                <Input
                  id="cnpjCpf"
                  type="text"
                  ref="cnpjCpf"
                  name="cnpjCpf"
                  label="CNPJ/CPF Participante"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('cnpjCpf')}
                />
              </Col>
              <Col xs={12} sm={12} md={12}>
                <Input
                  id="objeto"
                  type="text"
                  ref="objeto"
                  name="objeto"
                  placeholder="Objeto"
                  label="Objeto"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('objeto')}
                />
              </Col>
              <Col xs={12} sm={4} md={3}>
                <FilterButtons
                  idBtnReset="btnResetFilter"
                  onConsulta={this.onConsultaLicitacoes}
                  onReset={this.onResetFiltro}
                />
              </Col>
            </Row>
          </form>
        </Panel>

        <Panel isForm expansible={false} tableResponsive>
          <Row>
            <Col xs={4}>
              <UltimaAtualizacao modulo="10" tipo="licitacao" />
            </Col>
            <Col xs={5}>
              <ManualFornecedor />
            </Col>
            <Col xs={3}>
              <ExportButtonGroup onClick={this.onExporta} visible={true} />
            </Col>
          </Row>
          <div className="panel-table-outer">
            <table className="panel-table striped fancy">
              <TableHeader empty={page.get('content').isEmpty()}>
                <tr>
                  <TableHeaderItem
                    field="dataAbertura"
                    label="Abertura"
                    page={page}
                    searchWithPage={this.searchWithPage}
                  />
                  <TableHeaderItem
                    field="descricao"
                    label="Modalidade"
                    page={page}
                    searchWithPage={this.searchWithPage}
                  />
                  <TableHeaderItem
                    field="numeroLicitacao"
                    label="Número"
                    page={page}
                    searchWithPage={this.searchWithPage}
                  />
                  <TableHeaderItem
                    field="natureza"
                    label="Natureza"
                    page={page}
                    searchWithPage={this.searchWithPage}
                  />
                  <TableHeaderItem
                    field="numeroProcessoAdm"
                    label="Processo Administrativo"
                    page={page}
                    searchWithPage={this.searchWithPage}
                  />
                  <TableHeaderItem
                    field="tipoCancelamento"
                    label="Situação"
                    page={page}
                    searchWithPage={this.searchWithPage}
                  />
                  <TableHeaderItem
                    field="valorMaximo"
                    label="Valor Máximo Processo"
                    page={page}
                    searchWithPage={this.searchWithPage}
                    alignment="right"
                  />
                  <TableHeaderItem
                    field="totalVencido"
                    label="Valor Homologado"
                    page={page}
                    searchWithPage={this.searchWithPage}
                    alignment="right"
                  />
                  <TableHeaderItem
                    field="objeto"
                    label="Objeto"
                    page={page}
                    searchWithPage={this.searchWithPage}
                  />
                  <th>Recursos Impugnações</th>
                  <th />
                </tr>
              </TableHeader>
              <tbody>{page.get('content').map(this.renderLicitacoes)}</tbody>
            </table>
          </div>
        </Panel>

        <SearchPagination page={page} searchWithPage={this.searchWithPage} />
      </section>
    );
  }
}

function mapStateToProps(state) {
  const situacoes = fromJS([
    'Adjudicada',
    'Aguardando Abertura',
    'Anulada',
    'Descartada',
    'Deserta',
    'Em Andamento',
    'Fracassada',
    'Homologada',
    'Revogada',
    'Suspensa pelo TCE',
    'Suspensa'
  ]);

  return {
    page: state.licitacao.get('licitacoes'),
    naturezas: state.licitacao.get('naturezasProcedimento'),
    situacoes: situacoes,
    modalidades: state.licitacao.get('modalidades'),
    filtros: state.crud.getIn(['filtroLicitacoes', 'currentRecord'], Map({})),
    paramUsaWebService: ParametroService.getValorParametro(
      state,
      PARAM_USAWEBSERVICE_MARINGA
    ),
    linksLicitacao: state.licitacao.get('linksLicitacao'),
    paramLicitacoesEntidadesDif: ParametroService.getValorParametro(
      state,
      PARAM_LICITACOES_ENTIDADES_DIFERENTES
    )
  };
}

const allActions = {
  ...actions,
  changeFieldValue,
  onChange,
  resetCrud,
  downloadArquivo,
  addErrorNotification
};

export default connect(mapStateToProps, allActions)(Licitacao);
