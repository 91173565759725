import { Map } from 'immutable';
import DatePicker from 'portaltransparencia-common/components/DatePicker.react.js';
import Input from 'portaltransparencia-common/components/EloInput.react.js';
import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import SearchPagination from 'portaltransparencia-common/components/SearchPagination.react.js';
import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import {
  onChange,
  resetCrud
} from 'portaltransparencia-common/crud/Actions.js';
import {
  addFilterUrl,
  addPagination
} from 'portaltransparencia-common/libs/AddFilter.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import PageHeader from '../components/PageHeader.react.js';
import NotasExplicativas from '../notaexplicativa/Form.react.js';
import UltimaAtualizacaoByTable from '../ultimaatualizacao/UltimaAtualizacaoByTable.react.js';
import * as actions from './Actions.js';

export class FormEmLiquidacao extends Component {
  static propTypes = {
    page: PropTypes.object.isRequired,
    findEmpenhosEmLiquidacao: PropTypes.func.isRequired,
    filtros: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    resetCrud: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.loadInitialFiltros();
  }

  loadInitialFiltros = () => {
    const { onChange } = this.props;
    const { exercicio } = StorageService.getItem('userInfo');

    onChange('filtroLiquidacoes', {
      name: 'dataInicial',
      value: `${exercicio.id}-01-01`
    });

    onChange('filtroLiquidacoes', {
      name: 'dataFinal',
      value: `${exercicio.id}-12-31`
    });
  };

  carregarEmLiquidacao = () => {
    const { page } = this.props;
    const initialPage = page.set('number', 0);

    this.searchWithPage(initialPage);
  };

  consultarEmLiquidacao = event => {
    this.carregarEmLiquidacao();
    event && event.preventDefault();
    this.refs.panelFiltroEmLiquidacao &&
      this.refs.panelFiltroEmLiquidacao.collapse();
  };

  onChangeFiltro = event => {
    const { onChange } = this.props;
    onChange('filtroLiquidacoes', event);
  };

  onResetFiltro = () => {
    const { resetCrud } = this.props;

    resetCrud('filtroLiquidacoes');
    this.loadInitialFiltros();
  };

  montarFiltro = function (page) {
    const { entidade, exercicio } = StorageService.getItem('userInfo');

    const { filtros } = this.props;

    let filter = `entidade=${entidade.id}&exercicio=${exercicio.id}`;

    if (filtros.get('cnpj')) {
      filter = addFilterUrl(filter, `cnpjCpf=${filtros.get('cnpj')}`);
    }
    if (filtros.get('nome')) {
      filter = addFilterUrl(
        filter,
        `nome=${encodeURIComponent(filtros.get('nome'))}`
      );
    }
    if (filtros.get('empenho')) {
      filter = addFilterUrl(filter, `empenho=${filtros.get('empenho')}`);
    }
    if (
      filtros.get('dataInicial') &&
      filtros.get('dataInicial').length === 10
    ) {
      let validValue = DateUtils.transform(
        filtros.get('dataInicial'),
        'yy-mm-dd',
        'yy-mm-dd'
      );
      filter = addFilterUrl(filter, `dataInicial=${validValue}`);
    }
    if (filtros.get('dataFinal') && filtros.get('dataFinal').length === 10) {
      let validValue = DateUtils.transform(
        filtros.get('dataFinal'),
        'yy-mm-dd',
        'yy-mm-dd'
      );
      filter = addFilterUrl(filter, `dataFinal=${validValue}`);
    }
    return addPagination(page, filter);
  };

  searchWithPage = page => {
    const { findEmpenhosEmLiquidacao } = this.props;
    findEmpenhosEmLiquidacao(this.montarFiltro(page));
  };

  onViewMore = data => {
    this.props.history.push(
      `empenhos/detalhe?search=id.entidade==${data.get(
        'entidade'
      )}&entidade=${data.get('entidade')}&exercicio=${data.get(
        'exercicio'
      )}&empenho=${data.get('noDocOrigem')}&active=emliquidacao`
    );
  };

  renderEmLiquidacao = data => {
    return (
      <TableActions
        ref="row"
        key={data.get('id')}
        data={data}
        onMore={this.onViewMore}
      >
        <td>{`${data.get('noDocOrigem')}/${data.get('anoDocOrigem')}`}</td>
        <td>{DateUtils.formatDateTimeShort(data.get('data'))}</td>
        <td>{data.get('noEmLiquidacao')}</td>
        <td>{`${data.get('cnpj')} - ${data.get('nomeFornecedor')}`}</td>
        <td className="right">
          <NumberFormatter value={data.get('valor')} />
        </td>
      </TableActions>
    );
  };

  render() {
    const { page, filtros, location } = this.props;

    return (
      <section>
        <PageHeader location={location} />

        <NotasExplicativas pathname={this.props.location.pathname} />

        <Panel
          isForm
          mobileClose
          title="Consulta"
          ref="panelFiltroEmLiquidacao"
        >
          <form>
            <Row>
              <Col xs={12} sm={4} md={4}>
                <Input
                  id="cnpj"
                  name="cnpj"
                  ref="cnpj"
                  placeholder="CNPJ/CPF"
                  label="CNPJ/CPF"
                  type="text"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('cnpj')}
                />
              </Col>
              <Col xs={12} sm={8} md={8}>
                <Input
                  id="nome"
                  name="nome"
                  ref="nome"
                  placeholder="Nome"
                  label="Nome"
                  type="text"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('nome')}
                />
              </Col>
              <Col xs={12} sm={4} md={3}>
                <Input
                  id="empenho"
                  name="empenho"
                  ref="empenho"
                  placeholder="Empenho"
                  label="Empenho"
                  type="number"
                  min="0"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('empenho')}
                />
              </Col>
              <Col xs={12} sm={6} md={3}>
                <DatePicker
                  id="dataInicial"
                  name="dataInicial"
                  ref="dataInicial"
                  label="Data Inicial"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('dataInicial')}
                />
              </Col>
              <Col xs={12} sm={6} md={3}>
                <DatePicker
                  id="dataFinal"
                  name="dataFinal"
                  ref="dataFinal"
                  label="Data Final"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('dataFinal')}
                />
              </Col>

              <Col xs={12} sm={4} md={4}>
                <div className="form-group">
                  <label className="label"></label>
                  <div className="btn-group">
                    <button
                      id="btnFiltrar"
                      className="btn module-color icon-right"
                      type="submit"
                      ref="pesquisar"
                      onClick={this.consultarEmLiquidacao}
                    >
                      Pesquisar
                      <em className="fa fa-search"></em>
                    </button>
                    <button
                      id="btnResetFilter"
                      className="btn neutral icon-right inline no-text"
                      type="button"
                      onClick={this.onResetFiltro}
                    >
                      Limpar
                      <em className="fa fa-times"></em>
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </form>
        </Panel>

        {page && page.get('content') && (
          <Panel isForm tableResponsive expansible={false}>
            <Row>
              <Col xs={6}>
                <UltimaAtualizacaoByTable
                  modulo="3"
                  table="emliquidacao"
                  column="dataultimaatualizacao"
                />
              </Col>
              <Col xs={6} />
            </Row>

            <div className="panel-table-outer">
              <table className="panel-table striped fancy">
                <TableHeader
                  empty={page.get('content').isEmpty()}
                  text="Sem registros"
                >
                  <tr>
                    <th>Empenho</th>
                    <th>Data</th>
                    <th>Nº Em Liquidação</th>
                    <th>Credor</th>
                    <th>Valor</th>
                    <th />
                  </tr>
                </TableHeader>
                <tbody>
                  {page.get('content').map(this.renderEmLiquidacao)}
                </tbody>
              </table>
            </div>

            <SearchPagination
              page={page}
              searchWithPage={this.searchWithPage}
            />
          </Panel>
        )}
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    filtros: state.crud.getIn(['filtroLiquidacoes', 'currentRecord'], Map({})),
    page: state.emLiquidacao.get('empenhosEmLiquidacao')
  };
}

const allActions = {
  ...actions,
  onChange,
  resetCrud
};

export default connect(mapStateToProps, allActions)(FormEmLiquidacao);
