import Component from 'react-pure-render/component';
import React from 'react';

export default class EmManutencao extends Component {
  render() {
    return (
      <div className="not-found">
        <h1>
          <em className="fa fa-exclamation-triangle"></em>
        </h1>
        <h2>Desculpe, estamos em manutenção!</h2>
        <small>Status: 451</small>
      </div>
    );
  }
}
