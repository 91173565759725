import { Map } from 'immutable';
import Input from 'portaltransparencia-common/components/EloInput.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import SearchPagination from 'portaltransparencia-common/components/SearchPagination.react.js';
import {
  onChange,
  resetCrud
} from 'portaltransparencia-common/crud/Actions.js';
import {
  addFilterUrl,
  addPagination
} from 'portaltransparencia-common/libs/AddFilter.js';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import FilterButtons from '../components/FilterButtons.react';
import PageHeader from '../components/PageHeader.react.js';
import NotasExplicativas from '../notaexplicativa/Form.react';
import * as actions from './Actions.js';
import ConvenioListagem from './ConvenioListagem.react.js';
import {
  getTipoRegistroConvenio,
  tipoRegistroConvenioArray
} from './TipoRegistroConvenioEnum';
import { addErrorNotification } from 'portaltransparencia-common/ui/Actions.js';
import configuration from 'portaltransparencia-common/config/Config.js';

export class FormConvenio extends Component {
  static propTypes = {
    page: PropTypes.object.isRequired,
    findConvenios: PropTypes.func.isRequired,
    filtros: PropTypes.object,
    onChange: PropTypes.func,
    situacoes: PropTypes.object.isRequired,
    findSituacoes: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    resetCrud: PropTypes.func.isRequired,
    addErrorNotification: PropTypes.func.isRequired
  };

  componentDidMount() {
    const { findSituacoes, page } = this.props;

    findSituacoes().then(this.searchWithPage(page));
  }

  onConsultaConvenios = event => {
    const { page } = this.props;

    const initialPage = page.set('number', 0);

    this.searchWithPage(initialPage);

    event && event.preventDefault();
    this.refs.panelFiltroConvenios && this.refs.panelFiltroConvenios.collapse();
  };

  onChangeFiltro = event => {
    const { onChange } = this.props;

    onChange('filtroConvenios', event);
  };

  onResetFiltro = () => {
    const { resetCrud, findConvenios } = this.props;
    resetCrud('filtroConvenios');
    findConvenios('');
  };

  searchWithPage = page => {
    const { findConvenios } = this.props;

    const filtro = this.montaFiltro(page);

    findConvenios(filtro);
  };

  montaFiltro = function (page) {
    const { filtros } = this.props;

    let filter;

    const { entidade } = StorageService.getItem('userInfo');

    filter = `entidade=${entidade.id}`;

    if (filtros.get('nrConvenio')) {
      filter = addFilterUrl(filter, `nrConvenio=${filtros.get('nrConvenio')}`);
    }
    if (filtros.get('nrAnoConvenio')) {
      filter = addFilterUrl(
        filter,
        `nrAnoConvenio=${filtros.get('nrAnoConvenio')}`
      );
    }
    if (filtros.get('situacao')) {
      filter = addFilterUrl(filter, `situacao=${filtros.get('situacao')}`);
    }
    if (filtros.get('destinatario')) {
      filter = addFilterUrl(
        filter,
        `destinatario=${filtros.get('destinatario')}`
      );
    }
    if (filtros.get('tipoRegistroConvenio')) {
      filter = addFilterUrl(
        filter,
        `tipoRegistroConvenio=${filtros.get('tipoRegistroConvenio')}`
      );
    }
    if (filtros.get('objeto')) {
      filter = addFilterUrl(filter, `objeto=${filtros.get('objeto')}`);
    }
    return addPagination(page, filter);
  };

  onExporta = (exportType, openTab, windowParams) => {
    const { apiURL } = configuration;
    const { page, addErrorNotification } = this.props;

    if (page.get('content').size > 0) {
      let filtro = this.montaFiltro(page) + '&exportType=' + exportType;

      window.open(
        `${apiURL}/api/convenios/report?${filtro}`,
        openTab,
        windowParams
      );
    } else {
      addErrorNotification(
        'A consulta não apresentou resultado. Não é possível exportar para o formato selecionado!'
      );
    }
  };

  renderSituacao = item => {
    return (
      <option key={item.get('id')} value={item.get('id')}>
        {item.get('descricaoTipoSituacao')}
      </option>
    );
  };

  render() {
    const { page, filtros, situacoes } = this.props;

    const filter = this.montaFiltro(page);

    if (!page.has('content')) {
      return null;
    }

    return (
      <section>
        <PageHeader location={this.props.location} />

        <NotasExplicativas pathname={this.props.location.pathname} />

        <Panel
          title="Consultar em Convênios"
          isForm
          mobileClose
          ref="panelFiltroConvenios"
        >
          <form>
            <Row>
              <Col xs={6} sm={2} md={2}>
                <Input
                  id="nrConvenio"
                  name="nrConvenio"
                  ref="nrConvenio"
                  placeholder="Convênio"
                  label="Convênio"
                  type="number"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('nrConvenio')}
                />
              </Col>
              <Col xs={6} sm={3} md={2}>
                <div className={'form-group'}>
                  <label className="label">Tipo do Instrumento</label>
                  <select
                    key={filtros.get('tipoRegistroConvenio')}
                    id="tipoRegistroConvenio"
                    name="tipoRegistroConvenio"
                    className="form-control control-label"
                    ref="tipoRegistroConvenio"
                    onChange={this.onChangeFiltro}
                    value={filtros.get('tipoRegistroConvenio')}
                  >
                    <option value="">Selecione</option>
                    {tipoRegistroConvenioArray.map(item => {
                      return (
                        <option key={item.valor} value={item.valor}>
                          {getTipoRegistroConvenio(item.valor)}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </Col>
              <Col xs={6} sm={3} md={2}>
                <Input
                  id="nrAnoConvenio"
                  name="nrAnoConvenio"
                  ref="nrAnoConvenio"
                  placeholder="Ano do Convênio"
                  label="Ano do Convênio"
                  type="number"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('nrAnoConvenio')}
                />
              </Col>
              <Col xs={6} sm={3} md={2}>
                <div className={'form-group'}>
                  <label className="label">Situação</label>
                  <select
                    key={filtros.get('situacao')}
                    id="situacao"
                    name="situacao"
                    className="form-control control-label"
                    ref="situacao"
                    onChange={this.onChangeFiltro}
                    value={filtros.get('situacao')}
                  >
                    <option value="">Selecione</option>
                    {situacoes.map(this.renderSituacao)}
                  </select>
                </div>
              </Col>
              <Col xs={6} sm={3} md={3}>
                <Input
                  id="destinatario"
                  ref="destinatario"
                  name="destinatario"
                  placeholder="Destinatário"
                  label="Destinatário"
                  type="text"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('destinatario')}
                />
              </Col>

              <Col xs={6} sm={3} md={11}>
                <Input
                  id="objeto"
                  ref="objeto"
                  name="objeto"
                  placeholder="Objeto"
                  label="Objeto"
                  type="text"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('objeto')}
                />
              </Col>

              <Col xs={12} sm={4} md={3}>
                <FilterButtons
                  idBtnReset="btnResetFilter"
                  onConsulta={this.onConsultaConvenios}
                  onReset={this.onResetFiltro}
                />
              </Col>
            </Row>
          </form>
        </Panel>

        <ConvenioListagem
          conveniosPage={page}
          filter={filter}
          history={this.props.history}
          searchWithPage={this.searchWithPage}
          onExporta={this.onExporta}
        />

        <SearchPagination page={page} searchWithPage={this.searchWithPage} />
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    filtros: state.crud.getIn(['filtroConvenios', 'currentRecord'], Map({})),
    page: state.convenio.get('convenios'),
    situacoes: state.convenio.get('situacoes')
  };
}

const allActions = {
  ...actions,
  onChange,
  resetCrud,
  addErrorNotification
};

export default connect(mapStateToProps, allActions)(FormConvenio);
