import BotaoImprimir from 'portaltransparencia-common/components/BotaoImprimir.react.js';
import DisplayData from 'portaltransparencia-common/components/displaydata/DisplayData.react.js';
import DisplayDataItem from 'portaltransparencia-common/components/displaydata/DisplayDataItem.react.js';
import DisplayDataRow from 'portaltransparencia-common/components/displaydata/DisplayDataRow.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

import CabecalhoEntidade from '../entidades/CabecalhoEntidade.react.js';

export class AutonomoDetalheInformacao extends Component {
  static propTypes = {
    autonomo: PropTypes.object.isRequired,
    usaWebService: PropTypes.bool.isRequired,
    exibeSalarioBase: PropTypes.bool.isRequired
  };

  render() {
    const { autonomo } = this.props;

    if (!autonomo) {
      return null;
    }

    return (
      <div>
        <CabecalhoEntidade />
        <BotaoImprimir />
        <Panel title="Informações Cadastrais" mobileClose expansible={false}>
          <DisplayData border>
            <DisplayDataRow>
              <DisplayDataItem
                sm={9}
                title="Nome"
                value={autonomo.get('nome')}
                id="nome"
              />
              <DisplayDataItem
                sm={3}
                title="Autônomo"
                value={autonomo.get('matricula')}
                id="autonomo"
              />
            </DisplayDataRow>
            <DisplayDataRow>
              <DisplayDataItem
                sm={9}
                title="Lotação"
                value={autonomo.get('descricaoLotacao')}
                id="descricaoLotacao"
              />
              <DisplayDataItem
                sm={3}
                title="Data Início"
                value={DateUtils.formatDateTimeShort(
                  autonomo.get('dataAdmissao')
                )}
                id="dataInicio"
              />
              <DisplayDataItem
                sm={3}
                title="Data Fim"
                value={DateUtils.formatDateTimeShort(
                  autonomo.get('dataFimContrato')
                )}
                id="dataFim"
              />
            </DisplayDataRow>
            <DisplayDataRow>
              <DisplayDataItem
                sm={12}
                title="Descrição das Atividades"
                value={autonomo.get('descricaoAtividades')}
                id="descricaoAtividades"
              />
            </DisplayDataRow>
          </DisplayData>
        </Panel>
      </div>
    );
  }
}

export default AutonomoDetalheInformacao;
