export const REPASSES_RECEBIDOS_PAGE = 'REPASSES_RECEBIDOS_PAGE';
export const REPASSES_RECEBIDOS_PAGE_START = 'REPASSES_RECEBIDOS_PAGE_START';
export const REPASSES_RECEBIDOS_PAGE_SUCCESS =
  'REPASSES_RECEBIDOS_PAGE_SUCCESS';
export const REPASSES_RECEBIDOS_PAGE_ERROR = 'REPASSES_RECEBIDOS_PAGE_ERROR';

export const REPASSES_MES = 'REPASSES_MES';
export const REPASSES_MES_START = 'REPASSES_MES_START';
export const REPASSES_MES_SUCCESS = 'REPASSES_MES_SUCCESS';
export const REPASSES_MES_ERROR = 'REPASSES_MES_ERROR';
