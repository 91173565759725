export const FORNECIMENTO_BENS = 'FORNECIMENTO_BENS';
export const FORNECIMENTO_BENS_START = 'FORNECIMENTO_BENS_START';
export const FORNECIMENTO_BENS_SUCCESS = 'FORNECIMENTO_BENS_SUCCESS';
export const FORNECIMENTO_BENS_ERROR = 'FORNECIMENTO_BENS_ERROR';

export const FORNECIMENTO_BENS_TOTAL = 'FORNECIMENTO_BENS_TOTAL';
export const FORNECIMENTO_BENS_TOTAL_START = 'FORNECIMENTO_BENS_TOTAL_START';
export const FORNECIMENTO_BENS_TOTAL_SUCCESS =
  'FORNECIMENTO_BENS_TOTAL_SUCCESS';
export const FORNECIMENTO_BENS_TOTAL_ERROR = 'FORNECIMENTO_BENS_TOTAL_ERROR';

export const LOCACOES = 'LOCACOES';
export const LOCACOES_START = 'LOCACOES_START';
export const LOCACOES_SUCCESS = 'LOCACOES_SUCCESS';
export const LOCACOES_ERROR = 'LOCACOES_ERROR';

export const LOCACOES_TOTAL = 'LOCACOES_TOTAL';
export const LOCACOES_TOTAL_START = 'LOCACOES_TOTAL_START';
export const LOCACOES_TOTAL_SUCCESS = 'LOCACOES_TOTAL_SUCCESS';
export const LOCACOES_TOTAL_ERROR = 'LOCACOES_TOTAL_ERROR';

export const PRESTACAO_SERVICOS = 'PRESTACAO_SERVICOS';
export const PRESTACAO_SERVICOS_START = 'PRESTACAO_SERVICOS_START';
export const PRESTACAO_SERVICOS_SUCCESS = 'PRESTACAO_SERVICOS_SUCCESS';
export const PRESTACAO_SERVICOS_ERROR = 'PRESTACAO_SERVICOS_ERROR';

export const PRESTACAO_SERVICOS_TOTAL = 'PRESTACAO_SERVICOS_TOTAL';
export const PRESTACAO_SERVICOS_TOTAL_START = 'PRESTACAO_SERVICOS_TOTAL_START';
export const PRESTACAO_SERVICOS_TOTAL_SUCCESS =
  'PRESTACAO_SERVICOS_TOTAL_SUCCESS';
export const PRESTACAO_SERVICOS_TOTAL_ERROR = 'PRESTACAO_SERVICOS_TOTAL_ERROR';

export const OBRAS = 'OBRAS';
export const OBRAS_START = 'OBRAS_START';
export const OBRAS_SUCCESS = 'OBRAS_SUCCESS';
export const OBRAS_ERROR = 'OBRAS_ERROR';

export const OBRAS_TOTAL = 'OBRAS_TOTAL';
export const OBRAS_TOTAL_START = 'OBRAS_TOTAL_START';
export const OBRAS_TOTAL_SUCCESS = 'OBRAS_TOTAL_SUCCESS';
export const OBRAS_TOTAL_ERROR = 'OBRAS_TOTAL_ERROR';

export const PAGAMENTOS = 'PAGAMENTOS';
export const PAGAMENTOS_START = 'PAGAMENTOS_START';
export const PAGAMENTOS_SUCCESS = 'PAGAMENTOS_SUCCESS';
export const PAGAMENTOS_ERROR = 'PAGAMENTOS_ERROR';

export const PAGAMENTOS_TOTAL = 'PAGAMENTOS_TOTAL';
export const PAGAMENTOS_TOTAL_START = 'PAGAMENTOS_TOTAL_START';
export const PAGAMENTOS_TOTAL_SUCCESS = 'PAGAMENTOS_TOTAL_SUCCESS';
export const PAGAMENTOS_TOTAL_ERROR = 'PAGAMENTOS_TOTAL_ERROR';
