import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';

import * as types from './Types.js';

const initialState = fromJS({
  licitacoes: [],
  licitacao: {},
  midias: [],
  arquivos: [],
  publicacoes: [],
  certidoes: [],
  homologacoes: [],
  cotacoes: [],
  pareceres: [],
  atas: [],
  precos: [],
  adjudicacoes: [],
  recursos: [],
  manualFornecedor: {},
  interessadosEditalDownload: [],
  interessadoEdital: {},
  linksLicitacao: [],
  mensagemSolicitacao: {},
  autorizacoes: [],
  numerosei: [],
  naturezasProcedimento: []
});

function licitacaoReducer(state = initialState, action) {
  switch (action.type) {
    case types.LICITACOES_START:
    case types.CONSULTA_LICITACOES_START:
    case types.MODALIDADES_START:
    case types.NATUREZAS_START:
    case types.ARQUIVOS_LICITACAO_START:
    case types.LICITACAO_START:
    case types.MIDIAS_START:
    case types.PUBLICACOES_START:
    case types.CERTIDOES_START:
    case types.HOMOLOGACOES_START:
    case types.COTACOES_START:
    case types.PARECERES_START:
    case types.ATAS_START:
    case types.PRECOS_START:
    case types.ADJUDICACOES_START:
    case types.SOLICITACAO_NOTIFICACAO_START:
    case types.MANUAL_FORNECEDOR_START:
    case types.RECURSOS_START:
    case types.LINKS_LICITACAO_START:
    case types.MENSAGEM_SOLICITACAO_LICITACAO_START:
    case types.AUTORIZACOES_START:
    case types.LICITACAO_SEI_START: {
      return startLoad(state);
    }

    case types.LICITACOES_ERROR:
    case types.CONSULTA_LICITACOES_ERROR:
    case types.MODALIDADES_ERROR:
    case types.NATUREZAS_ERROR:
    case types.ARQUIVOS_LICITACAO_ERROR:
    case types.LICITACAO_ERROR:
    case types.MIDIAS_ERROR:
    case types.PUBLICACOES_ERROR:
    case types.CERTIDOES_ERROR:
    case types.HOMOLOGACOES_ERROR:
    case types.COTACOES_ERROR:
    case types.PARECERES_ERROR:
    case types.ATAS_ERROR:
    case types.PRECOS_ERROR:
    case types.ADJUDICACOES_ERROR:
    case types.SOLICITACAO_NOTIFICACAO_ERROR:
    case types.MANUAL_FORNECEDOR_ERROR:
    case types.RECURSOS_ERROR:
    case types.LINKS_LICITACAO_ERROR:
    case types.MENSAGEM_SOLICITACAO_LICITACAO_ERROR:
    case types.AUTORIZACOES_ERROR:
    case types.LICITACAO_SEI_ERROR: {
      return endLoad(state);
    }

    case types.LICITACOES_SUCCESS:
    case types.CONSULTA_LICITACOES_SUCCESS: {
      return endLoad(state.set('licitacoes', fromJS(action.payload)));
    }
    case types.MODALIDADES_SUCCESS: {
      return endLoad(state.set('modalidades', fromJS(action.payload)));
    }
    case types.NATUREZAS_SUCCESS: {
      return endLoad(
        state.set('naturezasProcedimento', fromJS(action.payload))
      );
    }
    case types.ARQUIVOS_LICITACAO_SUCCESS: {
      return endLoad(state.set('arquivos', fromJS(action.payload)));
    }
    case types.LICITACAO_SUCCESS: {
      return endLoad(state.set('licitacao', fromJS(action.payload)));
    }
    case types.MIDIAS_SUCCESS: {
      return endLoad(state.set('midias', fromJS(action.payload)));
    }
    case types.PUBLICACOES_SUCCESS: {
      return endLoad(state.set('publicacoes', fromJS(action.payload)));
    }
    case types.CERTIDOES_SUCCESS: {
      return endLoad(state.set('certidoes', fromJS(action.payload)));
    }
    case types.HOMOLOGACOES_SUCCESS: {
      return endLoad(state.set('homologacoes', fromJS(action.payload)));
    }
    case types.COTACOES_SUCCESS: {
      return endLoad(state.set('cotacoes', fromJS(action.payload)));
    }
    case types.PARECERES_SUCCESS: {
      return endLoad(state.set('pareceres', fromJS(action.payload)));
    }
    case types.RECURSOS_SUCCESS: {
      return endLoad(state.set('recursos', fromJS(action.payload)));
    }
    case types.ATAS_SUCCESS: {
      return endLoad(state.set('atas', fromJS(action.payload)));
    }
    case types.PRECOS_SUCCESS: {
      return endLoad(state.set('precos', fromJS(action.payload)));
    }
    case types.ADJUDICACOES_SUCCESS: {
      return endLoad(state.set('adjudicacoes', fromJS(action.payload)));
    }
    case types.SOLICITACAO_NOTIFICACAO_SUCCESS: {
      let listaInteressadosEditalDownload = state
        .get('interessadosEditalDownload')
        .push(fromJS(action.payload));
      return endLoad(state)
        .set('interessadoEdital', fromJS(action.payload))
        .set('interessadosEditalDownload', listaInteressadosEditalDownload);
    }
    case types.MANUAL_FORNECEDOR_SUCCESS: {
      return endLoad(state.set('manualFornecedor', fromJS(action.payload)));
    }
    case types.LINKS_LICITACAO_SUCCESS: {
      return endLoad(state.set('linksLicitacao', fromJS(action.payload)));
    }
    case types.MENSAGEM_SOLICITACAO_LICITACAO_SUCCESS: {
      return endLoad(state.set('mensagemSolicitacao', fromJS(action.payload)));
    }
    case types.AUTORIZACOES_SUCCESS: {
      return endLoad(state.set('autorizacoes', fromJS(action.payload)));
    }
    case types.LICITACAO_SEI_SUCCESS: {
      return endLoad(state.set('numerosei', fromJS(action.payload)));
    }

    //RESET
    case types.RESET_CONSULTA_LICITACOES: {
      return initialState;
    }
  }

  return state;
}

export default licitacaoReducer;
