import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import PageHeader from '../components/PageHeader.react.js';
import NotasExplicativas from '../notaexplicativa/Form.react';

export class FormCotasParlamentares extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired
  };

  render() {
    return (
      <section>
        <PageHeader location={this.props.location} />

        <NotasExplicativas pathname={this.props.location.pathname} />
      </section>
    );
  }
}

export default FormCotasParlamentares;
