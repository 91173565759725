import { fromJS } from 'immutable';

export const tiposPatrimonio = fromJS([
  {
    sigla: 'M',
    oxyValue: 'MOVEL',
    descricao: 'Móvel'
  },
  {
    sigla: 'I',
    oxyValue: 'IMOVEL',
    descricao: 'Imóvel'
  },
  {
    sigla: 'V',
    oxyValue: 'VEICULO',
    descricao: 'Veículo'
  },
  {
    sigla: 'B',
    oxyValue: 'BEM_INTANGIVEL',
    descricao: 'Bem Intangível'
  },
  {
    sigla: 'T',
    oxyValue: 'TITULO_VALOR',
    descricao: 'Título e Valor'
  },
  {
    sigla: 'S',
    oxyValue: 'SEMOVENTE',
    descricao: 'Semovente'
  }
]);
