import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export class LicitacaoDetalheParecer extends Component {
  static propTypes = {
    pareceres: PropTypes.object.isRequired,
    onDownload: PropTypes.func.isRequired
  };

  renderPareceres = data => {
    const { onDownload } = this.props;

    const key =
      data.get('tipoParecer') +
      '_' +
      data.get('sequencia') +
      '_' +
      data.get('data');

    return (
      <TableActions
        ref="row"
        key={key}
        data={data.get('idArquivo')}
        onDownload={data.get('idArquivo') && onDownload}
      >
        <td>{DateUtils.formatDateTimeShort(data.get('data'))}</td>
        <td>{data.get('responsavel')}</td>
        <td>{data.get('tipoParecer')}</td>
      </TableActions>
    );
  };

  render() {
    const { pareceres } = this.props;

    return (
      <div className="panel-table-outer">
        <table className="panel-table striped fancy">
          <TableHeader empty={pareceres.isEmpty()}>
            <tr>
              <th>Data</th>
              <th>Responsável</th>
              <th>Tipo</th>
              <th></th>
            </tr>
          </TableHeader>
          <tbody>{pareceres.map(this.renderPareceres)}</tbody>
        </table>
      </div>
    );
  }
}

export default LicitacaoDetalheParecer;
