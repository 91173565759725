import * as types from './Types.js';

export function findMenu(idEntidade, idExercicio) {
  return ({ fetch }) => ({
    type: types.PARAMETRO_MENU,
    payload: fetch(`/parametros/menu/${idEntidade}/${idExercicio}`)
  });
}

export function buscaNotificacao(idEntidade, idExercicio, path) {
  return ({ fetch }) => ({
    type: types.PARAMETRO_BUSCA_NOTIFICACAO,
    payload: fetch(
      `/api/notificacao?entidade=${idEntidade}&exercicio=${idExercicio}&path=${path}`
    )
  });
}

export function buscaArquivoNotificacao(IdArquivo) {
  return ({ doCustomRequest }) => ({
    type: types.PARAMETRO_BUSCA_ARQUIVO_NOTIFICACAO,
    payload: doCustomRequest(`/api/notificacao/${IdArquivo}/arquivo`)
      .then(response => response.blob())
      .then(blob => {
        return new Promise(resolve => {
          const reader = new FileReader();
          reader.readAsDataURL(blob);

          reader.onload = () => {
            resolve(reader.result.replace(/^data:.+;base64,/, ''));
          };
        });
      })
  });
}

export function fechaModal() {
  return () => ({
    type: types.PARAMETRO_TIPO_FECHA_MODAL
  });
}
