export const PESQUISA_PUBLICACOES = 'PESQUISA_PUBLICACOES';
export const PESQUISA_PUBLICACOES_START = 'PESQUISA_PUBLICACOES_START';
export const PESQUISA_PUBLICACOES_ERROR = 'PESQUISA_PUBLICACOES_ERROR';
export const PESQUISA_PUBLICACOES_SUCCESS = 'PESQUISA_PUBLICACOES_SUCCESS';

export const FILTRAR_PUBLICACOES = 'FILTRAR_PUBLICACOES';
export const RESETAR_PUBLICACOES = 'RESETAR_PUBLICACOES';

export const PESQUISA_PUBLICACOESGRUPO = 'PESQUISA_PUBLICACOESGRUPO';
export const PESQUISA_PUBLICACOESGRUPO_START =
  'PESQUISA_PUBLICACOESGRUPO_START';
export const PESQUISA_PUBLICACOESGRUPO_ERROR =
  'PESQUISA_PUBLICACOESGRUPO_ERROR';
export const PESQUISA_PUBLICACOESGRUPO_SUCCESS =
  'PESQUISA_PUBLICACOESGRUPO_SUCCESS';
