import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export class LicitacaoDetalheHomologacao extends Component {
  static propTypes = {
    homologacoes: PropTypes.object.isRequired
  };

  onViewDetalhes = homologacao => {
    const entidade = homologacao.get('entidade');
    const exercicio = homologacao.get('exercicio');
    const exercicioLicitacao = homologacao.get('exercicioLicitacao');
    const tipoLicitacao = homologacao.get('tipoLicitacao');
    const licitacao = homologacao.get('licitacao');
    const pessoa = homologacao.get('pessoa');

    this.props.history.push(
      `/homologacoes/detalhes?entidade=${entidade}&exercicio=${exercicio}&exercicioLicitacao=${exercicioLicitacao}&tipoLicitacao=${tipoLicitacao}&licitacao=${licitacao}&pessoa=${pessoa}`
    );
  };

  renderHomologacoes = data => {
    const key =
      data.get('entidade') +
      '_' +
      data.get('exercicio') +
      '_' +
      data.get('exerciciolicitacao') +
      '_' +
      data.get('tipolicitacao') +
      '_' +
      data.get('licitacao') +
      '_' +
      data.get('pessoa');

    return (
      <TableActions
        ref="row"
        key={key}
        data={data}
        onMore={this.onViewDetalhes}
      >
        <td>{data.get('nome')}</td>
        <td>{data.get('cnpjCpf')}</td>
        <td>{DateUtils.formatDateTimeShort(data.get('data'))}</td>
      </TableActions>
    );
  };

  render() {
    const { homologacoes } = this.props;

    return (
      <div className="panel-table-outer">
        <table className="panel-table striped fancy">
          <TableHeader empty={homologacoes.isEmpty()}>
            <tr>
              <th>Fornecedor</th>
              <th>CNPJ/CPF</th>
              <th>Data Homologação</th>
              <th></th>
            </tr>
          </TableHeader>
          <tbody>{homologacoes.map(this.renderHomologacoes)}</tbody>
        </table>
      </div>
    );
  }
}

export default LicitacaoDetalheHomologacao;
