import { Map } from 'immutable';
import React from 'react';
import Component from 'react-pure-render/component';
import PageHeader from '../components/PageHeader.react';
import NotasExplicativas from '../notaexplicativa/Form.react';
import { Panel } from '@elotech/components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from './Actions.js';
import { Col, Row } from 'react-bootstrap';
import Input from 'portaltransparencia-common/components/EloInput.react.js';
import {
  onChange,
  resetCrud
} from 'portaltransparencia-common/crud/Actions.js';
import {
  addFilterUrl,
  addPagination
} from 'portaltransparencia-common/libs/AddFilter';
import storageService from 'portaltransparencia-common/libs/StorageService';
import ExportButtonGroup from 'portaltransparencia-common/components/ExportButtonGroup.react';
import PcaTable from './PcaTable.js';
import SearchPagination from 'portaltransparencia-common/components/SearchPagination.react.js';
import UltimaAtualizacaoDataConsulta from '../ultimaatualizacao/UltimaAtualizacaoDataConsulta.react.js';
import configuration from 'portaltransparencia-common/config/Config';
import { addErrorNotification } from 'portaltransparencia-common/ui/Actions.js';
import DatePicker from 'portaltransparencia-common/components/DatePicker.react.js';

export class FormPca extends Component {
  static propTypes = {
    page: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    findPcas: PropTypes.func.isRequired,
    filtros: PropTypes.object,
    onChange: PropTypes.func,
    resetCrud: PropTypes.func.isRequired,
    isIntegradoOxy: PropTypes.bool,
    addErrorNotification: PropTypes.func.isRequired
  };

  componentDidMount() {
    const { isIntegradoOxy } = this.props;
    if (!isIntegradoOxy) {
      this.onResetFiltro();
    }
  }

  onConsultaPcas = event => {
    const { page } = this.props;
    const initialPage = page.set('number', 0);

    this.searchWithPage(initialPage);

    event && event.preventDefault();
    event && event.stopPropagation();
    this.refs.panelfiltroPcas && this.refs.panelfiltroPcas.collapse();
  };

  onChangeFiltro = event => {
    const { onChange } = this.props;

    onChange('filtroPcas', event);
  };

  onResetFiltro = event => {
    const { resetCrud, findPcas } = this.props;
    const { entidade, exercicio } = storageService.getItem('userInfo');
    const filter = 'entidade=' + entidade.id + '&exercicio=' + exercicio.id;

    event && event.preventDefault();
    event && event.stopPropagation();

    resetCrud('filtroPcas');
    findPcas(filter);
  };

  searchWithPage = (page, props) => {
    const { findPcas } = props || this.props;
    findPcas(this.montaFiltro(page));
  };

  montaFiltro = function (page) {
    const { filtros } = this.props;
    const { entidade, exercicio } = storageService.getItem('userInfo');

    let filter = 'entidade=' + entidade.id + '&exercicio=' + exercicio.id;

    if (filtros.get('planoContratacao')) {
      filter = addFilterUrl(
        filter,
        `planoContratacao=${filtros.get('planoContratacao')}`
      );
    }

    if (filtros.get('dataPca')) {
      filter = addFilterUrl(filter, `dataPca=${filtros.get('dataPca')}`);
    }

    return addPagination(page, filter);
  };

  onExporta = (exportType, openTab, windowParams) => {
    const { apiURL } = configuration;
    const { page, addErrorNotification } = this.props;
    const { entidade, exercicio } = storageService.getItem('userInfo');

    if (page.get('content').size > 0) {
      let filtro = this.montaFiltro(page) + '&exportType=' + exportType;

      window.open(
        `${apiURL}/api/pca/report?entidade=${entidade.id}&exercicio=${exercicio.id}&${filtro}`,
        openTab,
        windowParams
      );
    } else {
      addErrorNotification(
        'A consulta não apresentou resultado. Não é possível exportar para o formato selecionado!'
      );
    }
  };

  render() {
    const { page, location, filtros, isIntegradoOxy } = this.props;

    if (!page.has('content')) {
      return null;
    }

    if (isIntegradoOxy) {
      return null;
    }

    return (
      <section>
        <PageHeader location={location} />

        <NotasExplicativas pathname={location.pathname} />

        <Panel
          title="Consultar em PCA - Plano de Contratação Anual"
          mobileClose
          isForm
          ref="panelfiltroPcas"
        >
          <form>
            <Row>
              <Col xs={6} sm={4} md={2}>
                <Input
                  id="planoContratacao"
                  placeholder="Informe o número"
                  name="planoContratacao"
                  ref="planoContratacao"
                  label="Número do PCA"
                  type="number"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('planoContratacao')}
                />
              </Col>
              <Col xs={6} sm={4} md={2}>
                <DatePicker
                  id="dataPca"
                  name="dataPca"
                  ref="dataPca"
                  label="Data"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('dataPca')}
                ></DatePicker>
              </Col>
              <Col xs={12} sm={12} md={12}>
                <div className="form-group">
                  <label className="label" />

                  <div className="btn-group">
                    <button
                      id="btnFiltrar"
                      className="btn module-color icon-right"
                      ref="filtrar"
                      onClick={this.onConsultaPcas}
                    >
                      Pesquisar
                      <em className="fa fa-search" />
                    </button>
                    <button
                      id="btnReset"
                      className="btn neutral icon-right inline no-text"
                      type="button"
                      onClick={this.onResetFiltro}
                    >
                      Limpar
                      <em className="fa fa-times" />
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </form>
        </Panel>

        <Panel isForm tableResponsive expansible={false}>
          <Row>
            <Col xs={6}>
              <UltimaAtualizacaoDataConsulta />
            </Col>
            <Col xs={6}>
              <ExportButtonGroup onClick={this.onExporta} visible={true} />
            </Col>
          </Row>

          <PcaTable
            page={page}
            searchWithPage={this.searchWithPage}
            isIntegradoOxy={isIntegradoOxy}
            history={this.props.history}
          />
          <SearchPagination page={page} searchWithPage={this.searchWithPage} />
        </Panel>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    filtros: state.crud.getIn(['filtroPcas', 'currentRecord'], Map({})),
    page: state.pca.get('pcas'),
    isIntegradoOxy: state.entidade.getIn([
      'configuracoesGerais',
      'isIntegradoOxy'
    ])
  };
}

const allActions = {
  ...actions,
  onChange,
  resetCrud,
  addErrorNotification
};

export default connect(mapStateToProps, allActions)(FormPca);
