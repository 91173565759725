import { Map } from 'immutable';
import DatePicker from 'portaltransparencia-common/components/DatePicker.react.js';
import Input from 'portaltransparencia-common/components/EloInput.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import SearchPagination from 'portaltransparencia-common/components/SearchPagination.react.js';
import {
  onChange,
  resetCrud
} from 'portaltransparencia-common/crud/Actions.js';
import {
  addFilterUrl,
  addPagination
} from 'portaltransparencia-common/libs/AddFilter.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import { PARAM_ADIANTAMENTO } from 'portaltransparencia-common/parametros/ParametrosPortalTransparencia';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import FilterButtons from '../components/FilterButtons.react';
import PageHeader from '../components/PageHeader.react.js';
import NotasExplicativas from '../notaexplicativa/Form.react';
import ParametroService from '../parametros/ParametroService.js';
import * as actions from './Actions.js';
import AdiantamentoListagem from './AdiantamentoListagem.react.js';

export class FormAdiantamento extends Component {
  static propTypes = {
    page: PropTypes.object.isRequired,
    findAdiantamentos: PropTypes.func.isRequired,
    filtros: PropTypes.object,
    onChange: PropTypes.func,
    findTotalAdiantamentos: PropTypes.func.isRequired,
    totalAdiantamentos: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    resetCrud: PropTypes.func.isRequired,
    valorParametro: PropTypes.string,
    valorParametroValorExcedido: PropTypes.string
  };

  componentDidMount() {
    this.carregarAdiantamentos();
  }

  carregarAdiantamentos = () => {
    const { page } = this.props;
    const initialPage = page.set('number', 0);

    this.searchWithPage(initialPage);
  };

  consultarAdiantamentos = event => {
    this.carregarAdiantamentos();
    event && event.preventDefault();
    this.refs.panelFiltroAdiantamentos &&
      this.refs.panelFiltroAdiantamentos.collapse();
  };

  onResetFiltro = () => {
    const { resetCrud, findAdiantamentos, findTotalAdiantamentos } = this.props;
    resetCrud('filtroAdiantamentos');
    findAdiantamentos('');
    findTotalAdiantamentos('');
  };

  searchWithPage = page => {
    const { findAdiantamentos, findTotalAdiantamentos } = this.props;

    const filtro = this.montaFiltro(page);

    findAdiantamentos(filtro);
    findTotalAdiantamentos(filtro);
  };

  montaFiltro = function (page) {
    const { entidade, exercicio } = StorageService.getItem('userInfo');

    let filter = 'entidade=' + entidade.id + '&exercicio=' + exercicio.id;

    const { filtros } = this.props;

    if (filtros.get('nome')) {
      filter = addFilterUrl(filter, `nome=${filtros.get('nome')}`);
    }

    if (filtros.get('dataInicial') && filtros.get('dataInicial').length == 10) {
      filter = addFilterUrl(
        filter,
        `dataInicial=${filtros.get('dataInicial')}`
      );
    }

    if (filtros.get('dataFinal') && filtros.get('dataFinal').length == 10) {
      filter = addFilterUrl(filter, `dataFinal=${filtros.get('dataFinal')}`);
    }

    return addPagination(page, filter);
  };

  onChangeFiltro = event => {
    const { onChange } = this.props;

    onChange('filtroAdiantamentos', event);
  };

  render() {
    const { page, filtros, totalAdiantamentos } = this.props;

    const filter = this.montaFiltro(page);

    if (!page.has('content')) {
      return null;
    }

    return (
      <section>
        <PageHeader location={this.props.location} />

        <NotasExplicativas pathname={this.props.location.pathname} />

        <Panel
          title="Consultar em Adiantamento / Reembolso"
          mobileClose
          isForm
          ref="panelFiltroAdiantamentos"
        >
          <form>
            <Row>
              <Col xs={12} sm={4} md={5}>
                <Input
                  id="nome"
                  ref="nome"
                  name="nome"
                  placeholder="Nome do Funcionário"
                  label="Nome do Funcionário"
                  type="text"
                  value={filtros.get('nome')}
                  onChange={this.onChangeFiltro}
                />
              </Col>
              <Col xs={6} sm={2} md={2}>
                <DatePicker
                  id="dataInicial"
                  name="dataInicial"
                  ref="dataInicial"
                  label="Data inicial de prestação"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('dataInicial')}
                />
              </Col>
              <Col xs={6} sm={2} md={2}>
                <DatePicker
                  id="dataFinal"
                  name="dataFinal"
                  ref="dataFinal"
                  label="Data final de prestação"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('dataFinal')}
                />
              </Col>
              <Col xs={12} sm={4} md={3}>
                <FilterButtons
                  onConsulta={this.consultarAdiantamentos}
                  onReset={this.onResetFiltro}
                />
              </Col>
            </Row>
          </form>
        </Panel>

        <AdiantamentoListagem
          filter={filter}
          totalAdiantamentos={totalAdiantamentos}
          page={page}
          history={this.props.history}
          searchWithPage={this.searchWithPage}
          valorParametroValorExcedido={this.valorParametroValorExcedido}
        />

        <SearchPagination page={page} searchWithPage={this.searchWithPage} />
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    filtros: state.crud.getIn(
      ['filtroAdiantamentos', 'currentRecord'],
      Map({})
    ),
    page: state.adiantamento.get('adiantamentos'),
    totalAdiantamentos: state.adiantamento.get('totalAdiantamentos'),
    valorParametro: ParametroService.getValorParametro(
      state,
      PARAM_ADIANTAMENTO
    )
  };
}

const allActions = {
  ...actions,
  onChange,
  resetCrud
};

export default connect(mapStateToProps, allActions)(FormAdiantamento);
