import * as types from './Types.js';

export function findAptosPagamentos(filter) {
  return ({ fetch }) => ({
    type: types.APTOS_PAGAMENTOS,
    payload: {
      promise: fetch(`/api/liquidacoes/agenda-previsao-pagamentos?${filter}`)
    }
  });
}

export function findAptosPagamentosTotal(filter) {
  return ({ fetch }) => ({
    type: types.APTOS_PAGAMENTOS_TOTAL,
    payload: {
      promise: fetch(`/api/liquidacoes/aptos-pagamentos/total?${filter}`)
    }
  });
}

export function findContaPagamento(filter) {
  return ({ fetch }) => ({
    type: types.CONTA_PAGAMENTO,
    payload: {
      promise: fetch(
        `/api/liquidacoes/agenda-previsao-pagamentos/conta?${filter}`
      )
    }
  });
}

export function findRetencoesLiquidacao(filter) {
  return ({ fetch }) => ({
    type: types.RETENCOES_LIQUIDACAO,
    payload: {
      promise: fetch(`/empenhos/detalhe/retencoes?${filter}`)
    }
  });
}
