import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';

import * as types from './Types.js';

const initialState = fromJS({
  servidores: [],
  servidor: [],
  dadosFinanceiros: [],
  dadosPensao: [],
  vencimentosDescontos: [],
  naturezas: [],
  situacoes: [],
  registrosPontos: [],
  servidoresFiltrados: [],
  resumoNatureza: [],
  estagiarios: [],
  estagiario: {},
  anotacoes: [],
  horarioFlexivel: [],
  autonomos: [],
  autonomo: []
});

function servidorReducer(state = initialState, action) {
  switch (action.type) {
    case types.SERVIDORES_RESUMO_START:
    case types.SITUACOES_LIST_START:
    case types.SERVIDOR_REGISTROS_PONTOS_START:
    case types.NATUREZAS_LIST_START:
    case types.SERVIDOR_VENCIMENTOS_DESCONTOS_START:
    case types.SERVIDOR_DETALHE_START:
    case types.SERVIDOR_DETALHE_PENSAO_START:
    case types.SERVIDORES_LIST_START:
    case types.ESTAGIARIO_DETALHE_START:
    case types.ESTAGIARIOS_LIST_START: {
      return startLoad(state);
    }
    case types.AUTONOMO_DETALHE_START:
    case types.AUTONOMOS_LIST_START: {
      return startLoad(state);
    }

    case types.SERVIDORES_LIST_SUCCESS: {
      return endLoad(state.set('servidores', fromJS(action.payload)));
    }

    case types.SERVIDORES_RESUMO_ERROR:
    case types.SITUACOES_LIST_ERROR:
    case types.SERVIDOR_REGISTROS_PONTOS_ERROR:
    case types.NATUREZAS_LIST_ERROR:
    case types.SERVIDOR_VENCIMENTOS_DESCONTOS_ERROR:
    case types.SERVIDOR_DETALHE_ERROR:
    case types.SERVIDOR_DETALHE_PENSAO_ERROR:
    case types.SERVIDORES_LIST_ERROR:
    case types.ESTAGIARIO_DETALHE_ERROR:
    case types.ESTAGIARIOS_LIST_ERROR: {
      return endLoad(state);
    }

    case types.SERVIDOR_DETALHE_SUCCESS: {
      return endLoad(
        state
          .set('servidor', fromJS(action.payload))
          .set('dadosFinanceiros', fromJS(action.payload.dadosFinanceiros))
          .set('anotacoes', fromJS(action.payload.anotacoes))
          .set('horarioFlexivel', fromJS(action.payload.horarioFlexivel))
      );
    }

    case types.SERVIDOR_DETALHE_PENSAO_SUCCESS: {
      return endLoad(state.set('dadosPensao', fromJS(action.payload)));
    }

    case types.CLEAR_DETALHE: {
      return endLoad(
        state
          .set('dadosFinanceiros', fromJS([]))
          .set('servidor', fromJS({}))
          .set('anotacoes', fromJS([]))
          .set('horarioFlexivel', fromJS([]))
      );
    }

    case types.SERVIDOR_VENCIMENTOS_DESCONTOS_SUCCESS: {
      return endLoad(state.set('vencimentosDescontos', fromJS(action.payload)));
    }

    case types.NATUREZAS_LIST_SUCCESS: {
      return endLoad(state.set('naturezas', fromJS(action.payload)));
    }

    case types.SERVIDOR_REGISTROS_PONTOS_SUCCESS: {
      return endLoad(state.set('registrosPontos', fromJS(action.payload)));
    }

    case types.SITUACOES_LIST_SUCCESS: {
      return endLoad(state.set('situacoes', fromJS(action.payload)));
    }

    case types.SERVIDORES_RESUMO_SUCCESS: {
      return endLoad(state.set('resumoNatureza', fromJS(action.payload)));
    }

    case types.ESTAGIARIOS_LIST_SUCCESS: {
      return endLoad(state.set('estagiarios', fromJS(action.payload)));
    }

    case types.ESTAGIARIO_DETALHE_SUCCESS: {
      return endLoad(
        state
          .set('estagiario', fromJS(action.payload))
          .set('dadosFinanceiros', fromJS(action.payload.dadosFinanceiros))
      );
    }

    case types.AUTONOMOS_LIST_SUCCESS: {
      return endLoad(state.set('autonomos', fromJS(action.payload)));
    }

    case types.AUTONOMO_DETALHE_SUCCESS: {
      return endLoad(
        state.set('autonomo', fromJS(action.payload))
        //.set('dadosFinanceiros', fromJS(action.payload.dadosFinanceiros))
      );
    }
  }

  return state;
}

export default servidorReducer;
