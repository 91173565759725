import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';

import * as types from './Types.js';

const initialState = fromJS({
  subvencoes: [],
  subvencao: {},
  valoresLiberados: [],
  prestacaoContas: [],
  aditivos: [],
  remuneracoes: [],
  anexos: [],
  tipoSubvencao: [],
  anexosCertidao: [],
  subvencaoArquivoSit: []
});

function participantesReducer(state = initialState, action) {
  switch (action.type) {
    case types.SUBVENCOES_START:
    case types.SUBVENCAO_START:
    case types.SUBVENCAO_VALORES_LIBERADOS_START:
    case types.SUBVENCAO_PRESTACAO_CONTAS_START:
    case types.SUBVENCAO_ADITIVOS_START:
    case types.SUBVENCAO_REMUNERACOES_START:
    case types.SUBVENCAO_ANEXOS_START:
    case types.CERTIDAO_ANEXOS_START:
    case types.TIPO_SUBVENCAO_START:
    case types.SUBVENCAO_ARQUIVO_SIT_START: {
      return startLoad(state);
    }

    case types.SUBVENCOES_SUCCESS: {
      return endLoad(state.set('subvencoes', fromJS(action.payload)));
    }
    case types.SUBVENCAO_SUCCESS: {
      return endLoad(state.set('subvencao', fromJS(action.payload)));
    }
    case types.SUBVENCAO_VALORES_LIBERADOS_SUCCESS: {
      return endLoad(state.set('valoresLiberados', fromJS(action.payload)));
    }
    case types.SUBVENCAO_PRESTACAO_CONTAS_SUCCESS: {
      return endLoad(state.set('prestacaoContas', fromJS(action.payload)));
    }
    case types.SUBVENCAO_ADITIVOS_SUCCESS: {
      return endLoad(state.set('aditivos', fromJS(action.payload)));
    }
    case types.SUBVENCAO_REMUNERACOES_SUCCESS: {
      return endLoad(state.set('remuneracoes', fromJS(action.payload)));
    }
    case types.SUBVENCAO_ANEXOS_SUCCESS: {
      return endLoad(state.set('anexos', fromJS(action.payload)));
    }
    case types.TIPO_SUBVENCAO_SUCCESS: {
      return endLoad(state.set('tipoSubvencao', fromJS(action.payload)));
    }
    case types.CERTIDAO_ANEXOS_SUCCESS: {
      return endLoad(state.set('anexosCertidao', fromJS(action.payload)));
    }
    case types.SUBVENCAO_ARQUIVO_SIT_SUCCESS: {
      return endLoad(state.set('subvencaoArquivoSit', fromJS(action.payload)));
    }

    case types.SUBVENCOES_ERROR:
    case types.SUBVENCAO_ERROR:
    case types.SUBVENCAO_VALORES_LIBERADOS_ERROR:
    case types.SUBVENCAO_PRESTACAO_CONTAS_ERROR:
    case types.SUBVENCAO_ADITIVOS_ERROR:
    case types.SUBVENCAO_REMUNERACOES_ERROR:
    case types.SUBVENCAO_ANEXOS_ERROR:
    case types.CERTIDAO_ANEXOS_ERROR:
    case types.TIPO_SUBVENCAO_ERROR:
    case types.SUBVENCAO_ARQUIVO_SIT_ERROR: {
      return endLoad(state);
    }
  }
  return state;
}

export default participantesReducer;
