import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import TableHeaderItem from 'portaltransparencia-common/components/TableHeaderItem.react';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export default class AdiantamentoDetalheDocumentos extends Component {
  static propTypes = {
    documentos: PropTypes.object.isRequired
  };

  renderItens = doc => {
    let key =
      doc.get('id') + '_' + doc.get('numero') + '_' + doc.get('fornecedor');

    return (
      <tr key={key} data={doc}>
        <td>{doc.get('numero')}</td>
        <td>{DateUtils.formatDateTimeShort(doc.get('data'))}</td>
        <td>{doc.get('tipoDocumentoFiscal').get('descricao')}</td>
        <td>{doc.get('tipoSerieDocumentoFiscal').get('descricao')}</td>
        <td className="right">
          <NumberFormatter value={doc.get('valor')} />
        </td>
      </tr>
    );
  };
  render() {
    const { documentos } = this.props;

    return (
      <div>
        {documentos && (
          <table className="panel-table striped fancy">
            <TableHeader empty={documentos.isEmpty()}>
              <tr>
                <TableHeaderItem
                  sortable={false}
                  field="numero"
                  label="Número"
                />
                <TableHeaderItem sortable={false} field="data" label="Data" />
                <TableHeaderItem
                  sortable={false}
                  field="descricao"
                  label="Tipo Documento Fiscal"
                />
                <TableHeaderItem
                  field="descricao"
                  label="Tipo Série"
                  sortable={false}
                />
                <TableHeaderItem
                  field="valor"
                  label="Valor"
                  sortable={false}
                  alignment="right"
                />
              </tr>
            </TableHeader>
            <tbody>{documentos.map(this.renderItens)}</tbody>
          </table>
        )}
      </div>
    );
  }
}
