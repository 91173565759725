import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';

import * as types from './Types.js';

const initialState = fromJS({
  comprasDireta: {},
  totais: {}
});

function ComprasDiretaReducer(state = initialState, action) {
  switch (action.type) {
    case types.FORNECEDOR_START:
    case types.FORNECEDORTOTAIS_START: {
      return startLoad(state);
    }

    case types.FORNECEDOR_ERROR:
    case types.FORNECEDORTOTAIS_ERROR: {
      return endLoad(state);
    }

    case types.FORNECEDOR_SUCCESS: {
      return endLoad(state.set('comprasDireta', fromJS(action.payload)));
    }
    case types.FORNECEDORTOTAIS_SUCCESS: {
      return endLoad(state.set('totais', fromJS(action.payload)));
    }
  }

  return state;
}

export default ComprasDiretaReducer;
