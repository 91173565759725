import { fromJS } from 'immutable';
import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export class LicitacaoDetalheCertidao extends Component {
  static propTypes = {
    certidoes: PropTypes.object.isRequired,
    onDownload: PropTypes.func.isRequired
  };

  mostrarArquivo = data => {
    const arquivos = [];

    if (data.get('idArquivo')) {
      arquivos.push(
        fromJS({
          id: data.get('idArquivo'),
          nome: data.get('nomeArquivo'),
          tamanho: data.get('tamanhoArquivo'),
          data: data.get('dataArquivo')
        })
      );
    }

    if (data.get('arquivos')?.count()) {
      arquivos.push(...data.get('arquivos'));
    }

    return (
      <td>
        {arquivos.length ? arquivos.map(this.renderArquivo) : 'Sem arquivo'}
      </td>
    );
  };

  renderArquivo = arquivo => {
    const { onDownload } = this.props;

    return (
      <div key={arquivo.get('id')} style={{ marginTop: '3px' }}>
        <a
          style={{ cursor: 'pointer' }}
          onClick={() => onDownload(arquivo.get('id'))}
          hidden={arquivo.length === 0}
        >
          <i className="fa fa-download" style={{ marginRight: '5px' }} />
          {arquivo.get('nome')}
        </a>
      </div>
    );
  };

  renderCertidoes = data => {
    const { onDownload } = this.props;
    const key =
      data.get('fornecedor') +
      '_' +
      data.get('tipoCertidao') +
      '_' +
      data.get('numeroDocumento') +
      '_' +
      data.get('');

    return (
      <TableActions
        ref="row"
        key={key}
        data={data}
        onDownload={data.get('idArquivo') && onDownload}
      >
        <td>{data.get('fornecedor')}</td>
        <td>{data.get('cnpjCpf')}</td>
        <td>{data.get('tipoCertidao')}</td>
        <td>{data.get('numeroDocumento')}</td>
        <td>{DateUtils.formatDateTimeShort(data.get('dataEmissao'))}</td>
        <td>{DateUtils.formatDateTimeShort(data.get('dataValidade'))}</td>
        {this.mostrarArquivo(data)}
      </TableActions>
    );
  };

  render() {
    const { certidoes } = this.props;

    return (
      <div className="panel-table-outer">
        <table className="panel-table striped fancy">
          <TableHeader empty={certidoes.isEmpty()}>
            <tr>
              <th>Fornecedor</th>
              <th>CNPJ/CPF</th>
              <th>Tipo Certidão</th>
              <th>Número do Documento</th>
              <th>Emissão</th>
              <th>Validade</th>
              <th>Arquivos</th>
              <th></th>
            </tr>
          </TableHeader>
          <tbody>{certidoes.map(this.renderCertidoes)}</tbody>
        </table>
      </div>
    );
  }
}

export default LicitacaoDetalheCertidao;
