import * as types from './Types.js';

import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';

import { fromJS } from 'immutable';

const initialState = fromJS({
  patrimonios: [],
  incorporacoes: [],
  desincorporacoes: [],
  detalhes: [],
  bem: {},
  empenhos: []
});

function patrimonioReducer(state = initialState, action) {
  switch (action.type) {
    case types.PATRIMONIOS_LIST_START:
    case types.PATRIMONIO_EMPENHO_START:
    case types.INCORPORACOES_LIST_START:
    case types.DESINCORPORACOES_LIST_START:
    case types.CONSULTA_PATRIMONIOS_START:
    case types.PATRIMONIOS_DETALHES_LIST_START:
    case types.PATRIMONIO_BEM_START: {
      return startLoad(state);
    }

    case types.PATRIMONIOS_LIST_ERROR:
    case types.PATRIMONIO_EMPENHO_ERROR:
    case types.INCORPORACOES_LIST_ERROR:
    case types.DESINCORPORACOES_LIST_ERROR:
    case types.CONSULTA_PATRIMONIOS_ERROR:
    case types.PATRIMONIOS_DETALHES_LIST_ERROR:
    case types.PATRIMONIO_BEM_ERROR: {
      return endLoad(state);
    }

    case types.PATRIMONIOS_LIST_SUCCESS: {
      return endLoad(state.set('patrimonios', fromJS(action.payload)));
    }
    case types.INCORPORACOES_LIST_SUCCESS: {
      return endLoad(state.set('incorporacoes', fromJS(action.payload)));
    }
    case types.DESINCORPORACOES_LIST_SUCCESS: {
      return endLoad(state.set('desincorporacoes', fromJS(action.payload)));
    }
    case types.CONSULTA_PATRIMONIOS_SUCCESS: {
      return endLoad(state.set('patrimonios', fromJS(action.payload)));
    }
    case types.PATRIMONIOS_DETALHES_LIST_SUCCESS: {
      return endLoad(state.set('detalhes', fromJS(action.payload)));
    }
    case types.PATRIMONIO_BEM_SUCCESS: {
      return endLoad(state.set('bem', fromJS(action.payload)));
    }
    case types.PATRIMONIO_EMPENHO_SUCCESS: {
      return endLoad(state.set('empenhos', fromJS(action.payload)));
    }

    case types.RESET_CONSULTA_PATRIMONIOS: {
      return initialState;
    }
  }

  return state;
}

export default patrimonioReducer;
