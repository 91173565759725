import { addHttp } from 'portaltransparencia-common/libs/LinkExterno.js';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import { getRoute } from '../menu/UrlAuxiliar';

export default class HomeMenuItem extends React.Component {
  static propTypes = {
    menuItem: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      classes: 'item'
    };
  }

  menuAreaEnter = () => {
    this.setState({
      classes: 'item active'
    });
  };

  menuAreaExit = () => {
    this.setState({
      classes: 'item'
    });
  };

  renderSubItem = subItem => {
    if (subItem.get('link')) {
      return (
        <li
          className="sub-item"
          key={subItem.get('id') + subItem.get('route') + subItem.get('title')}
          title={subItem.get('subtitle')}
        >
          <a href={addHttp(subItem.get('link'))} target="_blank">
            {subItem.get('title')}
          </a>
        </li>
      );
    }

    return (
      <li
        className="sub-item"
        key={`${subItem.get('route')}_${subItem.get('id')}_${subItem.get(
          'title'
        )}`}
        title={subItem.get('subtitle')}
      >
        <Link to={getRoute(subItem)}>{subItem.get('title')}</Link>
      </li>
    );
  };

  renderSubItens = () => {
    const { menuItem } = this.props;

    let subMenus = menuItem.get('subMenu');

    return <ul className="sub-itens">{subMenus.map(this.renderSubItem)}</ul>;
  };

  renderItemAligner = () => {
    const { menuItem } = this.props;
    const hasItems = !menuItem.get('subMenu').isEmpty();
    const menuItemToUse = menuItem;
    const title = menuItemToUse.get('title');
    const subtitle = menuItemToUse.get('subtitle');

    return (
      <div>
        <div className="item-aligner">
          <div className="item-aligner-inner">
            <em className={menuItemToUse.get('image')} />
            <span className="item-title">{title}</span>
            <em className="item-subtitle">{subtitle}</em>
            <button className="btn neutral center item-opener">Abrir</button>
          </div>
        </div>
        <span className="close-sub-itens" onClick={this.menuAreaExit} />
        {hasItems && this.renderSubItens()};
      </div>
    );
  };

  renderHomeMenu = () => {
    const { menuItem } = this.props;
    const hasItems = !menuItem.get('subMenu').isEmpty();
    const menuItemToUse = hasItems ? menuItem.getIn(['subMenu', 0]) : menuItem;
    const route = getRoute(menuItemToUse);
    const link = menuItemToUse.get('link');

    if (!hasItems && link) {
      return (
        <a id="linkMenu" href={addHttp(link)} target="_blank">
          {this.renderItemAligner()}
        </a>
      );
    } else if (!hasItems && menuItemToUse.has('route')) {
      return <Link to={route}>{this.renderItemAligner()}</Link>;
    } else if (
      hasItems ||
      (!menuItemToUse.has('route') && !menuItemToUse.has('link'))
    ) {
      return this.renderItemAligner();
    } else {
      /* eslint-disable no-console */
      const menuItemJs = menuItem.toJS();
      console.error('Não foi possível renderizar o item do menu: ', menuItemJs);
      /* eslint-enable no-console */
    }
  };

  render() {
    return (
      <li
        className={this.state.classes}
        ref="item"
        onMouseOver={this.menuAreaEnter}
        onMouseEnter={this.menuAreaEnter}
        onMouseOut={this.menuAreaExit}
        onMouseLeave={this.menuAreaExit}
      >
        {this.renderHomeMenu()}
      </li>
    );
  }
}
