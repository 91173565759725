import { List, Map } from 'immutable';
import Input from 'portaltransparencia-common/components/EloInput.react.js';
import ExportButtonGroup from 'portaltransparencia-common/components/ExportButtonGroup.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import Tab from 'portaltransparencia-common/components/Tab.react.js';
import Tabs from 'portaltransparencia-common/components/Tabs.react.js';
import configuration from 'portaltransparencia-common/config/Config.js';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import { Chart } from 'react-google-charts';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import PageHeader from '../components/PageHeader.react.js';
import NotasExplicativas from '../notaexplicativa/Form.react';
import UltimaAtualizacaoByTable from '../ultimaatualizacao/UltimaAtualizacaoByTable.react.js';
import * as actions from './Actions.js';
import DespesaListagem from './DespesaListagem.react';
import { addErrorNotification } from 'portaltransparencia-common/ui/Actions.js';

const nivelList = List([
  Map({ rota: 'orgao', descricao: 'Orgão', nivellei: 'O', tipo: 'P' }),
  Map({ rota: 'funcao', descricao: 'Função', nivellei: 'F', tipo: 'P' }),
  Map({ rota: 'programa', descricao: 'Programa', nivellei: 'P', tipo: 'P' }),
  Map({
    rota: 'projeto',
    descricao: 'Projeto Atividade',
    nivellei: 'PA',
    tipo: 'P'
  }),
  Map({ rota: 'elemento', descricao: 'Elemento', nivellei: 'N', tipo: 'C' }),
  Map({
    rota: 'fonte-recursos',
    descricao: 'Fonte Recursos',
    nivellei: 'R',
    tipo: 'R'
  }),
  Map({
    rota: 'esfera-administrativa',
    descricao: 'Esfera Administrativa',
    nivellei: 'E',
    tipo: 'E'
  })
]);

export class DespesaPorNivel extends Component {
  static propTypes = {
    params: PropTypes.object,
    orgao: PropTypes.object,
    funcao: PropTypes.object,
    programa: PropTypes.object,
    projeto: PropTypes.object,
    elemento: PropTypes.object,
    fonteRecurso: PropTypes.object,
    esferaAdmin: PropTypes.object,
    findDespesaPorNivel: PropTypes.func.isRequired,
    findDiafindDespesaPorFonteRecurso: PropTypes.func.isRequired,
    findDiafindDespesaPorEsferaAdministrativa: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    tipo: PropTypes.object.isRequired,
    changeTipo: PropTypes.func.isRequired,
    addErrorNotification: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      width: 400,
      height: 200,
      showGraph: false
    };
  }

  componentDidMount() {
    const {
      findDiafindDespesaPorFonteRecurso,
      findDiafindDespesaPorEsferaAdministrativa,
      tipo,
      changeTipo
    } = this.props;

    const node = ReactDOM.findDOMNode(this);

    changeTipo(tipo.get('valor'), tipo.get('label'));

    this.setState({
      width: node.clientWidth,
      height: node.clientHeight
    });

    findDiafindDespesaPorFonteRecurso();
    findDiafindDespesaPorEsferaAdministrativa();
    this.carregaDados(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.getNivel(this.props) !== this.getNivel(nextProps)) {
      this.carregaDados(nextProps);
    }
  }

  carregaDados = props => {
    const {
      findDespesaPorNivel,
      match: {
        params: { nivelDespesa }
      }
    } = props;

    let userInfo = StorageService.getItem('userInfo');
    const { entidade, exercicio } = userInfo;

    let nivel = this.getNivel(props);
    if (
      nivelDespesa !== 'fonte-recursos' &&
      nivelDespesa !== 'esfera-administrativa'
    ) {
      findDespesaPorNivel(
        entidade.id,
        exercicio.id,
        nivel.get('nivellei'),
        nivel.get('tipo')
      );
    }
  };

  getNivel = function (props) {
    const {
      match: {
        params: { nivelDespesa }
      }
    } = props;

    return nivelList.find(u => u.get('rota') === nivelDespesa);
  };

  getDespesa = function () {
    const {
      match: {
        params: { nivelDespesa }
      },
      fonteRecurso,
      esferaAdmin
    } = this.props;

    if (nivelDespesa == 'fonte-recursos') {
      return fonteRecurso;
    }
    if (nivelDespesa == 'esfera-administrativa') {
      return esferaAdmin;
    }
    return this.props[nivelDespesa];
  };

  onExporta = (exportType, openTab, windowParams) => {
    let userInfo = StorageService.getItem('userInfo');
    const { entidade, exercicio } = userInfo;
    const { apiURL } = configuration;
    const despesa = this.getDespesa();
    const { addErrorNotification } = this.props;

    if (despesa.size > 0) {
      const url = `${apiURL}/despesapornivel/fonte-recursos/report/${entidade.id}/${exercicio.id}/${exportType}`;

      window.open(url, openTab, windowParams);
    } else {
      addErrorNotification(
        'A consulta não apresentou resultado. Não é possível exportar para o formato selecionado!'
      );
    }
  };

  getOptions = () => {
    const despesas = this.getDespesa();

    const {
      match: {
        params: { nivelDespesa }
      }
    } = this.props;

    let { width, height, top, area, fontSize } = this.state;

    if (despesas.size > 100 && !this.controlaTamanho) {
      height = (window.innerHeight * despesas.size) / 20;
      top = '1%';
      area = '98%';
    } else if (despesas.size > 50 && !this.controlaTamanho) {
      height = (window.innerHeight * despesas.size) / 15;
      top = '3%';
      area = '95%';
    } else if (despesas.size > 40 && !this.controlaTamanho) {
      height = (window.innerHeight * despesas.size) / 15;
      top = '1%';
      area = '98%';
    } else if (despesas.size > 5 && !this.controlaTamanho) {
      height = (window.innerHeight * despesas.size) / 10;
      top = '5%';
      area = '92%';
    }

    this.controlaTamanho = this.showGraph;

    if (nivelDespesa == 'funcao') {
      fontSize = 15;
    } else {
      fontSize = 12;
    }

    return {
      bar: { groupWidth: '80%' },
      legend: { position: 'none' },
      width: width,
      height: height,
      annotations: {
        highContrast: true,
        textStyle: { fontSize: 13 }
      },
      hAxis: {
        textStyle: { fontSize: fontSize },
        minValue: 0
      },
      vAxis: {
        textStyle: { fontSize: fontSize }
      },
      chartArea: {
        top: top,
        height: area
      }
    };
  };

  onChange = (valor, label) => {
    return () => {
      const { changeTipo } = this.props;
      changeTipo(valor, label);
    };
  };

  getDataChart = (despesas, tipo) => {
    const {
      match: {
        params: { nivelDespesa }
      }
    } = this.props;

    const data = [[tipo.get('label'), 'Valor', { role: 'annotation' }]];

    if (nivelDespesa == 'esfera-administrativa') {
      return data.concat(
        despesas
          .map(item => {
            return [
              item.get('descricao'),
              item.get(tipo.get('valor')),
              item.get(tipo.get('valor'))
            ];
          })
          .toJS()
      );
    }

    return data.concat(
      despesas
        .map(item => {
          return [
            item.get('codigo') + ' - ' + item.get('descricao'),
            item.get(tipo.get('valor')),
            item.get(tipo.get('valor'))
          ];
        })
        .toJS()
    );
  };

  onTabClick = tab => {
    this.setState({ showGraph: tab.name === 'graficos' });
  };

  render() {
    const { tipo } = this.props;

    const nivel = this.getNivel(this.props);
    const despesa = this.getDespesa();

    const data = this.getDataChart(despesa, tipo);
    const options = this.getOptions(tipo);

    const { showGraph } = this.state;

    return (
      <section>
        <PageHeader location={this.props.location} />

        <NotasExplicativas pathname={this.props.location.pathname} />

        <Panel isForm expansible={false} tableResponsive>
          <Row>
            <Col xs={6}>
              <UltimaAtualizacaoByTable
                modulo="3"
                table="despesa"
                column="dataultimaatualizacao"
              />
            </Col>
            <Col xs={6}>
              <ExportButtonGroup onClick={this.onExporta} visible={true} />
            </Col>
          </Row>

          <Tabs ref="tab" onTabClick={this.onTabClick}>
            <Tab title="Listagem" name="listagem" onlyTable active>
              <DespesaListagem
                despesas={despesa}
                nivel={nivel}
                history={this.props.history}
              />
            </Tab>

            <Tab title={'Gráfico'} name="graficos" onlyTable>
              {despesa.size > 0 && showGraph && (
                <div className="hidden-print">
                  <div
                    className="radio-check-group"
                    style={{ textAlign: 'center' }}
                  >
                    <Input
                      label="Valor Orçado"
                      value="valorOrcado"
                      type="radio"
                      name="valor"
                      onChange={this.onChange('valorOrcado', 'Valor Orçado')}
                      checked={tipo.get('valor') == 'valorOrcado'}
                    />
                    <Input
                      label="Valor Empenhado"
                      value="valorEmpenhado"
                      type="radio"
                      name="valor"
                      onChange={this.onChange(
                        'valorEmpenhado',
                        'Valor Empenhado'
                      )}
                      checked={tipo.get('valor') == 'valorEmpenhado'}
                    />
                    <Input
                      label="Valor Liquidado"
                      value="valorLiquidado"
                      type="radio"
                      name="valor"
                      onChange={this.onChange(
                        'valorLiquidado',
                        'Valor Liquidado'
                      )}
                      checked={tipo.get('valor') == 'valorLiquidado'}
                    />
                    <Input
                      label="Valor Retido"
                      value="valorRetido"
                      type="radio"
                      name="valor"
                      onChange={this.onChange('valorRetido', 'Valor Retido')}
                      checked={tipo.get('valor') == 'valorRetido'}
                    />
                    <Input
                      label="Valor Pago"
                      value="valorPago"
                      type="radio"
                      name="valor"
                      onChange={this.onChange('valorPago', 'Valor Pago')}
                      checked={tipo.get('valor') == 'valorPago'}
                    />
                  </div>
                  <div style={{ marginLeft: -20 + 'px' }}>
                    <Chart
                      ref="chart"
                      chartType="BarChart"
                      data={data}
                      options={options}
                      graphID="despesa-bar-chart"
                      width={`${options.width}px`}
                      height={`${options.height}px`}
                      chartLanguage="pt-br"
                    />
                  </div>
                </div>
              )}
              {despesa.size == 0 && (
                <div className="info">
                  Não foi possível carregar o gráfico :(
                </div>
              )}
            </Tab>
          </Tabs>
        </Panel>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    orgao: state.despesaPorNivel.get('orgao'),
    funcao: state.despesaPorNivel.get('funcao'),
    programa: state.despesaPorNivel.get('programa'),
    projeto: state.despesaPorNivel.get('projeto'),
    elemento: state.despesaPorNivel.get('elemento'),
    fonteRecurso: state.despesaPorNivel.get('fonteRecurso'),
    esferaAdmin: state.despesaPorNivel.get('esferaAdmin'),
    tipo: state.despesaPorNivel.get('tipo')
  };
}

const allActions = {
  ...actions,
  addErrorNotification
};

export default connect(mapStateToProps, allActions)(DespesaPorNivel);
