import Modal from 'portaltransparencia-common/components/Modal.react.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

import GastoPneuTable from './GastoPneuTable.react';

export class GastoPneuModal extends Component {
  static propTypes = {
    gastosPneu: PropTypes.object.isRequired,
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
  };

  render() {
    const { gastosPneu, show, onClose } = this.props;

    if (show) {
      return (
        <Modal onClose={onClose}>
          <GastoPneuTable gastosPneu={gastosPneu} />
        </Modal>
      );
    }

    return null;
  }
}

export default GastoPneuModal;
