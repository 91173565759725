import * as types from './Types.js';

export function findHorasExtras(mesCompetencia) {
  return ({ fetch }) => ({
    type: types.HORAS_EXTRAS,
    payload: {
      promise: fetch(
        `/api/servidores/hora-extra?mesCompetencia=${mesCompetencia}`
      )
    }
  });
}

export function findDetalhesHorasExtras(mesCompetencia, secretaria) {
  return ({ fetch }) => ({
    type: types.HORAS_EXTRAS_DETALHES,
    payload: {
      promise: fetch(
        `/api/servidores/hora-extra/detalhes?mesCompetencia=${mesCompetencia}&secretaria=${secretaria}`
      )
    }
  });
}
