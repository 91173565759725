import { Map } from 'immutable';
import DatePicker from 'portaltransparencia-common/components/DatePicker.react.js';
import Input from 'portaltransparencia-common/components/EloInput.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import SearchPagination from 'portaltransparencia-common/components/SearchPagination.react.js';
import Validation from 'portaltransparencia-common/components/Validation.js';
import {
  onChange,
  resetCrud
} from 'portaltransparencia-common/crud/Actions.js';
import {
  addFilterUrl,
  addPagination
} from 'portaltransparencia-common/libs/AddFilter.js';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import FilterButtons from '../components/FilterButtons.react';
import PageHeader from '../components/PageHeader.react.js';
import NotasExplicativas from '../notaexplicativa/Form.react';
import * as actions from './Actions.js';
import SearchResult from './SearchResult.react.js';

export class FormEstoque extends Component {
  static propTypes = {
    loadCentrosCusto: PropTypes.func.isRequired,
    loadOrgao: PropTypes.func.isRequired,
    pesquisaItens: PropTypes.func.isRequired,
    resetItens: PropTypes.func.isRequired,
    resetCrud: PropTypes.func.isRequired,
    page: PropTypes.object.isRequired,
    errors: PropTypes.object,
    validate: PropTypes.func,
    handleError: PropTypes.func,
    filtros: PropTypes.object,
    onChange: PropTypes.func,
    location: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      orgaos: [],
      centrosCustos: []
    };
  }

  componentDidMount() {
    const { loadOrgao, pesquisaItens } = this.props;

    loadOrgao().then(({ value }) => this.setState({ orgaos: value }));
    pesquisaItens('');
  }

  onChangeFiltro = event => {
    const { onChange } = this.props;

    onChange('filtroItensEstoque', event);
  };

  onPesquisaItens = event => {
    event && event.preventDefault();

    const { validate, handleError } = this.props;

    validate(error => {
      if (handleError(this, error)) {
        return;
      }

      const { page } = this.props;
      const initialPage = page.set('number', 0);

      this.searchWithPage(initialPage);
      this.refs.panelFiltroAlmoxarifado &&
        this.refs.panelFiltroAlmoxarifado.collapse();
    });
  };

  onResetFiltro = () => {
    const { resetItens, resetCrud } = this.props;
    resetCrud('filtroItensEstoque');
    resetItens();
  };

  searchWithPage = page => {
    const { pesquisaItens } = this.props;

    const filter = this.montaFilter(page);

    pesquisaItens(filter);
  };

  montaFilter = function (page) {
    let userInfo = StorageService.getItem('userInfo');
    const { entidade, exercicio } = userInfo;

    let filter = 'entidade=' + entidade.id + '&exercicio=' + exercicio.id;
    const { filtros } = this.props;

    if (filtros.get('data') !== undefined && filtros.get('data').length == 10) {
      filter = addFilterUrl(filter, `data=${filtros.get('data')}`);
    }
    if (filtros.get('orgao') !== undefined) {
      filter = addFilterUrl(filter, 'orgao=' + filtros.getIn(['orgao']));
    }
    if (filtros && filtros.get('centroCusto')) {
      filter = addFilterUrl(
        filter,
        'centroCusto=' + filtros.getIn(['centroCusto'])
      );
    }
    if (filtros && filtros.get('produto')) {
      filter = addFilterUrl(filter, 'itemDescricao=' + filtros.get('produto'));
    }

    return addPagination(page, filter);
  };

  getValidatorData = () => {
    const { filtros } = this.props;
    return { orgao: filtros.get('orgao') };
  };

  renderCentrosCusto = data => {
    const key =
      data.getIn(['id', 'entidade']) + '_' + data.getIn(['id', 'centroCusto']);

    return (
      <option key={key} value={data.getIn(['id', 'centroCusto'])}>
        {data.get('descricao')}
      </option>
    );
  };

  renderValue = option => {
    return <span>{`${option.id.centroCusto} - ${option.descricao}`}</span>;
  };

  render() {
    const {
      loadCentrosCusto,
      loadOrgao,
      page,
      errors,
      filtros,
      history
    } = this.props;

    const filter = this.montaFilter(page);

    const { orgaos, centrosCustos } = this.state;

    return (
      <section>
        <PageHeader location={this.props.location} />

        <NotasExplicativas pathname={this.props.location.pathname} />

        <Panel
          title="Posição de Estoque por Período"
          isForm
          ref="panelFiltroAlmoxarifado"
        >
          <form>
            <Row>
              <Col sm={6}>
                <DatePicker
                  id="data"
                  name="data"
                  ref="data"
                  label="Data"
                  placeholder="Data da Posição de Estoque"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('data')}
                  errors={errors}
                />
              </Col>
              <Col sm={6}>
                <Input
                  id="orgao"
                  name="orgao"
                  ref="orgao"
                  placeholder="Selecione o Órgão"
                  label="Órgão"
                  type="select"
                  value={filtros.get('orgao')}
                  onChange={event => {
                    this.onChangeFiltro(event);

                    loadCentrosCusto(event.target.value).then(({ value }) => {
                      this.setState({ centrosCustos: value });
                    });
                  }}
                >
                  <option key="" value=""></option>
                  {orgaos.map(value => {
                    return (
                      <option key={value.orgao} value={value.orgao}>
                        {`${value.orgao} - ${value.descricao}`}
                      </option>
                    );
                  })}
                </Input>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Input
                  id="centroCusto"
                  name="centroCusto"
                  ref="centroCusto"
                  placeholder="Selecione o Centro de Custo"
                  label="Centro de Custo"
                  type="select"
                  value={filtros.get('centroCusto')}
                  onChange={this.onChangeFiltro}
                >
                  <option key="" value=""></option>
                  {centrosCustos.map(value => {
                    return (
                      <option key={value.centroCusto} value={value.centroCusto}>
                        {`${value.centroCusto} - ${value.descricao}`}
                      </option>
                    );
                  })}
                </Input>
              </Col>
              <Col sm={6}>
                <Input
                  label="Produto"
                  type="text"
                  ref="produto"
                  name="produto"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('produto')}
                  id="produto"
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <FilterButtons
                  onConsulta={this.onPesquisaItens}
                  onReset={this.onResetFiltro}
                />
              </Col>
            </Row>
          </form>
        </Panel>

        <SearchResult
          filter={filter}
          itensEstoque={page}
          searchWithPage={this.searchWithPage}
          history={history}
        />

        <SearchPagination page={page} searchWithPage={this.searchWithPage} />
      </section>
    );
  }
}

const validatorTypes = {
  rules: {
    orgao: 'required'
  }
};

function mapStateToProps(state) {
  return {
    page: state.almoxarifado.get('itensEstoque'),
    filtros: state.crud.getIn(['filtroItensEstoque', 'currentRecord'], Map({}))
  };
}

const allActions = {
  ...actions,
  onChange,
  resetCrud
};

FormEstoque = Validation(validatorTypes)(FormEstoque);

export default connect(mapStateToProps, allActions)(FormEstoque);
