import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';

import * as types from './Types.js';

const initialState = fromJS({
  leilgpd: {},
  responsavelLGPD: {},
  arquivoLGPD: {}
});

function leilgpdReducer(state = initialState, action) {
  switch (action.type) {
    case types.ARQUIVO_LGPD_START:
    case types.LEILGPD_START:
    case types.RESPONSAVEL_LGPD_START: {
      return startLoad(state);
    }

    case types.ARQUIVO_LGPD_ERROR:
    case types.LEILGPD_ERROR:
    case types.RESPONSAVEL_LGPD_ERROR: {
      return endLoad(state);
    }

    case types.LEILGPD_SUCCESS: {
      return endLoad(state.set('leilgpd', fromJS(action.payload)));
    }

    case types.ARQUIVO_LGPD_SUCCESS: {
      return endLoad(state.set('arquivoLGPD', fromJS(action.payload)));
    }

    case types.RESPONSAVEL_LGPD_SUCCESS: {
      return endLoad(state.set('responsavelLGPD', fromJS(action.payload)));
    }
  }

  return state;
}

export default leilgpdReducer;
