import Component from 'react-pure-render/component';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import LinkToEmpenho from '../empenhos/LinkToEmpenho.react';
import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react';
import PropTypes from 'prop-types';
import React from 'react';
import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react.js';

export class ObraDetalheEmpenho extends Component {
  static propTypes = {
    empenhos: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object,
    entidade: PropTypes.number.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      showImagens: false
    };
  }

  onViewDetalhes = item => {
    const { entidade } = this.props;
    const exercicio = item.get('exercicio');
    const empenho = item.get('numeroEmpenho');

    this.props.history.push(
      `/empenhos/detalhe?search=id.entidade==${entidade}&entidade=${entidade}&exercicio=${exercicio}&empenho=${empenho}`
    );
  };

  renderEmpenhos = data => {
    const { entidade } = this.props;
    const key =
      entidade + '_' + data.get('exercicio') + '_' + data.get('numeroEmpenho');

    return (
      <TableActions
        ref="row"
        key={key}
        data={data}
        onMore={this.onViewDetalhes}
      >
        <td>
          <LinkToEmpenho
            entidade={entidade}
            exercicio={data.get('exercicio')}
            empenho={data.get('numeroEmpenho')}
          />
        </td>
        <td>{DateUtils.formatDateTimeShort(data.get('data'))}</td>
        <td>{data.get('nomeCredor')}</td>
        <td>
          <NumberFormatter value={data.get('valor')} />
        </td>
      </TableActions>
    );
  };

  render() {
    const { empenhos } = this.props;

    return (
      <section>
        <div className="panel-table-outer">
          <table className="panel-table striped fancy">
            <TableHeader empty={empenhos.isEmpty()}>
              <tr>
                <th>Empenho/Ano</th>
                <th>Data</th>
                <th>Fornecedor</th>
                <th>Valor</th>
                <th></th>
              </tr>
            </TableHeader>
            <tbody>{empenhos.map(this.renderEmpenhos)}</tbody>
          </table>
        </div>
      </section>
    );
  }
}

export default ObraDetalheEmpenho;
