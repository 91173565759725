export const AJUDA_CUSTO_SERVIDORES = 'AJUDA_CUSTO_SERVIDORES';
export const AJUDA_CUSTO_SERVIDORES_START = 'AJUDA_CUSTO_SERVIDORES_START';
export const AJUDA_CUSTO_SERVIDORES_SUCCESS = 'AJUDA_CUSTO_SERVIDORES_SUCCESS';
export const AJUDA_CUSTO_SERVIDORES_ERROR = 'AJUDA_CUSTO_SERVIDORES_ERROR';

export const AJUDA_CUSTO_SERVIDOR = 'AJUDA_CUSTO_SERVIDOR';
export const AJUDA_CUSTO_SERVIDOR_START = 'AJUDA_CUSTO_SERVIDOR_START';
export const AJUDA_CUSTO_SERVIDOR_SUCCESS = 'AJUDA_CUSTO_SERVIDOR_SUCCESS';
export const AJUDA_CUSTO_SERVIDOR_ERROR = 'AJUDA_CUSTO_SERVIDOR_ERROR';

export const AJUDAS_CUSTO = 'AJUDAS_CUSTO';
export const AJUDAS_CUSTO_START = 'AJUDAS_CUSTO_START';
export const AJUDAS_CUSTO_SUCCESS = 'AJUDAS_CUSTO_SUCCESS';
export const AJUDAS_CUSTO_ERROR = 'AJUDAS_CUSTO_ERROR';
