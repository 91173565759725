export const EMPENHOS = 'EMPENHOS';
export const EMPENHOS_START = 'EMPENHOS_START';
export const EMPENHOS_SUCCESS = 'EMPENHOS_SUCCESS';
export const EMPENHOS_ERROR = 'EMPENHOS_ERROR';

export const EMPENHODETALHE = 'EMPENHODETALHE';
export const EMPENHODETALHE_START = 'EMPENHODETALHE_START';
export const EMPENHODETALHE_SUCCESS = 'EMPENHODETALHE_SUCCESS';
export const EMPENHODETALHE_ERROR = 'EMPENHODETALHE_ERROR';

export const EMPENHOSTOTAIS = 'EMPENHOSTOTAIS';
export const EMPENHOSTOTAIS_START = 'EMPENHOSTOTAIS_START';
export const EMPENHOSTOTAIS_SUCCESS = 'EMPENHOSTOTAIS_SUCCESS';
export const EMPENHOSTOTAIS_ERROR = 'EMPENHOSTOTAIS_ERROR';

export const EMPENHODETALHEITENS = 'EMPENHODETALHEITENS';
export const EMPENHODETALHEITENS_START = 'EMPENHODETALHEITENS_START';
export const EMPENHODETALHEITENS_SUCCESS = 'EMPENHODETALHEITENS_SUCCESS';
export const EMPENHODETALHEITENS_ERROR = 'EMPENHODETALHEITENS_ERROR';

export const EMPENHODETALHEITEM = 'EMPENHODETALHEITEM';
export const EMPENHODETALHEITEM_START = 'EMPENHODETALHEITEM_START';
export const EMPENHODETALHEITEM_SUCCESS = 'EMPENHODETALHEITEM_SUCCESS';
export const EMPENHODETALHEITEM_ERROR = 'EMPENHODETALHEITEM_ERROR';

export const EMPENHODETALHEANULACOES = 'EMPENHODETALHEANULACOES';
export const EMPENHODETALHEANULACOES_START = 'EMPENHODETALHEANULACOES_START';
export const EMPENHODETALHEANULACOES_SUCCESS =
  'EMPENHODETALHEANULACOES_SUCCESS';
export const EMPENHODETALHEANULACOES_ERROR = 'EMPENHODETALHEANULACOES_ERROR';

export const EMPENHODETALHELIQUIDACOES_RESET =
  'EMPENHODETALHELIQUIDACOES_RESET';
export const EMPENHODETALHELIQUIDACOES_RESET_START =
  'EMPENHODETALHELIQUIDACOES_RESET_START';
export const EMPENHODETALHELIQUIDACOES_RESET_SUCCESS =
  'EMPENHODETALHELIQUIDACOES_RESET_SUCCESS';
export const EMPENHODETALHELIQUIDACOES_RESET_ERROR =
  'EMPENHODETALHELIQUIDACOES_MULTIPLAS_ERROR';

export const EMPENHODETALHELIQUIDACOES_MULTIPLAS =
  'EMPENHODETALHELIQUIDACOES_MULTIPLAS';
export const EMPENHODETALHELIQUIDACOES_MULTIPLAS_START =
  'EMPENHODETALHELIQUIDACOES_MULTIPLAS_START';
export const EMPENHODETALHELIQUIDACOES_MULTIPLAS_SUCCESS =
  'EMPENHODETALHELIQUIDACOES_MULTIPLAS_SUCCESS';
export const EMPENHODETALHELIQUIDACOES_MULTIPLAS_ERROR =
  'EMPENHODETALHELIQUIDACOES_MULTIPLAS_ERROR';

export const EMPENHODETALHELIQUIDACOES = 'EMPENHODETALHELIQUIDACOES';
export const EMPENHODETALHELIQUIDACOES_START =
  'EMPENHODETALHELIQUIDACOES_START';
export const EMPENHODETALHELIQUIDACOES_SUCCESS =
  'EMPENHODETALHELIQUIDACOES_SUCCESS';
export const EMPENHODETALHELIQUIDACOES_ERROR =
  'EMPENHODETALHELIQUIDACOES_ERROR';

export const EMPENHODETALHERETENCOES = 'EMPENHODETALHERETENCOES';
export const EMPENHODETALHERETENCOES_START = 'EMPENHODETALHERETENCOES_START';
export const EMPENHODETALHERETENCOES_SUCCESS =
  'EMPENHODETALHERETENCOES_SUCCESS';
export const EMPENHODETALHERETENCOES_ERROR = 'EMPENHODETALHERETENCOES_ERROR';

export const EMPENHODETALHELIQUIDACOESDOCUMENTOS =
  'EMPENHODETALHELIQUIDACOESDOCUMENTOS';
export const EMPENHODETALHELIQUIDACOESDOCUMENTOS_START =
  'EMPENHODETALHELIQUIDACOESDOCUMENTOS_START';
export const EMPENHODETALHELIQUIDACOESDOCUMENTOS_SUCCESS =
  'EMPENHODETALHELIQUIDACOESDOCUMENTOS_SUCCESS';
export const EMPENHODETALHELIQUIDACOESDOCUMENTOS_ERROR =
  'EMPENHODETALHELIQUIDACOESDOCUMENTOS_ERROR';

export const EMPENHODETALHELIQUIDACOESDOCUMENTOS_RESET =
  'EMPENHODETALHELIQUIDACOESDOCUMENTOS_RESET';
export const EMPENHODETALHELIQUIDACOESDOCUMENTOS_RESET_START =
  'EMPENHODETALHELIQUIDACOESDOCUMENTOS_RESET_START';
export const EMPENHODETALHELIQUIDACOESDOCUMENTOS_RESET_SUCCESS =
  'EMPENHODETALHELIQUIDACOESDOCUMENTOS_RESET_SUCCESS';
export const EMPENHODETALHELIQUIDACOESDOCUMENTOS_RESET_ERROR =
  'EMPENHODETALHELIQUIDACOESDOCUMENTOS_ERROR';

export const EMPENHODETALHELIQUIDACOESDOCUMENTOS_MULTIPLO =
  'EMPENHODETALHELIQUIDACOESDOCUMENTOS_MULTIPLO';
export const EMPENHODETALHELIQUIDACOESDOCUMENTOS_MULTIPLO_START =
  'EMPENHODETALHELIQUIDACOESDOCUMENTOS_MULTIPLO_START';
export const EMPENHODETALHELIQUIDACOESDOCUMENTOS_MULTIPLO_SUCCESS =
  'EMPENHODETALHELIQUIDACOESDOCUMENTOS_MULTIPLO_SUCCESS';
export const EMPENHODETALHELIQUIDACOESDOCUMENTOS_MULTIPLO_ERROR =
  'EMPENHODETALHELIQUIDACOESDOCUMENTOS_ERROR';

export const EMPENHODETALHEANEXOS = 'EMPENHODETALHEANEXOS';
export const EMPENHODETALHEANEXOS_START = 'EMPENHODETALHEANEXOS_START';
export const EMPENHODETALHEANEXOS_SUCCESS = 'EMPENHODETALHEANEXOS_SUCCESS';
export const EMPENHODETALHEANEXOS_ERROR = 'EMPENHODETALHEANEXOS_ERROR';

export const EMPENHODETALHEPAGAMENTOS = 'EMPENHODETALHEPAGAMENTOS';
export const EMPENHODETALHEPAGAMENTOS_START = 'EMPENHODETALHEPAGAMENTOS_START';
export const EMPENHODETALHEPAGAMENTOS_SUCCESS =
  'EMPENHODETALHEPAGAMENTOS_SUCCESS';
export const EMPENHODETALHEPAGAMENTOS_ERROR = 'EMPENHODETALHEPAGAMENTOS_ERROR';

export const EMPENHODETALHEMOVIMENTACAO = 'EMPENHODETALHEMOVIMENTACAO';
export const EMPENHODETALHEMOVIMENTACAO_START =
  'EMPENHODETALHEMOVIMENTACAO_START';
export const EMPENHODETALHEMOVIMENTACAO_SUCCESS =
  'EMPENHODETALHEMOVIMENTACAO_SUCCESS';
export const EMPENHODETALHEMOVIMENTACAO_ERROR =
  'EMPENHODETALHEMOVIMENTACAO_ERROR';

export const PROGRAMATICAORGAO = 'PROGRAMATICAORGAO';
export const PROGRAMATICAORGAO_START = 'PROGRAMATICAORGAO_START';
export const PROGRAMATICAORGAO_SUCCESS = 'PROGRAMATICAORGAO_SUCCESS';
export const PROGRAMATICAORGAO_ERROR = 'PROGRAMATICAORGAO_ERROR';

export const PROGRAMATICAUNIDADE = 'PROGRAMATICAUNIDADE';
export const PROGRAMATICAUNIDADE_START = 'PROGRAMATICAUNIDADE_';
export const PROGRAMATICAUNIDADE_SUCCESS = 'PROGRAMATICAUNIDADE_SUCCESS';
export const PROGRAMATICAUNIDADE_ERROR = 'PROGRAMATICAUNIDADE_ERROR';

export const PROGRAMATICAFUNCAO = 'PROGRAMATICAFUNCAO';
export const PROGRAMATICAFUNCAO_START = 'PROGRAMATICAFUNCAO_START';
export const PROGRAMATICAFUNCAO_SUCCESS = 'PROGRAMATICAFUNCAO_SUCCESS';
export const PROGRAMATICAFUNCAO_ERROR = 'PROGRAMATICAFUNCAO_ERROR';

export const PROGRAMATICASUBFUNCAO = 'PROGRAMATICASUBFUNCAO';
export const PROGRAMATICASUBFUNCAO_START = 'PROGRAMATICASUBFUNCAO_START';
export const PROGRAMATICASUBFUNCAO_SUCCESS = 'PROGRAMATICASUBFUNCAO_SUCCESS';
export const PROGRAMATICASUBFUNCAO_ERROR = 'PROGRAMATICASUBFUNCAO_ERROR';

export const PROGRAMATICAPROGRAMA = 'PROGRAMATICAPROGRAMA';
export const PROGRAMATICAPROGRAMA_START = 'PROGRAMATICAPROGRAMA_START';
export const PROGRAMATICAPROGRAMA_SUCCESS = 'PROGRAMATICAPROGRAMA_SUCCESS';
export const PROGRAMATICAPROGRAMA_ERROR = 'PROGRAMATICAPROGRAMA_ERROR';

export const PROGRAMATICAPROJETO = 'PROGRAMATICAPROJETO';
export const PROGRAMATICAPROJETO_START = 'PROGRAMATICAPROJETO_START';
export const PROGRAMATICAPROJETO_SUCCESS = 'PROGRAMATICAPROJETO_SUCCESS';
export const PROGRAMATICAPROJETO_ERROR = 'PROGRAMATICAPROJETO_ERROR';

export const PROGRAMATICAELEMENTO = 'PROGRAMATICAELEMENTO';
export const PROGRAMATICAELEMENTO_START = 'PROGRAMATICAELEMENTO_START';
export const PROGRAMATICAELEMENTO_SUCCESS = 'PROGRAMATICAELEMENTO_SUCCESS';
export const PROGRAMATICAELEMENTO_ERROR = 'PROGRAMATICAELEMENTO_ERROR';

export const PROGRAMATICADESDOBRAMENTO = 'PROGRAMATICADESDOBRAMENTO';
export const PROGRAMATICADESDOBRAMENTO_START =
  'PROGRAMATICADESDOBRAMENTO_START';
export const PROGRAMATICADESDOBRAMENTO_SUCCESS =
  'PROGRAMATICADESDOBRAMENTO_SUCCESS';
export const PROGRAMATICADESDOBRAMENTO_ERROR =
  'PROGRAMATICADESDOBRAMENTO_ERROR';

export const PROGRAMATICASUBDESDOBRAMENTO = 'PROGRAMATICASUBDESDOBRAMENTO';
export const PROGRAMATICASUBDESDOBRAMENTO_START =
  'PROGRAMATICASUBDESDOBRAMENTO_START';
export const PROGRAMATICASUBDESDOBRAMENTO_SUCCESS =
  'PROGRAMATICASUBDESDOBRAMENTO_SUCCESS';
export const PROGRAMATICASUBDESDOBRAMENTO_ERROR =
  'PROGRAMATICASUBDESDOBRAMENTO_ERROR';

export const PROGRAMATICAFONTERECURSO = 'PROGRAMATICAFONTERECURSO';
export const PROGRAMATICAFONTERECURSO_START = 'PROGRAMATICAFONTERECURSO_START';
export const PROGRAMATICAFONTERECURSO_SUCCESS =
  'PROGRAMATICAFONTERECURSO_SUCCESS';
export const PROGRAMATICAFONTERECURSO_ERROR = 'PROGRAMATICAFONTERECURSO_ERROR';

export const EMPENHO_DETALHE_EM_LIQUIDACAO = 'EMPENHO_DETALHE_EM_LIQUIDACAO';
export const EMPENHO_DETALHE_EM_LIQUIDACAO_START =
  'EMPENHO_DETALHE_EM_LIQUIDACAO_START';
export const EMPENHO_DETALHE_EM_LIQUIDACAO_SUCCESS =
  'EMPENHO_DETALHE_EM_LIQUIDACAO_SUCCESS';
export const EMPENHO_DETALHE_EM_LIQUIDACAO_ERROR =
  'EMPENHO_DETALHE_EM_LIQUIDACAO_ERROR';

export const NOTAEMPENHO = 'NOTAEMPENHO';
export const NOTAEMPENHO_START = 'NOTAEMPENHO_START';
export const NOTAEMPENHO_SUCCESS = 'NOTAEMPENHO_SUCCESS';
export const NOTAEMPENHO_ERROR = 'NOTAEMPENHO_ERROR';

export const RESET_EMPENHO = 'RESET_EMPENHO';
export const FILTROLISTAGEM_EMPENHO = 'FILTROLISTAGEM_EMPENHO';

export const EMPENHODETALHELINKS = 'EMPENHODETALHELINKS';
export const EMPENHODETALHELINKS_START = 'EMPENHODETALHELINKS_START';
export const EMPENHODETALHELINKS_SUCCESS = 'EMPENHODETALHELINKS_SUCCESS';
export const EMPENHODETALHELINKS_ERROR = 'EMPENHODETALHELINKS_ERROR';
