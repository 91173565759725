import ReactDOM from 'react-dom';
import ValidationError from './ValidationError.js';

function findTopmostComponent(node, fields) {
  let topmost;
  for (let key in fields) {
    const current = node.querySelector(`[name=${key}]`);
    if (current) {
      if (!topmost) {
        topmost = current;
      } else if (
        current.getBoundingClientRect().top <
        topmost.getBoundingClientRect().top
      ) {
        topmost = current;
      }
    }
  }
  return topmost;
}

export default function focusInvalidField(component, error) {
  if (error instanceof ValidationError) {
    return focusByValidationError(component, error);
  }

  if (error.length === 0) return;

  const node = ReactDOM.findDOMNode(component);
  if (!node) return;

  const el = findTopmostComponent(node, error);

  return focus(el);
}

function focusByValidationError(component, error) {
  if (!error.params.prop) return;
  const node = ReactDOM.findDOMNode(component);
  if (!node) return;
  const el = node.querySelector(`[name=${error.params.prop}]`);

  return focus(el);
}

function focus(el) {
  if (!el) {
    return false;
  }

  el.focus();

  return true;
}
