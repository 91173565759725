export const DIVIDAS_ATIVAS = 'DIVIDAS_ATIVAS';
export const DIVIDAS_ATIVAS_START = 'DIVIDAS_ATIVAS_START';
export const DIVIDAS_ATIVAS_SUCCESS = 'DIVIDAS_ATIVAS_SUCCESS';
export const DIVIDAS_ATIVAS_ERROR = 'DIVIDAS_ATIVAS_ERROR';

export const DIVIDAS_ATIVAS_CONTRIBUINTE = 'DIVIDAS_ATIVAS_CONTRIBUINTE';
export const DIVIDAS_ATIVAS_CONTRIBUINTE_START =
  'DIVIDAS_ATIVAS_CONTRIBUINTE_START';
export const DIVIDAS_ATIVAS_CONTRIBUINTE_SUCCESS =
  'DIVIDAS_ATIVAS_CONTRIBUINTE_SUCCESS';
export const DIVIDAS_ATIVAS_CONTRIBUINTE_ERROR =
  'DIVIDAS_ATIVAS_CONTRIBUINTE_ERROR';

export const DIVIDAS_ATIVAS_ULTIMA_ATUALIZACAO_START =
  'DIVIDAS_ATIVAS_ULTIMA_ATUALIZACAO_START';
export const DIVIDAS_ATIVAS_ULTIMA_ATUALIZACAO_ERROR =
  'DIVIDAS_ATIVAS_ULTIMA_ATUALIZACAO_ERROR';
export const DIVIDAS_ATIVAS_TOTAL = 'DIVIDAS_ATIVAS_TOTAL';
