import Component from 'react-pure-render/component';
import React from 'react';
import { Link } from 'react-router-dom';

export default class NotFound extends Component {
  render() {
    return (
      <div className="not-found">
        <h1>
          <em className="fa fa-frown-o"></em>
        </h1>
        <h2>Desculpe, não conseguimos encontrar o que você está procurando.</h2>
        <small>404</small>
        <Link to="/">
          <button className="btn module-color icon-right center mt-xs">
            Volte ao Início <em className="fa fa-home"></em>
          </button>
        </Link>
      </div>
    );
  }
}
