import DisplayData from 'portaltransparencia-common/components/displaydata/DisplayData.react.js';
import DisplayDataItem from 'portaltransparencia-common/components/displaydata/DisplayDataItem.react.js';
import DisplayDataRow from 'portaltransparencia-common/components/displaydata/DisplayDataRow.react.js';
import NumberUtils from 'portaltransparencia-common/libs/NumberUtils.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export default class AvaliacaoPpaTotais extends Component {
  static propTypes = {
    totais: PropTypes.object.isRequired
  };

  render() {
    const { totais } = this.props;

    return (
      <DisplayData border>
        <DisplayDataRow>
          <DisplayDataItem
            id="planejados"
            sm={4}
            title="Total Planejado"
            value={NumberUtils.format(totais.get('planejados'))}
          />
          <DisplayDataItem
            id="atualizados"
            sm={4}
            title="Total Planejado Atualizado"
            value={NumberUtils.format(totais.get('atualizados'))}
          />
          <DisplayDataItem
            id="executados"
            sm={4}
            title="Total Executado"
            value={NumberUtils.format(totais.get('executados'))}
          />
        </DisplayDataRow>
      </DisplayData>
    );
  }
}
