import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react.js';
import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

import LinkToEmpenho from '../empenhos/LinkToEmpenho.react';

export default class SuprimentoFundosRow extends Component {
  static propTypes = {
    suprimentoFundos: PropTypes.object.isRequired
  };

  generateKey = suprimentoFundo => {
    return `${suprimentoFundo.get('entidade')}_
            ${suprimentoFundo.get('exercicio')}_
            ${suprimentoFundo.get('empenhoExercicio')}_
            ${suprimentoFundo.get('empenho')}_
            ${suprimentoFundo.get('noLiquidacao')}_
            ${suprimentoFundo.get('valor')}`;
  };

  onViewEmpenho = item => {
    const entidade = item.get('entidade');
    const exercicio = item.get('exercicio');
    const empenho = item.get('empenho');

    this.props.history.push(
      `empenhos/detalhe?search=id.entidade==${entidade}&entidade=${entidade}&exercicio=${exercicio}&empenho=${empenho}`
    );
  };

  render() {
    const { suprimentoFundos } = this.props;

    return (
      <TableActions
        ref="row"
        key={this.generateKey(suprimentoFundos)}
        data={suprimentoFundos}
        onMore={this.onViewEmpenho}
      >
        <td>
          <LinkToEmpenho
            entidade={suprimentoFundos.get('entidade')}
            exercicio={suprimentoFundos.get('exercicio')}
            empenho={suprimentoFundos.get('empenho')}
          />
        </td>
        <td>{DateUtils.transform(suprimentoFundos.get('data'))}</td>
        <td>{suprimentoFundos.get('processo')}</td>
        <td>{suprimentoFundos.get('fornecedor')}</td>
        <td className="right">
          <NumberFormatter value={suprimentoFundos.get('valor')} />
        </td>
      </TableActions>
    );
  }
}
