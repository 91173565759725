import Panel from 'portaltransparencia-common/components/Panel.react.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

import AvaliacaoPpaProgramaTable from './AvaliacaoPpaProgramaTable.react.js';

export default class AvaliacaoPpaFuncaoPanel extends Component {
  static propTypes = {
    funcao: PropTypes.object.isRequired
  };

  render() {
    const { funcao } = this.props;

    return (
      <Panel
        key={funcao.get('codigoFuncao')}
        isForm
        mobileClose
        title={`Função ${funcao.get('codigoFuncao') || ''}`}
        insidePanel
      >
        {funcao.get('programas') && (
          <AvaliacaoPpaProgramaTable programas={funcao.get('programas')} />
        )}
      </Panel>
    );
  }
}
