import DatePicker from 'portaltransparencia-common/components/DatePicker.react';
import Input from 'portaltransparencia-common/components/EloInput.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';

import FilterButtons from '../components/FilterButtons.react';

export default class FormEspecificoFiltro extends Component {
  static propTypes = {
    page: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    filtros: PropTypes.object.isRequired,
    findReceitasEspecifico: PropTypes.func.isRequired,
    montaFiltro: PropTypes.func.isRequired,
    onConsultaWithPage: PropTypes.func.isRequired,
    resetCrud: PropTypes.func.isRequired,
    filtroEspecifico: PropTypes.string.isRequired,
    filtroInicial: PropTypes.func.isRequired
  };

  onChangeFiltro = event => {
    const { onChange } = this.props;
    onChange('filtroEspecifico', event);
  };

  onResetFiltro = () => {
    const { resetCrud, onResetEspecifico } = this.props;
    resetCrud('filtroEspecifico');
    onResetEspecifico();
  };

  onConsulta = event => {
    const { page, onConsultaWithPage } = this.props;
    const initialPage = page.set('number', 0);
    onConsultaWithPage(initialPage);
    event && event.preventDefault();
    this.refs.panelfiltroEspecifico &&
      this.refs.panelfiltroEspecifico.collapse();
  };

  render = () => {
    const { filtros } = this.props;
    return (
      <Panel title="Consultar" mobileClose isForm ref="panelfiltroEspecifico">
        <form>
          <Row>
            <Col xs={6} sm={4} md={3}>
              <Input
                id="receita"
                name="receita"
                ref="receita"
                placeholder="Digite aqui a receita"
                label="Receita"
                type="text"
                onChange={this.onChangeFiltro}
                value={filtros.get('receita')}
              />
            </Col>
            <Col xs={6} sm={4} md={8}>
              <Input
                id="descricaoReceita"
                name="descricaoReceita"
                ref="descricaoReceita"
                placeholder="Digite aqui a Descrição da Receita"
                label="Descrição Receita"
                type="text"
                onChange={this.onChangeFiltro}
                value={filtros.get('descricaoReceita')}
              />
            </Col>
            <Col xs={6} sm={4} md={3}>
              <Input
                id="fonteRecursoRateio"
                name="fonteRecursoRateio"
                ref="fonteRecursoRateio"
                placeholder="Digite aqui a Fonte de Recurso"
                label="Fonte de Recurso"
                type="text"
                onChange={this.onChangeFiltro}
                value={filtros.get('fonteRecursoRateio')}
              />
            </Col>
            <Col xs={6} sm={4} md={8}>
              <Input
                id="descricaoFonteRecurso"
                name="descricaoFonteRecurso"
                ref="descricaoFonteRecurso"
                placeholder="Digite aqui a Descrição da Fonte de Recurso"
                label="Descrição Fonte de Recurso"
                type="text"
                onChange={this.onChangeFiltro}
                value={filtros.get('descricaoFonteRecurso')}
              />
            </Col>
            <Col xs={6} sm={4} md={3}>
              <DatePicker
                id="dataInicial"
                name="dataInicial"
                ref="dataInicial"
                label="Data Inicial"
                onChange={this.onChangeFiltro}
                value={filtros.get('dataInicial')}
              />
            </Col>
            <Col xs={6} sm={4} md={3}>
              <DatePicker
                id="dataFinal"
                name="dataFinal"
                ref="dataFinal"
                label="Data Final"
                onChange={this.onChangeFiltro}
                value={filtros.get('dataFinal')}
              />
            </Col>
            <Col xs={12} sm={4} md={6}>
              <FilterButtons
                idBtnConsulta="btnFiltrar"
                idBtnReset="btnResetFilter"
                on
                onConsulta={this.onConsulta}
                onReset={this.onResetFiltro}
              />
            </Col>
          </Row>
        </form>
      </Panel>
    );
  };
}
