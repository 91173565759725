import * as actions from './Actions.js';

import { Col, Row } from 'react-bootstrap';
import {
  addFilterUrl,
  addPagination
} from 'portaltransparencia-common/libs/AddFilter.js';
import {
  onChange,
  resetCrud
} from 'portaltransparencia-common/crud/Actions.js';

import AutoComplete from 'portaltransparencia-common/components/AutoComplete.react.js';
import Component from 'react-pure-render/component';
import DatePicker from 'portaltransparencia-common/components/DatePicker.react.js';
import ExportButtonGroup from 'portaltransparencia-common/components/ExportButtonGroup.react.js';
import FilterButtons from '../components/FilterButtons.react';
import { FormConsultaProcesso } from './FormConsultaProcesso.react.js';
import Input from 'portaltransparencia-common/components/EloInput.react.js';
import { Map } from 'immutable';
import NotasExplicativas from '../notaexplicativa/Form.react';
import PageHeader from '../components/PageHeader.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import PropTypes from 'prop-types';
import React from 'react';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import Tab from 'portaltransparencia-common/components/Tab.react.js';
import Tabs from 'portaltransparencia-common/components/Tabs.react.js';
import UltimaAtualizacao from '../ultimaatualizacao/Form.react.js';
import { addErrorNotification } from 'portaltransparencia-common/ui/Actions.js';
import configuration from 'portaltransparencia-common/config/Config.js';
import { connect } from 'react-redux';

export class ConsultaSic extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    consultaSic: PropTypes.object,
    situacoes: PropTypes.array,
    consultaSicFisico: PropTypes.object,
    processoTramitacao: PropTypes.object,
    resetCrud: PropTypes.func,
    filtros: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    findProcessoTipo: PropTypes.func,
    findProcessoTipoSic: PropTypes.func,
    findEsics: PropTypes.func,
    findSics: PropTypes.func,
    findTotalEsic: PropTypes.func,
    findProcesso: PropTypes.func,
    findSituacoes: PropTypes.func,
    findAssuntos: PropTypes.func,
    errors: PropTypes.object,
    addErrorNotification: PropTypes.func.isRequired,
    isIntegradoOxy: PropTypes.bool
  };

  componentDidMount() {
    const { findProcessoTipo, findProcessoTipoSic, findSituacoes } = this.props;
    findProcessoTipo();
    findProcessoTipoSic();
    findSituacoes();
  }

  onChangeFiltro = event => {
    const { onChange } = this.props;
    onChange('consultaSic', event);
  };

  onResetFiltro = () => {
    const { resetCrud, findSics, findEsics } = this.props;
    resetCrud('consultaSic');
    findEsics('');
    findSics('');
  };

  montarFiltro = function (page) {
    const { filtros } = this.props;
    let filter = '';
    if (
      filtros.get('dataInicial') !== undefined &&
      filtros.get('dataInicial').length == 10
    ) {
      filter = addFilterUrl(
        filter,
        `dataInicial=${filtros.get('dataInicial')}`
      );
    }

    if (
      filtros.get('dataFinal') !== undefined &&
      filtros.get('dataFinal').length == 10
    ) {
      filter = addFilterUrl(filter, `dataFinal=${filtros.get('dataFinal')}`);
    }

    if (filtros.get('processoSigiloso')) {
      filter = addFilterUrl(
        filter,
        `sigiloso=${filtros.get('processoSigiloso')}`
      );
    }

    if (filtros.get('processoCancelado')) {
      filter = addFilterUrl(
        filter,
        `cancelado=${filtros.get('processoCancelado')}`
      );
    }

    if (filtros.get('processoInteressePublico')) {
      filter = addFilterUrl(
        filter,
        `interessePublico=${filtros.get('processoInteressePublico')}`
      );
    }

    if (filtros.get('situacao')) {
      filter = addFilterUrl(filter, `situacao=${filtros.get('situacao')}`);
    }

    if (filtros.get('assunto')) {
      filter = addFilterUrl(
        filter,
        `assuntoId=${filtros.getIn(['assunto', 'id'])}`
      );
    }

    if (filtros.get('complementoAssunto')) {
      filter = addFilterUrl(
        filter,
        `complementoAssunto=${filtros.get('complementoAssunto')}`
      );
    }

    return addPagination(page, filter);
  };

  searchWithPage = page => {
    const { findEsics, findSics } = this.props;
    const filtro = this.montarFiltro(page);

    const activeTab = this.refs.tab.getActiveTabName();
    if (activeTab === 'eSic') {
      findEsics(filtro);
    } else if (activeTab === 'sicFisico') {
      findSics(filtro);
    }
  };

  search = page => {
    const { findEsics, findSics } = this.props;
    const filtro = this.montarFiltro(page);
    findEsics(filtro);
    findSics(filtro);
  };

  onConsultaSic = event => {
    const { consultaSic } = this.props;
    const initialPage = consultaSic.set('number', 0);
    this.search(initialPage);
    event && event.preventDefault();

    this.refs.panelFiltroConsultaSic &&
      this.refs.panelFiltroConsultaSic.collapse();
  };

  onExporta = (exportType, openTab, windowParams) => {
    const { consultaSic, consultaSicFisico, addErrorNotification } = this.props;
    const { apiURL } = configuration;
    const { exercicio, entidade } = StorageService.getItem('userInfo');

    let filters = '';
    let page;
    if (this.refs.tab) {
      switch (this.refs.tab.getActiveTabName()) {
        case 'eSic':
          filters = this.montarFiltro(consultaSic);
          page = consultaSic;
          break;
        case 'sicFisico':
          filters = this.montarFiltro(consultaSicFisico);
          page = consultaSicFisico;
          break;
      }
    }

    if (!page.get('content').isEmpty()) {
      filters = addFilterUrl(filters, `exportType=${exportType}`);
      filters = addFilterUrl(filters, `exercicio=${exercicio.id}`);
      filters = addFilterUrl(filters, `entidade=${entidade.id}`);

      if (this.refs.tab.getActiveTabName() === 'eSic') {
        filters = addFilterUrl(filters, 'usuario=e-SIC');
      }

      window.open(
        `${apiURL}/api/processos/report?${filters}`,
        openTab,
        windowParams
      );
    } else {
      addErrorNotification(
        'A consulta não apresentou resultado. Não é possível exportar para o formato selecionado!'
      );
    }
  };

  render() {
    const {
      filtros,
      consultaSicFisico,
      consultaSic,
      location,
      processoTramitacao,
      findProcesso,
      situacoes,
      findAssuntos,
      errors,
      isIntegradoOxy
    } = this.props;

    if (!consultaSic.has('content')) {
      return null;
    }

    return (
      <section>
        <PageHeader location={location} />

        <NotasExplicativas pathname={location.pathname} />

        <Panel
          title="Consultar"
          isForm
          mobileClose
          ref="panelFiltroConsultaSic"
        >
          <form>
            <Row>
              <Col xs={12} sm={4} md={3}>
                <DatePicker
                  id="dataInicial"
                  value={filtros.get('dataInicial')}
                  name="dataInicial"
                  ref="dataInicial"
                  label="Data Inicial"
                  onChange={this.onChangeFiltro}
                />
              </Col>
              <Col xs={12} sm={4} md={3}>
                <DatePicker
                  id="dataFinal"
                  value={filtros.get('dataFinal')}
                  name="dataFinal"
                  ref="dataFinal"
                  label="Data Final"
                  onChange={this.onChangeFiltro}
                />
              </Col>
              <Col xs={12} sm={4} md={3}>
                <Input
                  id="situacao"
                  value={filtros.get('situacao')}
                  name="situacao"
                  ref="situacao"
                  label="Situação"
                  type="select"
                  onChange={this.onChangeFiltro}
                >
                  <option value="">Selecione</option>
                  {situacoes?.map(situacao => (
                    <option key={situacao.get('id')} value={situacao.get('id')}>
                      {situacao.get('descricao')}
                    </option>
                  ))}
                </Input>
              </Col>
              {isIntegradoOxy && (
                <Col xs={12} sm={4} md={3} key={filtros.get('assunto')}>
                  <AutoComplete
                    label="Assunto"
                    id="assunto"
                    name="assunto"
                    onLoad={findAssuntos}
                    onChange={this.onChangeFiltro}
                    value={filtros.get('assunto')}
                    errors={errors}
                    valueKey="id"
                    labelKey="descricao"
                    placeholder="Selecione um motivo / assunto"
                    clearable
                  />
                </Col>
              )}
            </Row>
            <Row>
              {isIntegradoOxy && (
                <Col xs={12} sm={4} md={3}>
                  <Input
                    id="complementoAssunto"
                    name="complementoAssunto"
                    ref="complementoAssunto"
                    label="Motivo"
                    type="text"
                    onChange={this.onChangeFiltro}
                    value={filtros.get('complementoAssunto')}
                  />
                </Col>
              )}
              <Col xs={12} sm={4} md={3}>
                <div className="form-group">
                  <label className="label" />
                  <Input
                    label="Processos Sigilosos"
                    type="checkbox"
                    name="processoSigiloso"
                    ref="processoSigiloso"
                    onChange={this.onChangeFiltro}
                    checked={filtros.get('processoSigiloso')}
                  />
                </div>
              </Col>
              <Col xs={12} sm={4} md={3}>
                <div className="form-group">
                  <label className="label" />
                  <Input
                    label="Processos Cancelados"
                    type="checkbox"
                    name="processoCancelado"
                    ref="processoCancelado"
                    onChange={this.onChangeFiltro}
                    checked={filtros.get('processoCancelado')}
                  />
                </div>
              </Col>
              <Col xs={12} sm={4} md={3}>
                <div className="form-group">
                  <label className="label" />
                  <Input
                    label="Processos Interesse Público"
                    type="checkbox"
                    name="processoInteressePublico"
                    ref="processoInteressePublico"
                    onChange={this.onChangeFiltro}
                    checked={filtros.get('processoInteressePublico')}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={4} md={6}>
                <FilterButtons
                  idBtnConsulta="btnFiltrar"
                  onConsulta={this.onConsultaSic}
                  idBtnReset="btnResetFilter"
                  onReset={this.onResetFiltro}
                />
              </Col>
            </Row>
          </form>
        </Panel>

        <Panel isForm expansible={false} tableResponsive>
          <Row>
            <Col xs={6}>
              <UltimaAtualizacao modulo="10" />
            </Col>
            <Col xs={6}>
              <ExportButtonGroup onClick={this.onExporta} visible={true} />
            </Col>
          </Row>
          <Tabs ref="tab">
            <Tab title="E-SIC" name="eSic" onlyTable tableResponsive active>
              <FormConsultaProcesso
                searchWithPage={this.searchWithPage}
                consultaProcesso={consultaSic.get('content')}
                page={consultaSic}
                processoTramitacao={processoTramitacao}
                findProcesso={findProcesso}
              />
            </Tab>
            <Tab title="Sic Físico" name="sicFisico" onlyTable tableResponsive>
              <FormConsultaProcesso
                searchWithPage={this.searchWithPage}
                consultaProcesso={consultaSicFisico.get('content')}
                page={consultaSicFisico}
                processoTramitacao={processoTramitacao}
                findProcesso={findProcesso}
              />
            </Tab>
          </Tabs>
        </Panel>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    consultaSic: state.consultaSic.get('consultaSic'),
    consultaSicFisico: state.consultaSic.get('consultaSicFisico'),
    filtros: state.crud.getIn(['consultaSic', 'currentRecord'], Map({})),
    situacoes: state.consultaSic.get('situacoes'),
    processoTramitacao: state.consultaSic.get('processoTramitacao'),
    isIntegradoOxy: state.entidade.getIn([
      'configuracoesGerais',
      'isIntegradoOxy'
    ])
  };
}

const allActions = {
  ...actions,
  onChange,
  resetCrud,
  addErrorNotification
};

export default connect(mapStateToProps, allActions)(ConsultaSic);
