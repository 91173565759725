import Panel from 'portaltransparencia-common/components/Panel.react.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import PageHeader from '../components/PageHeader.react.js';
import NotasExplicativas from '../notaexplicativa/Form.react';
import * as actions from './Actions.js';
import LinkUtil from './LinkUtil.react';

export class FormLinkUtil extends Component {
  static propTypes = {
    linksUteis: PropTypes.object.isRequired,
    findLinksUteis: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired
  };

  componentDidMount() {
    const { findLinksUteis } = this.props;

    findLinksUteis();
  }

  renderLinksUteis = link => {
    return <LinkUtil key={link.get('id')} linkUtil={link} />;
  };

  render() {
    const { linksUteis } = this.props;

    return (
      <section>
        <PageHeader location={this.props.location} />

        <NotasExplicativas pathname={this.props.location.pathname} />

        {linksUteis.size > 0 ? (
          <Panel isForm expansible={false}>
            {linksUteis.map(this.renderLinksUteis)}
          </Panel>
        ) : (
          <div className="info">Sem links disponíveis.</div>
        )}
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    linksUteis: state.linkutil.get('linksUteis')
  };
}

export default connect(mapStateToProps, actions)(FormLinkUtil);
