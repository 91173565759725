import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';

import * as types from './Types.js';

const initialState = fromJS({
  liquidacoesPagar: [],
  totalLiquidacoesPagar: {},
  liquidacoesPagas: [],
  totalLiquidacoesPagas: {},
  filtros: []
});

function LiquidacaoReducer(state = initialState, action) {
  switch (action.type) {
    case types.PAGAR_LIST_START:
    case types.TOTAL_PAGAR_START:
    case types.PAGAS_LIST_START:
    case types.TOTAL_PAGAS_START:
    case types.PROGRAMATICAFONTERECURSO_START: {
      return startLoad(state);
    }

    case types.PAGAR_LIST_ERROR:
    case types.TOTAL_PAGAR_ERROR:
    case types.PAGAS_LIST_ERROR:
    case types.TOTAL_PAGAS_ERROR:
    case types.PROGRAMATICAFONTERECURSO_ERROR: {
      return endLoad(state);
    }

    case types.PAGAR_LIST_SUCCESS: {
      return endLoad(state.set('liquidacoesPagar', fromJS(action.payload)));
    }
    case types.TOTAL_PAGAR_SUCCESS: {
      return endLoad(
        state.set('totalLiquidacoesPagar', fromJS(action.payload))
      );
    }

    case types.PAGAS_LIST_SUCCESS: {
      return endLoad(state.set('liquidacoesPagas', fromJS(action.payload)));
    }
    case types.TOTAL_PAGAS_SUCCESS: {
      return endLoad(
        state.set('totalLiquidacoesPagas', fromJS(action.payload))
      );
    }
    case types.PROGRAMATICAFONTERECURSO_SUCCESS: {
      return endLoad(
        state.setIn(
          ['filtroLiquidacoes', 'fonteRecurso'],
          fromJS(action.payload)
        )
      );
    }
  }

  return state;
}

export default LiquidacaoReducer;
