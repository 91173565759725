import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export class CargoFaixaRow extends Component {
  static propTypes = {
    faixa: PropTypes.object.isRequired
  };

  generateKey = faixa => {
    return `${faixa.get('cargo')}_
            ${faixa.get('quadroSalarial')}_
            ${faixa.get('grupoOcupacional')}_
            ${faixa.get('nivel')}_
            ${faixa.get('faixa')}_
            ${faixa.get('inicioValidade')}`;
  };

  render() {
    const { faixa } = this.props;

    return (
      <tr key={this.generateKey(faixa)}>
        <td>{faixa.get('faixa')}</td>
        <td>{faixa.get('faixaDesc')}</td>
        <td className="right">
          <NumberFormatter value={faixa.get('valor')} />
        </td>
        <td className="right">
          <NumberFormatter value={faixa.get('valorGratificacao')} />
        </td>
      </tr>
    );
  }
}

export default CargoFaixaRow;
