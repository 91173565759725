import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';

import * as types from './Types.js';

const initialState = fromJS({
  prestacaoContas: {},
  arquivos: []
});

function prestacaoContasReducer(state = initialState, action) {
  switch (action.type) {
    case types.PRESTACAOCONTAS_ARQUIVOS_START:
    case types.PRESTACAOCONTAS_START: {
      return startLoad(state);
    }

    case types.PRESTACAOCONTAS_ARQUIVOS_ERROR:
    case types.PRESTACAOCONTAS_ERROR: {
      return endLoad(state);
    }

    case types.PRESTACAOCONTAS_SUCCESS: {
      return endLoad(state.set('prestacaoContas', fromJS(action.payload)));
    }
    case types.PRESTACAOCONTAS_ARQUIVOS_SUCCESS: {
      return endLoad(state.set('arquivos', fromJS(action.payload)));
    }
  }

  return state;
}

export default prestacaoContasReducer;
