import BotaoImprimir from 'portaltransparencia-common/components/BotaoImprimir.react.js';
import DisplayData from 'portaltransparencia-common/components/displaydata/DisplayData.react.js';
import DisplayDataItem from 'portaltransparencia-common/components/displaydata/DisplayDataItem.react.js';
import DisplayDataRow from 'portaltransparencia-common/components/displaydata/DisplayDataRow.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import { getAllValuesFromUrlSearchParams } from 'portaltransparencia-common/libs/URLUtils';
import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import { downloadArquivo } from '../arquivo/Actions.js';
import PageHeader from '../components/PageHeader.react.js';
import CabecalhoEntidade from '../entidades/CabecalhoEntidade.react.js';
import SubvencaoAnexos from '../subvencoes/FormDetalheAnexo.react.js';
import * as actions from './Actions.js';

export class PrestacaoContasDetalhe extends Component {
  static propTypes = {
    arquivos: PropTypes.object.isRequired,
    prestacaoContas: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    downloadArquivo: PropTypes.func.isRequired,
    findArquivos: PropTypes.func.isRequired,
    findPrestacaoContas: PropTypes.func.isRequired
  };

  componentDidMount() {
    const { location, findPrestacaoContas, findArquivos } = this.props;

    const {
      entidade,
      exercicio,
      subvencao,
      anoSubvencao,
      sequencia
    } = getAllValuesFromUrlSearchParams(location);

    findPrestacaoContas(
      entidade,
      exercicio,
      subvencao,
      anoSubvencao,
      sequencia
    ).then(() => {
      findArquivos(entidade, exercicio, subvencao, anoSubvencao, sequencia);
    });
  }

  render() {
    const { prestacaoContas, downloadArquivo, arquivos, location } = this.props;

    return (
      <div>
        <Helmet title={'Prestação de Contas'} />

        <BotaoImprimir />

        <PageHeader location={location} />

        <CabecalhoEntidade />

        <Panel title={'Prestação de Contas'}>
          <DisplayData border>
            <DisplayDataRow>
              <DisplayDataItem
                sm={3}
                title="Período"
                value={prestacaoContas.get('periodoPrestacao')}
                id="periodoPrestacao"
              />
              <DisplayDataItem
                sm={3}
                title="Tipo"
                value={prestacaoContas.get('tipo')}
                id="tipo"
              />
              <DisplayDataItem
                sm={3}
                title="Data"
                value={DateUtils.transform(prestacaoContas.get('data'))}
                id="data"
              />
              <DisplayDataItem
                sm={3}
                title="Data Prevista"
                value={DateUtils.transform(prestacaoContas.get('dataPrevista'))}
                id="dataPrevista"
              />
            </DisplayDataRow>
            <DisplayDataRow>
              <DisplayDataItem
                sm={9}
                title="Descrição"
                value={prestacaoContas.get('descricao')}
                id="descricao"
              />
              <DisplayDataItem
                sm={3}
                title="Prazo Análise"
                value={DateUtils.transform(prestacaoContas.get('dataPrazo'))}
                id="dataPrazo"
              />
            </DisplayDataRow>
          </DisplayData>
        </Panel>

        <Panel isTable tableResponsive title="Anexos">
          <SubvencaoAnexos anexos={arquivos} onDownload={downloadArquivo} />
        </Panel>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    prestacaoContas: state.prestacaoContas.get('prestacaoContas'),
    arquivos: state.prestacaoContas.get('arquivos')
  };
}

const allActions = {
  ...actions,
  downloadArquivo
};

export default connect(mapStateToProps, allActions)(PrestacaoContasDetalhe);
