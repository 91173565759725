import PropTypes from 'prop-types';
import React from 'react';

import $ from 'jquery';
export default class TableHeader extends React.PureComponent {
  static propTypes = {
    empty: PropTypes.bool.isRequired,
    children: PropTypes.node,
    text: PropTypes.string
  };

  static defaultProps = {
    text: 'Sem registros.'
  };

  componentDidMount() {
    this.refreshResponsiveTable();
  }

  componentDidUpdate() {
    this.refreshResponsiveTable();
  }

  refreshResponsiveTable = () => {
    //Tabela Responsive
    function addNumber(item) {
      $('.table-responsive tr').each(function () {
        $(item).each(function () {
          var classe = this.cellIndex;
          $(this).addClass(`coluna-${classe}`);
        });
      });
    }
    addNumber('.table-responsive th');
    addNumber('.table-responsive td');

    $('.table-responsive .panel-table-outer').each(function () {
      $(this)
        .find('td')
        .each(function () {
          var esteTd = $(this);
          var classeTd = esteTd.attr('class');
          var label = '';

          esteTd
            .parents('.panel-table-outer')
            .find('th')
            .each(function () {
              var esteTh = $(this);

              if (esteTh.attr('class').includes(classeTd)) {
                label = esteTh.text();
              }
            });

          if (
            esteTd.find('.btn-actions').length == 0 &&
            esteTd.find('.td-content').length == 0
          ) {
            esteTd.wrapInner('<span class="td-content"></span>');
            esteTd.prepend(`<b class="td-label">${label}</b>`);
          }

          if (
            esteTd.find('.btn-actions').length == 0 &&
            esteTd.find('.td-content').text().length == 0 &&
            esteTd.parents('tfoot')
          ) {
            esteTd.addClass('hidden-xs');
          }
        });
    });
  };

  render() {
    const { empty, children, text } = this.props;

    if (empty) {
      return (
        <tbody>
          <tr>
            <td>
              <div className="center">{text}</div>
            </td>
          </tr>
        </tbody>
      );
    }

    return <thead>{children}</thead>;
  }
}
