import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react.js';
import TableActions from 'portaltransparencia-common/components/TableActions.react';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Component from 'react-pure-render/component';
import { PARAM_USAWEBSERVICE_MARINGA } from 'portaltransparencia-common/parametros/ParametrosPortalTransparencia';
import ParametroService from '../parametros/ParametroService.js';

export class AutonomoDetalheFinanceiro extends Component {
  static propTypes = {
    dadosFinanceiros: PropTypes.object.isRequired,
    paramUsaWebServiceMaringa: PropTypes.string
  };

  renderDadosFinanceiros = item => {
    if (!item.get('mesCompetencia') || !item.get('anoCompetencia')) {
      return null;
    }

    const key = item.get('mesCompetencia') + '_' + item.get('anoCompetencia');
    const bruto = item.get('vencimentos') == null ? 0 : item.get('vencimentos');
    const descontos = item.get('descontos') == null ? 0 : item.get('descontos');

    return (
      <TableActions ref="row" key={key} data={item}>
        <td>{item.get('mesCompetencia') + '/' + item.get('anoCompetencia')}</td>
        <td className="right">
          <NumberFormatter value={bruto} />
        </td>
        <td className="right">
          <NumberFormatter value={descontos} />
        </td>
        <td className="right">
          <NumberFormatter value={bruto - descontos} />
        </td>
      </TableActions>
    );
  };

  render() {
    const { dadosFinanceiros, paramUsaWebServiceMaringa } = this.props;

    return (
      <section>
        <div className="panel-table-outer">
          <table className="panel-table striped fancy">
            <TableHeader empty={dadosFinanceiros.isEmpty()}>
              <tr>
                <th>Competência</th>
                <th className="right">Bruto</th>
                <th className="right">
                  {paramUsaWebServiceMaringa == '1'
                    ? 'Descontos Obrigatórios'
                    : 'Descontos'}
                </th>
                <th className="right">
                  {paramUsaWebServiceMaringa == '1'
                    ? 'Proventos - Descontos Obrigatórios'
                    : 'Líquido'}
                </th>
                <th></th>
              </tr>
            </TableHeader>
            <tbody>{dadosFinanceiros.map(this.renderDadosFinanceiros)}</tbody>
          </table>
        </div>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    paramUsaWebServiceMaringa: ParametroService.getValorParametro(
      state,
      PARAM_USAWEBSERVICE_MARINGA
    )
  };
}

export default connect(mapStateToProps)(AutonomoDetalheFinanceiro);
