import * as types from './Types.js';

export function findArrecadacaoDespesaMes() {
  return ({ fetch }) => {
    return {
      type: types.ARRECADACAO_DESPESA_MES,
      payload: {
        promise: fetch('/api/dashboard/arrecadacao-despesa')
      }
    };
  };
}

export function findReceitaValorAtualizado() {
  return ({ fetch }) => {
    return {
      type: types.RECEITA_VALOR_ATUALIZADO,
      payload: {
        promise: fetch('/api/dashboard/receita')
      }
    };
  };
}

export function findDespesaValorAtualizado() {
  return ({ fetch }) => {
    return {
      type: types.DESPESA_VALOR_ATUALIZADO,
      payload: {
        promise: fetch('/api/dashboard/despesa')
      }
    };
  };
}

export function findGastosPessoal() {
  return ({ fetch }) => {
    return {
      type: types.GASTOS_PESSOAL,
      payload: {
        promise: fetch('/api/dashboard/pessoal')
      }
    };
  };
}

export function findGastosEducacao() {
  return ({ fetch }) => {
    return {
      type: types.GASTOS_EDUCACAO,
      payload: {
        promise: fetch('/api/dashboard/educacao')
      }
    };
  };
}

export function findGastosSaude() {
  return ({ fetch }) => {
    return {
      type: types.GASTOS_SAUDE,
      payload: {
        promise: fetch('/api/dashboard/saude')
      }
    };
  };
}

export function findReceitasDashBoardPorMes() {
  return ({ fetch }) => {
    return {
      type: types.RECEITA_DASHBOARD,
      payload: {
        promise: fetch('/api/dashboard/receita-dashboard')
      }
    };
  };
}
