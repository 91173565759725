import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';

import * as types from './Types.js';

const initialState = fromJS({
  notasExplicativas: [],
  notasFiltradas: [],
  image: {}
});

function notaExplicativaReducer(state = initialState, action) {
  switch (action.type) {
    case types.NOTAS_EXPLICATIVA_START: {
      return startLoad(state);
    }
    case types.STORE_IMAGE_START: {
      return startLoad(state);
    }
    case types.NOTAS_EXPLICATIVA_ERROR: {
      return endLoad(state);
    }

    case types.NOTAS_EXPLICATIVA_SUCCESS: {
      return endLoad(state.set('notasExplicativas', fromJS(action.payload)));
    }
    case types.STORE_IMAGE_SUCCESS: {
      return endLoad(state.set('image', fromJS(action.payload)));
    }
    case types.NOTAS_EXPLICATIVA_MENU: {
      let notasFiltrado = state
        .get('notasExplicativas')
        .filter(n => n.get('path') === action.payload);

      return endLoad(state.set('notasFiltradas', fromJS(notasFiltrado)));
    }
  }

  return state;
}

export default notaExplicativaReducer;
