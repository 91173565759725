import PropTypes from 'prop-types';
import React from 'react';
import NumberFormatter from './NumberFormatter.react.js';

const countValues = (field, distinctSet) => (valor, item) => {
  if (!item.has(field) || distinctSet.has(item.get(field))) {
    return valor;
  }
  distinctSet.add(item.get(field));
  return valor + 1;
};

export default class CountListField extends React.PureComponent {
  static propTypes = {
    fieldName: PropTypes.string,
    list: PropTypes.object.isRequired,
    format: PropTypes.string
  };

  static defaultProps = {
    format: '0'
  };

  render() {
    const { fieldName, list, format } = this.props;
    const distinctSet = new Set();

    return (
      <NumberFormatter
        value={
          fieldName
            ? list.reduce(countValues(fieldName, distinctSet), 0)
            : list.size
        }
        format={format}
      />
    );
  }
}
