import ExportButtonGroup from 'portaltransparencia-common/components/ExportButtonGroup.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import Tab from 'portaltransparencia-common/components/Tab.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import Tabs from 'portaltransparencia-common/components/Tabs.react.js';
import TreeTable from 'portaltransparencia-common/components/treetable/TreeTable.react';
import configuration from 'portaltransparencia-common/config/Config.js';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import { PARAM_EM_LIQUIDACAO } from 'portaltransparencia-common/parametros/ParametrosPortalTransparencia';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import PageHeader from '../components/PageHeader.react.js';
import NotasExplicativas from '../notaexplicativa/Form.react';
import ParametroService from '../parametros/ParametroService.js';
import UltimaAtualizacaoByTable from '../ultimaatualizacao/UltimaAtualizacaoByTable.react.js';
import * as actions from './Actions.js';
import FormDespesaNivel from './FormFiltroNivel.react.js';
import TableItem from './TableItem.js';
import { addErrorNotification } from 'portaltransparencia-common/ui/Actions.js';

export class FormDespesaDetalhada extends Component {
  static propTypes = {
    findDespesas: PropTypes.func.isRequired,
    despesas: PropTypes.object.isRequired,
    expand: PropTypes.func.isRequired,
    collapse: PropTypes.func.isRequired,
    paramEmLiquidacoes: PropTypes.string,
    location: PropTypes.object.isRequired,
    addErrorNotification: PropTypes.func.isRequired
  };

  componentDidMount() {
    const { findDespesas } = this.props;

    findDespesas();
  }

  onExporta = (exportType, openTab, windowParams) => {
    const { apiURL } = configuration;
    const { entidade, exercicio } = StorageService.getItem('userInfo');
    const { despesas, addErrorNotification } = this.props;

    if (!despesas.isEmpty()) {
      window.open(
        `${apiURL}/despesapornivel/reportNivel?entidade=${entidade.id}&exercicio=${exercicio.id}&exportType=${exportType}`,
        openTab,
        windowParams
      );
    } else {
      addErrorNotification(
        'A consulta não apresentou resultado. Não é possível exportar para o formato selecionado!'
      );
    }
  };

  renderItem = item => {
    const { expand, collapse, paramEmLiquidacoes } = this.props;

    return (
      <TableItem
        key={item.get('programatica')}
        item={item}
        expand={expand}
        collapse={collapse}
        paramEmLiquidacoes={paramEmLiquidacoes}
      />
    );
  };

  render() {
    const { despesas, paramEmLiquidacoes } = this.props;

    const header = (
      <TableHeader empty={despesas.isEmpty()}>
        <tr>
          <th>Programática</th>
          <th>Descrição</th>
          <th className="right">Dotação Inicial</th>
          <th className="right">Créditos Adicionais</th>
          <th className="right">Dotação Atualizada</th>
          <th className="right">Valor Empenhado</th>
          {paramEmLiquidacoes == 'S' && (
            <th className="right">Em Liquidação</th>
          )}
          <th className="right">Valor Liquidado</th>
          <th className="right">Valor Pago</th>
        </tr>
      </TableHeader>
    );

    return (
      <section>
        <PageHeader location={this.props.location} />

        <NotasExplicativas pathname={this.props.location.pathname} />

        <Panel isTable tableResponsive expansible={false}>
          <Row>
            <Col xs={6}>
              <UltimaAtualizacaoByTable
                modulo="3"
                table="despesa"
                column="dataultimaatualizacao"
              />
            </Col>
            <Col xs={6} />
          </Row>
          <Tabs ref="tab">
            <Tab title="Árvore das Despesas" name="arvore" onlyTable active>
              <ExportButtonGroup onClick={this.onExporta} visible={true} />
              <TreeTable
                list={despesas}
                header={header}
                itemRenderer={this.renderItem}
              />
            </Tab>
            <Tab title="Despesas por Nível" name="nivel" onlyTable>
              <FormDespesaNivel />
            </Tab>
          </Tabs>
        </Panel>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    despesas: state.despesasDetalhe.get('despesas'),
    paramEmLiquidacoes: ParametroService.getValorParametro(
      state,
      PARAM_EM_LIQUIDACAO
    )
  };
}

const allActions = {
  ...actions,
  addErrorNotification
};

export default connect(mapStateToProps, allActions)(FormDespesaDetalhada);
