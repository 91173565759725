export const TOTALACESSOS = 'TOTALACESSOS';
export const TOTALACESSOS_START = 'TOTALACESSOS_START';
export const TOTALACESSOS_SUCCESS = 'TOTALACESSOS_SUCCESS';
export const TOTALACESSOS_ERROR = 'TOTALACESSOS_ERROR';
export const TOTALACESSOS_DIARIO_OFICIAL = 'TOTALACESSOS_DIARIO_OFICIAL';
export const TOTALACESSOS_DIARIO_OFICIAL_SUCCESS =
  'TOTALACESSOS_DIARIO_OFICIAL_SUCCESS';
export const TOTALACESSOS_DIARIO_OFICIAL_START =
  'TOTALACESSOS_DIARIO_OFICIAL_START';
export const TOTALACESSOS_DIARIO_OFICIAL_ERROR =
  'TOTALACESSOS_DIARIO_OFICIAL_ERROR';
