import Panel from 'portaltransparencia-common/components/Panel.react.js';
import SumListField from 'portaltransparencia-common/components/SumListField.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react.js';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

import GastoAbastecimentoModal from './GastoAbastecimentoModal.react';
import GastoBateriaModal from './GastoBateriaModal.react';
import GastoManutencaoModal from './GastoManutencaoModal.react';
import GastoPneuModal from './GastoPneuModal.react';
import GastoPecaModal from './GastoPecaModal.react';
import VeiculoGasto from './VeiculoGasto.react.js';

export class VeiculoGastosTable extends Component {
  static propTypes = {
    gastos: PropTypes.object.isRequired,
    veiculo: PropTypes.object.isRequired,
    gastosManutencao: PropTypes.object,
    gastosAbastecimento: PropTypes.object,
    gastosPneu: PropTypes.object,
    gastosBateria: PropTypes.object,
    gastosPeca: PropTypes.object,
    loadGastosManutencao: PropTypes.func.isRequired,
    loadGastosAbastecimento: PropTypes.func.isRequired,
    loadGastosBateria: PropTypes.func.isRequired,
    loadGastosPneu: PropTypes.func.isRequired,
    loadGastosPeca: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      showModalAbastecimento: false,
      showModalBateria: false,
      showModalPneu: false,
      showModalPeca: false
    };
  }

  onShowModal = mes => {
    const { loadGastosManutencao, veiculo } = this.props;
    loadGastosManutencao(
      this.getVeiculoId(),
      mes,
      veiculo.get('entidade'),
      veiculo.get('chapa')
    );
    this.setState({
      showModal: true
    });
  };

  onShowModalAbastecimento = mes => {
    const { loadGastosAbastecimento, veiculo } = this.props;
    loadGastosAbastecimento(
      this.getVeiculoId(),
      mes,
      veiculo.get('entidade'),
      veiculo.get('chapa')
    );
    this.setState({
      showModalAbastecimento: true
    });
  };

  onShowModalBateria = mes => {
    const { loadGastosBateria, veiculo } = this.props;
    loadGastosBateria(
      this.getVeiculoId(),
      mes,
      veiculo.get('entidade'),
      veiculo.get('chapa')
    );
    this.setState({
      showModalBateria: true
    });
  };

  onShowModalPneu = mes => {
    const { loadGastosPneu, veiculo } = this.props;
    loadGastosPneu(
      this.getVeiculoId(),
      mes,
      veiculo.get('entidade'),
      veiculo.get('chapa')
    );
    this.setState({
      showModalPneu: true
    });
  };

  onShowModalPeca = mes => {
    const { loadGastosPeca, veiculo } = this.props;
    loadGastosPeca(
      this.getVeiculoId(),
      mes,
      veiculo.get('entidade'),
      veiculo.get('chapa')
    );
    this.setState({
      showModalPeca: true
    });
  };

  onCloseModal = () => {
    this.setState({
      showModal: false
    });
  };

  onCloseModalAbastecimento = () => {
    this.setState({
      showModalAbastecimento: false
    });
  };

  onCloseModalPneu = () => {
    this.setState({
      showModalPneu: false
    });
  };

  onCloseModalBateria = () => {
    this.setState({
      showModalBateria: false
    });
  };

  onCloseModalPeca = () => {
    this.setState({
      showModalPeca: false
    });
  };

  renderGastosVeiculo = data => {
    return (
      <VeiculoGasto
        key={data.get('mes')}
        gasto={data}
        onShowModal={this.onShowModal}
        onShowModalAbastecimento={this.onShowModalAbastecimento}
        onShowModalBateria={this.onShowModalBateria}
        onShowModalPneu={this.onShowModalPneu}
        onShowModalPeca={this.onShowModalPeca}
      />
    );
  };

  isTrator = () => {
    return this.props.veiculo.get('idTipoMedidor') === 2;
  };

  getVeiculoId = () => {
    const { veiculo } = this.props;

    return veiculo.get('veiculoOxy')
      ? veiculo.get('veiculoOxy')
      : veiculo.get('veiculo');
  };

  render() {
    const {
      gastos,
      gastosManutencao,
      gastosAbastecimento,
      gastosPneu,
      gastosBateria,
      gastosPeca
    } = this.props;
    const { exercicio } = StorageService.getItem('userInfo');

    return (
      <section>
        <GastoManutencaoModal
          gastosManutencao={gastosManutencao}
          show={this.state.showModal}
          onClose={this.onCloseModal}
        />
        <GastoAbastecimentoModal
          gastosAbastecimento={gastosAbastecimento}
          show={this.state.showModalAbastecimento}
          onClose={this.onCloseModalAbastecimento}
        />
        <GastoPneuModal
          gastosPneu={gastosPneu}
          show={this.state.showModalPneu}
          onClose={this.onCloseModalPneu}
        />
        <GastoBateriaModal
          gastosBateria={gastosBateria}
          show={this.state.showModalBateria}
          onClose={this.onCloseModalBateria}
        />
        <GastoPecaModal
          gastosPeca={gastosPeca}
          show={this.state.showModalPeca}
          onClose={this.onCloseModalPeca}
        />

        <Panel
          isTable
          tableResponsive
          expansible={false}
          title="Gastos do Veículo"
        >
          <div className="panel-table-outer">
            <table className="panel-table striped fancy">
              <TableHeader
                empty={gastos.isEmpty()}
                texto="Nenhum gasto encontrado."
              >
                <tr>
                  <th>Mês/{exercicio.id}</th>
                  <th className="right">Manutenção R$</th>
                  <th className="right">Abastecimento R$</th>
                  <th className="right">Pneus R$</th>
                  <th className="right">Baterias R$</th>
                  <th className="right">Peças R$</th>
                  <th className="right">Gasto Total R$</th>
                  <th className="right">
                    {this.isTrator() ? 'Hora Trabalhada' : 'Km Percorrido'}
                  </th>
                </tr>
              </TableHeader>
              <tbody>{gastos.map(this.renderGastosVeiculo)}</tbody>
              {!gastos.isEmpty() && (
                <tfoot>
                  <tr>
                    <td></td>
                    <td className="right">
                      <i>Total: </i>
                      <SumListField fieldName="manutencao" list={gastos} />
                    </td>
                    <td className="right">
                      <i>Total: </i>
                      <SumListField fieldName="abastecimento" list={gastos} />
                    </td>
                    <td className="right">
                      <i>Total: </i>
                      <SumListField fieldName="pneus" list={gastos} />
                    </td>
                    <td className="right">
                      <i>Total: </i>
                      <SumListField fieldName="baterias" list={gastos} />
                    </td>
                    <td className="right">
                      <i>Total: </i>
                      <SumListField fieldName="pecas" list={gastos} />
                    </td>
                    <td className="right">
                      <i>Total: </i>
                      <SumListField fieldName="totalGeral" list={gastos} />
                    </td>
                    <td className="right">
                      <i>Total: </i>
                      <SumListField
                        fieldName="kmPercorrido"
                        list={gastos}
                        format="0,0.0"
                      />
                    </td>
                  </tr>
                </tfoot>
              )}
            </table>
          </div>
        </Panel>
      </section>
    );
  }
}

export default VeiculoGastosTable;
