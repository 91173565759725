import Panel from 'portaltransparencia-common/components/Panel.react.js';
import {
  addFilterUrl,
  addPagination
} from 'portaltransparencia-common/libs/AddFilter';
import {
  onChange,
  resetCrud
} from 'portaltransparencia-common/crud/Actions.js';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';
import PageHeader from '../components/PageHeader.react.js';
import NotasExplicativas from '../notaexplicativa/Form.react';
import * as actions from './Actions.js';
import { downloadArquivo } from '../arquivo/Actions';
import { changeFieldValue } from 'portaltransparencia-common/crud/Actions.js';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import Fields from 'portaltransparencia-common/libs/Fields.js';
import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import TableHeaderItem from 'portaltransparencia-common/components/TableHeaderItem.react';
import { compactText } from 'portaltransparencia-common/libs/TextCompact.js';
import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react.js';
import configuration from 'portaltransparencia-common/config/Config.js';
import Input from 'portaltransparencia-common/components/EloInput.react.js';
import FilterButtons from '../components/FilterButtons.react.js';
import UltimaAtualizacao from '../ultimaatualizacao/Form.react.js';
import ExportButtonGroup from 'portaltransparencia-common/components/ExportButtonGroup.react.js';
import SearchPagination from 'portaltransparencia-common/components/SearchPagination.react.js';
import { Map } from 'immutable';
import { addErrorNotification } from 'portaltransparencia-common/ui/Actions.js';
import LinkToConvenio from './LinkToConvenio.js';

export class FormEmendaParlamentar extends Component {
  static propTypes = {
    emendaParlamentar: PropTypes.object.isRequired,
    findEmendaParlamentar: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    page: PropTypes.object.isRequired,
    searchEmendas: PropTypes.func.isRequired,
    changeFieldValue: PropTypes.func.isRequired,
    exportaEmenda: PropTypes.func,
    filtros: PropTypes.object,
    onChange: PropTypes.func,
    resetCrud: PropTypes.func.isRequired,
    downloadArquivo: PropTypes.func.isRequired,
    addErrorNotification: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.onResetFiltro('filtroEmendas');
  }

  onChangeFiltro = event => {
    const { onChange } = this.props;

    onChange('filtroEmendas', event);
  };

  onResetFiltro = () => {
    const { resetCrud, searchEmendas } = this.props;
    resetCrud('filtroEmendas');
    let userInfo = StorageService.getItem('userInfo');
    const { entidade } = userInfo;

    let filter = `${`entidade=${entidade.id}`}`;

    searchEmendas(filter);
  };

  montaFilter = function (page) {
    const { entidade } = StorageService.getItem('userInfo');

    let filter = `${`entidade=${entidade.id}`}`;

    if (this.refs.autoria && this.refs.autoria.getValue()) {
      filter = addFilterUrl(filter, 'autoria=' + this.refs.autoria.getValue());
    }
    if (this.refs.tipo && this.refs.tipo.getValue()) {
      filter = addFilterUrl(filter, 'tipo=' + this.refs.tipo.getValue());
    }
    if (this.refs.numero && this.refs.numero.getValue()) {
      filter = addFilterUrl(filter, 'numero=' + this.refs.numero.getValue());
    }
    if (this.refs.exercicio && this.refs.exercicio.getValue()) {
      filter = addFilterUrl(
        filter,
        'exercicio=' + this.refs.exercicio.getValue()
      );
    }
    if (this.refs.objeto && this.refs.objeto.getValue()) {
      filter = addFilterUrl(filter, 'objeto=' + this.refs.objeto.getValue());
    }
    if (this.refs.funcao && this.refs.funcao.getValue()) {
      filter = addFilterUrl(filter, 'funcao=' + this.refs.funcao.getValue());
    }

    return addPagination(page, filter);
  };

  onConsultaEmendas = event => {
    const { page } = this.props;

    const initialPage = page.set('number', 0);

    this.searchWithPage(initialPage);

    event && event.preventDefault();
    this.refs.panelFiltroEmendas && this.refs.panelFiltroEmendas.collapse();
  };

  searchWithPage = page => {
    const { searchEmendas } = this.props;

    let filter = this.montaFilter(page);

    searchEmendas(filter);
  };

  onChange = event => {
    const { changeFieldValue } = this.props;

    Fields.fireChange(changeFieldValue, event);

    this.onChangeFiltro(event);
  };

  onViewDetalhes = item => {
    const id = item.get('id');

    this.props.history.push(`emendas-parlamentares/${id}`);
  };

  renderEmendas = data => {
    const key = `${data.get('entidade')}_${data.get('exercicio')}_${data.get(
      'id'
    )}}`;
    let vinculadoConvenioVar = '';

    if (data.get('vinculadoConvenio')) {
      vinculadoConvenioVar = 'S';
    }

    return (
      <TableActions
        ref="row"
        key={key}
        data={data}
        onMore={this.onViewDetalhes}
      >
        <td>{data.get('autoria')}</td>
        <td>{data.get('tipo')}</td>
        <td>
          <LinkToConvenio
            entidade={data.get('entidade')}
            nrConvenio={data.get('numero')}
            nrAnoConvenio={data.get('ano')}
            historyConvenio={this.props.history}
            vinculadoConvenio={vinculadoConvenioVar}
          />
        </td>
        <td>{data.get('ano')}</td>
        <td title={data.get('objeto')}>
          {compactText(data.get('objeto'), 110)}
        </td>
        <td>{data.get('funcao')}</td>
        <td className="right">
          <NumberFormatter value={data.get('valorPrevisto')} />
        </td>
        <td className="right">
          <NumberFormatter value={data.get('valorRealizado')} />
        </td>
      </TableActions>
    );
  };

  onExporta = (exportType, openTab, windowParams) => {
    const { page, addErrorNotification } = this.props;
    const { apiURL } = configuration;

    if (page.get('content').size > 0) {
      let filtro = this.montaFilter(page) + '&exportType=' + exportType;

      window.open(
        `${apiURL}/api/emendas-parlamentares/report?${filtro}`,
        openTab,
        windowParams
      );
    } else {
      addErrorNotification(
        'A consulta não apresentou resultado. Não é possível exportar para o formato selecionado!'
      );
    }
  };

  render() {
    const { page, filtros } = this.props;

    if (!page.has('content')) {
      return null;
    }

    return (
      <section>
        <PageHeader location={this.props.location} />

        <NotasExplicativas pathname={this.props.location.pathname} />

        <Panel
          title="Consulta Emendas Parlamentares"
          isForm
          mobileClose
          ref="panelFiltroEmendas"
        >
          <form>
            <Row>
              <Col xs={12} sm={4} md={3}>
                <Input
                  id="autoria"
                  name="autoria"
                  ref="autoria"
                  placeholder="Autoria"
                  label="Autoria"
                  type="text"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('autoria')}
                />
              </Col>
              <Col xs={12} sm={4} md={3}>
                <Input
                  id="tipo"
                  name="tipo"
                  ref="tipo"
                  placeholder="Tipo de Emenda"
                  label="Tipo de Emenda"
                  type="text"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('tipo')}
                />
              </Col>
              <Col xs={6} sm={4} md={3}>
                <Input
                  id="numero"
                  name="numero"
                  ref="numero"
                  placeholder="Número"
                  label="Número"
                  type="number"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('numero')}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={6} sm={4} md={3}>
                <Input
                  id="exercicio"
                  name="exercicio"
                  ref="exercicio"
                  placeholder="Exercício"
                  label="Exercício"
                  type="number"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('exercicio')}
                />
              </Col>
              <Col xs={12} sm={12} md={3}>
                <Input
                  id="funcao"
                  name="funcao"
                  ref="funcao"
                  placeholder="Função"
                  label="Função"
                  type="text"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('funcao')}
                />
              </Col>
              <Col xs={12} sm={12} md={9}>
                <Input
                  id="objeto"
                  type="text"
                  ref="objeto"
                  name="objeto"
                  placeholder="Objeto"
                  label="Objeto"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('objeto')}
                />
              </Col>
              <Col xs={12} sm={4} md={3}>
                <FilterButtons
                  idBtnReset="btnResetFilter"
                  onConsulta={this.onConsultaEmendas}
                  onReset={this.onResetFiltro}
                />
              </Col>
            </Row>
          </form>
        </Panel>

        <Panel isForm expansible={false} tableResponsive>
          <Row>
            <Col xs={4}>
              <UltimaAtualizacao modulo="3" tipo="emendaParlamentar" />
            </Col>
            <Col xs={8}>
              <ExportButtonGroup onClick={this.onExporta} visible={true} />
            </Col>
          </Row>

          <div className="panel-table-outer">
            <table className="panel-table striped fancy">
              <TableHeader
                text="Não foi encontrada nenhuma emenda."
                empty={page.get('content').isEmpty()}
              >
                <tr>
                  <TableHeaderItem
                    field="autoria"
                    label="Autoria"
                    page={page}
                    searchWithPage={this.searchWithPage}
                  />
                  <TableHeaderItem
                    field="tipo"
                    label="Tipo de Emenda"
                    page={page}
                    searchWithPage={this.searchWithPage}
                  />
                  <TableHeaderItem
                    field="numero"
                    label="Número"
                    page={page}
                    searchWithPage={this.searchWithPage}
                  />
                  <TableHeaderItem
                    field="exercicio"
                    label="Ano"
                    page={page}
                    searchWithPage={this.searchWithPage}
                  />
                  <TableHeaderItem
                    field="objeto"
                    label="Objeto"
                    page={page}
                    searchWithPage={this.searchWithPage}
                  />
                  <TableHeaderItem
                    field="funcao"
                    label="Função"
                    page={page}
                    searchWithPage={this.searchWithPage}
                  />
                  <TableHeaderItem
                    field="valorPrevisto"
                    label="Valor Previsto"
                    page={page}
                    searchWithPage={this.searchWithPage}
                    alignment="right"
                  />
                  <TableHeaderItem
                    field="valorRealizado"
                    label="Valor Realizado"
                    page={page}
                    searchWithPage={this.searchWithPage}
                    alignment="right"
                  />
                  <th />
                </tr>
              </TableHeader>
              <tbody>{page.get('content').map(this.renderEmendas)}</tbody>
            </table>
          </div>
        </Panel>

        <SearchPagination page={page} searchWithPage={this.searchWithPage} />
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    page: state.emendaParlamentar.get('emendas'),
    filtros: state.crud.getIn(['filtroEmendas', 'currentRecord'], Map({}))
  };
}

const allActions = {
  ...actions,
  changeFieldValue,
  onChange,
  resetCrud,
  downloadArquivo,
  addErrorNotification
};

export default connect(mapStateToProps, allActions)(FormEmendaParlamentar);
