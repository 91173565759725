import { Map } from 'immutable';
import React from 'react';
import Component from 'react-pure-render/component';
import PageHeader from '../components/PageHeader.react';
import NotasExplicativas from '../notaexplicativa/Form.react';
import { Panel } from '@elotech/components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from './Actions.js';
import { Col, Row } from 'react-bootstrap';
import Input from 'portaltransparencia-common/components/EloInput.react.js';
import DatePicker from 'portaltransparencia-common/components/DatePicker.react';
import {
  onChange,
  resetCrud
} from 'portaltransparencia-common/crud/Actions.js';
import {
  addFilterUrl,
  addPagination
} from 'portaltransparencia-common/libs/AddFilter';
import storageService from 'portaltransparencia-common/libs/StorageService';
import ExportButtonGroup from 'portaltransparencia-common/components/ExportButtonGroup.react';
import Tabs from 'portaltransparencia-common/components/Tabs.react';
import Tab from 'portaltransparencia-common/components/Tab.react';
import UltimaAtualizacaoDataConsulta from '../ultimaatualizacao/UltimaAtualizacaoDataConsulta.react.js';
import configuration from 'portaltransparencia-common/config/Config';
import ProcessoTable from './ProcessoTable';
import { addErrorNotification } from 'portaltransparencia-common/ui/Actions.js';

export class FormProcessosSigilosos extends Component {
  static propTypes = {
    pageEmSigilo: PropTypes.object.isRequired,
    pageDesclassificados: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    searchProcessosSigilosos: PropTypes.func.isRequired,
    searchProcessosDesclassificados: PropTypes.func.isRequired,
    filtros: PropTypes.object,
    onChange: PropTypes.func,
    resetCrud: PropTypes.func.isRequired,
    addErrorNotification: PropTypes.func.isRequired
  };

  componentDidMount() {
    const { pageEmSigilo } = this.props;

    this.searchWithPage(pageEmSigilo);
  }

  getSelectedTabDesclassificados() {
    return this.refs.tab?.getActiveTabName() === 'tabDesclassificados';
  }

  getPage() {
    const { pageEmSigilo, pageDesclassificados } = this.props;

    if (this.getSelectedTabDesclassificados()) {
      return pageDesclassificados;
    }

    return pageEmSigilo;
  }

  onChangeFiltro = event => {
    const { onChange } = this.props;

    onChange('filtroProcessos', event);
  };

  onConsultaProcessos = event => {
    const initialPage = this.getPage().set('number', 0);

    this.searchWithPage(initialPage);

    event && event.preventDefault();
    event && event.stopPropagation();

    this.refs.panelFiltroProcessos && this.refs.panelFiltroProcessos.collapse();
  };

  onResetFiltro = event => {
    const { resetCrud } = this.props;
    const initialPage = this.getPage().set('number', 0);

    event && event.preventDefault();
    event && event.stopPropagation();

    resetCrud('filtroProcessos');
    this.searchWithPage(initialPage);
  };

  onChangeTab = () => {
    const initialPage = this.getPage().set('number', 0);
    this.searchWithPage(initialPage);
  };

  montaFilter = function (page) {
    const { filtros } = this.props;

    let filter = '';

    if (filtros.get('tipoGrauSigilo')) {
      filter = addFilterUrl(
        filter,
        `tipoGrauSigilo=${filtros.get('tipoGrauSigilo')}`
      );
    }

    if (filtros.get('dataInicialAbertura')) {
      filter = addFilterUrl(
        filter,
        `dataInicialAbertura=${filtros.get('dataInicialAbertura')}`
      );
    }

    if (filtros.get('dataFinalAbertura')) {
      filter = addFilterUrl(
        filter,
        `dataFinalAbertura=${filtros.get('dataFinalAbertura')}`
      );
    }

    if (filtros.get('dataInicialDesclassificacao')) {
      filter = addFilterUrl(
        filter,
        `dataInicialDesclassificacao=${filtros.get(
          'dataInicialDesclassificacao'
        )}`
      );
    }

    if (filtros.get('dataFinalDesclassificacao')) {
      filter = addFilterUrl(
        filter,
        `dataFinalDesclassificacao=${filtros.get('dataFinalDesclassificacao')}`
      );
    }

    return addPagination(page, filter);
  };

  searchWithPage = (page, props) => {
    const { searchProcessosSigilosos, searchProcessosDesclassificados } =
      props || this.props;

    this.getSelectedTabDesclassificados()
      ? searchProcessosDesclassificados(this.montaFilter(page))
      : searchProcessosSigilosos(this.montaFilter(page));
  };

  onExporta = (exportType, openTab, windowParams) => {
    const { entidade } = storageService.getItem('userInfo');
    const { apiURL } = configuration;
    const { pageEmSigilo, addErrorNotification } = this.props;

    if (pageEmSigilo.get('content')?.size > 0) {
      let filtro = this.getSelectedTabDesclassificados
        ? 'desclassificado=true&'
        : 'sigiloso=true&';

      filtro += this.montaFilter(this.getPage()) + '&exportType=' + exportType;

      window.open(
        `${apiURL}/api/processos/report/sigilosos?entidade=${entidade.id}&${filtro}`,
        openTab,
        windowParams
      );
    } else {
      addErrorNotification(
        'A consulta não apresentou resultado. Não é possível exportar para o formato selecionado!'
      );
    }
  };

  render() {
    const {
      pageEmSigilo,
      pageDesclassificados,
      location,
      filtros
    } = this.props;

    if (!pageEmSigilo.has('content') && !pageDesclassificados.has('content')) {
      return null;
    }

    return (
      <section>
        <PageHeader location={location} />

        <NotasExplicativas pathname={location.pathname} />

        <Panel
          title="Consultar em Processos Sigilosos"
          mobileClose
          isForm
          ref="panelFiltroProcessos"
        >
          <form>
            <Row>
              <Col xs={12} sm={4} md={3}>
                <DatePicker
                  id="dataInicialAbertura"
                  name="dataInicialAbertura"
                  ref="dataInicialAbertura"
                  label="Início da Data de Abertura"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('dataInicialAbertura')}
                ></DatePicker>
              </Col>
              <Col xs={12} sm={4} md={3}>
                <DatePicker
                  id="dataFinalAbertura"
                  name="dataFinalAbertura"
                  ref="dataFinalAbertura"
                  label="Fim da Data de Abertura"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('dataFinalAbertura')}
                ></DatePicker>
              </Col>
              <Col xs={12} sm={4} md={3}>
                <DatePicker
                  id="dataInicialDesclassificacao"
                  name="dataInicialDesclassificacao"
                  ref="dataInicialDesclassificacao"
                  label="Início da Data de Desclassificação"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('dataInicialDesclassificacao')}
                ></DatePicker>
              </Col>
              <Col xs={12} sm={4} md={3}>
                <DatePicker
                  id="dataFinalDesclassificacao"
                  name="dataFinalDesclassificacao"
                  ref="dataFinalDesclassificacao"
                  label="Fim da Data de Desclassificação"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('dataFinalDesclassificacao')}
                ></DatePicker>
              </Col>
              <Col xs={12} sm={4} md={3}>
                <Input
                  id="tipoGrauSigilo"
                  placeholder="Informe o grau de sigilo"
                  type="select"
                  ref="tipoGrauSigilo"
                  name="tipoGrauSigilo"
                  label="Grau de Sigilo"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('tipoGrauSigilo')}
                >
                  <option value="">Selecione</option>
                  <option key={'RESERVADA'} value={'RESERVADA'}>
                    RESERVADA
                  </option>
                  <option key={'SECRETA'} value={'SECRETA'}>
                    SECRETA
                  </option>
                  <option key={'ULTRASSECRETA'} value={'ULTRASSECRETA'}>
                    ULTRASSECRETA
                  </option>
                </Input>
              </Col>
              <Col xs={12} sm={12} md={12}>
                <div className="form-group">
                  <label className="label" />

                  <div className="btn-group">
                    <button
                      id="btnFiltrar"
                      className="btn module-color icon-right"
                      ref="filtrar"
                      onClick={this.onConsultaProcessos}
                    >
                      Pesquisar
                      <em className="fa fa-search" />
                    </button>
                    <button
                      id="btnReset"
                      className="btn neutral icon-right inline no-text"
                      type="button"
                      onClick={this.onResetFiltro}
                    >
                      Limpar
                      <em className="fa fa-times" />
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </form>
        </Panel>

        <Panel isForm tableResponsive expansible={false}>
          <Row>
            <Col xs={6}>
              <UltimaAtualizacaoDataConsulta />
            </Col>
            <Col xs={6}>
              <ExportButtonGroup onClick={this.onExporta} visible={true} />
            </Col>
          </Row>

          <Tabs ref="tab" name="tab" onTabClick={this.onChangeTab}>
            <Tab title="Em Sigilo" name="tabEmSigilo" active onlyTable>
              <ProcessoTable
                page={pageEmSigilo}
                searchWithPage={this.searchWithPage}
              />
            </Tab>
            <Tab title="Desclassificados" name="tabDesclassificados" onlyTable>
              <ProcessoTable
                page={pageDesclassificados}
                searchWithPage={this.searchWithPage}
              />
            </Tab>
          </Tabs>
        </Panel>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    pageEmSigilo: state.processoSigiloso.get('sigilosos'),
    pageDesclassificados: state.processoSigiloso.get('desclassificados'),
    filtros: state.crud.getIn(['filtroProcessos', 'currentRecord'], Map({}))
  };
}

const allActions = {
  ...actions,
  onChange,
  resetCrud,
  addErrorNotification
};

export default connect(mapStateToProps, allActions)(FormProcessosSigilosos);
