import $ from 'jquery';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import Tree from 'portaltransparencia-common/components/tree/Tree.react.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import { downloadArquivo } from '../arquivo/Actions.js';
import PageHeader from '../components/PageHeader.react.js';
import NotasExplicativas from '../notaexplicativa/Form.react';
import UltimaAtualizacaoByTable from '../ultimaatualizacao/UltimaAtualizacaoByTable.react.js';
import * as actions from './Actions.js';
import PublicacaoFiltro from './PublicacaoFiltro.react';

export class FormPublicacao extends Component {
  static propTypes = {
    params: PropTypes.object,
    publicacoes: PropTypes.object,
    findPublicacoes: PropTypes.func.isRequired,
    filterPublicacoes: PropTypes.func.isRequired,
    resetPublicacoes: PropTypes.func.isRequired,
    downloadArquivo: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    findPublicacoesGrupo: PropTypes.func.isRequired,
    findPublicacoesGrupoSubGrupo: PropTypes.func.isRequired,
    publicacoesGrupo: PropTypes.object,
    isIntegradoOxy: PropTypes.bool
  };

  componentDidMount() {
    $('.tree .tree-list .tree-list-item')
      .find('.tree-list')
      .parent()
      .addClass('has-ul');
    $('.tree .tree-list .tree-list-label').click(function () {
      $(this).parent('.has-ul').toggleClass('open');
    });

    const {
      findPublicacoes,
      findPublicacoesGrupo,
      findPublicacoesGrupoSubGrupo,
      match: {
        params: { idGrupo, idSubGrupo }
      }
    } = this.props;
    if (idSubGrupo) {
      findPublicacoesGrupoSubGrupo(idGrupo, idSubGrupo);
    } else if (idGrupo) {
      findPublicacoesGrupo(idGrupo);
    } else {
      findPublicacoes();
    }
  }

  render() {
    const {
      publicacoes,
      downloadArquivo,
      match: {
        params: { idGrupo }
      },
      publicacoesGrupo,
      findPublicacoes,
      filterPublicacoes,
      resetPublicacoes
    } = this.props;

    let list = publicacoes;
    let expanded = false;

    if (idGrupo) {
      list = publicacoesGrupo;
      expanded = true;
    }

    return (
      <section>
        <PageHeader location={this.props.location} />

        <NotasExplicativas pathname={this.props.location.pathname} />

        <PublicacaoFiltro
          show={!idGrupo}
          findPublicacoes={findPublicacoes}
          filterPublicacoes={filterPublicacoes}
          resetPublicacoes={resetPublicacoes}
        />

        <Panel expansible={false}>
          <UltimaAtualizacaoByTable
            modulo="3"
            table="eloportalsubgrupopublicacao"
            column="dataultimaatualizacao"
          />
          <Tree
            list={list}
            onClick={item => downloadArquivo(item)}
            text="Nenhuma publicação encontrada."
            expanded={expanded}
          />
        </Panel>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    isIntegradoOxy: state.entidade.getIn([
      'configuracoesGerais',
      'isIntegradoOxy'
    ]),
    publicacoes: state.publicacao.get('publicacoesFiltradas'),
    publicacoesGrupo: state.publicacao.get('publicacoesGrupo')
  };
}

const allActions = {
  ...actions,
  downloadArquivo
};

export default connect(mapStateToProps, allActions)(FormPublicacao);
