import Input from 'portaltransparencia-common/components/EloInput.react.js';
import Validation from 'portaltransparencia-common/components/Validation.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export class FormSolicitacaoDetailEndereco extends Component {
  static propTypes = {
    dados: PropTypes.object,
    cadastrada: PropTypes.any,
    onChangeDados: PropTypes.func.isRequired,
    errors: PropTypes.object
  };

  getValidatorData = () => {
    const { logradouro, numero, bairro, cidade, cep, uf } = this.refs;

    const dataValidator = {
      logradouro: logradouro.getValue(),
      numero: numero.getValue(),
      bairro: bairro.getValue(),
      cidade: cidade.getValue(),
      cep: cep.getValue(),
      uf: uf.getValue()
    };

    return dataValidator;
  };

  isEmpty = value => {
    return value === '' || value === undefined ? true : false;
  };

  isValidaErro = () => {
    const { dados, errors } = this.props;

    const naoTemRegistros =
      this.isEmpty(dados.get('logradouro')) &&
      this.isEmpty(dados.get('numero')) &&
      this.isEmpty(dados.get('bairro')) &&
      this.isEmpty(dados.get('cep')) &&
      this.isEmpty(dados.get('cidade')) &&
      this.isEmpty(dados.get('uf')) &&
      this.isEmpty(dados.get('complemento'));

    return naoTemRegistros ? undefined : errors;
  };

  render() {
    const { dados, onChangeDados } = this.props;

    return (
      <div>
        <Input
          label="Logradouro"
          type="text"
          name="logradouro"
          ref="logradouro"
          onChange={onChangeDados}
          value={dados.get('logradouro')}
          errors={this.isValidaErro()}
        />

        <Input
          label="Número"
          type="text"
          name="numero"
          ref="numero"
          onChange={onChangeDados}
          value={dados.get('numero')}
          errors={this.isValidaErro()}
        />

        <Input
          label="Bairro"
          type="text"
          name="bairro"
          ref="bairro"
          onChange={onChangeDados}
          value={dados.get('bairro')}
          errors={this.isValidaErro()}
        />

        <Input
          label="C.E.P."
          type="text"
          name="cep"
          ref="cep"
          onChange={onChangeDados}
          value={dados.get('cep')}
          maxLength="9"
          errors={this.isValidaErro()}
        />

        <Input
          label="Cidade"
          type="text"
          name="cidade"
          ref="cidade"
          onChange={onChangeDados}
          value={dados.get('cidade')}
          errors={this.isValidaErro()}
        />

        <Input
          label="U.F."
          type="select"
          name="uf"
          ref="uf"
          onChange={onChangeDados}
          value={dados.get('uf')}
          errors={this.isValidaErro()}
        >
          <option key="" value="">
            Selecione
          </option>
          <option key="AC" value="AC">
            Acre
          </option>
          <option key="AL" value="AL">
            Alagoas
          </option>
          <option key="AP" value="AP">
            Amapá
          </option>
          <option key="AM" value="AM">
            Amazonas
          </option>
          <option key="BA" value="BA">
            Bahia
          </option>
          <option key="CE" value="CE">
            Ceará
          </option>
          <option key="DF" value="DF">
            Distrito Federal
          </option>
          <option key="ES" value="ES">
            Espírito Santo
          </option>
          <option key="GO" value="GO">
            Goiás
          </option>
          <option key="MA" value="MA">
            Maranhão
          </option>
          <option key="MT" value="MT">
            Mato Grosso
          </option>
          <option key="MS" value="MS">
            Mato Grosso do Sul
          </option>
          <option key="MG" value="MG">
            Minas Gerais
          </option>
          <option key="PA" value="PA">
            Pará
          </option>
          <option key="PB" value="PB">
            Paraíba
          </option>
          <option key="PR" value="PR">
            Paraná
          </option>
          <option key="PE" value="PE">
            Pernambuco
          </option>
          <option key="PI" value="PI">
            Piauí
          </option>
          <option key="RJ" value="RJ">
            Rio de Janeiro
          </option>
          <option key="RN" value="RN">
            Rio Grande do Norte
          </option>
          <option key="RS" value="RS">
            Rio Grande do Sul
          </option>
          <option key="RO" value="RO">
            Rondônia
          </option>
          <option key="RR" value="RR">
            Roraima
          </option>
          <option key="SC" value="SC">
            Santa Catarina
          </option>
          <option key="SP" value="SP">
            São Paulo
          </option>
          <option key="SE" value="SE">
            Sergipe
          </option>
          <option key="TO" value="TO">
            Tocantins
          </option>
        </Input>

        <Input
          label="Complemento"
          type="text"
          name="complemento"
          ref="complemento"
          onChange={onChangeDados}
          value={dados.get('complemento')}
        />
      </div>
    );
  }
}

const validationSchema = {
  rules: {
    logradouro: 'required',
    numero: 'required',
    bairro: 'required',
    cidade: 'required',
    cep: 'required',
    uf: 'required'
  },
  labels: {
    logradouro: 'Logradouro',
    numero: 'Número',
    bairro: 'Bairro',
    cidade: 'Cidade',
    cep: 'C.E.P.',
    uf: 'U.F.'
  }
};

FormSolicitacaoDetailEndereco = Validation(validationSchema)(
  FormSolicitacaoDetailEndereco
);

export default FormSolicitacaoDetailEndereco;
