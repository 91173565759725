import { addHttp } from 'portaltransparencia-common/libs/LinkExterno.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import { withRouter } from 'react-router';

export class MenuLink extends Component {
  static propTypes = {
    children: PropTypes.node,
    to: PropTypes.string,
    onClick: PropTypes.func,
    active: PropTypes.bool,
    hasLink: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.state = {
      active: false
    };
  }

  onClick = e => {
    if (this.props.onClick) {
      this.props.onClick(e);
    }

    e.preventDefault();
    e.stopPropagation();

    if (this.props.hasLink && this.props.to) {
      window.open(addHttp(this.props.to));
    }

    if (this.props.to && !this.props.hasLink) {
      this.props.history.push(this.props.to);
    }

    this.setState({
      active: !this.state.active
    });
  };

  render() {
    return (
      <li className="item" onClick={this.onClick}>
        {this.props.children}
      </li>
    );
  }
}

export default withRouter(MenuLink);
