import StorageService from 'portaltransparencia-common/libs/StorageService.js';

import * as types from './Types.js';

export function searchResponsaveis(filter) {
  const { entidade } = StorageService.getItem('userInfo');
  const baseFilter = `entidade=${entidade.id}&`;

  return ({ fetch }) => ({
    type: types.RESPONSAVEIS_LIST,
    payload: {
      promise: fetch(`/api/responsaveis?${baseFilter}${filter}`)
    }
  });
}
