import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';

import * as types from './Types.js';

const initialState = fromJS({
  filtros: [],
  projetos: [],
  totais: {}
});

function avaliacaoPpaReducer(state = initialState, action) {
  switch (action.type) {
    case types.AVALIACAO_PPA_START: {
      return startLoad(state);
    }

    case types.AVALIACAO_PPA_ERROR: {
      return endLoad(state);
    }

    case types.AVALIACAO_PPA_SUCCESS: {
      const avaliacaoPPA = fromJS(action.payload);
      const projetos = avaliacaoPPA.get('projetos');
      const totais = fromJS({
        planejados: avaliacaoPPA.get('somaPlanejado'),
        atualizados: avaliacaoPPA.get('somaAtualizado'),
        executados: avaliacaoPPA.get('somaExecutado')
      });

      return endLoad(state.set('projetos', projetos).set('totais', totais));
    }
  }

  return state;
}

export default avaliacaoPpaReducer;
