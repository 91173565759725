import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

import PageHeader from '../components/PageHeader.react.js';
import { Link } from 'react-router-dom';

export class Acessibilidade extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired
  };

  onClickMapaSite = () => {
    this.props.history.push('/mapa-do-site');
  };

  render() {
    return (
      <section>
        <PageHeader location={this.props.location} />

        <div class="acessibilidade">
          <div id="#div-space">&nbsp;</div>
          <p class="titleItems">
            <strong>Visualização</strong>
          </p>
          <div id="#div-space">&nbsp;</div>
          <p class="descriptionTitle">
            Os seguintes recursos melhoram a visualização das informações:
          </p>

          <div id="#div-space">&nbsp;</div>
          <p class="tamanho-texto-titulo">
            <strong>Tamanho do texto</strong>
          </p>
          <div id="#div-space">&nbsp;</div>
          <div class="tamanho-texto-descricao">
            Para recursos relacionados ao tamanho do texto, utilize os botões
            localizados na parte superior direita.
          </div>
          <div id="#div-space">&nbsp;</div>

          <div class="panel table table-responsive">
            <ul class="accessibility-btns active">
              <div class="panel-body">
                <div class="panel-table-outer">
                  <table class="panel-table border">
                    <tbody>
                      <tr>
                        <td class="center" width="100px">
                          <li class="accessibility-btn btn-maximize">
                            <span class="accessibility-label">
                              Aumentar o tamanho do texto
                            </span>
                          </li>
                        </td>
                        <td> Aumentar o tamanho do texto </td>
                      </tr>
                      <tr>
                        <td class="center" width="100px">
                          <li class="accessibility-btn btn-minimize">
                            <span class="accessibility-label">
                              Diminuir o tamanho do texto
                            </span>
                          </li>
                        </td>
                        <td> Diminuir o tamanho do texto </td>
                      </tr>
                      <tr>
                        <td class="center" width="100px">
                          <li class="accessibility-btn btn-reset">
                            <span class="accessibility-label">
                              Redefinir ao tamanho normal do texto
                            </span>
                          </li>
                        </td>
                        <td>Redefinir ao tamanho normal do texto</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </ul>
          </div>

          <div id="#div-space">&nbsp;</div>
          <p class="alto-contraste-titulo">
            <strong>Alto-Contraste</strong>
          </p>
          <div id="#div-space">&nbsp;</div>
          <div class="alto-contraste-descricao">
            Para o recurso de alto-contraste, utilize o botão localizado na
            parte superior direita.
          </div>
          <div id="#div-space">&nbsp;</div>
          <div class="panel table table-responsive">
            <ul class="accessibility-btns active">
              <div class="panel-body">
                <div class=" panel-table-outer">
                  <table class="panel-table border">
                    <tbody>
                      <tr>
                        <td class="center" width="100px">
                          <li class="accessibility-btn btn-invert">
                            <span class="accessibility-label">
                              Inverter as cores do Portal da Transparência
                            </span>
                          </li>
                        </td>
                        <td>Inverter as cores do Portal da Transparência</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </ul>
          </div>

          <div id="#div-space">&nbsp;</div>
          <p class="libras-titulo">
            <strong>Libras</strong>
          </p>
          <div id="#div-space">&nbsp;</div>
          <div class="libras-descricao">
            O conteúdo do Portal da Transparência está acessível em Libras
            através do <a href="https://www.vlibras.gov.br/">VLibras</a>, o
            tradutor automático da Língua Portuguesa para a Língua Brasileira de
            Sinais. Utilize o botão localizado na parte superior direita.
          </div>
          <div id="#div-space">&nbsp;</div>
          <div class="panel table table-responsive">
            <ul class="accessibility-btns active">
              <div class="panel-body">
                <div class=" panel-table-outer">
                  <table class="panel-table border">
                    <tbody>
                      <tr>
                        <td class="center" width="100px">
                          <li class="accessibility-btn btn-sign-language">
                            <span class="accessibility-label">
                              Traduzir o texto para Libras
                            </span>
                          </li>
                        </td>
                        <td>Traduzir o texto para Libras</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </ul>
          </div>

          <div id="#div-space">&nbsp;</div>
          <p class="navegacao-titulo">
            <strong>Navegação</strong>
          </p>
          <div id="#div-space">&nbsp;</div>
          <div class="navegacao-descricao">
            Para conhecer as teclas de atalho disponíveis, utilize o
            <Link onClick={this.onClickMapaSite}> Mapa do Site </Link>.
          </div>
          <div id="#div-space">&nbsp;</div>
          <div id="#div-space">&nbsp;</div>

          <h3 class="leitores-titulo">
            <strong>Leitores</strong>
          </h3>
          <div id="#div-space">&nbsp;</div>
          <p class="ltexto-voz-titulo">
            <strong>Texto em Voz</strong>
          </p>
          <div id="#div-space">&nbsp;</div>
          <div class="texto-voz-descricao">
            Ative o recurso de conversão do texto para áudio por meio do botão
            localizado na parte superior direita e escute o conteúdo ao mover o
            cursor do mouse sobre o texto.
          </div>
          <div id="#div-space">&nbsp;</div>
          <div class="panel table table-responsive">
            <ul class="accessibility-btns active">
              <div class="panel-body">
                <div class=" panel-table-outer">
                  <table class="panel-table border">
                    <tbody>
                      <tr>
                        <td class="center" width="100px">
                          <li class="accessibility-btn btn-reader">
                            <span class="accessibility-label">
                              Converter o texto em áudio
                            </span>
                          </li>
                        </td>
                        <td>Converter o texto em áudio</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </ul>
          </div>
        </div>
      </section>
    );
  }
}

export default Acessibilidade;
