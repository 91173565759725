// Super simple sync / async validation based on chriso/validator.js
import ValidationError from './ValidationError';
import validator from 'validator';

// Subclass to add custom validations.
export default class Validation {
  // JSON to be validated.
  constructor(object) {
    this._object = object;
    this._prop = null;
    this._label = null;
    this._name = null;
    this._validator = validator;
    this.promise = Promise.resolve();
  }

  validate(callback, { required } = {}) {
    const prop = this._prop;
    const label = this._label;
    const name = this._name;
    const value = this._object[prop];
    const object = this._object;
    this.promise = this.promise.then(() => {
      if (required && !this._isEmptyString(value)) return;
      callback(value, prop, label, name, object);
    });
    return this;
  }

  _isEmptyString(value) {
    return !this._validator.toString(value).trim();
  }

  prop(prop) {
    this._prop = prop;
    this._label = prop;
    this._name = prop;
    return this;
  }

  label(label) {
    this._label = label;
    return this;
  }

  name(name) {
    this._name = name;
    return this;
  }

  required(getRequiredMessage) {
    return this.validate(
      (value, prop, label, name) => {
        const msg = getRequiredMessage
          ? getRequiredMessage(label, value)
          : this.getRequiredMessage(label, value);
        throw new ValidationError(msg, { prop, label, name });
      },
      { required: true }
    );
  }

  getRequiredMessage(label) {
    return `${label} é obrigatório.`;
  }

  email() {
    return this.validate((value, prop, label) => {
      if (this._validator.isEmail(value)) return;
      throw new ValidationError(`"${value}" não é um email válido.`, {
        prop,
        label
      });
    });
  }

  simplePassword() {
    return this.validate((value, prop, label) => {
      const minLength = 5;
      if (value.length >= minLength) return;
      throw new ValidationError(
        `Senha precisa conter ${minLength} caracteres.`,
        { prop, label }
      );
    });
  }
}
