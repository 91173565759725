import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';

import * as types from './Types.js';

const initialState = fromJS({
  sicFisico: {},
  mensagem: ''
});

function sicFisicoReducer(state = initialState, action) {
  switch (action.type) {
    case types.ARQUIVO_SIC_FISICO_START:
    case types.MENSAGEM_SIC_FISICO_START: {
      return startLoad(state);
    }

    case types.ARQUIVO_SIC_FISICO_SUCCESS: {
      return endLoad(state.set('sicFisico', fromJS(action.payload)));
    }

    case types.MENSAGEM_SIC_FISICO_SUCCESS: {
      return endLoad(state.set('mensagem', fromJS(action.payload)));
    }

    case types.ARQUIVO_SIC_FISICO_ERROR:
    case types.MENSAGEM_SIC_FISICO_ERROR: {
      return endLoad(state);
    }
  }

  return state;
}

export default sicFisicoReducer;
