import Panel from 'portaltransparencia-common/components/Panel.react.js';
import SumListField from 'portaltransparencia-common/components/SumListField.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import DateUtils from 'portaltransparencia-common/libs/DateUtils';
import NumberUtils from 'portaltransparencia-common/libs/NumberUtils';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export class GastoAbastecimentoTable extends Component {
  static propTypes = {
    gastosAbastecimento: PropTypes.object.isRequired
  };

  renderGastoAbastecimento = gastosAbastecimento => {
    const key = `${gastosAbastecimento.get('numero')}_${gastosAbastecimento.get(
      'data'
    )}_${gastosAbastecimento.get('item')}`;

    return (
      <tr key={key}>
        <td>{gastosAbastecimento.get('numero')}</td>
        <td>{DateUtils.transform(gastosAbastecimento.get('data'))}</td>
        <td>{`${gastosAbastecimento.get(
          'centroDestino'
        )} - ${gastosAbastecimento.get('centroDestinoDescricao')}`}</td>
        <td>{gastosAbastecimento.get('item')}</td>
        <td className="right">{gastosAbastecimento.get('kmAnterior')}</td>
        <td className="right">{gastosAbastecimento.get('kmAtual')}</td>
        <td className="right">{gastosAbastecimento.get('mediaCombustivel')}</td>
        <td className="right">
          {gastosAbastecimento.get('quantidade') &&
          gastosAbastecimento.get('quantidade') > 0
            ? NumberUtils.format(
                (gastosAbastecimento.get('kmAtual') -
                  gastosAbastecimento.get('kmAnterior')) /
                  gastosAbastecimento.get('quantidade')
              )
            : 0}
        </td>

        <td className="right">
          {NumberUtils.format(gastosAbastecimento.get('quantidade'))}
        </td>
        <td className="right">
          {NumberUtils.format(gastosAbastecimento.get('valor'))}
        </td>
        <td className="right">
          {NumberUtils.format(gastosAbastecimento.get('valorTotal'))}
        </td>
      </tr>
    );
  };

  render() {
    const { gastosAbastecimento } = this.props;

    return (
      <Panel title="Gastos com Abastecimento do Veículo" isTable>
        <div className="panel-table-outer">
          <table className="panel-table striped fancy">
            <TableHeader
              empty={gastosAbastecimento.isEmpty()}
              texto="Nenhum gasto encontrado."
            >
              <tr>
                <th>Número</th>
                <th>Data</th>
                <th>Centro Consumo</th>
                <th>Ítem</th>
                <th className="right">Km Anterior</th>
                <th className="right">Km</th>
                <th className="right">Média padrão</th>
                <th className="right">Média gasto</th>
                <th className="right">Qtde.</th>
                <th className="right">Valor</th>
                <th className="right">Valor Total</th>
              </tr>
            </TableHeader>
            <tbody>
              {gastosAbastecimento.map(this.renderGastoAbastecimento)}
            </tbody>
            {!gastosAbastecimento.isEmpty() && (
              <tfoot>
                <tr>
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  <td className="right">
                    <i>Total: </i>
                    <SumListField
                      fieldName="valorTotal"
                      list={gastosAbastecimento}
                    />
                  </td>
                </tr>
              </tfoot>
            )}
          </table>
        </div>
      </Panel>
    );
  }
}

export default GastoAbastecimentoTable;
