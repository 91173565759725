export const LICITACOES = 'LICITACOES';
export const LICITACOES_START = 'LICITACOES_START';
export const LICITACOES_SUCCESS = 'LICITACOES_SUCCESS';
export const LICITACOES_ERROR = 'LICITACOES_ERROR';

export const CONSULTA_LICITACOES = 'CONSULTA_LICITACOES';
export const CONSULTA_LICITACOES_START = 'CONSULTA_LICITACOES_START';
export const CONSULTA_LICITACOES_SUCCESS = 'CONSULTA_LICITACOES_SUCCESS';
export const CONSULTA_LICITACOES_ERROR = 'CONSULTA_LICITACOES_ERROR';

export const MODALIDADES = 'MODALIDADES';
export const MODALIDADES_START = 'MODALIDADES_START';
export const MODALIDADES_SUCCESS = 'MODALIDADES_SUCCESS';
export const MODALIDADES_ERROR = 'MODALIDADES_ERROR';

export const LICITACAO = 'LICITACAO';
export const LICITACAO_START = 'LICITACAO_START';
export const LICITACAO_SUCCESS = 'LICITACAO_SUCCESS';
export const LICITACAO_ERROR = 'LICITACAO_ERROR';

export const ARQUIVOS_LICITACAO = 'ARQUIVOS_LICITACAO';
export const ARQUIVOS_LICITACAO_START = 'ARQUIVOS_LICITACAO_START';
export const ARQUIVOS_LICITACAO_SUCCESS = 'ARQUIVOS_LICITACAO_SUCCESS';
export const ARQUIVOS_LICITACAO_ERROR = 'ARQUIVOS_LICITACAO_ERROR';

export const MIDIAS = 'MIDIAS';
export const MIDIAS_START = 'MIDIAS_START';
export const MIDIAS_SUCCESS = 'MIDIAS_SUCCESS';
export const MIDIAS_ERROR = 'MIDIAS_ERROR';

export const PUBLICACOES = 'PUBLICACOES';
export const PUBLICACOES_START = 'PUBLICACOES_START';
export const PUBLICACOES_SUCCESS = 'PUBLICACOES_SUCCESS';
export const PUBLICACOES_ERROR = 'PUBLICACOES_ERROR';

export const CERTIDOES = 'CERTIDOES';
export const CERTIDOES_START = 'CERTIDOES_START';
export const CERTIDOES_SUCCESS = 'CERTIDOES_SUCCESS';
export const CERTIDOES_ERROR = 'CERTIDOES_ERROR';

export const HOMOLOGACOES = 'HOMOLOGACOES';
export const HOMOLOGACOES_START = 'HOMOLOGACOES_START';
export const HOMOLOGACOES_SUCCESS = 'HOMOLOGACOES_SUCCESS';
export const HOMOLOGACOES_ERROR = 'HOMOLOGACOES_ERROR';

export const AUTORIZACOES = 'AUTORIZACOES';
export const AUTORIZACOES_START = 'AUTORIZACOES_START';
export const AUTORIZACOES_SUCCESS = 'AUTORIZACOES_SUCCESS';
export const AUTORIZACOES_ERROR = 'AUTORIZACOES_ERROR';

export const COTACOES = 'COTACOES';
export const COTACOES_START = 'COTACOES_START';
export const COTACOES_SUCCESS = 'COTACOES_SUCCESS';
export const COTACOES_ERROR = 'COTACOES_ERROR';

export const PARECERES = 'PARECERES';
export const PARECERES_START = 'PARECERES_START';
export const PARECERES_SUCCESS = 'PARECERES_SUCCESS';
export const PARECERES_ERROR = 'PARECERES_ERROR';

export const RECURSOS = 'RECURSOS';
export const RECURSOS_START = 'RECURSOS_START';
export const RECURSOS_SUCCESS = 'RECURSOS_SUCCESS';
export const RECURSOS_ERROR = 'RECURSOS_ERROR';

export const PRECOS = 'PRECOS';
export const PRECOS_START = 'PRECOS_START';
export const PRECOS_SUCCESS = 'PRECOS_SUCCESS';
export const PRECOS_ERROR = 'PRECOS_ERROR';

export const ADJUDICACOES = 'ADJUDICACOES';
export const ADJUDICACOES_START = 'ADJUDICACOES_START';
export const ADJUDICACOES_SUCCESS = 'ADJUDICACOES_SUCCESS';
export const ADJUDICACOES_ERROR = 'ADJUDICACOES_ERROR';

export const ATAS = 'ATAS';
export const ATAS_START = 'ATAS_START';
export const ATAS_SUCCESS = 'ATAS_SUCCESS';
export const ATAS_ERROR = 'ATAS_ERROR';

export const RESET_CONSULTA_LICITACOES = 'RESET_CONSULTA_LICITACOES';

export const MANUAL_FORNECEDOR = 'MANUAL_FORNECEDOR';
export const MANUAL_FORNECEDOR_START = 'MANUAL_FORNECEDOR_START';
export const MANUAL_FORNECEDOR_SUCCESS = 'MANUAL_FORNECEDOR_SUCCESS';
export const MANUAL_FORNECEDOR_ERROR = 'MANUAL_FORNECEDOR_ERROR';

export const SOLICITACAO_NOTIFICACAO = 'SOLICITACAO_NOTIFICACAO';
export const SOLICITACAO_NOTIFICACAO_START = 'SOLICITACAO_NOTIFICACAO_START';
export const SOLICITACAO_NOTIFICACAO_SUCCESS =
  'SOLICITACAO_NOTIFICACAO_SUCCESS';
export const SOLICITACAO_NOTIFICACAO_ERROR = 'SOLICITACAO_NOTIFICACAO_ERROR';

export const FORMULARIO_SOLICITACAO_NOTIFICACAO_RESET =
  'FORMULARIO_SOLICITACAO_NOTIFICACAO_RESET';

export const LINKS_LICITACAO = 'LINKS_LICITACAO';
export const LINKS_LICITACAO_START = 'LINKS_LICITACAO_START';
export const LINKS_LICITACAO_SUCCESS = 'LINKS_LICITACAO_SUCCESS';
export const LINKS_LICITACAO_ERROR = 'LINKS_LICITACAO_ERROR';

export const MENSAGEM_SOLICITACAO_LICITACAO = 'MENSAGEM_SOLICITACAO_LICITACAO';
export const MENSAGEM_SOLICITACAO_LICITACAO_START =
  'MENSAGEM_SOLICITACAO_LICITACAO_START';
export const MENSAGEM_SOLICITACAO_LICITACAO_SUCCESS =
  'MENSAGEM_SOLICITACAO_LICITACAO_SUCCESS';
export const MENSAGEM_SOLICITACAO_LICITACAO_ERROR =
  'MENSAGEM_SOLICITACAO_LICITACAO_ERROR';

export const LICITACAO_SEI = 'LICITACAO_SEI';
export const LICITACAO_SEI_START = 'LICITACAO_SEI_START';
export const LICITACAO_SEI_SUCCESS = 'LICITACAO_SEI_SUCCESS';
export const LICITACAO_SEI_ERROR = 'LICITACAO_SEI_ERROR';

export const NATUREZAS = 'NATUREZAS';
export const NATUREZAS_START = 'NATUREZAS_START';
export const NATUREZAS_SUCCESS = 'NATUREZAS_SUCCESS';
export const NATUREZAS_ERROR = 'NATUREZAS_ERROR';
