import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export class RecursoDetalheParecer extends Component {
  static propTypes = {
    recursoPareceres: PropTypes.object.isRequired
  };

  renderPareceres = data => {
    return (
      <TableActions ref="row" key={data.get('id')} data={data}>
        <td>{DateUtils.formatDateTimeShort(data.get('data'))}</td>
        <td>{data.get('tipo')}</td>
        <td>{data.get('descricao')}</td>
      </TableActions>
    );
  };

  render() {
    const { recursoPareceres } = this.props;

    return (
      <div className="panel-table-outer">
        <table className="panel-table striped fancy">
          <TableHeader empty={recursoPareceres.isEmpty()}>
            <tr>
              <th>Data</th>
              <th>Tipo</th>
              <th>Descrição</th>
            </tr>
          </TableHeader>
          <tbody>{recursoPareceres.map(this.renderPareceres)}</tbody>
        </table>
      </div>
    );
  }
}

export default RecursoDetalheParecer;
