import * as types from './Types.js';

export function findPrestacaoContas(
  entidade,
  exercicio,
  subvencao,
  anoSubvencao,
  sequencia
) {
  return ({ fetch }) => ({
    type: types.PRESTACAOCONTAS,
    payload: {
      promise: fetch(
        `/api/subvencoes/prestacao-contas/detalhe?entidade=${entidade}&exercicio=${exercicio}&subvencao=${subvencao}&anoSubvencao=${anoSubvencao}&sequencia=${sequencia}`
      )
    }
  });
}

export function findArquivos(
  entidade,
  exercicio,
  subvencao,
  anoSubvencao,
  sequencia
) {
  return ({ fetch }) => ({
    type: types.PRESTACAOCONTAS_ARQUIVOS,
    payload: {
      promise: fetch(
        `/api/subvencoes/prestacao-contas/arquivos?entidade=${entidade}&exercicio=${exercicio}&subvencao=${subvencao}&anoSubvencao=${anoSubvencao}&sequencia=${sequencia}`
      )
    }
  });
}
