import * as types from './Types.js';

export function findAtendimento(entidade = 0, exercicio = 0) {
  return ({ fetch }) => ({
    type: types.ATENDIMENTO,
    payload: {
      promise: fetch(`/atendimento/${entidade}/${exercicio}`)
    }
  });
}

export function findContatoResponsaveis(params) {
  return ({ fetch }) => ({
    type: types.CONTATOS,
    payload: {
      promise: fetch(`/api/contato-responsaveis?${params}`)
    }
  });
}

export function findParametrosRedesSociais(entidade) {
  return ({ fetch }) => ({
    type: types.PARAMS,
    payload: fetch(`/parametros/redes-sociais/${entidade}`)
  });
}

export function findConfigAparencia(filtro) {
  return ({ fetch }) => ({
    type: types.CONFIG_APARENCIA,
    payload: fetch('/portalconfigaparencia?' + filtro)
  });
}
