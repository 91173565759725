import { addFilterUrl } from 'portaltransparencia-common/libs/AddFilter.js';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';

export function buildFilterGaragem(params, page) {
  let userInfo = StorageService.getItem('userInfo');
  const { entidade, exercicio } = userInfo;

  let filter = `entidade=${entidade.id}&exercicio=${exercicio.id}`;

  const { letraPlaca, numeroPlaca, veiculo, data } = params;

  //Page request [number: 0, size 0, sort: '']
  if (page) {
    if (page.get('number')) {
      filter = addFilterUrl(filter, 'page=' + page.get('number'));
    }
    if (page.get('size')) {
      filter = addFilterUrl(filter, 'size=' + page.get('size'));
    }

    if (page.get('sort') && typeof page.get('sort') === 'string') {
      filter = addFilterUrl(filter, 'sort=' + page.get('sort'));
    }
  }

  if (letraPlaca != null && letraPlaca != '') {
    filter = addFilterUrl(filter, 'letraPlaca=' + letraPlaca);
  }
  if (numeroPlaca != null && numeroPlaca != '') {
    filter = addFilterUrl(filter, 'numeroPlaca=' + numeroPlaca);
  }
  if (veiculo != null && veiculo != '') {
    filter = addFilterUrl(filter, 'veiculo=' + veiculo);
  }
  if (data != null && data != '') {
    filter = addFilterUrl(filter, 'data=' + data);
  }

  return '?' + filter;
}
