import Component from 'react-pure-render/component';
import PropTypes from 'prop-types';
import React from 'react';

export class TextLink extends Component {
  static propTypes = {
    text: PropTypes.string.isRequired
  };

  render() {
    const { text } = this.props;

    let textArray = text.split(' ');
    let arrayFinal = [];

    textArray.forEach(element => {
      if (element.includes('http://') || element.includes('https://')) {
        arrayFinal.push(
          <a href={element} target="_blank">
            {element}
          </a>
        );
      } else {
        arrayFinal.push(element);
      }
      arrayFinal.push(' ');
    });

    return <div>{arrayFinal}</div>;
  }
}

export default TextLink;
