import { Map } from 'immutable';
import Input from 'portaltransparencia-common/components/EloInput.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import {
  onChange,
  resetCrud
} from 'portaltransparencia-common/crud/Actions.js';
import { addFilterUrl } from 'portaltransparencia-common/libs/AddFilter.js';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import FilterButtons from '../components/FilterButtons.react.js';
import PageHeader from '../components/PageHeader.react.js';
import NotasExplicativas from '../notaexplicativa/Form.react';
import * as actions from './Actions.js';
import AvaliacaoPpaProjetoPanel from './AvaliacaoPpaProjetoPanel.react.js';
import AvaliacaoPpaTotais from './AvaliacaoPpaTotais.react.js';

export class FormAvaliacaoPPA extends Component {
  static propTypes = {
    projetos: PropTypes.object.isRequired,
    totais: PropTypes.object.isRequired,
    findAvaliacaoPPA: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    filtros: PropTypes.object,
    resetCrud: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.props.findAvaliacaoPPA(this.montarFiltro());
  }

  montarFiltro() {
    const { filtros } = this.props;
    const { entidade, exercicio } = StorageService.getItem('userInfo');

    let filter = `entidade=${entidade.id}&exercicio=${exercicio.id}`;

    if (filtros.get('descricaoProjeto')) {
      filter = addFilterUrl(
        filter,
        `descricaoProjeto=${filtros.get('descricaoProjeto')}`
      );
    }

    return filter;
  }

  consultarAvaliacaoPPA = () => {
    const { findAvaliacaoPPA } = this.props;

    findAvaliacaoPPA(this.montarFiltro());
    this.refs.panelFiltroAvaliacao && this.refs.panelFiltroAvaliacao.collapse();
  };

  onChangeFiltro = event => {
    const { onChange } = this.props;

    onChange('filtroProjetos', event);
  };

  onResetFiltro = () => {
    const { resetCrud, findAvaliacaoPPA } = this.props;

    resetCrud('filtroProjetos');
    findAvaliacaoPPA('');
  };

  renderProjetos = projeto => {
    return (
      <AvaliacaoPpaProjetoPanel key={projeto.get('codigo')} projeto={projeto} />
    );
  };

  handleKeyDown = event => {
    const teclaEnter = 13;
    if (event.keyCode === teclaEnter) {
      this.consultarAvaliacaoPPA();
    }
  };

  render() {
    const { projetos, totais, filtros } = this.props;

    return (
      <section>
        <PageHeader location={this.props.location} />

        <NotasExplicativas pathname={this.props.location.pathname} />

        <Panel
          title="Consultar em Avaliação PPA"
          mobileClose
          isForm
          ref="panelFiltroAvaliacao"
        >
          <Row>
            <Col xs={12} sm={8} md={9}>
              <Input
                key="descricaoProjeto"
                id="descricaoProjeto"
                ref="descricaoProjeto"
                name="descricaoProjeto"
                placeholder="Descrição do Projeto"
                label="Descrição do Projeto"
                type="text"
                value={filtros.get('descricaoProjeto')}
                onChange={this.onChangeFiltro}
                onKeyDown={this.handleKeyDown}
              />
            </Col>
            <Col xs={12} sm={4} md={3}>
              <FilterButtons
                onConsulta={this.consultarAvaliacaoPPA}
                onReset={this.onResetFiltro}
              />
            </Col>
          </Row>
        </Panel>

        <Panel isForm title="Projetos" insidePanel>
          {projetos.map(this.renderProjetos)}
          {projetos.isEmpty() && (
            <div>
              <br />
              Sem registros.
            </div>
          )}
        </Panel>

        <AvaliacaoPpaTotais totais={totais} />
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    filtros: state.crud.getIn(['filtroProjetos', 'currentRecord'], Map({})),
    projetos: state.avaliacaoppa.get('projetos'),
    totais: state.avaliacaoppa.get('totais')
  };
}

const allActions = {
  ...actions,
  onChange,
  resetCrud
};

export default connect(mapStateToProps, allActions)(FormAvaliacaoPPA);
