import React from 'react';
import Component from 'react-pure-render/component';
import PropTypes from 'prop-types';
import { FormattedCurrency, Panel, Table } from '@elotech/components';
import * as types from './Types';
import { fromJS } from 'immutable';
import SearchPagination from 'portaltransparencia-common/components/SearchPagination.react';

class FooterResumo extends Component {
  constructor(props) {
    super(props);
  }

  static propTypes = {
    totalizador: PropTypes.object.isRequired
  };

  render() {
    const { totalizador } = this.props;

    return totalizador?.valorTotal ? (
      <tfoot>
        <tr>
          <td />
          <td />
          <td />
          <td />
          <td className="left">
            <i>Total Geral: </i>
            {<FormattedCurrency value={totalizador.valorTotal} />}
          </td>
        </tr>
      </tfoot>
    ) : (
      <></>
    );
  }
}
class RenunciaReceitasResumoList extends Component {
  constructor(props) {
    super(props);
    this.state = { sort: undefined };
  }

  static propTypes = {
    filter: PropTypes.object.isRequired,
    resumo: PropTypes.object.isRequired,
    search: PropTypes.func.isRequired,
    totalizador: PropTypes.object.isRequired
  };

  hasElements = () => {
    const { resumo } = this.props;

    return resumo?.totalElements > 0 && resumo.content !== undefined;
  };

  sortOnHeaderColumnClick = sort => {
    if (!this.hasElements()) return;

    const { search, filter, resumo } = this.props;
    const pageWithSort = fromJS({ ...resumo, ...sort });

    search(filter, pageWithSort).then(() => {
      this.setState({ sort: sort });
    });
  };

  searchWithPage = resumo => {
    const { search, filter } = this.props;
    const { sort } = this.state;
    const pageWithSort = fromJS({ ...resumo.toJS(), ...sort });

    search(filter, pageWithSort);
  };

  getTitlePanel = () => {
    const { filter } = this.props;
    return filter.tipoMovimentacaoTributo
      ? `Resumido - ${types.getLabelByTipo(filter.tipoMovimentacaoTributo)}`
      : 'Resumido';
  };

  render() {
    const { resumo, totalizador } = this.props;
    const {
      getTitlePanel,
      hasElements,
      sortOnHeaderColumnClick,
      searchWithPage
    } = this;

    return (
      <Panel isTable title={getTitlePanel()} mobileClose expansible>
        <Table
          keyExtractor={(_, index) => index}
          values={resumo?.content ?? []}
          sortOnHeaderColumnClick={sortOnHeaderColumnClick}
          footer={<FooterResumo totalizador={totalizador} />}
        >
          <Table.Column
            sortable
            name="classificacaoTributo"
            header="Tributo"
            value={value => value.classificacaoTributo}
          />
          <Table.Column
            sortable
            name="justificativa"
            header="Justificativa"
            value={value => value.justificativa}
          />
          <Table.Column
            sortable
            name="fundamentacao"
            header="Fundamentação Legal"
            value={value => value.fundamentacao}
          />
          <Table.Colum
            sortable
            name="tpDeducao"
            header="Tipo Dedução"
            value={value => value.tpDeducao}
          />
          <Table.Column
            sortable
            name="valorTotal"
            header="Valor Dedução"
            value={value => <FormattedCurrency value={value.valorTotal} />}
          />
        </Table>
        {hasElements() && (
          <SearchPagination
            page={fromJS(resumo)}
            searchWithPage={searchWithPage}
          />
        )}
      </Panel>
    );
  }
}

export default RenunciaReceitasResumoList;
