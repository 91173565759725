import Component from 'react-pure-render/component';
import PropTypes from 'prop-types';
import React from 'react';

export default class Hint extends Component {
  static propTypes = {
    color: PropTypes.string,
    position: PropTypes.string,
    size: PropTypes.string,
    isInline: PropTypes.bool,
    icon: PropTypes.string,
    message: PropTypes.string,
    messageComponent: PropTypes.object,
    spacing: PropTypes.string
  };

  static defaultProps = {
    color: 'module-color',
    position: 'bottom-right',
    size: 'md',
    isInline: false,
    icon: 'fa fa-question',
    spacing: 'ml-xs',
    mobile: false
  };

  render() {
    const {
      position,
      isInline,
      size,
      color,
      icon,
      message,
      messageComponent,
      spacing
    } = this.props;

    const inline = isInline ? 'inline' : '';

    return (
      <div
        className={`hint mobile ${position} ${inline} ${size} ${color} ${icon} ${spacing}`}
      >
        <div className="hint-content">{message || messageComponent}</div>
      </div>
    );
  }
}
