const storageService = {
  isTemporary(key) {
    return !!sessionStorage.getItem(key);
  },

  getItemAsJson(key) {
    return localStorage.getItem(key) || sessionStorage.getItem(key);
  },

  getItem(key) {
    const value = this.getItemAsJson(key);

    if (value) {
      try {
        return JSON.parse(value);
      } catch (e) {
        return;
      }
    }
  },
  saveItem(key, value, persistent = false) {
    if (!value) {
      return;
    }

    try {
      const storage = persistent ? localStorage : sessionStorage;

      storage.setItem(key, value);
    } catch (e) {
      return;
    }
  },
  saveItemAsJson(key, value, persistent = false) {
    if (!value) {
      return;
    }

    try {
      const storage = persistent ? localStorage : sessionStorage;

      storage.setItem(key, JSON.stringify(value));
    } catch (e) {
      return;
    }
  },
  removeItem(key) {
    localStorage.removeItem(key);
    sessionStorage.removeItem(key);
  }
};

export default storageService;
