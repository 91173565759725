import * as types from './Types.js';
import { FilterUtils } from '@elotech/components';

export function findProcessoTipo() {
  const esic = 'e-SIC';
  return ({ fetch }) => ({
    type: types.FIND_TIPOESIC,
    payload: {
      promise: fetch(`/api/processos/esic?usuario=${esic}`)
    }
  });
}

export function findProcessoTipoSic() {
  return ({ fetch }) => ({
    type: types.FIND_TIPOESIC,
    payload: {
      promise: fetch('/api/processos/esic')
    },
    meta: {
      fisico: true
    }
  });
}

export function resetConsulta() {
  return {
    type: types.RESET_CONSULTA_ESIC,
    payload: {}
  };
}

export function findSics(filtro) {
  return ({ fetch }) => ({
    type: types.ESIC,
    payload: {
      promise: fetch(`/api/processos/esic?${filtro}`)
    },
    meta: {
      fisico: true
    }
  });
}

export function findEsics(filtro) {
  const esic = 'e-SIC';

  return ({ fetch }) => ({
    type: types.ESIC,
    payload: {
      promise: fetch(`/api/processos/esic?usuario=${esic}&${filtro}`)
    }
  });
}

export function findProcesso(filtro) {
  return ({ fetch }) => ({
    type: types.PROCESSO,
    payload: {
      promise: fetch(`/api/processos?${filtro}`)
    },
    meta: {
      fisico: true
    }
  });
}

export function findSituacoes() {
  return ({ fetch }) => ({
    type: types.FIND_SITUACOES,
    payload: {
      promise: fetch('/api/processos/situacoes')
    }
  });
}

export function findAssuntos({ value }) {
  const search = value
    ? `?search=${FilterUtils.buildNumberAndStringFilter(
        ['id'],
        ['descricao']
      )(value)}`
    : '';

  return ({ fetch }) => ({
    type: types.FIND_ASSUNTOS,
    payload: {
      promise: fetch(`/api/processos/assuntos${search}`)
    }
  });
}
