import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';

import * as types from './Types.js';

const initialState = fromJS({
  arrecadacoes: [],
  totalDividaAtiva: {},
  arrecadacoesPorBairros: [],
  bairros: []
});

function arrecadacaoReducer(state = initialState, action) {
  switch (action.type) {
    case types.BAIRROS_START:
    case types.RECEITA_ARRECADACAO_BAIRROS_START:
    case types.TOTAL_DIVIDA_ATIVA_START:
    case types.RECEITA_ARRECADACAO_START: {
      return startLoad(state);
    }

    case types.BAIRROS_ERROR:
    case types.RECEITA_ARRECADACAO_BAIRROS_ERROR:
    case types.TOTAL_DIVIDA_ATIVA_ERROR:
    case types.RECEITA_ARRECADACAO_ERROR: {
      return endLoad(state);
    }

    case types.RECEITA_ARRECADACAO_SUCCESS: {
      return endLoad(state.set('arrecadacoes', fromJS(action.payload)));
    }

    case types.TOTAL_DIVIDA_ATIVA_SUCCESS: {
      return endLoad(state.set('totalDividaAtiva', fromJS(action.payload)));
    }

    case types.RECEITA_ARRECADACAO_BAIRROS_SUCCESS: {
      return endLoad(
        state.set('arrecadacoesPorBairros', fromJS(action.payload))
      );
    }

    case types.RESETAR_ARRECADACOES: {
      return state.set('arrecadacoesPorBairros', fromJS([]));
    }

    case types.BAIRROS_SUCCESS: {
      return endLoad(
        state.set(
          'bairros',
          fromJS(
            action.payload.map(b => {
              return { descricao: b };
            })
          )
        )
      );
    }
  }

  return state;
}

export default arrecadacaoReducer;
