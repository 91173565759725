import Widget from 'portaltransparencia-common/components/Widget.react.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';
import * as actions from './Actions.js';

export class FormSelo extends Component {
  static propTypes = {
    configAparencia: PropTypes.object,
    findConfigAparencia: PropTypes.func,
    entidade: PropTypes.object,
    exercicio: PropTypes.object
  };

  componentDidMount() {
    const { findConfigAparencia, entidade, exercicio } = this.props;

    findConfigAparencia(
      'entidade=' + entidade.get('id') + '&exercicio=' + exercicio.get('id')
    );
  }

  render() {
    const { configAparencia } = this.props;

    let dataAvaliacaoSelo = '';
    let processo = '';
    let nota = '';
    let selo = '';

    if (configAparencia && configAparencia.size > 0) {
      dataAvaliacaoSelo = DateUtils.formatDateTimeShort(
        configAparencia.get('seloDataAvaliacao')
      );
      processo = configAparencia.get('seloProcessoTCE');
      nota = configAparencia.get('seloNotaObtida');
      selo = configAparencia.get('selo');
    }

    return (
      <div>
        <Widget
          icon="fa fa-certificate"
          title={'Certificado de Qualidade em Transparência Pública'}
          noGradientBackground
        >
          <Row>
            {dataAvaliacaoSelo && (
              <Col sm={4} md={4}>
                <article className="widget-numeric block">
                  <b className="widget-numeric-number">{dataAvaliacaoSelo}</b>
                  <div className="widget-numeric-subtitle">
                    Data da Avaliação
                  </div>
                </article>
              </Col>
            )}
            {processo && (
              <Col sm={4} md={4}>
                <article className="widget-numeric block">
                  <b className="widget-numeric-number">{processo}</b>
                  <p className="widget-numeric-subtitle">Processo</p>
                </article>
              </Col>
            )}
            {nota && (
              <Col sm={4} md={4}>
                <article className="widget-numeric block">
                  <b className="widget-numeric-number">{nota + '%'}</b>
                  <div className="widget-numeric-subtitle">Nota</div>
                </article>
              </Col>
            )}
          </Row>
          {selo && (
            <Row className="mt-xs">
              <Col sm={4} smOffset={4}>
                <img
                  className="img-responsive mt-xs"
                  src={`data:image/*;base64,${selo}`}
                />
              </Col>
            </Row>
          )}
        </Widget>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    configAparencia: state.atendimento.get('configAparencia'),
    entidade: state.auth.getIn(['userInfo', 'entidade']),
    exercicio: state.auth.getIn(['userInfo', 'exercicio'])
  };
}

const allActions = {
  ...actions
};

export default connect(mapStateToProps, allActions)(FormSelo);
