import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';

import * as types from './Types.js';

const initialState = fromJS({
  acessibilidade: []
});

function acessibilidadeReducer(state = initialState, action) {
  switch (action.type) {
    case types.ACESSIBILIDADE_START: {
      return startLoad(state);
    }

    case types.ACESSIBILIDADE_ERROR: {
      return endLoad(state);
    }

    case types.ACESSIBILIDADE_SUCCESS: {
      return endLoad(state.set('acessibilidade', fromJS(action.payload)));
    }
  }

  return state;
}

export default acessibilidadeReducer;
