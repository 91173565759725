import ExportButtonGroup from 'portaltransparencia-common/components/ExportButtonGroup.react.js';
import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react.js';
import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import TableHeaderItem from 'portaltransparencia-common/components/TableHeaderItem.react';
import configuration from 'portaltransparencia-common/config/Config.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import LinkToEmpenho from '../empenhos/LinkToEmpenho.react';
import { addErrorNotification } from 'portaltransparencia-common/ui/Actions.js';
import { connect } from 'react-redux';
import * as actions from './Actions.js';

export class AgendaPagamentoListagem extends Component {
  static propTypes = {
    page: PropTypes.object,
    openDetail: PropTypes.func.isRequired,
    total: PropTypes.object,
    filter: PropTypes.string,
    tipo: PropTypes.string.isRequired,
    mensagem: PropTypes.string,
    searchWithPage: PropTypes.func,
    sortedUrl: PropTypes.string,
    filtradoFornecedor: PropTypes.bool,
    mostraDataPagamento: PropTypes.bool,
    findContaPagamento: PropTypes.func.isRequired,
    findRetencoesLiquidacao: PropTypes.func.isRequired,
    addErrorNotification: PropTypes.func.isRequired
  };

  fornecedorUnico = true;

  onExporta = (exportType, openTab, windowParams) => {
    const { apiURL } = configuration;
    const { filter, tipo, sortedUrl, page, addErrorNotification } = this.props;
    const { exercicio } = StorageService.getItem('userInfo');

    if (page.get('content').size > 0) {
      let filtro = `${filter}&exportType=${exportType}&exercicio=${exercicio.id}`;

      if (sortedUrl) {
        filtro = `${filtro}&sort=${sortedUrl}`;
      }

      window.open(
        `${apiURL}/api/liquidacoes/${tipo}/report?${filtro}`,
        openTab,
        windowParams
      );
    } else {
      addErrorNotification(
        'A consulta não apresentou resultado. Não é possível exportar para o formato selecionado!'
      );
    }
  };

  onViewContas = item => {
    const {
      findContaPagamento,
      openDetail,
      findRetencoesLiquidacao
    } = this.props;

    const filtro = `entidade=${item.get('entidade')}&exercicio=${item.get(
      'exercicio'
    )}&noOrdem=${item.get('noOrdem')}&sequencia=${item.get('sequencia')}`;

    let filter =
      '?search=id.entidade==' +
      item.get('entidade') +
      '&entidade=' +
      item.get('entidade') +
      '&exercicio=' +
      item.get('exercicio') +
      '&empenho=' +
      item.get('empenho') +
      '&noLiquidacao=' +
      item.get('noLiquidacao');

    findRetencoesLiquidacao(filter);

    if (item.get('noOrdem') && item.get('sequencia')) {
      findContaPagamento(filtro).then(() => {
        openDetail();
      });
    }
  };

  generateKey = liquidacao => {
    return `${liquidacao.get('ordemFonteRecurso')}_
            ${liquidacao.get('entidade')}_
            ${liquidacao.get('exercicio')}_
            ${liquidacao.get('empenhoExercicio')}_`;
  };

  renderTableLiquidacoes = data => {
    const { tipo, filtradoFornecedor, mostraDataPagamento } = this.props;
    return (
      <TableActions
        ref="row"
        key={this.generateKey(data)}
        data={data}
        onMore={this.onViewContas}
      >
        {!(filtradoFornecedor && this.fornecedorUnico) && (
          <td>{data.get('fornecedor')}</td>
        )}
        {tipo === 'aptos-pagamentos' && (
          <td>{data.get('ordemFonteRecurso')}</td>
        )}
        <td>{data.get('fonteRecurso')}</td>
        <td>{data.get('tipoLicitacao')}</td>
        <td className="right">{data.get('licitacao')}</td>
        <td className="right">
          <LinkToEmpenho
            entidade={data.get('entidade')}
            exercicio={data.get('exercicio')}
            empenho={data.get('empenho')}
          />
        </td>
        <td className="right">{data.get('noLiquidacao')}</td>
        <td>{data.get('notasFiscais')}</td>
        <td className="right">
          {DateUtils.formatDateTimeShort(data.get('dataVencimento'))}
        </td>
        {mostraDataPagamento && (
          <td className="right">
            {DateUtils.formatDateTimeShort(data.get('dataPagamento'))}
          </td>
        )}
        <td className="right">
          <NumberFormatter value={data.get('valor')} />
        </td>
        <td className="right">
          <NumberFormatter value={data.get('saldo')} />
        </td>
      </TableActions>
    );
  };

  render() {
    const {
      total,
      mensagem,
      page,
      searchWithPage,
      tipo,
      filtradoFornecedor,
      mostraDataPagamento
    } = this.props;
    let fornecedor = '';

    if (!page.get('content')) {
      return null;
    }

    if (filtradoFornecedor) {
      page.get('content').forEach(item => {
        if (fornecedor == '') {
          fornecedor = item.get('fornecedor');
          this.fornecedorUnico = true;
        } else if (fornecedor != item.get('fornecedor')) {
          this.fornecedorUnico = false;
        }
      });
    }

    return (
      <section>
        <Row>
          <Col xs={6} />
          <Col xs={6}>
            <ExportButtonGroup onClick={this.onExporta} visible={true} />
          </Col>
        </Row>

        <div className="panel-table-outer">
          <table className="panel-table striped fancy">
            <TableHeader empty={page.get('content').isEmpty()} text={mensagem}>
              <tr>
                {!(filtradoFornecedor && this.fornecedorUnico) && (
                  <TableHeaderItem
                    field="fornecedor"
                    label="Fornecedor"
                    page={page}
                    searchWithPage={searchWithPage}
                    alignment="left"
                  />
                )}
                {tipo === 'aptos-pagamentos' && (
                  <TableHeaderItem
                    field="ordemFonteRecurso"
                    label="Ordem"
                    page={page}
                    searchWithPage={searchWithPage}
                    alignment="left"
                  />
                )}
                <TableHeaderItem
                  field="fonteRecurso"
                  label="Fonte Recurso"
                  page={page}
                  searchWithPage={searchWithPage}
                  alignment="left"
                />
                <TableHeaderItem
                  field="tipoLicitacao"
                  label="Tipo Licitação"
                  page={page}
                  searchWithPage={searchWithPage}
                  alignment="left"
                />
                <TableHeaderItem
                  field="licitacao"
                  label="Licitação"
                  page={page}
                  searchWithPage={searchWithPage}
                  alignment="left"
                />
                <TableHeaderItem
                  field="empenho"
                  label="Empenho/Ano"
                  page={page}
                  searchWithPage={searchWithPage}
                  alignment="left"
                />
                <TableHeaderItem
                  field="noLiquidacao"
                  label="Liquidação"
                  page={page}
                  searchWithPage={searchWithPage}
                  alignment="left"
                />
                <th>Nota Fiscal</th>
                <TableHeaderItem
                  field="dataVencimento"
                  label="Previsão de Pagamento"
                  page={page}
                  searchWithPage={searchWithPage}
                  alignment="left"
                />
                {mostraDataPagamento && (
                  <TableHeaderItem
                    field="dataPagamento"
                    label="Data de Pagamento"
                    page={page}
                    searchWithPage={searchWithPage}
                    alignment="left"
                  />
                )}
                <TableHeaderItem
                  field="valor"
                  label="Valor"
                  page={page}
                  searchWithPage={searchWithPage}
                  alignment="left"
                />
                <TableHeaderItem
                  field="saldo"
                  label="Saldo"
                  page={page}
                  searchWithPage={searchWithPage}
                  alignment="left"
                />
                <th />
              </tr>
            </TableHeader>
            <tbody>
              {page.get('content').map(this.renderTableLiquidacoes)}
            </tbody>

            {!page.get('content').isEmpty() && (
              <tfoot>
                <tr>
                  {tipo === 'aptos-pagamentos' && <td />}
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  {!(filtradoFornecedor && this.fornecedorUnico) && <td />}
                  {mostraDataPagamento && <td />}
                  <td className="right">
                    <i>Total:</i>
                    <br />
                    <NumberFormatter value={total.get('valor')} />
                  </td>
                  <td className="right">
                    <i>Total:</i>
                    <br />
                    <NumberFormatter value={total.get('saldo')} />
                  </td>
                  <td />
                </tr>
              </tfoot>
            )}
          </table>
        </div>
      </section>
    );
  }
}

function mapStateToProps(state) {}

const allActions = {
  ...actions,
  addErrorNotification
};

export default connect(mapStateToProps, allActions)(AgendaPagamentoListagem);
