import { List, Map } from 'immutable';
import BotaoVoltar from 'portaltransparencia-common/components/BotaoVoltar.react.js';
import * as wizardActions from 'portaltransparencia-common/components/wizard/Actions.js';
import Wizard from 'portaltransparencia-common/components/wizard/Wizard.react.js';
import WizardContentItem from 'portaltransparencia-common/components/wizard/WizardContentItem.react.js';
import focusInvalidField from 'portaltransparencia-common/libs/focusInvalidField.js';
import { validaCpfCnpj } from 'portaltransparencia-common/libs/ValidaCPFCNPJ.js';
import { validaEmail } from 'portaltransparencia-common/libs/ValidaEmail.js';
import {
  addErrorNotification,
  addSuccessNotification
} from 'portaltransparencia-common/ui/Actions.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import * as actions from './Actions.js';
import FormSolicitacaoDetailEndereco from './FormSolicitacaoDetailEndereco.react.js';
import FormSolicitacaoDetailPedido from './FormSolicitacaoDetailPedido.react.js';
import FormSolicitacaoDetailPessoa from './FormSolicitacaoDetailPessoa.react.js';
import FormSolicitacaoDetailTipo from './FormSolicitacaoDetailTipo.react.js';

class SolicitacaoEsic extends Component {
  static propTypes = {
    onChangeDados: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    saveProcesso: PropTypes.func.isRequired,
    addSuccessNotification: PropTypes.func.isRequired,
    addErrorNotification: PropTypes.func.isRequired,
    findPessoa: PropTypes.func.isRequired,
    dados: PropTypes.object,
    assuntos: PropTypes.object,
    previousStep: PropTypes.func.isRequired,
    nextStep: PropTypes.func.isRequired,
    goToStep: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    cadastrada: PropTypes.any,
    isIntegradoOxy: PropTypes.bool
  };

  componentDidMount() {
    actions.loadAssuntos();
  }

  onChangeDados = event => {
    const { onChange } = this.props;

    onChange('solicitacaoEsic', event);
  };

  findPessoa = () => {
    const { findPessoa, dados } = this.props;
    return findPessoa(dados.get('cpf'));
  };

  validarDadosTipo = callback => {
    this.refs.detailTipo.validate(error => {
      if (error) {
        focusInvalidField(this.refs.detailTipo, error);
      } else {
        callback();
      }
    });
  };

  validarDadosPessoa = () => {
    const { nextStep } = this.props;

    this.refs.detailPessoa.validate(error => {
      if (error) {
        focusInvalidField(this.refs.detailPessoa, error);
      } else {
        nextStep();
      }
    });
  };

  validarDadosEndereco = () => {
    const { nextStep } = this.props;

    this.refs.detailEndereco.validate(error => {
      if (error) {
        focusInvalidField(this.refs.detailEndereco, error);
      } else {
        nextStep();
      }
    });
  };

  saveProcesso = () => {
    const { saveProcesso, dados } = this.props;

    this.refs.detailPedido.validate(error => {
      if (error) {
        focusInvalidField(this.refs.detailPedido, error);
        return;
      }

      saveProcesso(dados);
    });
  };

  handlePreviousClick = () => {
    const { dados, previousStep, goToStep } = this.props;

    switch (dados.get('activeStep')) {
      case 4: {
        if (dados.get('tipoSolicitacao') === 'I') {
          previousStep();
        } else {
          goToStep(1);
        }
        break;
      }

      default: {
        previousStep();
      }
    }
  };

  handleNextClick = () => {
    const { dados, nextStep, goToStep, addErrorNotification } = this.props;

    switch (dados.get('activeStep')) {
      case 1: {
        this.validarDadosTipo(() => {
          if (dados.get('tipoSolicitacao') == 'I') {
            this.findPessoa()
              .then(res => {
                nextStep();
                return res;
              })
              .catch(() => {
                addErrorNotification(
                  'Nenhuma pessoa encontrada, preencha seu cadastro.'
                );
              });
          } else {
            goToStep(4);
          }
        });
        break;
      }
      case 2: {
        this.validarDadosPessoa();
        break;
      }
      case 3: {
        this.validarDadosEndereco();
        break;
      }
      case 4: {
        this.saveProcesso();
        break;
      }
    }
  };

  render() {
    const { dados, cadastrada, isIntegradoOxy, assuntos } = this.props;

    let wizardHeader = List([
      Map({
        activeStep: dados.get('activeStep'),
        stepNumber: 1,
        stepFaIcon: 'fa-files-o',
        stepTitle: 'Tipo de Solicitação'
      }),
      Map({
        activeStep: dados.get('activeStep'),
        stepNumber: 2,
        stepFaIcon: 'fa-user-o',
        stepTitle: 'Dados da Pessoa'
      }),
      Map({
        activeStep: dados.get('activeStep'),
        stepNumber: 3,
        stepFaIcon: 'fa-map-marker',
        stepTitle: 'Dados do Endereço'
      }),
      Map({
        activeStep: dados.get('activeStep'),
        stepNumber: 4,
        stepFaIcon: 'fa-file-text-o',
        stepTitle: 'Dados do Pedido'
      })
    ]);

    return (
      <section>
        <div className="cousins">
          <BotaoVoltar />
        </div>
        <div className="cousins">
          <h1 className="text-title mb-xs center">
            Solicitação Online
            <br /> do Acesso à Informação
          </h1>
        </div>

        <div>
          <Wizard listHeader={wizardHeader}>
            <WizardContentItem
              activeStep={dados.get('activeStep')}
              stepNumber={1}
              stepTitle="Tipo de Solicitação"
              handlePreviousClick={this.handlePreviousClick}
              handleNextClick={this.handleNextClick}
            >
              <FormSolicitacaoDetailTipo
                ref="detailTipo"
                dados={dados}
                onChangeDados={this.onChangeDados}
              />
            </WizardContentItem>
            {dados.get('tipoSolicitacao') === 'I' && (
              <WizardContentItem
                activeStep={dados.get('activeStep')}
                stepNumber={2}
                stepTitle="Dados da Pessoa"
                handlePreviousClick={this.handlePreviousClick}
                handleNextClick={this.handleNextClick}
              >
                <FormSolicitacaoDetailPessoa
                  ref="detailPessoa"
                  dados={dados}
                  cadastrada={cadastrada}
                  onChangeDados={this.onChangeDados}
                />
              </WizardContentItem>
            )}
            {dados.get('tipoSolicitacao') === 'I' && (
              <WizardContentItem
                activeStep={dados.get('activeStep')}
                stepNumber={3}
                stepTitle="Dados do Endereço"
                handlePreviousClick={this.handlePreviousClick}
                handleNextClick={this.handleNextClick}
              >
                <FormSolicitacaoDetailEndereco
                  ref="detailEndereco"
                  dados={dados}
                  cadastrada={cadastrada}
                  onChangeDados={this.onChangeDados}
                />
              </WizardContentItem>
            )}
            <WizardContentItem
              activeStep={dados.get('activeStep')}
              stepNumber={4}
              stepTitle="Dados do Pedido"
              handlePreviousClick={this.handlePreviousClick}
              handleNextClick={this.handleNextClick}
              nextButtonLabel="Enviar"
            >
              <FormSolicitacaoDetailPedido
                ref="detailPedido"
                assuntos={assuntos}
                dados={dados}
                onChangeDados={this.onChangeDados}
                isIntegradoOxy={isIntegradoOxy}
              />
            </WizardContentItem>
          </Wizard>
        </div>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    dados: state.esic.get('currentRecord'),
    cadastrada: state.esic.get('cadastrada'),
    assuntos: state.esic.get('assuntos'),
    isIntegradoOxy: state.entidade.getIn([
      'configuracoesGerais',
      'isIntegradoOxy'
    ])
  };
}

const allActions = {
  ...actions,
  ...wizardActions,
  addSuccessNotification,
  addErrorNotification,
  validaCpfCnpj,
  validaEmail
};

export default connect(mapStateToProps, allActions)(SolicitacaoEsic);
