import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';

import * as types from './Types.js';

const initialState = fromJS({
  cedidosDe: {},
  cedidosPara: {},
  histCedidosDe: {},
  histCedidosPara: {}
});

function cedidoReducer(state = initialState, action) {
  switch (action.type) {
    case types.CEDIDOS_DE_START:
    case types.CEDIDOS_PARA_START:
    case types.HISTCEDIDOS_DE_START:
    case types.HISTCEDIDOS_PARA_START: {
      return startLoad(state);
    }

    case types.CEDIDOS_DE_ERROR:
    case types.CEDIDOS_PARA_ERROR:
    case types.HISTCEDIDOS_DE_ERROR:
    case types.HISTCEDIDOS_PARA_ERROR: {
      return endLoad(state);
    }

    case types.CEDIDOS_DE_SUCCESS: {
      return endLoad(state.set('cedidosDe', fromJS(action.payload)));
    }
    case types.CEDIDOS_PARA_SUCCESS: {
      return endLoad(state.set('cedidosPara', fromJS(action.payload)));
    }
    case types.HISTCEDIDOS_DE_SUCCESS: {
      return endLoad(state.set('histCedidosDe', fromJS(action.payload)));
    }
    case types.HISTCEDIDOS_PARA_SUCCESS: {
      return endLoad(state.set('histCedidosPara', fromJS(action.payload)));
    }
    default:
      return state;
  }
}

export default cedidoReducer;
