import Panel from 'portaltransparencia-common/components/Panel.react.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

import Arquivo from '../arquivo/Arquivo.react';
import ProcessoInformacoes from './ProcessoInformacoes.react.js';
import ProcessoInformacoesTramitesTable from './ProcessoInformacoesTramitesTable.react.js';

export default class Processo extends Component {
  static propTypes = {
    protocolo: PropTypes.object,
    abrirRecursoProcesso: PropTypes.func,
    offsidebarToggle: PropTypes.func,
    downloadArquivo: PropTypes.func
  };

  abrirRecursoProcesso = () => {
    const { abrirRecursoProcesso, offsidebarToggle } = this.props;
    abrirRecursoProcesso();
    offsidebarToggle();
  };

  render() {
    const { protocolo, downloadArquivo } = this.props;
    const hint =
      'Ao abrir recurso para o processo desejado, abrirá o formulário preenchê-lo. Este novo processo será vinculado ao processo escolhido.';

    return (
      <Panel expansible={false} tableResponsive>
        {protocolo.get('permiteCriarReferencia') && (
          <div className="btn-group center mb-xs">
            <button
              className="btn neutral"
              type="button"
              onClick={this.abrirRecursoProcesso}
              title={hint}
            >
              Abrir recurso para o processo
            </button>
          </div>
        )}

        <ProcessoInformacoes protocolo={protocolo} />

        <hr className="mt-xs mb-xs" />

        <ProcessoInformacoesTramitesTable
          tramites={protocolo.get('tramitacoes')}
        />

        <hr className="mt-xs mb-xs" />

        <Arquivo
          onDownload={downloadArquivo}
          arquivos={protocolo.get('arquivos')}
        />
      </Panel>
    );
  }
}
