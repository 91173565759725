import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';

import * as types from './Types.js';

const initialState = fromJS({
  dividas: [],
  dividasContribuinte: []
});

function dividaAtivaReducer(state = initialState, action) {
  switch (action.type) {
    case types.DIVIDAS_ATIVAS_START:
    case types.DIVIDAS_ATIVAS_CONTRIBUINTE_START:
    case types.DIVIDAS_ATIVAS_ULTIMA_ATUALIZACAO_START: {
      return startLoad(state);
    }

    case types.DIVIDAS_ATIVAS_ERROR:
    case types.DIVIDAS_ATIVAS_CONTRIBUINTE_ERROR:
    case types.DIVIDAS_ATIVAS_ULTIMA_ATUALIZACAO_ERROR: {
      return endLoad(state);
    }

    case types.DIVIDAS_ATIVAS_SUCCESS: {
      return endLoad(state.set('dividas', fromJS(action.payload)));
    }
    case types.DIVIDAS_ATIVAS_CONTRIBUINTE_SUCCESS: {
      return endLoad(state.set('dividasContribuinte', fromJS(action.payload)));
    }

    default:
      return state;
  }
}

export default dividaAtivaReducer;
