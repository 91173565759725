import ExportButtonGroup from 'portaltransparencia-common/components/ExportButtonGroup.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import TableHeaderItem from 'portaltransparencia-common/components/TableHeaderItem.react';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';

import UltimaAtualizacao from '../ultimaatualizacao/Form.react.js';
import PatrimonioRow from './PatrimonioRow.react.js';

export default class PatrimonioTable extends Component {
  static propTypes = {
    patrimoniosPage: PropTypes.object.isRequired,
    filter: PropTypes.string,
    searchWithPage: PropTypes.func.isRequired,
    onExporta: PropTypes.func.isRequired
  };

  renderPatrimonios = data => {
    const key =
      data.get('entidade') +
      '_' +
      data.get('tipo') +
      '_' +
      data.get('bem') +
      '_' +
      data.get('chapa');

    return (
      <PatrimonioRow key={key} patrimonio={data} history={this.props.history} />
    );
  };

  render() {
    const { patrimoniosPage, searchWithPage, onExporta } = this.props;
    const patrimonios = patrimoniosPage.get('content');

    return (
      <Panel expansible={false} isForm tableResponsive>
        <Row>
          <Col xs={6}>
            <UltimaAtualizacao modulo="7" />
          </Col>
          <Col xs={6}>
            <ExportButtonGroup onClick={onExporta} />
          </Col>
        </Row>

        <div className="panel-table-outer">
          <table className="panel-table striped fancy">
            <TableHeader
              empty={patrimonios.isEmpty()}
              text="Não existem Bens Tombados com os filtros utilizados."
            >
              <tr>
                <TableHeaderItem
                  field="chapa"
                  label="Tombamento"
                  page={patrimoniosPage}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="tipo"
                  label="Tipo"
                  page={patrimoniosPage}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="descricao"
                  label="Descrição"
                  page={patrimoniosPage}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="dataAquisicao"
                  label="Aquisição"
                  page={patrimoniosPage}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="descTipoIncoporacao"
                  label="Tipo Incorporação"
                  page={patrimoniosPage}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="valorAtual"
                  label="Valor Atual"
                  page={patrimoniosPage}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="valorAquisicao"
                  label="Valor Aquisição"
                  page={patrimoniosPage}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="baixado"
                  label="Situação"
                  page={patrimoniosPage}
                  searchWithPage={searchWithPage}
                />
                <th></th>
              </tr>
            </TableHeader>
            <tbody>{patrimonios.map(this.renderPatrimonios)}</tbody>
          </table>
        </div>
      </Panel>
    );
  }
}
