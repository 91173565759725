import Component from 'react-pure-render/component';
import PropTypes from 'prop-types';
import React from 'react';
import Validation from 'react-validation-mixin';
import Validator from 'validatorjs';
import strategy from 'react-validatorjs-strategy';
import { messages } from '../libs/validatorjs.pt-BR.js';
import { parseMetaModel } from '../libs/MetaModelValidationParser.js';
import focusInvalidField from '../libs/focusInvalidField.js';
import ValidationError from '../libs/ValidationError.js';
import { validaCpfCnpj, valida_cpf } from '../libs/ValidaCPFCNPJ.js';

Validator.setMessages('pt-BR', messages);
Validator.useLang('pt-BR');

export default function validationForm(validationSchema = {}, loadModel) {
  return WrappedForm => {
    class ValidationForm extends Component {
      static displayName = `ValidationForm(${WrappedForm.name})`;

      static contextTypes = {
        store: PropTypes.object // Redux store.
      };

      constructor(props) {
        super(props);
      }

      componentDidMount() {
        const { dispatch } = this.props;

        if (loadModel) {
          dispatch(loadModel()).then(payload => {
            this.validatorTypes = this.createValidatorFromMetaModel(
              payload.value
            );
          });
        } else {
          this.validatorTypes = this.createValidatorFromSchema(
            validationSchema
          );
        }
      }

      createValidatorFromMetaModel = metaModel => {
        let metaModelValidationSchema = parseMetaModel(metaModel);

        metaModelValidationSchema.rules = {
          ...metaModelValidationSchema.rules,
          ...validationSchema.rules
        };

        metaModelValidationSchema.messages = {
          ...metaModelValidationSchema.messages,
          ...validationSchema.messages
        };
        metaModelValidationSchema.labels = {
          ...metaModelValidationSchema.labels,
          ...validationSchema.labels
        };

        return this.createValidatorFromSchema(metaModelValidationSchema);
      };

      createValidatorFromSchema = schema => {
        return strategy.createSchema(
          schema.rules,
          schema.messages,
          validator => {
            this.configureValidator(validator, schema);
          }
        );
      };

      configureValidator = (validator, metaModelValidationSchema) => {
        validator.constructor.register('cnpj_cpf', value => {
          return validaCpfCnpj(value);
        });

        validator.constructor.register('cpf', value => {
          return valida_cpf(value);
        });

        validator.messages.messages = Validator.getMessages('pt-BR');
        validator.messages.lang = 'pt-BR';
        validator.messages.attributeFormatter = attribute => {
          const customLabel =
            metaModelValidationSchema.labels &&
            metaModelValidationSchema.labels[attribute];
          return customLabel || attribute;
        };
      };

      getValidatorData = () => {
        return this.refs.validationForm.getValidatorData();
      };

      handleError = (component, errors) => {
        if (!errors) {
          return false;
        }

        if (focusInvalidField(component, errors)) {
          return true;
        } else {
          let messages = [];

          for (let key in errors) {
            messages.push(errors[key]);
          }
          throw new ValidationError(messages.join(' - '));
        }
      };

      validate = () => {
        return this.refs.validationForm.validate;
      };

      render() {
        return (
          <WrappedForm
            {...this.props}
            ref="validationForm"
            validatorTypes={this.validatorTypes}
            handleError={this.handleError}
          />
        );
      }
    }

    return Validation(strategy)(ValidationForm);
  };
}
