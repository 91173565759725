import { Ellipsis } from 'portaltransparencia-common/components/Ellipsis.react';
import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react.js';
import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react.js';
import TableHeaderItem from 'portaltransparencia-common/components/TableHeaderItem.react';
import configuration from 'portaltransparencia-common/config/Config.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export default class ContratoListagem extends Component {
  static propTypes = {
    contratosPage: PropTypes.object.isRequired,
    filter: PropTypes.string,
    searchWithPage: PropTypes.func.isRequired,
    pathname: PropTypes.string,
    textoSemRegistro: PropTypes.string
  };

  onViewDetalhes = contrato => {
    const { pathname, history } = this.props;
    const entidade = contrato.get('entidade');
    const exercicio = contrato.get('exercicio');
    const codigoContrato = contrato.get('contrato');
    const tipoAto = contrato.get('tipoAto');

    history.push(
      `${pathname}/detalhes?entidade=${entidade}&exercicio=${exercicio}&contrato=${codigoContrato}&tipoAto=${tipoAto}`
    );
  };

  onExporta = (exportType, openTab, windowParams) => {
    const { apiURL } = configuration;
    const { filter } = this.props;

    let filtro = filter + '&exportType=' + exportType;

    window.open(
      `${apiURL}/api/contratos/report?${filtro}`,
      openTab,
      windowParams
    );
  };

  renderContratos = data => {
    const key =
      data.get('entidade') +
      '_' +
      data.get('exercicio') +
      '_' +
      data.get('contrato') +
      '_' +
      data.get('numeroContrato') +
      '_' +
      data.get('tipoAto');

    return (
      <TableActions
        ref="row"
        data={data}
        onMore={this.onViewDetalhes}
        key={key}
      >
        <td>{data.get('descricaoTipoAto')}</td>
        <td>{data.get('numeroContrato') + '/' + data.get('exercicio')}</td>
        <td title={data.get('objeto')}>
          <Ellipsis value={data.get('objeto')} maxLength={160} />
        </td>
        <td>{data.get('descricaoTipoLicitacao')}</td>
        <td>
          {data.get('numeroLicitacao') &&
            data.get('numeroLicitacao') + '/' + data.get('exercicioLicitacao')}
        </td>
        <td>{data.get('tipoContrato')}</td>
        <td>{data.get('nome')}</td>
        <td>{DateUtils.transform(data.get('inicioVigencia'))}</td>
        <td className="right">
          <NumberFormatter value={data.get('valorContratado')} />
        </td>
        <td className="right">
          <NumberFormatter value={data.get('valorAditivo')} />
        </td>
        <td>{data.get('situacao')}</td>
      </TableActions>
    );
  };

  render() {
    const { contratosPage, searchWithPage, textoSemRegistro } = this.props;
    const contratos = contratosPage.get('content');

    return (
      <div className="panel-table-outer">
        <table className="panel-table striped fancy">
          <TableHeader
            empty={contratos.isEmpty()}
            text={textoSemRegistro === '' ? undefined : textoSemRegistro}
          >
            <tr>
              <TableHeaderItem
                field="descricaoTipoAto"
                label="Tipo Ato"
                page={contratosPage}
                searchWithPage={searchWithPage}
              />
              <TableHeaderItem
                field="numeroContrato"
                label="N° Contrato"
                page={contratosPage}
                searchWithPage={searchWithPage}
              />
              <TableHeaderItem
                field="objeto"
                label="Objeto"
                page={contratosPage}
                searchWithPage={searchWithPage}
              />
              <TableHeaderItem
                field="descricaoTipoLicitacao"
                label="Tipo Licitação"
                page={contratosPage}
                searchWithPage={searchWithPage}
              />
              <TableHeaderItem
                field="numeroLicitacao"
                label="N° Licitação"
                page={contratosPage}
                searchWithPage={searchWithPage}
              />
              <TableHeaderItem
                field="tipoContrato"
                label="Tipo Contrato"
                page={contratosPage}
                searchWithPage={searchWithPage}
              />
              <TableHeaderItem
                field="nome"
                label="Contratada"
                page={contratosPage}
                searchWithPage={searchWithPage}
              />
              <TableHeaderItem
                field="inicioVigencia"
                label="Início Vigência"
                page={contratosPage}
                searchWithPage={searchWithPage}
              />
              <TableHeaderItem
                field="valorContratado"
                label="Valor Contratado"
                page={contratosPage}
                searchWithPage={searchWithPage}
                alignment="right"
              />
              <TableHeaderItem
                field="valorAditivo"
                label="Valor Aditivos"
                page={contratosPage}
                searchWithPage={searchWithPage}
                alignment="right"
              />
              <TableHeaderItem
                field="situacao"
                label="Situação"
                page={contratosPage}
                searchWithPage={searchWithPage}
              />
              <th></th>
            </tr>
          </TableHeader>
          <tbody>{contratos && contratos.map(this.renderContratos)}</tbody>
        </table>
      </div>
    );
  }
}
