import DisplayData from 'portaltransparencia-common/components/displaydata/DisplayData.react';
import DisplayDataItem from 'portaltransparencia-common/components/displaydata/DisplayDataItem.react';
import DisplayDataRow from 'portaltransparencia-common/components/displaydata/DisplayDataRow.react';
import Modal from 'portaltransparencia-common/components/Modal.react.js';
import PropTypes from 'prop-types';
import React from 'react';
import { Panel } from 'react-bootstrap';
import Component from 'react-pure-render/component';

export class OrdemObservacao extends Component {
  static propTypes = {
    justificativa: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
  };

  render() {
    const { justificativa, show, onClose } = this.props;

    if (show) {
      return (
        <Modal onClose={onClose}>
          <div className="container">
            <Panel
              title="Ordem Cronológica de Pagamentos"
              isForm
              expansible={false}
            >
              <DisplayData border column>
                <DisplayDataRow>
                  <DisplayDataItem
                    sm={12}
                    title="Justificativa"
                    value={justificativa}
                    id="justificativa"
                  />
                </DisplayDataRow>
              </DisplayData>
            </Panel>
          </div>
        </Modal>
      );
    }
    return null;
  }
}

export default OrdemObservacao;
