import * as types from './Types.js';

export function findReceitas(filter) {
  return ({ fetch }) => ({
    type: types.RECEITAS,
    payload: {
      promise: fetch(`/api/receitas?${filter}`)
    }
  });
}

export function findReceitasWithFiltro(filter) {
  return ({ fetch }) => ({
    type: types.RECEITAS,
    payload: {
      promise: fetch(`/api/receitas?${filter}`)
    }
  });
}

export function findReceitasFonteRecursos(filter) {
  return ({ fetch }) => ({
    type: types.RECEITAS_FONTE_RECURSO,
    payload: {
      promise: fetch(`/api/receitas/fonte-recursos?${filter}`)
    }
  });
}

export function findDetalheReceitasFonteRecurso(filter) {
  return ({ fetch }) => ({
    type: types.DETALHE_RECEITAS_FONTE_RECURSO,
    payload: {
      promise: fetch(`/api/receitas/fonte-recursos/detalhes?${filter}`)
    }
  });
}

export function findReceitasDia(filter) {
  return ({ fetch }) => ({
    type: types.RECEITAS_DIA,
    payload: {
      promise: fetch(`/api/receitas/dia?${filter}`)
    }
  });
}

export function findReceitasRazao(filter) {
  return ({ fetch }) => ({
    type: types.RECEITAS_RAZAO,
    payload: {
      promise: fetch(`/api/receitas/dia?${filter}`)
    }
  });
}

export function findReceita(filter) {
  return ({ fetch }) => ({
    type: types.RECEITA,
    payload: {
      promise: fetch(`/api/receitas?${filter}`)
    }
  });
}

export function findReceitasCovid(filter) {
  return ({ fetch }) => ({
    type: types.RECEITAS_COVID,
    payload: {
      promise: fetch(`/api/receitas/covid?${filter}`)
    }
  });
}

export function findReceitasEspecifico(filter) {
  return ({ fetch }) => ({
    type: types.RECEITAS_ESPECIFICO,
    payload: {
      promise: fetch(`/api/receitas/especifico?${filter}`)
    }
  });
}

export function getGraficoAnual(filter) {
  return ({ fetch }) => ({
    type: types.GRAFICO_ANUAL,
    payload: {
      promise: fetch(`/api/receitas/grafico-anual?${filter}`)
    }
  });
}

export function selecionarReceita(receita) {
  return {
    type: types.SELECT_RECEITA,
    payload: receita.toJS()
  };
}

export function expand(receita, nivel) {
  return {
    type: types.EXPAND_RECEITA,
    payload: {
      receita,
      nivel
    }
  };
}

export function collapse(receita, nivel) {
  return {
    type: types.COLLAPSE_RECEITA,
    payload: {
      receita,
      nivel
    }
  };
}
