import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';
import * as types from './Types.js';

const initialState = fromJS({
  estruturas: {},
  filtroEstruturas: [],
  servidores: {}
});

function estruturaReducer(state = initialState, action) {
  switch (action.type) {
    case types.LIST_ESTRUTURAS_START:
    case types.LIST_SERVIDORES_START:
    case types.FILTRO_ESTRUTURAS_START: {
      return startLoad(state);
    }

    case types.LIST_ESTRUTURAS_ERROR:
    case types.LIST_SERVIDORES_ERROR:
    case types.FILTRO_ESTRUTURAS_ERROR: {
      return endLoad(state);
    }

    case types.LIST_ESTRUTURAS_SUCCESS: {
      return endLoad(state.set('estruturas', fromJS(action.payload)));
    }

    case types.FILTRO_ESTRUTURAS_SUCCESS: {
      return endLoad(state.set('filtroEstruturas', fromJS(action.payload)));
    }

    case types.LLIST_SERVIDORES_SUCCESS: {
      return endLoad(state.set('servidores', fromJS(action.payload)));
    }
  }
  return state;
}

export default estruturaReducer;
