import { Map } from 'immutable';
import BotaoImprimir from 'portaltransparencia-common/components/BotaoImprimir.react.js';
import DatePicker from 'portaltransparencia-common/components/DatePicker.react.js';
import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import SumListField from 'portaltransparencia-common/components/SumListField.react.js';
import TableActions from 'portaltransparencia-common/components/TableActions.react';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import {
  onChange,
  resetCrud
} from 'portaltransparencia-common/crud/Actions.js';
import { addFilterUrl } from 'portaltransparencia-common/libs/AddFilter.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils';
import { getAllValuesFromUrlSearchParams } from 'portaltransparencia-common/libs/URLUtils';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Helmet from 'react-helmet';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import FilterButtons from '../components/FilterButtons.react';
import PageHeader from '../components/PageHeader.react.js';
import CabecalhoEntidade from '../entidades/CabecalhoEntidade.react.js';
import * as actions from './Actions.js';

export class FormReceitaDia extends Component {
  static propTypes = {
    receitasDia: PropTypes.object.isRequired,
    findReceitasDia: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    findReceita: PropTypes.func.isRequired,
    receita: PropTypes.object.isRequired,
    filtros: PropTypes.object,
    resetCrud: PropTypes.func,
    onChange: PropTypes.func
  };

  componentDidMount() {
    this.carregaReceita(this.props);
  }

  carregaReceita(props) {
    const { findReceitasDia, findReceita } = props;

    const filtro = this.montarFiltro(props);
    findReceitasDia(filtro);
    findReceita(filtro);
  }

  montarFiltro(props) {
    const { location, filtros } = props;
    const {
      entidade,
      exercicio,
      receita,
      fonteRecursoRateio
    } = getAllValuesFromUrlSearchParams(location);

    let filter = '';

    if (entidade) {
      filter = addFilterUrl(filter, `entidade=${entidade}`);
    }
    if (exercicio) {
      filter = addFilterUrl(filter, `exercicio=${exercicio}`);
    }
    if (receita) {
      filter = addFilterUrl(filter, `receita=${receita}`);
    }
    if (fonteRecursoRateio) {
      filter = addFilterUrl(filter, `fonteRecursoRateio=${fonteRecursoRateio}`);
    }
    if (filtros.get('dataInicial') && filtros.get('dataInicial').length == 10) {
      filter = addFilterUrl(
        filter,
        `dataInicial=${filtros.get('dataInicial')}`
      );
    }
    if (filtros.get('dataFinal') && filtros.get('dataFinal').length == 10) {
      filter = addFilterUrl(filter, `dataFinal=${filtros.get('dataFinal')}`);
    }

    return filter;
  }

  onView = data => {
    const { location } = this.props;
    const {
      entidade,
      exercicio,
      receita,
      fonteRecurso
    } = getAllValuesFromUrlSearchParams(location);
    const dia = DateUtils.formatDateTime(data.get('data'), 'YYYY-MM-DD');
    let filtro = '';
    if (fonteRecurso) {
      filtro = `${filtro}&fonteRecurso=${fonteRecurso}`;
    }
    this.props.history.push(
      `/receita/dias/razao?entidade=${entidade}&exercicio=${exercicio}&data=${dia}&receita=${receita}${filtro}`
    );
  };

  onConsulta = () => {
    const { findReceitasDia } = this.props;
    findReceitasDia(this.montarFiltro(this.props));
  };

  renderReceitas = data => {
    return (
      <TableActions
        ref="row"
        key={data.get('data')}
        data={data}
        onMore={this.onView}
      >
        <td>{DateUtils.formatDateTimeShort(data.get('data'))}</td>
        <td className="right">
          <NumberFormatter value={data.get('valorArrecadado')} />
        </td>
      </TableActions>
    );
  };

  onResetFiltro = () => {
    const { resetCrud, findReceitasDia } = this.props;
    resetCrud('filtroReceitaDia');

    const { location } = this.props;
    const {
      entidade,
      exercicio,
      receita,
      fonteRecursoRateio
    } = getAllValuesFromUrlSearchParams(location);

    findReceitasDia(
      `entidade=${entidade}&exercicio=${exercicio}&receita=${receita}&fonteRecursoRateio=${fonteRecursoRateio}`
    );
  };

  onChangeFiltro = event => {
    const { onChange } = this.props;
    onChange('filtroReceitaDia', event);
  };

  render() {
    const { receitasDia, receita, filtros } = this.props;

    return (
      <section>
        <Helmet title={`Receita - ${receita.get('descricao')}`} />
        <PageHeader
          location={this.props.location}
          textoDetalhe={`Receita: ${receita.get('receita')} - ${receita.get(
            'descricao'
          )}`}
        />
        <CabecalhoEntidade />
        <BotaoImprimir />

        <Panel title="Buscar" isForm mobileClose>
          <Row>
            <Col xs={6} sm={3} md={3}>
              <DatePicker
                id="dataInicial"
                name="dataInicial"
                ref="dataInicial"
                label="Data Inicial do Lançamento"
                onChange={this.onChangeFiltro}
                value={filtros.get('dataInicial')}
              />
            </Col>
            <Col xs={6} sm={3} md={3}>
              <DatePicker
                id="dataFinal"
                name="dataFinal"
                ref="dataFinal"
                label="Data Final do Lançamento"
                onChange={this.onChangeFiltro}
                value={filtros.get('dataFinal')}
              />
            </Col>
            <Col xs={12} sm={3} md={3}>
              <FilterButtons
                idBtnReset="btnResetFilter"
                onConsulta={this.onConsulta}
                onReset={this.onResetFiltro}
              />
            </Col>
          </Row>
        </Panel>

        <div className="panel table open">
          <div className="panel-body">
            <div className="panel-table-outer">
              <table className="panel-table striped fancy">
                <TableHeader empty={receitasDia.isEmpty()}>
                  <tr>
                    <th>Lançamentos por dia</th>
                    <th className="right">Valor Arrecadado</th>
                    <th></th>
                  </tr>
                </TableHeader>
                <tbody>{receitasDia.map(this.renderReceitas)}</tbody>
                {!receitasDia.isEmpty() && (
                  <tfoot>
                    <tr>
                      <td></td>
                      <td className="right">
                        <i>Total: </i>
                        <SumListField
                          fieldName="valorArrecadado"
                          list={receitasDia}
                        />
                      </td>
                      <td></td>
                    </tr>
                  </tfoot>
                )}
              </table>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    filtros: state.crud.getIn(['filtroReceitaDia', 'currentRecord'], Map({})),
    receitasDia: state.receita.get('receitasDia'),
    receita: state.receita.get('receita')
  };
}

const allActions = {
  ...actions,
  resetCrud,
  onChange
};

export default connect(mapStateToProps, allActions)(FormReceitaDia);
