import Panel from 'portaltransparencia-common/components/Panel.react.js';
import SearchPagination from 'portaltransparencia-common/components/SearchPagination.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import TableHeaderItem from 'portaltransparencia-common/components/TableHeaderItem.react';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

import FuncaoGratificada from './FuncaoGratificada.react.js';

export class FuncoesGratificadasTable extends Component {
  static propTypes = {
    page: PropTypes.object.isRequired,
    searchWithPage: PropTypes.func.isRequired
  };

  renderFuncoesGratificadas = funcaoGratificada => {
    return <FuncaoGratificada funcaoGratificada={funcaoGratificada} />;
  };

  render() {
    const { page, searchWithPage } = this.props;
    const funcoesGratificadas = page.get('content');

    return (
      <Panel isForm expansible={false} tableResponsive>
        <div className="panel-table-outer">
          <table className="panel-table striped fancy">
            <TableHeader empty={funcoesGratificadas.isEmpty()}>
              <tr>
                <TableHeaderItem
                  field="descricao"
                  label="Descrição"
                  page={page}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="vagas"
                  label="Vagas"
                  page={page}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="ocupadas"
                  label="Ocupadas"
                  page={page}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="valor"
                  label="Valor"
                  alignment="right"
                  page={page}
                  searchWithPage={searchWithPage}
                />
                <th></th>
              </tr>
            </TableHeader>
            <tbody>
              {funcoesGratificadas.map(this.renderFuncoesGratificadas)}
            </tbody>
          </table>
        </div>
        <SearchPagination page={page} searchWithPage={searchWithPage} />
      </Panel>
    );
  }
}

export default FuncoesGratificadasTable;
