import BotaoImprimir from 'portaltransparencia-common/components/BotaoImprimir.react.js';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import PageHeader from '../components/PageHeader.react.js';
import NotasExplicativas from '../notaexplicativa/Form.react';
import * as actions from './Actions.js';
import ArrecadacaoReceitaXDespesa from './ArrecadacaoReceitaXDespesa.react';
import ReceitaAno from './ReceitaAno.react';
import {
  PARAM_DESPESAS_ENTIDADES_DIFERENTES,
  PARAM_RECEITAS_ENTIDADES_DIFERENTES
} from 'portaltransparencia-common/parametros/ParametrosPortalTransparencia';
import ParametroService from '../parametros/ParametroService.js';

export class FormDashboard extends Component {
  static propTypes = {
    arrecadacaoDespesaMes: PropTypes.object.isRequired,
    receitaValorAtualizado: PropTypes.number.isRequired,
    findArrecadacaoDespesaMes: PropTypes.func.isRequired,
    findEmpenhoValorAnual: PropTypes.func.isRequired,
    entidadeSelecionada: PropTypes.object,
    location: PropTypes.object.isRequired,
    valorArrecadado: PropTypes.number.isRequired,
    valorEmpenhado: PropTypes.number,
    valorLiquidado: PropTypes.number,
    valorPago: PropTypes.number,
    valorResultadoReceitaMenosEmpenhado: PropTypes.number,
    paramDespesasEntidadesDiferentes: PropTypes.string,
    paramReceitasEntidadesDiferentes: PropTypes.string
  };

  constructor(props) {
    super(props);

    this.state = {
      width: 800
    };
  }

  componentDidMount() {
    const { findArrecadacaoDespesaMes, findEmpenhoValorAnual } = this.props;

    findArrecadacaoDespesaMes(
      this.props.location.pathname == '/receitaxdespesa-fundeb'
    );
    findEmpenhoValorAnual(
      this.props.location.pathname == '/receitaxdespesa-fundeb'
    );

    const node = ReactDOM.findDOMNode(this);

    this.setState({
      width: node.clientWidth
    });
  }

  render() {
    const {
      valorArrecadado,
      valorResultadoReceitaMenosEmpenhado,
      arrecadacaoDespesaMes,
      entidadeSelecionada,
      receitaValorAtualizado,
      paramDespesasEntidadesDiferentes,
      paramReceitasEntidadesDiferentes
    } = this.props;

    let { width } = this.state;
    const { exercicio } = StorageService.getItem('userInfo');
    let exercicioId = exercicio ? exercicio.id : null;
    const tipoEntidadeLegislativa =
      entidadeSelecionada && entidadeSelecionada.get('tipo') == 'L';

    return (
      <section>
        <PageHeader location={this.props.location} />

        <BotaoImprimir />

        <NotasExplicativas pathname={this.props.location.pathname} />

        {entidadeSelecionada && !tipoEntidadeLegislativa && (
          <ReceitaAno
            valorResultadoReceitaMenosEmpenhado={
              valorResultadoReceitaMenosEmpenhado
            }
            receitaValorAtualizado={receitaValorAtualizado}
            valorArrecadado={valorArrecadado}
            exercicio={exercicioId}
            paramDespesasEntidadesDiferentes={paramDespesasEntidadesDiferentes}
            paramReceitasEntidadesDiferentes={paramReceitasEntidadesDiferentes}
            pathname={this.props.location.pathname}
          />
        )}

        <ArrecadacaoReceitaXDespesa
          width={width}
          arrecadacaoDespesaMes={arrecadacaoDespesaMes}
          exercicio={exercicioId}
          pathname={this.props.location.pathname}
        />
      </section>
    );
  }
}

function calculaReceitaMenosEmpenhado(arrecadado, empenhado) {
  if (arrecadado <= 0) return 0;
  return arrecadado - empenhado;
}

function mapStateToProps(state) {
  const valorArrecadado = state.dashboard.get('valorArrecadado');
  const valorEmpenhado = state.dashboard.get('valorEmpenhado');
  const valorLiquidado = state.dashboard.get('valorLiquidado');
  const valorPago = state.dashboard.get('valorPago');
  return {
    arrecadacaoDespesaMes: state.dashboard.get('arrecadacaoDespesaMes'),
    receitaValorAtualizado: state.dashboard.get('receitaValorAtualizado'),
    valorResultadoReceitaMenosEmpenhado: calculaReceitaMenosEmpenhado(
      valorArrecadado,
      state.dashboard.get('receitaValorAtualizado')
    ),
    valorArrecadado: valorArrecadado,
    valorEmpenhado: valorEmpenhado,
    valorLiquidado: valorLiquidado,
    valorPago: valorPago,
    entidadeSelecionada: state.entidade.get('entidadeSelecionada'),
    paramDespesasEntidadesDiferentes: ParametroService.getValorParametro(
      state,
      PARAM_DESPESAS_ENTIDADES_DIFERENTES
    ),
    paramReceitasEntidadesDiferentes: ParametroService.getValorParametro(
      state,
      PARAM_RECEITAS_ENTIDADES_DIFERENTES
    )
  };
}

const allActions = {
  ...actions
};

export default connect(mapStateToProps, allActions)(FormDashboard);
