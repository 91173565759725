import { useWindowWidth } from '@elotech/components';
import React from 'react';
import styled from 'styled-components';

const TABLET_WIDTH = 768;

const calcHeight = width => {
  if (width > TABLET_WIDTH) {
    return 'calc(100vh - 113px)';
  }

  if (width <= TABLET_WIDTH) {
    return 'calc(100vh - 156px)';
  }

  return '100vh';
};

const StyledIFrame = styled.iframe`
  height: ${props => calcHeight(Number(props.width))};
  width: ${props => props.width};
`;

export const IFrame = props => {
  const width = useWindowWidth();

  return (
    <StyledIFrame
      src={`${props.url || window.location.origin}${props.path || ''}`}
      width={width}
    />
  );
};
