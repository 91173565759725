import { Map } from 'immutable';
import Input from 'portaltransparencia-common/components/EloInput.react.js';
import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import {
  changeFieldValue,
  onChange,
  resetCrud
} from 'portaltransparencia-common/crud/Actions.js';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import FilterButtons from '../components/FilterButtons.react';
import PageHeader from '../components/PageHeader.react.js';
import * as actions from './Actions.js';
import TableActions from 'portaltransparencia-common/components/TableActions.react';

export class FormHorasExtras extends Component {
  static propTypes = {
    horasExtras: PropTypes.object.isRequired,
    findHorasExtras: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    filtros: PropTypes.object,
    onChange: PropTypes.func,
    resetCrud: PropTypes.func,
    item: PropTypes.object,
    detalhes: PropTypes.object.isRequired,
    findDetalhesHorasExtras: PropTypes.object
  };

  onViewDetalhes = item => {
    const { filtros } = this.props;
    this.props.history.push(
      `/horas-extras/detalhes?mesCompetencia=${filtros.get(
        'mesCompetencia'
      )}&secretaria=${item.get('secretaria')}`
    );
  };

  componentDidMount() {
    const { filtros } = this.props;

    filtros.setIn('mesCompetencia', '1');
  }

  onChangeFiltro = event => {
    const { onChange } = this.props;

    onChange('filtroHorasExtras', event);
  };

  consultarHorasExtras = () => {
    const { findHorasExtras, filtros } = this.props;

    if (filtros.get('mesCompetencia')) {
      findHorasExtras(filtros.get('mesCompetencia'));
    }
  };

  onResetFiltro = () => {
    const { resetCrud } = this.props;

    resetCrud('filtroHorasExtras');
  };

  renderSecretarias = secretaria => {
    return (
      <TableActions ref="row" data={secretaria} onMore={this.onViewDetalhes}>
        <td>{secretaria.get('secretaria')}</td>
        <td className="right">{secretaria.get('horasExtras').toFixed(2)}</td>
        <td className="right">
          <NumberFormatter value={secretaria.get('valorPago')} />
        </td>
        <td />
      </TableActions>
    );
  };

  render() {
    const { horasExtras, location, filtros } = this.props;

    return (
      <section>
        <PageHeader location={location} />
        <Panel
          title="Consultar gastos com horas extras"
          isForm
          mobileClose
          ref="panelfiltroLicitacoes"
        >
          <Row>
            <Col xs={12} sm={6} md={4}>
              <Input
                id="mesCompetencia"
                name="mesCompetencia"
                ref="mesCompetencia"
                label="Mês Competência "
                type="select"
                onChange={this.onChangeFiltro}
                value={filtros.get('mesCompetencia')}
              >
                <option value="">Selecione</option>
                <option value="1">Janeiro</option>
                <option value="2">Fevereiro</option>
                <option value="3">Março</option>
                <option value="4">Abril</option>
                <option value="5">Maio</option>
                <option value="6">Junho</option>
                <option value="7">Julho</option>
                <option value="8">Agosto</option>
                <option value="9">Setembro</option>
                <option value="10">Outubro</option>
                <option value="11">Novembro</option>
                <option value="12">Dezembro</option>
              </Input>
            </Col>
            <Col xs={12} sm={4} md={3}>
              <FilterButtons
                onConsulta={this.consultarHorasExtras}
                onReset={this.onResetFiltro}
              />
            </Col>
          </Row>
        </Panel>
        <Panel
          title="Despesas com horas extras"
          isForm
          expansible={false}
          tableResponsive
        >
          <div className="panel-table-outer">
            <table className="panel-table striped facny">
              <TableHeader empty={horasExtras.get('secretarias').isEmpty()}>
                <tr>
                  <th>Secretaria</th>
                  <th className="right">Horas Extras</th>
                  <th className="right">Valor Pago</th>
                  <th />
                </tr>
              </TableHeader>
              <tbody>
                {horasExtras.get('secretarias').map(this.renderSecretarias)}
              </tbody>
              {!horasExtras.get('secretarias').isEmpty() && (
                <tfoot>
                  <tr>
                    <td />
                    <td className="right">
                      <i>Total horas:</i>
                      <br />
                      <NumberFormatter
                        value={horasExtras.get('totalHorasExtras')}
                      />
                    </td>
                    <td className="right">
                      <i>Total pago:</i>
                      <br />
                      <NumberFormatter value={horasExtras.get('totalValor')} />
                    </td>
                    <td />
                  </tr>
                </tfoot>
              )}
            </table>
          </div>
        </Panel>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    horasExtras: state.horasExtras.get('horasExtras'),
    filtros: state.crud.getIn(['filtroHorasExtras', 'currentRecord'], Map({}))
  };
}

const allActions = {
  ...actions,
  onChange,
  changeFieldValue,
  resetCrud
};

export default connect(mapStateToProps, allActions)(FormHorasExtras);
