export const PATRIMONIOS_LIST = 'PATRIMONIOS_LIST';
export const PATRIMONIOS_LIST_START = 'PATRIMONIOS_LIST_START';
export const PATRIMONIOS_LIST_SUCCESS = 'PATRIMONIOS_LIST_SUCCESS';
export const PATRIMONIOS_LIST_ERROR = 'PATRIMONIOS_LIST_ERROR';

export const INCORPORACOES_LIST = 'INCORPORACOES_LIST';
export const INCORPORACOES_LIST_START = 'INCORPORACOES_LIST_START';
export const INCORPORACOES_LIST_SUCCESS = 'INCORPORACOES_LIST_SUCCESS';
export const INCORPORACOES_LIST_ERROR = 'INCORPORACOES_LIST_ERROR';

export const DESINCORPORACOES_LIST = 'DESINCORPORACOES_LIST';
export const DESINCORPORACOES_LIST_START = 'DESINCORPORACOES_LIST_START';
export const DESINCORPORACOES_LIST_SUCCESS = 'DESINCORPORACOES_LIST_SUCCESS';
export const DESINCORPORACOES_LIST_ERROR = 'DESINCORPORACOES_LIST_ERROR';

export const CONSULTA_PATRIMONIOS = 'CONSULTA_PATRIMONIOS';
export const CONSULTA_PATRIMONIOS_START = 'CONSULTA_PATRIMONIOS_START';
export const CONSULTA_PATRIMONIOS_ERROR = 'CONSULTA_PATRIMONIOS_ERROR';
export const CONSULTA_PATRIMONIOS_SUCCESS = 'CONSULTA_PATRIMONIOS_SUCCESS';

export const RESET_CONSULTA_PATRIMONIOS = 'RESET_CONSULTA_PATRIMONIOS';

export const PATRIMONIOS_DETALHES_LIST = 'PATRIMONIOS_DETALHES_LIST';
export const PATRIMONIOS_DETALHES_LIST_START =
  'PATRIMONIOS_DETALHES_LIST_START';
export const PATRIMONIOS_DETALHES_LIST_SUCCESS =
  'PATRIMONIOS_DETALHES_LIST_SUCCESS';
export const PATRIMONIOS_DETALHES_LIST_ERROR =
  'PATRIMONIOS_DETALHES_LIST_ERROR';

export const MODELO = 'MODELO';
export const MODELO_START = 'MODELO_START';
export const MODELO_SUCCESS = 'MODELO_SUCCESS';
export const MODELO_ERROR = 'MODELO_ERROR';

export const PATRIMONIO_BEM = 'PATRIMONIO_BEM';
export const PATRIMONIO_BEM_START = 'PATRIMONIO_BEM_START';
export const PATRIMONIO_BEM_SUCCESS = 'PATRIMONIO_BEM_SUCCESS';
export const PATRIMONIO_BEM_ERROR = 'PATRIMONIO_BEM_ERROR';

export const PATRIMONIO_EMPENHO = 'PATRIMONIO_EMPENHO';
export const PATRIMONIO_EMPENHO_START = 'PATRIMONIO_EMPENHO_START';
export const PATRIMONIO_EMPENHO_SUCCESS = 'PATRIMONIO_EMPENHO_SUCCESS';
export const PATRIMONIO_EMPENHO_ERROR = 'PATRIMONIO_EMPENHO_ERROR';
