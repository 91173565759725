import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react.js';
import SumListField from 'portaltransparencia-common/components/SumListField.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export class RepasseDetalhe extends Component {
  static propTypes = {
    repasses: PropTypes.object.isRequired
  };

  renderRepasses = data => {
    const key = `${data.get('data')}_${data.get('valor')}_${data.get(
      'conta'
    )}_${data.get('idContaCorrente')}_${data.get('nroLancamento')}`;
    return (
      <tr key={key}>
        <td colSpan={2}>{DateUtils.formatDateTimeShort(data.get('data'))} </td>
        <td colSpan={9}>{data.get('local')}</td>
        <td colSpan={9}>{data.get('compHist')}</td>
        <td colSpan={1} className="right">
          <NumberFormatter value={data.get('valor')} />
        </td>
      </tr>
    );
  };

  render() {
    const { repasses } = this.props;

    return (
      <section>
        <div className="panel-table-outer">
          <table className="panel-table striped fancy">
            <TableHeader width="100%" empty={repasses.isEmpty()}>
              <tr>
                <th colSpan={2}>Data do Repasse</th>
                <th colSpan={9}>Banco</th>
                <th colSpan={9}>Histórico</th>
                <th style={{ textAlign: 'right' }} colSpan={1}>
                  Valor
                </th>
              </tr>
            </TableHeader>
            <tbody>{repasses.map(this.renderRepasses)}</tbody>
            {!repasses.isEmpty() && (
              <tfoot>
                <tr>
                  <td colSpan={2}></td>
                  <td colSpan={9}></td>
                  <td colSpan={9}></td>
                  <td colSpan={1} className="right">
                    <i>Total: </i>
                    <SumListField fieldName="valor" list={repasses} />
                  </td>
                </tr>
              </tfoot>
            )}
          </table>
        </div>
      </section>
    );
  }
}

export default RepasseDetalhe;
