import TableActions from 'portaltransparencia-common/components/TableActions.react';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export class LeisAtosPublicacoes extends Component {
  static propTypes = {
    veiculosPublicacao: PropTypes.object,
    onDownload: PropTypes.func
  };

  downloadArquivo = arquivo => {
    const { onDownload } = this.props;

    return () => onDownload(arquivo);
  };

  renderDetalhe = veiculoPublicacao => {
    const key = veiculoPublicacao.get('id');
    if (!key) {
      return null;
    }

    return (
      <TableActions
        ref="row"
        key={key}
        data="veiculoPublicacao"
        onDownload={this.downloadArquivo(veiculoPublicacao)}
        showDownload={veiculoPublicacao.get('idArquivo')}
      >
        <td>{veiculoPublicacao.get('descricao')}</td>
        <td>
          {DateUtils.formatDateTimeShort(
            veiculoPublicacao.get('dataPublicacao')
          )}
        </td>
        <td>{veiculoPublicacao.get('pagina')}</td>
        <td>{`${veiculoPublicacao.get('numeroEdicao')}/${veiculoPublicacao.get(
          'anoEdicao'
        )}`}</td>
        <td>{veiculoPublicacao.get('nomeArquivo')}</td>
      </TableActions>
    );
  };

  render() {
    const { veiculosPublicacao } = this.props;

    return (
      <section>
        <div className="panel-table-outer">
          <table className="panel-table striped fancy">
            <TableHeader empty={veiculosPublicacao.isEmpty()} onMore>
              <tr>
                <th>Veículo de Publicação</th>
                <th>Data de Publicação</th>
                <th>Página</th>
                <th>Número da Edição</th>
                <th>Arquivo</th>
                <th></th>
              </tr>
            </TableHeader>
            <tbody>{veiculosPublicacao.map(this.renderDetalhe)}</tbody>
          </table>
        </div>
      </section>
    );
  }
}

export default LeisAtosPublicacoes;
