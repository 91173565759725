import { FETCH_INFO } from './Types.js';

export function fetchInfo() {
  return ({ fetch }) => {
    return {
      type: FETCH_INFO,

      payload: fetch('/actuator/info')
    };
  };
}
