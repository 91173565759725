import Modal from 'portaltransparencia-common/components/Modal.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import * as actions from './Actions.js';

export class ServidorDetalheRegistroPonto extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    loadRegistrosPontos: PropTypes.func.isRequired,
    registrosPontos: PropTypes.object.isRequired
  };

  componentDidMount() {
    const { item, loadRegistrosPontos } = this.props;

    loadRegistrosPontos(item);
  }

  renderRegistrosPontos = item => {
    const key = item.get('data');

    return (
      <tr key={key}>
        <td id="data">{item.get('data')}</td>
        <td id="diaSemana">{item.get('diaSemana')}</td>
        <td id="registros">{item.get('registros')}</td>
        <td id="observacao">{item.get('observacao')}</td>
      </tr>
    );
  };

  render() {
    const { onClose, registrosPontos } = this.props;

    return (
      <Modal onClose={onClose}>
        <div className="container">
          <Panel
            title="Registro de Ponto"
            isForm
            tableResponsive
            expansible={false}
          >
            <div className="panel-table-outer">
              <table className="panel-table striped fancy">
                <TableHeader
                  empty={registrosPontos.isEmpty()}
                  text="Carregando registros de ponto"
                >
                  <tr>
                    <th>Data</th>
                    <th>Dia da Semana</th>
                    <th>Registros</th>
                    <th>Observação</th>
                  </tr>
                </TableHeader>
                <tbody>{registrosPontos.map(this.renderRegistrosPontos)}</tbody>
              </table>
            </div>
          </Panel>
        </div>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    registrosPontos: state.servidor.get('registrosPontos')
  };
}

export default connect(mapStateToProps, actions)(ServidorDetalheRegistroPonto);
