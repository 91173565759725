import React from 'react';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import PropTypes from 'prop-types';
import PageHeader from '../components/PageHeader.react.js';
import * as actions from './Actions.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import TableHeaderItem from 'portaltransparencia-common/components/TableHeaderItem.react';
import { getValueFromUrlSearchParams } from 'portaltransparencia-common/libs/URLUtils';
import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react.js';

export class HorasExtrasDetalhes extends Component {
  static propTypes = {
    item: PropTypes.object,
    location: PropTypes.object,
    detalhes: PropTypes.object,
    findDetalhesHorasExtras: PropTypes.func,
    horasExtras: PropTypes.object
  };

  componentDidMount() {
    const { findDetalhesHorasExtras, location } = this.props;
    const mesCompetencia = getValueFromUrlSearchParams(
      location,
      'mesCompetencia'
    );
    const secretaria = getValueFromUrlSearchParams(location, 'secretaria');
    findDetalhesHorasExtras(
      mesCompetencia,
      secretaria && secretaria.substring(0, 2)
    );
  }

  renderDetalhes(detalhe, index) {
    return (
      <tr ref="row" key={index}>
        <td>{detalhe.get('matricula')}</td>
        <td>{detalhe.get('nome')}</td>
        <td>
          <NumberFormatter value={detalhe.get('horasExtras')} />
        </td>
        <td>
          <NumberFormatter value={detalhe.get('valorPago')} />
        </td>
      </tr>
    );
  }

  render() {
    const { detalhes } = this.props;
    const secretaria = getValueFromUrlSearchParams(
      this.props.location,
      'secretaria'
    );

    return (
      <div>
        <PageHeader
          location={this.props.location}
          textoDetalhe={`Secretária:${secretaria}`}
        />
        <Panel title="Detalhes Horas Extras" mobileClose tableResponsive>
          <table
            className="panel-table striped fancy"
            id="tableDetalhesHorasExtras"
          >
            <TableHeader>
              <tr>
                <TableHeaderItem
                  field="matricula"
                  label="Matrícula"
                  page={detalhes}
                  searchWithPage={this.searchWithPage}
                  alignment="left"
                />
                <TableHeaderItem
                  field="nome"
                  label="Nome"
                  page={detalhes}
                  searchWithPage={this.searchWithPage}
                  alignment="left"
                />
                <TableHeaderItem
                  field="horasExtras"
                  label="Horas Extras"
                  page={detalhes}
                  searchWithPage={this.searchWithPage}
                  alignment="center"
                />
                <TableHeaderItem
                  field="valorPago"
                  label="Valor Pago"
                  page={detalhes}
                  searchWithPage={this.searchWithPage}
                  alignment="center"
                />
              </tr>
            </TableHeader>
            <tbody>{detalhes.map(this.renderDetalhes)}</tbody>
          </table>
        </Panel>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    detalhes: state.horasExtras.get('detalhes')
  };
}

export default connect(mapStateToProps, actions)(HorasExtrasDetalhes);
