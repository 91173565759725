import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';

import * as types from './Types.js';

const initialState = fromJS({
  orcamentos: [],
  objetivoEstrategico: [],
  objetivoEstrategicoArq: []
});

function orcamentoReducer(state = initialState, action) {
  switch (action.type) {
    case types.ORCAMENTO_LIST_START:
    case types.OBJETIVO_ESTRATEGICO_START:
    case types.OBJETIVO_ESTRATEGICO_ARQ_START: {
      return startLoad(state);
    }

    case types.ORCAMENTO_LIST_ERROR:
    case types.OBJETIVO_ESTRATEGICO_ERROR:
    case types.OBJETIVO_ESTRATEGICO_ARQ_ERROR: {
      return endLoad(state);
    }

    case types.ORCAMENTO_LIST_SUCCESS: {
      return endLoad(state.set('orcamentos', fromJS(action.payload)));
    }

    case types.OBJETIVO_ESTRATEGICO_SUCCESS: {
      return endLoad(state.set('objetivoEstrategico', fromJS(action.payload)));
    }
    default:
      return state;
  }
}

export default orcamentoReducer;
