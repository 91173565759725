import Modal from 'portaltransparencia-common/components/Modal.react.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

import GastoManutencaoTable from './GastoManutencaoTable.react';

export class GastoManutencaoModal extends Component {
  static propTypes = {
    gastosManutencao: PropTypes.object.isRequired,
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
  };

  render() {
    const { gastosManutencao, show, onClose } = this.props;

    if (show) {
      return (
        <Modal onClose={onClose}>
          <GastoManutencaoTable gastosManutencao={gastosManutencao} />
        </Modal>
      );
    }

    return null;
  }
}

export default GastoManutencaoModal;
