import * as types from './Types.js';

export function findAudienciasPublicas(filtro) {
  return ({ fetch }) => ({
    type: types.AUDIENCIAS_PUBLICAS,
    payload: {
      promise: fetch(`/api/audiencias-publicas?${filtro}`)
    }
  });
}

export function findAudienciasPublicasDetalhes(entidade, exercicio, id) {
  return ({ fetch }) => ({
    type: types.AUDIENCIAS_PUBLICAS_DETALHES,
    payload: {
      promise: fetch(
        `/api/audiencias-publicas/detalhes?entidade=${entidade}&exercicio=${exercicio}&id=${id}`
      )
    }
  });
}

export function findArquivos(entidade, exercicio, id) {
  return ({ fetch }) => ({
    type: types.AUDIENCIAS_PUBLICAS_ARQUIVOS,
    payload: {
      promise: fetch(
        `/api/audiencias-publicas/arquivos?entidade=${entidade}&exercicio=${exercicio}&id=${id}`
      )
    }
  });
}
