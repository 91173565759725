import * as types from './Types.js';

export function findGlossario() {
  return ({ fetch }) => ({
    type: types.GLOSSARIO,
    payload: {
      promise: fetch('/api/glossario/entidade')
    }
  });
}

export function selecionarLetra(letra) {
  return {
    type: types.LETRA_SELECIONADA,
    payload: letra
  };
}
