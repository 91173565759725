import { fromJS } from 'immutable';
import {
  changeFieldValue,
  endLoad,
  startLoad
} from 'portaltransparencia-common/crud/Core.js';

import * as types from './Types.js';

const initialState = fromJS({
  enviado: false,
  currentRecord: {}
});

function esicReducer(state = initialState, action) {
  switch (action.type) {
    case types.ENVIAR_MENSAGEM_START: {
      return startLoad(state);
    }

    case types.ENVIAR_MENSAGEM_ERROR: {
      return endLoad(state);
    }

    case types.ENVIAR_MENSAGEM_SUCCESS: {
      return endLoad(state.set('enviado', fromJS(action.payload)));
    }

    case types.CHANGE_FIELD_CONTATO_VALUE: {
      return changeFieldValue(state, action.payload);
    }

    case types.FORMULARIO_CONTATO_RESET: {
      return initialState;
    }
  }

  return state;
}
export default esicReducer;
