import { List } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';

import ResultadoPesquisaItem from './ResultadoPesquisaItem.react.js';

export class ResultadoPesquisaList extends React.PureComponent {
  static propTypes = {
    list: PropTypes.instanceOf(List).isRequired,
    valorPesquisado: PropTypes.string.isRequired
  };

  render() {
    const { list, valorPesquisado } = this.props;

    return (
      <ul>
        {list &&
          list.map(item => {
            return (
              <ResultadoPesquisaItem
                key={`${item.get('id')}_${item.get('route')}_${item.get(
                  'title'
                )}}`}
                item={item}
                valorPesquisado={valorPesquisado}
              />
            );
          })}
      </ul>
    );
  }
}

export default ResultadoPesquisaList;
