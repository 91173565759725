import * as types from './Types.js';

export function searchSuspensaoFornecedores(filter) {
  return ({ fetch }) => ({
    type: types.SUSPENSAOFORNECEDORES_LIST,
    payload: {
      promise: fetch(`/api/pessoaadm?${filter}`)
    }
  });
}
