import * as types from './Types.js';

export function findCreditosAdicionais(filtro) {
  return ({ fetch }) => {
    return {
      type: types.CREDITOS_ADICIONAIS,
      payload: {
        promise: fetch('/api/creditosadicionais?' + filtro)
      }
    };
  };
}
