import * as types from './Types.js';

export function findMovimentosGaragem(filter) {
  let url = '/api/movimento-garagem' + filter;

  return ({ fetch }) => ({
    type: types.MOVIMENTOS_GARAGEM_FIND,
    payload: {
      promise: fetch(url)
    }
  });
}

export function findMovimentoGaragem(entidade, exercicio, numero) {
  return ({ fetch }) => ({
    type: types.MOVIMENTO_GARAGEM,
    payload: {
      promise: fetch(
        `/api/movimento-garagem?entidade=${entidade}&exercicio=${exercicio}&numero=${numero}`
      )
    }
  });
}
