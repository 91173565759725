import 'isomorphic-fetch';
import 'font-awesome/css/font-awesome.min.css';
import '@elotech/arc/src/assets/less/compiled/portaltransparencia.css';

import App from './app/App.js';
import BootstrapErrorPage from 'portaltransparencia-common/components/BootstrapErrorPage.react.js';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import React from 'react';
import { Router } from 'react-router';
import changePrimaryColor from './changeColor';
import { configure } from 'portaltransparencia-common/config/Config.js';
import configureStore from 'portaltransparencia-common/config/ConfigureStore.js';
import { createBrowserHistory } from 'history';
import { fetchInitialState, resolveEntidade } from './FetchInitialState.js';
import { render } from 'react-dom';
import rootReducer from './app/Reducer.js';
import { replaceEntidadeFromPath } from './utils/BaseHrefUtils.js';
import { fromJS } from 'immutable';

const configuration = configure({
  apiURL: '/portaltransparencia-api',
  modulo: '34'
});

// Busca o id da entidade para configurar o basename
const idEntidade = resolveEntidade() || '';
const buildBaseName = process.env.PUBLIC_URL;
const basename = `${buildBaseName}${idEntidade ? '/' + idEntidade : ''}`;

console.log(
  `[INDEX] entidade:${idEntidade} basename:${basename} pathname:${window.location.pathname}`
);

const history = createBrowserHistory({
  basename
});

// recarrega a página para alterar a URL com o id da entidade
const primeiraVez =
  idEntidade &&
  (window.location.pathname === '/' ||
    window.location.pathname === buildBaseName ||
    window.location.pathname === `${buildBaseName}/`);
const novaUrl = replaceEntidadeFromPath(window.location.pathname, idEntidade);
const mudouEntidade =
  window.location.pathname !== '/' && window.location.pathname != novaUrl;

console.log(
  `[INDEX] primeiraVez:${!!primeiraVez} mudouEntidade:${!!mudouEntidade} novaUrl:${novaUrl}`
);
if (primeiraVez) {
  console.log('Recarregando a página para adicionar o id da entidade a URL');
  history.push('/');
} else if (mudouEntidade) {
  console.log('Recarregando a página para adicionar o id da entidade a URL');
  window.location.href = `${window.location.origin}${novaUrl}${window.location.search}`;
}

const initialState = {};

const store = configureStore(
  '../portaltransparencia/src/app/Reducer',
  rootReducer,
  configuration.apiURL,
  initialState,
  history
);

const app = document.getElementById('root');
const locale = (navigator.languages && navigator.languages[0]) || 'pt';

/* eslint-disable no-console */
fetchInitialState(store)
  .then(() => {
    console.log('Estado inicial carregado!!!');

    const onLoad = () => {
      bootstrapApp();
    };
    loadTemplate(onLoad);
  })
  .catch(err => {
    const message = 'Falha ao carregar o estado inicial da aplicação!';
    console.error(message, err);
    if (err.action) {
      console.info('Render BootstrapErrorPage');
      render(<BootstrapErrorPage message={message} error={err} />, app);
    } else {
      console.info('Render App');
      bootstrapApp();
    }
  });
/* eslint-enable no-console */

function loadTemplate(onLoad) {
  let urlImagem = store
    .getState()
    .entidade.getIn(['configuracoesGerais', 'urlImagem']);
  if (urlImagem) {
    var styleCustomImage = document.createElement('style');
    styleCustomImage.innerHTML = `
      .portal .pt-home:before { background-image: url(${urlImagem})!important };
      .portal .page-header .page-header-title:after { background-image: url(${urlImagem})!important };
    `;
    document.head.appendChild(styleCustomImage);
  }

  const cor = store.getState().entidade.getIn(['configuracoesGerais', 'cor']);
  changePrimaryColor(cor);
  onLoad();
}

function bootstrapApp() {
  render(
    <Provider store={store}>
      <IntlProvider locale={locale}>
        <Router history={history}>
          <App history={history} />
        </Router>
      </IntlProvider>
    </Provider>,
    app
  );
}
