import Widget from 'portaltransparencia-common/components/Widget.react.js';
import { format } from 'portaltransparencia-common/libs/NumberUtils';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import { Chart } from 'react-google-charts';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import PageHeader from '../components/PageHeader.react.js';
import NotasExplicativas from '../notaexplicativa/Form.react';
import * as actions from './Actions.js';

export class FormArrecadacao extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    totalDividaAtiva: PropTypes.object.isRequired,
    arrecadacoes: PropTypes.object.isRequired,
    totalPago: PropTypes.number.isRequired,
    percentualLancadoPorPago: PropTypes.number.isRequired,
    findReceitaArrecadacao: PropTypes.func.isRequired,
    findValorTotalDividasAtivas: PropTypes.func.isRequired,
    totalLancadoGlobal: PropTypes.number.isRequired,
    totalLancadoPeriodo: PropTypes.number.isRequired,
    totalInadimplentePeriodo: PropTypes.number.isRequired,
    percentualInadimplencia: PropTypes.number.isRequired
  };

  componentDidMount() {
    const { findReceitaArrecadacao, findValorTotalDividasAtivas } = this.props;
    const { entidade, exercicio } = StorageService.getItem('userInfo');

    findReceitaArrecadacao(entidade.id, exercicio.id);
    findValorTotalDividasAtivas();

    const node = ReactDOM.findDOMNode(this);

    this.setState({
      width: node.clientWidth
    });
  }

  getOptionsPie = () => {
    return {
      legend: 'top',
      width: 450,
      height: 300,
      slices: {
        0: { color: '#c7382d' },
        1: { color: '#379C44' },
        2: { color: '#f0b624' }
      },
      sliceVisibilityThreshold: 0
    };
  };

  getDataChartPie = data => {
    return [
      ['Tipo', 'Valor'],
      [
        'Lançado',
        data.get('valorLancado') -
          data.get('valorPago') -
          data.get('valorRenuncia')
      ],
      ['Pago', data.get('valorPago')],
      ['Isento', data.get('valorRenuncia')]
    ];
  };

  goDetalhePorBairros = (receita, descricao) => {
    return () => {
      let route = '/receita/arrecadacoes/bairros';

      if (receita) {
        route = route + `?receita=${receita}&descricao=${descricao}`;
      }

      this.props.history.push(route);
    };
  };

  renderChartPie = data => {
    const { exercicio } = StorageService.getItem('userInfo');

    if (!data) {
      return null;
    }

    const dataChart = this.getDataChartPie(data);
    const options = this.getOptionsPie();

    return (
      <Widget
        key={data.get('descricao')}
        icon="fa fa-pie-chart"
        title={`Valor Lançamento ${data.get('descricao')} ${exercicio.id}`}
      >
        {data.size > 0 && (
          <Chart
            chartType="PieChart"
            data={dataChart}
            legend_toggle
            graphID="iptu-pie-chart"
            options={options}
          />
        )}
        <div className="align-group right">
          <button
            className="btn"
            onClick={this.goDetalhePorBairros(
              data.get('receita'),
              data.get('descricao')
            )}
          >
            Detalhar por bairros
          </button>
        </div>
        <hr className="mb-xs" />

        <div className="panel-table-outer">
          <table className="panel-table striped fancy">
            <tbody>
              <tr>
                <td>( + ) Valor Lançamento</td>
                <td className="right">
                  {format(data.get('valorLancado'), 'R$ 0,0.00')}
                </td>
              </tr>
              <tr>
                <td>( - ) Valor Pago</td>
                <td className="right">
                  {format(data.get('valorPago'), 'R$ 0,0.00')}
                </td>
              </tr>
              <tr>
                <td>( - ) Valor Isenção</td>
                <td className="right">
                  {format(data.get('valorRenuncia'), 'R$ 0,0.00')}
                </td>
              </tr>
              <tr>
                <td>( = ) Valor a Arrecadar</td>
                <td className="right">
                  {format(
                    data.get('valorLancado') -
                      data.get('valorPago') -
                      data.get('valorRenuncia'),
                    'R$ 0,0.00'
                  )}
                </td>
              </tr>
              <tr>
                <td>Quantidade Cadastros</td>
                <td className="right">{data.get('totalCadastro')} cadastros</td>
              </tr>
              <tr>
                <td>Quantidade Cadastros Inadimplentes</td>
                <td className="right">
                  {data.get('totalCadastroInadimplente')} cadastros
                </td>
              </tr>
              <tr>
                <td>Quantidade Cadastros Isentos</td>
                <td className="right">
                  {data.get('totalCadastroRenuncia')} cadastros
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Widget>
    );
  };

  renderChartReceita = receita => {
    return (
      <Col md={6} sm={6} key={`${receita.get('receita')}}`}>
        {this.renderChartPie(receita)}
      </Col>
    );
  };

  render() {
    const {
      arrecadacoes,
      totalLancadoPeriodo,
      totalInadimplentePeriodo,
      percentualInadimplencia,
      totalDividaAtiva
    } = this.props;

    const valorDividaAtiva = format(
      totalDividaAtiva && totalDividaAtiva.get('valor')
        ? totalDividaAtiva.get('valor')
        : 0
    );

    return (
      <section>
        <PageHeader location={this.props.location} />

        <NotasExplicativas pathname={this.props.location.pathname} />

        <Widget
          icon="fa fa-dashboard"
          title="Percentual de inadimplência até o período"
        >
          <Row>
            <Col sm={4} md={4}>
              <article className="widget-numeric">
                <b className="widget-numeric-number">
                  {format(totalLancadoPeriodo)}
                </b>
                <p className="widget-numeric-subtitle">
                  Total Lançado referente ao período
                </p>
              </article>
            </Col>
            <Col sm={4} md={4}>
              <article className="widget-numeric">
                <b className="widget-numeric-number">
                  {format(totalInadimplentePeriodo)}
                </b>
                <p className="widget-numeric-subtitle">Valores em Atraso</p>
              </article>
            </Col>
            <Col sm={4} md={4}>
              <article className="widget-numeric">
                <b
                  className="widget-numeric-number"
                  style={{ color: '#379C44' }}
                >
                  {format(percentualInadimplencia || 0) + '%'}
                </b>
                <p className="widget-numeric-subtitle">
                  Percentual de Inadimplência
                </p>
              </article>
            </Col>
          </Row>
        </Widget>

        <Row>{arrecadacoes.map(this.renderChartReceita)}</Row>

        <Widget icon="fa fa-usd" title="Valor total da Dívida Ativa">
          <article className="widget-numeric">
            <b className="widget-numeric-number">{valorDividaAtiva}</b>
          </article>
        </Widget>
      </section>
    );
  }
}

function getTotal(arrecadacoes, field) {
  return arrecadacoes
    .map(a => a.get(field))
    .reduce((acc, curr) => acc + curr, 0);
}

function mapStateToProps(state) {
  const arrecadacoes = state.arrecadacao.get('arrecadacoes');

  const totalLancado = getTotal(arrecadacoes, 'valorLancado');
  const totalRenuncia = getTotal(arrecadacoes, 'valorRenuncia');
  const totalPago = getTotal(arrecadacoes, 'valorPago');
  const totalLancadoPeriodo = getTotal(arrecadacoes, 'valorLancadoPeriodo');
  const totalInadimplentePeriodo = getTotal(arrecadacoes, 'valorInadimplente');
  const totalLancadoGlobal = totalLancado - totalRenuncia;

  return {
    totalDividaAtiva: state.arrecadacao.get('totalDividaAtiva'),
    arrecadacoes: arrecadacoes,
    totalPago,
    totalLancadoPeriodo,
    totalInadimplentePeriodo,
    totalLancadoGlobal,
    percentualLancadoPorPago: (totalPago / totalLancadoGlobal) * 100,
    percentualInadimplencia:
      (totalInadimplentePeriodo / totalLancadoPeriodo) * 100
  };
}

export default connect(mapStateToProps, actions)(FormArrecadacao);
