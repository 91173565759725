import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

import { getDate, getNameAndSize } from '../licitacoes/Arquivo';

export class SubvencaoAnexos extends Component {
  static propTypes = {
    anexos: PropTypes.object.isRequired,
    onDownload: PropTypes.func.isRequired
  };

  renderAnexos = data => {
    const { onDownload } = this.props;
    const key = data.get('id');

    return (
      <TableActions
        ref="row"
        key={key}
        data={data.get('id')}
        onDownload={data.get('id') && onDownload}
      >
        <td>{getNameAndSize(data)}</td>
        <td>{getDate(data.get('dataArquivo'))}</td>
      </TableActions>
    );
  };

  render() {
    const { anexos } = this.props;

    return (
      <div className="panel-table-outer">
        <table className="panel-table striped fancy">
          <TableHeader empty={anexos.isEmpty()}>
            <tr>
              <th>Arquivo</th>
              <th>Data Publicação</th>
              <th></th>
            </tr>
          </TableHeader>
          <tbody>{anexos.map(this.renderAnexos)}</tbody>
        </table>
      </div>
    );
  }
}

export default SubvencaoAnexos;
