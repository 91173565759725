export const CENTRO_CUSTO = 'CENTRO_CUSTO';
export const CENTRO_CUSTO_START = 'CENTRO_CUSTO_START';
export const CENTRO_CUSTO_ERROR = 'CENTRO_CUSTO_ERROR';
export const CENTRO_CUSTO_SUCCESS = 'CENTRO_CUSTO_SUCCESS';

export const PESQUISA_ITEMESTOQUE = 'PESQUISA_ITEMESTOQUE';
export const PESQUISA_ITEMESTOQUE_START = 'PESQUISA_ITEMESTOQUE_START';
export const PESQUISA_ITEMESTOQUE_ERROR = 'PESQUISA_ITEMESTOQUE_ERROR';
export const PESQUISA_ITEMESTOQUE_SUCCESS = 'PESQUISA_ITEMESTOQUE_SUCCESS';

export const PESQUISA_ITEMCONSUMO = 'PESQUISA_ITEMCONSUMO';
export const PESQUISA_ITEMCONSUMO_START = 'PESQUISA_ITEMCONSUMO_START';
export const PESQUISA_ITEMCONSUMO_ERROR = 'PESQUISA_ITEMCONSUMO_ERROR';
export const PESQUISA_ITEMCONSUMO_SUCCESS = 'PESQUISA_ITEMCONSUMO_SUCCESS';

export const PESQUISA_ITEMCONSUMO_TOTAL = 'PESQUISA_ITEMCONSUMO_TOTAL';
export const PESQUISA_ITEMCONSUMO_TOTAL_START =
  'PESQUISA_ITEMCONSUMO_TOTAL_START';
export const PESQUISA_ITEMCONSUMO_TOTAL_ERROR =
  'PESQUISA_ITEMCONSUMO_TOTAL_ERROR';
export const PESQUISA_ITEMCONSUMO_TOTAL_SUCCESS =
  'PESQUISA_ITEMCONSUMO_TOTAL_SUCCESS';

export const PESQUISA_MOVIMENTACAO = 'PESQUISA_MOVIMENTACAO';
export const PESQUISA_MOVIMENTACAO_START = 'PESQUISA_MOVIMENTACAO_START';
export const PESQUISA_MOVIMENTACAO_ERROR = 'PESQUISA_MOVIMENTACAO_ERROR';
export const PESQUISA_MOVIMENTACAO_SUCCESS = 'PESQUISA_MOVIMENTACAO_SUCCESS';

export const ALMOXARIFADO_ORGAO = 'ALMOXARIFADO_ORGAO';
export const ALMOXARIFADO_ORGAO_START = 'ALMOXARIFADO_ORGAO_START';
export const ALMOXARIFADO_ORGAO_ERROR = 'ALMOXARIFADO_ORGAO_ERROR';
export const ALMOXARIFADO_ORGAO_SUCCESS = 'ALMOXARIFADO_ORGAO_SUCCESS';

export const RESET_ITENS = 'RESET_ITENS';
export const RECUPERAR_FILTRO = 'RECUPERAR_FILTRO';
