import StorageService from 'portaltransparencia-common/libs/StorageService.js';

import * as types from './Types.js';

export function findPublicacoes() {
  return ({ fetch }) => {
    let userInfo = StorageService.getItem('userInfo');
    const { entidade, exercicio } = userInfo;

    return {
      type: types.PESQUISA_PUBLICACOES,
      payload: {
        promise: fetch(
          `/api/publicacoes?entidade=${entidade.id}&exercicio=${exercicio.id}`
        )
      }
    };
  };
}

export function filterPublicacoes(filtro) {
  return ({ getState }) => {
    const publicacoes = getState().publicacao.get('publicacoesFiltradas');

    return {
      type: types.FILTRAR_PUBLICACOES,
      payload: publicacoes,
      meta: filtro
    };
  };
}

export function resetPublicacoes() {
  return {
    type: types.RESETAR_PUBLICACOES
  };
}

export function findPublicacoesGrupo(idGrupo) {
  return ({ fetch }) => {
    let userInfo = StorageService.getItem('userInfo');
    const { entidade, exercicio } = userInfo;

    return {
      type: types.PESQUISA_PUBLICACOESGRUPO,
      payload: {
        promise: fetch(
          `/api/publicacoes/${idGrupo}?entidade=${entidade.id}&exercicio=${exercicio.id}`
        )
      }
    };
  };
}

export function findPublicacoesGrupoSubGrupo(idGrupo, idSubGrupo) {
  return ({ fetch }) => {
    let userInfo = StorageService.getItem('userInfo');
    const { entidade, exercicio } = userInfo;

    return {
      type: types.PESQUISA_PUBLICACOESGRUPO,
      payload: {
        promise: fetch(
          `/api/publicacoes/${idGrupo}/${idSubGrupo}?entidade=${entidade.id}&exercicio=${exercicio.id}`
        )
      }
    };
  };
}
