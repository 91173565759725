import Panel from 'portaltransparencia-common/components/Panel.react.js';
import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';
import DisplayData from 'portaltransparencia-common/components/displaydata/DisplayData.react.js';
import DisplayDataItem from 'portaltransparencia-common/components/displaydata/DisplayDataItem.react.js';
import DisplayDataRow from 'portaltransparencia-common/components/displaydata/DisplayDataRow.react.js';
import { downloadArquivo } from '../arquivo/Actions.js';
import PageHeader from '../components/PageHeader.react.js';
import * as actions from './Actions.js';
import NumberUtils from 'portaltransparencia-common/libs/NumberUtils';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import LinkToConvenio from './LinkToConvenio.js';

export class FormEmendaParlamentarDetail extends Component {
  static propTypes = {
    emenda: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    downloadArquivo: PropTypes.func.isRequired,
    findEmendaById: PropTypes.func.isRequired,
    convenio: PropTypes.object,
    findConvenioWithoutId: PropTypes.func
  };

  componentDidMount() {
    const {
      findEmendaById,
      findConvenioWithoutId,
      match: {
        params: { id }
      }
    } = this.props;

    findEmendaById(id).then(emenda => {
      if (emenda.value.vinculadoConvenio) {
        let entidade = emenda.value.entidade;
        let nrConvenio = emenda.value.numero;
        let nrAnoConvenio = emenda.value.ano;

        if (entidade && nrConvenio && nrAnoConvenio) {
          findConvenioWithoutId(entidade, nrConvenio, nrAnoConvenio);
        }
      }
    });
  }

  onDownloadEstrutura = idArquivo => {
    return () => {
      const { downloadArquivo } = this.props;

      downloadArquivo(idArquivo);
    };
  };

  getFonteRecurso(convenio) {
    let fonteRecurso = '';
    let juncao = '';

    if (convenio.get('fonteRecurso')) {
      fonteRecurso += convenio.get('fonteRecurso');
      juncao = ' - ';
    }

    if (convenio.get('descricaoFonte')) {
      fonteRecurso += juncao + convenio.get('descricaoFonte');
    }
    return fonteRecurso;
  }

  getFiscalConvenio(convenio) {
    let fiscalConvenio = '';

    if (convenio.get('fiscalConvenio')) {
      fiscalConvenio = convenio.get('fiscalConvenio');
    }
    return fiscalConvenio;
  }

  render() {
    const { emenda, convenio, location } = this.props;

    const fonteRecurso = this.getFonteRecurso(convenio);
    const fiscalConvenio = this.getFiscalConvenio(convenio);
    const linkDoGoverno = (
      <em>
        <a target="_blank" href={convenio.get('linkGov')}>
          {convenio.get('linkGov')}{' '}
        </a>
      </em>
    );
    let vinculadoConvenioVar = '';

    if (emenda.get('vinculadoConvenio')) {
      vinculadoConvenioVar = 'S';
    }

    const tamanho =
      (emenda.get('arquivo') &&
        NumberUtils.formatBytes(emenda.get('tamanhoArquivo'))) ||
      '';

    return (
      <section>
        <Helmet
          id="helmet"
          title={'Emenda Parlamentar ' + emenda.get('numero')}
        />

        <PageHeader
          location={location}
          textoDetalhe={`EmendaParlamentar: ${emenda.get('numero')}`}
        />

        <Panel title="Detalhes da Emenda Parlamentar">
          <DisplayData border>
            <DisplayDataRow>
              <DisplayDataItem
                sm={2}
                title="Número"
                value={
                  emenda.get('ano') ? (
                    <LinkToConvenio
                      entidade={emenda.get('entidade')}
                      nrConvenio={emenda.get('numero')}
                      nrAnoConvenio={emenda.get('ano')}
                      historyConvenio={this.props.history}
                      vinculadoConvenio={vinculadoConvenioVar}
                    />
                  ) : (
                    emenda.get('numero')
                  )
                }
                id="numero"
              />
              <DisplayDataItem
                sm={2}
                title="Ano"
                value={emenda.get('ano')}
                id="ano"
              />
              <DisplayDataItem
                sm={8}
                title="Tipo"
                value={emenda.get('tipo')}
                id="tipo"
              />
            </DisplayDataRow>

            <DisplayDataRow>
              <DisplayDataItem
                sm={12}
                title="Objeto"
                value={emenda.get('objeto')}
                id="objeto"
              />
            </DisplayDataRow>

            <DisplayDataRow>
              <DisplayDataItem
                sm={6}
                title="Autoria"
                value={emenda.get('autoria')}
                id="autoria"
              />
              <DisplayDataItem
                sm={6}
                title="Função"
                value={emenda.get('funcao')}
                id="funcao"
              />
            </DisplayDataRow>

            <DisplayDataRow>
              <DisplayDataItem
                sm={6}
                title="Valor Previsto"
                value={NumberUtils.format(emenda.get('valorPrevisto'))}
                id="valorPrevisto"
              />
              <DisplayDataItem
                sm={6}
                title="Valor Realizado"
                value={NumberUtils.format(emenda.get('valorRealizado'))}
                id="valorRealizado"
              />
            </DisplayDataRow>
          </DisplayData>
          {emenda.get('vinculadoConvenio') && (
            <div className="panel-inside-panel mt-xs">
              <Panel insidePanel title="Convênio" isTable tableResponsive>
                <DisplayData border>
                  <DisplayDataRow>
                    <DisplayDataItem
                      sm={7}
                      title="Fornecedor"
                      value={convenio.get('nome')}
                      id="nome"
                    />
                    <DisplayDataItem
                      sm={5}
                      title="CNPJ"
                      value={convenio.get('cnpj')}
                      id="cnpj"
                    />
                  </DisplayDataRow>

                  <DisplayDataRow>
                    <DisplayDataItem
                      sm={4}
                      title="Data Celebração"
                      value={DateUtils.formatDateTimeShort(
                        convenio.get('dataCelebracao')
                      )}
                      id="dataCelebracao"
                    />
                    <DisplayDataItem
                      sm={4}
                      title="Início Vigência Convênio"
                      value={DateUtils.formatDateTimeShort(
                        convenio.get('dataInicioVigencia')
                      )}
                      id="dataInicioVigencia"
                    />
                    <DisplayDataItem
                      sm={4}
                      title="Fim Vigência Convênio"
                      value={DateUtils.formatDateTimeShort(
                        convenio.get('dataFimVigencia')
                      )}
                      id="dataFimVigencia"
                    />
                  </DisplayDataRow>

                  <DisplayDataRow>
                    <DisplayDataItem
                      sm={4}
                      title="Quantidade Parcelas"
                      value={convenio.get('qtParcelas')}
                      id="qtParcelas"
                    />
                    <DisplayDataItem
                      sm={4}
                      title="Valor Repasse Convênio"
                      value={NumberUtils.format(convenio.get('valorConvenio'))}
                      id="valorConvenio"
                    />
                    <DisplayDataItem
                      sm={4}
                      title="Valor Recurso Próprio"
                      value={NumberUtils.format(
                        convenio.get('vlRecursoProprio')
                      )}
                      id="vlRecursoProprio"
                    />
                    <DisplayDataItem
                      sm={4}
                      title="Situação"
                      value={convenio.get('situacao')}
                      id="situacao"
                    />
                  </DisplayDataRow>

                  <DisplayDataRow>
                    <DisplayDataItem
                      sm={12}
                      title="Objeto"
                      value={convenio.get('dsObjeto')}
                      id="dsObjeto"
                    />
                  </DisplayDataRow>

                  <DisplayDataRow>
                    <DisplayDataItem
                      sm={7}
                      title="Fonte de Recurso"
                      value={fonteRecurso}
                      id="fonteRecurso"
                    />
                    <DisplayDataItem
                      sm={5}
                      title="Fiscal Convênio"
                      value={fiscalConvenio}
                      id="fiscalConvenio"
                    />
                  </DisplayDataRow>

                  <DisplayDataRow>
                    <DisplayDataItem
                      sm={12}
                      title="Link do Governo"
                      value={linkDoGoverno}
                      id="linkGov"
                    />
                  </DisplayDataRow>
                </DisplayData>
              </Panel>
            </div>
          )}
          <div className="panel-inside-panel mt-xs">
            <Panel insidePanel title="Arquivo" isTable tableResponsive>
              <DisplayDataRow>
                <DisplayDataItem
                  sm={12}
                  value={emenda.getIn(['arquivo', 'nome'])}
                  id="arquivo"
                />
              </DisplayDataRow>
              {emenda.get('arquivo') && (
                <DisplayDataRow>
                  <DisplayDataItem
                    sm={12}
                    value={
                      <button
                        type="button"
                        className="btn neutral icon-left mt-xs"
                        onClick={this.onDownloadEstrutura(
                          emenda.getIn(['arquivo', 'eloArquivo', 'id'])
                        )}
                      >
                        <i className="fa fa-download"></i>
                        {`Baixar ${tamanho}`}
                      </button>
                    }
                    id="arquivoDownload"
                  />
                </DisplayDataRow>
              )}
            </Panel>
          </div>
        </Panel>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    emenda: state.emendaParlamentar.get('emenda'),
    convenio: state.emendaParlamentar.get('convenio')
  };
}

const allActions = {
  ...actions,
  downloadArquivo
};

export default connect(
  mapStateToProps,
  allActions
)(FormEmendaParlamentarDetail);
