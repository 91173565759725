const REGEX_PARSE_ID_ENTIDADE = /^(\/[a-z]+)?(\/\d+)?(\/?.*)$/; ///^\/(\d+)\/?.*$/;

export const extractEntidadeFromPath = path => {
  var match = path?.match(REGEX_PARSE_ID_ENTIDADE);
  if (match && match[2]) {
    return match[2].replace('/', '');
  }
};

export const replaceEntidadeFromPath = (path, novaEntidade) => {
  if (path === '/') {
    return `/${novaEntidade}`;
  }
  if (!novaEntidade) {
    return path;
  }
  return path?.replace(REGEX_PARSE_ID_ENTIDADE, (match, p1, p2, p3) => {
    return `${p1 || ''}/${novaEntidade}${p3 || ''}`;
  });
};
