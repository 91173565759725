import Input from 'portaltransparencia-common/components/EloInput.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';

import FilterButtons from '../components/FilterButtons.react';

export default class EstabelecimentoFiltro extends Component {
  static propTypes = {
    show: PropTypes.bool,
    findEstabelecimentos: PropTypes.func.isRequired,
    filterEscalas: PropTypes.func.isRequired,
    resetEscalas: PropTypes.func.isRequired
  };

  onPesquisa = () => {
    const { filterEscalas, resetEscalas } = this.props;
    const { estabelecimento: filtro } = this.refs;

    resetEscalas();

    if (filtro.getValue()) {
      filterEscalas(filtro.getValue());
    }
  };

  onResetFiltro = () => {
    const { resetEscalas } = this.props;
    resetEscalas();
  };

  handleKeyDown = event => {
    const teclaEnter = 13;
    if (event.keyCode === teclaEnter) {
      this.onPesquisa();
    }
  };

  render() {
    const { show } = this.props;

    if (!show) {
      return null;
    }

    return (
      <Panel title="Consultar em Estabelecimentos" mobileClose isForm>
        <Row>
          <Col xs={12} sm={4} md={8}>
            <Input
              id="estabelecimento"
              name="estabelecimento"
              ref="estabelecimento"
              label="Estabelecimento"
              placeholder="Pesquisar Estabelecimentos"
              onKeyDown={this.handleKeyDown}
              type="text"
            />
          </Col>
          <Col xs={12} sm={4} md={4}>
            <FilterButtons
              idBtnReset="btnResetFilter"
              onConsulta={this.onPesquisa}
              onReset={this.onResetFiltro}
            />
          </Col>
        </Row>
      </Panel>
    );
  }
}
