import { Map } from 'immutable';
import { param } from 'jquery';
import DatePicker from 'portaltransparencia-common/components/DatePicker.react.js';
import Input from 'portaltransparencia-common/components/EloInput.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import SearchPagination from 'portaltransparencia-common/components/SearchPagination.react.js';
import {
  onChange,
  resetCrud
} from 'portaltransparencia-common/crud/Actions.js';
import {
  addFilterUrl,
  addPagination
} from 'portaltransparencia-common/libs/AddFilter.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import { getAllValuesFromUrlSearchParams } from 'portaltransparencia-common/libs/URLUtils';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import FilterButtons from '../components/FilterButtons.react';
import PageHeader from '../components/PageHeader.react.js';
import NotasExplicativas from '../notaexplicativa/Form.react';
import UltimaAtualizacaoByTable from '../ultimaatualizacao/UltimaAtualizacaoByTable.react.js';
import * as actions from './Actions.js';
import AgendaPagamentoConta from './AgendaPagamentoConta.react.js';
import AgendaPagamentoListagem from './AgendaPagamentoListagem.react.js';

export class FormAgendaPagamento extends Component {
  static propTypes = {
    page: PropTypes.object.isRequired,
    contaPagamento: PropTypes.object,
    findAptosPagamentos: PropTypes.func.isRequired,
    findAptosPagamentosTotal: PropTypes.func.isRequired,
    findContaPagamento: PropTypes.func.isRequired,
    retencoesLiquidacao: PropTypes.object,
    findRetencoesLiquidacao: PropTypes.func.isRequired,
    total: PropTypes.object.isRequired,

    filtros: PropTypes.object,
    onChange: PropTypes.func,
    location: PropTypes.object.isRequired,
    resetCrud: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      filtradoFornecedor: false,
      showDetail: false
    };
  }

  componentDidMount() {
    const {
      location,
      page,
      findAptosPagamentos,
      findAptosPagamentosTotal
    } = this.props;
    const params = getAllValuesFromUrlSearchParams(location);

    const { exercicio } = StorageService.getItem('userInfo');
    let filter = this.montarFiltro(page);

    if (!params.dataInicial) {
      params.dataInicial = `${exercicio.id}-01-01`;
    }

    if (!params.dataFinal) {
      params.dataFinal = `${exercicio.id}-01-31`;
    }

    this.onChangeFiltro({
      name: 'cnpj',
      value: params.cnpj
    });
    this.onChangeFiltro({
      name: 'nome',
      value: params.nome
    });
    this.onChangeFiltro({
      name: 'notaFiscal',
      value: params.notaFiscal
    });
    this.onChangeFiltro({
      name: 'dataInicial',
      value: params.dataInicial
    });
    this.onChangeFiltro({
      name: 'dataFinal',
      value: params.dataFinal
    });
    this.onChangeFiltro({
      name: 'empenho',
      value: params.empenho
    });
    this.onChangeFiltro({
      name: 'empenhoExercicio',
      value: params.empenhoExercicio
    });
    this.onChangeFiltro({
      name: 'mostraPagos',
      value: true
    });

    filter = addFilterUrl(filter, `dataInicial=${`${params.dataInicial}`}`);
    filter = addFilterUrl(filter, `dataFinal=${`${params.dataFinal}`}`);

    findAptosPagamentos(filter);
    findAptosPagamentosTotal(filter);
  }

  openDetail = () => {
    this.setState({
      showDetail: true
    });
  };

  onCloseDetail = () => {
    this.setState({
      showDetail: false
    });
  };

  consultarAptosPagamentos = () => {
    const { page } = this.props;
    const initialPage = page.set('number', 0);

    this.searchWithPage(initialPage);
  };

  onConsultaAptosPagamento = () => {
    const { filtros } = this.props;
    const filtrado = filtros.get('cnpj') || filtros.get('nome');

    this.setState({ filtradoFornecedor: filtrado !== undefined });

    this.consultarAptosPagamentos();
  };

  onChangeFiltro = event => {
    const { onChange } = this.props;
    onChange('filtroAptoPagamento', event);
  };

  onResetFiltro = () => {
    const {
      resetCrud,
      findAptosPagamentos,
      findAptosPagamentosTotal
    } = this.props;

    this.setState({ filtradoFornecedor: false });
    resetCrud('filtroAptoPagamento');
    findAptosPagamentos('');
    findAptosPagamentosTotal('');
  };

  montarFiltro = function (page) {
    let userInfo = StorageService.getItem('userInfo');
    const { entidade } = userInfo;

    const { filtros } = this.props;

    let filter = 'entidade=' + entidade.id;

    if (filtros.get('cnpj')) {
      filter = addFilterUrl(filter, `cnpjCpf=${filtros.get('cnpj')}`);
    }

    if (filtros.get('nome')) {
      filter = addFilterUrl(
        filter,
        `nome=${encodeURIComponent(filtros.get('nome'))}`
      );
    }

    if (filtros.get('notaFiscal')) {
      filter = addFilterUrl(filter, `notaFiscal=${filtros.get('notaFiscal')}`);
    }

    if (
      filtros.get('dataInicial') &&
      filtros.get('dataInicial').length === 10
    ) {
      let validValue = DateUtils.transform(
        filtros.get('dataInicial'),
        'yy-mm-dd',
        'yy-mm-dd'
      );
      filter = addFilterUrl(filter, `dataInicial=${validValue}`);
    }

    if (filtros.get('dataFinal') && filtros.get('dataFinal').length === 10) {
      let validValue = DateUtils.transform(
        filtros.get('dataFinal'),
        'yy-mm-dd',
        'yy-mm-dd'
      );
      filter = addFilterUrl(filter, `dataFinal=${validValue}`);
    }

    if (filtros.get('empenho')) {
      filter = addFilterUrl(filter, `empenho=${filtros.get('empenho')}`);
    }

    if (filtros.get('empenhoExercicio')) {
      filter = addFilterUrl(
        filter,
        `empenhoExercicio=${filtros.get('empenhoExercicio')}`
      );
    }

    if (this.refs.mostraPagos) {
      if (this.refs.mostraPagos.getChecked()) {
        filter = addFilterUrl(
          filter,
          'isSomentePagos=' + this.refs.mostraPagos.getChecked()
        );
      }
      filter = addFilterUrl(filter, 'isPorExercicio=true');
    }

    filter = addFilterUrl(filter, `tipo=${'agenda-pagamento'}`);

    return addPagination(page, filter);
  };

  searchWithPage = page => {
    const { findAptosPagamentos, findAptosPagamentosTotal } = this.props;

    const filtro = `${this.montarFiltro(page)}`;

    findAptosPagamentos(filtro);
    findAptosPagamentosTotal(filtro);
  };

  mensagemSemFornecedor = liquidacao => {
    if (liquidacao && !liquidacao.isEmpty()) {
      return 'Entidade não possui pendências financeiras "processadas" para filtro informado';
    } else {
      return 'Entidade não possui pendências financeiras "processadas"!';
    }
  };

  render() {
    const {
      page,
      contaPagamento,
      filtros,
      location,
      total,
      findContaPagamento,
      retencoesLiquidacao,
      findRetencoesLiquidacao
    } = this.props;
    const { filtradoFornecedor, showDetail } = this.state;
    let mensagem = this.mensagemSemFornecedor(filtros);

    return (
      <section>
        <AgendaPagamentoConta
          contaPagamento={contaPagamento}
          show={showDetail}
          onClose={this.onCloseDetail}
          retencoesLiquidacao={retencoesLiquidacao}
        />

        <PageHeader location={location} />

        <NotasExplicativas pathname={this.props.location.pathname} />

        <Panel
          isForm
          mobileClose
          title="Consulta"
          ref="panelFiltroAptosPagamento"
        >
          <form>
            <Row>
              <Col xs={12} sm={4} md={4}>
                <Input
                  id="cnpj"
                  name="cnpj"
                  ref="cnpj"
                  placeholder="CNPJ/CPF"
                  label="CNPJ/CPF"
                  type="text"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('cnpj')}
                />
              </Col>
              <Col xs={12} sm={8} md={8}>
                <Input
                  id="nome"
                  name="nome"
                  ref="nome"
                  placeholder="Nome"
                  label="Nome"
                  type="text"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('nome')}
                />
              </Col>
              <Col xs={12} sm={8} md={4}>
                <Input
                  id="notaFiscal"
                  name="notaFiscal"
                  ref="notaFiscal"
                  placeholder="Nota Fiscal"
                  label="Nota Fiscal"
                  type="text"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('notaFiscal')}
                />
              </Col>
              <Col xs={12} sm={6} md={2}>
                <DatePicker
                  id="dataInicial"
                  name="dataInicial"
                  ref="dataInicial"
                  label="Data Inicial"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('dataInicial')}
                />
              </Col>
              <Col xs={12} sm={6} md={2}>
                <DatePicker
                  id="dataFinal"
                  name="dataFinal"
                  ref="dataFinal"
                  label="Data Final"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('dataFinal')}
                />
              </Col>
              <Col xs={12} sm={6} md={2}>
                <Input
                  id="empenho"
                  name="empenho"
                  ref="empenho"
                  placeholder="Nº Empenho"
                  label="Nº Empenho"
                  type="number"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('empenho')}
                />
              </Col>
              <Col xs={12} sm={6} md={2}>
                <Input
                  id="empenhoExercicio"
                  name="empenhoExercicio"
                  ref="empenhoExercicio"
                  placeholder="Ano Empenho"
                  label="Ano Empenho"
                  type="number"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('empenhoExercicio')}
                />
              </Col>
              <Col xs={12} sm={8} md={6}>
                <div className="form-group">
                  <label className="label"></label>
                  <div className="radio-check-group">
                    <Input
                      id="mostraPagos"
                      label="Mostrar pagamentos já efetuados"
                      type="checkbox"
                      name="mostraPagos"
                      ref="mostraPagos"
                      onChange={this.onChangeFiltro}
                      checked={filtros.get('mostraPagos')}
                    />
                  </div>
                </div>
              </Col>

              <Col xs={12} sm={4} md={4}>
                <FilterButtons
                  idBtnReset="btnResetFilter"
                  onConsulta={this.onConsultaAptosPagamento}
                  onReset={this.onResetFiltro}
                />
              </Col>
            </Row>
          </form>
        </Panel>

        <Panel isForm tableResponsive expansible={false}>
          <Row>
            <Col xs={6}>
              <UltimaAtualizacaoByTable
                modulo="3"
                table="liquidacao"
                column="dataultimaatualizacao"
              />
            </Col>
            <Col xs={6} />
          </Row>

          <AgendaPagamentoListagem
            page={page}
            searchWithPage={this.searchWithPage}
            liquidacoes={page.get('content')}
            total={total}
            filter={this.montarFiltro(page)}
            tipo={'aptos-pagamentos'}
            mensagem={mensagem}
            filtradoFornecedor={filtradoFornecedor}
            mostraDataPagamento={filtros.get('mostraPagos')}
            openDetail={this.openDetail}
            findContaPagamento={findContaPagamento}
            findRetencoesLiquidacao={findRetencoesLiquidacao}
          />

          <SearchPagination page={page} searchWithPage={this.searchWithPage} />
        </Panel>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    filtros: state.crud.getIn(
      ['filtroAptoPagamento', 'currentRecord'],
      Map({})
    ),
    page: state.agendaPagamento.get('aptosPagamentos'),
    total: state.agendaPagamento.get('total'),
    contaPagamento: state.agendaPagamento.get('contaPagamento'),
    retencoesLiquidacao: state.agendaPagamento.get('retencoesLiquidacao')
  };
}

const allActions = {
  ...actions,
  onChange,
  resetCrud
};

export default connect(mapStateToProps, allActions)(FormAgendaPagamento);
