import BotaoImprimir from 'portaltransparencia-common/components/BotaoImprimir.react.js';
import DisplayData from 'portaltransparencia-common/components/displaydata/DisplayData.react.js';
import DisplayDataItem from 'portaltransparencia-common/components/displaydata/DisplayDataItem.react.js';
import DisplayDataRow from 'portaltransparencia-common/components/displaydata/DisplayDataRow.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import NumberUtils from 'portaltransparencia-common/libs/NumberUtils.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import LinkToEmpenho from '../empenhos/LinkToEmpenho.react';
import CabecalhoEntidade from '../entidades/CabecalhoEntidade.react.js';

export class DiariaDetalheInformacao extends Component {
  static propTypes = {
    diaria: PropTypes.object.isRequired,
    mostrarGastosTotais: PropTypes.bool,
    isIntegradoOxy: PropTypes.bool
  };

  render() {
    const { diaria, mostrarGastosTotais, isIntegradoOxy } = this.props;
    const possuiValorAnulado = diaria.get('valorAnulado') > 0;
    const numeroProcesso = diaria.get('numeroProcesso') ?? '';
    const anoProcesso = diaria.get('anoProcesso') ?? '';

    return (
      <section>
        <CabecalhoEntidade />

        <BotaoImprimir />

        <Panel title="Detalhes da Diária">
          <DisplayData border>
            <DisplayDataRow>
              <DisplayDataItem
                sm={isIntegradoOxy ? 6 : 4}
                title="Empenho"
                value={
                  <LinkToEmpenho
                    entidade={diaria.get('entidadeEmpenho')}
                    exercicio={diaria.get('exercicioEmpenho')}
                    empenho={diaria.get('empenho')}
                  />
                }
                id="empenho"
              />
              {!isIntegradoOxy && (
                <DisplayDataItem
                  sm={4}
                  title="Nº Liquidação"
                  value={diaria.get('numeroLiquidacao')}
                  id="liquidacao"
                />
              )}
              <DisplayDataItem
                sm={isIntegradoOxy ? 6 : 4}
                title="Processo"
                value={numeroProcesso && `${numeroProcesso}/${anoProcesso}`}
                id="processo"
              />
            </DisplayDataRow>

            <DisplayDataRow>
              <DisplayDataItem
                sm={3}
                title="Nome Servidor"
                value={diaria.get('nome')}
                id="nome"
              />
              <DisplayDataItem
                sm={4}
                title={diaria.get('labelCargo')}
                value={diaria.get('cargo')}
                id="cargo"
              />
              <DisplayDataItem
                sm={2}
                title="Matrícula"
                value={diaria.get('matricula')}
                id="matricula"
              />
              <DisplayDataItem
                sm={3}
                title="Lotação"
                value={diaria.get('lotacao')}
                id="lotacao"
              />
            </DisplayDataRow>

            <DisplayDataRow>
              <DisplayDataItem
                sm={3}
                title="Situação"
                value={diaria.get('situacaoCancelamento')}
                show={possuiValorAnulado}
                id="situacaoCancelamento"
              />
              <DisplayDataItem
                sm={possuiValorAnulado ? 2 : 3}
                title="Valor Empenhado"
                value={NumberUtils.format(diaria.get('valorEmpenho'))}
                id="valorEmpenho"
              />
              <DisplayDataItem
                sm={possuiValorAnulado ? 2 : 3}
                title="Valor Anulado"
                value={NumberUtils.format(diaria.get('valorAnulado'))}
                id="valorAnulado"
              />
              <DisplayDataItem
                sm={possuiValorAnulado ? 2 : 3}
                title="Qtd. Anulado"
                value={diaria.get('quantidadeAnulacoes')}
                id="qtdAnulado"
              />
              <DisplayDataItem
                sm={possuiValorAnulado ? 2 : 3}
                title="Valor Total Diária"
                value={NumberUtils.format(diaria.get('valorDiarias'))}
                id="valorDiarias"
              />
            </DisplayDataRow>

            <DisplayDataRow>
              <DisplayDataItem
                sm={3}
                title="Valor Hospedagem"
                value={NumberUtils.format(diaria.get('valorHospedagem'))}
                id="valorHospedagem"
              />
              <DisplayDataItem
                sm={3}
                title="Valor Evento"
                value={NumberUtils.format(diaria.get('valorEvento'))}
                id="valorEvento"
              />
              <DisplayDataItem
                sm={3}
                title="Número Ato"
                value={diaria.get('noAto')}
                id="noAto"
              />
              <DisplayDataItem
                sm={3}
                title="Data Ato"
                value={DateUtils.formatDateTimeShort(diaria.get('dataAto'))}
                id="dataAto"
              />
            </DisplayDataRow>

            <DisplayDataRow>
              <DisplayDataItem
                sm={4}
                title="Destino / UF"
                value={diaria.get('destino')}
                id="destino"
              />
            </DisplayDataRow>

            <DisplayDataRow>
              <DisplayDataItem
                sm={3}
                title="Data Saída"
                value={DateUtils.formatDateTimeShort(diaria.get('dataSaida'))}
                id="dataSaida"
              />
              <DisplayDataItem
                sm={3}
                title="Horário Saída"
                value={diaria.get('horaSaida')}
                id="horarioSaida"
              />
              <DisplayDataItem
                sm={3}
                title="Data Retorno"
                value={DateUtils.formatDateTimeShort(diaria.get('dataRetorno'))}
                id="dataRetorno"
              />
              <DisplayDataItem
                sm={3}
                title="Horário Retorno"
                value={diaria.get('horaRetorno')}
                id="horarioRetorno"
              />
            </DisplayDataRow>

            <DisplayDataRow>
              <DisplayDataItem
                sm={3}
                title="Qtde. Diárias"
                value={diaria.get('quantidadeDiarias')}
                id="qtdeDiarias"
              />
              <DisplayDataItem
                sm={3}
                title="Valor da Passagem/Combustível"
                value={NumberUtils.format(diaria.get('valorPassagem'))}
                id="valorPassagem"
              />
              <DisplayDataItem
                sm={3}
                title="Gastos Totais"
                value={NumberUtils.format(diaria.get('gastosTotais'))}
                show={mostrarGastosTotais}
                id="gastosTotais"
              />
              <DisplayDataItem
                sm={3}
                title="Meio de Transporte"
                value={diaria.get('meioTransporte')}
                id="meioTransporte"
              />
            </DisplayDataRow>

            <DisplayDataRow>
              <DisplayDataItem
                sm={12}
                title="Motivo"
                value={diaria.get('motivo')}
                id="motivo"
              />
            </DisplayDataRow>

            <DisplayDataRow>
              <DisplayDataItem
                sm={12}
                title="Tipo Objetivo Diária"
                value={diaria.get('tipoObjetivoDiaria')}
                id="tipoObjetivoDiaria"
              />
            </DisplayDataRow>
          </DisplayData>
        </Panel>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    isIntegradoOxy: state.entidade.getIn([
      'configuracoesGerais',
      'isIntegradoOxy'
    ])
  };
}

export default connect(mapStateToProps, {})(DiariaDetalheInformacao);
