import Widget from 'portaltransparencia-common/components/Widget.react.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils';
import NumberUtils from 'portaltransparencia-common/libs/NumberUtils';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Chart } from 'react-google-charts';
import Component from 'react-pure-render/component';

export class ArrecadacaoDespesa extends Component {
  static propTypes = {
    width: PropTypes.number.isRequired,
    arrecadacaoDespesaMes: PropTypes.object.isRequired,
    calculoArrecadacao: PropTypes.number.isRequired,
    exercicio: PropTypes.any.isRequired,
    tipoEntidadeLegislativa: PropTypes.bool.isRequired,
    paramExibirRepasses: PropTypes.string.isRequired,
    paramExibirEmpenhado: PropTypes.string.isRequired,
    paramExibirLiquidado: PropTypes.string.isRequired,
    paramExibirPago: PropTypes.string.isRequired,
    campoCalculo: PropTypes.string
  };

  getDataChartBarFull = data => {
    const {
      paramExibirRepasses,
      paramExibirEmpenhado,
      paramExibirLiquidado,
      paramExibirPago
    } = this.props;

    const colors = ['#3366CC'];

    const dataChart = [['Mês', 'Arrecadado']];

    if (paramExibirEmpenhado === 'S') {
      dataChart[0].push('Empenhado');
      colors.push('#DC3912');
    }

    if (paramExibirLiquidado === 'S') {
      dataChart[0].push('Liquidado');
      colors.push('#FF9900');
    }

    if (paramExibirPago === 'S') {
      dataChart[0].push('Pago');
      colors.push('#109618');
    }

    if (paramExibirRepasses === 'S') {
      dataChart[0].push('Repasses Recebidos');
      colors.push('#0099C6');

      dataChart[0].push('Repasses Enviados');
      colors.push('#990099');
    }

    return {
      dataChart: dataChart.concat(
        data
          .map(item => {
            const row = [
              DateUtils.getMonthDescription(item.get('mes'), true),
              item.get('valorArrecadado')
            ];

            if (paramExibirEmpenhado === 'S') {
              row.push(item.get('valorEmpenhado'));
            }

            if (paramExibirLiquidado === 'S') {
              row.push(item.get('valorLiquidado'));
            }

            if (paramExibirPago === 'S') {
              row.push(item.get('valorPago'));
            }

            if (paramExibirRepasses === 'S') {
              row.push(item.get('repasseRecebido'));
              row.push(item.get('repasseConcedido'));
            }

            return row;
          })
          .toJS()
      ),
      colors
    };
  };

  getOptions = colors => {
    let { width } = this.props;
    if (width > 400) {
      width = width * 0.8;
    }

    return {
      bar: { groupWidth: '90%' },
      legend: { position: 'top' },
      width: width,
      height: 300,
      colors: colors
    };
  };

  getDescricaoCalculo = () => {
    const { campoCalculo } = this.props;
    return 'Variação da Arrecadação sobre o ' + campoCalculo;
  };

  render() {
    const {
      arrecadacaoDespesaMes,
      calculoArrecadacao,
      exercicio,
      tipoEntidadeLegislativa
    } = this.props;

    const { dataChart, colors } = this.getDataChartBarFull(
      arrecadacaoDespesaMes
    );

    const options = this.getOptions(colors);

    const red = '#c7382d';
    const green = '#659937';
    let color = green;
    if (calculoArrecadacao < 0) {
      color = red;
    }

    return (
      <Widget
        icon="fa fa-bar-chart"
        isChart
        title={`Arrecadação / Despesa de ${exercicio}`}
      >
        <Row>
          <Col xs={12} sm={9} md={9}>
            {arrecadacaoDespesaMes.size > 0 && (
              <Chart
                ref="chart"
                chartType="ColumnChart"
                data={dataChart}
                legend_toggle
                graphID="dashboard-bar-chart"
                options={options}
                width={`${options.width}px`}
                chartLanguage="pt-br"
              />
            )}
          </Col>
          {!tipoEntidadeLegislativa && (
            <Col xs={12} sm={3} md={3}>
              <div style={{ height: '300px', display: 'table' }}>
                <article
                  className="widget-numeric"
                  style={{
                    verticalAlign: 'middle',
                    display: 'table-cell',
                    padding: '0 20px 0 0'
                  }}
                >
                  <b style={{ color: color }} className="widget-numeric-number">
                    {calculoArrecadacao &&
                      NumberUtils.format(calculoArrecadacao) + '%'}
                  </b>
                  <p className="widget-numeric-subtitle">
                    {this.getDescricaoCalculo()}
                  </p>
                </article>
              </div>
            </Col>
          )}
        </Row>
      </Widget>
    );
  }
}

export default ArrecadacaoDespesa;
