import $ from 'jquery';

export const keyMap = {
  home: 'ctrl+alt+home',
  pesquisar: 'ctrl+alt+end',
  acessibilidade_reset: 'alt+1',
  acessibilidade_aumentar: 'alt+2',
  acessibilidade_diminuir: 'alt+3',
  acessibilidade_inverter: 'alt+4'
};

export const handlers = history => ({
  home: () => history.push('/'),
  pesquisar: () => $('#botaoPesquisar').click(),

  acessibilidade_reset: () => $('.accessibility-btn.btn-reset').click(),
  acessibilidade_aumentar: () => $('.accessibility-btn.btn-maximize').click(),
  acessibilidade_diminuir: () => $('.accessibility-btn.btn-minimize').click(),
  acessibilidade_inverter: () => $('.accessibility-btn.btn-invert').click()
});
