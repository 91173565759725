import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import TableHeaderItem from 'portaltransparencia-common/components/TableHeaderItem.react';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

import EstagiarioRowOxy from './EstagiarioRow.react.js';

export default class EstagiarioTable extends Component {
  static propTypes = {
    page: PropTypes.object.isRequired,
    searchWithPage: PropTypes.func.isRequired
  };

  renderEstagiarios = data => {
    const key = `${data.get('entidade')}_${data.get('codigo')}`;

    return (
      <EstagiarioRowOxy
        key={key}
        estagiario={data}
        history={this.props.history}
      />
    );
  };

  render() {
    const { page, searchWithPage } = this.props;
    let estagiarios = page.get('content');

    if (!estagiarios) {
      return null;
    }
    return (
      <div className="panel-table-outer">
        <table className="panel-table striped fancy">
          <TableHeader
            empty={estagiarios.isEmpty()}
            text="Não foi encontrado nenhum estagiário para a pesquisa selecionada."
          >
            <tr>
              <TableHeaderItem
                field="nome"
                label="Nome do(a) Estagiário(a)"
                page={page}
                searchWithPage={searchWithPage}
              />
              <TableHeaderItem
                field="matricula"
                label="Matrícula"
                page={page}
                searchWithPage={searchWithPage}
              />
              <TableHeaderItem
                field="situacao"
                label="Situação"
                page={page}
                searchWithPage={searchWithPage}
              />
              <TableHeaderItem
                field="descricaoCargo"
                label="Cargo / Função"
                page={page}
                searchWithPage={searchWithPage}
              />
              <TableHeaderItem
                field="descricaoClasse"
                label="Classe / Natureza"
                page={page}
                searchWithPage={searchWithPage}
              />
              <TableHeaderItem
                field="descricaoLotacao"
                label="Lotação"
                page={page}
                searchWithPage={searchWithPage}
              />
              <th />
            </tr>
          </TableHeader>

          <tbody>{estagiarios.map(this.renderEstagiarios)}</tbody>
        </table>
      </div>
    );
  }
}
