export const ULTIMAATUALIZACAO = 'ULTIMAATUALIZACAO';
export const ULTIMAATUALIZACAO_START = 'ULTIMAATUALIZACAO_START';
export const ULTIMAATUALIZACAO_SUCCESS = 'ULTIMAATUALIZACAO_SUCCESS';
export const ULTIMAATUALIZACAO_ERROR = 'ULTIMAATUALIZACAO_ERROR';

export const ULTIMAATUALIZACAO_BY_TABLE = 'ULTIMAATUALIZACAO_BY_TABLE';
export const ULTIMAATUALIZACAO_BY_TABLE_START =
  'ULTIMAATUALIZACAO_BY_TABLE_START';
export const ULTIMAATUALIZACAO_BY_TABLE_SUCCESS =
  'ULTIMAATUALIZACAO_BY_TABLE_SUCCESS';
export const ULTIMAATUALIZACAO_BY_TABLE_ERROR =
  'ULTIMAATUALIZACAO_BY_TABLE_ERROR';

export const ULTIMAATUALIZACAO_BY_TABLE_COMPARATIVO =
  'ULTIMAATUALIZACAO_BY_TABLE_COMPARATIVO';
export const ULTIMAATUALIZACAO_BY_TABLE_COMPARATIVO_START =
  'ULTIMAATUALIZACAO_BY_TABLE_COMPARATIVO_START';
export const ULTIMAATUALIZACAO_BY_TABLE_COMPARATIVO_SUCCESS =
  'ULTIMAATUALIZACAO_BY_TABLE_COMPARATIVO_SUCCESS';
export const ULTIMAATUALIZACAO_BY_TABLE_COMPARATIVO_ERROR =
  'ULTIMAATUALIZACAO_BY_TABLE_COMPARATIVO_ERROR';

export const DATA_CONSULTA = 'DATA_CONSULTA';
export const DATA_CONSULTA_START = 'DATA_CONSULTA_START';
export const DATA_CONSULTA_SUCCESS = 'DATA_CONSULTA_SUCCESS';
export const DATA_CONSULTA_ERROR = 'DATA_CONSULTA_ERROR';
