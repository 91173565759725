import ExportButtonGroup from 'portaltransparencia-common/components/ExportButtonGroup.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import TableHeaderItem from 'portaltransparencia-common/components/TableHeaderItem.react';
import configuration from 'portaltransparencia-common/config/Config.js';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { addErrorNotification } from 'portaltransparencia-common/ui/Actions.js';
import { connect } from 'react-redux';
import * as actions from './Actions.js';
import UltimaAtualizacao from '../ultimaatualizacao/Form.react.js';
import Estoque from './Estoque.react.js';

class SearchResult extends Component {
  static propTypes = {
    itensEstoque: PropTypes.object.isRequired,
    filter: PropTypes.string,
    searchWithPage: PropTypes.func.isRequired,
    addErrorNotification: PropTypes.func.isRequired
  };

  onView = data => {
    const centrocusto = data.get('centroCusto');
    const item = data.get('item');

    this.props.history.push(`/almoxarifado/${centrocusto}/${item}`);
  };

  onExporta = (exportType, openTab, windowParams) => {
    const { apiURL } = configuration;
    const { filter, itensEstoque, addErrorNotification } = this.props;

    if (itensEstoque.get('content').size > 0) {
      let filtro = filter + '&exportType=' + exportType;

      window.open(
        `${apiURL}/api/estoque/report?${filtro}`,
        openTab,
        windowParams
      );
    } else {
      addErrorNotification(
        'A consulta não apresentou resultado. Não é possível exportar para o formato selecionado!'
      );
    }
  };

  renderItensEstoque = data => {
    const key = data.get('item');

    return <Estoque key={key} itensEstoque={data} onView={this.onView} />;
  };

  render() {
    const { itensEstoque, searchWithPage } = this.props;

    if (!itensEstoque.has('content')) {
      return null;
    }

    const hasItemsEstoque = itensEstoque.get('content').size > 0;

    return (
      <Panel isForm tableResponsive expansible={false}>
        <Row>
          <Col xs={6}>
            <UltimaAtualizacao modulo="19" />
          </Col>
          <Col xs={6}>
            <ExportButtonGroup onClick={this.onExporta} visible={true} />
          </Col>
        </Row>

        <div className="panel-table-outer">
          <table className="panel-table striped fancy">
            <TableHeader empty={itensEstoque.get('content').isEmpty()}>
              <tr>
                <TableHeaderItem
                  field="item"
                  label="Cód. Item"
                  page={itensEstoque}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="itemDescricao"
                  label="Descrição"
                  page={itensEstoque}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="unidade"
                  label="Unidade"
                  page={itensEstoque}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="centroCusto"
                  label="Centro Custo"
                  page={itensEstoque}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="centroCustoDescricao"
                  label="Descrição"
                  page={itensEstoque}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="saldo"
                  label="Saldo"
                  page={itensEstoque}
                  searchWithPage={searchWithPage}
                  alignment="right"
                />
                <TableHeaderItem
                  field="custoUnitario"
                  label="Custo Médio"
                  page={itensEstoque}
                  searchWithPage={searchWithPage}
                  alignment="right"
                />
                <TableHeaderItem
                  field="valorTotal"
                  label="Valor Total"
                  page={itensEstoque}
                  searchWithPage={searchWithPage}
                  alignment="right"
                />
                <th></th>
              </tr>
            </TableHeader>
            <tbody>
              {itensEstoque
                .get('content')
                .map(this.renderItensEstoque)
                .toArray()}
            </tbody>
          </table>
        </div>
      </Panel>
    );
  }
}

function mapStateToProps(state) {}

const allActions = {
  ...actions,
  addErrorNotification
};

export default connect(mapStateToProps, allActions)(SearchResult);
