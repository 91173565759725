import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';
import ListUtils from 'portaltransparencia-common/libs/ListUtils.js';

import * as types from './Types.js';

const initialState = fromJS({
  publicacoes: [],
  publicacoesFiltradas: [],
  publicacoesGrupo: []
});

function publicacaoReducer(state = initialState, action) {
  switch (action.type) {
    case types.PESQUISA_PUBLICACOES_START:
    case types.PESQUISA_PUBLICACOESGRUPO_START: {
      return startLoad(state);
    }

    case types.PESQUISA_PUBLICACOES_SUCCESS: {
      return endLoad(
        state
          .set('publicacoes', fromJS(action.payload))
          .set('publicacoesFiltradas', fromJS(action.payload))
      );
    }

    case types.PESQUISA_PUBLICACOESGRUPO_SUCCESS: {
      return endLoad(state.set('publicacoesGrupo', fromJS(action.payload)));
    }

    case types.PESQUISA_PUBLICACOES_ERROR:
    case types.PESQUISA_PUBLICACOESGRUPO_ERROR: {
      return endLoad(state);
    }

    case types.FILTRAR_PUBLICACOES: {
      const listaFiltrada = ListUtils.filterTreePublicacoes(
        fromJS(action.payload),
        action.meta,
        ['valor'],
        'list'
      );

      return state.set('publicacoesFiltradas', listaFiltrada);
    }

    case types.RESETAR_PUBLICACOES: {
      const publicacoes = state.get('publicacoes').toJS();
      return state.set('publicacoesFiltradas', fromJS(publicacoes));
    }
  }

  return state;
}

export default publicacaoReducer;
