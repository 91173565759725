import * as types from './Types.js';

export function findReceitaArrecadacao(entidade, exercicio) {
  return ({ fetch }) => {
    return {
      type: types.RECEITA_ARRECADACAO,
      payload: {
        promise: fetch(
          `/api/arrecadacoes?entidade=${entidade}&exercicio=${exercicio}`
        )
      }
    };
  };
}

export function findValorTotalDividasAtivas() {
  return ({ fetch }) => {
    return {
      type: types.TOTAL_DIVIDA_ATIVA,
      payload: {
        promise: fetch('/api/dividas-ativas/total')
      }
    };
  };
}

export function findReceitaArrecadacaoPorBairros(filter) {
  return ({ fetch }) => {
    return {
      type: types.RECEITA_ARRECADACAO_BAIRROS,
      payload: {
        promise: fetch(`/api/arrecadacoes/bairros?${filter}`)
      }
    };
  };
}

export function resetArrecadacoesPorBairro() {
  return () => {
    return {
      type: types.RESETAR_ARRECADACOES
    };
  };
}

export function findBairros(entidade, descricao) {
  const descricaoFilter = descricao ? `&descricao=${descricao}` : '';

  return ({ fetch }) => {
    return {
      type: types.BAIRROS,
      payload: {
        promise: fetch(`/api/bairros?entidade=${entidade}${descricaoFilter}`)
      }
    };
  };
}
