import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react.js';
import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import storageService from 'portaltransparencia-common/libs/StorageService.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

import LinkToEmpenho from '../empenhos/LinkToEmpenho.react';

export class AdiantamentoListagemItem extends Component {
  static propTypes = {
    adiantamento: PropTypes.object.isRequired
  };

  onViewDetalhes = item => {
    const entidade = item.get('entidade');
    const exercicio = item.get('exercicio');
    const permiteExcederPrestacaoContas = item.get(
      'permiteExcederPrestacaoContas'
    );

    const isReembolso = item.get('reembolso');

    if (item.get('unidadeOrcamentaria') == ' ') {
      const prestacaoContas = item.get('prestacaoContas');

      this.props.history.push(
        `adiantamento/detalhes?entidade=${entidade}&exercicio=${exercicio}&prestacaoContas=${prestacaoContas}&permiteExcederPrestacaoContas=${permiteExcederPrestacaoContas}&isReembolso=${isReembolso}`
      );
    } else {
      const empenho = item.get('empenho');
      const noLiquidacao = item.get('noLiquidacao');
      const unidadeOrcamentaria = item.get('unidadeOrcamentaria');
      const matricula = item.get('matricula');

      this.props.history.push(
        `adiantamento/detalhes?entidade=${entidade}&exercicio=${exercicio}&empenho=${empenho}&noLiquidacao=${noLiquidacao}&unidadeOrcamentaria=${unidadeOrcamentaria}&matricula=${matricula}&permiteExcederPrestacaoContas=${permiteExcederPrestacaoContas}&isReembolso=${isReembolso}`
      );
    }
  };

  generateKey = adiantamento => {
    return `${adiantamento.get('entidade')}_
            ${adiantamento.get('exercicio')}_
            ${adiantamento.get('prestacaoContas')}_
            ${adiantamento.get('empenho')}_
            ${adiantamento.get('exercicioEmpenho')}_
            ${adiantamento.get('noLiquidacao')}`;
  };

  render() {
    const { adiantamento } = this.props;
    const { entidade } = storageService.getItem('userInfo');
    return (
      <TableActions
        id="btnAction"
        ref="row"
        key={this.generateKey(adiantamento)}
        data={adiantamento}
        onMore={this.onViewDetalhes}
      >
        {entidade && entidade.id <= 0 && (
          <td id="listEntidade" className="center">
            {adiantamento.get('entidade')}
          </td>
        )}
        <td id="listPrestacaoContas">{adiantamento.get('prestacaoContas')}</td>
        <td id="listNome">{adiantamento.get('nome')} </td>
        <td id="listDataPrestacao">
          {DateUtils.formatDateTimeShort(adiantamento.get('dataPrestacao'))}{' '}
        </td>
        <td>
          <LinkToEmpenho
            id="empenho"
            entidade={adiantamento.get('entidade')}
            exercicio={adiantamento.get('exercicioEmpenho')}
            empenho={adiantamento.get('empenho')}
          />
        </td>
        <td id="listDataEmpenho">
          {DateUtils.formatDateTimeShort(adiantamento.get('dataEmpenho'))}
        </td>
        <td className="right">
          <NumberFormatter
            id="listValorEmpenho"
            value={adiantamento.get('valorEmpenho')}
          />
        </td>
        <td className="right">
          <NumberFormatter
            id="listValorDocumentos"
            value={adiantamento.get('valorDocumentos')}
          />
        </td>
        <td className="right">
          <NumberFormatter
            id="listValorRestituido"
            value={adiantamento.get('valorRestituido')}
          />
        </td>
        {adiantamento.get('permiteExcederPrestacaoContas') == 'S' && (
          <td className="right">
            <NumberFormatter
              id="listValorExcedido"
              value={adiantamento.get('valorExcedido')}
            />
          </td>
        )}
        <td id="listCargo">{adiantamento.get('cargo')}</td>
        <td id="listLotacao">{adiantamento.get('lotacao')}</td>
      </TableActions>
    );
  }
}

export default AdiantamentoListagemItem;
