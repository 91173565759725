import Panel from 'portaltransparencia-common/components/Panel.react.js';
import {
  getAllValuesFromUrlSearchParams,
  getValueFromUrlSearchParams
} from 'portaltransparencia-common/libs/URLUtils';
import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import { downloadArquivo } from '../arquivo/Actions.js';
import Arquivo from '../arquivo/Arquivo.react.js';
import PageHeader from '../components/PageHeader.react.js';
import * as actions from './Actions.js';
import AdiantamentoDetalheInformacao from './AdiantamentoDetalheInformacao.react.js';

export class FormAdiantamentoDetail extends Component {
  static propTypes = {
    adiantamento: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    downloadArquivo: PropTypes.func.isRequired,
    findAdiantamentoDetail: PropTypes.func.isRequired,
    findAdiantamentoPrestacaoContas: PropTypes.func.isRequired,
    arquivos: PropTypes.object.isRequired,
    findArquivos: PropTypes.func.isRequired
  };

  componentDidMount() {
    const {
      location,
      findAdiantamentoDetail,
      findArquivos,
      findAdiantamentoPrestacaoContas
    } = this.props;

    const params = getAllValuesFromUrlSearchParams(location);

    if (params.matricula) {
      findAdiantamentoDetail(params);
    } else {
      findAdiantamentoPrestacaoContas(
        params.entidade,
        params.exercicio,
        params.prestacaoContas
      );
      findArquivos(params.entidade, params.exercicio, params.prestacaoContas);
    }
  }

  render() {
    const { adiantamento, downloadArquivo, arquivos, location } = this.props;

    const matricula = getValueFromUrlSearchParams(location, 'matricula');
    const permiteExcederPrestacaoContas = getValueFromUrlSearchParams(
      location,
      'permiteExcederPrestacaoContas'
    );

    return (
      <section>
        <Helmet
          id="helmet"
          title={'Adiantamento / Reembolso - ' + adiantamento.get('nome')}
        />

        <PageHeader
          location={this.props.location}
          textoDetalhe={`Adiantamento / Reembolso: ${adiantamento.get('nome')}`}
        />

        <AdiantamentoDetalheInformacao
          adiantamento={adiantamento}
          permiteExcederPrestacaoContas={permiteExcederPrestacaoContas}
          downloadArquivo={downloadArquivo}
        />

        <div className="hidden-print">
          <form>
            {!matricula && (
              <Panel isTable tableResponsive expansible={false} title="Anexos">
                <Arquivo arquivos={arquivos} onDownload={downloadArquivo} />
              </Panel>
            )}
          </form>
        </div>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    adiantamento: state.adiantamento.get('adiantamento'),
    arquivos: state.adiantamento.get('arquivos')
  };
}

const allActions = {
  ...actions,
  downloadArquivo
};

export default connect(mapStateToProps, allActions)(FormAdiantamentoDetail);
