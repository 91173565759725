import {
  Button,
  DisplayDataGrid,
  DisplayDataItem,
  FormattedCurrency,
  FormattedDate,
  Panel,
  Row,
  SectionTitle
} from '@elotech/components';
import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router';
import { findOneObra } from '../service/ObraService';

const ObraDetalheInformacao = ({
  entidade,
  exercicioObra,
  idOrdemServico,
  valorEmpenhado,
  isIntegradoOxy,
  idOxy,
  paramExibeNumeroSEI
}) => {
  const history = useHistory();
  const [obra, setObra] = useState({});

  useEffect(() => {
    findOneObra(
      entidade,
      exercicioObra,
      idOrdemServico,
      idOxy
    ).then(({ data }) => setObra(data));
  }, [setObra]);

  const getValorPercentualEmpenhado = obra => {
    let valorIntervencao = obra.valorIntervencao ? obra.valorIntervencao : 0;
    let valorAditivo = obra.valorAditivo ? obra.valorAditivo : 0;
    return ((valorEmpenhado * 100) / (valorAditivo + valorIntervencao)).toFixed(
      2
    );
  };

  return (
    <Panel expansible>
      <SectionTitle marginTop="0">Detalhes da Obra</SectionTitle>
      <DisplayDataGrid>
        <Row>
          <DisplayDataItem sm={3} title="Ordem Serviço / Ano">
            {`${obra.ordemServico}/${obra.exercicio}`}
          </DisplayDataItem>
          <DisplayDataItem sm={3} title="Data Início">
            {<FormattedDate value={obra.data} />}
          </DisplayDataItem>
          <DisplayDataItem sm={3} title="Previsão de Término">
            {obra.previsaoTermino ? (
              <FormattedDate value={obra.previsaoTermino} />
            ) : (
              'Sem registro'
            )}
          </DisplayDataItem>
          <DisplayDataItem sm={3} title="Situação">
            {obra.situacao}
          </DisplayDataItem>
        </Row>
        <Row>
          <DisplayDataItem sm={4} title="Valor Intervenção">
            <FormattedCurrency value={obra.valorIntervencao} />
          </DisplayDataItem>
          <DisplayDataItem sm={4} title="Valor Contrato">
            <div>
              {<FormattedCurrency value={obra.valorContrato} />}
              <Button
                type="button"
                className="btn neutral ml-xs inline icon-right"
                onClick={() => {
                  history.push(
                    `/contratos/detalhes?entidade=${
                      isIntegradoOxy ? obra.entidade : entidade
                    }&exercicio=${obra.exercicioContrato}&contrato=${
                      isIntegradoOxy ? obra.idContrato : obra.contrato
                    }&tipoAto=${obra.tipoAto}`
                  );
                }}
              >
                Ver<em className="fa fa-external-link"></em>
              </Button>
            </div>
          </DisplayDataItem>
          <DisplayDataItem sm={4} title="Valor Aditivo">
            <FormattedCurrency value={obra.valorAditivo} />
          </DisplayDataItem>
        </Row>
        <Row>
          <DisplayDataItem sm={4} title="Medições">
            {`${obra.percentual}%`}
          </DisplayDataItem>
          {!isIntegradoOxy && (
            <DisplayDataItem sm={4} title="Percentual Empenhado">
              {`${getValorPercentualEmpenhado(obra)}%`}
            </DisplayDataItem>
          )}

          <DisplayDataItem sm={4} title="Empresa">
            {obra.empresa}
          </DisplayDataItem>
          <DisplayDataItem sm={4} title="Bem (Imóvel)">
            {obra.descricaoBem}
          </DisplayDataItem>
        </Row>
        <Row>
          <DisplayDataItem sm={12} title="Objeto">
            {obra.objeto}
          </DisplayDataItem>
        </Row>
        <Row>
          <DisplayDataItem sm={12} title="População atendida">
            {obra.populacaoAtendida}
          </DisplayDataItem>
        </Row>
        {paramExibeNumeroSEI === 'S' && (
          <Row>
            <DisplayDataItem sm={12} title="Nº SEI">
              {obra.numeroSei}
            </DisplayDataItem>
          </Row>
        )}
        {obra.matricula && (
          <Row>
            <DisplayDataItem sm={12} title="Fiscal técnico">
              {obra.nomeFiscal}, Matrícula: {obra.matricula}
            </DisplayDataItem>
          </Row>
        )}
      </DisplayDataGrid>
    </Panel>
  );
};

export default ObraDetalheInformacao;
