import * as types from './Types.js';

export function findReceitas(filter) {
  return ({ fetch }) => ({
    type: types.RECEITAS,
    payload: {
      promise: fetch(`/api/receitas/detalhada?${filter}`)
    }
  });
}

export function expand(receita, nivel) {
  return {
    type: types.EXPAND_RECEITA,
    payload: {
      receita,
      nivel
    }
  };
}

export function collapse(receita, nivel) {
  return {
    type: types.COLLAPSE_RECEITA,
    payload: {
      receita,
      nivel
    }
  };
}
