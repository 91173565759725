import DisplayData from 'portaltransparencia-common/components/displaydata/DisplayData.react.js';
import DisplayDataItem from 'portaltransparencia-common/components/displaydata/DisplayDataItem.react.js';
import DisplayDataRow from 'portaltransparencia-common/components/displaydata/DisplayDataRow.react.js';
import NumberUtils from 'portaltransparencia-common/libs/NumberUtils.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
export default class EmpenhoDetalheValores extends Component {
  static propTypes = {
    empenho: PropTypes.object.isRequired,
    paramDespesasPagasValor: PropTypes.string
  };

  render() {
    const { empenho } = this.props;
    let valorPago = 0;
    if (
      this.props.paramDespesasPagasValor &&
      this.props.paramDespesasPagasValor == 'S'
    ) {
      valorPago = empenho.get('valorPago') + empenho.get('valorRetido');
    } else {
      valorPago = empenho.get('valorPago');
    }

    return (
      <DisplayData border>
        <DisplayDataRow>
          <DisplayDataItem
            sm={4}
            title="Empenhado"
            value={NumberUtils.format(empenho.get('valor'))}
          />
          <DisplayDataItem
            sm={4}
            title="Anulado"
            value={NumberUtils.format(empenho.get('valorAnulado'))}
          />
          <DisplayDataItem
            sm={4}
            title="Liquidado"
            value={NumberUtils.format(empenho.get('valorLiquidado'))}
          />
        </DisplayDataRow>

        <DisplayDataRow>
          <DisplayDataItem
            sm={4}
            title="Retido"
            value={NumberUtils.format(empenho.get('valorRetido'))}
          />
          <DisplayDataItem
            id="valorPago"
            sm={4}
            title="Valor Pago"
            value={valorPago && NumberUtils.format(valorPago)}
          />
          <DisplayDataItem
            sm={4}
            title="A Pagar"
            value={NumberUtils.format(empenho.get('valorAPagar'))}
          />
        </DisplayDataRow>
      </DisplayData>
    );
  }
}
