import Keycloak from 'keycloak-js';
import { KeycloakConfig } from './KeycloakConfig';
import cookie from 'react-cookie';

class KeycloakService {
  constructor() {
    this.DATA_EXPIRACAO_TOKEN = 7;
  }

  isLogged() {
    return this.keycloak?.authenticated || false;
  }

  getUser() {
    return new Promise(resolve => {
      return this.keycloak.loadUserInfo().success(user => resolve(user));
    });
  }

  redirectToUserProfile() {
    return this.keycloak.accountManagement();
  }

  login(onLogin) {
    return new Promise((resolve, reject) => {
      this.keycloak
        .init({ onLoad: 'login-required' })
        .success(authenticated => {
          if (authenticated) {
            resolve(authenticated);
            onLogin && onLogin(authenticated);
          } else {
            reject();
          }
        });
    });
  }

  loadLoggedUser() {
    return new Promise((resolve, reject) => {
      this.keycloak
        .init({ onLoad: 'login-required' })
        .success(authenticated => {
          if (authenticated) {
            resolve(authenticated);
          } else {
            reject();
          }
        });
    });
  }

  checkSSO({ token, refreshToken }) {
    return new Promise(resolve => {
      this.keycloak
        .init({
          onLoad: 'check-sso',
          token,
          refreshToken
        })
        .success(authenticated => resolve(authenticated));
    });
  }

  logout() {
    this.keycloak.logout();
  }

  getToken() {
    return this.keycloak?.token;
  }

  getRefreshToken() {
    return this.keycloak?.refreshToken;
  }

  refreshToken() {
    return new Promise((resolve, reject) => {
      this.keycloak
        .updateToken()
        .success(refreshed => {
          resolve(refreshed);
        })
        .error(err => {
          reject(err);
        });
    });
  }

  getTokenParsed() {
    return this.keycloak.tokenParsed;
  }

  hasRole(role) {
    return this.keycloak.hasResourceRole(role, 'portal-transparencia-backend');
  }

  setKeycloakUser(usuarioInfo) {
    cookie.save(
      'keycloakUser',
      JSON.stringify(usuarioInfo),
      this.DATA_EXPIRACAO_TOKEN,
      '/',
      window.location.hostname
    );
  }

  getKeycloakUser() {
    return cookie.load('keycloakUser');
  }

  setusuarioLogado() {
    cookie.save('usuarioLogado', true);
  }

  getUsuarioLogado() {
    return cookie.load('usuarioLogado');
  }

  setConfig(onPremise) {
    this.keycloak = new Keycloak(KeycloakConfig.config(onPremise));
  }
}
const keycloakService = new KeycloakService();

export default keycloakService;
