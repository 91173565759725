import { Map } from 'immutable';
import DatePicker from 'portaltransparencia-common/components/DatePicker.react.js';
import Input from 'portaltransparencia-common/components/EloInput.react.js';
import ExportButtonGroup from 'portaltransparencia-common/components/ExportButtonGroup.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import SearchPagination from 'portaltransparencia-common/components/SearchPagination.react.js';
import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import TableHeaderItem from 'portaltransparencia-common/components/TableHeaderItem.react';
import configuration from 'portaltransparencia-common/config/Config.js';
import {
  onChange,
  resetCrud
} from 'portaltransparencia-common/crud/Actions.js';
import {
  addFilterUrl,
  addPagination
} from 'portaltransparencia-common/libs/AddFilter.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import {
  PARAM_INTEGRADO_OXY_FOLHA_PAGAMENTO,
  PARAM_LEISEATOS_AISE,
  PARAM_LINK_LEIS_ATOS,
  PARAM_USA_LEGISLATIVO,
  PARAM_LEI_ATOS_CONSULTA
} from 'portaltransparencia-common/parametros/ParametrosPortalTransparencia';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import FilterButtons from '../components/FilterButtons.react.js';
import PageHeader from '../components/PageHeader.react.js';
import NotasExplicativas from '../notaexplicativa/Form.react';
import ParametroService from '../parametros/ParametroService.js';
import UltimaAtualizacaoByTable from '../ultimaatualizacao/UltimaAtualizacaoByTable.react.js';
import UltimaAtualizacaoDataConsulta from '../ultimaatualizacao/UltimaAtualizacaoDataConsulta.react.js';
import * as actions from './Actions.js';
import LeisAtosTerceiros from './LeisAtosTerceiros.react.js';
import { addErrorNotification } from 'portaltransparencia-common/ui/Actions.js';

export class LeisEAtos extends Component {
  static propTypes = {
    params: PropTypes.object,
    page: PropTypes.object.isRequired,
    searchLeisAtos: PropTypes.func.isRequired,
    paramLeisAtos: PropTypes.string,
    paramIntegradoAise: PropTypes.string,
    paramIntegradoLegislacao: PropTypes.string,
    paramIntegradoOxyFolha: PropTypes.string,
    location: PropTypes.object.isRequired,
    filtros: PropTypes.object,
    onChange: PropTypes.func,
    resetCrud: PropTypes.func,
    LimpaPesquisa: PropTypes.func.isRequired,
    valorParametro: PropTypes.string,
    loadLeisAtosNatureza: PropTypes.func,
    naturezaLei: PropTypes.object,
    loadTiposDocumentoLegal: PropTypes.func,
    tiposDocumentoLegal: PropTypes.object,
    loadTipoEscopo: PropTypes.func,
    uf: PropTypes.string,
    tiposEscopo: PropTypes.object,
    addErrorNotification: PropTypes.func.isRequired,
    paramConsultaAoClicar: PropTypes.string
  };

  componentDidMount() {
    const {
      paramLeisAtos,
      page,
      loadLeisAtosNatureza,
      loadTiposDocumentoLegal,
      loadTipoEscopo,
      paramConsultaAoClicar
    } = this.props;

    if (!paramLeisAtos) {
      const filter = this.getFiltroBase(page);

      this.onResetFiltro(page);
      loadLeisAtosNatureza(filter);
      loadTiposDocumentoLegal(filter);
      loadTipoEscopo(filter);

      if (paramConsultaAoClicar === 'N') {
        this.onSearchLeisatos();
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { page } = this.props;

    if (this.getSubAba(this.props) !== this.getSubAba(nextProps)) {
      this.onResetFiltro(page);
    }
  }

  getSubAba = props => {
    const {
      match: {
        params: { tipoLeisEAtos }
      }
    } = props;

    return tipoLeisEAtos;
  };

  onChangeFiltroAno = event => {
    const { onChange } = this.props;

    if (this.refs.ano.getValue() !== '') {
      this.refs.dataInicial.refs.datepicker.clear();
      this.refs.dataFinal.refs.datepicker.clear();
      onChange('filtroLeisAtos', {
        name: 'dataInicial',
        value: ''
      });
      onChange('filtroLeisAtos', {
        name: 'dataFinal',
        value: ''
      });
    }
    this.onChangeFiltro(event);
  };

  onChangeFiltroData = event => {
    if (this.refs.ano.getValue() !== '') {
      this.refs.ano.clear();
      onChange('filtroLeisAtos', {
        name: 'ano',
        value: ''
      });
    }

    onChange('filtroLeisAtos', {
      name: event.name,
      value: event.value
    });
  };

  onChangeFiltro = event => {
    const { onChange } = this.props;

    onChange('filtroLeisAtos', event);
  };

  loadInitialFiltros = () => {
    const { onChange } = this.props;
    const { exercicio } = StorageService.getItem('userInfo');

    onChange('filtroLeisAtos', {
      name: 'dataInicial',
      value: `${exercicio.id}-01-01`
    });

    onChange('filtroLeisAtos', {
      name: 'dataFinal',
      value: `${exercicio.id}-12-31`
    });
  };

  clearLeisAtos = () => {
    const { LimpaPesquisa } = this.props;

    LimpaPesquisa();
  };

  onResetFiltro = () => {
    const { resetCrud } = this.props;
    resetCrud('filtroLeisAtos');

    this.clearLeisAtos();
  };

  onSearchLeisatos = () => {
    const { page } = this.props;
    const initialPage = page.set('number', 0);

    this.clearLeisAtos();

    this.searchWithPage(initialPage);
  };

  searchWithPage = page => {
    const { searchLeisAtos } = this.props;

    const filter = this.montaFiltro(page);

    searchLeisAtos(filter);

    this.refs.panelFiltroLeis && this.refs.panelFiltroLeis.collapse();
  };

  addFilter = (actualFilter, filterAnd) => {
    let filterOperator = '';
    if (actualFilter !== '') {
      filterOperator = '&';
    }
    return actualFilter + filterOperator + filterAnd;
  };

  getFiltroBase = () => {
    const { entidade, exercicio } = StorageService.getItem('userInfo');
    const {
      match: {
        params: { tipoLeisEAtos }
      }
    } = this.props;

    return `entidade=${entidade.id}&exercicio=${exercicio.id}&subAbaLeiAtos=${tipoLeisEAtos}`;
  };

  montaFiltro = page => {
    const { filtros } = this.props;

    let filter = this.getFiltroBase();

    if (this.refs.ano && this.refs.ano.getValue()) {
      filter = this.addFilter(filter, 'ano=' + this.refs.ano.getValue());
    }
    if (this.refs.natureza && this.refs.natureza.getValue()) {
      filter = addFilterUrl(
        filter,
        'natureza=' + this.refs.natureza.getValue()
      );
    }
    if (this.refs.numero && this.refs.numero.getValue()) {
      filter = this.addFilter(filter, 'numero=' + this.refs.numero.getValue());
    }

    if (filtros.get('descricaoSumula')) {
      filter = addFilterUrl(
        filter,
        `descricaoSumula=${filtros.get('descricaoSumula')}`
      );
    }
    if (
      this.refs.dataInicial &&
      this.refs.dataInicial.getValue().length === 10
    ) {
      filter = addFilterUrl(
        filter,
        `dataInicial=${this.refs.dataInicial.getValue()}`
      );
    }

    if (this.refs.dataFinal && this.refs.dataFinal.getValue().length === 10) {
      filter = addFilterUrl(
        filter,
        `dataFinal=${this.refs.dataFinal.getValue()}`
      );
    }

    if (filtros.get('tipoDocumentoLegal')) {
      filter = this.addFilter(
        filter,
        `tipoDocumentoLegal=${filtros.get('tipoDocumentoLegal')}`
      );
    }

    if (filtros.get('tipoEscopo')) {
      filter = this.addFilter(
        filter,
        `tipoEscopo=${filtros.get('tipoEscopo')}`
      );
    }

    if (this.isTelaLeisEAtosCovid()) {
      filter = this.addFilter(filter, 'isCovid=S');
    }

    return addPagination(page, filter);
  };

  onExporta = (exportType, openTab, windowParams) => {
    const { apiURL } = configuration;
    const { page, addErrorNotification } = this.props;

    if (page.get('content').size > 0) {
      let filtro =
        this.montaFiltro(page, this.props) + '&exportType=' + exportType;

      window.open(
        `${apiURL}/api/leisatos/report?${filtro}`,
        openTab,
        windowParams
      );
    } else {
      addErrorNotification(
        'A consulta não apresentou resultado. Não é possível exportar para o formato selecionado!'
      );
    }
  };

  passaTipoNaUrl = tipo => {
    return tipo ? `tipo=${tipo}&` : '';
  };

  onViewDetalhesLei = lei => {
    const entidade = lei.get('entidade');
    const exercicio = lei.get('exercicio');
    const tipo = lei.get('tipo');
    const id = lei.get('id');
    const sistema = lei.get('sistema');
    const natureza = lei.get('natureza');
    const {
      match: {
        params: { tipoLeisEAtos }
      }
    } = this.props;

    const url =
      `/leiseatos-${tipoLeisEAtos}/detalhes?` +
      `entidade=${entidade}&` +
      `ano=${exercicio}&` +
      this.passaTipoNaUrl(tipo) +
      `id=${id}&` +
      `sistema=${sistema}&` +
      `natureza=${natureza}&` +
      `tipoLeisAtos=${tipoLeisEAtos}`;
    this.props.history.push(url);
  };

  isTelaLeisEAtosCovid = () => {
    const { location } = this.props;
    return location.pathname && location.pathname.includes('covid');
  };

  getTitle = () => {
    const { paramIntegradoOxyFolha } = this.props;

    if (paramIntegradoOxyFolha === 'S') {
      return 'Consultar em Leis e Atos de Pessoal';
    }
    return 'Consultar em Leis e Atos';
  };

  renderLeisAtosNatureza = data => {
    return (
      <option key={data.get('id')} value={data.get('descricao')}>
        {data.get('descricao')}
      </option>
    );
  };

  renderTiposDocumentoLegal = data => {
    return (
      <option key={data.get('codigo')} value={data.get('codigo')}>
        {data.get('descricao')}
      </option>
    );
  };

  renderTiposEscopo = data => {
    return (
      <option key={data.get('codigo')} value={data.get('codigo')}>
        {data.get('descricao')}
      </option>
    );
  };

  renderLeisatos = data => {
    const { paramIntegradoLegislacao } = this.props;

    const key =
      data.get('id') +
      '_' +
      data.get('sistema') +
      '_' +
      data.get('numero') +
      '_' +
      data.get('exercicio');

    return (
      <TableActions
        ref="row"
        key={key}
        data={data}
        onMore={this.onViewDetalhesLei}
      >
        <td>{data.get('natureza')}</td>
        <td>{data.get('numero')}</td>
        <td>{data.get('exercicio')}</td>
        {(!paramIntegradoLegislacao || paramIntegradoLegislacao === 'N') && (
          <td>{data.get('descricao')}</td>
        )}
        <td>{DateUtils.formatDateTimeShort(data.get('data'))}</td>
        <td>{data.get('sumula')}</td>
        <td>{data.get('veiculoPublicacao')}</td>
        <td>{DateUtils.formatDateTimeShort(data.get('dataPublicacao'))}</td>
        {(!paramIntegradoLegislacao || paramIntegradoLegislacao === 'N') && (
          <td>{data.get('tipoDocumentoLegal')}</td>
        )}
      </TableActions>
    );
  };

  renderDataUltimaAtualizacao = paramIntegradoAise => {
    if (paramIntegradoAise === 'S') {
      return (
        <UltimaAtualizacaoByTable
          moduloComparativo="1"
          tableComparativo="CGATO"
          columnComparativo="DATAATO"
          comparativo="S"
          table="leis_atos"
        />
      );
    } else {
      return <UltimaAtualizacaoDataConsulta />;
    }
  };

  render() {
    const {
      page,
      paramLeisAtos,
      filtros,
      naturezaLei,
      tiposDocumentoLegal,
      paramIntegradoAise,
      tiposEscopo,
      paramIntegradoOxyFolha,
      paramIntegradoLegislacao,
      uf
    } = this.props;

    if (uf && uf != 'RO') {
      this.loadInitialFiltros();
    }

    if (paramLeisAtos) {
      return (
        <LeisAtosTerceiros
          valor={paramLeisAtos}
          location={this.props.location}
        />
      );
    }

    return (
      <section>
        <PageHeader location={this.props.location} />

        <NotasExplicativas pathname={this.props.location.pathname} />

        <Panel title={this.getTitle()} isForm mobileClose ref="panelFiltroLeis">
          <form>
            <Row>
              <Col xs={6} sm={6} md={2}>
                <Input
                  name="numero"
                  ref="numero"
                  placeholder="Número"
                  label="Número"
                  type="number"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('numero')}
                  id="numero"
                />
              </Col>
              <Col xs={6} sm={6} md={2}>
                <Input
                  name="ano"
                  ref="ano"
                  placeholder="Ano"
                  label="Ano"
                  type="number"
                  onChange={this.onChangeFiltroAno}
                  value={filtros.get('ano')}
                  id="ano"
                />
              </Col>
              {(!paramIntegradoLegislacao ||
                paramIntegradoLegislacao === 'N') && (
                <Col xs={12} sm={6} md={8}>
                  <Input
                    name="descricaoSumula"
                    ref="descricaoSumula"
                    placeholder="Descrição/Súmula"
                    label="Descrição/Súmula"
                    type="text"
                    onChange={this.onChangeFiltro}
                    value={filtros.get('descricaoSumula')}
                    id="descricaoSumula"
                  />
                </Col>
              )}
            </Row>
            <Row>
              <Col xs={12} sm={6} md={2}>
                <DatePicker
                  name="dataInicial"
                  ref="dataInicial"
                  label="Data Inicial"
                  onChange={this.onChangeFiltroData}
                  value={filtros.get('dataInicial')}
                  id="dataInicial"
                />
              </Col>
              <Col xs={12} sm={6} md={2}>
                <DatePicker
                  name="dataFinal"
                  ref="dataFinal"
                  label="Data Final"
                  onChange={this.onChangeFiltroData}
                  value={filtros.get('dataFinal')}
                  id="dataFinal"
                />
              </Col>
              {paramIntegradoOxyFolha === 'S' ? null : (
                <>
                  <Col xs={12} sm={6} md={4}>
                    <Input
                      name="tipoEscopo"
                      ref="tipoEscopo"
                      placeholder="Escopo"
                      label="Escopo"
                      type="select"
                      onChange={this.onChangeFiltro}
                      value={filtros.get('tipoEscopo')}
                      id="tipoEscopo"
                    >
                      <option value="">Selecione</option>
                      {tiposEscopo.map(this.renderTiposEscopo)}
                    </Input>
                  </Col>
                </>
              )}
              <Col xs={12} sm={6} md={4}>
                <Input
                  name="natureza"
                  ref="natureza"
                  placeholder="Natureza"
                  label="Natureza"
                  type="select"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('natureza')}
                  id="natureza"
                >
                  <option value="">Selecione</option>
                  {naturezaLei.map(this.renderLeisAtosNatureza)}
                </Input>
              </Col>
              {paramIntegradoOxyFolha === 'S'
                ? null
                : (!paramIntegradoLegislacao ||
                    paramIntegradoLegislacao === 'N') && (
                    <Col xs={12} sm={6} md={4}>
                      <Input
                        name="tipoDocumentoLegal"
                        ref="tipoDocumentoLegal"
                        placeholder="Tipo Documento Legal"
                        label="Tipo Documento Legal"
                        type="select"
                        onChange={this.onChangeFiltro}
                        value={filtros.get('tipoDocumentoLegal')}
                        id="tipoDocumentoLegal"
                      >
                        <option value="">Selecione</option>
                        {tiposDocumentoLegal.map(
                          this.renderTiposDocumentoLegal
                        )}
                      </Input>
                    </Col>
                  )}
            </Row>
            <Row>
              <Col xs={12} sm={4} md={3}>
                <FilterButtons
                  onConsulta={this.onSearchLeisatos}
                  onReset={this.onResetFiltro}
                />
              </Col>
            </Row>
          </form>
        </Panel>

        {page.has('content') && (
          <Panel isForm expansible={false} tableResponsive>
            {
              <Row>
                <Col xs={6}>
                  {this.renderDataUltimaAtualizacao(paramIntegradoAise)}
                </Col>
                <Col xs={6}>
                  <ExportButtonGroup onClick={this.onExporta} visible={true} />
                </Col>
              </Row>
            }

            <div className="panel-table-outer">
              <table className="panel-table striped fancy">
                <TableHeader empty={page.get('content').isEmpty()}>
                  <tr>
                    <TableHeaderItem
                      field="natureza"
                      label="Natureza"
                      page={page}
                      searchWithPage={this.searchWithPage}
                    />
                    <TableHeaderItem
                      field="numero"
                      label="Número"
                      page={page}
                      searchWithPage={this.searchWithPage}
                    />
                    <TableHeaderItem
                      field="exercicio"
                      label="Ano"
                      page={page}
                      searchWithPage={this.searchWithPage}
                    />
                    {(!paramIntegradoLegislacao ||
                      paramIntegradoLegislacao === 'N') && (
                      <TableHeaderItem
                        field="descricao"
                        label="Descrição"
                        page={page}
                        searchWithPage={this.searchWithPage}
                      />
                    )}
                    <TableHeaderItem
                      field="data"
                      label="Data"
                      page={page}
                      searchWithPage={this.searchWithPage}
                    />
                    <TableHeaderItem
                      field="sumula"
                      label="Súmula"
                      page={page}
                      searchWithPage={this.searchWithPage}
                    />
                    <TableHeaderItem
                      field="veiculoPublicacao"
                      label="Veículo Publicação"
                      page={page}
                      searchWithPage={this.searchWithPage}
                    />
                    <TableHeaderItem
                      field="dataPublicacao"
                      label="Data Publicação"
                      page={page}
                      searchWithPage={this.searchWithPage}
                    />
                    {(!paramIntegradoLegislacao ||
                      paramIntegradoLegislacao === 'N') && (
                      <TableHeaderItem
                        field="tipoDocumentoLegal"
                        label="Tipo Documento Legal"
                        page={page}
                        searchWithPage={this.searchWithPage}
                      />
                    )}
                    <th />
                  </tr>
                </TableHeader>
                <tbody>{page.get('content').map(this.renderLeisatos)}</tbody>
              </table>
            </div>
          </Panel>
        )}
        <SearchPagination page={page} searchWithPage={this.searchWithPage} />
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    page: state.leisatos.get('leisatos'),
    naturezaLei: state.leisatos.get('naturezaLei'),
    tiposDocumentoLegal: state.leisatos.get('tiposDocumentoLegal'),
    tiposEscopo: state.leisatos.get('tiposEscopo'),
    paramLeisAtos: ParametroService.getValorParametro(
      state,
      PARAM_LINK_LEIS_ATOS
    ),
    paramIntegradoAise: ParametroService.getValorParametro(
      state,
      PARAM_LEISEATOS_AISE
    ),
    filtros: state.crud.getIn(['filtroLeisAtos', 'currentRecord'], Map({})),
    paramIntegradoOxyFolha: ParametroService.getValorParametro(
      state,
      PARAM_INTEGRADO_OXY_FOLHA_PAGAMENTO
    ),
    paramIntegradoLegislacao: ParametroService.getValorParametro(
      state,
      PARAM_USA_LEGISLATIVO
    ),
    paramConsultaAoClicar: ParametroService.getValorParametro(
      state,
      PARAM_LEI_ATOS_CONSULTA
    ),
    uf: state.entidade.getIn(['entidadeSelecionada', 'uf'])
  };
}

const allActions = {
  ...actions,
  onChange,
  resetCrud,
  addErrorNotification
};

export default connect(mapStateToProps, allActions)(LeisEAtos);
