import { Map } from 'immutable';
import ExportButtonGroup from 'portaltransparencia-common/components/ExportButtonGroup.react.js';
import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import SearchPagination from 'portaltransparencia-common/components/SearchPagination.react.js';
import SumListField from 'portaltransparencia-common/components/SumListField.react.js';
import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import TableHeaderItem from 'portaltransparencia-common/components/TableHeaderItem.react';
import configuration from 'portaltransparencia-common/config/Config.js';
import {
  onChange,
  resetCrud
} from 'portaltransparencia-common/crud/Actions.js';
import {
  addFilterUrl,
  addPagination
} from 'portaltransparencia-common/libs/AddFilter';
import DateUtils from 'portaltransparencia-common/libs/DateUtils';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import PageHeader from '../components/PageHeader.react.js';
import NotasExplicativas from '../notaexplicativa/Form.react.js';
import UltimaAtualizacao from '../ultimaatualizacao/Form.react.js';
import * as actions from './Actions.js';
import FormCovidFiltro from './FormCovidFiltro.react.js';
import { addErrorNotification } from 'portaltransparencia-common/ui/Actions.js';

export class FormReceitaCovid extends Component {
  static propTypes = {
    covidpage: PropTypes.object.isRequired,
    findReceitasCovid: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    addErrorNotification: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.onResetCovid();
  }

  onResetCovid = () => {
    const { exercicio } = StorageService.getItem('userInfo');
    const { findReceitasCovid, onChange, covidpage } = this.props;

    onChange('filtroCovid', {
      name: 'dataInicial',
      value: `${exercicio.id}-01-01`
    });
    onChange('filtroCovid', {
      name: 'dataFinal',
      value: `${exercicio.id}-12-31`
    });

    let filtroDateIni = `&dataInicial=${exercicio.id}-01-01&dataFinal=${exercicio.id}-12-31`;

    findReceitasCovid(this.montaFiltro(covidpage, filtroDateIni));
  };

  isTelaReceitasCovid = () => {
    const { location } = this.props;
    return location.pathname && location.pathname.includes('receitas-covid');
  };

  onConsultaWithPage = page => {
    const { findReceitasCovid } = this.props;
    let filter = this.montaFiltro(page);
    findReceitasCovid(filter);
  };

  filtroInicial = () => {
    const userInfo = StorageService.getItem('userInfo');
    const { entidade, exercicio } = userInfo;
    let filter = 'entidade=' + entidade.id + '&exercicio=' + exercicio.id;
    filter += this.isTelaReceitasCovid() ? "&covid='S'" : '';

    return filter;
  };

  montaFiltro = (page, filtroDateIni = '') => {
    const { exercicio } = StorageService.getItem('userInfo');
    let filter = this.filtroInicial();

    if (filtroDateIni) {
      filter += filtroDateIni;
    }

    const { filtros } = this.props;

    if (filtros.get('receita')) {
      filter = addFilterUrl(
        filter,
        `&receitaLike=${encodeURIComponent(filtros.get('receita'))}`
      );
    }

    if (filtros.get('descricaoReceita')) {
      filter = addFilterUrl(
        filter,
        `&descricaoReceita=${encodeURIComponent(
          filtros.get('descricaoReceita')
        )}`
      );
    }

    if (filtros.get('fonteRecursoRateio')) {
      filter = addFilterUrl(
        filter,
        `&fonteRecursoRateio=${encodeURIComponent(
          filtros.get('fonteRecursoRateio')
        )}`
      );
    }

    if (filtros.get('descricaoFonteRecurso')) {
      filter = addFilterUrl(
        filter,
        `&descricaoFonteRecurso=${encodeURIComponent(
          filtros.get('descricaoFonteRecurso')
        )}`
      );
    }

    if (
      filtros.get('dataInicial') &&
      filtros.get('dataInicial').length === 10
    ) {
      filter = addFilterUrl(
        filter,
        `&dataInicial=${encodeURIComponent(
          DateUtils.transform(
            filtros.get('dataInicial'),
            'yy-mm-dd',
            'yy-mm-dd'
          )
        )}`
      );
    } else {
      filter = addFilterUrl(
        filter,
        `&dataInicial=${encodeURIComponent(
          DateUtils.transform(`${exercicio.id}-01-01`, 'yy-mm-dd', 'yy-mm-dd')
        )}`
      );
    }

    if (filtros.get('dataFinal') && filtros.get('dataFinal').length === 10) {
      filter = addFilterUrl(
        filter,
        `&dataFinal=${encodeURIComponent(
          DateUtils.transform(filtros.get('dataFinal'), 'yy-mm-dd', 'yy-mm-dd')
        )}`
      );
    } else {
      filter = addFilterUrl(
        filter,
        `&dataFinal=${encodeURIComponent(
          DateUtils.transform(`${exercicio.id}-12-31`, 'yy-mm-dd', 'yy-mm-dd')
        )}`
      );
    }

    return addPagination(page, filter);
  };

  generateKey = data => {
    return `${data.get('receita')}_
            ${data.get('descricao')}_
            ${data.get('fonteRecursoRateio')}_
            ${data.get('descricaoFonte')}`;
  };

  onViewMore = data => {
    const { exercicio } = StorageService.getItem('userInfo');
    const receita = data.get('receita');
    const fonteRecursoRateio = data.get('fonteRecursoRateio');

    let filtro = '';
    if (fonteRecursoRateio) {
      filtro = `${filtro}&fonteRecursoRateio=${fonteRecursoRateio}`;
    }

    this.props.history.push(
      `/receita/dias?entidade=${data.get('entidade')}&exercicio=${
        exercicio.id
      }&receita=${receita}${filtro}`
    );
  };

  renderCovid = data => {
    const { entidade } = StorageService.getItem('userInfo');
    return (
      <TableActions
        ref="row"
        key={this.generateKey(data)}
        data={data}
        onMore={this.onViewMore}
      >
        {entidade && entidade.id <= 0 && (
          <td className="center">{data.get('entidade')}</td>
        )}
        <td>{data.get('receita')}</td>
        <td>{data.get('descricao')}</td>
        <td>{data.get('fonteRecursoRateio')}</td>
        <td>{data.get('descricaoFonte')}</td>
        <td className="right">
          <NumberFormatter value={data.get('valorOrcado')} />
        </td>
        <td className="right">
          <NumberFormatter value={data.get('valorAtualizado')} />
        </td>
        <td className="right">
          <NumberFormatter value={data.get('valorArrecadado')} />
        </td>
      </TableActions>
    );
  };

  onExporta = (exportType, openTab, windowParams) => {
    const { apiURL } = configuration;
    const { sortedUrl, covidpage, addErrorNotification } = this.props;

    if (covidpage.get('content').size > 0) {
      let filtro = this.montaFiltro(covidpage) + '&exportType=' + exportType;

      if (sortedUrl) {
        filtro = `${filtro}&sort=${sortedUrl}`;
      }

      window.open(
        `${apiURL}/api/receitas/covid/report?${filtro}`,
        openTab,
        windowParams
      );
    } else {
      addErrorNotification(
        'A consulta não apresentou resultado. Não é possível exportar para o formato selecionado!'
      );
    }
  };

  render() {
    const {
      covidpage,
      location,
      filtros,
      onChange,
      findReceitasCovid,
      resetCrud
    } = this.props;
    const { entidade } = StorageService.getItem('userInfo');

    if (!covidpage || (covidpage && !covidpage.get('content'))) {
      return null;
    }

    const covid = covidpage.get('content');

    const filtroCovid = 'filtroCovid';
    return (
      <section>
        <PageHeader location={location} />

        <NotasExplicativas pathname={this.props.location.pathname} />

        <FormCovidFiltro
          filtros={filtros}
          montaFiltro={this.montaFiltro}
          onChange={onChange}
          page={covid}
          findReceitasCovid={findReceitasCovid}
          onConsultaWithPage={this.onConsultaWithPage}
          resetCrud={resetCrud}
          filtroCovid={filtroCovid}
          filtroInicial={this.filtroInicial}
          onResetCovid={this.onResetCovid}
        />

        {covid && (
          <Panel isForm tableResponsive expansible={false}>
            <Row>
              <Col xs={6}>
                <UltimaAtualizacao modulo="3" />
              </Col>
              <Col xs={6} />
              <Col xs={6}>
                <ExportButtonGroup onClick={this.onExporta} visible={true} />
              </Col>
            </Row>

            <div className="panel-table-outer">
              <table className="panel-table striped fancy">
                <TableHeader empty={covid.isEmpty()} text="Sem registros">
                  <tr>
                    {entidade && entidade.id <= 0 && (
                      <TableHeaderItem
                        field="entidade"
                        label="Entidade"
                        page={covidpage}
                        searchWithPage={this.onConsultaWithPage}
                      />
                    )}
                    <TableHeaderItem
                      field="receita"
                      label="Receita"
                      page={covidpage}
                      searchWithPage={this.onConsultaWithPage}
                    />
                    <TableHeaderItem
                      field="descricao"
                      label="Descrição Receita"
                      page={covidpage}
                      searchWithPage={this.onConsultaWithPage}
                    />
                    <TableHeaderItem
                      field="fonteRecursoRateio"
                      label="Fonte Recursos"
                      page={covidpage}
                      searchWithPage={this.onConsultaWithPage}
                    />
                    <TableHeaderItem
                      field="descricaoFonte"
                      label="Descrição Fonte de Recurso"
                      page={covidpage}
                      searchWithPage={this.onConsultaWithPage}
                    />
                    <th className="right">Valor Orçado</th>
                    <th className="right">Valor Atualizado</th>
                    <th className="right">Valor Arrecadado</th>
                  </tr>
                </TableHeader>
                <tbody>{covid.map(this.renderCovid)}</tbody>

                {!covid.isEmpty() && (
                  <tfoot>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td id="totalValorRetido" className="right">
                        <i>Total: </i>
                        <SumListField fieldName="valorOrcado" list={covid} />
                      </td>
                      <td id="totalValorPago" className="right">
                        <i>Total: </i>
                        <SumListField
                          fieldName="valorAtualizado"
                          list={covid}
                        />
                      </td>
                      <td id="totalValorAPagar" className="right">
                        <i>Total: </i>
                        <SumListField
                          fieldName="valorArrecadado"
                          list={covid}
                        />
                      </td>
                      <td></td>
                    </tr>
                  </tfoot>
                )}
              </table>
            </div>
          </Panel>
        )}

        <SearchPagination
          page={covidpage}
          searchWithPage={this.onConsultaWithPage}
        />
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    covidpage: state.receita.get('covid'),
    filtros: state.crud.getIn(['filtroCovid', 'currentRecord'], Map({}))
  };
}

const allActions = {
  ...actions,
  onChange,
  resetCrud,
  addErrorNotification
};

export default connect(mapStateToProps, allActions)(FormReceitaCovid);
