export const ENTIDADE_BRASAO = 'ENTIDADE_BRASAO';
export const ENTIDADE_BRASAO_START = 'ENTIDADE_BRASAO_START';
export const ENTIDADE_BRASAO_SUCCESS = 'ENTIDADE_BRASAO_SUCCESS';
export const ENTIDADE_BRASAO_ERROR = 'ENTIDADE_BRASAO_ERROR';

export const ENTIDADES_LIST = 'ENTIDADES_LIST';
export const ENTIDADES_LIST_START = 'ENTIDADES_LIST_START';
export const ENTIDADES_LIST_SUCCESS = 'ENTIDADES_LIST_SUCCESS';
export const ENTIDADES_LIST_ERROR = 'ENTIDADES_LIST_ERROR';

export const EXERCICIOSBYENTIDADE_LIST = 'EXERCICIOSBYENTIDADE_LIST';
export const EXERCICIOSBYENTIDADE_LIST_START =
  'EXERCICIOSBYENTIDADE_LIST_START';
export const EXERCICIOSBYENTIDADE_LIST_SUCCESS =
  'EXERCICIOSBYENTIDADE_LIST_SUCCESS';
export const EXERCICIOSBYENTIDADE_LIST_ERROR =
  'EXERCICIOSBYENTIDADE_LIST_ERROR';

export const EXERCICIO_PADRAO = 'EXERCICIO_PADRAO';
export const EXERCICIO_PADRAO_START = 'EXERCICIO_PADRAO_START';
export const EXERCICIO_PADRAO_SUCCESS = 'EXERCICIO_PADRAO_SUCCESS';
export const EXERCICIO_PADRAO_ERROR = 'EXERCICIO_PADRAO_ERROR';

export const EXERCICIO_LOGADO = 'EXERCICIO_LOGADO';
export const EXERCICIO_LOGADO_START = 'EXERCICIO_LOGADO_START';
export const EXERCICIO_LOGADO_SUCCESS = 'EXERCICIO_LOGADO_SUCCESS';
export const EXERCICIO_LOGADO_ERROR = 'EXERCICIO_LOGADO_ERROR';

export const CONFIGURACOES_GERAIS = 'CONFIGURACOES_GERAIS';
export const CONFIGURACOES_GERAIS_START = 'CONFIGURACOES_GERAIS_START';
export const CONFIGURACOES_GERAIS_SUCCESS = 'CONFIGURACOES_GERAIS_SUCCESS';
export const CONFIGURACOES_GERAIS_ERROR = 'CONFIGURACOES_GERAIS_ERROR';
