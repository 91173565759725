import {
  FormattedCurrency,
  FormattedDate,
  Table,
  Panel
} from '@elotech/components';
import React from 'react';
import Component from 'react-pure-render/component';
import PropTypes from 'prop-types';
import SearchPagination from 'portaltransparencia-common/components/SearchPagination.react';
import { fromJS } from 'immutable';
import ExportButtonGroup from 'portaltransparencia-common/components/ExportButtonGroup.react';
import * as types from './Types';

class RenunciaReceitasList extends Component {
  constructor(props) {
    super(props);
    this.state = { sort: undefined };
  }

  static propTypes = {
    page: PropTypes.object.isRequired,
    filter: PropTypes.object.isRequired,
    search: PropTypes.func.isRequired,
    onExport: PropTypes.func.isRequired
  };

  hasElements = () => {
    const { page } = this.props;

    return page?.get('totalElements') > 0 && page?.has('content');
  };

  getContent = () => {
    const { page } = this.props;

    return page?.get('content').toJS();
  };

  shouldShow = () => this.hasElements();

  sortOnHeaderColumnClick = sort => {
    if (!this.hasElements()) return;

    const { search, filter, page } = this.props;
    const pageWithSort = fromJS({ ...page.toJS(), ...sort });

    search(filter, pageWithSort).then(() => {
      this.setState({ sort: sort });
    });
  };

  searchWithPage = page => {
    const { search, filter } = this.props;
    const { sort } = this.state;
    const pageWithSort = fromJS({ ...page.toJS(), ...sort });

    search(filter, pageWithSort);
  };

  onExport = (exportType, openTab, windowParams) => {
    const { onExport, page } = this.props;
    const { sort } = this.state;
    const pageWithSort = fromJS({ ...page.toJS(), ...sort });

    onExport(exportType, openTab, windowParams, pageWithSort);
  };

  getTitlePanel = () => {
    const { filter } = this.props;
    return filter.tipoMovimentacaoTributo
      ? `Detalhado - ${types.getLabelByTipo(filter.tipoMovimentacaoTributo)}`
      : 'Detalhado';
  };

  render() {
    const { page } = this.props;
    const {
      getTitlePanel,
      onExport,
      shouldShow,
      getContent,
      searchWithPage,
      sortOnHeaderColumnClick
    } = this;

    return (
      <Panel
        isTable
        scrollHorizontal
        title={getTitlePanel()}
        visible={true}
        mobileClose
        expansible
      >
        <ExportButtonGroup onClick={onExport} visible={true} />
        <Table
          keyExtractor={(_, index) => index}
          values={shouldShow() ? getContent() : []}
          sortOnHeaderColumnClick={sortOnHeaderColumnClick}
        >
          <Table.Column
            sortable
            name="dataSituacao"
            header="Data"
            value={value => <FormattedDate value={value.dataSituacao} />}
          />
          <Table.Column
            sortable
            name="cpfCnpj"
            header="CPF/CNPJ"
            value={value => value.cpfCnpj}
          />
          <Table.Column
            sortable
            name="contribuinte"
            header="Contribuinte"
            value={value => value.contribuinte}
          />
          <Table.Column
            sortable
            name="especie"
            header="Espécie"
            value={value => value.especie}
          />
          <Table.Column
            sortable
            name="justificativa"
            header="Produto"
            value={value => value.justificativa}
          />
          <Table.Column
            sortable
            name="valor"
            header="Valor"
            value={value => <FormattedCurrency value={value.valor} />}
          />
        </Table>
        {shouldShow() && (
          <SearchPagination page={page} searchWithPage={searchWithPage} />
        )}
      </Panel>
    );
  }
}

export default RenunciaReceitasList;
