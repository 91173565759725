import * as types from './Types.js';

export function findAptosPagamentos(filter) {
  return ({ fetch }) => ({
    type: types.APTOS_PAGAMENTOS,
    payload: {
      promise: fetch(`/api/liquidacoes/aptos-pagamentos?${filter}`)
    }
  });
}

export function findAptosPagamentosTotal(filter) {
  return ({ fetch }) => ({
    type: types.APTOS_PAGAMENTOS_TOTAL,
    payload: {
      promise: fetch(`/api/liquidacoes/aptos-pagamentos/total?${filter}`)
    }
  });
}
