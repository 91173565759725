import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';
import * as types from './Types.js';

const initialState = fromJS({
  terceirizados: []
});

function terceirizadosReducer(state = initialState, action) {
  switch (action.type) {
    case types.TERCEIRIZADOS_START:
    case types.CONSULTA_TERCEIRIZADOS_START: {
      return startLoad(state);
    }

    case types.TERCEIRIZADOS_ERROR:
    case types.CONSULTA_TERCEIRIZADOS_ERROR: {
      return endLoad(state);
    }

    case types.TERCEIRIZADOS_SUCCESS:
    case types.CONSULTA_TERCEIRIZADOS_SUCCESS: {
      return endLoad(state.set('terceirizados', fromJS(action.payload)));
    }

    case types.RESET_CONSULTA_TERCEIRIZADOS: {
      return initialState;
    }
  }

  return state;
}

export default terceirizadosReducer;
