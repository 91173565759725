import Component from 'react-pure-render/component';
import PropTypes from 'prop-types';
import React from 'react';
import WizardHeaderItem from './WizardHeaderItem.react.js';

export default class Wizard extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    listHeader: PropTypes.any
  };

  renderHeader = headerItem => {
    return (
      <WizardHeaderItem
        key={headerItem.get('stepNumber')}
        activeStep={headerItem.get('activeStep')}
        stepNumber={headerItem.get('stepNumber')}
        stepFaIcon={headerItem.get('stepFaIcon')}
        stepTitle={headerItem.get('stepTitle')}
      />
    );
  };

  render() {
    const { listHeader, children } = this.props;

    return (
      <div className="wizard">
        {listHeader && (
          <ul className="wizard-header">{listHeader.map(this.renderHeader)}</ul>
        )}
        <ul className="wizard-content">{children}</ul>
      </div>
    );
  }
}
