import Panel from 'portaltransparencia-common/components/Panel.react.js';
import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import { downloadArquivoEscalas } from '../arquivo/Actions.js';
import PageHeader from '../components/PageHeader.react.js';
import NotasExplicativas from '../notaexplicativa/Form.react';
import UltimaAtualizacaoByTable from '../ultimaatualizacao/UltimaAtualizacaoByTable.react.js';
import * as actions from './Actions';
import EstabelecimentoFiltro from './EstabelecimentoFiltro.react';

export class FormEscalas extends Component {
  static propTypes = {
    params: PropTypes.object,
    escalas: PropTypes.object,
    findEstabelecimentos: PropTypes.func.isRequired,
    filterEscalas: PropTypes.func.isRequired,
    resetEscalas: PropTypes.func.isRequired,
    downloadArquivo: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired
  };

  componentDidMount() {
    const { findEstabelecimentos } = this.props;
    findEstabelecimentos();
  }

  renderListas = escala => {
    return (
      <TableActions
        ref="row"
        key={escala.get('id')}
        data={escala}
        onDownload={downloadArquivoEscalas}
      >
        <td>{escala.get('nome')}</td>
      </TableActions>
    );
  };

  render() {
    const {
      escalas,
      findEstabelecimentos,
      filterEscalas,
      resetEscalas
    } = this.props;

    let list = escalas;

    return (
      <section>
        <PageHeader location={this.props.location} />

        <NotasExplicativas pathname={this.props.location.pathname} />

        <EstabelecimentoFiltro
          show={list}
          findEstabelecimentos={findEstabelecimentos}
          filterEscalas={filterEscalas}
          resetEscalas={resetEscalas}
        />

        <Panel expansible={false}>
          <UltimaAtualizacaoByTable
            modulo="14"
            table="escala"
            column="dataultimaatualizacao"
          />
          <Panel isTable tableResponsive expansible={false}>
            <div className="panel-table-outer">
              <table className="panel-table striped fancy">
                <TableHeader empty={list.isEmpty()}>
                  <tr>
                    <th>Estabelecimento</th>
                    <th></th>
                  </tr>
                </TableHeader>
                <tbody>{list.map(this.renderListas)}</tbody>
              </table>
            </div>
          </Panel>
        </Panel>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    escalas: state.escala.get('escalasFiltradas')
  };
}

const allActions = {
  ...actions,
  downloadArquivoEscalas
};

export default connect(mapStateToProps, allActions)(FormEscalas);
