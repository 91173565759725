import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import TableHeaderItem from 'portaltransparencia-common/components/TableHeaderItem.react';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

import EstagiarioRow from './EstagiarioRow.react.js';

export default class EstagiarioTable extends Component {
  static propTypes = {
    page: PropTypes.object.isRequired,
    searchWithPage: PropTypes.func.isRequired
  };

  renderEstagiarios = data => {
    const { usaWebService } = this.props;
    const key = `${data.get('entidade')}_${data.get('codigo')}`;

    return (
      <EstagiarioRow
        key={key}
        estagiario={data}
        usaWebService={usaWebService}
        history={this.props.history}
      />
    );
  };

  render() {
    const { page, usaWebService, searchWithPage } = this.props;
    let estagiarios = page.get('content');

    if (!estagiarios) {
      return null;
    }
    return (
      <div className="panel-table-outer">
        <table className="panel-table striped fancy">
          <TableHeader
            empty={estagiarios.isEmpty()}
            text="Não foi encontrado nenhum estagiário para a pesquisa selecionada."
          >
            <tr>
              <TableHeaderItem
                field="nome"
                label="Nome do Estagiário"
                page={page}
                searchWithPage={searchWithPage}
              />
              <TableHeaderItem
                field="matricula"
                label="Matrícula"
                page={page}
                searchWithPage={searchWithPage}
              />
              <TableHeaderItem
                field="cargo"
                label="Cargo"
                page={page}
                searchWithPage={searchWithPage}
              />
              <TableHeaderItem
                field="lotacao"
                label="Lotação"
                page={page}
                searchWithPage={searchWithPage}
              />
              {!usaWebService && (
                <TableHeaderItem
                  field="inicioContrato"
                  label="Início do Contrato"
                  page={page}
                  searchWithPage={searchWithPage}
                />
              )}
              {!usaWebService && (
                <TableHeaderItem
                  field="terminoContrato"
                  label="Término do Contrato"
                  page={page}
                  searchWithPage={searchWithPage}
                />
              )}
              <th />
            </tr>
          </TableHeader>

          <tbody>{estagiarios.map(this.renderEstagiarios)}</tbody>
        </table>
      </div>
    );
  }
}
