import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export default class ProcessoInformacoesTramitesTable extends Component {
  static propTypes = {
    tramites: PropTypes.object.isRequired
  };

  renderTramites = tramite => {
    const key =
      tramite.get('sequencia') +
      '_' +
      tramite.get('data') +
      '_' +
      tramite.get('local');
    return (
      <tr ref="row" key={key}>
        <td>{tramite.get('sequencia')}</td>
        <td>{tramite.get('data')}</td>
        <td>{tramite.get('local')}</td>
        <td>{tramite.get('situacao')}</td>
        <td>{tramite.get('parecer')}</td>
      </tr>
    );
  };

  render() {
    const { tramites } = this.props;

    return (
      <div className="panel-table-outer">
        <table className="panel-table striped fancy">
          <TableHeader empty={tramites.isEmpty()}>
            <tr>
              <th>Ordem</th>
              <th>Data</th>
              <th>Local</th>
              <th>Situação</th>
              <th>Parecer</th>
            </tr>
          </TableHeader>
          <tbody>{tramites.map(this.renderTramites)}</tbody>
        </table>
      </div>
    );
  }
}
