import Panel from 'portaltransparencia-common/components/Panel.react.js';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import { downloadArquivo } from '../arquivo/Actions.js';
import PageHeader from '../components/PageHeader.react.js';
import NotasExplicativas from '../notaexplicativa/Form.react';
import * as actions from './Actions.js';
import EstruturaOrganizacional from './EstruturaOrganizacional.react';

/* eslint-disable react/no-danger*/

export class FormOrganograma extends Component {
  static propTypes = {
    findOrganograma: PropTypes.func.isRequired,
    organograma: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    downloadArquivo: PropTypes.func.isRequired,
    exercicios: PropTypes.object
  };

  componentDidMount() {
    const { findOrganograma } = this.props;
    let userInfo = StorageService.getItem('userInfo');
    const { entidade } = userInfo;

    const filtro = 'entidade=' + entidade.id;
    findOrganograma(filtro);
  }

  renderEstruturaOrganizacional = data => {
    const { downloadArquivo, exercicios } = this.props;

    return (
      <EstruturaOrganizacional
        organograma={data}
        exercicios={exercicios}
        downloadArquivo={downloadArquivo}
      />
    );
  };

  render() {
    const { organograma } = this.props;

    if (!organograma || organograma.size == 0) {
      return (
        <section>
          <PageHeader location={this.props.location} />
          <div className="info">Nehum registro.</div>
        </section>
      );
    }

    return (
      <section>
        <PageHeader location={this.props.location} />

        <NotasExplicativas pathname={this.props.location.pathname} />

        <Panel expansible={false}>
          {organograma.map(this.renderEstruturaOrganizacional)}
        </Panel>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    organograma: state.organograma.get('organograma'),
    exercicios: state.entidade.get('exercicios')
  };
}

const allActions = {
  ...actions,
  downloadArquivo
};

export default connect(mapStateToProps, allActions)(FormOrganograma);
