import * as types from './Types';

const url = '/api/movimentacao-tributo';

export const findRenunciaReceitas = params => ({ fetch }) => {
  return {
    type: types.FIND_RENUNCIA_RECEITAS,
    payload: {
      promise: fetch(`${url}?${params}`)
    }
  };
};

export const findClassificacoes = () => ({ fetch }) => {
  return {
    type: types.FIND_CLASSIFICACOES,
    payload: {
      promise: fetch(`${url}/classificacoes`)
    }
  };
};

export const findResumo = params => ({ fetch }) => {
  return {
    type: types.FIND_RESUMO,
    payload: fetch(`${url}/resumo?${params}`)
  };
};

export const findTotalResumo = params => ({ fetch }) => {
  return {
    type: types.FIND_TOTAL_RESUMO,
    payload: {
      promise: fetch(`${url}/total-resumo?${params}`)
    }
  };
};
