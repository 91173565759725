import { getAllValuesFromUrlSearchParams } from 'portaltransparencia-common/libs/URLUtils';
import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import SearchPagination from 'portaltransparencia-common/components/SearchPagination.react.js';
import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import TableHeaderItem from 'portaltransparencia-common/components/TableHeaderItem.react';
import {
  onChange,
  resetCrud
} from 'portaltransparencia-common/crud/Actions.js';
import {
  addFilterUrl,
  addPagination
} from 'portaltransparencia-common/libs/AddFilter.js';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';
import PageHeader from '../components/PageHeader.react.js';
import * as actions from './Actions.js';
import { Map } from 'immutable';
import { FormattedDate } from '@elotech/components';
import DatePicker from 'portaltransparencia-common/components/DatePicker.react.js';
import Input from 'portaltransparencia-common/components/EloInput.react.js';
import AutoComplete from 'portaltransparencia-common/components/AutoComplete.react.js';

export class FormRenunciaReceitaDetalhe extends Component {
  static propTypes = {
    page: PropTypes.object.isRequired,
    findRenunciaReceitaDetalhes: PropTypes.func.isRequired,
    filtros: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    resetCrud: PropTypes.func.isRequired,
    findTipoOperacaoReceita: PropTypes.func.isRequired,
    tipoOperacaoReceita: PropTypes.object.isRequired,
    findReceita: PropTypes.func.isRequired
  };

  componentDidMount() {
    const { findTipoOperacaoReceita } = this.props;
    findTipoOperacaoReceita();
  }

  carregarRenunciaReceitaDetalhes = () => {
    const { page } = this.props;
    const initialPage = page.set('number', 0);

    this.searchWithPage(initialPage);
  };

  consultarRenunciaReceitaDetalhes = event => {
    this.carregarRenunciaReceitaDetalhes();
    event && event.preventDefault();
    this.refs.panelFiltroRenunciaReceitaDetalhes &&
      this.refs.panelFiltroRenunciaReceitaDetalhes.collapse();
  };

  onChangeFiltro = event => {
    const { onChange } = this.props;
    onChange('filtroRenunciasDetalhe', event);
  };

  onResetFiltro = () => {
    const { resetCrud } = this.props;

    resetCrud('filtroRenunciasDetalhe');
    this.loadInitialFiltros();
  };

  montarFiltro = function (page) {
    const { filtros, location } = this.props;
    const params = getAllValuesFromUrlSearchParams(location);

    let filter = `entidade=${params.entidade}&exercicio=${params.exercicio}&descTipoTributo=${params.descTipoTributo}`;

    if (filtros.get('dataInicio')) {
      filter = addFilterUrl(filter, `dataInicio=${filtros.get('dataInicio')}`);
    }

    if (filtros.get('dataFim')) {
      filter = addFilterUrl(filter, `dataFim=${filtros.get('dataFim')}`);
    }

    if (filtros.get('tipoOperacao')) {
      filter = addFilterUrl(
        filter,
        `idTipoOperacaoReceita=${filtros.get('tipoOperacao')}`
      );
    }

    if (filtros.get('receitaSelecionada')) {
      const receitaMap = filtros.get('receitaSelecionada');
      const receita = receitaMap.get('receita');
      filter = addFilterUrl(filter, `receita=${encodeURIComponent(receita)}`);
    }

    return addPagination(page, filter);
  };

  searchWithPage = page => {
    const { findRenunciaReceitaDetalhes } = this.props;
    findRenunciaReceitaDetalhes(this.montarFiltro(page));
  };

  renderRenunciaReceitaDetalhe = data => {
    return (
      <TableActions ref="row" key={data.get('lancamento')} data={data}>
        <td>{data.get('receita')}</td>
        <td>{data.get('descTipoOperacaoReceita')}</td>
        <td>{data.get('descricao')}</td>
        <td className="right">
          <NumberFormatter value={data.get('valor')} />
        </td>
      </TableActions>
    );
  };

  renderTipoOperacaoReceita = item => {
    return (
      <option key={item.get('id')} value={item.get('id')}>
        {item.get('id')} - {item.get('descricao')}
      </option>
    );
  };

  renderValue = option => {
    return <span>{option.codigo + ' - ' + option.descricao}</span>;
  };

  render() {
    const {
      page,
      filtros,
      location,
      tipoOperacaoReceita,
      findReceita
    } = this.props;

    return (
      <section>
        <PageHeader location={location} />

        <Panel
          isForm
          mobileClose
          title="Consultar em Detalhes"
          ref="panelFiltroRenunciaReceitaDetalhes"
        >
          <form>
            <Row>
              <Col xs={12} sm={2} md={2}>
                <DatePicker
                  label="Data Inicial do Lançamento"
                  name="dataInicio"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('dataInicio')}
                />
              </Col>
              <Col xs={12} sm={2} md={2}>
                <DatePicker
                  label="Data Final do Lançamento"
                  name="dataFim"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('dataFim')}
                />
              </Col>
              <Col xs={12} sm={12} md={3}>
                <Input
                  id="tipoOperacao"
                  label="Tipo de Operação"
                  type="select"
                  name="tipoOperacao"
                  ref="tipoOperacao"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('tipoOperacao')}
                >
                  <option value="">Selecione</option>
                  {tipoOperacaoReceita.map(this.renderTipoOperacaoReceita)}
                </Input>
              </Col>
              <Col xs={12} sm={4} md={4}>
                <AutoComplete
                  label="Receita"
                  id="receitaSelecionada"
                  name="receitaSelecionada"
                  onLoad={findReceita}
                  onChange={this.onChangeFiltro}
                  value={filtros.get('receitaSelecionada')}
                  valueKey="receita"
                  labelKey="descricao"
                  placeholder="Selecione a Receita"
                  valueRenderer={this.renderValue}
                  filterFields={['receita', 'descricao']}
                />
              </Col>
              <Col xs={12} sm={4} md={4}>
                <div className="form-group">
                  <label className="label"></label>
                  <div className="btn-group">
                    <button
                      id="btnFiltrar"
                      className="btn module-color icon-right"
                      type="submit"
                      ref="pesquisar"
                      onClick={this.consultarRenunciaReceitaDetalhes}
                    >
                      Pesquisar
                      <em className="fa fa-search"></em>
                    </button>
                    <button
                      id="btnResetFilter"
                      className="btn neutral icon-right inline no-text"
                      type="button"
                      onClick={this.onResetFiltro}
                    >
                      Limpar
                      <em className="fa fa-times"></em>
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </form>
        </Panel>

        {page && page.get('content') && (
          <Panel isForm tableResponsive expansible={false}>
            <div className="panel-table-outer">
              <table className="panel-table striped fancy">
                <TableHeader
                  empty={page.get('content').isEmpty()}
                  text="Não foram encontrados registros."
                >
                  <tr>
                    <TableHeaderItem
                      field="receita"
                      label="Receita"
                      page={page}
                      searchWithPage={this.searchWithPage}
                    />
                    <TableHeaderItem
                      field="descTipoOperacaoReceita"
                      label="Tipo de Operação"
                      page={page}
                      searchWithPage={this.searchWithPage}
                    />
                    <TableHeaderItem
                      field="descricao"
                      label="Descrição"
                      page={page}
                      searchWithPage={this.searchWithPage}
                    />
                    <TableHeaderItem
                      field="valor"
                      label="Valor"
                      page={page}
                      searchWithPage={this.searchWithPage}
                      alignment="right"
                    />
                    <th />
                  </tr>
                </TableHeader>
                <tbody>
                  {page.get('content').map(this.renderRenunciaReceitaDetalhe)}
                </tbody>
              </table>
            </div>

            <SearchPagination
              page={page}
              searchWithPage={this.searchWithPage}
            />
          </Panel>
        )}
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    filtros: state.crud.getIn(
      ['filtroRenunciasDetalhe', 'currentRecord'],
      Map({})
    ),
    page: state.contabRenunciaReceitas.get('renunciasReceitaDetalhe'),
    tipoOperacaoReceita: state.contabRenunciaReceitas.get('tipoOperacaoReceita')
  };
}

const allActions = {
  ...actions,
  onChange,
  resetCrud
};

export default connect(mapStateToProps, allActions)(FormRenunciaReceitaDetalhe);
