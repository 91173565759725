import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';

import * as types from './Types.js';

const initialState = fromJS({
  participantes: [],
  participante: {}
});

function participantesReducer(state = initialState, action) {
  switch (action.type) {
    case types.LICITACOES_PARTICIPANTES_START:
    case types.LICITACOES_PARTICIPANTE_START: {
      return startLoad(state);
    }

    case types.LICITACOES_PARTICIPANTES_SUCCESS: {
      return endLoad(state.set('participantes', fromJS(action.payload)));
    }

    case types.LICITACOES_PARTICIPANTE_SUCCESS: {
      return endLoad(state.set('participante', fromJS(action.payload)));
    }

    case types.LICITACOES_PARTICIPANTES_ERROR:
    case types.LICITACOES_PARTICIPANTE_ERROR: {
      return endLoad(state);
    }
  }
  return state;
}

export default participantesReducer;
