import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export default class PensionistaRow extends Component {
  static propTypes = {
    pensionista: PropTypes.object.isRequired
  };

  onViewDetalhes = item => {
    this.props.history.push(
      `/pensionistas/detalhes?vinculo=${item.get(
        'vinculo'
      )}&matricula=${item.get('matricula')}`
    );
  };

  render() {
    const { pensionista, usaWebService } = this.props;
    const key = `${pensionista.get('entidade')}_${pensionista.get(
      'matricula'
    )}`;

    return (
      <TableActions
        ref="row"
        key={key}
        data={pensionista}
        onMore={this.onViewDetalhes}
      >
        <td id="tableNome">{pensionista.get('nome')}</td>
        <td id="tableMatricula">{pensionista.get('matricula')}</td>
        <td id="tableSituacao">{pensionista.get('situacao')}</td>
        <td id="tableDescricaoCargo">{pensionista.get('descricaoCargo')}</td>
        {!usaWebService && (
          <td id="tableDescricaoClasse">
            {pensionista.get('descricaoClasse')}
          </td>
        )}
        <td id="tableDescricaoLotacao">
          {pensionista.get('descricaoLotacao')}
        </td>
      </TableActions>
    );
  }
}
