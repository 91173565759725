import { BaseError } from 'make-error';

export default class ServerError extends BaseError {
  constructor(response, payload = {}) {
    super();
    this.status = payload.status || response.status;
    this.message = payload.message || response.message;

    this.error = payload.error;
    this.exception = payload.exception;
    this.path = payload.path;
    this.timeStamp = payload.timestamp;
    this.detail = payload.detail;
    this.violations = payload.violations || [];
  }
}
