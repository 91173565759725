import * as actions from './Actions.js';

import Arquivo from '../arquivo/Arquivo.react.js';
import BotaoImprimir from 'portaltransparencia-common/components/BotaoImprimir.react.js';
import CabecalhoEntidade from '../entidades/CabecalhoEntidade.react.js';
import Component from 'react-pure-render/component';
import Empenhos from './../empenhos/Form.react.js';
import Helmet from 'react-helmet';
import { PARAM_EMPENHOS } from 'portaltransparencia-common/parametros/ParametrosPortalTransparencia';
import PageHeader from '../components/PageHeader.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import ParametroService from '../parametros/ParametroService.js';
import PatrimonioDetalheEmpenho from './PatrimonioDetalheEmpenho.react.js';
import PatrimonioDetalheInformacao from './PatrimonioDetalheInformacao.react.js';
import PatrimonioLocalTable from './PatrimonioLocalTable.react.js';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { downloadArquivo } from '../arquivo/Actions.js';
import { getAllValuesFromUrlSearchParams } from 'portaltransparencia-common/libs/URLUtils';
import { getLabelByTipo } from './TipoBemUtils.js';

export class FormPatrimonioDetail extends Component {
  static propTypes = {
    detalhes: PropTypes.object.isRequired,
    findPatrimoniosDetalhes: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    bem: PropTypes.object.isRequired,
    downloadArquivo: PropTypes.func.isRequired,
    findPatrimonioBem: PropTypes.func.isRequired,
    findEmpenhos: PropTypes.func.isRequired,
    paramEmpenhos: PropTypes.string,
    isIntegradoOxy: PropTypes.bool,
    empenhos: PropTypes.object.isRequired
  };

  componentDidMount() {
    const {
      findPatrimoniosDetalhes,
      location,
      findPatrimonioBem,
      findEmpenhos,
      isIntegradoOxy
    } = this.props;

    const params = getAllValuesFromUrlSearchParams(location);

    findPatrimonioBem(params.entidade, params.tipo, params.bem, params.chapa);
    findPatrimoniosDetalhes(
      params.entidade,
      params.tipo,
      params.bem,
      params.chapa
    );
    if (isIntegradoOxy) {
      findEmpenhos(params.entidade, params.tipo, params.bem, params.chapa);
    }
  }

  searchEmpenho = function (location) {
    const params = getAllValuesFromUrlSearchParams(location);

    let search = `?search=id.entidade==${params.entidade}&tipoBem=${params.tipo}&chapaBem=${params.chapa}&entidade=${params.entidade}`;

    return search;
  };

  getEntidade = location => {
    const params = getAllValuesFromUrlSearchParams(location);

    return params.entidade;
  };

  render() {
    const {
      paramEmpenhos,
      detalhes,
      bem,
      downloadArquivo,
      history,
      match,
      location,
      isIntegradoOxy,
      empenhos
    } = this.props;

    const tipo = getLabelByTipo(bem.getIn(['id', 'tipo']));

    if (bem.isEmpty()) {
      return null;
    }

    return (
      <div>
        <Helmet title={`Patrimônio - ${bem.get('descricao')}`} />

        <BotaoImprimir />

        <PageHeader
          location={this.props.location}
          textoDetalhe={`Tombamento: ${tipo} - ${bem.getIn(['id', 'chapa'])}`}
        />

        <CabecalhoEntidade />

        <PatrimonioDetalheInformacao
          bem={bem}
          isIntegradoOxy={isIntegradoOxy}
        />

        <PatrimonioLocalTable locais={detalhes} />

        {paramEmpenhos == 'S' && isIntegradoOxy && (
          <PatrimonioDetalheEmpenho
            empenhos={empenhos}
            entidade={this.getEntidade(location)}
            location={location}
            history={history}
          />
        )}

        {paramEmpenhos == 'S' && !isIntegradoOxy && (
          <Panel title="Empenhos" isTable tableResponsive>
            <Empenhos
              filtroParam={this.searchEmpenho(location)}
              disableHeader
              history={history}
              match={match}
              location={location}
            />
          </Panel>
        )}

        <div className="hidden-print">
          <Panel title="Anexos" expansible={false} isTable tableResponsive>
            <Arquivo
              arquivos={bem.get('arquivos')}
              onDownload={downloadArquivo}
            />
          </Panel>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    detalhes: state.patrimonio.get('detalhes'),
    bem: state.patrimonio.get('bem'),
    empenhos: state.patrimonio.get('empenhos'),
    paramEmpenhos: ParametroService.getValorParametro(state, PARAM_EMPENHOS),
    isIntegradoOxy: state.entidade.getIn([
      'configuracoesGerais',
      'isIntegradoOxy'
    ])
  };
}

const allActions = {
  ...actions,
  downloadArquivo
};

export default connect(mapStateToProps, allActions)(FormPatrimonioDetail);
