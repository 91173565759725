import ExportButtonGroup from 'portaltransparencia-common/components/ExportButtonGroup.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react.js';
import TableHeaderItem from 'portaltransparencia-common/components/TableHeaderItem.react';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import UltimaAtualizacao from '../ultimaatualizacao/Form.react.js';
import AjudaCustoServidorRow from './AjudaCustoServidorRow.react.js';

export default class AjudaCustoServidorTable extends Component {
  static propTypes = {
    filter: PropTypes.string,
    page: PropTypes.object.isRequired,
    searchWithPage: PropTypes.func.isRequired,
    onExporta: PropTypes.func.isRequired
  };

  generateKey = servidor => {
    return `${servidor.get('entidade')}_
            ${servidor.get('matricula')}_
            ${servidor.get('cargo')}`;
  };

  renderServidores = data => {
    return (
      <AjudaCustoServidorRow
        key={this.generateKey(data)}
        servidor={data}
        history={this.props.history}
      />
    );
  };

  render() {
    const { page, searchWithPage, onExporta } = this.props;

    return (
      <Panel isForm tableResponsive expansible={false}>
        <Row>
          <Col xs={6}>
            <UltimaAtualizacao modulo="1" />
          </Col>
          <Col xs={6}>
            <ExportButtonGroup onClick={onExporta} visible={true} />
          </Col>
        </Row>

        <div className="panel-table-outer">
          <table className="panel-table striped fancy">
            <TableHeader empty={page.get('content').isEmpty()}>
              <tr>
                <TableHeaderItem
                  field="matricula"
                  label="Matrícula"
                  page={page}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="nome"
                  label="Servidor"
                  page={page}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="cargoDescricao"
                  label="Cargo"
                  page={page}
                  searchWithPage={searchWithPage}
                />
                <th></th>
              </tr>
            </TableHeader>
            <tbody>{page.get('content').map(this.renderServidores)}</tbody>
          </table>
        </div>
      </Panel>
    );
  }
}
