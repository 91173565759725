import React from 'react';
import Component from 'react-pure-render/component';

export default class EmManutencao extends Component {
  render() {
    return (
      <div className="not-found">
        <h1>
          <em className="fa fa-exclamation-triangle"></em>
        </h1>
        <h2>Desculpe, estamos em manutenção!</h2>
      </div>
    );
  }
}
