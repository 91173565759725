import Component from 'react-pure-render/component';
import ContratoResponsavelTipoUtils from './ContratoResponsavelTipoUtils';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import PropTypes from 'prop-types';
import React from 'react';
import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react.js';

export class ContratoDetalheResponsavel extends Component {
  static propTypes = {
    responsaveis: PropTypes.object.isRequired,
    isOxy: PropTypes.bool.isRequired
  };

  renderResponsaveis = data => {
    const { isOxy } = this.props;

    const key =
      data.get('entidade') +
      '_' +
      data.get('exercicio') +
      '_' +
      data.get('contrato') +
      '_' +
      data.get('sequencia');

    return (
      <TableActions ref="row" key={key} data={data}>
        {isOxy && (
          <td>{ContratoResponsavelTipoUtils.format(data.get('tipo'))}</td>
        )}
        <td>{data.get('ocupante')}</td>
        <td>{DateUtils.transform(data.get('dataInicio'))}</td>
        <td>{DateUtils.transform(data.get('dataTermino'))} </td>
        <td>{data.get('descricaoCargo')}</td>
        {!isOxy && <td>{data.get('obsAtoDesignacao')}</td>}
      </TableActions>
    );
  };

  render() {
    const { responsaveis, isOxy } = this.props;
    const titulo = 'Responsáveis/Fiscalização ' + this.props.descricaoTipoAto;

    const responsaveisOrdenados = isOxy
      ? responsaveis.sort((a, b) => {
          const tipoA = a.get('tipo');
          const tipoB = b.get('tipo');
          return (
            ContratoResponsavelTipoUtils.order(tipoA) -
            ContratoResponsavelTipoUtils.order(tipoB)
          );
        })
      : responsaveis;

    return (
      <section>
        <Panel title={titulo} isTable tableResponsive>
          <div className="panel-table-outer">
            <table className="panel-table striped fancy word-break">
              <TableHeader
                empty={responsaveis.isEmpty()}
                text="Sem Fiscais do Contrato."
              >
                <tr>
                  {isOxy && <th>Tipo</th>}
                  <th>Nome</th>
                  <th>Data Início</th>
                  <th>Data Término</th>
                  <th>Cargo</th>
                  {!isOxy && <th>Ato de Designação</th>}
                  <th></th>
                </tr>
              </TableHeader>
              <tbody>
                {responsaveisOrdenados.map(this.renderResponsaveis)}
              </tbody>
            </table>
          </div>
        </Panel>
      </section>
    );
  }
}

export default ContratoDetalheResponsavel;
