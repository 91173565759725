import Panel from 'portaltransparencia-common/components/Panel.react.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

import AvaliacaoPpaFuncaoPanel from './AvaliacaoPpaFuncaoPanel.react.js';

export default class AvaliacaoPpaUnidadePanel extends Component {
  static propTypes = {
    unidade: PropTypes.object.isRequired
  };

  renderFuncoes = funcao => {
    return (
      <AvaliacaoPpaFuncaoPanel
        key={funcao.get('codigoFuncao')}
        funcao={funcao}
      />
    );
  };

  render() {
    const { unidade } = this.props;

    return (
      <Panel
        key={unidade.get('codigoUnidade')}
        isForm
        mobileClose
        title={`Unidade ${unidade.get('codigoUnidade') || ''}`}
        insidePanel
      >
        {unidade.get('funcoes') &&
          unidade.get('funcoes').map(this.renderFuncoes)}
      </Panel>
    );
  }
}
