import { Map } from 'immutable';
import DatePicker from 'portaltransparencia-common/components/DatePicker.react.js';
import Input from 'portaltransparencia-common/components/EloInput.react.js';
import ExportButtonGroup from 'portaltransparencia-common/components/ExportButtonGroup.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import SearchPagination from 'portaltransparencia-common/components/SearchPagination.react.js';
import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import TableHeaderItem from 'portaltransparencia-common/components/TableHeaderItem.react';
import configuration from 'portaltransparencia-common/config/Config.js';
import {
  onChange,
  resetCrud
} from 'portaltransparencia-common/crud/Actions.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import FilterButtons from '../components/FilterButtons.react';
import PageHeader from '../components/PageHeader.react.js';
import NotasExplicativas from '../notaexplicativa/Form.react';
import UltimaAtualizacao from '../ultimaatualizacao/Form.react.js';
import * as actions from './Actions.js';
import { buildFilterGaragem } from './Filter.js';
import { addErrorNotification } from 'portaltransparencia-common/ui/Actions.js';

export class Form extends Component {
  static propTypes = {
    page: PropTypes.object.isRequired,
    findMovimentosGaragem: PropTypes.func.isRequired,
    filtros: PropTypes.object,
    onChange: PropTypes.func,
    location: PropTypes.object.isRequired,
    resetCrud: PropTypes.func.isRequired,
    addErrorNotification: PropTypes.func.isRequired
  };

  componentDidMount() {
    const { page } = this.props;

    this.searchWithPage(page);
  }

  onChangeFiltro = event => {
    const { onChange } = this.props;

    onChange('filtroMovimentoGaragems', event);
  };

  onResetFiltro = () => {
    const { resetCrud, findMovimentosGaragem } = this.props;
    resetCrud('filtroMovimentoGaragems');
    findMovimentosGaragem('');
  };

  onConsultaMovimentoGaragems = event => {
    const { page } = this.props;

    const initialPage = page.set('number', 0);

    this.searchWithPage(initialPage);

    event && event.preventDefault();
    this.refs.panelFiltroGaragem && this.refs.panelFiltroGaragem.collapse();
  };

  searchWithPage = page => {
    const { findMovimentosGaragem } = this.props;
    const params = this.montaFiltro();
    const filtro = buildFilterGaragem(params, page);

    findMovimentosGaragem(filtro, page);
  };

  onExporta = (exportType, openTab, windowParams) => {
    const { apiURL } = configuration;
    const { page, addErrorNotification } = this.props;
    let userInfo = StorageService.getItem('userInfo');
    const { entidade, exercicio } = userInfo;

    if (page.get('content').size > 0) {
      let params = this.montaFiltro();
      let filtro =
        buildFilterGaragem(params, page) +
        '&entidade=' +
        entidade.id +
        '&exercicio=' +
        exercicio.id +
        '&exportType=' +
        exportType;

      window.open(
        `${apiURL}/api/movimento-garagem/report${filtro}`,
        openTab,
        windowParams
      );
    } else {
      addErrorNotification(
        'A consulta não apresentou resultado. Não é possível exportar para o formato selecionado!'
      );
    }
  };

  montaFiltro = () => {
    const { filtros } = this.props;

    const letraPlaca =
      this.refs.letraPlaca && this.refs.letraPlaca.getValue()
        ? this.refs.letraPlaca.getValue()
        : '';
    const numeroPlaca =
      this.refs.numeroPlaca && this.refs.numeroPlaca.getValue()
        ? this.refs.numeroPlaca.getValue()
        : '';
    const veiculo =
      this.refs.veiculo && this.refs.veiculo.getValue()
        ? this.refs.veiculo.getValue()
        : '';
    let data = '';
    if (filtros.get('data') && filtros.get('data').length === 10) {
      data = DateUtils.transform(filtros.get('data'), 'yy-mm-dd', 'yy-mm-dd');
    }

    let params = {
      letraPlaca: letraPlaca,
      numeroPlaca: numeroPlaca,
      veiculo: veiculo,
      data: data
    };
    return params;
  };

  viewMore = data => {
    const entidade = data.get('entidade');
    const exercicio = data.get('exercicio');
    const numero = data.get('numero');

    this.props.history.push(
      `movimentogaragem/detalhes?entidade=${entidade}&exercicio=${exercicio}&numero=${numero}`
    );
  };

  renderMovimentoGaragem = data => {
    return (
      <TableActions
        ref="row"
        key={data.get('numero')}
        data={data}
        onMore={this.viewMore}
      >
        <td>{data.get('placa') + ' - ' + data.get('veiculoDescricao')}</td>
        <td>{DateUtils.formatDateTime(data.get('dataSaida'), 'DD/MM/YYYY')}</td>
        <td>{DateUtils.formatTime(data.get('horaSaida'))}</td>
        <td>
          {DateUtils.formatDateTime(data.get('dataRetorno'), 'DD/MM/YYYY')}
        </td>
        <td>{DateUtils.formatTime(data.get('horaRetorno'))}</td>
        <td>{data.get('destino') + ' - ' + data.get('complementoDestino')}</td>
      </TableActions>
    );
  };

  render() {
    const { page, filtros } = this.props;

    if (!page.has('content')) {
      return null;
    }

    return (
      <section>
        <PageHeader location={this.props.location} />

        <NotasExplicativas pathname={this.props.location.pathname} />

        <Panel
          title="Consultar movimento de garagem"
          isForm
          mobileClose
          ref="panelFiltroGaragem"
        >
          <form>
            <Row>
              <Col xs={4} sm={2} md={2}>
                <Input
                  id="veiculo"
                  autoFocus
                  name="veiculo"
                  ref="veiculo"
                  placeholder="Número do Veículo"
                  label="Número do Veículo"
                  type="number"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('veiculo')}
                />
              </Col>
              <Col xs={4} sm={2} md={2}>
                <Input
                  name="letraPlaca"
                  ref="letraPlaca"
                  placeholder="Letras da Placa"
                  label="Letras da Placa"
                  type="text"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('letraPlaca')}
                />
              </Col>
              <Col xs={4} sm={2} md={2}>
                <Input
                  name="numeroPlaca"
                  ref="numeroPlaca"
                  placeholder="Números da Placa"
                  label="Números da Placa"
                  type="text"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('numeroPlaca')}
                />
              </Col>
              <Col xs={4} sm={2} md={2}>
                <DatePicker
                  name="data"
                  ref="data"
                  label="Data Saída"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('data')}
                />
              </Col>
              <Col xs={12} sm={4} md={4}>
                <FilterButtons
                  onConsulta={this.onConsultaMovimentoGaragems}
                  onReset={this.onResetFiltro}
                />
              </Col>
            </Row>
          </form>
        </Panel>

        <Panel isForm expansible={false} tableResponsive>
          <Row>
            <Col xs={6}>
              <UltimaAtualizacao modulo="8" />
            </Col>
            <Col xs={6}>
              <ExportButtonGroup
                id="btnExport"
                onClick={this.onExporta}
                visible={true}
              />
            </Col>
          </Row>

          <div className="panel-table-outer">
            <table className="panel-table striped fancy">
              <TableHeader empty={page.get('content').isEmpty()}>
                <tr>
                  <TableHeaderItem
                    field="placa"
                    label="Veículo"
                    page={page}
                    searchWithPage={this.searchWithPage}
                  />
                  <TableHeaderItem
                    field="dataSaida"
                    label="Data Saída"
                    page={page}
                    searchWithPage={this.searchWithPage}
                  />
                  <TableHeaderItem
                    field="horaSaida"
                    label="Horário Saída"
                    page={page}
                    searchWithPage={this.searchWithPage}
                  />
                  <TableHeaderItem
                    field="dataRetorno"
                    label="Data Retorno"
                    page={page}
                    searchWithPage={this.searchWithPage}
                  />
                  <TableHeaderItem
                    field="horaRetorno"
                    label="Horário Retorno"
                    page={page}
                    searchWithPage={this.searchWithPage}
                  />
                  <TableHeaderItem
                    field="destino"
                    label="Destino"
                    page={page}
                    searchWithPage={this.searchWithPage}
                  />
                  <th></th>
                </tr>
              </TableHeader>
              <tbody>
                {page.get('content').map(this.renderMovimentoGaragem)}
              </tbody>
            </table>
          </div>
        </Panel>

        <SearchPagination page={page} searchWithPage={this.searchWithPage} />
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    page: state.garagem.get('movimentos'),
    filtros: state.crud.getIn(
      ['filtroMovimentoGaragems', 'currentRecord'],
      Map({})
    )
  };
}

const allActions = {
  ...actions,
  onChange,
  resetCrud,
  addErrorNotification
};

export default connect(mapStateToProps, allActions)(Form);
