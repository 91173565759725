const tiposBem = new Map([
  ['M', 'Móvel'],
  ['I', 'Imóvel'],
  ['V', 'Veículo'],
  ['B', 'Bem Intangível'],
  ['T', 'Título e Valor'],
  ['S', 'Semovente']
]);

export const getLabelByTipo = tipo => {
  return tiposBem.get(tipo);
};
