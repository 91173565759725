import Component from 'react-pure-render/component';
import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

export default class WizardContentItem extends Component {
  static propTypes = {
    activeStep: PropTypes.number,
    stepNumber: PropTypes.number.isRequired,
    stepTitle: PropTypes.string.isRequired,
    children: PropTypes.node,
    handlePreviousClick: PropTypes.func,
    handleNextClick: PropTypes.func.isRequired,
    nextButtonLabel: PropTypes.string,
    onClickCancel: PropTypes.func
  };

  static defaultProps = {
    nextButtonLabel: 'Próximo'
  };

  render() {
    const {
      activeStep,
      stepNumber,
      stepTitle,
      children,
      handlePreviousClick,
      handleNextClick,
      nextButtonLabel,
      onClickCancel
    } = this.props;

    const classes = {
      step: true,
      [`step-${stepNumber}`]: true,
      active: stepNumber == activeStep
    };

    return (
      <li key={stepNumber} className={classnames(classes)}>
        <h3 className="center">
          <b>{stepTitle}</b>
        </h3>

        {children}

        <div className="mt-xs">
          <div className="btn-group center">
            {stepNumber > 1 && (
              <button
                className="btn btn-step-backward neutral icon-left"
                onClick={handlePreviousClick}
              >
                <em className="fa fa-chevron-left"></em>
                Anterior
              </button>
            )}
            {onClickCancel && (
              <button
                className="btn btn-step-backward negative icon-left"
                onClick={onClickCancel}
              >
                <em className="fa fa-times"></em>
                Cancelar
              </button>
            )}

            <button
              className="btn btn-step-forward module-color icon-right right"
              onClick={handleNextClick}
            >
              {nextButtonLabel}
              <em className="fa fa-chevron-right"></em>
            </button>
          </div>
        </div>
      </li>
    );
  }
}
