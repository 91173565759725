import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import TableHeaderItem from 'portaltransparencia-common/components/TableHeaderItem.react';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

import ServidorRow from './ServidorRow.react.js';

export default class ServidorTable extends Component {
  static propTypes = {
    usaWebService: PropTypes.bool.isRequired,
    page: PropTypes.object.isRequired,
    searchWithPage: PropTypes.func.isRequired
  };

  renderServidores = data => {
    const { usaWebService } = this.props;
    const key = `${data.get('entidade')}_${data.get('matricula')}`;

    return (
      <ServidorRow
        key={key}
        servidor={data}
        usaWebService={usaWebService}
        history={this.props.history}
      />
    );
  };

  render() {
    const { page, usaWebService, searchWithPage } = this.props;
    let servidores = page.get('content');

    return (
      <div className="panel-table-outer">
        <table className="panel-table striped fancy">
          <TableHeader
            empty={servidores.isEmpty()}
            text="Não foi encontrado nenhum servidor para a pesquisa selecionada."
          >
            <tr>
              <TableHeaderItem
                field="nome"
                label="Nome do Servidor"
                page={page}
                searchWithPage={searchWithPage}
              />
              <TableHeaderItem
                field="matricula"
                label="Matrícula"
                page={page}
                searchWithPage={searchWithPage}
              />
              <TableHeaderItem
                field="situacao"
                label="Situação"
                page={page}
                searchWithPage={searchWithPage}
              />
              {!usaWebService && (
                <TableHeaderItem
                  field="dataAdmissao"
                  label="Admissão"
                  page={page}
                  searchWithPage={searchWithPage}
                />
              )}
              {!usaWebService && (
                <TableHeaderItem
                  field="dataDemissao"
                  label="Desligamento"
                  page={page}
                  searchWithPage={searchWithPage}
                />
              )}
              <TableHeaderItem
                field="descricaoCargo"
                label="Cargo / Função"
                page={page}
                searchWithPage={searchWithPage}
              />
              {usaWebService && (
                <TableHeaderItem
                  field="vinculo"
                  label="Vínculo"
                  page={page}
                  searchWithPage={searchWithPage}
                />
              )}
              {!usaWebService && (
                <TableHeaderItem
                  field="descricaoClasse"
                  label="Classe"
                  page={page}
                  searchWithPage={searchWithPage}
                />
              )}
              {!usaWebService && (
                <TableHeaderItem
                  field="descricaoNatureza"
                  label="Natureza"
                  page={page}
                  searchWithPage={searchWithPage}
                />
              )}
              <TableHeaderItem
                field="descricaoLotacao"
                label="Lotação"
                page={page}
                searchWithPage={searchWithPage}
              />
              <th />
            </tr>
          </TableHeader>

          <tbody>{servidores.map(this.renderServidores)}</tbody>
        </table>
      </div>
    );
  }
}
