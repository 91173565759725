import * as types from './Types.js';

export function findQuadroPessoal(filtro) {
  return ({ fetch }) => ({
    type: types.QUADRO_PESSOAL_LIST,
    payload: {
      promise: fetch(`/api/quadro-pessoal?competencia=${filtro}`)
    }
  });
}

export function resetQuadroPessoal() {
  return {
    type: types.RESET_QUADRO_PESSOAL,
    payload: {}
  };
}
