import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export default class LinkArquivoLeiPortal extends Component {
  static propTypes = {
    arquivo: PropTypes.object,
    downloadArquivo: PropTypes.func.isRequired
  };

  openLeiESic = event => {
    event && event.preventDefault();
    const { downloadArquivo, arquivo } = this.props;
    downloadArquivo(arquivo.get('id'));
  };

  render() {
    const { arquivo } = this.props;

    return (
      <div>
        {arquivo && arquivo.get('nome') && (
          <a id="link-esic" href="#" onClick={this.openLeiESic} target="_blank">
            {arquivo.get('nome')}
          </a>
        )}
      </div>
    );
  }
}
