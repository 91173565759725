import DisplayData from 'portaltransparencia-common/components/displaydata/DisplayData.react.js';
import DisplayDataItem from 'portaltransparencia-common/components/displaydata/DisplayDataItem.react.js';
import DisplayDataRow from 'portaltransparencia-common/components/displaydata/DisplayDataRow.react.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import NumberUtils from 'portaltransparencia-common/libs/NumberUtils.js';
import PropTypes from 'prop-types';
import React from 'react';
import { Col } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { Link } from 'react-router-dom';

export class ServidorDetalheInformacao extends Component {
  static propTypes = {
    servidor: PropTypes.object.isRequired,
    dadosPensao: PropTypes.object,
    usaWebService: PropTypes.bool.isRequired,
    anotacoes: PropTypes.object.isRequired,
    exibeSalarioBase: PropTypes.bool.isRequired,
    horarioFlexivel: PropTypes.object.isRequired,
    uf: PropTypes.string
  };

  renderDadosPensao = () => {
    const { dadosPensao } = this.props;

    if (dadosPensao && dadosPensao.has('tipoBeneficio')) {
      return (
        <div>
          <DisplayDataRow>
            <DisplayDataItem
              sm={4}
              title="Tipo de Benefício"
              value={dadosPensao.get('tipoBeneficio')}
              id="tipoBeneficio"
            />
            <DisplayDataItem
              sm={5}
              title="Parentesco"
              value={dadosPensao.get('parentesco')}
              id="parentesco"
            />
            <DisplayDataItem
              sm={3}
              title="Parcela Percentual da Pensão"
              value={dadosPensao.get('percentualBeneficio')}
              id="percentualPensao"
            />
          </DisplayDataRow>

          <DisplayDataRow>
            <DisplayDataItem
              sm={6}
              title="Origem da Pensão"
              value={dadosPensao.get('origemPensao')}
              id="origemPensao"
            />
            <DisplayDataItem
              sm={6}
              title="Data de Óbito"
              value={DateUtils.formatDateTimeShort(
                dadosPensao.get('dataObito')
              )}
              id="dataObito"
            />
          </DisplayDataRow>

          <DisplayDataRow>
            <DisplayDataItem
              sm={6}
              title="Cargo da Origem da Pensão"
              value={dadosPensao.get('cargoOrigemPensao')}
              id="cargoOrigemPensao"
            />
            <DisplayDataItem
              sm={6}
              title="Entidade Origem"
              value={dadosPensao.get('entidadeOrigem')}
              id="entidadeOrigem"
            />
          </DisplayDataRow>
        </div>
      );
    }

    return null;
  };

  renderAnotacoes() {
    const { anotacoes } = this.props;

    if (anotacoes && !anotacoes.isEmpty()) {
      return (
        <DisplayDataRow>
          <div>
            <b className="dd-title">Anotações</b>
            <div className="col-sm-12 col-md-12">
              <div className="dd-content">
                <ul id="anotacoes">
                  {anotacoes.map((item, i) => (
                    <li key={i}>{item}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </DisplayDataRow>
      );
    }

    return null;
  }

  renderDadosCargo() {
    const { servidor, usaWebService, exibeSalarioBase } = this.props;

    if (!exibeSalarioBase || usaWebService) {
      return (
        <DisplayDataRow>
          <DisplayDataItem
            sm={8}
            title="Cargo"
            value={servidor.get('descricaoCargo')}
            id="descricaoCargo"
            show={servidor.has('descricaoCargo')}
          />
          <DisplayDataItem
            sm={4}
            title="Faixa"
            value={servidor.get('faixa')}
            id="faixa"
            show={servidor.has('faixa')}
          />
        </DisplayDataRow>
      );
    } else {
      return (
        <DisplayDataRow>
          <DisplayDataItem
            sm={5}
            title="Cargo"
            value={servidor.get('descricaoCargo')}
            id="descricaoCargo"
            show={servidor.has('descricaoCargo')}
          />
          <DisplayDataItem
            sm={3}
            title="Faixa"
            value={servidor.get('faixa')}
            id="faixa"
            show={servidor.has('faixa')}
          />
          <DisplayDataItem
            sm={4}
            title="Valor"
            value={NumberUtils.format(servidor.get('salarioBase'))}
            id="salarioBase"
            show={servidor.has('faixa') && servidor.has('salarioBase')}
          />
        </DisplayDataRow>
      );
    }
  }

  renderPeriodo = (inicio, fim) =>
    DateUtils.formatTime(inicio) + ' às ' + DateUtils.formatTime(fim);

  renderHorario = item => (
    <span>
      <b>{item.get('dia') + ': '}</b>
      {item.get('entrada1') &&
        this.renderPeriodo(item.get('entrada1'), item.get('saida1'))}
      {item.get('entrada2') &&
        ' ' + this.renderPeriodo(item.get('entrada2'), item.get('saida2'))}
      {item.get('entrada3') &&
        ' ' + this.renderPeriodo(item.get('entrada3'), item.get('saida3'))}
      {item.get('entrada4') &&
        ' ' + this.renderPeriodo(item.get('entrada4'), item.get('saida4'))}{' '}
    </span>
  );

  renderHorarioFlexivel() {
    const { horarioFlexivel } = this.props;

    return (
      <Col sm={8}>
        <b className="dd-title">Horário de Trabalho</b>
        <div className="dd-content" id={'horarioTrabalhoFlexivel'}>
          {horarioFlexivel.map(this.renderHorario)}
        </div>
      </Col>
    );
  }

  render() {
    const { servidor, usaWebService, horarioFlexivel, uf } = this.props;
    const exibirHorarioFlexivel = horarioFlexivel && horarioFlexivel.length > 0;
    if (!servidor) {
      return null;
    }

    let ato;
    if (servidor.get('atoId')) {
      ato = `entidade=${servidor.get('atoEntidade')}&exercicio=${servidor.get(
        'atoExercicio'
      )}&id=${servidor.get('atoId')}&sistema=${servidor.get('atoSistema')}`;
    }

    return (
      <section>
        <DisplayData border>
          <DisplayDataRow>
            <DisplayDataItem
              sm={5}
              title="Nome"
              value={servidor.get('nome')}
              id="nome"
            />

            <DisplayDataItem
              sm={3}
              title="Matrícula"
              value={servidor.get('matricula')}
              id="matricula"
            />

            <DisplayDataItem
              sm={4}
              title="Situação"
              value={servidor.get('situacao')}
              id="situacao"
            />
          </DisplayDataRow>

          <DisplayDataRow>
            <DisplayDataItem
              sm={12}
              title="Lotação"
              value={servidor.get('descricaoLotacao')}
              id="descricaoLotacao"
            />
          </DisplayDataRow>

          <DisplayDataRow>
            <DisplayDataItem
              sm={4}
              title="Classe"
              value={servidor.get('descricaoClasse')}
              id="descricaoClasse"
              show={!usaWebService && servidor.has('descricaoClasse')}
            />
            <DisplayDataItem
              sm={4}
              title="Natureza"
              value={servidor.get('descricaoNatureza')}
              id="descricaoNatureza"
              show={!usaWebService && servidor.has('descricaoNatureza')}
            />
            <DisplayDataItem
              sm={4}
              title="Forma de Investidura"
              value={servidor.get('formaInvestidura')}
              id="formaInvestidura"
              show={!usaWebService && servidor.has('formaInvestidura')}
            />
          </DisplayDataRow>

          <DisplayDataRow>
            <DisplayDataItem
              sm={12}
              title="Vínculo"
              value={servidor.get('vinculo')}
              id="vinculo"
              show={usaWebService}
            />
          </DisplayDataRow>

          <DisplayDataRow>
            <DisplayDataItem
              sm={12}
              title="Nomeação/Função"
              value={servidor.get('nomeacao')}
              id="nomeacao"
              show={servidor.has('nomeacao')}
            />
          </DisplayDataRow>

          <DisplayDataRow>
            <DisplayDataItem
              sm={6}
              title="Cedido de"
              value={servidor.get('cedido')}
              id="cedido"
              show={servidor.has('cedido')}
            />
            <DisplayDataItem
              sm={6}
              title="Cedido para"
              value={servidor.get('cedidoPara')}
              id="cedidoPara"
              show={servidor.has('cedidoPara')}
            />
          </DisplayDataRow>

          <DisplayDataRow>
            <DisplayDataItem
              sm={4}
              title="Admissão"
              value={DateUtils.formatDateTimeShort(
                servidor.get('dataAdmissao')
              )}
              id="dataAdmissao"
              show={
                (uf != 'RO' ||
                  (servidor.get('definicaoClasse') != 8 &&
                    servidor.get('definicaoClasse') != 9)) &&
                servidor.has('dataAdmissao')
              }
            />

            <DisplayDataItem
              sm={4}
              title="Admissão de Ativo"
              value={DateUtils.formatDateTimeShort(
                servidor.get('dataAdmissaoAtivo')
              )}
              id="dataAdmissaoAtivo"
              show={
                uf == 'RO' &&
                (servidor.get('definicaoClasse') == 8 ||
                  servidor.get('definicaoClasse') == 9)
              }
            />

            <DisplayDataItem
              sm={4}
              title="Data de Concessão do Benefício"
              value={DateUtils.formatDateTimeShort(
                servidor.get('dataConcessaoBeneficio')
              )}
              id="dataConcessaoBeneficio"
              show={
                uf == 'RO' &&
                (servidor.get('definicaoClasse') == 8 ||
                  servidor.get('definicaoClasse') == 9)
              }
            />

            <DisplayDataItem
              sm={4}
              title="Desligamento"
              value={DateUtils.formatDateTimeShort(
                servidor.get('dataDemissao')
              )}
              id="dataDemissao"
              show={servidor.has('dataDemissao')}
            />
            <DisplayDataItem
              sm={4}
              title="Final do Contrato"
              value={DateUtils.formatDateTimeShort(
                servidor.get('dataFimContrato')
              )}
              id="dataFimContrato"
              show={servidor.has('dataFimContrato')}
            />
            <DisplayDataItem
              sm={4}
              title="Data Transposição"
              value={DateUtils.formatDateTimeShort(
                servidor.get('dataTransposicao')
              )}
              id="dataTransposicao"
              show={servidor.has('dataTransposicao')}
            />
          </DisplayDataRow>

          {(!servidor.has('exibeCargaHoraria') ||
            usaWebService ||
            servidor.get('exibeCargaHoraria') == 'S') && (
            <DisplayDataRow>
              <DisplayDataItem
                sm={4}
                title="Local de Trabalho"
                value={servidor.get('localTrabalho')}
                id="localTrabalho"
                show={servidor.has('localTrabalho')}
              />
              <DisplayDataItem
                sm={4}
                title="Horário de Trabalho"
                value={servidor.get('horarioTrabalho')}
                id="horarioTrabalho"
                show={!exibirHorarioFlexivel && servidor.has('horarioTrabalho')}
              />
              {exibirHorarioFlexivel && this.renderHorarioFlexivel()}
              <DisplayDataItem
                sm={4}
                title="Horas Semanais"
                value={servidor.get('horasSemanais')}
                id="horasSemanais"
                show={servidor.has('horasSemanais')}
              />
            </DisplayDataRow>
          )}

          <DisplayDataRow>
            <DisplayDataItem
              sm={12}
              title="Forma de Trabalho"
              value={servidor.get('formaTrabalho')}
              id="formaTrabalho"
              show={uf == 'RO'}
            />
          </DisplayDataRow>

          {this.renderDadosCargo()}

          {this.renderAnotacoes()}

          <DisplayDataRow>
            {ato && (
              <Col sm={4}>
                <b className="dd-title">Ato de Nomeação</b>
                <div className="dd-content" id="atoNomeacao">
                  <Link to={`/leiseatos-pessoal/detalhes?${ato}`}>
                    Visualizar
                  </Link>
                </div>
              </Col>
            )}
          </DisplayDataRow>

          {this.renderDadosPensao()}
        </DisplayData>
      </section>
    );
  }
}

export default ServidorDetalheInformacao;
