export const SERVIDORES_OXY_LIST = 'SERVIDORES_OXY_LIST';
export const SERVIDORES_OXY_LIST_START = 'SERVIDORES_OXY_LIST_START';
export const SERVIDORES_OXY_LIST_SUCCESS = 'SERVIDORES_OXY_LIST_SUCCESS';
export const SERVIDORES_OXY_LIST_ERROR = 'SERVIDORES_OXY_LIST_ERROR';

export const SERVIDOR_OXY_DETALHE = 'SERVIDOR_OXY_DETALHE';
export const SERVIDOR_OXY_DETALHE_START = 'SERVIDOR_OXY_DETALHE_START';
export const SERVIDOR_OXY_DETALHE_SUCCESS = 'SERVIDOR_OXY_DETALHE_SUCCESS';
export const SERVIDOR_OXY_DETALHE_ERROR = 'SERVIDOR_OXY_DETALHE_ERROR';

export const SERVIDOR_OXY_DETALHE_PENSAO = 'SERVIDOR_OXY_DETALHE_PENSAO';
export const SERVIDOR_OXY_DETALHE_PENSAO_START =
  'SERVIDOR_OXY_DETALHE_PENSAO_START';
export const SERVIDOR_OXY_DETALHE_PENSAO_SUCCESS =
  'SERVIDOR_OXY_DETALHE_PENSAO_SUCCESS';
export const SERVIDOR_OXY_DETALHE_PENSAO_ERROR =
  'SERVIDOR_OXY_DETALHE_PENSAO_ERROR';

export const SERVIDOR_OXY_VENCIMENTOS_DESCONTOS =
  'SERVIDOR_OXY_VENCIMENTOS_DESCONTOS';
export const SERVIDOR_OXY_VENCIMENTOS_DESCONTOS_START =
  'SERVIDOR_OXY_VENCIMENTOS_DESCONTOS_START';
export const SERVIDOR_OXY_VENCIMENTOS_DESCONTOS_SUCCESS =
  'SERVIDOR_OXY_VENCIMENTOS_DESCONTOS_SUCCESS';
export const SERVIDOR_OXY_VENCIMENTOS_DESCONTOS_ERROR =
  'SERVIDOR_OXY_VENCIMENTOS_DESCONTOS_ERROR';

export const NATUREZAS_OXY_LIST = 'NATUREZAS_OXY_LIST';
export const NATUREZAS_OXY_LIST_START = 'NATUREZAS_OXY_LIST_START';
export const NATUREZAS_OXY_LIST_SUCCESS = 'NATUREZAS_OXY_LIST_SUCCESS';
export const NATUREZAS_OXY_LIST_ERROR = 'NATUREZAS_OXY_LIST_ERROR';

export const SERVIDOR_OXY_REGISTROS_PONTOS = 'SERVIDOR_OXY_REGISTROS_PONTOS';
export const SERVIDOR_OXY_REGISTROS_PONTOS_START =
  'SERVIDOR_OXY_REGISTROS_PONTOS_START';
export const SERVIDOR_OXY_REGISTROS_PONTOS_SUCCESS =
  'SERVIDOR_OXY_REGISTROS_PONTOS_SUCCESS';
export const SERVIDOR_OXY_REGISTROS_PONTOS_ERROR =
  'SERVIDOR_OXY_REGISTROS_PONTOS_ERROR';

export const SITUACOES_OXY_LIST = 'SITUACOES_OXY_LIST';
export const SITUACOES_OXY_LIST_START = 'SITUACOES_OXY_LIST_START';
export const SITUACOES_OXY_LIST_SUCCESS = 'SITUACOES_OXY_LIST_SUCCESS';
export const SITUACOES_OXY_LIST_ERROR = 'SITUACOES_OXY_LIST_ERROR';

export const CLEAR_OXY_DETALHE = 'CLEAR_OXY_DETALHE';

export const SERVIDORES_OXY_RESUMO = 'SERVIDORES_OXY_RESUMO';
export const SERVIDORES_OXY_RESUMO_START = 'SERVIDORES_OXY_RESUMO_START';
export const SERVIDORES_OXY_RESUMO_SUCCESS = 'SERVIDORES_OXY_RESUMO_SUCCESS';
export const SERVIDORES_OXY_RESUMO_ERROR = 'SERVIDORES_OXY_RESUMO_ERROR';

export const ESTAGIARIOS_OXY_LIST = 'ESTAGIARIOS_OXY_LIST';
export const ESTAGIARIOS_OXY_LIST_START = 'ESTAGIARIOS_OXY_LIST_START';
export const ESTAGIARIOS_OXY_LIST_SUCCESS = 'ESTAGIARIOS_OXY_LIST_SUCCESS';
export const ESTAGIARIOS_OXY_LIST_ERROR = 'ESTAGIARIOS_OXY_LIST_ERROR';

export const ESTAGIARIO_OXY_DETALHE = 'ESTAGIARIO_OXY_DETALHE';
export const ESTAGIARIO_OXY_DETALHE_START = 'ESTAGIARIO_OXY_DETALHE_START';
export const ESTAGIARIO_OXY_DETALHE_SUCCESS = 'ESTAGIARIO_OXY_DETALHE_SUCCESS';
export const ESTAGIARIO_OXY_DETALHE_ERROR = 'ESTAGIARIO_OXY_DETALHE_ERROR';

export const INATIVOS_LIST = 'INATIVOS_LIST';
export const INATIVOS_LIST_START = 'INATIVOS_LIST_START';
export const INATIVOS_LIST_SUCCESS = 'INATIVOS_LIST_SUCCESS';
export const INATIVOS_LIST_ERROR = 'INATIVOS_LIST_ERROR';

export const INATIVO_DETALHE = 'INATIVO_DETALHE';
export const INATIVO_DETALHE_START = 'INATIVO_DETALHE_START';
export const INATIVO_DETALHE_SUCCESS = 'INATIVO_DETALHE_SUCCESS';
export const INATIVO_DETALHE_ERROR = 'INATIVO_DETALHE_ERROR';

export const PENSIONISTAS_LIST = 'PENSIONISTAS_LIST';
export const PENSIONISTAS_LIST_START = 'PENSIONISTAS_LIST_START';
export const PENSIONISTAS_LIST_SUCCESS = 'PENSIONISTAS_LIST_SUCCESS';
export const PENSIONISTAS_LIST_ERROR = 'PENSIONISTAS_LIST_ERROR';

export const PENSIONISTA_DETALHE = 'PENSIONISTA_DETALHE';
export const PENSIONISTA_DETALHE_START = 'PENSIONISTA_DETALHE_START';
export const PENSIONISTA_DETALHE_SUCCESS = 'PENSIONISTA_DETALHE_SUCCESS';
export const PENSIONISTA_DETALHE_ERROR = 'PENSIONISTA_DETALHE_ERROR';

export const AUTONOMOS_LIST = 'AUTONOMOS_LIST';
export const AUTONOMOS_LIST_START = 'AUTONOMOS_LIST_START';
export const AUTONOMOS_LIST_SUCCESS = 'AUTONOMOS_LIST_SUCCESS';
export const AUTONOMOS_LIST_ERROR = 'AUTONOMOS_LIST_ERROR';

export const AUTONOMO_DETALHE = 'AUTONOMO_DETALHE';
export const AUTONOMO_DETALHE_START = 'AUTONOMO_DETALHE_START';
export const AUTONOMO_DETALHE_SUCCESS = 'AUTONOMO_DETALHE_SUCCESS';
export const AUTONOMO_DETALHE_ERROR = 'AUTONOMO_DETALHE_ERROR';
