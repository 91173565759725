import DisplayData from 'portaltransparencia-common/components/displaydata/DisplayData.react.js';
import DisplayDataItem from 'portaltransparencia-common/components/displaydata/DisplayDataItem.react.js';
import DisplayDataRow from 'portaltransparencia-common/components/displaydata/DisplayDataRow.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import { getAllValuesFromUrlSearchParams } from 'portaltransparencia-common/libs/URLUtils';
import { PARAM_DESPESAS_PAGAS_VALOR } from 'portaltransparencia-common/parametros/ParametrosPortalTransparencia';
import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import PageHeader from '../components/PageHeader.react.js';
import CabecalhoEntidade from '../entidades/CabecalhoEntidade.react.js';
import ParametroService from '../parametros/ParametroService.js';
import * as actions from './Actions.js';
import Abas from './FormDetalheAbas.react.js';
import Cabecalho from './FormDetalheCabecalho.react.js';
import Despesa from './FormDetalheDespesa.react.js';
import Fornecedor from './FormDetalheFornecedor.react.js';
import Valores from './FormDetalheValores.react.js';

class EmpenhoDetalhe extends Component {
  static propTypes = {
    empenhoDetalhe: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    findEmpenhoDetalhe: PropTypes.func.isRequired,
    findEmpenhoItens: PropTypes.func.isRequired,
    itens: PropTypes.object.isRequired,
    findEmpenhoAnulacoes: PropTypes.func.isRequired,
    anulacoes: PropTypes.object.isRequired,
    findEmpenhoLiquidacoes: PropTypes.func.isRequired,
    liquidacoes: PropTypes.object.isRequired,
    findEmpenhoRetencoes: PropTypes.func.isRequired,
    retencoes: PropTypes.object.isRequired,
    findEmpenhoLiquidacoesDocumentos: PropTypes.func.isRequired,
    liquidacoesDocumentos: PropTypes.object.isRequired,
    findEmpenhoPagamentos: PropTypes.func.isRequired,
    pagamentos: PropTypes.object.isRequired,
    findEmpenhoMovimentacao: PropTypes.func.isRequired,
    movimentacao: PropTypes.object.isRequired,
    findEmpenhoAnexos: PropTypes.func.isRequired,
    anexos: PropTypes.object.isRequired,
    findEmpenhoEmLiquidacao: PropTypes.func.isRequired,
    findNotaEmpenho: PropTypes.func.isRequired,
    emLiquidacao: PropTypes.object.isRequired,
    paramDespesasPagasValor: PropTypes.string,
    findEmpenhoLinks: PropTypes.func.isRequired,
    links: PropTypes.object.isRequired
  };

  onClickImprimir = () => {
    const params = getAllValuesFromUrlSearchParams(this.props.location);

    let filtro =
      'entidade=' +
      params.entidade +
      '&exercicio=' +
      params.exercicio +
      '&empenho=' +
      params.empenho +
      '&unidadeOrcamentaria=0101';

    this.props.findNotaEmpenho(filtro).then(response => {
      response.value
        .blob()
        .then(blob => window.open(URL.createObjectURL(blob), '_blank'));
    });
  };

  componentDidMount() {
    const {
      findEmpenhoDetalhe,
      findEmpenhoItens,
      findEmpenhoAnulacoes,
      findEmpenhoLiquidacoes,
      findEmpenhoPagamentos,
      findEmpenhoLiquidacoesDocumentos,
      findEmpenhoMovimentacao,
      findEmpenhoAnexos,
      findEmpenhoRetencoes,
      findEmpenhoEmLiquidacao,
      findEmpenhoLinks,
      location,
      resetLiquidacoesMultipla,
      resetLiquidacoesDocumentosMultiplos
    } = this.props;
    const params = getAllValuesFromUrlSearchParams(location);

    let filtro =
      '?search=id.entidade==' +
      params.entidade +
      '&entidade=' +
      params.entidade +
      '&exercicio=' +
      params.exercicio +
      '&empenho=' +
      params.empenho;

    resetLiquidacoesMultipla();
    resetLiquidacoesDocumentosMultiplos();
    findEmpenhoDetalhe(filtro)
      .then(findEmpenhoItens(filtro))
      .then(findEmpenhoAnulacoes(filtro))
      .then(findEmpenhoLiquidacoes(filtro))
      .then(findEmpenhoRetencoes(filtro))
      .then(findEmpenhoPagamentos(filtro))
      .then(findEmpenhoLiquidacoesDocumentos(filtro))
      .then(findEmpenhoMovimentacao(filtro))
      .then(findEmpenhoAnexos(filtro))
      .then(findEmpenhoEmLiquidacao(filtro))
      .then(findEmpenhoLinks(filtro));
  }

  render() {
    const { empenhoDetalhe, location, history } = this.props;

    return (
      <section>
        <Helmet
          title={`Empenho - #${empenhoDetalhe.get(
            'empenho'
          )} ${empenhoDetalhe.get('nome')}`}
        />

        <CabecalhoEntidade />

        <PageHeader
          location={location}
          textoDetalhe={`Empenho: ${empenhoDetalhe.get(
            'empenho'
          )} / ${empenhoDetalhe.get('exercicio')} - ${empenhoDetalhe.get(
            'nome'
          )}`}
        />

        <div className="btn-save">
          <button
            className="btn other fa fa-print"
            title="Imprimir"
            type="button"
            onClick={this.onClickImprimir}
          ></button>
        </div>

        <Panel expansible={false}>
          <h3 className="mb-xs">
            <b className="module-color">Empenho</b>
          </h3>

          <Cabecalho empenho={empenhoDetalhe} history={history} />

          <hr className="mt-xs mb-xs" />

          <h3 className="mb-xs">
            <b className="module-color">Fornecedor</b>
          </h3>
          <Fornecedor empenho={empenhoDetalhe} />

          <hr className="mt-xs mb-xs" />

          <h3 className="mb-xs">
            <b className="module-color">Programática</b>
          </h3>

          <DisplayData border>
            <DisplayDataRow>
              <DisplayDataItem
                sm={7}
                title="Programática"
                value={empenhoDetalhe.get('programatica')}
              />
              <DisplayDataItem
                sm={5}
                title="Fonte de Recurso"
                value={empenhoDetalhe.get('fonteRecurso')}
                value2={empenhoDetalhe.get('descricaoFonte')}
              />
            </DisplayDataRow>
            {empenhoDetalhe.get('codigoAplicacao') && (
              <DisplayDataRow>
                <DisplayDataItem
                  sm={12}
                  title="Aplicação"
                  value={`${empenhoDetalhe.get(
                    'codigoAplicacao'
                  )} - ${empenhoDetalhe.get('descricaoAplicacao')}`}
                />
              </DisplayDataRow>
            )}
          </DisplayData>

          <div className="panel-inside-panel mt-xs">
            <Panel
              insidePanel
              title="Detalhes da Programática"
              isTable
              tableResponsive
            >
              <Despesa empenho={empenhoDetalhe} />
            </Panel>
          </div>

          <hr className="mt-xs mb-xs" />

          <h3 className="mb-xs">
            <b className="module-color">Valores</b>
          </h3>
          <Valores
            empenho={empenhoDetalhe}
            paramDespesasPagasValor={this.props.paramDespesasPagasValor}
          />

          <hr className="mt-xs mb-xs" />

          <h3 className="mt-xs mb-xs">
            <b className="module-color">Justificativa / Histórico</b>
          </h3>

          <DisplayData border>
            <DisplayDataRow>
              <div>
                <pre
                  style={{
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-word'
                  }}
                >
                  {empenhoDetalhe.get('historico')}
                </pre>
              </div>
            </DisplayDataRow>
          </DisplayData>
          <hr className="mt-xs mb-xs" />

          <Abas empenho={empenhoDetalhe} location={location} />
        </Panel>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    empenhoDetalhe: state.empenhos.get('empenhoDetalhe'),
    itens: state.empenhos.get('itens'),
    anulacoes: state.empenhos.get('anulacoes'),
    liquidacoes: state.empenhos.get('liquidacoes'),
    retencoes: state.empenhos.get('retencoes'),
    pagamentos: state.empenhos.get('pagamentos'),
    liquidacoesDocumentos: state.empenhos.get('liquidacoesDocumentos'),
    movimentacao: state.empenhos.get('movimentacao'),
    anexos: state.empenhos.get('anexos'),
    emLiquidacao: state.empenhos.get('emLiquidacao'),
    links: state.empenhos.get('links'),
    paramDespesasPagasValor: ParametroService.getValorParametro(
      state,
      PARAM_DESPESAS_PAGAS_VALOR
    )
  };
}

const allActions = {
  ...actions
};

export default connect(mapStateToProps, allActions)(EmpenhoDetalhe);
