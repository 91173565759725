import { Map } from 'immutable';
import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react.js';
import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export default class Estoque extends Component {
  static propTypes = {
    itensEstoque: PropTypes.instanceOf(Map).isRequired,
    onView: PropTypes.func
  };

  render() {
    const { itensEstoque, onView } = this.props;

    return (
      <TableActions
        key={itensEstoque.get('item')}
        data={itensEstoque}
        onMore={onView}
      >
        <td>{itensEstoque.get('item')}</td>
        <td>{itensEstoque.get('itemDescricao')}</td>
        <td>{itensEstoque.get('unidade')}</td>
        <td>{itensEstoque.get('centroCusto')}</td>
        <td>{itensEstoque.get('centroCustoDescricao')}</td>
        <td>
          <div className="right">
            <NumberFormatter value={itensEstoque.get('saldo')} />
          </div>
        </td>
        <td>
          <div className="right">
            <NumberFormatter value={itensEstoque.get('custoUnitario')} />
          </div>
        </td>
        <td>
          <div className="right">
            <NumberFormatter value={itensEstoque.get('valorTotal')} />
          </div>
        </td>
      </TableActions>
    );
  }
}
