import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';

import * as types from './Types.js';

const initialState = fromJS({
  atendimento: [],
  contatos: [],
  parametrosRedesSociais: [],
  configAparencia: []
});

function atendimentoReducer(state = initialState, action) {
  switch (action.type) {
    case types.ATENDIMENTO_START:
    case types.PARAMS_START:
    case types.CONTATOS_START:
    case types.CONFIG_APARENCIA_START: {
      return startLoad(state);
    }

    case types.ATENDIMENTO_SUCCESS: {
      return endLoad(state.set('atendimento', fromJS(action.payload)));
    }

    case types.CONTATOS_SUCCESS: {
      return endLoad(state.set('contatos', fromJS(action.payload)));
    }

    case types.PARAMS_SUCCESS: {
      return endLoad(
        state.set('parametrosRedesSociais', fromJS(action.payload))
      );
    }

    case types.CONFIG_APARENCIA_SUCCESS: {
      return endLoad(state.set('configAparencia', fromJS(action.payload)));
    }

    case types.ATENDIMENTO_ERROR:
    case types.PARAMS_ERROR:
    case types.CONTATOS_ERROR:
    case types.CONFIG_APARENCIA_ERROR: {
      return endLoad(state);
    }
  }

  return state;
}

export default atendimentoReducer;
