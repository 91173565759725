import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import NumberUtils from 'portaltransparencia-common/libs/NumberUtils.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export default class AjudaCustoRow extends Component {
  static propTypes = {
    ajudaCusto: PropTypes.object
  };

  generateKey = ajudaCusto => {
    return `${ajudaCusto.get('anoCompetencia')}_
            ${ajudaCusto.get('mesCompetencia')}_
            ${ajudaCusto.get('competenciaLancamento')}_
            ${ajudaCusto.get('evento')}`;
  };

  render() {
    const { ajudaCusto } = this.props;

    if (!ajudaCusto) {
      return null;
    }

    return (
      <tr ref="row" key={this.generateKey(ajudaCusto)} data={ajudaCusto}>
        <td>{ajudaCusto.get('anoCompetencia')}</td>
        <td>
          {DateUtils.getMonthDescription(ajudaCusto.get('mesCompetencia'))}{' '}
        </td>
        <td>{DateUtils.transform(ajudaCusto.get('competenciaLancamento'))} </td>
        <td>{ajudaCusto.get('matricula')}</td>
        <td>{NumberUtils.format(ajudaCusto.get('valor'))}</td>
        <td>{ajudaCusto.get('eventoDescricao')}</td>
      </tr>
    );
  }
}
