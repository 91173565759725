import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react.js';
import SumListField from 'portaltransparencia-common/components/SumListField.react.js';
import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import * as actions from './Actions.js';

export class EmpenhoDetalheItens extends Component {
  static propTypes = {
    itens: PropTypes.object.isRequired
  };

  onViewDetalhes = data => {
    const { entidade, exercicio } = StorageService.getItem('userInfo');
    const empenho = data.get('empenho');
    const ordemItemEmpenho = data.get('ordemNadItemPerc');
    this.props.history.push(
      `/empenhos/item/detalhe?entidade=${entidade.id}&exercicio=${exercicio.id}&empenho=${empenho}&ordemItemEmpenho=${ordemItemEmpenho}`
    );
  };

  renderItens = data => {
    return (
      <TableActions
        ref="row"
        key={data.get('sequencia')}
        data={data}
        onMore={data.get('itensPerc').size > 0 && this.onViewDetalhes}
      >
        <td id="sequencia">{data.get('sequencia')}</td>
        <td id="especificacao">{data.get('especificacao')}</td>
        <td id="quantidade" className="right">
          <NumberFormatter value={data.get('quantidade')} format="0,0.0000" />
        </td>
        <td id="unidade">{data.get('unidade')}</td>
        <td id="valorUnitario" className="right">
          <NumberFormatter
            value={data.get('valorUnitario')}
            format="0,0.0000"
          />
        </td>
        <td id="valorTotal" className="right">
          <NumberFormatter value={data.get('valorTotal')} />
        </td>
      </TableActions>
    );
  };

  render() {
    const { itens } = this.props;
    let colDetalheTitulo = '';

    itens.forEach(item => {
      if (item.get('itensPerc') && item.get('itensPerc').size > 0) {
        colDetalheTitulo = 'Detalhe';
      }
    });

    return (
      <div className="panel-table-outer">
        <table className="panel-table striped fancy">
          <TableHeader empty={itens.isEmpty()}>
            <tr>
              <th>Sequencia</th>
              <th>Especificação</th>
              <th className="right">Quantidade</th>
              <th>Unidade</th>
              <th className="right">Valor Unitário</th>
              <th className="right">Valor Total</th>
              <th className="right">{colDetalheTitulo}</th>
            </tr>
          </TableHeader>
          <tbody>{itens.map(this.renderItens)}</tbody>

          {!itens.isEmpty() && (
            <tfoot>
              <tr>
                <td></td>
                <td></td>
                <td className="right">
                  <i>Total: </i>
                  {
                    <SumListField
                      fieldName="quantidade"
                      list={itens}
                      format="0,0.0000"
                    />
                  }
                </td>
                <td></td>
                <td className="right">
                  <i>Total: </i>
                  {
                    <SumListField
                      fieldName="valorUnitario"
                      list={itens}
                      format="0,0.0000"
                    />
                  }
                </td>
                <td className="right">
                  <i>Total: </i>
                  {<SumListField fieldName="valorTotal" list={itens} />}
                </td>
                <td></td>
              </tr>
            </tfoot>
          )}
        </table>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    itens: state.empenhos.get('itens')
  };
}

const allActions = {
  ...actions
};

export default connect(
  mapStateToProps,
  allActions
)(withRouter(EmpenhoDetalheItens));
