import PropTypes from 'prop-types';
import React from 'react';
import { Panel } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';
import * as actions from './Actions.js';

export class NotaExplicativaModal extends Component {
  static propTypes = {
    modalId: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
    nota: PropTypes.object.isRequired,
    show: PropTypes.bool.isRequired
  };

  render() {
    const { show, onClose, nota, modalId } = this.props;

    if (show) {
      return (
        <div className="panel">
          <div className="panel-body">
            <div className="modal open">
              <div className="container" style={{ marginTop: '54px' }}>
                <span
                  className="close close-modal"
                  onClick={() => onClose(modalId)}
                ></span>
                <div>
                  <Panel title={nota.get('titulo')} expansible={false}>
                    <div key={nota.get('id')} style={{ marginTop: '5px' }}>
                      <div
                        className="form-group"
                        style={{
                          marginTop: '5px',
                          marginBottom: '10px',
                          cursor: 'pointer',
                          padding: '11px 6px 6px 5px'
                        }}
                      ></div>
                      <div>
                        {nota.get('imgPath') && (
                          <div className="form-group">
                            <div
                              style={{
                                minWidth: '300px',
                                minHeight: '300px',
                                border: '1px solid #ccc',
                                padding: '10px',
                                display: 'ruby-text'
                              }}
                            >
                              <a
                                href={nota.get('link') ? nota.get('link') : '#'}
                                target={nota.get('link') ? '_blank' : ''}
                                style={{
                                  cursor: nota.get('link')
                                    ? 'pointer'
                                    : 'default',
                                  display: 'block'
                                }}
                              >
                                <img src={nota.get('imgPath')} />
                              </a>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </Panel>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return null;
  }
}
function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, actions)(NotaExplicativaModal);
