import Input from 'portaltransparencia-common/components/EloInput.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import FilterButtons from '../components/FilterButtons.react';
import PageHeader from '../components/PageHeader.react.js';
import * as actions from './Actions.js';

export class FormFilaEsperaAtendimento extends Component {
  static propTypes = {
    filas: PropTypes.object.isRequired,
    findFilasEspera: PropTypes.func.isRequired,
    findFilasEsperaCns: PropTypes.func.isRequired,
    clearRegistros: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired
  };

  componentDidMount() {
    const { findFilasEspera } = this.props;
    findFilasEspera();
  }

  componentWillUnmount() {
    const { clearRegistros } = this.props;
    clearRegistros();
  }

  onPesquisa = () => {
    const { cns: filtro } = this.refs;
    const { findFilasEsperaCns } = this.props;
    const { clearRegistros } = this.props;

    if (filtro.getValue()) {
      findFilasEsperaCns(filtro.getValue());
      return;
    }
    clearRegistros();
    this.refs.cns = '';
  };

  onReset = () => {
    const { findFilasEspera } = this.props;
    findFilasEspera();
  };

  onViewDetalhes = item => {
    const idFila = item.get('f_fila_codigo');
    const nomeFila = item.get('f_fila_nome');
    let url = `/fila-espera-atendimento/${idFila}?fila=${nomeFila}`;
    this.props.history.push(url);
  };

  renderListas = fila => {
    return (
      <TableActions
        ref="row"
        key={fila.get('f_fila_codigo')}
        data={fila}
        onMore={this.onViewDetalhes}
      >
        <td>{fila.get('f_fila_nome')}</td>
      </TableActions>
    );
  };

  render() {
    const { filas } = this.props;

    if (!filas) {
      return null;
    }

    return (
      <section>
        <PageHeader location={this.props.location} />

        <Panel title="Consultar em Filas" mobileClose isForm>
          <Row>
            <Col xs={12} sm={4} md={8}>
              <Input
                id="cns"
                name="cns"
                ref="cns"
                label="Cidadão CNS (Cartão Nacional de Saúde)"
                placeholder="Pesquisar Filas"
                onKeyDown={this.handleKeyDown}
                type="text"
              />
            </Col>
            <Col xs={12} sm={4} md={4}>
              <FilterButtons
                idBtnReset="btnResetFilter"
                onConsulta={this.onPesquisa}
                onReset={this.onReset}
              />
            </Col>
          </Row>
        </Panel>
        <Panel
          title="Filas de Espera de atendimento"
          isForm
          expansible={false}
          tableResponsive
        >
          <div className="panel-table-outer">
            <table className="panel-table striped fancy">
              <TableHeader empty={filas.isEmpty()}>
                <tr>
                  <th>Descrição</th>
                  <th />
                </tr>
              </TableHeader>
              <tbody>{filas.map(this.renderListas)}</tbody>
            </table>
          </div>
        </Panel>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    filas: state.filaEsperaAtendimento.get('filas')
  };
}

export default connect(mapStateToProps, actions)(FormFilaEsperaAtendimento);
