import { Map } from 'immutable';
import DatePicker from 'portaltransparencia-common/components/DatePicker.react.js';
import Input from 'portaltransparencia-common/components/EloInput.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import SearchPagination from 'portaltransparencia-common/components/SearchPagination.react.js';
import {
  onChange,
  resetCrud
} from 'portaltransparencia-common/crud/Actions.js';
import {
  addFilterUrl,
  addPagination
} from 'portaltransparencia-common/libs/AddFilter.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import LiquidacoesListagem from './../liquidacoes/LiquidacoesListagem.react.js';
import FilterButtons from '../components/FilterButtons.react';
import PageHeader from '../components/PageHeader.react.js';
import NotasExplicativas from '../notaexplicativa/Form.react';
import UltimaAtualizacaoByTable from '../ultimaatualizacao/UltimaAtualizacaoByTable.react.js';
import * as actions from './Actions.js';
import configuration from 'portaltransparencia-common/config/Config.js';
import { addErrorNotification } from 'portaltransparencia-common/ui/Actions.js';

export class FormAptosPagamentos extends Component {
  static propTypes = {
    page: PropTypes.object.isRequired,
    findAptosPagamentos: PropTypes.func.isRequired,
    findAptosPagamentosTotal: PropTypes.func.isRequired,
    total: PropTypes.object.isRequired,

    filtros: PropTypes.object,
    onChange: PropTypes.func,
    location: PropTypes.object.isRequired,
    resetCrud: PropTypes.func.isRequired,
    addErrorNotification: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.loadInitialFiltros();
  }

  loadInitialFiltros = () => {
    const {
      onChange,
      page,
      findAptosPagamentos,
      findAptosPagamentosTotal
    } = this.props;
    const { exercicio } = StorageService.getItem('userInfo');
    let filter = this.montarFiltro(page);

    onChange('filtroAptoPagamento', {
      name: 'dataInicial',
      value: `${exercicio.id}-01-01`
    });

    onChange('filtroAptoPagamento', {
      name: 'dataFinal',
      value: `${exercicio.id}-12-31`
    });

    filter = addFilterUrl(filter, `dataInicial=${`${exercicio.id}-01-01`}`);
    filter = addFilterUrl(filter, `dataFinal=${`${exercicio.id}-12-31`}`);

    findAptosPagamentos(filter);
    findAptosPagamentosTotal(filter);
  };

  consultarAptosPagamentos = () => {
    const { page } = this.props;
    const initialPage = page.set('number', 0);

    this.searchWithPage(initialPage);
  };

  onConsultaAptosPagamento = () => {
    this.consultarAptosPagamentos();
    this.refs.panelFiltroAptosPagamento &&
      this.refs.panelFiltroAptosPagamento.collapse();
  };

  onChangeFiltro = event => {
    const { onChange } = this.props;
    onChange('filtroAptoPagamento', event);
  };

  onResetFiltro = () => {
    const {
      resetCrud,
      findAptosPagamentos,
      findAptosPagamentosTotal
    } = this.props;

    resetCrud('filtroAptoPagamento');
    findAptosPagamentos('');
    findAptosPagamentosTotal('');
  };

  montarFiltro = function (page) {
    let userInfo = StorageService.getItem('userInfo');
    const { entidade } = userInfo;

    const { filtros } = this.props;

    let filter = 'entidade=' + entidade.id;

    if (filtros.get('cnpj')) {
      filter = addFilterUrl(filter, `cnpjCpf=${filtros.get('cnpj')}`);
    }

    if (filtros.get('nome')) {
      filter = addFilterUrl(
        filter,
        `nome=${encodeURIComponent(filtros.get('nome'))}`
      );
    }

    if (filtros.get('notaFiscal')) {
      filter = addFilterUrl(filter, `notaFiscal=${filtros.get('notaFiscal')}`);
    }

    if (
      filtros.get('dataInicial') &&
      filtros.get('dataInicial').length === 10
    ) {
      let validValue = DateUtils.transform(
        filtros.get('dataInicial'),
        'yy-mm-dd',
        'yy-mm-dd'
      );
      filter = addFilterUrl(filter, `dataInicial=${validValue}`);
    }

    if (filtros.get('dataFinal') && filtros.get('dataFinal').length === 10) {
      let validValue = DateUtils.transform(
        filtros.get('dataFinal'),
        'yy-mm-dd',
        'yy-mm-dd'
      );
      filter = addFilterUrl(filter, `dataFinal=${validValue}`);
    }

    return addPagination(page, filter);
  };

  onExporta = (exportType, openTab, windowParams) => {
    const { apiURL } = configuration;
    const { page, addErrorNotification } = this.props;

    if (page.get('content').size > 0) {
      let filtro = `${this.montarFiltro(page)}&exportType=${exportType}`;

      window.open(
        `${apiURL}/api/liquidacoes/aptos-pagamentos/report?${filtro}`,
        openTab,
        windowParams
      );
    } else {
      addErrorNotification(
        'A consulta não apresentou resultado. Não é possível exportar para o formato selecionado!'
      );
    }
  };

  searchWithPage = page => {
    const { findAptosPagamentos, findAptosPagamentosTotal } = this.props;

    const filtro = `${this.montarFiltro(page)}`;

    findAptosPagamentos(filtro);
    findAptosPagamentosTotal(filtro);
  };

  mensagemSemFornecedor = liquidacao => {
    if (liquidacao && !liquidacao.isEmpty()) {
      return 'Entidade não possui pendências financeiras "processadas" para filtro informado';
    } else {
      return 'Entidade não possui pendências financeiras "processadas"!';
    }
  };

  render() {
    const { page, filtros, location, total } = this.props;
    let mensagem = this.mensagemSemFornecedor(filtros);

    return (
      <section>
        <PageHeader location={location} />

        <NotasExplicativas pathname={this.props.location.pathname} />

        <Panel
          isForm
          mobileClose
          title="Consulta"
          ref="panelFiltroAptosPagamento"
        >
          <form>
            <Row>
              <Col xs={12} sm={4} md={4}>
                <Input
                  id="cnpj"
                  name="cnpj"
                  ref="cnpj"
                  placeholder="CNPJ/CPF"
                  label="CNPJ/CPF"
                  type="text"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('cnpj')}
                />
              </Col>
              <Col xs={12} sm={8} md={8}>
                <Input
                  id="nome"
                  name="nome"
                  ref="nome"
                  placeholder="Nome"
                  label="Nome"
                  type="text"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('nome')}
                />
              </Col>
              <Col xs={12} sm={8} md={4}>
                <Input
                  id="notaFiscal"
                  name="notaFiscal"
                  ref="notaFiscal"
                  placeholder="Nota Fiscal"
                  label="Nota Fiscal"
                  type="text"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('notaFiscal')}
                />
              </Col>
              <Col xs={12} sm={6} md={2}>
                <DatePicker
                  id="dataInicial"
                  name="dataInicial"
                  ref="dataInicial"
                  label="Data Inicial"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('dataInicial')}
                />
              </Col>
              <Col xs={12} sm={6} md={2}>
                <DatePicker
                  id="dataFinal"
                  name="dataFinal"
                  ref="dataFinal"
                  label="Data Final"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('dataFinal')}
                />
              </Col>

              <Col xs={12} sm={4} md={4}>
                <FilterButtons
                  idBtnReset="btnResetFilter"
                  onConsulta={this.onConsultaAptosPagamento}
                  onReset={this.onResetFiltro}
                />
              </Col>
            </Row>
          </form>
        </Panel>

        <Panel isForm tableResponsive expansible={false}>
          <Row>
            <Col xs={6}>
              <UltimaAtualizacaoByTable
                modulo="3"
                table="liquidacao"
                column="dataultimaatualizacao"
              />
            </Col>
            <Col xs={6} />
          </Row>

          <LiquidacoesListagem
            page={page}
            searchWithPage={this.searchWithPage}
            liquidacoes={page.get('content')}
            total={total}
            history={this.props.history}
            filter={this.montarFiltro(page)}
            tipo={'aptos-pagamentos'}
            mensagem={mensagem}
            onExporta={this.onExporta}
          />

          <SearchPagination page={page} searchWithPage={this.searchWithPage} />
        </Panel>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    filtros: state.crud.getIn(
      ['filtroAptoPagamento', 'currentRecord'],
      Map({})
    ),
    page: state.aptopagamento.get('aptosPagamentos'),
    total: state.aptopagamento.get('total')
  };
}

const allActions = {
  ...actions,
  onChange,
  resetCrud,
  addErrorNotification
};

export default connect(mapStateToProps, allActions)(FormAptosPagamentos);
