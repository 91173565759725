import * as types from './Types.js';

export function findVerbas(definicaoEvento, mesCompetencia) {
  return ({ fetch }) => ({
    type: types.VERBAS,
    payload: {
      promise: fetch(
        `/api/servidores/verbas/${definicaoEvento}/${mesCompetencia}`
      )
    }
  });
}

export function findVerbaDetalhes(definicaoEvento, mesCompetencia, lotacao) {
  return ({ fetch }) => ({
    type: types.VERBAS_DETALHES,
    payload: {
      promise: fetch(
        `/api/servidores/verbas/detalhes/${definicaoEvento}/${mesCompetencia}/${lotacao}`
      )
    }
  });
}
