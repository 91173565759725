import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react.js';
import SumListField from 'portaltransparencia-common/components/SumListField.react.js';
import CountListField from 'portaltransparencia-common/components/CountListField.react.js';
import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export class ContratoDetalheItens extends Component {
  static propTypes = {
    contrato: PropTypes.object.isRequired
  };

  renderItens = data => {
    const itemKey =
      data.get('entidade') +
      '_' +
      data.get('exercicio') +
      '_' +
      data.get('contrato') +
      '_' +
      data.get('lote') +
      '_' +
      data.get('ordem');
    return (
      <TableActions ref="row" key={itemKey} data={data}>
        <td id="lote">{data.get('lote')}</td>
        <td id="ordem">{data.get('ordem')}</td>
        <td id="descricao">{data.get('descricao')}</td>
        <td id="quantidade" className="right">
          <NumberFormatter value={data.get('quantidade')} format="0,0.0000" />
        </td>
        <td id="unidade">{data.get('unidade')}</td>
        <td id="valorUnitario" className="right">
          <NumberFormatter
            value={data.get('valorUnitario')}
            format="0,0.0000"
          />
        </td>
        <td id="valor" className="right">
          <NumberFormatter value={data.get('valor')} format="0,0.0000" />
        </td>
      </TableActions>
    );
  };

  render() {
    const itens = this.props.contrato?.get('itens');

    return (
      <div className="panel-table-outer">
        <table className="panel-table striped fancy">
          <TableHeader empty={itens?.isEmpty()}>
            <tr>
              <th>Lote</th>
              <th>Sequência</th>
              <th>Descrição</th>
              <th className="right">Quantidade</th>
              <th>Unidade</th>
              <th className="right">Valor Unitário</th>
              <th className="right">Valor Total</th>
            </tr>
          </TableHeader>
          <tbody>{itens?.map(this.renderItens)}</tbody>

          {itens && !itens.isEmpty() && (
            <tfoot>
              <tr>
                <td colSpan={2}>
                  <i>Lotes: </i>
                  {<CountListField fieldName="lote" list={itens} />}
                </td>
                <td>
                  <i>Itens: </i>
                  {<CountListField list={itens} />}
                </td>
                <td className="right">
                  <i>Total: </i>
                  {
                    <SumListField
                      fieldName="quantidade"
                      list={itens}
                      format="0,0.0000"
                    />
                  }
                </td>
                <td></td>
                <td className="right">
                  <i>Total: </i>
                  {
                    <SumListField
                      fieldName="valorUnitario"
                      list={itens}
                      format="0,0.0000"
                    />
                  }
                </td>
                <td className="right">
                  <i>Total: </i>
                  {
                    <SumListField
                      fieldName="valor"
                      list={itens}
                      format="0,0.0000"
                    />
                  }
                </td>
                <td></td>
              </tr>
            </tfoot>
          )}
        </table>
      </div>
    );
  }
}

export default ContratoDetalheItens;
