import * as types from './Types.js';

import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import { isNumber } from 'portaltransparencia-common/libs/NumberUtils';

export function findIncorporacoes() {
  return ({ fetch }) => ({
    type: types.INCORPORACOES_LIST,
    payload: {
      promise: fetch('/api/patrimonios/alienacoes?tipo=C')
    }
  });
}

export function findDesincorporacoes() {
  return ({ fetch }) => ({
    type: types.DESINCORPORACOES_LIST,
    payload: {
      promise: fetch('/api/patrimonios/alienacoes?tipo=B')
    }
  });
}

export function searchPatrimonios(filter) {
  const { entidade } = StorageService.getItem('userInfo');

  const baseFilter = `entidade=${entidade.id}&`;

  return ({ fetch }) => ({
    type: types.CONSULTA_PATRIMONIOS,
    payload: {
      promise: fetch(`/api/patrimonios?${baseFilter}${filter}`)
    }
  });
}

export function resetConsulta() {
  return {
    type: types.RESET_CONSULTA_PATRIMONIOS,
    payload: {}
  };
}

export function findPatrimoniosDetalhes(entidade, tipo, bem, chapa) {
  return ({ fetch }) => ({
    type: types.PATRIMONIOS_DETALHES_LIST,
    payload: {
      promise: fetch(
        `/api/patrimonios/detalhe?entidade=${entidade}&tipo=${tipo}&${handlerFieldBem(
          bem
        )}=${bem}&chapa=${chapa}`
      )
    }
  });
}

export function findPatrimonioBem(entidade, tipo, bem, chapa) {
  return ({ fetch }) => ({
    type: types.PATRIMONIO_BEM,
    payload: {
      promise: fetch(
        `/api/patrimonios/bem?entidade=${entidade}&tipo=${tipo}&${handlerFieldBem(
          bem
        )}=${bem}&chapa=${chapa}`
      )
    }
  });
}

export function findEmpenhos(entidade, tipo, bem, chapa) {
  return ({ fetch }) => ({
    type: types.PATRIMONIO_EMPENHO,
    payload: {
      promise: fetch(
        `/api/patrimonios/bem/empenhos?entidade=${entidade}&tipo=${tipo}&${handlerFieldBem(
          bem
        )}=${bem}&chapa=${chapa}`
      )
    }
  });
}

const handlerFieldBem = bem => {
  /*
   * Quando o portal está integrado com o oxy patrimonio
   * o tipo do ID do bem passa a ser UUID, atributo idOxy no parâmetro do request.
   */
  return isNumber(bem) ? 'bem' : 'idOxy';
};
