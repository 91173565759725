import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export default class AjudaCustoServidorRow extends Component {
  static propTypes = {
    servidor: PropTypes.object.isRequired
  };

  onViewDetalhes = item => {
    this.props.history.push(
      `ajuda-custo/detalhes?matricula=${item.get('matricula')}`
    );
  };

  generateKey = servidor => {
    return `${servidor.get('entidade')}_
            ${servidor.get('matricula')}_
            ${servidor.get('cargo')}`;
  };

  render() {
    const { servidor } = this.props;

    return (
      <TableActions
        id="btnAction"
        ref="row"
        key={this.generateKey(servidor)}
        data={servidor}
        onMore={this.onViewDetalhes}
      >
        <td>{servidor.get('matricula')}</td>
        <td>{servidor.get('nome')} </td>
        <td>{servidor.get('cargoDescricao')}</td>
      </TableActions>
    );
  }
}
