import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';

import * as types from './Types.js';

const initialState = fromJS({
  sigilosos: {
    content: []
  },
  desclassificados: {
    content: []
  }
});

function processosSigilososReducer(state = initialState, action) {
  switch (action.type) {
    case (types.SIGILOSOS_LIST_START, types.DESCLASSIFICADOS_LIST_START): {
      return startLoad(state);
    }

    case types.SIGILOSOS_LIST_SUCCESS: {
      return endLoad(state.set('sigilosos', fromJS(action.payload)));
    }

    case types.DESCLASSIFICADOS_LIST_SUCCESS: {
      return endLoad(state.set('desclassificados', fromJS(action.payload)));
    }

    case (types.SIGILOSOS_LIST_ERROR, types.DESCLASSIFICADOS_LIST_ERROR): {
      return endLoad(state);
    }
  }

  return state;
}

export default processosSigilososReducer;
