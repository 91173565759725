import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';

import * as types from './Types.js';

const initialState = fromJS({
  despesas: [],
  despesasNivel: []
});

function despesasDetalheReducer(state = initialState, action) {
  switch (action.type) {
    case types.DESPESAS_START:
    case types.DESPESAS_NIVEL_START: {
      return startLoad(state);
    }

    case types.DESPESAS_ERROR:
    case types.DESPESAS_NIVEL_ERROR: {
      return endLoad(state);
    }

    case types.DESPESAS_NIVEL_SUCCESS: {
      return endLoad(state.set('despesasNivel', fromJS(action.payload)));
    }

    case types.DESPESAS_SUCCESS: {
      const despesas = fromJS(
        action.payload.map(item => {
          return {
            ...item,
            open: false,
            visible: item.nivel === 1
          };
        })
      );
      return endLoad(state.set('despesas', despesas));
    }
    case types.EXPAND_DESPESA: {
      const despesas = state.get('despesas').map(item => {
        if (item.get('programatica') === action.payload.despesa) {
          return item.set('open', true).set('visible', true);
        } else if (
          item.get('programatica') &&
          item.get('programatica').indexOf(action.payload.despesa) === 0 &&
          item.get('nivel') === nextLevel(state.get('despesas'), action.payload)
        ) {
          return item.set('visible', true);
        }
        return item;
      });

      return state.set('despesas', despesas);
    }
    case types.COLLAPSE_DESPESA: {
      const despesas = state.get('despesas').map(item => {
        if (item.get('programatica') === action.payload.despesa) {
          return item.set('open', false).set('visible', true);
        } else if (
          item.get('programatica') &&
          item.get('programatica').indexOf(action.payload.despesa) === 0 &&
          item.get('nivel') > action.payload.nivel
        ) {
          return item.set('open', false).set('visible', false);
        }
        return item;
      });

      return state.set('despesas', despesas);
    }
  }

  return state;
}

function nextLevel(list, item) {
  const index = list.findIndex(i => i.get('programatica') === item.despesa);

  const proximoNivel = list.getIn([index + 1, 'nivel']);

  return proximoNivel;
}

export default despesasDetalheReducer;
