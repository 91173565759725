import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';

import * as types from './Types.js';

const initialState = fromJS({
  adiantamentos: [],
  adiantamento: {},
  filtros: [],
  arquivos: [],
  totalAdiantamentos: []
});

function adiantamentoReducer(state = initialState, action) {
  switch (action.type) {
    case types.ADIANTAMENTOS_START:
    case types.CONSULTA_ADIANTAMENTOS_START:
    case types.ADIANTAMENTO_START:
    case types.ARQUIVOS_START:
    case types.TOTAL_ADIANTAMENTOS_START: {
      return startLoad(state);
    }

    case types.ADIANTAMENTOS_ERROR:
    case types.CONSULTA_ADIANTAMENTOS_ERROR:
    case types.ADIANTAMENTO_ERROR:
    case types.ARQUIVOS_ERROR:
    case types.TOTAL_ADIANTAMENTOS_ERROR: {
      return endLoad(state);
    }

    case types.ADIANTAMENTOS_SUCCESS:
    case types.CONSULTA_ADIANTAMENTOS_SUCCESS: {
      return endLoad(state.set('adiantamentos', fromJS(action.payload)));
    }
    case types.ADIANTAMENTO_SUCCESS: {
      return endLoad(state.set('adiantamento', fromJS(action.payload)));
    }
    case types.ARQUIVOS_SUCCESS: {
      return endLoad(state.set('arquivos', fromJS(action.payload)));
    }
    case types.TOTAL_ADIANTAMENTOS_SUCCESS: {
      return endLoad(state.set('totalAdiantamentos', fromJS(action.payload)));
    }

    default:
      return state;
  }
}

export default adiantamentoReducer;
