import SearchPagination from 'portaltransparencia-common/components/SearchPagination.react.js';
import { addFilterUrl } from 'portaltransparencia-common/libs/AddFilter.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

import AjudaCustoTable from './AjudaCustoTable.react.js';

export default class AjudaCustoPage extends Component {
  static propTypes = {
    page: PropTypes.object.isRequired,
    findAjudasCusto: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired
  };

  searchWithPage = page => {
    const { findAjudasCusto } = this.props;

    findAjudasCusto(this.montarPaginacao(page));
  };

  montarPaginacao = function (page) {
    const { location } = this.props;

    let pagination = '';

    if (page) {
      if (page.get('number')) {
        pagination = addFilterUrl(pagination, `page=${page.get('number')}`);
      }
      if (page.get('size')) {
        pagination = addFilterUrl(pagination, `size=${page.get('size')}`);
      }
      if (page.get('sort')) {
        pagination = addFilterUrl(pagination, `sort=${page.get('sort')}`);
      }
    }

    return location.search + '&' + pagination;
  };

  render() {
    const { page } = this.props;

    if (!page.has('content')) {
      return null;
    }

    return (
      <section>
        <AjudaCustoTable ajudasCusto={page.get('content')} />

        <SearchPagination page={page} searchWithPage={this.searchWithPage} />
      </section>
    );
  }
}
