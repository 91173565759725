import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react.js';
import SumListField from 'portaltransparencia-common/components/SumListField.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import * as actions from './Actions.js';

const sumValue = field => (valor, item) => valor + item.get(field);

export class EmpenhoDetalheLiquidacoes extends Component {
  static propTypes = {
    liquidacoes: PropTypes.object.isRequired,
    exibeEmpenho: PropTypes.bool.isRequired,
    paramDespesasPagasValor: PropTypes.string
  };

  renderItens = data => {
    const { exibeEmpenho } = this.props;
    let noLiquidacao =
      data.get('noLiquidacao') + '/' + data.get('exercicioLiquidacao');

    let noEmpenho = data.get('empenho') + '/' + data.get('exercicio');

    return (
      <tr key={noLiquidacao} data={data}>
        {exibeEmpenho && <td id="noEmpenho">{noEmpenho}</td>}
        <td id="noLiquidacao">{noLiquidacao}</td>
        <td id="data">{DateUtils.formatDateTimeShort(data.get('data'))}</td>
        <td id="valor" className="right">
          <NumberFormatter value={data.get('valor')} />
        </td>
        <td id="valorEstornado" className="right">
          <NumberFormatter value={data.get('valorEstornado')} />
        </td>
        <td id="valorRetido" className="right">
          <NumberFormatter value={data.get('valorRetido')} />
        </td>
        <td id="valorPago" className="right">
          <NumberFormatter
            value={
              this.props.paramDespesasPagasValor &&
              this.props.paramDespesasPagasValor == 'S'
                ? data.get('valorPago') + data.get('valorRetido')
                : data.get('valorPago')
            }
          />
        </td>
      </tr>
    );
  };

  render() {
    const { liquidacoes, exibeEmpenho } = this.props;

    return (
      <div className="panel-table-outer">
        <table className="panel-table striped fancy">
          <TableHeader empty={liquidacoes.isEmpty()}>
            <tr>
              {exibeEmpenho && <th>Empenho</th>}
              <th>Liquidação</th>
              <th>Data</th>
              <th className="right">Valor</th>
              <th className="right">Valor Estornado</th>
              <th className="right">Valor Retido</th>
              <th className="right">Valor Pago</th>
            </tr>
          </TableHeader>
          <tbody>
            {liquidacoes
              .sortBy(liquidacao => liquidacao.get('empenho'))
              .map(this.renderItens)}
          </tbody>

          {!liquidacoes.isEmpty() && (
            <tfoot>
              <tr>
                {exibeEmpenho && <td></td>}
                <td></td>
                <td></td>
                <td className="right">
                  <i>Total:</i>{' '}
                  {<SumListField fieldName="valor" list={liquidacoes} />}
                </td>
                <td className="right">
                  <i>Total:</i>{' '}
                  {
                    <SumListField
                      fieldName="valorEstornado"
                      list={liquidacoes}
                    />
                  }
                </td>
                <td className="right">
                  <i>Total:</i>{' '}
                  {<SumListField fieldName="valorRetido" list={liquidacoes} />}
                </td>
                <td className="right">
                  <i>Total:</i>{' '}
                  {this.props.paramDespesasPagasValor &&
                  this.props.paramDespesasPagasValor == 'S' ? (
                    <NumberFormatter
                      value={
                        liquidacoes.reduce(sumValue('valorPago'), 0) +
                        liquidacoes.reduce(sumValue('valorRetido'), 0)
                      }
                    />
                  ) : (
                    <SumListField fieldName="valorPago" list={liquidacoes} />
                  )}
                </td>
              </tr>
            </tfoot>
          )}
        </table>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    liquidacoes: state.empenhos.get('liquidacoes')
  };
}

const allActions = {
  ...actions
};

export default connect(mapStateToProps, allActions)(EmpenhoDetalheLiquidacoes);
