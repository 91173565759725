import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';

import * as types from './Types.js';

const initialState = fromJS({
  restosAPagarProcessados: [],
  restosAPagarNaoProcessados: []
});

function restosAPagarReducer(state = initialState, action) {
  switch (action.type) {
    case (types.RESTOSAPAGARPROCESSADOS_START,
    types.RESTOSAPAGARNAOPROCESSADOS_START,
    types.PROGRAMATICAORGAO_START,
    types.PROGRAMATICAUNIDADE_START,
    types.PROGRAMATICAFUNCAO_START,
    types.PROGRAMATICASUBFUNCAO_START,
    types.PROGRAMATICAPROGRAMA_START,
    types.PROGRAMATICAPROJETO_START,
    types.PROGRAMATICAELEMENTO_START,
    types.PROGRAMATICADESDOBRAMENTO_START,
    types.PROGRAMATICASUBDESDOBRAMENTO_START,
    types.PROGRAMATICAFONTERECURSO_START): {
      return startLoad(state);
    }

    case (types.RESTOSAPAGARPROCESSADOS_ERROR,
    types.RESTOSAPAGARNAOPROCESSADOS_ERROR,
    types.PROGRAMATICAORGAO_ERROR,
    types.PROGRAMATICAUNIDADE_ERROR,
    types.PROGRAMATICAFUNCAO_ERROR,
    types.PROGRAMATICASUBFUNCAO_ERROR,
    types.PROGRAMATICAPROGRAMA_ERROR,
    types.PROGRAMATICAPROJETO_ERROR,
    types.PROGRAMATICAELEMENTO_ERROR,
    types.PROGRAMATICADESDOBRAMENTO_ERROR,
    types.PROGRAMATICASUBDESDOBRAMENTO_ERROR,
    types.PROGRAMATICAFONTERECURSO_ERROR): {
      return endLoad(state);
    }

    case types.RESTOSAPAGARPROCESSADOS_SUCCESS: {
      return endLoad(
        state.set('restosAPagarProcessados', fromJS(action.payload))
      );
    }

    case types.RESTOSAPAGARNAOPROCESSADOS_SUCCESS: {
      return endLoad(
        state.set('restosAPagarNaoProcessados', fromJS(action.payload))
      );
    }

    case types.PROGRAMATICAORGAO_SUCCESS: {
      return endLoad(
        state.setIn(['filtroProgramatica', 'orgao'], fromJS(action.payload))
      );
    }

    case types.PROGRAMATICAUNIDADE_SUCCESS: {
      return endLoad(
        state.setIn(['filtroProgramatica', 'unidade'], fromJS(action.payload))
      );
    }

    case types.PROGRAMATICAFUNCAO_SUCCESS: {
      return endLoad(
        state.setIn(['filtroProgramatica', 'funcao'], fromJS(action.payload))
      );
    }

    case types.PROGRAMATICASUBFUNCAO_SUCCESS: {
      return endLoad(
        state.setIn(['filtroProgramatica', 'subFuncao'], fromJS(action.payload))
      );
    }

    case types.PROGRAMATICAPROGRAMA_SUCCESS: {
      return endLoad(
        state.setIn(['filtroProgramatica', 'programa'], fromJS(action.payload))
      );
    }

    case types.PROGRAMATICAPROJETO_SUCCESS: {
      return endLoad(
        state.setIn(['filtroProgramatica', 'projeto'], fromJS(action.payload))
      );
    }

    case types.PROGRAMATICAELEMENTO_SUCCESS: {
      return endLoad(
        state.setIn(['filtroProgramatica', 'elemento'], fromJS(action.payload))
      );
    }

    case types.PROGRAMATICADESDOBRAMENTO_SUCCESS: {
      return endLoad(
        state.setIn(
          ['filtroProgramatica', 'desdobramento'],
          fromJS(action.payload)
        )
      );
    }

    case types.PROGRAMATICASUBDESDOBRAMENTO_SUCCESS: {
      return endLoad(
        state.setIn(
          ['filtroProgramatica', 'subDesdobramento'],
          fromJS(action.payload)
        )
      );
    }

    case types.PROGRAMATICAFONTERECURSO_SUCCESS: {
      return endLoad(
        state.setIn(
          ['filtroProgramatica', 'fonteRecurso'],
          fromJS(action.payload)
        )
      );
    }
  }

  return state;
}

export default restosAPagarReducer;
