import Modal from 'portaltransparencia-common/components/Modal.react.js';
import TableActions from 'portaltransparencia-common/components/TableActions.react';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import PropTypes from 'prop-types';
import React from 'react';
import { Panel } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { downloadArquivoBlob, downloadByUrl } from '../arquivo/ArquivoUtils';
import Hint from 'portaltransparencia-common/components/hint/Hint.react';
import configuration from 'portaltransparencia-common/config/Config';

export class ObraDetalheMedicaoArquivo extends Component {
  static propTypes = {
    arquivos: PropTypes.object.isRequired,
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
  };

  onDownload = data => {
    if (data.get('arquivo')) {
      downloadArquivoBlob(data.get('arquivo'), data.get('nomeArquivo'));
    }

    if (data.get('idarquivo')) {
      downloadByUrl(
        `${configuration.apiURL}/api/files/arquivo/${data.get('idarquivo')}`,
        data.get('nomeArquivo')
      );
    }
  };

  renderArquivos = data => {
    const key = data.get('nomeArquivo');

    return (
      <TableActions
        ref="row"
        key={key}
        data={data}
        onDownload={data => this.onDownload(data)}
      >
        <td>
          {data.get('nomeArquivo')}
          {data.get('arquivoLaudo') && (
            <Hint
              message="Documento do Laudo de Medição"
              isInline
              spacing="ml-xs"
              position="left"
              size="sm"
              icon="fa fa-info"
            ></Hint>
          )}
        </td>
      </TableActions>
    );
  };

  render() {
    const { arquivos, show, onClose } = this.props;

    if (show && !arquivos.isEmpty()) {
      return (
        <Modal onClose={onClose}>
          <Panel className="panel-table-outer container info">
            <table className="panel-table striped fancy">
              <TableHeader>
                <tr>
                  <th>Arquivos do Acompanhamento</th>
                </tr>
              </TableHeader>
              <tbody>{arquivos.map(this.renderArquivos)}</tbody>
            </table>
          </Panel>
        </Modal>
      );
    }

    return null;
  }
}

export default ObraDetalheMedicaoArquivo;
