import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export default class AtalhoGeralItem extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    desc: PropTypes.string.isRequired,
    shortcut: PropTypes.string.isRequired
  };

  render() {
    const { title, desc, shortcut } = this.props;

    return (
      <div style={{ marginLeft: '20px' }}>
        <a> {title} </a>
        <small>
          <i> - {desc} </i>
        </small>
        <b> - {shortcut} </b>
      </div>
    );
  }
}
