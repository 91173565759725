import Fields from 'portaltransparencia-common/libs/Fields.js';

import * as types from './Types.js';

export function enviarMensagem(dados, entidade) {
  return ({ post }) => ({
    type: types.ENVIAR_MENSAGEM,
    payload: {
      promise: post(`/api/mensagens?entidade=${entidade}`, dados.toJS())
    }
  });
}

export function onChange(resource, event) {
  const field = Fields.getName(event);
  const value = Fields.getValue(event);

  return {
    type: types.CHANGE_FIELD_CONTATO_VALUE,
    payload: {
      field: field,
      value: value
    },
    meta: {
      path: resource
    }
  };
}

export function reset() {
  return {
    type: types.FORMULARIO_CONTATO_RESET
  };
}
