import Information from 'portaltransparencia-common/components/Information.react.js';
import EmManutencao from 'portaltransparencia-common/notfound/Maintence.react.js';
import {
  PARAM_FORMULARIO_MENSAGEM,
  PARAM_HABILITAR_RECAPTCHA,
  PARAM_INTEGRADO_OXY_FOLHA_PAGAMENTO,
  PARAM_OCULTA_LOGO,
  PARAM_PERGUNTASFREQUENTES,
  PARAM_PERMITIR_SOLICITACAO_ANONIMA
} from 'portaltransparencia-common/parametros/ParametrosPortalTransparencia';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import Routes from '../CreateRoutes.js';
import ParametroService from '../parametros/ParametroService.js';
import Footer from './Footer.react.js';
import Header from './Header.react.js';
import Helper from './Helper.react.js';
import MainSection from './MainSection.react.js';
import OffSidebarESIC from './OffSidebarESIC.jsx';
import SubHeader from './SubHeader.react.js';
import ReCAPTCHA from 'react-google-recaptcha';

import './recaptcha.css';

export class Base extends Component {
  static propTypes = {
    location: PropTypes.object,
    children: PropTypes.object,
    menu: PropTypes.object,
    paramShowAcessoAInformacao: PropTypes.string,
    paramPermiteSolicitacaoAnonima: PropTypes.string,
    paramIntegradoOxyFolha: PropTypes.string,
    paramPerguntas: PropTypes.string,
    paramLogoElotech: PropTypes.string,
    exercicioLogado: PropTypes.object,
    entidadeSelecionada: PropTypes.object,
    manuntencao: PropTypes.bool,
    selo: PropTypes.string,
    menuState: PropTypes.object
  };

  renderMainContainer = () => {
    const {
      paramShowAcessoAInformacao,
      paramPermiteSolicitacaoAnonima,
      paramIntegradoOxyFolha,
      manuntencao,
      isIntegradoOxy,
      paramHabilitaRECAPTCHA,
      ...rest
    } = this.props;

    this.props.menuState.isIntegradoOxy = paramIntegradoOxyFolha;

    const habilitaReCAPTCHA = isIntegradoOxy && paramHabilitaRECAPTCHA === 'S';

    return (
      <div className="main-container">
        <OffSidebarESIC
          paramShowAcessoAInformacao={paramShowAcessoAInformacao}
          paramPermiteSolicitacaoAnonima={paramPermiteSolicitacaoAnonima}
        />

        <Information />

        {manuntencao ? (
          <EmManutencao />
        ) : (
          <MainSection {...rest}>
            <Routes
              menuState={this.props.menuState}
              paramIntegradoOxyFolha={paramIntegradoOxyFolha}
            />
          </MainSection>
        )}

        {habilitaReCAPTCHA ? (
          <ReCAPTCHA
            style={{ display: 'inline-block' }}
            theme="dark"
            size="invisible"
            sitekey="6LcFEucpAAAAACSWuBqybMBDjt366q4FngLt-FMP"
          />
        ) : null}

        <Footer hideLogo={this.shouldHideLogo()} />

        <Helper />
      </div>
    );
  };

  shouldHideLogo = () => {
    const { paramLogoElotech } = this.props;
    return paramLogoElotech && paramLogoElotech == 'S';
  };

  render() {
    const {
      entidadeSelecionada,
      menu,
      paramPerguntas,
      exercicioLogado,
      location,
      selo
    } = this.props;

    const perguntas = paramPerguntas == 'S';

    return (
      <div className="portal">
        <Header menu={menu} selo={selo} hideLogo={this.shouldHideLogo()} />
        <SubHeader
          entidadeSelecionada={entidadeSelecionada}
          exercicioLogado={exercicioLogado}
          habilitaPerguntas={perguntas}
          location={location}
        />

        {this.renderMainContainer()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    paramIntegradoOxyFolha: ParametroService.getValorParametro(
      state,
      PARAM_INTEGRADO_OXY_FOLHA_PAGAMENTO
    ),
    paramShowAcessoAInformacao: ParametroService.getValorParametro(
      state,
      PARAM_FORMULARIO_MENSAGEM
    ),
    paramPerguntas: ParametroService.getValorParametro(
      state,
      PARAM_PERGUNTASFREQUENTES
    ),
    paramLogoElotech: ParametroService.getValorParametro(
      state,
      PARAM_OCULTA_LOGO
    ),
    paramPermiteSolicitacaoAnonima: ParametroService.getValorParametro(
      state,
      PARAM_PERMITIR_SOLICITACAO_ANONIMA,
      'S'
    ),
    exercicioLogado: state.entidade.get('exercicioLogado'),
    entidadeSelecionada: state.entidade.get('entidadeSelecionada'),
    manuntencao: state.ui.maintance,
    selo: state.entidade.getIn(['configuracoesGerais', 'selo']),
    menuState: state.menu,
    isIntegradoOxy: state.entidade.getIn([
      'configuracoesGerais',
      'isIntegradoOxy'
    ]),
    paramHabilitaRECAPTCHA: ParametroService.getValorParametro(
      state,
      PARAM_HABILITAR_RECAPTCHA,
      'S'
    )
  };
}

export default connect(mapStateToProps)(Base);
