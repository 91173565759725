import { addHttp } from 'portaltransparencia-common/libs/LinkExterno.js';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';

export default class LinkUtil extends Component {
  static propTypes = {
    linkUtil: PropTypes.object.isRequired
  };

  render() {
    const { linkUtil } = this.props;

    if (!linkUtil) {
      return null;
    }

    const linkComHttp = addHttp(linkUtil.get('link'));
    return (
      <Row key={linkUtil.get('id')}>
        <Col sm={12} className="mt-xs">
          <a href={linkComHttp} target="_blank">
            <em className="fa fa-link"></em>{' '}
            {linkUtil.get('descricao') || linkComHttp}
          </a>
        </Col>
      </Row>
    );
  }
}
