import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

import LinkToEmpenho from '../empenhos/LinkToEmpenho.react';

export class SubvencaoValoresLiberados extends Component {
  static propTypes = {
    valores: PropTypes.object.isRequired
  };

  renderValoresLiberados = data => {
    return (
      <tr ref="row" key={data.get('id')}>
        <td>
          <LinkToEmpenho
            entidade={data.get('entidade')}
            exercicio={data.get('exercicio')}
            empenho={data.get('empenho')}
          />
        </td>
        <td>
          {data.get('nome') && `${data.get('exercicio')} - ${data.get('nome')}`}
        </td>
        <td>
          <NumberFormatter value={data.get('valor')} />
        </td>
        <td>{DateUtils.formatDateTimeShort(data.get('data'))}</td>
        <td>
          <NumberFormatter value={data.get('valorPago')} />
        </td>
        <td>{DateUtils.formatDateTimeShort(data.get('dataPagamento'))}</td>
      </tr>
    );
  };

  render() {
    const { valores } = this.props;

    return (
      <div className="panel-table-outer">
        <table className="panel-table striped fancy">
          <TableHeader empty={valores.isEmpty()}>
            <tr>
              <th>Empenho</th>
              <th>Credor</th>
              <th>Valor Empenhado</th>
              <th>Data Empenho</th>
              <th>Valor Pago</th>
              <th>Data Pagamento</th>
            </tr>
          </TableHeader>
          <tbody>{valores.map(this.renderValoresLiberados)}</tbody>
        </table>
      </div>
    );
  }
}

export default SubvencaoValoresLiberados;
