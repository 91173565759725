import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export default class AudienciasPublicasRow extends Component {
  static propTypes = {
    audienciasPublicas: PropTypes.object.isRequired
  };

  onViewDetalhes = item => {
    const entidade = item.get('entidade') || '';
    const exercicio = item.get('exercicio') || '';
    const id = item.get('id');

    this.props.history.push(
      `audiencias-publicas/detalhes?entidade=${entidade}&exercicio=${exercicio}&id=${id}`
    );
  };

  render() {
    const { audienciasPublicas } = this.props;
    const key =
      audienciasPublicas.get('entidade') +
      '_' +
      audienciasPublicas.get('exercicio') +
      '_' +
      audienciasPublicas.get('descNatureza') +
      '_' +
      audienciasPublicas.get('descTipo');

    return (
      <TableActions
        ref="row"
        key={key}
        data={audienciasPublicas}
        onMore={this.onViewDetalhes}
      >
        <td>{audienciasPublicas.get('descNatureza')}</td>
        <td>{audienciasPublicas.get('descTipo')}</td>
        <td>{DateUtils.formatDateTimeShort(audienciasPublicas.get('data'))}</td>
        <td>{audienciasPublicas.get('local')}</td>
        <td>{audienciasPublicas.get('chamamento')}</td>
      </TableActions>
    );
  }
}
