import StorageService from 'portaltransparencia-common/libs/StorageService.js';

import * as types from './Types.js';

export function loadCentrosCusto(orgao) {
  let filter = '';

  if (orgao) {
    filter += `?orgao=${orgao}`;
  }

  return ({ fetch }) => ({
    type: types.CENTRO_CUSTO,
    payload: fetch(`/api/centroscusto/estocadores${filter}`)
  });
}

export function loadCentrosCustoDestino() {
  return ({ fetch }) => ({
    type: types.CENTRO_CUSTO,
    payload: fetch('/api/centroscusto/consumidores')
  });
}

export function loadTotalConsumido(filter) {
  return ({ fetch }) => ({
    type: types.PESQUISA_ITEMCONSUMO_TOTAL,
    payload: fetch(`/api/estoque/consumo/total${filter}`)
  });
}

export function loadOrgao() {
  const userInfo = StorageService.getItem('userInfo');
  const { entidade, exercicio } = userInfo;

  return ({ fetch }) => ({
    type: types.ALMOXARIFADO_ORGAO,
    payload: fetch(
      `/api/estoque/orgao/?entidade=${entidade.id}&exercicio=${exercicio.id}`
    )
  });
}

export function findMovimentacoes(centroCusto, item) {
  return ({ fetch }) => {
    const userInfo = StorageService.getItem('userInfo');
    const { entidade, exercicio } = userInfo;

    return {
      type: types.PESQUISA_MOVIMENTACAO,
      payload: {
        promise: fetch(
          `/api/movimentacao/?entidade=${entidade.id}&exercicio=${exercicio.id}&centroCusto=${centroCusto}&item=${item}`
        )
      }
    };
  };
}

export function researchMovimentacoes(centroCusto, item, exercicio) {
  return ({ fetch }) => {
    const userInfo = StorageService.getItem('userInfo');
    const { entidade } = userInfo;

    return {
      type: types.PESQUISA_MOVIMENTACAO,
      payload: {
        promise: fetch(
          `/api/movimentacao/?entidade=${entidade.id}&exercicio=${exercicio}&centroCusto=${centroCusto}&item=${item}`
        )
      }
    };
  };
}

export function pesquisaItens(filter) {
  const { entidade } = StorageService.getItem('userInfo');
  const baseFilter = `entidade=${entidade.id}&`;

  return ({ fetch }) => ({
    type: types.PESQUISA_ITEMESTOQUE,
    payload: {
      promise: fetch(`/api/estoque?${baseFilter}${filter}`)
    }
  });
}

export function pesquisaItensConsumo(filter) {
  return ({ fetch }) => ({
    type: types.PESQUISA_ITEMCONSUMO,
    payload: {
      promise: fetch(`/api/estoque/consumo${filter}`)
    }
  });
}

export function resetItens() {
  return {
    type: types.RESET_ITENS
  };
}
