import * as types from './Types.js';

export function findLeieSic(entidade, exercicio) {
  return ({ fetch }) => ({
    type: types.LEIESIC,
    payload: {
      promise: fetch(`/parametros/leiesic/${entidade}/${exercicio}`)
    }
  });
}

export function findInstrucaoNormativa(entidade, exercicio) {
  return ({ fetch }) => ({
    type: types.INSTRUCAO_NORMATIVA,
    payload: {
      promise: fetch(`/parametros/instrucao-normativa/${entidade}/${exercicio}`)
    }
  });
}

export function findLeiOrganicaMunicipio() {
  return ({ fetch }) => ({
    type: types.LEI_ORGANICA_MUNICIPIO,
    payload: {
      promise: fetch('/parametros/leiOrganicaMunicipio')
    }
  });
}

export function findResponsavelLAI() {
  return ({ fetch }) => ({
    type: types.RESPONSAVEL_LAI,
    payload: {
      promise: fetch('/parametros/responsavel-lai')
    }
  });
}
