import PropTypes from 'prop-types';
import React from 'react';
import { getFileBase64Url } from '../../libs/FileUtils';
import { Button } from '@elotech/components';

class SlideShowItem extends React.PureComponent {
  static propTypes = {
    image: PropTypes.object.isRequired,
    isThumbnail: PropTypes.bool
  };

  state = {
    imageError: false
  };

  onImageError = () => {
    this.setState({ imageError: true });
  };

  download = data => {
    const url = getFileBase64Url(data.get('arquivo'), data.get('nomeArquivo'));
    const link = document.createElement('a');
    link.href = url;
    link.download = data.get('nomeArquivo');
    link.target = '_blank';
    link.click();
  };

  render() {
    const { image, isThumbnail } = this.props;
    const { imageError } = this.state;

    if (!image.get('arquivo')) {
      return null;
    }

    const key =
      image.get('id') +
      '_' +
      image.get('exercicio') +
      '_' +
      image.get('entidade') +
      '_' +
      image.get('acompanhamento') +
      '_' +
      image.get('idOrdemServico');

    if (imageError && !isThumbnail) {
      return (
        <div key={key}>
          <Button
            type="button"
            onClick={() => this.download(image)}
            className="center-block"
          >
            Baixar Arquivo {image.get('nomeArquivo')}
          </Button>
        </div>
      );
    }

    return (
      <div key={key}>
        <img
          className="center"
          src={'data:image/*;base64,' + image.get('arquivo')}
          onError={this.onImageError}
        />
      </div>
    );
  }
}

export default SlideShowItem;
