import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import * as actions from '../menu/Actions.js';

class ModalBackend extends Component {
  static propTypes = {
    notificacao: PropTypes.object,
    arquivoNotificacao: PropTypes.any,
    aberto: PropTypes.any
  };

  state = {
    conteudo: undefined
  };

  componentDidMount() {
    const {
      buscaArquivoNotificacao,
      buscaNotificacao,
      entidade,
      exercicio
    } = this.props;
    buscaNotificacao(entidade.get('id'), exercicio.get('id'), 'home').then(
      response => {
        if (response.value[0]) {
          buscaArquivoNotificacao(response.value[0].id);
        }
      }
    );
  }

  fechar = () => {
    document.body.toggleAttribute('modal-block');
    this.props.fechaModal();
  };

  arrayBufferToBase64(buffer) {
    console.log('buffer', buffer);
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;

    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }

    return window.btoa(binary);
  }

  render() {
    const { arquivoNotificacao, notificacao, fechado } = this.props;

    if (arquivoNotificacao === undefined || fechado) {
      return null;
    }

    return (
      <div className="modal open">
        <div className="container">
          <span className="close close-modal gray-10" onClick={this.fechar} />
          <div className="panel highlight">
            <div className="panel-title">
              {notificacao.get(0).get('titulo')}
            </div>
            {Object.keys(arquivoNotificacao).length > 5 && (
              <img
                style={{ display: 'block' }}
                className="center"
                src={`data:image/png;base64, ${arquivoNotificacao}`}
              />
            )}
            <p className="center"> {notificacao.get(0).get('descricao')}</p>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    notificacao: state.menu.get('notificacao'),
    arquivoNotificacao: state.menu.get('arquivoNotificacao'),
    fechado: state.menu.get('fechado'),
    entidade: state.auth.getIn(['userInfo', 'entidade']),
    exercicio: state.auth.getIn(['userInfo', 'exercicio'])
  };
}

const allActions = {
  ...actions
};

export default connect(mapStateToProps, allActions)(withRouter(ModalBackend));
