export const PARAMETRO_MENU = 'PARAMETRO_MENU';
export const PARAMETRO_MENU_START = 'PARAMETRO_MENU_START';
export const PARAMETRO_MENU_SUCCESS = 'PARAMETRO_MENU_SUCCESS';
export const PARAMETRO_MENU_ERROR = 'PARAMETRO_MENU_ERROR';

export const PARAMETRO_BUSCA_NOTIFICACAO = 'BUSCA_NOTIFICACAO';
export const PARAMETRO_BUSCA_NOTIFICACAO_START = 'BUSCA_NOTIFICACAO_START';
export const PARAMETRO_BUSCA_NOTIFICACAO_SUCCESS = 'BUSCA_NOTIFICACAO_SUCCESS';
export const PARAMETRO_BUSCA_NOTIFICACAO_ERROR = 'BUSCA_NOTIFICACAO_ERROR';

export const PARAMETRO_BUSCA_ARQUIVO_NOTIFICACAO = 'BUSCA_ARQUIVO_NOTIFICACAO';
export const PARAMETRO_BUSCA_ARQUIVO_NOTIFICACAO_START =
  'BUSCA_ARQUIVO_NOTIFICACAO_START';
export const PARAMETRO_BUSCA_ARQUIVO_NOTIFICACAO_SUCCESS =
  'BUSCA_ARQUIVO_NOTIFICACAO_SUCCESS';
export const PARAMETRO_BUSCA_ARQUIVO_NOTIFICACAO_ERROR =
  'BUSCA_ARQUIVO_NOTIFICACAO_ERROR';

export const PARAMETRO_TIPO_FECHA_MODAL = 'PARAMETRO_TIPO_FECHA_MODAL';
