import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import * as actions from './Actions.js';

export class FormUltimaAtualizacaoByTable extends Component {
  static propTypes = {
    modulo: PropTypes.string.isRequired,
    table: PropTypes.string.isRequired,
    column: PropTypes.string.isRequired,
    moduloComparativo: PropTypes.string,
    tableComparativo: PropTypes.string,
    columnComparativo: PropTypes.string,
    comparativo: PropTypes.string.isRequired,
    ultimaAtualizacaoByTable: PropTypes.object.isRequired,
    ultimaAtualizacaoByTableComparativo: PropTypes.object.isRequired,
    findUltimaAtualizacaoByTable: PropTypes.func.isRequired,
    filtrarEntidade: PropTypes.bool
  };

  static defaultProps = {
    modulo: '3',
    column: 'dataultimaatualizacao',
    comparativo: 'N',
    filtrarEntidade: false
  };

  componentDidMount() {
    const {
      findUltimaAtualizacaoByTable,
      modulo,
      table,
      column,
      comparativo,
      moduloComparativo,
      tableComparativo,
      columnComparativo,
      filtrarEntidade
    } = this.props;

    findUltimaAtualizacaoByTable(modulo, table, column, 'N', filtrarEntidade);

    if (comparativo == 'S') {
      findUltimaAtualizacaoByTable(
        moduloComparativo,
        tableComparativo,
        columnComparativo,
        comparativo,
        filtrarEntidade
      );
    }
  }

  render() {
    const {
      ultimaAtualizacaoByTable,
      modulo,
      comparativo,
      ultimaAtualizacaoByTableComparativo
    } = this.props;
    let date, dateComparativo;

    if (modulo == 3 || modulo == 14) {
      date = DateUtils.formatDateTime(
        ultimaAtualizacaoByTable.get('ultimoAcesso'),
        'DD/MM/YYYY HH:mm:ss'
      );
      if (!date) {
        date = DateUtils.formatDateTime(new Date());
      }
    } else {
      date = DateUtils.formatDateTimeShort(
        ultimaAtualizacaoByTable.get('ultimoAcesso')
      );
    }

    if (comparativo == 'S') {
      if (modulo == 3 || modulo == 14) {
        dateComparativo = DateUtils.formatDateTime(
          ultimaAtualizacaoByTableComparativo.get('ultimoAcesso'),
          'DD/MM/YYYY HH:mm:ss'
        );
        if (!dateComparativo) {
          dateComparativo = DateUtils.formatDateTime(new Date());
        }
      } else {
        dateComparativo = DateUtils.formatDateTimeShort(
          ultimaAtualizacaoByTableComparativo.get('ultimoAcesso')
        );
      }

      if (
        ultimaAtualizacaoByTableComparativo.get('ultimoAcesso') >=
        ultimaAtualizacaoByTable.get('ultimoAcesso')
      ) {
        date = dateComparativo;
      }
    }

    return (
      <div className="last-updated" id="datetime">
        <i className="fa fa-clock-o"></i> Última Atualização: {date}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ultimaAtualizacaoByTable: state.ultimaAtualizacao.get(
      'ultimaAtualizacaoByTable'
    ),
    ultimaAtualizacaoByTableComparativo: state.ultimaAtualizacao.get(
      'ultimaAtualizacaoByTableComparativo'
    )
  };
}

export default connect(mapStateToProps, actions)(FormUltimaAtualizacaoByTable);
