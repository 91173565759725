import Component from 'react-pure-render/component';
import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import ReactDOM from 'react-dom';
import Hint from './hint/Hint.react';

import $ from 'jquery';

export default class Widget extends Component {
  static propTypes = {
    title: PropTypes.string,
    value: PropTypes.any,
    icon: PropTypes.string.isRequired,
    enableClick: PropTypes.bool,
    onClick: PropTypes.func,
    clickMessage: PropTypes.string,
    isNumeric: PropTypes.bool,
    children: PropTypes.node,
    isTable: PropTypes.bool,
    isForm: PropTypes.bool,
    isChart: PropTypes.bool,
    subtitle: PropTypes.string,
    sameHeight: PropTypes.bool,
    showHint: PropTypes.bool,
    iconHint: PropTypes.string,
    messageHint: PropTypes.string,
    isFixed: PropTypes.bool,
    alturaTopo: PropTypes.number,
    isLoading: PropTypes.bool,
    minHeight: PropTypes.any,
    noGradientBackground: PropTypes.bool
  };

  static defaultProps = {
    showHint: false,
    iconHint: 'fa fa-exclamation',
    isFixed: false
  };

  componentDidMount() {
    var element = ReactDOM.findDOMNode(this.refs.widget);

    element.setAttribute('height-menu-fixed', this.props.alturaTopo);

    function widgetSameHeight(classe) {
      var window_width = $(window).width();

      var alturaMaior = 0;

      $(classe).each(function () {
        $(classe).css('height', 'initial');

        if ($(this).height() > alturaMaior) {
          alturaMaior = $(this).height();
        }
      });

      if (window_width > 760) {
        $(classe).height(alturaMaior);
      }
    }
    setTimeout(function () {
      widgetSameHeight('.widget-same-height .panel-body');
      widgetSameHeight('.widget-same-height');

      $(window).resize(function () {
        widgetSameHeight('.widget-same-height .panel-body');
        widgetSameHeight('.widget-same-height');
      });
    }, 1000);

    if (this.props.isFixed) {
      const fixed = $('.widget.fixed');
      const width = fixed.width();
      const heightHeader = fixed.attr('height-menu-fixed');
      const elementOffset = fixed.offset() ? fixed.offset().top : undefined;
      const distance = elementOffset - heightHeader;

      this.setState({
        width,
        heightHeader,
        distance
      });

      $(document).scroll(this.onScroll);
    }
  }

  componentWillUnmount() {
    $(document).off('scroll', this.onScroll);
  }

  onScroll = () => {
    const { width, heightHeader, distance } = this.state;

    const fixed = $('.widget.fixed');

    var windowOffset = $(window).scrollTop();

    if (windowOffset >= distance && !fixed.hasClass('on')) {
      fixed.addClass('on');
      fixed.css('width', width);
      fixed.css('top', parseInt(heightHeader) + parseInt(50) + 'px');
    }

    if (windowOffset < distance && fixed.hasClass('on')) {
      fixed.removeClass('on');
      fixed.css('width', 'auto');
      fixed.css('top', '0');
    }
  };

  render() {
    const {
      isLoading,
      value,
      title,
      icon,
      onClick,
      enableClick,
      isNumeric,
      children,
      sameHeight,
      minHeight,
      isTable,
      isForm,
      subtitle,
      isChart,
      isFixed,
      iconHint,
      messageHint,
      showHint,
      noGradientBackground
    } = this.props;
    const classes = {
      'panel widget': true,
      'panel-form': isForm,
      table: isTable,
      'widget-same-height': sameHeight,
      chart: isChart,
      fixed: isFixed,
      'no-gradient-background': noGradientBackground
    };

    return (
      <div
        className={classnames(classes)}
        ref="widget"
        style={{ minHeight: minHeight }}
      >
        <span className="widget-icon">
          <em className={icon}></em>
        </span>
        <h2 className="panel-title">
          {title}
          {showHint && (
            <Hint
              isInline
              position="bottom-right"
              size="xs"
              icon={iconHint}
              messageComponent={messageHint}
              spacing="ml-xs"
            />
          )}
        </h2>
        <div className="panel-body">
          {isNumeric && (
            <article className="widget-numeric">
              {isLoading ? (
                <div className="center">
                  <i className="fa fa-spinner fa-pulse fa-2x fa-fw margin-bottom"></i>
                </div>
              ) : (
                <b className="widget-numeric-number">{value}</b>
              )}

              {subtitle && (
                <p className="widget-numeric-subtitle">{subtitle}</p>
              )}
            </article>
          )}

          {!isNumeric && children}
        </div>
        {onClick && enableClick && (
          <a href="" className="btn" onClick={onClick}>
            Visualizar
          </a>
        )}
      </div>
    );
  }
}
