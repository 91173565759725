import Panel from 'portaltransparencia-common/components/Panel.react.js';
import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import { downloadArquivoDocumentos } from '../arquivo/Actions.js';
import PageHeader from '../components/PageHeader.react.js';
import NotasExplicativas from '../notaexplicativa/Form.react';
import UltimaAtualizacaoByTable from '../ultimaatualizacao/UltimaAtualizacaoByTable.react.js';
import * as actions from './Actions';

export class FormDocumentos extends Component {
  static propTypes = {
    params: PropTypes.object,
    documentos: PropTypes.object,
    findDocumentos: PropTypes.func.isRequired,
    downloadArquivoDocumentos: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired
  };

  componentDidMount() {
    const { findDocumentos } = this.props;
    findDocumentos();
  }

  renderListas = documento => {
    return (
      <TableActions
        ref="row"
        key={documento.get('id')}
        data={documento}
        onDownload={downloadArquivoDocumentos}
      >
        <td>{documento.get('nome')}</td>
      </TableActions>
    );
  };

  render() {
    const { documentos } = this.props;

    let list = documentos;

    return (
      <section>
        <PageHeader location={this.props.location} />

        <NotasExplicativas pathname={this.props.location.pathname} />

        <Panel isTable tableResponsive expansible={false}>
          <UltimaAtualizacaoByTable
            modulo="14"
            table="gestao"
            column="dataultimaatualizacao"
          />
          <div className="panel-table-outer">
            <table className="panel-table striped fancy">
              <TableHeader empty={list.isEmpty()}>
                <tr>
                  <th>Descrição</th>
                  <th></th>
                </tr>
              </TableHeader>
              <tbody>{list.map(this.renderListas)}</tbody>
            </table>
          </div>
        </Panel>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    documentos: state.documento.get('documentosFiltrados')
  };
}

const allActions = {
  ...actions,
  downloadArquivoDocumentos
};

export default connect(mapStateToProps, allActions)(FormDocumentos);
