import * as actions from './Actions.js';

import {
  addAlertNotification,
  offsidebarToggle
} from 'portaltransparencia-common/ui/Actions.js';

import Component from 'react-pure-render/component';
import FormAtendimento from '../atendimento/Form.react.js';
import { IFrame } from '../components/iframe/IFrame.react.js';
import { Map } from 'immutable';
import NotasExplicativas from '../notaexplicativa/Form.react';
import { PARAM_ENTIDADE_PROTOCOLO } from 'portaltransparencia-common/parametros/ParametrosPortalTransparencia';
import PageHeader from '../components/PageHeader.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import ParametroService from '../parametros/ParametroService.js';
import Processo from './Processo.react.js';
import ProcessoConsultaFiltro from './ProcessoConsultaFiltro.react.js';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { downloadArquivo } from '../arquivo/Actions.js';
import { getAllValuesFromUrlSearchParams } from 'portaltransparencia-common/libs/URLUtils';

export class SolicitacaoEsic extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    findSolicitacaoEsic: PropTypes.func,
    loadTiposProcesso: PropTypes.func,
    abrirRecursoProcesso: PropTypes.func,
    location: PropTypes.object.isRequired,
    filtros: PropTypes.object,
    protocolo: PropTypes.object,
    valorParametroEntidadeProtocolo: PropTypes.string,
    offsidebarToggle: PropTypes.func,
    downloadArquivo: PropTypes.func,
    addAlertNotification: PropTypes.func
  };

  componentDidMount() {
    const {
      location,
      findSolicitacaoEsic,
      valorParametroEntidadeProtocolo,
      addAlertNotification,
      loadTiposProcesso
    } = this.props;

    const params = getAllValuesFromUrlSearchParams(location);

    let entidade = valorParametroEntidadeProtocolo;
    if (!valorParametroEntidadeProtocolo) {
      entidade = params.entidade;
    }

    const exercicio = params.exercicio;
    const tipo = params.tipo;
    const numero = params.numero;
    const senha = params.senha;

    loadTiposProcesso(entidade, exercicio);

    if (exercicio && numero && senha && tipo) {
      addAlertNotification(
        'Anote seus dados',
        `Número: <b>${numero} </b><br> Senha: <b>${senha}</b><br> Ano: <b>${exercicio}</b>`,
        30
      );
      findSolicitacaoEsic(entidade, exercicio, numero, senha, tipo);
    }
  }

  onClickEstatistica = () => {
    this.props.history.push('/esic/estatisticas');
  };

  render() {
    const {
      filtros,
      protocolo,
      offsidebarToggle,
      onChange,
      findSolicitacaoEsic,
      downloadArquivo,
      valorParametroEntidadeProtocolo,
      abrirRecursoProcesso,
      location,
      solicitacaoEsic,
      isIntegradoOxy,
      urlProcessoExterno,
      tiposProcessos
    } = this.props;

    return (
      <section>
        <PageHeader location={this.props.location} />

        <div className="btn-group center mb-xs">
          <button
            className="btn neutral icon-left"
            type="button"
            onClick={this.onClickEstatistica}
            style={{ fontSize: '15px' }}
          >
            <i className="fa fa-bar-chart"></i>
            Estatísticas
          </button>
          <button
            className="btn neutral icon-right"
            type="button"
            onClick={offsidebarToggle}
            style={{ fontSize: '15px' }}
          >
            Solicitar Informações
            <i className="fa fa-plus"></i>
          </button>
        </div>

        {solicitacaoEsic && isIntegradoOxy && urlProcessoExterno && (
          <IFrame url={`${urlProcessoExterno}?iframe=true`}></IFrame>
        )}

        <NotasExplicativas pathname={location.pathname} />

        <ProcessoConsultaFiltro
          filtros={filtros}
          onChange={onChange}
          findSolicitacaoEsic={findSolicitacaoEsic}
          tiposProcessos={tiposProcessos}
          valorParametroEntidadeProtocolo={valorParametroEntidadeProtocolo}
        />

        {protocolo && protocolo.get('processo') && (
          <Processo
            protocolo={protocolo}
            abrirRecursoProcesso={abrirRecursoProcesso}
            offsidebarToggle={offsidebarToggle}
            downloadArquivo={downloadArquivo}
          />
        )}

        {protocolo && !protocolo.get('processo') && (
          <h3 className="mb-xs center">Nenhum processo encontrado</h3>
        )}

        <Panel expansible={false}>
          <FormAtendimento />
        </Panel>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    urlProcessoExterno: state.entidade.getIn([
      'configuracoesGerais',
      'urlProcessoExterno'
    ]),
    isIntegradoOxy: state.entidade.getIn([
      'configuracoesGerais',
      'isIntegradoOxy'
    ]),
    solicitacaoEsic: state.ui.offsidebarToggled,
    filtros: state.esic.getIn(['currentRecord'], Map({})),
    protocolo: state.esic.get('protocolo'),
    valorParametroEntidadeProtocolo: ParametroService.getValorParametro(
      state,
      PARAM_ENTIDADE_PROTOCOLO
    ),
    tiposProcessos: state.esic.get('tiposProcessos')
  };
}

const allActions = {
  ...actions,
  offsidebarToggle,
  downloadArquivo,
  addAlertNotification
};

export default connect(mapStateToProps, allActions)(SolicitacaoEsic);
