import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export default class FormLeisRegemPortal extends Component {
  static propTypes = {
    responsavelLAI: PropTypes.object
  };

  render() {
    const { responsavelLAI } = this.props;

    if (responsavelLAI && responsavelLAI.size > 0) {
      return (
        <div>
          <b>
            Responsável por assegurar o cumprimento da Lei de Acesso à
            Informação(LAI){' '}
          </b>{' '}
          <br />
          <br />
          <div key={responsavelLAI}>
            Nome: {responsavelLAI.get('nome')}
            <br />
            e-Mail: {responsavelLAI.get('email')}
            <br />
            Fone: {responsavelLAI.get('tel')}
            <br />
            <br />
          </div>
        </div>
      );
    }

    return null;
  }
}
