export const ARRECADACAO_DESPESA_MES = 'ARRECADACAO_DESPESA_MES';
export const ARRECADACAO_DESPESA_MES_START = 'ARRECADACAO_DESPESA_MES_START';
export const ARRECADACAO_DESPESA_MES_SUCCESS =
  'ARRECADACAO_DESPESA_MES_SUCCESS';
export const ARRECADACAO_DESPESA_MES_ERROR = 'ARRECADACAO_DESPESA_MES_ERROR';

export const RECEITA_VALOR_ATUALIZADO = 'RECEITA_VALOR_ATUALIZADO';
export const RECEITA_VALOR_ATUALIZADO_START = 'RECEITA_VALOR_ATUALIZADO_START';
export const RECEITA_VALOR_ATUALIZADO_SUCCESS =
  'RECEITA_VALOR_ATUALIZADO_SUCCESS';
export const RECEITA_VALOR_ATUALIZADO_ERROR = 'RECEITA_VALOR_ATUALIZADO_ERROR';
