export const SOLICITACAO_ESIC = 'SOLICITACAO_ESIC';
export const SOLICITACAO_ESIC_START = 'SOLICITACAO_ESIC_START';
export const SOLICITACAO_ESIC_SUCCESS = 'SOLICITACAO_ESIC_SUCCESS';
export const SOLICITACAO_ESIC_ERROR = 'SOLICITACAO_ESIC_ERROR';

export const SOLICITACAO_ESIC_RESET = 'SOLICITACAO_ESIC_RESET';

export const FIND_ESIC = 'FIND_ESIC';
export const FIND_ESIC_START = 'FIND_ESIC_START';
export const FIND_ESIC_SUCCESS = 'FIND_ESIC_SUCCESS';
export const FIND_ESIC_ERROR = 'FIND_ESIC_ERROR';

export const FIND_PESSOA = 'FIND_PESSOA';
export const FIND_PESSOA_START = 'FIND_PESSOA_START';
export const FIND_PESSOA_SUCCESS = 'FIND_PESSOA_SUCCESS';
export const FIND_PESSOA_ERROR = 'FIND_PESSOA_ERROR';

export const FIND_ASSUNTO = 'FIND_ASSUNTO';
export const FIND_ASSUNTO_START = 'FIND_ASSUNTO_START';
export const FIND_ASSUNTO_SUCCESS = 'FIND_ASSUNTO_SUCCESS';
export const FIND_ASSUNTO_ERROR = 'FIND_ASSUNTO_ERROR';

export const CHANGE_FIELD_ESIC_VALUE = 'CHANGE_FIELD_ESIC_VALUE';

export const ESTATISTICAS = 'ESTATISTICAS';
export const ESTATISTICAS_START = 'ESTATISTICAS_START';
export const ESTATISTICAS_SUCCESS = 'ESTATISTICAS_SUCCESS';
export const ESTATISTICAS_ERROR = 'ESTATISTICAS_ERROR';

export const ESTATISTICAS_ANO = 'ESTATISTICAS_ANO';
export const ESTATISTICAS_ANO_START = 'ESTATISTICAS_ANO_START';
export const ESTATISTICAS_ANO_SUCCESS = 'ESTATISTICAS_ANO_SUCCESS';
export const ESTATISTICAS_ANO_ERROR = 'ESTATISTICAS_ANO_ERROR';

export const MEDIA_RESPOSTAS_ANO = 'MEDIA_RESPOSTAS_ANO';
export const MEDIA_RESPOSTAS_ANO_START = 'MEDIA_RESPOSTAS_ANO_START';
export const MEDIA_RESPOSTAS_ANO_SUCCESS = 'MEDIA_RESPOSTAS_ANO_SUCCESS';
export const MEDIA_RESPOSTAS_ANO_ERROR = 'MEDIA_RESPOSTAS_ANO_ERROR';

export const MEDIA_RESPOSTAS = 'MEDIA_RESPOSTAS';
export const MEDIA_RESPOSTAS_START = 'MEDIA_RESPOSTAS_START';
export const MEDIA_RESPOSTAS_SUCCESS = 'MEDIA_RESPOSTAS_SUCCESS';
export const MEDIA_RESPOSTAS_ERROR = 'MEDIA_RESPOSTAS_ERROR';

export const ESIC_LOAD_ESCOLARIDADE = 'ESIC_LOAD_ESCOLARIDADE';
export const ESIC_LOAD_ESCOLARIDADE_START = 'ESIC_LOAD_ESCOLARIDADE_START';
export const ESIC_LOAD_ESCOLARIDADE_SUCCESS = 'ESIC_LOAD_ESCOLARIDADE_SUCCESS';
export const ESIC_LOAD_ESCOLARIDADE_ERROR = 'ESIC_LOAD_ESCOLARIDADE_ERROR';

export const ESTATISTICAS_PROCESSO_POR_GENERO =
  'ESTATISTICAS_PROCESSO_POR_GENERO';
export const ESTATISTICAS_PROCESSO_POR_GENERO_START =
  'ESTATISTICAS_PROCESSO_POR_GENERO_START';
export const ESTATISTICAS_PROCESSO_POR_GENERO_SUCCESS =
  'ESTATISTICAS_PROCESSO_POR_GENERO_SUCCESS';
export const ESTATISTICAS_PROCESSO_POR_GENERO_ERROR =
  'ESTATISTICAS_PROCESSO_POR_GENERO_ERROR';

export const ESTATISTICAS_PROCESSO_POR_ESCOLARIDADE =
  'ESTATISTICAS_PROCESSO_POR_ESCOLARIDADE';
export const ESTATISTICAS_PROCESSO_POR_ESCOLARIDADE_START =
  'ESTATISTICAS_PROCESSO_POR_ESCOLARIDADE_START';
export const ESTATISTICAS_PROCESSO_POR_ESCOLARIDADE_SUCCESS =
  'ESTATISTICAS_PROCESSO_POR_ESCOLARIDADE_SUCCESS';
export const ESTATISTICAS_PROCESSO_POR_ESCOLARIDADE_ERROR =
  'ESTATISTICAS_PROCESSO_POR_ESCOLARIDADE_ERROR';

export const ESTATISTICAS_PROCESSO_POR_IDADE =
  'ESTATISTICAS_PROCESSO_POR_IDADE';
export const ESTATISTICAS_PROCESSO_POR_IDADE_START =
  'ESTATISTICAS_PROCESSO_POR_IDADE_START';
export const ESTATISTICAS_PROCESSO_POR_IDADE_SUCCESS =
  'ESTATISTICAS_PROCESSO_POR_IDADE_SUCCESS';
export const ESTATISTICAS_PROCESSO_POR_IDADE_ERROR =
  'ESTATISTICAS_PROCESSO_POR_IDADE_ERROR';

export const RECURSO_PROCESSO = 'RECURSO_PROCESSO';

export const PULAR_TIPO_SOLICITACAO = 'PULAR_TIPO_SOLICITACAO';

export const ESIC_LOAD_TIPOPROCESSO = 'ESIC_LOAD_TIPOPROCESSO';
export const ESIC_LOAD_TIPOPROCESSO_START = 'ESIC_LOAD_TIPOPROCESSO_START';
export const ESIC_LOAD_TIPOPROCESSO_SUCCESS = 'ESIC_LOAD_TIPOPROCESSO_SUCCESS';
export const ESIC_LOAD_TIPOPROCESSO_ERROR = 'ESIC_LOAD_TIPOPROCESSO_ERROR';

export const RELATORIO_ANUAL_ESTATISTICA = 'RELATORIO_ANUAL_ESTATISTICA';
export const RELATORIO_ANUAL_ESTATISTICA_START =
  'RELATORIO_ANUAL_ESTATISTICA_START';
export const RELATORIO_ANUAL_ESTATISTICA_SUCCESS =
  'RELATORIO_ANUAL_ESTATISTICA_SUCCESS';
export const RELATORIO_ANUAL_ESTATISTICA_ERROR =
  'RELATORIO_ANUAL_ESTATISTICA_ERROR';
