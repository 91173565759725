import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';

import * as types from './Types.js';

const initialState = fromJS({
  diarias: [],
  diaria: {},
  arquivos: [],
  filtros: [],
  totalDiarias: {},
  objetivosDiarias: [],
  secretariasDiarias: [],
  diariasWithoutQuantidade: []
});

function diariaReducer(state = initialState, action) {
  switch (action.type) {
    case types.DIARIAS_LIST_START:
    case types.DIARIA_START:
    case types.ARQUIVOS_START:
    case types.TOTAL_DIARIAS_START:
    case types.OBJETIVO_DIARIAS_START:
    case types.SECRETARIA_DIARIAS_START:
    case types.DIARIAS_WITHOUT_QUANTIDADE_LIST_START: {
      return startLoad(state);
    }

    case types.DIARIAS_LIST_ERROR:
    case types.DIARIA_ERROR:
    case types.ARQUIVOS_ERROR:
    case types.TOTAL_DIARIAS_ERROR:
    case types.OBJETIVO_DIARIAS_ERROR:
    case types.SECRETARIA_DIARIAS_ERROR:
    case types.DIARIAS_WITHOUT_QUANTIDADE_LIST_ERROR: {
      return endLoad(state);
    }

    case types.DIARIAS_LIST_SUCCESS: {
      return endLoad(state.set('diarias', fromJS(action.payload)));
    }
    case types.DIARIA_SUCCESS: {
      return endLoad(state.set('diaria', fromJS(action.payload)));
    }
    case types.ARQUIVOS_SUCCESS: {
      return endLoad(state.set('arquivos', fromJS(action.payload)));
    }
    case types.TOTAL_DIARIAS_SUCCESS: {
      return endLoad(state.set('totalDiarias', fromJS(action.payload)));
    }
    case types.OBJETIVO_DIARIAS_SUCCESS: {
      return endLoad(state.set('objetivosDiarias', fromJS(action.payload)));
    }
    case types.SECRETARIA_DIARIAS_SUCCESS: {
      return endLoad(state.set('secretariasDiarias', fromJS(action.payload)));
    }
    case types.DIARIAS_WITHOUT_QUANTIDADE_LIST_SUCCESS: {
      return endLoad(
        state.set('diariasWithoutQuantidade', fromJS(action.payload))
      );
    }

    case types.SELECT_CODIGO_SECRETARIA: {
      return state.set(
        'codigoSecretariaSelecionada',
        fromJS(action.payload.value)
      );
    }

    case types.RESET_CONSULTA_DIARIAS: {
      return initialState;
    }
  }

  return state;
}

export default diariaReducer;
