import StorageService from 'portaltransparencia-common/libs/StorageService.js';

import * as types from './Types';

export function findConselhos() {
  return ({ fetch }) => {
    let userInfo = StorageService.getItem('userInfo');
    const { exercicio } = userInfo;

    return {
      type: types.PESQUISA_CONSELHOS,
      payload: {
        promise: fetch(`/api/conselhos/${exercicio.id}`)
      }
    };
  };
}

export function findConselho(id) {
  return ({ fetch }) => {
    let userInfo = StorageService.getItem('userInfo');
    const { exercicio } = userInfo;

    return {
      type: types.PESQUISA_CONSELHO,
      payload: {
        promise: fetch(`/api/conselhos/${exercicio.id}/${id}`)
      }
    };
  };
}

export function filterConselhos(filtro) {
  return ({ getState }) => {
    const conselhos = getState().conselho.get('conselhosFiltrados');

    return {
      type: types.FILTRAR_CONSELHOS,
      payload: conselhos,
      meta: filtro
    };
  };
}

export function resetConselhos() {
  return {
    type: types.RESETAR_CONSELHOS
  };
}
