import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';
import ListUtils from 'portaltransparencia-common/libs/ListUtils.js';

import * as types from './Types.js';

const initialState = fromJS({
  documentos: [],
  documentosFiltrados: []
});

function documentoReducer(state = initialState, action) {
  switch (action.type) {
    case types.PESQUISA_DOCUMENTOS_START:
      return startLoad(state);

    case types.PESQUISA_DOCUMENTOS_SUCCESS: {
      return endLoad(
        state
          .set('documentos', fromJS(action.payload))
          .set('documentosFiltrados', fromJS(action.payload))
      );
    }

    case types.FILTRAR_DOCUMENTOS: {
      const listaFiltrada = ListUtils.filterTree(
        fromJS(action.payload),
        action.meta,
        ['nome'],
        'list'
      );
      return state.set('documentosFiltrados', listaFiltrada);
    }

    case types.RESETAR_DOCUMENTOS: {
      const documentos = state.get('documentos').toJS();
      return state.set('documentosFiltrados', fromJS(documentos));
    }
  }

  return state;
}

export default documentoReducer;
