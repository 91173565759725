import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';

import * as types from './Types.js';

const initialState = fromJS({
  lotacoes: [],
  detalhes: []
});

function verbasReducer(state = initialState, action) {
  switch (action.type) {
    case types.VERBAS_START:
    case types.VERBAS_DETALHES_START: {
      return startLoad(state);
    }

    case types.VERBAS_SUCCESS: {
      return endLoad(state.set('lotacoes', fromJS(action.payload)));
    }

    case types.VERBAS_DETALHES_SUCCESS: {
      return endLoad(state.set('detalhes', fromJS(action.payload)));
    }

    case types.VERBAS_ERROR: {
      return endLoad(state);
    }

    case types.VERBAS_DETALHES_ERROR: {
      return endLoad(state);
    }
  }
  return state;
}

export default verbasReducer;
