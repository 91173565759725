import Panel from 'portaltransparencia-common/components/Panel.react.js';
import {
  addFilterUrl,
  addPagination
} from 'portaltransparencia-common/libs/AddFilter';
import {
  onChange,
  resetCrud
} from 'portaltransparencia-common/crud/Actions.js';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';
import PageHeader from '../components/PageHeader.react.js';
import NotasExplicativas from '../notaexplicativa/Form.react';
import * as actions from './Actions.js';
import { changeFieldValue } from 'portaltransparencia-common/crud/Actions.js';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import Fields from 'portaltransparencia-common/libs/Fields.js';
import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import TableHeaderItem from 'portaltransparencia-common/components/TableHeaderItem.react';
import { compactText } from 'portaltransparencia-common/libs/TextCompact.js';
import configuration from 'portaltransparencia-common/config/Config.js';
import Input from 'portaltransparencia-common/components/EloInput.react.js';
import FilterButtons from '../components/FilterButtons.react.js';
import UltimaAtualizacaoByTable from '../ultimaatualizacao/UltimaAtualizacaoByTable.react.js';
import ExportButtonGroup from 'portaltransparencia-common/components/ExportButtonGroup.react.js';
import SearchPagination from 'portaltransparencia-common/components/SearchPagination.react.js';
import { Map, fromJS } from 'immutable';
import { addErrorNotification } from 'portaltransparencia-common/ui/Actions.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import DatePicker from 'portaltransparencia-common/components/DatePicker.react.js';

export class FormAcordoSemRecurso extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    page: PropTypes.object.isRequired,
    searchAcordos: PropTypes.func.isRequired,
    changeFieldValue: PropTypes.func.isRequired,
    exportaAcordo: PropTypes.func,
    filtros: PropTypes.object,
    onChange: PropTypes.func,
    resetCrud: PropTypes.func.isRequired,
    addErrorNotification: PropTypes.func.isRequired,
    tipoSituacao: PropTypes.object.isRequired
  };

  componentDidMount() {
    this.onResetFiltro('filtroAcordos');
  }

  onChangeFiltro = event => {
    const { onChange } = this.props;

    onChange('filtroAcordos', event);
  };

  onResetFiltro = () => {
    const { resetCrud, searchAcordos } = this.props;
    resetCrud('filtroAcordos');
    let userInfo = StorageService.getItem('userInfo');
    const { entidade } = userInfo;

    let filter = `${`entidade=${entidade.id}`}`;

    searchAcordos(filter);
  };

  montaFilter = function (page) {
    const { entidade } = StorageService.getItem('userInfo');

    let filter = `${`entidade=${entidade.id}`}`;

    if (this.refs.nomeFornecedor && this.refs.nomeFornecedor.getValue()) {
      filter = addFilterUrl(
        filter,
        'nomeFornecedor=' + this.refs.nomeFornecedor.getValue()
      );
    }
    if (this.refs.tipo && this.refs.tipo.getValue()) {
      filter = addFilterUrl(filter, 'tipo=' + this.refs.tipo.getValue());
    }
    if (this.refs.situacao && this.refs.situacao.getValue()) {
      filter = addFilterUrl(
        filter,
        'situacao=' + this.refs.situacao.getValue()
      );
    }
    if (this.refs.numero && this.refs.numero.getValue()) {
      filter = addFilterUrl(filter, 'numero=' + this.refs.numero.getValue());
    }
    if (this.refs.ano && this.refs.ano.getValue()) {
      filter = addFilterUrl(filter, 'ano=' + this.refs.ano.getValue());
    }
    if (this.refs.dataInicio && this.refs.dataInicio.getValue().length == 10) {
      filter = addFilterUrl(
        filter,
        'dataInicio=' + this.refs.dataInicio.getValue()
      );
    }
    if (
      this.refs.dataTermino &&
      this.refs.dataTermino.getValue().length == 10
    ) {
      filter = addFilterUrl(
        filter,
        'dataTermino=' + this.refs.dataTermino.getValue()
      );
    }
    if (this.refs.objeto && this.refs.objeto.getValue()) {
      filter = addFilterUrl(filter, 'objeto=' + this.refs.objeto.getValue());
    }

    return addPagination(page, filter);
  };

  onConsultaAcordos = event => {
    const { page } = this.props;

    const initialPage = page.set('number', 0);

    this.searchWithPage(initialPage);

    event && event.preventDefault();
    this.refs.panelFiltroAcordos && this.refs.panelFiltroAcordos.collapse();
  };

  searchWithPage = page => {
    const { searchAcordos } = this.props;

    let filter = this.montaFilter(page);

    searchAcordos(filter);
  };

  onChange = event => {
    const { changeFieldValue } = this.props;

    Fields.fireChange(changeFieldValue, event);

    this.onChangeFiltro(event);
  };

  onViewDetalhes = item => {
    const id = item.get('id');

    this.props.history.push(`acordos-sem-recurso/${id}`);
  };

  renderAcordos = data => {
    const key = `${data.get('entidade')}_${data.get('exercicio')}_${data.get(
      'id'
    )}}`;

    return (
      <TableActions
        ref="row"
        key={key}
        data={data}
        onMore={this.onViewDetalhes}
      >
        <td>{data.get('numero')}</td>
        <td>{data.get('ano')}</td>
        <td>{data.get('tipo')}</td>
        <td>{data.getIn(['fornecedor', 'nome'])}</td>
        <td>{data.get('situacao')}</td>
        <td>{DateUtils.formatDateTimeShort(data.get('dataInicio'))} </td>
        <td>{DateUtils.formatDateTimeShort(data.get('dataTermino'))} </td>
        <td title={data.get('objeto')}>
          {compactText(data.get('objeto'), 110)}
        </td>
      </TableActions>
    );
  };

  onExporta = (exportType, openTab, windowParams) => {
    const { page, addErrorNotification } = this.props;
    const { apiURL } = configuration;

    if (page.get('content').size > 0) {
      let filtro = this.montaFilter(page) + '&exportType=' + exportType;

      window.open(
        `${apiURL}/api/acordos-sem-recurso/report?${filtro}`,
        openTab,
        windowParams
      );
    } else {
      addErrorNotification(
        'A consulta não apresentou resultado. Não é possível exportar para o formato selecionado!'
      );
    }
  };

  renderCombo = item => {
    return (
      <option key={item} value={item}>
        {item}
      </option>
    );
  };

  render() {
    const { page, filtros, tipoSituacao } = this.props;

    if (!page.has('content')) {
      return null;
    }

    return (
      <section>
        <PageHeader location={this.props.location} />

        <NotasExplicativas pathname={this.props.location.pathname} />

        <Panel
          title="Consulta Acordos Sem Recursos Financeiros"
          isForm
          mobileClose
          ref="panelFiltroAcordos"
        >
          <form>
            <Row>
              <Col sm={8}>
                <Input
                  id="nomeFornecedor"
                  name="nomeFornecedor"
                  ref="nomeFornecedor"
                  placeholder="Parte Contratada"
                  label="Parte Contratada"
                  type="text"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('nomeFornecedor')}
                />
              </Col>
              <Col sm={4}>
                <Input
                  id="tipo"
                  name="tipo"
                  ref="tipo"
                  placeholder="Tipo"
                  label="Tipo"
                  type="text"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('tipo')}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={4}>
                <Input
                  id="situacao"
                  name="situacao"
                  ref="situacao"
                  label="Situação"
                  type="select"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('situacao')}
                >
                  <option value="">Todas</option>
                  {tipoSituacao.map(this.renderCombo)}
                </Input>
              </Col>
              <Col sm={2}>
                <Input
                  id="numero"
                  name="numero"
                  ref="numero"
                  placeholder="Número"
                  label="Número"
                  type="number"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('numero')}
                />
              </Col>
              <Col sm={2}>
                <Input
                  id="ano"
                  name="ano"
                  ref="ano"
                  placeholder="Ano"
                  label="Ano"
                  type="number"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('ano')}
                />
              </Col>
              <Col sm={2}>
                <DatePicker
                  id="dataInicio"
                  name="dataInicio"
                  ref="dataInicio"
                  label="Data Início"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('dataInicio')}
                />
              </Col>
              <Col sm={2}>
                <DatePicker
                  id="dataTermino"
                  name="dataTermino"
                  ref="dataTermino"
                  label="Data Término"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('dataTermino')}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <Input
                  id="objeto"
                  type="text"
                  ref="objeto"
                  name="objeto"
                  placeholder="Objeto"
                  label="Objeto"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('objeto')}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={4}>
                <FilterButtons
                  idBtnReset="btnResetFilter"
                  onConsulta={this.onConsultaAcordos}
                  onReset={this.onResetFiltro}
                />
              </Col>
            </Row>
          </form>
        </Panel>

        <Panel isForm expansible={false} tableResponsive>
          <Row>
            <Col xs={4}>
              <UltimaAtualizacaoByTable
                modulo="3"
                table="eloportalacordosemrecurso"
                column="dataultimaatualizacao"
              />
            </Col>
            <Col xs={8}>
              <ExportButtonGroup onClick={this.onExporta} visible={true} />
            </Col>
          </Row>

          <div className="panel-table-outer">
            <table className="panel-table striped fancy">
              <TableHeader
                text="Não foi encontrada nenhuma acordo."
                empty={page.get('content').isEmpty()}
              >
                <tr>
                  <TableHeaderItem
                    field="numero"
                    label="Número"
                    page={page}
                    searchWithPage={this.searchWithPage}
                  />
                  <TableHeaderItem
                    field="ano"
                    label="Ano"
                    page={page}
                    searchWithPage={this.searchWithPage}
                  />
                  <TableHeaderItem
                    field="tipo"
                    label="Tipo"
                    page={page}
                    searchWithPage={this.searchWithPage}
                  />
                  <TableHeaderItem
                    field="nomeFornecedor"
                    label="Parte Contratada"
                    page={page}
                    searchWithPage={this.searchWithPage}
                  />
                  <TableHeaderItem
                    field="situacao"
                    label="Situacao"
                    page={page}
                    searchWithPage={this.searchWithPage}
                  />
                  <TableHeaderItem
                    field="dataInicio"
                    label="Início"
                    page={page}
                    searchWithPage={this.searchWithPage}
                  />
                  <TableHeaderItem
                    field="dataTermino"
                    label="Término"
                    page={page}
                    searchWithPage={this.searchWithPage}
                    alignment="right"
                  />
                  <TableHeaderItem
                    field="objeto"
                    label="Objeto"
                    page={page}
                    searchWithPage={this.searchWithPage}
                    alignment="right"
                  />
                  <th />
                </tr>
              </TableHeader>
              <tbody>{page.get('content').map(this.renderAcordos)}</tbody>
            </table>
          </div>
        </Panel>

        <SearchPagination page={page} searchWithPage={this.searchWithPage} />
      </section>
    );
  }
}

function mapStateToProps(state) {
  const tipoSituacao = fromJS([
    'Em Andamento',
    'Cancelado',
    'Paralisado',
    'Concluído'
  ]);

  return {
    page: state.acordoSemRecurso.get('acordos'),
    filtros: state.crud.getIn(['filtroAcordos', 'currentRecord'], Map({})),
    tipoSituacao: tipoSituacao
  };
}

const allActions = {
  ...actions,
  changeFieldValue,
  onChange,
  resetCrud,
  addErrorNotification
};

export default connect(mapStateToProps, allActions)(FormAcordoSemRecurso);
