import * as types from './Types.js';

export function findDividasAtivas(filter) {
  return ({ fetch }) => ({
    type: types.DIVIDAS_ATIVAS,
    payload: {
      promise: fetch(`/api/dividas-ativas?${filter}`)
    }
  });
}

export function findDividasAtivasContribuinte(cnpjCpf) {
  return ({ fetch }) => ({
    type: types.DIVIDAS_ATIVAS_CONTRIBUINTE,
    payload: {
      promise: fetch(`/api/dividas-ativas/contribuinte?cnpjCpfDB=${cnpjCpf}`)
    }
  });
}

export function findDividasAtivasTotal(filter) {
  return ({ fetch }) => ({
    type: types.DIVIDAS_ATIVAS_TOTAL,
    payload: {
      promise: fetch(`/api/dividas-ativas/total?${filter}`)
    }
  });
}
