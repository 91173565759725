import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';

import * as types from './Types.js';

const initialState = fromJS({
  pageRecebidos: [],
  repassesMes: [],
  filtros: []
});

function repasseReducer(state = initialState, action) {
  switch (action.type) {
    case types.REPASSES_RECEBIDOS_PAGE_START:
    case types.REPASSES_MES_START: {
      return startLoad(state);
    }

    case types.REPASSES_RECEBIDOS_PAGE_ERROR:
    case types.REPASSES_MES_ERROR: {
      return endLoad(state);
    }

    case types.REPASSES_RECEBIDOS_PAGE_SUCCESS: {
      return endLoad(state.set('pageRecebidos', fromJS(action.payload)));
    }

    case types.REPASSES_MES_SUCCESS: {
      return endLoad(state.set('repassesMes', fromJS(action.payload)));
    }
  }

  return state;
}

export default repasseReducer;
