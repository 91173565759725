import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export class LicitacaoDetalhePublicacao extends Component {
  static propTypes = {
    publicacoes: PropTypes.object.isRequired,
    onDownload: PropTypes.func.isRequired
  };

  renderPublicacoes = data => {
    const { onDownload } = this.props;
    const key = data.get('orgaoPublicacao') + '_' + data.get('tipoPublicacao');

    return (
      <TableActions
        ref="row"
        key={key}
        data={data}
        onDownload={data.get('idArquivo') && onDownload}
      >
        <td>{data.get('orgaoPublicacao')}</td>
        <td>{DateUtils.formatDateTimeShort(data.get('dataPublicacao'))}</td>
        <td>{data.get('tipoPublicacao')}</td>
      </TableActions>
    );
  };

  render() {
    const { publicacoes } = this.props;

    return (
      <div className="panel-table-outer">
        <table className="panel-table striped fancy">
          <TableHeader empty={publicacoes.isEmpty()}>
            <tr>
              <th>Órgão Publicação</th>
              <th>Data</th>
              <th>Tipo Publicação</th>
              <th></th>
            </tr>
          </TableHeader>
          <tbody>{publicacoes.map(this.renderPublicacoes)}</tbody>
        </table>
      </div>
    );
  }
}

export default LicitacaoDetalhePublicacao;
