import { Map } from 'immutable';
import {
  onChange,
  resetCrud
} from 'portaltransparencia-common/crud/Actions.js';
import {
  addFilterUrl,
  addPagination
} from 'portaltransparencia-common/libs/AddFilter.js';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import PageHeader from '../components/PageHeader.react.js';
import NotasExplicativas from '../notaexplicativa/Form.react';
import * as actions from './Actions.js';
import FornecedorFiltro from './FornecedorFiltro.react.js';
import FornecedorTable from './FornecedorTable.react.js';
import configuration from 'portaltransparencia-common/config/Config.js';
import { addErrorNotification } from 'portaltransparencia-common/ui/Actions.js';

export class FornecedoresAPagar extends React.Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    page: PropTypes.object.isRequired,
    totais: PropTypes.object,
    findFornecedoresAPagar: PropTypes.func.isRequired,
    findFornecedoresAPagarTotais: PropTypes.func.isRequired,
    filtros: PropTypes.object,
    location: PropTypes.object.isRequired,
    resetCrud: PropTypes.func.isRequired,
    addErrorNotification: PropTypes.func.isRequired
  };

  componentDidMount() {
    const { page } = this.props;
    this.onConsultaWithPage(page);
  }

  onConsultaWithPage = page => {
    const { findFornecedoresAPagar, findFornecedoresAPagarTotais } = this.props;
    let filter = this.montaFiltro(page);

    findFornecedoresAPagar(filter);
    findFornecedoresAPagarTotais(filter);
  };

  montaFiltro = page => {
    const userInfo = StorageService.getItem('userInfo');
    const { entidade, exercicio } = userInfo;
    const { filtros } = this.props;
    let filter = 'entidade=' + entidade.id + '&exercicio=' + exercicio.id;

    if (filtros.get('nome')) {
      filter = addFilterUrl(
        filter,
        `nome=${encodeURIComponent(filtros.get('nome'))}`
      );
    }
    if (filtros.get('cnpj')) {
      filter = addFilterUrl(filter, `cnpjCpf=${filtros.get('cnpj')}`);
    }

    return addPagination(page, filter);
  };

  onExporta = (exportType, openTab, windowParams) => {
    const { page, addErrorNotification } = this.props;
    const { apiURL } = configuration;

    if (page.get('content').size > 0) {
      let filtro = this.montaFiltro(page) + '&exportType=' + exportType;
      window.open(
        `${apiURL}/fornecedorapagar/report?${filtro}`,
        openTab,
        windowParams
      );
    } else {
      addErrorNotification(
        'A consulta não apresentou resultado. Não é possível exportar para o formato selecionado!'
      );
    }
  };

  render() {
    const {
      filtros,
      onChange,
      findFornecedoresAPagar,
      findFornecedoresAPagarTotais,
      page,
      totais,
      resetCrud
    } = this.props;
    const filtroFornecedor = 'filtroFornecedorAPagar';
    return (
      <section>
        <PageHeader location={this.props.location} />

        <NotasExplicativas pathname={this.props.location.pathname} />

        <FornecedorFiltro
          filtros={filtros}
          montaFiltro={this.montaFiltro}
          onChange={onChange}
          page={page}
          findFornecedoresTotais={findFornecedoresAPagarTotais}
          findFornecedores={findFornecedoresAPagar}
          onConsultaWithPage={this.onConsultaWithPage}
          resetCrud={resetCrud}
          filtroFornecedor={filtroFornecedor}
        />

        <FornecedorTable
          montaFiltro={this.montaFiltro}
          page={page}
          history={this.props.history}
          onConsultaWithPage={this.onConsultaWithPage}
          totais={totais}
          onExporta={this.onExporta}
        />
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    page: state.fornecedoresAPagar.get('fornecedoresAPagar'),
    totais: state.fornecedoresAPagar.get('totais'),
    filtros: state.crud.getIn(
      ['filtroFornecedorAPagar', 'currentRecord'],
      Map({})
    )
  };
}

const allActions = {
  ...actions,
  onChange,
  resetCrud,
  addErrorNotification
};

export default connect(mapStateToProps, allActions)(FornecedoresAPagar);
