import { addHttp } from 'portaltransparencia-common/libs/LinkExterno';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import { Link } from 'react-router-dom';

import { getRoute } from '../menu/UrlAuxiliar';

export class ItemLink extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    style: PropTypes.object
  };

  render() {
    const { item, style } = this.props;

    return (
      <div style={style}>
        {!item.get('link') && (
          <Link to={getRoute(item)}>{item.get('title')}</Link>
        )}
        {item.get('link') && (
          <a href={addHttp(item.get('link'))} target="_blank">
            {item.get('title')}
          </a>
        )}

        {item.get('subtitle') && (
          <small>
            <i> - {item.get('subtitle')}</i>
          </small>
        )}

        {item.get('atalho') && <b> - {item.get('atalho')} </b>}
      </div>
    );
  }
}
export default ItemLink;
