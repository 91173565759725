import Fields from 'portaltransparencia-common/libs/Fields.js';
import {
  addErrorNotification,
  unSetInMaintance
} from 'portaltransparencia-common/ui/Actions.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import * as actionsMenu from '../menu/Actions.js';
import * as actionsParametros from '../parametros/Actions.js';
import * as actions from './Actions.js';

export class FormEntidade extends Component {
  static propTypes = {
    entidades: PropTypes.object.isRequired,
    findEntidades: PropTypes.func.isRequired,
    brasao: PropTypes.string.isRequired,

    exercicios: PropTypes.object,
    findExerciciosByEntidade: PropTypes.func.isRequired,

    exercicioLogado: PropTypes.object,
    acExercicioLogado: PropTypes.func.isRequired,

    userInfo: PropTypes.object.isRequired,
    addErrorNotification: PropTypes.func.isRequired,

    location: PropTypes.object.isRequired,
    unSetInMaintance: PropTypes.func.isRequired
  };

  componentDidMount() {
    const { userInfo, findEntidades, findExerciciosByEntidade } = this.props;

    findEntidades().then(() =>
      findExerciciosByEntidade(userInfo.getIn(['entidade', 'id']))
    );
  }

  renderEntidades = () => {
    const { entidades } = this.props;

    return entidades.map(data => {
      return (
        <option
          key={data.get('id')}
          label={data.get('nome')}
          value={data.get('id')}
        >
          {data.get('nome')}
        </option>
      );
    });
  };

  renderExercicio = () => {
    const { exercicios } = this.props;

    return exercicios.map(e => {
      return (
        <option
          key={e.getIn(['id', 'exercicio'])}
          value={e.getIn(['id', 'exercicio'])}
        >
          {e.getIn(['id', 'exercicio'])}
        </option>
      );
    });
  };

  onChangeEntidade = event => {
    const {
      findExerciciosByEntidade,
      acExercicioLogado,
      userInfo,
      addErrorNotification,
      unSetInMaintance,
      history
    } = this.props;

    const entidade = Fields.getValue(event);
    this.refs.exercicio.value = '';

    findExerciciosByEntidade(entidade)
      .then(({ value }) => {
        if (value && value.length > 0) {
          acExercicioLogado(entidade, value[0].id.exercicio);
        } else {
          addErrorNotification('Entidade não possui nenhum exercício.');

          const entidade = userInfo.getIn(['entidade', 'id']);
          findExerciciosByEntidade(entidade).then(({ value }) => {
            acExercicioLogado(entidade, value[0].id.exercicio);
          });
        }
      })
      .then(() => unSetInMaintance())
      .then(
        () =>
          (window.location.href = `${window.location.origin}${
            process.env.PUBLIC_URL || '/'
          }`)
      );
  };

  onChangeExercicio = event => {
    const exercicio = Fields.getValue(event);

    const { acExercicioLogado, unSetInMaintance } = this.props;
    const { entidade } = this.refs;

    unSetInMaintance();

    acExercicioLogado(entidade.value, exercicio);

    refreshPage();
  };

  render() {
    const { entidades, brasao, userInfo } = this.props;

    if (entidades.isEmpty()) {
      return null;
    }

    return (
      <div>
        {brasao && (
          <div id="brasao" key={brasao} className="logo-img">
            <img src={`data:image/*;base64,${brasao}`} />
          </div>
        )}
        <b className="change-entity-label entidade">Entidade:</b>
        <select
          id="entidade"
          name="entidade"
          required
          className="form-control control-label"
          onChange={this.onChangeEntidade}
          ref="entidade"
          value={userInfo.getIn(['entidade', 'id'])}
        >
          {this.renderEntidades()}
        </select>

        <b className="change-entity-label ano">Ano:</b>
        <select
          id="ano"
          name="exercicio"
          required
          className="form-control"
          onChange={this.onChangeExercicio}
          ref="exercicio"
          value={userInfo.getIn(['exercicio', 'id'])}
        >
          {this.renderExercicio()}
        </select>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    entidades: state.entidade.get('entidades'),
    brasao: state.entidade.get('brasao'),
    exercicios: state.entidade.get('exercicios'),
    userInfo: state.auth.get('userInfo')
  };
}

function refreshPage() {
  window.location.reload(false);
}

const allActions = {
  ...actions,
  ...actionsMenu,
  ...actionsParametros,
  addErrorNotification,
  unSetInMaintance
};

export default connect(mapStateToProps, allActions)(withRouter(FormEntidade));
