export const RECEITA_ARRECADACAO = 'RECEITA_ARRECADACAO';
export const RECEITA_ARRECADACAO_START = 'RECEITA_ARRECADACAO_START';
export const RECEITA_ARRECADACAO_SUCCESS = 'RECEITA_ARRECADACAO_SUCCESS';
export const RECEITA_ARRECADACAO_ERROR = 'RECEITA_ARRECADACAO_ERROR';

export const TOTAL_DIVIDA_ATIVA = 'TOTAL_DIVIDA_ATIVA';
export const TOTAL_DIVIDA_ATIVA_START = 'TOTAL_DIVIDA_ATIVA_START';
export const TOTAL_DIVIDA_ATIVA_SUCCESS = 'TOTAL_DIVIDA_ATIVA_SUCCESS';
export const TOTAL_DIVIDA_ATIVA_ERROR = 'TOTAL_DIVIDA_ATIVA_ERROR';

export const RECEITA_ARRECADACAO_BAIRROS = 'RECEITA_ARRECADACAO_BAIRROS';
export const RECEITA_ARRECADACAO_BAIRROS_START =
  'RECEITA_ARRECADACAO_BAIRROS_START';
export const RECEITA_ARRECADACAO_BAIRROS_SUCCESS =
  'RECEITA_ARRECADACAO_BAIRROS_SUCCESS';
export const RECEITA_ARRECADACAO_BAIRROS_ERROR =
  'RECEITA_ARRECADACAO_BAIRROS_ERROR';

export const BAIRROS = 'BAIRROS';
export const BAIRROS_START = 'BAIRROS_START';
export const BAIRROS_SUCCESS = 'BAIRROS_SUCCESS';
export const BAIRROS_ERROR = 'BAIRROS_ERROR';

export const FILTRAR_ARRECADACOES = 'FILTRAR_ARRECADACOES';
export const RESETAR_ARRECADACOES = 'RESETAR_ARRECADACOES';
