import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export default class ServidorRowOxy extends Component {
  static propTypes = {
    servidor: PropTypes.object.isRequired,
    usaWebService: PropTypes.bool.isRequired
  };

  onViewDetalhes = item => {
    const entidadeOrigem = item.get('entidade') ? item.get('entidade') : 0;

    this.props.history.push(
      `/servidores/detalhes?vinculo=${item.get('vinculo')}&matricula=${item.get(
        'matricula'
      )}&entidadeOrigem=${entidadeOrigem}`
    );
  };

  render() {
    const { servidor, usaWebService } = this.props;
    const key = `${servidor.get('entidade')}_${servidor.get('matricula')}`;

    return (
      <TableActions
        ref="row"
        key={key}
        data={servidor}
        onMore={this.onViewDetalhes}
      >
        <td id="tableNome">{servidor.get('nome')}</td>
        <td id="tableMatricula">{servidor.get('matricula')}</td>
        <td id="tableSituacao">{servidor.get('situacao')}</td>
        <td id="tableDescricaoCargo">{servidor.get('descricaoCargo')}</td>
        {!usaWebService && (
          <td id="tableDescricaoClasse">{servidor.get('descricaoClasse')}</td>
        )}
        <td id="tableDescricaoLotacao">{servidor.get('descricaoLotacao')}</td>
      </TableActions>
    );
  }
}
