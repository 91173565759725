import * as types from './Types.js';

export function findComprasDiretasItens(filtro) {
  return ({ fetch }) => ({
    type: types.COMPRAS_DIRETA_ITENS,
    payload: {
      promise: fetch(`/api/compras-direta/itens?${filtro}`)
    }
  });
}
