import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export class LicitacaoDetalheCotacao extends Component {
  static propTypes = {
    cotacoes: PropTypes.object.isRequired,
    onDownload: PropTypes.func.isRequired
  };

  renderCotacoesArquivo = cotacaoKey => arquivo => {
    if (arquivo == null || arquivo.get('id') == null) {
      return null;
    }

    const { onDownload } = this.props;
    const key = cotacaoKey + '_' + arquivo.get('id');

    return (
      <TableActions
        key={key}
        data={arquivo.get('id')}
        onDownload={arquivo.get('id') && onDownload}
      >
        <td>{arquivo.get('nome')}</td>
      </TableActions>
    );
  };

  renderCotacoesArquivos = (key, arquivos) => {
    if (!arquivos?.length) {
      return null;
    }

    return (
      <table className="panel-table child-table">
        <tbody>
          {arquivos
            .sort((a, b) => {
              let nomeA = (a.nome || '').toUpperCase();
              let nomeB = (b.nome || '').toUpperCase();
              return nomeA < nomeB ? -1 : nomeA > nomeB ? 1 : 0;
            })
            .map(this.renderCotacoesArquivo(key))}
        </tbody>
      </table>
    );
  };

  renderCotacoes = data => {
    const key =
      data.get('cotacao') + '_' + data.get('pessoa') + '_' + data.get('data');

    return (
      <tr ref="row" key={key}>
        <td>{data.get('nome')}</td>
        <td>{data.get('cnpjCpf')}</td>
        <td>{DateUtils.formatDateTimeShort(data.get('data'))}</td>
        <td>
          {data.get('arquivos').length > 0
            ? this.renderCotacoesArquivos(key, data.get('arquivos'))
            : 'Sem Arquivos'}
        </td>
      </tr>
    );
  };

  render() {
    const { cotacoes } = this.props;

    return (
      <div className="panel-table-outer">
        <table className="panel-table striped fancy">
          <TableHeader empty={cotacoes.isEmpty()}>
            <tr>
              <th>Fornecedor</th>
              <th>CNPJ/CPF</th>
              <th>Data</th>
              <th>Arquivos</th>
            </tr>
          </TableHeader>
          <tbody>{cotacoes.map(this.renderCotacoes)}</tbody>
        </table>
      </div>
    );
  }
}

export default LicitacaoDetalheCotacao;
