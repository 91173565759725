export const SUFFIX_START = 'START';
export const SUFFIX_SUCCESS = 'SUCCESS';
export const SUFFIX_ERROR = 'ERROR';

export const SEARCH = 'SEARCH';
export const RETRIEVE = 'RETRIEVE';
export const SAVE_RECORD = 'SAVE_RECORD';
export const UPDATE_RECORD = 'UPDATE_RECORD';
export const DELETE_RECORD = 'DELETE_RECORD';

export const NEW_RECORD = 'NEW_RECORD';
export const EDIT_RECORD = 'EDIT_RECORD';
export const CANCEL_RECORD = 'CANCEL_RECORD';

export const CHANGE_FIELD_VALUE = 'CHANGE_FIELD_VALUE';
export const ADD_ITEM = 'ADD_ITEM';
export const REMOVE_ITEM = 'REMOVE_ITEM';
export const RESET_CRUD = 'RESET_CRUD';

export const ADD_FILTER = 'ADD_FILTER';
export const APPLY_FILTER = 'APPLY_FILTER';
export const REMOVE_FILTER = 'REMOVE_FILTER';
export const REORDER_RECORD = 'REORDER_RECORD';

export const LOAD_MODEL = 'LOAD_MODEL';

export function start(type) {
  return `${type}_${SUFFIX_START}`;
}

export function success(type) {
  return `${type}_${SUFFIX_SUCCESS}`;
}

export function error(type) {
  return `${type}_${SUFFIX_ERROR}`;
}
