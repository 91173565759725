import { fromJS } from 'immutable';
import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';

import * as types from './Types.js';

const initialState = fromJS({
  filas: [],
  pacientes: []
});

function saudeReducer(state = initialState, action) {
  switch (action.type) {
    case types.FILAS_ESPERA_LIST_START:
    case types.PACIENTES_LIST_START: {
      return startLoad(state);
    }

    case types.FILAS_ESPERA_LIST_SUCCESS: {
      return endLoad(state.set('filas', fromJS(action.payload)));
    }
    case types.PACIENTES_LIST_SUCCESS: {
      return endLoad(state.set('pacientes', fromJS(action.payload)));
    }

    case types.CLEAR_REGISTROS: {
      return endLoad(
        state.set('filas', fromJS([])).set('pacientes', fromJS([]))
      );
    }

    case types.FILAS_ESPERA_LIST_ERROR:
    case types.PACIENTES_LIST_ERROR: {
      return endLoad(state);
    }
  }

  return state;
}

export default saudeReducer;
