import TableHeader from 'portaltransparencia-common/components/TableHeader.react.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import NumberUtils from 'portaltransparencia-common/libs/NumberUtils.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export class ConvenioAditivos extends Component {
  static propTypes = {
    aditivos: PropTypes.object.isRequired
  };

  renderAditivos = aditivo => {
    const key =
      aditivo.get('nrAditivo') +
      '_' +
      aditivo.get('nrAnoAditivo' + '_' + aditivo.get('dtTermoAditivo'));
    return (
      <tr ref="row" id={key}>
        <td>{aditivo.get('nrAditivo') + '/' + aditivo.get('nrAnoAditivo')}</td>
        <td>{DateUtils.formatDateTimeShort(aditivo.get('dtTermoAditivo'))}</td>
        <td>{aditivo.get('dstipoAditivoConvenio')}</td>
        <td>{NumberUtils.format(aditivo.get('valor'))}</td>
        <td>{NumberUtils.format(aditivo.get('valorContraPartida'))}</td>
        <td>{DateUtils.formatDateTimeShort(aditivo.get('dtFimVigencia'))}</td>
        <td>
          {DateUtils.formatDateTimeShort(aditivo.get('dtPublicacaoDiario'))}
        </td>
        <td>
          {aditivo.get('nrDiarioOficial') +
            '/' +
            aditivo.get('nrAnoDiarioOficial')}
        </td>
        <td>{aditivo.get('descricao')}</td>
      </tr>
    );
  };

  render() {
    const { aditivos } = this.props;

    return (
      <div className="panel-table-outer">
        <table className="panel-table striped fancy">
          <TableHeader empty={aditivos.isEmpty()} text="Não possui aditivos.">
            <tr>
              <th>Nr./Ano Aditivo</th>
              <th>Data de Término</th>
              <th>Tipo Aditivo</th>
              <th>Valor</th>
              <th>Valor Contrapoartida</th>
              <th>Fim Vigência</th>
              <th>Publicação</th>
              <th>Nr./Ano Diário</th>
              <th>Descrição</th>
            </tr>
          </TableHeader>
          <tbody>{aditivos.map(this.renderAditivos)}</tbody>
        </table>
      </div>
    );
  }
}

export default ConvenioAditivos;
