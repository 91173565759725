const parametroService = {
  getParametro(state, codigoParametro) {
    if (state.parametros && state.parametros.has('parametros')) {
      const listaParametros = state.parametros.get('parametros');

      const parametro = listaParametros.find(
        p => p.getIn(['id', 'id']) == codigoParametro
      );

      return parametro;
    }
    return undefined;
  },

  getValorParametro(state, codigoParametro, valorDefault) {
    const parametro = this.getParametro(state, codigoParametro);

    if (parametro) {
      const valorParametro = parametro.get('valor');

      return valorParametro || valorDefault;
    }

    return valorDefault;
  }
};

export default parametroService;
