import Component from 'react-pure-render/component';
import PropTypes from 'prop-types';
import React from 'react';

import $ from 'jquery';
export default class Tabs extends Component {
  static propTypes = {
    children: PropTypes.node,
    onTabClick: PropTypes.func,
    scrollVisibled: PropTypes.bool
  };

  static defaultProps = {
    onTabClick: () => {},
    scrollVisibled: false
  };

  componentDidMount() {
    $(
      '.panel-tab-content .tab-item:nth-of-type(1), .panel-tab-selector .tab-item:nth-of-type(1)'
    );

    function addNumber(item) {
      let count = 0;
      $(item).each(function () {
        let classe = count++;
        $(this).attr('data-position', classe.toString());
      });
    }

    addNumber('.panel-tab-selector .tab-item');
    addNumber('.panel-tab-content .tab-item');

    $('.panel-tab-selector .tab-item').click(function () {
      let este = $(this);

      function remove(item) {
        $(item).each(function () {
          este.parents('.panel-tab').find(item).removeClass('active');
        });
      }
      remove('.panel-tab-selector .tab-item');
      remove('.panel-tab-content .tab-item');

      function recognize() {
        $('.panel-tab-content .tab-item').each(function () {
          if (este.attr('data-position') == $(this).attr('data-position')) {
            $(this).addClass('active');
            este.addClass('active');
          }
        });
      }
      recognize();
    });
  }

  renderTab = tab => {
    if (tab == null || tab.props == null || tab.props.hide) {
      return null;
    }

    const classsName =
      'tab-item' +
      (tab.props.hiddenPrint ? ' hidden-print' : '') +
      (tab.props.active ? ' active' : '');

    const onClick = () => this.props.onTabClick(tab.props);

    return (
      <div className={classsName} name={tab.props.name} onClick={onClick}>
        {tab.props.title}
      </div>
    );
  };

  getActiveTabName = () => {
    const activeTab = $('.panel-tab-selector .tab-item.active');

    return activeTab.attr('name');
  };

  render() {
    const { children, scrollVisibled } = this.props;

    return (
      <div className="panel-tab">
        <div
          className="panel-tab-selector"
          style={{ overflow: scrollVisibled ? 'auto' : 'none' }}
        >
          {React.Children.map(children, this.renderTab)}
        </div>
        <div className="panel-tab-content">{children}</div>
      </div>
    );
  }
}
