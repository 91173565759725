import * as types from './Types.js';

import {
  GO_TO_STEP,
  NEXT_STEP,
  PREVIOUS_STEP
} from 'portaltransparencia-common/components/wizard/Types.js';
import {
  changeFieldValue,
  endLoad,
  startLoad
} from 'portaltransparencia-common/crud/Core.js';

import { fromJS } from 'immutable';

const initialState = fromJS({
  currentRecord: {
    tipoSolicitacao: 'I',
    formaRecebimento: 'On-Line',
    activeStep: 1
  },
  cadastrada: false,
  estatisticas: [],
  estatisticasAno: [],
  processosPorGenero: [],
  processosPorIdade: [],
  processosPorEscolaridade: [],
  mediaRespostasAno: 0,
  mediaRespostas: 0,
  protocolo: {},
  tiposProcessos: []
});

function esicReducer(state = initialState, action) {
  switch (action.type) {
    case types.SOLICITACAO_ESIC_START:
    case types.FIND_ESIC_START:
    case types.FIND_PESSOA_START:
    case types.FIND_ASSUNTO_START:
    case types.ESTATISTICAS_START:
    case types.ESTATISTICAS_ANO_START:
    case types.MEDIA_RESPOSTAS_ANO_START:
    case types.MEDIA_RESPOSTAS_START:
    case types.RELATORIO_ANUAL_ESTATISTICA_START:
    case types.ESIC_LOAD_TIPOPROCESSO_START: {
      return startLoad(state);
    }
    case types.SOLICITACAO_ESIC_ERROR:
    case types.FIND_ESIC_ERROR:
    case types.FIND_PESSOA_ERROR:
    case types.FIND_ASSUNTO_ERROR:
    case types.ESTATISTICAS_ERROR:
    case types.ESTATISTICAS_ANO_ERROR:
    case types.MEDIA_RESPOSTAS_ANO_ERROR:
    case types.MEDIA_RESPOSTAS_ERROR:
    case types.RELATORIO_ANUAL_ESTATISTICA_ERROR:
    case types.ESIC_LOAD_ESCOLARIDADE_ERROR: {
      return endLoad(state);
    }
    case types.FIND_ASSUNTO_SUCCESS: {
      const assuntos = fromJS(action.payload).sort(
        (a, b) =>
          (a.get('descricao') > b.get('descricao') && 1) ||
          (a.get('descricao') < b.get('descricao') && -1) ||
          0
      );
      const newState = state.set('assuntos', fromJS(assuntos));
      return endLoad(newState);
    }
    case types.FIND_PESSOA_SUCCESS: {
      const cadastrada = fromJS(action.payload);
      if (cadastrada && cadastrada.get('id')) {
        const newState = state
          //.setIn(['pessoa'], cadastrada)
          .set('cadastrada', true)
          .setIn(['currentRecord', 'nome'], cadastrada.get('nome'))
          .setIn(['currentRecord', 'cpf'], cadastrada.get('cnpjCpf'))
          .setIn(['currentRecord', 'rg'], cadastrada.get('rg'))
          .setIn(
            ['currentRecord', 'celular'],
            cadastrada.getIn(['contato', 'celular'])
          )
          .setIn(
            ['currentRecord', 'telefone'],
            cadastrada.getIn(['contato', 'telefone'])
          )
          .setIn(
            ['currentRecord', 'email'],
            cadastrada.getIn(['contato', 'email'])
          );

        if (
          cadastrada.has('enderecos') &&
          cadastrada.get('enderecos') &&
          !cadastrada.get('enderecos').isEmpty()
        ) {
          const endereco = cadastrada.getIn(['enderecos', 0]);

          return newState
            .setIn(
              ['currentRecord', 'cidade'],
              endereco.getIn(['cidade', 'descricao'])
            )
            .setIn(
              ['currentRecord', 'bairro'],
              endereco.getIn(['bairro', 'descricao'])
            )
            .setIn(
              ['currentRecord', 'logradouro'],
              endereco.getIn(['logradouro', 'descricao'])
            )
            .setIn(['currentRecord', 'numero'], endereco.get('numero'))
            .setIn(
              ['currentRecord', 'complemento'],
              endereco.get('complemento')
            )
            .setIn(['currentRecord', 'uf'], endereco.getIn(['uf', 'id']))
            .setIn(['currentRecord', 'cep'], endereco.get('cep'));
        }
        return newState;
      } else {
        return state
          .setIn(['cadastrada'], false)
          .setIn(['currentRecord', 'nome'], '')
          .setIn(['currentRecord', 'rg'], '')
          .setIn(['currentRecord', 'celular'], '')
          .setIn(['currentRecord', 'telefone'], '')
          .setIn(['currentRecord', 'email'], '')
          .setIn(['currentRecord', 'sexo'], '')
          .setIn(['currentRecord', 'dataNascimento'], '')
          .setIn(['currentRecord', 'escolaridade'], '')
          .setIn(['currentRecord', 'cidade'], '')
          .setIn(['currentRecord', 'bairro'], '')
          .setIn(['currentRecord', 'logradouro'], '')
          .setIn(['currentRecord', 'numero'], '')
          .setIn(['currentRecord', 'complemento'], '')
          .setIn(['currentRecord', 'uf'], '')
          .setIn(['currentRecord', 'cep'], '');
      }
    }
    case types.SOLICITACAO_ESIC_SUCCESS: {
      return state.set('solicitacaoEsic', action.payload);
    }
    case types.FIND_ESIC_SUCCESS: {
      const solicitacao = action.payload;
      const newState = state
        .set('protocolo', fromJS(solicitacao))
        .setIn(['currentRecord', 'anoProcesso'], solicitacao.exercicio)
        .setIn(['currentRecord', 'numeroProcesso'], solicitacao.processo)
        .setIn(['currentRecord', 'senhaProcesso'], solicitacao.senha)
        .setIn(['currentRecord', 'tipoProcesso'], solicitacao.tipo);
      return endLoad(newState);
    }
    case types.RECURSO_PROCESSO: {
      const tipo = state.getIn(['protocolo', 'tipo']);
      const processo = state.getIn(['protocolo', 'processo']);
      const ano = state.getIn(['protocolo', 'exercicio']);
      const processoReferencia = `${tipo}-${processo}/${ano}`;

      const newState = state
        .setIn(
          ['currentRecord', 'assunto'],
          state.getIn(['protocolo', 'assunto'])
        )
        .setIn(['currentRecord', 'cpf'], state.getIn(['protocolo', 'cpf']))
        .setIn(['currentRecord', 'processoReferencia'], processoReferencia);
      return endLoad(newState);
    }

    case types.CHANGE_FIELD_ESIC_VALUE: {
      return changeFieldValue(state, action.payload);
    }
    case PREVIOUS_STEP: {
      const newActiveStep = state.getIn(['currentRecord', 'activeStep']) - 1;

      return state.setIn(['currentRecord', 'activeStep'], newActiveStep);
    }
    case NEXT_STEP: {
      const newActiveStep = state.getIn(['currentRecord', 'activeStep']) + 1;

      return state.setIn(['currentRecord', 'activeStep'], newActiveStep);
    }
    case GO_TO_STEP: {
      return state.setIn(['currentRecord', 'activeStep'], action.payload);
    }

    case types.ESTATISTICAS_SUCCESS: {
      return endLoad(state.set('estatisticas', fromJS(action.payload)));
    }
    case types.ESTATISTICAS_ANO_SUCCESS: {
      return endLoad(state.set('estatisticasAno', fromJS(action.payload)));
    }
    case types.MEDIA_RESPOSTAS_ANO_SUCCESS: {
      return endLoad(state.set('mediaRespostasAno', fromJS(action.payload)));
    }
    case types.MEDIA_RESPOSTAS_SUCCESS: {
      return endLoad(state.set('mediaRespostas', fromJS(action.payload)));
    }
    case types.ESTATISTICAS_PROCESSO_POR_GENERO_SUCCESS: {
      return endLoad(state.set('processosPorGenero', fromJS(action.payload)));
    }
    case types.ESTATISTICAS_PROCESSO_POR_IDADE_SUCCESS: {
      return endLoad(state.set('processosPorIdade', fromJS(action.payload)));
    }
    case types.ESTATISTICAS_PROCESSO_POR_ESCOLARIDADE_SUCCESS: {
      return endLoad(
        state.set('processosPorEscolaridade', fromJS(action.payload))
      );
    }
    case types.SOLICITACAO_ESIC_RESET: {
      return state.set(
        'currentRecord',
        fromJS(initialState.get('currentRecord'))
      );
    }
    case types.ESIC_LOAD_ESCOLARIDADE_SUCCESS: {
      return endLoad(
        state.setIn(
          ['currentRecord', 'listaEscolaridades'],
          fromJS(action.payload)
        )
      );
    }
    case types.ESIC_LOAD_TIPOPROCESSO_SUCCESS: {
      return endLoad(state.set('tiposProcessos', fromJS(action.payload)));
    }
    case types.RELATORIO_ANUAL_ESTATISTICA_SUCCESS: {
      return endLoad(state.set('relatorioAnual', fromJS(action.payload)));
    }
  }

  return state;
}
export default esicReducer;
