import Component from 'react-pure-render/component';
import PropTypes from 'prop-types';
import React from 'react';
import { Pagination } from 'react-bootstrap';

class SearchPagination extends Component {
  static propTypes = {
    page: PropTypes.object.isRequired,
    searchWithPage: PropTypes.func.isRequired,
    showAllIfLessThan: PropTypes.number,
    showTotalCount: PropTypes.bool
  };

  executeSearch = numberPage => {
    const { searchWithPage, page } = this.props;

    let newPage = page
      .set('number', numberPage)
      .set('size', this.itemsPorPagina);

    searchWithPage(newPage);
  };

  handleSelectNumeroItems = () => {
    this.itemsPorPagina = this.refs.itemsPagina.value;
    this.executeSearch(0);
  };

  handleSelect = eventKey => {
    this.itemsPorPagina = this.refs.itemsPagina.value;
    this.executeSearch(eventKey - 1);
  };

  render() {
    const { page, showAllIfLessThan, showTotalCount } = this.props;
    const pageHasData =
      page && page.has('numberOfElements') && page.get('numberOfElements') > 0;

    if (!pageHasData) {
      return null;
    }

    if (showAllIfLessThan && page.get('totalElements') <= showAllIfLessThan) {
      if (page.get('numberOfElements') < page.get('totalElements')) {
        this.itemsPorPagina = showAllIfLessThan;
        this.executeSearch(0);
      }
      return null;
    }

    return (
      <div className="panel-pagination">
        <div className="sisters">
          <div className="number-itens">
            <b>Mostrar</b>
            <select
              name="itemsPagina"
              className="form-control control-label"
              onChange={this.handleSelectNumeroItems}
              ref="itemsPagina"
              value={page.get('size')}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="50">50</option>
            </select>
            <b>Itens</b>
            {showTotalCount && page.get('totalElements') > 0 && (
              <b>Total de {page.get('totalElements')} registros</b>
            )}
          </div>
        </div>
        <div className="sisters">
          <Pagination
            bsClass="panel-pagination-inner"
            first={!page.get('first')}
            prev={!page.get('first')}
            next={!page.get('last')}
            last={!page.get('last')}
            activePage={page.get('number') + 1}
            ellipsis
            boundaryLinks
            items={page.get('totalPages')}
            maxButtons={5}
            onSelect={this.handleSelect}
          />
        </div>
      </div>
    );
  }
}

export default SearchPagination;
