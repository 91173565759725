import ExportButtonGroup from 'portaltransparencia-common/components/ExportButtonGroup.react.js';
import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import TableHeaderItem from 'portaltransparencia-common/components/TableHeaderItem.react';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import storageService from 'portaltransparencia-common/libs/StorageService.js';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import UltimaAtualizacaoByTable from '../ultimaatualizacao/UltimaAtualizacaoByTable.react.js';

export class ConvenioListagem extends Component {
  static propTypes = {
    conveniosPage: PropTypes.object.isRequired,
    filter: PropTypes.string,
    searchWithPage: PropTypes.func.isRequired,
    isIntegradoOxy: PropTypes.bool,
    onExporta: PropTypes.func.isRequired
  };

  onViewDetalhes = item => {
    const entidade = item.get('entidade');
    const id = item.get('id');
    const nrConvenio = item.get('nrConvenio');
    const nrAnoConvenio = item.get('nrAnoConvenio');
    const idContaCorrente = item.get('idContaCorrente');
    const local = item.get('local');

    this.props.history.push(
      `convenios/detalhes?entidade=${entidade}&id=${id}&nrConvenio=${nrConvenio}&nrAnoConvenio=${nrAnoConvenio}&idContaCorrente=${idContaCorrente}&local=${local}`
    );
  };

  renderConvenios = (data, isOxy) => {
    const key =
      data.get('id') +
      '_' +
      data.get('nrConvenio') +
      '_' +
      data.get('nrAnoConvenio');
    const { entidade } = storageService.getItem('userInfo');

    return (
      <TableActions
        ref="row"
        key={key}
        data={data}
        onMore={this.onViewDetalhes}
      >
        {entidade && entidade.id <= 0 && (
          <td className="center">{data.get('entidade')}</td>
        )}
        <td>{data.get('nrConvenio')}</td>
        <td>{data.get('nrAnoConvenio')}</td>
        {isOxy && <td>{data.get('tipoRegistroConvenio')?.get('descricao')}</td>}
        <td>{data.get('nome')}</td>
        <td className="right">
          <NumberFormatter value={data.get('valorConvenio')} />
        </td>
        <td>{data.get('cnpj')}</td>
        <td>{data.get('qtParcelas')}</td>
        <td>{data.get('situacao')}</td>
        <td>{DateUtils.formatDateTimeShort(data.get('dataInicioVigencia'))}</td>
        <td>{DateUtils.formatDateTimeShort(data.get('dataFimVigencia'))}</td>
        <td>{data.get('fonteRecurso')}</td>
      </TableActions>
    );
  };

  render() {
    const { conveniosPage, searchWithPage, isOxy, onExporta } = this.props;
    const convenios = conveniosPage.get('content');
    const { entidade } = storageService.getItem('userInfo');

    return (
      <Panel isForm expansible={false} tableResponsive>
        <Row>
          <Col xs={6}>
            <UltimaAtualizacaoByTable
              modulo="3"
              table="cadastroconvenio"
              column="dataultimaatualizacao"
            />
          </Col>
          <Col xs={6}>
            <ExportButtonGroup onClick={onExporta} visible={true} />
          </Col>
        </Row>

        <div className="panel-table-outer">
          <table className="panel-table striped fancy">
            <TableHeader empty={convenios.isEmpty()}>
              <tr>
                {entidade && entidade.id <= 0 && (
                  <TableHeaderItem
                    field="entidade"
                    label="Entidade"
                    page={conveniosPage}
                    searchWithPage={searchWithPage}
                  />
                )}
                <TableHeaderItem
                  field="nrConvenio"
                  label="Convênio"
                  page={conveniosPage}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="nrAnoConvenio"
                  label="Ano"
                  page={conveniosPage}
                  searchWithPage={searchWithPage}
                />
                {isOxy && (
                  <TableHeaderItem
                    field="tipoRegistroConvenio"
                    label="Tipo instrumento"
                    page={conveniosPage}
                    searchWithPage={searchWithPage}
                  />
                )}
                <TableHeaderItem
                  field="nome"
                  label="Destinatário ou Repassador"
                  page={conveniosPage}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="valorConvenio"
                  label="Valor ou Repassador"
                  page={conveniosPage}
                  searchWithPage={searchWithPage}
                  alignment="right"
                />
                <TableHeaderItem
                  field="cnpj"
                  label="CNPJ/CPF"
                  page={conveniosPage}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="qtParcelas"
                  label="Parcelas"
                  page={conveniosPage}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="situacao"
                  label="Situação"
                  page={conveniosPage}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="dataInicioVigencia"
                  label="Início"
                  page={conveniosPage}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="dataFimVigencia"
                  label="Término"
                  page={conveniosPage}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="fonteRecurso"
                  label="Fonte"
                  page={conveniosPage}
                  searchWithPage={searchWithPage}
                />
                <th></th>
              </tr>
            </TableHeader>
            <tbody>
              {convenios.map(convenios =>
                this.renderConvenios(convenios, isOxy)
              )}
            </tbody>
          </table>
        </div>
      </Panel>
    );
  }
}

export default ConvenioListagem;
