export const LOAD_VEICULO = 'LOAD_VEICULO';
export const LOAD_VEICULO_START = 'LOAD_VEICULO_START';
export const LOAD_VEICULO_SUCCESS = 'LOAD_VEICULO_SUCCESS';
export const LOAD_VEICULO_ERROR = 'LOAD_VEICULO_ERROR';

export const CONSULTA_VEICULOS = 'CONSULTA_VEICULOS';
export const CONSULTA_VEICULOS_START = 'CONSULTA_VEICULOS_START';
export const CONSULTA_VEICULOS_ERROR = 'CONSULTA_VEICULOS_ERROR';
export const CONSULTA_VEICULOS_SUCCESS = 'CONSULTA_VEICULOS_SUCCESS';

export const VEICULOS_GASTOS = 'VEICULOS_GASTOS';
export const VEICULOS_GASTOS_START = 'VEICULOS_GASTOS_START';
export const VEICULOS_GASTOS_SUCCESS = 'VEICULOS_GASTOS_SUCCESS';
export const VEICULOS_GASTOS_ERROR = 'VEICULOS_GASTOS_ERROR';

export const TIPOSALIENACAO = 'TIPOSALIENACAO';
export const TIPOSALIENACAO_START = 'TIPOSALIENACAO_START';
export const TIPOSALIENACAO_SUCCESS = 'TIPOSALIENACAO_SUCCESS';
export const TIPOSALIENACAO_ERROR = 'TIPOSALIENACAO_ERROR';

export const GASTOS_MANUTENCAO = 'GASTOS_MANUTENCAO';
export const GASTOS_MANUTENCAO_START = 'GASTOS_MANUTENCAO_START';
export const GASTOS_MANUTENCAO_SUCCESS = 'GASTOS_MANUTENCAO_SUCCESS';
export const GASTOS_MANUTENCAO_ERROR = 'GASTOS_MANUTENCAO_ERROR';

export const GASTOS_ABASTECIMENTO = 'GASTOS_ABASTECIMENTO';
export const GASTOS_ABASTECIMENTO_START = 'GASTOS_ABASTECIMENTO_START';
export const GASTOS_ABASTECIMENTO_SUCCESS = 'GASTOS_ABASTECIMENTO_SUCCESS';
export const GASTOS_ABASTECIMENTO_ERROR = 'GASTOS_ABASTECIMENTO_ERROR';

export const GASTOS_PNEU = 'GASTOS_PNEU';
export const GASTOS_PNEU_START = 'GASTOS_PNEU_START';
export const GASTOS_PNEU_SUCCESS = 'GASTOS_PNEU_SUCCESS';
export const GASTOS_PNEU_ERROR = 'GASTOS_PNEU_ERROR';

export const GASTOS_BATERIA = 'GASTOS_BATERIA';
export const GASTOS_BATERIA_START = 'GASTOS_BATERIA_START';
export const GASTOS_BATERIA_SUCCESS = 'GASTOS_BATERIA_SUCCESS';
export const GASTOS_BATERIA_ERROR = 'GASTOS_BATERIA_ERROR';

export const GASTOS_PECA = 'GASTOS_PECA';
export const GASTOS_PECA_START = 'GASTOS_PECA_START';
export const GASTOS_PECA_SUCCESS = 'GASTOS_PECA_SUCCESS';
export const GASTOS_PECA_ERROR = 'GASTOS_PECA_ERROR';

export const RESET_CONSULTA_VEICULOS = 'RESET_CONSULTA_VEICULOS';
