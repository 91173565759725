export const AUDIENCIAS_PUBLICAS = 'AUDIENCIAS_PUBLICAS';
export const AUDIENCIAS_PUBLICAS_START = 'AUDIENCIAS_PUBLICAS_START';
export const AUDIENCIAS_PUBLICAS_SUCCESS = 'AUDIENCIAS_PUBLICAS_SUCCESS';
export const AUDIENCIAS_PUBLICAS_ERROR = 'AUDIENCIAS_PUBLICAS_ERROR';

export const AUDIENCIAS_PUBLICAS_DETALHES = 'AUDIENCIAS_PUBLICAS_DETALHES';
export const AUDIENCIAS_PUBLICAS_DETALHES_START =
  'AUDIENCIAS_PUBLICAS_DETALHES_START';
export const AUDIENCIAS_PUBLICAS_DETALHES_SUCCESS =
  'AUDIENCIAS_PUBLICAS_DETALHES_SUCCESS';
export const AUDIENCIAS_PUBLICAS_DETALHES_ERROR =
  'AUDIENCIAS_PUBLICAS_DETALHES_ERROR';

export const AUDIENCIAS_PUBLICAS_ARQUIVOS = 'AUDIENCIAS_PUBLICAS_ARQUIVOS';
export const AUDIENCIAS_PUBLICAS_ARQUIVOS_START =
  'AUDIENCIAS_PUBLICAS_ARQUIVOS_START';
export const AUDIENCIAS_PUBLICAS_ARQUIVOS_SUCCESS =
  'AUDIENCIAS_PUBLICAS_ARQUIVOS_SUCCESS';
export const AUDIENCIAS_PUBLICAS_ARQUIVOS_ERROR =
  'AUDIENCIAS_PUBLICAS_ARQUIVOS_ERROR';
