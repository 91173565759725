import StorageService from 'portaltransparencia-common/libs/StorageService.js';

import * as types from './Types.js';

export function findCedidosDe(filter) {
  const { entidade } = StorageService.getItem('userInfo');
  const baseFilter = `entidade=${entidade.id}`;

  return ({ fetch }) => ({
    type: types.CEDIDOS_DE,
    payload: {
      promise: fetch(`/api/cedidos?${baseFilter}&${filter}`)
    }
  });
}

export function findCedidosPara(filter) {
  const { entidade } = StorageService.getItem('userInfo');
  const baseFilter = `entidade=${entidade.id}`;

  return ({ fetch }) => ({
    type: types.CEDIDOS_PARA,
    payload: {
      promise: fetch(`/api/cedidos?${baseFilter}&${filter}`)
    }
  });
}

export function findHistCedidosDe(filter) {
  const { entidade } = StorageService.getItem('userInfo');
  const baseFilter = `entidade=${entidade.id}`;

  return ({ fetch }) => ({
    type: types.HISTCEDIDOS_DE,
    payload: {
      promise: fetch(
        `/api/cedidos?${baseFilter}&${filter}&cedenteFinalizado=true`
      )
    }
  });
}

export function findHistCedidosPara(filter) {
  const { entidade } = StorageService.getItem('userInfo');
  const baseFilter = `entidade=${entidade.id}`;

  return ({ fetch }) => ({
    type: types.HISTCEDIDOS_PARA,
    payload: {
      promise: fetch(
        `/api/cedidos?${baseFilter}&${filter}&cedenteFinalizado=true`
      )
    }
  });
}
