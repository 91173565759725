import Panel from 'portaltransparencia-common/components/Panel.react.js';
import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import { downloadArquivoAtas } from '../arquivo/Actions.js';
import PageHeader from '../components/PageHeader.react.js';
import * as actions from './Actions.js';

export class FormConselhoDetalhe extends Component {
  static propTypes = {
    conselheiros: PropTypes.object,
    findConselho: PropTypes.func,
    clearRegistros: PropTypes.func,
    location: PropTypes.object,
    params: PropTypes.object
  };

  componentDidMount() {
    const {
      match: {
        params: { idConselho }
      }
    } = this.props;
    const { findConselho } = this.props;
    findConselho(idConselho);
  }

  componentWillUnmount() {
    const { clearRegistros } = this.props;

    clearRegistros();
  }

  renderConselhoConselheiros = conselho => {
    return conselho.get('conselheiros').map(this.renderConselheiros);
  };

  renderConselhoReunioes = conselho => {
    return conselho.get('reunioes').map(this.renderReunioes);
  };

  renderConselheiros = conselheiro => {
    return (
      <TableActions ref="row" key={conselheiro.get('id')} data={conselheiro}>
        <td>{conselheiro.get('nome')}</td>
        <td>{conselheiro.get('funcao')}</td>
        <td>{conselheiro.get('status') === 'A' ? 'Ativo' : 'Inativo'}</td>
      </TableActions>
    );
  };

  renderReunioes = reunioes => {
    return (
      <TableActions
        ref="row"
        key={reunioes.get('id')}
        data={reunioes}
        onDownload={downloadArquivoAtas}
      >
        <td>{DateUtils.formatDateTimeShort(reunioes.get('data'))}</td>
        <td>{reunioes.get('horario')}</td>
        <td>{reunioes.get('local')}</td>
      </TableActions>
    );
  };

  render() {
    const { conselheiros } = this.props;

    if (!conselheiros) {
      return null;
    }

    return (
      <section>
        <PageHeader location={this.props.location} />

        <Panel title="Conselheiros" isForm expansible={false} mobileClose>
          <div className="panel-table-outer">
            <table className="panel-table striped fancy">
              <TableHeader empty={conselheiros.isEmpty()}>
                <tr>
                  <th>Nome</th>
                  <th>Função</th>
                  <th>Status</th>
                  <th />
                </tr>
              </TableHeader>
              <tbody>{conselheiros.map(this.renderConselhoConselheiros)}</tbody>
            </table>
          </div>
        </Panel>

        <Panel title="Reuniões" isForm expansible={false} mobileClose>
          <div className="panel-table-outer">
            <table className="panel-table striped fancy">
              <TableHeader empty={conselheiros.isEmpty()}>
                <tr>
                  <th>Data</th>
                  <th>Horário</th>
                  <th>Local</th>
                  <th />
                </tr>
              </TableHeader>
              <tbody>{conselheiros.map(this.renderConselhoReunioes)}</tbody>
            </table>
          </div>
        </Panel>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    conselheiros: state.conselho.get('conselheiros')
  };
}

const allActions = {
  ...actions,
  downloadArquivoAtas
};

export default connect(mapStateToProps, allActions)(FormConselhoDetalhe);
