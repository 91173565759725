import Component from 'react-pure-render/component';
import PropTypes from 'prop-types';
import React from 'react';
import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react.js';

export class ObraFonteRecurso extends Component {
  static propTypes = {
    empenhos: PropTypes.object,
    isIntegradoOxy: PropTypes.bool,
    fontesRecurso: PropTypes.object
  };

  constructor(props) {
    super(props);
  }

  renderFonteRecurso = data => {
    const key = data.codigoFonte + '_' + data.descricaoFonte;

    return (
      <TableActions ref="row" key={key}>
        <td>{data.codigo}</td>
        <td>{data.descricao}</td>
      </TableActions>
    );
  };

  renderFonteRecursoOxy = data => {
    const key = data.get('codigo') + '_' + data.get('id');

    return (
      <TableActions ref="row" key={key}>
        <td>{data.get('codigo')}</td>
        <td>{data.get('descricao')}</td>
      </TableActions>
    );
  };

  render() {
    const { fontesRecurso, empenhos, isIntegradoOxy } = this.props;

    const fontesRecursos = [];

    if (!isIntegradoOxy) {
      empenhos.map(empenho => {
        const index = fontesRecursos.findIndex(
          fonte => fonte.codigo === empenho.get('codigoFonte')
        );
        if (index < 0) {
          fontesRecursos.push({
            codigo: empenho.get('codigoFonte'),
            descricao: empenho.get('descricaoFonte')
          });
        }
      });
    }

    return (
      <section>
        <div className="panel-table-outer">
          <table className="panel-table striped fancy">
            {isIntegradoOxy && (
              <>
                <TableHeader empty={fontesRecurso.isEmpty()}>
                  <tr>
                    <th>Código</th>
                    <th>Descrição</th>
                    <th></th>
                  </tr>
                </TableHeader>
                <tbody>{fontesRecurso.map(this.renderFonteRecursoOxy)}</tbody>
              </>
            )}
            {!isIntegradoOxy && (
              <>
                <TableHeader empty={empenhos.isEmpty()}>
                  <tr>
                    <th>Código</th>
                    <th>Descrição</th>
                    <th></th>
                  </tr>
                </TableHeader>
                <tbody>{fontesRecursos.map(this.renderFonteRecurso)}</tbody>
              </>
            )}
          </table>
        </div>
      </section>
    );
  }
}

export default ObraFonteRecurso;
