import PropTypes from 'prop-types';
import React from 'react';

export default class BootstrapErrorPage extends React.Component {
  static propTypes = {
    message: PropTypes.string.isRequired,
    error: PropTypes.object.isRequired
  };

  render() {
    const { message, error } = this.props;

    return (
      <div>
        <h2>{message}</h2>
        <div>
          <b>Erro:</b> {error.reason.message}
        </div>
      </div>
    );
  }
}
