import * as types from './Types.js';

export function findFilasEspera() {
  return ({ fetch }) => ({
    type: types.FILAS_ESPERA_LIST,
    payload: {
      promise: fetch('/api/filas-atendimento-saude')
    }
  });
}

export function findFilasEsperaCns(cns) {
  return ({ fetch }) => ({
    type: types.FILAS_ESPERA_LIST,
    payload: {
      promise: fetch(`/api/filas-atendimento-saude/cns/${cns}`)
    }
  });
}

export function findPacientes(filaId) {
  return ({ fetch }) => ({
    type: types.PACIENTES_LIST,
    payload: {
      promise: fetch(`/api/filas-atendimento-saude/${filaId}`)
    }
  });
}

export function clearRegistros() {
  return {
    type: types.CLEAR_REGISTROS,
    payload: {}
  };
}
