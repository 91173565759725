import Panel from 'portaltransparencia-common/components/Panel.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import TableHeaderItem from 'portaltransparencia-common/components/TableHeaderItem.react';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import AudienciasPublicasRow from './AudienciasPublicasRow.react.js';
import ExportButtonGroup from 'portaltransparencia-common/components/ExportButtonGroup.react.js';

export default class AudienciasPublicasListagem extends Component {
  static propTypes = {
    audienciasPublicasPage: PropTypes.object.isRequired,
    filter: PropTypes.string,
    searchWithPage: PropTypes.func.isRequired,
    isIntegradoOxy: PropTypes.bool,
    onExporta: PropTypes.func.isRequired
  };

  renderAudienciasPublicas = data => {
    const key =
      data.get('entidade') +
      '_' +
      data.get('exercicio') +
      '_' +
      data.get('descNatureza') +
      '_' +
      data.get('descTipo');

    return (
      <AudienciasPublicasRow
        key={key}
        audienciasPublicas={data}
        history={this.props.history}
      />
    );
  };

  render() {
    const {
      audienciasPublicasPage,
      searchWithPage,
      isIntegradoOxy,
      onExporta
    } = this.props;
    const audienciasPublicas = audienciasPublicasPage.get('content');

    return (
      <Panel expansible={false} isForm tableResponsive>
        <Row>
          <Col xs={12}>
            <ExportButtonGroup onClick={onExporta} visible={true} />
          </Col>
        </Row>
        <div className="panel-table-outer">
          <table className="panel-table striped fancy">
            <TableHeader empty={audienciasPublicas.isEmpty()}>
              <tr>
                <TableHeaderItem
                  field={isIntegradoOxy ? 'natureza' : 'descNatureza'}
                  label="Natureza"
                  page={audienciasPublicasPage}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field={isIntegradoOxy ? 'tipo' : 'descTipo'}
                  label="Tipo"
                  page={audienciasPublicasPage}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="data"
                  label="Data"
                  page={audienciasPublicasPage}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="local"
                  label="Local"
                  page={audienciasPublicasPage}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field={isIntegradoOxy ? 'formaChamamento' : 'chamamento'}
                  label="Forma Chamamento"
                  page={audienciasPublicasPage}
                  searchWithPage={searchWithPage}
                />
                <th></th>
              </tr>
            </TableHeader>
            <tbody>
              {audienciasPublicas.map(this.renderAudienciasPublicas)}
            </tbody>
          </table>
        </div>
      </Panel>
    );
  }
}
