import DisplayData from 'portaltransparencia-common/components/displaydata/DisplayData.react.js';
import DisplayDataItem from 'portaltransparencia-common/components/displaydata/DisplayDataItem.react.js';
import DisplayDataRow from 'portaltransparencia-common/components/displaydata/DisplayDataRow.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export class CargoDetalheInformacaoOxy extends Component {
  static propTypes = {
    cargo: PropTypes.object.isRequired
  };

  render() {
    const { cargo } = this.props;

    return (
      <Panel expansible={false}>
        <DisplayData border>
          <DisplayDataRow>
            <DisplayDataItem
              sm={4}
              title="Tipo do Cargo"
              value={cargo.cargoTipo.get('descricao')}
              id="cargoTipo"
            />
            <DisplayDataItem
              sm={4}
              title="Cargo"
              value={cargo.descricao}
              id="descricao"
            />
            <DisplayDataItem
              sm={4}
              title="Matriz Salarial"
              value={cargo.matrizSalarialNome}
              id="matrizSalarialNome"
            />
          </DisplayDataRow>
          <DisplayDataRow>
            <DisplayDataItem
              sm={4}
              title="Vagas total"
              value={cargo.quantidadeVagas}
              id="quantidadeVagas"
            />
            <DisplayDataItem
              sm={4}
              title="Total de Vagas Ocupadas"
              value={cargo.quantidadeVagasOcupadas}
            />
            <DisplayDataItem
              sm={4}
              title="Vagas disponíveis"
              value={cargo.quantidadeVagasDisponiveis}
              id="vagasDisponiveis"
            />
          </DisplayDataRow>
        </DisplayData>
      </Panel>
    );
  }
}

export default CargoDetalheInformacaoOxy;
