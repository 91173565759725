import DisplayData from 'portaltransparencia-common/components/displaydata/DisplayData.react.js';
import DisplayDataItem from 'portaltransparencia-common/components/displaydata/DisplayDataItem.react.js';
import DisplayDataRow from 'portaltransparencia-common/components/displaydata/DisplayDataRow.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import NumberUtils from 'portaltransparencia-common/libs/NumberUtils.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

import { getLabelByTipo } from './TipoBemUtils';

export class PatrimonioDetalheInformacao extends Component {
  static propTypes = {
    bem: PropTypes.object.isRequired,
    isIntegradoOxy: PropTypes.bool
  };

  render() {
    const { bem, isIntegradoOxy } = this.props;
    const tipo = getLabelByTipo(bem.getIn(['id', 'tipo']));

    return (
      <Panel title={`Tombamento ${bem.getIn(['id', 'chapa'])} - ${tipo}`}>
        <DisplayData border>
          <DisplayDataRow>
            <DisplayDataItem
              sm={12}
              title="Descrição"
              value={bem.get('descricao')}
              id="descricao"
            />
          </DisplayDataRow>

          <DisplayDataRow>
            <DisplayDataItem
              sm={3}
              title="Tombamento"
              value={bem.getIn(['id', 'chapa'])}
              id="chapa"
            />
            <DisplayDataItem
              sm={3}
              title="Tipo Patrimônio"
              value={tipo}
              id="tipo"
            />
            <DisplayDataItem
              sm={3}
              title="Aquisição"
              value={DateUtils.formatDateTimeShort(bem.get('dataAquisicao'))}
              id="aquisicao"
            />
            <DisplayDataItem
              sm={3}
              title="Local"
              value={bem.get('localAtual')}
              id="local"
            />
          </DisplayDataRow>

          <DisplayDataRow>
            <DisplayDataItem
              sm={3}
              title="Matrícula"
              value={bem.getIn(['imovel', 'matricula'])}
              id="matricula"
            />
            <DisplayDataItem
              sm={3}
              title="Data Matrícula"
              value={DateUtils.formatDateTimeShort(
                bem.getIn(['imovel', 'dataMatricula'])
              )}
              id="dataMatricula"
            />
            <DisplayDataItem
              sm={3}
              title="Tipo de Incorporação"
              value={bem.getIn(['incorporacao', 'descricao'])}
              id="tipoIncorporacao"
            />
          </DisplayDataRow>

          <DisplayDataRow>
            <DisplayDataItem
              sm={3}
              title="Valor Aquisição"
              value={NumberUtils.format(bem.get('valorAquisicao'), 'R$ 0,0.00')}
              id="valorAquisicao"
            />
            <DisplayDataItem
              sm={3}
              title="Valor Atual"
              value={NumberUtils.format(bem.get('valorAtual'), 'R$ 0,0.00')}
              id="valor"
            />
            <DisplayDataItem
              sm={3}
              title="Nota Fiscal"
              value={bem.get('notaFiscal')}
              id="notaFiscal"
            />
          </DisplayDataRow>

          <DisplayDataRow>
            <DisplayDataItem
              sm={3}
              title="Cidade"
              value={bem.getIn(['imovel', 'cidade', 'descricao'])}
              id="cidade"
              show={bem.get('imovel') != null}
            />
            <DisplayDataItem
              sm={3}
              title="Bairro"
              value={bem.getIn(['imovel', 'bairro', 'descricao'])}
              id="bairro"
              show={bem.get('imovel') != null}
            />
            <DisplayDataItem
              sm={3}
              title="Logradouro"
              value={bem.getIn(['imovel', 'logradouro', 'descricao'])}
              id="logradouro"
              show={bem.get('imovel') != null}
            />
          </DisplayDataRow>

          <DisplayDataRow>
            <DisplayDataItem
              sm={3}
              title="CEP"
              value={bem.getIn(['imovel', 'cep'])}
              id="cep"
              show={bem.get('imovel') != null}
            />
            <DisplayDataItem
              sm={3}
              title="Número"
              value={bem.getIn(['imovel', 'numero'])}
              id="numero"
              show={bem.get('imovel') != null}
            />
            <DisplayDataItem
              sm={3}
              title="Complemento"
              value={bem.getIn(['imovel', 'complemento'])}
              id="complemento"
              show={bem.get('imovel') != null}
            />
          </DisplayDataRow>

          <DisplayDataRow>
            <DisplayDataItem
              sm={3}
              id="situacao"
              title="Situação"
              value={bem.get('baixado') ? 'Baixado' : 'Ativo'}
            />
            <DisplayDataItem
              sm={3}
              id="dataBaixa"
              title="Data Baixa"
              value={DateUtils.formatDateTimeShort(bem.get('dataBaixa'))}
            />
            <DisplayDataItem
              sm={3}
              id="tipoBaixa"
              title="Tipo da Baixa"
              value={bem.get('tipoBaixa')}
            />
            {!isIntegradoOxy && (
              <DisplayDataItem
                sm={3}
                id="processoBaixa"
                title="Processo Baixa"
                value={bem.get('processoBaixa')}
              />
            )}
          </DisplayDataRow>

          <DisplayDataRow>
            <DisplayDataItem
              sm={12}
              id="justificativa"
              title="Justificativa"
              value={bem.get('justificativa')}
            />
          </DisplayDataRow>
          <DisplayDataRow>
            <DisplayDataItem
              sm={12}
              id="nomeComprador"
              title="Responsável Recebimento"
              value={bem.get('nomeComprador')}
            />
          </DisplayDataRow>
          {bem.get('comodato') && (
            <React.Fragment>
              <DisplayDataRow>
                <DisplayDataItem
                  sm={3}
                  title="Número Comodato"
                  value={bem.getIn(['comodato', 'id', 'codigo'])}
                  id="codigoComodato"
                />
                <DisplayDataItem
                  sm={3}
                  title="Local Comodato"
                  value={bem.getIn(['comodato', 'local'])}
                  id="localComodato"
                />
                <DisplayDataItem
                  sm={3}
                  title="Data Inicio"
                  value={DateUtils.formatDateTimeShort(
                    bem.getIn(['comodato', 'dataInicio'])
                  )}
                  id="dataInicio"
                />
                <DisplayDataItem
                  sm={3}
                  title="Data Fim"
                  value={DateUtils.formatDateTimeShort(
                    bem.getIn(['comodato', 'dataFim'])
                  )}
                  id="dataFim"
                />
              </DisplayDataRow>
              <DisplayDataRow>
                <DisplayDataItem
                  sm={12}
                  title="Descrição Comodato"
                  value={bem.getIn(['comodato', 'descricao'])}
                  id="descricaoComodato"
                />
              </DisplayDataRow>
            </React.Fragment>
          )}
        </DisplayData>
      </Panel>
    );
  }
}

export default PatrimonioDetalheInformacao;
