export const NOTAS_EXTRAORCAMENTARIAS = 'NOTAS_EXTRAORCAMENTARIAS';
export const NOTAS_EXTRAORCAMENTARIAS_START = 'NOTAS_EXTRAORCAMENTARIAS_START';
export const NOTAS_EXTRAORCAMENTARIAS_SUCCESS =
  'NOTAS_EXTRAORCAMENTARIAS_SUCCESS';
export const NOTAS_EXTRAORCAMENTARIAS_ERROR = 'NOTAS_EXTRAORCAMENTARIAS_ERROR';

export const NOTA_EXTRAORCAMENTARIA = 'NOTA_EXTRAORCAMENTARIA';
export const NOTA_EXTRAORCAMENTARIA_START = 'NOTA_EXTRAORCAMENTARIA_START';
export const NOTA_EXTRAORCAMENTARIA_SUCCESS = 'NOTA_EXTRAORCAMENTARIA_SUCCESS';
export const NOTA_EXTRAORCAMENTARIA_ERROR = 'NOTA_EXTRAORCAMENTARIA_ERROR';

export const NOTA_EXTRAORCAMENTARIA_ITENS = 'NOTA_EXTRAORCAMENTARIA_ITENS';
export const NOTA_EXTRAORCAMENTARIA_ITENS_START =
  'NOTA_EXTRAORCAMENTARIA_ITENS_START';
export const NOTA_EXTRAORCAMENTARIA_ITENS_SUCCESS =
  'NOTA_EXTRAORCAMENTARIA_ITENS_SUCCESS';
export const NOTA_EXTRAORCAMENTARIA_ITENS_ERROR =
  'NOTA_EXTRAORCAMENTARIA_ITENS_ERROR';
