import Component from 'react-pure-render/component';
import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

export default class Panel extends Component {
  static propTypes = {
    title: PropTypes.string,
    children: PropTypes.node,
    isTable: PropTypes.bool,
    isForm: PropTypes.bool,
    collapsed: PropTypes.bool,
    expansible: PropTypes.bool,
    mobileClose: PropTypes.bool,
    insidePanel: PropTypes.bool,
    tableResponsive: PropTypes.bool,
    onClickPanel: PropTypes.func
  };

  static defaultProps = {
    collapsed: false,
    expansible: true
  };

  constructor(props) {
    super(props);
    this.state = {
      collapsed: props.collapsed
    };
  }

  componentDidMount() {
    if (document.body.clientWidth < 768) {
      this.setState({
        collapsed: this.props.mobileClose
      });
    }
  }

  componentDidUpdate(oldProps) {
    if (oldProps.collapsed !== this.props.collapsed) {
      this.setState({ collapsed: this.props.collapsed });
    }
  }

  onClick = () => {
    const { onClickPanel } = this.props;
    if (onClickPanel) {
      onClickPanel(!this.state.collapsed);
    } else {
      this.setState(state => ({ collapsed: !state.collapsed }));
    }
  };

  collapse = () => {
    this.setState({
      collapsed: true
    });
  };

  render() {
    var childElement = this.props.children;
    const { collapsed } = this.state;

    const {
      title,
      isTable,
      isForm,
      expansible,
      insidePanel,
      tableResponsive
    } = this.props;

    const classes = {
      panel: true,
      expansible: expansible,
      open: !collapsed,
      'panel-form': isForm,
      table: isTable,
      'inside-panel': insidePanel,
      'table-responsive': tableResponsive
    };

    return (
      <div className={classnames(classes)}>
        <span
          className="dropdown-arrow"
          onClick={this.onClick}
          title="Clique para expandir ou minimizar"
        ></span>
        {title && (
          <h2 className="panel-title" onClick={this.onClick}>
            {title}
          </h2>
        )}
        <div className="panel-body">{childElement}</div>
      </div>
    );
  }
}
