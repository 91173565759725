export const PESQUISA_DIARIO = 'PESQUISA_DIARIO';
export const PESQUISA_DIARIO_START = 'PESQUISA_DIARIO_START';
export const PESQUISA_DIARIO_ERROR = 'PESQUISA_DIARIO_ERROR';
export const PESQUISA_DIARIO_SUCCESS = 'PESQUISA_DIARIO_SUCCESS';

export const DIARIO_URL_DOWNLOAD = 'DIARIO_URL_DOWNLOAD';
export const DIARIO_URL_DOWNLOAD_START = 'DIARIO_URL_DOWNLOAD_START';
export const DIARIO_URL_DOWNLOAD_SUCCESS = 'DIARIO_URL_DOWNLOAD_SUCCESS';

export const FILTRAR_DIARIO = 'FILTRAR_DIARIO';
export const LIMPAR_FILTRO = 'LIMPAR_FILTRO';
export const RESETAR_DIARIO = 'RESETAR_DIARIO';

export const COPIAR_DIARIO_URL = 'COPIAR_DIARIO_URL';
export const COPIAR_DIARIO_URL_SUCCESS = 'COPIAR_DIARIO_URL_SUCCESS';
