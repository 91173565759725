import ExportButtonGroup from 'portaltransparencia-common/components/ExportButtonGroup.react.js';
import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import configuration from 'portaltransparencia-common/config/Config.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { addErrorNotification } from 'portaltransparencia-common/ui/Actions.js';
import { connect } from 'react-redux';
import * as actions from './Actions.js';
import Subtitle from 'portaltransparencia-common/components/subtitle/Subtitle.react.js';

class MovimentacaoSaida extends Component {
  static propTypes = {
    movimentacoes: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
    addErrorNotification: PropTypes.func.isRequired
  };

  renderMovimentacoesSaida = movimentacoes => {
    return movimentacoes
      .filter(movimentacao => movimentacao.get('tipo') == 'SAIDA')
      .map(saida => {
        return (
          <tr ref="row" key={saida.get('numero')}>
            <td>{saida.get('centroCustoConsumoDescricao')}</td>
            <td>{DateUtils.formatDateTimeShort(saida.get('data'))}</td>
            <td>{saida.get('numero')}</td>
            <td>{saida.get('numeroNota')}</td>
            <td>{saida.get('quantidade')}</td>
            <td>{saida.get('unidade')}</td>
            <td className="right">
              <NumberFormatter value={saida.get('valorUnitario')} />
            </td>
            <td className="right">
              <NumberFormatter value={saida.get('valorTotal')} />
            </td>
          </tr>
        );
      });
  };

  onExporta = (exportType, openTab, windowParams) => {
    const { apiURL } = configuration;
    const { params, movimentacoes, addErrorNotification } = this.props;
    const { entidade, exercicio } = StorageService.getItem('userInfo');

    if (!movimentacoes.isEmpty()) {
      const movimentacaoItem = `entidade=${entidade.id}&exercicio=${exercicio.id}&centroCusto=${params.centrocusto}&item=${params.item}`;

      window.open(
        `${apiURL}/api/movimentacao/report?${movimentacaoItem}&tipo=SAIDA&exportType=${exportType}`,
        openTab,
        windowParams
      );
    } else {
      addErrorNotification(
        'A consulta não apresentou resultado. Não é possível exportar para o formato selecionado!'
      );
    }
  };

  render() {
    const { movimentacoes } = this.props;

    if (movimentacoes.isEmpty()) {
      return null;
    }

    return (
      <Panel isForm tableResponsive expansible={false}>
        <Row>
          <Col xs={6}>
            <Subtitle caption="Saídas" />
          </Col>
          <Col xs={6}>
            <ExportButtonGroup onClick={this.onExporta} visible={true} />
          </Col>
        </Row>

        <div className="panel-table-outer">
          <table className="panel-table striped fancy">
            <TableHeader
              empty={movimentacoes
                .filter(movimentacao => movimentacao.get('tipo') == 'SAIDA')
                .isEmpty()}
            >
              <tr>
                <th>Consumidor</th>
                <th>Data</th>
                <th>Número</th>
                <th>Número Nota</th>
                <th>Quantidade</th>
                <th>Unidade</th>
                <th className="right">Valor Unitário</th>
                <th className="right">Valor Total</th>
              </tr>
            </TableHeader>
            <tbody>{this.renderMovimentacoesSaida(movimentacoes)}</tbody>
          </table>
        </div>
      </Panel>
    );
  }
}

function mapStateToProps(state) {}

const allActions = {
  ...actions,
  addErrorNotification
};

export default connect(mapStateToProps, allActions)(MovimentacaoSaida);
