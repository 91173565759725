import Panel from 'portaltransparencia-common/components/Panel.react.js';
import { addHttp } from 'portaltransparencia-common/libs/LinkExterno.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

import PageHeader from '../components/PageHeader.react.js';

export class LeisAtosTerceiros extends Component {
  static propTypes = {
    valor: PropTypes.string.isRequired,
    location: PropTypes.object.isRequired
  };

  renderLinks = linkProcessado => {
    let url = linkProcessado.url;
    url = addHttp(url);
    return (
      <li>
        <a href={url} target="_blank">
          {linkProcessado.descricao || url}
        </a>
      </li>
    );
  };

  render() {
    const { valor } = this.props;
    const linksBrutos = valor.split(';');
    let linksProcessados = [];

    if (linksBrutos && linksBrutos.length > 0) {
      linksBrutos.forEach(link => {
        let url = link.split(',')[0];
        let descricao = link.split(',')[1];
        linksProcessados.push({ url, descricao });
      });
    }

    return (
      <section>
        <PageHeader location={this.props.location} />

        <Panel title="Acesso ao sistema de Leis e Atos" isForm mobileClose>
          <ul className="mt-xs">{linksProcessados.map(this.renderLinks)}</ul>
        </Panel>
      </section>
    );
  }
}

export default LeisAtosTerceiros;
