import BotaoImprimir from 'portaltransparencia-common/components/BotaoImprimir.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import { getAllValuesFromUrlSearchParams } from 'portaltransparencia-common/libs/URLUtils';
import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import {
  downloadArquivo,
  downloadArquivoConvenio
} from '../arquivo/Actions.js';
import PageHeader from '../components/PageHeader.react.js';
import CabecalhoEntidade from '../entidades/CabecalhoEntidade.react.js';
import * as actions from './Actions.js';
import ConvenioDetalheInformacao from './ConvenioDetalheInformacao.react.js';
import Abas from './FormDetailAbas.react.js';

export class FormConvenioDetail extends Component {
  static propTypes = {
    convenio: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    downloadArquivo: PropTypes.func.isRequired,
    downloadArquivoConvenio: PropTypes.func.isRequired,
    findConvenio: PropTypes.func.isRequired,
    aditivos: PropTypes.object.isRequired,
    arquivos: PropTypes.object.isRequired,
    contrapartidarepasses: PropTypes.object.isRequired,
    contrapartidadevolucao: PropTypes.object.isRequired,
    contrapartidapagamentos: PropTypes.object.isRequired,
    repasses: PropTypes.object.isRequired,
    pagamentos: PropTypes.object.isRequired,
    findAditivos: PropTypes.func.isRequired,
    findArquivos: PropTypes.func.isRequired,
    findRepasses: PropTypes.func.isRequired,
    findContraPartidaRepasses: PropTypes.func.isRequired,
    findContraPartidaDevolucao: PropTypes.func.isRequired,
    findContraPartidaPagamento: PropTypes.func.isRequired,
    findPagamentos: PropTypes.func.isRequired,
    isIntegradoOxy: PropTypes.bool
  };

  componentDidMount() {
    const {
      location,
      findConvenio,
      findAditivos,
      findArquivos,
      findRepasses,
      findContraPartidaRepasses,
      findContraPartidaDevolucao,
      findContraPartidaPagamento,
      findPagamentos
    } = this.props;

    const params = getAllValuesFromUrlSearchParams(location);

    findConvenio(
      params.entidade,
      params.id,
      params.nrConvenio,
      params.nrAnoConvenio
    );
    findAditivos(params.entidade, params.id);
    findArquivos(params.entidade, params.id);
    findRepasses(params.entidade, params.id);
    findContraPartidaRepasses(
      params.entidade,
      params.nrConvenio,
      params.nrAnoConvenio,
      params.idContaCorrente,
      1,
      params.local,
      params.id
    );
    findContraPartidaDevolucao(
      params.entidade,
      params.nrConvenio,
      params.nrAnoConvenio,
      params.idContaCorrente,
      2,
      params.local,
      params.id
    );
    findContraPartidaPagamento(
      params.entidade,
      params.nrConvenio,
      params.nrAnoConvenio,
      params.idContaCorrente,
      3,
      params.local,
      params.id
    );

    findPagamentos(
      params.entidade,
      params.idContaCorrente,
      params.local,
      params.id
    );
  }

  render() {
    const {
      convenio,
      downloadArquivo,
      downloadArquivoConvenio,
      arquivos,
      aditivos,
      repasses,
      contrapartidarepasses,
      contrapartidadevolucao,
      contrapartidapagamentos,
      pagamentos,
      isIntegradoOxy
    } = this.props;

    return (
      <div>
        <Helmet
          title={`Convênios - #${convenio.get('nrConvenio')} ${convenio.get(
            'nome'
          )}`}
        />

        <PageHeader
          location={this.props.location}
          textoDetalhe={`Convênio ${convenio.get('nrConvenio')}/${convenio.get(
            'nrAnoConvenio'
          )} - ${convenio.get('nome')}`}
        />

        <BotaoImprimir />

        <CabecalhoEntidade />

        <ConvenioDetalheInformacao convenio={convenio} />

        <Panel expansible={false}>
          <Abas
            aditivos={aditivos}
            repasses={repasses}
            pagamentos={pagamentos}
            arquivos={arquivos}
            downloadArquivo={
              isIntegradoOxy ? downloadArquivoConvenio : downloadArquivo
            }
            contrapartidarepasses={contrapartidarepasses}
            contrapartidadevolucao={contrapartidadevolucao}
            contrapartidapagamentos={contrapartidapagamentos}
          />
        </Panel>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    convenio: state.convenio.get('convenio'),
    aditivos: state.convenio.get('aditivos'),
    arquivos: state.convenio.get('arquivos'),
    repasses: state.convenio.get('repasses'),
    pagamentos: state.convenio.get('pagamentos'),
    contrapartidarepasses: state.convenio.get('contrapartidarepasses'),
    contrapartidadevolucao: state.convenio.get('contrapartidadevolucao'),
    contrapartidapagamentos: state.convenio.get('contrapartidapagamentos'),
    isIntegradoOxy: state.entidade.getIn([
      'configuracoesGerais',
      'isIntegradoOxy'
    ])
  };
}

const allActions = {
  ...actions,
  downloadArquivo,
  downloadArquivoConvenio
};

export default connect(mapStateToProps, allActions)(FormConvenioDetail);
