import { Map } from 'immutable';
import React from 'react';
import Component from 'react-pure-render/component';
import PageHeader from '../components/PageHeader.react';
import NotasExplicativas from '../notaexplicativa/Form.react';
import { Panel } from '@elotech/components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from './Actions.js';
import { Col, Row } from 'react-bootstrap';
import Input from 'portaltransparencia-common/components/EloInput.react.js';
import DatePicker from 'portaltransparencia-common/components/DatePicker.react';
import {
  onChange,
  resetCrud
} from 'portaltransparencia-common/crud/Actions.js';
import {
  addFilterUrl,
  addPagination
} from 'portaltransparencia-common/libs/AddFilter';
import storageService from 'portaltransparencia-common/libs/StorageService';
import ExportButtonGroup from 'portaltransparencia-common/components/ExportButtonGroup.react';
import Tabs from 'portaltransparencia-common/components/Tabs.react';
import Tab from 'portaltransparencia-common/components/Tab.react';
import ResponsavelTable from './ResponsavelTable';
import SearchPagination from 'portaltransparencia-common/components/SearchPagination.react.js';
import UltimaAtualizacaoDataConsulta from '../ultimaatualizacao/UltimaAtualizacaoDataConsulta.react.js';
import configuration from 'portaltransparencia-common/config/Config';
import { addErrorNotification } from 'portaltransparencia-common/ui/Actions.js';

export class FormResponsavel extends Component {
  static propTypes = {
    page: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    searchResponsaveis: PropTypes.func.isRequired,
    filtros: PropTypes.object,
    onChange: PropTypes.func,
    resetCrud: PropTypes.func.isRequired,
    isIntegradoOxy: PropTypes.bool,
    addErrorNotification: PropTypes.func.isRequired
  };

  componentDidMount() {
    const { page, onChange } = this.props;

    const { exercicio } = storageService.getItem('userInfo');

    Promise.all([
      onChange('filtroResponsaveis', {
        name: 'somenteVigentes',
        value: true
      }),

      onChange('filtroResponsaveis', {
        name: 'exercicioContrato',
        value: exercicio.id
      })
    ]).then(() => {
      this.searchWithPage(page);
    });
  }

  onChangeFiltro = event => {
    const { onChange } = this.props;

    onChange('filtroResponsaveis', event);
  };

  onConsultaResponsaveis = event => {
    const { page } = this.props;
    const initialPage = page.set('number', 0);

    this.searchWithPage(initialPage);

    event && event.preventDefault();
    event && event.stopPropagation();
    this.refs.panelFiltroResponsaveis &&
      this.refs.panelFiltroResponsaveis.collapse();
  };

  onResetFiltro = event => {
    const { resetCrud, searchResponsaveis } = this.props;
    event && event.preventDefault();
    event && event.stopPropagation();
    resetCrud('filtroResponsaveis');
    searchResponsaveis('');
  };

  montaFilter = function (page) {
    const { filtros } = this.props;
    const { entidade } = storageService.getItem('userInfo');

    let filter = `entidade=${entidade.id}`;

    if (filtros.get('ocupante')) {
      filter = addFilterUrl(filter, `ocupante=${filtros.get('ocupante')}`);
    }

    if (filtros.get('tipoAto')) {
      filter = addFilterUrl(filter, `tipoAto=${filtros.get('tipoAto')}`);
    }

    if (filtros.get('numeroContrato')) {
      filter = addFilterUrl(
        filter,
        `numeroContrato=${filtros.get('numeroContrato')}`
      );
    }

    if (filtros.get('exercicioContrato')) {
      filter = addFilterUrl(
        filter,
        `exercicioContrato=${filtros.get('exercicioContrato')}`
      );
    }

    if (filtros.get('inicioVigencia')) {
      filter = addFilterUrl(
        filter,
        `inicioVigencia=${filtros.get('inicioVigencia')}`
      );
    }

    if (filtros.get('fimVigencia')) {
      filter = addFilterUrl(
        filter,
        `fimVigencia=${filtros.get('fimVigencia')}`
      );
    }

    if (filtros.get('somenteVigentes')) {
      filter = addFilterUrl(
        filter,
        `somenteVigentes=${filtros.get('somenteVigentes')}`
      );
    }

    return addPagination(page, filter);
  };

  searchWithPage = (page, props) => {
    const { searchResponsaveis } = props || this.props;
    searchResponsaveis(this.montaFilter(page));
  };

  onExporta = (exportType, openTab, windowParams) => {
    const { entidade } = storageService.getItem('userInfo');
    const { page, addErrorNotification } = this.props;
    const { apiURL } = configuration;

    if (page.get('content').size > 0) {
      let filtro = this.montaFilter(page) + '&exportType=' + exportType;

      window.open(
        `${apiURL}/api/responsaveis/report?entidade=${entidade.id}&${filtro}`,
        openTab,
        windowParams
      );
    } else {
      addErrorNotification(
        'A consulta não apresentou resultado. Não é possível exportar para o formato selecionado!'
      );
    }
  };

  render() {
    const { page, location, filtros, isIntegradoOxy } = this.props;

    if (!page.has('content')) {
      return null;
    }

    return (
      <section>
        <PageHeader location={location} />

        <NotasExplicativas pathname={location.pathname} />

        <Panel
          title="Consultar em Responsáveis"
          mobileClose
          isForm
          ref="panelFiltroResponsaveis"
        >
          <form>
            <Row>
              <Col xs={12} sm={4} md={3}>
                <Input
                  id="ocupante"
                  placeholder="Informe o nome do ocupante"
                  name="ocupante"
                  ref="ocupante"
                  label="Ocupante"
                  type="text"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('ocupante')}
                />
              </Col>
              <Col xs={12} sm={4} md={3}>
                <Input
                  id="tipoAto"
                  placeholder="Informe o Tipo do Ato"
                  type="select"
                  ref="tipoAto"
                  name="tipoAto"
                  label="tipo Ato"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('tipoAto')}
                >
                  <option value="">Selecione</option>
                  <option key={'Contrato'} value={1}>
                    Contrato
                  </option>
                  <option key={'Ata de Registro de Preços'} value={2}>
                    Ata de Registro de Preços
                  </option>
                </Input>
              </Col>
              <Col xs={12} sm={4} md={3}>
                <Input
                  id="numeroContrato"
                  placeholder="Informe Número do Contrato"
                  type="text"
                  ref="numeroContrato"
                  name="numeroContrato"
                  label="Número do Contrato"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('numeroContrato')}
                />
              </Col>
              <Col xs={12} sm={4} md={3}>
                <Input
                  id="exercicioContrato"
                  placeholder="Informe Exercício do Contrato"
                  type="text"
                  ref="exercicioContrato"
                  name="exercicioContrato"
                  label="Exercício do Contrato"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('exercicioContrato')}
                />
              </Col>
              <Col xs={12} sm={4} md={3}>
                <DatePicker
                  id="inicioVigencia"
                  name="inicioVigencia"
                  ref="inicioVigencia"
                  label="Início do Término da Vigência Contrato"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('inicioVigencia')}
                ></DatePicker>
              </Col>
              <Col xs={12} sm={4} md={3}>
                <DatePicker
                  id="fimVigencia"
                  name="fimVigencia"
                  ref="fimVigencia"
                  label="Fim do Término da Vigência Contrato"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('fimVigencia')}
                ></DatePicker>
              </Col>
              <Col xs={6} sm={6} md={3}>
                <div className="form-group">
                  <label className="label"></label>
                  <Input
                    id="somenteVigentes"
                    label="Somente Vigentes"
                    type="checkbox"
                    name="somenteVigentes"
                    ref="somenteVigentes"
                    onChange={this.onChangeFiltro}
                    checked={filtros.get('somenteVigentes')}
                  />
                </div>
              </Col>
              <Col xs={12} sm={12} md={12}>
                <div className="form-group">
                  <label className="label" />

                  <div className="btn-group">
                    <button
                      id="btnFiltrar"
                      className="btn module-color icon-right"
                      ref="filtrar"
                      onClick={this.onConsultaResponsaveis}
                    >
                      Pesquisar
                      <em className="fa fa-search" />
                    </button>
                    <button
                      id="btnReset"
                      className="btn neutral icon-right inline no-text"
                      type="button"
                      onClick={this.onResetFiltro}
                    >
                      Limpar
                      <em className="fa fa-times" />
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </form>
        </Panel>

        <Panel isForm tableResponsive expansible={false}>
          <Row>
            <Col xs={6}>
              <UltimaAtualizacaoDataConsulta />
            </Col>
            <Col xs={6}>
              <ExportButtonGroup onClick={this.onExporta} visible={true} />
            </Col>
          </Row>

          <Tabs ref="tab">
            <Tab title="Responsáveis" name="responsaveis" onlyTable active>
              <ResponsavelTable
                page={page}
                searchWithPage={this.searchWithPage}
                isIntegradoOxy={isIntegradoOxy}
              />
              <SearchPagination
                page={page}
                searchWithPage={this.searchWithPage}
              />
            </Tab>
          </Tabs>
        </Panel>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    page: state.responsavel.get('responsaveis'),
    filtros: state.crud.getIn(['filtroResponsaveis', 'currentRecord'], Map({})),
    isIntegradoOxy: state.entidade.getIn([
      'configuracoesGerais',
      'isIntegradoOxy'
    ])
  };
}

const allActions = {
  ...actions,
  onChange,
  resetCrud,
  addErrorNotification
};

export default connect(mapStateToProps, allActions)(FormResponsavel);
