export const LICITACOES_PARTICIPANTES = 'LICITACOES_PARTICIPANTES';
export const LICITACOES_PARTICIPANTES_START = 'LICITACOES_PARTICIPANTES_START';
export const LICITACOES_PARTICIPANTES_SUCCESS =
  'LICITACOES_PARTICIPANTES_SUCCESS';
export const LICITACOES_PARTICIPANTES_ERROR = 'LICITACOES_PARTICIPANTES_ERROR';

export const LICITACOES_PARTICIPANTE = 'LICITACOES_PARTICIPANTE';
export const LICITACOES_PARTICIPANTE_START = 'LICITACOES_PARTICIPANTE_START';
export const LICITACOES_PARTICIPANTE_SUCCESS =
  'LICITACOES_PARTICIPANTE_SUCCESS';
export const LICITACOES_PARTICIPANTE_ERROR = 'LICITACOES_PARTICIPANTE_ERROR';
