import ButtonSwitch from 'portaltransparencia-common/components/buttonswitch/ButtonSwitch.react.js';
import ButtonSwitchItem from 'portaltransparencia-common/components/buttonswitch/ButtonSwitchItem.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import Tab from 'portaltransparencia-common/components/Tab.react.js';
import Tabs from 'portaltransparencia-common/components/Tabs.react.js';
import { addHttp } from 'portaltransparencia-common/libs/LinkExterno.js';
import NumberUtils from 'portaltransparencia-common/libs/NumberUtils';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import {
  PARAM_ATAS_AUDIENCIA,
  PARAM_PLANEJAMENTOS,
  PARAM_PLANEJAMENTO_ESTRATEGICO
} from 'portaltransparencia-common/parametros/ParametrosPortalTransparencia';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import { downloadArquivo } from '../arquivo/Actions.js';
import PageHeader from '../components/PageHeader.react.js';
import NotasExplicativas from '../notaexplicativa/Form.react';
import ParametroService from '../parametros/ParametroService.js';
import * as actions from './Actions.js';
import FormArquivosObjetivoEstrategico from './FormArquivosObjetivoEstrategico.react.js';

export class FormOrcamento extends Component {
  static propTypes = {
    findOrcamentos: PropTypes.func.isRequired,
    findObjetivoEstrategico: PropTypes.func.isRequired,
    orcamentos: PropTypes.object.isRequired,
    objetivoEstrategico: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    downloadArquivo: PropTypes.func.isRequired,
    exercicios: PropTypes.object.isRequired,
    valorParametro: PropTypes.string,
    paramPlanejamentoEstrategico: PropTypes.string,
    paramAtasAudiencia: PropTypes.string
  };

  state = {
    type: 1
  };

  componentDidMount() {
    const { findOrcamentos, exercicios, findObjetivoEstrategico } = this.props;
    const { entidade, exercicio } = StorageService.getItem('userInfo');

    const exercicioLogado = exercicios.find(
      u =>
        u.get('id').get('entidade').get('id') == entidade.id &&
        u.get('id').get('exercicio') == exercicio.id
    );
    const filtro =
      'entidade=' +
      entidade.id +
      '&exercicio=' +
      exercicio.id +
      '&anoBasePPA=' +
      exercicioLogado.get('anoBasePPA');
    findOrcamentos(filtro);
    findObjetivoEstrategico(filtro);
  }

  onDownloadEstrutura = data => {
    return () => {
      const { downloadArquivo } = this.props;

      downloadArquivo(data);
    };
  };

  renderObjetivoEstrategico = data => {
    return (
      <Panel
        key={data.get('objCodigo')}
        insidePanel
        title={data.get('descricao')}
      >
        <FormArquivosObjetivoEstrategico
          arquivos={data.get('arquivos')}
          download={this.onDownloadEstrutura}
        />
      </Panel>
    );
  };

  renderOrcamento = data => {
    return (
      <Panel key={data.get('id')} insidePanel title={data.get('descricao')}>
        {data.get('resumo') || data.get('nomeArquivo') || data.get('tipo')}

        {data.get('idArquivo') && (
          <button
            type="button"
            className="btn neutral icon-left inline ml-xs"
            onClick={this.onDownloadEstrutura(data)}
            title="Baixar"
          >
            <i className="fa fa-download"></i>
            {`Baixar ${NumberUtils.formatBytes(data.get('tamanhoArquivo'))}`}
          </button>
        )}
        {data.get('link') && (
          <a
            href={addHttp(data.get('link'))}
            target="_blank"
            className="btn neutral icon-left inline ml-xs"
          >
            <i className="fa fa-external-link"></i>
            Abrir Link
          </a>
        )}
      </Panel>
    );
  };

  semArquivo() {
    return <div className="center">Sem arquivos.</div>;
  }

  setType = value => {
    return () => this.setState({ type: value });
  };

  render() {
    const {
      orcamentos,
      objetivoEstrategico,
      paramPlanejamentoEstrategico,
      paramAtasAudiencia
    } = this.props;
    const { type } = this.state;

    let orcamentoPPA = orcamentos
      .filter(o => o.get('tipo') === 'PPA')
      .sort((a, b) => a.get('ordem') - b.get('ordem'));
    let orcamentoLOA = orcamentos
      .filter(o => o.get('tipo') === 'LOA')
      .sort((a, b) => a.get('ordem') - b.get('ordem'));
    let orcamentoLDO = orcamentos
      .filter(o => o.get('tipo') === 'LDO')
      .sort((a, b) => a.get('ordem') - b.get('ordem'));
    let orcamentoAtasPPA = orcamentos
      .filter(o => o.get('tipo') === 'Atas de Audiência - PPA')
      .sort((a, b) => a.get('ordem') - b.get('ordem'));
    let orcamentoAtasLDO = orcamentos
      .filter(o => o.get('tipo') === 'Atas de Audiência - LDO')
      .sort((a, b) => a.get('ordem') - b.get('ordem'));
    let orcamentoAtasLOA = orcamentos
      .filter(o => o.get('tipo') === 'Atas de Audiência - LOA')
      .sort((a, b) => a.get('ordem') - b.get('ordem'));

    return (
      <section>
        <PageHeader location={this.props.location} />

        <NotasExplicativas pathname={this.props.location.pathname} />

        <Panel expansible={false}>
          <Tabs ref="tab">
            <Tab key="ppa" title="PPA (Plano Pluri Anual)" name="ppa" active>
              {orcamentoPPA.map(this.renderOrcamento)}
              {orcamentoPPA.isEmpty() && this.semArquivo()}
            </Tab>

            <Tab
              key="ldo"
              title="LDO (Lei de Diretrizes Orçamentaria)"
              name="ldo"
            >
              {orcamentoLDO.map(this.renderOrcamento)}
              {orcamentoLDO.isEmpty() && this.semArquivo()}
            </Tab>

            <Tab key="loa" title="LOA (Lei Orçamentária Anual)" name="loa">
              {orcamentoLOA.map(this.renderOrcamento)}
              {orcamentoLOA.isEmpty() && this.semArquivo()}
            </Tab>

            {paramAtasAudiencia == 'S' && (
              <Tab key="atas" title="Atas de Audiência" name="atas">
                <ButtonSwitch>
                  <ButtonSwitchItem
                    key="btnPPA"
                    title="PPA (Plano Pluri Anual)"
                    name="btnPPA"
                    active={type === 1}
                    onClick={this.setType(1)}
                  />
                  <ButtonSwitchItem
                    key="btnLDO"
                    title="LDO (Lei de Diretrizes Orçamentaria)"
                    name="btnLDO"
                    active={type === 2}
                    onClick={this.setType(2)}
                  />
                  <ButtonSwitchItem
                    key="btnLOA"
                    title="LOA (Lei Orçamentária Anual)"
                    name="btnLOA"
                    active={type === 3}
                    onClick={this.setType(3)}
                  />
                </ButtonSwitch>

                {type === 1 && (
                  <div className="mt-xs">
                    {orcamentoAtasPPA.map(this.renderOrcamento)}
                    {orcamentoAtasPPA.isEmpty() && this.semArquivo()}
                  </div>
                )}
                {type === 2 && (
                  <div className="mt-xs">
                    {orcamentoAtasLDO.map(this.renderOrcamento)}
                    {orcamentoAtasLDO.isEmpty() && this.semArquivo()}
                  </div>
                )}
                {type === 3 && (
                  <div className="mt-xs">
                    {orcamentoAtasLOA.map(this.renderOrcamento)}
                    {orcamentoAtasLOA.isEmpty() && this.semArquivo()}
                  </div>
                )}
              </Tab>
            )}

            {paramPlanejamentoEstrategico == 'S' && (
              <Tab
                key="planejamento"
                title="Planejamento Estratégico"
                name="planejamento"
              >
                {objetivoEstrategico.map(this.renderObjetivoEstrategico)}
                {objetivoEstrategico.isEmpty() && this.semArquivo()}
              </Tab>
            )}
          </Tabs>
        </Panel>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    orcamentos: state.orcamento.get('orcamentos'),
    objetivoEstrategico: state.orcamento.get('objetivoEstrategico'),
    exercicios: state.entidade.get('exercicios'),
    valorParametro: ParametroService.getValorParametro(
      state,
      PARAM_PLANEJAMENTOS
    ),
    paramPlanejamentoEstrategico: ParametroService.getValorParametro(
      state,
      PARAM_PLANEJAMENTO_ESTRATEGICO
    ),
    paramAtasAudiencia: ParametroService.getValorParametro(
      state,
      PARAM_ATAS_AUDIENCIA
    )
  };
}

const allActions = {
  ...actions,
  downloadArquivo
};

export default connect(mapStateToProps, allActions)(FormOrcamento);
