import PropTypes from 'prop-types';
import React from 'react';

export class Subtitle extends React.PureComponent {
  static propTypes = {
    caption: PropTypes.string,
    description: PropTypes.string
  };

  render() {
    const { caption, description } = this.props;

    return (
      <div>
        <strong>{caption}</strong>
        <br />
        <em>{description}</em>
      </div>
    );
  }
}

export default Subtitle;
