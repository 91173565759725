export function numeroPlaca(placa) {
  if (placa) {
    return placa.replace(/[^A-Z0-9]/gi, '').substring(3);
  }
  return '';
}

export function letraPlaca(placa) {
  if (placa) {
    return placa.replace(/[^A-Z0-9]/gi, '').substring(0, 3);
  }
  return '';
}
